import React from "react";
import Container from "../../containers/events-noregister";

const Index = () => {
  return (
    <div className={""}>
      <Container />
    </div>
  );
};

export default Index;
