/* eslint-disable react-hooks/rules-of-hooks */
import CreateAiPersonality from "../../components/ai-profile/create-ai-personality";
import { useIntl } from "react-intl";

import { Link } from "react-router-dom";
import { useAuth } from "../../../auth";

type ApiProps =  {
  isAd: boolean;
  userImage: string;
  personalityImage: string;
  personalityName: string;
}

export default function Api({isAd, personalityImage, userImage, personalityName}:ApiProps) {
  const { formatMessage } = useIntl();
  const {currentUser, setNextRoute} = useAuth()
  return (
    <div className="h-[80vh]">
      {isAd && <CreateAiPersonality />}
      <div className={"text-center my-[88px]"}>
        <h2
          className={
            "text-[14px] md:text-[24px] leading-5 md:leading-[32px] text-[#FFFFFFCC] font-semibold mb-[12px]"
          }
        >
          {formatMessage({
            id: "API access of"
          })}
          {" "}{personalityName?.split(' ')[0]}{"'s "}
          {
            formatMessage({
              id:"AI Clone or Personality"
            })
          }
        </h2>
        <div className={"flex justify-center"}>
          <div className={"relative h-[70px] md:h-[110px] w-72 mb-[12px]"}>
            <div
              className={
                "absolute top-0 right-32 bg-[#2E2F45] shadow-default rounded-full p-[4px] md:p-1.5"
              }
            >
              <img
                src={personalityImage}
                className={"rounded-full md:h-[108px] h-[60px] md:w-[108px]"}
                alt=""
              />
            </div>
            <div
              className={
                "absolute top-0  left-32 bg-[#2E2F45] shadow-default rounded-full p-[4px] md:p-1.5"
              }
            >
              <img
                src={userImage}
                className={"rounded-full h-[60px] md:h-[108px] md:w-[108px]"}
                alt=""
              />
            </div>
            <div
              className={
                "absolute -bottom-2 left-1/2 -translate-x-1/2 bg-[#2E2F45] shadow-default p-1.5 rounded-full"
              }
            >
              <svg
                className={"h-[16px] md:h-[28px] w-[16px] md:w-[28px]"}
                viewBox="0 0 28 28"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g clipPath="url(#clip0_344_19686)">
                  <path
                    d="M9.33333 10.4993H18.6667M9.33333 15.166H16.3333M21 4.66602C21.9283 4.66602 22.8185 5.03476 23.4749 5.69114C24.1313 6.34752 24.5 7.23776 24.5 8.16602V17.4993C24.5 18.4276 24.1313 19.3178 23.4749 19.9742C22.8185 20.6306 21.9283 20.9994 21 20.9994H15.1667L9.33333 24.4994V20.9994H7C6.07174 20.9994 5.1815 20.6306 4.52513 19.9742C3.86875 19.3178 3.5 18.4276 3.5 17.4993V8.16602C3.5 7.23776 3.86875 6.34752 4.52513 5.69114C5.1815 5.03476 6.07174 4.66602 7 4.66602H21Z"
                    stroke="#C2D24B"
                    strokeWidth="1.8"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_344_19686">
                    <rect width="28" height="28" fill="white" />
                  </clipPath>
                </defs>
              </svg>
            </div>
          </div>
        </div>

        <h2
          className={
            "text-[14px] md:text-[24px] leading-5 md:leading-[32px] text-[#FFFFFFCC] font-semibold mt-[24px] mb-[8px]"
          }
        >
          {formatMessage({
            id: "Subscribe and Utilize"
          })}
          {" "}{personalityName?.split(' ')[0]}{"'s "}
          {formatMessage({id:"AI Clone"})}
        </h2>
        <div className={"flex justify-center mb-4"}>
          <p
            className={
              "max-w-[650px] mb-[20px] text-[14px] leading-[24px] text-[#FFFFFFA6] font-normal"
            }
          >
            {formatMessage({
              id: "Chat in an exclusive realm with the"
            })}
            {" "}{personalityName}{"'s "}
            {formatMessage({
              id:"AI Clone or Character within your mobile app, website or game. Experience the"
            })}
            {" "}{personalityName}{"'s "}
            {formatMessage({
              id:"charm through engaging dialogues and interactive conversations within your own applications."
            })}
          </p>
        </div>
        <Link
          to={currentUser ? '/apis?tab=api-keys': '/auth/login'}
          onClick={()=>{
            !currentUser && setNextRoute('/apis?tab=api-keys')
          }}
          className={
            "bg-[#C2D24B] py-[12px] md:py-[16px] px-[24px] rounded w-full md:w-auto text-black font-semibold"
          }
        >
          {formatMessage({
            id: "Get API Access Now",
          })}
        </Link>
      </div>
    </div>
  );
}
