import React, { useEffect, useState } from "react";
import { Modal, Dropdown, Spinner } from "react-bootstrap";
import { useIntl } from "react-intl";
import { toAbsoluteUrl } from "../../../../_metronic/helpers";
import {
  updateTransferOwnership,
  getUser,
  deleteUser,
  deletePendingInvite,
  getPendingUsers,
  getUserList,
} from "../../profile/core/_requests";
import { useAuth } from "../../auth";
import { toast } from "react-toastify";
import UserModal from "../../profile/components/UserModal";
import { BasicButton } from "../../widgets/components/UI/BasicButton";
import { useDateFormat } from "../../../hooks/useDateFormat";
import { DateTime } from "luxon";
import { useTimeZone } from "../../../hooks/useTimeZone";
import { RolesName } from "../../profile/core/_constants";
import SearchInput from "../../profile/components/SearchInput";
import { capitalize } from "lodash";
import ReceivedInvites from "../../profile/components/ReceivedInvites";
import useGTM from "../../../hooks/useGTM";
export const Team = ({ key }: { key: number }) => {
  const { formatMessage } = useIntl();
  const {dataLayerPush} = useGTM()
  const [modalShow, setModalShow] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [showDeleteModal, setDeleteShowModal] = useState(false);
  const [addModal, setAddModal] = useState(false);
  const { selectedPersonality, currentUser } = useAuth();
  const [userId, setUserId] = useState<any>();
  const [editUserModal, setEditUserModal] = useState(false);
  const [showUserId, setShowUserId] = useState<any>();
  const [userDetails, setUserDetails] = useState<any>();
  const [flag, setFlag] = useState(false);
  const [transferOwnershipRadio, setTransferOwnershipRadio] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [deletePendingLoading, setDeletePendingLoading] = useState(false);
  const [getUserApiSpinner, setUserApiSpinner] = useState(false);
  const { getDateValue } = useDateFormat();
  const { getTimeZoneValue } = useTimeZone();
  const [showDeletePendingInvites, setShowDeletePendingInvites] =
    useState(false);
  const [selectedInviteId, setSelectedInviteId] = useState<any>();
  const [userList, setUserList] = useState<any[]>([]);
  const [pendingUsersList, setPendingUsersList] = useState<any[]>([]);
  const currentUserId = currentUser?.userId;
  const personalityId = selectedPersonality?.personalityId || "";
  let currentUserRole = "";
  if (currentUserId && userList && userList.length) {
    let filteredUser = userList.filter(
      (item: any) => item.userId === currentUserId
    );
    currentUserRole = filteredUser?.[0]?.roleId[0];
  }
  const [userListLoading, setUserListLoading] = useState<boolean>(true);
  const [pendingListLoading, setPendingListLoading] = useState<boolean>(true);
  const getPendingUserList = async () => {
    setPendingListLoading(true);
    try {
      if (personalityId) {
        const {
          data: { success, data, errors },
        } = await getPendingUsers(personalityId);
        if (success) {
          setPendingUsersList(data);
        } else {
          errors.forEach((error: string) => {
            toast.error(formatMessage({ id: error }),
            {
              autoClose: false,
              closeButton: true, // Display close button (cross icon)
            });
          });
        }
      }
    } catch (err) {
      console.log(err);
    }
    setPendingListLoading(false);
  };

  const getUsersList = async () => {
    setUserListLoading(true);
    try {
      if (personalityId) {
        const {
          data: { success, data, errors },
        } = await getUserList(personalityId);
        if (success) {
          setUserList(data);
        } else {
          errors.forEach((error: string) => {
            toast.error(formatMessage({ id: error }),
            {
              autoClose: false,
              closeButton: true, // Display close button (cross icon)
            });
          });
        }
      }
    } catch (err) {
      console.log(err);
    }
    setUserListLoading(false);
  };

  useEffect(() => {
    getUsersList();
  }, [personalityId]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (personalityId) getPendingUserList();
  }, [personalityId]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (flag && showUserId) {
      getUserData();
    }
  }, [flag, showUserId]);

  const UpdateTransferOwnership = async () => {
    try {
      const {
        data: { success, errors },
      } = await updateTransferOwnership(personalityId, userId);

      if (success) {
        toast.success(formatMessage({ id: "Transfer Ownership updated" }));
      } else {
        errors.forEach((error: string) => {
          toast.error(formatMessage({ id: error }),
          {
            autoClose: false,
            closeButton: true, // Display close button (cross icon)
          });
        });
      }
    } catch (err) {
      console.log(err);
    }
  };

  const DeleteUser = async () => {
    try {
      setDeleteLoading(true);
      const {
        data: { success, errors },
      } = await deleteUser(personalityId, showUserId);
      if (success) {
        toast.success(formatMessage({ id: "User Deleted" }));
        getUsersList();
      } else {
        errors.forEach((error: string) => {
          toast.error(formatMessage({ id: error }),
          {
            autoClose: false,
            closeButton: true, // Display close button (cross icon)
          });
        });
      }
    } catch (err) {
      console.log(err);
    } finally {
      setDeleteLoading(false);
      setDeleteShowModal(!showDeleteModal);
    }
  };

  const deleteInvite = async (inviteId: number) => {
    try {
      setDeletePendingLoading(true);
      const {
        data: { success, errors },
      } = await deletePendingInvite(inviteId);
      if (success) {
        toast.success(formatMessage({ id: "Invite Deleted" }));
        getPendingUserList();
      } else {
        errors.forEach((error: string) => {
          toast.error(formatMessage({ id: error }),
          {
            autoClose: false,
            closeButton: true, // Display close button (cross icon)
          });
        });
      }
    } catch (err) {
      console.log(err);
    } finally {
      setDeletePendingLoading(false);
      setShowDeletePendingInvites(false);
    }
  };

  const getUserData = async () => {
    try {
      setUserApiSpinner(true);
      const {
        data: { success, data, errors },
      } = await getUser(personalityId, showUserId);
      if (success) {
        setUserDetails(data);
        setUserApiSpinner(false);
      } else {
        setUserApiSpinner(false);
        errors.forEach((error: string) => {
          toast.error(formatMessage({ id: error }),
          {
            autoClose: false,
            closeButton: true, // Display close button (cross icon)
          });
        });
      }
    } catch (err) {
      setUserApiSpinner(false);
      console.log(err);
    }
  };

  return (
    <>
      <SearchInput />
      <div className="tab-container !px-[0px] !py-[8px] md:!p-[24px] shadow-default rounded mt-4" >
        <div className="card-body">
          <div className="row d-flex">
            <div className="col-md-9 col-12">
              <h4 className="text-[16px] leading-[22px] primary-text font-medium  mb-[4px] md:mb-[8px]">
                {formatMessage({ id: "My Team" })}
              </h4>
              <p className="text-[14px] leading-5 secondary-text font-normal">
                {formatMessage({
                  id: "Name & Roles of users who are part of your team to help you manage",
                })}{" "}
                {selectedPersonality?.name}
                {"'s "}
                {formatMessage({ id: "AI Personality" })}
              </p>
            </div>
            <div className="col-12 d-md-flex flex-md-row justify-content-end col-md-3">
              <div className="mt-3 row">
                <div className="col-12">
                  {currentUserRole === "owner" && (
                    <button
                      className="btn export-button text-[14px] font-medium w-full md:w-auto"
                      onClick={() => {
                        setAddModal(true);
                        setFlag(false);
                      }}
                    >
                      {formatMessage({ id: "Add New User" })}
                    </button>
                  )}
                  <UserModal
                    title={formatMessage({ id: "Add New User" })}
                    buttonText={formatMessage({ id: "Add User" })}
                    addModal={addModal}
                    setAddModal={setAddModal}
                    flag={flag}
                    getUsersList={getUsersList}
                    getPendingUsers={getPendingUserList}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <div className="mt-6 table-responsive custom-table">
                <table className="w-100">
                  <thead>
                    <tr className="custom-thead font-size-12 font-weight-400">
                      <td className="pb-9 text-[12px] leading-[16px]">
                        {formatMessage({ id: "Name" })}
                      </td>
                      <td className="pb-9 text-[12px] leading-[16px]">
                        {formatMessage({ id: "Role" })}
                      </td>
                      <td className="pb-9 text-[12px] leading-[16px]">
                        {formatMessage({ id: "Email Address" })}
                      </td>
                      <td className="pb-9 text-[12px] leading-[16px]">
                        <div className="gap-2 d-flex">
                          <p>
                            {formatMessage({ id: "Last Modification Date" })}
                          </p>
                          <img
                            src={toAbsoluteUrl(
                              "/media/icons/profile/down_arrow.svg"
                            )}
                            alt=""
                            width={10}
                            className="mt-2"
                            height={7}
                          />
                        </div>
                      </td>
                      {currentUserRole === "owner" && (
                        <td className="text-center pb-9 text-[12px] leading-[16px]">
                          {formatMessage({ id: "Action" })}
                        </td>
                      )}
                    </tr>
                  </thead>
                  <tbody className="font-size-13 font-weight-400 secondary-text leading-[20px]">
                    {userListLoading ? (
                      <tr className="!h-[60px]">
                        <td colSpan={5} className="text-center">
                          <Spinner animation="border" />
                        </td>
                      </tr>
                    ) : (
                      userList.map((user: any) =>
                        user.roleId.includes("owner") ? (
                          <>
                            <tr className="!h-[40px]">
                              <td className="min-w-175px">
                                {user.firstName} {user.lastName}
                              </td>
                              <td className=" min-w-175px">
                                {RolesName[user.roleId[0]]}
                              </td>
                              <td className=" min-w-250px">{user.email}</td>
                              <td className=" min-w-175px">
                                {user.updatedAt
                                  ? getDateValue(user.updatedAt?.toString())
                                    ? getDateValue(user.updatedAt?.toString())
                                    : DateTime.fromISO(
                                        user.updatedAt?.toString()
                                      ).toLocaleString(DateTime.DATE_MED)
                                  : "-"}{" "}
                                {user.updatedAt &&
                                  getTimeZoneValue(user.updatedAt?.toString())}
                              </td>
                              {user.userId === currentUserId && (
                                <td
                                  className="text-center cursor-pointer main-text min-w-175px"
                                  onClick={() => setModalShow(!modalShow)}
                                >
                                  {formatMessage({ id: "Transfer Ownership" })}
                                </td>
                              )}
                            </tr>
                          </>
                        ) : (
                          ""
                        )
                      )
                    )}

                    {userList.map((user: any) =>
                      user.roleId.includes("owner") ? (
                        ""
                      ) : (
                        <>
                          <tr key={user.userId} className="!h-[40px]">
                            <td className="min-w-175px">
                              <div>
                                {user.firstName} {user.lastName}
                              </div>
                            </td>
                            <td className="min-w-175px">
                              {user.roleId.length > 0
                                ? RolesName[user.roleId[0]]
                                : "-"}
                            </td>
                            <td className="min-w-250px">{user.email}</td>
                            <td className="min-w-175px">
                              {user.updatedAt
                                ? getDateValue(user.updatedAt?.toString())
                                  ? getDateValue(user.updatedAt?.toString())
                                  : DateTime.fromISO(
                                      user.updatedAt?.toString()
                                    ).toLocaleString(DateTime.DATE_MED)
                                : "-"}{" "}
                              {user.updatedAt &&
                                getTimeZoneValue(user.updatedAt?.toString())}
                            </td>
                            {currentUserRole === "owner" && (
                              <td className="p-0 text-center min-w-175px">
                                <div className="w-100 d-flex justify-content-center position-relative">
                                  <div className="flex gap-x-2">
                                    <button
                                      className={
                                        "w-9 h-9 flex items-center justify-center rounded-[6px] bg-[#C2D24B1A]"
                                      }
                                      onClick={() => {
                                        setShowUserId(user.userId);
                                        setEditUserModal(true);
                                        setFlag(true);
                                      }}
                                    >
                                      <svg
                                        width="19"
                                        height="18"
                                        viewBox="0 0 19 18"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                      >
                                        <path
                                          d="M11 3.0003L15 7.0003M13.5 14.5003H17.5M5.5 16.5003L16 6.0003C16.2626 5.73766 16.471 5.42585 16.6131 5.08269C16.7553 4.73953 16.8284 4.37174 16.8284 4.0003C16.8284 3.62887 16.7553 3.26107 16.6131 2.91791C16.471 2.57475 16.2626 2.26295 16 2.0003C15.7374 1.73766 15.4256 1.52932 15.0824 1.38718C14.7392 1.24503 14.3714 1.17187 14 1.17188C13.6286 1.17188 13.2608 1.24503 12.9176 1.38718C12.5744 1.52932 12.2626 1.73766 12 2.0003L1.5 12.5003V16.5003H5.5Z"
                                          stroke="#C2D24B"
                                          strokeWidth="1.6"
                                          strokeLinecap="round"
                                          strokeLinejoin="round"
                                        />
                                      </svg>
                                    </button>
                                    <button
                                      className={
                                        "w-9 h-9 flex items-center justify-center rounded-[6px] bg-[#C2D24B1A]"
                                      }
                                      onClick={() => {
                                        setShowUserId(user.userId);
                                        setDeleteShowModal(!showDeleteModal);
                                      }}
                                    >
                                      <svg
                                        width="24"
                                        height="24"
                                        viewBox="0 0 24 24"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                      >
                                        <path
                                          d="M4 7H20M10 11V17M14 11V17M5 7L6 19C6 19.5304 6.21071 20.0391 6.58579 20.4142C6.96086 20.7893 7.46957 21 8 21H16C16.5304 21 17.0391 20.7893 17.4142 20.4142C17.7893 20.0391 18 19.5304 18 19L19 7M9 7V4C9 3.73478 9.10536 3.48043 9.29289 3.29289C9.48043 3.10536 9.73478 3 10 3H14C14.2652 3 14.5196 3.10536 14.7071 3.29289C14.8946 3.48043 15 3.73478 15 4V7"
                                          stroke="#C2D24B"
                                          strokeWidth="1.6"
                                          strokeLinecap="round"
                                          strokeLinejoin="round"
                                        />
                                      </svg>
                                    </button>
                                  </div>
                                </div>
                              </td>
                            )}
                          </tr>
                          <UserModal
                            title={formatMessage({ id: "Edit User" })}
                            buttonText={formatMessage({ id: "Edit" })}
                            addModal={editUserModal}
                            setAddModal={setEditUserModal}
                            flag={flag}
                            userDetails={userDetails}
                            getUsersList={getUsersList}
                            getUserApiSpinner={getUserApiSpinner}
                            setShowUserId={setShowUserId}
                          />
                        </>
                      )
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Pending Invites */}
      {pendingListLoading ||
      (!pendingListLoading && !pendingUsersList.length) ? null : (
        <div className="tab-container !px-0 !py-[8px] md:!p-[24px] shadow-default rounded mt-8">
          <div className="card-body">
            <div className="row d-flex">
              <div className="col-md-9 col-12">
                <h4 className="text-[16px] leading-[22px] primary-text font-medium  mb-[4px] md:mb-[8px]">
                  {formatMessage({ id: "Invites I have sent" })}
                </h4>
                <p className="text-[14px] leading-5 secondary-text font-normal">
                  {formatMessage({
                    id: "List of pending Invites sent by you to other people to join your Team to manage",
                  })}{" "}
                  {selectedPersonality?.name}
                  {"'s "}
                  {formatMessage({ id: "AI Personality" })}
                </p>
              </div>
            </div>
            <div className="row">
              <div className="col-12">
                <div className="mt-4 table-responsive custom-table">
                  <table className="w-100">
                    <thead>
                      <tr className="custom-thead font-size-12 leading-[16px] font-weight-400 primary-text">
                        <td className="pb-4">
                          {formatMessage({ id: "Name" })}
                        </td>
                        <td className="pb-4">
                          {formatMessage({ id: "Email Address" })}
                        </td>
                        <td className="pb-4">
                          {formatMessage({ id: "Role Invited For" })}
                        </td>
                        <td className="pb-4">
                          <div className="gap-2 d-flex">
                            <p>{formatMessage({ id: "Invited At" })}</p>
                            <img
                              src={toAbsoluteUrl(
                                "/media/icons/profile/down_arrow.svg"
                              )}
                              alt=""
                              width={10}
                              className="mt-2"
                              height={7}
                            />
                          </div>
                        </td>
                        <td className="pb-4 text-center">
                          {formatMessage({ id: "Action" })}
                        </td>
                      </tr>
                    </thead>
                    <tbody className="font-size-13 leading-[20px] font-weight-400 secondary-text">
                      {pendingUsersList.map((user: any) => (
                        <>
                          <tr id={user.inviteId} className="!h-[40px]">
                            <td className="pt-4 min-w-175px">{user.name}</td>
                            <td className=" min-w-250px">{user.email}</td>
                            <td className=" min-w-250px">
                              {capitalize(user.role)}
                            </td>
                            <td className=" min-w-175px">
                              {user.createdAt
                                ? getDateValue(user.createdAt?.toString())
                                  ? getDateValue(user.createdAt?.toString())
                                  : DateTime.fromISO(
                                      user.createdAt?.toString()
                                    ).toLocaleString(DateTime.DATE_MED)
                                : "-"}{" "}
                              {user.createdAt &&
                                getTimeZoneValue(user.createdAt?.toString())}
                            </td>
                            <td className="text-center flex justify-center cursor-pointer main-text min-w-175px">
                              <button
                                className={
                                  "w-9 h-9 flex items-center justify-center rounded-[6px] bg-[#C2D24B1A]"
                                }
                                onClick={() => {
                                  setShowDeletePendingInvites(
                                    !showDeletePendingInvites
                                  );
                                  setSelectedInviteId(user.inviteId);
                                }}
                              >
                                <svg
                                  width="24"
                                  height="24"
                                  viewBox="0 0 24 24"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="M4 7H20M10 11V17M14 11V17M5 7L6 19C6 19.5304 6.21071 20.0391 6.58579 20.4142C6.96086 20.7893 7.46957 21 8 21H16C16.5304 21 17.0391 20.7893 17.4142 20.4142C17.7893 20.0391 18 19.5304 18 19L19 7M9 7V4C9 3.73478 9.10536 3.48043 9.29289 3.29289C9.48043 3.10536 9.73478 3 10 3H14C14.2652 3 14.5196 3.10536 14.7071 3.29289C14.8946 3.48043 15 3.73478 15 4V7"
                                    stroke="#C2D24B"
                                    strokeWidth="1.6"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                  />
                                </svg>
                              </button>
                            </td>
                          </tr>
                        </>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      <ReceivedInvites />
      <div>
        <Modal
          show={modalShow}
          onHide={() => setModalShow(false)}
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header
            closeButton
            onClick={() => setTransferOwnershipRadio(false)}
          >
            <h2 className={""}>
              {formatMessage({ id: "Transfer Ownership" })}
            </h2>
          </Modal.Header>
          <Modal.Body>
            <h6 className="pb-2 mb-4">
              {formatMessage({
                id: "Select the user whom you want transfer ownership to",
              })}
            </h6>
            <table className="table mb-0 transfer-ownership-table">
              <thead>
                <tr className="border border-2 border-top-0 border-right-0 border-left-0">
                  <td className="p-0 pt-4 m-0">
                    <p className="ps-15 transfer-ownership-td">
                      {formatMessage({ id: "Name" })}
                    </p>
                  </td>
                  <td className="p-0 pt-4 m-0">
                    <p className="transfer-ownership-td">
                      {formatMessage({ id: "Email Address" })}
                    </p>
                  </td>
                </tr>
              </thead>
              <tbody>
                {userList.map((user: any) =>
                  user.roleId.includes("owner") ? (
                    ""
                  ) : (
                    <tr className="border border-2 border-top-0 border-right-0 border-left-0">
                      <td className="p-0 m-0">
                        <div className="pt-4 m-0 form-check form-check-inline">
                          <input
                            className="form-check-input radio-button"
                            type="radio"
                            name="inlineRadioOptions"
                            id="inlineRadio1"
                            value="option1"
                            onClick={() => {
                              setUserId(user.userId);
                              setTransferOwnershipRadio(true);
                            }}
                          />
                          <p className="ms-2">
                            {user.firstName} {user.lastName}
                          </p>
                        </div>
                      </td>
                      <td className="p-0 m-0">
                        <div className="pt-4 m-0">{user.email}</div>
                      </td>
                    </tr>
                  )
                )}
              </tbody>
            </table>
          </Modal.Body>
          <Modal.Footer>
            <button
              type="button"
              className="btn !bg-[#C2D24B1A] !text-[#C2D24B]"
              onClick={() => {
                setModalShow(!modalShow);
                setTransferOwnershipRadio(false);
              }}
            >
              {formatMessage({
                id: "Cancel",
              })}
            </button>
            <button
              type="button"
              className="btn btn-primary !text-[#000000]"
              disabled={!transferOwnershipRadio}
              onClick={() => {
                setShowModal(!showModal);
                setModalShow(!modalShow);
              }}
            >
              {formatMessage({
                id: "Transfer Ownership",
              })}
            </button>
          </Modal.Footer>
        </Modal>
      </div>
      <div>
        <Modal
          show={showModal}
          onHide={() => setShowModal(false)}
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header closeButton>
            <h2>{formatMessage({ id: "Transfer Ownership" })}</h2>
          </Modal.Header>
          <Modal.Body>
            <p>
              {formatMessage({
                id: "Please note that the transfer of ownership is a permanent action and cannot be undone. As a result, you will no longer have the authority to access functionalities or design a new owner . Are you certain you wish to proceed with the transfer?",
              })}
            </p>
          </Modal.Body>
          <Modal.Footer>
            <button
              type="button"
              className="btn !bg-[#C2D24B1A] !text-[#C2D24B]"
              onClick={() => {
                setShowModal(!showModal);
                setTransferOwnershipRadio(false);
              }}
            >
              {formatMessage({ id: "Cancel" })}
            </button>
            <button
              type="button"
              className="btn btn-primary !text-[#000000]"
              onClick={() => {
                UpdateTransferOwnership();
                setShowModal(!showModal);
              }}
            >
              {formatMessage({ id: "Confirm & Transfer" })}
            </button>
          </Modal.Footer>
        </Modal>
      </div>
      <div>
        <Modal
          show={showDeleteModal}
          onHide={() => setDeleteShowModal(false)}
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header closeButton>
            <h2>{formatMessage({ id: "Delete User" })}</h2>
          </Modal.Header>
          <Modal.Body>
            <p>
              {formatMessage({
                id: "Please note that the delete user is a permanent action and cannot be undone. As a result,you will no longer have the user. Are you certain you wish to proceed with this",
              })}
            </p>
          </Modal.Body>
          <Modal.Footer>
            <BasicButton
              height="44px"
              border="none"
              color="#C2D24B1A"
              textColor="#C2D24B"
              padding="12px 16px"
              onClick={() => {
                setDeleteShowModal(!showDeleteModal);
              }}
              buttonText={formatMessage({ id: "Cancel" })}
              minWidth={56}
            />

            <BasicButton
              height="44px"
              border="1px solid #C2D24B"
              color="#C2D24B"
              textColor="#000000"
              padding="12px 24px"
              onClick={() => {
                DeleteUser();
              }}
              buttonText={formatMessage({ id: "Confirm Delete" })}
              minWidth={56}
              loading={deleteLoading}
              disabled={deleteLoading}
            />
          </Modal.Footer>
        </Modal>
      </div>
      <div>
        <Modal
          show={showDeletePendingInvites}
          onHide={() => setShowDeletePendingInvites(false)}
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header closeButton>
            <h2>{formatMessage({ id: "Delete Pending Invites" })}</h2>
          </Modal.Header>
          <Modal.Body>
            <p>
              {formatMessage({
                id: "Please note that the delete invite is a permanent action and cannot be undone. As a result, the user will no longer be able to accept the invite. Are you certain you wish to proceed with this?",
              })}
            </p>
          </Modal.Body>
          <Modal.Footer>
            <BasicButton
              height="44px"
              border="none"
              color="#C2D24B1A"
              textColor="#C2D24B"
              padding="12px 16px"
              onClick={() => {
                setShowDeletePendingInvites(false);
              }}
              buttonText={formatMessage({ id: "Cancel" })}
              minWidth={56}
            />

            <BasicButton
              height="44px"
              border="1px solid #C2D24B"
              color="#C2D24B"
              textColor="#000000"
              padding="12px 24px"
              buttonText={formatMessage({ id: "Confirm Delete" })}
              minWidth={56}
              loading={deletePendingLoading}
              disabled={deletePendingLoading}
              onClick={() => deleteInvite(selectedInviteId)}
            />
          </Modal.Footer>
        </Modal>
      </div>
    </>
  );
};
