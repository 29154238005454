import React from "react";
import Breadcrumb from "../components/breadcrumb";
import { PublishedPost } from "../components/post";
import PostContainer from "../../ai-personality/components/post";
import { useAuth } from "../../auth";

const Index = () => {
  const {selectedPersonality} = useAuth()
  return (
    <div className={"md:px-5 px-3 py-3"}>
      <Breadcrumb />
      <div
        className={
          "max-w-5xl mx-auto flex flex-col gap-y-6 h-[75vh] pr-4 overflow-y-auto custom-scroll mt-[14px] md:mt-10 mb-6"
        }
      >
        <PostContainer isPrivateFeed={true} personality={selectedPersonality} showProfileLink={true}/>
        </div>
    </div>
  );
};

export default Index;
