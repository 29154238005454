import React, { useEffect, useState } from "react";
import infoIcon from "../../../../../_metronic/assets/images/svg/info-small-icon.svg";
import dollarIcon from "../../../../../_metronic/assets/images/svg/dollar-icon.svg";
import { accessPaymentPortal, createCoinbasePortal, createPhonepePortal, createRazorpayOrder, processRazorpayOrder } from "../../core/_request";
import { CustomButton } from "../../../widgets/components/UI/CustomButton";
import { Button, Modal } from "react-bootstrap";
import { useSearchParams } from "react-router-dom";
import crossIcon from "../../../../../_metronic/assets/images/svg/cross-dual-green-icon.svg";
import Lottie from "lottie-react";
import processingJson from "../../../../assets/lotties/processing-animation.json";
import { getUserBalance, processCredits } from "../../core/_request";
import { toast } from "react-toastify";
import { useAuth } from "../../../auth";
import { useIntl } from "react-intl";
import { ToolTipUI } from "../../../widgets/components/UI/ToolTipUI";
import useGTM from "../../../../hooks/useGTM";
import { GTMEvent } from "../../../../hooks/gtm_helpers";
import pricingPlans from "../../../../../qr_pricing.json";
import clsx from "clsx";
import RazorpayModal from "./RazorpayModal";

const getPricingByCountry = (country:string) => {
  let pricing:any = {}
  pricing.card = pricingPlans.card.find((el:any) => {
      return el.countries.includes(country)
  })
  pricing.crypto = pricingPlans.crypto.find((el:any) => {
      return el.countries.includes(country)
  })

  if(!pricing.card){
      pricing.card = pricingPlans.card.slice(-1)[0]
  }
  if(!pricing.crypto){
      pricing.crypto = pricingPlans.crypto.slice(-1)[0]
  }
  return pricing
}
export default function AddCreditsModal() {
  const { formatMessage } = useIntl();
  const { dataLayerPush } = useGTM();
  const [value, setValue] = useState<number>(1); // Default value state
  const [loading, setLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [urlData, setUrlData] = useState("");
  const [processing, setProcessing] = useState<boolean>(false);
  const [queryParams, setQueryParams] = useSearchParams();
  const [open, setOpen] = useState(false);
  const { setCredits, ipStackCountry, personalityInView } = useAuth();
  const [mode, setMode] = useState<string>("card")
  const [orderId, setOrderId] = useState<string>("")
  const [razorPayOpen, setRazorPayOpen] = useState<boolean>(false)
  // Function to handle input changes
  function handleChange(event: React.ChangeEvent<HTMLInputElement>) {
    setValue(parseInt(event.target.value));
  }
  const pricing = getPricingByCountry(ipStackCountry?.country_code)
  async function getChargebeePortalLink() {
    setLoading(true);
    let {
      data: { success, data },
    } = await accessPaymentPortal(value);
    if (success) {
      if (data) {
        setUrlData(data);
        setShowModal(true);
      }
      dataLayerPush(GTMEvent.PaymentPortalOpenedSuccessful);
    } else {
      dataLayerPush(GTMEvent.PaymentPortalOpenedFailed);
      toast.error(
        "Error while connecting to payment portal. Please retry later.",
        {
          autoClose: false,
          closeButton: true, // Display close button (cross icon)
        }
      );
    }
    setLoading(false);
  }


  async function getPhonepePortalLink() {
    setLoading(true);
    let {
      data: { success, data },
    } = await createPhonepePortal(1);
    if (success) {
      if (data) {
        setUrlData(data);
        setShowModal(true);
      }
      dataLayerPush(GTMEvent.PaymentPortalOpenedSuccessful);
    } else {
      dataLayerPush(GTMEvent.PaymentPortalOpenedFailed);
      toast.error(
        "Error while connecting to payment portal. Please retry later.",
        {
          autoClose: false,
          closeButton: true, // Display close button (cross icon)
        }
      );
    }
    setLoading(false);
  }

  async function getCoinbasePortalLink() {
    setLoading(true);
    let {
      data: { success, data },
    } = await createCoinbasePortal({
      amount: value,
      description: personalityInView?.name ? `Recharge to talk with ${personalityInView?.name} of amount ${value}` : `Wallet topup`,
      name:personalityInView?.name || "Wallet topup",
      personalityId: personalityInView?.personalityId
    });
    if (success) {
      if (data) {
        setUrlData(data);
        setShowModal(true);
      }
      dataLayerPush(GTMEvent.PaymentPortalOpenedSuccessful);
    } else {
      dataLayerPush(GTMEvent.PaymentPortalOpenedFailed);
      toast.error(
        "Error while connecting to payment portal. Please retry later.",
        {
          autoClose: false,
          closeButton: true, // Display close button (cross icon)
        }
      );
    }
    setLoading(false);
  }
  async function getBalance() {
    const {
      data: { success, data },
    } = await getUserBalance();
    if (success) {
      setCredits(data.amount);
      return data;
    }
  }

  async function processUserCredit() {
    setProcessing(true);
    const {
      data: { success, data, errors },
    } = await processCredits(queryParams.get("id") || "");
    queryParams.delete("id");
    queryParams.delete("state");
    setQueryParams(queryParams);
    if (success) {
      let amountData: any = data;
      toast.success("Amount added to your account successfully");
      dataLayerPush(GTMEvent.CreditRechargeSuccessful, {
        amount: { value: amountData.amount, currency: "USD" },
      });
      setProcessing(false);
      getBalance()
    } else {
      dataLayerPush(GTMEvent.CreditRechargeFailed);
      toast.error(errors[0],
        {
          autoClose: false,
          closeButton: true, // Display close button (cross icon)
        });
    }
    setProcessing(false);
  }

  async function razorpaySuccess(obj:any) {
    setProcessing(true);
    const {
      data: { success, data, errors },
    } = await processRazorpayOrder(obj);
    if (success) {
      let amountData: any = data;
      toast.success("Amount added to your account successfully");
      dataLayerPush(GTMEvent.CreditRechargeSuccessful, {
        amount: { value: amountData.amount, currency: "USD" },
      });
      setProcessing(false);
      getBalance()
    } else {
      dataLayerPush(GTMEvent.CreditRechargeFailed);
      toast.error(errors[0],
        {
          autoClose: false,
          closeButton: true, // Display close button (cross icon)
        });
    }
    setProcessing(false);
  }

  async function razorPayOrder(){
    setLoading(true);
    const {
      data: { success, data, errors },
    } = await createRazorpayOrder(value);
    if (success) {
      setOrderId(data)
      setRazorPayOpen(true)
      setLoading(false);
    } else {
      toast.error(errors[0],
        {
          autoClose: false,
          closeButton: true, // Display close button (cross icon)
        });
    }
    setLoading(false);
  }

  function verifyAmount():boolean{
    if(value < pricing[mode].amounts[0]){
      toast.warn("Amount too low to proceed")
      return false
    }

    return true
  }

  useEffect(() => {
    if (queryParams.has("id")) {
      processUserCredit();
    }
  }, []);

  useEffect(()=>{
    let amounts = pricing[mode].amounts
    setValue(amounts[Math.floor(amounts.length/2)])
  },[mode])

  const accessor:Record<string,()=>void> = {
    'coinbase': getCoinbasePortalLink,
    'phonepe': getPhonepePortalLink,
    'chargebee': getChargebeePortalLink,
    'razorpay': razorPayOrder
  }
  


  return (
    <>
      <Button
        className={
          "px-16 main-button py-4 rounded text-[14px] leading-5 text-black font-semibold w-full md:w-auto whitespace-nowrap"
        }
        variant="primary"
        onClick={() => setOpen(true)}
      >
        {formatMessage({
          id: "Add Credits",
        })}
      </Button>
      <Modal
        backdropClassName="sub-modal-1"
        className="sub-modal-1"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={processing}
      >
        <Modal.Header>
          <Modal.Title
            className="modal-title text-[20px] leading-[28px] font-weight-600 primary-text"
            id="contained-modal-title-vcenter"
          >
            {formatMessage({
              id: "Please Wait...",
            })}
          </Modal.Title>
          <img src={crossIcon} alt=""></img>
        </Modal.Header>
        <Modal.Body>
          <div className="flex-column d-flex align-items-center">
            <Lottie
              className=""
              animationData={processingJson}
              style={{
                width: 185,
                height: 145,
                borderRadius: 25,
                // borderTopLeftRadius: 0,
                // overflow: "hidden",
              }}
            />
            <h4 className="text-[22px] mt-[20px] mb-[8px] leading-[32px] font-weight-600 primary-text">
              {formatMessage({
                id: "Processing...",
              })}
            </h4>
            <p className="text-[13px] leading-[20px] font-weight-400 secondary-text">
              {formatMessage({
                id: "We are processing your request...",
              })}
            </p>
          </div>
        </Modal.Body>
      </Modal>

      <Modal
        show={open}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdropClassName="sub-modal-1"
        className="sub-modal-1"
      >
        <Modal.Header closeButton>
          <Modal.Title
            className="modal-title text-[20px] leading-[28px] font-weight-600 primary-text"
            id="contained-modal-title-vcenter"
          >
            {formatMessage({
              id: "Add Chat Credits",
            })}
          </Modal.Title>
          <img
            src={crossIcon}
            className="cursor-pointer"
            onClick={() => setOpen(false)}
            alt=""
          ></img>
        </Modal.Header>
        <Modal.Body className=" p-[16px] md:p-[24px]">
          <div className="mb-3">
          {!pricing.crypto.blocked &&<>
            <label
                className="form-label font-weight-400 text-[13px] leading-[20px] mr-[8px] mb-3"
              >
                 Recharge with
            </label>
          <div className="flex gap-x-4 mb-5">
              <button
                className={clsx(
                  "py-4 rounded text-[14px] leading-5 !text-white px-4 whitespace-nowrap border-[transparent] border-2 border-solid !bg-[#c2d24812]",
                  mode=="card" && "!border-[#c2d24b]"
                )}
                onClick={() => setMode("card")}
              >
                {formatMessage({
                  id: "Credit Card",
                })}
              </button>
              <button
                className={clsx(
                  "py-4 rounded text-[14px] leading-5 !text-white px-4 whitespace-nowrap border-[transparent] border-2 border-solid !bg-[#0380FF]",
                  mode=="crypto" && "!border-[#c2d24b]"
                )}
                onClick={() => setMode("crypto")}
              >
                {formatMessage({
                  id: "Crypto",
                })}
              </button>
            </div>
            </>
            }
            <div className="flex-row mb-2 d-flex align-items-center">
              <label
                htmlFor="EnterCreditAmount"
                className="form-label font-weight-400 text-[13px] leading-[20px] mr-[8px] mb-0"
              >
                 Enter Amount in {pricing[mode].currency}
              </label>
              <ToolTipUI
                tooltipText={`Enter amount in ${pricing[mode].currency}`}
              />
            </div>
            <div className="position-relative">
              <input
                type="number"
                className="form-control secondary-bg text-primary border border-main h-[40px] ps-[32px]"
                id="EnterCreditAmount"
                value={value}
                onChange={handleChange}
                placeholder="Enter Amount"
              />
              <span
                className="position-absolute left-[16px] top-[11px]"
              >
                {pricing[mode].symbol}
              </span>
            </div>
          </div>
          <div className="flex-wrap gap-3 d-flex mt-[16px] pills-wrap">
           {pricing[mode].amounts.map((val:number)=> <button
              className={`pills widgets-customize shadow-sm text-[13px] leading-[20px] font-weight-500 rounded-pill  py-[4px] px-[20px] border-[transparent] border-2 border-solid ${
                value === val
                  ? "!border-[#C2D24B]   bg-[#C2D24B1A] text-[#C2D24B]"
                  : ""
              }`}
              onClick={() => setValue(val)}
            >
              {pricing[mode].symbol}{val}
            </button>)}
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div
            className={
              "flex flex-col-reverse md:flex-row gap-y-4  justify-end gap-x-2.5 w-full"
            }
          >
            <button
              className={
                "secondary-btn w-full md:w-auto !text-[14px] !leading-[20px] !font-medium !py-[12px] !px-[16px]  rounded !ml-0 !my-0"
              }
              onClick={() => setOpen(false)}
            >
              {formatMessage({
                id: "Close",
              })}
            </button>
            <CustomButton
              isValid={!!value}
              isSubmitting={loading}
              buttonText={formatMessage({
                id: "Add Credits",
              })}
              loading={loading}
              customClass={"w-full main-button flex-grow"}
              onSubmit={()=>{
                verifyAmount() && accessor[pricing[mode].gateway]()
              }}
            />
          </div>
        </Modal.Footer>
      </Modal>

      <Modal
        show={showModal}
        onHide={() => setShowModal(false)}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdropClassName="sub-modal-2"
        className="sub-modal-2"
      >
        <Modal.Body>
          <iframe
            title="Add Credits"
            src={urlData}
            width="100%"
            height={600}
          ></iframe>
        </Modal.Body>
      </Modal>
      <RazorpayModal open={razorPayOpen} options={{
        order_id: orderId,
        handler: (async(response)=> {
          console.log(response)
          const { razorpay_order_id, razorpay_payment_id, razorpay_signature } = response
          await razorpaySuccess({ razorpay_order_id, razorpay_payment_id, razorpay_signature })
          setOrderId('')
          setRazorPayOpen(false)
        }),
      }}
        modalOptions={
          {
            ondismiss: function () {
                setOpen(false)
                setRazorPayOpen(false)
            }
          }
        }
      />
    </>
  );
}
