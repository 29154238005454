import React, { useEffect, useState, useRef } from "react";
import Sider from "../components/sider";
import { InfoCard } from "../../widgets/components/UI/InfoCard";
import { useIntl } from "react-intl";
import { map } from "lodash";

import DataItem from "../components/chat-item/data-item";
import { Form, Formik } from "formik";
import TextInput from "../../widgets/components/Input/TextInput";
import clsx from "clsx";
import { useAuth } from "../../auth";
import {
  createTraining,
  deleteTraining,
  getTrainingsByType,
  updateTraining,
} from "../core/_request";
import { toast } from "react-toastify";
import FileUploader from "../components/file-upload";
import { BasicButton } from "../../widgets/components/UI/BasicButton";
import { CustomButton } from "../../widgets/components/UI/CustomButton";
import { Modal, Spinner } from "react-bootstrap";
import useInfiniteScroll from "../../../hooks/useInfiniteScroll";
import useGTM from "../../../hooks/useGTM";
import { GTMEvent } from "../../../hooks/gtm_helpers";
const Trainbydata: React.FC<any> = ({ }) => {
  const [openSider, setOpenSider] = useState(false);
  const [activeChat, setActiveChat] = useState<null | number>(null);
  const [createFormOpen, setCreateFormOpen] = useState(true);
  const [uploadFormOpen, setUploadFormOpen] = useState(false);
  const [trainings, setTrainings] = useState<any[]>([]);
  const [activeTraining, setActiveTraining] = useState<any>(null);
  const [selectedTraining, setSelectedTraining] = useState<any>(null);
  const [draftTrainings, setDraftTrainings] = useState<string[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const { formatMessage } = useIntl();
  const {dataLayerPush} = useGTM()
  const { selectedPersonality, currentUser } = useAuth();
  const [deleteModalOpen, setDeleteModalOpen] = useState<boolean>(false);
  const [files, setFiles] = useState<any[]>([]);
  const [page, setPage] = useState<number>(1);
  const [noPlan, setNoPlan] = useState<boolean>(false);
  const ref = useRef();
  const { removeListener } = useInfiniteScroll(ref, 140, () => {
    setPage((page) => page + 1);
  });

  const FILE_MB = parseInt(selectedPersonality?.entitlements?.find((obj:any)=>obj.feature_id=="allowed-file-size-for-training")?.value||"0")
  const FILE_SIZE = FILE_MB * 1024 * 1024

  async function get() {
    const {
      data: { data, success },
    } = await getTrainingsByType(
      selectedPersonality.personalityId,
      "file",
      page
    );
    if (success) {
      setTrainings((trainings) => [...trainings, ...data]);
      if (!data.length) {
        removeListener();
      }
    }
    setLoading(false);
  }

  useEffect(()=>{
    if(selectedPersonality && FILE_MB==0){
      setNoPlan(true)
    }
  },[selectedPersonality])

  useEffect(() => {
    const draftIds = trainings.reduce((acc, item) => {
      if (item.status === "draft") {
        acc.push(item.trainingId);
      }
      return acc;
    }, []);

    setDraftTrainings(draftIds);
  }, [trainings]);

  useEffect(() => {
    selectedPersonality && get();
  }, [selectedPersonality, page]);

  const isEditable =
    currentUser?.userId == activeTraining?.userId &&
    activeTraining?.status == "draft";

  const user = {
    username: currentUser?.username,
    userId: currentUser?.userId,
    firstName: currentUser?.firstName,
    lastName: currentUser?.lastName,
    photo: currentUser?.photo,
  };
  return (
    <div
      className={
        "tab-container rounded h-[calc(100vh-200px)] lg:h-[calc(100vh-220px)] mt-4"
      }
    >
      <div className={"flex h-full"}>
        <Sider
          open={openSider}
          setOpen={setOpenSider}
          onPlusClick={setCreateFormOpen}
          heading={formatMessage({ id: "Private Files for Training " })}
          ref={ref}
          draftTrainings={draftTrainings}
          setTrainings={setTrainings}
        >
          <ul>
            {map(trainings, (item, index) => (
              <li key={item.trainingId}>
                <DataItem
                  training={item}
                  setCreateFormOpen={setCreateFormOpen}
                  setActiveTraining={setActiveTraining}
                  activeTraining={activeTraining}
                  setSelectedTraining={setSelectedTraining}
                  setDeleteModalOpen={setDeleteModalOpen}
                  isOwned={item.userId == currentUser?.userId}
                />
              </li>
            ))}
          </ul>
          {loading && (
            <div className="h-[100px] w-full d-flex justify-content-center align-items-center">
              <Spinner animation="border" />
            </div>
          )}
        </Sider>
        <div
          className={
            "flex-grow  md:p-[16px_16px_24px] relative overflow-y-auto pr-2 custom-scroll h-[calc(100vh-200px)] lg:h-[calc(100vh-220px)]"
          }
        >
          <button onClick={() => setOpenSider(true)} className={"md:hidden "}>
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M5.14291 7.42859H18.8572M5.14062 12H18.8515M5.14291 16.5714H18.8515"
                stroke="#8898A6"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </button>
          {createFormOpen ? (
            noPlan ?    <InfoCard
              title={formatMessage({
                id: "Upgrade Your Plan !!!",
              })}
              desc={
                <>
                  {formatMessage({
                    id: "A plan upgrade is required to use this functionality of training by private data.",
                  })}{" "}
                </>
              }
              slug={""}
              isAnchor={""}
            />:
            <div>
              <InfoCard
                title={formatMessage({
                  id: "How to train AI personality using private or licensed data?",
                })}
                desc={
                  <>
                    {formatMessage({
                      id: "With Kamoto.AI, you have the flexibility to use private or licensed data that isn't publicly available to train your AI personality or character. To begin, prepare your files in .txt format, following the ideal question-and-answer format. For your convenience, you can download file templates for .txt/.text",
                    })}{" "}
                    <a
                      href={
                        process.env.REACT_APP_SAMPLE_TXT_FILE_FOR_TRAINING || ""
                      }
                      className="text-primary fw-bold underline"
                      target="_blank"
                      rel="noreferrer"
                      download
                    >
                      {formatMessage({
                        id: "here.",
                      })}
                    </a>{" "}
                    {formatMessage({
                      id: "Ensure that your file is under",
                    })}{" "}
                    {FILE_MB} MB {" "}
                    {formatMessage({id:"in size. Once your files are prepared, simply upload them here and submit them for training. Checkout detailed instructions to prepare the data file"})}
                    <a
                      href="https://help.kamoto.ai/en/articles/8329959-crafting-your-ai-personality-creating-the-perfect-training-data-file"
                      className="text-primary fw-bold underline"
                      target="_blank"
                      rel="noreferrer"
                    >
                      {formatMessage({
                        id: "here.",
                      })}
                    </a>{" "}
                    {formatMessage({
                      id: "Your unique and personalized AI personality will soon be crafted using your selected data.",
                    })}
                    <br></br>
                    <br></br>
                  </>
                }
                slug={""}
                isAnchor={
                  "https://help.kamoto.ai/en/articles/7945565-elevate-ai-training-with-private-data-train-your-ai-personality-with-customized-files"
                }
              />
              <Formik
                initialValues={{}}
                onSubmit={async (values, { setSubmitting }) => {
                  setSubmitting(true);
                  const items = files.map(({ id, ...rest }) => rest);
                  const {
                    data: { success, data, errors },
                  } = await createTraining(
                    selectedPersonality.personalityId,
                    "file",
                    { files: items }
                  );
                  if (success) {
                    setTrainings((trainings) => [...data, ...trainings]);
                    setFiles([]);
                    toast.success("Training added successfully");
                    // dataLayerPush(GTMEvent.TrainingFileSubmissionSuccessful,{count:items.length})
                    dataLayerPush(GTMEvent.TrainingFileCreationSuccessful)
                  } else {
                    toast.error(errors[0]?.includes("Quota") ?"Your Quota for training by private data has been exhausted" : "Training cannot be created at the moment",
                    {
                      autoClose: false,
                      closeButton: true, // Display close button (cross icon)
                    });
                    // dataLayerPush(GTMEvent.TrainingFileSubmissionFailed,{count:items.length})
                    dataLayerPush(GTMEvent.TrainingFileCreationFailed)
                  }
                  setSubmitting(false);
                }}
              >
                {(formik) => (
                  <Form>
                    <div className={clsx("mt-4")}>
                      <FileUploader
                        fileSize={FILE_SIZE + 1} 
                        maxFileNumber={3}
                        allowType={[
                          "application/pdf",
                          // "application/msword",
                          // "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
                          // "text/csv",
                          "text/plain",
                        ]}
                        resourceType="personality-trainings"
                        metaData={{ module: "document", isProtected: true }}
                        modalStatus={true}
                        handleClose={() => { }}
                        handleSuccess={() => { }}
                        isPrivate={true}
                        onComplete={(data: any) => {
                          let obj = data.results[":original"][0];
                          if (obj) {
                            let title = obj.basename;
                            let fileType = obj.ext;
                            let sslUrl = new URL(obj.ssl_url);
                            let url = sslUrl.origin + sslUrl.pathname;
                            if (url) {
                              // dataLayerPush(GTMEvent.TrainingFileCreationSuccessful)
                              setFiles((files) => [
                                ...files,
                                {
                                  id:
                                    Math.floor(Math.random() * 10000) +
                                    "_" +
                                    new Date().valueOf(),
                                  title,
                                  fileType,
                                  url,
                                },
                              ]);
                            }
                          }
                        }}
                        signatureParams={{
                          personalityId: selectedPersonality?.personalityId,
                        }}
                      />
                    </div>
                    <div className="flex gap-2 my-4">
                      {files.map((item) => (
                        <p
                          className="bg-[#C2D24B1A] rounded px-4 py-2"
                          key={item.id}
                        >
                          {item.title}{" "}
                          <span
                            className="inline-block text-[red] bold ml-2 cursor-pointer"
                            onClick={() => {
                              // dataLayerPush(GTMEvent.TrainingFileDeletionSuccessful)
                              setFiles((files) =>
                                files.filter((it) => it.id !== item.id)
                              );
                            }}
                          >
                            X
                          </span>
                        </p>
                      ))}
                    </div>
                    <div
                      className={
                        "flex md:items-center gap-x-2 md:justify-start mt-6 text-[13px] leading-5"
                      }
                    >
                      <CustomButton
                        isSubmitting={formik.isSubmitting}
                        isValid={!!files.length}
                        buttonText={formatMessage({
                          id: "Create",
                        })}
                        customClass={'main-button'}
                        loading={formik.isSubmitting}
                        height={44}
                        widthLoading={1}
                        width={1}
                        onSubmit={formik.handleSubmit}
                      />
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
          ) : (
            <Formik
              enableReinitialize
              initialValues={{ title: activeTraining?.title || "" }}
              onSubmit={async (values, { setSubmitting, setStatus }) => {
                setSubmitting(true);
                const {
                  data: { success, data },
                } = await updateTraining(activeTraining.trainingId, {
                  title: values.title.trim(),
                });
                if (success) {
                  const updated = trainings.map((item) => {
                    if (item.trainingId === activeTraining.trainingId) {
                      return {
                        ...item,
                        title: data.title,
                      };
                    } else return item;
                  });
                  setTrainings(updated);
                  setActiveTraining((active: any) => {
                    return { ...active, title: data.title };
                  });
                  setStatus(true);
                  toast.success("Training updated successfully");
                  dataLayerPush(GTMEvent.TrainingFileEditSuccessful)
                }
                setSubmitting(false);
              }}
            >
              {(formik) => (
                <form>
                  <TextInput
                    label={"Title"}
                    toolTipText={formatMessage({
                      id: "Please, Enter title of the link",
                    })}
                    isDisabled={!isEditable}
                    fieldName={"title"}
                    placeholder={formatMessage({
                      id: "Enter title here",
                    })}
                    fieldType={"text"}
                    formik={formik}
                  />
                  {/* <TextInput
                    label={"File Type"}
                    toolTipText={formatMessage({
                      id: "Please, Enter title of the Public URL",
                    })}
                    fieldName={"title"}
                    placeholder={"Excel Sheet"}
                    fieldType={"text"}
                    formik={formik}
                  /> */}
                  {isEditable && (
                    <div
                      className={
                        "flex items-center gap-x-2 justify-end text-[13px] leading-5"
                      }
                    >
                      <BasicButton
                        buttonText={formatMessage({ id: "Cancel" })}
                        border="none"
                        color="#C2D24B1A"
                        textColor="#C2D24B"
                        customClass={"px-[24px]"}
                        height="44px"
                        onClick={() => formik.resetForm()}
                      />
                      <CustomButton
                        isSubmitting={formik.isSubmitting}
                        isValid={formik.isValid && formik.dirty}
                        buttonText={"Save"}
                        loading={formik.isSubmitting}
                        height={44}
                        widthLoading={1}
                        width={1}
                        onSubmit={formik.handleSubmit}
                      />
                    </div>
                  )}
                </form>
              )}
            </Formik>
          )}
        </div>
      </div>
      <Modal
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={deleteModalOpen}
        onHide={() => setDeleteModalOpen(false)}
      >
        <Modal.Header closeButton>
          <Modal.Title
            className="modal-title text-[20px] leading-[28px] font-weight-600 primary-text"
            id="contained-modal-title-vcenter"
          >
            {formatMessage({ id: "Delete Confirmation" })}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className=" p-[16px] md:p-[24px]">
          <p className="primary-text text-[16px]">
            {formatMessage({ id: "Are you sure to delete" })}{" "}
            <span className="main-text">
              {selectedTraining?.title || "this"}
            </span>{" "}
            {formatMessage({ id: "training?" })}
          </p>
          <Formik
            initialValues={{}}
            onSubmit={async (values, { setSubmitting }) => {
              setSubmitting(true);
              const {
                data: { success, data },
              } = await deleteTraining(selectedTraining.trainingId);
              if (success) {
                setTrainings((trainings) =>
                  trainings.filter(
                    ({ trainingId }) =>
                      trainingId !== selectedTraining.trainingId
                  )
                );
                setDeleteModalOpen(false);
                setActiveTraining(selectedTraining.trainingId==trainings[0].trainingId ? null : trainings[0]);
                toast.success("Training deleted successfully");
                dataLayerPush(GTMEvent.TrainingFileDeletionSuccessful)
              } else {
                toast.error("Error while deleting the training",
                {
                  autoClose: false,
                  closeButton: true, // Display close button (cross icon)
                });
                dataLayerPush(GTMEvent.TrainingFileDeletionFailed)
              }
              setSubmitting(false);
            }}
          >
            {(formik) => (
              <div className="flex gap-x-3 justify-end items-center">
                <BasicButton
                  buttonText={formatMessage({ id: "Cancel" })}
                  border="none"
                  color="#C2D24B1A"
                  textColor="#C2D24B"
                  customClass={"px-[24px] mt-8"}
                  height="44px"
                  onClick={() => {
                    setDeleteModalOpen(false);
                  }}
                />
                <CustomButton
                  customClass="mt-8"
                  isSubmitting={formik.isSubmitting}
                  isValid={true}
                  buttonText={formatMessage({ id: "Delete" })}
                  loading={formik.isSubmitting}
                  height={44}
                  widthLoading={1}
                  width={1}
                  onSubmit={formik.handleSubmit}
                />
              </div>
            )}
          </Formik>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default Trainbydata;
