import { ReactElement } from "react"

export const BENEFIT_ICONS: Record<string, ReactElement> = {
  smile_heart: <svg
    width="30"
    height="30"
    viewBox="0 0 30 30"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_10714_33585)">
      <path
        d="M26.25 14.9995C26.2499 12.7218 25.5584 10.4977 24.267 8.62146C22.9755 6.74521 21.145 5.30517 19.0174 4.49178C16.8899 3.67839 14.5655 3.52996 12.3518 4.06613C10.1381 4.60231 8.13922 5.79782 6.61962 7.49456C5.10003 9.19131 4.13126 11.3093 3.84145 13.5686C3.55163 15.8278 3.95442 18.1218 4.99655 20.1472C6.03867 22.1725 7.67103 23.8338 9.67776 24.9114C11.6845 25.989 13.971 26.4321 16.235 26.182M11.25 12.4995H11.2625M18.75 12.4995H18.7625"
        stroke="white"
        stroke-opacity="0.5"
        stroke-width="1.6"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M11.875 18.75C12.3326 19.1897 12.8783 19.5274 13.476 19.7407C14.0737 19.954 14.7099 20.0382 15.3425 19.9875M23.7425 26.875L26.89 23.65C27.2806 23.2454 27.4993 22.7053 27.5003 22.1429C27.5012 21.5805 27.2843 21.0396 26.895 20.6337C26.7057 20.4359 26.4783 20.2783 26.2266 20.1705C25.9748 20.0627 25.7039 20.0068 25.4301 20.0062C25.1562 20.0056 24.8851 20.0604 24.6329 20.1671C24.3807 20.2739 24.1527 20.4305 23.9625 20.6275L23.7525 20.8425L23.5425 20.6275C23.3531 20.4297 23.1256 20.2723 22.8739 20.1646C22.6221 20.0568 22.3512 20.0011 22.0773 20.0006C21.8035 20.0001 21.5323 20.055 21.2802 20.1618C21.0281 20.2687 20.8001 20.4254 20.61 20.6225C20.2197 21.027 20.0013 21.5669 20.0003 22.129C19.9994 22.6911 20.2161 23.2317 20.605 23.6375L23.7425 26.875Z"
        stroke="white"
        stroke-opacity="0.5"
        stroke-width="1.6"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_10714_33585">
        <rect width="30" height="30" fill="white" />
      </clipPath>
    </defs>
  </svg>,
  autograph: <svg
    width="30"
    height="30"
    viewBox="0 0 30 30"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_10714_33652)">
      <path
        d="M3.75 21.25C7.91625 17.0838 10 13.75 10 11.25C10 7.5 8.75 7.5 7.5 7.5C6.25 7.5 4.96 8.85625 5 11.25C5.0425 13.81 7.0725 17.3463 8.125 18.75C10 21.25 11.25 21.875 12.5 20L15 16.25C15.4163 19.5838 16.6662 21.25 18.75 21.25C19.4125 21.25 22.0487 18.75 22.5 18.75C23.1463 18.75 24.3962 19.5838 26.25 21.25"
        stroke="white"
        stroke-opacity="0.5"
        stroke-width="1.6"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_10714_33652">
        <rect width="30" height="30" fill="white" />
      </clipPath>
    </defs>
  </svg>,
  calling_icon: <svg
    width="30"
    height="30"
    viewBox="0 0 30 30"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_10714_33655)">
      <path
        d="M18.75 8.75C19.413 8.75 20.0489 9.01339 20.5178 9.48223C20.9866 9.95107 21.25 10.587 21.25 11.25M18.75 3.75C20.7391 3.75 22.6468 4.54018 24.0533 5.9467C25.4598 7.35322 26.25 9.26088 26.25 11.25M6.25 5H11.25L13.75 11.25L10.625 13.125C11.9637 15.8394 14.1606 18.0363 16.875 19.375L18.75 16.25L25 18.75V23.75C25 24.413 24.7366 25.0489 24.2678 25.5178C23.7989 25.9866 23.163 26.25 22.5 26.25C17.6241 25.9537 13.0252 23.8831 9.57104 20.429C6.11688 16.9748 4.04631 12.3759 3.75 7.5C3.75 6.83696 4.01339 6.20107 4.48223 5.73223C4.95107 5.26339 5.58696 5 6.25 5Z"
        stroke="white"
        stroke-opacity="0.5"
        stroke-width="1.6"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_10714_33655">
        <rect width="30" height="30" fill="white" />
      </clipPath>
    </defs>
  </svg>,
  gift_box: <svg
    width="30"
    height="30"
    viewBox="0 0 30 30"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_10714_33658)">
      <path
        d="M15 10V26.25M3.75 11.25C3.75 10.9185 3.8817 10.6005 4.11612 10.3661C4.35054 10.1317 4.66848 10 5 10H25C25.3315 10 25.6495 10.1317 25.8839 10.3661C26.1183 10.6005 26.25 10.9185 26.25 11.25V13.75C26.25 14.0815 26.1183 14.3995 25.8839 14.6339C25.6495 14.8683 25.3315 15 25 15H5C4.66848 15 4.35054 14.8683 4.11612 14.6339C3.8817 14.3995 3.75 14.0815 3.75 13.75V11.25Z"
        stroke="white"
        stroke-opacity="0.5"
        stroke-width="1.6"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M23.75 15.0005V23.7505C23.75 24.4136 23.4866 25.0495 23.0178 25.5183C22.5489 25.9872 21.913 26.2505 21.25 26.2505H8.75C8.08696 26.2505 7.45107 25.9872 6.98223 25.5183C6.51339 25.0495 6.25 24.4136 6.25 23.7505V15.0005M9.375 10.0005C8.5462 10.0005 7.75134 9.67131 7.16529 9.08526C6.57924 8.4992 6.25 7.70435 6.25 6.87555C6.25 6.04675 6.57924 5.25189 7.16529 4.66584C7.75134 4.07979 8.5462 3.75055 9.375 3.75055C10.5809 3.72954 11.7625 4.31462 12.7659 5.4295C13.7693 6.54437 14.5478 8.1373 15 10.0005C15.4522 8.1373 16.2307 6.54437 17.2341 5.4295C18.2375 4.31462 19.4191 3.72954 20.625 3.75055C21.4538 3.75055 22.2487 4.07979 22.8347 4.66584C23.4208 5.25189 23.75 6.04675 23.75 6.87555C23.75 7.70435 23.4208 8.4992 22.8347 9.08526C22.2487 9.67131 21.4538 10.0005 20.625 10.0005"
        stroke="white"
        stroke-opacity="0.5"
        stroke-width="1.6"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_10714_33658">
        <rect width="30" height="30" fill="white" />
      </clipPath>
    </defs>
  </svg>,
  discount: <svg
    width="30"
    height="30"
    viewBox="0 0 30 30"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_10714_33663)">
      <path
        d="M11.25 18.75L18.75 11.25"
        stroke="white"
        strokeOpacity="0.5"
        strokeWidth="1.6"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.875 12.5C12.2202 12.5 12.5 12.2202 12.5 11.875C12.5 11.5298 12.2202 11.25 11.875 11.25C11.5298 11.25 11.25 11.5298 11.25 11.875C11.25 12.2202 11.5298 12.5 11.875 12.5Z"
        fill="white"
        fill-opacity="0.5"
        stroke="white"
        stroke-opacity="0.5"
        stroke-width="1.6"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M18.125 18.75C18.4702 18.75 18.75 18.4702 18.75 18.125C18.75 17.7798 18.4702 17.5 18.125 17.5C17.7798 17.5 17.5 17.7798 17.5 18.125C17.5 18.4702 17.7798 18.75 18.125 18.75Z"
        fill="white"
        fill-opacity="0.5"
        stroke="white"
        stroke-opacity="0.5"
        stroke-width="1.6"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M6.25009 9.00155C6.25009 8.27221 6.53982 7.57273 7.05554 7.05701C7.57127 6.54128 8.27074 6.25155 9.00009 6.25155H10.2501C10.9762 6.25114 11.6727 5.96356 12.1876 5.45155L13.0626 4.57655C13.3181 4.31955 13.622 4.11561 13.9566 3.97644C14.2913 3.83727 14.6502 3.76563 15.0126 3.76562C15.375 3.76563 15.7339 3.83727 16.0685 3.97644C16.4032 4.11561 16.707 4.31955 16.9626 4.57655L17.8376 5.45155C18.3525 5.96356 19.049 6.25114 19.7751 6.25155H21.0251C21.7544 6.25155 22.4539 6.54128 22.9696 7.05701C23.4854 7.57273 23.7751 8.27221 23.7751 9.00155V10.2516C23.7755 10.9777 24.0631 11.6742 24.5751 12.1891L25.4501 13.0641C25.7071 13.3196 25.911 13.6235 26.0502 13.9581C26.1894 14.2928 26.261 14.6516 26.261 15.0141C26.261 15.3765 26.1894 15.7353 26.0502 16.07C25.911 16.4047 25.7071 16.7085 25.4501 16.9641L24.5751 17.8391C24.0631 18.3539 23.7755 19.0504 23.7751 19.7766V21.0266C23.7751 21.7559 23.4854 22.4554 22.9696 22.9711C22.4539 23.4868 21.7544 23.7766 21.0251 23.7766H19.7751C19.049 23.777 18.3525 24.0646 17.8376 24.5766L16.9626 25.4516C16.707 25.7086 16.4032 25.9125 16.0685 26.0517C15.7339 26.1908 15.375 26.2625 15.0126 26.2625C14.6502 26.2625 14.2913 26.1908 13.9566 26.0517C13.622 25.9125 13.3181 25.7086 13.0626 25.4516L12.1876 24.5766C11.6727 24.0646 10.9762 23.777 10.2501 23.7766H9.00009C8.27074 23.7766 7.57127 23.4868 7.05554 22.9711C6.53982 22.4554 6.25009 21.7559 6.25009 21.0266V19.7766C6.24967 19.0504 5.96209 18.3539 5.45009 17.8391L4.57509 16.9641C4.31809 16.7085 4.11414 16.4047 3.97497 16.07C3.8358 15.7353 3.76416 15.3765 3.76416 15.0141C3.76416 14.6516 3.8358 14.2928 3.97497 13.9581C4.11414 13.6235 4.31809 13.3196 4.57509 13.0641L5.45009 12.1891C5.96209 11.6742 6.24967 10.9777 6.25009 10.2516V9.00155Z"
        stroke="white"
        stroke-opacity="0.5"
        stroke-width="1.6"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_10714_33663">
        <rect width="30" height="30" fill="white" />
      </clipPath>
    </defs>
  </svg>
}

export function getBenefitIcon(icon: string) {
  return BENEFIT_ICONS[icon] || BENEFIT_ICONS['discount']
}