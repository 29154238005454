import React from "react";
import { useAuth } from "../../../auth";
import { useIntl } from "react-intl";

const Matching: React.FC<any> = ({
  setStartChat,
  personalityImage,
  userImage,
}) => {
  const { personalityInView } = useAuth();
  const { formatMessage } = useIntl();
  return (
    <div
      className={
        "p-6 flex flex-grow w-full justify-center  items-center  rounded py-20"
      }
    >
      <div className={"text-center"}>
        <h2
          className={
            "text-[14px] md:text-[24px] leading-[24px] md:leading-[32px] primary-text font-semibold mb-[4px]"
          }
        >
          {formatMessage({ id: "It’s Match!" })}
        </h2>
        <div className={"flex justify-center"}>
          <div className={"relative h-[70px] md:h-[120px] w-72 mb-[28px]"}>
            <div
              className={
                "absolute top-0 right-32  secondary-bg shadow-default rounded-full p-1.5"
              }
            >
              <img
                src={personalityImage}
                className={
                  "rounded-full object-cover object-top h-[60px] md:h-[116px] w-[60px] md:w-[116px]"
                }
                alt={"profile"}
              />
            </div>
            <div
              className={
                "w-10 h-10 flex items-center justify-center rounded-full secondary-bg shadow-default absolute bottom-0 left-1/2 -translate-x-1/2 z-10"
              }
            >
              <svg
                width="28"
                height="28"
                viewBox="0 0 28 28"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g clipPath="url(#clip0_497_19547)">
                  <path
                    d="M9.33333 10.4993H18.6667M9.33333 15.166H16.3333M21 4.66602C21.9283 4.66602 22.8185 5.03476 23.4749 5.69114C24.1313 6.34752 24.5 7.23776 24.5 8.16602V17.4993C24.5 18.4276 24.1313 19.3178 23.4749 19.9742C22.8185 20.6306 21.9283 20.9994 21 20.9994H15.1667L9.33333 24.4994V20.9994H7C6.07174 20.9994 5.1815 20.6306 4.52513 19.9742C3.86875 19.3178 3.5 18.4276 3.5 17.4993V8.16602C3.5 7.23776 3.86875 6.34752 4.52513 5.69114C5.1815 5.03476 6.07174 4.66602 7 4.66602H21Z"
                    stroke="#C2D24B"
                    strokeWidth="1.8"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_497_19547">
                    <rect width="28" height="28" fill="white" />
                  </clipPath>
                </defs>
              </svg>
            </div>
            <div
              className={
                "absolute top-0  left-32 secondary-bg shadow-default rounded-full p-1.5"
              }
            >
              <img
                src={userImage}
                className={
                  "rounded-full h-[60px] md:h-[116px] w-[60px] md:w-[116px]"
                }
                alt={"profile"}
              />
            </div>
          </div>
        </div>
        <h2
          className={
            "text-[14px] md:text-[24px] leading-[24px] md:leading-[32px] primary-text font-semibold mb-[8px]"
          }
        >
          {formatMessage({ id: "Premium Conversations Await You" })}
        </h2>
        <p className={"max-w-2xl mb-[20px] secondary-text"}>
          {formatMessage({
            id: `Chat in an exclusive realm with the Verified `,
          })}
          {personalityInView?.name}{"'s "}
          {formatMessage({
            id: `Chatbot.
          Experience the celebrity charm through engaging dialogues and
          interactive conversations.`,
          })}
        </p>
        <div className={""}>
          <button
            // onClick={() => setOpenSubscribeModal(true)}
            className={
              "export-button w-full max-w-md py-[16px] text-[13px] md:text-[14px] leading-5 font-semibold rounded"
            }
            onClick={() => setStartChat(true)}
          >
            {formatMessage({ id: "Unleash Your Curiosity. Chat Now!" })}
          </button>
        </div>
      </div>
    </div>
  );
};

export default Matching;
