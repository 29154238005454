import React, { useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import Modal, { ModalProps } from "react-bootstrap/Modal";
import { Omit, BsPrefixProps } from "react-bootstrap/esm/helpers";
import crossIcon from "../../../../../../_metronic/assets/images/svg/cross-dual-green-icon.svg";
import StarRatings from "react-star-ratings";
import Form from "react-bootstrap/Form";
import infoIcon from "../../../../../../_metronic/assets/images/svg/info-small-icon.svg";
import { CustomButton } from "../../../../widgets/components/UI/CustomButton";
import { addFeedback } from "../core/_request";
import { useAuth } from "../../../../auth";
import { toast } from "react-toastify";
import { useIntl } from "react-intl";
import useGTM from "../../../../../hooks/useGTM";
import { GTMEvent } from "../../../../../hooks/gtm_helpers";

export default function AiProfileFeedbackModal(
  props: JSX.IntrinsicAttributes &
    Omit<
      Pick<
        React.DetailedHTMLProps<
          React.HTMLAttributes<HTMLDivElement>,
          HTMLDivElement
        >,
        "key" | keyof React.HTMLAttributes<HTMLDivElement>
      > & {
        ref?:
          | ((instance: HTMLDivElement | null) => void)
          | React.RefObject<HTMLDivElement>
          | null
          | undefined;
      },
      BsPrefixProps<"div"> & ModalProps
    > &
    BsPrefixProps<"div"> &
    ModalProps & { children?: React.ReactNode }
) {
  const { formatMessage } = useIntl();
  const {dataLayerPush} = useGTM()
  const [rating, setRating] = useState<number>(0);
  const [loading, setLoading] = useState<boolean>(false);
  const [text, setText] = useState<string>("");
  const { show, discard } = props;
  const [session, setSession] = useState(null);
  const { personalityInView, prevChatSession, currentChatSession } = useAuth();

  useEffect(() => {
    if (!currentChatSession && prevChatSession.current) {
      setSession(prevChatSession.current.id);
    }
  }, [currentChatSession, prevChatSession]);
  async function handleSubmit() {
    if (!personalityInView || !session) {
      return;
    }
    setLoading(true);
    const {
      data: { success, data, errors },
    } = await addFeedback(
      personalityInView.personalityId,
      session,
      rating,
      text
    );
    if (success) {
      discard();
      toast.success("Feedback given successfully");
      dataLayerPush(GTMEvent.FeedbackSubmissionSuccessful)
    } else {
      toast.error("Failed to give feedback",
      {
        autoClose: false,
        closeButton: true, // Display close button (cross icon)
      });
      dataLayerPush(GTMEvent.FeedbackSubmissionFailed)
    }
    setLoading(false);
  }
  return (
    <Modal
      show={show}
      onHide={discard}
      className="feedback-modal-ai-profile"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      style={{zIndex: 9999}}
    >
      <Modal.Header closeButton>
        <Modal.Title
          id="contained-modal-title-vcenter"
          className="flex-row d-flex align-item-center primary-text text-[20px] leading-[28px] font-weight-600"
        >
          {formatMessage({
            id: "Feedback",
          })}
          <img src={infoIcon} className="ms-2" alt=""></img>
        </Modal.Title>
        <img src={crossIcon} alt="" onClick={discard}></img>
      </Modal.Header>
      <Modal.Body className="pt-[50px] ">
        <h4 className="primary-text text-center text-[24px] leading-[32px] font-weight-600">
          {formatMessage({
            id: "Rate Your Experience!",
          })}
        </h4>
        <p className="secondary-text text-center mt-[12px] mb-[20px] text-[14px] leading-[24px] font-weight-400">
          {formatMessage({
            id: "Enjoyed your chat? Rate out of 5 stars and share your thoughts to help us improve.",
          })}
        </p>
        <div className="text-center">
          <StarRatings
            starRatedColor="#C2D24B"
            starEmptyColor={"#4F516D"}
            starHoverColor="#C2D24B"
            starSpacing={"2"}
            numberOfStars={5}
            rating={rating}
            starDimension={"52px"}
            name="rating"
            svgIconPath={
              "M5.49518 4.89328L1.24184 5.50995L1.16651 5.52528C1.05247 5.55556 0.948507 5.61555 0.865239 5.69915C0.78197 5.78274 0.72238 5.88694 0.692552 6.0011C0.662724 6.11526 0.663729 6.23529 0.695462 6.34893C0.727196 6.46257 0.788522 6.56576 0.873177 6.64795L3.95451 9.64728L3.22784 13.8839L3.21918 13.9573C3.2122 14.0752 3.23669 14.1929 3.29014 14.2983C3.3436 14.4037 3.4241 14.4929 3.5234 14.557C3.6227 14.621 3.73724 14.6575 3.85528 14.6627C3.97332 14.6679 4.09062 14.6416 4.19518 14.5866L7.99918 12.5866L11.7945 14.5866L11.8612 14.6173C11.9712 14.6606 12.0908 14.6739 12.2077 14.6558C12.3246 14.6377 12.4345 14.5888 12.5262 14.5141C12.618 14.4395 12.6882 14.3418 12.7297 14.231C12.7713 14.1203 12.7826 14.0005 12.7625 13.8839L12.0352 9.64728L15.1178 6.64728L15.1698 6.59062C15.2441 6.49913 15.2928 6.38958 15.311 6.27315C15.3292 6.15671 15.3161 6.03753 15.2732 5.92777C15.2303 5.818 15.1591 5.72157 15.0668 5.64829C14.9745 5.57501 14.8645 5.52751 14.7478 5.51062L10.4945 4.89328L8.59318 1.03995C8.53816 0.928305 8.45299 0.834292 8.3473 0.768551C8.24162 0.702811 8.11964 0.667969 7.99518 0.667969C7.87071 0.667969 7.74874 0.702811 7.64305 0.768551C7.53736 0.834292 7.45219 0.928305 7.39718 1.03995L5.49518 4.89328Z"
            }
            svgIconViewBox={"0 0 16 16"}
            changeRating={(value) => setRating(value)}
          />
        </div>

        <Form>
          <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
            <Form.Label className="flex-row d-flex align-item-center mt-[20px]">
              {formatMessage({
                id: "Feedback",
              })}
              <img src={infoIcon} className="ms-2" alt=""></img>{" "}
            </Form.Label>
            <Form.Control
              as="textarea"
              rows={3}
              placeholder={formatMessage({
                id: "Enter feedback here",
              })}
              className="secondary-bg"
              maxLength={500}
              onChange={({ target: { value } }) =>
                value.length > 500
                  ? setText(value.slice(0, 500))
                  : setText(value)
              }
            />
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <CustomButton
          isSubmitting={loading}
          isValid={!!rating || !!text.trim()}
          buttonText={formatMessage({
            id: "Submit",
          })}
          loading={loading}
          customClass={"w-full m-0 "}
          onSubmit={handleSubmit}
        />
      </Modal.Footer>
    </Modal>
  );
}
