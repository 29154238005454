import React, { useEffect, useRef, useState } from 'react'
import NavSearch from './NavSearch';

export default function HeaderSearchMob() {
    const [moreOpen, setMoreOpen] = useState<boolean>(false);
    const menuRef = useRef<HTMLDivElement>(null);
     // Function to handle clicks outside the MoreMenu
  const handleOutsideClick = (event: MouseEvent) => {
    if (menuRef.current && !menuRef.current.contains(event.target as Node)) {
      setMoreOpen(false);
    }
  };

  // Add event listener when component mounts
  useEffect(() => {
    document.addEventListener("mousedown", handleOutsideClick);
    return () => {
      // Clean up the event listener when component unmounts
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, []);
  return (
    
    <div className="mr-[16px] sm:hidden bl relative">
            <div className=""  onClick={() => setMoreOpen((open) => !open)}>
              <div className="main-border border px-[6px] py-[4px] rounded-[4px]">
              <svg
            className="w-[20px] h-[20px] "
            width="24"
            height="25"
            viewBox="0 0 24 25"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g clipPath="url(#clip0_344_20872)">
              <path
                opacity="0.3"
                d="M14.2929 17.6993C13.9024 17.3088 13.9024 16.6756 14.2929 16.2851C14.6834 15.8946 15.3166 15.8946 15.7071 16.2851L19.7071 20.2851C20.0976 20.6756 20.0976 21.3088 19.7071 21.6993C19.3166 22.0898 18.6834 22.0898 18.2929 21.6993L14.2929 17.6993Z"
                fill="white"
                fillOpacity="0.65"
              />
              <path
                d="M11 16.9922C13.7614 16.9922 16 14.7536 16 11.9922C16 9.23076 13.7614 6.99219 11 6.99219C8.23858 6.99219 6 9.23076 6 11.9922C6 14.7536 8.23858 16.9922 11 16.9922ZM11 18.9922C7.13401 18.9922 4 15.8582 4 11.9922C4 8.12619 7.13401 4.99219 11 4.99219C14.866 4.99219 18 8.12619 18 11.9922C18 15.8582 14.866 18.9922 11 18.9922Z"
                fill="white"
                fillOpacity="0.65"
              />
            </g>
            <defs>
              <clipPath id="clip0_344_20872">
                <rect
                  width="24"
                  height="24"
                  fill="white"
                  transform="translate(0 0.992188)"
                />
              </clipPath>
            </defs>
              </svg>

              </div>
            </div>
            {moreOpen && (
        <div ref={menuRef} className="z-1 position-absolute top-[40px] w-[200px]"
        style={{ zIndex: 1 }}>
            <NavSearch/>
        </div>
         )}
    </div> 
    
  )
}
