import React, { useEffect, useRef, useState } from "react";
import NavSearchModal from "../../../partials/layout/header-menus/NavSearchModal";
import { useIntl } from "react-intl";
import request from "../../../../app/core/_apis";
import clsx from 'clsx'
import useGTM from "../../../../app/hooks/useGTM";
import { GTMEvent } from "../../../../app/hooks/gtm_helpers";
export default function NavSearch() {
  const [moreOpen, setMoreOpen] = useState<boolean>(false);
  const menuRef = useRef<HTMLDivElement>(null);
  const { formatMessage } = useIntl();
  const {dataLayerPush} = useGTM()
  const [text, setText] = useState<string | undefined>()
  const [searchText, setSearchText] = useState<string | undefined>()
  const [personalities, setPersonalities] = useState<any>([])
  const [apiLoading, setApiLoading] = useState(true)

  useEffect(() => {
    const interval = setTimeout(() => {
      setSearchText(text != '' ? text : undefined)
    }, 500);
    return () => clearTimeout(interval)
  }, [text])

  const handleSearch = (e: any) => {
    setText(e.target.value)
  }

  useEffect(() => {
    setApiLoading(true)
    let url:any
    if (searchText) {
      url = `/personality?searchText=${searchText}&perPage=5`
    }else{
      url = `/personality?page=1&perPage=5`
    }
    request.get(url).then((resp) => {
      if (resp.data.success === true) {
        setPersonalities(resp?.data?.data?.rows ?? [])
      }
      if(url != '/personality?page=1&perPage=5'){
        dataLayerPush(GTMEvent.HeaderSearchBoxSearchSuccessful)
      }
    }).catch((error) => {
      console.log(error)
      if(url != '/personality?page=1&perPage=5'){
        dataLayerPush(GTMEvent.HeaderSearchBoxSearchFailed)
      }
    }).finally(() => setApiLoading(false))
  }, [searchText])

  // Function to handle clicks outside the MoreMenu
  const handleOutsideClick = (event: MouseEvent) => {
    if (menuRef.current && !menuRef.current.contains(event.target as Node)) {
      setMoreOpen(false);
    }
  };

  // Add event listener when component mounts
  useEffect(() => {
    document.addEventListener("mousedown", handleOutsideClick);
    return () => {
      // Clean up the event listener when component unmounts
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, []);
  return (
    <div className={clsx("relative menu menu-sub menu-sub-dropdown show bg-transparent")}>
      <div
        className="cursor-pointer"
        // data-kt-menu-trigger="click"
        // data-kt-menu-placement="bottom-end"
        // data-kt-menu-flip="top-end"
        onClick={() => setMoreOpen((open) => !open)}
      >
        <div className="relative">
          <svg
            className="absolute top-1/2 left-2 -translate-y-1/2"
            width="24"
            height="25"
            viewBox="0 0 24 25"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g clipPath="url(#clip0_344_20872)">
              <path
                opacity="0.3"
                d="M14.2929 17.6993C13.9024 17.3088 13.9024 16.6756 14.2929 16.2851C14.6834 15.8946 15.3166 15.8946 15.7071 16.2851L19.7071 20.2851C20.0976 20.6756 20.0976 21.3088 19.7071 21.6993C19.3166 22.0898 18.6834 22.0898 18.2929 21.6993L14.2929 17.6993Z"
                className={'icon-main-color'}

                fillOpacity="0.65"
              />
              <path
                d="M11 16.9922C13.7614 16.9922 16 14.7536 16 11.9922C16 9.23076 13.7614 6.99219 11 6.99219C8.23858 6.99219 6 9.23076 6 11.9922C6 14.7536 8.23858 16.9922 11 16.9922ZM11 18.9922C7.13401 18.9922 4 15.8582 4 11.9922C4 8.12619 7.13401 4.99219 11 4.99219C14.866 4.99219 18 8.12619 18 11.9922C18 15.8582 14.866 18.9922 11 18.9922Z"
                className={'icon-main-color'}
                fillOpacity="0.65"
              />
            </g>
            <defs>
              <clipPath id="clip0_344_20872">
                <rect
                  width="24"
                  height="24"
                  fill="white"
                  transform="translate(0 0.992188)"
                />
              </clipPath>
            </defs>
          </svg>
          <input
            onChange={handleSearch}
            className="search-input-table form-control border border-main h-[40px] font-size-12 primary-text !bg-[#171825] sm:max-w-[160px] md:max-w-[200px] xl:min-w-[200px] w-full pl-10 rounded-[8px]"
            placeholder={formatMessage({ id: "Search Personality" })}
          />
        </div>
      </div>
      {moreOpen && (
        <div
          ref={menuRef}
          className="z-1 position-absolute top-[50px] "
          style={{ zIndex: 1 }}
        >
          <NavSearchModal apiLoading={apiLoading} personalities={personalities} />
        </div>
      )}
    </div>
  );
}
