// import { formatMessage } from '@formatjs/intl'
import React from 'react'
import { useIntl } from 'react-intl'

export default function UserInboxNoChat() {
    const {formatMessage} = useIntl()

    return (
        <div className='primary-bg h-full flex items-center justify-center rounded-[8px] flex-grow'>
            <div className='text-center max-w-[285px]'>
                <svg className='mx-auto' width="61" height="60" viewBox="0 0 61 60" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <rect x="4.50354" y="3.50354" width="52.6239" height="52.6239" rx="10.5248" stroke="#C2D24B" stroke-width="3.00708" />
                    <path d="M23.6999 11.0983C21.4348 11.5322 19.6109 12.9442 18.5961 15.0623C18.2136 15.8712 18.0151 16.6729 17.9636 17.6583L17.9195 18.4746L17.287 18.6364C15.7058 19.0262 14.1394 20.1073 13.2422 21.4311C12.8156 22.0635 12.3817 22.9828 12.1905 23.6815C11.9625 24.5272 11.9625 26.2922 12.1905 27.1232C12.3817 27.8219 12.8156 28.7706 13.2275 29.3736L13.5363 29.8223L13.2348 30.2635C12.8083 30.8886 12.3817 31.8079 12.1905 32.5066C11.9625 33.3523 11.9625 35.1173 12.1905 35.9631C12.8671 38.4414 14.8528 40.4124 17.287 41.0081L17.9195 41.1699L17.9636 41.9862C18.1033 44.6411 19.6036 46.8621 22.0305 48.002C23.0674 48.4873 23.619 48.5977 24.9281 48.5977C25.8621 48.5977 26.1709 48.5682 26.6563 48.4432C27.3991 48.2447 28.5684 47.6637 29.2156 47.1783L29.7083 46.7959V29.8223V12.8486L29.2156 12.4662C28.5611 11.9735 27.3917 11.3999 26.6563 11.2086C25.8988 11.0101 24.4353 10.9586 23.6999 11.0983Z" fill="#C2D24B" />
                    <path d="M35.4671 11.1045C34.2684 11.3325 33.1579 11.862 32.1945 12.6489L31.915 12.8769V17.1203V21.3711H33.1138H34.3052L34.489 20.9813C34.7244 20.4812 35.4451 19.7678 35.9746 19.5104C37.9676 18.5544 40.3062 19.7678 40.6813 21.952C40.9828 23.7171 39.7547 25.4159 37.9529 25.7248C36.5997 25.9528 35.173 25.2615 34.5552 24.0554L34.3052 23.5773H33.1138H31.915V26.1513V28.7253H34.5846H37.2469L37.4307 28.3355C37.6661 27.8354 38.3868 27.1221 38.9163 26.8647C40.5416 26.0851 42.4978 26.7397 43.3068 28.3429C44.3143 30.3359 43.1008 32.6966 40.8872 33.079C39.5414 33.307 38.1147 32.6083 37.4969 31.4096L37.2469 30.9316H34.5846H31.915V33.5056V36.0796H33.1138H34.3052L34.489 35.6898C34.7244 35.1897 35.4451 34.4763 35.9746 34.2189C37.9676 33.2629 40.3062 34.4763 40.6813 36.6605C40.9828 38.4256 39.7547 40.1244 37.9529 40.4333C36.5997 40.6613 35.173 39.97 34.5552 38.7639L34.3052 38.2858H33.1138H31.915V42.5292V46.78L32.1945 47.008C32.9741 47.6478 34.0184 48.192 34.967 48.4421C35.8422 48.6847 37.5116 48.6847 38.3868 48.4494C40.4974 47.8758 42.1154 46.5226 43.0273 44.5811C43.4171 43.75 43.6083 42.9558 43.6598 41.9924L43.7039 41.1761L44.3364 41.0143C46.7706 40.4186 48.7563 38.4476 49.4255 35.9692C49.6682 35.0941 49.6682 33.4247 49.4328 32.5495C49.2269 31.7994 48.8151 30.8948 48.3959 30.2771L48.087 29.8284L48.3959 29.3798C48.8151 28.7621 49.2269 27.8575 49.4328 27.1074C49.6682 26.2322 49.6682 24.5628 49.4255 23.6876C48.7563 21.2093 46.7706 19.2383 44.3364 18.6426L43.7039 18.4808L43.6598 17.6645C43.5642 15.8995 42.8655 14.311 41.63 13.0607C40.7916 12.215 39.5855 11.5237 38.4235 11.2148C37.6661 11.0163 36.2099 10.9648 35.4671 11.1045Z" fill="#C2D24B" />
                    <path d="M36.9027 21.5224C36.5865 21.6842 36.3291 22.1181 36.3291 22.4711C36.3291 22.8388 36.5865 23.258 36.9174 23.4271C37.0719 23.508 37.3072 23.5742 37.4322 23.5742C37.5573 23.5742 37.7926 23.508 37.947 23.4271C38.278 23.258 38.5354 22.8388 38.5354 22.4711C38.5354 22.1034 38.278 21.6842 37.947 21.515C37.5867 21.3312 37.2557 21.3312 36.9027 21.5224Z" fill="#C2D24B" />
                    <path d="M39.8402 28.8818C39.524 29.0436 39.2666 29.4775 39.2666 29.8305C39.2666 30.1982 39.524 30.6174 39.8549 30.7865C40.0094 30.8674 40.2447 30.9336 40.3697 30.9336C40.7375 30.9336 41.1566 30.6762 41.3258 30.3453C41.4067 30.1908 41.4729 29.9555 41.4729 29.8305C41.4729 29.4627 41.2155 29.0436 40.8845 28.8744C40.5242 28.6905 40.1932 28.6905 39.8402 28.8818Z" fill="#C2D24B" />
                    <path d="M36.9027 36.2294C36.5865 36.3912 36.3291 36.8251 36.3291 37.1781C36.3291 37.3031 36.3953 37.5385 36.4762 37.6929C36.6453 38.0239 37.0645 38.2812 37.4322 38.2812C37.9912 38.2812 38.5354 37.737 38.5354 37.1781C38.5354 36.8104 38.278 36.3912 37.947 36.2221C37.5867 36.0382 37.2557 36.0382 36.9027 36.2294Z" fill="#C2D24B" />
                </svg>
                <p className='mt-[14px] mb-[8px] text-[14px] font-[600] text-[#FFFFFFCC]'>{formatMessage({id:"Continue your conversation."})}</p>
                <p className='mt-[14px] mb-[8px] text-[13px] leading-[18px] text-[#FFFFFFA6]'>{formatMessage({id:"To continue a conversation, simply select message thread from the list on the left."})}</p>
            </div>

        </div>
    )
}
