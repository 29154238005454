/* eslint-disable react-hooks/exhaustive-deps */
import clsx from "clsx";
import { Link } from "react-router-dom";
import { KTSVG, toAbsoluteUrl } from "../../../helpers";
import { useLayout } from "../../core";
import { Navbar } from "./Navbar";

export function HeaderWrapper() {
  const { config, classes } = useLayout();
  if (!config.app?.header?.display) {
    return null;
  }

  return (
    <div id="kt_app_header" className="app-header app-header-bg">
      <div
        id="kt_app_header_container"
        className={clsx(
          "app-container flex-lg-grow-1 w-full",
          classes.headerContainer.join(" "),
          config.app?.header?.default?.containerClass
        )}
      >
        {config.app.sidebar?.display && (
          <>
            <div
              className="d-flex  align-items-center d-lg-none ms-n2 me-2"
              title="Show sidebar menu"
            >
              <div
                className="btn btn-icon btn-active-color-primary w-35px h-35px mr-[8px] sm:mr-[12px]"
                id="kt_app_sidebar_mobile_toggle"
              >
                <KTSVG
                  path="/media/icons/duotune/abstract/abs015.svg"
                  className=" svg-icon-1"
                />
              </div>
              <div className="d-flex align-items-center flex-grow-1 flex-lg-grow-0">
                <Link to="/dashboard" className="d-lg-none">
                  {/* <img
                    alt="Logo"
                    src={toAbsoluteUrl("/media/auth/logo-mini.png")}
                    className="h-25px"
                  /> */}
                  <svg
                    width="32"
                    height="32"
                    viewBox="0 0 32 32"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g clipPath="url(#clip0_5237_17083)">
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M25.1797 1.72973H7.32021C4.50206 1.72973 2.2175 4.05301 2.2175 6.91892V25.0811C2.2175 27.947 4.50206 30.2703 7.32021 30.2703H25.1797C27.9978 30.2703 30.2824 27.947 30.2824 25.0811V6.91892C30.2824 4.05301 27.9978 1.72973 25.1797 1.72973ZM7.32021 0C3.56268 0 0.516602 3.09771 0.516602 6.91892V25.0811C0.516602 28.9023 3.56268 32 7.32021 32H25.1797C28.9372 32 31.9833 28.9023 31.9833 25.0811V6.91892C31.9833 3.09771 28.9372 0 25.1797 0H7.32021Z"
                        fill="#C2D24B"
                      />
                      <path
                        d="M12.4186 5.1621C11.1506 5.40912 10.1295 6.21301 9.56136 7.41883C9.34728 7.87939 9.23611 8.33576 9.20729 8.89681L9.18259 9.36155L8.82852 9.45366C7.94334 9.67557 7.0664 10.291 6.56411 11.0447C6.32532 11.4048 6.08241 11.9281 5.97536 12.3259C5.84773 12.8074 5.84773 13.8122 5.97536 14.2853C6.08241 14.6831 6.32532 15.2232 6.55587 15.5665L6.72879 15.8219L6.55999 16.0731C6.3212 16.429 6.08241 16.9524 5.97536 17.3501C5.84773 17.8316 5.84773 18.8365 5.97536 19.318C6.35414 20.729 7.46576 21.851 8.82852 22.1902L9.18259 22.2823L9.20729 22.747C9.28552 24.2585 10.1254 25.523 11.4841 26.1719C12.0646 26.4483 12.3734 26.5111 13.1062 26.5111C13.6291 26.5111 13.802 26.4943 14.0737 26.4231C14.4895 26.3101 15.1442 25.9793 15.5065 25.703L15.7823 25.4853V6.15858L15.5065 5.94086C15.14 5.66034 14.4854 5.33376 14.0737 5.2249C13.6497 5.11186 12.8304 5.08255 12.4186 5.1621Z"
                        fill="#C2D24B"
                      />
                      <path
                        d="M19.0055 5.16276C18.3345 5.29255 17.7128 5.59401 17.1734 6.04201L17.017 6.1718V11.0077H18.355L18.458 10.7858C18.5897 10.501 18.9932 10.0949 19.2896 9.94838C20.4054 9.40408 21.7146 10.0949 21.9246 11.3384C22.0934 12.3433 21.4058 13.3105 20.3971 13.4863C19.6396 13.6161 18.8409 13.2225 18.495 12.5359L18.355 12.2637H17.017V15.1946H20.0019L20.1048 14.9726C20.2366 14.6879 20.64 14.2818 20.9365 14.1353C21.8463 13.6915 22.9415 14.0641 23.3944 14.9768C23.9584 16.1115 23.2791 17.4555 22.0399 17.6732C21.2864 17.803 20.4877 17.4052 20.1419 16.7228L20.0019 16.4506H17.017V19.3814H18.355L18.458 19.1595C18.5897 18.8748 18.9932 18.4687 19.2896 18.3222C20.4054 17.7779 21.7146 18.4687 21.9246 19.7122C22.0934 20.7171 21.4058 21.6842 20.3971 21.8601C19.6396 21.9899 18.8409 21.5963 18.495 20.9097L18.355 20.6375H17.017V25.4734L17.1734 25.6032C17.6098 25.9674 18.1945 26.2773 18.7256 26.4196C19.2155 26.5578 20.1501 26.5578 20.64 26.4238C21.8216 26.0972 22.7274 25.3268 23.2379 24.2215C23.4561 23.7484 23.5632 23.2962 23.592 22.7477L23.6167 22.283L23.9708 22.1908C25.3335 21.8517 26.4452 20.7296 26.8198 19.3186C26.9557 18.8204 26.9557 17.87 26.8239 17.3717C26.7087 16.9447 26.4781 16.4297 26.2434 16.078L26.0705 15.8226L26.2434 15.5672C26.4781 15.2155 26.7087 14.7005 26.8239 14.2734C26.9557 13.7752 26.9557 12.8248 26.8198 12.3265C26.4452 10.9155 25.3335 9.79346 23.9708 9.45432L23.6167 9.36221L23.592 8.89747C23.5385 7.89261 23.1474 6.98825 22.4557 6.27647C21.9863 5.79498 21.3111 5.40141 20.6606 5.22556C20.2366 5.11252 19.4214 5.08321 19.0055 5.16276Z"
                        fill="#C2D24B"
                      />
                      <path
                        d="M19.8093 11.0949C19.6323 11.187 19.4882 11.4341 19.4882 11.635C19.4882 11.8444 19.6323 12.083 19.8175 12.1793C19.904 12.2254 20.0357 12.2631 20.1057 12.2631C20.1757 12.2631 20.3075 12.2254 20.3939 12.1793C20.5792 12.083 20.7233 11.8444 20.7233 11.635C20.7233 11.4257 20.5792 11.187 20.3939 11.0907C20.1922 10.9861 20.0069 10.9861 19.8093 11.0949Z"
                        fill="#C2D24B"
                      />
                      <path
                        d="M21.4549 15.282C21.2778 15.3741 21.1337 15.6211 21.1337 15.8221C21.1337 16.0315 21.2778 16.2701 21.4631 16.3664C21.5495 16.4125 21.6813 16.4501 21.7513 16.4501C21.9571 16.4501 22.1918 16.3036 22.2865 16.1152C22.3318 16.0273 22.3688 15.8933 22.3688 15.8221C22.3688 15.6128 22.2248 15.3741 22.0395 15.2778C21.8377 15.1731 21.6525 15.1731 21.4549 15.282Z"
                        fill="#C2D24B"
                      />
                      <path
                        d="M19.8093 19.4691C19.6323 19.5612 19.4882 19.8082 19.4882 20.0092C19.4882 20.0804 19.5252 20.2144 19.5705 20.3023C19.6652 20.4907 19.8999 20.6372 20.1057 20.6372C20.4186 20.6372 20.7233 20.3274 20.7233 20.0092C20.7233 19.7999 20.5792 19.5612 20.3939 19.4649C20.1922 19.3602 20.0069 19.3602 19.8093 19.4691Z"
                        fill="#C2D24B"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_5237_17083">
                        <rect width="32" height="32" fill="white" />
                      </clipPath>
                    </defs>
                  </svg>
                </Link>
              </div>
            </div>
          </>
        )}
        {!config.app.sidebar?.display && (
          <div className="d-flex align-items-center flex-grow-1 flex-lg-grow-0 me-lg-15">
            <Link to="/dashboard">
              {config.layoutType !== "dark-header" ? (
                <img
                  alt="Logo"
                  src={toAbsoluteUrl("/media/logos/default-dark.svg")}
                  className="h-20px h-lg-30px "
                />
              ) : (
                <>
                  <img
                    alt="Logo"
                    src={toAbsoluteUrl("/media/logos/default.svg")}
                    className="h-20px h-lg-30px app-sidebar-logo-default theme-light-show"
                  />
                  <img
                    alt="Logo"
                    src={toAbsoluteUrl("/media/logos/default-dark.svg")}
                    className="h-20px h-lg-30px app-sidebar-logo-default theme-dark-show"
                  />
                </>
              )}
            </Link>
          </div>
        )}
        <div
          id="kt_app_header_wrapper"
          className="d-flex align-items-stretch justify-content-between flex-lg-grow-1"
        >
          {config.app.header.default?.content === "menu" &&
            config.app.header.default.menu?.display && (
              <div
                className="app-header-menu app-header-mobile-drawer align-items-stretch"
                data-kt-drawer="true"
                data-kt-drawer-name="app-header-menu"
                data-kt-drawer-activate="{default: true, lg: false}"
                data-kt-drawer-overlay="true"
                data-kt-drawer-width="225px"
                data-kt-drawer-direction="end"
                data-kt-drawer-toggle="#kt_app_header_menu_toggle"
                data-kt-swapper="true"
                data-kt-swapper-mode="{default: 'append', lg: 'prepend'}"
                data-kt-swapper-parent="{default: '#kt_app_body', lg: '#kt_app_header_wrapper'}"
              ></div>
            )}
          <Navbar showMarketplace={false} />
        </div>
      </div>
    </div>
  );
}
