import React, { useEffect, useRef, useState } from 'react'

export interface MenuItem{
    label?:string;
    handleClick?:(...args:any[])=>any;
    overrideElement?:React.ReactElement
}
interface MenuProps{
    closeOnItemClick?:boolean;
    defaultOpen?:boolean;
    items:MenuItem[],
    verticalDots?:boolean;
}
export default function Menu({defaultOpen=false, items ,closeOnItemClick=false, verticalDots}:MenuProps) {
    const [open, setOpen] = useState<boolean>(defaultOpen)
    const containerRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
      function handleClickOutside(event: MouseEvent): void {
        if (containerRef.current && !containerRef.current.contains(event.target as Element)) {
          setOpen(false)
        }
      }
  
      document.addEventListener('mousedown', handleClickOutside);
  
      return () => {
        document.removeEventListener('mousedown', handleClickOutside);
      };
    }, []);

    return (
    items.length  ? <div className='three-dot-menu relative' ref={containerRef}>
        <button
            onClick={()=>setOpen((open)=>!open)}
            className={
              "menu-bg h-9 w-9 flex justify-center items-center p-[2px]  rounded-[6px]"
            }
          >
          {verticalDots ? <svg width="4" height="18" viewBox="0 0 4 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M2 0C0.9 0 0 0.9 0 2C0 3.1 0.9 4 2 4C3.1 4 4 3.1 4 2C4 0.9 3.1 0 2 0ZM2 14C0.9 14 0 14.9 0 16C0 17.1 0.9 18 2 18C3.1 18 4 17.1 4 16C4 14.9 3.1 14 2 14ZM2 7C0.9 7 0 7.9 0 9C0 10.1 0.9 11 2 11C3.1 11 4 10.1 4 9C4 7.9 3.1 7 2 7Z" fill="#7E8299" />
          </svg> : <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            className={'icon'}
            xmlns="http://www.w3.org/2000/svg"
          >
            <g clipPath="url(#clip0_344_18077)">
              <path
                d="M21 12C21 10.9 20.1 10 19 10C17.9 10 17 10.9 17 12C17 13.1 17.9 14 19 14C20.1 14 21 13.1 21 12ZM7 12C7 10.9 6.1 10 5 10C3.9 10 3 10.9 3 12C3 13.1 3.9 14 5 14C6.1 14 7 13.1 7 12ZM14 12C14 10.9 13.1 10 12 10C10.9 10 10 10.9 10 12C10 13.1 10.9 14 12 14C13.1 14 14 13.1 14 12Z"
                className={'icon'}
                fillOpacity="0.65"
              />
            </g>
            <defs>
              <clipPath id="clip0_344_18077">
                <rect
                  width="24"
                  height="24"
                  className={'icon'}
                  transform="translate(24) rotate(90)"
                />
              </clipPath>
            </defs>
          </svg>} 
          </button>
          {open && <div className="rounded-md absolute primary-bg right-0 mt-2 p-2 shadow-default z-10">
                {items.map(({label,handleClick, overrideElement})=>overrideElement ? overrideElement : <p className='secondary-text cursor-pointer px-4 py-3 text-nowrap' onClick={()=>{
                    handleClick && handleClick()
                    closeOnItemClick && setOpen(false)
                }}>
                    {label}
                </p>)}
          </div>}
    </div>: null
  )
}
