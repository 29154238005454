import React, { useEffect, useRef, useState } from "react";
import Chat from "../../../modules/ai-personality/components/chat";
import { useAuth } from "../../auth";
import clsx from "clsx";
import CustomToolTip from "../../widgets/components/UI/CustomToolTip";
import Lottie from "lottie-react";
import ArrowAnimationJson from "../../../../app/assets/lotties/arrow-animation.json";
import { useIntl } from "react-intl";
import TextareaAutosize from "react-textarea-autosize";
import { Link, useSearchParams } from "react-router-dom";
import request from "../../../core/_apis";
import { showPastForChat } from "./UserList";
import UserInboxNoChat from "./UserInboxNoChat";
import { getCdnUrl } from "../../../core/_util";
import { ComponentSpinner } from "../../widgets/components/General/Spinner";
import { Spinner } from "react-bootstrap"

export default function UserInboxChatBox({ AllUsers, StarredUser, fetchInboxes, ...props }: { AllUsers: any, StarredUser: any, fetchInboxes: any }) {
  const [isStarred, setIsStarred] = useState(false);
  const { formatMessage } = useIntl();
  const { currentUser } = useAuth()
  const [sp, setSp] = useSearchParams()
  const username = sp.get('u')
  const [chatUser, setChatUser] = useState<any>(undefined)
  const [messages, setMessages] = useState<any>([])
  const [msgText, setMsgText] = useState<string>('')
  const [isFirstMsg, setIsFirstMsg] = useState<boolean>(true)
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [starLoading, setStarLoading] = useState<boolean>(false)
  const [refreshLoading, setRefreshLoading] = useState<boolean>(false)
  const chatRef = useRef<HTMLDivElement | null>(null);


  const initChatBox = async () => {
    setIsLoading(true)
    let user = AllUsers.find((el: any) => (el?.username).toLocaleLowerCase() === username?.toLocaleLowerCase())
    if (user) {
      setChatUser(user)
    } else {
      let userResp = await request.get(`/user/username/${username}`);

      user = {
        name: `${userResp?.data?.data?.firstName || ''} ${userResp?.data?.data?.lastName || ''}`,
        userId: userResp?.data?.data?.userId,
        userProfileImage: userResp?.data?.data?.photo
      }
      setChatUser(user)
      return;
    }
    setIsStarred(user.starred)
    let resp = await request.get(`/me/inbox/${user.id}`)
    setIsLoading(false)
    let msgs = resp?.data?.data?.userInboxMessages || []
    msgs = msgs.sort((a: any, b: any) => new Date(a.sentAt).getTime() - new Date(b.sentAt).getTime())
    setMessages(msgs)
    setTimeout(() => {
      if (chatRef.current) {
        chatRef.current.scrollTop = chatRef.current?.scrollHeight
      }
    });
    if (user?.unreadCount > 0) {
      fetchInboxes()
    }
  }

  useEffect(() => {
    if (username) {
      initChatBox()
    }
  }, [username, AllUsers])

  const handleRefresh = async () => {
    setRefreshLoading(true)
    await initChatBox()
    setRefreshLoading(false)
  }

  const sendMessage = async () => {
    if (msgText && msgText !== '') {
      setMsgText('')
      let resp = await request.post('/me/inbox', {
        message: msgText,
        receiverUserId: chatUser.userId
      })
      if (resp.status == 200) {
        if (isFirstMsg) {
          fetchInboxes()
        }
        setMessages((messages: any) => [...messages, resp.data.data.userInboxMessage])
        // setMsgText('')
        setIsFirstMsg(false)
        setTimeout(() => {
          if (chatRef.current) {
            chatRef.current.scrollTop = chatRef.current?.scrollHeight
          }
        });
      }
    }
  }

  const handleStar = async () => {
    setStarLoading(true)
    let resp = await request.post(`/me/inbox/${chatUser.id}/star?star=${!isStarred}`)
    if (resp.status == 200) {
      setIsStarred(!isStarred)
      // setKey("M"+Math.random())
      fetchInboxes()
    }
    setStarLoading(false)

  }

  // const messages = [
  //   {
  //     id: 1001,
  //     prompt: "dev",
  //     response: "madaan",
  //     createdAt: "",
  //     customParams: "",
  //   },
  //   {
  //     id: 1002,
  //     prompt: "dev",
  //     response: "madaan",
  //     createdAt: "",
  //     customParams: "",
  //   },
  //   {
  //     id: 1003,
  //     prompt: "dev",
  //     response: "madaan",
  //     createdAt: "",
  //     customParams: "",
  //   },
  //   {
  //     id: 1004,
  //     prompt: "dev",
  //     response: "madaan",
  //     createdAt: "",
  //     customParams: "",
  //   },
  // ];
  return (
    <div className="flex-grow sm:p-[16px_12px_8px_16px] sm:block hidden ">
      {
        username ?
          <>
            <div className="flex p-[14px] sm:p-[0px] border-b sm:border-b-0 border-solid border-[#2E2F45] flex-row justify-between items-center">
              <div className="flex  flex-row gap-[14px] items-center">
                <svg
                  className="cursor-pointer  sm:hidden block"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M11.4375 18.75L4.6875 12L11.4375 5.25M5.625 12H19.3125"
                    stroke="#BBBBC3"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
                <Link to={`/u/${username}`} target={"_blank"}>
                  <div className="flex flex-row gap-[8px] cursor-pointer">
                    <img src={getCdnUrl(chatUser?.userProfileImage)} className="min-w-[50px] w-[50px] h-[50px] object-top object-cover rounded-full" alt=""></img>
                    <div className="">
                      <h5 className="text-[14px] leading-[20px] md:text-[20px] md:leading-[28px] font-[500] text-[#FFFFFFCC]">
                        {chatUser?.name}
                      </h5>
                      <p className="text-[#FFFFFFA6] mt-[4px] text-[12px] md:text-[14px] leading-[16px] md:leading-[20px]">
                        {chatUser?.lastSeenUser ? showPastForChat(chatUser?.lastSeenUser) : `Your communication with ${chatUser?.name}`}
                      </p>
                    </div>
                  </div>
                </Link>
              </div>

              <div className="flex flex-row items-center gap-[8px] sm:gap-[20px]">
                {
                  messages?.length > 0
                  &&
                  <>
                    {
                      starLoading ?
                        <>
                          <div className="flex  p-[8px] xl:p-[8px_24px] rounded-[4px] w-full min-h-[36px] flex-row items-center gap-[10px] secondary-btn">
                            <p className="sm:block hidden">
                            {formatMessage({ id: "Please wait..." })}
                            </p>
                          </div>
                        </>
                        :
                        <div
                          className="cursor-pointer"
                          onClick={(e) => handleStar()}
                        >
                          {isStarred ? (
                            <div className="flex  p-[8px] xl:p-[8px_24px] rounded-[4px] w-full min-h-[36px] flex-row items-center gap-[10px] secondary-btn">
                              <svg
                                width="20"
                                height="20"
                                viewBox="0 0 20 20"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <g clip-path="url(#clip0_9150_30327)">
                                  <path
                                    d="M9.99986 14.7913L4.85653 17.4955L5.83903 11.768L1.67236 7.71214L7.42236 6.8788L9.99403 1.66797L12.5657 6.8788L18.3157 7.71214L14.149 11.768L15.1315 17.4955L9.99986 14.7913Z"
                                    fill="#C2D24B"
                                    stroke="#C2D24B"
                                    stroke-width="1.2"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                  />
                                </g>
                                <defs>
                                  <clipPath id="clip0_9150_30327">
                                    <rect width="20" height="20" fill="white" />
                                  </clipPath>
                                </defs>
                              </svg>
                              <p className="sm:block hidden">{formatMessage({ id: "Starred" })}</p>
                            </div>
                          ) : (
                            <div className="flex p-[8px] xl:p-[8px_24px] rounded-[4px] w-full h-[36px] flex-row items-center gap-[10px] secondary-btn h-auto">
                              <svg
                                width="20"
                                height="20"
                                viewBox="0 0 20 20"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <g clip-path="url(#clip0_9150_30210)">
                                  <path
                                    d="M9.99986 14.7913L4.85653 17.4955L5.83903 11.768L1.67236 7.71214L7.42236 6.8788L9.99403 1.66797L12.5657 6.8788L18.3157 7.71214L14.149 11.768L15.1315 17.4955L9.99986 14.7913Z"
                                    stroke="#C2D24B"
                                    stroke-width="1.2"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                  />
                                </g>
                                <defs>
                                  <clipPath id="clip0_9150_30210">
                                    <rect width="20" height="20" fill="white" />
                                  </clipPath>
                                </defs>
                              </svg>
                              <p className="sm:block hidden">{formatMessage({ id: "Star this conversation" })}</p>
                            </div>
                          )}
                        </div>
                    }
                  </>
                }
                <CustomToolTip tooltipText={formatMessage({ id: "Click here to refresh" })}>
                  <div className="bg-[#3C3D54] rounded-[6px] p-[8px] cursor-pointer"
                    onClick={handleRefresh}
                  >
                    {
                      refreshLoading ?
                        <Spinner animation="border" size="sm" />
                        :
                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <g clip-path="url(#clip0_8328_154256)">
                            <path d="M15.6501 4.35179C14.436 3.13403 12.8619 2.33985 11.1611 2.08698C9.46019 1.83411 7.72312 2.13601 6.20731 2.94793C4.6915 3.75984 3.47764 5.03856 2.74565 6.59454C2.01366 8.15053 1.80251 9.90095 2.14349 11.5864C2.48447 13.2718 3.35943 14.8025 4.63868 15.9516C5.91794 17.1006 7.5334 17.807 9.2456 17.9658C10.9578 18.1246 12.6756 17.7276 14.1445 16.8334C15.6133 15.9393 16.7549 14.5957 17.4001 13.0018H15.1801C14.562 14.0935 13.6173 14.964 12.4788 15.4909C11.3402 16.0178 10.0652 16.1745 8.83294 15.9391C7.60069 15.7037 6.47323 15.088 5.60912 14.1785C4.745 13.269 4.18769 12.1115 4.01558 10.8689C3.84346 9.6262 4.0652 8.36084 4.64959 7.25074C5.23399 6.14063 6.15165 5.24163 7.27353 4.68016C8.3954 4.11869 9.66505 3.923 10.9039 4.1206C12.1428 4.31821 13.2886 4.89917 14.1801 5.78179L11.0001 9.00179H18.0001V2.00179L15.6501 4.35179Z" fill="white"
                              fill-opacity="0.65" />
                          </g>
                          <defs>
                            <clipPath id="clip0_8328_154256">
                              <rect width="20" height="20" fill="white" />
                            </clipPath>
                          </defs>
                        </svg>
                    }

                  </div>
                </CustomToolTip>
              </div>
            </div>
            <div className="primary-bg sm:rounded-[8px] sm:mt-[14px] p-[24px_11px_0px] sm:h-auto h-full">
              <div
                // ref={containerRef}
                className="flex-grow max-h-[calc(76vh-100px)] min-h-[calc(76vh-100px)] md:max-h-[calc(76vh-100px)] md:min-h-[calc(76vh-100px)] overflow-y-auto custom-scroll  justify-between flex-col flex pr-[12px] md:pr-[24px] "
              >
                <div className="scroll-y h-[50vh] pr-[8px]" ref={chatRef}>
                  {
                    isLoading ?
                      <div className="flex h-full justify-content-center align-items-center">
                        <Spinner animation="border" />
                      </div>
                      :
                      <>
                        {messages?.map(
                          (el: any) => {
                            const templateAttr = {};
                            const contentClass = `"d-flex mb-10`;
                            // if(isLoading){
                            //   return <ComponentSpinner/>
                            // }
                            return (
                              el.senderId !== chatUser.userId
                                ?
                                <div
                                  key={`${el.id}-prompt`}
                                  className={clsx(
                                    "d-flex",
                                    contentClass,
                                    "mb-10",
                                    "justify-content-end"
                                  )}
                                  {...templateAttr}
                                >
                                  <div
                                    className={
                                      "flex flex-col md:flex-row md:items-start gap-x-8 gap-y-2"
                                    }
                                  >
                                    <div
                                      className={clsx(
                                        "bg-[#171825]  rounded px-[12px] py-[8px] relative"
                                      )}
                                    >
                                      <div className="flex items-start gap-x-2">
                                        <div className={"flex min-h-[35px] items-center"}>
                                          <div
                                            className={clsx(
                                              "secondary-text ",
                                              " text-[14px] overflow-hidden text-break leading-[22px] font-normal max-w-2xl float-right"
                                            )}
                                            data-kt-element="message-text"
                                            dangerouslySetInnerHTML={{
                                              __html: el.message,
                                            }}
                                          ></div>
                                        </div>
                                        <div className="flex items-start">
                                          <>
                                            <div className="w-[35px] h-[35px] symbol symbol-35px symbol-circle">
                                              <img alt="Pic" className="object-top object-cover " src={getCdnUrl(currentUser?.photo || '')} />
                                            </div>
                                          </>
                                        </div>
                                      </div>
                                      <div className=" w-full text-right text-[#ffffff80] text-[11px] leading-[17px] pl-[44px] mt-[4px]">
                                        {/* {getTimeZoneValue(createdAt)},{" "}
                                      {getDateValue(createdAt)} */}
                                        {showPastForChat(el.sentAt)}
                                      </div>

                                    </div>
                                  </div>
                                </div>
                                :
                                <div
                                  key={`${el.id}-response`}
                                  className={clsx(
                                    "d-flex",
                                    contentClass,
                                    "mb-10",
                                    "justify-content-start"
                                  )}
                                >
                                  <div
                                    className={
                                      "flex flex-col md:flex-row md:items-start gap-x-8 gap-y-2"
                                    }
                                  >
                                    <div
                                      className={clsx(
                                        "bg-[#2E2F45] rounded p-[12px]  relative "
                                      )}
                                    >
                                      {/* {customParams?.responseEmoji && (
                                  <span
                                    className={
                                      "text-[20px] absolute right-1 md:-right-3 -bottom-4"
                                    }
                                  >
                                    {customParams.responseEmoji}
                                  </span>
                                )} */}

                                      <div className="flex items-start gap-x-2">
                                        <div className="flex items-center">
                                          <Link to={`/u/${username}`} target={"_blank"}>
                                            <div className="w-[35px] h-[35px] symbol symbol-35px symbol-circle">
                                              <img alt="Pic" className="object-top object-cover " src={getCdnUrl(chatUser?.userProfileImage)} />
                                            </div>
                                          </Link>
                                        </div>
                                        <div className="flex min-h-[35px] items-center">
                                          <div
                                            className={clsx(
                                              "secondary-text ",
                                              " text-[14px] overflow-hidden text-break leading-[22px] font-normal max-w-2xl float-right"
                                            )}
                                            data-kt-element="message-text"
                                            dangerouslySetInnerHTML={{
                                              __html: el.message,
                                            }}
                                          ></div>
                                        </div>

                                      </div>
                                      <div className=" w-full text-right text-[#ffffff80] text-[11px] leading-[17px] pl-[44px] mt-[4px]">
                                        {/* {getTimeZoneValue(createdAt)},{" "}
                                      {getDateValue(createdAt)} */}
                                        {showPastForChat(el.sentAt)}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                            );
                          }
                        )}
                      </>
                  }
                </div>

                <div
                  className={`flex   md:py-4 flex-col justify-content-between h-full ${
                    // outerContainerCss || ""
                    true || ""
                    }`}
                  id={
                    // isDrawer
                    true ? "kt_drawer_chat_messenger_body" : "kt_chat_messenger_body"
                  }
                >
                  {/* <div
                  ref={containerRef}
                  className={clsx("scroll-y h-[50vh] pr-[8px]", chatContainerCss)}
                  data-kt-element="messages"
                  data-kt-scroll="true"
                  data-kt-scroll-activate="{default: false, lg: true}"
                  data-kt-scroll-max-height="auto"
                  data-kt-scroll-dependencies={
                    isDrawer
                      ? "#kt_drawer_chat_messenger_header, #kt_drawer_chat_messenger_footer"
                      : "#kt_header, #kt_app_header, #kt_app_toolbar, #kt_toolbar, #kt_footer, #kt_app_footer, #kt_chat_messenger_header, #kt_chat_messenger_footer"
                  }
                  data-kt-scroll-wrappers={
                    isDrawer
                      ? "#kt_drawer_chat_messenger_body"
                      : "#kt_content, #kt_app_content, #kt_chat_messenger_body"
                  }
                  data-kt-scroll-offset={isDrawer ? "0px" : "5px"}
                >
                  {chatLoading && (
                    <div className="flex h-12 justify-content-center align-items-center">
                      <Spinner animation="border" />
                    </div>
                  )}
                  {!chatLoading &&
                  showQuestion &&
                  !messages.length &&
                  sampleQuestions.length ? (
                    <div className="flex w-full h-full bg-transparent justify-content-center align-items-center">
                      <div className="max-w-[380px]">
                        <h4 className="d-flex flex-row align-items-center justify-content-center text-[16px] leading-[24px] font-weight-600 text-[#FFFFFFCC] mb-[12px]">
                          <svg
                            className="me-[12px]"
                            width="32"
                            height="32"
                            viewBox="0 0 32 32"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <g clipPath="url(#clip0_1993_27971)">
                              <path
                                d="M17.3332 4V13.3333H25.3332L14.6665 28V18.6667H6.6665L17.3332 4Z"
                                stroke="white"
                                strokeWidth="2"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                            </g>
                            <defs>
                              <clipPath id="clip0_1993_27971">
                                <rect width="32" height="32" fill="white" />
                              </clipPath>
                            </defs>
                          </svg>
                          {formatMessage({ id: "Sample questions you can ask me" })}
                        </h4>
                        {sampleQuestions.map((question: string) => (
                          <p
                            onClick={() => {
                              setMessage(question);
                            }}
                            className="sample-question cursor-pointer text-[14px] p-[12px_24px] leading-[22px] font-normal secondary-text secondary-bg rounded-[4px] mb-[8px]"
                          >
                            {question}
                          </p>
                        ))}
                      </div>
                    </div>
                  ) : null}
                  {messages.map(
                    (
                      {
                        id,
                        prompt,
                        response,
                        error,
                        liked,
                        createdAt,
                        customParams,
                        sessionAction,
                      },
                      index
                    ) => {
                      const templateAttr = {};
                      // if (message.true) {
                      //   Object.defineProperty(templateAttr, "data-kt-element", {
                      //     value: `template-${message.type}`,
                      //   });
                      // }
                      const contentClass = `${isDrawer ? "" : "d-flex"} mb-10`;
                      return (
                        <>
                          {(index == 0 ||
                            new Date(createdAt).getDate() !=
                              new Date(
                                messages[index - 1].createdAt
                              ).getDate()) && (
                            <div className={clsx("py-4 timeline")}>
                              <span></span>
                              <p>{getDateValue(createdAt)}</p>
                              <span></span>
                            </div>
                          )}
                          {sessionAction ? (
                            <p
                              className={`mx-auto text-center py-2 px-2 text-sm my-4 w-[140px] ${
                                sessionAction == "closed"
                                  ? "session-ended text-[#D24B4B]"
                                  : ""
                              } ${
                                sessionAction == "created"
                                  ? "session-ended text-[#C2D24B]"
                                  : ""
                              } rounded`}
                            >
                              {formatMessage({
                                id: `${
                                  sessionAction == "created"
                                    ? "New Session Started"
                                    : "Session Ended"
                                }`,
                              })}
                            </p>
                          ) : (
                            <>
                              <div
                                key={`${id}-prompt`}
                                className={clsx(
                                  "d-flex",
                                  contentClass,
                                  "mb-10",
                                  "justify-content-end",

                                  {
                                    // "d-none": message.template,
                                  }
                                )}
                                {...templateAttr}
                              >
                                <div
                                  className={
                                    "flex flex-col md:flex-row md:items-start gap-x-8 gap-y-2"
                                  }
                                >
                                  <div
                                    className={clsx(
                                      "flex items-start gap-x-2 rounded px-2 py-3 relative",
                                      "chat-request"
                                    )}
                                  >
                                    <div
                                      className={"flex min-h-[35px] items-center"}
                                    >
                                      <div
                                        className={clsx(
                                          "secondary-text",
                                          " break overflow-hidden text-break text-[14px] leading-[22px] font-normal max-w-2xl float-right"
                                          // `text-${message.type === 'in' ? 'start' : 'end'}`
                                        )}
                                        data-kt-element="message-text"
                                        dangerouslySetInnerHTML={{ __html: prompt }}
                                      ></div>
                                    </div>
                                    <div className="flex items-start">
                                      <>
                                        <div className="w-[35px] h-[35px] symbol symbol-35px symbol-circle">
                                          <img
                                            className="w-full"
                                            alt="Pic"
                                            src={userImage}
                                          />
                                        </div>
                                      </>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <ChatResponse
                                key={`response-${id}`}
                                data={{
                                  contentClass,
                                  id,
                                  response,
                                  error,
                                  personalityImage,
                                  liked,
                                  customParams,
                                  setShareChatId,
                                }}
                              />
                            </>
                          )}
                        </>
                      );
                    }
                  )}
                  {defaultMessage.prompt && (
                    <>
                      <div
                        key={`${defaultMessage.id}-prompt`}
                        className={clsx(
                          "d-flex",
                          `${isDrawer ? "" : "d-flex"}mb-10`,
                          "mb-10",
                          "justify-content-end",
                          {
                            // "d-none": message.template,
                          }
                        )}
                      >
                        <div
                          className={
                            "flex flex-col md:flex-row md:items-start gap-x-8 gap-y-2"
                          }
                        >
                          <div
                            className={clsx(
                              "flex items-start gap-x-2 rounded px-2 py-3 relative",
                              "chat-request"
                            )}
                          >
                            <div
                              className={clsx(
                                "secondary-text overflow-hidden text-break text-[14px] leading-[22px] font-normal  max-w-2xl float-right"
                                // `text-${message.type === 'in' ? 'start' : 'end'}`
                              )}
                              data-kt-element="message-text"
                              dangerouslySetInnerHTML={{
                                __html: defaultMessage.prompt,
                              }}
                            ></div>
                            <div className="flex items-center">
                              <>
                                <div className="w-12 h-12 symbol symbol-35px symbol-circle">
                                  <img alt="Pic" src={userImage} />
                                </div>
                              </>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        key={`${defaultMessage.id}-response`}
                        className={clsx(
                          "d-flex",
                          `${isDrawer ? "" : "d-flex"} mb-10`,
                          "mb-10",
                          "justify-content-start",
                          {
                            // "d-none": message.template,
                          }
                        )}
                      >
                        <div
                          className={
                            "flex flex-col md:flex-row md:items-start gap-x-8 gap-y-2"
                          }
                        >
                          <div
                            className={clsx(
                              "flex items-start gap-x-2 rounded px-2 py-3 relative"
                              // "bg-[#2E2F45] "
                            )}
                          >
                            <div className="flex items-center">
                              <>
                                <div className="w-12 h-12 symbol symbol-35px symbol-circle">
                                  <img alt="Pic" src={personalityImage} />
                                </div>
                              </>
                            </div>
                            {isResponding ? (
                              <Lottie
                                animationData={
                                  isEqual(mode, "light")
                                    ? TypingAnimationJsonLight
                                    : TypingAnimationJson
                                }
                                style={{
                                  width: 85,
                                  height: 45,
                                  borderRadius: 25,
                                  borderTopLeftRadius: 0,
                                  overflow: "hidden",
                                }}
                              />
                            ) : (
                              <>
                                <div
                                  className={clsx(
                                    "secondary-text text-[14px] overflow-hidden text-break leading-[22px] font-normal max-w-2xl float-right"
                                    // `text-${message.type === 'in' ? 'start' : 'end'}`
                                  )}
                                  data-kt-element="message-text"
                                  dangerouslySetInnerHTML={{
                                    __html: defaultMessage.response,
                                  }}
                                ></div>
                                <p
                                  style={{ marginTop: -20, color: "#ff2424" }}
                                  className="text-sm"
                                >
                                  {defaultMessage.error}
                                </p>
                              </>
                            )}
                          </div>
                        </div>
                      </div>
                    </>
                  )}
                  {showQuotaError && (
                    <p
                      className={
                        "mx-auto text-center py-2 px-2 text-sm my-4 max-w-[80%] bg-[#000000bb] text-[#D24B4B]"
                      }
                    >
                      {formatMessage({
                        id: `This AI Personality is temporarily unavailable. Please contact the owner or manager of this AI Personality`,
                      })}
                    </p>
                  )}
                </div>  */}
                  <div
                    className="py-[8px] card-footer"
                    id={
                      // isDrawer
                      true
                        ? "kt_drawer_chat_messenger_footer"
                        : "kt_chat_messenger_footer"
                    }
                  >
                    {/* {creatingSession && ( */}
                    {false && (
                      <div className="text-center secondary-text py-2 px-4 mb-4 border-2 border-[#C2D24B] w-fit mx-auto rounded">
                        Creating Session for you. Please wait...
                      </div>
                    )}
                    <div className={"flex flex-col  md:flex-row md:items-end"}>
                      <div className={"relative flex-grow"}>
                        <TextareaAutosize
                          // maxLength={CHAT_INPUT_LENGTH}
                          // ref={inputRef}
                          style={{ height: 52, background: "#2E2F45" }}
                          className={clsx(
                            " search-input-table ",
                            "form-control rounded pr-10"
                          )}
                          data-kt-element="input"
                          placeholder={formatMessage({
                            // id: `${isResponding ? "Waiting for reply" : "Type a message"}`,
                            id: `Type a message`,
                          })}
                          value={msgText}
                          // disabled={isResponding}
                          onChange={(e) => setMsgText(e.target.value)}
                          onKeyDown={(e) => {
                            if (e.keyCode === 13 && e.shiftKey === false) {
                              e.preventDefault();
                              sendMessage()
                            }
                          }}
                        />
                        <button
                          className={"absolute right-4 bottom-3"}
                          // disabled={isResponding || isDisabled}
                          onClick={sendMessage}
                        >
                          <svg
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <g clipPath="url(#clip0_344_24401)">
                              <path
                                d="M9.99958 14L20.9996 3M9.99958 14L13.4996 21C13.5435 21.0957 13.6139 21.1769 13.7025 21.2338C13.7912 21.2906 13.8943 21.3209 13.9996 21.3209C14.1049 21.3209 14.208 21.2906 14.2966 21.2338C14.3853 21.1769 14.4557 21.0957 14.4996 21L20.9996 3M9.99958 14L2.99958 10.5C2.90384 10.4561 2.82271 10.3857 2.76583 10.2971C2.70895 10.2084 2.67871 10.1053 2.67871 10C2.67871 9.89468 2.70895 9.79158 2.76583 9.70295C2.82271 9.61431 2.90384 9.54387 2.99958 9.5L20.9996 3"
                                // stroke={isResponding || isDisabled ? "#ababab" : "#C2D24B"}
                                stroke={false ? "#ababab" : "#C2D24B"}
                                strokeWidth="2"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                            </g>
                            <defs>
                              <clipPath id="clip0_344_24401">
                                <rect width="24" height="24" fill="white" />
                              </clipPath>
                            </defs>
                          </svg>
                        </button>
                        {/* {showEnterMessageAnimation && ( */}
                        {messages.length === 0 && (
                          <div className="absolute top-[-83px] left-0 enter-message-animation p-[10px_10px_0px_10px] rounded-lg">
                            <p className="mb-0 text-[12px] leading-[18px]">
                              {formatMessage({ id: "Enter your message here" })}
                            </p>
                            <Lottie
                              animationData={ArrowAnimationJson}
                              style={{
                                width: 100,
                                height: 55,
                                borderRadius: 25,
                                borderTopLeftRadius: 0,
                                overflow: "hidden",
                              }}
                            />
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
          :
          <UserInboxNoChat />
      }
    </div>
  );
}