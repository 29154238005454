import React, { useMemo, useState } from "react";
import Uppy from "@uppy/core";
import { Dashboard } from "@uppy/react";
import ImageEditor from "@uppy/image-editor";
import GoogleDrive from "@uppy/google-drive";
import Dropbox from "@uppy/dropbox";
import OneDrive from "@uppy/onedrive";
import Url from "@uppy/url";
//@ts-ignore
import Webcam from "@uppy/webcam";
import { AuthModel, getAuth } from "../../../../modules/auth";
import "@uppy/core/dist/style.min.css";
import "@uppy/dashboard/dist/style.min.css";
import "@uppy/webcam/dist/style.min.css";
import { toast } from "react-toastify";
import Transloadit from "@uppy/transloadit";
import { useIntl } from "react-intl";
import { getUploadSign } from "../../../auth/core/_requests";
function FileUploader({
  resourceType,
  handleClose,
  maxFileNumber,
  allowType,
  metaData,
  fileSize,
  handleSuccess,
  onComplete,
  signatureParams={},
  isPrivate
}:any) {
  const { token } = (getAuth() as AuthModel) || {};
  if (!token) {
    getAuth();
  }
  
  isPrivate = isPrivate??false
  const { formatMessage } = useIntl();
  const getAssemblyOptions = (): Promise<any> => {
    return new Promise((resolve, reject) => {
      getUploadSign(resourceType, signatureParams)
        .then((resp) => {
          let transloaditObject = resp.data.data;
          resolve({
            params: {
              auth: {
                key: transloaditObject.transloaditKey,
                expires: transloaditObject.expires,
              },
              steps: {
                ":original": {
                  robot: "/upload/handle",
                },
                image_optimized: {
                  use: ":original",
                  robot: "/image/optimize",
                  priority: "compression-ratio",
                },
                described: {
                  "use": ":original",
                  "robot": "/image/describe",
                  "provider": "aws",
                  "explicit_descriptions": true,
                  "format": "meta",
                  "granularity": "list",
                  "result": true
                },
                filtered: {
                  "result": true,
                  "use": "described",
                  "robot": "/file/filter",
                  "declines": [
                    [
                      "${file.meta.descriptions}",
                      "includes",
                      "Nudity"
                    ]
                  ],
                  "error_on_decline": true,
                  "error_msg": "Explicit content is not allowed!"
                },
                exported: isPrivate ? transloaditObject.s3PrivateBucketObj: transloaditObject.s3PublicBucketObj,
              },
            },
            signature: isPrivate ? transloaditObject.privateUploadSignature: transloaditObject.publicUploadSignature,
          });
        })
        .catch((err) => {
          let errorMsg = err.message.includes("Explicit content")
            ? "Explicit content is not allowed!"
            : formatMessage({
                id: "Server error while uploading. Please try again later.",
              });
          toast.error(errorMsg,
            {
              autoClose: false,
              closeButton: true, // Display close button (cross icon)
            });
          uppy.cancelAll();
          handleClose();
        });
    });
  };

  const uppy = useMemo(() => {
    return new Uppy({
      autoProceed: false,
      debug: false,
      restrictions: {
        maxFileSize: fileSize,
        maxNumberOfFiles: maxFileNumber,
        allowedFileTypes: allowType,
      },
      meta: metaData,
      onBeforeFileAdded: (currentFile, files) => {
        let msg = "";
        if (
          resourceType == "user-avatar" ||
          resourceType == "personality-avatar"
        ) {
          msg = `Please upload a square image for better compatibility.`;
        }
        if (
          resourceType == "user-banner" ||
          resourceType == "personality-banner"
        ) {
          msg = `Recommended image size is 1440x320 px i.e. width of 1440 pixels, height of 320 pixels.`;
        }
        if(resourceType == "user-posts"){
          msg =`Recommended image should have an aspect ratio of 16:9 like 1600 X 900px, 800 X 450px`
        }
        uppy.info(msg, "info", 3000);
        return true;
      },
    })
      .use(ImageEditor, {
        id: "ImageEditor",
        cropperOptions: {
          viewMode: 1,
          background: false,
          autoCropArea: 1,
          responsive: true,
          initialAspectRatio: 1,
          croppedCanvasOptions: {},
        },
        actions: {
          revert: true,
          rotate: true,
          granularRotate: true,
          flip: true,
          zoomIn: true,
          zoomOut: true,
          cropSquare: true,
          cropWidescreen: true,
          cropWidescreenVertical: true,
        },
      })
      .use(Transloadit, {
        getAssemblyOptions,
        waitForEncoding: true,
      })
      // .use(ImageEditor, { id: "MyImageEditor" })
      // .use(Webcam, {})
      // .use(GoogleDrive, { companionUrl: "http://localhost:3000" })
      // .use(Dropbox, { companionUrl: "http://localhost:3000" })
      // .use(OneDrive, { companionUrl: "http://localhost:3000" })
      .on("transloadit:complete", (assembly) => {
        handleClose();
        onComplete(assembly)
        handleSuccess(5, assembly?.results[":original"]?.[0]?.ssl_url);
        //   setPhotoUrl(assembly.results[':original'][0].ssl_url)
      })
      .on("error", (err) => {
        console.log(err);

        let errorMsg = err.message.includes("Explicit content")
            ? "Explicit content is not allowed!"
            : formatMessage({
                id: "Server error while uploading. Please try again later.",
              });

        toast.error(errorMsg,
          {
            autoClose: false,
            closeButton: true, // Display close button (cross icon)
          });
        uppy.cancelAll();
      });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps
  React.useEffect(() => {
    return () => uppy.close();
  }, [fileSize]);

  uppy.on("complete", (results) => {
   
  });

  uppy.on("error", (err) => {
    console.log(err)
  });

  return (
    <Dashboard
      height={250}
      className={""}
      dir={"horizontal"}
      uppy={uppy}
      proudlyDisplayPoweredByUppy={true}
      showProgressDetails={false}
      plugins={[
        "ImageEditor",
        "Webcam",
        "GoogleDrive",
        "OneDrive",
        "Dropbox",
        "OneDrive",
      ]}
    />
  );
}
export default FileUploader;
