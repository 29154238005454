import React, {useEffect, useState} from 'react';
import {useNavigate} from "react-router-dom";
import SplashScreen from "../components/splash-screen";
import Footer from "../components/footer";
import Lottie from "lottie-react";
import  ringing from '../animations/ringing.json'
import {toAbsoluteUrl} from "../../../../_metronic/helpers";
const RingingPage = () => {
    const navigate = useNavigate()
    const [isLoading, setIsLoading] = useState(true)
    const [isPending, setIsPending] = useState(false)

    useEffect(() => {
        setTimeout(()=>{
            setIsLoading(false)
        },2000)
    }, []);


    useEffect(() => {
        setTimeout(()=>{
           navigate('/call/speaking')
        },5000)
    }, []);

    if (isLoading){
        return  <SplashScreen/>
    }
    return (
        <div className={'flex flex-col justify-end bg-image-speaking  h-full w-full'}>
            <div className={'recharge-footer-container  px-5 flex flex-col justify-end'}>
                <div className={'recharge-input-container  h-full p-3 pb-4 flex flex-col items-center'}>
                    <div className="-mt-[50px] mb-2.5 relative  w-[90px] h-[90px]">
                        <div className={'bg-[#C2C751] rounded-full p-1 blur-md w-[80px] h-[80px] absolute z-10'}/>
                        <img src={toAbsoluteUrl('/recharge/images/sunny.png')}  className={'z-40 absolute bg-[#1F083E] w-[74px] h-[74px] rounded-full'}/>
                        <p className="absolute  right-5 bottom-[5px] !text-[20px] z-[50]">😍</p>
                    </div>
                    <div className={'flex-grow relative'}>
                    <Lottie animationData={ringing} loop={true} className={'w-80 h-80 absolute -bottom-20 right-1/2 translate-x-1/2'}/>
                    </div>
                    <div className={'flex flex-col items-center'}>
                        <p className="mb-3">Ringing...</p>
                    <button className="btn btn-back w-36" onClick={()=>{
                        navigate('/call/matched')
                    }}>
                        <a className="underline-0 text-white">
                            End Call
                        </a>
                    </button>
                    </div>
                </div>
                <Footer/>
            </div>
        </div>
    );
};

export default RingingPage;