import React, { useState } from "react";
import clsx from "clsx";
import useDropdownMenu from "react-accessible-dropdown-menu-hook";
import { isEqual } from "lodash";
const Index = () => {
  const [sortType, setSortType] = useState("desc");
  const { buttonProps, itemProps, isOpen } = useDropdownMenu<any>(3);

  // @ts-ignore
  // @ts-ignore
  // @ts-ignore
  return (
    <div className={"relative"}>
      <button
        className={"p-1 bg-[#2E2F45] border !border-[#FFFFFF1A] rounded"}
        {...buttonProps}
      >
        <svg
          width="32"
          height="32"
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g clipPath="url(#clip0_598_26376)">
            <path
              d="M5.3335 8H17.3335M5.3335 16H14.6668M5.3335 24H14.6668M20.0002 20L24.0002 24M24.0002 24L28.0002 20M24.0002 24V8"
              stroke="#C2D24B"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </g>
          <defs>
            <clipPath id="clip0_598_26376">
              <rect width="32" height="32" fill="white" />
            </clipPath>
          </defs>
        </svg>
      </button>
      <div
        className={clsx(
          isOpen ? "visible" : "hidden",
          "absolute right-0  mt-3 rounded  bg-[#2E2F45]  z-10"
        )}
        role="menu"
      >
        <div className={"relative"}>
          <span
            className={
              "absolute w-4 h-4 bg-[#2E2F45] right-4 -translate-y-1/2 rotate-45 rounded-[2px] -z-10"
            }
          ></span>
          <ul className={"whitespace-nowrap w-56"}>
            {/* @ts-ignore: */}
            <li
              {...itemProps[0]}
              onClick={() => setSortType("desc")}
              className={
                "py-[7px] px-[12px] hover:bg-[#C2D24B1A] cursor-pointer border-b border-[#161718] rounded-t flex justify-between items-center"
              }
            >
              Descending
              {isEqual(sortType, "desc") && (
                <svg
                  width="15"
                  height="11"
                  viewBox="0 0 15 11"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M1 5.90909L5.0625 10L14 1"
                    stroke="white"
                    strokeWidth="1.4"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              )}
            </li>
            {/* @ts-ignore: */}
            <li
              {...itemProps[1]}
              onClick={() => setSortType("asc")}
              className={
                "py-[7px] px-[12px] hover:bg-[#C2D24B1A] cursor-pointer rounded-b flex justify-between items-center"
              }
            >
              Ascending
              {isEqual(sortType, "asc") && (
                <svg
                  width="15"
                  height="11"
                  viewBox="0 0 15 11"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M1 5.90909L5.0625 10L14 1"
                    stroke="white"
                    strokeWidth="1.4"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              )}
            </li>
            {/* @ts-ignore: */}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default Index;
