import React, { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import Sider from "../../components/marketplace-chat/sider";
import ChatBg from "./chat-bg.png";
import ChatBody from "../../components/marketplace-chat/chat-body";
import { Spinner } from "../../../widgets/components/General/Spinner";
import Empty from "../../components/ai-user-profile/empty";
import { useAuth } from "../../../auth";
import { getPersonalityById } from "../../components/marketplace-chat/core/_request";
const Index = () => {
  const [openFilter, setOpenFilter] = useState(false);
  const [hasBackgroud, setBackground] = useState(false);
  const [loadPersonality, setLoadPersonality] = useState(false);
  const [loadChats, setLoadChats] = useState(false);
  const [chatsCount, setChatsCount] = useState(0);
  const { formatMessage } = useIntl();
  const {setPersonalityInView, setCurrentChatSession} = useAuth();

  async function chatChange(activeChat:string){
    setPersonalityInView(undefined)
    if (!activeChat)
    return;
    setLoadPersonality(true)
    const { data: { success, data } } = await getPersonalityById(activeChat)
    if (success) {
      setPersonalityInView(data)
    }
    setLoadPersonality(false)
    }
    useEffect(()=>{
      return ()=>{
        setPersonalityInView(undefined)
        setCurrentChatSession(undefined);
      }
    },[])
  return (
    <div className={"pt-[10px] md:pt-5"}>
      <div className={"flex w-full card-bg rounded border border-main"}>
        <Sider open={openFilter} chatChange={chatChange}  setOpen={setOpenFilter} loadChats={loadChats} setLoadChats={setLoadChats} setChatsCount={setChatsCount} />
          <div
            className={
              "h-[calc(95vh-80px)] w-full bg-center bg-no-repeat overflow-y-hidden my-chat-container custom-scroll"
            }
            style={{
              backgroundImage: hasBackgroud ? `url(${ChatBg})` : "",
            }}
          >
            {loadPersonality && <Spinner/>}
            {loadChats ? null :(chatsCount>0 ? <ChatBody isDark={true} /> : chatsCount===0 ? <Empty  atMarketplace={false} heading={formatMessage({ id: 'No chats found!' })} contents={formatMessage({ id: 'No chats found. Start your conversation with popular personalities on Kamoto' })} /> : null)}
          </div>
      </div>
    </div>
  );
};

export default Index;
