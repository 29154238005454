import React, { useEffect, useRef } from "react";
import * as echarts from "echarts";
import {get, isEqual, map, range} from "lodash";
import { useThemeMode } from "../../../../../../_metronic/partials";
// @ts-ignore
import useChartResizer from "../../../../../hooks/useChartResizer";
import moment from "moment";
const Index = ({}) => {
  const { mode } = useThemeMode();
  function func(x: number) {
    x /= 10;
    return Math.sin(x) * Math.cos(x * 2 + 1) * Math.sin(x * 3 + 2) * 50;
  }
  let option = {
    xAxis: {
      type: "category",
      nameLocation: "start",
      boundaryGap: false,
      data: [1,5,10,15,20,25,30],
      clip: true,
      axisLine: {
        show: true,
        lineStyle: {
          color: "#2E2F45",
        },
      },
      axisTick: {
        show: true,
        alignTicks: true,
      },
      axisLabel: {
        margin: 4,
        fontSize: 12,
        color: isEqual(mode, "light") ? "#5E6278" : "#FFFFFFA6",
        // formatter: dayjs("{value}"),
      },
    },
    yAxis: {
      show: true,
      type: "value",
      axisLine: {
        show: false,
      },
      splitNumber:8,
      splitLine: {
        lineStyle: {
          color: "#2E2F45",
        },
      },
      axisLabel: {
        margin: 4,
        fontSize: 12,
        color: isEqual(mode, "light") ? "#5E6278" : "#FFFFFFA6",
        // formatter: dayjs("{value}"),
      },
    },
    grid: {
      left: 0,
      top: 5,
      right: 15,
      bottom: 0,
      containLabel: true,
    },
    tooltip: {
      trigger: "axis",
      className: "secondary-bg",
      borderWidth: 0,
      formatter: (data: any) => {
        return `<span class="text-[12px] font-semibold primary-text">Likes Count : ${get(
          data[0],
          "data[1]"
        )}</span> </br> <span class="text-[10px] font-normal primary-text">${moment(
          get(data[0], "data[0]")
        ).format("MMM DD, YYYY HH:mm")}</span>`;
      },
      axisPointer: {
        animation: false,
      },
    },
    legend: {
      show: false,
    },
    series: [
      {
        stack: "User Joined",
        showSymbol: false,
        type: "line",
        label: {
          show: true,
          position: [20, -20],
          margin: 4,
          fontSize: 14,
          color: isEqual(mode, "light") ? "#5E6278" : "#FFFFFFA6",
        },
        lineStyle: {
          color: "#3DD883",
          width: 3,
        },
        itemStyle: {
          color: "#C2D24B",
        },
        smooth: true,
        data: [123,512,500,1000,200,600,900]
      },
      {
        stack: "Pending action",
        showSymbol: false,
        type: "line",
        label: {
          show: true,
          position: [20, -20],
          margin: 4,
          fontSize: 14,
          color: isEqual(mode, "light") ? "#5E6278" : "#FFFFFFA6",
        },
        lineStyle: {
          color: "#C2D24B",
          width: 3,
        },
        itemStyle: {
          color: "#C2D24B",
        },
        smooth: true,
        data: [1234,235,1500,890,1000,450,500]
      },
    ],
  };
  const chartRef = useRef();
  useChartResizer(chartRef);
  useEffect(() => {
    const chart = echarts.init(chartRef.current, null);
    // @ts-ignore
    chart.setOption(option);
  }, [option]);

  // @ts-ignore
  return <div className={"w-full h-full pt-4"} ref={chartRef}></div>;
};

export default Index;
