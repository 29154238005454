import React, { useEffect, useState } from "react";
import { get, isEqual, map, range } from "lodash";
import CustomTable from "../../../components/table";
import { useThemeMode } from "../../../../_metronic/partials";
import { useIntl } from "react-intl";
import {
  deleteApiKey,
  getAllApiForUser,
  updateApiKey,
} from "../core/_requests";
import { useDateFormat } from "../../../hooks/useDateFormat";
import { useTimeZone } from "../../../hooks/useTimeZone";
import { getCdnUrl } from "../../../core/_util";
import { toast } from "react-toastify";
import { CustomButton } from "../../widgets/components/UI/CustomButton";
import { BasicButton } from "../../widgets/components/UI/BasicButton";
import { Formik } from "formik";
import { Modal } from "react-bootstrap";
import useGTM from "../../../hooks/useGTM";
import { GTMEvent } from "../../../hooks/gtm_helpers";
const ApiKeys: React.FC<any> = ({
  setApiKeyCreated,
  apiKeyCreated,
  setOpen,
}) => {
  const { formatMessage } = useIntl(); 
  const {dataLayerPush} = useGTM()
  const { mode } = useThemeMode();
  const [loading, setLoading] = useState<boolean>(true);
  const { getDateValue } = useDateFormat();
  const { getTimeZoneValue } = useTimeZone();
  const [data, setData] = useState<any[]>([]);
  const [pageCount, setPageCount] = useState<number>(0);
  const [selectedApiKey, setSelectedApiKey] = useState<any>(null);
  const [deleteModalOpen, setDeleteModalOpen] = useState<boolean>(false);
  async function getKeys({ page, size }: any) {
    setLoading(true);
    const {
      data: { success, data },
    } = await getAllApiForUser({
      page: page + 1,
      limit: size,
    });
    if (success) {
      let formattedData = data.keys.map(
        ({
          name,
          apiKeyPreview,
          createdAt,
          totalTokens,
          personality,
          tokensUsed,
          lastUsed,
          isActive,
          id,
        }: any) => {
          return {
            name,
            id,
            apiKey: apiKeyPreview,
            createdAt: `${getDateValue(createdAt)} ${getTimeZoneValue(
              createdAt
            )}`,
            totalTokens,
            personality,
            tokensUsed: tokensUsed ?? "-",
            lastUsed: !lastUsed
              ? "-"
              : `${getDateValue(lastUsed)} ${getTimeZoneValue(lastUsed)}`,
            isActive,
          };
        }
      );
      setLoading(false);
      setData(formattedData);
      setPageCount(Math.ceil(data.count / size));
    }
  }

  useEffect(() => {
    if (apiKeyCreated) {
      getKeys({ page: 0, size: 10 });
      setApiKeyCreated(false);
    }
  }, [apiKeyCreated]);

  const headers = [
    {
      label: `${formatMessage({ id: "Name" })}`,
      key: "name",
      enableSorting: true,
    },
    {
      label: `${formatMessage({ id: "API key" })}`,
      key: "apiKey",
      enableSorting: true,
    },
    {
      label: `${formatMessage({ id: "AI Personality" })}`,
      key: "personality",
      cell: true,
      enableSorting: true,
    },
    {
      label: `${formatMessage({ id: "Tokens Utilized" })}`,
      key: "tokensUsed",
      enableSorting: true,
    },
    {
      label: `${formatMessage({ id: "Last Used" })}`,
      key: "lastUsed",
      enableSorting: true,
    },
    {
      label: `${formatMessage({ id: "Created" })}`,
      key: "createdAt",
      enableSorting: true,
    },
    {
      label: `${formatMessage({ id: "Actions" })}`,
      key: "isActive",
      cell: true,
      enableSorting: true,
    },
  ];

  const columns = React.useMemo<any>(
    () =>
      map(headers, (item) => {
        return {
          header: get(item, "label"),
          accessorKey: get(item, "key"),
          enableSorting: get(item, "enableSorting"),
          cell: (info: any) => {
            if (item.cell) {
              if (isEqual(get(item, "key"), "personality")) {
                return (
                  <div className={"flex items-center gap-x-2"}>
                    <img
                      src={getCdnUrl(info.getValue().image)}
                      className={"rounded-full w-6 h-6"}
                    />
                    {info.getValue().name}
                  </div>
                );
              }
              if (isEqual(get(item, "key"), "isActive")) {
                return (
                  <div
                    className={"flex items-center gap-x-3.5 company-container"}
                  >
                    <form className="form-check  form-check-custom react-switch-bg form-check-solid form-check-primary form-switch flex items-center gap-x-[8px]">
                      <input
                        className="form-check-input flex-grow !w-[45px]"
                        type="checkbox"
                        // id={id.toString()}
                        name="model.app.sidebar.default.minimize.desktop.hoverable"
                        checked={!!info.getValue()}
                        onChange={async (e) => {
                          setData((data) =>
                            data.map((item) => {
                              if (item.id === info.row.original.id)
                                return {
                                  ...item,
                                  isActive: e.target.checked ? 1 : 0,
                                };
                              else return item;
                            })
                          );
                          const {
                            data: { success },
                          } = await updateApiKey(
                            info.row.original.id,
                            e.target.checked
                          );
                          if (success) {
                            toast.success(
                              `API Key ${
                                e.target.checked ? "Enabled" : "Disabled"
                              } successfully`
                            );
                            if(e.target.checked){
                              dataLayerPush(GTMEvent.ApiKeyEnableSuccessful)
                            }else{
                              dataLayerPush(GTMEvent.ApiKeyDisableSuccessful)
                            }
                          } else {
                            setData((data) =>
                              data.map((item) => {
                                if (item.id === info.row.original.id)
                                  return {
                                    ...item,
                                    isActive: e.target.checked ? 0 : 1,
                                  };
                                else return item;
                              })
                            );
                            toast.error("Error while updating",
                            {
                              autoClose: false,
                              closeButton: true, // Display close button (cross icon)
                            });
                            if(e.target.checked){
                              dataLayerPush(GTMEvent.ApiKeyEnableFailed)
                            }else{
                              dataLayerPush(GTMEvent.ApiKeyDisableFailed)
                            }
                          }
                        }}
                      />
                    </form>
                    <button
                      className={
                        "w-9 h-9 flex items-center justify-center rounded-[6px] bg-[#C2D24B1A]"
                      }
                      onClick={async () => {
                        setSelectedApiKey(info.row.original);
                        setDeleteModalOpen(true);
                      }}
                    >
                      <svg
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M4 7H20M10 11V17M14 11V17M5 7L6 19C6 19.5304 6.21071 20.0391 6.58579 20.4142C6.96086 20.7893 7.46957 21 8 21H16C16.5304 21 17.0391 20.7893 17.4142 20.4142C17.7893 20.0391 18 19.5304 18 19L19 7M9 7V4C9 3.73478 9.10536 3.48043 9.29289 3.29289C9.48043 3.10536 9.73478 3 10 3H14C14.2652 3 14.5196 3.10536 14.7071 3.29289C14.8946 3.48043 15 3.73478 15 4V7"
                          stroke="#C2D24B"
                          strokeWidth="1.6"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                    </button>
                  </div>
                );
              }
            } else {
              return info.getValue();
            }
          },

          footer: (props: any) => props.column.id,
        };
      }),

    []
  );

  return (
    <div className={"mt-[16px] md:mt-4"}>
      <div
        className={
          "tab-container shadow-default sm:py-[14px] sm:px-[6px] md:p-6  rounded min-h-[70vh]"
        }
      >
        <CustomTable
          tableData={data}
          pageCount={pageCount}
          loading={loading}
          headers={headers}
          columns={columns}
          noPagination
          fetchData={getKeys}
          emptyControl={{
            heading: formatMessage({ id: "No API Keys found" }),
            content: formatMessage({
              id: "Create one by clicking on the button below",
            }),
            footer: (
              <div className="mt-4">
                <CustomButton
                  loading={false}
                  isValid={true}
                  buttonText={formatMessage({ id: "Create API Key" })}
                  onSubmit={() => setOpen(true)}
                />
              </div>
            ),
          }}
        />
      </div>
      <Modal
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={deleteModalOpen}
        onHide={() => setDeleteModalOpen(false)}
      >
        <Modal.Header closeButton>
          <Modal.Title
            className="modal-title text-[20px] leading-[28px] font-weight-600 text-[#FFFFFFCC] "
            id="contained-modal-title-vcenter"
          >
            {formatMessage({ id: "Delete Confirmation" })}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className=" p-[16px] md:p-[24px]">
          <p className="text-[#FFFFFFCC] text-[16px]">
            {formatMessage({ id: "Are you sure to delete" })}{" "}
            <span className="text-[#C2D24B]">
              {selectedApiKey?.name || "this"}
            </span>{" "}
            {formatMessage({ id: "API Key?" })}
          </p>
          <Formik
            initialValues={{}}
            onSubmit={async (values, { setSubmitting }) => {
              setSubmitting(true);
              const {
                data: { success, data },
              } = await deleteApiKey(selectedApiKey.id);
              if (success) {
                setData((data) =>
                  data.filter((item) => item.id !== selectedApiKey.id)
                );
                setDeleteModalOpen(false);
                toast.success("API key deleted successfully");
                dataLayerPush(GTMEvent.ApiKeyDeletionSuccessful)
              } else {
                toast.error("Failed to delete",
                {
                  autoClose: false,
                  closeButton: true, // Display close button (cross icon)
                });
                dataLayerPush(GTMEvent.ApiKeyDeletionSuccessful)
              }
              setSubmitting(false);
            }}
          >
            {(formik) => (
              <div className="flex gap-x-3 justify-end items-center">
                <BasicButton
                  buttonText={formatMessage({ id: "Cancel" })}
                  border="none"
                  color="#C2D24B1A"
                  textColor="#C2D24B"
                  customClass={"px-[24px] mt-8"}
                  height="44px"
                  onClick={() => {
                    setDeleteModalOpen(false);
                  }}
                />
                <CustomButton
                  customClass="mt-8"
                  isSubmitting={formik.isSubmitting}
                  isValid={true}
                  buttonText={formatMessage({ id: "Delete" })}
                  loading={formik.isSubmitting}
                  height={44}
                  widthLoading={1}
                  width={1}
                  onSubmit={formik.handleSubmit}
                />
              </div>
            )}
          </Formik>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default ApiKeys;
