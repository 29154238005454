import React, { useEffect, useState } from "react";
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
import { useIntl } from "react-intl";

import { useAuth } from "../../auth";
import { useSearchParams } from "react-router-dom";
import InteractionBreadCrumb from "../components/breadcrumb/interaction-breadcrumb";
import InteractionList from "./interaction-list";
import UserInboxBreadcrumbs from "../components/breadcrumb/user-inbox-breadcrumbs";
import UserInboxChatList from "./UserInboxChatList";
import UserInboxChatBox from "./UserInboxChatBox";
import request from "../../../core/_apis";

const tabsToIndex: { [key: string]: number } = {
  "user-inbox": 0,
};
const indexToTabs: { [key: number]: string } = {
  0: "user-inbox",
};
const UserInbox = () => {
  const { formatMessage } = useIntl();
  const [searchParams, setSearchParams] = useSearchParams();
  const [key,setKey] = useState("M"+Math.random())
  const [inboxLoading,setInboxLoading] = useState<boolean>(false)
  const [activeTab, setActiveTab] = useState<number>(
    tabsToIndex[searchParams.get("tab") || "user-inbox"]
  );
  useEffect(() => {
    searchParams.set("tab", "" + indexToTabs[activeTab]);
    setSearchParams(searchParams);
  }, [activeTab]);

  useEffect(() => {
    let tab = searchParams.get("tab");
    if (tab) {
      setActiveTab(tabsToIndex[tab]);
    }
  }, [searchParams]);

  const [AllUsers, setAllUsers] = useState<any>([])
  const [StarredUser, setStarredUser] = useState([])

  const fetchInboxes = () => {
    request.get('/me/inbox').then((resp) => {
      let all = resp.data.data.map((el: any) => {
        return {
          id: el.id,
          userId: el.user.userId,
          username: el.user.username,
          name: `${el.user.firstName || ''} ${el.user.lastName || ''}`,
          verifiedIcon: el.user.isVerified,
          userProfileImage: el.user.photo,
          date: el.lastMessage.sentAt,
          starred: el.starred,
          lastMessageText: el.lastMessage.message,
          lastSeenUser: el.userLastSeen,
          unreadCount: el.unreadCount
        }
      }).sort((a: any, b: any) => new Date(b.date).getTime() - new Date(a.date).getTime());
      setAllUsers(all)
      setStarredUser(all.filter((el:any) => el.starred == true))
      setInboxLoading(false)
    }).catch((e) => {
      setInboxLoading(false)
      console.log(e)
    })
  }

  useEffect(() => {
    setInboxLoading(true)
    fetchInboxes()
  }, [])


  return (
    <div className="md:px-5 px-3 py-3" key={key}>
      <UserInboxBreadcrumbs/>
      <div className="flex flex-row rounded-[8px] sm:rounded-[4px]  filter-tab-container overflow-hidden min-h-[calc(95vh-116px)] sm:min-h-[calc(95vh-130px)]">
        <UserInboxChatList AllUsers={AllUsers} StarredUser={StarredUser} inboxLoading={inboxLoading} />
        <UserInboxChatBox AllUsers={AllUsers} StarredUser={StarredUser} fetchInboxes={fetchInboxes} />
      </div>
    </div>
  );
};

export default UserInbox;
