import React, { useEffect, useState } from "react";
import { SelectInput } from "../Input/SelectInput";
import { useIntl } from "react-intl";
import { getLocation, state } from "../../../onboarding/core/_requests";
import { StateModel } from "../../../onboarding";
import {get, map} from "lodash";
import FormikReactSelect from "../Input/FormikReactSelect";

export const State = ({
  countryId,
  formik,
  initialValues,
  tooltipText,
  fieldName = 'state'
}: {
  initialValues: any;
  countryId: string | undefined;
  formik: any;
  tooltipText: string;
  fieldName?: string
}) => {
  const [stateOptions, setstateOptions] = useState<any[]>([]);

  useEffect(() => {
    const fetchState = async () => {
      try {
        if (!countryId) {
          return;
        }
        const [
          ipStackData,
          {
            data: { data: stateApi, success },
          },
        ] = await Promise.all([getLocation(), state(countryId!)]);
        if (success) {
          const stateData = stateApi.map((state: any) => {
            return {
              id: state.stateId,
              name: state.state_name,
              value: state.stateId,
            };
          });
          setstateOptions([...stateData]);
          if(!initialValues[fieldName]){
            const state = stateApi.find(
              (state: StateModel) =>
                state.state_name === ipStackData.data.region_name
            );
            if (state) {
              initialValues[fieldName] = state.stateId;
            }
          }
        }
      } catch (err) {
        const {
          data: { success, data: states },
        } = await state(countryId!);
        if (success) {
          const stateData = states?.map((state: any) => {
            return {
              id: state.stateId,
              name: state.state_name,
              value: state.stateId,
            };
          });
          setstateOptions([...stateData]);
        }
      }
    };
    fetchState();
  }, [countryId]); // eslint-disable-line react-hooks/exhaustive-deps
  const { formatMessage } = useIntl();
  return (
      <FormikReactSelect
          isFieldArray={true}
          name={fieldName}
          formik={formik}
          placeholder={formatMessage({ id: "Select Your State or Province" })}
          label={formatMessage({ id: "State/Province" })}
          tooltipText={tooltipText}
          isStarRequired={true}
          options={map(stateOptions,(item)=>{
            return{
              label:get(item,'name'),
              value:get(item,'value'),
            }
          })}
      />
  );
};
