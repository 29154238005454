import React from "react";
import { useIntl } from "react-intl";
import LeaderboardBannerImg from "../../../../../assets/images/marketplace/leaderboard-banner.png";
const Index = () => {
  const { formatMessage } = useIntl();

  return (
    <div>
      <div className="bg-[#171825] rounded-[14px] flex flex-col lg:flex-row mt-[32px] justify-between lg:items-end">
        <div className="max-w-[578px] xl:min-h-[230px] px-[16px] py-[20px] md:p-[36px_32px_16px_32px]">
          <h1 className="!text-[24px] md:!text-[32px] leading-[32px] md:leading-[44px] font-weight-600 text-[#ffffff]">
            {formatMessage({ id: "Trending & Top AI Personalities" })}
          </h1>
          <p className="text-[13px] leading-[20px] text-[#FFFFFFCC]">
            {formatMessage({
              id: "Explore and interact with trending and top-rated AI personalities and characters across various categories and countries. Immerse yourself in a world of dynamic AI interactions!",
            })}
          </p>
          <button
            className={
              "bg-[#C2D24B] py-4 mt-[14px] px-5 rounded text-black text-[14px] leading-5 font-semibold w-full md:w-auto"
            }
            // onClick={(e) => navigate(`/marketplace/explore`)}
          >
            {formatMessage({ id: "Create Your Own Personality" })}
          </button>
        </div>
        <div className="">
          <img src={LeaderboardBannerImg} alt="" className="w-full"></img>
        </div>
      </div>
    </div>
  );
};

export default Index;
