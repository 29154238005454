import React, { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { InfoCard } from "../../../widgets/components/UI/InfoCard";
import { Swiper, SwiperSlide } from "swiper/react";
import { FreeMode, Navigation, Thumbs } from "swiper";
import AvatarImage from "../../../../assets/images/personality/avatar-image.png";
import ThumnailImage from "../../../../assets/images/personality/avatar-thumnail.png";
import { get, isEqual, map, range } from "lodash";
import clsx from "clsx";
import { men, nonGender, women } from "./avatarList";
import { AvatarCreatorViewer } from "@readyplayerme/rpm-react-sdk";
import { Avatar as AvatarHalfBody } from "@readyplayerme/visage";
import { updatePersonalityInfo } from "../../../profile/core/_requests";
import { useAuth } from "../../../auth";
import AvatarViewer from "./avatarViewer";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";
import { Form } from "react-bootstrap";
import { DisplayImage } from "../../../widgets/components/General/DisplayImage";
import { FileUpload } from "../../../widgets/components/FileUpload";
import RpmModal from "./rpmModal";
import useGTM from "../../../../hooks/useGTM";
import { GTMEvent } from "../../../../hooks/gtm_helpers";

const Avatar: React.FC<any> = ({ setOpenEdit, personalityData }) => {
  const { formatMessage } = useIntl();
  const { dataLayerPush } = useGTM()
  const [activegender, setActiveGender] = useState<number>(0);
  const [thumbsSwiper, setThumbsSwiper] = useState<any>(null);
  const [avatarUrl, setAvatarUrl] = useState<string>();
  const [showCustomButton, setShowCustomButton] = useState<boolean>(false);
  const [disableSave, setDisableSave] = useState(false);
  const [viewMode, setViewMode] = useState(true);
  const [openInformation, setOpenInformation] = useState<null | number>(0);
  const [isAccordion1Open, setIsAccordion1Open] = useState(false);
  const [isAccordion2Open, setIsAccordion2Open] = useState(false);
  const [hyperImg, setHyperImg] = useState<string>("");
  const [uploadModal, setUploadModal] = useState<boolean>(false);
  const [rpmModalOpen, setRpmModalOpen] = useState<boolean>(false);
  const [brand, setBrand] = useState<string>("KMT");
  const [showCustomAvatar, setShowCustomAvatar] = useState<number>(-1);
  const [enable3d, setEnable3d] = useState<boolean | undefined>(undefined);
  const [enableHyper, setEnableHyper] = useState<boolean | undefined>(
    undefined
  );
  const [key, setKey] = useState<string>("M" + Math.random());

  const handleClose = () => {
    setUploadModal(false);
  };

  const toggleAccordion1 = () => {
    setIsAccordion1Open((prevState) => !prevState);
    // setIsAccordion2Open(false); // Close the other accordion
  };

  const toggleAccordion2 = () => {
    setIsAccordion2Open((prevState) => !prevState);
    // setIsAccordion1Open(false); // Close the other accordion
  };
  const {
    referenceData,
    setSelectedPersonality,
    selectedPersonality: updatedData,
  } = useAuth();
  const [initSlide, setInitSlide] = useState<any>(null);

  personalityData = updatedData || personalityData;

  const avatarData = personalityData?.personalityJson?.avatar || {};


  useEffect(() => {
    if (avatarData?.file) {
      setAvatarUrl(avatarData?.file);
      let found = false;
      if (men.find((el) => el.glbUrl == avatarData?.file)) {
        men.map((el, i) => {
          if (el.glbUrl == avatarData?.file) {
            setActiveGender(0);
            setInitSlide(i);
            found = true;
          }
        });
      }
      if (women.find((el) => el.glbUrl == avatarData?.file)) {
        women.map((el, i) => {
          if (el.glbUrl == avatarData?.file) {
            setActiveGender(1);
            setInitSlide(i);
            found = true;
          }
        });
      }
      if (nonGender.find((el) => el.glbUrl == avatarData?.file)) {
        nonGender.map((el, i) => {
          if (el.glbUrl == avatarData?.file) {
            setActiveGender(2);
            setInitSlide(i);
            found = true;
          }
        });
      }
      if (!found) {
        // console.log(avatarData)
        let gender =
          avatarData?.gender == "Male"
            ? 0
            : avatarData?.gender == "Female"
              ? 1
              : 2;
        if (avatarData?.file) {
          // modify relative const
          [men, women, nonGender][gender][
            [men, women, nonGender][gender].length - 1
          ]["glbUrl"] = avatarData?.file;
        }
        setActiveGender(gender);
        setInitSlide(men.length - 1);
        setShowCustomButton(true);
      }
    } else {
      setActiveGender(0);
      setInitSlide(0);
    }
  }, []);

  const onSubmit = async () => {
    // save data to api
    setDisableSave(true);
    try {
      let {
        data: { success, data },
      } = await updatePersonalityInfo({
        personalityId: personalityData.personalityId,
        personalityJson: {
          ...personalityData.personalityJson,
          avatar: {
            enabled: enable3d,
            file: avatarUrl,
            brand: brand,
            format: "glb",
            gender:
              activegender == 0
                ? "Male"
                : activegender == 1
                  ? "Female"
                  : "Non Gender",
          },
          hyper_realistic_avatar: {
            enabled: enableHyper,
            image: hyperImg,
          },
          // : { avatar: {} }),
        },
      });

      if (success) {
        setSelectedPersonality(data);
        setDisableSave(false);
        toast.success(
          formatMessage({ id: "Avatar details updated successfully" })
        );
        dataLayerPush(GTMEvent.AIPersonalityEditSuccessful, { "tab": "avatar-visuals" })
      } else {
        setDisableSave(false);
        toast.error(
          formatMessage({ id: "Error while updating avatar" }),
          {
            autoClose: false,
            closeButton: true, // Display close button (cross icon)
          }
        );
        dataLayerPush(GTMEvent.AIPersonalityEditFailed, { "tab": "avatar-visuals" })

      }
    } catch (e) {
      dataLayerPush(GTMEvent.AIPersonalityEditFailed, { "tab": "avatar-visuals" })
      toast.error(formatMessage({ id: "Error when updating Avatar details" }),
        {
          autoClose: false,
          closeButton: true, // Display close button (cross icon)
        });
      setDisableSave(false);
    }
  };

  useEffect(() => {
    let avatar = personalityData?.personalityJson?.avatar;
    let avatarReal = personalityData?.personalityJson?.hyper_realistic_avatar;
    setHyperImg(avatarReal?.image || personalityData?.image);
    setEnable3d(avatar?.enabled || false);
    setEnableHyper(avatarReal?.enabled || false);
  }, [personalityData]);

  useEffect(() => {
    if (avatarUrl && brand && brand === "RPM") {
      // [men,women,nonGender][activegender][[men,women,nonGender][activegender].length - 1].glbUrl = avatarUrl
      setShowCustomAvatar(activegender);
    }
  }, [avatarUrl, brand]);




  if (initSlide === null) {
    return <></>;
  }

  if (enable3d === undefined || enableHyper === undefined) {
    return <></>;
  }
  return (
    <div className={"grid grid-cols-12 gap-y-[20px] md:gap-x-[40px]"} key={key}>
      <div className="col-span-12 md:col-span-7">
        <div className="">
          <div
            className={
              "container-field rounded p-[16px_14px] md:p-[16px] mb-[24px]"
            }
          >
            <div
              className={clsx("flex justify-between items-center gap-[8px]")}
            >
              <div className="flex items-start gap-x-[8px]">
                {/* <Switch
                    onChange={(value) => {
                      if (value) {
                        setOpenInformation(index);
                      }
                      formik.setFieldValue(get(item, "value"), value);
                    }}
                    name={"verification"}
                    handleDiameter={22}
                    borderRadius={22}
                    activeBoxShadow={"null"}
                    checked={get(formik.values, get(item, "value"))}
                    checkedIcon={false}
                    uncheckedIcon={false}
                    offHandleColor={"#fff"}
                    width={45}
                    height={30}
                    onColor={"#C2D24B"}
                    offColor={"#474761"}
                  /> */}

                <Form.Check
                  checked={enable3d}
                  className="edit-personality-avatar-switch" // prettier-ignore
                  type="switch"
                  id="custom-switch"
                  onChange={(e) => setEnable3d(!enable3d)}
                />
                <div className="d-flex flex-column justify-content-end">
                  <div className="d-flex">
                    <div>
                      <label
                        className="text-[13px] leading-5 primary-text font-semibold"
                        data-bs-toggle="tooltip"
                        data-kt-initialized="1"
                      >
                        {formatMessage({ id: "Enable 3D Avatar" })}
                      </label>
                    </div>
                  </div>
                  <label className="text-[12px] leading-4 secondary-text mt-2">
                    {formatMessage({
                      id: "Select a Predefined 3D Avatar or Choose a Custom Avatar",
                    })}
                  </label>
                </div>
              </div>
              <button
                className={"p-[5px] rounded secondary-bg"}
                type={"button"}
                onClick={toggleAccordion1}
              >
                <svg
                  className={`${isAccordion1Open ? "rotate-90" : ""}`}
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g clipPath="url(#clip0_497_17198)">
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M15.4839 12.7562C15.6693 12.3959 15.6346 11.933 15.3687 11.6132L9.96867 5.11843C9.63466 4.71671 9.06498 4.69111 8.69627 5.06124C8.32755 5.43138 8.29942 6.05709 8.63342 6.45881L13.4508 12.2528L8.68039 17.5129C8.32969 17.8996 8.33079 18.5256 8.68284 18.9111C9.03488 19.2966 9.60458 19.2956 9.95527 18.9089L15.3337 12.9783C15.3948 12.9109 15.4449 12.836 15.4839 12.7562Z"
                      fill="#8898A6"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_497_17198">
                      <rect width="24" height="24" fill="white" />
                    </clipPath>
                  </defs>
                </svg>
              </button>
            </div>

            <div className={`pt-[20px] ${isAccordion1Open ? "" : "hidden"}`}>
              <div
                className={
                  "border border-dashed !border-[#C2D24B40] bg-[#C2D24B1A] rounded px-[8px] py-[12px] mb-[14px] md:mb-[20px]"
                }
              >
                <div className={"flex gap-x-2"}>
                  <div>
                    <svg
                      width="32"
                      height="32"
                      viewBox="0 0 32 32"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M15.9993 29.3327C8.63535 29.3327 2.66602 23.3633 2.66602 15.9993C2.66602 8.63535 8.63535 2.66602 15.9993 2.66602C23.3633 2.66602 29.3327 8.63535 29.3327 15.9993C29.3327 23.3633 23.3633 29.3327 15.9993 29.3327ZM15.9993 26.666C18.8283 26.666 21.5414 25.5422 23.5418 23.5418C25.5422 21.5414 26.666 18.8283 26.666 15.9993C26.666 13.1704 25.5422 10.4573 23.5418 8.45688C21.5414 6.45649 18.8283 5.33268 15.9993 5.33268C13.1704 5.33268 10.4573 6.45649 8.45688 8.45688C6.45649 10.4573 5.33268 13.1704 5.33268 15.9993C5.33268 18.8283 6.45649 21.5414 8.45688 23.5418C10.4573 25.5422 13.1704 26.666 15.9993 26.666ZM14.666 9.33268H17.3327V11.9993H14.666V9.33268ZM14.666 14.666H17.3327V22.666H14.666V14.666Z"
                        fill="#C2D24B"
                        fillOpacity="0.65"
                      />
                    </svg>
                  </div>
                  <div>
                    <p
                      className={
                        "whitespace-break-spaces text-[12px] leading-[18px] primary-text"
                      }
                    >
                      {formatMessage({
                        id: "Kamoto.AI integrates with the ReadyPlayer.Me Avatar Service to utilize 3D avatars, which can also be employed as NPCs in video games and within metaverse applications. UsersTable have the option to choose from pre-defined avatars categorized by gender, or to personalize their experience by selecting the custom option. To import a personalized custom avatar, users must log into their readyplayer.me account.",
                      })}
                      <br></br> <br></br>
                      {formatMessage({
                        id: "Should both 3D and 2D avatar options be enabled, users will have the flexibility to switch between these avatars, enhancing their interactive experience.",
                      })}
                    </p>
                    <div className={"flex justify-end mt-[8px]"}>
                      <a
                        href={
                          "https://help.kamoto.ai/en/articles/8290668-how-you-can-create-and-enable-3d-avatar-for-your-ai-personality"
                        }
                        target="_blank"
                        rel="noreferrer"
                        className={
                          "flex  gap-x-1 items-center text-[12px] leading-[18px] main-text"
                        }
                      >
                        {formatMessage({ id: "Learn More" })}

                        <svg
                          width="16"
                          height="16"
                          viewBox="0 0 16 16"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            opacity="0.3"
                            d="M4.00053 7.33317C3.63234 7.33317 3.33386 7.63165 3.33386 7.99984C3.33386 8.36803 3.63234 8.6665 4.00053 8.6665H12.0005C12.3687 8.6665 12.6672 8.36803 12.6672 7.99984C12.6672 7.63165 12.3687 7.33317 12.0005 7.33317H4.00053Z"
                            fill="#C2D24B"
                          />
                          <path
                            d="M7.52807 11.5284C7.26772 11.7888 7.26772 12.2109 7.52807 12.4712C7.78842 12.7316 8.21053 12.7316 8.47088 12.4712L12.4709 8.47124C12.7233 8.21886 12.7321 7.81247 12.4909 7.54935L8.82424 3.54936C8.57545 3.27794 8.15373 3.25961 7.88232 3.5084C7.61091 3.7572 7.59257 4.17891 7.84137 4.45032L11.0767 7.97979L7.52807 11.5284Z"
                            fill="#C2D24B"
                          />
                        </svg>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className={clsx(
                  " tab-container-2 rounded-[4px] p-[14px] md:pt-[20px] md:pb-[32px] md:px-[24px] ",
                  !viewMode && "h-[700px]"
                )}
              >
                <h3
                  className={clsx(
                    "text-[16px] leading-[24px] primary-text font-medium mb-[24px] md:mb-[46px]",
                    !viewMode && "hidden"
                  )}
                >
                  {formatMessage({
                    id: "Select a Predefined Avatar or Create a Custom Avatar",
                  })}
                </h3>
                {
                  // avatarData?.file
                  // ?
                  // <Exhibit
                  //   modelSrc={'https://models.readyplayer.me/6491ca97cbaee4f11cfb6200.glb'}
                  // />
                  // :
                  // viewMode ?
                  //   <>
                  //     <h3
                  //       className={
                  //         "text-[16px] leading-6 text-[#FFFFFFCC] font-medium mb-[46px]"
                  //       }
                  //     >
                  //       Avatar of this AI Personality
                  //     </h3>
                  //     {/* <div>
                  //       URL:
                  //       <a href={avatarUrl}>
                  //         &nbsp;{avatarUrl}&nbsp;
                  //       </a>
                  //     </div>
                  //     <div className="mt-3">
                  //       Download your avatar by clicking above url
                  //     </div> */}
                  //     <AvatarViewer src={avatarUrl} />
                  //     <button
                  //       type="button"
                  //       className={
                  //         `bg-[#C2D24B] mt-3 text-black text-[14px] leading-5 font-medium py-[12px] w-1/2  md:w-[140px] rounded`
                  //       }
                  //       onClick={(e) => {setViewMode(false)}}
                  //     >
                  //       Change Avatar
                  //     </button>
                  //   </>
                  //   :
                  //   <AvatarCreatorViewer
                  //     subdomain={process.env.REACT_APP_RPM_SUBDOMAIN || 'demo'}
                  //     onAvatarExported={(url) => { setAvatarUrl(url);setViewMode(true) }}
                  //   />
                }
                {viewMode ? (
                  <>
                    <div
                      className={
                        "flex flex-wrap  md:flex-nowrap justify-center   align-items-center gap-[8px] "
                      }
                    >
                      <button
                        className={clsx(
                          "flex items-center gap-2 border py-2 px-4 rounded bg-secondary h-[40px] flex-grow min-w-1/3 max-w-[160px]   justify-content-center",
                          isEqual(activegender, 0) &&
                          "!border-[#C2D24B] active-avatar-gender-tab"
                        )}
                        onClick={() => setActiveGender(0)}
                      >
                        <svg
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M9.33333 9.33333C10.48 9.33333 11.5556 9.69778 12.4178 10.32L16.96 5.77778H12.8889V4L20 4V11.1111H18.2222V7.03111L13.68 11.5556C14.3022 12.4444 14.6667 13.5111 14.6667 14.6667C14.6667 16.0812 14.1048 17.4377 13.1046 18.4379C12.1044 19.4381 10.7478 20 9.33333 20C7.91885 20 6.56229 19.4381 5.5621 18.4379C4.5619 17.4377 4 16.0812 4 14.6667C4 13.2522 4.5619 11.8956 5.5621 10.8954C6.56229 9.89524 7.91885 9.33333 9.33333 9.33333ZM9.33333 11.1111C8.39034 11.1111 7.48597 11.4857 6.81918 12.1525C6.15238 12.8193 5.77778 13.7237 5.77778 14.6667C5.77778 15.6097 6.15238 16.514 6.81918 17.1808C7.48597 17.8476 8.39034 18.2222 9.33333 18.2222C10.2763 18.2222 11.1807 17.8476 11.8475 17.1808C12.5143 16.514 12.8889 15.6097 12.8889 14.6667C12.8889 13.7237 12.5143 12.8193 11.8475 12.1525C11.1807 11.4857 10.2763 11.1111 9.33333 11.1111Z"
                            className={"icon"}
                          />
                        </svg>
                        {formatMessage({
                          id: "Male",
                        })}
                      </button>
                      <button
                        className={clsx(
                          "flex items-center gap-2 border py-2 px-4 rounded bg-secondary h-[40px] min-w-1/3 flex-grow max-w-[160px] justify-content-center",
                          isEqual(activegender, 1) && "!border-[#C2D24B] active-avatar-gender-tab"
                        )}
                        onClick={() => setActiveGender(1)}
                      >
                        <svg
                          width="10"
                          height="16"
                          viewBox="0 0 10 16"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M5 0.5C6.32608 0.5 7.59785 1.02678 8.53553 1.96447C9.47322 2.90215 10 4.17392 10 5.5C10 7.975 8.2 10.0333 5.83333 10.4333V12.1667H7.5V13.8333H5.83333L5.83333 15.5L4.16667 15.5L4.16667 13.8333H2.5L2.5 12.1667H4.16667V10.4333C1.8 10.0333 0 7.975 0 5.5C0 4.17392 0.526784 2.90215 1.46447 1.96447C2.40215 1.02678 3.67392 0.5 5 0.5ZM5 2.16667C4.11594 2.16667 3.2681 2.51786 2.64298 3.14298C2.01786 3.7681 1.66667 4.61594 1.66667 5.5C1.66667 6.38405 2.01786 7.2319 2.64298 7.85702C3.2681 8.48214 4.11594 8.83333 5 8.83333C5.88405 8.83333 6.7319 8.48214 7.35702 7.85702C7.98214 7.2319 8.33333 6.38405 8.33333 5.5C8.33333 4.61594 7.98214 3.7681 7.35702 3.14298C6.7319 2.51786 5.88405 2.16667 5 2.16667Z"
                            className={"icon"}
                          />
                        </svg>
                        {formatMessage({
                          id: "Female",
                        })}
                      </button>
                      <button
                        className={clsx(
                          "flex items-center gap-2 border py-2 px-4 rounded bg-secondary h-[40px] min-w-1/3 flex-grow xl:max-w-[160px] justify-content-center",
                          isEqual(activegender, 2) && "!border-[#C2D24B] active-avatar-gender-tab "
                        )}
                        onClick={() => setActiveGender(2)}
                      >
                        <svg
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M12 4.5C13.3261 4.5 14.5979 5.02678 15.5355 5.96447C16.4732 6.90215 17 8.17392 17 9.5C17 11.975 15.2 14.0333 12.8333 14.4333V16.1667H14.5V17.8333H12.8333L12.8333 19.5H11.1667L11.1667 17.8333H9.5V16.1667H11.1667V14.4333C8.8 14.0333 7 11.975 7 9.5C7 8.17392 7.52678 6.90215 8.46447 5.96447C9.40215 5.02678 10.6739 4.5 12 4.5ZM12 6.16667C11.1159 6.16667 10.2681 6.51786 9.64298 7.14298C9.01786 7.7681 8.66667 8.61594 8.66667 9.5C8.66667 10.3841 9.01786 11.2319 9.64298 11.857C10.2681 12.4821 11.1159 12.8333 12 12.8333C12.8841 12.8333 13.7319 12.4821 14.357 11.857C14.9821 11.2319 15.3333 10.3841 15.3333 9.5C15.3333 8.61594 14.9821 7.7681 14.357 7.14298C13.7319 6.51786 12.8841 6.16667 12 6.16667Z"
                            className={"icon"}
                          />
                        </svg>
                        {formatMessage({
                          id: "Non-Gender",
                        })}
                      </button>
                    </div>
                    <div className={"max-w-xl mx-auto"}>
                      <Swiper
                        initialSlide={initSlide}
                        spaceBetween={10}
                        navigation={true}
                        thumbs={{ swiper: thumbsSwiper }}
                        loop={true}
                        centeredSlides={true}
                        modules={[FreeMode, Navigation, Thumbs]}
                        className="mt-5 avatar-swiper"
                        onSlideChange={(swiper) => {
                          if (swiper.realIndex == swiper.slides.length - 1) {
                            setShowCustomButton(true);
                            if (
                              avatarData?.file &&
                              avatarData?.brand == "RPM" &&
                              ["Male", "Female", "Non Gender"].indexOf(
                                avatarData?.gender || "Male"
                              ) == activegender
                            ) {
                              setAvatarUrl(avatarData?.file);
                            } else {
                              setAvatarUrl(undefined);
                            }
                            setBrand("RPM");
                          } else {
                            setBrand("KMT");
                            setShowCustomButton(false);
                            setAvatarUrl(
                              (isEqual(activegender, 0)
                                ? men
                                : isEqual(activegender, 1)
                                  ? women
                                  : nonGender)[swiper.realIndex]["glbUrl"]
                            );
                          }
                        }}
                      >
                        {map(
                          isEqual(activegender, 0)
                            ? men
                            : isEqual(activegender, 1)
                              ? women
                              : nonGender,
                          (item) => {
                            console.log(item, 'Sadadsfasdfgas')
                            return <SwiperSlide
                              key={get(item, "name")}
                            >
                              <div className={'flex flex-col gap-y-8 items-center'}>
                                <div className={
                                  "flex justify-center w-full items-center"
                                }>
                                  {get(item, "glbUrl") ? (
                                    <AvatarViewer src={get(item, "glbUrl")} />
                                  ) : // <AvatarHalfBody style={{pointerEvents: "none"}} modelSrc={get(item,"glbUrl")} halfBody={false} />
                                    showCustomAvatar == activegender ? (
                                      <AvatarViewer src={avatarUrl} />
                                    ) : (
                                      <img
                                        src={get(item, "image")}
                                        className={"h-[180px]"}
                                      />
                                    )}
                                </div>
                                {isEqual(get(item, 'name'), 'Custom') && get(item, 'glbUrl') && <button
                                  className={
                                    "bg-[#C2D24B] py-[12px] px-[24px] rounded w-[200px] text-black"
                                  }
                                  onClick={() => setRpmModalOpen(true)}
                                >
                                  {formatMessage({
                                    id: "Customize Avatar",
                                  })}
                                </button>}
                                {isEqual(get(item, 'name'), 'Custom') && !get(item, 'glbUrl') && <button
                                  className={
                                    "bg-[#C2D24B] py-[12px] px-[24px] rounded w-[200px] text-black"
                                  }
                                  onClick={() => setRpmModalOpen(true)}
                                >
                                  {formatMessage({
                                    id: "Select Custom Avatar",
                                  })}
                                </button>}
                              </div>
                            </SwiperSlide>
                          })}
                      </Swiper>
                      <div
                        className={"flex justify-center mt-[20px] mb-[36px]"}
                      >
                        {/*{isEqual(men.length - 1 , initSlide) &&  <button*/}
                        {/*    className={*/}
                        {/*      "bg-[#C2D24B] py-[12px] px-[24px] rounded w-[200px] text-black"*/}
                        {/*    }*/}
                        {/*    onClick={() => setRpmModalOpen(true)}*/}
                        {/*>*/}
                        {/*  {formatMessage({*/}
                        {/*    id: "Select Custom Avatar",*/}
                        {/*  })}*/}
                        {/*</button>}*/}
                        {
                          // showCustomButton && (
                          //   <button
                          //     className={
                          //       "bg-[#C2D24B] py-[12px] px-[24px] rounded w-[200px] text-black"
                          //     }
                          //     onClick={() => setRpmModalOpen(true)}
                          //   >
                          //     {formatMessage({
                          //       id: "Select Custom Avatar",
                          //     })}
                          //   </button>
                          // )
                          // : (
                          //   <button
                          //     className={
                          //       "bg-[#C2D24B] py-[12px] px-[24px] rounded w-[170px] text-black"
                          //     }
                          //   >
                          //     {formatMessage({
                          //       id: "Select",
                          //     })}
                          //   </button>
                          // )
                        }
                      </div>
                      <Swiper
                        onSwiper={setThumbsSwiper}
                        breakpoints={{
                          320: {
                            slidesPerView: 3,
                            spaceBetween: 20,
                          },
                          768: {
                            slidesPerView: 4,
                            spaceBetween: 20,
                          },

                          1024: {
                            slidesPerView: 5,
                            spaceBetween: 20,
                          },
                          1200: {
                            slidesPerView: 6,
                            spaceBetween: 10,
                          },
                        }}
                        freeMode={true}
                        watchSlidesProgress={true}
                        modules={[FreeMode, Navigation, Thumbs]}
                        className="avatar-swiper-thumbnail"
                      >
                        {map(
                          isEqual(activegender, 0)
                            ? men
                            : isEqual(activegender, 1)
                              ? women
                              : nonGender,
                          (item, index) => (
                            <div key={index}>
                              <SwiperSlide>
                                <div
                                  className={
                                    "text-center flex flex-col justify-center items-center"
                                  }
                                >
                                  <div
                                    className={
                                      "rounded border border-[#363738] mb-1 flex justify-center items-center  w-[60px] h-[60px]"
                                    }
                                  >
                                    <img
                                      alt=""
                                      src={get(item, "thumbImage")}
                                      className={clsx(
                                        "",
                                        isEqual(index, 0) && "",
                                        isEqual(men.length - 1, index) &&
                                        "!w-[45px] !h-[30px]"
                                      )}
                                    />
                                  </div>
                                  <p
                                    className={
                                      "text-[12px] text-normal leading-[18px]"
                                    }
                                  >
                                    {get(item, "name")}
                                  </p>
                                </div>
                              </SwiperSlide>
                            </div>
                          )
                        )}
                      </Swiper>
                    </div>
                  </>
                ) : (
                  <AvatarCreatorViewer
                    subdomain={process.env.REACT_APP_RPM_SUBDOMAIN || "demo"}
                    onAvatarExported={(url) => {
                      setAvatarUrl(url);
                      // setViewMode(true);
                      // setKey('M'+Math.random())
                    }}
                    avatarConfig={{ morphTargets: ["Oculus Visemes"] }}
                  />
                )}
              </div>
            </div>
          </div>
          <div
            className={
              "container-field rounded p-[16px_14px] md:p-[16px] mb-[24px]"
            }
          >
            <div
              className={clsx("flex justify-between items-center gap-[8px]")}
            >
              <div className="flex items-start gap-x-[8px]">
                {/* <Switch
                    onChange={(value) => {
                      if (value) {
                        setOpenInformation(index);
                      }
                      formik.setFieldValue(get(item, "value"), value);
                    }}
                    name={"verification"}
                    handleDiameter={22}
                    borderRadius={22}
                    activeBoxShadow={"null"}
                    checked={get(formik.values, get(item, "value"))}
                    checkedIcon={false}
                    uncheckedIcon={false}
                    offHandleColor={"#fff"}
                    width={45}
                    height={30}
                    onColor={"#C2D24B"}
                    offColor={"#474761"}
                  /> */}

                <Form.Check
                  className="edit-personality-avatar-switch" // prettier-ignore
                  type="switch"
                  id="custom-switch"
                  checked={enableHyper}
                  onChange={(e) => setEnableHyper(!enableHyper)}
                />
                <div className="d-flex flex-column justify-content-end">
                  <div className="d-flex">
                    <div>
                      <label
                        className="text-[13px] leading-5 primary-text font-semibold"
                        data-bs-toggle="tooltip"
                        data-kt-initialized="1"
                      >
                        {formatMessage({
                          id: "Enable Hyper-Realistic (Image) Avatar",
                        })}
                      </label>
                    </div>
                  </div>
                  <label className="text-[12px] leading-4 secondary-text mt-2">
                    {formatMessage({
                      id: "Enable the visually rich image-based avatar that looks very realistic",
                    })}
                  </label>
                </div>
              </div>
              <button
                className={"p-[5px] rounded secondary-bg"}
                type={"button"}
                onClick={toggleAccordion2}
              >
                <svg
                  className={`${isAccordion2Open ? "rotate-90" : ""}`}
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g clipPath="url(#clip0_497_17198)">
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M15.4839 12.7562C15.6693 12.3959 15.6346 11.933 15.3687 11.6132L9.96867 5.11843C9.63466 4.71671 9.06498 4.69111 8.69627 5.06124C8.32755 5.43138 8.29942 6.05709 8.63342 6.45881L13.4508 12.2528L8.68039 17.5129C8.32969 17.8996 8.33079 18.5256 8.68284 18.9111C9.03488 19.2966 9.60458 19.2956 9.95527 18.9089L15.3337 12.9783C15.3948 12.9109 15.4449 12.836 15.4839 12.7562Z"
                      fill="#8898A6"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_497_17198">
                      <rect width="24" height="24" fill="white" />
                    </clipPath>
                  </defs>
                </svg>
              </button>
            </div>

            <div className={`pt-[20px] ${isAccordion2Open ? "" : "hidden"}`}>
              <div
                className={
                  "border border-dashed !border-[#C2D24B40] bg-[#C2D24B1A] rounded px-[8px] py-[12px] mb-[14px] md:mb-[20px]"
                }
              >
                <div className={"flex gap-x-2"}>
                  <div>
                    <svg
                      width="32"
                      height="32"
                      viewBox="0 0 32 32"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M15.9993 29.3327C8.63535 29.3327 2.66602 23.3633 2.66602 15.9993C2.66602 8.63535 8.63535 2.66602 15.9993 2.66602C23.3633 2.66602 29.3327 8.63535 29.3327 15.9993C29.3327 23.3633 23.3633 29.3327 15.9993 29.3327ZM15.9993 26.666C18.8283 26.666 21.5414 25.5422 23.5418 23.5418C25.5422 21.5414 26.666 18.8283 26.666 15.9993C26.666 13.1704 25.5422 10.4573 23.5418 8.45688C21.5414 6.45649 18.8283 5.33268 15.9993 5.33268C13.1704 5.33268 10.4573 6.45649 8.45688 8.45688C6.45649 10.4573 5.33268 13.1704 5.33268 15.9993C5.33268 18.8283 6.45649 21.5414 8.45688 23.5418C10.4573 25.5422 13.1704 26.666 15.9993 26.666ZM14.666 9.33268H17.3327V11.9993H14.666V9.33268ZM14.666 14.666H17.3327V22.666H14.666V14.666Z"
                        fill="#C2D24B"
                        fillOpacity="0.65"
                      />
                    </svg>
                  </div>
                  <div>
                    <p
                      className={
                        "whitespace-break-spaces text-[12px] leading-[18px] primary-text"
                      }
                    >
                      {formatMessage({
                        id: "Kamoto.AI uses the image provided below to create the idle avatar animation for the AI personality, as well as for the talking head that appears when the AI personality generates a response. This visualization will only be displayed if the user has activated the Avatar button, and it has been enabled from the corresponding setting.",
                      })}
                      <br></br> <br></br>
                      {formatMessage({
                        id: "If both 3D and 2D avatar options are activated, users can effortlessly switch between these avatars, further enriching their interactive experience. To learn more about this feature, please visit our help center.",
                      })}
                    </p>
                    <div className={"flex justify-end"}>
                      <a
                        href={
                          "https://help.kamoto.ai/en/articles/8290683-how-to-create-and-enable-a-hyper-realistic-avatar-for-your-ai-personality"
                        }
                        target="_blank"
                        rel="noreferrer"
                        className={
                          "flex  gap-x-1 items-center text-[12px] leading-[18px] main-text"
                        }
                      >
                        {formatMessage({ id: "Learn More" })}

                        <svg
                          width="16"
                          height="16"
                          viewBox="0 0 16 16"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            opacity="0.3"
                            d="M4.00053 7.33317C3.63234 7.33317 3.33386 7.63165 3.33386 7.99984C3.33386 8.36803 3.63234 8.6665 4.00053 8.6665H12.0005C12.3687 8.6665 12.6672 8.36803 12.6672 7.99984C12.6672 7.63165 12.3687 7.33317 12.0005 7.33317H4.00053Z"
                            fill="#C2D24B"
                          />
                          <path
                            d="M7.52807 11.5284C7.26772 11.7888 7.26772 12.2109 7.52807 12.4712C7.78842 12.7316 8.21053 12.7316 8.47088 12.4712L12.4709 8.47124C12.7233 8.21886 12.7321 7.81247 12.4909 7.54935L8.82424 3.54936C8.57545 3.27794 8.15373 3.25961 7.88232 3.5084C7.61091 3.7572 7.59257 4.17891 7.84137 4.45032L11.0767 7.97979L7.52807 11.5284Z"
                            fill="#C2D24B"
                          />
                        </svg>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div className="">
                <div className={`relative max-w-fit mx-auto`}>
                  <DisplayImage
                    imgName={hyperImg}
                    fit="contain"
                    alt="profile"
                    className={
                      "w-[120px] h-[120px] !object-cover display-image-position  !rounded !overflow-hidden"
                    }
                  />

                  <div
                    className="rounded-full widgets-customize absolute -top-2 -right-3 p-2 shadow-[0px_2px_4px_0px_#0000001A]"
                    onClick={(e) => setUploadModal(true)}
                  >
                    <svg
                      width="16"
                      height="16"
                      viewBox="0 0 16 16"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g clipPath="url(#clip0_344_12864)">
                        <path
                          d="M12.8667 5.94967L10.0333 3.14967L10.9667 2.21634C11.2222 1.96079 11.5362 1.83301 11.9087 1.83301C12.2807 1.83301 12.5944 1.96079 12.85 2.21634L13.7833 3.14967C14.0389 3.40523 14.1722 3.71367 14.1833 4.07501C14.1944 4.4359 14.0722 4.74412 13.8167 4.99967L12.8667 5.94967ZM11.9 6.93301L4.83333 13.9997H2V11.1663L9.06667 4.09967L11.9 6.93301Z"
                          className={"icon"}
                          fillOpacity="0.65"
                        />
                      </g>
                      <defs>
                        <clipPath id="clip0_344_12864">
                          <rect width="16" height="16" className={"icon"} />
                        </clipPath>
                      </defs>
                    </svg>
                  </div>
                </div>
                <p className=" text-[9px] leading-[18px] max-w-[180px] mx-auto text-center secondary-text mt-[12px]">
                  {formatMessage({
                    id: "Use image of square size (ratio 1:1), in png or jpg format only",
                  })}
                </p>
              </div>
            </div>
          </div>
          <div className={"flex justify-end gap-[10px] md:order-1 md:mt-auto"}>
            {/* <button
            onClick={() => setOpenEdit(false)}
            className={
              "main-button-primary-light text-[14px] leading-5 font-medium py-[12px] w-1/2 md:w-[128px] rounded"
            }
          >
            {formatMessage({
              id: "Cancel",
            })}
          </button> */}
            <button
              type="submit"
              className={`main-button text-[14px] leading-5 font-medium py-[12px] w-full sm:w-1/2  md:w-[140px] rounded ${disableSave ? "opacity-75" : ""
                }`}
              disabled={disableSave}
              onClick={onSubmit}
            >
              {formatMessage({
                id: `${disableSave ? "Please Wait" : "Save"}`,
              })}
            </button>
          </div>
        </div>
      </div>

      <div
        className={
          "col-span-12 md:col-span-5 flex flex-col justify-start gap-[20px]"
        }
      >
        <InfoCard
          title={formatMessage({
            id: "Understanding Avatar of AI Personality",
          })}
          desc={
            <>
              {formatMessage({
                id: "Introducing a realm of limitless creativity on Kamoto.AI: our dual-avatar feature. Craft your AI Personality with unmatched precision through two distinct avenues – the immersive 3D Avatar and the Hyper-realistic (Image) Avatar. ",
              })}
              <br></br>
              <br></br>
              {formatMessage({
                id: "Unleash your imagination as you bring your AI Persona to life in stunning detail, opening doors to an entirely new dimension of interaction and engagement. Embrace the power to choose, design, and personify your AI like never before, all within the Kamoto.AI platform. ",
              })}
              <br></br>
              <br></br>
              {formatMessage({
                id: "Choose from predefined avatars or import your own custom avatars, creating a unique and engaging chat experience. Enable or disable avatars during chat sessions for added flexibility.",
              })}
              <br></br>
              <br></br>
            </>
          }
          slug={""}
          isAnchor={
            "https://help.kamoto.ai/en/articles/7952396-customizing-avatars-in-kamoto-ai-enhance-your-ai-personality-s-visual-representation"
          }
        />
      </div>
      <FileUpload
        fileSize={2097152}
        maxFileNumber={1}
        allowType={["image/*", ".jpg", ".jpeg", ".png"]}
        metaData={{ module: "profileimg", isProtected: true }}
        modalStatus={uploadModal}
        handleClose={handleClose}
        handleSuccess={(id: number, name: string) => {
          setHyperImg(name);
          // formik.setFieldValue("image", name);
          // formik.setFieldValue("profileImageId", id);
          dataLayerPush(GTMEvent.PersonalityAvatarImageUploadSuccessful)
        }}
        resourceType="hyper-realistic-avatar-image"
      />
      <RpmModal
        setOpen={setRpmModalOpen}
        open={rpmModalOpen}
        setAvatarUrl={setAvatarUrl}
      />
    </div>
  );
};

export default Avatar;
