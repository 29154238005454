import React from "react";
import { Modal } from "react-bootstrap";
import { useIntl } from "react-intl";

export const ChangePasswordModal = ({
  show,
  handleClose,
  setShowModal,
}: {
  show: boolean;
  handleClose?: () => void;
  setShowModal: (show: boolean) => void;
}) => {
  const { formatMessage } = useIntl();
  return (
    <Modal
      id="kt_modal_create_app"
      tabIndex={-1}
      aria-hidden="true"
      dialogClassName="modal-dialog modal-dialog-centered mw-md-480px"
      show={show}
      onHide={handleClose}
      backdrop={true}
    >
      <div className="modal-body py-lg-10 px-lg-10 d-md-flex flex-md-column align-items-md-center">
        <div className="text-center text-[16px] font-[400]  mb-[14px]   secondary-text">
          {formatMessage({ id: "An email containing a password reset link has been sent to your inbox. Please click on the link to proceed with resetting your password." })}
        </div>
        <button
          className="btn export-button text-[13px] font-[400] !p-[8px_24px]"
          onClick={() => setShowModal(false)}
        >
          {formatMessage({ id: "Okay" })}
        </button>
      </div>
    </Modal>
  );
};
