import React, { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import "../styles/onboarding.scss";
import TextInput from "../../widgets/components/Input/TextInput";
import * as Yup from "yup";
import { Formik, Form } from "formik";
import { InfoCard } from "../../widgets/components/UI/InfoCard";
import { SelectInput } from "../../widgets/components/Input/SelectInput";
import { CustomButton } from "../../widgets/components/UI/CustomButton";
import { useNavigate } from "react-router-dom";
import { createPersonality, getLocation } from "../core/_requests";
import { FileUpload } from "../../widgets/components/FileUpload";

import { useAuth } from "../../auth";
import { industryOptions } from "../../../core/_constants";

import { Country } from "../../widgets/components/General/CountryAlt";
import { State } from "../../widgets/components/General/State";
import { verifyToken } from "../../auth/core/_requests";
import { DisplayImage } from "../../widgets/components/General/DisplayImage";
import { toast } from "react-toastify";
import { Toaster } from "../../widgets/components/General/Toaster";
import { BillingModal, PlanData } from "./BillingModal";
import useGTM from "../../../hooks/useGTM";
import { GTMEvent } from "../../../hooks/gtm_helpers";

const initialValues = {
  personalityName: "",
  industry: "",
  country: "",
  state: "",
};

export const CompanyDetails = () => {
  const { formatMessage } = useIntl();
  const [modelStatus, setModelStatus] = useState<boolean>(false);
  const [loading, setLoading] = useState(false);
  const [countryId, setCountryId] = useState<string | undefined>();
  const [imgName, setImgName] = useState<string | undefined>();
  const [modalShow, setModalShow] = useState(false);
  const [currentState, setCurrentState] = useState<string>("Monthly");
  const [selected, setSelected] = useState("Free");
  const [currencyBill, setCurrencyBill] = useState("USD");
  const [price, setPrice] = useState("0");
  const navigate = useNavigate();
  const { dataLayerPush } = useGTM();

  const {
    referenceData,
    setCurrentUser,
    onboardingData,
    setOnboardingData,
    setNewPersonality,
    newPersonality,
    currentUser,
    billingPlans: plans,
    ipStackCountry
  } = useAuth();

  const personalityValidationSchema = Yup.object().shape({
    personalityName: Yup.string()
      .min(3, formatMessage({ id: "Minimum 3 characters" }))
      .max(50, formatMessage({ id: "Maximum 50 characters" }))
      .required(formatMessage({ id: "Personality name is required" })),
    industry: Yup.string().required(
      formatMessage({ id: "Industry is required" })
    ),
    country: Yup.string().required(
      formatMessage({ id: "Country is required" })
    ),
    state: Yup.string().required(
      formatMessage({ id: "State/Province is required" })
    ),
  });
  useEffect(() => {
    const billPlan = localStorage.getItem("plan");
    const plan: any = billPlan?.split("-");
    const parts = plan.splice(-2)
    if (plan) {
        setSelected(plan.join('-'));
      if (parts[0]) {
        setCurrencyBill(parts[0]);
      }
      if (parts[1]) {
        setCurrentState(parts[1]);
      }
      if (plan.length && parts.length) {
        const data: PlanData = plans.find(
          (p: PlanData) => p.chargebee_id === billPlan
        );
        setPrice((data.plan.price / 100).toString());
      }
    } else {
      const getCurrencyCode = async () => {
        try {
          const {
            data: {
              currency: { code },
            },
          } = await getLocation();
          if (code === "INR") {
            setCurrencyBill(code);
          }
        } catch (err) {
          console.log(err);
        }
      };
      getCurrencyCode();
    }
    // check if onboardingData is not null and step is 2
    if (onboardingData && onboardingData.step === 2) {
      navigate("/onboarding/create-ai-personality");
    }
  }, []);

  useEffect(() => {
    if(referenceData && ipStackCountry && currentUser && referenceData?.platformConfig?.EXPRESS_ONBOARDING){
      let onboardData = {
        personalityName: `${currentUser?.firstName} ${currentUser?.lastName || ''} AI Personality`,
        industry: '',
        country: ipStackCountry.countryId,
        chargebeePlanId: ipStackCountry.country_code == 'IN' ? 'Free-INR-Monthly' : 'Free-USD-Monthly',
        category: 'Unknown',
        description: `Hi i am ${currentUser?.firstName} ${currentUser?.lastName || ''}. Please provider your short description to update your personality.`,
        hearAbout: 'Other'
      }
      navigate('/onboarding/create-ai-personality',{state: {onboardData}})
      // return;
    }
  },[referenceData,ipStackCountry,currentUser])

  const onSubmit = async (values: any) => {
    try {
      setLoading(true);
      // const chargebeePlanId = `${selected}-${currencyBill}-${currentState}`;
      const chargebeePlanId =
        selected == "Free"
          ? `${selected}-${currencyBill}-${currentState}`
          : selected;
      setOnboardingData({
        ...values,
        chargebeePlanId: chargebeePlanId,
        step: 2,
      });
      // save details in localstorage and apply api call on next page
      dataLayerPush(GTMEvent.NewPersonalityCreationInitiatedSuccessful);
      navigate("/onboarding/create-ai-personality");
    } catch (e) {
      dataLayerPush(GTMEvent.NewPersonalityCreationInitiatedFailed);
    }
  };

  const handleOpen = () => {
    setModelStatus(true);
  };
  const handleClose = () => {
    setModelStatus(false);
  };

  const cancelPersonalityAdd = () => {
    setNewPersonality(undefined);
    setOnboardingData(undefined);
    navigate("/dashboard");
  };

  return (
    <>
      <Toaster />
      <Formik
        validationSchema={personalityValidationSchema}
        initialValues={onboardingData || initialValues}
        enableReinitialize={true}
        onSubmit={onSubmit}
        validateOnMount
      >
        {(formik) => {
          setCountryId(formik.values.country);
          return (
            <Form className="grid grid-cols-12 md:gap-[44px] gap-y-[16px]">
              <div className="col-span-12 md:col-span-6">
                <h1 className="text-[22px] leading-[32px] primary-text font-semibold mb-[8px]">
                  {formatMessage({ id: "AI Personality Details" })}
                </h1>
                <div className="text-[16px] leading-[20px] secondary-text font-semibold ">
                  {formatMessage({
                    id: "Please enter your AI Personality name",
                  })}
                </div>
                <div className="mt-[28px]">
                  <TextInput
                    fieldType={"text"}
                    label={formatMessage({ id: "AI Personality name*" })}
                    fieldName={"personalityName"}
                    placeholder={formatMessage({ id: "My AI Personality" })}
                    formik={formik}
                    margin="me-4"
                    toolTipText={formatMessage({
                      id: "GLOBAL.TOOLTIP.COMPANY_DETAILS.COMPANY_NAME",
                    })}
                  />
                  {/* file upload starts */}

                  <div
                    className={`flex justify-center md:justify-start`}
                    onClick={handleOpen}
                  >
                    {/* <DisplayImage
                      imgName={imgName}
                      className={"w-[125px] h-[125px] rounded shadow"}
                      fit="contain"
                      alt="profile"
                    /> */}
                    {/*<div className='pencil-containers'>*/}
                    {/*  <img src={toAbsoluteUrl('/media/icons/duotune/general/pencil.svg')} alt='' />*/}
                    {/*</div>*/}
                  </div>
                  {/* <FileUpload
                    fileSize={2097152}
                    maxFileNumber={1}
                    allowType={["image/*", ".jpg", ".jpeg", ".png"]}
                    metaData={{ module: "logo", isProtected: true }}
                    modalStatus={modelStatus}
                    handleClose={handleClose}
                    handleSuccess={(id: number, name: string) => {
                      setImgName(name);
                      formik.setFieldValue("logoId", id);
                    }}
                  />
                  <div className="hidden mt-3 text-center text-gray-500 font-size-12 w-md-250px w-100 text-md-start">
                    <span>
                      {formatMessage({ id: "Max size 2mb, supported" })}{" "}
                    </span>
                    {formatMessage({ id: "format jpg, png" })}
                  </div> */}
                </div>
                <div className="chart-card rounded p-[16px] mt-[24px] md:mt-[30px]  flex flex-col  xl:flex-row items-start gap-y-[8px] xl:items-center justify-between">
                  <div className="items-center justify-between w-full mb-2 xl:w-auto md:justify-start">
                    <div
                      className={"flex items-center justify-between mb-[8px]"}
                    >
                      <h2 className=" m-0 text-[16px] leading-[24px] primary-text font-semibold ">
                        {selected}
                      </h2>
                      <p className="btn btn-sm  bg-[#CDFFE4] text-[#50CD89] font-[700] m-0 ms-4 text-[12px] leading-[16px] py-1 px-3">
                        {formatMessage({ id: "Selected Plan" })}
                      </p>
                    </div>
                    <div className="flex-wrap d-md-flex justify-content-md-between align-items-md-baseline">
                      <div>
                        <span className="gap-2 d-flex align-items-center">
                          <span className="bullet bullet-dot secondary-text"></span>
                          <p className="m-0 text-[13px] leading-[20px] secondary-text">
                            {formatMessage({ id: "30 Days Free trail" })}
                          </p>
                        </span>
                        <span className="gap-2 d-flex align-items-center">
                          <span className="bullet bullet-dot secondary-text"></span>
                          <p className="m-0  text-[13px] leading-[20px] secondary-text">
                            {" "}
                            {formatMessage({ id: "No Credit Card Required" })}
                          </p>
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="flex">
                    <span className="text-[14px] leading-6 font-semibold primary-text mt-[14px]">
                      {currencyBill === "INR" ? "₹" : "$"}
                    </span>
                    <p className="text-[20px] leading-[44px] font-semibold primary-text">
                      {price}/
                      <span className="text-[14px] leading-[24px]">
                        {currentState}
                      </span>
                    </p>
                  </div>
                  <button
                    type="button"
                    className={
                      "w-full xl:w-auto min-w-[120px] export-button p-[12px_24px] font-medium rounded  md:mt-0 text-[14px] leading-[20px]"
                    }
                    onClick={() => setModalShow(true)}
                  >
                    {formatMessage({ id: "Change Plan" })}
                  </button>
                </div>
              </div>
              <div className="col-span-12 md:col-span-6">
                <div>
                  <InfoCard
                    title={formatMessage({ id: "What is an AI Personality?" })}
                    desc={
                      <>
                        {formatMessage({
                          id: "AI Personality refers to the personalized AI replicas that users can create and train. These AI personalities can reflect the individual users or other specific personalities such as celebrities or influencer.",
                        })}
                        <br></br>
                        <br></br>
                      </>
                    }
                    slug={""}
                    isAnchor={"#"}
                  />

                  <SelectInput
                    label={formatMessage({ id: "Industry" })}
                    fieldName={"industry"}
                    placeholder={formatMessage({ id: "Select Your Industry" })}
                    formik={formik}
                    toolTipText={formatMessage({
                      id: "GLOBAL.TOOLTIP.COMPANY_DETAILS.INDUSTRY",
                    })}
                    options={referenceData?.platformOptions?.industry?.map(
                      (el: any, index: number) => {
                        return { name: el, value: el, id: index };
                      }
                    )}
                    isStarRequired={true}
                  />
                  <SelectInput
                    label={formatMessage({ id: "How did you hear about us" })}
                    fieldName={"hear-about"}
                    placeholder={formatMessage({
                      id: "How did you hear about us",
                    })}
                    formik={formik}
                    toolTipText={formatMessage({
                      id: "How did you hear about us",
                    })}
                    options={referenceData?.platformOptions?.hearAboutUs?.map(
                      (el: any, index: number) => {
                        return { name: el, value: el, id: index };
                      }
                    )}
                  />
                  <Country
                    initialValues={initialValues}
                    formik={formik}
                    label={formatMessage({ id: "Country" })}
                    setCountryId={setCountryId}
                    tooltipText={formatMessage({
                      id: "GLOBAL.TOOLTIP.COMPANY_DETAILS.COUNTRY",
                    })}
                    width={12}
                  />
                  <State
                    countryId={countryId}
                    formik={formik}
                    initialValues={initialValues}
                    tooltipText={formatMessage({
                      id: "GLOBAL.TOOLTIP.COMPANY_DETAILS.STATE",
                    })}
                  />
                </div>
                <div className="mt-md-15 d-flex justify-content-end ">
                  <div className="mr-4">
                    {(currentUser?.personality?.length > 0 || false) && (
                      <button
                        type="reset"
                        className="main-button-primary-light text-[14px] leading-5 font-medium py-[12px] w-1/2 md:w-[110px] rounded"
                        onClick={cancelPersonalityAdd}
                      >
                        <span className="text-[14px] font-medium leading-[20px]">
                          {formatMessage({ id: "Cancel" })}
                        </span>
                      </button>
                    )}
                  </div>
                  <CustomButton
                    isSubmitting={false}
                    isValid={true}
                    buttonText={formatMessage({ id: "Next" })}
                    loading={loading}
                    customClass={'export-button'}
                    width={2}
                    widthLoading={4}
                    imgName={imgName}
                    height={44}
                    marginButtom="mb-5"
                  />
                </div>
              </div>
            </Form>
          );
        }}
      </Formik>
      <BillingModal
        modalShow={modalShow}
        setModalShow={setModalShow}
        currentState={currentState}
        setCurrentState={setCurrentState}
        selected={selected}
        setSelected={setSelected}
        currencyBill={currencyBill}
        setPrice={setPrice}
        // plans={plans}
        upgrade={false}
      />
    </>
  );
};
