import axios from 'axios'
import React, { useState } from 'react'
import { useIntl } from 'react-intl'
import { useNavigate } from 'react-router'
import { toAbsoluteUrl } from '../../../../_metronic/helpers'
import { useAuth } from '../core/Auth'
import { MenuComponent } from '../../../../_metronic/assets/ts/components'

const DiscordLoginButton = ({localStorageReferral,setErrorsArray,disableAll, setDisableAll}:{localStorageReferral:any,setErrorsArray:any,disableAll:any, setDisableAll:any}) => {
    const { formatMessage } = useIntl()
    const [loading, setLoading] = useState(false)
    const {saveAuth,setUserToken,setNewPersonality,nextRoute} = useAuth()
    const navigate = useNavigate()

    const handleLogin = () => {
        setDisableAll(true)
        setLoading(true)
        const clientId = process.env.REACT_APP_DISCORD_CLIENT_ID;
        const redirectUri = process.env.REACT_APP_DISCORD_REDIRECT_URI;
        const scope = 'identify email';
        let url = `https://discord.com/oauth2/authorize?client_id=${clientId}&redirect_uri=${encodeURIComponent(redirectUri?? '')}&response_type=code&scope=${scope}&prompt=consent`
        const screenWidth = window.screen.width;
        const screenHeight = window.screen.height;
        const popupWidth = 800;
        const popupHeight = 600;
        const left = (screenWidth - popupWidth) / 2;
        const top = (screenHeight - popupHeight) / 2;

        const popup = window.open(url, 'Discord Login', `width=${popupWidth}, height=${popupHeight}, left=${left}, top=${top}`);

        // Listen for the message event from the popup window
        window.addEventListener('message', (event) => {
            if (event.origin === window.location.origin && event.data && event.data.code) {
                // You can close the popup window here if needed
                let code = event.data.code
                popup?.close();
                (async () => {
                    const {
                        data: { data: discordData, errors, success },
                      } = await axios.post(
                        `${process.env.REACT_APP_BASE_API_URL}/auth/social-signin`,
                        {
                          type: "DISCORD",
                          token: code,
                          referral: localStorageReferral,
                        }
                      );
                
                      if (success) {
                        setLoading(false);
                        await saveAuth(discordData);
                        setUserToken(discordData.token);
                        if (!discordData.personalityPresent) {
                          setNewPersonality(false)
                        }
                        if(discordData?.is_signup){
                            navigate('/onboarding')
                          }else{
                            nextRoute ? navigate(nextRoute) : navigate("/")
                          }
                        MenuComponent.bootstrap()
                      } else {
                        setLoading(false);
                        setErrorsArray(errors);
                        await saveAuth(undefined);
                        setDisableAll(false)
                      }
                })()
            }
        });
    }

    return (
        <>
            <button
                onClick={handleLogin}
                className="bg-[#5865F2] w-full flex items-center justify-center rounded py-4"
                disabled={disableAll}
            >
                <img
                    alt="Logo"
                    src={toAbsoluteUrl("/media/svg/brand-logos/discord-logo.svg")}
                    className="theme-light-show h-20px me-3"
                />
                <img
                    alt="Logo"
                    src={toAbsoluteUrl("/media/svg/brand-logos/discord-logo.svg")}
                    className="theme-dark-show h-15px me-3"
                />
                {!loading && (
                    <span className="text-[14px] text-[#FFFFFF] font-semibold leading-[20px]">
                        {formatMessage({ id: "Continue with Discord" })}
                    </span>
                )}
                {loading && (
                    <span className="indicator-progress" style={{ display: "block" }}>
                        {formatMessage({ id: "Please wait..." })}
                        <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                    </span>
                )}
            </button>
        </>
    )
}

export default DiscordLoginButton