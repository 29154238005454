import React, { useEffect, useRef, useState } from "react";
import Switch from "react-switch";
import Select from "../../../../../components/select/select";
import SortButton from "../../sort-button";
import { useIntl } from "react-intl";
import crossIcon from "../../../../../../app/assets/svgs/cross-white-icon.svg";
import { useAuth } from "../../../../auth";
import {useThemeMode} from "../../../../../../_metronic/partials";
import {isEqual} from "lodash";
import { GTMEvent } from "../../../../../hooks/gtm_helpers";
import useGTM from "../../../../../hooks/useGTM";

const Index: React.FC<any> = ({
  setOpenFilter,
  openFilter,
  handleFilters = () => {},
  showFilterButton,
  initFilters,
  filters,
  showSearchFilter,
  showFilterApplied,
  setFilters,
  onReset,
  trackUserAction
}) => {
  const { formatMessage } = useIntl();
  const {dataLayerPush} = useGTM()
  const {referenceData} = useAuth()
  const [filtersApplied, setFilterApplied] = useState<{key:string, id:string, label: any, value:any}[]>([]);
  const { mode } = useThemeMode();

  const sortOptions = [
    { value: "popular", label: formatMessage({ id: "Most Popular" }) },
    { value: "likes", label: formatMessage({ id: "Most Liked" }) },
    { value: "interacted", label: formatMessage({ id: "Most Interacted" }) },
    { value: "created", label: formatMessage({ id: "Creation Time" }) },
    { value: "name", label: formatMessage({ id: "Name" }) },
  ];
  const [licensed, setLicensed] = useState<boolean>(
    initFilters?.licensed ?? false
  );
  const [text, setText] = useState<string | undefined>(
    initFilters?.searchText ?? undefined
  );
  const [searchText, setSearchText] = useState<string | undefined>(
    initFilters?.searchText ?? undefined
  );
  const [sortByCondition, setSortByCondition] = useState<any>(
    initFilters?.sortByCondition ?? ""
  );
  const [sortBy, setSortBy] = useState<string>(initFilters?.sortBy ?? "desc");

  useEffect(() => {
    let obj: any = {
      licensed,
      sortBy,
      searchText,
    };
    if (sortByCondition != "") {
      obj.sortByCondition = sortByCondition;
    }
    handleFilters(obj);
  }, [licensed, searchText, sortByCondition, sortBy]);

  useEffect(() => {
    const interval = setTimeout(() => {
      setSearchText(text != "" ? text : undefined);
    }, 500);
    return () => clearTimeout(interval);
  }, [text]);

  const handleSearch = (e: any) => {
    trackUserAction.current = true
    setText(e.target.value);
  };

  const [applied, setApplied] = useState(0);
  useEffect(() => {
    setApplied(0);
    let count = 0;
    if (filters?.country?.length > 0) {
      count++;
    }
    if (filters?.industry?.length > 0) {
      count++;
    }
    if (filters?.racial_information?.length > 0) {
      count++;
    }
    if (filters?.category?.length > 0) {
      count++;
    }
    if (filters?.gender?.length > 0) {
      count++;
    }
    if (filters?.stage_in_life?.length > 0) {
      count++;
    }
    setApplied(count);
  }, [JSON.stringify(filters)]);

  useEffect(()=>{
    let options:any[] = []
    Object.keys(filters).map((key)=>{
      if(Array.isArray(filters[key])){
        options.push(...filters[key].map((item:any)=>{return {key, id: key +'-'+ item, label:key=='country' ? referenceData?.platformOptions?.countries?.find((country)=>country.countryId==item)?.country_name : item, value: item}}))
      }
    })
    setFilterApplied(options)
  },[filters])

  function removeFilter(obj:any){
    setFilterApplied((applied)=>applied.filter((item)=>item.id!==obj.id))
    let updatedFilter = {...filters}
    updatedFilter[obj.key] = updatedFilter[obj.key].filter((item:any)=>item!==obj.value)
    setFilters(updatedFilter)
  }
  const filterContainerRef = useRef<any>(null)
  const [overflowCount, setOverflowCount] = useState<number>(0)
  useEffect(() => {
    if(!filterContainerRef.current){
      return;
    }
    const containerWidth = filterContainerRef.current.offsetWidth;
    const filterItems:any[] = Array.from(filterContainerRef.current.children);
    
    let visibleWidth = 0;
    let visibleFiltersCount = 0;

    for (const filterItem of filterItems) {
      visibleWidth += filterItem.offsetWidth;
      if (visibleWidth <= containerWidth) {
        visibleFiltersCount++;
      } else {
        break;
      }
    }

    const overflowCount = filterItems.length - visibleFiltersCount;
    setOverflowCount(overflowCount);
  },[filtersApplied])
  return (
    <div className="">
      <div
        className={
          "flex flex-wrap items-center gap-x-2 md:gap-x-6 mb-7 md:my-7 gap-y-3.5"
        }
      >
        {showFilterButton && (
          <button
              className={
                "flex items-center h-[44px] gap-x-2 px-4 py-2.5 secondary-bg border border-main rounded order-1"
              }
            onClick={() => setOpenFilter(!openFilter)}
          >
            <svg
              className=" w-[20px] h-[20px] md:w-[24px] md:h-[24px]"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g clipPath="url(#clip0_2091_29533)">
                <path
                  d="M4 4H20V6.172C19.9999 6.70239 19.7891 7.21101 19.414 7.586L15 12V19L9 21V12.5L4.52 7.572C4.18545 7.20393 4.00005 6.7244 4 6.227V4Z"
                  className="icon-stroke-primary"
                  strokeOpacity="0.65"
                  strokeWidth="1.4"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </g>
              <defs>
                <clipPath id="clip0_2091_29533">
                  <rect width="24" height="24" fill="white" />
                </clipPath>
              </defs>
            </svg>
            <span
              className={
                "hidden md:block text-[14px] leading-5 primary-text"
              }
            >
              {formatMessage({ id: "Filters" })}
              {filtersApplied.length != 0 ? <span className="">({filtersApplied.length})</span> : ""}
            </span>
          </button>
        )}
        <div
          className={` ${
            showSearchFilter ? "" : "hidden"
          } flex items-center gap-x-2 !order-3 md:order-2  company-container`}
        >
          <form className="form-check  form-check-custom react-switch-bg form-check-solid form-check-primary form-switch flex items-center gap-x-[8px]">
            <div>
              <input
                  className="form-check-input flex-grow !w-[45px]"
                  type="checkbox"
                  checked={licensed}
                  onChange={(e) => {
                    trackUserAction.current = true
                    if(!!e.target.checked){
                      dataLayerPush(GTMEvent.LicensedFilterEnableSuccessful)
                    }else{
                      dataLayerPush(GTMEvent.LicensedFilterDisableSuccessful)
                    }
                    setLicensed(!!e.target.checked);
                  }}
              />
            </div></form>
          <label className={"text-[14px] leading-[20px] secondary-text"}>
            {formatMessage({ id: "Licensed" })}
          </label>
        </div>
        <div
          className={`${
            showSearchFilter ? "" : "hidden"
          } flex-grow !order-2 md:!order-3`}
        >
          <div className={"relative md:w-full"}>
            <svg
              className={"absolute top-1/2 -translate-y-1/2 left-2"}
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g clipPath="url(#clip0_497_31689)">
                <path
                  opacity="0.3"
                  d="M14.2929 16.7071C13.9024 16.3166 13.9024 15.6834 14.2929 15.2929C14.6834 14.9024 15.3166 14.9024 15.7071 15.2929L19.7071 19.2929C20.0976 19.6834 20.0976 20.3166 19.7071 20.7071C19.3166 21.0976 18.6834 21.0976 18.2929 20.7071L14.2929 16.7071Z"
                  fill="#585960"
                />
                <path
                  d="M11 16C13.7614 16 16 13.7614 16 11C16 8.23858 13.7614 6 11 6C8.23858 6 6 8.23858 6 11C6 13.7614 8.23858 16 11 16ZM11 18C7.13401 18 4 14.866 4 11C4 7.13401 7.13401 4 11 4C14.866 4 18 7.13401 18 11C18 14.866 14.866 18 11 18Z"
                  fill="#585960"
                />
              </g>
              <defs>
                <clipPath id="clip0_497_31689">
                  <rect width="24" height="24" fill="white" />
                </clipPath>
              </defs>
            </svg>
            <input
                className={"form-control  !h-[44px]  pl-10 !w-full search-input-table primary-text border border-main"}
                placeholder={formatMessage({
                  id: "Search Personality By Name or category",
                })}
                onChange={handleSearch}
                value={text}
            />
          </div>
        </div>
        <div
          className={`${
            showSearchFilter ? "" : "hidden"
          } flex-grow md:flex-grow-0 flex items-center gap-x-3 order-4`}
        >
          <div className={"flex-grow"}>
            <Select
                options={sortOptions}
                onChange={(value: any) => {setSortByCondition(value.value);trackUserAction.current = true}}
                value={sortOptions.find((el) => el.value === sortByCondition)}
                label={false}
                className={
                  "event-select  md:min-w-[200px] !w-full text-[14px]"
                }
                placeHolder={formatMessage({ id: "Select" })}
            />
          </div>
          <SortButton sortType={sortBy} setSortType={setSortBy} />
        </div>
      </div>

      {showFilterApplied && !!filtersApplied.length && (
        <div className="filter-applied mt-[12px] mb-[8px]">
          <div className="flex flex-row !flex-wrap justify-between md:!flex-nowrap align-items-center">
            <span className="primary-text text-[14px] leading-[26px] mr-[12px] md:order-1 whitespace-nowrap">
              {formatMessage({ id: "Filters Applied:" })}
            </span>
            <div className="flex flex-row md:w-auto w-full !order-3 overflow-hidden align-items-center md:!order-2 flex-grow-1 md:mt-0 mt-[12px]">
              <div ref={filterContainerRef} className="flex explore-filter-applied-css flex-row align-items-center gap-[8px]  overflow-x-auto whitespace-nowrap">
                {filtersApplied.map(({ id, label }, index, array) => (
                  <div
                    key={id}
                    className="flex flex-row align-items-center px-[8px] py-[3px] bg-secondary rounded-[4px] shadow-[0px 4px 4px 0px #00000040] min-w-fit "
                  >
                    <div className="text-[13px] leading-[20px] primary-text mr-[4px]">
                      {label}
                    </div>
                    <img
                      className="cursor-pointer"
                      src={crossIcon}
                      alt=""
                      onClick={() => {
                          trackUserAction.current = true
                          removeFilter(array[index])
                        }
                      }
                    ></img>
                  </div>
                ))}
              </div>
              {!!overflowCount && (
                <div className="flex flex-row align-items-center px-[8px] py-[3px]  shadow-[0px 4px 4px 0px #00000040] ">
                  <div className="text-[13px] leading-[20px] primary-text mr-[4px] whitespace-nowrap">
                   +{overflowCount} more
                  </div>
                </div>
              )}
            </div>

            <button
              className={
                "main-button-primary-light text-[12px] leading-[20px] py-[3px]  px-[8px] rounded md:order-3 whitespace-nowrap"
              }
              onClick={(e) => { onReset();trackUserAction.current = true}}
            >
              {formatMessage({
                id: "Clear All",
              })}
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default Index;
