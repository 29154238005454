import React, { useState, useEffect, useMemo, useRef } from 'react';
import { Avatar } from '@readyplayerme/visage';
import { Vector3 } from 'three';
import { useAuth } from '../../../auth';
import AWS from "aws-sdk";
import axios from 'axios';
import request from '../../../../core/_apis';
import useGTM from '../../../../hooks/useGTM';
import { GTMEvent } from '../../../../hooks/gtm_helpers';

AWS.config.update({
  region: (process.env.REACT_APP_AWS_POLLY_IDENTITY_POOL_ID || '')?.split(":")?.[0] || 'us-east-1',
  credentials: new AWS.CognitoIdentityCredentials({ IdentityPoolId: process.env.REACT_APP_AWS_POLLY_IDENTITY_POOL_ID || '' })
});

const POLLY_TO_VISAGE_VISEME_MAPPING: any = {
  'p': 'viseme_PP',
  'b': 'viseme_PP',
  'f': 'viseme_PP',
  'v': 'viseme_PP',
  't': 'viseme_FF',
  'd': 'viseme_FF',
  's': 'viseme_FF',
  'z': 'viseme_FF',
  'th': 'viseme_TH',
  'dh': 'viseme_DD',
  'k': 'viseme_kk',
  'g': 'viseme_kk',
  'ch': 'viseme_CH',
  'jh': 'viseme_CH',
  'sh': 'viseme_SS',
  'm': 'viseme_nn',
  'n': 'viseme_nn',
  'ng': 'viseme_nn',
  'r': 'viseme_RR',
  'a': 'viseme_aa',
  'ao': 'viseme_aa',
  'ah': 'viseme_aa',
  'eh': 'viseme_E',
  'er': 'viseme_E',
  'i': 'viseme_I',
  'o': 'viseme_O',
  'u': 'viseme_U',
  'sil': 'viseme_sil'
};

export enum SpeechServiceProvider {
  Polly,
  ElevenLabs,
  PlayHT
}

function ChatAvatar({ ...props }) {
  const {dataLayerPush} = useGTM()
  const isFirstRender = useRef<boolean>(true);
  const [visemeMapping, setVisemeMapping] = useState({});
  const [visemeChunks, setVisemeChunks] = useState<any>([])
  const { playTextAvatar, setPlayTextAvatar, avatarMuted, personalityInView, referenceData, setPauseListening } = useAuth()
  const [text, setText] = useState(null);
  const [chunks, setChunks] = useState<any>([]);
  const [currentChunkIndex, setCurrentChunkIndex] = useState(0);
  const [playlist, setPlaylist] = useState<any>([]);
  const [playing, setPlaying] = useState(false);
  const avatarUrl = (personalityInView?.personalityJson?.avatar?.file || "https://models.readyplayer.me/64cbd0317345960bf885198c.glb") + "?morphTargets=ARKit,Oculus Visemes"
  const emojiRegex = /[\u{1F600}-\u{1F64F}]|[\u{1F300}-\u{1F5FF}]|[\u{1F680}-\u{1F6FF}]|[\u{1F700}-\u{1F77F}]|[\u{1F780}-\u{1F7FF}]|[\u{1F800}-\u{1F8FF}]|[\u{1F900}-\u{1F9FF}]|[\u{1FA00}-\u{1FA6F}]|[\u{2600}-\u{26FF}]|[\u{2700}-\u{27BF}]|\u{23E9}|\u{23EA}|\u{23EB}|\u{23EC}|\u{2B05}|\u{2B06}|\u{2B07}|\u{2B1B}|\u{2B1C}|\u{2B50}|\u{2B55}|[0-9]\u{FE0F}\u{20E3}|\u{203C}\u{FE0F}|\u{2049}\u{FE0F}|\u{2122}\u{FE0F}|\u{2139}\u{FE0F}|\u{2194}-\u{2199}|\u{21A9}\u{FE0F}|\u{21AA}\u{FE0F}|\u{231A}\u{FE0F}|\u{231B}\u{FE0F}|\u{2328}\u{FE0F}|\u{23CF}\u{FE0F}|\u{23E9}\u{FE0F}|\u{23EA}\u{FE0F}|\u{23EB}\u{FE0F}|\u{23EC}\u{FE0F}|\u{23ED}\u{FE0F}|\u{23EE}\u{FE0F}|\u{23EF}\u{FE0F}|\u{23F0}\u{FE0F}|\u{23F1}\u{FE0F}|\u{23F2}\u{FE0F}|\u{23F3}\u{FE0F}|\u{23F8}\u{FE0F}|\u{23F9}\u{FE0F}|\u{23FA}\u{FE0F}|\u{24C2}\u{FE0F}/ug;

  const voiceType = personalityInView?.personalityJson?.voice?.voice_type;
  const isClonedVoice = voiceType === 'cloned';
  let voiceId: any;
  let service: SpeechServiceProvider = SpeechServiceProvider.Polly

  if (voiceType === 'stock') {
    voiceId = personalityInView?.personalityJson?.voice?.model;
    service = SpeechServiceProvider.Polly
  } else if (isClonedVoice) {
    let cloneEnabled: boolean
    if (personalityInView?.personalityJson?.voice?.brand == 'play-ht') {
      cloneEnabled = referenceData?.platformConfig?.['VOICE_SERVICE_PLAY_HT_ENABLED'] === 'true';
      service = SpeechServiceProvider.PlayHT
    } else if (personalityInView?.personalityJson?.voice?.brand == '11labs') {
      cloneEnabled = referenceData?.platformConfig?.['VOICE_SERVICE_11_LABS_ENABLED'] === 'true';
      service = SpeechServiceProvider.ElevenLabs
    } else {
      cloneEnabled = false
      service = SpeechServiceProvider.Polly
    }
    if (cloneEnabled) {
      voiceId = personalityInView?.personalityJson?.voice?.cloned_voices[0]?.voiceId;
    } else {
      service = SpeechServiceProvider.Polly
      const defaultVoiceId = personalityInView?.personalityJson?.avatar?.gender === 'Male'
        ? referenceData?.platformConfig?.['POLLY_DEFAULT_MALE_VOICE_ID']
        : referenceData?.platformConfig?.['POLLY_DEFAULT_FEMALE_VOICE_ID'];
      voiceId = defaultVoiceId;
    }
  } else {
    service = SpeechServiceProvider.Polly
    const defaultVoiceId = personalityInView?.personalityJson?.avatar?.gender === 'Male'
      ? referenceData?.platformConfig?.['POLLY_DEFAULT_MALE_VOICE_ID']
      : referenceData?.platformConfig?.['POLLY_DEFAULT_FEMALE_VOICE_ID'];
    voiceId = defaultVoiceId;
  }

  // watch for playtext if not null play audio
  useEffect(() => {
    if (playTextAvatar) {
      // start playing
      setText(playTextAvatar.replace(emojiRegex,''))
    }
  }, [playTextAvatar])

  useEffect(() => {
    if (avatarMuted) {
      document.dispatchEvent(new Event('mute'));
    } else {
      document.dispatchEvent(new Event('unmute'));
    }
  }, [avatarMuted])

  // set play text null when unmound
  useEffect(() => {
    dataLayerPush(GTMEvent._3DAvatarOnSuccessful)
    isFirstRender.current = false
    return () => {
      setPlayTextAvatar(null)
      document.dispatchEvent(new Event('mute'));
    }
  }, [])

  useEffect(() => {
    if (isFirstRender.current) {
      return;
    }
    if (text) {
      fetchTextToSpeech(text)
    }
  }, [text])

  // Initialize visemeMapping with all visemes as keys and 0 as values
  const initialVisemeMapping = useMemo(() => ({
    viseme_sil: 0,
    viseme_PP: 0,
    viseme_FF: 0,
    viseme_TH: 0,
    viseme_DD: 0,
    viseme_kk: 0,
    viseme_CH: 0,
    viseme_SS: 0,
    viseme_nn: 0,
    viseme_RR: 0,
    viseme_aa: 0,
    viseme_E: 0,
    viseme_I: 0,
    viseme_O: 0,
    viseme_U: 0,
  }), []);

  // Initialize visemeMapping with all visemes as keys and 0 as values
  useEffect(() => {
    setVisemeMapping(initialVisemeMapping);
  }, [initialVisemeMapping]);

  useEffect(() => {
    if (playlist.length > 0 && !playing) {
      setPlaying(true);
      playChunk(playlist[0], visemeChunks[0]);
      // playViseme(visemeChunks[0]);
    }
  }, [playlist, playing]);

  // Function to split text into chunks of 300 characters
  const splitTextIntoChunks = (text: string) => {
    // const chunkSize = 300;
    // const regex = /.{1,300}\s|\S+$/g;
    // return text.match(regex);
    return [text]
  };

  const playAudioInSync = (url: any) => {
    const audio = new Audio(url);

    return new Promise((resolve, reject) => {
      audio.addEventListener('canplay', () => {
        // audio.load()
        resolve(audio);
      });
      audio.addEventListener('error', (error) => {
        reject(error);
      });
      audio.load(); // Start loading the audio
      if (audio.readyState >= 4) {
        // audio.play()
        resolve(audio);
      }
    });
  };

  const playChunk = async (audioStream: any, visemes: any) => {
    let audioUrl
    if (service == SpeechServiceProvider.Polly) {
      const audioBlob = new Blob([audioStream], { type: 'audio/mp3' });
      audioUrl = URL.createObjectURL(audioBlob);
    }
    if (service == SpeechServiceProvider.ElevenLabs) {
      audioUrl = audioStream
    }
    if (service == SpeechServiceProvider.PlayHT) {
      audioUrl = audioStream
    }

    // const audioElement = new Audio(audioUrl);
    // audioElement.play();
    let audio: any = await playAudioInSync(audioUrl)
    audio.play()
    let duration = audio.duration * 1000
    playViseme(visemes, duration)
    audio.muted = avatarMuted
    const mute = () => {
      audio.muted = true; // Toggle the muted state
    };
    const unmute = () => {
      audio.muted = false; // Toggle the muted state
    };
    document.addEventListener('mute', mute);
    document.addEventListener('unmute', unmute);

    // Wait for the current audio to finish before playing the next chunk
    await new Promise<void>(resolve => {
      audio.addEventListener('ended', () => {
        if(playlist.length === 1){
          setPauseListening(false)
        }
        // Remove the played chunk from the playlist
        setPlaylist((prevPlaylist: any) => prevPlaylist.slice(1));
        setPlaying(false); // Allow the next chunk to play
        document.removeEventListener('mute', mute);
        document.removeEventListener('unmute', unmute);
        resolve();
      });
    });
  }

  const playViseme = (visemes: any, duration: any) => {
    console.log(visemes)
    let visemeDataArray = visemes.trim().split('\n').filter(Boolean).map((line: any) => {
      try {
        return JSON.parse(line);
      } catch (error) {
        console.error("Error parsing JSON:", error, "Invalid JSON:", line);
        return null;
      }
    }).filter(Boolean);

    let visemeFactor = (duration / visemeDataArray[visemeDataArray.length - 1].time)
    // console.log({audioDuration, visemeFactor,last:visemeDataArray[visemeDataArray.length - 1].time})
    visemeDataArray = visemeDataArray.map((el: any) => {
      return {
        ...el,
        time: parseInt((el.time * visemeFactor).toString())
      }
    })
    setVisemeChunks((chunks: any) => chunks.slice(1));
    visemeDataArray.forEach((visemeDataItem: any, index: number) => {
      const nextVisemeDataItem = visemeDataArray[index + 1];
      const viseme = POLLY_TO_VISAGE_VISEME_MAPPING[visemeDataItem.value];
      const nextViseme = POLLY_TO_VISAGE_VISEME_MAPPING[nextVisemeDataItem ? nextVisemeDataItem.value : 'sil'];

      let frames = 50
      if (viseme && nextViseme) {
        const duration = nextVisemeDataItem ? nextVisemeDataItem.time - visemeDataItem.time : 0;
        let halfFrames = frames / 2
        setTimeout(() => {
          // Reset visemeMapping
          setVisemeMapping(initialVisemeMapping);
        }, visemeDataItem.time);

        for (let index = 0; index < halfFrames; index++) {
          setTimeout(() => {
            setVisemeMapping((prev) => ({ ...prev, [viseme]: (1 / halfFrames) * (halfFrames - index) }));
          }, visemeDataItem.time + (duration / frames) * (index + 1));
        }

        if (duration > 0) {
          for (let index = 0; index < halfFrames; index++) {
            setTimeout(() => {
              // Transition to the next viseme
              setVisemeMapping((prev) => ({ ...prev, [nextViseme]: (1 / halfFrames) * (index + 1) }));
            }, visemeDataItem.time + duration / 2 + (duration / frames) * (index + 1));
          }
        }
      }
    });
  }

  const fetchTextToSpeech = async (text: string) => {
    const textChunks = splitTextIntoChunks(text) || [];
    setChunks(textChunks);

    for (let i = 0; i < textChunks?.length; i++) {
      const params = {
        OutputFormat: 'mp3',
        Text: textChunks[i],
        VoiceId: voiceId
      };
      const visemeParams = {
        OutputFormat: 'json',
        Text: textChunks[i],
        VoiceId: service == SpeechServiceProvider.Polly ? voiceId : 'Justin',
        SpeechMarkTypes: ['viseme']
      }
      try {
        const polly = new AWS.Polly();
        // here deduct for polly viseme data
        await request.post('/polly-access',{text: textChunks[i]},{headers: {'x-personality-id': personalityInView.personalityId}})
        const visemeResult = await polly.synthesizeSpeech(visemeParams).promise();
        setVisemeChunks((chunks: any) => [...chunks, visemeResult.AudioStream?.toString()])

        if (service == SpeechServiceProvider.Polly) {
          // here deduct for polly voice
          await request.post('/polly-access',{text: textChunks[i]},{headers: {'x-personality-id': personalityInView.personalityId}})
          const result = await polly.synthesizeSpeech(params).promise();
          setPlaylist((prevPlaylist: any) => [...prevPlaylist, result.AudioStream]);
        }
        if (service == SpeechServiceProvider.ElevenLabs) {
          const result = await get11LabsConvert(textChunks[i]);
          setPlaylist((prevPlaylist: any) => [...prevPlaylist, result]);
        }
        if (service == SpeechServiceProvider.PlayHT) {
          const result = await getPlayHtConvert(textChunks[i]);
          setPlaylist((prevPlaylist: any) => [...prevPlaylist, result]);
        }

        setCurrentChunkIndex(i + 1);
      } catch (error) {
        console.error('Error synthesizing speech:', error);
      }
    }
  };

  const get11LabsConvert = async (text: any) => {
    // const headers = {
    //   'accept': 'audio/mpeg',
    //   'xi-api-key': process.env.REACT_APP_20_KI || '',
    //   'Content-Type': 'application/json'
    // };

    // const requestData = {
    //   text: text,
    //   model_id: 'eleven_monolingual_v1',
    //   voice_settings: {
    //     stability: 0.5,
    //     similarity_boost: 0.5
    //   }
    // };
    let relayUrl = await getRelayUrl(text)
    const requestOptions = {
      method: 'GET',
      // headers: headers,
      // body: JSON.stringify(requestData)
    };

    try {
      const response = await fetch(relayUrl, requestOptions);
      const blobData = await response.blob();
      const url = URL.createObjectURL(blobData);
      return url;
    } catch (error) {
      console.error('Error:', error);
      // Handle the error as needed
      return null;
    }
  }

  const getPlayHtConvert = async (text: any) => {
    const requestOptions = {
      method: 'GET',
    };
    let relayUrl = await getRelayUrl(text)
    try {
      const response = await fetch(relayUrl, requestOptions);
      const blobData = await response.blob();
      const url = URL.createObjectURL(blobData);
      return url;
    } catch (error) {
      console.error('Error:', error);
      // Handle the error as needed
      return null;
    }
  }

  const getRelayUrl = async (textForConvert:string) => {
    let serviceReq = service == SpeechServiceProvider.PlayHT ? 'play-ht' : (service == SpeechServiceProvider.ElevenLabs ? '11labs' : false)
    if (!serviceReq) {
      return;
    }
    const resp = await axios.post(
      process.env.REACT_APP_BASE_API_URL + '/streaming/relay-url',
      {
        text: textForConvert,
        voiceId: voiceId,
        service: serviceReq
      },
      { headers: { 'x-personality-id': personalityInView.personalityId } }
    );
    if (resp.status == 200) {
      return resp.data.data.url
    }
  }


  return (
    <div className="AvatarContainer w-full h-full">
      <Avatar
        modelSrc={avatarUrl}
        // animationSrc={ playing ? '' : `https://public-content.kamoto.ai/personality-avatars-library/3d-avatar-animation-source.glb`}
        ambientLightColor="#fff5b6"
        ambientLightIntensity={0.25}
        // cameraInitialDistance={0}
        // cameraTarget={7.2}
        // cameraZoomTarget={{
        //   "x": -0.4,
        //   "y": 3,
        //   "z": 8,
        //   "_constructor-name_": "Vector3"
        // }}
        dirLightColor="#002aff"
        dirLightIntensity={5}
        dirLightPosition={
          // {
          // "x": -3,
          // "y": 5,
          // "z": -5,
          // "_constructor-name_": new THREE.Vector3()
          // }
          new Vector3(-3, 5, -5)
        }
        emotion={visemeMapping}
        halfBody={false}
        scale={1.02}
        spotLightAngle={0.314}
        // spotLightColor="#fff5b6"
        spotLightIntensity={1}
        spotLightPosition={
          // {
          //   "x": 12,
          //   "y": 10,
          //   "z": 7.5,
          //   "_constructor-name_": "Vector3"
          // }
          new Vector3(12, 10, 7.5)
        }
        style={{
          background: 'transparent'
        }}
      />
    </div>
  );
}

export default ChatAvatar;