import axios from "axios";
import { get } from "lodash";

const request = axios.create({
    baseURL: process.env.REACT_APP_BASE_API_URL,
});

request.interceptors.request.use((config) => {
    const token = localStorage.getItem('kt-auth-react-v') ? get(JSON.parse(localStorage.getItem('kt-auth-react-v') || ''), 'token', null) : null;
    if (token && config && config.headers) {
        config.headers['Authorization'] = `Bearer ${token}`
    }
    return config;
}, (error) => {
    // write configuration here for error case
});

export default request