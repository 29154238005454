import { Formik, Field } from "formik";
import React, { Dispatch, SetStateAction, useState } from "react";
import { Form, Modal } from "react-bootstrap";
import { useIntl } from "react-intl";
import TextInput from "../../widgets/components/Input/TextInput";
import { CustomButton } from "../../widgets/components/UI/CustomButton";
import * as Yup from "yup";
import { ToolTipUI } from "../../widgets/components/UI/ToolTipUI";
import { addUser, editUser } from "../core/_requests";
import { Roles, RolesName } from "../core/_constants";
import { toast } from "react-toastify";
import { useAuth } from "../../auth";
import { Spinner } from "../../widgets/components/General/Spinner";
import { BasicButton } from "../../widgets/components/UI/BasicButton";
import useGTM from "../../../hooks/useGTM";
import { GTMEvent } from "../../../hooks/gtm_helpers";

const initialValues = {
  email: "",
  firstName:"",
  lastName:"",
  roleId: "",
  designation: ""
};

const UserModal = ({
  title,
  buttonText,
  addModal,
  setAddModal,
  flag,
  userDetails,
  getUsersList,
  getPendingUsers,
  getUserApiSpinner,
  setShowUserId
}: {
  title: string;
  buttonText: string;
  addModal: boolean;
  setAddModal: Dispatch<SetStateAction<boolean>>;
  flag: boolean;
  userDetails?: any;
  getUsersList: any;
  getPendingUsers?: any;
  getUserApiSpinner?: boolean;
  setShowUserId?:any;
}) => {
  const { formatMessage } = useIntl()
  const {dataLayerPush} = useGTM()
  const [loading, setLoading] = useState(false)
  const { personalityId } = useAuth()

  const userSchema = Yup.object().shape({
    email: Yup.string()
      .email(formatMessage({ id: "Invalid email format" }))
      .max(50, formatMessage({ id: "Maximum 50 characters" }))
      .required(formatMessage({ id: "Email is required" })),
    roleId: Yup.string()
      .required(formatMessage({ id: "Role is required" })),
    firstName: Yup.string().required(formatMessage({id:"First Name is required"})),
    lastName: Yup.string().required(formatMessage({id:"Last Name is required"}))
  });

  const onSubmit = async (values: any) => {
    try {
      if(!personalityId){
        return;
      }
      setLoading(true);
      if (!flag) {
        const payload = {
              email: values.email,
              role: values.roleId,
              designation: RolesName[values.roleId],
              firstName: values.firstName, 
              lastName: values.lastName
        };
        const {
          data: { success, errors },
        } = await addUser(personalityId, payload);
        if (success) {
          setLoading(false);
          setAddModal(!addModal);
          getPendingUsers();
          toast.success(formatMessage({ id: "User Invited" }));
          dataLayerPush(GTMEvent.AIPersonalityEditSuccessful,{"tab": "team"})
        } else {
          setLoading(false);
          // setAddModal(!addModal);
          errors.forEach((error: string) => {
            toast.error(formatMessage({ id: error }),
            {
              autoClose: false,
              closeButton: true, // Display close button (cross icon)
            });
          });
          dataLayerPush(GTMEvent.AIPersonalityEditFailed,{"tab": "team"})
        }
      } else {
        const payload = {
          personalityId: personalityId,
          role: values.roleId,
        };
        const {
          data: { success, errors },
        } = await editUser(userDetails?.userId, payload);
        if (success) {
          setLoading(false);
          setAddModal(!addModal);
          toast.success(formatMessage({ id: "User Updated" }));
          getUsersList();
          setShowUserId(undefined)
          dataLayerPush(GTMEvent.AIPersonalityEditSuccessful,{"tab": "team"})
        } else {
          setLoading(false);
          errors.forEach((error: string) => {
            toast.error(formatMessage({ id: error }),
            {
              autoClose: false,
              closeButton: true, // Display close button (cross icon)
            });
          });
          dataLayerPush(GTMEvent.AIPersonalityEditFailed,{"tab": "team"})
        }
      }
    } catch (err) {
      console.log(err);
    }
  };
  if (flag) {
    initialValues.email = userDetails?.email;
    initialValues.roleId = userDetails?.userRoles[0]?.roleId;
    initialValues.designation = RolesName[userDetails?.userRoles[0]?.roleId]
    initialValues.firstName = userDetails?.firstName;
    initialValues.lastName = userDetails?.lastName
  } else {
    initialValues.email = "";
    initialValues.roleId = "";
    initialValues.designation = "";
    initialValues.firstName = "";
    initialValues.lastName = "";
  }
  return (
    <>
      <Modal
        show={addModal}
        onHide={() => setAddModal(!addModal)}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        {getUserApiSpinner ? <Spinner placement={true} /> : null}
        <Modal.Header closeButton>
          <div className={"flex justify-between items-center !w-full"}>
            <h2 className={"text-[20px] leading-[28px] font-semibold primary-text"}>
              {title}
            </h2>
            <button onClick={() => setAddModal(false)}>
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M16.2426 6.34359L6.34309 16.2431C5.95257 16.6336 5.95257 17.2668 6.34309 17.6573C6.73362 18.0478 7.36678 18.0478 7.75731 17.6573L17.6568 7.75781C18.0473 7.36728 18.0473 6.73412 17.6568 6.34359C17.2663 5.95307 16.6331 5.95307 16.2426 6.34359Z"
                  fill="#C2D24B"
                  fillOpacity="0.5"
                />
                <path
                  opacity="0.3"
                  d="M17.6569 16.2423L7.7574 6.34285C7.36688 5.95232 6.73371 5.95232 6.34319 6.34285C5.95266 6.73337 5.95266 7.36654 6.34319 7.75706L16.2427 17.6566C16.6332 18.0471 17.2664 18.0471 17.6569 17.6566C18.0474 17.266 18.0474 16.6329 17.6569 16.2423Z"
                  fill="#C2D24B"
                  fillOpacity="0.5"
                />
              </svg>
            </button>
          </div>
        </Modal.Header>
        <Formik
          initialValues={initialValues}
          validationSchema={userSchema}
          onSubmit={onSubmit}
          validateOnMount={true}
        >
          {(formik) => {
            return (
              <Form>
                <Modal.Body>
                  <div className="row">
                    <div className="col-6">
                      <TextInput
                        fieldType={"text"}
                        fieldName={"firstName"}
                        isDisabled={flag}
                        formik={formik}
                        placeholder={formatMessage({ id: "Enter first name" })}
                        label={formatMessage({ id: "First Name" })}
                        isTooltipNotRequired={true}
                      />
                    </div>
                    <div className="col-6">
                      <TextInput
                        fieldType={"text"}
                        fieldName={"lastName"}
                        isDisabled={flag}
                        formik={formik}
                        placeholder={formatMessage({ id: "Enter last name" })}
                        label={formatMessage({ id: "Last Name" })}
                        isTooltipNotRequired={true}
                      />
                    </div>
                  </div>
                  <TextInput
                    fieldType={"text"}
                    fieldName={"email"}
                    isDisabled={flag}
                    formik={formik}
                    placeholder={formatMessage({ id: "Enter email" })}
                    label={formatMessage({ id: "Email Address" })}
                    isTooltipNotRequired={true}
                  />
                  <div className="col-12 mb-4">
                    <label className="text-[13px] leading-[18px] secondary-text font-medium flex items-center">
                      {formatMessage({ id: "Assign Role" })}
                      <ToolTipUI
                        tooltipText={formatMessage({ id: "Assign Role" })}
                      />
                    </label>
                  </div>
                  <div className="grid grid-cols-2 gap-4">
                    <Field name={'roleId'}>
                      {
                        ({ field }: { field: any }) => {
                          return Object.keys(RolesName).map(role => {
                            if(role=='owner') return null;
                            return (
                              <div key={role}>
                                <input
                                  className="form-check-input"
                                  type='radio'
                                  id={role}
                                  {...field}
                                  value={role}
                                  checked={field.value === role}
                                />
                                <label className="ml-[14px] text-[13px] leading-[20px] font-normal secondary-text" htmlFor={role}>{formatMessage({ id: RolesName[role] })}</label>

                              </div>
                            );
                          })
                        }
                      }
                    </Field>
                  </div>

                </Modal.Body>
                <Modal.Footer>
                  <div className="d-flex flex-wrap gap-3">
                    <BasicButton
                      buttonText={flag ? formatMessage({id:"Cancel"}) : formatMessage({ id: "Discard" })}
                      customClass={"px-[24px] main-button-primary-light"}
                      height="42px"
                      onClick={() => flag? setAddModal(false) : formik.resetForm()}
                    />
                    <CustomButton
                      isSubmitting={formik.isSubmitting}
                      isValid={formik.isValid}
                      buttonText={buttonText}
                      customClass={'export-button'}
                      loading={loading}
                      height={44}
                      widthLoading={1}
                      width={1}
                      onSubmit={formik.handleSubmit}
                    />
                  </div>
                </Modal.Footer>
              </Form>
            );
          }}
        </Formik>
      </Modal>
    </>
  );
};

export default UserModal;
