import React, { useEffect, useRef, useState } from "react";
import AiList from "../../components/marketplace-discovery/ai-list";
import Sider from "../../components/marketplace-discovery/sider";
import Filter from "../../components/marketplace-discovery/filter";
import clsx from "clsx";
import { Link, useLocation, useSearchParams } from "react-router-dom";
import {
  ComponentSpinner,
  Spinner,
} from "../../../widgets/components/General/Spinner";
import request from "../../../../core/_apis";
import { useIntl } from "react-intl";
import useGTM from "../../../../hooks/useGTM";
import { GTMEvent } from "../../../../hooks/gtm_helpers";

const SearchWidget = ({
  showFilterButton = false,
  showFilterApplied = true,
  updateUrl = false,
  searchParamsFilter = false,
  showSearchFilter = true,
  heading = "",
  preAppliedFilters,
  paginationDefault,
  showPagination = true,
  showViewAllButton = undefined,
}: {
  showFilterButton?: boolean;
  showFilterApplied?: boolean;
  updateUrl?: boolean;
  searchParamsFilter?: boolean;
  showSearchFilter?: boolean;
  heading?: string;
  preAppliedFilters?: any;
  paginationDefault: any;
  showPagination?: boolean;
  showViewAllButton: string | undefined;
}) => {
  const { formatMessage } = useIntl();
  const {dataLayerPush} = useGTM()
  const [key, setKey] = useState("0");
  const [openFilter, setOpenFilter] = useState(false);
  const [loading, setLoading] = useState(true);
  const [apiLoading, setApiLoading] = useState(true);
  const [filters, setFilters] = useState<any>({});
  const [apiFilter, setApiFilter] = useState<any>(null);
  const [initFilters, setInitFilters] = useState<any>({});
  const [searchParams, setSearchParams] = useSearchParams();
  const [personalities, setPersonalities] = useState<any>([]);
  const [total, setTotal] = useState<number>(0);
  const location = useLocation();
  const locationState: any = location?.state || null;
  // keep the reference of previous filter to keep track of changed filters
  const prevApiFilters = useRef<any>(null)
  const trackUserAction = useRef<boolean>(false)

  useEffect(() => {
    // logic to update url by condition
    let obj: any = Object.fromEntries(
      Object.entries(filters).filter(
        ([key, value]) =>
          value !== undefined &&
          value !== "" &&
          value !== null &&
          (Array.isArray(value) ? value.length > 0 : true)
      )
    );

    if (updateUrl) {
      setSearchParams(obj, { replace: true });
    }
    if (JSON.stringify(filters) !== "{}") {
      let obj2 = Object.fromEntries(
        Object.entries(obj).filter(
          ([key, value]) =>
            value !== undefined &&
            value !== "" &&
            value !== null &&
            (Array.isArray(value) ? value.length > 0 : true)
        )
      );
      setApiFilter(obj2);
    }

    // call api on filters change
  }, [JSON.stringify(filters)]);

  useEffect(() => {
    if (apiFilter) {
      let queryString = "";
      for (const key in apiFilter) {
        if (Array.isArray(apiFilter[key])) {
          apiFilter[key].forEach((value: string | number | boolean) => {
            queryString += `&${key}=${encodeURIComponent(value)}`;
          });
        } else {
          queryString += `&${key}=${encodeURIComponent(apiFilter[key])}`;
        }
      }
      setApiLoading(true);
      request
        .get(`/personality?${queryString.substring(1)}`)
        .then((resp) => {
          if (resp.data.success == true) {
            setPersonalities(resp?.data?.data?.rows ?? []);
            setTotal(resp?.data?.data?.count ?? 0);
          }
          if(!trackUserAction.current) return;
          dataLayerPush(GTMEvent.FilterChangeSuccessful)
          if(prevApiFilters.current){
            // if(prevApiFilters.current?.licensed !== apiFilter?.licensed){
            //   if(apiFilter?.licensed){
            //     dataLayerPush(GTMEvent.LicensedFilterEnableSuccessful)
            //   }else{
            //     dataLayerPush(GTMEvent.LicensedFilterDisableSuccessful)
            //   }
            // }
            if(prevApiFilters.current?.searchText !== apiFilter?.searchText && apiFilter?.searchText){
              dataLayerPush(GTMEvent.SearchSuccessful)
            }
          }
          trackUserAction.current = false
        })
        .catch((error) => {
          console.log(error);
          if(!trackUserAction.current) return;
          dataLayerPush(GTMEvent.FilterChangeFailed)
          if(prevApiFilters.current){
            // if(prevApiFilters.current?.licensed !== apiFilter?.licensed){
            //   if(apiFilter?.licensed){
            //     dataLayerPush(GTMEvent.LicensedFilterEnableSuccessful)
            //   }else{
            //     dataLayerPush(GTMEvent.LicensedFilterDisableSuccessful)
            //   }
            // }
            if(prevApiFilters.current?.searchText !== apiFilter?.searchText && apiFilter?.searchText){
              dataLayerPush(GTMEvent.SearchFailed)
            }
          }
          trackUserAction.current = false
        })
        .finally(() => {
          setApiLoading(false)
          prevApiFilters.current = apiFilter
        });
    }
  }, [JSON.stringify(apiFilter)]);

  const handleFilters = (values: any) => {
    setFilters({ ...filters, ...values });
  };

  useEffect(() => {
    if (locationState?.rerender) {
      // if page has country then include that too
      setFilters({
        category: [locationState?.rerender],
        country: searchParams.getAll("country"),
      });
      // setInitFilters({ category: [locationState?.rerender], country: searchParams.getAll('country') });
      setKey("M" + Math.random().toString());
    }
  }, [locationState?.rerender]);

  const onReset = (e: any) => {
    try{
      setFilters({});
      setInitFilters({});
      setApiFilter(null);
      setKey("M" + Math.random().toString());
      dataLayerPush(GTMEvent.FilterResetSuccessful)
    }catch(e){
      dataLayerPush(GTMEvent.FilterResetFailed)
    }
  };

  useEffect(() => {
    if (preAppliedFilters) {
      let obj: any = Object.fromEntries(
        Object.entries(preAppliedFilters).filter(
          ([key, value]) =>
            value !== undefined &&
            value !== "" &&
            value !== null &&
            (Array.isArray(value) ? value.length > 0 : true)
        )
      );
      setInitFilters(obj);
      setFilters(obj);
      setLoading(false);
    } else {
      if (searchParamsFilter) {
        let obj = {
          page: searchParams.get("page")
            ? Number(searchParams.get("page"))
            : null,
          perPage: searchParams.get("perPage")
            ? Number(searchParams.get("perPage"))
            : null,
          licensed: searchParams.get("licensed"),
          sortBy: searchParams.get("sortBy"),
          sortByCondition: searchParams.get("sortByCondition"),
          searchText: searchParams.get("searchText"),
          industry: searchParams.getAll("industry"),
          country: searchParams.getAll("country"),
          stage_in_life: searchParams.getAll("stage_in_life"),
          gender: searchParams.getAll("gender"),
          category: searchParams.getAll("category"),
          racial_information: searchParams.getAll("racial_information"),
        };
        setInitFilters(
          Object.fromEntries(
            Object.entries(obj).filter(
              ([key, value]) =>
                value !== undefined &&
                value !== "" &&
                value !== null &&
                (Array.isArray(value) ? value.length > 0 : true)
            )
          )
        );

        setFilters(
          Object.fromEntries(
            Object.entries(obj).filter(
              ([key, value]) =>
                value !== undefined &&
                value !== "" &&
                value !== null &&
                (Array.isArray(value) ? value.length > 0 : true)
            )
          )
        );
        setLoading(false);
      } else {
        setLoading(false);
      }
    }
  }, []);

  if (loading) {
    return <Spinner />;
  }

  return (
    <div className={"pt-[1px] md:pt-5"} key={key}>
      <div className="flex  md:justify-between flex-row md:items-center my-[27px]">
        {heading !== "" && (
          <h2
            className={
              "text-[24px] lg:text-[32px] leading-[32px] lg:leading-[45px] font-semibold primary-text"
            }
          >
            {heading}
          </h2>
        )}
        {showViewAllButton && (
          <Link
            to={showViewAllButton}
            className="flex flex-row whitespace-nowrap align-items-center ml-auto sm:ml-0 main-text text-[16px] leading-[24px] font-weight-500 "
          >
            {formatMessage({ id: "View all" })}
            <svg
              className="ml-[10px]"
              width="6"
              height="10"
              viewBox="0 0 6 10"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M1 1L5 5L1 9"
                stroke="#C2D24B"
                strokeWidth="1.6"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </Link>
        )}
      </div>

      <Filter
        setOpenFilter={setOpenFilter}
        openFilter={openFilter}
        handleFilters={handleFilters}
        showFilterButton={showFilterButton}
        showFilterApplied={showFilterApplied}
        initFilters={initFilters}
        showSearchFilter={showSearchFilter}
        filters={filters}
        setFilters={setFilters}
        onReset={onReset}
        trackUserAction={trackUserAction}
      />

      <div className={"flex md:gap-x-4"}>
        <div
          className={clsx(
            "md:transition-all md:duration-300",
            !openFilter ? "-translate-x-full -left-full absolute" : ""
          )}
        >
          <Sider
            open={openFilter}
            setOpen={setOpenFilter}
            handleFilters={handleFilters}
            initFilters={filters}
            trackUserAction={trackUserAction}
          />
        </div>
        <div className={"flex-grow transition-all duration-300"}>
          <AiList
            openFilter={openFilter}
            handleFilters={handleFilters}
            filters={filters}
            personalities={personalities}
            total={total}
            apiLoading={apiLoading}
            onReset={onReset}
            paginationDefault={paginationDefault}
            showPagination={showPagination}
          />
        </div>
      </div>
    </div>
  );
};

export default SearchWidget;
