import React, { useState } from "react";
import clsx from "clsx";
import FilterForm from "./filter-form";

const Index: React.FC<any> = ({
  open = true,
  setOpen,
  handleFilters = () => {},
  initFilters,
  trackUserAction
}) => {
  return (
    <div
      className={clsx(
        "w-[280px] md:w-[300px] md:min-w-[300px] lg:w-[340px] lg:min-w-[340px] fixed top-[82px] md:top-[79px]  md:static duration-300 transition-all flex z-40 border border-main rounded h-full md:min-h-[70vh] filter-tab-container",
        open
          ? "shadow-default left-0"
          : "md:translate-x-0 -translate-x-full -left-12"
      )}
    >
      <button
        className={clsx(
          "absolute right-0 top-9 translate-x-full w-[36px] h-[36px] flex items-center justify-center bg-[#2E2F45] rounded-r-[8px] border md:hidden"
        )}
        onClick={() => setOpen(!open)}
      >
        <svg
          width="14"
          height="14"
          viewBox="0 0 14 14"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M7 5.44469L12.4447 0L14 1.55531L8.55531 7L14 12.4447L12.4447 14L7 8.55531L1.55531 14L0 12.4447L5.44469 7L0 1.55531L1.55531 0L7 5.44469Z"
            fill="white"
            fillOpacity="0.8"
          />
        </svg>
      </button>
      <div className={"flex flex-col flex-grow py-4 pb-0 mb-8 justify-between"}>
        <div className={"overflow-y-auto custom-scroll max-h-[100vh]"}>
          <FilterForm handleFilters={handleFilters} initFilters={initFilters} trackUserAction={trackUserAction} />
        </div>
      </div>
    </div>
  );
};

export default Index;
