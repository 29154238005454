/* eslint-disable jsx-a11y/anchor-is-valid */
import { useEffect, useState } from "react";
import * as Yup from "yup";
import { Link, useLocation } from "react-router-dom";
import { Field, Form, Formik } from "formik";
import { login } from "../core/_requests";
import { useAuth } from "../core/Auth";
import SocialLoginButtons from "../components/SocialLoginButtons";
import TextInput from "../../widgets/components/Input/TextInput";
import { useIntl } from "react-intl";
import { CustomButton } from "../../widgets/components/UI/CustomButton";
import { useNavigate } from "react-router-dom";
import { CustomToast } from "../../widgets/components/UI/CustomToast";
import { FormikProps } from "../core/_models";
import { Toaster } from "../../widgets/components/General/Toaster";
import { MenuComponent } from "../../../../_metronic/assets/ts/components";
import useGTM from "../../../hooks/useGTM";
import { GTMEvent } from "../../../hooks/gtm_helpers";
import { toast, ToastContainer } from "react-toastify";
import { Tabs, TabList, Tab, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import ReactSelect from "react-select";
import FormikReactSelect from "../../widgets/components/Input/FormikReactSelect";
import request from "../../../core/_apis";

export function Login() {
  const { formatMessage } = useIntl();
  const [loading, setLoading] = useState(false);
  const [emailDisabled, setEmailDisabled] = useState<boolean>(false);
  const { saveAuth, setUserToken, nextRoute, referenceData, ipStackCountry } = useAuth();
  const navigate = useNavigate();
  const [errorsArray, setErrorsArray] = useState<string[]>();
  const [disableAll, setDisableAll] = useState<boolean>(false);
  const [otpSent, setOtpSent] = useState<boolean>(false);
  const [userId, setUserId] = useState<any>()
  const [resentTimer, setResentTimer] = useState<number>(0)
  const [contact, setContact] = useState<any>()
  const [country, setCountry] = useState<any>()
  // track page view
  const { dataLayerPush } = useGTM()
  let countryOption = referenceData?.platformOptions?.countries?.find((el) => el.country_code == ipStackCountry?.country_code)
  const defaultCountry = {
    value: `${countryOption?.phone_code}${countryOption?.country_code}`,
    label: `${countryOption?.country_name} (+${countryOption?.phone_code})`
  }
  const initialValues = {
    email: "",
    password: "",
  };

  const loginSchema = Yup.object().shape({
    email: Yup.string()
      .email(formatMessage({ id: "Invalid email format" }))
      .max(50, formatMessage({ id: "Maximum 50 characters" }))
      .required(formatMessage({ id: "Email is required" })),
    password: Yup.string()
      .min(6, formatMessage({ id: "Minimum 6 characters" }))
      .max(50, formatMessage({ id: "Maximum 50 characters" }))
      .required(formatMessage({ id: "Password is required" })),
  });

  const otpSchema = Yup.object().shape({
    contact: Yup.number()
      .required(formatMessage({ id: "Mobile no. is required" })),
    country: Yup.string()
      .required(formatMessage({ id: "Country code is required" })),
  });

  const onSubmit = async (values: any, { setSubmitting }: FormikProps) => {
    setLoading(true);
    setDisableAll(true);
    try {
      const {
        data: { data, success, errors },
      } = await login(values.email, values.password);
      if (success) {
        await saveAuth(data);
        setUserToken(data.token);
        nextRoute ? navigate(nextRoute) : navigate("/");
        MenuComponent.bootstrap();
      } else {
        setErrorsArray(errors);
        await saveAuth(undefined);
      }
    } catch (error) {
      dataLayerPush(GTMEvent.LoginFailed, { email: values.email, method: 'EMAIL' })
      console.log(error);
    } finally {
      setSubmitting(false);
      setLoading(false);
      setDisableAll(false);
    }
  };

  const resendOtp = async () => {
    try {
      await request.post('/auth/send-login-otp', {
        contact: contact,
        phone_code: parseInt(country)
      })
      setOtpSent(true)
      setLoading(false);
      setDisableAll(false);
      setResentTimer(60);
    } catch (e) {
      setLoading(false);
      setDisableAll(false);
    }
  }

  const submitPhoneForOtp = async (values: any, { setSubmitting }: FormikProps) => {
    setLoading(true);
    setDisableAll(true);
    setContact(values.contact)
    setCountry(values.country.slice(0,-2))
    // console.log(values.country.slice(0,-2),values.country)
    // return;
    try {
      let resp = await request.post('/auth/send-login-otp', {
        contact: values.contact,
        phone_code: parseInt(values.country)
      })
      setUserId(resp.data.data.userId)
      setOtpSent(true)
      setSubmitting(false);
      setLoading(false);
      setDisableAll(false);
      setResentTimer(60);
    } catch (e:any) {
      if(e?.response?.status === 404){
        toast.error("Account does not exists with this Mobile no.")
      }
      setSubmitting(false);
      setLoading(false);
      setDisableAll(false);
    }
  }

  useEffect(() => {
    if (resentTimer === 60) {
      let int = setInterval(() => {
        setResentTimer((value) => {
          if (value === 0) {
            clearInterval(int);
          }
          return value - 1;
        });
      }, 1000);
      // return () => clearInterval(int);
    }
  }, [resentTimer]);
  

  const loginUsingOtp = async (values: any, { setSubmitting }: FormikProps) => {
    setLoading(true);
    setDisableAll(true);
    try {
      let resp = await request.post('/auth/verify-login-otp', {
        otp: values.otp,
        userId: userId
      })
      await saveAuth(resp.data.data);
      setUserToken(resp.data.data.token);
      nextRoute ? navigate(nextRoute) : navigate("/");
      MenuComponent.bootstrap();
      setSubmitting(false);
      setLoading(false);
      setDisableAll(false);
    } catch (e) {
      setSubmitting(false);
      setLoading(false);
      setDisableAll(false);
    }
  }

  const { state } = useLocation() as any;

  return (
    <>
      <ToastContainer />

      <>
        <div className="text-center mb-10">
          <h1 className="text-[22px] primary-text leading-[32px] font-semibold pb-[8px]">
            {formatMessage({ id: "Sign In to Kamoto.AI" })}
          </h1>
          <div className="secondary-text text-center text-[16px]  leading-[20px] font-semibold">
            {formatMessage({ id: "New Here?" })}{" "}
            <Link
              to="/auth/registration/"
              className="main-text text-[16px]  leading-[20px] font-semibold"
            >
              {formatMessage({ id: "Create an Account" })}
            </Link>
          </div>
        </div>
        <Tabs
          className="font-size-13"
          // ulClassName="text-muted dark-border overflow-x-auto whitespace-nowrap "
          selectedTabClassName="bg-primary"
        >
          <TabList
            className={
              " text-[13px] leading-[20px] font-normal primary-text setting-tabs overflow-x-auto overflow-y-hidden whitespace-nowrap  border-main border-b"
            }
          >
            <Tab style={{ width: "50%", textAlign: "center" }}>{formatMessage({ id: "Login with Email" })}</Tab>
            <Tab style={{ width: "50%", textAlign: "center" }}>{formatMessage({ id: "Login with Phone" })}</Tab>
          </TabList>
          <TabPanel
            className="mt-5"
          >
            <Formik
              validationSchema={loginSchema}
              initialValues={initialValues}
              onSubmit={onSubmit}
              validateOnMount
            >
              {(formik) => {
                if (state) {
                  initialValues.email = state.email;
                  setEmailDisabled(true);
                }
                return (
                  <Form className="form w-100 px-3" id="kt_login_signin_form">

                    {errorsArray?.map((error: string) => (
                      <CustomToast status={error} />
                    ))}
                    <TextInput
                      isDisabled={emailDisabled}
                      fieldName={"email"}
                      formik={formik}
                      fieldType={"text"}
                      placeholder={""}
                      label={formatMessage({ id: "Email" })}
                      toolTipText={formatMessage({
                        id: "GLOBAL.TOOLTIP.LOGIN.EMAIL",
                      })}
                      isStarRequired={true}
                    />
                    <TextInput
                      fieldName={"password"}
                      formik={formik}
                      fieldType={"text"}
                      isPassword={true}
                      placeholder={""}
                      isForgetPassword={true}
                      label={formatMessage({ id: "Password" })}
                      toolTipText={formatMessage({
                        id: "GLOBAL.TOOLTIP.LOGIN.PASSWORD",
                      })}
                      isStarRequired={true}
                    />
                    <CustomButton
                      isSubmitting={formik.isSubmitting || disableAll}
                      isValid={formik.isValid}
                      buttonText={formatMessage({ id: "Continue" })}
                      customClass={"!w-full main-bg"}
                      loading={loading}
                      height={54}
                    />

                  </Form>
                );
              }}
            </Formik>
          </TabPanel>
          <TabPanel
            className=""
          >
            {
              otpSent ?
                <Formik
                  initialValues={{}}
                  // validationSchema={otpSchema}
                  onSubmit={loginUsingOtp}
                  validateOnMount
                >
                  {(formik) => {
                    return (
                      <Form>
                        <div className="flex flex-row justify-between my-5 secondary-text">
                          {
                            resentTimer > 0
                              ?
                              <>
                                <span>OTP sent. Check your phone.</span>
                                <div>
                                  Send OTP again in {`${String(Math.floor(resentTimer / 60)).padStart(2, '0')}:${String(resentTimer % 60).padStart(2, '0')}`} secs
                                </div>
                              </>
                              :
                              <div>
                                <span>OTP not received? </span>
                                <button
                                  type="button"
                                  className="text-primary"
                                  onClick={resendOtp}
                                >
                                  Send Again
                                </button>
                              </div>

                          }
                        </div>
                        <Field
                          className={'form-control secondary-bg mb-8 border-main font-size-12'}
                          name={"otp"}
                          placeholder={formatMessage({ id: "Enter Your OTP here" })}
                          type={"number"}
                        />
                        <CustomButton
                          isSubmitting={formik.isSubmitting || disableAll}
                          isValid={formik.isValid}
                          buttonText={formatMessage({ id: "Login" })}
                          customClass={"!w-full main-bg"}
                          loading={loading}
                          height={54}
                        />
                      </Form>
                    );
                  }}
                </Formik>
                :
                <Formik
                  initialValues={{ country: defaultCountry.value }}
                  validationSchema={otpSchema}
                  onSubmit={submitPhoneForOtp}
                  validateOnMount
                >
                  {(formik) => {
                    return (
                      <Form>
                        <div className="flex items-start">
                          <FormikReactSelect
                            name={'country'}
                            className={"mr-2 w-[150px]"}
                            options={referenceData?.platformOptions?.countries?.map((el) => { return { value: el.phone_code + el.country_code, label: `${el?.country_name} (+${el?.phone_code})` } })}
                            // noRightBorder={true}
                          // value={defaultCountry}
                          />
                          {/* <div className={"mb-[16px] md:mb-[24px]"}> */}
                          <Field
                            className={'form-control ml-3 secondary-bg border-main font-size-12'}
                            name={"contact"}
                            type={"number"}
                          />
                          {/* </div> */}
                        </div>
                        <CustomButton
                          isSubmitting={formik.isSubmitting || disableAll}
                          isValid={formik.isValid}
                          buttonText={formatMessage({ id: "Send OTP" })}
                          customClass={"!w-full main-bg"}
                          loading={loading}
                          height={54}
                        />
                      </Form>
                    );
                  }}
                </Formik>
            }
          </TabPanel>
        </Tabs>
        <div className="">
          <div className="separator separator-content my-14">
            <span className="  text-[14px] leading-[20px] font-normal text-uppercase primary-text">
              {formatMessage({ id: "Or" })}
            </span>
          </div>
        </div>
        <SocialLoginButtons
          setDisableAll={setDisableAll}
          disableAll={disableAll}
        />
      </>

    </>
  );
}
