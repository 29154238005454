import React, { useState } from "react";
import TextareaAutosize from "react-textarea-autosize";
import { FileUpload } from "../../../widgets/components/FileUpload";
import { CustomButton } from "../../../widgets/components/UI/CustomButton";
import { addNewPost } from "../../core/_request";
import { useAuth } from "../../../auth";
import { toast } from "react-toastify";
import { getCdnUrl } from "../../../../core/_util";
import { useIntl } from "react-intl";
import useGTM from "../../../../hooks/useGTM";
import { GTMEvent } from "../../../../hooks/gtm_helpers";

interface UnpublishedProps {
  setPosts: (posts: any) => any;
  personalityImage: string;
}
const Unpublished = ({ setPosts, personalityImage }: UnpublishedProps) => {
  const { formatMessage } = useIntl();

  const [modal, setModal] = useState<boolean>(false);
  const [image, setImage] = useState<string>("");
  const [content, setContent] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);
  const { personalityId } = useAuth();
  const {dataLayerPush} = useGTM()
  async function handleSubmit() {
    if (!personalityId || (!image && !content)) {
      return;
    }
    setLoading(true);
    const {
      data: { data, success, errors },
    } = await addNewPost(personalityId, image, content);
    if (success) {
      toast.success("Post added successfully");
      setPosts((posts: any) => [{ ...data, likes: 0, isLiked: 0 }, ...posts]);
      setContent("");
      setImage("");
      dataLayerPush(GTMEvent.PostCreationSuccessful)
    } else {
      toast.error("Error while adding post",
      {
        autoClose: false,
        closeButton: true, // Display close button (cross icon)
      });
      dataLayerPush(GTMEvent.PostCreationFailed)
    }
    setLoading(false);
  }

  return (
    <div
      className={
        "secondary-bg  border-main rounded  border p-[8px] md:p-[16px_32px_12px_32px]"
      }
    >
      <div className={"secondary-text flex justify-between mb-[60px]"}>
        <div className={"flex w-full gap-[8px]"}>
          <img
            alt="Pic"
            src={personalityImage}
            className={"w-[48px] h-[48px] rounded-full object-cover object-top"}
          />
          <div className={"flex-grow"}>
            <TextareaAutosize
              value={content}
              maxLength={1000}
              onChange={({ target: { value } }) => setContent(value)}
              className={
                "text-[13px] leading-[20px] input-placeholder placeholder:font-normal bg-transparent w-full form-control border-0 border-transparent focus:border-transparent focus:ring-0 "
              }
              data-kt-element="input"
              placeholder={formatMessage({
                id: "Enter some funny conversations or interactions with the AI personality",
              })}
            />
          </div>
        </div>
      </div>
      {image && (
        <img
          className="max-h-[600px] object-contain mx-auto my-4"
          src={getCdnUrl(image)}
        />
      )}
      <div className={"flex items-center gap-[8px] justify-between"}>
        <button
          className={"px-[12px] py-[8px] h-[44px] primary-bg rounded"}
          onClick={() => setModal(true)}
        >
          <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M12.5 6.66667H12.5083M2.5 5C2.5 4.33696 2.76339 3.70107 3.23223 3.23223C3.70107 2.76339 4.33696 2.5 5 2.5H15C15.663 2.5 16.2989 2.76339 16.7678 3.23223C17.2366 3.70107 17.5 4.33696 17.5 5V15C17.5 15.663 17.2366 16.2989 16.7678 16.7678C16.2989 17.2366 15.663 17.5 15 17.5H5C4.33696 17.5 3.70107 17.2366 3.23223 16.7678C2.76339 16.2989 2.5 15.663 2.5 15V5Z"
              className={"icon-stroke"}
              strokeWidth="1.4"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M2.5 13.3333L6.66667 9.16665C7.44 8.42248 8.39333 8.42248 9.16667 9.16665L13.3333 13.3333"
              className={"icon-stroke"}
              strokeWidth="1.4"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M11.667 11.6667L12.5003 10.8333C13.2737 10.0892 14.227 10.0892 15.0003 10.8333L17.5003 13.3333"
              className={"icon-stroke"}
              strokeWidth="1.4"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </button>
        <FileUpload
          fileSize={2097152}
          maxFileNumber={1}
          allowType={["image/*", ".jpg", ".jpeg", ".png"]}
          metaData={{ module: "profileimg", isProtected: true }}
          modalStatus={modal}
          handleClose={() => setModal(false)}
          handleSuccess={(id: number, name: string) => {
            setImage(name);
          }}
          resourceType="user-posts"
        />
        <CustomButton
          isSubmitting={loading}
          isValid={!!content.trim() || !!image}
          buttonText={formatMessage({
            id: "Post Now",
          })}
          loading={loading}
          customClass={"w-full m-0 main-button"}
          onSubmit={handleSubmit}
        />
      </div>
    </div>
  );
};

export default Unpublished;
