import React, { useEffect, useState } from "react";
import { get, isEqual, map, range } from "lodash";
import clsx from "clsx";
import { useIntl } from "react-intl";
import CustomTable from "../../../components/table";
import { useDateFormat } from "../../../hooks/useDateFormat";
import { useTimeZone } from "../../../hooks/useTimeZone";
import { getApiLogs } from "../core/_requests";
import { TabList, Tabs, Tab, TabPanel } from "react-tabs";
import { useSearchParams } from "react-router-dom";


const PrettyPrintJSON = ({ jsonString }:{jsonString:string}) => {
  try {
    const parsedJSON = JSON.parse(jsonString);
    const prettyJSON = JSON.stringify(parsedJSON, null, 2);

    return (
      <pre>
        {prettyJSON}
      </pre>
    );
  } catch (error) {
    return (
      <>{jsonString}</>
    );
  }
};


const Logs = () => {
  const [activeLog, setActiveLog] = useState<any>(null);
 
  const { formatMessage } = useIntl();
  const [loading, setLoading] = useState<boolean>(true);
  const { getDateValue } = useDateFormat();
  const { getTimeZoneValue } = useTimeZone();
  const [data, setData] = useState<any[]>([])
  const [pageCount, setPageCount] = useState<number>(0)
  const [searchParams, setSearchParams] = useSearchParams()
  const [type, setType] = useState<string>('')
  const [activeTab, setActiveTab] = useState<number>(-1)
  const mappings:{[key:number]:string} = {
    0:'all',
    1:'success',
    2:'failed'
  }
  const revMap:{[key:string]:number} = {
    'all':0,
    'success':1,
    'failed':2
  }

  useEffect(() => {
    if(activeTab<0){
      return;
    }
    searchParams.set('type', '' + mappings[activeTab])
    setSearchParams(searchParams)
    setType(mappings[activeTab])
  }, [activeTab])

  useEffect(() => {
    let tab = searchParams.get('type')
    console.log({tab})
    if (tab) {
      setActiveTab(revMap[tab] ?? 0)
    } else {
      searchParams.set('type','all')
      setType('all')
      setActiveTab(0)
    }
  }, [searchParams])

   const TabLabel:React.FC<any> = ({activeTab})=>{
    return <>
        <Tabs
        defaultIndex={activeTab}
        selectedIndex={activeTab}
        className="font-size-13 h-[28px]"
        selectedTabClassName="bg-primary"
        onSelect={(index) => {
          setActiveTab(index)
        }}
      >
        <TabList
          className={
            " text-[13px] leading-[20px] font-normal primary-text setting-tabs overflow-x-auto overflow-y-hidden whitespace-nowrap border-none"
          }
        >
          <Tab>{formatMessage({ id: "All" })}</Tab>
          <Tab>{formatMessage({ id: "Success" })}</Tab>
          <Tab>{formatMessage({ id: "Errors" })}</Tab>
        </TabList>
      </Tabs>
      </>
   }
  const headers = [
    {
      label: <TabLabel activeTab={activeTab}/>,
      key: "today.time",
      render: "today",
      enableSorting:false,
      cell: true,
      
    },
  ];
  const columns = React.useMemo<any>(
    () =>
      map(headers, (item) => {
        return {
          header: get(item, "label"),
          accessorKey: get(item, "render"),
          enableSorting: get(item, "enableSorting"),
          cell: (info: any) => {
            if (item.cell && isEqual(get(item, "render"), "today")) {
              return (
                <div
                  key={info.getValue().id}
                  onClick={() => setActiveLog(info?.row?.original?.today)}
                  className={clsx(
                    "px-2 w-full  h-full text-[13px] leading-[18px] primary-text  flex gap-x-4 items-center justify-between cursor-pointer"
                  )}
                >
                 
                  <div>
                 
                    <div className={"flex items-center gap-x-4"}>
                      <div
                        className={
                          "px-4 py-[3px] secondary-bg whitespace-nowrap rounded-[40px] text-[10px] leading-[14px] font-medium primary-text"
                        }
                      >
                        {info.getValue().status}
                      </div>
                      <div>{info.getValue().method}</div>
                      <p>{info.getValue().url}</p>
                    </div>
                  </div>
                  <div>{info.getValue().time}</div>
                </div>
              );
            } else {
              return null;
            }
          },
          footer: (props: any) => props.column.id,
        };
      }),

    [activeTab]
  );

  async function getLogs({page, size, search}:any) {
    if(!type) return;
    setLoading(true);
    const {
      data: { success, data },
    } = await getApiLogs(type,{
      page: page + 1,
      limit: size,
      search
    });
    if (success) {
      let formattedData = data.rows.map(
        ({
          httpMethod,
          httpStatus,
          id,
          response,
          ip,
          source,
          endpoint,
          apiVersion,
          createdAt

        }: any) => {
          return {
            today:{
           method: httpMethod?.toUpperCase(),
           status: httpStatus,
           url: endpoint ? new URL(endpoint).pathname : '',
           id,
           response,
           endpoint,
           ip,
           source,
           version: apiVersion,
           time: `${getDateValue(createdAt)} ${getTimeZoneValue(createdAt)}`
            },
          };
        }
        );
        setLoading(false);
        setData(formattedData)
        setActiveLog(formattedData[0]?.today)
        setPageCount(Math.ceil(data.count / size))
    }
  }

  useEffect(()=>{
    type && getLogs({page: 0, size: 10, search:''})
  },[type])

  return (
    <div className={"mt-[0px] md:mt-4"}>
      <div
        className={
          "tab-container shadow-default sm:py-[14px] sm:px-[6px] md:p-6 rounded min-h-[70vh] flex flex-col justify-between"
        }
      >
        <div>
          <CustomTable
            optionalTrigger={['search']}
            queryKey="api-logs"
            tableData={data}
            pageCount={pageCount}
            headers={headers}
            columns={columns}
            fullTd={true}
            type={"logs"}
            fetchData={getLogs}
            loading={loading}
            extra={
              activeLog && <div
                className={
                  "col-span-12 md:col-span-6  card-bg shadow-default  rounded py-6"
                }
              >
                <h3
                  className={
                    "text-[14px] md:text-[16px] leading-6  secondary-text font-semibold mb-6 p-6 pb-0  break-all"
                  }
                >
                    {activeLog.method}  {activeLog.url}
                </h3>
                <div
                  className={
                    "grid grid-cols-12 gap-x-2 md:gap-y-3.5 px-6 text-[13px] leading-5 secondary-text"
                  }
                >
                  <div className={"col-span-6 md:col-span-4 primary-text"}>
                    {formatMessage({ id: "Status" })}
                  </div>
                  <div className={"col-span-6 md:col-span-8"}>{activeLog.status}</div>
                  <div className={"col-span-6 md:col-span-4 primary-text"}>
                    {formatMessage({ id: "Request ID" })}
                  </div>
                  <div className={"col-span-6 md:col-span-8"}>
                    {activeLog.id}
                  </div>
                  <div className={"col-span-6 md:col-span-4 primary-text"}>
                    {formatMessage({ id: "Time" })}
                  </div>
                  <div className={"col-span-6 md:col-span-8"}>
                    {activeLog.time}
                  </div>
                  <div className={"col-span-6 md:col-span-4 primary-text"}>
                    {formatMessage({ id: "IP Address" })}
                  </div>
                  <div className={"col-span-6 md:col-span-8"}>
                    {activeLog.ip}
                  </div>
                  <div className={"col-span-6 md:col-span-4 primary-text"}>
                    {formatMessage({ id: "API Version" })}
                  </div>
                  <div className={"col-span-6 md:col-span-8 main-text"}>
                    {activeLog.version}
                  </div>
                  <div className={"col-span-6 md:col-span-4 primary-text"}>
                    {formatMessage({ id: "Source" })}
                  </div>
                  <div className={"col-span-6 md:col-span-8 break-all"}>
                    {activeLog.source}
                  </div>
                  <div className={"col-span-6 md:col-span-4 primary-text"}>
                    {formatMessage({ id: "Endpoint" })}
                  </div>
                  <div
                    className={"col-span-6 md:col-span-8 main-text  break-all"}
                  >
                    {activeLog.endpoint}
                  </div>
                </div>
                <div className={"w-full border-b main-border my-6"}></div>
                <h3
                  className={
                    "text-[16px] leading-6  secondary-text font-semibold mb-6 px-6"
                  }
                >
                  {formatMessage({ id: "Response body" })}
                </h3>
                <div className="text-break px-6">
                  <PrettyPrintJSON jsonString={activeLog.response}/>
                </div>
              </div>
            }
            emptyControl={{
              heading:formatMessage({ id: "No Logs found"}),
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default Logs;
