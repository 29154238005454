import React from "react";
import { Modal } from "react-bootstrap";
import { ToolTipUI } from "../../../../widgets/components/UI/ToolTipUI";
import { useIntl } from "react-intl";
import avatarImage from "../../../../../assets/images/profile/profile-image.png";
import { Link } from "react-router-dom";
import { useAuth } from "../../../../auth";
import { getCdnUrl } from "../../../../../core/_util";

const LicenceModal: React.FC<any> = ({ open, setOpen, setSubscribed }) => {
  const { personalityInView,currentUser } = useAuth();
  const { formatMessage } = useIntl();
  const licensed = personalityInView?.isLicensed || false;
  const forDemo = personalityInView?.forDemo || false;
  return (
    <Modal
      size="lg"
      show={open}
      onHide={() => setOpen(false)}
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <div className={"flex items-center justify-between w-full"}>
          <div className="d-flex fw-bold">
            <h2 className="font-size-20">
              {formatMessage({
                id: licensed
                  ? "Licensed AI Personality"
                  : "Unlicensed AI Personality",
              })}
            </h2>{" "}
            <ToolTipUI
              tooltipText={formatMessage({
                id: "GLOBAL.TOOLTIP.BILLING.UPGRADE_PLAN",
              })}
            />
          </div>
          <button onClick={() => setOpen(false)}>
            <svg
              width="32"
              height="32"
              viewBox="0 0 32 32"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M21.6568 8.45747L8.45746 21.6568C7.93676 22.1775 7.93676 23.0217 8.45746 23.5424C8.97816 24.0631 9.82238 24.0631 10.3431 23.5424L23.5424 10.3431C24.0631 9.82239 24.0631 8.97817 23.5424 8.45747C23.0217 7.93677 22.1775 7.93677 21.6568 8.45747Z"
                fill="white"
              />
              <path
                opacity="0.5"
                d="M23.5425 21.6578L10.3432 8.45843C9.8225 7.93773 8.97828 7.93773 8.45758 8.45843C7.93688 8.97913 7.93688 9.82335 8.45758 10.3441L21.6569 23.5434C22.1776 24.0641 23.0218 24.0641 23.5425 23.5434C24.0632 23.0227 24.0632 22.1785 23.5425 21.6578Z"
                fill="white"
              />
            </svg>
          </button>
        </div>
      </Modal.Header>
      <Modal.Body className={"flex justify-center flex-col"}>
        <div className={"text-center"}>
          <div className={"flex justify-center"}>
            <div
              className={
                "secondary-bg shadow-default rounded-full p-[4px] md:p-1.5"
              }
            >
              <img
                src={getCdnUrl(personalityInView?.image)}
                className={"rounded-full h-[60px] md:h-[108px]  md:w-[108px]"}
                alt=""
              />
            </div>
          </div>
          {licensed ? (
            <button
              className={
                "flex items-center mx-auto mt-[14px] gap-x-2 bg-[#C2D24B1A] text-[#C2D24B] px-[24px] py-[8px] rounded"
              }
            >
              <svg
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g clipPath="url(#clip0_1993_28135)">
                  <path
                    d="M10 12.5C10 13.163 10.2634 13.7989 10.7322 14.2678C11.2011 14.7366 11.837 15 12.5 15C13.163 15 13.7989 14.7366 14.2678 14.2678C14.7366 13.7989 15 13.163 15 12.5C15 11.837 14.7366 11.2011 14.2678 10.7322C13.7989 10.2634 13.163 10 12.5 10C11.837 10 11.2011 10.2634 10.7322 10.7322C10.2634 11.2011 10 11.837 10 12.5Z"
                    stroke="#C2D24B"
                    strokeWidth="1.4"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M10.834 14.582V18.332L12.5007 17.082L14.1673 18.332V14.582"
                    stroke="#C2D24B"
                    strokeWidth="1.4"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M8.33333 15.8327H4.16667C3.72464 15.8327 3.30072 15.6571 2.98816 15.3445C2.67559 15.032 2.5 14.608 2.5 14.166V5.83268C2.5 4.91602 3.25 4.16602 4.16667 4.16602H15.8333C16.2754 4.16602 16.6993 4.34161 17.0118 4.65417C17.3244 4.96673 17.5 5.39065 17.5 5.83268V14.166C17.4997 14.4583 17.4225 14.7453 17.2763 14.9984C17.13 15.2514 16.9198 15.4615 16.6667 15.6077M5 7.49935H15M5 9.99935H7.5M5 12.4993H6.66667"
                    stroke="#C2D24B"
                    strokeWidth="1.4"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_1993_28135">
                    <rect width="20" height="20" fill="white" />
                  </clipPath>
                </defs>
              </svg>
              {formatMessage({ id: "Licensed AI Personality" })}
            </button>
          ) : (
            <button
              className={
                "flex items-center mx-auto mt-[14px] gap-x-2 bg-[#C2D24B1A] text-[#C2D24B] px-[24px] py-[8px] rounded"
              }
            >
              {formatMessage({ id: "Unlicensed AI Personality" })}
            </button>
          )}
          <div className={"flex justify-center mb-[18px]"}></div>
          <h2
            className={
              "text-[14px] md:text-[24px] leading-5 md:leading-[32px] primary-text font-semibold mb-[14px]"
            }
          >
            {
              licensed ? 
              formatMessage({id: "Licensed AI Personality"})
              :
              (
                forDemo ? 
                formatMessage({id:"This is the Demo AI Personality"})
                :
                formatMessage({id: "This is the Unlicensed AI Personality"})
              )
            }
          </h2>
          <div className={"flex justify-center"}>
            <p
              className={
                "max-w-xl mb-[20px] text-[16px] leading-6 font-semibold secondary-text"
              }
            >
              {licensed ? (
                <>{`This is a Licensed & Official AI Personality of ${personalityInView?.name}`}</>
              ) : (
                forDemo ? 
                <>
                  {formatMessage({
                    id: "If you are the original owner of this IP or AI personality, please claim it by clicking on the below button",
                  })}
                </>
                :
                <>
                  {formatMessage({id: "If you are the owner of this AI personality, please request for verification by clicking on the below button"})}
                </>
              )}
            </p>
          </div>
          <p className={"text-[14px] leading-6 secondary-text"}>
            {" "}
            {formatMessage({
              id: "For more information, ",
            })}
            <a
              href="https://help.kamoto.ai/en/articles/8329637-all-you-need-to-know-about-getting-your-ai-personality-verified-on-kamoto-ai"
              target="_blank"
              rel="noreferrer"
              className={"text-[#C2D24B] underline"}
            >
              {formatMessage({
                id: "Click Here",
              })}
            </a>
          </p>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <div className={"flex flex-grow md:justify-end gap-x-4"}>
          {!licensed && (
            forDemo ?
            <a
              href={`mailto:support@kamoto.ai?cc=tosh@kamoto.ai&subject=Claiming%20Demo%20AI%20personality%20of%20${personalityInView?.name}&body=Hi%2C%0A%0AI%20want%20to%20claim%20the%20demo%20AI%20personality%20of%20${personalityInView?.name}%20available%20at%20${window.location.origin}/p/${personalityInView?.username} .%0A%0APlease%20let%20me%20know%20the%20process.%0A%0A`}
              // onClick={() => setOpenSubscribeModal(true)}
              className={
                "export-button w-[160px] flex items-center justify-center py-[16px] text-[13px] md:text-[14px] leading-5 font-semibold rounded"
              }
              target="_blank"
              rel="noreferrer"
              onClick={() => setOpen(false)}
            >
              {formatMessage({
                id: "Claim AI Personality",
              })}
            </a>
            :
            <a
              href={`mailto:support@kamoto.ai?cc=tosh@kamoto.ai&subject=Verification%20Request%20of%20AI%20personality%20${personalityInView?.name}&body=Hi%2C%0A%0AI%20want%20to%20request%20the%20verification%20of%20AI%20personality%20${personalityInView?.name}%20available%20at%20${window.location.origin}/p/${personalityInView?.username} .%0A%0APlease%20let%20me%20know%20the%20process.%0A%0A`}
              // onClick={() => setOpenSubscribeModal(true)}
              className={
                "export-button w-[160px] flex items-center justify-center py-[16px] text-[13px] md:text-[14px] leading-5 font-semibold rounded"
              }
              target="_blank"
              rel="noreferrer"
              onClick={() => setOpen(false)}
            >
              {formatMessage({
                id: "Request Verification",
              })}
            </a>
          )}
          <button
            className={
              "secondary-bg py-[12px] px-[24px] rounded w-full md:w-auto"
            }
            onClick={() => {
              setOpen(false);
            }}
          >
            {formatMessage({
              id: "Ok",
            })}
          </button>
        </div>
      </Modal.Footer>
    </Modal>
  );
};

export default LicenceModal;
