import React from "react";

import InteractionContainer from "../../containers/interaction-container";
const Index = () => {
  return (
    <div>
      <InteractionContainer />
    </div>
  );
};

export default Index;
