import React from "react";
import AiImage from "./ai-avatar.png";
import { useIntl } from "react-intl";

const Index = () => {
  const { formatMessage } = useIntl();

  return (
    <div className={"bg-[#6B4DFF] p-[8px] md:p-1 md:pr-2 rounded mb-[20px]"}>
      <div className={"items-center justify-between md:gap-x-6 hidden lg:flex"}>
        <div className={"flex flex-row items-center gap-x-2"}>
          <div>
            <img src={AiImage} className="min-w-[70px]" />
          </div>
          <div>
            <div>
              <h5
                className={
                  "text-[16px] leading-6 text-[#FFFFFFCC] font-semibold mb-1"
                }
              >
                {formatMessage({
                  id: "Create Your Own Personality And Monetize It",
                })}
              </h5>
              <p className={"text-[14px] leading-5 text-[#FFFFFFA6]"}>
                {formatMessage({
                  id: "You can create a custom AI personality and earn revenue whenever other users interact with it",
                })}
              </p>
            </div>
          </div>
        </div>
        <div className={"flex items-center gap-x-3.5"}>
          <button
            className={
              "flex items-center font-weight-500 gap-x-2.5 bg-[#C2D24B] px-6 py-3 text-black rounded whitespace-nowrap"
            }
          >
            <svg
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g clipPath="url(#clip0_497_19554)">
                <path
                  d="M5 17.5V15.8333C5 14.9493 5.35119 14.1014 5.97631 13.4763C6.60143 12.8512 7.44928 12.5 8.33333 12.5H10.8333M17.5 12.5H15.4167C15.0851 12.5 14.7672 12.6317 14.5328 12.8661C14.2984 13.1005 14.1667 13.4185 14.1667 13.75C14.1667 14.0815 14.2984 14.3995 14.5328 14.6339C14.7672 14.8683 15.0851 15 15.4167 15H16.25C16.5815 15 16.8995 15.1317 17.1339 15.3661C17.3683 15.6005 17.5 15.9185 17.5 16.25C17.5 16.5815 17.3683 16.8995 17.1339 17.1339C16.8995 17.3683 16.5815 17.5 16.25 17.5H14.1667M15.8333 17.5V18.3333M15.8333 11.6667V12.5M6.66667 5.83333C6.66667 6.71739 7.01786 7.56524 7.64298 8.19036C8.2681 8.81548 9.11594 9.16667 10 9.16667C10.8841 9.16667 11.7319 8.81548 12.357 8.19036C12.9821 7.56524 13.3333 6.71739 13.3333 5.83333C13.3333 4.94928 12.9821 4.10143 12.357 3.47631C11.7319 2.85119 10.8841 2.5 10 2.5C9.11594 2.5 8.2681 2.85119 7.64298 3.47631C7.01786 4.10143 6.66667 4.94928 6.66667 5.83333Z"
                  stroke="black"
                  strokeWidth="1.4"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </g>
              <defs>
                <clipPath id="clip0_497_19554">
                  <rect width="20" height="20" fill="white" />
                </clipPath>
              </defs>
            </svg>
            {formatMessage({
              id: "Create Your Own AI Personality",
            })}
          </button>
          <button>
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g clipPath="url(#clip0_1993_27688)">
                <path
                  d="M18.3002 5.71022C18.2077 5.61752 18.0978 5.54397 17.9768 5.49379C17.8559 5.44361 17.7262 5.41778 17.5952 5.41778C17.4643 5.41778 17.3346 5.44361 17.2136 5.49379C17.0926 5.54397 16.9827 5.61752 16.8902 5.71022L12.0002 10.5902L7.11022 5.70022C7.01764 5.60764 6.90773 5.5342 6.78677 5.4841C6.6658 5.43399 6.53615 5.4082 6.40522 5.4082C6.27429 5.4082 6.14464 5.43399 6.02368 5.4841C5.90272 5.5342 5.79281 5.60764 5.70022 5.70022C5.60764 5.79281 5.5342 5.90272 5.4841 6.02368C5.43399 6.14464 5.4082 6.27429 5.4082 6.40522C5.4082 6.53615 5.43399 6.6658 5.4841 6.78677C5.5342 6.90773 5.60764 7.01764 5.70022 7.11022L10.5902 12.0002L5.70022 16.8902C5.60764 16.9828 5.5342 17.0927 5.4841 17.2137C5.43399 17.3346 5.4082 17.4643 5.4082 17.5952C5.4082 17.7262 5.43399 17.8558 5.4841 17.9768C5.5342 18.0977 5.60764 18.2076 5.70022 18.3002C5.79281 18.3928 5.90272 18.4662 6.02368 18.5163C6.14464 18.5665 6.27429 18.5922 6.40522 18.5922C6.53615 18.5922 6.6658 18.5665 6.78677 18.5163C6.90773 18.4662 7.01764 18.3928 7.11022 18.3002L12.0002 13.4102L16.8902 18.3002C16.9828 18.3928 17.0927 18.4662 17.2137 18.5163C17.3346 18.5665 17.4643 18.5922 17.5952 18.5922C17.7262 18.5922 17.8558 18.5665 17.9768 18.5163C18.0977 18.4662 18.2076 18.3928 18.3002 18.3002C18.3928 18.2076 18.4662 18.0977 18.5163 17.9768C18.5665 17.8558 18.5922 17.7262 18.5922 17.5952C18.5922 17.4643 18.5665 17.3346 18.5163 17.2137C18.4662 17.0927 18.3928 16.9828 18.3002 16.8902L13.4102 12.0002L18.3002 7.11022C18.6802 6.73022 18.6802 6.09022 18.3002 5.71022Z"
                  fill="white"
                  fillOpacity="0.5"
                />
              </g>
              <defs>
                <clipPath id="clip0_1993_27688">
                  <rect width="24" height="24" fill="white" />
                </clipPath>
              </defs>
            </svg>
          </button>
        </div>
      </div>
      <div className={"lg:hidden flex flex-col gap-y-3.5"}>
        <div className={"flex items-center gap-x-2.5"}>
          <img src={AiImage} alt={"Image of AI"} />
          <h3
            className={"text-[14px] leading-5 font-semibold text-[#FFFFFFCC]"}
          >
            {formatMessage({
              id: "Create your own personality and monetise it",
            })}
          </h3>
        </div>
        <p>
          {formatMessage({
            id: "You can create a custom personality and earn revenue whenever other users interact with it",
          })}
        </p>
        <button
          className={
            "flex justify-center font-weight-500 items-center gap-x-2.5 bg-[#C2D24B] px-6 py-3 text-black rounded whitespace-nowrap"
          }
        >
          <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g clipPath="url(#clip0_497_19554)">
              <path
                d="M5 17.5V15.8333C5 14.9493 5.35119 14.1014 5.97631 13.4763C6.60143 12.8512 7.44928 12.5 8.33333 12.5H10.8333M17.5 12.5H15.4167C15.0851 12.5 14.7672 12.6317 14.5328 12.8661C14.2984 13.1005 14.1667 13.4185 14.1667 13.75C14.1667 14.0815 14.2984 14.3995 14.5328 14.6339C14.7672 14.8683 15.0851 15 15.4167 15H16.25C16.5815 15 16.8995 15.1317 17.1339 15.3661C17.3683 15.6005 17.5 15.9185 17.5 16.25C17.5 16.5815 17.3683 16.8995 17.1339 17.1339C16.8995 17.3683 16.5815 17.5 16.25 17.5H14.1667M15.8333 17.5V18.3333M15.8333 11.6667V12.5M6.66667 5.83333C6.66667 6.71739 7.01786 7.56524 7.64298 8.19036C8.2681 8.81548 9.11594 9.16667 10 9.16667C10.8841 9.16667 11.7319 8.81548 12.357 8.19036C12.9821 7.56524 13.3333 6.71739 13.3333 5.83333C13.3333 4.94928 12.9821 4.10143 12.357 3.47631C11.7319 2.85119 10.8841 2.5 10 2.5C9.11594 2.5 8.2681 2.85119 7.64298 3.47631C7.01786 4.10143 6.66667 4.94928 6.66667 5.83333Z"
                stroke="black"
                strokeWidth="1.4"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </g>
            <defs>
              <clipPath id="clip0_497_19554">
                <rect width="20" height="20" fill="white" />
              </clipPath>
            </defs>
          </svg>
          {formatMessage({
            id: "Create Your Own AI Personality",
          })}
        </button>
      </div>
    </div>
  );
};

export default Index;
