import React, { useState } from "react";
import TextInput from "../../../widgets/components/Input/TextInput";
import { useIntl } from "react-intl";
import { FieldArray, Form, Formik } from "formik";
import TextArea from "../../../widgets/components/Input/TextArea";
import FormikReactSelect from "../../../widgets/components/Input/FormikReactSelect";
import { isEqual } from "lodash";
import Select from "../../../../components/select/select";
import { InfoCard } from "../../../widgets/components/UI/InfoCard";
import * as Yup from "yup";
import { SelectInput } from "../../../widgets/components/Input/SelectInput";
import { useAuth } from "../../../auth";
import FormikReactCreatable from "../../../widgets/components/Input/FormikReactCreatable";
import { updatePersonalityInfo } from "../../../profile/core/_requests";
import { toast } from "react-toastify";
import useGTM from "../../../../hooks/useGTM";
import { GTMEvent } from "../../../../hooks/gtm_helpers";

const Basic: React.FC<any> = ({ setOpenEdit, personalityData }) => {
  const { formatMessage } = useIntl();
  const {
    referenceData,
    setSelectedPersonality,
    selectedPersonality: updatedData,
  } = useAuth();
  personalityData = updatedData || personalityData;
  const [disableSave, setDisableSave] = useState(false);
  const {dataLayerPush} = useGTM()

  const onSubmit = async (values: any, { setSubmitting }: any) => {
    // add additional data from main object
    values.full_name = personalityData.name;
    values.gender = personalityData.gender;
    values.personality_type = personalityData.personalityType;
    values.industry = personalityData.industry;
    // save data to api
    setDisableSave(true);
    setSubmitting(true);
    try {
      let {
        data: { success, data },
      } = await updatePersonalityInfo({
        personalityId: personalityData.personalityId,
        personalityJson: {
          ...personalityData.personalityJson,
          basic_details_of_ai_personality: values,
        },
      });
      if (success) {
        setSelectedPersonality(data);
        toast.success(
          formatMessage({ id: "Basic details updated successfully" })
        );
      } else {
        setSubmitting(false);
        toast.error(formatMessage({ id: "Error when updating Basic details" }),
        {
          autoClose: false,
          closeButton: true, // Display close button (cross icon)
        });
      }
      setDisableSave(false);
      dataLayerPush(GTMEvent.AIPersonalityEditSuccessful,{"tab": "basic-details"})
    } catch (e) {
      toast.error(formatMessage({ id: "Error when updating Basic details" }),
      {
        autoClose: false,
        closeButton: true, // Display close button (cross icon)
      });
      setSubmitting(false);
      setDisableSave(false);
      dataLayerPush(GTMEvent.AIPersonalityEditFailed,{"tab": "basic-details"})
    }
  };

  // const basicDetailsValidation = Yup.object().shape({
  //   description: Yup.string().required(
  //     formatMessage({ id: "Description is required" })
  //   ).min(20).max(500),
  //   difficulties: Yup.string().required(
  //     formatMessage({ id: "Difficulties is required" })
  //   ).min(10).max(500),
  //   motivations: Yup.string().required(
  //     formatMessage({ id: "Motivations is required" })
  //   ).min(10).max(500),
  //   native_language_of_this_ai_personality: Yup.string().required(
  //     formatMessage({ id: "Please select native language of this ai Personality" })
  //   ),
  //   languages_spoken_by_this_ai_personality: Yup.array().required(
  //     formatMessage({ id: "Please select spoken languages of this ai Personality" })
  //   ).min(1, formatMessage({ id: "Please select at least 1 spoken language for this ai Personality" }))
  //     .max(4, formatMessage({ id: "You can select maximum of 4 spoken languages for this ai Personality" })),
  // });

  const basicDetailsValidation = Yup.object().shape({
    native_language_of_this_ai_personality: Yup.string(),
    // .required(
    //   formatMessage({
    //     id: "Please select native language of this ai Personality",
    //   })
    // )
    languages_spoken_by_this_ai_personality: Yup.array()
      // .required(
      //   formatMessage({
      //     id: "Please select spoken languages of this ai Personality",
      //   })
      // )
      .min(
        1,
        formatMessage({
          id: "Please select at least 1 spoken language for this ai Personality",
        })
      )
      .max(
        4,
        formatMessage({
          id: "You can select maximum of 4 spoken languages for this ai Personality",
        })
      ),
    // personality_type: Yup.string().oneOf(
    //   [
    //     "Animal Companion",
    //     "Artist",
    //     "Astronaut",
    //     "Author",
    //     "Business Tycoon",
    //     "Cartoon Character",
    //     "Celebrity",
    //     "Chef",
    //     "Coach",
    //     "Comedian",
    //     "Dancer",
    //     "Detective",
    //     "Doctor",
    //     "Explorer",
    //     "Fantasy Creature",
    //     "Fictional Character",
    //     "Gamer",
    //     "Historical Figure",
    //     "Historical Mythological Character",
    //     "Imaginary Personality",
    //     "Impersonator",
    //     "Influencer",
    //     "Lawyer",
    //     "Magician",
    //     "Mentor",
    //     "Muse or Inspiration",
    //     "Musician",
    //     "News Anchor",
    //     "Philosopher",
    //     "Political Figure",
    //     "Robot Companion",
    //     "Scientist",
    //     "Spiritual Guru",
    //     "Sports Person",
    //     "Superhero",
    //     "Support Agent",
    //     "Talk Show Host",
    //     "Teacher",
    //     "Villain",
    //     "Virtual Assistant",
    //     "Other",
    //   ],
    //   "Invalid category"
    // ),
    description: Yup.string()
      .required("Description is required")
      .min(20, "Description must have at least 20 characters")
      .max(1000, "Description can have at most 500 characters"),
    motivations: Yup.string()
      .min(10, "Motivations must have at least 10 characters")
      .max(500, "Motivations can have at most 500 characters"),
    difficulties: Yup.string()
      .min(10, "Difficulties must have at least 10 characters")
      .max(500, "Difficulties can have at most 500 characters"),
    food_choices_of_this_ai_personality: Yup.array()
      .of(
        Yup.string().oneOf(
          [
            "American",
            "Brazilian",
            "Burgers",
            "Caribbean",
            "Chinese",
            "French",
            "Gluten-free",
            "Greek",
            "Indian",
            "Italian",
            "Japanese",
            "Korean",
            "Mediterranean",
            "Mexican",
            "Middle Eastern",
            "Pasta",
            "Pizza",
            "Salads",
            "Seafood",
            "Spanish",
            "Steaks and grills",
            "Sushi",
            "Thai",
            "Vegan",
            "Vegetarian",
            "Vietnamese",
            "Other",
          ],
          "Invalid food choice"
        )
      )
      .min(1, "At least 1 food choice is required")
      .max(6, "Food choices cannot exceed 6")
      .test("unique", "Food choices must be unique", (value) => {
        if (value) {
          const uniqueSet = new Set(value);
          return uniqueSet.size === value.length;
        }
        return true;
      }),
    favorite_foods_of_this_ai_personality: Yup.array()
      .of(
        Yup.string()
          .min(3, "Favorite food must have at least 3 characters")
          .max(50, "Favorite food can have at most 50 characters")
      )
      .min(1, "At least 1 favorite food is required")
      .max(10, "Favorite foods cannot exceed 10")
      .test("unique", "Favorite foods must be unique", (value) => {
        if (value) {
          const uniqueSet = new Set(value);
          return uniqueSet.size === value.length;
        }
        return true;
      }),
    favorite_movies_of_this_ai_personality: Yup.array()
      .of(
        Yup.string()
          .min(3, "Favorite movie must have at least 3 characters")
          .max(100, "Favorite movie can have at most 100 characters")
      )
      .min(1, "At least 1 favorite movie is required")
      .max(10, "Favorite movies cannot exceed 10")
      .test("unique", "Favorite movies must be unique", (value) => {
        if (value) {
          const uniqueSet = new Set(value);
          return uniqueSet.size === value.length;
        }
        return true;
      }),
    favorite_songs_of_this_ai_personality: Yup.array()
      .of(
        Yup.string()
          .min(10, "Favorite song must have at least 10 characters")
          .max(100, "Favorite song can have at most 100 characters")
      )
      .min(1, "At least 1 favorite song is required")
      .max(10, "Favorite songs cannot exceed 10")
      .test("unique", "Favorite songs must be unique", (value) => {
        if (value) {
          const uniqueSet = new Set(value);
          return uniqueSet.size === value.length;
        }
        return true;
      }),
  });

  let initValues =
    personalityData?.personalityJson?.basic_details_of_ai_personality;

  return (
    <Formik
      initialValues={initValues || {}}
      onSubmit={onSubmit}
      validationSchema={basicDetailsValidation}
      validateOnMount
    >
      {(formik) => {
        return (
          <Form>
            <div className={"grid grid-cols-12 gap-y-[20px] md:gap-x-[40px]"}>
              <div className={"col-span-12 md:col-span-7 order-0"}>
                <TextArea
                  label={formatMessage({ id: "Description" })}
                  toolTipText={formatMessage({
                    id: "GLOBAL.TOOLTIP.EDIT_AI_PERSONALITY.DESCRIPTION",
                  })}
                  fieldName={"description"}
                  placeholder={formatMessage({
                    id: "Write a paragraph describing who your personality is",
                  })}
                  formik={formik}
                  className={"!min-h-[210px]"}
                />
                <TextArea
                  label={formatMessage({ id: "Motivations" })}
                  fieldName={"motivations"}
                  placeholder={formatMessage({
                    id: "What motivates your personality",
                  })}
                  formik={formik}
                  toolTipText={formatMessage({
                    id: "GLOBAL.TOOLTIP.EDIT_AI_PERSONALITY.MOTIVATIONS",
                  })}
                  className={"!min-h-[107px]"}
                />
                <TextArea
                  label={formatMessage({
                    id: "Difficulties, insecurities of the characters",
                  })}
                  fieldName={"difficulties"}
                  placeholder={formatMessage({
                    id: "What kind of challenges your personality is facing in his world, this makes the personality more human",
                  })}
                  formik={formik}
                  toolTipText={formatMessage({
                    id: "GLOBAL.TOOLTIP.EDIT_AI_PERSONALITY.DIFFICULTIES",
                  })}
                  className={"!min-h-[107px]"}
                />
                <div
                  className={
                    "tab-container-2   p-[14px] md:p-[20px] rounded mb-[20px] md:mb-[24px]"
                  }
                >
                  <h4
                    className={
                      "text-[16px] leading-6 font-medium primary-text mb-[16px]"
                    }
                  >
                    {formatMessage({ id: "Languages" })}
                  </h4>
                  <FormikReactSelect
                    isMulti={false}
                    label={formatMessage({
                      id: "Native Language of Personality",
                    })}
                    name={"native_language_of_this_ai_personality"}
                    placeholder={formatMessage({
                      id: "Select your native language",
                    })}
                    formik={formik}
                    tooltipText={formatMessage({
                      id: "GLOBAL.TOOLTIP.EDIT_AI_PERSONALITY.NATIVE_LANGUAGE",
                    })}
                    options={referenceData?.languageList?.map(
                      (el: any, index: number) => {
                        return {
                          value: el.language,
                          label: el.label,
                          id: index,
                        };
                      }
                    )}
                  />
                  <FormikReactSelect
                    isMulti={true}
                    label={formatMessage({
                      id: "Languages personality can speak",
                    })}
                    name={"languages_spoken_by_this_ai_personality"}
                    placeholder={formatMessage({
                      id: "Select your native language",
                    })}
                    formik={formik}
                    tooltipText={formatMessage({
                      id: "GLOBAL.TOOLTIP.EDIT_AI_PERSONALITY.LANGUAGE_PERSONALITY",
                    })}
                    options={referenceData?.languageList?.map(
                      (el: any, index: number) => {
                        return {
                          value: el.language,
                          label: el.label,
                          id: index,
                        };
                      }
                    )}
                  />
                </div>
                <FormikReactSelect
                  tooltipText={formatMessage({
                    id: "GLOBAL.TOOLTIP.EDIT_AI_PERSONALITY.FOOD_CHOICES",
                  })}
                  isMulti={true}
                  label={formatMessage({
                    id: "Food Choices",
                  })}
                  name={"food_choices_of_this_ai_personality"}
                  isClearable={false}
                  defaultValue={formik.values?.food_choices_of_this_ai_personality?.map(
                    (el: any, index: number) => {
                      return { value: el, label: el, id: index };
                    }
                  )}
                  options={referenceData?.personalityOptions?.foodChoices?.map(
                    (el: any, index: number) => {
                      return { value: el, label: el, id: index };
                    }
                  )}
                  formik={formik}
                />
                <div className={" p-[0px]  rounded mt-[20px]"}>
                  <FormikReactCreatable
                    name={"favorite_foods_of_this_ai_personality"}
                    isMulti={true}
                    isClearable={true}
                    formik={formik}
                    className=""
                    label={formatMessage({
                      id: "Favorite Foods",
                    })}
                    placeholder={formatMessage({
                      id: "Type something and press Enter or Tab to create name",
                    })}
                    tooltipText={formatMessage({
                      id: "GLOBAL.TOOLTIP.EDIT_AI_PERSONALITY.FAVORITE_FOOD",
                    })}
                    defaultValue={formik.values?.favorite_foods_of_this_ai_personality?.map(
                      (el: string, id: number) => {
                        return { value: el, label: el, id: id };
                      }
                    )}
                  />
                  {/* <FieldArray
                    name="favorite_foods_of_this_ai_personality"
                    render={(arrayHelpers) => {
                      return (
                        <>
                          {(formik.values?.favorite_foods_of_this_ai_personality || ['']).map((_ : any, index: number) => (
                            <div key={index}>
                              <div
                                className={
                                  "flex flex-col md:flex-row  items-start  gap-x-[20px]"
                                }
                              >
                                <div className={"flex-grow w-full md:w-auto"}>
                                  <TextInput
                                    isFieldArray={true}
                                    fieldType={"text"}
                                    fieldName={`favorite_foods_of_this_ai_personality[${index}]`}
                                    // value={
                                    //   "Tell me about your experience on working on Circus sets"
                                    // }
                                    formik={formik}
                                    placeholder={
                                      "Tell me about your experience on working on Circus sets"
                                    }
                                    label={formatMessage({
                                      id: "Food" + " " + (index + 1),
                                    })}
                                    toolTipText={formatMessage({
                                      id: "GLOBAL.TOOLTIP.FORGOT_PASSWORD.EMAIL",
                                    })}
                                    isStarRequired={true}
                                  />
                                </div>
                                <div
                                  className={
                                    "flex justify-end gap-[12px] md:mt-[29px] mb-4 md:mb-0"
                                  }
                                >
                                  <button
                                    className={
                                      "p-[6px] bg-[#FFFFFF1A] rounded-[6px]"
                                    }
                                    onClick={() => {
                                      if (!isEqual(index, 0))
                                        arrayHelpers.remove(index);
                                    }}
                                  >
                                    <svg
                                      width="24"
                                      height="24"
                                      viewBox="0 0 24 24"
                                      fill="none"
                                      xmlns="http://www.w3.org/2000/svg"
                                    >
                                      <path
                                        d="M4 7H20M10 11V17M14 11V17M5 7L6 19C6 19.5304 6.21071 20.0391 6.58579 20.4142C6.96086 20.7893 7.46957 21 8 21H16C16.5304 21 17.0391 20.7893 17.4142 20.4142C17.7893 20.0391 18 19.5304 18 19L19 7M9 7V4C9 3.73478 9.10536 3.48043 9.29289 3.29289C9.48043 3.10536 9.73478 3 10 3H14C14.2652 3 14.5196 3.10536 14.7071 3.29289C14.8946 3.48043 15 3.73478 15 4V7"
                                        stroke="#FFFFFFA6"
                                        strokeWidth="1.6"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                      />
                                    </svg>
                                  </button>
                                  <button
                                    className={
                                      "p-[6px] bg-[#FFFFFF1A] rounded-[6px]"
                                    }
                                  >
                                    <svg
                                      width="24"
                                      height="24"
                                      viewBox="0 0 24 24"
                                      fill="none"
                                      xmlns="http://www.w3.org/2000/svg"
                                    >
                                      <g clipPath="url(#clip0_344_35631)">
                                        <path
                                          d="M17 21H7M17 21H17.803C18.921 21 19.48 21 19.907 20.782C20.284 20.59 20.59 20.284 20.782 19.908C21 19.481 21 18.921 21 17.803V9.22C21 8.77 21 8.545 20.952 8.331C20.9094 8.14007 20.839 7.95643 20.743 7.786C20.637 7.596 20.487 7.431 20.193 7.104L17.438 4.042C17.097 3.664 16.924 3.472 16.717 3.334C16.5303 3.21012 16.3241 3.11851 16.107 3.063C15.863 3 15.6 3 15.075 3H6.2C5.08 3 4.52 3 4.092 3.218C3.71565 3.40969 3.40969 3.71565 3.218 4.092C3 4.52 3 5.08 3 6.2V17.8C3 18.92 3 19.48 3.218 19.907C3.41 20.284 3.715 20.59 4.092 20.782C4.519 21 5.079 21 6.197 21H7M17 21V17.197C17 16.079 17 15.519 16.782 15.092C16.5899 14.7156 16.2836 14.4096 15.907 14.218C15.48 14 14.92 14 13.8 14H10.2C9.08 14 8.52 14 8.092 14.218C7.71565 14.4097 7.40969 14.7157 7.218 15.092C7 15.52 7 16.08 7 17.2V21M15 7H9"
                                          stroke="#FFFFFFA6"
                                          strokeWidth="1.6"
                                          strokeLinecap="round"
                                          strokeLinejoin="round"
                                        />
                                      </g>
                                      <defs>
                                        <clipPath id="clip0_344_35631">
                                          <rect
                                            width="24"
                                            height="24"
                                            fill="white"
                                          />
                                        </clipPath>
                                      </defs>
                                    </svg>
                                  </button>
                                </div>
                              </div>
                            </div>
                          ))}
                          <div className={"flex justify-end mt-[16px]"}>
                            <button
                              className={
                                "bg-[#C2D24B] text-black text-[13px] leading-[20px] font-medium  py-[8px] w-full  md:w-[180px] rounded"
                              }
                              onClick={() =>
                                arrayHelpers.push('')
                              }
                            >
                              Add More Food
                            </button>
                          </div>
                        </>
                      );
                    }}
                  /> */}
                </div>
                <div className={" p-[0px]  rounded mt-[20px]"}>
                  <FormikReactCreatable
                    name={"favorite_movies_of_this_ai_personality"}
                    isMulti={true}
                    isClearable={true}
                    formik={formik}
                    label={formatMessage({
                      id: "Favorite Movies",
                    })}
                    placeholder={formatMessage({
                      id: "Type something and press Enter or Tab to create name",
                    })}
                    tooltipText={formatMessage({
                      id: "GLOBAL.TOOLTIP.EDIT_AI_PERSONALITY.FAVORITE_MOVIES",
                    })}
                    defaultValue={formik.values?.favorite_movies_of_this_ai_personality?.map(
                      (el: string, id: number) => {
                        return { value: el, label: el, id: id };
                      }
                    )}
                  />
                  {/* <FieldArray
                    name="favorite_movies_of_this_ai_personality"
                    render={(arrayHelpers) => {
                      return (
                        <>
                          {(formik.values?.favorite_movies_of_this_ai_personality || ['']).map((_:any, index:number) => (
                            <div key={index}>
                              <div
                                className={
                                  "flex flex-col md:flex-row  items-start  gap-x-[20px]"
                                }
                              >
                                <div className={"flex-grow w-full md:w-auto"}>
                                  <TextInput
                                    isFieldArray={true}
                                    fieldType={"text"}
                                    fieldName={`favorite_movies_of_this_ai_personality[${index}]`}
                                    // value={
                                    //   "Tell me about your experience on working on Circus sets"
                                    // }
                                    formik={formik}
                                    placeholder={
                                      "Tell me about your experience on working on Circus sets"
                                    }
                                    label={formatMessage({
                                      id: "Movie" + " " + (index + 1),
                                    })}
                                    toolTipText={formatMessage({
                                      id: "GLOBAL.TOOLTIP.FORGOT_PASSWORD.EMAIL",
                                    })}
                                    isStarRequired={true}
                                  />
                                </div>
                                <div
                                  className={
                                    "flex justify-end gap-[12px] md:mt-[29px] mb-4 md:mb-0"
                                  }
                                >
                                  <button
                                    className={
                                      "p-[6px] bg-[#FFFFFF1A] rounded-[6px]"
                                    }
                                    onClick={() => {
                                      if (!isEqual(index, 0))
                                        arrayHelpers.remove(index);
                                    }}
                                  >
                                    <svg
                                      width="24"
                                      height="24"
                                      viewBox="0 0 24 24"
                                      fill="none"
                                      xmlns="http://www.w3.org/2000/svg"
                                    >
                                      <path
                                        d="M4 7H20M10 11V17M14 11V17M5 7L6 19C6 19.5304 6.21071 20.0391 6.58579 20.4142C6.96086 20.7893 7.46957 21 8 21H16C16.5304 21 17.0391 20.7893 17.4142 20.4142C17.7893 20.0391 18 19.5304 18 19L19 7M9 7V4C9 3.73478 9.10536 3.48043 9.29289 3.29289C9.48043 3.10536 9.73478 3 10 3H14C14.2652 3 14.5196 3.10536 14.7071 3.29289C14.8946 3.48043 15 3.73478 15 4V7"
                                        stroke="#FFFFFFA6"
                                        strokeWidth="1.6"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                      />
                                    </svg>
                                  </button>
                                  <button
                                    className={
                                      "p-[6px] bg-[#FFFFFF1A] rounded-[6px]"
                                    }
                                  >
                                    <svg
                                      width="24"
                                      height="24"
                                      viewBox="0 0 24 24"
                                      fill="none"
                                      xmlns="http://www.w3.org/2000/svg"
                                    >
                                      <g clipPath="url(#clip0_344_35631)">
                                        <path
                                          d="M17 21H7M17 21H17.803C18.921 21 19.48 21 19.907 20.782C20.284 20.59 20.59 20.284 20.782 19.908C21 19.481 21 18.921 21 17.803V9.22C21 8.77 21 8.545 20.952 8.331C20.9094 8.14007 20.839 7.95643 20.743 7.786C20.637 7.596 20.487 7.431 20.193 7.104L17.438 4.042C17.097 3.664 16.924 3.472 16.717 3.334C16.5303 3.21012 16.3241 3.11851 16.107 3.063C15.863 3 15.6 3 15.075 3H6.2C5.08 3 4.52 3 4.092 3.218C3.71565 3.40969 3.40969 3.71565 3.218 4.092C3 4.52 3 5.08 3 6.2V17.8C3 18.92 3 19.48 3.218 19.907C3.41 20.284 3.715 20.59 4.092 20.782C4.519 21 5.079 21 6.197 21H7M17 21V17.197C17 16.079 17 15.519 16.782 15.092C16.5899 14.7156 16.2836 14.4096 15.907 14.218C15.48 14 14.92 14 13.8 14H10.2C9.08 14 8.52 14 8.092 14.218C7.71565 14.4097 7.40969 14.7157 7.218 15.092C7 15.52 7 16.08 7 17.2V21M15 7H9"
                                          stroke="#FFFFFFA6"
                                          strokeWidth="1.6"
                                          strokeLinecap="round"
                                          strokeLinejoin="round"
                                        />
                                      </g>
                                      <defs>
                                        <clipPath id="clip0_344_35631">
                                          <rect
                                            width="24"
                                            height="24"
                                            fill="white"
                                          />
                                        </clipPath>
                                      </defs>
                                    </svg>
                                  </button>
                                </div>
                              </div>
                            </div>
                          ))}
                          <div className={"flex justify-end mt-[16px]"}>
                            <button
                              className={
                                "bg-[#C2D24B] text-black text-[13px] leading-[20px] font-medium  py-[8px] w-full  md:w-[180px] rounded"
                              }
                              onClick={() =>
                                arrayHelpers.push('')
                              }
                            >
                              Add More Movies
                            </button>
                          </div>
                        </>
                      );
                    }}
                  /> */}
                </div>
                <div className={" p-[0px]  rounded mt-[20px]"}>
                  {/* <h4
                    className={
                      "text-[16px] leading-6 font-medium text-[#FFFFFFCC] mb-[16px]"
                    }
                  >
                    Favorite Songs
                  </h4> */}
                  <FormikReactCreatable
                    name={"favorite_songs_of_this_ai_personality"}
                    isMulti={true}
                    isClearable={true}
                    formik={formik}
                    label={formatMessage({
                      id: "Favorite Songs",
                    })}
                    placeholder={formatMessage({
                      id: "Type something and press Enter or Tab to create name",
                    })}
                    tooltipText={formatMessage({
                      id: "GLOBAL.TOOLTIP.EDIT_AI_PERSONALITY.FAVORITE_SONGS",
                    })}
                    defaultValue={formik.values?.favorite_songs_of_this_ai_personality?.map(
                      (el: string, id: number) => {
                        return { value: el, label: el, id: id };
                      }
                    )}
                  />
                  {/* <FieldArray
                    name="favorite_songs_of_this_ai_personality"
                    render={(arrayHelpers) => {
                      return (
                        <>
                          {(formik.values?.favorite_songs_of_this_ai_personality || ['']).map((_:any, index:number) => (
                            <div key={index}>
                              <div
                                className={
                                  "flex flex-col md:flex-row  items-start  gap-x-[20px]"
                                }
                              >
                                <div className={"flex-grow w-full md:w-auto"}>
                                  <TextInput
                                    isFieldArray={true}
                                    fieldType={"text"}
                                    fieldName={`favorite_songs_of_this_ai_personality[${index}]`}
                                    // value={
                                    //   "Tell me about your experience on working on Circus sets"
                                    // }
                                    formik={formik}
                                    placeholder={
                                      "Tell me about your experience on working on Circus sets"
                                    }
                                    label={formatMessage({
                                      id: "Song" + " " + (index + 1),
                                    })}
                                    toolTipText={formatMessage({
                                      id: "GLOBAL.TOOLTIP.FORGOT_PASSWORD.EMAIL",
                                    })}
                                    isStarRequired={true}
                                  />
                                </div>
                                <div
                                  className={
                                    "flex justify-end gap-[12px] md:mt-[29px] mb-4 md:mb-0"
                                  }
                                >
                                  <button
                                    className={
                                      "p-[6px] bg-[#FFFFFF1A] rounded-[6px]"
                                    }
                                    onClick={() => {
                                      if (!isEqual(index, 0))
                                        arrayHelpers.remove(index);
                                    }}
                                  >
                                    <svg
                                      width="24"
                                      height="24"
                                      viewBox="0 0 24 24"
                                      fill="none"
                                      xmlns="http://www.w3.org/2000/svg"
                                    >
                                      <path
                                        d="M4 7H20M10 11V17M14 11V17M5 7L6 19C6 19.5304 6.21071 20.0391 6.58579 20.4142C6.96086 20.7893 7.46957 21 8 21H16C16.5304 21 17.0391 20.7893 17.4142 20.4142C17.7893 20.0391 18 19.5304 18 19L19 7M9 7V4C9 3.73478 9.10536 3.48043 9.29289 3.29289C9.48043 3.10536 9.73478 3 10 3H14C14.2652 3 14.5196 3.10536 14.7071 3.29289C14.8946 3.48043 15 3.73478 15 4V7"
                                        stroke="#FFFFFFA6"
                                        strokeWidth="1.6"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                      />
                                    </svg>
                                  </button>
                                  <button
                                    className={
                                      "p-[6px] bg-[#FFFFFF1A] rounded-[6px]"
                                    }
                                  >
                                    <svg
                                      width="24"
                                      height="24"
                                      viewBox="0 0 24 24"
                                      fill="none"
                                      xmlns="http://www.w3.org/2000/svg"
                                    >
                                      <g clipPath="url(#clip0_344_35631)">
                                        <path
                                          d="M17 21H7M17 21H17.803C18.921 21 19.48 21 19.907 20.782C20.284 20.59 20.59 20.284 20.782 19.908C21 19.481 21 18.921 21 17.803V9.22C21 8.77 21 8.545 20.952 8.331C20.9094 8.14007 20.839 7.95643 20.743 7.786C20.637 7.596 20.487 7.431 20.193 7.104L17.438 4.042C17.097 3.664 16.924 3.472 16.717 3.334C16.5303 3.21012 16.3241 3.11851 16.107 3.063C15.863 3 15.6 3 15.075 3H6.2C5.08 3 4.52 3 4.092 3.218C3.71565 3.40969 3.40969 3.71565 3.218 4.092C3 4.52 3 5.08 3 6.2V17.8C3 18.92 3 19.48 3.218 19.907C3.41 20.284 3.715 20.59 4.092 20.782C4.519 21 5.079 21 6.197 21H7M17 21V17.197C17 16.079 17 15.519 16.782 15.092C16.5899 14.7156 16.2836 14.4096 15.907 14.218C15.48 14 14.92 14 13.8 14H10.2C9.08 14 8.52 14 8.092 14.218C7.71565 14.4097 7.40969 14.7157 7.218 15.092C7 15.52 7 16.08 7 17.2V21M15 7H9"
                                          stroke="#FFFFFFA6"
                                          strokeWidth="1.6"
                                          strokeLinecap="round"
                                          strokeLinejoin="round"
                                        />
                                      </g>
                                      <defs>
                                        <clipPath id="clip0_344_35631">
                                          <rect
                                            width="24"
                                            height="24"
                                            fill="white"
                                          />
                                        </clipPath>
                                      </defs>
                                    </svg>
                                  </button>
                                </div>
                              </div>
                            </div>
                          ))}
                          <div className={"flex justify-end mt-[16px]"}>
                            <button
                              className={
                                "bg-[#C2D24B] text-black text-[13px] leading-[20px] font-medium  py-[8px] w-full  md:w-[180px] rounded"
                              }
                              onClick={() =>
                                arrayHelpers.push('')
                              }
                            >
                              Add More Song
                            </button>
                          </div>
                        </>
                      );
                    }}
                  /> */}
                </div>
              </div>
              <div
                className={
                  "col-span-12 md:col-span-5 flex flex-col justify-start md:gap-0 gap-[20px]"
                }
              >
                <div
                  className={
                    "flex justify-end gap-[10px] md:order-1 md:mt-auto"
                  }
                >
                  {/* <button
                    onClick={() => setOpenEdit(false)}
                    className={
                      "main-button-primary-light text-[14px] leading-5 font-medium py-[12px] w-1/2 md:w-[128px] rounded"
                    }
                  >
                    {formatMessage({
                      id: "Cancel",
                    })}
                  </button> */}
                  <button
                    type="submit"
                    className={`main-button text-[14px] leading-5 font-medium py-[12px] w-full sm:w-1/2  md:w-[140px] rounded ${
                      disableSave ? "opacity-75" : ""
                    }`}
                    disabled={disableSave}
                  >
                    {formatMessage({
                      id: `${disableSave ? "Please Wait" : "Save"}`,
                    })}
                  </button>
                </div>
                <InfoCard
                  title={formatMessage({
                    id: "Understanding Basic Description of AI Personality",
                  })}
                  desc={
                    <>
                      {formatMessage({
                        id: 'In Kamoto.AI, the "Basic Description" category allows you to create virtual AI personalities that embody unique personalities and traits. You have the power to describe your AI persona in a comprehensive paragraph, capturing its essence and individuality.',
                      })}
                      <br></br>
                      <br></br>
                      {formatMessage({
                        id: "Dive into its motivations, understanding what drives and inspires this AI personality. By highlighting its difficulties and insecurities, you make the personality more human, relatable, and authentic. \n",
                      })}
                      <br></br>
                      <br></br>
                      {formatMessage({
                        id: "With Kamoto.AI, you can bring your virtual AI persona to life, shaping it into a dynamic and engaging character that resonates with users. Unleash your creativity and craft AI personalities that leave a lasting impression.",
                      })}
                      <br></br>
                      <br></br>
                    </>
                  }
                  slug={""}
                  isAnchor={
                    "https://help.kamoto.ai/en/articles/7941749-understanding-basic-description-tab-of-ai-personalities"
                  }
                />
              </div>
            </div>
          </Form>
        );
      }}
    </Formik>
  );
};

export default Basic;
