import React, { useEffect, useRef } from "react";
import * as echarts from "echarts";
import { get, isEqual } from "lodash";
import { useThemeMode } from "../../../../../../_metronic/partials";
// @ts-ignore
import useChartResizer from "../../../../../hooks/useChartResizer";
import moment from "moment";
const Index: React.FC<any> = () => {
  const { mode } = useThemeMode();
  let option = {
    xAxis: {
      type: "category",
      data: ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"],
      scale: true,
      axisLine: {
        show: false,
      },
      axisTick: {
        show: false,
      },
      axisLabel: {
        margin: 4,
        fontSize: 12,
        color: isEqual(mode, "light") ? "#5E6278" : "#FFFFFFA6",
      },
      splitLine: {
        show: false,
      },
    },
    yAxis: [{
      splitNumber:3,
      max:24,
      show: true,
      type: "value",
      axisLine: {
        show: false,
      },
      axisTick: {
        show: false,
      },
      axisLabel: {
        show:true,
        formatter:"{value}h",
        showMinLabel:false,
        margin: 4,
        fontSize: 12,
        color: isEqual(mode, "light") ? "#5E6278" : "#FFFFFFA6",
      },
      splitLine: {
        show: false,
      },
    }],
    grid: {
      left: 32,
      top: 5,
      right: 0,
      bottom: 25,
      containLabel: false,
    },
    tooltip: {
      trigger: "axis",
      className: "secondary-bg",
      borderWidth: 0,
      formatter: (data: any) => {
        return `<span class="text-[12px] font-semibold primary-text">Likes Count : ${get(
          data[0],
          "data[1]"
        )}</span> </br> <span class="text-[10px] font-normal primary-text">${moment(
          get(data[0], "data[0]")
        ).format("MMM DD, YYYY HH:mm")}</span>`;
      },
      axisPointer: {
        animation: false,
      },
    },
    legend: {
      show: false,
    },
    series: [
      {
        type: "bar",
        itemStyle: {
          borderRadius: [6, 6, 6, 6],
        },
        barMaxWidth: 48,
        color: "#C2D24B",
        data: [18, 20, 15, 24, 20, 20, 12],
      },
    ],
  };
  const chartRef = useRef();
  useChartResizer(chartRef);
  useEffect(() => {
    const chart = echarts.init(chartRef.current, null);
    // @ts-ignore
    chart.setOption(option);
  }, [option]);

  // @ts-ignore
  return <div className={"w-full h-full"} ref={chartRef}></div>;
};

export default Index;
