import axios from "axios"
import { ResponseType } from "./_models";
const API_URL = process.env.REACT_APP_BASE_API_URL;

export const API_KEYS_URL = `${API_URL}/api-keys`
export const API_LOGS_URL = `${API_URL}/api-logs`
export const EVENT_URL= `${API_URL}/events`
export const PERSONALITY_SEARCH= `${API_URL}/personality/search`
export const CHAT_SUBSCRIPTION = `${API_URL}/chat-subscription`
export const API_SUBSCRIPTION = `${API_URL}/api-subscription`
export const getAllApiForUser = async(params?:{[key:string]:any})=>{
    return await axios.get<ResponseType<{count: number, keys: any[]}>>(API_KEYS_URL,{
      params,
        validateStatus(status) {
          return true;
        }})
}

export const searchPersonalityForApiKey = async(search:string, isOwned:boolean)=>{
  return await axios.get(PERSONALITY_SEARCH,{
    params:{text:search, purpose:'api-keys', isOwned},
    validateStatus(status) {
      return true;
    }})
}

export const createApiKey = async(personalityId:string, name:string)=>{
  return await axios.post<ResponseType<any>>(API_KEYS_URL,{
    name,
    personalityId
  },{
    validateStatus(status) {
      return true;
    }})
}


export const updateApiKey = async(keyId:string, active:boolean)=>{
  return await axios.post<ResponseType<any>>(`${API_KEYS_URL}/${keyId}`,{
    active
  },{
    validateStatus(status) {
      return true;
    }})
}

export const deleteApiKey = async(keyId:string)=>{
  return await axios.delete<ResponseType<any>>(`${API_KEYS_URL}/${keyId}`,{
    validateStatus(status) {
      return true;
    }})
}


export const getApiLogs = async(type:string, params?:{[key:string]:any})=>{
  return await axios.get<ResponseType<{count: number, rows: any[]}>>(API_LOGS_URL,{
    params:{
      ...params,
      type
    },
      validateStatus(status) {
        return true;
      }})
}

export const getEvents = async(type:string, params?:{[key:string]:any})=>{
  return await axios.get<ResponseType<{count: number, rows: any[]}>>(EVENT_URL,{
    params:{
      ...params,
      type
    },
      validateStatus(status) {
        return true;
      }})
}

export const getApiSubscription = async(personalityId:string)=>{
  return await axios.get<ResponseType<any>>(CHAT_SUBSCRIPTION, {
      params:{type:'api'},
      validateStatus(status) {
          return true;
      },
      headers:{
          'x-personality-id':personalityId
        }
  })
}

export const createApiSubscription = async(personalityId:string)=>{
  return await axios.post<ResponseType<string>>(API_SUBSCRIPTION, null,{
      validateStatus(status) {
          return true;
      },
      headers:{
          'x-personality-id':personalityId
        }
  })
}
