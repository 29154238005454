import React, { useEffect, useRef } from "react";
import * as echarts from "echarts";
import { get, isEqual } from "lodash";
import { useThemeMode } from "../../../../../../_metronic/partials";
// @ts-ignore
import useChartResizer from "../../../../../hooks/useChartResizer";
import moment from "moment";
const Index = ({}) => {
  const { mode } = useThemeMode();
  let option = {
    xAxis: {
      type: "category",
      nameLocation: "start",
      boundaryGap: false,
      data: [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ],
      axisLine: {
        show: true,
        lineStyle: {
          color: "#2E2F45",
        },
      },
      axisTick: {
        show: true,
        alignTicks: true,
      },
      axisLabel: {
        margin: 4,
        fontSize: 12,
        color: isEqual(mode, "light") ? "#5E6278" : "#FFFFFFA6",
        // formatter: dayjs("{value}"),
      },
    },
    yAxis: {
      show: true,
      type: "value",
      axisLine: {
        show: false,
      },
      splitNumber:3,
      splitLine: {
        lineStyle: {
          color: "#2E2F45",
        },
      },
      axisLabel: {
        margin: 4,
        fontSize: 12,
        color: isEqual(mode, "light") ? "#5E6278" : "#FFFFFFA6",
        formatter:(value:number)=> new Intl.NumberFormat('en', { notation: 'compact' }).format(value),
      },
    },
    grid: {
      left: 0,
      top: 5,
      right: 15,
      bottom: 0,
      containLabel: true,
    },
    tooltip: {
      trigger: "axis",
      className: "secondary-bg",
      borderWidth: 0,
      formatter: (data: any) => {
        return `<span class="text-[12px] font-semibold primary-text">Likes Count : ${get(
          data[0],
          "data[1]"
        )}</span> </br> <span class="text-[10px] font-normal primary-text">${moment(
          get(data[0], "data[0]")
        ).format("MMM DD, YYYY HH:mm")}</span>`;
      },
      axisPointer: {
        animation: false,
      },
    },
    legend: {
      show: false,
    },
    series: [
      {
        stack: "Discord Bots",
        showSymbol: false,
        type: "line",
        label: {
          show: true,
          position: [20, -20],
          margin: 4,
          fontSize: 14,
          color: isEqual(mode, "light") ? "#5E6278" : "#FFFFFFA6",
        },
        lineStyle: {
          color: "#C2D24B",
          width: 3,
        },
        itemStyle: {
          color: "#C2D24B",
        },
        smooth: true,
        data: [10020, 28200, 10011, 23004, 20020, 34000, 31000],
      },
      {
        stack: "Twitter Bots",
        showSymbol: false,
        type: "line",
        lineStyle: {
          color: "#4C73FF",
          width: 3,
        },
        label: {
          show: true,
          position: [20, -20],
          margin: 4,
          fontSize: 14,
          color: isEqual(mode, "light") ? "#5E6278" : "#FFFFFFA6",
        },
        itemStyle: {
          color: "#4C73FF",
        },
        position: "end",
        smooth: true,
        data: [20020, 40002, 23001, 13004, 19000, 230000, 12020],
      },
    ],
  };
  const chartRef = useRef();
  useChartResizer(chartRef);
  useEffect(() => {
    const chart = echarts.init(chartRef.current, null);
    // @ts-ignore
    chart.setOption(option);
  }, [option]);

  // @ts-ignore
  return <div className={"w-full h-full pt-4"} ref={chartRef}></div>;
};

export default Index;
