/* eslint-disable jsx-a11y/anchor-is-valid */
import clsx from "clsx";
import { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { KTSVG, toAbsoluteUrl } from "../../../helpers";
import "../style/header.scss";
import { useIntl } from "react-intl";
import {
  getAllAlerts,
  getAllNotifications,
  getAllUpdates,
  getNotifications,
  updateStatus,
} from "../core/_requests";
import { useAuth } from "../../../../app/modules/auth";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { DateTime } from "luxon";
import { useThemeMode } from "../theme-mode/ThemeModeProvider";
import { isEqual } from "lodash";
import { ComponentSpinner } from "../../../../app/modules/widgets/components/General/Spinner";


const alertTypes: any = {
  "edit_ai_personality": "update",
  "train_ai_personality_by_chat": "update",
  "train_ai_personality_by_public_link": "update",
  "train_ai_personality_by_private_data": "update",
  "monetization_details_updated": "normal",
  "listing_status_changed": "normal",
  "ai_personality_followed": "success",
  "ai_personality_paid_access_subscribed": "success",
  "paid_session_complete": "success",
  "settlement_executed": "success",
  "training_complete": "success",
  "ai_personality_api_subscribed": "success",
  "personality_chat_feedback_given": "update",
};

const LinkWithTitle = (
  title: string,
  link: string | null,
  type: "alert" | "update",
  id: string | null
) => {
  const handleStatus = async (e: any) => {
    if (type === "alert") {
      await updateStatus({ alert_id: id });
    }
    return true;
  };

  if(!link){
    return (
      <p className="fs-6 text-gray-800 text-hover-primary font-weight-600 dark_text_color hover:cursor-pointer"
      onClick={handleStatus}
      >
        {title}
      </p>
    )
  }

  return (
    <>
      {link?.match(/^(https?|ftp):\/\/[^\s/$.?#].[^\s]*$/) ? (
        <a
          href={link || "#"}
          target={"_blank"}
          className="fs-6 text-gray-800 text-hover-primary font-weight-600 dark_text_color"
          rel="noopener noreferrer"
          onClick={handleStatus}
        >
          {title}
        </a>
      ) : (
        <Link
          to={link || "#"}
          target={"_self"}
          className="fs-6 text-gray-800 text-hover-primary font-weight-600 dark_text_color"
          onClick={handleStatus}
        >
          {title}
        </Link>
      )}
    </>
  );
};

const HeaderNotificationsMenu = ({
  blinker,
  setBlinker,
}: {
  blinker: boolean;
  setBlinker: any;
}) => {
  const { formatMessage } = useIntl();
  const { personalityId } = useAuth();
  const [alerts, setAlert] = useState<any>();
  const [logs, setLog] = useState<any>();
  const [updates, setUpdate] = useState<any>();
  const [length, setLength] = useState<number>();
  const { mode } = useThemeMode();
  const [loading, setLoading] = useState(false)

  const svgIconByType = (type: string | null) => {
    switch (type) {
      case 'error':
        return (<svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
          <rect width="40" height="40" rx="8" fill="#372533" />
          <g clip-path="url(#clip0_9608_31040)">
            <path d="M28.5057 18.11L25.88 13.61C25.2102 12.4625 23.9697 11.75 22.6415 11.75H17.3382C16.01 11.75 14.7687 12.4632 14.099 13.61L11.4732 18.11C10.7937 19.2755 10.7937 20.7238 11.4732 21.8892L14.099 26.3892C14.7687 27.5367 16.0092 28.2493 17.3375 28.2493H22.6407C23.969 28.2493 25.2102 27.536 25.88 26.3892L28.505 21.8892C29.1845 20.7238 29.1852 19.2755 28.5057 18.11ZM22.7802 21.719C23.0735 22.0122 23.0735 22.4862 22.7802 22.7795C22.634 22.9257 22.442 22.9992 22.25 22.9992C22.058 22.9992 21.866 22.9257 21.7197 22.7795L20 21.0597L18.2802 22.7795C18.134 22.9257 17.942 22.9992 17.75 22.9992C17.558 22.9992 17.366 22.9257 17.2197 22.7795C16.9265 22.4862 16.9265 22.0122 17.2197 21.719L18.9395 19.9992L17.2197 18.2795C16.9265 17.9863 16.9265 17.5122 17.2197 17.219C17.513 16.9257 17.987 16.9257 18.2802 17.219L20 18.9388L21.7197 17.219C22.013 16.9257 22.487 16.9257 22.7802 17.219C23.0735 17.5122 23.0735 17.9863 22.7802 18.2795L21.0605 19.9992L22.7802 21.719Z" fill="#DE506E" />
          </g>
          <defs>
            <clipPath id="clip0_9608_31040">
              <rect width="18" height="18" fill="white" transform="translate(11 11)" />
            </clipPath>
          </defs>
        </svg>)
        break;
      case 'warning':
        return (<svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
          <rect width="40" height="40" rx="8" fill="#372F29" />
          <g clip-path="url(#clip0_9608_31049)">
            <path d="M27.902 17.3456L22.6527 12.0948C21.944 11.3868 21.0027 10.9961 20.0007 10.9961C18.9987 10.9961 18.0567 11.3868 17.3487 12.0948L12.0995 17.3456C10.6377 18.8073 10.6377 21.1856 12.0995 22.6473L17.3487 27.8981C18.0567 28.6061 18.9987 28.9968 20.0007 28.9968C21.0027 28.9968 21.944 28.6061 22.6527 27.8981L27.902 22.6473C28.61 21.9393 29 20.9981 29 19.9968C29 18.9956 28.61 18.0536 27.902 17.3456ZM19.25 16.2506C19.25 15.8358 19.586 15.5006 20 15.5006C20.414 15.5006 20.75 15.8358 20.75 16.2506V20.3756C20.75 20.7903 20.414 21.1256 20 21.1256C19.586 21.1256 19.25 20.7903 19.25 20.3756V16.2506ZM20 24.5006C19.379 24.5006 18.875 23.9966 18.875 23.3756C18.875 22.7546 19.379 22.2506 20 22.2506C20.621 22.2506 21.125 22.7546 21.125 23.3756C21.125 23.9966 20.621 24.5006 20 24.5006Z" fill="#F6C944" />
          </g>
          <defs>
            <clipPath id="clip0_9608_31049">
              <rect width="18" height="18" fill="white" transform="translate(11 11)" />
            </clipPath>
          </defs>
        </svg>)
        break;
      case 'success':
        return (<svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
          <rect width="40" height="40" rx="8" fill="#213137" />
          <g clip-path="url(#clip0_9608_31062)">
            <path d="M25.25 11H14.75C12.68 11.0025 11.0025 12.68 11 14.75V25.25C11.0025 27.32 12.68 28.9975 14.75 29H25.25C27.32 28.9975 28.9975 27.32 29 25.25V14.75C28.9975 12.68 27.32 11.0025 25.25 11ZM26 17.0593L19.0595 23.9998C18.4738 24.5857 17.5241 24.5859 16.9382 24.0002C16.938 24 16.9379 23.9999 16.9377 23.9998L14 21.0628C13.7065 20.7693 13.7065 20.2935 14 20C14.2935 19.7066 14.7693 19.7066 15.0627 20L17.9997 22.937L24.941 15.9965C25.2344 15.7041 25.7094 15.7049 26.0018 15.9984C26.2943 16.2919 26.2935 16.7668 26 17.0593Z" fill="#74CA8F" />
          </g>
          <defs>
            <clipPath id="clip0_9608_31062">
              <rect width="18" height="18" fill="white" transform="translate(11 11)" />
            </clipPath>
          </defs>
        </svg>)
        break;
      case 'system':
        return (<svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
          <rect width="40" height="40" rx="8" fill="#242E46" />
          <g clip-path="url(#clip0_9608_31088)">
            <path d="M24.875 12.5H15.125C14.0313 12.5012 12.9828 12.9362 12.2095 13.7095C11.4362 14.4828 11.0012 15.5313 11 16.625V21.125C11.0012 22.2187 11.4362 23.2672 12.2095 24.0405C12.9828 24.8138 14.0313 25.2488 15.125 25.25H18.875V26.75H17C16.7016 26.75 16.4155 26.8685 16.2045 27.0795C15.9935 27.2905 15.875 27.5766 15.875 27.875C15.875 28.1734 15.9935 28.4595 16.2045 28.6705C16.4155 28.8815 16.7016 29 17 29H23C23.2984 29 23.5845 28.8815 23.7955 28.6705C24.0065 28.4595 24.125 28.1734 24.125 27.875C24.125 27.5766 24.0065 27.2905 23.7955 27.0795C23.5845 26.8685 23.2984 26.75 23 26.75H21.125V25.25H24.875C25.9687 25.2488 27.0172 24.8138 27.7905 24.0405C28.5638 23.2672 28.9988 22.2187 29 21.125V16.625C28.9988 15.5313 28.5638 14.4828 27.7905 13.7095C27.0172 12.9362 25.9687 12.5012 24.875 12.5Z" fill="#5795F7" />
          </g>
          <defs>
            <clipPath id="clip0_9608_31088">
              <rect width="18" height="18" fill="white" transform="translate(11 11)" />
            </clipPath>
          </defs>
        </svg>)
        break;
      case 'update':
        return (<svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
          <rect width="40" height="40" rx="8" fill="#242E46" />
          <g clip-path="url(#clip0_9608_31083)">
            <path d="M13.3147 19.0131C13.8578 15.3223 17.29 12.7706 20.9808 13.3137C22.24 13.499 23.4213 14.0363 24.3884 14.8637L23.5178 15.7344C23.2259 16.0263 23.226 16.4995 23.5179 16.7913C23.658 16.9314 23.8481 17.0101 24.0462 17.0101H27.4736C27.8863 17.0101 28.2209 16.6755 28.2209 16.2628V12.8354C28.2208 12.4226 27.8862 12.0881 27.4734 12.0882C27.2753 12.0882 27.0853 12.1669 26.9452 12.307L25.9736 13.2785C22.2685 9.97582 16.5876 10.302 13.2849 14.007C12.1148 15.3196 11.3555 16.9466 11.101 18.6865C11.0007 19.3033 11.4194 19.8846 12.0362 19.985C12.0917 19.994 12.1478 19.9989 12.2041 19.9996C12.7684 19.9935 13.2421 19.5727 13.3147 19.0131Z" fill="#5795F7" />
            <path d="M27.7955 20C27.2312 20.0061 26.7576 20.4269 26.685 20.9865C26.1419 24.6773 22.7096 27.229 19.0189 26.6859C17.7597 26.5006 16.5784 25.9633 15.6112 25.1359L16.4819 24.2652C16.7737 23.9733 16.7737 23.5001 16.4817 23.2083C16.3416 23.0682 16.1516 22.9895 15.9535 22.9895H12.5262C12.1134 22.9895 11.7788 23.3241 11.7788 23.7368V27.1643C11.7789 27.577 12.1136 27.9115 12.5263 27.9115C12.7244 27.9114 12.9145 27.8327 13.0546 27.6927L14.0261 26.7211C17.7303 30.0241 23.4108 29.699 26.7139 25.9948C27.8846 24.6819 28.6442 23.0544 28.8986 21.3139C28.9994 20.6972 28.5811 20.1155 27.9644 20.0147C27.9085 20.0056 27.8521 20.0007 27.7955 20Z" fill="#5795F7" />
          </g>
          <defs>
            <clipPath id="clip0_9608_31083">
              <rect width="18" height="18" fill="white" transform="translate(11 11)" />
            </clipPath>
          </defs>
        </svg>)
        break;
      // default is for normal
      default:
        return (<svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
          <rect width="40" height="40" rx="8" fill="#242E46" />
          <g clip-path="url(#clip0_9608_31069)">
            <path d="M12.2052 24.5C12.8256 25.5767 14.2014 25.9466 15.2781 25.3262C15.2788 25.3258 15.2795 25.3254 15.2802 25.325L15.614 25.1322C16.244 25.6712 16.9681 26.0892 17.75 26.3652V26.75C17.75 27.9926 18.7573 29 20 29C21.2426 29 22.25 27.9926 22.25 26.75V26.3652C23.0319 26.0888 23.7561 25.6703 24.386 25.1307L24.7212 25.3242C25.7982 25.9456 27.1749 25.5762 27.7962 24.4992C28.4175 23.4223 28.0482 22.0455 26.9712 21.4242L26.6382 21.2322C26.7881 20.4166 26.7881 19.5804 26.6382 18.7647L26.9712 18.5727C28.0482 17.9514 28.4175 16.5747 27.7962 15.4977C27.1749 14.4208 25.7982 14.0514 24.7212 14.6727L24.3875 14.8655C23.7568 14.3271 23.0322 13.9099 22.25 13.6347V13.25C22.25 12.0074 21.2426 11 20 11C18.7573 11 17.75 12.0074 17.75 13.25V13.6347C16.968 13.9112 16.2438 14.3297 15.614 14.8693L15.2787 14.675C14.2018 14.0537 12.825 14.4231 12.2037 15.5C11.5824 16.5769 11.9518 17.9537 13.0287 18.575L13.3617 18.767C13.2118 19.5827 13.2118 20.4188 13.3617 21.2345L13.0287 21.4265C11.9547 22.0495 11.5866 23.4235 12.2052 24.5ZM20 17C21.6568 17 23 18.3432 23 20C23 21.6568 21.6568 23 20 23C18.3431 23 17 21.6568 17 20C17 18.3432 18.3431 17 20 17Z" fill="#5795F7" />
          </g>
          <defs>
            <clipPath id="clip0_9608_31069">
              <rect width="18" height="18" fill="white" transform="translate(11 11)" />
            </clipPath>
          </defs>
        </svg>)
        break;
    }
  }

  const notifications = async () => {
    let notificationId = localStorage.getItem("notificationId") || "";
    setLoading(true)
    try {
      const {
        data: { data, success, errors },
      } = await getAllAlerts();
      if (success) {
        setAlert(data);
      } else {
        errors.forEach((error: string) => {
          toast.error(formatMessage({ id: error }), {
            autoClose: false,
            closeButton: true, // Display close button (cross icon)
          });
        });
      }
      setLoading(false)
      let resp = await getAllUpdates();
      if (resp?.data?.success) {
        setUpdate(resp.data.data);
      } else {
        toast.error(formatMessage({ id: "Unable to get updates" }), {
          autoClose: false,
          closeButton: true, // Display close button (cross icon)
        });
      }
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    if (personalityId) {
      notifications();
    }
  }, [personalityId]); // eslint-disable-line react-hooks/exhaustive-deps

  const divRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    const observer = new MutationObserver(mutations => {
      mutations.forEach(mutation => {
        if (mutation.attributeName === 'class' && divRef.current) {
          // Class name (className in React) has changed
          const newClassName = divRef.current.getAttribute('class');
          if (newClassName?.includes('show')) {
            notifications()
          }
          // You can perform any action or logic here
        }
      });
    });

    if (divRef.current) {
      // Start observing the 'class' attribute of the div
      observer.observe(divRef.current, { attributes: true });
    }

    // Clean up the observer when the component unmounts
    return () => observer.disconnect();
  }, []);

  const showDate = (date: any) => {
    if (!date) {
      return "Invalid date";
    }

    const now = DateTime.utc();
    const pastTime = DateTime.fromISO(date);

    const diff = now.diff(pastTime, ["seconds", "minutes", "hours", "days", "months", "years"]).toObject();

    if (diff.years !== undefined && diff.years >= 1) {
      if (diff.years === 1) {
        return "1 year ago";
      } else {
        return `${diff.years} years ago`;
      }
    } else if (diff.months !== undefined && diff.months >= 1) {
      if (diff.months === 1) {
        return "1 month ago";
      } else {
        return `${diff.months} months ago`;
      }
    } else if (diff.days !== undefined && diff.days >= 1) {
      if (diff.days === 1) {
        return "1 day ago";
      } else if (diff.days <= 7) {
        return `${diff.days} days ago`;
      } else {
        return pastTime.toFormat("MMM dd");
      }
    } else if (diff.hours !== undefined && diff.hours >= 1) {
      if (diff.hours === 1) {
        return "an hour ago";
      } else {
        return `${diff.hours} hours ago`;
      }
    } else if (diff.minutes !== undefined && diff.minutes >= 1) {
      if (diff.minutes === 1) {
        return "a min ago";
      } else {
        return `${diff.minutes} mins ago`;
      }
    } else {
      return "just now";
    }
  };
  return (
    <>
      <ToastContainer />
      <div
        className="menu menu-sub menu-sub-dropdown !right-[-18px] menu-column w-325px w-lg-375px"
        data-kt-menu="true"
        ref={divRef}
      // onLoad={notifications}
      // onClick={notifications}
      >
        <div className="d-flex flex-column bgi-no-repeat rounded-top notification-header text-black justify-between">
          <h3 className="text-black fw-bold px-9 mt-6 mb-6 fs-2">
            {formatMessage({ id: "Notifications" })}
            <span className="font-size-12 font-weight-400 opacity-75 ps-3">
              {length} {formatMessage({ id: "reports" })}
            </span>
          </h3>
          <ul className="nav nav-line-tabs nav-line-tabs-2x nav-stretch fw-bold px-9 mt-n4 ">
            <li className="nav-item">
              <a
                className="nav-link text-black opacity-75 opacity-state-100 active  font-size-13 font-weight-500"
                data-bs-toggle="tab"
                href="#kt_topbar_notifications_1"
              >
                {formatMessage({ id: "Alerts" })}
              </a>
            </li>

            <li className="nav-item">
              <a
                className="nav-link text-black opacity-75 opacity-state-100  font-size-13 font-weight-500"
                data-bs-toggle="tab"
                href="#kt_topbar_notifications_2"
              >
                {formatMessage({ id: "Updates" })}
              </a>
            </li>
          </ul>
        </div>
        <div className="tab-content">
          <div
            className="tab-pane fade "
            id="kt_topbar_notifications_2"
            role="tabpanel"
          >
            {updates?.length > 0 ? (
              <div className="scroll-y mh-325px my-5 px-8">
                {updates &&
                  updates?.map((update: any, index: any) => (
                    <div
                      key={`update${index}`}
                      className="d-flex flex-stack py-4"
                    >
                      <div className="d-flex align-items-center">
                        <div className="symbol symbol-35px me-4">
                          <span
                            className={clsx("symbol-label", `bg-light-success`)}
                          >
                            {/* <KTSVG
                              path={`/media/icons/duotune/technology/teh008.svg`}
                              className={`svg-icon-2 svg-icon-${update.state}`}
                            /> */}
                            {svgIconByType(update.type)}
                          </span>
                        </div>
                        <div className="mb-0 me-2">
                          <a
                            href="#"
                            className="fs-6 text-gray-800 text-hover-primary font-weight-600 dark_text_color"
                          >
                            {update.title}
                          </a>
                          <div className="text-clr5E font-weight-400 font-size-13">
                            {update.description}
                          </div>
                        </div>
                      </div>

                      <span className="badge badge-light primary-text font-size-12 font-weight-500">
                        {showDate(update?.createdAt)}
                      </span>
                    </div>
                  ))}
              </div>
            ) : (
              <div className="mx-6 mt-12 text-center">
                <h4 className="font-size-14 font-weight-700">
                  {formatMessage({ id: "No Notification found" })}
                </h4>
                <p className="font-size-13 font-weight-400">
                  {formatMessage({
                    id: "There are currently no notifications to display. Please check back later",
                  })}
                </p>
                {isEqual(mode, 'light') ? <img
                  src={toAbsoluteUrl("/media/svg/illustrations/noActivityLight.svg")}
                  alt="notification"
                  width={300}
                  height={200}
                  className="mt-17 mb-12"
                /> : <img
                  src={toAbsoluteUrl("/media/svg/illustrations/noActivity.svg")}
                  alt="notification"
                  width={300}
                  height={200}
                  className="mt-17 mb-12"
                />}
              </div>
            )}
          </div>

          {/* <div className='tab-pane fade show active' id='kt_topbar_notifications_2' role='tabpanel'>
            <div className='d-flex flex-column px-9'>
              <div className='pt-10 pb-0'>
                <h3 className='text-clr38 text-center fw-bolder font-size-14'>Get Pro Access</h3>

                <div className='text-center  pt-1 font-size-13 font-weight-400 text-clr5E'>
                  Outlines keep you honest. They stoping you from amazing poorly about drive
                </div>

                <div className='text-center mt-5 mb-9'>
                  <a
                    href='#'
                    className='btn btn-sm btn-primary px-6'
                    data-bs-toggle='modal'
                    data-bs-target='#kt_modal_upgrade_plan'
                  >
                    Upgrade
                  </a>
                </div>
              </div>

              <div className='text-center px-4'>
                <img
                  className='mw-100 mh-250px mt-0'
                  alt='metronic'
                  src={toAbsoluteUrl('/media/icons/header/project-launch.svg')}
                />
              </div>
            </div>
          </div> */}
          <div
            className="tab-pane fade active show"
            id="kt_topbar_notifications_1"
            role="tabpanel"
          >
            {!loading
              ?
              <>
                {alerts?.length > 0 ? (
                  <div className="scroll-y mh-325px my-5 px-8">
                    {alerts &&
                      alerts?.map((alert: any, index: any) => (
                        <div
                          key={`alert${index}`}
                          className="d-flex flex-stack py-4"
                        >
                          <div className="d-flex align-items-center">
                            <div className="symbol symbol-35px me-4">
                              <span
                                className={clsx("symbol-label", `bg-light-success`)}
                              >
                                {/* <KTSVG
                                path={`/media/icons/duotune/technology/teh008.svg`}
                                className={`svg-icon-2 svg-icon-${alert.state}`}
                              /> */}
                                {svgIconByType(alertTypes?.[alert.type])}
                              </span>
                            </div>

                            <div className="mb-0 me-2">
                              {/* <a
                              href="#"
                              className="fs-6 text-gray-800 text-hover-primary font-weight-600 dark_text_color"
                            >
                              {alert?.action_text}
                            </a> */}
                              {LinkWithTitle(
                                alert.action_text,
                                alert.action_url,
                                "alert",
                                alert.id
                              )}
                              <div className="text-clr5E font-weight-400 font-size-13">
                                {alert?.content}
                              </div>
                            </div>
                          </div>

                          <span className="badge badge-light font-size-12 primary-text font-weight-500">
                            {showDate(alert?.createdAt)}
                          </span>
                        </div>
                      ))}
                  </div>
                ) : (
                  <div className="mx-6 mt-12 text-center">
                    <h4 className="font-size-14 font-weight-700">
                      {formatMessage({ id: "No Notification found" })}
                    </h4>
                    <p className="font-size-13 font-weight-400">
                      {formatMessage({
                        id: "There are currently no notifications to display. Please check back later",
                      })}
                    </p>

                    {isEqual(mode, 'light') ? <img
                      src={toAbsoluteUrl("/media/svg/illustrations/noActivityLight.svg")}
                      alt="notification"
                      width={300}
                      height={200}
                      className="mt-17 mb-12"
                    /> : <img
                      src={toAbsoluteUrl("/media/svg/illustrations/noActivity.svg")}
                      alt="notification"
                      width={300}
                      height={200}
                      className="mt-17 mb-12"
                    />}
                  </div>
                )}
              </>
              :
              <ComponentSpinner />
            }
          </div>
        </div>
      </div>
    </>
  );
};

export { HeaderNotificationsMenu };
