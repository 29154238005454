import React, { useState } from "react";
import { useIntl } from "react-intl";
import DateFilter from "../components/date-filter";
import { get, isEqual, map } from "lodash";
import LineChart from "../components/line-chart";
import { useNavigate } from "react-router-dom";
import Select from "../../../components/select/select";
import clsx from "clsx";
import { toAbsoluteUrl } from "../../../../_metronic/helpers";
import { ToolTipUI } from "../../widgets/components/UI/ToolTipUI";
import { CorothonChart } from "../../../components/cohort-chart";
import FunnelChart from "../components/funnel-chart";
import PersonalitiesTable from "./personalities-table";
import BarChart from "../components/bar-chart";
import LineGraph from "../components/line-graph";
import CheckboxSelect from "../components/checkbox-select";

const PersonalitiesContainer = () => {
  const { formatMessage } = useIntl();
  const navigate = useNavigate();
  const [showUsersTable, setShowUsersTable] = useState(false);
  const [activePerformanceFilter, setActivePerformanceFilter] =
    useState("personalities");
  const sortOptions = [
    { value: "popular", label: formatMessage({ id: "Most Popular" }) },
    { value: "likes", label: formatMessage({ id: "Most Liked" }) },
    { value: "interacted", label: formatMessage({ id: "Most Interacted" }) },
    { value: "created", label: formatMessage({ id: "Creation Time" }) },
    { value: "name", label: formatMessage({ id: "Name" }) },
  ];
  const topPerformance = [
    {
      id: 1,
      name: "Shahrukh Khan",
      owner: "Toshendra Sharma",
      totalVisitors: "2,000,000",
      followers: "200k",
      likes: "90k",
    },
    {
      id: 2,
      name: "Shahrukh Khan",
      owner: "Toshendra Sharma",
      totalVisitors: "2,000,000",
      followers: "200k",
      likes: "90k",
    },
    {
      id: 3,
      name: "Shahrukh Khan",
      owner: "Toshendra Sharma",
      totalVisitors: "2,000,000",
      followers: "200k",
      likes: "90k",
    },
    {
      id: 4,
      name: "Shahrukh Khan",
      owner: "Toshendra Sharma",
      totalVisitors: "2,000,000",
      followers: "200k",
      likes: "90k",
    },
    {
      id: 5,
      name: "Shahrukh Khan",
      owner: "Toshendra Sharma",
      totalVisitors: "2,000,000",
      followers: "200k",
      likes: "90k",
    },
    {
      id: 6,
      name: "Shahrukh Khan",
      owner: "Toshendra Sharma",
      totalVisitors: "2,000,000",
      followers: "200k",
      likes: "90k",
    },
    {
      id: 7,
      name: "Shahrukh Khan",
      owner: "Toshendra Sharma",
      totalVisitors: "2,000,000",
      followers: "200k",
      likes: "90k",
    },
    {
      id: 8,
      name: "Shahrukh Khan",
      owner: "Toshendra Sharma",
      totalVisitors: "2,000,000",
      followers: "200k",
      likes: "90k",
    },
    {
      id: 9,
      name: "Shahrukh Khan",
      owner: "Toshendra Sharma",
      totalVisitors: "2,000,000",
      followers: "200k",
      likes: "90k",
    },
    {
      id: 10,
      name: "Shahrukh Khan",
      owner: "Toshendra Sharma",
      totalVisitors: "2,000,000",
      followers: "200k",
      likes: "90k",
    },
  ];
  const lineChart = [
    {
      heading: "All Personalities",
      count: "8,31,22,625",
      increase: true,
      description: "33,662 Joined Yesterday",
      linkTitle: "Check all Personalities",
    },
    {
      heading: "Paid Personalities",
      count: "3,41,00,625",
      increase: true,
      description: "33,662 Verified Yesterday",
      linkTitle: "Check all Personalities",
    },
    {
      heading: "Licensed Personalities",
      count: "4,70,80,452",
      increase: true,
      description: "33,662 Pending action",
      linkTitle: "Check all Personalities",
    },
    {
      heading: "Inactive Personalities",
      count: "6,31,22,625",
      increase: true,
      description: "33,662 Paid Yesterday",
      linkTitle: "Check all Personalities",
    },
    {
      heading: "Avg. Chat sessions per user",
      count: "5.50m",
      increase: true,
      description: "33,662 Joined Yesterday",
      linkTitle: "Check all Personalities",
    },
    {
      heading: "Avg. Chat Session per personality",
      count: "3.60m",
      increase: true,
      description: "33,662 Verified Yesterday",
      linkTitle: "Check all Personalities",
    },
    {
      heading: "Avg. Chat Messages Per Session",
      count: "4.95",
      increase: true,
      description: "33,662 Pending action",
      linkTitle: "Check all Personalities",
    },
    {
      heading: "Avg. Tokens per User Prompt",
      count: "6.00",
      increase: true,
      description: "33,662 Paid Yesterday",
      linkTitle: "Check all Personalities",
    },
  ];

  return showUsersTable ? (
    <PersonalitiesTable />
  ) : (
    <div className={"pb-10"}>
      <DateFilter />
      <div className={"grid grid-cols-12 gap-4 mt-[16px] mb-[24px]"}>
        {map(lineChart, (item: any) => (
          <div className={"col-span-12 md:col-span-6 xl:col-span-3"}>
            <LineChart
              heading={get(item, "heading")}
              count={get(item, "count")}
              description={get(item, "description")}
              tooltipText={"Test"}
              linkText={get(item, "linkTitle")}
              onClickLink={() => {
                setShowUsersTable(true);
              }}
            />
          </div>
        ))}
      </div>
      <div
        className={
          "grid grid-cols-12 md:gap-x-[22px] gap-y-[14px] md:gap-y-0 my-5"
        }
      >
        <div
            className={
              "col-span-12 lg:col-span-6 p-5 rounded chart-card"
            }
        >
          <div className={"flex flex-wrap gap-y-2 justify-between items-center mb-2"}>
            <h3 className="flex items-center  text-[14px] leading-[20px] primary-text">
              {formatMessage({ id: "Personality Bots Created" })}
              <ToolTipUI
                tooltipText={formatMessage({
                  id: "Personality Bots Created",
                })}
              />
            </h3>
            <CheckboxSelect/>
          </div>
          <div className={"flex items-center gap-x-2"}>
            <h3 className={"text-[32px] primary-text font-semibold"}>86,986</h3>
            <span className="text-[14px] text-[#3DD883] flex items-center gap-x-1">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="14"
                height="14"
                viewBox="0 0 14 14"
                fill="none"
              >
                <path
                  opacity="0.5"
                  d="M7.5332 9.98475V4.31706C7.5332 4.0325 7.29435 3.80182 6.99972 3.80182C6.70508 3.80182 6.46624 4.0325 6.46624 4.31706V9.98475C6.46624 10.2693 6.70508 10.5 6.99972 10.5C7.29435 10.5 7.5332 10.2693 7.5332 9.98475Z"
                  fill="#3DD883"
                />
                <path
                  d="M7.30179 5.12378L9.53405 7.27972C9.75502 7.49314 10.1133 7.49314 10.3343 7.27972C10.5552 7.06631 10.5552 6.72027 10.3343 6.50686L7.37723 3.65091C7.1689 3.4497 6.8311 3.4497 6.62278 3.65091L3.66573 6.50686C3.44476 6.72027 3.44476 7.06631 3.66573 7.27972C3.8867 7.49314 4.24498 7.49314 4.46596 7.27972L6.69821 5.12378C6.86487 4.96281 7.13513 4.96281 7.30179 5.12378Z"
                  fill="#3DD883"
                />
              </svg>
              80%
            </span>
          </div>
          <div className={"h-96"}>
            <LineGraph />
          </div>
        </div>
        <div
          className={
            "col-span-12 lg:col-span-6 p-5 rounded chart-card"
          }
        >
          <div
            className={
              "flex  flex-wrap items-center justify-between gap-y-2 mb-5"
            }
          >
            <h3 className="flex items-center  text-[14px] leading-[20px] primary-text">
              {formatMessage({ id: "Top Performance " })}
              <ToolTipUI
                tooltipText={formatMessage({
                  id: "Top Performance ",
                })}
              />
            </h3>
            <div className={"flex items-center gap-x-2"}>
              <CheckboxSelect
                  options={[
                    {
                      value: "personality_creation",
                      label: `${formatMessage({ id: "Personality Creation" })}`,
                    },
                    {
                      value: "personality_edited",
                      label: `${formatMessage({ id: "Personality Edited" })}`,
                    },
                    {
                      value: "personality_published",
                      label: `${formatMessage({ id: "Personality Published" })}`,
                    },
                    {
                      value: "personality_used",
                      label: `${formatMessage({ id: "Personality Used" })}`,
                    },
                    {
                      value: "generated_revenue",
                      label: `${formatMessage({ id: "Generated Revenue" })}`,
                    },
                  ]}
                  defaultValue={[
                    {
                      value: "personality_creation",
                      label: `${formatMessage({ id: "Personality Creation" })}`,
                    },
                    {
                      value: "personality_edited",
                      label: `${formatMessage({ id: "Personality Edited" })}`,
                    },
                    {
                      value: "personality_published",
                      label: `${formatMessage({ id: "Personality Published" })}`,
                    },
                    {
                      value: "personality_used",
                      label: `${formatMessage({ id: "Personality Used" })}`,
                    },
                    {
                      value: "generated_revenue",
                      label: `${formatMessage({ id: "Generated Revenue" })}`,
                    },
                  ]}
              />
            </div>
          </div>
          <div className={"h-96"}>
            <FunnelChart />
          </div>
        </div>
      </div>
      <div
        className={
          "grid grid-cols-12 md:gap-x-[22px] gap-y-[14px] md:gap-y-0 my-5"
        }
      >
        <div
          className={
            "col-span-12 md:col-span-6 p-5 rounded chart-card"
          }
        >
          <div className={"flex justify-between items-center mb-2"}>
            <h3 className="flex items-center  text-[14px] leading-[20px] primary-text">
              {formatMessage({ id: "Chats Liked" })}
              <ToolTipUI
                tooltipText={formatMessage({
                  id: "Chats Liked",
                })}
              />
            </h3>
            <Select
              options={sortOptions}
              label={false}
              className={"event-select min-w-[200px] !w-full text-[14px]"}
              placeHolder={formatMessage({ id: "Select" })}
            />
          </div>
          <div className={"flex items-center gap-x-2"}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="40"
              height="40"
              viewBox="0 0 40 40"
              fill="none"
            >
              <path
                d="M20 36L17.39 33.6262C8.12 25.2278 2 19.6708 2 12.891C2 7.33406 6.356 3 11.9 3C15.032 3 18.038 4.45668 20 6.7406C21.962 4.45668 24.968 3 28.1 3C33.644 3 38 7.33406 38 12.891C38 19.6708 31.88 25.2278 22.61 33.6262L20 36Z"
                fill="#E80054"
              />
            </svg>
            <h3 className={"text-[32px] primary-text font-semibold"}>24 M</h3>
            <span className="text-[14px] text-[#3DD883] flex items-center gap-x-1">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="14"
                height="14"
                viewBox="0 0 14 14"
                fill="none"
              >
                <path
                  opacity="0.5"
                  d="M7.5332 9.98475V4.31706C7.5332 4.0325 7.29435 3.80182 6.99972 3.80182C6.70508 3.80182 6.46624 4.0325 6.46624 4.31706V9.98475C6.46624 10.2693 6.70508 10.5 6.99972 10.5C7.29435 10.5 7.5332 10.2693 7.5332 9.98475Z"
                  fill="#3DD883"
                />
                <path
                  d="M7.30179 5.12378L9.53405 7.27972C9.75502 7.49314 10.1133 7.49314 10.3343 7.27972C10.5552 7.06631 10.5552 6.72027 10.3343 6.50686L7.37723 3.65091C7.1689 3.4497 6.8311 3.4497 6.62278 3.65091L3.66573 6.50686C3.44476 6.72027 3.44476 7.06631 3.66573 7.27972C3.8867 7.49314 4.24498 7.49314 4.46596 7.27972L6.69821 5.12378C6.86487 4.96281 7.13513 4.96281 7.30179 5.12378Z"
                  fill="#3DD883"
                />
              </svg>
              80%
            </span>
          </div>
          <div className={"h-40"}>
            <BarChart />
          </div>
        </div>
        <div
            className={
              "col-span-12 md:col-span-6 p-5 rounded chart-card"
            }
        >
          <div className={"flex justify-between items-center mb-2"}>
            <h3 className="flex items-center  text-[14px] leading-[20px] primary-text">
              {formatMessage({ id: "Chat Sent" })}
              <ToolTipUI
                tooltipText={formatMessage({
                  id: "Chat Sent",
                })}
              />
            </h3>
            <Select
              options={sortOptions}
              label={false}
              className={"event-select min-w-[200px] !w-full text-[14px]"}
              placeHolder={formatMessage({ id: "Select" })}
            />
          </div>
          <div className={"flex items-center gap-x-2"}>
            <h3 className={"text-[32px] primary-text font-semibold"}>100 B</h3>
            <span className="text-[14px] text-[#3DD883] flex items-center gap-x-1">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="14"
                height="14"
                viewBox="0 0 14 14"
                fill="none"
              >
                <path
                  opacity="0.5"
                  d="M7.5332 9.98475V4.31706C7.5332 4.0325 7.29435 3.80182 6.99972 3.80182C6.70508 3.80182 6.46624 4.0325 6.46624 4.31706V9.98475C6.46624 10.2693 6.70508 10.5 6.99972 10.5C7.29435 10.5 7.5332 10.2693 7.5332 9.98475Z"
                  fill="#3DD883"
                />
                <path
                  d="M7.30179 5.12378L9.53405 7.27972C9.75502 7.49314 10.1133 7.49314 10.3343 7.27972C10.5552 7.06631 10.5552 6.72027 10.3343 6.50686L7.37723 3.65091C7.1689 3.4497 6.8311 3.4497 6.62278 3.65091L3.66573 6.50686C3.44476 6.72027 3.44476 7.06631 3.66573 7.27972C3.8867 7.49314 4.24498 7.49314 4.46596 7.27972L6.69821 5.12378C6.86487 4.96281 7.13513 4.96281 7.30179 5.12378Z"
                  fill="#3DD883"
                />
              </svg>
              80%
            </span>
          </div>
          <div className={"h-40"}>
            <BarChart />
          </div>
        </div>
      </div>
      <div
        className={
          "grid grid-cols-12 md:gap-x-[22px] gap-y-[14px] md:gap-y-0 my-5"
        }
      >
        <div
            className={
              "col-span-12 md:col-span-8 xl:col-span-9 p-5 rounded chart-card"
            }
        >
          <div
            className={
              "flex flex-col md:flex-row  md:items-center justify-between gap-y-2 mb-5"
            }
          >
            <h3 className={"text-[20px] font-semibold primary-text"}>
              Top Performing Personalities
            </h3>
            <div className={"flex items-center gap-x-2"}>
              <Select
                options={sortOptions}
                label={false}
                className={"event-select min-w-[200px] !w-full text-[14px]"}
                placeHolder={formatMessage({ id: "Select" })}
              />
            </div>
          </div>
          <div className={"h-96 overflow-y-auto custom-scroll pr-5"}>
            <table className={"table table-auto"}>
              <tbody>
                {map(topPerformance, (item) => (
                  <tr>
                    <td>
                      <div className={"flex items-center gap-x-2"}>
                        <img
                          alt="Pic"
                          src={toAbsoluteUrl(`/media/avatars/300-1.jpg`)}
                          className={"w-[50px] h-[50px] rounded"}
                        />
                        <div className={"flex flex-col gap-y-1"}>
                          <p className="leading-5 font-semibold text-[14px] primary-text">
                            {get(item, "name")}
                          </p>
                          <p className="text-[12px] secondary-text font-medium">
                            Owner: {get(item, "owner")}
                          </p>
                        </div>
                      </div>
                    </td>
                    <td>
                      <div className={"flex flex-col gap-y-1"}>
                        <p className="leading-5 font-semibold text-[14px] primary-text flex gap-x-1">
                          {get(item, "totalVisitors")}
                          <span className="text-[10px] text-[#3DD883] flex items-center gap-x-1">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="14"
                              height="14"
                              viewBox="0 0 14 14"
                              fill="none"
                            >
                              <path
                                opacity="0.5"
                                d="M7.5332 9.98475V4.31706C7.5332 4.0325 7.29435 3.80182 6.99972 3.80182C6.70508 3.80182 6.46624 4.0325 6.46624 4.31706V9.98475C6.46624 10.2693 6.70508 10.5 6.99972 10.5C7.29435 10.5 7.5332 10.2693 7.5332 9.98475Z"
                                fill="#3DD883"
                              />
                              <path
                                d="M7.30179 5.12378L9.53405 7.27972C9.75502 7.49314 10.1133 7.49314 10.3343 7.27972C10.5552 7.06631 10.5552 6.72027 10.3343 6.50686L7.37723 3.65091C7.1689 3.4497 6.8311 3.4497 6.62278 3.65091L3.66573 6.50686C3.44476 6.72027 3.44476 7.06631 3.66573 7.27972C3.8867 7.49314 4.24498 7.49314 4.46596 7.27972L6.69821 5.12378C6.86487 4.96281 7.13513 4.96281 7.30179 5.12378Z"
                                fill="#3DD883"
                              />
                            </svg>
                            2.6%
                          </span>
                        </p>
                        <p className="text-[12px] secondary-text font-medium">
                          Total Visitors
                        </p>
                      </div>
                    </td>
                    <td>
                      {" "}
                      <div className={"flex flex-col gap-y-1"}>
                        <p className="leading-5 font-semibold text-[14px] primary-text flex gap-x-1">
                          {get(item, "followers")}
                          <span className="text-[10px] text-[#3DD883] flex items-center gap-x-1">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="14"
                              height="14"
                              viewBox="0 0 14 14"
                              fill="none"
                            >
                              <path
                                opacity="0.5"
                                d="M7.5332 9.98475V4.31706C7.5332 4.0325 7.29435 3.80182 6.99972 3.80182C6.70508 3.80182 6.46624 4.0325 6.46624 4.31706V9.98475C6.46624 10.2693 6.70508 10.5 6.99972 10.5C7.29435 10.5 7.5332 10.2693 7.5332 9.98475Z"
                                fill="#3DD883"
                              />
                              <path
                                d="M7.30179 5.12378L9.53405 7.27972C9.75502 7.49314 10.1133 7.49314 10.3343 7.27972C10.5552 7.06631 10.5552 6.72027 10.3343 6.50686L7.37723 3.65091C7.1689 3.4497 6.8311 3.4497 6.62278 3.65091L3.66573 6.50686C3.44476 6.72027 3.44476 7.06631 3.66573 7.27972C3.8867 7.49314 4.24498 7.49314 4.46596 7.27972L6.69821 5.12378C6.86487 4.96281 7.13513 4.96281 7.30179 5.12378Z"
                                fill="#3DD883"
                              />
                            </svg>
                            2.6%
                          </span>
                        </p>
                        <p className="text-[12px] secondary-text font-medium">
                          Followers
                        </p>
                      </div>
                    </td>
                    <td>
                      <div className={"flex flex-col gap-y-1"}>
                        <p className="leading-5 font-semibold text-[14px] primary-text flex gap-x-1">
                          {get(item, "likes")}
                          <span className="text-[10px] text-[#3DD883] flex items-center gap-x-1">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="14"
                              height="14"
                              viewBox="0 0 14 14"
                              fill="none"
                            >
                              <path
                                opacity="0.5"
                                d="M7.5332 9.98475V4.31706C7.5332 4.0325 7.29435 3.80182 6.99972 3.80182C6.70508 3.80182 6.46624 4.0325 6.46624 4.31706V9.98475C6.46624 10.2693 6.70508 10.5 6.99972 10.5C7.29435 10.5 7.5332 10.2693 7.5332 9.98475Z"
                                fill="#3DD883"
                              />
                              <path
                                d="M7.30179 5.12378L9.53405 7.27972C9.75502 7.49314 10.1133 7.49314 10.3343 7.27972C10.5552 7.06631 10.5552 6.72027 10.3343 6.50686L7.37723 3.65091C7.1689 3.4497 6.8311 3.4497 6.62278 3.65091L3.66573 6.50686C3.44476 6.72027 3.44476 7.06631 3.66573 7.27972C3.8867 7.49314 4.24498 7.49314 4.46596 7.27972L6.69821 5.12378C6.86487 4.96281 7.13513 4.96281 7.30179 5.12378Z"
                                fill="#3DD883"
                              />
                            </svg>
                            2.6%
                          </span>
                        </p>
                        <p className="text-[12px] secondary-text font-medium">
                          Likes
                        </p>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
        <div
          className={
            "col-span-12 md:col-span-4 xl:col-span-3 p-5 rounded  chart-card"
          }
        >
          <div
            className={
              "flex flex-col md:flex-row  md:items-center justify-between gap-y-2 h-11 mb-5"
            }
          >
            <h3 className={"text-[20px] font-semibold primary-text"}>
              Top Users
            </h3>
          </div>
          <div className={"h-96 overflow-y-auto custom-scroll pr-5"}>
            <table className={"table table-auto"}>
              <tbody>
                {map(topPerformance, (item) => (
                  <tr>
                    <td>
                      <div className={"flex items-center gap-x-2"}>
                        <img
                          alt="Pic"
                          src={toAbsoluteUrl(`/media/avatars/300-1.jpg`)}
                          className={"w-[50px] h-[50px] rounded"}
                        />
                        <div className={"flex flex-col gap-y-1"}>
                          <p className="leading-5 font-semibold text-[14px] primary-text">
                            {get(item, "owner")}
                          </p>
                          <p className="text-[12px] secondary-text font-medium">
                            2 personalites
                          </p>
                        </div>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PersonalitiesContainer;
