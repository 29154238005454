import React, { useEffect, useState } from "react";

import Breadcrumb from "../components/breadcrumb";
// import Trainbychat from "./trainbychat";
// import Trainbylink from "./trainbylink";
// import History from "./history";
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
// import Trainbydata from "./trainbydata";
import { useIntl } from "react-intl";
import Behavior from "./behavior";
import AvatarVisuals from "./avatar";
import VoiceSettings from "./voice";
import KnowledgeBase from "./knowledgeBase";
import { useSearchParams } from "react-router-dom";

const Index = () => {
  const { formatMessage } = useIntl();
  const [searchParams, setSearchParams] = useSearchParams();
  const tab = searchParams.get("tab");
  const allTabs = [
    "behaviour-traits",
    "avatar-visuals",
    "voice-audio",
    "knowledge-base",
  ];
  const [defaultTab, setDefaultTab] = useState<number | 0>(
    allTabs.indexOf(tab || "")
  );
  const [key, setKey] = useState("M" + Math.random().toString());

  useEffect(() => {
    setDefaultTab(allTabs.indexOf(tab || ""));
    setKey("M" + Math.random().toString());
  }, [tab]);

  return (
    <div className="md:px-5 px-3 py-3" key={key}>
      <Breadcrumb />
      <Tabs
        className="font-size-13"
        selectedTabClassName="bg-primary"
        defaultIndex={defaultTab || 0}
        onSelect={(index: number) => setSearchParams({ tab: allTabs[index] })}
      >
        <TabList
          className={
            " text-[13px] leading-[20px] font-normal primary-text setting-tabs overflow-x-auto overflow-y-hidden whitespace-nowrap  border-main border-b"
          }
        >
          <Tab>{formatMessage({ id: "Behavior & Traits" })}</Tab>
          <Tab>{formatMessage({ id: "Avatar & Visuals" })}</Tab>
          <Tab>{formatMessage({ id: "Voice & Audio" })}</Tab>
          <Tab>{formatMessage({ id: "Knowledge Base" })}</Tab>
          {/* <Tab>{formatMessage({ id: "History" })}</Tab> */}
        </TabList>
        <TabPanel>
          <Behavior />
        </TabPanel>
        <TabPanel>
          <AvatarVisuals />
        </TabPanel>
        <TabPanel>
          <VoiceSettings />
        </TabPanel>
        <TabPanel>
          <KnowledgeBase />
        </TabPanel>
        {/* <TabPanel>
          <History />
        </TabPanel> */}
      </Tabs>
    </div>
  );
};

export default Index;
