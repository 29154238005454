import React, { useEffect, useState } from "react";
import { map } from "lodash";
import { Formik } from "formik";
import FilterItem from "./filter-item";
import { useAuth } from "../../../../auth";
import { categories } from "../../images-category";
import { useIntl } from "react-intl";

const FilterForm = ({ handleFilters = () => { }, initFilters,trackUserAction }: { handleFilters?: any, initFilters?: any,trackUserAction?: React.MutableRefObject<boolean> }) => {
  const [filterObject, setFilterObject] = useState<any>({})
  const { referenceData } = useAuth()
  const [tempFilters, setTempFilters] = useState<any>(null)
  // let filters: any = []
  const { formatMessage } = useIntl();  

  const handleChange = (key: any, value: any) => {
    setFilterObject({ ...filterObject, [key]: value })
  }

  useEffect(() => {
    handleFilters(filterObject)
  }, [JSON.stringify(filterObject)])

  useEffect(() => {
    let filters = []
    if (referenceData) {
      filters.push({
        categoryName: 'Industry',
        name: 'industry',
        filters: referenceData?.platformOptions?.industry.map((value) => { return { value: value, label: value } })
      })

      filters.push({
        categoryName: 'Country',
        name: 'country',
        filters: referenceData?.platformOptions?.countries.map((value) => { return { value: value.countryId, label: value.country_name, icon: `${process.env.REACT_APP_APP_PUBLIC_CDN_BASE_URL}/${value.flag_icon}` } })
      })

      filters.push({
        categoryName: 'Gender',
        name: 'gender',
        filters: referenceData?.platformOptions?.genders.map((value) => { return { value: value, label: value } })
      })

      filters.push({
        categoryName: 'Category',
        name: 'category',
        filters: referenceData?.platformOptions?.categoryPersonalityTypes.map((value) => { return { value: value.category, label: value.category } })
      })

      filters.push({
        categoryName: 'Racial Information',
        name: 'racial_information',
        filters: referenceData?.personalityOptions?.racialInformation.map((value) => { return { value: value, label: value } })
      })

      filters.push({
        categoryName: `${formatMessage({ id: "Stages in Life" })}`,
        name: 'stage_in_life',
        filters: referenceData?.personalityOptions?.stageInLife.map((value) => { return { value: value, label: value } })
      })
      setTempFilters(filters)
    }
  }, [referenceData])

  if (!referenceData || !tempFilters) {
    return (<></>)
  }







  return (
    <Formik initialValues={{}} onSubmit={() => { }}>
      {({ values }) => (
        <div>
          {map(tempFilters, (item) => (
            <FilterItem
              item={item}
              handleChange={handleChange}
              initFilters={initFilters}
            // handleBlur={handleBlur}
              trackUserAction={trackUserAction}
            />
          ))}
        </div>
      )}
    </Formik>
  );
};

export default FilterForm;
