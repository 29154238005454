import React, { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { ToolTipUI } from "../../widgets/components/UI/ToolTipUI";
import clsx from "clsx";
import Slider from "../../../components/slider/index";
import TextArea from "../../widgets/components/Input/TextArea";
import { Field, Form, Formik } from "formik";
import { InfoCard } from "../../widgets/components/UI/InfoCard";
import FormikReactSelect from "../../widgets/components/Input/FormikReactSelect";
import { useAuth } from "../../auth";
import { FACEBOOK_CONFIG, TWITTER_CONFIG } from "../../../core/_constants";
import { BasicButton } from "../../widgets/components/UI/BasicButton";
import { CustomButton } from "../../widgets/components/UI/CustomButton";
import { Modal } from "react-bootstrap";
import { toast } from "react-toastify";
import { capitalize } from "lodash";
import { unlinkSocialAccount, updateSocialAccount } from "../core/_request";
import FormikReactCreatable from "../../widgets/components/Input/FormikReactCreatable";
import moment from "moment-timezone";
import { useNavigate } from "react-router-dom";
type SettingProps = {
  selectedSocialMedia: string;
  setAccounts: React.Dispatch<React.SetStateAction<any>>;
  accountDetails: any;
}
const SocialMediaSetting: React.FC<SettingProps> = ({
  selectedSocialMedia,
  setAccounts,
  accountDetails
}) => {
  const { formatMessage } = useIntl();
  const [deleteModalOpen, setDeleteModalOpen] = useState<boolean>(false);
  const { selectedPersonality } = useAuth()
  const navigate = useNavigate()
  const timeZoneOptions = moment.tz.names().map((item)=>{
    const now = moment.tz(item);
    const offset = now.format('Z'); 
    return  {
    id:item,
    name: `${item} (UTC${offset})`,
    value: item
    }
  })
  const schedulePost = accountDetails?.postSettings?.schedulePostsFrequency
  const initialSettings = {
    postLength: 220,
    tone: '',
    schedulePostsFrequency: '',
    schedulePostsTiming: '',
    hashTags: [],
    timezone:"UTC",
    socialAccountTags: [],
    ...accountDetails?.postSettings,
    ...schedulePost && {schedulePostsFrequency :schedulePost.posts+","+schedulePost.frequencyInDays},
  }
  const frequencyActualValue:Record<string,any> = {
    '2,1': {
      posts: 2,
      frequencyInDays: 1
    },
    '1,1': {
      posts: 1,
      frequencyInDays: 1
    },
    '1,2': {
      posts: 1,
      frequencyInDays: 2
    },
    '1,5': {
      posts: 1,
      frequencyInDays: 5
    },
    '2,5': {
      posts: 2,
      frequencyInDays: 5
    },
  }
  const frequencyOptions = [
    {
      label: "2 posts daily",
      value: '2,1',
    },
    {
      label: "1 post daily",
      value: '1,1',
    },
    {
      label: "1 post in 2 days",
      value: '1,2',
    },
    {
      label: "1 post in 5 days",
      value: '1,5',
    },
    {
      label: "2 posts in 5 days",
      value: '2,5',
    },
  ]
  const onSubmit = async (values: any, { setSubmitting }: any) => {
    const settings:Record<string,any> = values || {}
    let frequency:string = settings?.schedulePostsFrequency
    if (frequency) {
      settings.schedulePostsFrequency = frequencyActualValue[frequency]
    }
    if(settings?.hashTags){
     settings.hashTags = settings.hashTags.map((item:string)=>{
      return item[0]=='#' ? item.slice(1) : item
     })  
    }

    if(settings?.socialAccountTags){
      settings.socialAccountTags = settings.socialAccountTags.map((item:string)=>{
       return item[0]=='@' ? item.slice(1) : item
      })  
     }

    if (JSON.stringify(settings) == JSON.stringify(initialSettings)) {
      toast.warn("No changes detected")
    }

    setSubmitting(true)
    const { data: { success, errors } } = await updateSocialAccount(selectedPersonality.personalityId, selectedSocialMedia, settings)
    if (success) {
      toast.success("Updated successfully")
      setAccounts((accounts: Record<string, any>) => {
        let accCopy = { ...accounts };
        let selectedAcc = {...accCopy[selectedSocialMedia]}
        selectedAcc.postSettings = settings
        accCopy[selectedSocialMedia] = selectedAcc
        return accCopy
      })
    } else {
      toast.error("Error while updating social account", {
        autoClose: false,
        closeButton: true,
      });
    }

  };


  async function authorizeService() {
    switch (selectedSocialMedia) {
      case 'twitter': {
        window.open(`https://twitter.com/i/oauth2/authorize?response_type=code&client_id=${TWITTER_CONFIG.CLIENT_ID}&redirect_uri=${TWITTER_CONFIG.REDIRECT_URI}&scope=tweet.read%20tweet.write%20users.read%20offline.access&state=state&code_challenge=challenge&code_challenge_method=plain`)
      }
        break;
      case 'facebook': {
        const state = crypto.randomUUID()
        window.open(`https://www.facebook.com/v18.0/dialog/oauth?client_id=${FACEBOOK_CONFIG.CLIENT_ID}&redirect_uri=${FACEBOOK_CONFIG.REDIRECT_URI}&state=${state}&scope=pages_manage_posts,read_insights,business_management`)
      }
        break;
      case 'kamoto':{
        const code = crypto.randomUUID()
        navigate(`?code=${code}&state=${code}`)
      }
      break;
      default: {

      }
    }
  }
  const getButtonText: { [key: string]: any } = {
    'twitter': formatMessage({ id: "Connect Twitter" }),
    'facebook': formatMessage({ id: "Connect Facebook" }),
    'instagram': formatMessage({ id: "Connect Instagram" }),
    'kamoto': formatMessage({ id: "Connect Kamoto" })
  }
  const getButtonTextUnlink: { [key: string]: any } = {
    'twitter': formatMessage({ id: "Disconnect Twitter" }),
    'facebook': formatMessage({ id: "Disconnect Facebook" }),
    'instagram': formatMessage({ id: "Disconnect Instagram" }),
    'kamoto': formatMessage({ id: "Disconnect Kamoto" })
  }


  return (
    <div className="flex flex-col  mt-[12px] ">
      <Formik
        initialValues={initialSettings}
        onSubmit={onSubmit}
        // validationSchema={basicDetailsValidation}
        validateOnMount
        enableReinitialize
      >
        {(formik) => {
          return (
            <Form>
              <div className="schedulePostsFrequency">
                <div
                  className={
                    "tab-container-2 p-[14px] md:p-[20px]  rounded mb-[14px] md:mb-[20px]"
                  }
                >
                  <div className={"flex flex-col md:flex-row gap-[40px]"}>
                    <div className={"w-full"}>
                      <label
                        className={
                          "text-[16px] mb-[4px] flex items-center  leading-[24px] secondary-text"
                        }
                      >
                        {formatMessage({
                          id: "Typical Posts length (Characters count)",
                        })}
                        <ToolTipUI
                          tooltipText={formatMessage({
                            id: "Typical Posts length (Characters count)",
                          })}
                        />
                      </label>
                      <div className="flex flex-col lg:flex-row lg:items-center w-full lg:gap-[24px]">
                        <div className="w-full">
                          <Slider
                            startLabel="30"
                            stopLabel={selectedSocialMedia=='twitter' ? "280" :"1000"}
                            min={3}
                            max={selectedSocialMedia=='twitter' ? 28 :100}
                            defaultValue={formik.values.postLength / 10}
                            valueInPoints={true}
                            fieldName="postLength"
                            onChange={(value: number) => {
                              formik.setFieldValue('postLength', value * 10)
                            }}
                          />
                        </div>
                        <div className="mt-[8px]">
                          <span className="text-[#FFFFFFCC] font-[500] whitespace-nowrap ">
                            {formik.values.postLength} {formatMessage({ id: "characters" })}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className={
                    "tab-container-2 p-[14px] md:p-[20px] xl:pb-[20px] pb-[0px] md:pb-[0px] rounded flex flex-col xl:flex-row gap-[12px] mb-[20px]"
                  }
                >
                  <div className="xl:w-1/2">
                    <h4
                      className={
                        "text-[16px] leading-[24px] font-medium primary-text mb-[12px]"
                      }
                    >
                      {formatMessage({
                        id: "Describe Posts Nature & Tone",
                      })}
                    </h4>
                    <TextArea
                      label={formatMessage({
                        id: "Anything you want to specify about the posts",
                      })}
                      toolTipText={formatMessage({
                        id: "Anything you want to specify about the posts",
                      })}
                      fieldName={"tone"}
                      defaultValue={formik.values.tone}
                      placeholder={""}
                      formik={formik}
                      className={"!min-h-[96px]"}
                    />
                  </div>
                  <div className="xl:w-1/2">
                    <InfoCard
                      title={formatMessage({
                        id: "What’s post tone/ mood?",
                      })}
                      desc={
                        <>
                          {formatMessage({
                            id: "The 'Accessibility Geography' feature allows you to set geographic restrictions on the accessibility of your AI Personality. You achieve this by providing below information, The 'Accessibility Geography' feature allows you to set geographic restrictions on the accessibility of your AI Personality. You achieve this by providing",
                          })}
                          <br></br>
                          <br></br>
                        </>
                      }
                      slug={""}
                      isAnchor={"#"}
                    />
                  </div>
                </div>
                <div
                  className={
                    "tab-container-2 p-[14px] md:p-[20px] xl:pb-[20px] pb-[1px] md:pb-[1px] rounded  mb-[20px]"
                  }
                >
                  <h4
                    className={
                      "text-[16px] leading-[24px] font-medium primary-text mb-[8px]"
                    }
                  >
                    {formatMessage({
                      id: "Posts to Schedule in a week",
                    })}
                  </h4>
                  <label className="flex flex-row items-center text-[#FFFFFFA6] text-[13px] leading-[20px] mb-[4px]">
                    {formatMessage({
                      id: "Frequency of the posts",
                    })}
                    <ToolTipUI
                      tooltipText={formatMessage({
                        id: "Frequency of the posts",
                      })}
                    />
                  </label>
                  <div>
                    <FormikReactSelect
                      name={"schedulePostsFrequency"}
                      placeholder={formatMessage({
                        id: "Select frequency",
                      })}
                      formik={formik}
                      options={frequencyOptions}
                    />
                  </div>
                </div>
                <div
                  className={
                    "tab-container-2 p-[14px] md:p-[20px] rounded mb-[20px]"
                  }
                >
                  <h4
                    className={
                      "text-[16px] leading-[24px] font-medium primary-text mb-[14px] md:mb-[8px]"
                    }
                  >
                    {formatMessage({
                      id: "Ideal Posts Scheduling Time",
                    })}
                  </h4>

                  <div className="flex flex-col md:flex-row  gap-[12px]">
                    <div className={"flex items-center gap-x-4 md:w-1/2"}>
                      <Field
                        type={"radio"}
                        formik={formik}
                        name="schedulePostsTiming"
                        className={
                          "form-check-input min-w-[22px] min-h-[22px] max-w-[22px] max-h-[22px]"
                        }
                        checked={formik.values.schedulePostsTiming === "morning"}
                        value={"morning"}
                        onClick={() => formik.setFieldValue("schedulePostsTiming", "morning")}
                      />
                      <label
                        className={clsx(
                          "text-[14px] leading-6 flex gap-1",
                          formik.values.schedulePostsTiming === "morning"
                            ? "main-text"
                            : "secondary-text"
                        )}
                      >
                        {formatMessage({
                          id: "Morning (6AM- 12PM)",
                        })}

                        <ToolTipUI
                          tooltipText={formatMessage({
                            id: "Morning (6AM- 12PM)",
                          })}
                        />
                      </label>
                    </div>
                    <div className={"flex items-center gap-x-4 md:w-1/2"}>
                      <Field
                        type={"radio"}
                        formik={formik}
                        name="schedulePostsTiming"
                        className={
                          "form-check-input min-w-[22px] min-h-[22px] max-w-[22px] max-h-[22px]"
                        }
                        checked={formik.values.schedulePostsTiming === "afternoon"}
                        value={"afternoon"}
                        onClick={() => formik.setFieldValue("schedulePostsTiming", "afternoon")}
                      />
                      <label
                        className={clsx(
                          "text-[14px] leading-6 flex gap-1",
                          formik.values.schedulePostsTiming === "afternoon"
                            ? "main-text"
                            : "secondary-text"
                        )}
                      >
                        {formatMessage({
                          id: "Afternoon (12PM- 6PM)",
                        })}

                        <ToolTipUI
                          tooltipText={formatMessage({
                            id: "Afternoon (12PM- 6PM)",
                          })}
                        />
                      </label>
                    </div>
                  </div>

                  <div className="flex flex-col md:flex-row  gap-[12px] mt-[14px]">
                    <div className={"flex items-center gap-x-4 md:w-1/2"}>
                      <Field
                        type={"radio"}
                        formik={formik}
                        name="schedulePostsTiming"
                        className={
                          "form-check-input min-w-[22px] min-h-[22px] max-w-[22px] max-h-[22px]"
                        }
                        checked={formik.values.schedulePostsTiming === "evening"}
                        value={"evening"}
                        onClick={() => formik.setFieldValue("schedulePostsTiming", "evening")}
                      />
                      <label
                        className={clsx(
                          "text-[14px] leading-6 flex gap-1",
                          formik.values.schedulePostsTiming === "evening"
                            ? "main-text"
                            : "secondary-text"
                        )}
                      >
                        {formatMessage({
                          id: "Evening (6PM- 12AM)",
                        })}

                        <ToolTipUI
                          tooltipText={formatMessage({
                            id: "Evening (6PM- 12AM)",
                          })}
                        />
                      </label>
                    </div>
                    <div className={"flex items-center gap-x-4 md:w-1/2"}>
                      <Field
                        type={"radio"}
                        formik={formik}
                        name="schedulePostsTiming"
                        className={
                          "form-check-input min-w-[22px] min-h-[22px] max-w-[22px] max-h-[22px]"
                        }
                        checked={formik.values.schedulePostsTiming === "night"}
                        value={"night"}
                        onClick={() => formik.setFieldValue("schedulePostsTiming", "night")}
                      />
                      <label
                        className={clsx(
                          "text-[14px] leading-6 flex gap-1",
                          formik.values.schedulePostsTiming === "night"
                            ? "main-text"
                            : "secondary-text"
                        )}
                      >
                        {formatMessage({
                          id: "Night (12AM- 6AM)",
                        })}

                        <ToolTipUI
                          tooltipText={formatMessage({
                            id: "Night (12AM- 6AM)",
                          })}
                        />
                      </label>
                    </div>
                  </div>
                </div>
                <div
                  className={
                    "tab-container-2 p-[14px] md:p-[20px] xl:pb-[20px]  rounded mb-[20px]"
                  }
                >
                  <h4
                    className={
                      "text-[16px] leading-[24px] font-medium primary-text mb-[8px]"
                    }
                  >
                    {formatMessage({
                      id: "Hashtags",
                    })}
                  </h4>

                  <FormikReactCreatable
                    name={"hashTags"}
                    isMulti={true}
                    isClearable={true}
                    formik={formik}
                    label={formatMessage({
                      id: "Ideal Hashtags",
                    })}
                    placeholder={formatMessage({
                      id: "Type something and press Enter or Tab to create hashtags",
                    })}
                    tooltipText={formatMessage({
                      id: "Ideal Hashtags",
                    })}
                    options={formik.values?.hashTags?.map(
                      (el: string, id: number) => {
                        return { value: el, label: '#' + el.replace(/#/g,''), id: id };
                      }
                    )}
                  />
                    <FormikReactSelect
                          label={formatMessage({ id: "Time Zone" })}
                          name={"timezone"}
                          placeholder={formatMessage({
                            id: "Select the Time Zone",
                          })}
                          formik={formik}
                          tooltipText={formatMessage({
                            id: "GLOBAL.TOOLTIP.LOCALE.TIME_ZONE",
                          })}
                          options={timeZoneOptions.map((item:any)=>{
                            return  {label:item.name,value:item.value}
                          })}
                      />
                  {/* <label
                    className={
                      "text-[13px] mb-[4px] flex items-center  leading-[20px] secondary-text"
                    }
                  >
                    {formatMessage({
                      id: "Suggested For you",
                    })}
                    <ToolTipUI
                      tooltipText={formatMessage({
                        id: "Suggested For you",
                      })}
                    />
                  </label>
                  <div className="flex flex-row items-center mt-[8px] flex-wrap gap-[8px] ">
                    <div className="flex flex-row gap-[10px] items-center whitespace-nowrap bg-[#171825] border border-solid border-[#C2D24B33] p-[6px_12px] rounded-[8px] ">
                      {formatMessage({
                        id: "#Kingkhan",
                      })}{" "}
                      <svg
                        width="16"
                        height="16"
                        viewBox="0 0 16 16"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <g clip-path="url(#clip0_7190_43411)">
                          <path
                            d="M8.66657 7.33347L13.3335 7.33347L13.3335 8.6666L8.66657 8.6666V13.3335H7.33343V8.6666L2.66653 8.6666L2.66653 7.33347L7.33343 7.33347L7.33343 2.66657H8.66657L8.66657 7.33347Z"
                            fill="white"
                            fill-opacity="0.65"
                          />
                        </g>
                        <defs>
                          <clipPath id="clip0_7190_43411">
                            <rect width="16" height="16" fill="white" />
                          </clipPath>
                        </defs>
                      </svg>
                    </div>
                    <div className="flex flex-row gap-[10px] items-center whitespace-nowrap bg-[#171825] border border-solid border-[#C2D24B33] p-[6px_12px] rounded-[8px] ">
                      {formatMessage({
                        id: "#Kingkhan",
                      })}{" "}
                      <svg
                        width="16"
                        height="16"
                        viewBox="0 0 16 16"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <g clip-path="url(#clip0_7190_43411)">
                          <path
                            d="M8.66657 7.33347L13.3335 7.33347L13.3335 8.6666L8.66657 8.6666V13.3335H7.33343V8.6666L2.66653 8.6666L2.66653 7.33347L7.33343 7.33347L7.33343 2.66657H8.66657L8.66657 7.33347Z"
                            fill="white"
                            fill-opacity="0.65"
                          />
                        </g>
                        <defs>
                          <clipPath id="clip0_7190_43411">
                            <rect width="16" height="16" fill="white" />
                          </clipPath>
                        </defs>
                      </svg>
                    </div>
                    <div className="flex flex-row gap-[10px] items-center whitespace-nowrap bg-[#171825] border border-solid border-[#C2D24B33] p-[6px_12px] rounded-[8px] ">
                      {formatMessage({
                        id: "#Kingkhan",
                      })}{" "}
                      <svg
                        width="16"
                        height="16"
                        viewBox="0 0 16 16"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <g clip-path="url(#clip0_7190_43411)">
                          <path
                            d="M8.66657 7.33347L13.3335 7.33347L13.3335 8.6666L8.66657 8.6666V13.3335H7.33343V8.6666L2.66653 8.6666L2.66653 7.33347L7.33343 7.33347L7.33343 2.66657H8.66657L8.66657 7.33347Z"
                            fill="white"
                            fill-opacity="0.65"
                          />
                        </g>
                        <defs>
                          <clipPath id="clip0_7190_43411">
                            <rect width="16" height="16" fill="white" />
                          </clipPath>
                        </defs>
                      </svg>
                    </div>
                    <div className="flex flex-row gap-[10px] items-center whitespace-nowrap bg-[#171825] border border-solid border-[#C2D24B33] p-[6px_12px] rounded-[8px] ">
                      {formatMessage({
                        id: "#Kingkhan",
                      })}{" "}
                      <svg
                        width="16"
                        height="16"
                        viewBox="0 0 16 16"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <g clip-path="url(#clip0_7190_43411)">
                          <path
                            d="M8.66657 7.33347L13.3335 7.33347L13.3335 8.6666L8.66657 8.6666V13.3335H7.33343V8.6666L2.66653 8.6666L2.66653 7.33347L7.33343 7.33347L7.33343 2.66657H8.66657L8.66657 7.33347Z"
                            fill="white"
                            fill-opacity="0.65"
                          />
                        </g>
                        <defs>
                          <clipPath id="clip0_7190_43411">
                            <rect width="16" height="16" fill="white" />
                          </clipPath>
                        </defs>
                      </svg>
                    </div>
                    <div className="flex flex-row gap-[10px] items-center whitespace-nowrap bg-[#171825] border border-solid border-[#C2D24B33] p-[6px_12px] rounded-[8px] ">
                      {formatMessage({
                        id: "#Kingkhan",
                      })}{" "}
                      <svg
                        width="16"
                        height="16"
                        viewBox="0 0 16 16"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <g clip-path="url(#clip0_7190_43411)">
                          <path
                            d="M8.66657 7.33347L13.3335 7.33347L13.3335 8.6666L8.66657 8.6666V13.3335H7.33343V8.6666L2.66653 8.6666L2.66653 7.33347L7.33343 7.33347L7.33343 2.66657H8.66657L8.66657 7.33347Z"
                            fill="white"
                            fill-opacity="0.65"
                          />
                        </g>
                        <defs>
                          <clipPath id="clip0_7190_43411">
                            <rect width="16" height="16" fill="white" />
                          </clipPath>
                        </defs>
                      </svg>
                    </div>
                  </div> */}
                </div>
                <div
                  className={
                    "tab-container-2 p-[14px] md:p-[20px] xl:pb-[20px]  rounded mb-[20px]"
                  }
                >
                  <h4
                    className={
                      "text-[16px] leading-[24px] font-medium primary-text mb-[8px]"
                    }
                  >
                    {formatMessage({
                      id: "Social account Tags",
                    })}
                  </h4>

                  <FormikReactCreatable
                    name={"socialAccountTags"}
                    isMulti={true}
                    isClearable={true}
                    formik={formik}
                    label={formatMessage({
                      id: "Accounts, Pages or Handles to Tag (Wherever possible)",
                    })}
                    placeholder={formatMessage({
                      id: "Type something and press Enter or Tab to create account tags",
                    })}
                    tooltipText={formatMessage({
                      id: "Social Account Tags",
                    })}
                    options={formik.values?.socialAccountTags?.map(
                      (el: string, id: number) => {
                        return { value: el, label: '@' + el.replace(/@/g,''), id: id };
                      }
                    )}
                  />
                  {/* <label
                    className={
                      "text-[13px] mb-[4px] flex items-center  leading-[20px] secondary-text"
                    }
                  >
                    {formatMessage({
                      id: "Suggested For you",
                    })}
                    <ToolTipUI
                      tooltipText={formatMessage({
                        id: "Suggested For you",
                      })}
                    />
                  </label>
                  <div className="flex flex-row items-center mt-[8px] flex-wrap gap-[8px] ">
                    <div className="flex flex-row gap-[10px] items-center whitespace-nowrap bg-[#171825] border border-solid border-[#C2D24B33] p-[6px_12px] rounded-[8px] ">
                      {formatMessage({
                        id: "#Kingkhan",
                      })}{" "}
                      <svg
                        width="16"
                        height="16"
                        viewBox="0 0 16 16"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <g clip-path="url(#clip0_7190_43411)">
                          <path
                            d="M8.66657 7.33347L13.3335 7.33347L13.3335 8.6666L8.66657 8.6666V13.3335H7.33343V8.6666L2.66653 8.6666L2.66653 7.33347L7.33343 7.33347L7.33343 2.66657H8.66657L8.66657 7.33347Z"
                            fill="white"
                            fill-opacity="0.65"
                          />
                        </g>
                        <defs>
                          <clipPath id="clip0_7190_43411">
                            <rect width="16" height="16" fill="white" />
                          </clipPath>
                        </defs>
                      </svg>
                    </div>
                    <div className="flex flex-row gap-[10px] items-center whitespace-nowrap bg-[#171825] border border-solid border-[#C2D24B33] p-[6px_12px] rounded-[8px] ">
                      {formatMessage({
                        id: "#Kingkhan",
                      })}{" "}
                      <svg
                        width="16"
                        height="16"
                        viewBox="0 0 16 16"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <g clip-path="url(#clip0_7190_43411)">
                          <path
                            d="M8.66657 7.33347L13.3335 7.33347L13.3335 8.6666L8.66657 8.6666V13.3335H7.33343V8.6666L2.66653 8.6666L2.66653 7.33347L7.33343 7.33347L7.33343 2.66657H8.66657L8.66657 7.33347Z"
                            fill="white"
                            fill-opacity="0.65"
                          />
                        </g>
                        <defs>
                          <clipPath id="clip0_7190_43411">
                            <rect width="16" height="16" fill="white" />
                          </clipPath>
                        </defs>
                      </svg>
                    </div>
                    <div className="flex flex-row gap-[10px] items-center whitespace-nowrap bg-[#171825] border border-solid border-[#C2D24B33] p-[6px_12px] rounded-[8px] ">
                      {formatMessage({
                        id: "#Kingkhan",
                      })}{" "}
                      <svg
                        width="16"
                        height="16"
                        viewBox="0 0 16 16"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <g clip-path="url(#clip0_7190_43411)">
                          <path
                            d="M8.66657 7.33347L13.3335 7.33347L13.3335 8.6666L8.66657 8.6666V13.3335H7.33343V8.6666L2.66653 8.6666L2.66653 7.33347L7.33343 7.33347L7.33343 2.66657H8.66657L8.66657 7.33347Z"
                            fill="white"
                            fill-opacity="0.65"
                          />
                        </g>
                        <defs>
                          <clipPath id="clip0_7190_43411">
                            <rect width="16" height="16" fill="white" />
                          </clipPath>
                        </defs>
                      </svg>
                    </div>
                    <div className="flex flex-row gap-[10px] items-center whitespace-nowrap bg-[#171825] border border-solid border-[#C2D24B33] p-[6px_12px] rounded-[8px] ">
                      {formatMessage({
                        id: "#Kingkhan",
                      })}{" "}
                      <svg
                        width="16"
                        height="16"
                        viewBox="0 0 16 16"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <g clip-path="url(#clip0_7190_43411)">
                          <path
                            d="M8.66657 7.33347L13.3335 7.33347L13.3335 8.6666L8.66657 8.6666V13.3335H7.33343V8.6666L2.66653 8.6666L2.66653 7.33347L7.33343 7.33347L7.33343 2.66657H8.66657L8.66657 7.33347Z"
                            fill="white"
                            fill-opacity="0.65"
                          />
                        </g>
                        <defs>
                          <clipPath id="clip0_7190_43411">
                            <rect width="16" height="16" fill="white" />
                          </clipPath>
                        </defs>
                      </svg>
                    </div>
                    <div className="flex flex-row gap-[10px] items-center whitespace-nowrap bg-[#171825] border border-solid border-[#C2D24B33] p-[6px_12px] rounded-[8px] ">
                      {formatMessage({
                        id: "#Kingkhan",
                      })}{" "}
                      <svg
                        width="16"
                        height="16"
                        viewBox="0 0 16 16"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <g clip-path="url(#clip0_7190_43411)">
                          <path
                            d="M8.66657 7.33347L13.3335 7.33347L13.3335 8.6666L8.66657 8.6666V13.3335H7.33343V8.6666L2.66653 8.6666L2.66653 7.33347L7.33343 7.33347L7.33343 2.66657H8.66657L8.66657 7.33347Z"
                            fill="white"
                            fill-opacity="0.65"
                          />
                        </g>
                        <defs>
                          <clipPath id="clip0_7190_43411">
                            <rect width="16" height="16" fill="white" />
                          </clipPath>
                        </defs>
                      </svg>
                    </div>
                  </div> */}
                </div>

                {accountDetails ?
                  <button type="button" onClick={() => setDeleteModalOpen(true)} className="secondary-btn w-full  !text-[14px] !leading-[20px] !font-medium !py-[12px] !px-[16px]  rounded !ml-0 !my-0 md:max-w-[296px] ">
                    {getButtonTextUnlink[selectedSocialMedia]}
                  </button>
                  : <button type="button" onClick={authorizeService} className="secondary-btn w-full  !text-[14px] !leading-[20px] !font-medium !py-[12px] !px-[16px]  rounded !ml-0 !my-0 md:max-w-[296px] ">
                    {getButtonText[selectedSocialMedia]}
                  </button>}
                {/* <p className="text-[#FFFFFFA6] text-[12px] leading-[18px] mt-[8px]">
                  {formatMessage({
                    id: "Connection will auto expire on 5 PM, 15 Nov 2023 IST",
                  })}
                </p> */}

                <div className={"flex justify-end gap-[10px] mt-[24px]"}>
                {accountDetails && <CustomButton
                    customClass={`main-button text-[14px] leading-5 font-medium py-[12px] w-full sm:w-1/2  md:w-[140px] rounded`}
                    buttonText="Save"
                    isSubmitting={formik.isSubmitting}
                    loading={formik.isSubmitting}
                    isValid={true}
                  />}
                </div>
              </div>
            </Form>
          );
        }}
      </Formik>
      <Modal
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={deleteModalOpen}
        onHide={() => setDeleteModalOpen(false)}
      >
        <Modal.Header closeButton>
          <Modal.Title
            className="modal-title text-[20px] leading-[28px] font-weight-600 text-[#FFFFFFCC] "
            id="contained-modal-title-vcenter"
          >
            {formatMessage({ id: "Disconnect Account Confirmation" })}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className=" p-[16px] md:p-[24px]">
          <p className="text-[#FFFFFFCC] text-[16px]">
            {formatMessage({ id: "Are you sure to disconnect" })}{" "}
            <span className="text-[#C2D24B]">
              {capitalize(selectedSocialMedia)}
            </span>{" "}
            {formatMessage({ id: "account?" })}
          </p>
          <p className="text-[#FFFFFFA6] text-[12px]">This is a permanent action, your scheduled posts will no longer be published, also your posts settings will be removed.</p>
          <Formik
            initialValues={{}}
            onSubmit={async (values, { setSubmitting }) => {
              setSubmitting(true);
              const {
                data: { success, data, errors },
              } = await unlinkSocialAccount(selectedPersonality.personalityId, selectedSocialMedia);
              if (success) {
                setAccounts((accounts: Record<string, any>) => {
                  let accCopy = { ...accounts };
                  delete accCopy[selectedSocialMedia]
                  return accCopy
                }
                );
                setDeleteModalOpen(false);
                toast.success("Account un-linked successfully");
              } else {
                toast.error(errors[0],
                  {
                    autoClose: false,
                    closeButton: true, // Display close button (cross icon)
                  });
              }
              setSubmitting(false);
            }}
          >
            {(formik) => (
              <div className="flex gap-x-3 justify-end items-center">
                <BasicButton
                  buttonText={formatMessage({ id: "Cancel" })}
                  border="none"
                  color="#C2D24B1A"
                  textColor="#C2D24B"
                  customClass={"px-[24px] mt-8"}
                  height="44px"
                  onClick={() => {
                    setDeleteModalOpen(false);
                  }}
                />
                <CustomButton
                  customClass="mt-8"
                  isSubmitting={formik.isSubmitting}
                  isValid={true}
                  buttonText={formatMessage({ id: "Disconnect" })}
                  loading={formik.isSubmitting}
                  height={44}
                  widthLoading={1}
                  width={1}
                  onSubmit={formik.handleSubmit}
                />
              </div>
            )}
          </Formik>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default SocialMediaSetting;
