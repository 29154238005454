import React from "react";
import { Layout } from "../widgets/components/General/Layout";
import AuthBg from "../../assets/images/auth/auth-background.png";
import LogoImg from "../../assets/images/auth/logo.svg";
import { Link, Outlet, useNavigate } from "react-router-dom";
import { useIntl } from "react-intl";
import { useAuth } from "../auth";

export const OnboardingLayout = () => {
  const { formatMessage } = useIntl();
  const navigate = useNavigate()
  const {setNewPersonality,setOnboardingData,currentUser} = useAuth()

  const cancelPersonalityAdd = () => {
    if((currentUser?.personality?.length > 0 || false)){
      setNewPersonality(undefined)
      setOnboardingData(undefined)
      navigate('/dashboard')
    }else{
      navigate('/')
    }
  }

  return (
    <div
      className={
        "min-h-screen  flex  justify-center items-center  auth-bg py-4"
      }
      style={{
        backgroundImage: `url(${AuthBg})`,
      }}
    >
      <div className={"container"}>
        <div className={"flex justify-center mb-8"}>
          <img src={LogoImg} className={"w-[125px] md:w-[290px]"}  onClick={cancelPersonalityAdd}/>
        </div>
        <div
          className={
            "tab-container shadow-[0px_2px_15px_0px_#00000040] px-[16px] md:px-[68px] py-[32px] md:py-12 rounded-md "
          }
        >
          <Outlet />
        </div>
        <div className="flex flex-wrap justify-center md:justify-between mt-[12px] secondary-text text-[13px] leading-5">
          <p className={"w-full md:w-auto text-center"}>2023© Kamoto.AI</p>
          <div className={"flex justify-between gap-[25px]"}>
            <a href={process.env.REACT_APP_TERMS_POLICY_LINK} target="_blank" rel="noreferrer">
              {formatMessage({ id: "Terms of use" })}
            </a>
            <a href={process.env.REACT_APP_PRIVACY_POLICY_LINK} target="_blank" rel="noreferrer">
              {formatMessage({ id: "Privacy Policy" })}
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};
