/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useAuth } from "../../../../app/modules/auth";
import { Languages } from "./Languages";
import clsx from "clsx";
import { ThemeModeSwitcher } from "../theme-mode/ThemeModeSwitcher";
import { DisplayImage } from "../../../../app/modules/widgets/components/General/DisplayImage";
import { useIntl } from "react-intl";

const HeaderUserMenu: FC = () => {
  const navigate = useNavigate();
  const { currentUser, logout, personalityId } = useAuth();
  const selectedPersonality = currentUser?.personality.find(
    (el: any) => el.personalityId === personalityId
  );
  const { formatMessage } = useIntl();
  const handleLogout = async () => {
    await logout();
    navigate("/");
  };
  const itemClass = " ";
  return (
    <div
      className="py-4 menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg menu-state-primary fw-bold fs-6 w-325px"
      data-kt-menu="true"
    >
      <div className="px-3 menu-item">
        <div className="px-3 menu-content d-flex align-items-center">
          <div className="symbol symbol-50px me-5">
            <DisplayImage
              imgName={currentUser?.photo}
              width="100%"
              alt="profile"
              fit="contain"
            />
          </div>

          <div className="d-flex flex-column">
            <div className="fw-bolder d-flex align-items-center fs-5">
              {currentUser?.firstName} {currentUser?.lastName}
              {selectedPersonality && (
                <span className="px-2 py-1 badge badge-light-success fw-bolder fs-8 ms-2">
                  {formatMessage({
                    id: `${selectedPersonality?.personality_user?.designation}`,
                  })}
                </span>
              )}
            </div>
            <div className="fw-bold text-muted text-hover-primary fs-7 wrap">
              {currentUser?.email}
            </div>
          </div>
        </div>
      </div>

      <div className={clsx("app-navbar-item", itemClass)}>
        <ThemeModeSwitcher
          toggleBtnClass={clsx("btn-active-light-primary btn-custom")}
        />
      </div>
      <Languages />

      <div className="px-5 menu-item ">
        <Link to={`/my-chats`} className="px-5 menu-link">
          {formatMessage({ id: "My Chats" })}
        </Link>
      </div>

      <div className="px-5 menu-item ">
        <Link to={`/u/${currentUser?.username}`} className="px-5 menu-link">
          {formatMessage({ id: "My Public Profile" })}
        </Link>
      </div>

      <div className="px-5 menu-item ">
        <Link to="/dashboard" className="px-5 menu-link">
          {formatMessage({ id: "My Dashboard" })}
        </Link>
      </div>
      <div className="px-5 menu-item ">
        <Link to="/user-settings" className="px-5 menu-link">
          {formatMessage({ id: "Account Settings" })}
        </Link>
      </div>

      <div className="px-5 menu-item">
        <a onClick={handleLogout} className="px-5 menu-link">
          {formatMessage({ id: "Sign Out" })}
        </a>
      </div>
    </div>
  );
};

export { HeaderUserMenu };
