import { FC, useState } from "react";
import clsx from "clsx";
import { Link } from "react-router-dom";
import { useLocation } from "react-router";
import { checkIsActive, KTSVG, WithChildren } from "../../../../helpers";
import { useLayout } from "../../../core";

type Props = {
  to: string;
  title: string;
  icon?: any;
  linkIcon?: any;
  activeLinkIcon?: any;
  activeIcon?: any;
  fontIcon?: string;
  hasBullet?: boolean;
  target?: string;
  matchUpto?:string;
};

const SidebarMenuItem: FC<Props & WithChildren> = ({
  children,
  to,
  title,
  icon,
  linkIcon,
  activeLinkIcon,
  activeIcon,
  fontIcon,
  hasBullet = false,
  target,
  matchUpto
}) => {
  const { pathname,search } = useLocation();
  const isActive = matchUpto ? pathname.includes(matchUpto) : (!search ? pathname==to : (pathname+search||'').includes(to));
  const { config } = useLayout();
  const { app } = config;
  const [onHover, setOnHover] = useState(false);

  return (
    <div
      className="menu-item group"
      // onMouseEnter={() => setOnHover(true)}
      // onMouseLeave={() => setOnHover(false)}
    >
      <Link
        target={target}
        className={clsx("menu-link without-sub", {
          active: isActive,
        })}
        to={to}
      >
        {hasBullet && (
          <span className="menu-bullet">
            <span className="bullet bullet-dot "></span>
          </span>
        )}
        {icon && activeIcon && app?.sidebar?.default?.menu?.iconType === "svg" && (
          <span className="menu-icon ">
            {" "}
            <>{isActive ? activeIcon : icon}</>
          </span>
        )}
        {fontIcon && app?.sidebar?.default?.menu?.iconType === "font" && (
          <i className={clsx("bi fs-3", fontIcon)}></i>
        )}
        <span className="menu-title text-truncate whitespace-nowrap">{title}</span>
        {linkIcon &&
          activeLinkIcon &&
          app?.sidebar?.default?.menu?.iconType === "svg" && (
            <span className="menu-icon !mr-0 ml-auto ">
              {" "}
              <>{isActive ? activeLinkIcon : linkIcon}</>
            </span>
          )}
      </Link>
      {children}
    </div>
  );
};

export { SidebarMenuItem };
