/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC, useCallback, useEffect, useRef, useState } from "react";
import clsx from "clsx";
import {
  toAbsoluteUrl,
  defaultMessages,
  defaultUserInfos,
  MessageModel,
  UserInfoModel,
  messageFromClient,
} from "../../../../../_metronic/helpers";
import TextareaAutosize from "react-textarea-autosize";
import CustomToolTip from "../../../widgets/components/UI/CustomToolTip";
import { useIntl } from "react-intl";

import {
  addChat,
  addFreeChat,
  closeChatSession,
  createChatSession,
  createFreeChatSession,
  getChats,
  getCurrentChatSession,
} from "../../../apps/chat/core/_request";
import { ChatItem } from "../../../apps/chat/core/_models";
import { useAuth } from "../../../auth";
import Lottie from "lottie-react";
import ChatResponse from "./chat-response";
import TypingAnimationJson from "../../../../assets/lotties/typing-animation.json";
import TypingAnimationJsonLight from "../../../../assets/lotties/typing-animation-light.json";
import ArrowAnimationJson from "../../../../assets/lotties/arrow-animation.json";
import { useDateFormat } from "../../../../hooks/useDateFormat";
import {
  CHAT_INPUT_LENGTH,
  CHAT_TIMEOUT_IN_MS,
  SESSION_ERRORS,
} from "../../../../core/_constants";
import { toast } from "react-toastify";
import { copyToClipboard } from "../../../../utils";
import { getCdnUrl } from "../../../../core/_util";
import { Spinner } from "react-bootstrap";
import userDefault from ".././../../../assets/images/profile/user-default.png";
import LoginSignUpModal from "../../../marketplace-public/containers/ai-profile/modals/LoginSignUpModal";
import useSpeech2Text from "../../../../hooks/useSpeechRecognition";
import ShareChatModal from "../modals/ShareChatModal";
import useGTM from "../../../../hooks/useGTM";
import { GTMEvent } from "../../../../hooks/gtm_helpers";
import {useThemeMode} from "../../../../../_metronic/partials";
import { isEqual } from "lodash";
type Props = {
  isDrawer?: boolean;
};

interface ChatProps {
  isDrawer?: boolean;
  currentPersonality: any;
  outerContainerCss?: string;
  chatContainerCss?: string;
  closeSessionCallback?: (success: boolean, errors: any, data: any) => void;
  createSessionCheck?: () => Promise<boolean>;
  sessionPills?: boolean;
  showSampleQuestions?: boolean;
  defaultPrompt?: string;
}
const MainChat: FC<ChatProps> = ({
  isDrawer = false,
  currentPersonality,
  outerContainerCss,
  chatContainerCss,
  closeSessionCallback,
  createSessionCheck,
  sessionPills = false,
  showSampleQuestions = false,
  defaultPrompt,
}) => {
  const { mode } = useThemeMode();
  const [chatLoading, setChatLoading] = useState<boolean>(true);
  const [isResponding, setIsResponding] = useState<boolean>(false);
  const [isDisabled, setIsDisabled] = useState<boolean>(true);
  // const [mute, setMute] = useState(true);
  const {dataLayerPush} = useGTM()
  const [startRecord, setStartRecord] = useState(false);
  const [message, setMessage] = useState<string>(defaultPrompt || "");
  const [messages, setMessages] = useState<ChatItem[]>([]);
  const [userInfos] = useState<UserInfoModel[]>(defaultUserInfos);
  const containerRef = useRef<HTMLDivElement | null>(null);
  const inputRef = useRef<HTMLTextAreaElement | null>(null);
  const { getDateValue } = useDateFormat();
  const [page, setPage] = useState<number>(1);
  const [showQuestion, setShowQuestion] =
    useState<boolean>(showSampleQuestions);
  const [loginSignupModal, showLoginSignupModal] = useState<boolean>(false);
  const [creatingSession, setCreatingSession] = useState<boolean>(false);
  const [showQuotaError, setShowQuotaError] = useState<boolean>(false);
  const [shareChatId, setShareChatId] = useState("");
  const {
    currentUser,
    currentChatSession,
    setCurrentChatSession,
    prevChatSession,
    setPlayTextAvatar,
    avatarMuted,
    setAvatarMuted,
    setPauseListening,
    isAvatarEnabled
  } = useAuth();
  let userImage: string = getCdnUrl(currentUser?.photo || userDefault);
  let personalityImage: string = getCdnUrl(
    currentPersonality?.image || userDefault
  );
  const initialMessage = {
    id: "" + Math.random() * 100000,
    createdAt: new Date().toISOString(),
    chatSessionId: "",
    prompt: "",
    response: "",
    error: "",
    liked: 0,
    customParams: null,
    sessionAction: "",
    warn: null,
  };

  const proccessMessage = () => {
    currentUser ? sendMessage() : sendFreeMessage()
  }

  const { startListening, stopListening, listening, voiceText, isSupported } =
    useSpeech2Text(proccessMessage,setMessage);
  // const [tempVoiceMix, setTempVoiceMix] = useState<string | undefined>(undefined);


  // useEffect(() => {
  //   if (listening) {
  //     inputRef?.current?.focus();
  //     // setTempVoiceMix(message + voiceText);
  //     setMessage(voiceText)
  //   } else {
  //     // setTempVoiceMix(undefined);
  //     if (voiceText) {
  //       setMessage(voiceText);
  //     }
  //   }
  // }, [voiceText, listening]);

  const [defaultMessage, setDefaultMessage] =
    useState<ChatItem>(initialMessage);

  useEffect(() => {
    defaultPrompt && setMessage(defaultPrompt);
  }, [defaultPrompt]);

  useEffect(() => {
    if (!currentChatSession) {
      return;
    }
    let intervalId: any;
    intervalId = setInterval(async () => {
      if (!currentChatSession) {
        clearInterval(intervalId);
      }
      if (
        currentChatSession &&
        !checkSessionValid(
          currentChatSession.lastMessageAt || currentChatSession.startedAt
        )
      ) {
        clearInterval(intervalId);
        await closeSession(currentChatSession.id, "SESSION_EXPIRED");
      }
    }, 2000);

    return () => {
      clearInterval(intervalId);
    };
  }, [currentChatSession]);

  useEffect(() => {
    if (
      !currentChatSession &&
      prevChatSession.current &&
      prevChatSession.current.personalityId == currentPersonality.personalityId
    ) {
      if (sessionPills) {
        let newObj = { ...initialMessage };
        newObj.sessionAction = "closed";
        newObj.id = Math.random() * 10000 + "close";
        setMessages((messages) => [...messages, newObj]);
        setTimeout(() => scrollToBottom(), 500);
      }
    }
    if (
      currentChatSession &&
      !prevChatSession.current &&
      currentChatSession.personalityId == currentPersonality.personalityId &&
      currentChatSession.totalMessages == 0
    ) {
      if (sessionPills) {
        let newObj = { ...initialMessage };
        newObj.sessionAction = "created";
        newObj.id = Math.random() * 10000 + "create";
        setMessages((messages) => [...messages, newObj]);
        setTimeout(() => scrollToBottom(), 500);
      }
    }
  }, [currentChatSession, prevChatSession]);

  useEffect(() => {
    setIsDisabled(!message.trim() ? true : false);
  }, [message]);

  function scrollToBottom() {
    if (containerRef.current) {
      containerRef.current.scrollTop = containerRef.current.scrollHeight;
    }
  }

  function autoFocusInput() {
    setTimeout(() => inputRef?.current?.focus(), 0);
  }

  useEffect(() => {
    if (
      defaultMessage.prompt &&
      (defaultMessage.response || defaultMessage.error)
    ) {
      setDefaultMessage(initialMessage);
      setMessages((messages) => [...messages, defaultMessage]);
      setTimeout(() => scrollToBottom(), 500);
    }
  }, [defaultMessage]);

  async function createSession() {
    setCreatingSession(true);
    if (createSessionCheck) {
      let val = await createSessionCheck();
      if (!val) {
        setCreatingSession(false);
        return;
      }
    }
    const {
      data: { success, data, errors },
    } = await createChatSession(currentPersonality.personalityId);
    if (success) {
      setCurrentChatSession(data);
      setCreatingSession(false);
      dataLayerPush(GTMEvent.ChatSessionStartSuccessful)
      return data.id;
    } else {
      dataLayerPush(GTMEvent.ChatSessionStartFailed)
      if(errors[0]?.includes('Quota')){
        setShowQuotaError(true)
      }else {
      toast.error(
        errors[0]
          ? SESSION_ERRORS[errors[0]] || "Cannot start chat at the moment"
          : "Cannot start chat at the moment",
          {
            autoClose: false,
            closeButton: true, // Display close button (cross icon)
          }
      );
      }
      setDefaultMessage(initialMessage);
      setIsResponding(false);
      setCreatingSession(false);
      return null;
    }
  }

  async function closeSession(sessionId: string, reason?: string) {
    const {
      data: { success, data, errors },
    } = await closeChatSession(sessionId, reason);
    if (success) {
      setCurrentChatSession(undefined);
      dataLayerPush(GTMEvent.ChatSessionEndSuccessful)
    }else{
      dataLayerPush(GTMEvent.ChatSessionEndFailed)
    }
    if (closeSessionCallback) {
      await closeSessionCallback(success, data, errors);
    }
  }

  async function restartSession() {
    await closeSession(currentChatSession?.id, "CLOSED_BY_USER");
    await createSession();
    toast.success("New session started successfully");
    dataLayerPush(GTMEvent.RestartConversationPressSuccessful)
  }

  function copyTranscript() {
    let transcript: any = [];
    let personalityName = currentPersonality.name || "AI Personality";
    let userName = currentUser?.firstName || "User";
    messages.forEach((item) => {
      transcript.push({
        [userName]: item.prompt,
      });
      transcript.push({
        [personalityName]: item.response,
      });
    });
    if (transcript.length) {
      copyToClipboard(
        JSON.stringify(transcript),
        true,
        "Transcript copied successfully"
      );
      dataLayerPush(GTMEvent.ChatThreadCopySuccessful)
    } else {
      toast.info("No messages available to copy");
    }
  }

  const STORAGE_KEY = "kai_x_check";
  const sendFreeMessage = async () => {
    let key: any = localStorage.getItem(STORAGE_KEY);
    key = key ? JSON.parse(key) : {};
    setIsResponding(true);
    setDefaultMessage((defaultMessage) => {
      return { ...defaultMessage, prompt: message };
    });
    setMessage("");
    let currentSessionId = key[currentPersonality.personalityId];
    if (!currentSessionId) {
      const {
        data: { data, success, errors },
      } = await createFreeChatSession(currentPersonality.personalityId);
      if (success) {
        currentSessionId = data.id;
        key[currentPersonality.personalityId] = currentSessionId;
        localStorage.setItem(STORAGE_KEY, JSON.stringify(key));
      } else {
        if (errors[0]?.includes("Quota")) {
          setShowQuotaError(true);
        } else {
          toast.error("Chat cannot be started at the moment",
          {
            autoClose: false,
            closeButton: true, // Display close button (cross icon)
          });
        }
      }
    }
    if (!currentSessionId) {
      return;
    }
    const {
      data: { success, data, errors },
      status,
    } = await addFreeChat(message, currentSessionId);
    if (success) {
      if (data.error) {
        setTimeout(() => showLoginSignupModal(true), 3000);
      }
      setDefaultMessage((defaultMessage) => {
        return {
          ...defaultMessage,
          response: data.response,
          error: data.error,
          id: data.id,
          createdAt: data.createdAt,
          chatSessionId: data.chatSessionId,
          customParams: data.customParams,
        };
      });
      setPlayTextAvatar(data.response);
      if(!isAvatarEnabled){ setPauseListening(false) }
      setMessage("");
      setIsResponding(false);
      setCurrentChatSession((currentChatSession: any) => {
        return { ...currentChatSession, lastMessageAt: data.createdAt };
      });
    }else{
      setPauseListening(false)
    }
    autoFocusInput();
  };
  const sendMessage = async () => {
    let currentSessionId = currentChatSession?.id;
    if (!currentSessionId) {
      currentSessionId = await createSession();
    }
    if (!currentSessionId) {
      return;
    }
    showQuestion && setShowQuestion(false);
    setIsResponding(true);
    setDefaultMessage((defaultMessage) => {
      return { ...defaultMessage, prompt: message };
    });
    setMessage("");
    setTimeout(() => scrollToBottom(), 500);

    const {
      data: { success, data, errors },
      status,
    } = await addChat(message, currentSessionId);
    if (success) {
      setDefaultMessage((defaultMessage) => {
        return {
          ...defaultMessage,
          response: data.response,
          error: data.error,
          id: data.id,
          createdAt: data.createdAt,
          chatSessionId: data.chatSessionId,
          customParams: data.customParams,
        };
      });
      setPlayTextAvatar(data.response);
      if(!isAvatarEnabled){ setPauseListening(false) }
      setMessage("");
      setIsResponding(false);
      setCurrentChatSession((currentChatSession: any) => {
        return { ...currentChatSession, lastMessageAt: data.createdAt };
      });
      if (data.error && errorAction[data.error]?.close) {
        await closeSession(currentSessionId, data.error);
      }
    } else {
      setDefaultMessage((defaultMessage) => {
        return {
          ...defaultMessage,
          error: "Some error occured",
        };
      });
      setIsResponding(false);
      if (errors[0]?.includes("Quota")) {
        setShowQuotaError(true);
      }
      setPauseListening(false)
    }
    autoFocusInput();
  };

  const onEnterPress = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (e.keyCode === 13 && e.shiftKey === false) {
      e.preventDefault();
      currentUser ? sendMessage() : sendFreeMessage();
    }
  };

  async function getUserChats() {
    if (!currentPersonality) {
      return;
    }
    setChatLoading(true);
    const {
      data: { success, data, errors },
    } = await getChats(currentPersonality.personalityId, page);
    if (success) {
      if (data && data.length === 0) {
        containerRef.current?.removeEventListener("scroll", debounceScroll);
      }
      const chatContainer = containerRef.current;

      if (chatContainer) {
        const { scrollTop, scrollHeight } = chatContainer;

        setMessages((messages) =>
          messages.length ? [...data, ...messages] : data
        );
        requestAnimationFrame(() => {
          const newScrollPosition =
            chatContainer.scrollHeight - scrollHeight + scrollTop;
          chatContainer.scrollTop = newScrollPosition;
        });
      }
    }
    setChatLoading(false);
  }

  useEffect(() => {
    if (!currentUser) {
      setChatLoading(false);
      return;
    }
    getUserChats();
  }, [page, currentPersonality?.personalityId, currentUser]);

  function checkSessionValid(date: string) {
    return (
      new Date().valueOf() - new Date(date).valueOf() <= CHAT_TIMEOUT_IN_MS
    );
  }

  useEffect(() => {
    if (!currentUser) {
      return;
    }
    setMessages([]);
    setPage(1);
    async function getUserChatSession() {
      const {
        data: { success, data, errors },
      } = await getCurrentChatSession(currentPersonality.personalityId);
      if (success) {
        if (data) {
          setCurrentChatSession(data);
          let isValid = checkSessionValid(
            data?.lastMessageAt || data?.startedAt
          );
          if (!isValid) {
            closeSession(data.id, "SESSION_EXPIRED");
          }
        } else {
          setCurrentChatSession(null);
        }
      }
    }
    if (currentPersonality?.personalityId) {
      getUserChatSession();
      // getUserChats();
      setTimeout(() => scrollToBottom(), 1000);
    }
  }, [currentPersonality?.personalityId]);

  function checkTopAndFetchChats(e: any) {
    if (e.target.scrollTop <= 200) {
      setPage((page) => page + 1);
    }
  }

  let timeoutRef: NodeJS.Timeout;
  const debounceScroll = useCallback((e: any) => {
    clearTimeout(timeoutRef);
    timeoutRef = setTimeout(() => checkTopAndFetchChats(e), 200);
  }, []);

  useEffect(() => {
    // autoFocusInput();
    containerRef.current?.addEventListener("scroll", debounceScroll);
    return () =>
      containerRef.current?.removeEventListener("scroll", debounceScroll);
  }, []);

  const sampleQuestions =
    currentPersonality?.personalityJson?.welcome_message
      ?.sample_questions_users_can_ask || [];
  const errorAction: { [key: string]: { close: boolean; create: boolean } } = {
    SESSION_EXPIRED: { close: true, create: false },
    LOW_BALANCE: { close: true, create: false },
    NO_BALANCE: { close: true, create: false },
    SESSION_NOT_FOUND: { close: true, create: false },
    TRIAL_LIMIT_EXCEEDED: {close: true, create: false}
  };
  const { formatMessage } = useIntl();

  const startPos = 0.1;

  // useEffect(() => {
  //   const handleScroll = () => {
  //     if (containerRef.current) {
  //       const container = containerRef.current;
  //       const vh = container.clientHeight;

  //       const scrollFadeElements = container.querySelectorAll(".scrollFade");

  //       scrollFadeElements.forEach((element: any) => {
  //         const rect = element.getBoundingClientRect();
  //         const pos = rect.top - container.getBoundingClientRect().top;

  //         if (pos < vh * startPos) {
  //           const opacity = (pos / (vh * startPos)) * 1;
  //           element.style.opacity = opacity.toString();
  //         } else {
  //           element.style.opacity = "1";
  //         }
  //       });
  //     }
  //   };

  //   if (containerRef.current) {
  //     containerRef.current.addEventListener("scroll", handleScroll);
  //   }

  //   return () => {
  //     if (containerRef.current) {
  //       containerRef.current.removeEventListener("scroll", handleScroll);
  //     }
  //   };
  // }, []);

  const [showEnterMessageAnimation, setShowEnterMessageAnimation] =
    useState(false);

  useEffect(() => {
    if (chatLoading) return;
    setShowEnterMessageAnimation(!messages.length);
  }, [chatLoading, messages.length]);

  const emojiRegex =
    /[\u{1F600}-\u{1F64F}]|[\u{1F300}-\u{1F5FF}]|[\u{1F680}-\u{1F6FF}]|[\u{1F700}-\u{1F77F}]|[\u{1F780}-\u{1F7FF}]|[\u{1F800}-\u{1F8FF}]|[\u{1F900}-\u{1F9FF}]|[\u{1FA00}-\u{1FA6F}]|[\u{2600}-\u{26FF}]|[\u{2700}-\u{27BF}]|\u{23E9}|\u{23EA}|\u{23EB}|\u{23EC}|\u{2B05}|\u{2B06}|\u{2B07}|\u{2B1B}|\u{2B1C}|\u{2B50}|\u{2B55}|[0-9]\u{FE0F}\u{20E3}|\u{203C}\u{FE0F}|\u{2049}\u{FE0F}|\u{2122}\u{FE0F}|\u{2139}\u{FE0F}|\u{2194}-\u{2199}|\u{21A9}\u{FE0F}|\u{21AA}\u{FE0F}|\u{231A}\u{FE0F}|\u{231B}\u{FE0F}|\u{2328}\u{FE0F}|\u{23CF}\u{FE0F}|\u{23E9}\u{FE0F}|\u{23EA}\u{FE0F}|\u{23EB}\u{FE0F}|\u{23EC}\u{FE0F}|\u{23ED}\u{FE0F}|\u{23EE}\u{FE0F}|\u{23EF}\u{FE0F}|\u{23F0}\u{FE0F}|\u{23F1}\u{FE0F}|\u{23F2}\u{FE0F}|\u{23F3}\u{FE0F}|\u{23F8}\u{FE0F}|\u{23F9}\u{FE0F}|\u{23FA}\u{FE0F}|\u{24C2}\u{FE0F}/gu;
  function generateMessagesForShare(count: number) {
    let index = messages.findIndex((item) => item.id == shareChatId);
    let start = index + 1 - count,
      end = index + 1;
    start = start < 0 ? 0 : start;
    const origin = window.location.origin;
    const msgs = messages.slice(start, end).flatMap(({ prompt, response }) => {
      return [
        {
          user: {
            profileImageUrl: origin + userImage,
            name: currentUser?.firstName,
          },
          message: prompt.replace(emojiRegex, ""),
        },
        {
          user: {
            profileImageUrl: origin + personalityImage,
            name: currentPersonality?.name,
          },
          message: response.replace(emojiRegex, ""),
        },
      ];
    });
    return msgs;
  }

  return (
    <div
      className={`flex   md:py-4 flex-col justify-content-between h-full ${
        outerContainerCss || ""
      }`}
      id={isDrawer ? "kt_drawer_chat_messenger_body" : "kt_chat_messenger_body"}
    >
      <div
        ref={containerRef}
        className={clsx("scroll-y h-[50vh] pr-[8px]", chatContainerCss)}
        data-kt-element="messages"
        data-kt-scroll="true"
        data-kt-scroll-activate="{default: false, lg: true}"
        data-kt-scroll-max-height="auto"
        data-kt-scroll-dependencies={
          isDrawer
            ? "#kt_drawer_chat_messenger_header, #kt_drawer_chat_messenger_footer"
            : "#kt_header, #kt_app_header, #kt_app_toolbar, #kt_toolbar, #kt_footer, #kt_app_footer, #kt_chat_messenger_header, #kt_chat_messenger_footer"
        }
        data-kt-scroll-wrappers={
          isDrawer
            ? "#kt_drawer_chat_messenger_body"
            : "#kt_content, #kt_app_content, #kt_chat_messenger_body"
        }
        data-kt-scroll-offset={isDrawer ? "0px" : "5px"}
      >
        {chatLoading && (
          <div className="flex h-12 justify-content-center align-items-center">
            <Spinner animation="border" />
          </div>
        )}
        {!chatLoading &&
        showQuestion &&
        !messages.length &&
        sampleQuestions.length ? (
          <div className="flex w-full h-full bg-transparent justify-content-center align-items-center">
            <div className="max-w-[380px]">
              <h4 className="d-flex flex-row align-items-center justify-content-center text-[16px] leading-[24px] font-weight-600 text-[#FFFFFFCC] mb-[12px]">
                <svg
                  className="me-[12px]"
                  width="32"
                  height="32"
                  viewBox="0 0 32 32"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g clipPath="url(#clip0_1993_27971)">
                    <path
                      d="M17.3332 4V13.3333H25.3332L14.6665 28V18.6667H6.6665L17.3332 4Z"
                      stroke="white"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_1993_27971">
                      <rect width="32" height="32" fill="white" />
                    </clipPath>
                  </defs>
                </svg>
                {formatMessage({ id: "Sample questions you can ask me" })}
              </h4>
              {sampleQuestions.map((question: string) => (
                <p
                  onClick={() => {
                    setMessage(question);
                  }}
                  className="sample-question cursor-pointer text-[14px] p-[12px_24px] leading-[22px] font-normal secondary-text secondary-bg rounded-[4px] mb-[8px]"
                >
                  {question}
                </p>
              ))}
            </div>
          </div>
        ) : null}
        {messages.map(
          (
            {
              id,
              prompt,
              response,
              error,
              liked,
              createdAt,
              customParams,
              sessionAction,
            },
            index
          ) => {
            const templateAttr = {};
            // if (message.true) {
            //   Object.defineProperty(templateAttr, "data-kt-element", {
            //     value: `template-${message.type}`,
            //   });
            // }
            const contentClass = `${isDrawer ? "" : "d-flex"} mb-10`;
            return (
              <>
                {(index == 0 ||
                  new Date(createdAt).getDate() !=
                    new Date(messages[index - 1].createdAt).getDate()) && (
                  <div className={clsx("py-4 timeline")}>
                    <span></span>
                    <p>{getDateValue(createdAt)}</p>
                    <span></span>
                  </div>
                )}
                {sessionAction ? (
                  <p
                    className={`mx-auto text-center py-2 px-2 text-sm my-4 w-[140px] ${
                      sessionAction == "closed"
                        ? "session-ended text-[#D24B4B]"
                        : ""
                    } ${
                      sessionAction == "created"
                        ? "session-ended text-[#C2D24B]"
                        : ""
                    } rounded`}
                  >
                    {
                      sessionAction == "created"
                        ? formatMessage({
                          id: "New Session Started"
                        })
                        : formatMessage({
                          id: "Session Ended"
                        })
                    }
                  </p>
                ) : (
                  <>
                    <div
                      key={`${id}-prompt`}
                      className={clsx(
                        "d-flex",
                        contentClass,
                        "mb-10",
                        "justify-content-end",

                        {
                          // "d-none": message.template,
                        }
                      )}
                      {...templateAttr}
                    >
                      <div
                        className={
                          "flex flex-col md:flex-row md:items-start gap-x-8 gap-y-2"
                        }
                      >
                        <div
                          className={clsx(
                            "flex items-start gap-x-2 rounded px-2 py-3 relative",
                            "chat-request"
                          )}
                        >
                          <div className={"flex min-h-[35px] items-center"}>
                            <div
                              className={clsx(
                                "secondary-text",
                                " break overflow-hidden text-break text-[14px] leading-[22px] font-normal max-w-2xl float-right"
                                // `text-${message.type === 'in' ? 'start' : 'end'}`
                              )}
                              data-kt-element="message-text"
                              dangerouslySetInnerHTML={{ __html: prompt }}
                            ></div>
                          </div>
                          <div className="flex items-start">
                            <>
                              <div className="w-[35px] h-[35px] symbol symbol-35px symbol-circle">
                                <img
                                  className="w-full object-cover object-top"
                                  alt="Pic"
                                  src={userImage}
                                />
                              </div>
                            </>
                          </div>
                        </div>
                      </div>
                    </div>
                    <ChatResponse
                      key={`response-${id}`}
                      data={{
                        contentClass,
                        id,
                        response,
                        error,
                        personalityImage,
                        liked,
                        customParams,
                        setShareChatId,
                      }}
                    />
                  </>
                )}
              </>
            );
          }
        )}
        {defaultMessage.prompt && (
          <>
            <div
              key={`${defaultMessage.id}-prompt`}
              className={clsx(
                "d-flex",
                `${isDrawer ? "" : "d-flex"}mb-10`,
                "mb-10",
                "justify-content-end",
                {
                  // "d-none": message.template,
                }
              )}
            >
              <div
                className={
                  "flex flex-col md:flex-row md:items-start gap-x-8 gap-y-2"
                }
              >
                <div
                  className={clsx(
                    "flex items-start gap-x-2 rounded px-2 py-3 relative",
                    "chat-request"
                  )}
                >
                  <div
                    className={clsx(
                      "secondary-text overflow-hidden text-break text-[14px] leading-[22px] font-normal  max-w-2xl float-right"
                      // `text-${message.type === 'in' ? 'start' : 'end'}`
                    )}
                    data-kt-element="message-text"
                    dangerouslySetInnerHTML={{ __html: defaultMessage.prompt }}
                  ></div>
                  <div className="flex items-center">
                    <>
                      <div className="w-12 h-12 symbol symbol-35px symbol-circle">
                        <img alt="Pic" src={userImage} />
                      </div>
                    </>
                  </div>
                </div>
              </div>
            </div>
            <div
              key={`${defaultMessage.id}-response`}
              className={clsx(
                "d-flex",
                `${isDrawer ? "" : "d-flex"} mb-10`,
                "mb-10",
                "justify-content-start",
                {
                  // "d-none": message.template,
                }
              )}
            >
              <div
                className={
                  "flex flex-col md:flex-row md:items-start gap-x-8 gap-y-2"
                }
              >
                <div
                  className={clsx(
                    "flex items-start gap-x-2 rounded px-2 py-3 relative"
                    // "bg-[#2E2F45] "
                  )}
                >
                  <div className="flex items-center">
                    <>
                      <div className="w-12 h-12 symbol symbol-35px symbol-circle">
                        <img alt="Pic" src={personalityImage} />
                      </div>
                    </>
                  </div>
                  {isResponding ? (
                    <Lottie
                      animationData={isEqual(mode,'light') ?  TypingAnimationJsonLight:TypingAnimationJson}
                      style={{
                        width: 85,
                        height: 45,
                        borderRadius: 25,
                        borderTopLeftRadius: 0,
                        overflow: "hidden",
                      }}
                    />
                  ) : (
                    <>
                      <div
                        className={clsx(
                          "secondary-text text-[14px] overflow-hidden text-break leading-[22px] font-normal max-w-2xl float-right"
                          // `text-${message.type === 'in' ? 'start' : 'end'}`
                        )}
                        data-kt-element="message-text"
                        dangerouslySetInnerHTML={{
                          __html: defaultMessage.response,
                        }}
                      ></div>
                      <p
                        style={{ marginTop: -20, color: "#ff2424" }}
                        className="text-sm"
                      >
                        {defaultMessage.error}
                      </p>
                    </>
                  )}
                </div>
              </div>
            </div>
          </>
        )}
        {showQuotaError && (
          <p
            className={
              "mx-auto text-center py-2 px-2 text-sm my-4 max-w-[80%] bg-[#000000bb] text-[#D24B4B]"
            }
          >
            {formatMessage({
              id: `This AI Personality is temporarily unavailable. Please contact the owner or manager of this AI Personality`,
            })}
          </p>
        )}
      </div>
      <div
        className="py-[8px] card-footer"
        id={
          isDrawer
            ? "kt_drawer_chat_messenger_footer"
            : "kt_chat_messenger_footer"
        }
      >
        {creatingSession && (
          <div className="text-center secondary-text py-2 px-4 mb-4 border-2 border-[#C2D24B] w-fit mx-auto rounded">
            Creating Session for you. Please wait...
          </div>
        )}
        <div className={"flex flex-col  md:flex-row md:items-end"}>
          <div className={"relative flex-grow"}>
            <TextareaAutosize
              maxLength={CHAT_INPUT_LENGTH}
              ref={inputRef}
              style={{ height: 52, background: "#2E2F45" }}
              className={clsx(
                " search-input-table ",
                "form-control rounded pr-10"
              )}
              data-kt-element="input"
              placeholder={formatMessage({
                id: `${isResponding ? "Waiting for reply" : "Type a message"}`,
              })}
              value={message}
              disabled={isResponding}
              onChange={(e) =>
                setMessage(
                  e.target.value.length <= CHAT_INPUT_LENGTH
                    ? e.target.value
                    : e.target.value.slice(0, CHAT_INPUT_LENGTH)
                )
              }
              onKeyDown={onEnterPress}
            />
            <button
              className={"absolute right-4 bottom-3"}
              disabled={isResponding || isDisabled}
              onClick={
                isResponding || isDisabled
                  ? () => {}
                  : () => {
                      currentUser ? sendMessage() : sendFreeMessage();
                    }
              }
            >
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g clipPath="url(#clip0_344_24401)">
                  <path
                    d="M9.99958 14L20.9996 3M9.99958 14L13.4996 21C13.5435 21.0957 13.6139 21.1769 13.7025 21.2338C13.7912 21.2906 13.8943 21.3209 13.9996 21.3209C14.1049 21.3209 14.208 21.2906 14.2966 21.2338C14.3853 21.1769 14.4557 21.0957 14.4996 21L20.9996 3M9.99958 14L2.99958 10.5C2.90384 10.4561 2.82271 10.3857 2.76583 10.2971C2.70895 10.2084 2.67871 10.1053 2.67871 10C2.67871 9.89468 2.70895 9.79158 2.76583 9.70295C2.82271 9.61431 2.90384 9.54387 2.99958 9.5L20.9996 3"
                    stroke={isResponding || isDisabled ? "#ababab" : "#C2D24B"}
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_344_24401">
                    <rect width="24" height="24" fill="white" />
                  </clipPath>
                </defs>
              </svg>
            </button>
            {showEnterMessageAnimation && (
              <div className="absolute top-[-83px] left-0 enter-message-animation p-[10px_10px_0px_10px] rounded-lg">
                <p className="mb-0 text-[12px] leading-[18px]">
                  {formatMessage({ id: "Enter your message here" })}
                </p>
                <Lottie
                  animationData={ArrowAnimationJson}
                  style={{

                    width: 100,
                    height: 55,
                    borderRadius: 25,
                    borderTopLeftRadius: 0,
                    overflow: "hidden",
                  }}
                />
              </div>
            )}
          </div>
          <div
            className={
              "flex justify-end md:justify-normal gap-1 p-[14px_8px_6px] md:p-[10px]"
            }
          >
            <CustomToolTip tooltipText={avatarMuted ? "Unmute" : "Mute"}>
              {!avatarMuted ? (
                <button
                  className={
                    "transition-all duration-300 secondary-bg-hover h-[24px] w-[24px] flex items-center justify-center rounded-full"
                  }
                  onClick={() => {setAvatarMuted(true);dataLayerPush(GTMEvent.SpeakerOffSuccessful)}}
                >
                  <svg
                    width="18"
                    height="18"
                    viewBox="0 0 18 18"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M11.25 6.00004C11.7157 6.34934 12.0937 6.80228 12.3541 7.32299C12.6145 7.8437 12.75 8.41787 12.75 9.00004C12.75 9.58221 12.6145 10.1564 12.3541 10.6771C12.0937 11.1978 11.7157 11.6507 11.25 12M13.275 3.75004C14.058 4.38278 14.6895 5.18263 15.1234 6.09103C15.5572 6.99943 15.7824 7.99336 15.7824 9.00004C15.7824 10.0067 15.5572 11.0007 15.1234 11.909C14.6895 12.8174 14.058 13.6173 13.275 14.25M4.5 11.25H3C2.80109 11.25 2.61032 11.171 2.46967 11.0304C2.32902 10.8897 2.25 10.699 2.25 10.5V7.50004C2.25 7.30113 2.32902 7.11036 2.46967 6.96971C2.61032 6.82906 2.80109 6.75004 3 6.75004H4.5L7.125 3.37504C7.19055 3.24771 7.29949 3.14803 7.43212 3.09401C7.56475 3.03999 7.71233 3.03519 7.84819 3.08047C7.98405 3.12576 8.09923 3.21815 8.17292 3.34095C8.24662 3.46374 8.27395 3.60885 8.25 3.75004V14.25C8.27395 14.3912 8.24662 14.5363 8.17292 14.6591C8.09923 14.7819 7.98405 14.8743 7.84819 14.9196C7.71233 14.9649 7.56475 14.9601 7.43212 14.9061C7.29949 14.852 7.19055 14.7524 7.125 14.625L4.5 11.25Z"
                      stroke="white"
                      className={"icon-stroke"}
                      strokeOpacity="0.8"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </button>
              ) : (
                <button
                  className={
                    "transition-all duration-300 secondary-bg-hover h-[24px] w-[24px] flex items-center justify-center rounded-full"
                  }
                  onClick={() => {setAvatarMuted(false);dataLayerPush(GTMEvent.SpeakerOnSuccessful)}}
                >
                  <svg
                    width="18"
                    height="18"
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g clipPath="url(#clip0_497_37686)">
                      <path
                        d="M12.5 6.66667C13.1236 7.13437 13.6035 7.76761 13.8852 8.49445C14.1668 9.22128 14.2389 10.0126 14.0933 10.7783M12.9458 12.9467C12.8065 13.0859 12.6576 13.2151 12.5 13.3333M14.75 4.16667C16.0875 5.24753 17.0129 6.75518 17.3712 8.43704C17.7296 10.1189 17.4991 11.8728 16.7183 13.405M15.3217 15.3208C15.14 15.5013 14.9492 15.6724 14.75 15.8333M7.5575 4.21167L7.91667 3.75C7.9895 3.60853 8.11054 3.49777 8.25791 3.43774C8.40528 3.37772 8.56925 3.37238 8.72021 3.4227C8.87117 3.47302 8.99915 3.57568 9.08103 3.71212C9.16291 3.84856 9.19328 4.00979 9.16667 4.16667V5.83333M9.16667 9.16667V15.8333C9.19328 15.9902 9.16291 16.1514 9.08103 16.2879C8.99915 16.4243 8.87117 16.527 8.72021 16.5773C8.56925 16.6276 8.40528 16.6223 8.25791 16.5623C8.11054 16.5022 7.9895 16.3915 7.91667 16.25L5 12.5H3.33333C3.11232 12.5 2.90036 12.4122 2.74408 12.2559C2.5878 12.0996 2.5 11.8877 2.5 11.6667V8.33333C2.5 8.11232 2.5878 7.90036 2.74408 7.74408C2.90036 7.5878 3.11232 7.5 3.33333 7.5H5L6.07833 6.11333M2.5 2.5L17.5 17.5"
                        className={"icon-stroke"}
                        strokeOpacity="0.8"
                        strokeWidth="1.4"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_497_37686">
                        <rect width="20" height="20" className={"icon"} />
                      </clipPath>
                    </defs>
                  </svg>
                </button>
              )}
            </CustomToolTip>
            {isSupported && (
              <CustomToolTip tooltipText={"Turn on microphone"}>
                {!listening ? (
                  <button
                    className={
                      "transition-all duration-300 secondary-bg-hover h-[24px] w-[24px] flex items-center justify-center rounded-full"
                    }
                    onClick={() => {startListening();dataLayerPush(GTMEvent.MicTurnOnSuccessful)}}
                  >
                    <svg
                      width="18"
                      height="18"
                      viewBox="0 0 18 18"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M6.75 3.75C6.75 3.15326 6.98705 2.58097 7.40901 2.15901C7.83097 1.73705 8.40326 1.5 9 1.5C9.59674 1.5 10.169 1.73705 10.591 2.15901C11.0129 2.58097 11.25 3.15326 11.25 3.75V7.5C11.25 8.09674 11.0129 8.66903 10.591 9.09099C10.169 9.51295 9.59674 9.75 9 9.75C8.40326 9.75 7.83097 9.51295 7.40901 9.09099C6.98705 8.66903 6.75 8.09674 6.75 7.5V3.75Z"
                        className={"icon-stroke"}
                        strokeOpacity="0.8"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M3.75 7.5C3.75 8.89239 4.30312 10.2277 5.28769 11.2123C6.27226 12.1969 7.60761 12.75 9 12.75M9 12.75C10.3924 12.75 11.7277 12.1969 12.7123 11.2123C13.6969 10.2277 14.25 8.89239 14.25 7.5M9 12.75V15.75M6 15.75H12"
                        className={"icon-stroke"}
                        strokeOpacity="0.8"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </button>
                ) : (
                  <button
                    className={
                      "transition-all duration-300 secondary-bg-hover h-[24px] w-[24px] flex items-center justify-center rounded-full"
                    }
                    onClick={() => {stopListening();dataLayerPush(GTMEvent.MicTurnOffSuccessful)}}
                  >
                    <svg
                      width="18"
                      height="18"
                      viewBox="0 0 20 20"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <circle cx="10" cy="10" r="5" className={"icon"} />
                      <circle cx="10" cy="10" r="8" className={"icon-stroke"} />
                    </svg>
                  </button>
                )}
              </CustomToolTip>
            )}
            <CustomToolTip tooltipText={"Restart conversation"}>
              <button
                className={
                  "transition-all duration-300 secondary-bg-hover h-[24px] w-[24px] flex items-center justify-center rounded-full"
                }
                onClick={restartSession}
              >
                <svg
                  width="18"
                  height="18"
                  viewBox="0 0 18 18"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M14.95 9.78075C14.8084 10.8607 14.3755 11.8818 13.6977 12.7344C13.0198 13.587 12.1227 14.239 11.1025 14.6205C10.0823 15.002 8.97747 15.0986 7.90653 14.8999C6.83559 14.7013 5.83894 14.2148 5.02345 13.4927C4.20797 12.7707 3.60442 11.8402 3.27754 10.8012C2.95066 9.76223 2.91278 8.65384 3.16796 7.59494C3.42313 6.53604 3.96174 5.56658 4.72602 4.79052C5.4903 4.01446 6.45142 3.46109 7.50629 3.18975C10.4305 2.43975 13.4575 3.945 14.575 6.75"
                    className={"icon-stroke"}
                    strokeOpacity="0.8"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M15 3V6.75H11.25"
                    className={"icon-stroke"}
                    strokeOpacity="0.8"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </button>
            </CustomToolTip>
            <CustomToolTip tooltipText={"Copy transcript to clipboard"}>
              <button
                className={
                  "transition-all duration-300 secondary-bg-hover h-[24px] w-[24px] flex items-center justify-center rounded-full"
                }
                onClick={copyTranscript}
              >
                <svg
                  width="18"
                  height="18"
                  viewBox="0 0 18 18"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M6 7.5C6 7.10218 6.15804 6.72064 6.43934 6.43934C6.72064 6.15804 7.10218 6 7.5 6H13.5C13.8978 6 14.2794 6.15804 14.5607 6.43934C14.842 6.72064 15 7.10218 15 7.5V13.5C15 13.8978 14.842 14.2794 14.5607 14.5607C14.2794 14.842 13.8978 15 13.5 15H7.5C7.10218 15 6.72064 14.842 6.43934 14.5607C6.15804 14.2794 6 13.8978 6 13.5V7.5Z"
                    className={"icon-stroke"}
                    strokeOpacity="0.8"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M12 6V4.5C12 4.10218 11.842 3.72064 11.5607 3.43934C11.2794 3.15804 10.8978 3 10.5 3H4.5C4.10218 3 3.72064 3.15804 3.43934 3.43934C3.15804 3.72064 3 4.10218 3 4.5V10.5C3 10.8978 3.15804 11.2794 3.43934 11.5607C3.72064 11.842 4.10218 12 4.5 12H6"
                    className={"icon-stroke"}
                    strokeOpacity="0.8"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </button>
            </CustomToolTip>
          </div>
        </div>
      </div>
      <LoginSignUpModal
        show={loginSignupModal}
        discard={() => showLoginSignupModal(false)}
      />
      {shareChatId && (
        <ShareChatModal
          generateMessagesForShare={generateMessagesForShare}
          show={!!shareChatId}
          onHide={() => setShareChatId("")}
          personalityInView={currentPersonality}
        />
      )}
    </div>
  );
};

export default MainChat;
