import React, { createRef, forwardRef, useState } from "react";
import clsx from "clsx";
import useInfiniteScroll from "../../../../hooks/useInfiniteScroll";
import { submitTrainings } from "../../core/_request";
import { Spinner } from "react-bootstrap";
import { toast } from "react-toastify";
import { useIntl } from "react-intl";
import useGTM from "../../../../hooks/useGTM";
import { GTMEvent } from "../../../../hooks/gtm_helpers";
import { useSearchParams } from "react-router-dom";

const Index: React.FC<any> = forwardRef<HTMLDivElement, any>(
  (
    {
      heading,
      open = true,
      setOpen,
      onPlusClick,
      children,
      draftTrainings,
      setTrainings,
    },
    ref
  ) => {
    const [loading, setLoading] = useState<boolean>(false);
    const { formatMessage } = useIntl();
    const { dataLayerPush } = useGTM()
    const [sp, setSp] = useSearchParams()
    const tab = sp.get('tab')
    async function handleClick() {
      setLoading(true);
      const {
        data: { success, data },
      } = await submitTrainings(draftTrainings);
      toast.info("Submitting trainings. Please wait ...");
      if (success) {
        toast.success("Trainings submitted successfully");
        if (tab == 'train-by-chats') {
          dataLayerPush(GTMEvent.TrainingChatSubmissionSuccessful, { count: draftTrainings.length })
        }
        if (tab == 'train-by-private-files') {
          dataLayerPush(GTMEvent.TrainingFileSubmissionSuccessful, { count: draftTrainings.length })
        }
        const map = draftTrainings.reduce(
          (result: Map<string, string>, item: string) => {
            result.set(item, item);
            return result;
          },
          new Map()
        );

        setTrainings((trainings: any) =>
          trainings.map((item: any) => {
            return {
              ...item,
              ...(map.get(item.trainingId) && { status: "submitted" }),
            };
          })
        );
      } else {
        if (tab == 'train-by-chats') {
          dataLayerPush(GTMEvent.TrainingChatSubmissionFailed, { count: draftTrainings.length })
        }
        if (tab == 'train-by-private-files') {
          dataLayerPush(GTMEvent.TrainingFileSubmissionFailed, { count: draftTrainings.length })
        }
      }
      setLoading(false);
    }
    return (
      <div
        className={clsx(
          "w-[300px] fold-w-256 train-link-mobile-height md:!min-w-[340px] md:w-[340px] md:!max-w-[340px] fixed  md:static duration-300 transition-all flex z-50 bg-[#171825]",
          open
            ? "shadow-default left-0"
            : "md:translate-x-0 -translate-x-full -left-10"
        )}
      >
        <button
          className={clsx(
            "absolute right-0 top-3.5 translate-x-full md:hidden"
          )}
          onClick={() => setOpen(!open)}
        >
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g clipPath="url(#clip0_497_28433)">
              <path
                d="M0 0H20C22.2091 0 24 1.79086 24 4V20C24 22.2091 22.2091 24 20 24H0V0Z"
                fill="#2E2F45"
              />
              <path
                d="M12.0002 10.586L16.9502 5.63599L18.3642 7.04999L13.4142 12L18.3642 16.95L16.9502 18.364L12.0002 13.414L7.05023 18.364L5.63623 16.95L10.5862 12L5.63623 7.04999L7.05023 5.63599L12.0002 10.586Z"
                fill="white"
                fillOpacity="0.8"
              />
            </g>
            <defs>
              <clipPath id="clip0_497_28433">
                <path
                  d="M0 0H20C22.2091 0 24 1.79086 24 4V20C24 22.2091 22.2091 24 20 24H0V0Z"
                  fill="white"
                />
              </clipPath>
            </defs>
          </svg>
        </button>
        <div
          className={
            "flex flex-col flex-grow pt-[16px] mb-0 justify-between tab-container md:border-r border-main "
          }
        >
          <div>
            <div className={"flex items-center justify-between mb-6 px-[16px]"}>
              <span
                className={"text-[16px] leading-[24px] primary-text font-[500]"}
              >
                {formatMessage({
                  id: heading,
                })}
              </span>
              <button
                className={"bg-[#C2D24B1A] h-[32px] w-[32px] flex items-center justify-center rounded-[6px]"}
                onClick={() => onPlusClick(true)}
              >
                <svg
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g clipPath="url(#clip0_497_28439)">
                    <path
                      d="M9.99984 4.16669V15.8334M4.1665 10H15.8332"
                      stroke="#C2D24B"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_497_28439">
                      <rect width="20" height="20" fill="white" />
                    </clipPath>
                  </defs>
                </svg>
              </button>
            </div>
            <div
              ref={ref}
              className={
                "overflow-y-auto custom-scroll h-[calc(100vh-320px)] md:h-[calc(100vh-322px)] lg:h-[calc(100vh-350px)] "
              }
            >
              {children}
            </div>
          </div>
          {!!draftTrainings.length && (
            <div className={"flex py-4"}>
              <button
                className={"main-button rounded py-3 mx-3.5 w-full"}
                onClick={loading ? () => { } : handleClick}
              >
                {loading ? (
                  <Spinner animation="border" />
                ) : (
                  <>
                    {formatMessage({
                      id: `Send Chats for Training `
                    })}
                    ({draftTrainings.length} {formatMessage({id: `new`})})
                  </>
                )}
              </button>
            </div>
          )}
        </div>
      </div>
    );
  }
);

export default Index;
