import React, { useEffect, useState } from "react";
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
import { useIntl } from "react-intl";

import { useAuth } from "../../auth";
import { useSearchParams } from "react-router-dom";
import InteractionBreadCrumb from "../components/breadcrumb/interaction-breadcrumb";
import InteractionList from "./interaction-list";

const tabsToIndex: { [key: string]: number } = {
  "interactions": 0,
};
const indexToTabs: { [key: number]: string } = {
  0: "interactions",
};
const InteractionContainer = () => {
  const { formatMessage } = useIntl();
  const [searchParams, setSearchParams] = useSearchParams();
  const [activeTab, setActiveTab] = useState<number>(
    tabsToIndex[searchParams.get("tab") || "interactions"]
  );
  useEffect(() => {
    searchParams.set("tab", "" + indexToTabs[activeTab]);
    setSearchParams(searchParams);
  }, [activeTab]);

  useEffect(() => {
    let tab = searchParams.get("tab");
    if (tab) {
      setActiveTab(tabsToIndex[tab]);
    }
  }, [searchParams]);
  return (
    <div className="md:px-5 px-3 py-3">
      <InteractionBreadCrumb />
      <Tabs
        defaultIndex={activeTab}
        selectedIndex={activeTab}
        className="font-size-13"
        selectedTabClassName="bg-primary"
        onSelect={(index) => {
          setActiveTab(index);
        }}
      >
        <TabList
          className={
            " text-[13px] leading-[20px] font-normal primary-text setting-tabs overflow-x-auto overflow-y-hidden whitespace-nowrap  border-main border-b"
          }
        >
          <Tab>{formatMessage({ id: "Interactions" })}</Tab>
        </TabList>
        <TabPanel>
          <InteractionList />
        </TabPanel>
      </Tabs>
    </div>
  );
};

export default InteractionContainer;
