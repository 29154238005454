import React from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import { getCdnUrl } from "../../../../core/_util";
import { kmbConvertor } from "../../../../utils";

interface LinkWithTooltipProps {
  id: string;
  children: React.ReactNode;
  tooltip: string;
  className: string;
}

const LinkWithTooltip: React.FC<LinkWithTooltipProps> = ({
  id,
  children,
  tooltip,
  className,
}) => {
  return (
    <OverlayTrigger
      overlay={<Tooltip id={id}>{tooltip}</Tooltip>}
      placement="top"
      delay={{ show: 200, hide: 150 }}
    >
      <div className={className}>{children}</div>
    </OverlayTrigger>
  );
};

const Index: React.FC<any> = ({
  id,
  slug,
  image,
  likes,
  followers,
  name,
  isVerified,
  occupation,
  owners,
  description,
  interactions
}) => {
  const navigate = useNavigate();

  const truncateLine2 = {
    webkitLineClamp: "2",
    display: "-webkit-box",
    webkitBoxOrient: "vertical",
    overflow: "hidden",
  };

  const personalityImage = getCdnUrl(image, "width=300,fit=cover,gravity=auto");
  const ownerImage = getCdnUrl(
    owners?.[0]?.photo,
    "width=40,fit=cover,gravity=auto"
  );

  return (
    <div className="position-relative">
      <Link to={`/p/${slug}`}
            onClick={(e) => {
              window.scrollTo(0, 0);
            }}

      >
      <div
        className={
          "border  border-main rounded-md shadow-default p-[8px_8px_12px_8px] transition-all duration-300 ease-in-out marketplace-card h-full cursor-pointer "
        }
        onClick={(e) => {
          navigate(`/p/${slug}`);
          window.scrollTo(0, 0);
        }}
      >
        <div className="position-relative mb-[8px]">
          <img
            src={personalityImage}
            alt=""
            className={"w-full rounded-t-md  object-cover aspect-square "}
          />
          <div
            className="bottom-0 left-0 w-full position-absolute p-[17px_4px_4px] text-[12px] leading-[18px] text-[#FFFFFFA6] d-inline-block text-truncate "
            style={{
              background:
                "linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #000000 100%)",
            }}
          >
            {occupation}
          </div>
        </div>

        <h3
          className={
            "text-[14px] leading-[20px] primary-text font-semibold mb-[4px] flex items-center gap-x-[4px]"
          }
        >
          <span className="text-truncate">{name}</span>
          {isVerified ? (
            <LinkWithTooltip
              tooltip="Default tooltip"
              id="tooltip-1"
              className=""
            >
              <svg
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M10 0L12.3794 2.67687L15.8779 1.90983L16.2294 5.47405L19.5106 6.90983L17.7 10L19.5106 13.0902L16.2294 14.5259L15.8779 18.0902L12.3794 17.3231L10 20L7.62057 17.3231L4.12215 18.0902L3.77057 14.5259L0.489435 13.0902L2.3 10L0.489435 6.90983L3.77057 5.47405L4.12215 1.90983L7.62057 2.67687L10 0Z"
                  fill="#4776E6"
                />
                <path
                  d="M6.92285 9.9991L8.97855 12.3068L14.2305 7.69141"
                  stroke="white"
                  strokeWidth="2"
                  strokeLinecap="round"
                />
              </svg>
            </LinkWithTooltip>
          ) : (
            ""
          )}
        </h3>
        <p
          className={" mb-[8px] secondary-text text-[12px] leading-[18px]"}
          style={truncateLine2}
        >
          {description && <>{description}</>}
        </p>
        <div
          className={"flex flex-row align-items-center justify-content-between md:justify-content-start"}
        >
          <div className="flex flex-row flex-grow align-items-center justify-content-between">
            <LinkWithTooltip
              tooltip={`${name} has ${followers ?? 0} followers`}
              id="tooltip-1"
              className=""
            >
              <div className={"flex  items-center gap-x-1"}>
                <svg
                  width="14"
                  height="14"
                  viewBox="0 0 14 14"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g clipPath="url(#clip0_344_30822)">
                    <path
                      d="M1.75 12.25V11.0833C1.75 10.4645 1.99583 9.871 2.43342 9.43342C2.871 8.99583 3.46449 8.75 4.08333 8.75H6.41667C7.0355 8.75 7.629 8.99583 8.06658 9.43342C8.50417 9.871 8.75 10.4645 8.75 11.0833V12.25M9.33333 1.82583C9.83524 1.95434 10.2801 2.24624 10.5978 2.65551C10.9155 3.06479 11.0879 3.56815 11.0879 4.08625C11.0879 4.60435 10.9155 5.10771 10.5978 5.51699C10.2801 5.92626 9.83524 6.21816 9.33333 6.34667M12.25 12.25V11.0833C12.247 10.5683 12.0738 10.0688 11.7572 9.66257C11.4407 9.25634 10.9986 8.96624 10.5 8.8375M2.91667 4.08333C2.91667 4.70217 3.1625 5.29566 3.60008 5.73325C4.03767 6.17083 4.63116 6.41667 5.25 6.41667C5.86884 6.41667 6.46233 6.17083 6.89992 5.73325C7.3375 5.29566 7.58333 4.70217 7.58333 4.08333C7.58333 3.46449 7.3375 2.871 6.89992 2.43342C6.46233 1.99583 5.86884 1.75 5.25 1.75C4.63116 1.75 4.03767 1.99583 3.60008 2.43342C3.1625 2.871 2.91667 3.46449 2.91667 4.08333Z"
                   className={'icon-stroke-primary'}
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_344_30822">
                      <rect width="14" height="14" className={'icon-main-color'} />
                    </clipPath>
                  </defs>
                </svg>
                <span className="text-[12px] leading-[18px] primary-text">
                  {followers ?? 0}
                </span>
              </div>
            </LinkWithTooltip>
            <LinkWithTooltip
              tooltip={`${name} has ${likes ?? 0} likes`}
              id="tooltip-1"
              className=""
            >
              <div className={"flex  items-center gap-x-1"}>
                <svg
                  width="14"
                  height="14"
                  viewBox="0 0 14 14"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g clipPath="url(#clip0_344_30828)">
                    <path
                      d="M11.3748 7.33316L6.99977 11.6662L2.62477 7.33316C2.3362 7.05235 2.10889 6.71483 1.95717 6.34186C1.80545 5.96889 1.7326 5.56855 1.7432 5.16604C1.7538 4.76353 1.84763 4.36757 2.01878 4.00311C2.18992 3.63864 2.43468 3.31356 2.73764 3.04833C3.04059 2.7831 3.39518 2.58348 3.77907 2.46202C4.16297 2.34057 4.56785 2.29992 4.96823 2.34263C5.36861 2.38534 5.75581 2.51048 6.10544 2.71019C6.45508 2.90989 6.75958 3.17983 6.99977 3.50299C7.241 3.18217 7.54585 2.9146 7.89524 2.71701C8.24464 2.51943 8.63106 2.39609 9.03031 2.35471C9.42957 2.31334 9.83306 2.35482 10.2156 2.47655C10.598 2.59829 10.9513 2.79767 11.2532 3.0622C11.5551 3.32674 11.7991 3.65074 11.97 4.01393C12.1409 4.37712 12.235 4.77167 12.2464 5.17291C12.2579 5.57414 12.1863 5.97341 12.0363 6.34572C11.8864 6.71804 11.6611 7.05539 11.3748 7.33666"
                      className={'icon-stroke-primary'}
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_344_30828">
                      <rect width="14" height="14" className={'icon-main-color'} />
                    </clipPath>
                  </defs>
                </svg>
                <span className="text-[12px] leading-[18px] primary-text">
                  {likes ?? 0}
                </span>
              </div>
            </LinkWithTooltip>
            <LinkWithTooltip
              tooltip={`${name}'s AI Personality has got ${interactions ?? 0} interactions`}
              id="tooltip-1"
              className=""
            >
              <div className={"flex  items-center gap-x-1"}>
                <svg
                  width="14"
                  height="14"
                  viewBox="0 0 14 14"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g clipPath="url(#clip0_6027_15594)">
                    <path
                      d="M8.16667 8.75V9.91667C8.16667 10.0714 8.10521 10.2197 7.99581 10.3291C7.88642 10.4385 7.73804 10.5 7.58333 10.5H3.5L1.75 12.25V6.41667C1.75 6.26196 1.81146 6.11358 1.92085 6.00419C2.03025 5.89479 2.17862 5.83333 2.33333 5.83333H3.5M12.25 8.16667L10.5 6.41667H6.41667C6.26196 6.41667 6.11358 6.35521 6.00419 6.24581C5.89479 6.13642 5.83333 5.98804 5.83333 5.83333V2.33333C5.83333 2.17862 5.89479 2.03025 6.00419 1.92085C6.11358 1.81146 6.26196 1.75 6.41667 1.75H11.6667C11.8214 1.75 11.9697 1.81146 12.0791 1.92085C12.1885 2.03025 12.25 2.17862 12.25 2.33333V8.16667Z"
                      stroke="white"
                      strokeOpacity="0.8"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_6027_15594">
                      <rect width="14" height="14" fill="white" />
                    </clipPath>
                  </defs>
                </svg>

                <span className="text-[12px] leading-[18px] text-[##FFFFFFCC]">
                  {kmbConvertor(interactions)}
                </span>
              </div>
            </LinkWithTooltip>
          </div>

          <div className="h-[24px] w-[24px] md:h-[28px] md:w-[28px] md:ml-[12px] flex-grow md:flex-grow-0"></div>
        </div>
      </div>
      </Link>
      <Link
        onClick={(e) => window.scrollTo(0, 0)}
        to={`/u/${owners?.[0]?.username}`}
        className="z-10 position-absolute h-[24px] w-[24px] md:h-[28px] md:w-[28px] d-block right-[8px] bottom-[12px]"
      >
        <LinkWithTooltip
          tooltip={`${owners?.[0]?.firstName} ${owners?.[0]?.lastName}`}
          id="tooltip-1"
          className=""
        >
          <img
            src={ownerImage}
            alt=""
            className="object-cover w-full h-full overflow-hidden aspect-square rounded-circle"
          ></img>
        </LinkWithTooltip>
      </Link>
    </div>
  );
};

export default Index;
