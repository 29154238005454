import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import { ToolTipUI } from "../../../../widgets/components/UI/ToolTipUI";
import { useIntl } from "react-intl";
import { Link } from "react-router-dom";
import { useAuth } from "../../../../auth";
import userDefault from "../.././../../../assets/images/profile/user-default.png";
import { getCdnUrl } from "../../../../../core/_util";
import {
  API_SUBSCRIPTION_CHARGE,
  BALANCE_REQUIRED_FOR_MINS,
} from "../../../../../core/_constants";
import AddCreditsModal from "../../../../chat-credits/components/modal/AddCreditsModal";
import { CustomButton } from "../../../../widgets/components/UI/CustomButton";
import { Formik } from "formik";
import { createApiSubscription } from "../../../../apis/core/_requests";
import { toast } from "react-toastify";
const SubscribeApiModal: React.FC<any> = ({
  open,
  setOpen,
  selection,
  submitForm,
}) => {
  const { formatMessage } = useIntl();
  const { currentUser, credits, setCredits } = useAuth();
  const [checked, setChecked] = useState<boolean>(false);
  let userImage: string = currentUser?.photo
    ? getCdnUrl(currentUser.photo)
    : userDefault;
  let personalityImage: string = selection?.image
    ? getCdnUrl(selection.image)
    : userDefault;
  const insufficient = credits < API_SUBSCRIPTION_CHARGE;

  return (
    <Modal
      size="lg"
      show={open}
      onHide={() => setOpen(false)}
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <div className={"flex items-center justify-between w-full"}>
          <div className="d-flex fw-bold">
            <h2 className="font-size-20">
              {formatMessage({
                id: "Subscribe to",
              })}{" "}
              {selection?.label}
              {"'s "}
              {formatMessage({
                id: "AI Persona’s API",
              })}
            </h2>{" "}
            <ToolTipUI
              tooltipText={formatMessage({
                id: "GLOBAL.TOOLTIP.BILLING.UPGRADE_PLAN",
              })}
            />
          </div>
          <button onClick={() => setOpen(false)}>
            <svg
              width="32"
              height="32"
              viewBox="0 0 32 32"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M21.6568 8.45747L8.45746 21.6568C7.93676 22.1775 7.93676 23.0217 8.45746 23.5424C8.97816 24.0631 9.82238 24.0631 10.3431 23.5424L23.5424 10.3431C24.0631 9.82239 24.0631 8.97817 23.5424 8.45747C23.0217 7.93677 22.1775 7.93677 21.6568 8.45747Z"
                fill="white"
              />
              <path
                opacity="0.5"
                d="M23.5425 21.6578L10.3432 8.45843C9.8225 7.93773 8.97828 7.93773 8.45758 8.45843C7.93688 8.97913 7.93688 9.82335 8.45758 10.3441L21.6569 23.5434C22.1776 24.0641 23.0218 24.0641 23.5425 23.5434C24.0632 23.0227 24.0632 22.1785 23.5425 21.6578Z"
                fill="white"
              />
            </svg>
          </button>
        </div>
      </Modal.Header>
      <Modal.Body className={"flex justify-center flex-col"}>
        <div className={"text-center"}>
          <h2
            className={
              "text-[14px] md:text-[24px] leading-5 md:leading-[32px] text-[#FFFFFFCC] font-semibold mb-[4px]"
            }
          >
            {formatMessage({
              id: "It’s Match!",
            })}
          </h2>
          <div className={"flex justify-center"}>
            <div className={"relative h-[70px] md:h-[110px] w-72 mb-[12px]"}>
              <div
                className={
                  "absolute top-0 right-32 bg-[#2E2F45] shadow-default rounded-full p-[4px] md:p-1.5"
                }
              >
                <img
                  src={personalityImage}
                  className={
                    "rounded-full h-[60px] md:h-[108px] h-[60px] md:w-[108px]"
                  }
                />
              </div>
              <div
                className={
                  "absolute top-0  left-32 bg-[#2E2F45] shadow-default rounded-full p-[4px] md:p-1.5"
                }
              >
                <img
                  src={userImage}
                  className={
                    "rounded-full h-[60px] md:h-[108px] h-[60px] md:w-[108px]"
                  }
                />
              </div>
              <div
                className={
                  "absolute -bottom-2 left-1/2 -translate-x-1/2 bg-[#2E2F45] shadow-default p-1.5 rounded-full"
                }
              >
                <svg
                  className={"h-[16px] md:h-[28px] w-[16px] md:w-[28px]"}
                  viewBox="0 0 28 28"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g clipPath="url(#clip0_344_19686)">
                    <path
                      d="M9.33333 10.4993H18.6667M9.33333 15.166H16.3333M21 4.66602C21.9283 4.66602 22.8185 5.03476 23.4749 5.69114C24.1313 6.34752 24.5 7.23776 24.5 8.16602V17.4993C24.5 18.4276 24.1313 19.3178 23.4749 19.9742C22.8185 20.6306 21.9283 20.9994 21 20.9994H15.1667L9.33333 24.4994V20.9994H7C6.07174 20.9994 5.1815 20.6306 4.52513 19.9742C3.86875 19.3178 3.5 18.4276 3.5 17.4993V8.16602C3.5 7.23776 3.86875 6.34752 4.52513 5.69114C5.1815 5.03476 6.07174 4.66602 7 4.66602H21Z"
                      stroke="#C2D24B"
                      strokeWidth="1.8"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_344_19686">
                      <rect width="28" height="28" fill="white" />
                    </clipPath>
                  </defs>
                </svg>
              </div>
            </div>
          </div>
          <div className={"flex justify-center mb-[18px]"}>
            <div
              className={
                "flex items-center gap-x-2 bg-[#2E2F45] rounded px-2 py-1"
              }
            >
              <div className={"p-1.5 bg-[#171825] rounded-full"}>
                <svg
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g clipPath="url(#clip0_344_19662)">
                    <path
                      d="M3.37436 4.83563L6.54519 7.62563L9.37019 4.1473C9.4493 4.05006 9.54932 3.97191 9.66281 3.91869C9.77631 3.86547 9.90035 3.83853 10.0257 3.83989C10.151 3.84125 10.2745 3.87088 10.3868 3.92655C10.4991 3.98223 10.5974 4.06253 10.6744 4.16146L13.3702 7.6248L16.6435 4.7948C16.7721 4.68389 16.9314 4.61489 17.1003 4.59703C17.2691 4.57918 17.4394 4.61331 17.5883 4.69488C17.7372 4.77644 17.8576 4.90154 17.9335 5.05343C18.0093 5.20533 18.0369 5.37676 18.0127 5.5448L16.7627 14.1673H3.26853L1.99936 5.58396C1.97415 5.41499 2.00148 5.24236 2.07765 5.08944C2.15382 4.93652 2.27515 4.8107 2.42521 4.72904C2.57527 4.64737 2.7468 4.6138 2.91657 4.63286C3.08634 4.65193 3.24615 4.72271 3.37436 4.83563ZM3.33353 15.0006H16.6669V15.834C16.6669 16.055 16.5791 16.2669 16.4228 16.4232C16.2665 16.5795 16.0545 16.6673 15.8335 16.6673H4.16686C3.94585 16.6673 3.73388 16.5795 3.5776 16.4232C3.42132 16.2669 3.33353 16.055 3.33353 15.834V15.0006Z"
                      fill="#FFCD4E"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_344_19662">
                      <rect width="20" height="20" fill="white" />
                    </clipPath>
                  </defs>
                </svg>
              </div>
              <p className={"text-[12px] text-[#FFFFFFCC]  leading-[18px]"}>
                <span className={"text-[16px] leading-6 font-semibold mr-1"}>
                  ${selection?.charge}
                </span>
                {formatMessage({
                  id: "per 1,000 tokens",
                })}
              </p>
              <ToolTipUI tooltipText={"Price"} />
            </div>
          </div>
          <h2
            className={
              "text-[14px] md:text-[24px] leading-5 md:leading-[32px] text-[#FFFFFFCC] font-semibold mb-[8px]"
            }
          >
            {formatMessage({
              id: "You are subscribing to",
            })}{" "}
            {selection?.label}
            {"'s "}
            {formatMessage({
              id: "premium chat APIs",
            })}
          </h2>
          <div className={"flex justify-center"}>
            <p className={"max-w-xl mb-[20px]"}>
              {formatMessage({
                id: "You will be charged on token usage basis while using the APIs.",
              })}
              <br></br>
              <b>
                ${API_SUBSCRIPTION_CHARGE}
                {formatMessage({
                  id: "will be charged ",
                })}
              </b> 
              {formatMessage({
                id: "just now as an API request charges.",
              })}{" "}
              <a
                href={
                  "https://help.kamoto.ai/en/articles/8304683-learn-how-you-will-be-charged-for-using-premium-chat-apis"
                }
                className={"text-[#C2D24B]"}
              >
                {formatMessage({
                  id: "Learn More.",
                })}
              </a>
            </p>
          </div>
        </div>
        <div className={"bg-[#2E2F45] p-[14px] shadow-default rounded"}>
          <div
            className={
              "flex flex-col md:flex-row gap-y-2 justify-between items-center"
            }
          >
            <div className={"flex flex-col items-center md:items-start"}>
              <label
                className={
                  "text-[13px] text-[#FFFFFFA6] flex items-center gap-x-1  mb-1"
                }
              >
                {formatMessage({
                  id: "Available Chat Credits",
                })}
                <ToolTipUI
                  tooltipText={formatMessage({
                    id: "GLOBAL.TOOLTIP.MARKETPLACE.AI_PROFILE.SUBSCRIBE_API_MODAL.AVAILABLE_CHAT_CREDIT",
                  })}
                />
              </label>
              <h3
                className={
                  "text-[24px] leading-[32px] text-[#FFFFFFCC] font-semibold flex flex-col md:flex-row md:gap-x-4 items-center text-center"
                }
              >
                ${credits}
                {insufficient && (
                  <span
                    className={
                      "text-[13px] leading-5 font-normal text-[#D24B4B]"
                    }
                  >
                    {formatMessage({
                      id: "You don’t have enough credits to subscribe!",
                    })}
                  </span>
                )}
              </h3>
            </div>
            <AddCreditsModal />
          </div>
        </div>
        {!insufficient && (
          <div
            className={
              "mt-[32px] flex items-start md:items-center gap-x-3 event-filter"
            }
          >
            <div>
              <input
                type={"checkbox"}
                checked={checked}
                onChange={(e) => setChecked(e.target.checked)}
                className={"form-check-input bg-[#fff]"}
              />
            </div>
            <p className={"text-[#FFFFFFCC] text-[13px]"}>
              {formatMessage({
                id: "I accept pricing, Kamoto AI’s",
              })}{" "}
              <a
                target={"_blank"}
                rel="noreferrer"
                href={process.env.REACT_APP_TERMS_POLICY_LINK}
                className={"text-[#C2D24B] cursor-pointer"}
              >
                {formatMessage({
                  id: "terms of use",
                })}
              </a>{" "}
              {formatMessage({
                id: "&",
              })}
              <a
                target={"_blank"}
                rel="noreferrer"
                href={process.env.REACT_APP_PRIVACY_POLICY_LINK}
                className={"text-[#C2D24B] cursor-pointer"}
              >
                {" "}
                {formatMessage({
                  id: "privacy policy.",
                })}
              </a>{" "}
              {selection?.personalityTnc && (
                <>
                  {formatMessage({
                    id: "I also agree with",
                  })}{" "}
                  {selection?.label?.split(" ")[0]}
                  {"'s "}
                  {formatMessage({
                    id: "chatbot",
                  })}{" "}
                  <a
                    target={"_blank"}
                    rel="noreferrer"
                    href={selection.personalityTnc}
                    className={"text-[#C2D24B] cursor-pointer"}
                  >
                    {formatMessage({
                      id: "terms of use",
                    })}
                  </a>
                  .
                </>
              )}
            </p>
          </div>
        )}
      </Modal.Body>
      <Modal.Footer>
        <div className={"flex flex-grow md:justify-end gap-x-4"}>
          <button
            className={
              "bg-[#C2D24B1A] py-[12px] px-[24px] rounded w-full md:w-auto text-[#C2D24B]"
            }
            onClick={() => setOpen(false)}
          >
            {formatMessage({
              id: "Never Mind",
            })}
          </button>
          {!insufficient && (
            <Formik
              initialValues={{ x: 1 }}
              onSubmit={async ({}, { setSubmitting }) => {
                setSubmitting(true);
                const {
                  data: { success, errors },
                } = await createApiSubscription(selection.value);
                if (success) {
                  toast.success("Successfully subscribed", { autoClose: 1500 });
                  toast.info("Please wait generating API Key", { delay: 1800 });
                  await submitForm(false);
                  setCredits((credits: number) =>
                    parseFloat((credits - API_SUBSCRIPTION_CHARGE).toFixed(2))
                  );
                  setOpen(false);
                } else {
                  toast.error(errors[0] || "Error while subscribing",
                  {
                    autoClose: false,
                    closeButton: true, // Display close button (cross icon)
                  });
                }
                setSubmitting(false);
              }}
            >
              {(formik) => (
                <CustomButton
                  buttonText={formatMessage({
                    id: "I’m in!",
                  })}
                  loading={formik.isSubmitting}
                  // className={
                  //   "bg-[#C2D24B] font-weight-500 py-[12px] px-[24px] rounded w-full md:w-auto text-black"
                  // }
                  isValid={checked}
                  onSubmit={formik.submitForm}
                />
              )}
            </Formik>
          )}
        </div>
      </Modal.Footer>
    </Modal>
  );
};

export default SubscribeApiModal;
