import React, { useState } from "react";
import Categories from "../../components/events/categories";
import Card from "../../components/events/card";
import { cards } from "../../components/events/card/cards";
import { get, map } from "lodash";
import ReactPaginate from "react-paginate";
import Toolbar from "../../components/events-noregister/toolbar";
import Drawer from "react-modern-drawer";
import { ToolTipUI } from "../../../widgets/components/UI/ToolTipUI";
import FilterForm from "../../components/events-noregister/filter-form";
import Banner from "../../components/events-noregister/banner";

const Index = () => {
  const [openFilter, setOpenFilter] = useState(false);
  return (
    <div>
      <Banner />
      <div className={"px-[20px] md:px-20"}>
        <Categories />
        <div
          className={
            "border-b border-[#2E2F45] pb-[14px] md:pb-[16px] mb-[24px]"
          }
        >
          <Toolbar setOpenFilter={setOpenFilter} />
        </div>
        <div
          className={
            "grid grid-cols-12 gap-x-[8px] md:gap-x-[18px]  gap-y-5 mb-9"
          }
        >
          {map(cards, (el) => (
            <div
              className={"col-span-6 md:col-span-4 lg:col-span-3 xl:col-span-2"}
              key={get(el, "id")}
            >
              <Card
                image={get(el, "image")}
                name={get(el, "name")}
                occupation={get(el, "occupation")}
                isVerified={get(el, "isVerified")}
                followers={get(el, "follower")}
                likes={get(el, "likes")}
              />
            </div>
          ))}
        </div>
        <div className={"flex justify-between items-center"}>
          <div className={"flex items-center gap-x-2"}>
            <div className={"w-[75px]"}>
              <select
                className={
                  "form-control form-select form-control-sm py-0 leading-0 !min-h-[32px] !h-[32px]"
                }
              >
                <option value={10}>10</option>
                <option value={12}>12</option>
                <option value={14}>14</option>
                <option value={16}>16</option>
                <option value={18}>17</option>
                <option value={20}>20</option>
              </select>
            </div>
            <p
              className={
                "secondary-text text-[12px] leading-[18px] hidden md:inline-block"
              }
            >
              Showing 1 to 18 of 570 records
            </p>
          </div>
          <div>
            <ReactPaginate
              pageCount={4}
              containerClassName={"flex gap-x-2"}
              pageClassName={
                "w-8 h-8 bg-[#21233A] text-[14px] leading-[18px] text-[#FFFFFF99] flex items-center justify-center rounded"
              }
              nextClassName={
                "w-8 h-8 bg-[#21233A] flex items-center justify-center rounded"
              }
              previousClassName={
                "w-8 h-8 bg-[#21233A] flex items-center justify-center rounded"
              }
              activeClassName={"bg-[#C2D24B] text-black"}
              nextLabel={
                <svg
                  width="5"
                  height="7"
                  viewBox="0 0 5 7"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M0.218094 0.265H1.71309L4.85909 3.242L1.71309 6.232H0.218094L3.36409 3.242L0.218094 0.265Z"
                    fill="white"
                    fillOpacity="0.6"
                  />
                </svg>
              }
              previousLabel={
                <svg
                  width="6"
                  height="7"
                  viewBox="0 0 6 7"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M4.11953 6.232L0.986531 3.242L4.11953 0.265H5.61453L2.46853 3.242L5.61453 6.232H4.11953Z"
                    fill="white"
                    fillOpacity="0.6"
                  />
                </svg>
              }
            />
          </div>
        </div>
        <Drawer
          open={openFilter}
          overlayOpacity={0.5}
          onClose={() => setOpenFilter(false)}
          direction={"right"}
          lockBackgroundScroll={true}
          className={
            "!bg-[#171825] !shadow-default min-w-[320px] md:min-w-[480px] flex flex-col"
          }
        >
          <div
            className={
              "flex justify-between items-center border-b !border-[#2E2F45] pb-[8px] pt-[14px] md:py-[18px] px-[14px] md:px-[32px]"
            }
          >
            <div className={"flex gap-x-2.5 items-center"}>
              <button onClick={() => setOpenFilter(false)}>
                <svg
                  width="32"
                  height="32"
                  viewBox="0 0 32 32"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M21.6568 8.45747L8.45746 21.6568C7.93676 22.1775 7.93676 23.0217 8.45746 23.5424C8.97816 24.0631 9.82238 24.0631 10.3431 23.5424L23.5424 10.3431C24.0631 9.82239 24.0631 8.97817 23.5424 8.45747C23.0217 7.93677 22.1775 7.93677 21.6568 8.45747Z"
                    fill="white"
                  />
                  <path
                    opacity="0.5"
                    d="M23.5425 21.6568L10.3432 8.45746C9.8225 7.93676 8.97828 7.93676 8.45758 8.45746C7.93688 8.97816 7.93688 9.82238 8.45758 10.3431L21.6569 23.5424C22.1776 24.0631 23.0218 24.0631 23.5425 23.5424C24.0632 23.0217 24.0632 22.1775 23.5425 21.6568Z"
                    fill="white"
                  />
                </svg>
              </button>
              <h4
                className={
                  "flex items-center gap-x-2 text-[16px] md:text-[20px] leading-5 md:leading-8 text-[#FFFFFFCC] font-semibold"
                }
              >
                Filters
                <ToolTipUI tooltipText={"Filters"} />
              </h4>
            </div>
            <button className={"export-button py-[8px] px-[16px] rounded"}>
              <span className={"text-[13px] leading-5"}>
                Reset Filters
              </span>
            </button>
          </div>
          <FilterForm setOpenFilter={setOpenFilter} />
        </Drawer>
      </div>
    </div>
  );
};

export default Index;
