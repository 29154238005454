/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect } from "react";
import { FC, useState } from "react";
import clsx from "clsx";
import {
  toAbsoluteUrl,
  defaultMessages,
  defaultUserInfos,
  MessageModel,
  UserInfoModel,
  messageFromClient,
} from "../../../../../../_metronic/helpers";
import { isEqual } from "lodash";
import { useAuth } from "../../../../auth";
import MainChat from "../../../../ai-personality/components/chat";
import { useSearchParams } from "react-router-dom";
import chatAvatarDemo from "../../../../../assets/images/profile/chat-avatar-demo.png";
import ChatAvatar, {
  SpeechServiceProvider,
} from "../../../../ai-personality/components/chat/chat-avatar";
import HyperRealisticAvatar from "./hyper-realistic";
import {useWindowSize} from "../../../../../hooks/useWindowSize";
type Props = {
  isDrawer?: boolean;
  isDark?: boolean;
  hasBackground: boolean;
  setOpenSubscribeModal: (value: boolean) => void;
  onSessionEnd: (success: boolean, data: any, errors: any) => void;
  createSessionCheck: () => Promise<boolean>;
};
const bufferMessages = defaultMessages;

const Index: FC<Props> = ({
  isDrawer = false,
  isDark=false,
  hasBackground,
  createSessionCheck,
  onSessionEnd,
}) => {
  const { personalityInView } = useAuth();
  const [message, setMessage] = useState<string>();
  const [searchParams, setSearchParams] = useSearchParams();
  const [is3dAvatar, setIs3dAvatar] = useState<boolean>(true);
  const has3d = personalityInView?.personalityJson?.avatar?.enabled || false
  const hasHyper = personalityInView?.personalityJson?.hyper_realistic_avatar?.enabled || false
  const handle3DAvatarToggle = () => {
    if (!is3dAvatar) {
      setIs3dAvatar(true);
    }
  };

  const onHyperRealisticFail = () => {
    setIs3dAvatar(true)
  }

  const handleHyperRealisticToggle = () => {
    if (is3dAvatar) {
      setIs3dAvatar(false);
    }
  };
  useEffect(() => {
    if (searchParams.has("prompt")) {
      setMessage(searchParams.get("prompt") || "");
      searchParams.delete("prompt");
      setSearchParams(searchParams, { replace: true });
    }
  }, [searchParams.has("prompt")]);


  const {innerHeight} = useWindowSize()

  console.log(innerHeight,'windowSize')

  const findHeight =(height:any)=>{
    if (height < 700){
      return 'h-[100%]'
    }else  if (height < 800){
      return 'h-[100%]'
    }else {
      return 'h-full'
    }
  }
  return (
    <>
      <div className={clsx(findHeight(innerHeight))}>
        {hasBackground ? (
          <div className="chat-avatar-box relative z-10 pt-[14px] h-[34vh]">
            {
              has3d && hasHyper ?
              (
                is3dAvatar ? (
                  <div
                    className="relative mb-[14px]"
                    style={{ height: "calc(100% - 40px)" }}
                  >
                    <ChatAvatar />
                    <div className="absolute top-0 left-0 w-full h-full z-10"></div>
                  </div>
                ) : (
                  <HyperRealisticAvatar/>
                )
              )
              :
              <>
               {
                has3d ? 
                <div
                    className="relative mb-[14px]"
                    style={{ height: "calc(100% - 40px)" }}
                  >
                    <ChatAvatar />
                    <div className="absolute top-0 left-0 w-full h-full z-10"></div>
                  </div>
                  :
                  (hasHyper ? <HyperRealisticAvatar/> :<></>)
               }
              </>
            }
            {
              has3d && hasHyper &&
              <div className="flex flex-row items-center justify-center gap-0 cursor-pointer">
                <div
                  className={`avatar-tog text-[10px] leading-[18px] rounded-l-[8px] p-[4px] w-[90px] text-center ${
                    is3dAvatar
                      ? "bg-[#C2D24B] text-[#000000]"
                      : "bg-[#2E2F45] text-[#FFFFFFCC]"
                  }`}
                  onClick={handle3DAvatarToggle}
                >
                  3D Avatar
                </div>
                <div
                  className={`avatar-tog text-[10px] leading-[18px] rounded-r-[8px] p-[4px] w-[90px] text-center ${
                    is3dAvatar
                      ? "bg-[#2E2F45] text-[#FFFFFFCC]"
                      : "bg-[#C2D24B] text-[#000000]"
                  }`}
                  onClick={handleHyperRealisticToggle}
                >
                  Hyper-realistic
                </div>
              </div>
            }
          </div>
        ) : null}

        <MainChat
          outerContainerCss={`h-full p-[12px_8px] md:px-[22px] ${
            hasBackground
              ? "!h-[40vh] bottom-0 position-absolute chat-gradient chat-gradient-small"
              : "chat-gradient"
          }`}
          chatContainerCss="h-full"
          createSessionCheck={createSessionCheck}
          closeSessionCallback={onSessionEnd}
          sessionPills={true}
          showSampleQuestions={true}
          currentPersonality={personalityInView}
          defaultPrompt={message}
        />
      </div>
    </>
  );
  /*
  return (
    <div className="relative p-6 overflow-y-auto rounded custom-scroll">
      <div className={""}>
        {messages.map((message, index) => {
          const userInfo = userInfos[message.user];
          const state = message.type === "in" ? "in" : "out";
          const templateAttr = {};
          if (message.template) {
            Object.defineProperty(templateAttr, "data-kt-element", {
              value: `template-${message.type}`,
            });
          }
          const contentClass = `${isDrawer ? "" : "d-flex"} justify-content-${
            message.type === "in" ? "start" : "end"
          } mb-10`;
          return (
            <div
              key={`message${index}`}
              className={clsx("d-flex ", contentClass, "mb-10", {
                "d-none": message.template,
              })}
              {...templateAttr}
            >
              <div
                className={
                  "flex flex-col md:flex-row md:items-start gap-x-8 gap-y-2"
                }
              >
                <div
                  className={clsx(
                    "flex items-start gap-x-2 rounded px-2 py-3 relative",
                    isEqual(state, "in") ? "bg-[#2E2F45] " : "bg-[#171825]"
                  )}
                >
                  {isEqual(state, "in") && (
                    <span
                      className={
                        "text-[20px] absolute right-1 md:-right-3 -bottom-4"
                      }
                    >
                      😅
                    </span>
                  )}
                  <div className="flex items-center">
                    {message.type === "in" ? (
                      <>
                        <div className="w-12 h-12 symbol symbol-35px symbol-circle">
                          <img
                            alt="Pic"
                            src={toAbsoluteUrl(`/media/${userInfo.avatar}`)}
                          />
                        </div>
                      </>
                    ) : (
                      <>
                        <div className="w-12 h-12 symbol symbol-35px symbol-circle">
                          <img
                            alt="Pic"
                            src={toAbsoluteUrl(`/media/${userInfo.avatar}`)}
                          />
                        </div>
                      </>
                    )}
                  </div>
                  <div
                    className={clsx(
                      "text-[#FFFFFFCC] text-[14px] leading-[22px] font-normal max-w-2xl float-right"
                      // `text-${message.type === 'in' ? 'start' : 'end'}`
                    )}
                    data-kt-element="message-text"
                    dangerouslySetInnerHTML={{ __html: message.text }}
                  ></div>
                </div>
                {message.type === "in" && (
                  <div className={"flex items-center gap-x-2"}>
                    <button>
                      <svg
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <g clipPath="url(#clip0_344_24321)">
                          <path
                            d="M6.66602 8.33341C6.66602 7.89139 6.84161 7.46746 7.15417 7.1549C7.46673 6.84234 7.89065 6.66675 8.33268 6.66675H14.9993C15.4414 6.66675 15.8653 6.84234 16.1779 7.1549C16.4904 7.46746 16.666 7.89139 16.666 8.33341V15.0001C16.666 15.4421 16.4904 15.866 16.1779 16.1786C15.8653 16.4912 15.4414 16.6667 14.9993 16.6667H8.33268C7.89065 16.6667 7.46673 16.4912 7.15417 16.1786C6.84161 15.866 6.66602 15.4421 6.66602 15.0001V8.33341Z"
                            stroke="white"
                            strokeOpacity="0.8"
                            strokeWidth="1.2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            d="M13.333 6.66671V5.00004C13.333 4.55801 13.1574 4.13409 12.8449 3.82153C12.5323 3.50897 12.1084 3.33337 11.6663 3.33337H4.99967C4.55765 3.33337 4.13372 3.50897 3.82116 3.82153C3.5086 4.13409 3.33301 4.55801 3.33301 5.00004V11.6667C3.33301 12.1087 3.5086 12.5327 3.82116 12.8452C4.13372 13.1578 4.55765 13.3334 4.99967 13.3334H6.66634"
                            stroke="white"
                            strokeOpacity="0.8"
                            strokeWidth="1.2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </g>
                        <defs>
                          <clipPath id="clip0_344_24321">
                            <rect width="20" height="20" fill="white" />
                          </clipPath>
                        </defs>
                      </svg>
                    </button>
                    <button>
                      <svg
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <g clipPath="url(#clip0_344_24326)">
                          <path
                            d="M5.83333 9.16671V15.8334C5.83333 16.0544 5.74554 16.2664 5.58926 16.4226C5.43298 16.5789 5.22101 16.6667 5 16.6667H3.33333C3.11232 16.6667 2.90036 16.5789 2.74408 16.4226C2.5878 16.2664 2.5 16.0544 2.5 15.8334V10C2.5 9.77903 2.5878 9.56707 2.74408 9.41079C2.90036 9.25451 3.11232 9.16671 3.33333 9.16671H5.83333ZM5.83333 9.16671C6.71739 9.16671 7.56524 8.81552 8.19036 8.1904C8.81548 7.56528 9.16667 6.71743 9.16667 5.83337V5.00004C9.16667 4.55801 9.34226 4.13409 9.65482 3.82153C9.96738 3.50897 10.3913 3.33337 10.8333 3.33337C11.2754 3.33337 11.6993 3.50897 12.0118 3.82153C12.3244 4.13409 12.5 4.55801 12.5 5.00004V9.16671H15C15.442 9.16671 15.866 9.3423 16.1785 9.65486C16.4911 9.96742 16.6667 10.3913 16.6667 10.8334L15.8333 15C15.7135 15.5113 15.4861 15.9502 15.1855 16.2508C14.8849 16.5514 14.5274 16.6974 14.1667 16.6667H8.33333C7.67029 16.6667 7.03441 16.4033 6.56557 15.9345C6.09673 15.4656 5.83333 14.8297 5.83333 14.1667"
                            stroke="white"
                            strokeOpacity="0.8"
                            strokeWidth="1.2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </g>
                        <defs>
                          <clipPath id="clip0_344_24326">
                            <rect width="20" height="20" fill="white" />
                          </clipPath>
                        </defs>
                      </svg>
                    </button>
                    <button>
                      <svg
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <g clipPath="url(#clip0_344_24329)">
                          <path
                            d="M5.83333 10.8333V4.16663C5.83333 3.94561 5.74554 3.73365 5.58926 3.57737C5.43298 3.42109 5.22101 3.33329 5 3.33329H3.33333C3.11232 3.33329 2.90036 3.42109 2.74408 3.57737C2.5878 3.73365 2.5 3.94561 2.5 4.16663V9.99996C2.5 10.221 2.5878 10.4329 2.74408 10.5892C2.90036 10.7455 3.11232 10.8333 3.33333 10.8333H5.83333ZM5.83333 10.8333C6.71739 10.8333 7.56524 11.1845 8.19036 11.8096C8.81548 12.4347 9.16667 13.2826 9.16667 14.1666V15C9.16667 15.442 9.34226 15.8659 9.65482 16.1785C9.96738 16.491 10.3913 16.6666 10.8333 16.6666C11.2754 16.6666 11.6993 16.491 12.0118 16.1785C12.3244 15.8659 12.5 15.442 12.5 15V10.8333H15C15.442 10.8333 15.866 10.6577 16.1785 10.3451C16.4911 10.0326 16.6667 9.60865 16.6667 9.16663L15.8333 4.99996C15.7135 4.48873 15.4861 4.04975 15.1855 3.74915C14.8849 3.44855 14.5274 3.30261 14.1667 3.33329H8.33333C7.67029 3.33329 7.03441 3.59668 6.56557 4.06552C6.09673 4.53437 5.83333 5.17025 5.83333 5.83329"
                            stroke="white"
                            strokeOpacity="0.8"
                            strokeWidth="1.2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </g>
                        <defs>
                          <clipPath id="clip0_344_24329">
                            <rect
                              width="20"
                              height="20"
                              fill="white"
                              transform="matrix(1 0 0 -1 0 20)"
                            />
                          </clipPath>
                        </defs>
                      </svg>
                    </button>
                  </div>
                )}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
  */
};

export default Index;
