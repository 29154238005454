/* eslint-disable jsx-a11y/anchor-is-valid */
import { useState } from "react";
import { useIntl } from "react-intl";
import { BillingModal } from "../../onboarding/views/BillingModal";
import { BasicButton } from "../../widgets/components/UI/BasicButton";
import { getBillingAccess } from "../../profile/core/_requests";
import { toast } from "react-toastify";
import { Modal } from "react-bootstrap";
import SearchInput from "../../profile/components/SearchInput";
import { useAuth } from "../../auth";
import { copyToClipboard } from "../../../utils";
import useGTM from "../../../hooks/useGTM";
import { GTMEvent } from "../../../hooks/gtm_helpers";
import ClaimCouponModal from "./ClaimCouponModal";

export function Billing({
  key,
  billingData,
  currencyBill,
  selected,
  currentState,
  companyId,
  getBillingDetails,
}: {
  key: number;
  billingData: any;
  currencyBill: string;
  selected: string;
  currentState: string;
  companyId: string | undefined;
  getBillingDetails: () => Promise<void>;
}) {
  const { formatMessage } = useIntl();
  const {dataLayerPush} = useGTM()
  const [modalShow, setModalShow] = useState(false);
  const [accessLoading, setAccessLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [showCouponModal, setShowCouponModal] = useState(false);
  const [urlData, setUrlData] = useState();
  const {selectedPersonality} = useAuth()
  const onSubmitAccess = async () => {
    try {
      setAccessLoading(true);
      if (companyId) {
        const {
          data: { data: value, success, errors },
        } = await getBillingAccess(companyId);
        if (success) {
          setAccessLoading(false);
          setUrlData(value);
          setShowModal(true);
          dataLayerPush(GTMEvent.PaymentPortalOpenedSuccessful)
        } else {
          setAccessLoading(false);
          errors.forEach((error: string) => {
            toast.error(formatMessage({ id: error }),
            {
              autoClose: false,
              closeButton: true, // Display close button (cross icon)
            });
          });
          dataLayerPush(GTMEvent.PaymentPortalOpenedSuccessful)
        }
      }
    } catch (err) {
      setAccessLoading(false);
      console.log(err);
      dataLayerPush(GTMEvent.PaymentPortalOpenedSuccessful)
    }
  };

  return (
    <>
      <SearchInput />
      <div className="relative">
        <BasicButton
          buttonText={formatMessage({ id: "Claim Subscription" })}
          border="2px solid #C2D24B"
          textColor="#C2D24B"
          customClass={"px-[24px] absolute right-[20px] top-[10px]"}
          height="44px"
          onClick={()=>setShowCouponModal(true)}
        />
      </div>
      <div
        className={
          "grid grid-cols-12 tab-container rounded p-[0px] md:p-[24px] md:pb-[50px] gap-y-[16px]"
        }
      >
        <div className={"col-span-12 md:col-span-6 primary-text"}>
          <h3
            className={
              "text-[16px] leading-[22px] font-medium mb-[8px] primary-text"
            }
          >
            {formatMessage({
              id: "Billing",
            })}
          </h3>
          <div
            className={
              "setting-table shadow-[0px_2px_15px_0px_#0000001A] p-[16px]  rounded-md"
            }
          >
            <div className={"flex py-[8px] border-b border-main"}>
              <div className={"flex w-1/2"}>
                {formatMessage({
                  id: "Current Plan",
                })}
              </div>
              <div className={"flex w-1/2"}>
                {billingData?.status
                  ? `${selected} (${billingData?.status?.replace("_", " ")})`
                  : "No Selected Plan"}
              </div>
            </div>
            <div className={"flex py-[8px] border-b  border-main"}>
              <div className={"flex w-1/2"}>
                {formatMessage({
                  id: "Amount",
                })}
              </div>
              <div className={"flex w-1/2"}>
                {billingData?.currency_code}
                {billingData?.currency_code === "INR" ? " ₹" : " $"}
                {billingData?.subscription_items?.[0]?.amount > 0
                  ? (
                      billingData?.subscription_items?.[0]?.amount / 100
                    )?.toFixed(2)
                  : billingData?.subscription_items?.[0]?.amount?.toFixed(2)}
              </div>
            </div>
            <div className={"flex py-[8px] border-b  border-main"}>
              <div className={"flex w-1/2"}>
                {formatMessage({
                  id: "Next Billing Amount",
                })}
              </div>
              <div className={"flex w-1/2"}>
                {billingData?.currency_code}
                {billingData?.currency_code === "INR" ? " ₹" : " $"}
                {billingData?.subscription_items?.[0]?.amount > 0
                  ? (
                      billingData?.subscription_items?.[0]?.amount / 100
                    )?.toFixed(2)
                  : billingData?.subscription_items?.[0]?.amount?.toFixed(2)}
              </div>
            </div>
            <div className={"flex py-[8px] border-b  border-main"}>
              <div className={"flex w-1/2"}>
                {formatMessage({
                  id: "Subscription ID",
                })}
              </div>
              <div className={"flex w-1/2"}>
                {selectedPersonality?.chargebeeSubscriptionId}
                <div
                      onClick={() =>
                        copyToClipboard(
                          selectedPersonality?.chargebeeSubscriptionId,
                          true,
                          "Subscription ID copied successfully"
                        )
                      }
                      className="ml-2"
                    >
                      <svg
                        className="md:mr-[4px]"
                        width="24"
                        height="22"
                        viewBox="0 0 24 22"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M7.71484 9C7.71484 8.46957 7.9369 7.96086 8.33215 7.58579C8.72741 7.21071 9.26349 7 9.82247 7H18.253C18.8119 7 19.348 7.21071 19.7433 7.58579C20.1385 7.96086 20.3606 8.46957 20.3606 9V17C20.3606 17.5304 20.1385 18.0391 19.7433 18.4142C19.348 18.7893 18.8119 19 18.253 19H9.82247C9.26349 19 8.72741 18.7893 8.33215 18.4142C7.9369 18.0391 7.71484 17.5304 7.71484 17V9Z"
                          stroke="#C2D24B"
                          strokeWidth="1.4"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M16.1457 7V5C16.1457 4.46957 15.9237 3.96086 15.5284 3.58579C15.1332 3.21071 14.5971 3 14.0381 3H5.60762C5.04865 3 4.51256 3.21071 4.11731 3.58579C3.72205 3.96086 3.5 4.46957 3.5 5V13C3.5 13.5304 3.72205 14.0391 4.11731 14.4142C4.51256 14.7893 5.04865 15 5.60762 15H7.71525"
                          stroke="#C2D24B"
                          strokeWidth="1.4"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                    </div>
              </div>
            </div>
            <div className={"flex py-[8px]"}>
              <div className={"flex w-1/2"}>
                {" "}
                {formatMessage({
                  id: "Next Billing Date",
                })}
              </div>
              <div className={"flex w-1/2"}>
                {billingData?.next_billing_at
                  ? new Date(billingData?.next_billing_at * 1000).toDateString()
                  : "--"}
              </div>
            </div>
          </div>
        </div>
        <h6 className="col-span-12 text-[14px] leading-5 font-medium primary-text mb-[8px]">
          {formatMessage({
            id: "You can manage your subscription, payment menthod, and download invoices from your billing portal.",
          })}
        </h6>
        <div
          className={
            "col-span-12 md:col-span-5 flex flex-col md:flex-row gap-y-4 gap-x-[20px]"
          }
        >
          <BasicButton
            buttonText={formatMessage({ id: "Update Subscription" })}
            height="44px"
            customClass={"w-full md:min-w-[200px] export-button"}
            padding="12px 24px"
            onClick={() => {
              setModalShow(true);
            }}
          />
          <BasicButton
            buttonText={formatMessage({ id: "Access billing portal" })}
            height="44px"
            customClass={"w-full md:min-w-[200px] export-button"}
            padding="12px 24px"
            onClick={() => {
              onSubmitAccess();
            }}
            loading={accessLoading}
            disabled={accessLoading}
          />
        </div>
      </div>
      {/*<div className='mx-1 mt-0 card card-custom rounded-1 row'>*/}
      {/*  <div className='card-body'>*/}
      {/*    <h4 className='card-label fs-5'>{formatMessage({id: 'Billing'})}</h4>*/}
      {/*    <div className='gap-3 mx-0 bg-gray-100 col-md-6 row padding-16px'>*/}
      {/*      <div className='p-0 border-2 d-flex border-bottom border-secondary dark-border'>*/}
      {/*        <p className='pb-3 mb-0 text-gray-700 col-6 font-size-13'>*/}
      {/*          {formatMessage({id: 'Current Plan'})}*/}
      {/*        </p>*/}
      {/*        <p className='mb-0 col-6 font-size-13'>{`${selected} (${billingData?.status.replace(*/}
      {/*          '_',*/}
      {/*          ' '*/}
      {/*        )})`}</p>*/}
      {/*      </div>*/}
      {/*      <div className='p-0 border-2 d-flex border-bottom border-secondary dark-border'>*/}
      {/*        <p className='pb-3 mb-0 text-gray-700 col-6 font-size-13'>*/}
      {/*          {formatMessage({id: 'Amount'})}*/}
      {/*        </p>*/}
      {/*        <p className='mb-0 col-6 font-size-13'>*/}
      {/*          {billingData?.currency_code}*/}
      {/*          {billingData?.currency_code === 'INR' ? ' ₹' : ' $'}*/}
      {/*          {billingData?.subscription_items[0].amount > 0*/}
      {/*            ? (billingData?.subscription_items[0].amount / 100)?.toFixed(2)*/}
      {/*            : billingData?.subscription_items[0].amount?.toFixed(2)}*/}
      {/*        </p>*/}
      {/*      </div>*/}
      {/*      <div className='p-0 border-2 d-flex border-bottom border-secondary dark-border'>*/}
      {/*        <p className='pb-3 mb-0 text-gray-700 col-6 font-size-13'>*/}
      {/*          {' '}*/}
      {/*          {formatMessage({id: 'Next Billing Amount'})}*/}
      {/*        </p>*/}
      {/*        <p className='mb-0 col-6 font-size-13'>*/}
      {/*          {billingData?.currency_code}*/}
      {/*          {billingData?.currency_code === 'INR' ? ' ₹' : ' $'}*/}
      {/*          {billingData?.subscription_items[0].amount > 0*/}
      {/*            ? (billingData?.subscription_items[0].amount / 100)?.toFixed(2)*/}
      {/*            : billingData?.subscription_items[0].amount?.toFixed(2)}*/}
      {/*        </p>*/}
      {/*      </div>*/}
      {/*      <div className='p-0 border-2 d-flex border-bottom border-secondary dark-border'>*/}
      {/*        <p className='pb-3 mb-0 text-gray-700 col-6 font-size-13'>*/}
      {/*          {' '}*/}
      {/*          {formatMessage({id: 'Next Billing Date'})}*/}
      {/*        </p>*/}

      {/*        <p className='mb-0 col-6 font-size-13'>*/}
      {/*          {new Date(billingData?.next_billing_at * 1000).toDateString()}*/}
      {/*        </p>*/}
      {/*      </div>*/}
      {/*    </div>*/}

      {/*<h6 className='my-4 font-size-13'>*/}
      {/*  {formatMessage({*/}
      {/*    id: 'You can manage your subscription, payment menthod, and download invoices from your billing portal.',*/}
      {/*  })}*/}
      {/*</h6>*/}
      {/*    <div className='flex-wrap gap-3 my-5 d-flex'>*/}
      {/*      <BasicButton*/}
      {/*        buttonText={formatMessage({id: 'Update Subscription'})}*/}
      {/*        height='44px'*/}
      {/*        border='1px solid #4776E6'*/}
      {/*        color='#4776E6'*/}
      {/*        textColor='white'*/}
      {/*        padding='12px 24px'*/}
      {/*        onClick={() => {*/}
      {/*          setModalShow(true)*/}
      {/*        }}*/}
      {/*      />*/}
      {/*      <BasicButton*/}
      {/*        buttonText={formatMessage({id: 'Access billing portal'})}*/}
      {/*        height='44px'*/}
      {/*        border='1px solid #4776E6'*/}
      {/*        color='#4776E6'*/}
      {/*        textColor='white'*/}
      {/*        padding='12px 24px'*/}
      {/*        onClick={() => {*/}
      {/*          onSubmitAccess()*/}
      {/*        }}*/}
      {/*        loading={accessLoading}*/}
      {/*        disabled={accessLoading}*/}
      {/*      />*/}
      {/*    </div>*/}
      {/*  </div>*/}
      {/*</div>*/}
      <ClaimCouponModal
        modalShow={showCouponModal}
        setModalShow={setShowCouponModal}
        getBillingDetails={getBillingDetails}
      />
      <BillingModal
        setModalShow={setModalShow}
        modalShow={modalShow}
        currencyBill={currencyBill}
        selected={selected}
        currentState={currentState}
        // plans={plans}
        upgrade={true}
        companyId={companyId}
        getBillingDetails={getBillingDetails}
      />
      <>
        <Modal
          show={showModal}
          onHide={() => setShowModal(false)}
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header closeButton></Modal.Header>
          <Modal.Body>
            <iframe
              title="Manage Subscription"
              src={urlData}
              width="100%"
              height={600}
            ></iframe>
          </Modal.Body>
        </Modal>
      </>
    </>
  );
}
