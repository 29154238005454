import React from "react";
import { Route, Routes } from "react-router-dom";
import ViewPage from "./pages/view";

const MarketplaceRouting = () => {
  return (
    <Routes>
      <Route index element={<ViewPage />} />
    </Routes>
  );
};

export default MarketplaceRouting;
