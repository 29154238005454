import React, { FC, useEffect, useState } from "react";
import '@google/model-viewer';
import './styles/avatar_viewer.css'
import clsx from "clsx";

declare global {

  namespace JSX {

    interface IntrinsicElements {

      'model-viewer': MyElementAttributes;

    }

    interface MyElementAttributes {

      key: string,

      src: string,

      children?: any,

      id?: any,

      ref?: any,

      class?: string,

      alt?: string,

      ar?: boolean,

      exposure?: string,

      onClick?: any

      modelCacheSize?: any

      scale?: any

    }

  }

}

export default function AvatarViewer(props: any) {
  const modelRef = React.useRef();
  const [modelLoaded, setModelLoaded] = useState(true);

  // const displaySetup = () => {
  //   setModelLoaded(true)
  // }

  // useEffect(() => {
  //   // Simulating the model loading delay
  //   const modelViewer = document.getElementById('model-animation-viewer');
  //   console.log(modelViewer)
  //   modelViewer?.addEventListener('load', displaySetup)
  //   return () => {
  //     modelViewer?.removeEventListener('load', displaySetup)
  //   };
  // }, []);

  return (
    // <div>
    <>
      {!modelLoaded && (
        <div className="loading-animation">
          {/* Add your loading animation elements here */}
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="100%"
            height="500"
            viewBox="0 0 100 100"
            preserveAspectRatio="xMidYMid"
          >
            <rect x="0" y="0" width="100" height="100" fill="none" />
            {/* <text x="50" y="50" fontSize="3" textAnchor="middle" fill="#fff">
              Loading...
            </text> */}
            <circle cx="50" cy="50" fill="none" stroke="#fff" strokeWidth="0.25" r="20" strokeDasharray="90.49555921538757 32.83185307179585">
              <animateTransform attributeName="transform" type="rotate" repeatCount="indefinite" dur="1s" values="0 50 50;360 50 50" keyTimes="0;1"></animateTransform>
            </circle>
          </svg>
        </div>
      )}
      {/* {modelLoaded && ( */}
      <model-viewer
        key={Math.random().toString()}
        id={'model-animation-viewer'}
        class={clsx("model-viewer", !modelLoaded && 'model-viewer-min')}
        src={props.src}
        alt={props.src}
        exposure="1.5"
        // camera-controls
        // camera-orbit="0deg 45deg 0m"
        // camera-target="0m 2m 0m"
        camera-orbit="0deg 90deg 1m"
        camera-target="0m 1.65m 0m"
        field-of-view="11deg"
        // scale="3"
        ar
        ar-modes="webxr"
        // modelCacheSize="0"
        // onClick={handleClick}
        ref={(ref: any) => {
          modelRef.current = ref;
        }}
      >
      </model-viewer>
      {/* )} */}
    </>

    // </div>
  );
}
