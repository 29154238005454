import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import { useIntl } from "react-intl";
import { ToolTipUI } from "../../../widgets/components/UI/ToolTipUI";
import { InfoCard } from "../../../widgets/components/UI/InfoCard";
import { Formik, Form } from "formik";
import { get, isEqual, map, range } from "lodash";
import clsx from "clsx";
import FileUploader from "../../../ai-traning/components/file-upload";
import TextInput from "../../../widgets/components/Input/TextInput";
import { toast } from "react-toastify";
import useGTM from "../../../../hooks/useGTM";
import { GTMEvent } from "../../../../hooks/gtm_helpers";

const MessageForm: React.FC<any> = ({ index }) => {
  const [startRecording, setStartRecording] = useState(false);
  const [stopRecording, setStopRecording] = useState(false);

  return (
    <div className={"pr-[9px]"}>
      <label className={"text-[12px] leading-[18px] text-[#FFFFFFA6] mb-1"}>
        Script {index}
      </label>
      <div className={"flex flex-col md:flex-row gap-y-3 md:gap-x-3"}>
        <div className={"bg-[#2E2F45] py-[16px] px-[20px] rounded"}>
          <p
            className={
              "text-[14px] leading-[22px] text-[#FFFFFFCC] font-medium"
            }
          >
            I hereby authorise Kamoto AI to use and replicate my voice. I trust
            that my voice will be cloned with the utmost care, for the purpose
            of creating an AI personality that is representative of me. This is
            a thrilling endeavour that exemplifies the harmonious blend of human
            creativity and AI technology.
          </p>
        </div>
        <div className={"min-w-[270px] flex flex-col gap-y-3"}>
          {!startRecording ? (
            <>
              <button
                className={
                  "text-[#C2D24B] bg-[#C2D24B1A] w-full h-full rounded border !border-[#C2D24B] py-4 flex justify-center items-center gap-x-4"
                }
                onClick={() => {
                  setStartRecording(true);
                }}
              >
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M9 5C9 4.20435 9.31607 3.44129 9.87868 2.87868C10.4413 2.31607 11.2044 2 12 2C12.7956 2 13.5587 2.31607 14.1213 2.87868C14.6839 3.44129 15 4.20435 15 5V10C15 10.7956 14.6839 11.5587 14.1213 12.1213C13.5587 12.6839 12.7956 13 12 13C11.2044 13 10.4413 12.6839 9.87868 12.1213C9.31607 11.5587 9 10.7956 9 10V5Z"
                    stroke="#C2D24B"
                    strokeWidth="1.6"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M5 10C5 11.8565 5.7375 13.637 7.05025 14.9497C8.36301 16.2625 10.1435 17 12 17M12 17C13.8565 17 15.637 16.2625 16.9497 14.9497C18.2625 13.637 19 11.8565 19 10M12 17V21M8 21H16"
                    stroke="#C2D24B"
                    strokeWidth="1.6"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
                Start Recording my Voice
              </button>
              <button
                className={
                  "text-[#C2D24B] bg-[#C2D24B1A] w-full h-full rounded border !border-[#C2D24B] py-4 flex justify-center items-center gap-x-4"
                }
              >
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g clipPath="url(#clip0_602_27043)">
                    <path
                      d="M4 17V19C4 19.5304 4.21071 20.0391 4.58579 20.4142C4.96086 20.7893 5.46957 21 6 21H18C18.5304 21 19.0391 20.7893 19.4142 20.4142C19.7893 20.0391 20 19.5304 20 19V17M7 9L12 4M12 4L17 9M12 4V16"
                      stroke="#C2D24B"
                      strokeWidth="1.6"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_602_27043">
                      <rect width="24" height="24" fill="white" />
                    </clipPath>
                  </defs>
                </svg>
                Start Recording my Voice
              </button>
            </>
          ) : !stopRecording ? (
            <div
              className={
                "bg-[#FE58341A] rounded h-full flex justify-center items-center"
              }
            >
              <div className={"flex gap-x-6 items-center"}>
                <button onClick={() => setStopRecording(true)}>
                  <svg
                    width="53"
                    height="53"
                    viewBox="0 0 53 53"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <circle cx="26.5" cy="26.5" r="13.25" fill="#DF5656" />
                    <circle
                      cx="26.5008"
                      cy="26.4998"
                      r="21.2"
                      stroke="#DF5656"
                    />
                  </svg>
                </button>
                <div>
                  <h2
                    className={
                      "text-[32px] leading-[44px] text-[#FE5834] font-medium mb-1"
                    }
                  >
                    0:12
                  </h2>
                  <p className={"text-[12px] leading-[18px] text-[#FFFFFFA6]"}>
                    Click to stop recording
                  </p>
                </div>
              </div>
            </div>
          ) : (
            <div
              className={
                "p-[10px] bg-[#C2D24B1A] h-full rounded flex flex-col gap-y-4 justify-center"
              }
            >
              <div className={"flex items-center gap-x-2"}>
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g clipPath="url(#clip0_602_27065)">
                    <path
                      d="M7 4V20L20 12L7 4Z"
                      stroke="#C2D24B"
                      strokeWidth="1.6"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_602_27065">
                      <rect width="24" height="24" fill="white" />
                    </clipPath>
                  </defs>
                </svg>
                <p
                  className={
                    "flex items-center text-[12px] leading-[18px] text-[#FFFFFFA6]"
                  }
                >
                  <span
                    className={
                      "text-[16px] leading-6 text-[#FFFFFFCC] font-semibold mr-2"
                    }
                  >
                    0:31s
                  </span>
                  Click to preview audio
                </p>
              </div>
              <div className={"h-[1px] w-full bg-[#C2D24B1A]"}></div>
              <div
                className={"flex items-center gap-x-2 cursor-pointer"}
                onClick={() => {
                  setStopRecording(false);
                  setStartRecording(false);
                }}
              >
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g clipPath="url(#clip0_602_27128)">
                    <path
                      d="M4 7H20M10 11V17M14 11V17M5 7L6 19C6 19.5304 6.21071 20.0391 6.58579 20.4142C6.96086 20.7893 7.46957 21 8 21H16C16.5304 21 17.0391 20.7893 17.4142 20.4142C17.7893 20.0391 18 19.5304 18 19L19 7M9 7V4C9 3.73478 9.10536 3.48043 9.29289 3.29289C9.48043 3.10536 9.73478 3 10 3H14C14.2652 3 14.5196 3.10536 14.7071 3.29289C14.8946 3.48043 15 3.73478 15 4V7"
                      stroke="#FE5834"
                      strokeWidth="1.6"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_602_27128">
                      <rect width="24" height="24" fill="white" />
                    </clipPath>
                  </defs>
                </svg>

                <p className={"text-[13px] leading-[18px] text-[#FE5834]"}>
                  Delete Recording
                </p>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
const VoiceModal: React.FC<any> = ({
  open,
  setOpen,
  setCloneData = () => {},
}) => {
  const { formatMessage } = useIntl();
  const {dataLayerPush} = useGTM()
  const [uploadFileUrl, setUploadFileUrl] = useState<string | undefined>(undefined)
  const [disableSubmit, setDisableSubmit] = useState(true)
  const onSubmit = (values:any) => {
    if(!values?.["voiceName"]?.trim()){
      toast.error(formatMessage({id: "Please provide name for cloned voice"}))
      return;
    }
    if(!uploadFileUrl){
      toast.error(formatMessage({id: "Please upload a sample audio file"}),
      {
        autoClose: false,
        closeButton: true, // Display close button (cross icon)
      })
      return;
    }
    let data = { name: values?.["voiceName"], audio_url: uploadFileUrl };
    setCloneData(data);
    setOpen(false);
  };

  const handleClose = () =>{
    setUploadFileUrl(undefined)
    setDisableSubmit(true)
    setOpen(false)
  }

  return (
    <Modal
      size="lg"
      show={open}
      onHide={handleClose}
      aria-labelledby="contained-modal-title-vcenter"
      className={"z-[999999] "}
      centered
      contentClassName={"max-w-[1080px]"}
    >
      <Modal.Header closeButton className={"px-10"}>
        <div className={"flex items-center justify-between w-full"}>
          <div className="d-flex fw-bold">
            <h2 className="text-[20px] primary-text leading-[28px]">
              {formatMessage({ id: "Clone Voice" })}
            </h2>{" "}
            <ToolTipUI
              tooltipText={formatMessage({
                id: "GLOBAL.TOOLTIP.BILLING.UPGRADE_PLAN",
              })}
            />
          </div>
          <button onClick={handleClose}>
            <svg
              width="32"
              height="32"
              viewBox="0 0 32 32"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M21.6568 8.45747L8.45746 21.6568C7.93676 22.1775 7.93676 23.0217 8.45746 23.5424C8.97816 24.0631 9.82238 24.0631 10.3431 23.5424L23.5424 10.3431C24.0631 9.82239 24.0631 8.97817 23.5424 8.45747C23.0217 7.93677 22.1775 7.93677 21.6568 8.45747Z"
                fill="white"
              />
              <path
                opacity="0.5"
                d="M23.5425 21.6578L10.3432 8.45843C9.8225 7.93773 8.97828 7.93773 8.45758 8.45843C7.93688 8.97913 7.93688 9.82335 8.45758 10.3441L21.6569 23.5434C22.1776 24.0641 23.0218 24.0641 23.5425 23.5434C24.0632 23.0227 24.0632 22.1785 23.5425 21.6578Z"
                fill="white"
              />
            </svg>
          </button>
        </div>
      </Modal.Header>
      <Formik initialValues={{ voiceName: "" }} onSubmit={onSubmit}>
        {(formik: any) => (
          <Form>
            <Modal.Body className={"flex justify-center flex-col px-10"}>
              <div>
                <div className="">
                  <TextInput
                    fieldType={"text"}
                    fieldName={"voiceName"}
                    formik={formik}
                    placeholder={formatMessage({
                      id: "Enter your cloned voice name",
                    })}
                    label={formatMessage({ id: "Name of Cloned voice" })}
                    toolTipText={formatMessage({
                      id: "You can identify your voice later by this name",
                    })}
                  />
                </div>
                <div className="flex">
                  <svg
                    width="36"
                    height="36"
                    viewBox="0 0 36 36"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g clipPath="url(#clip0_7123_21489)">
                      <path
                        d="M21 4.5V10.5C21 10.8978 21.158 11.2794 21.4393 11.5607C21.7206 11.842 22.1022 12 22.5 12H28.5"
                        stroke="#C2D24B"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M18 16.5V25.5M25.5 31.5H10.5C9.70435 31.5 8.94129 31.1839 8.37868 30.6213C7.81607 30.0587 7.5 29.2956 7.5 28.5V7.5C7.5 6.70435 7.81607 5.94129 8.37868 5.37868C8.94129 4.81607 9.70435 4.5 10.5 4.5H21L28.5 12V28.5C28.5 29.2956 28.1839 30.0587 27.6213 30.6213C27.0587 31.1839 26.2956 31.5 25.5 31.5Z"
                        stroke="#C2D24B"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M14.25 20.25L18 16.5L21.75 20.25"
                        stroke="#C2D24B"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_7123_21489">
                        <rect width="36" height="36" fill="white" />
                      </clipPath>
                    </defs>
                  </svg>
                  <div className="ml-[10px] mb-[20px]">
                    <p className="text-[15px] bold">
                      {formatMessage({ id: "Upload Voice Sample" })}{" "}
                    </p>
                    <p className="text-[12px] opacity-75">
                      {formatMessage({
                        id: "Please upload at least 1 minute long .mp3, .wav, or .m4a audio file which you want to clone. Only English Voice is supported presently.",
                      })}{" "}
                    </p>
                  </div>
                </div>
                <FileUploader
                  fileSize={10485760}
                  maxFileNumber={1}
                  allowType={["audio/mpeg", "audio/wav", "audio/m4a"]}
                  resourceType="avatar-cloned-voice-sample"
                  // metaData={{ module: "document", isProtected: true }}
                  modalStatus={true}
                  handleClose={() => {}}
                  handleSuccess={(id: number, url: string) => {
                    setUploadFileUrl(url);
                    setDisableSubmit(false);
                    dataLayerPush(GTMEvent.ClonedVoiceUploadSuccessful)
                  }}
                  onComplete={() => {}}
                  // isPrivate={false}
                />
              </div>
            </Modal.Body>
            <Modal.Footer>
              <div className={"flex flex-grow md:justify-end gap-x-4"}>
                <button
                  className={
                    "main-button-primary-light py-[12px] px-[24px] rounded w-full md:w-auto"
                  }
                  type="button"
                  onClick={handleClose}
                >
                  {formatMessage({ id: "Cancel" })}
                </button>
                <button
                    type="submit"
                    className={`main-button text-[14px] leading-5 font-medium py-[12px] w-full sm:w-1/2  md:w-[140px] rounded ${
                        disableSubmit ? "opacity-75" : ""
                    }`}
                    disabled={disableSubmit}
                >
                  {formatMessage({
                    id: "Save",
                  })}
                </button>
              </div>
            </Modal.Footer>
          </Form>
        )}
      </Formik>
    </Modal>
  );
};

export default VoiceModal;
