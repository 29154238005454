import { Field, Formik } from "formik";
import React, { useEffect, useState } from "react";
import { Form, Modal } from "react-bootstrap";
import { useIntl } from "react-intl";
import { toast } from "react-toastify";
import request from "../../../core/_apis";
import { FormikProps, useAuth } from "../../auth";
import FormikReactSelect from "../../widgets/components/Input/FormikReactSelect";
import TextInput from "../../widgets/components/Input/TextInput";
import { CustomButton } from "../../widgets/components/UI/CustomButton";
import { ToolTipUI } from "../../widgets/components/UI/ToolTipUI";
import * as Yup from "yup";

export const UpdatePhoneModal = ({
    show,
    handleClose,
}: {
    show: boolean;
    handleClose?: () => void;
}) => {
    const { formatMessage } = useIntl();
    const { referenceData, ipStackCountry, currentUser } = useAuth()
    const [otpSent, setOtpSent] = useState<boolean>(false)
    const [userId, setUserId] = useState<any>()
    const [resentTimer, setResentTimer] = useState<number>(0)
    const [contact, setContact] = useState<any>()
    const [country, setCountry] = useState<any>()
    const [loading, setLoading] = useState(false);

    const defaultCountry = {
        value: referenceData?.platformOptions?.countries?.find((el) => el.country_code == ipStackCountry?.country_code)?.['phone_code'],
        label: referenceData?.platformOptions?.countries?.find((el) => el.country_code == ipStackCountry?.country_code)?.['phone_code']
    }

    const onSubmit = (values: any) => {
        console.log(values)
    }

    const otpSchema = Yup.object().shape({
        phone: Yup.number()
            .required(formatMessage({ id: "Mobile no. is required" })),
        phoneCode: Yup.string()
            .required(formatMessage({ id: "Country code is required" })),
    });

    const resendOtp = async () => {
        try {
            await request.post('/auth/update-phone', {
                phone: contact,
                phoneCode: parseInt(country)
            })
            setOtpSent(true)
            setLoading(false);
            setResentTimer(60);
        } catch (e) {
            setLoading(false);
        }
    }

    useEffect(() => {
        if (resentTimer === 60) {
            let int = setInterval(() => {
                setResentTimer((value) => {
                    if (value === 0) {
                        clearInterval(int);
                    }
                    return value - 1;
                });
            }, 1000);
            // return () => clearInterval(int);
        }
    }, [resentTimer]);

    const submitPhoneForOtp = async (values: any, { setSubmitting }: FormikProps) => {
        // console.log(values)
        // return;
        setLoading(true);
        setContact(values.phone)
        setCountry(values.phoneCode.slice(0,-2))
        try {
            let resp = await request.post('/auth/update-phone', {
                phone: values.phone,
                phoneCode: parseInt(values.phoneCode),
                userId: currentUser?.userId
            })
            setUserId(resp.data.data.userId)
            setOtpSent(true)
            setSubmitting(false);
            setLoading(false);
            setResentTimer(60);
        } catch (e: any) {
            if (e?.response?.status === 403) {
                toast.error(e?.response?.data?.errors[0])
            }
            setSubmitting(false);
            setLoading(false);
        }
    }

    const verifyPhoneOTP = async (values: any, { setSubmitting }: FormikProps) => {
        setLoading(true);
        // setContact(values.contact)
        // setCountry(values.country)
        try {
            let resp = await request.post('/auth/update-phone', {
                phone: contact,
                phoneCode: country,
                userId: currentUser?.userId,
                otp: values.otp
            })
            window.location.reload()
        } catch (e: any) {
            if (e?.response?.status === 404) {
                toast.error("Invalid OTP.")
            }
            setSubmitting(false);
            setLoading(false);
        }
    }

    return (
        <Modal
            id="kt_modal_create_app"
            tabIndex={-1}
            aria-hidden="true"
            dialogClassName="modal-dialog modal-dialog-centered mw-md-480px"
            show={show}
            onHide={handleClose}
            backdrop={true}
        >
            {
                otpSent ?
                    <Formik
                        initialValues={{}}
                        onSubmit={onSubmit}
                        validateOnMount
                        className={"flex-grow"}
                    >
                        {(formik) => {
                            return (
                                <Form className={"w-full"}>
                                    <div className="modal-body py-lg-10 px-lg-10 d-md-flex flex-md-column align-items-md-center">
                                        <div className="text-center text-[16px] font-[400]  mb-[14px]   secondary-text">
                                            {formatMessage({ id: "Change/Update your mobile number." })}
                                        </div>
                                        <div className="flex flex-row justify-between my-5 secondary-text w-full">
                                            {
                                                resentTimer > 0
                                                    ?
                                                    <>
                                                        <span>OTP sent. Check your phone.</span>
                                                        <div>
                                                            Send OTP again in {`${String(Math.floor(resentTimer / 60)).padStart(2, '0')}:${String(resentTimer % 60).padStart(2, '0')}`} secs
                                                        </div>
                                                    </>
                                                    :
                                                    <div>
                                                        <span>OTP not received? </span>
                                                        <button
                                                            type="button"
                                                            className="text-primary"
                                                            onClick={resendOtp}
                                                        >
                                                            Send Again
                                                        </button>
                                                    </div>

                                            }
                                        </div>
                                        <Field
                                            className={'form-control secondary-bg mb-8 border-main font-size-12'}
                                            name={"otp"}
                                            placeholder={formatMessage({ id: "Enter Your OTP here" })}
                                            type={"number"}
                                        />
                                        <button
                                            type="button"
                                            className={`main-button text-[14px] leading-5 font-medium py-[12px] w-full sm:w-1/2  md:w-[140px] rounded`}
                                            onClick={(e) => verifyPhoneOTP(formik.values, formik)}
                                        >
                                            {formatMessage({ id: "Verify & Update" })}
                                        </button>
                                    </div>
                                </Form>
                            )
                        }}
                    </Formik>
                    :
                    <Formik
                        initialValues={{ phoneCode: defaultCountry.value }}
                        validationSchema={otpSchema}
                        onSubmit={submitPhoneForOtp}
                        validateOnMount
                    // className={"flex-grow"}
                    >
                        {(formik) => {
                            return (
                                <Form>
                                    <div className="modal-body py-lg-10 px-lg-10 d-md-flex flex-md-column align-items-md-center">
                                        <div className="text-center text-[16px] font-[400]  mb-[14px]   secondary-text">
                                            {formatMessage({ id: "Change/Update your mobile number." })}
                                        </div>
                                        <div className={"w-full col-span-12 md:col-span-6"}>
                                            <label
                                                className={
                                                    "mb-2  text-[13px] leading-5 secondary-text flex"
                                                }
                                            >
                                                {formatMessage({ id: "Contact Number" })}
                                                <ToolTipUI
                                                    tooltipText={formatMessage({
                                                        id: "GLOBAL.TOOLTIP.USER.CONTACT_NUMBER",
                                                    })}
                                                />
                                            </label>
                                            <div className="flex items-start">
                                                <FormikReactSelect
                                                    name={'phoneCode'}
                                                    className={"mr-2 w-[150px]"}
                                                    options={referenceData?.platformOptions?.countries?.map((el) => { return { value: el.phone_code + el.country_code, label: `+${el.phone_code} - ${el.country_name}` } })}
                                                />
                                                <Field
                                                    className={'form-control ml-3 secondary-bg  border-main font-size-12'}
                                                    name={"phone"}
                                                    type={"number"}
                                                />
                                            </div>
                                        </div>
                                        <button
                                            type="button"
                                            className={`main-button text-[14px] leading-5 font-medium py-[12px] w-full sm:w-1/2  md:w-[140px] rounded ${loading ? 'opacity-75' : ''}`}
                                            onClick={(e) => submitPhoneForOtp(formik.values, formik)}
                                            disabled={loading}
                                        >
                                            {loading ? "Please Wait" : formatMessage({ id: "Send OTP" })}
                                        </button>
                                    </div>
                                </Form>
                            )
                        }}
                    </Formik>
            }
        </Modal>
    );
};
