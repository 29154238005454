import React from "react";
import { Formik } from "formik";
import { get, isEqual, map } from "lodash";
import clsx from "clsx";
import FormikReactSelect from "../../../../widgets/components/Input/FormikReactSelect";
import { useIntl } from "react-intl";

const Index: React.FC<any> = ({ setOpenFilter }) => {
  const { formatMessage } = useIntl();
  return (
    <Formik
      initialValues={{
        gender: "male",
        personality: null,
        racial: ["indian", "asian"],
        industry: "it",
        country: "all",
      }}
      onSubmit={(data) => {
        console.log(data);
      }}
    >
      {({ handleChange, handleBlur, handleSubmit, values }) => (
        <form
          onSubmit={handleSubmit}
          className={
            "px-[14px] py-[24px] md:p-[24px] md:pb-10 flex md:block flex-col justify-between flex-grow  overflow-y-auto event-filter"
          }
        >
          <div
            className={"h-[80vh] overflow-y-scroll md:h-auto md:overflow-auto"}
          >
            <div className={"border-b !border-[#2E2F45] pb-4 mb-[24px]"}>
              <h4
                className={
                  "text-[14px] leading-6 text-[#FFFFFF] font-semibold mb-2"
                }
              >
                {formatMessage({
                  id: "Gender",
                })}
              </h4>
              <div className={"flex items-center gap-x-[32px]"}>
                {map(
                  [
                    { id: "all", label: "All", value: "all" },
                    { id: "male", label: "Male", value: "male" },
                    { id: "female", label: "Female", value: "female" },
                  ],
                  (item) => (
                    <div className={"flex items-center gap-x-2"}>
                      <input
                        type={"checkbox"}
                        name={"gender"}
                        className={"form-check-input h-6 w-6 "}
                        value={formatMessage({ id: get(item, "value") })}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      <label
                        className={"text-[13px] text-[#FFFFFFA6] leading-5"}
                      >
                        {formatMessage({ id: get(item, "label") })}
                      </label>
                    </div>
                  )
                )}
              </div>
            </div>
            <div className={"border-b !border-[#2E2F45] pb-4 mb-[24px]"}>
              <h4
                className={
                  "text-[14px] leading-6 text-[#FFFFFF] font-semibold mb-3"
                }
              >
                {formatMessage({ id: "Personalities from" })}
              </h4>
              <div className={"flex flex-col gap-y-3"}>
                {map(
                  [
                    {
                      id: "verified",
                      label: "Verified UsersTable Only",
                      value: "verified",
                    },
                    {
                      id: "paid",
                      label: "Paid UsersTable Only",
                      value: "paid",
                    },
                    {
                      id: "unspecified",
                      label: "Unspecified",
                      value: "unspecified",
                    },
                  ],
                  (item) => (
                    <div className={"flex items-center gap-x-2"}>
                      <input
                        type={"radio"}
                        name={"personality"}
                        className={"form-check-input  h-4 w-4 bg-transparent"}
                        id={"event-filter-radio"}
                        value={formatMessage({ id: get(item, "value") })}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      <label
                        className={clsx(
                          "text-[13px]  leading-5 font-medium",
                          isEqual(values.personality, get(item, "value"))
                            ? "!text-[#FFFFFF]"
                            : "!text-[#FFFFFFA6]"
                        )}
                      >
                        {formatMessage({ id: get(item, "label") })}
                      </label>
                    </div>
                  )
                )}
              </div>
            </div>
            <div className={"border-b !border-[#2E2F45] mb-[12px]"}>
              <FormikReactSelect
                isMulti={true}
                name={"racial"}
                label={formatMessage({ id: "Racial Information" })}
                tooltipText={"Racial Information"}
                className={"without-arrow"}
                options={[
                  { value: "indian", label: "indian" },
                  { value: "asian", label: "asian" },
                ]}
              />
            </div>
            <div className={"border-b !border-[#2E2F45] mb-[12px]"}>
              <FormikReactSelect
                name={"industry"}
                label={formatMessage({ id: "Select Industry" })}
                tooltipText={"Select Industry"}
                options={[
                  {
                    value: "it",
                    label: formatMessage({ id: "Information Technology" }),
                  },
                  { value: "agro", label: formatMessage({ id: "Agro" }) },
                ]}
              />
            </div>
            <div className={"border-b !border-[#2E2F45] mb-[12px]"}>
              <FormikReactSelect
                name={"country"}
                label={formatMessage({ id: "Country" })}
                tooltipText={"Select Country"}
                options={[
                  { value: "all", label: formatMessage({ id: "All" }) },
                  { value: "england", label: formatMessage({ id: "England" }) },
                  { value: "india", label: formatMessage({ id: "India" }) },
                ]}
              />
            </div>
            <div className={"border-b !border-[#2E2F45] mb-[12px]"}>
              <FormikReactSelect
                name={"stage-life"}
                label= {formatMessage({ id: "Stage of Life" })}
                tooltipText={"Stage of Life"}
                options={[
                  { value: "all", label: "All" },
                  { value: "england", label: "England" },
                  { value: "india", label: "India" },
                ]}
              />
            </div>
            <div className={"border-b !border-[#2E2F45] pb-4 mb-[24px]"}>
              <h4
                className={
                  "text-[14px] leading-6 text-[#FFFFFF] font-semibold mb-2"
                }
              >
                AI Personality Traits
              </h4>
              <div
                className={"flex flex-wrap items-center gap-x-[32px] gap-y-3.5"}
              >
                {map(
                  [
                    {
                      id: "licensed",
                      label: "Is Licensed",
                      value: "licensed",
                    },
                    {
                      id: "hasVoice",
                      label: "Has Voice",
                      value: "hasVoice",
                    },
                    {
                      id: "hasAvatar",
                      label: "Has Avatar",
                      value: "hasAvatar",
                    },
                  ],
                  (item) => (
                    <div className={"flex items-center gap-x-2"}>
                      <input
                        type={"checkbox"}
                        name={"gender"}
                        className={"form-check-input h-6 w-6 "}
                        value={get(item, "value")}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      <label
                        className={"text-[13px] text-[#FFFFFFA6] leading-5"}
                      >
                        {get(item, "label")}
                      </label>
                    </div>
                  )
                )}
              </div>
            </div>
            <div className={"border-b !border-[#2E2F45] pb-4 mb-[24px]"}>
              <h4
                className={
                  "text-[14px] leading-6 text-[#FFFFFF] font-semibold mb-2"
                }
              >
                AI Personality Type
              </h4>
              <div
                className={"flex flex-wrap items-center gap-x-[32px] gap-y-3.5"}
              >
                {map(
                  [
                    {
                      id: "funny",
                      label: "Funny",
                      value: "funny",
                    },
                    {
                      id: "angry",
                      label: "Angry",
                      value: "angry",
                    },
                    {
                      id: "romantic",
                      label: "Romantic",
                      value: "romantic",
                    },
                    {
                      id: "poetic",
                      label: "Poetic",
                      value: "poetic",
                    },
                    {
                      id: "sadist",
                      label: "Sadist",
                      value: "sadist",
                    },
                  ],
                  (item) => (
                    <div className={"flex items-center gap-x-2"}>
                      <input
                        type={"checkbox"}
                        name={"gender"}
                        className={"form-check-input h-6 w-6 "}
                        value={get(item, "value")}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      <label
                        className={"text-[13px] text-[#FFFFFFA6] leading-5"}
                      >
                        {get(item, "label")}
                      </label>
                    </div>
                  )
                )}
              </div>
            </div>
          </div>
          <div className={"flex items-center gap-x-3.5 justify-end"}>
            <button
              className={"bg-[#C2D24B1A] py-3 px-10 rounded w-full md:w-auto"}
            >
              <span
                className={"text-[#C2D24B]"}
                onClick={() => setOpenFilter(false)}
              >
                Cancel
              </span>
            </button>
            <button
              className={"bg-[#C2D24B] py-3 px-11 rounded w-full md:w-auto"}
            >
              <span className={"text-black"}>Apply Filters</span>
            </button>
          </div>
        </form>
      )}
    </Formik>
  );
};

export default Index;
