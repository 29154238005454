import React, { useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper";
import { filter, get, isEqual, map, range } from "lodash";
// import { personalities } from "./constants";
import clsx from "clsx";
import Avatar from "../../ai-personality/components/edit-profile/avatar";
import Voice from "../../ai-personality/components/edit-profile/voice";
import { useAuth } from "../../auth";
import { useIntl } from "react-intl";

const VoiceSettings: React.FC<any> = ({ setOpenEdit }) => {
  const [active, setActive] = useState(0);
  const {
    personalityId,
    currentUser,
    setSelectedPersonality,
    selectedPersonality,
  } = useAuth();
  const personalityData =
    selectedPersonality ||
    currentUser?.personality?.find(
      (el: any) => el.personalityId == personalityId
    );
  setSelectedPersonality(personalityData);
  const { formatMessage } = useIntl();
  const settings = {
    breakpoints: {
      "240": {
        slidesPerView: 1,
      },
      "768": {
        slidesPerView: 3,
      },
      "1024": {
        slidesPerView: 4,
      },
      "1280": {
        preventInteractionOnTransition: true,
        slidesPerView: 4.8,
        watchSlidesProgress: true,
        shortSwipes: false,
      },
    },
  };

  const personalities = [
    {
      name: "avatar",
      title: `${formatMessage({ id: "Avatar & Visuals" })}`,
      text: `${formatMessage({
        id: "Assign Avatar & give visual look to your AI Personality",
      })}`,
    },
  ];

  return (
    <div className={"edit-profile-section p-[24px_10px_16px_10px] md:p-[24px_20px_16px_20px] rounded-[4px] mt-4"}>
      <Voice
        setOpenEdit={setOpenEdit}
        personalityData={personalityData}
      />
    </div>
  );
};

export default VoiceSettings;
