import React, { useEffect, useState } from "react";
import profileImage from "../../../../assets/images/profile/profile-user-image.png";
import Banner from "../../components/banner";
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
import { useFullScreenHandle } from "react-full-screen";
import { isEqual, map } from "lodash";
import clsx from "clsx";
import { Link, useNavigate, useParams, useSearchParams } from "react-router-dom";
import avatarImage from "../../../../assets/images/profile/avatar-image.png";
import Followings from "./followings";
import Empty from "../../components/ai-user-profile/empty";
import CreateAiPersonality from "../../components/ai-profile/create-ai-personality";
import SocialLinks from "../../components/social-links";
import request from "../../../../core/_apis";
import { Spinner } from "../../../widgets/components/General/Spinner";
import ReadMore from "../../../../components/utility/readMore";
import { kmbConvertor } from "../../../../utils";
import { followEntity, unfollowEntity } from "../ai-profile/core/_request";
import { Error404 } from "../../../errors/components/Error404";

import {Spinner as BootstrapSpinner} from "react-bootstrap"
import { useAuth } from "../../../auth";
import axios from "axios";
import { API_URL } from "../../../profile/core/_requests";
import Pagination from "../../components/pagination";
import { useIntl } from "react-intl";
import { getCdnUrl } from "../../../../core/_util";
import useGTM from "../../../../hooks/useGTM";
import { GTMEvent } from "../../../../hooks/gtm_helpers";

function getUrlWithProtocol(url:any) {
  // Check if the URL starts with http:// or https://
  if (url.startsWith("http://") || url.startsWith("https://")) {
    return url;
  } else {
    // Prepend https:// to the URL
    return "https://" + url;
  }
}


const Index = () => {
  const { formatMessage } = useIntl();
  const {dataLayerPush} = useGTM()
  const [follow, setFollow] = useState(false);
  const [followers, setFollowers] = useState(0);
  const [startChat, setStartChat] = useState(false);
  const tabs = ['personalities-managed','personalities-followed']
  const [searchParams, setSearchParams] = useSearchParams()
  const [selectedTabIndex, setSelectedTabIndex] = useState(tabs.indexOf(searchParams.get('tab')||'personalities-managed'));
  const [loading, setLoading] = useState<boolean>(true)
  const [user,setUser] = useState<any>(undefined)
  const { username } = useParams()
  const navigate = useNavigate()
  const [followLoading, setFollowLoading] = useState<boolean>(false);
  const {currentUser} = useAuth()
  const [filters,setFilters] = useState<any>({})
  const [showCreate,setShowCreate] = useState(false)


  useEffect(()=>{
    searchParams.set('tab',tabs[selectedTabIndex])
    setSearchParams(searchParams,{replace: true})
  },[selectedTabIndex])

  const setup = () => {
    if(username !== username?.toLowerCase()){
      navigate(`/u/${username?.toLowerCase()}`) 
    }
    setLoading(true)
    axios.get(`${API_URL}/user/username/${username}`,{params: {includes: `${encodeURIComponent(['followers','interactions','followed','following_personalities'].join(','))}`, ...filters}}).then((response) => {
      console.log(response)
      if(response.status === 200 && response.data.success === true){
        setUser(response.data.data)
        setFollow(response?.data?.data?.followed || false)
        setFollowers(response?.data?.data?.followers || 0)
      }
      setLoading(false)
    }).catch((err) => {
      // setLoading(false)
    }).finally(() => {
      setLoading(false)
    })
  }

  useEffect(() => {
    setup()
  }, [])

  useEffect(() => {
    setup()
  },[JSON.stringify(filters)])
  // if(loading){
  //   return <Spinner/>
  // }
  async function handleFollow(follow:boolean){
    if(!currentUser){
      navigate('/auth/login')
      return
    }
    setFollowLoading(true)
    if(follow){
      const {data:{success,data}} = await followEntity('user',user.userId)
      if(success){
        setFollow(true)
        setFollowers((followers)=>followers+1)
        dataLayerPush(GTMEvent.UserFollowSuccessful)
      }else{
        dataLayerPush(GTMEvent.UserFollowFailed)
      }
    } else {
      const {data:{success,data}} = await unfollowEntity('user',user.userId)
      if(success){
        setFollow(false)
        setFollowers((followers)=>followers-1)
        dataLayerPush(GTMEvent.UserUnfollowSuccessful)
      }else{
        dataLayerPush(GTMEvent.UserUnfollowFailed)
      }
    }
    setFollowLoading(false)
    
  }

  useEffect(()=>{
    if(currentUser?.personalities?.find((el:any) => el?.personality_user?.designation == `${formatMessage({ id: "Owner" })}`)){
      setShowCreate(false)
    }else{
      setShowCreate(true)
    }
  },[currentUser])
  
  if(!loading && !user){
    return (
      <div className="mt-20">
        <Error404 toHome={true} />
      </div>
    )
  }

  return (
    <div>
      {loading && <Spinner/>}
      <Banner bannerImageAlt={getCdnUrl(user?.banner)} />
      <div
        className={
          "grid grid-cols-12 md:gap-x-4 gap-y-4 px-[20px] md:px-20 -mt-52"
        }
      >
        <div
          className={
            "col-span-12 md:col-span-4 xl:col-span-3 tab-container shadow-default rounded mt-20 p-[14px] md:px-[20px] md:pb-5"
          }
        >
          <div
            className={
              "flex flex-col gap-y-1 items-center justify-center -mt-20"
            }
          >
            <div
              className={"rounded-full bg-[#2E2F45] p-1 w-[152px] h-[152px]"}
            >
              <img
                src={getCdnUrl(user?.photo)}
                className={"object-cover w-full h-full rounded-circle"}
                alt={"user's profile pic"}
              />
            </div>
            <div className={"text-center"}>
              <h3 className={"mt-1 mb-4 text-[24px] flex flex-row flex-wrap items-center leading-8 font-semibold"}>
                {user?.firstName}
                <span className={"flex gap-x-2 items-end ml-2"}>
                  {user?.lastName}
                  {
                    user?.isVerified == 1
                    &&
                    <svg
                      width="26"
                      height="26"
                      viewBox="0 0 26 26"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M13 0L16.0933 3.47992L20.6412 2.48278L21.0983 7.11627L25.3637 8.98278L23.01 13L25.3637 17.0172L21.0983 18.8837L20.6412 23.5172L16.0933 22.5201L13 26L9.90674 22.5201L5.35879 23.5172L4.90174 18.8837L0.636266 17.0172L2.99 13L0.636266 8.98278L4.90174 7.11627L5.35879 2.48278L9.90674 3.47992L13 0Z"
                        fill="#0386FF"
                      />
                      <path
                        d="M9 13L11.6724 16L18.5 10"
                        stroke="white"
                        strokeWidth="2"
                        strokeLinecap="round"
                      />
                    </svg>
                  }
                </span>
              </h3>
            </div>
          </div>
          {!(currentUser && currentUser?.userId == user?.userId) && <div className={"flex w-full items-center mb-2"}>
            <button
              className={clsx(
                " h-[44px] text-[#C2D24B]  py-[12px] px-[24px] w-full border !border-[#C2D24B] flex items-center justify-center gap-x-2.5 rounded",
                follow ? "bg-[#97A33C1A]" : "bg-[#C2D24B1A]"
              )}
              onClick={() => {
                handleFollow(!follow)
              }}
            >
              {followLoading ? <BootstrapSpinner animation="border" /> : <>
              {!follow?
              <svg
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g clipPath="url(#clip0_344_19479)">
                  <path
                    d="M16.2501 10.4771L10.0001 16.6671L3.75009 10.4771C3.33784 10.0759 3.01312 9.59374 2.79638 9.06092C2.57963 8.52811 2.47556 7.95618 2.4907 7.38117C2.50585 6.80615 2.63989 6.2405 2.88439 5.71984C3.12888 5.19917 3.47853 4.73477 3.91133 4.35587C4.34412 3.97698 4.85068 3.6918 5.3991 3.51829C5.94752 3.34479 6.52593 3.28671 7.09789 3.34773C7.66986 3.40874 8.223 3.58752 8.72248 3.87281C9.22196 4.1581 9.65696 4.54372 10.0001 5.00539C10.3447 4.54708 10.7802 4.16483 11.2793 3.88256C11.7785 3.6003 12.3305 3.4241 12.9009 3.36499C13.4712 3.30588 14.0477 3.36514 14.5941 3.53905C15.1405 3.71296 15.6451 3.99779 16.0764 4.37569C16.5077 4.7536 16.8563 5.21646 17.1004 5.7353C17.3446 6.25414 17.479 6.81779 17.4953 7.39098C17.5116 7.96417 17.4095 8.53455 17.1952 9.06643C16.9809 9.59831 16.6592 10.0802 16.2501 10.4821"
                    stroke="#C2D24B"
                    strokeWidth="1.4"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_344_19479">
                    <rect width="20" height="20" fill="white" />
                  </clipPath>
                </defs>
              </svg>
                :
              <svg width="18" height="18" viewBox="0 0 21 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M18.5001 10.5717L11.0001 17.9997L3.50006 10.5717C3.00536 10.0903 2.6157 9.5117 2.3556 8.87232C2.09551 8.23295 1.97062 7.54664 1.98879 6.85662C2.00697 6.1666 2.16782 5.48782 2.46121 4.86303C2.75461 4.23823 3.17419 3.68094 3.69354 3.22627C4.21289 2.77159 4.82076 2.42938 5.47887 2.22117C6.13697 2.01296 6.83106 1.94327 7.51743 2.01649C8.20379 2.08971 8.86756 2.30425 9.46693 2.64659C10.0663 2.98894 10.5883 3.45169 11.0001 4.00569C11.4136 3.45571 11.9362 2.99701 12.5352 2.65829C13.1341 2.31958 13.7966 2.10814 14.481 2.03721C15.1654 1.96628 15.8571 2.03739 16.5128 2.24608C17.1685 2.45477 17.7741 2.79656 18.2916 3.25005C18.8091 3.70354 19.2275 4.25897 19.5205 4.88158C19.8135 5.50419 19.9748 6.18057 19.9944 6.8684C20.0139 7.55622 19.8913 8.24068 19.6342 8.87894C19.3771 9.5172 18.991 10.0955 18.5001 10.5777" fill="#FF5A5A" />
                <path d="M18.5001 10.5717L11.0001 17.9997L3.50006 10.5717C3.00536 10.0903 2.6157 9.5117 2.3556 8.87232C2.09551 8.23295 1.97062 7.54664 1.98879 6.85662C2.00697 6.1666 2.16782 5.48782 2.46121 4.86303C2.75461 4.23823 3.17419 3.68094 3.69354 3.22627C4.21289 2.77159 4.82076 2.42938 5.47887 2.22117C6.13697 2.01296 6.83106 1.94327 7.51743 2.01649C8.20379 2.08971 8.86756 2.30425 9.46693 2.64659C10.0663 2.98894 10.5883 3.45169 11.0001 4.00569C11.4136 3.45571 11.9362 2.99701 12.5352 2.65829C13.1341 2.31958 13.7966 2.10814 14.481 2.03721C15.1654 1.96628 15.8571 2.03739 16.5128 2.24608C17.1685 2.45477 17.7741 2.79656 18.2916 3.25005C18.8091 3.70354 19.2275 4.25897 19.5205 4.88158C19.8135 5.50419 19.9748 6.18057 19.9944 6.8684C20.0139 7.55622 19.8913 8.24068 19.6342 8.87894C19.3771 9.5172 18.991 10.0955 18.5001 10.5777" stroke="#FF5A5A" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
              </svg>}
              {follow ? formatMessage({ id: "Unfollow" }) : formatMessage({ id: "Follow" })}
              </>}
            </button>
          </div>}
          <SocialLinks user={user}/>
          <div className={"grid grid-cols-12 gap-y-3.5 gap-x-2"}>
            <div className={"col-span-12 lg:col-span-6"}>
              <div className={"flex items-center gap-x-2 "}>
                <svg
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g clipPath="url(#clip0_344_19587)">
                    <path
                      d="M2.5 17.5V15.8333C2.5 14.9493 2.85119 14.1014 3.47631 13.4763C4.10143 12.8512 4.94928 12.5 5.83333 12.5H9.16667C10.0507 12.5 10.8986 12.8512 11.5237 13.4763C12.1488 14.1014 12.5 14.9493 12.5 15.8333V17.5M13.3333 2.60833C14.0503 2.79192 14.6859 3.20892 15.1397 3.79359C15.5935 4.37827 15.8399 5.09736 15.8399 5.8375C15.8399 6.57764 15.5935 7.29673 15.1397 7.88141C14.6859 8.46608 14.0503 8.88308 13.3333 9.06667M17.5 17.5V15.8333C17.4958 15.0976 17.2483 14.384 16.7961 13.8037C16.3439 13.2233 15.7124 12.8089 15 12.625M4.16667 5.83333C4.16667 6.71739 4.51786 7.56523 5.14298 8.19036C5.7681 8.81548 6.61594 9.16667 7.5 9.16667C8.38405 9.16667 9.2319 8.81548 9.85702 8.19036C10.4821 7.56523 10.8333 6.71739 10.8333 5.83333C10.8333 4.94928 10.4821 4.10143 9.85702 3.47631C9.2319 2.85119 8.38405 2.5 7.5 2.5C6.61594 2.5 5.7681 2.85119 5.14298 3.47631C4.51786 4.10143 4.16667 4.94928 4.16667 5.83333Z"
                      stroke="white"
                      strokeWidth="1.4"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_344_19587">
                      <rect width="20" height="20" fill="white" />
                    </clipPath>
                  </defs>
                </svg>
                <span className={"font-semibold"}>{kmbConvertor(followers)}</span>   {formatMessage({ id: "Followers" })}
              </div>
            </div>
            <div className={"col-span-12 lg:col-span-6"}>
              <div className={"flex items-center gap-x-2 "}>
                <svg
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g clipPath="url(#clip0_344_19592)">
                    <path
                      d="M5.83333 9.16732V15.834C5.83333 16.055 5.74554 16.267 5.58926 16.4232C5.43298 16.5795 5.22101 16.6673 5 16.6673H3.33333C3.11232 16.6673 2.90036 16.5795 2.74408 16.4232C2.5878 16.267 2.5 16.055 2.5 15.834V10.0007C2.5 9.77964 2.5878 9.56768 2.74408 9.4114C2.90036 9.25512 3.11232 9.16732 3.33333 9.16732H5.83333ZM5.83333 9.16732C6.71739 9.16732 7.56524 8.81613 8.19036 8.19101C8.81548 7.56589 9.16667 6.71804 9.16667 5.83398V5.00065C9.16667 4.55862 9.34226 4.1347 9.65482 3.82214C9.96738 3.50958 10.3913 3.33398 10.8333 3.33398C11.2754 3.33398 11.6993 3.50958 12.0118 3.82214C12.3244 4.1347 12.5 4.55862 12.5 5.00065V9.16732H15C15.442 9.16732 15.866 9.34291 16.1785 9.65547C16.4911 9.96803 16.6667 10.392 16.6667 10.834L15.8333 15.0007C15.7135 15.5119 15.4861 15.9509 15.1855 16.2515C14.8849 16.5521 14.5274 16.698 14.1667 16.6673H8.33333C7.67029 16.6673 7.03441 16.4039 6.56557 15.9351C6.09673 15.4662 5.83333 14.8304 5.83333 14.1673"
                      stroke="white"
                      strokeOpacity="0.8"
                      strokeWidth="1.2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_344_19592">
                      <rect width="20" height="20" fill="white" />
                    </clipPath>
                  </defs>
                </svg>
                <span className={"font-semibold"}>
                  {kmbConvertor(user?.likes)}
                </span>{" "}
                {formatMessage({ id: "Likes" })}
              </div>
            </div>
            <div className={"col-span-12 lg:col-span-6"}>
              <div className={"flex items-center gap-x-2 "}>
                <svg
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g clipPath="url(#clip0_2207_26633)">
                    <path
                      d="M11.6667 12.5V14.1667C11.6667 14.3877 11.5789 14.5996 11.4226 14.7559C11.2663 14.9122 11.0543 15 10.8333 15H5L2.5 17.5V9.16667C2.5 8.94565 2.5878 8.73369 2.74408 8.57741C2.90036 8.42113 3.11232 8.33333 3.33333 8.33333H5M17.5 11.6667L15 9.16667H9.16667C8.94565 9.16667 8.73369 9.07887 8.57741 8.92259C8.42113 8.76631 8.33333 8.55435 8.33333 8.33333V3.33333C8.33333 3.11232 8.42113 2.90036 8.57741 2.74408C8.73369 2.5878 8.94565 2.5 9.16667 2.5H16.6667C16.8877 2.5 17.0996 2.5878 17.2559 2.74408C17.4122 2.90036 17.5 3.11232 17.5 3.33333V11.6667Z"
                      stroke="white"
                      strokeOpacity="0.8"
                      strokeWidth="1.4"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_2207_26633">
                      <rect width="20" height="20" fill="white" />
                    </clipPath>
                  </defs>
                </svg>
                <span className={"font-semibold"}>{kmbConvertor(user?.interactions ?? 0)}</span>  {formatMessage({ id: "Interactions" })}
              </div>
            </div>
            <div className={"col-span-12 lg:col-span-6"}>
              <div className={"flex items-center gap-x-2 "}>
                <svg
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g clipPath="url(#clip0_344_19587)">
                    <path
                      d="M2.5 17.5V15.8333C2.5 14.9493 2.85119 14.1014 3.47631 13.4763C4.10143 12.8512 4.94928 12.5 5.83333 12.5H9.16667C10.0507 12.5 10.8986 12.8512 11.5237 13.4763C12.1488 14.1014 12.5 14.9493 12.5 15.8333V17.5M13.3333 2.60833C14.0503 2.79192 14.6859 3.20892 15.1397 3.79359C15.5935 4.37827 15.8399 5.09736 15.8399 5.8375C15.8399 6.57764 15.5935 7.29673 15.1397 7.88141C14.6859 8.46608 14.0503 8.88308 13.3333 9.06667M17.5 17.5V15.8333C17.4958 15.0976 17.2483 14.384 16.7961 13.8037C16.3439 13.2233 15.7124 12.8089 15 12.625M4.16667 5.83333C4.16667 6.71739 4.51786 7.56523 5.14298 8.19036C5.7681 8.81548 6.61594 9.16667 7.5 9.16667C8.38405 9.16667 9.2319 8.81548 9.85702 8.19036C10.4821 7.56523 10.8333 6.71739 10.8333 5.83333C10.8333 4.94928 10.4821 4.10143 9.85702 3.47631C9.2319 2.85119 8.38405 2.5 7.5 2.5C6.61594 2.5 5.7681 2.85119 5.14298 3.47631C4.51786 4.10143 4.16667 4.94928 4.16667 5.83333Z"
                      stroke="white"
                      strokeWidth="1.4"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_344_19587">
                      <rect width="20" height="20" fill="white" />
                    </clipPath>
                  </defs>
                </svg>
                <span className={"font-semibold"}>
                  {kmbConvertor(user?.totalFollowing)}
                </span>{" "}
                {formatMessage({ id: "Following" })}
              </div>
            </div>
          </div>
          <p className={"my-5 text-[#FFFFFFA6]"}>
            <ReadMore>{user?.description}</ReadMore>
          </p>
          {user?.website && <a
            href={getUrlWithProtocol(user.website)}
            target={"_blank"}
            rel="noreferrer"
            className={
              "flex gap-x-2 text-[#C2D24B] underline cursor-pointer mb-[14px]"
            }
          >
            <svg
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g clipPath="url(#clip0_344_19460)">
                <path
                  d="M10 0C8.02219 0 6.08879 0.58649 4.4443 1.6853C2.79981 2.78412 1.51809 4.3459 0.761209 6.17317C0.00433286 8.00043 -0.193701 10.0111 0.192152 11.9509C0.578004 13.8907 1.53041 15.6725 2.92894 17.0711C4.32746 18.4696 6.10929 19.422 8.0491 19.8079C9.98891 20.1937 11.9996 19.9957 13.8268 19.2388C15.6541 18.4819 17.2159 17.2002 18.3147 15.5557C19.4135 13.9112 20 11.9778 20 10C19.9971 7.34872 18.9426 4.80684 17.0679 2.9321C15.1932 1.05736 12.6513 0.00286757 10 0V0ZM17.2058 5.83333H14.5217C13.9208 4.44082 13.1297 3.1384 12.1708 1.96333C14.2929 2.54077 16.1019 3.93122 17.2058 5.83333V5.83333ZM13.75 10C13.7432 10.8484 13.6095 11.6911 13.3533 12.5H6.64667C6.39055 11.6911 6.25685 10.8484 6.25 10C6.25685 9.15155 6.39055 8.3089 6.64667 7.5H13.3533C13.6095 8.3089 13.7432 9.15155 13.75 10V10ZM7.315 14.1667H12.685C11.9777 15.5631 11.0735 16.8507 10 17.99C8.92615 16.851 8.02187 15.5634 7.315 14.1667V14.1667ZM7.315 5.83333C8.02231 4.43689 8.92654 3.1493 10 2.01C11.0739 3.14898 11.9781 4.43663 12.685 5.83333H7.315ZM7.83334 1.96333C6.87305 3.13815 6.08049 4.44058 5.47834 5.83333H2.79417C3.89905 3.93036 5.70972 2.5398 7.83334 1.96333V1.96333ZM2.05084 7.5H4.91667C4.70064 8.31595 4.58864 9.15595 4.58334 10C4.58864 10.8441 4.70064 11.684 4.91667 12.5H2.05084C1.53862 10.8727 1.53862 9.12731 2.05084 7.5V7.5ZM2.79417 14.1667H5.47834C6.08049 15.5594 6.87305 16.8618 7.83334 18.0367C5.70972 17.4602 3.89905 16.0696 2.79417 14.1667V14.1667ZM12.1708 18.0367C13.1297 16.8616 13.9208 15.5592 14.5217 14.1667H17.2058C16.1019 16.0688 14.2929 17.4592 12.1708 18.0367V18.0367ZM17.9492 12.5H15.0833C15.2994 11.684 15.4114 10.8441 15.4167 10C15.4114 9.15595 15.2994 8.31595 15.0833 7.5H17.9475C18.4597 9.12731 18.4597 10.8727 17.9475 12.5H17.9492Z"
                  fill="white"
                  fillOpacity="0.8"
                />
              </g>
              <defs>
                <clipPath id="clip0_344_19460">
                  <rect width="20" height="20" fill="white" />
                </clipPath>
              </defs>
            </svg>
            {user?.website}
          </a>}
          {/* <div className={"mt-[14px]"}>
            <label className={"mb-[4px]"}>Managed By</label>
            <div className={"flex items-center gap-x-2"}>
              <img src={avatarImage} className={"w-6 h-6 rounded-full"} />
              <h6 className={"font-medium text-[#FFFFFFA6]"}>
                Redchillie Entertainment
              </h6>
            </div>
          </div> */}
          {/* <div className={"mt-[14px]"}>
            <label className={"mb-[4px]"}>Owned By</label>
            <div className={"flex items-center gap-x-2"}>
              <img src={avatarImage} className={"w-6 h-6 rounded-full"} />
              <h6
                className={
                  "font-medium text-[#FFFFFFA6] flex items-center gap-x-1"
                }
              >
                Shahrukh Khan{" "}
                <svg
                  width="13"
                  height="13"
                  viewBox="0 0 13 13"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M6.5 0L8.04663 1.73996L10.3206 1.24139L10.5491 3.55813L12.6819 4.49139L11.505 6.5L12.6819 8.50861L10.5491 9.44186L10.3206 11.7586L8.04663 11.26L6.5 13L4.95337 11.26L2.6794 11.7586L2.45087 9.44186L0.318133 8.50861L1.495 6.5L0.318133 4.49139L2.45087 3.55813L2.6794 1.24139L4.95337 1.73996L6.5 0Z"
                    fill="#0386FF"
                  />
                  <path
                    d="M4.5 6.5L5.83621 8L9.25 5"
                    stroke="white"
                    strokeLinecap="round"
                  />
                </svg>
              </h6>
            </div>
          </div> */}
        </div>
        <div
          className={
            "col-span-12 md:col-span-8 xl:col-span-9 tab-container shadow-default rounded p-[14px] md:px-[16px] md:py-[24px]"
          }
        >
          <Tabs
            className="font-size-13"
            selectedTabClassName="bg-primary fill-red-500"
            onSelect={(index) => setSelectedTabIndex(index)}
          >
            <TabList
              className={
                " text-[13px] leading-[20px] font-normal text-[#FFFFFFA6] setting-tabs overflow-x-auto overflow-y-hidden whitespace-nowrap border-solid border-[#2E2F45] border-b flex items-center justify-between"
              }
            >
              <div>
                <Tab>
                  <span className={"flex items-center gap-x-2"}>
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g clipPath="url(#clip0_2000_31118)">
                        <path
                          d="M15.5 13C14.5717 13 13.6815 13.3687 13.0251 14.0251C12.3687 14.6815 12 15.5717 12 16.5M12 16.5V17.5M12 16.5C12 15.5717 11.6313 14.6815 10.9749 14.0251C10.3185 13.3687 9.42826 13 8.5 13M12 17.5C12 18.4283 12.3687 19.3185 13.0251 19.9749C13.6815 20.6313 14.5717 21 15.5 21C16.4283 21 17.3185 20.6313 17.9749 19.9749C18.6313 19.3185 19 18.4283 19 17.5V15.7M12 17.5C12 18.4283 11.6313 19.3185 10.9749 19.9749C10.3185 20.6313 9.42826 21 8.5 21C7.57174 21 6.6815 20.6313 6.02513 19.9749C5.36875 19.3185 5 18.4283 5 17.5V15.7"
                          stroke={
                            isEqual(selectedTabIndex, 0) ? "#C2D24B" : "#A1A5B7"
                          }
                          strokeWidth="1.6"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M17.5 16C18.4283 16 19.3185 15.6313 19.9749 14.9749C20.6313 14.3185 21 13.4283 21 12.5C21 11.5717 20.6313 10.6815 19.9749 10.0251C19.3185 9.36875 18.4283 9 17.5 9H17"
                          stroke={
                            isEqual(selectedTabIndex, 0) ? "#C2D24B" : "#A1A5B7"
                          }
                          strokeWidth="1.6"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M19 9.3V6.5C19 5.57174 18.6313 4.6815 17.9749 4.02513C17.3185 3.36875 16.4283 3 15.5 3C14.5717 3 13.6815 3.36875 13.0251 4.02513C12.3687 4.6815 12 5.57174 12 6.5M6.5 16C5.57174 16 4.6815 15.6313 4.02513 14.9749C3.36875 14.3185 3 13.4283 3 12.5C3 11.5717 3.36875 10.6815 4.02513 10.0251C4.6815 9.36875 5.57174 9 6.5 9H7"
                          stroke={
                            isEqual(selectedTabIndex, 0) ? "#C2D24B" : "#A1A5B7"
                          }
                          strokeWidth="1.6"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M5 9.3V6.5C5 5.57174 5.36875 4.6815 6.02513 4.02513C6.6815 3.36875 7.57174 3 8.5 3C9.42826 3 10.3185 3.36875 10.9749 4.02513C11.6313 4.6815 12 5.57174 12 6.5V16.5"
                          stroke={
                            isEqual(selectedTabIndex, 0) ? "#C2D24B" : "#A1A5B7"
                          }
                          strokeWidth="1.6"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </g>
                      <defs>
                        <clipPath id="clip0_2000_31118">
                          <rect width="24" height="24" fill="white" />
                        </clipPath>
                      </defs>
                    </svg>
                     {formatMessage({ id: "AI Personalities Managed" })}
                  </span>
                </Tab>
                <Tab>
                  <span className={"flex items-center gap-x-2"}>
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g clipPath="url(#clip0_2000_31104)">
                        <path
                          d="M3 21V19C3 17.9391 3.42143 16.9217 4.17157 16.1716C4.92172 15.4214 5.93913 15 7 15H11C12.0609 15 13.0783 15.4214 13.8284 16.1716C14.5786 16.9217 15 17.9391 15 19V21M16 3.13C16.8604 3.3503 17.623 3.8507 18.1676 4.55231C18.7122 5.25392 19.0078 6.11683 19.0078 7.005C19.0078 7.89317 18.7122 8.75608 18.1676 9.45769C17.623 10.1593 16.8604 10.6597 16 10.88M21 21V19C20.9949 18.1172 20.6979 17.2608 20.1553 16.5644C19.6126 15.868 18.8548 15.3707 18 15.15M5 7C5 8.06087 5.42143 9.07828 6.17157 9.82843C6.92172 10.5786 7.93913 11 9 11C10.0609 11 11.0783 10.5786 11.8284 9.82843C12.5786 9.07828 13 8.06087 13 7C13 5.93913 12.5786 4.92172 11.8284 4.17157C11.0783 3.42143 10.0609 3 9 3C7.93913 3 6.92172 3.42143 6.17157 4.17157C5.42143 4.92172 5 5.93913 5 7Z"
                          stroke={
                            isEqual(selectedTabIndex, 1) ? "#C2D24B" : "#A1A5B7"
                          }
                          strokeWidth="1.4"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </g>
                      <defs>
                        <clipPath id="clip0_2000_31104">
                          <rect width="24" height="24" fill="white" />
                        </clipPath>
                      </defs>
                    </svg>
                    {formatMessage({ id: "AI Personalities Followed" })}
                  </span>
                </Tab>
              </div>
            </TabList>
            <TabPanel>
              <div className={"min-h-[80vh]"}>
                {showCreate && <CreateAiPersonality />}
                {user?.personality ? <Followings personalities={user?.personality?.rows}/> : <Empty />}
                <Pagination handleFilters={setFilters} total={user?.personality?.count || 20} filters={filters}  paginationDefault={{perPage: 30, values: [20,30,40,50]}}/>
              </div>
            </TabPanel>
            <TabPanel>
              <div className={"min-h-[80vh]"}>
                {showCreate && <CreateAiPersonality />}
                {user?.follows && user.follows.length > 0 ? <Followings personalities={user?.follows}/> : <Empty />}
              </div>
            </TabPanel>
          </Tabs>
        </div>
      </div>
    </div>
  );
};

export default Index;
