import React, { useEffect, useRef, useState } from "react";
import { Navigation, Pagination } from "swiper";
import { get, isEqual, map } from "lodash";
import { Swiper, SwiperSlide, useSwiper } from "swiper/react";
import { categories } from "../../images-category";
import { useNavigate } from "react-router";
import { useIntl } from "react-intl";
import clsx from "clsx";
import { useAuth } from "../../../../auth";
import {useThemeMode} from "../../../../../../_metronic/partials";

const SwiperController: React.FC<any> = ({ ref }) => {
  const swiper = useSwiper();
  const [isEnd, setIsEnd] = useState<boolean>(false);
  const [isBegin, setIsBegin] = useState<boolean>(true);
  
  
  
  return (
    <div className={"flex items-center gap-x-2"}>
      <button
        className={
          "chart-card w-8 h-8 rounded-full shadow-default flex justify-center items-center"
        }
        onClick={() => {
          swiper.slidePrev();
          setIsEnd(get(swiper, "isEnd"));
          setIsBegin(get(swiper, "isBeginning"));
        }}
      >
        <svg
          width="8"
          height="16"
          viewBox="0 0 8 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M0.129265 7.15925C-0.0767865 7.55965 -0.0382047 8.07392 0.257272 8.42932L6.25728 15.6457C6.6284 16.0921 7.26138 16.1205 7.67106 15.7092C8.08074 15.298 8.112 14.6027 7.74088 14.1564L2.38825 7.71864L7.6887 1.87402C8.07836 1.44435 8.07714 0.748823 7.68598 0.320513C7.29482 -0.107795 6.66183 -0.106695 6.27216 0.322974L0.296134 6.91253C0.22821 6.98742 0.172587 7.07067 0.129265 7.15925Z"
            className={clsx(!isBegin?'category-btn-icon':"category-btn-icon-disable")}
          />
        </svg>
      </button>
      <button
        className={
          "chart-card w-8 h-8 rounded-full shadow-default flex justify-center items-center"
        }
        onClick={() => {
          swiper.slideNext();
          setIsEnd(get(swiper, "isEnd"));
          setIsBegin(get(swiper, "isBeginning"));
        }}
      >
        <svg
          width="8"
          height="16"
          viewBox="0 0 8 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M7.87074 7.15925C8.07679 7.55965 8.0382 8.07392 7.74273 8.42932L1.74272 15.6457C1.3716 16.0921 0.738623 16.1205 0.32894 15.7092C-0.0807422 15.298 -0.112004 14.6027 0.259116 14.1564L5.61175 7.71864L0.311298 1.87402C-0.0783634 1.44435 -0.0771435 0.748823 0.314018 0.320514C0.70518 -0.107795 1.33817 -0.106695 1.72784 0.322974L7.70387 6.91254C7.77179 6.98742 7.82741 7.07067 7.87074 7.15925Z"
            className={clsx(!isEnd?'category-btn-icon':"category-btn-icon-disable")}

          />
        </svg>
      </button>
    </div>
  );
};
const Index = () => {
  
  const navigate = useNavigate()
  const { formatMessage } = useIntl()
  const {ipStackCountry} = useAuth()
    const { mode } = useThemeMode();

  return (
    <div className={"my-20 xl:my-32"}>
      <Swiper
        modules={[Navigation]}
        breakpoints={{
          240: {
            spaceBetween: 12,
          },
          540: {
            spaceBetween: 12,
          },
          1024: {
            spaceBetween: 24,
            
          },
        }}
        slidesPerView={"auto"}
        className="!flex !flex-col-reverse"
        // wrapperClass={"flex-col-reverse"}
        // loop={true}
        navigation={false}
      >
        <div className={"items-end justify-between mb-6 hidden md:flex"}>
          <h3 className={"text-[32px] leading-[44px] font-semibold primary-text"}>
             {formatMessage({ id: "Categories" })}
          </h3>
          <SwiperController />
        </div>
        {map(categories, (el, i) => (
          <SwiperSlide
            key={get(el, "name")}
            className={"!w-[80px] md:!w-[120px] !h-[100px] md:!h-[150px] cursor-pointer"}
            onClick={(e) => navigate(`/marketplace/explore?category=${el.name}&country=${ipStackCountry?.countryId}`)}
          >
            <div
              className={
                "mb-2 widgets-customize border border-main rounded-[8px] shadow-default p-2.5 hover:!border-[#C2D24B] transition-all duration-300"
              }
            >
              <img src={isEqual(mode,'light')?get(el, "lightIcon"): get(el, "icon")} className={"w-full"} alt={"Icon"} />
            </div>
            <h4
              className={
                "text-[10px] md:text-[14px] leading-5 primary-text font-semibold"
              }
            >
             {formatMessage({ id: get(el, "name") })} {}
            </h4>
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
};

export default Index;
