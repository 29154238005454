import clsx from "clsx";
import React, { useEffect, useState } from "react";
import { get, map } from "lodash";
import { Field, FieldArray, Form, Formik } from "formik";
import { useIntl } from "react-intl";

const FilterItem: React.FC<any> = ({ item, handleChange, initFilters,trackUserAction }) => {
  const { formatMessage } = useIntl();
  const [openAccordion, setOpenAccordion] = useState(false);
  const [values, setValues] = useState<any>();
  const [count, setCount] = useState<number>(0);
  const [checkboxed, setCheckboxed] = useState(item);

  const initValues = {
    [item.name]: initFilters?.[item.name] ?? [],
  };

  useEffect(() => {
    setCount(values?.[item.name].length);
    handleChange(item.name, values?.[item.name]);
  }, [JSON.stringify(values)]);

  const searchItems = (e: any) => {
    e.preventDefault();
    item = {
      ...item,
      filters: item.filters.filter((el: any) =>
        el.label.toLowerCase().includes(e.target.value.toLowerCase())
      ),
    };
    setCheckboxed(item);
  };

  return (
    <Formik
      initialValues={initValues}
      onSubmit={console.log}
      enableReinitialize
      // onSubmit={onSubmit}
      // validationSchema={basicDetailsValidation}
      // validateOnMount
    >
      {(formik) => {
        setValues(formik.values);
        return (
          <Form>
            <div className={"h-full px-2 !pl-4 primary-text"}>
              <div className={"pr-2 border-b border-bottom-chat"}>
                <h3
                  className={
                    "text-[14px] p-[20px_8px_12px] leading-[20px] font-semibold flex items-center justify-between"
                  }
                  onClick={() => setOpenAccordion((prevState) => !prevState)}
                >
                  {formatMessage({ id: get(item, "categoryName") })} (
                  {formatMessage({ id: `${count == 0 ? "All" : count}` })})
                  <button>
                    <svg
                      className={clsx(
                        "transition-all duration-300",
                        !openAccordion && "rotate-180"
                      )}
                      width="14"
                      height="8"
                      viewBox="0 0 14 8"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M1 7L7 1L13 7"
                        className={"icon-stroke-primary"}
                        strokeOpacity="0.65"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </button>
                </h3>
                <div className="pb-[8px] overflow-hidden">
                  <div
                    className={clsx(
                      "transition-all duration-300",
                      openAccordion
                        ? "max-h-[250px] pr-[16px] py-2 overflow-y-auto overflow-x-hidden custom-scroll "
                        : "h-0 invisible"
                    )}
                  >
                    {(item.name == "industry" ||
                      item.name == "category" ||
                      item.name == "country") && (
                      <input
                        className={clsx(
                          "form-control w-[90%] mb-5 border rounded ml-1 p-2",
                          !openAccordion && "hidden"
                        )}
                        placeholder={formatMessage({ id: "Search Here" })}
                        onKeyUp={searchItems}
                      />
                    )}
                    <div
                      className={clsx(
                        "flex flex-col ",
                        !openAccordion && "hidden"
                      )}
                    >
                      {map(
                        get(checkboxed, "filters", []),
                        (filter: any, index: number) => (
                          <div
                            key={filter.value}
                            className={
                              "flex items-center p-[8px] gap-x-2 event-filter"
                            }
                          >
                            <label
                              className={
                                "h-[24px] w-full flex flex-row items-center gap-[4px]  "
                              }
                              onClick={(e) => trackUserAction.current = true}
                            >
                              <Field
                                type={"checkbox"}
                                name={`${get(item, "name")}`}
                                className={
                                  "form-check-input check-input-black h-[24px] w-[24px] "
                                }
                                value={filter.value}
                                checked={get(formik.values, item.name, "").some(
                                  (el: any) => {
                                    return el == filter.value;
                                  }
                                )}
                                formik={formik}
                                // onChange={handleChange}
                                // onBlur={handleBlur}
                                // onClick={(e:any) => handleChange(item.name,formik.values[item.name])}
                              />
                              {get(filter, "icon") && (
                                <img
                                  src={get(filter, "icon")}
                                  className={"w-6 h-6 rounded-[2px]"}
                                  alt={"flag"}
                                />
                              )}
                              <span className="ml-2 text-[13px] secondary-text">
                                {formatMessage({ id: get(filter, "label") })}
                              </span>
                            </label>
                          </div>
                        )
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Form>
        );
      }}
    </Formik>
  );
};

export default FilterItem;
