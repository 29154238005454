import { useCallback, useEffect } from "react";

export default function useInfiniteScroll(elementRef:any, offset:number, triggerFunction:(...args:any[])=>any, isTop?:boolean){
  isTop = isTop || false
  function checkOffset(){
        const { scrollTop, scrollHeight, clientHeight } = elementRef.current;
    if (isTop) {
      if (scrollTop <= offset) {
        triggerFunction()
      }
    } else {
      if (scrollTop + clientHeight + offset >= scrollHeight) {
        triggerFunction()
        }
      }
    }
    let timeoutRef: NodeJS.Timeout;
    const debounceScroll = useCallback((e: any) => {
        clearTimeout(timeoutRef);
        timeoutRef = setTimeout(() => checkOffset(), 200);
      }, []);
    useEffect(()=>{
        elementRef?.current?.addEventListener('scroll',debounceScroll)
        return ()=>{
            elementRef?.current?.removeEventListener('scroll',debounceScroll)
        }
    },[elementRef])

   function removeListener(){
     elementRef?.current?.removeEventListener('scroll',debounceScroll)
   }
  
   return {removeListener}
}