import React, { useEffect, useRef, useState } from "react";
import ChatAvatar, {
  SpeechServiceProvider,
} from "../modules/ai-personality/components/chat/chat-avatar";
import { getCdnUrl } from "../core/_util";
import userDefault from "../assets/images/profile/user-default.png";
import Switch from "react-switch";
import clsx from "clsx";
import Lottie from "lottie-react";
import TextareaAutosize from "react-textarea-autosize";
import CustomToolTip from "../modules/widgets/components/UI/CustomToolTip";
import TypingAnimationJson from "../assets/lotties/typing-animation.json";
import TypingAnimationLightJson from "../assets/lotties/typing-animation-light.json";
import ArrowAnimationJson from "../assets/lotties/arrow-animation.json";
import { CHAT_INPUT_LENGTH } from "../core/_constants";
import useSpeech2Text from "../hooks/useSpeechRecognition";
import { useDateFormat } from "../hooks/useDateFormat";
import { addWidgetChat } from "./core/_request";
import { useAuth } from "../modules/auth";
import { useSearchParams } from "react-router-dom";
import Empty from "../components/empty/Empty";
import { Spinner } from "../modules/widgets/components/General/Spinner";
import HyperRealisticAvatar from "../modules/marketplace-public/components/ai-profile/chat-body/hyper-realistic";
import Menu from "../components/menu/menu";
import { BasicButton } from "../modules/widgets/components/UI/BasicButton";
import { CustomButton } from "../modules/widgets/components/UI/CustomButton";
import { Modal } from "react-bootstrap";
import { Formik } from "formik";

export default function ChatWidget({ widget, loading, fault, inactive }: any) {
  const [hasBackground, setBackground] = useState(false);
  const [mute, setMute] = useState(false);
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [startRecord, setStartRecord] = useState(false);
  const [isResponding, setIsResponding] = useState<boolean>(false);
  const [isDisabled, setIsDisabled] = useState<boolean>(true);
  // const [mute, setMute] = useState(true);
  const [message, setMessage] = useState<string>("");
  const [messages, setMessages] = useState<any[]>([]);
  const inputRef = useRef<HTMLTextAreaElement | null>(null);
  const containerRef = useRef<HTMLDivElement | null>(null);
  const [showEnterMessageAnimation, setShowEnterMessageAnimation] =
    useState(false);
  const [chatLoading, setChatLoading] = useState<boolean>(true);
  const [showQuotaError, setShowQuotaError] = useState<boolean>(false);
  const { personalityInView,isAvatarEnabled,setPauseListening } = useAuth();
  const [deleteModalOpen, setDeleteModalOpen] = useState<boolean>(false);
  const widgetConfig = {
    darkMode: widget?.config?.darkMode || false,
    hideBranding: widget?.config?.hideBranding || false,
    hideMic: widget?.config?.hideMic || false,
    hideSpeaker: widget?.config?.hideSpeaker || false,
    primaryColor: widget?.config?.primaryColor || "#c2d24b",
    hideAvatar: widget?.config?.hideAvatar || false,
  };
  const STORAGE_KEY = "KAI_WIDGET_CHATS" + "-" + widget?.widgetId;

  function getLocalMessages() {
    let item = localStorage.getItem(STORAGE_KEY);
    if (!item) {
      return [];
    }
    let items = [];
    try {
      items = JSON.parse(item);
    } catch (err) {
      items = [];
    }
    return items;
  }

  useEffect(() => {
    setMessages(getLocalMessages());
    setChatLoading(false);
  }, []);

  useEffect(() => {
    setIsDisabled(!message.trim() ? true : false);
  }, [message]);

  function prepareMessageArray(message: string) {
    let CONTEXT_SIZE = widget?.contextSize || 0;
    const values = [];
    if (CONTEXT_SIZE) {
      CONTEXT_SIZE = CONTEXT_SIZE > 5 ? 5 : CONTEXT_SIZE;
      const lastMsgs = messages.slice(-5);
      lastMsgs.forEach(({ prompt, response }) => {
        values.push({ role: "user", content: prompt });
        values.push({ role: "character", content: response });
      });
    }
    values.push({
      role: "user",
      content: message,
    });
    return values;
  }

  const initialMessage = {
    id: "" + Math.random() * 100000,
    createdAt: new Date().toISOString(),
    prompt: "",
    response: "",
    liked: 0,
    customParams: null,
  };

  const { startListening, stopListening, listening, voiceText, isSupported } =
    useSpeech2Text(sendMessage,setMessage);
  // const [tempVoiceMix, setTempVoiceMix] = useState<string | undefined>(
  //   undefined
  // );
  const { getDateValue } = useDateFormat();

  const { setPlayTextAvatar, setAvatarMuted, avatarMuted } = useAuth();
  const [is3dAvatar, setIs3dAvatar] = useState<boolean>(true);
  const has3d = personalityInView?.personalityJson?.avatar?.enabled || false;
  const hasHyper =
    personalityInView?.personalityJson?.hyper_realistic_avatar?.enabled ||
    false;
  const handle3DAvatarToggle = () => {
    if (!is3dAvatar) {
      setIs3dAvatar(true);
    }
  };

  const handleHyperRealisticToggle = () => {
    if (is3dAvatar) {
      setIs3dAvatar(false);
    }
  };
  // useEffect(() => {
  //   if (listening) {
  //     inputRef?.current?.focus();
  //     setTempVoiceMix(message + voiceText);
  //   } else {
  //     setTempVoiceMix(undefined);
  //     if (voiceText) {
  //       setMessage(message + voiceText);
  //     }
  //   }
  // }, [voiceText, listening]);

  const [defaultMessage, setDefaultMessage] = useState<any>(initialMessage);
  const isDark = widgetConfig.darkMode;
  const personalityImage = personalityInView?.image
    ? getCdnUrl(personalityInView.image)
    : userDefault;
  let userImage: string = userDefault;

  useEffect(() => {
    setShowEnterMessageAnimation(!messages.length);
  }, [messages]);

  useEffect(() => {
    if (defaultMessage.prompt && defaultMessage.response) {
      setDefaultMessage(initialMessage);
      setMessages((messages) => [...messages, defaultMessage]);
      setTimeout(() => scrollToBottom(), 500);
    }
  }, [defaultMessage]);

  useEffect(() => {
    if (messages.length) {
      if (messages.length > 100) {
        let newMessages = messages.slice(1);
        localStorage.setItem(STORAGE_KEY, JSON.stringify(newMessages));
        return;
      }
      localStorage.setItem(STORAGE_KEY, JSON.stringify(messages));
    }
  }, [messages]);

  function autoFocusInput() {
    setTimeout(() => inputRef?.current?.focus(), 0);
  }

  async function sendMessage() {
    setIsResponding(true);
    setDefaultMessage((defaultMessage: any) => {
      return { ...defaultMessage, prompt: message };
    });
    setMessage("");
    setTimeout(() => scrollToBottom(), 500);
    const { data } = await addWidgetChat(
      widget?.widgetId,
      widget?.personalityId,
      prepareMessageArray(message)
    );
    if(typeof data == 'string' && data.includes("This AI Personality is temporarily unavailable")){
      setShowQuotaError(true)
    }else {
      setShowQuotaError(false)
    }
    if (data?.choices) {
      let resp = data.choices[0].message;
      setDefaultMessage((defaultMessage: any) => {
        return {
          ...defaultMessage,
          response: resp.content,
          id: data.timestamp * 1000,
          createdAt: new Date(data.timestamp * 1000).toISOString(),
          customParams: { emotionEmoji: resp.emotion_in_emoji },
        };
      });
      setPlayTextAvatar(resp.content);
      if(!isAvatarEnabled){ setPauseListening(false) }
      setMessage("");
      setIsResponding(false);
      autoFocusInput();
    } else {
      setDefaultMessage(defaultMessage);
      setMessage("");
      setIsResponding(false);
      autoFocusInput();
    }
  }

  const onEnterPress = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (e.keyCode === 13 && e.shiftKey === false) {
      e.preventDefault();
      sendMessage();
    }
  };

  function scrollToBottom() {
    if (containerRef.current) {
      containerRef.current.scrollTop = containerRef.current.scrollHeight;
    }
  }

  return (
    <div className="relative overflow-hidden h-full">
      {loading && <Spinner />}
      {fault ? (
        <Empty
          heading="Nothing to show here"
          content="Content you are looking for is not available"
        />
      ) : (
        <div
          className={clsx(
            isDark ? "bg-[#21233A]  " : "bg-[#ffffff] ",
            "rounded-[3px] relative flex flex-col h-full"
          )}
          style={{
            boxShadow:
              "1.2913739681243896px 1.2913739681243896px 15.496487617492676px 2.5827479362487793px rgba(0, 0, 0, 0.25)",
          }}
        >
          <div
            className={clsx(
              isDark ? "bg-[#21233A]  " : "bg-[#ffffff] ",
              "preview-chat-head  p-[12px_18px] flex flex-row   md:items-center justify-between"
            )}
          >
            <div className="flex flex-row items-center">
              <div className="bg-[#2E2F45] rounded-full overflow-hidden">
                <img
                  src={personalityImage}
                  className="w-[25px] h-[25px] object-cover"
                  alt=""
                ></img>
              </div>
              <h4
                className={clsx(
                  isDark ? "text-[#ffffff]  " : "text-[#383940] ",
                  "text-[16px] text-truncate leading-[22px] font-bold ml-[8px]"
                )}
              >
                {widget?.title}
              </h4>
            </div>
            <div className="flex gap-x-4">
            {!widgetConfig.hideAvatar && !inactive && (has3d || hasHyper) && (
              <div className="flex align-items-center">
                <Switch
                  onChange={(value) => {
                    setBackground(value);
                  }}
                  name={"verification"}
                  handleDiameter={22}
                  borderRadius={22}
                  activeBoxShadow={"null"}
                  checked={hasBackground}
                  checkedIcon={false}
                  uncheckedIcon={false}
                  offHandleColor={"#fff"}
                  width={45}
                  height={30}
                  onColor={widgetConfig.primaryColor || "#C2D24B"}
                  offColor={"#474761"}
                />
                <span
                  className={clsx(
                    isDark ? "text-[#FFFFFFA6]  " : "text-[#383940A6] ",
                    "text-[12px] leading-[16px] ml-[6px]"
                  )}
                >
                  Avatar
                </span>
              </div>
            )}
            <div>
                  <Modal
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                    show={deleteModalOpen}
                    onHide={() => setDeleteModalOpen(false)}
                  >
                    <Modal.Header closeButton>
                      <Modal.Title
                        className="modal-title text-[20px] leading-[28px] font-weight-600 text-[#FFFFFFCC] "
                        id="contained-modal-title-vcenter"
                      >
                        Restart Conversation
                      </Modal.Title>
                    </Modal.Header>
                    <Modal.Body className=" p-[16px] md:p-[24px]">
                      <p className="text-[#FFFFFFCC] text-[16px]">
                        Restarting the conversation will clear your chat history. Are you sure to do that?
                      </p>
                      <Formik
                        initialValues={{}}
                        onSubmit={async (values, { setSubmitting }) => {
                          setSubmitting(true);
                          localStorage.setItem(STORAGE_KEY, JSON.stringify([]));
                          setMessages([]);
                          setDeleteModalOpen(false);
                          setSubmitting(false);
                        }}
                      >
                        {(formik) => (
                          <div className="flex gap-x-3 justify-end items-center">
                            <BasicButton
                              buttonText={"No"}
                              border="none"
                              color="#C2D24B1A"
                              textColor="#C2D24B"
                              customClass={"px-[24px] mt-8 !w-fit"}
                              height="44px"
                              onClick={() => {
                                setDeleteModalOpen(false);
                              }}
                            />
                            <CustomButton
                              customClass="mt-8 !w-fit"
                              isSubmitting={formik.isSubmitting}
                              isValid={true}
                              buttonText={"Yes"}
                              loading={formik.isSubmitting}
                              height={44}
                              widthLoading={1}
                              width={1}
                              onSubmit={formik.handleSubmit}
                            />
                          </div>
                        )}
                      </Formik>
                    </Modal.Body>
                  </Modal>
        </div>
            </div>
          </div>
          {inactive ? (
            <div className="bg-[#11121C] h-full">
              <Empty
                heading="Disabled"
                content="This Chat Widget has been disabled. Please contact the Chat Widget creator."
              />
            </div>
          ) : (
            <>
              {hasBackground ? (
                <div
                  className={clsx(
                    "chat-avatar-box relative z-10 pt-[14px] h-[34vh] pb-4",
                    isDark ? "bg-[#11121C]  " : "bg-[#ffffff] "
                  )}
                >
                  {has3d && hasHyper ? (
                    is3dAvatar ? (
                      <div
                        className="relative mb-[14px]"
                        style={{ height: "calc(100% - 40px)" }}
                      >
                        <ChatAvatar />
                        <div className="absolute top-0 left-0 w-full h-full z-10"></div>
                      </div>
                    ) : (
                      <HyperRealisticAvatar
                        loaderCss={{
                          borderColor: `${
                            widgetConfig.primaryColor || "#C2D24B"
                          }`,
                          borderTopColor: "transparent",
                        }}
                      />
                    )
                  ) : (
                    <>
                      {has3d ? (
                        <div
                          className="relative mb-[14px]"
                          style={{ height: "calc(100% - 40px)" }}
                        >
                          <ChatAvatar />
                          <div className="absolute top-0 left-0 w-full h-full z-10"></div>
                        </div>
                      ) : hasHyper ? (
                        <HyperRealisticAvatar
                          loaderCss={{
                            borderColor: `${
                              widgetConfig.primaryColor || "#C2D24B"
                            }`,
                            borderTopColor: "transparent",
                          }}
                        />
                      ) : (
                        <></>
                      )}
                    </>
                  )}
                  {has3d && hasHyper && (
                    <div className="flex flex-row items-center justify-center gap-0 cursor-pointer">
                      <div
                        style={{backgroundColor: is3dAvatar ? widgetConfig.primaryColor :"#2E2F45" }}
                        className={`avatar-tog text-[10px] leading-[18px] rounded-l-[8px] p-[4px] w-[90px] text-center ${
                          is3dAvatar
                            ? `text-[#000000]`
                            : "text-[#FFFFFFCC]"
                        }`}
                        onClick={handle3DAvatarToggle}
                      >
                        3D Avatar
                      </div>
                      <div
                        style={{backgroundColor: !is3dAvatar ? widgetConfig.primaryColor :"#2E2F45" }}
                        className={`avatar-tog text-[10px] leading-[18px] rounded-r-[8px] p-[4px] w-[90px] text-center ${
                          is3dAvatar
                            ? "text-[#FFFFFFCC]"
                            : `text-[#000000]`
                        }`}
                        onClick={handleHyperRealisticToggle}
                      >
                        Hyper-realistic
                      </div>
                    </div>
                  )}
                </div>
              ) : null}
              <div
                ref={containerRef}
                className={clsx(
                  isDark
                    ? "bg-[#11121C]  "
                    : "bg-[#ffffff] !border-[#00000014] border-solid border !border-l-0 !border-r-0 ",
                  "preview-chat-body w-full custom-chat-scroll overflow-auto flex-grow-1 flex flex-col justify-between relative"
                )}
              >
                {/* Sample questions div start*/}
                <div className="flex px-4 py-4 flex-col flex-1 relative">
                  {widget?.sampleQuestions?.some((item: string) => !!item) &&
                    !messages.length &&
                    !chatLoading && !defaultMessage.prompt && (
                      <div className="flex w-full h-full bg-transparent justify-content-center align-items-center">
                        <div className="max-w-[280px] md:max-w-[380px]">
                          <h4
                            className={clsx(
                              isDark
                                ? "text-[#FFFFFFCC]  "
                                : "text-[#383940CC] ",
                              "d-flex flex-row align-items-center justify-content-center text-[16px] leading-[24px] font-weight-600 mb-[12px]"
                            )}
                          >
                            <svg
                              className="me-[12px]"
                              width="32"
                              height="32"
                              viewBox="0 0 32 32"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <g clipPath="url(#clip0_1993_27971)">
                                <path
                                  d="M17.3332 4V13.3333H25.3332L14.6665 28V18.6667H6.6665L17.3332 4Z"
                                  stroke="white"
                                  strokeWidth="2"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                              </g>
                              <defs>
                                <clipPath id="clip0_1993_27971">
                                  <rect width="32" height="32" fill="white" />
                                </clipPath>
                              </defs>
                            </svg>
                            Sample questions you can ask me
                          </h4>

                          {widget?.sampleQuestions.map((el: string) =>
                            el ? (
                              <p
                                key={el}
                                className={clsx(
                                  isDark
                                    ? "bg-[#2E2F45] text-[#FFFFFFCC] "
                                    : "bg-[#F9F9F9] text-[#383940CC] shadow-sm",
                                  "sample-question cursor-pointer text-[11px] p-[9px_18px] leading-[17px] font-normal rounded-[4px] mb-[8px]"
                                )}
                                onClick={() => setMessage(el)}
                              >
                                {el}
                              </p>
                            ) : null
                          )}
                        </div>
                      </div>
                    )}
                  {messages.map(
                    (
                      {
                        id,
                        prompt,
                        response,
                        error,
                        liked,
                        createdAt,
                        customParams,
                        sessionAction,
                      },
                      index
                    ) => {
                      const templateAttr = {};
                      // if (message.true) {
                      //   Object.defineProperty(templateAttr, "data-kt-element", {
                      //     value: `template-${message.type}`,
                      //   });
                      // }
                      const contentClass = `"d-flex mb-10`;
                      return (
                        <>
                          {(index == 0 ||
                            new Date(createdAt).getDate() !=
                              new Date(
                                messages[index - 1].createdAt
                              ).getDate()) && (
                            <div className="py-4 timeline">
                              {/* <span
                              className={clsx(
                                isDark
                                  ? "bg-[#2E2F45] "
                                  : "!bg-[#0000001A]"
                              )}
                            ></span> */}
                              <p
                                className={clsx(
                                  isDark
                                    ? "text-[#FFFFFFCC] "
                                    : "!text-[#00000080]",
                                  "mx-auto"
                                )}
                              >
                                {getDateValue(createdAt)}
                              </p>
                              {/* <span
                              className={clsx(
                                isDark
                                  ? "bg-[#2E2F45] "
                                  : "!bg-[#0000001A]"
                              )}
                            ></span> */}
                            </div>
                          )}
                          <>
                            <div
                              key={`${id}-prompt`}
                              className={clsx(
                                "d-flex",
                                contentClass,
                                "mb-10",
                                "justify-content-end",

                                {
                                  // "d-none": message.template,
                                }
                              )}
                              {...templateAttr}
                            >
                              <div
                                className={
                                  "flex flex-col md:flex-row md:items-start gap-x-8 gap-y-2"
                                }
                              >
                                <div
                                  className={clsx(
                                    isDark ? "bg-[#171825] " : "bg-[#E6EAF0]",
                                    "flex items-start gap-x-2 rounded px-2 py-3 relative"
                                  )}
                                >
                                  <div
                                    className={"flex min-h-[48px] items-center"}
                                  >
                                    <div
                                      className={clsx(
                                        isDark
                                          ? "secondary-text "
                                          : "text-[#000000CC]",
                                        " text-[14px] overflow-hidden text-break leading-[22px] font-normal max-w-2xl float-right"
                                      )}
                                      data-kt-element="message-text"
                                      dangerouslySetInnerHTML={{
                                        __html: prompt,
                                      }}
                                    ></div>
                                  </div>
                                  <div className="flex items-start">
                                    <>
                                      <div className="w-12 h-12 symbol symbol-35px symbol-circle">
                                        <img alt="Pic" src={userImage} />
                                      </div>
                                    </>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div
                              key={`${id}-response`}
                              className={clsx(
                                "d-flex",
                                contentClass,
                                "mb-10",
                                "justify-content-start",

                                {
                                  // "d-none": message.template,
                                }
                              )}
                            >
                              <div
                                className={
                                  "flex flex-col md:flex-row md:items-start gap-x-8 gap-y-2"
                                }
                              >
                                <div
                                  className={clsx(
                                    isDark ? "bg-[#2E2F45] " : "bg-[#F2F2F2]",
                                    "flex items-start gap-x-2 rounded px-2 py-3 relative"
                                  )}
                                >
                                  {customParams?.responseEmoji && (
                                    <span
                                      className={
                                        "text-[20px] absolute right-1 md:-right-3 -bottom-4"
                                      }
                                    >
                                      {customParams.responseEmoji}
                                    </span>
                                  )}
                                  <div className="flex items-center">
                                    <>
                                      <div className="w-12 h-12 symbol symbol-35px symbol-circle">
                                        <img alt="Pic" src={personalityImage} />
                                      </div>
                                    </>
                                  </div>
                                  <div
                                    className={clsx(
                                      isDark
                                        ? "secondary-text "
                                        : "text-[#000000CC]",
                                      " text-[14px] overflow-hidden text-break leading-[22px] font-normal max-w-2xl float-right"
                                    )}
                                    data-kt-element="message-text"
                                    dangerouslySetInnerHTML={{
                                      __html: response,
                                    }}
                                  ></div>
                                </div>
                              </div>
                            </div>
                          </>
                        </>
                      );
                    }
                  )}
                  {defaultMessage.prompt && (
                    <>
                      <div
                        key={`${defaultMessage.id}-prompt`}
                        className={clsx(
                          "d-flex",
                          `"d-flex" mb-10`,
                          "mb-10",
                          "justify-content-end",
                          {
                            // "d-none": message.template,
                          }
                        )}
                      >
                        <div
                          className={
                            "flex flex-col md:flex-row md:items-start gap-x-8 gap-y-2"
                          }
                        >
                          <div
                            className={clsx(
                              "flex items-start gap-x-2 rounded px-2 py-3 relative",
                              isDark ? "!bg-[#171825] " : "!bg-[#E6EAF0]"
                            )}
                          >
                            <div
                              className={clsx(
                                "overflow-hidden text-break text-[14px] leading-[22px] font-normal  max-w-2xl float-right",
                                isDark
                                  ? "!secondary-text "
                                  : "!text-[#000000CC]"
                              )}
                              data-kt-element="message-text"
                              dangerouslySetInnerHTML={{
                                __html: defaultMessage.prompt,
                              }}
                            ></div>
                            <div className="flex items-center">
                              <>
                                <div className="w-12 h-12 symbol symbol-35px symbol-circle">
                                  <img alt="Pic" src={userImage} />
                                </div>
                              </>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        key={`${defaultMessage.id}-response`}
                        className={clsx(
                          "d-flex",
                          `d-flex mb-10`,
                          "mb-10",
                          "justify-content-start",
                          {
                            // "d-none": message.template,
                          }
                        )}
                      >
                        <div
                          className={
                            "flex flex-col md:flex-row md:items-start gap-x-8 gap-y-2"
                          }
                        >
                          <div
                            className={clsx(
                              "flex items-start gap-x-2 rounded px-2 py-3 relative"
                            )}
                          >
                            <div className="flex items-center">
                              <>
                                <div className="w-12 h-12 symbol symbol-35px symbol-circle">
                                  <img alt="Pic" src={personalityImage} />
                                </div>
                              </>
                            </div>
                            {isResponding ? (
                              <Lottie
                                animationData={
                                  isDark
                                    ? TypingAnimationJson
                                    : TypingAnimationLightJson
                                }
                                style={{
                                  width: 85,
                                  height: 45,
                                  borderRadius: 25,
                                  borderTopLeftRadius: 0,
                                  overflow: "hidden",
                                }}
                              />
                            ) : (
                              <>
                                <div
                                  className={clsx(
                                    "secondary-text text-[14px] overflow-hidden text-break leading-[22px] font-normal max-w-2xl float-right"
                                    // `text-${message.type === 'in' ? 'start' : 'end'}`
                                  )}
                                  data-kt-element="message-text"
                                  dangerouslySetInnerHTML={{
                                    __html: defaultMessage.response,
                                  }}
                                ></div>
                                <p
                                  style={{ marginTop: -20, color: "#ff2424" }}
                                  className="text-sm"
                                >
                                  {defaultMessage.error}
                                </p>
                              </>
                            )}
                          </div>
                        </div>
                      </div>
                    </>
                  )}
                    {showQuotaError && <p
                    className={"mx-auto text-center py-2 px-2 text-sm my-4 max-w-[80%] bg-[#000000bb] text-[#D24B4B]"}
                  >
                 This AI Personality is temporarily unavailable. Please contact the owner or manager of this AI Personality`,
                  </p>}
                </div>
                {/* Sample questions div end */}
                <div className={"sticky bottom-1 "}>
                  <div className="relative w-full">
                    <div className="flex z-20 flex-row relative md:items-end px-4">
                      <div className={"relative flex-grow"}>
                        <TextareaAutosize
                          onFocus={() => setShowEnterMessageAnimation(false)}
                          maxLength={CHAT_INPUT_LENGTH}
                          ref={inputRef}
                          // style={{ height: 52, background: "#2E2F45" }}
                          className={clsx(
                            isDark
                              ? "!bg-[#2E2F45] !text-[#FFFFFFA6] "
                              : "!bg-[#ffffff] !text-[#383940A6] border border-solid !border-[#3839401A]",
                            "form-control form-control-flush h-[52px]  rounded pr-10"
                          )}
                          // className={"form-control search-input-table rounded pr-10"}
                          data-kt-element="input"
                          placeholder={
                            isResponding
                              ? "Waiting for reply"
                              : "Type a message"
                          }
                          value={message}
                          disabled={isResponding}
                          onChange={(e) =>
                            setMessage(
                              e.target.value.length <= CHAT_INPUT_LENGTH
                                ? e.target.value
                                : e.target.value.slice(0, CHAT_INPUT_LENGTH)
                            )
                          }
                          onKeyDown={onEnterPress}
                        />
                        <button
                          className={"absolute right-4 bottom-3"}
                          disabled={isResponding || isDisabled}
                          onClick={
                            isResponding || isDisabled
                              ? () => {}
                              : () => {
                                  sendMessage();
                                }
                          }
                        >
                          <svg
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <g clipPath="url(#clip0_344_24401)">
                              <path
                                d="M9.99958 14L20.9996 3M9.99958 14L13.4996 21C13.5435 21.0957 13.6139 21.1769 13.7025 21.2338C13.7912 21.2906 13.8943 21.3209 13.9996 21.3209C14.1049 21.3209 14.208 21.2906 14.2966 21.2338C14.3853 21.1769 14.4557 21.0957 14.4996 21L20.9996 3M9.99958 14L2.99958 10.5C2.90384 10.4561 2.82271 10.3857 2.76583 10.2971C2.70895 10.2084 2.67871 10.1053 2.67871 10C2.67871 9.89468 2.70895 9.79158 2.76583 9.70295C2.82271 9.61431 2.90384 9.54387 2.99958 9.5L20.9996 3"
                                stroke={widgetConfig.primaryColor || "#C2D24B"}
                                strokeWidth="2"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                            </g>
                            <defs>
                              <clipPath id="clip0_344_24401">
                                <rect width="24" height="24" fill="white" />
                              </clipPath>
                            </defs>
                          </svg>
                        </button>
                        <style>
                          {`

                    .enter-message-animation-widget{
                     ${
                       isDark
                         ? `background: linear-gradient(0deg, rgba(#11121c, 0) 0%, #11121c 100%,);`
                         : `background: linear-gradient(0deg, rgba(#ffffff, 0) 0%, #ffffff 100%,);`
                     }
                    }
                    .enter-message-animation-widget-text{
                      ${isDark ? `color:#ffffff` : `color:#11121c`}
                    }
                    `}
                        </style>
                        {showEnterMessageAnimation && (
                          <div className="absolute top-[-83px] left-0 p-[10px_10px_0px_10px] rounded-lg enter-message-animation-widget">
                            <p className="mb-0 text-[12px] leading-[18px] enter-message-animation-widget-text">
                              Enter your message here
                            </p>
                            <Lottie
                              animationData={ArrowAnimationJson}
                              style={{
                                width: 100,
                                height: 55,
                                borderRadius: 25,
                                borderTopLeftRadius: 0,
                                overflow: "hidden",
                              }}
                            />
                          </div>
                        )}
                      </div>
                      <div className={"relative flex gap-1 p-3 shrink-0"}>
                        {!widgetConfig.hideSpeaker && (
                          <CustomToolTip
                            tooltipText={avatarMuted ? "Unmute" : "Mute"}
                          >
                            {!avatarMuted ? (
                              <button
                                className={clsx(
                                  isDark
                                    ? "hover:bg-[#2E2F45] "
                                    : "hover:bg-[#2e2f4537]",
                                  "transition-all duration-300  h-[24px] w-[24px] flex items-center justify-center rounded-full"
                                )}
                                onClick={() => setAvatarMuted(true)}
                              >
                                <svg
                                  width="18"
                                  height="18"
                                  viewBox="0 0 18 18"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="M11.25 6.00004C11.7157 6.34934 12.0937 6.80228 12.3541 7.32299C12.6145 7.8437 12.75 8.41787 12.75 9.00004C12.75 9.58221 12.6145 10.1564 12.3541 10.6771C12.0937 11.1978 11.7157 11.6507 11.25 12M13.275 3.75004C14.058 4.38278 14.6895 5.18263 15.1234 6.09103C15.5572 6.99943 15.7824 7.99336 15.7824 9.00004C15.7824 10.0067 15.5572 11.0007 15.1234 11.909C14.6895 12.8174 14.058 13.6173 13.275 14.25M4.5 11.25H3C2.80109 11.25 2.61032 11.171 2.46967 11.0304C2.32902 10.8897 2.25 10.699 2.25 10.5V7.50004C2.25 7.30113 2.32902 7.11036 2.46967 6.96971C2.61032 6.82906 2.80109 6.75004 3 6.75004H4.5L7.125 3.37504C7.19055 3.24771 7.29949 3.14803 7.43212 3.09401C7.56475 3.03999 7.71233 3.03519 7.84819 3.08047C7.98405 3.12576 8.09923 3.21815 8.17292 3.34095C8.24662 3.46374 8.27395 3.60885 8.25 3.75004V14.25C8.27395 14.3912 8.24662 14.5363 8.17292 14.6591C8.09923 14.7819 7.98405 14.8743 7.84819 14.9196C7.71233 14.9649 7.56475 14.9601 7.43212 14.9061C7.29949 14.852 7.19055 14.7524 7.125 14.625L4.5 11.25Z"
                                    stroke={isDark ? "white" : "#383940"}
                                    strokeOpacity="0.8"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                  />
                                </svg>
                              </button>
                            ) : (
                              <button
                                className={clsx(
                                  isDark
                                    ? "hover:bg-[#2E2F45] "
                                    : "hover:bg-[#2e2f4537]",
                                  "transition-all duration-300  h-[24px] w-[24px] flex items-center justify-center rounded-full"
                                )}
                                onClick={() => setAvatarMuted(false)}
                              >
                                <svg
                                  width="18"
                                  height="18"
                                  viewBox="0 0 20 20"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <g clipPath="url(#clip0_497_37686)">
                                    <path
                                      d="M12.5 6.66667C13.1236 7.13437 13.6035 7.76761 13.8852 8.49445C14.1668 9.22128 14.2389 10.0126 14.0933 10.7783M12.9458 12.9467C12.8065 13.0859 12.6576 13.2151 12.5 13.3333M14.75 4.16667C16.0875 5.24753 17.0129 6.75518 17.3712 8.43704C17.7296 10.1189 17.4991 11.8728 16.7183 13.405M15.3217 15.3208C15.14 15.5013 14.9492 15.6724 14.75 15.8333M7.5575 4.21167L7.91667 3.75C7.9895 3.60853 8.11054 3.49777 8.25791 3.43774C8.40528 3.37772 8.56925 3.37238 8.72021 3.4227C8.87117 3.47302 8.99915 3.57568 9.08103 3.71212C9.16291 3.84856 9.19328 4.00979 9.16667 4.16667V5.83333M9.16667 9.16667V15.8333C9.19328 15.9902 9.16291 16.1514 9.08103 16.2879C8.99915 16.4243 8.87117 16.527 8.72021 16.5773C8.56925 16.6276 8.40528 16.6223 8.25791 16.5623C8.11054 16.5022 7.9895 16.3915 7.91667 16.25L5 12.5H3.33333C3.11232 12.5 2.90036 12.4122 2.74408 12.2559C2.5878 12.0996 2.5 11.8877 2.5 11.6667V8.33333C2.5 8.11232 2.5878 7.90036 2.74408 7.74408C2.90036 7.5878 3.11232 7.5 3.33333 7.5H5L6.07833 6.11333M2.5 2.5L17.5 17.5"
                                      stroke={isDark ? "white" : "#383940"}
                                      strokeOpacity="0.8"
                                      strokeWidth="1.4"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                    />
                                  </g>
                                  <defs>
                                    <clipPath id="clip0_497_37686">
                                      <rect
                                        width="20"
                                        height="20"
                                        fill={isDark ? "white" : "#383940"}
                                      />
                                    </clipPath>
                                  </defs>
                                </svg>
                              </button>
                            )}
                          </CustomToolTip>
                        )}
                        {!widgetConfig.hideMic && isSupported && (
                          <CustomToolTip tooltipText={"Turn on microphone"}>
                            {!listening ? (
                              <button
                                className={clsx(
                                  isDark
                                    ? "hover:bg-[#2E2F45] "
                                    : "hover:bg-[#2e2f4537]",
                                  "transition-all duration-300  h-[24px] w-[24px] flex items-center justify-center rounded-full"
                                )}
                                onClick={() => startListening()}
                              >
                                <svg
                                  width="18"
                                  height="18"
                                  viewBox="0 0 18 18"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="M6.75 3.75C6.75 3.15326 6.98705 2.58097 7.40901 2.15901C7.83097 1.73705 8.40326 1.5 9 1.5C9.59674 1.5 10.169 1.73705 10.591 2.15901C11.0129 2.58097 11.25 3.15326 11.25 3.75V7.5C11.25 8.09674 11.0129 8.66903 10.591 9.09099C10.169 9.51295 9.59674 9.75 9 9.75C8.40326 9.75 7.83097 9.51295 7.40901 9.09099C6.98705 8.66903 6.75 8.09674 6.75 7.5V3.75Z"
                                    stroke={isDark ? "white" : "#383940"}
                                    strokeOpacity="0.8"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                  />
                                  <path
                                    d="M3.75 7.5C3.75 8.89239 4.30312 10.2277 5.28769 11.2123C6.27226 12.1969 7.60761 12.75 9 12.75M9 12.75C10.3924 12.75 11.7277 12.1969 12.7123 11.2123C13.6969 10.2277 14.25 8.89239 14.25 7.5M9 12.75V15.75M6 15.75H12"
                                    stroke={isDark ? "white" : "#383940"}
                                    strokeOpacity="0.8"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                  />
                                </svg>
                              </button>
                            ) : (
                              <button
                                className={clsx(
                                  isDark
                                    ? "hover:bg-[#2E2F45] "
                                    : "hover:bg-[#2e2f4537]",
                                  "transition-all duration-300  h-[24px] w-[24px] flex items-center justify-center rounded-full"
                                )}
                                onClick={() => stopListening()}
                              >
                                <svg
                                  width="18"
                                  height="18"
                                  viewBox="0 0 20 20"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <circle
                                    cx="10"
                                    cy="10"
                                    r="5"
                                    fill={isDark ? "white" : "#383940"}
                                  />
                                  <circle
                                    cx="10"
                                    cy="10"
                                    r="8"
                                    stroke={isDark ? "white" : "#383940"}
                                  />
                                </svg>
                              </button>
                            )}
                          </CustomToolTip>
                        )}
                         <CustomToolTip tooltipText={"Restart Conversation"}>
                          <button
                            className={
                              "transition-all duration-300 secondary-bg-hover h-[24px] w-[24px] flex items-center justify-center rounded-full"
                            }
                            onClick={()=>setDeleteModalOpen(true)}
                          >
                            <svg
                              width="18"
                              height="18"
                              viewBox="0 0 18 18"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M14.95 9.78075C14.8084 10.8607 14.3755 11.8818 13.6977 12.7344C13.0198 13.587 12.1227 14.239 11.1025 14.6205C10.0823 15.002 8.97747 15.0986 7.90653 14.8999C6.83559 14.7013 5.83894 14.2148 5.02345 13.4927C4.20797 12.7707 3.60442 11.8402 3.27754 10.8012C2.95066 9.76223 2.91278 8.65384 3.16796 7.59494C3.42313 6.53604 3.96174 5.56658 4.72602 4.79052C5.4903 4.01446 6.45142 3.46109 7.50629 3.18975C10.4305 2.43975 13.4575 3.945 14.575 6.75"
                                stroke="#fff"
                                strokeOpacity="0.8"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                              <path
                                d="M15 3V6.75H11.25"
                                stroke="#fff"
                                strokeOpacity="0.8"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                            </svg>
                          </button>
                        </CustomToolTip>
                      </div>
                    </div>

                    <div
                      className={clsx(
                        "h-[51px] absolute z-10  top-[-3px] left-0 w-full",
                        isDark ? "!bg-[#11121C] " : "!bg-[#E6EAF0]"
                      )}
                    ></div>
                  </div>
                </div>
              </div>
            </>
          )}
          {!widgetConfig.hideBranding && (
            <a
              href={`${window.location.origin}/marketplace?utm_source=chat-widget&utm_medium=powered-by-badge&utm_compaign=${personalityInView.username}`}
              target="_blank"
              className="flex flex-row items-center justify-center p-[12px] "
            >
              <span
                className={clsx(
                  isDark ? "text-[#FFFFFFA6] " : " text-[#383940A6]",
                  "text-[10px] leading-[14px] mr-[10px] "
                )}
              >
                Powered By
              </span>
              <svg
                width="83"
                height="17"
                viewBox="0 0 83 17"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M12.6901 1.59039H4.01977C2.65164 1.59039 1.54254 2.69949 1.54254 4.06763V12.7379C1.54254 14.1061 2.65164 15.2152 4.01978 15.2152H12.6901C14.0582 15.2152 15.1673 14.1061 15.1673 12.7379V4.06763C15.1673 2.69949 14.0582 1.59039 12.6901 1.59039ZM4.01977 0.764648C2.19559 0.764648 0.716797 2.24344 0.716797 4.06763V12.7379C0.716797 14.5621 2.19559 16.0409 4.01978 16.0409H12.6901C14.5143 16.0409 15.9931 14.5621 15.9931 12.7379V4.06763C15.9931 2.24344 14.5143 0.764648 12.6901 0.764648H4.01977Z"
                  fill={isDark ? "#C2D24B" : "black"}
                />
                <path
                  d="M6.49483 3.22994C5.87922 3.34786 5.38353 3.73162 5.1077 4.30726C5.00376 4.52713 4.9498 4.74499 4.9358 5.01282L4.92381 5.23468L4.75192 5.27866C4.32219 5.38459 3.89645 5.67841 3.65261 6.03818C3.53668 6.21008 3.41875 6.45992 3.36678 6.6498C3.30482 6.87966 3.30482 7.35936 3.36678 7.58522C3.41875 7.7751 3.53668 8.03294 3.64861 8.19684L3.73256 8.31876L3.65061 8.43869C3.53468 8.60858 3.41875 8.85842 3.36678 9.04831C3.30482 9.27816 3.30482 9.75786 3.36678 9.98772C3.55067 10.6613 4.09033 11.197 4.75192 11.3589L4.92381 11.4028L4.9358 11.6247C4.97378 12.3462 5.38153 12.9499 6.04112 13.2597C6.32294 13.3916 6.47285 13.4216 6.82862 13.4216C7.08247 13.4216 7.16641 13.4136 7.29833 13.3796C7.5002 13.3256 7.81801 13.1677 7.9939 13.0358L8.12781 12.9319V3.70564L7.9939 3.6017C7.81601 3.46779 7.49821 3.31188 7.29833 3.25992C7.09246 3.20595 6.69471 3.19196 6.49483 3.22994Z"
                  fill={isDark ? "#C2D24B" : "black"}
                />
                <path
                  d="M9.69261 3.23025C9.36681 3.29221 9.065 3.43612 8.80316 3.64999L8.72721 3.71195V6.02051H9.3768L9.42677 5.91458C9.49073 5.77866 9.68661 5.58478 9.83052 5.51483C10.3722 5.25499 11.0078 5.58478 11.1097 6.17841C11.1917 6.65811 10.8579 7.11982 10.3682 7.20377C10.0004 7.26573 9.61266 7.07785 9.44476 6.75006L9.3768 6.62014H8.72721V8.01926H10.1763L10.2263 7.91333C10.2902 7.77741 10.4861 7.58354 10.63 7.51358C11.0717 7.30171 11.6034 7.4796 11.8233 7.91533C12.0971 8.45699 11.7673 9.09859 11.1657 9.20253C10.7999 9.26449 10.4122 9.07461 10.2443 8.74881L10.1763 8.61889H8.72721V10.018H9.3768L9.42677 9.91208C9.49073 9.77617 9.68661 9.58229 9.83052 9.51233C10.3722 9.25249 11.0078 9.58229 11.1097 10.1759C11.1917 10.6556 10.8579 11.1173 10.3682 11.2013C10.0004 11.2632 9.61266 11.0754 9.44476 10.7476L9.3768 10.6176H8.72721V12.9262L8.80316 12.9882C9.01503 13.1621 9.29885 13.31 9.55669 13.3779C9.79454 13.4439 10.2483 13.4439 10.4861 13.3799C11.0598 13.224 11.4995 12.8562 11.7473 12.3286C11.8533 12.1027 11.9052 11.8869 11.9192 11.625L11.9312 11.4032L12.1031 11.3592C12.7647 11.1973 13.3044 10.6616 13.4862 9.98803C13.5522 9.75018 13.5522 9.29647 13.4882 9.05862C13.4323 8.85474 13.3203 8.6089 13.2064 8.441L13.1225 8.31908L13.2064 8.19715C13.3203 8.02926 13.4323 7.78341 13.4882 7.57954C13.5522 7.34169 13.5522 6.88797 13.4862 6.65012C13.3044 5.97654 12.7647 5.44087 12.1031 5.27897L11.9312 5.235L11.9192 5.01314C11.8932 4.53344 11.7034 4.10171 11.3676 3.76192C11.1397 3.53206 10.8119 3.34418 10.4961 3.26023C10.2902 3.20627 9.89448 3.19228 9.69261 3.23025Z"
                  fill={isDark ? "#C2D24B" : "black"}
                />
                <path
                  d="M10.0828 6.06216C9.99686 6.10614 9.9269 6.22406 9.9269 6.32C9.9269 6.41994 9.99686 6.53387 10.0868 6.57984C10.1288 6.60183 10.1927 6.61982 10.2267 6.61982C10.2607 6.61982 10.3247 6.60183 10.3666 6.57984C10.4566 6.53387 10.5265 6.41994 10.5265 6.32C10.5265 6.22006 10.4566 6.10614 10.3666 6.06016C10.2687 6.0102 10.1787 6.0102 10.0828 6.06216Z"
                  fill={isDark ? "#C2D24B" : "black"}
                />
                <path
                  d="M10.8817 8.06101C10.7957 8.10498 10.7258 8.22291 10.7258 8.31885C10.7258 8.41879 10.7957 8.53272 10.8857 8.57869C10.9277 8.60067 10.9916 8.61866 11.0256 8.61866C11.1255 8.61866 11.2395 8.54871 11.2854 8.45876C11.3074 8.41679 11.3254 8.35283 11.3254 8.31885C11.3254 8.21891 11.2555 8.10498 11.1655 8.05901C11.0676 8.00904 10.9776 8.00904 10.8817 8.06101Z"
                  fill={isDark ? "#C2D24B" : "black"}
                />
                <path
                  d="M10.0828 10.0599C9.99686 10.1038 9.9269 10.2218 9.9269 10.3177C9.9269 10.3517 9.94489 10.4156 9.96688 10.4576C10.0129 10.5476 10.1268 10.6175 10.2267 10.6175C10.3786 10.6175 10.5265 10.4696 10.5265 10.3177C10.5265 10.2178 10.4566 10.1038 10.3666 10.0579C10.2687 10.0079 10.1787 10.0079 10.0828 10.0599Z"
                  fill={isDark ? "#C2D24B" : "black"}
                />
                <path
                  d="M19.4336 13.398V4.14966H20.8605V7.99432H21.0719L24.5202 4.14966H26.3831L22.2081 8.70777L26.5284 13.398H24.6127L21.0719 9.47406H20.8605V13.398H19.4336Z"
                  fill={isDark ? "#C2D24B" : "black"}
                />
                <path
                  d="M29.6618 13.583C29.195 13.583 28.7766 13.5037 28.4067 13.3451C28.0455 13.1866 27.7549 12.9532 27.5347 12.6449C27.3233 12.3366 27.2176 11.9623 27.2176 11.5219C27.2176 11.0727 27.3233 10.7028 27.5347 10.4121C27.7549 10.1126 28.0499 9.88803 28.4199 9.7383C28.7986 9.58856 29.2258 9.5137 29.7014 9.5137H31.6832V9.09091C31.6832 8.71217 31.5687 8.4083 31.3397 8.17929C31.1107 7.95029 30.7584 7.83578 30.2827 7.83578C29.8159 7.83578 29.4592 7.94588 29.2126 8.16608C28.966 8.38628 28.803 8.67254 28.7237 9.02485L27.4554 8.61528C27.5611 8.26297 27.7284 7.94588 27.9574 7.66403C28.1953 7.37337 28.5079 7.13995 28.8955 6.9638C29.283 6.78764 29.7499 6.69956 30.296 6.69956C31.1415 6.69956 31.8065 6.91535 32.291 7.34694C32.7754 7.77853 33.0176 8.39068 33.0176 9.1834V11.8654C33.0176 12.1297 33.1409 12.2618 33.3875 12.2618H33.9424V13.398H32.9251C32.6168 13.398 32.3658 13.3187 32.172 13.1602C31.9783 13.0016 31.8814 12.7858 31.8814 12.5128V12.4732H31.6832C31.6127 12.6053 31.507 12.7594 31.3661 12.9356C31.2252 13.1117 31.0182 13.2659 30.7452 13.398C30.4721 13.5213 30.111 13.583 29.6618 13.583ZM29.86 12.46C30.4061 12.46 30.8465 12.3058 31.1812 11.9975C31.5159 11.6804 31.6832 11.2489 31.6832 10.7028V10.5706H29.7807C29.4196 10.5706 29.1289 10.6499 28.9087 10.8085C28.6885 10.9582 28.5784 11.1828 28.5784 11.4823C28.5784 11.7817 28.6929 12.0196 28.9219 12.1957C29.1509 12.3719 29.4636 12.46 29.86 12.46Z"
                  fill={isDark ? "#C2D24B" : "black"}
                />
                <path
                  d="M35.2656 13.398V6.88452H36.6V7.65082H36.8114C36.9347 7.42181 37.1329 7.21923 37.4059 7.04307C37.679 6.86691 38.0489 6.77883 38.5157 6.77883C39.0002 6.77883 39.3877 6.88012 39.6784 7.0827C39.9778 7.27648 40.2024 7.5275 40.3522 7.83578H40.5636C40.7133 7.53631 40.9291 7.28529 41.211 7.0827C41.5016 6.88012 41.9112 6.77883 42.4397 6.77883C42.8624 6.77883 43.2368 6.86691 43.5627 7.04307C43.8886 7.21042 44.1484 7.46585 44.3422 7.80936C44.536 8.14406 44.6328 8.56244 44.6328 9.06449V13.398H43.272V9.17019C43.272 8.77383 43.1619 8.46995 42.9417 8.25856C42.7303 8.03836 42.4265 7.92827 42.0301 7.92827C41.6073 7.92827 41.2682 8.06479 41.0128 8.33783C40.7573 8.61088 40.6296 9.00283 40.6296 9.5137V13.398H39.2688V9.17019C39.2688 8.77383 39.1587 8.46995 38.9385 8.25856C38.7271 8.03836 38.4232 7.92827 38.0269 7.92827C37.6041 7.92827 37.265 8.06479 37.0096 8.33783C36.7541 8.61088 36.6264 9.00283 36.6264 9.5137V13.398H35.2656Z"
                  fill={isDark ? "#C2D24B" : "black"}
                />
                <path
                  d="M49.6457 13.583C48.9939 13.583 48.4126 13.4508 47.9018 13.1866C47.3997 12.9136 47.0033 12.526 46.7127 12.024C46.422 11.5219 46.2767 10.9274 46.2767 10.2404V10.0422C46.2767 9.35515 46.422 8.76502 46.7127 8.27178C47.0033 7.76972 47.3997 7.38217 47.9018 7.10913C48.4126 6.83608 48.9939 6.69956 49.6457 6.69956C50.2975 6.69956 50.8788 6.83608 51.3897 7.10913C51.9006 7.38217 52.3013 7.76972 52.592 8.27178C52.8826 8.76502 53.028 9.35515 53.028 10.0422V10.2404C53.028 10.9274 52.8826 11.5219 52.592 12.024C52.3013 12.526 51.9006 12.9136 51.3897 13.1866C50.8788 13.4508 50.2975 13.583 49.6457 13.583ZM49.6457 12.3675C50.2447 12.3675 50.7291 12.1781 51.099 11.7994C51.4778 11.4118 51.6672 10.8789 51.6672 10.2007V10.0818C51.6672 9.4036 51.4822 8.87512 51.1123 8.49638C50.7423 8.10883 50.2535 7.91505 49.6457 7.91505C49.0556 7.91505 48.5712 8.10883 48.1924 8.49638C47.8225 8.87512 47.6375 9.4036 47.6375 10.0818V10.2007C47.6375 10.8789 47.8225 11.4118 48.1924 11.7994C48.5712 12.1781 49.0556 12.3675 49.6457 12.3675Z"
                  fill={isDark ? "#C2D24B" : "black"}
                />
                <path
                  d="M56.9213 13.398C56.5249 13.398 56.2078 13.2791 55.97 13.0413C55.741 12.8035 55.6265 12.4864 55.6265 12.09V8.03396H53.8297V6.88452H55.6265V4.73098H56.9873V6.88452H58.9295V8.03396H56.9873V11.8522C56.9873 12.1164 57.1106 12.2486 57.3573 12.2486H58.7181V13.398H56.9213Z"
                  fill={isDark ? "#C2D24B" : "black"}
                />
                <path
                  d="M63.3479 13.583C62.6961 13.583 62.1148 13.4508 61.604 13.1866C61.1019 12.9136 60.7055 12.526 60.4149 12.024C60.1242 11.5219 59.9789 10.9274 59.9789 10.2404V10.0422C59.9789 9.35515 60.1242 8.76502 60.4149 8.27178C60.7055 7.76972 61.1019 7.38217 61.604 7.10913C62.1148 6.83608 62.6961 6.69956 63.3479 6.69956C63.9997 6.69956 64.581 6.83608 65.0919 7.10913C65.6028 7.38217 66.0035 7.76972 66.2942 8.27178C66.5849 8.76502 66.7302 9.35515 66.7302 10.0422V10.2404C66.7302 10.9274 66.5849 11.5219 66.2942 12.024C66.0035 12.526 65.6028 12.9136 65.0919 13.1866C64.581 13.4508 63.9997 13.583 63.3479 13.583ZM63.3479 12.3675C63.9469 12.3675 64.4313 12.1781 64.8012 11.7994C65.18 11.4118 65.3694 10.8789 65.3694 10.2007V10.0818C65.3694 9.4036 65.1844 8.87512 64.8145 8.49638C64.4445 8.10883 63.9557 7.91505 63.3479 7.91505C62.7578 7.91505 62.2734 8.10883 61.8946 8.49638C61.5247 8.87512 61.3397 9.4036 61.3397 10.0818V10.2007C61.3397 10.8789 61.5247 11.4118 61.8946 11.7994C62.2734 12.1781 62.7578 12.3675 63.3479 12.3675Z"
                  fill={isDark ? "#C2D24B" : "black"}
                />
                <path
                  d="M67.9609 13.398L70.6033 4.14966H73.1136L75.7428 13.398H74.2762L73.6685 11.218H70.0484L69.4407 13.398H67.9609ZM70.4051 9.89684H73.3118L71.9641 5.03486H71.7528L70.4051 9.89684Z"
                  fill={isDark ? "#C2D24B" : "#758027"}
                />
                <path
                  d="M76.9957 13.398V4.14966H78.4226V13.398H76.9957Z"
                  fill={isDark ? "#C2D24B" : "#758027"}
                />
                <path
                  d="M81.2091 13.583C80.9008 13.583 80.641 13.4817 80.4296 13.2791C80.227 13.0765 80.1257 12.8167 80.1257 12.4996C80.1257 12.1825 80.227 11.9271 80.4296 11.7333C80.641 11.5307 80.9008 11.4294 81.2091 11.4294C81.5174 11.4294 81.7728 11.5307 81.9754 11.7333C82.1868 11.9359 82.2925 12.1913 82.2925 12.4996C82.2925 12.8167 82.1868 13.0765 81.9754 13.2791C81.7728 13.4817 81.5174 13.583 81.2091 13.583Z"
                  fill={isDark ? "#C2D24B" : "#758027"}
                />
              </svg>
            </a>
          )}
        </div>
      )}
    </div>
  );
}
