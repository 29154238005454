import { get, map } from "lodash";
import React from "react";
import DateFilter from "../components/date-filter";
import LineChart from "../components/line-chart";
import GaugeChart from "../components/gauge-chart";
import { ToolTipUI } from "../../widgets/components/UI/ToolTipUI";
import { useIntl } from "react-intl";
import BarChart from "../components/traffic/bar-chart";
import Select from "../../../components/select/select";
import LineGraph from "../components/traffic/line-graph";
import CheckboxSelect from "../components/checkbox-select";

const TrafficAndVisitors = () => {
  const { formatMessage } = useIntl();
  const lineChart = [
    {
      heading: "All UsersTable",
      count: "8,31,22,625",
      increase: true,
      description: "33,662 Joined Yesterday",
      linkTitle: "Check all users",
    },
    {
      heading: "All UsersTable",
      count: "3,41,00,625",
      increase: true,
      description: "33,662 Verified Yesterday",
      linkTitle: "Check all users",
    },
    {
      heading: "All UsersTable",
      count: "4,70,80,452",
      increase: true,
      description: "33,662 Pending action",
      linkTitle: "Check all users",
    },
    {
      heading: "Paid Personality Owners",
      count: "6,31,22,625",
      increase: true,
      description: "33,662 Paid Yesterday",
      linkTitle: "Check all users",
    },
    {
      heading: "All UsersTable",
      count: "4,70,80,452",
      increase: true,
      description: "33,662 Pending action",
      linkTitle: "Check all users",
    },
    {
      heading: "Paid Personality Owners",
      count: "6,31,22,625",
      increase: true,
      description: "33,662 Paid Yesterday",
      linkTitle: "Check all users",
    },
  ];

  return (
    <div className="pb-10">
      <DateFilter />
      <div className={"grid grid-cols-12 gap-4 mt-[16px] mb-[24px]"}>
        {map(lineChart, (item: any) => (
          <div className={"col-span-12 md:col-span-6 xl:col-span-3"}>
            <LineChart
              heading={get(item, "heading")}
              count={get(item, "count")}
              description={get(item, "description")}
              tooltipText={"Test"}
            />
          </div>
        ))}
        <div
          className={
            "col-span-12 md:col-span-6 xl:col-span-3 chart-card p-5 gap-y-5 row-span-1 rounded"
          }
        >
          <h3 className="flex items-center  text-[14px] leading-[20px] primary-text">
            {formatMessage({ id: "Daily Retention" })}
            <ToolTipUI
              tooltipText={formatMessage({
                id: "Daily Retention",
              })}
            />
          </h3>
          <div className={"h-60"}>
            <GaugeChart />
          </div>
          <div className={"text-center"}>
            <span className={"text-[12px] secondary-text"}>
              Avg. for view: 60.009 %{" "}
            </span>
          </div>
        </div>
        <div
          className={
            "col-span-12 md:col-span-6 xl:col-span-3 chart-card p-5 rounded"
          }
        >
          <h3 className="flex items-center  text-[14px] leading-[20px] primary-text">
            {formatMessage({ id: "Conversion Rate" })}
            <ToolTipUI
              tooltipText={formatMessage({
                id: "Conversion Rate",
              })}
            />
          </h3>
          <div className={"h-60"}>
            <GaugeChart />
          </div>
          <div className={"text-center"}>
            <span className={"text-[12px] secondary-text"}>
              Avg. for view: 60.009 %{" "}
            </span>
          </div>
        </div>
      </div>
      <div  className={
          "grid grid-cols-12  md:gap-x-[22px] gap-y-[14px] md:gap-y-5 my-5"
      }>

        <div className={"col-span-12 md:col-span-6 chart-card p-5 rounded"}>
          <div className={"flex flex-wrap gap-y-1 justify-between items-center mb-2"}>
            <h3 className="flex items-center  text-[14px] leading-[20px] primary-text min-w-[100px]">
              {formatMessage({ id: "Avg time spends on chat users" })}
              <ToolTipUI
                  tooltipText={formatMessage({
                    id: "Avg time spends on chat users",
                  })}
              />
            </h3>
            <Select
                options={[{ value: "quater", label: "Quaterly" }]}
                defaultValue={{ value: "quater", label: "Quaterly" }}
                label={false}
                height={36}
                className={"event-select min-w-[150px] !w-full text-[14px]"}
                placeHolder={formatMessage({ id: "Select" })}
            />
          </div>
          <h3 className={'text-[16px] leading-8 font-medium primary-text mb-5'}>82h:45m:22s</h3>
          <div className={"h-60"}>
            <BarChart />
          </div>
        </div>
        <div className={"col-span-12 md:col-span-6 chart-card p-5 rounded"}>
          <div className={"flex flex-wrap gap-y-1 justify-between items-center mb-2"}>
            <h3 className="flex items-center  text-[14px] leading-[20px] primary-text min-w-[100px]">
              {formatMessage({ id: "Mobile Application" })}
              <ToolTipUI
                  tooltipText={formatMessage({
                    id: "Mobile Application",
                  })}
              />
            </h3>
            <CheckboxSelect options={[
                {
                  value: "all",
                  label: `${formatMessage({ id: "All" })}`,
                },
                {
                  value: "installs",
                  label: `${formatMessage({ id: "Installs" })}`,
                },
                {
                  value: "uninstalls",
                  label: `${formatMessage({ id: "Uninstalls" })}`,
                },
            ]}/>
          </div>
          <h3 className={'text-[14px] leading-8 font-medium primary-text flex items-center gap-x-2 mb-1'}><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
            <rect width="16" height="16" rx="8" fill="#4776E6"/>
          </svg>9,35,000 Installs Play Store</h3>
          <h3 className={'text-[14px] leading-8 font-medium primary-text flex items-center gap-x-2 mb-1'}><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
            <rect width="16" height="16" rx="8" fill="#C2D24B"/>
          </svg>9,35,000 Installs Apple Store</h3>
          <div className={'h-56'}>
          <LineGraph/>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TrafficAndVisitors;
