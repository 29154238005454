import React, { useEffect, useState } from "react";
import CustomTable from "../../../components/table";
import { get, isEqual, map } from "lodash";
import { useDateFormat } from "../../../hooks/useDateFormat";
import { useTimeZone } from "../../../hooks/useTimeZone";
import { getInteractions } from "../core/_request";
import { useIntl } from "react-intl";
import { useAuth } from "../../auth";
import { getCdnUrl } from "../../../core/_util";
import userDefault from "../../../assets/images/profile/user-default.png";
import { ToolTipUI } from "../../widgets/components/UI/ToolTipUI";
import InteractionChats from "./interaction-chats";
const InteractionList = () => {
  const { formatMessage } = useIntl();
  const { getDateValue } = useDateFormat();
  const { getTimeZoneValue } = useTimeZone();
  const [loading, setLoading] = useState<boolean>(true);
  const [data, setData] = useState<any[]>([]);
  const [pageCount, setPageCount] = useState<number>(0);
  const { selectedPersonality } = useAuth();
  const [user, setUser] = useState<null | Record<string, any>>(null);
  async function getHistory({ page, size, search }: any) {
    if (!selectedPersonality) {
      return;
    }
    setLoading(true);
    const {
      data: { success, data },
    } = await getInteractions(selectedPersonality.personalityId, {
      page: page + 1,
      limit: size,
      search,
    });
    if (success) {
      let formattedData = data.rows.map(
        ({ user, startedAt, totalMessages, userId }: any) => {
          return {
            user,
            lastSessionOn: `${getDateValue(startedAt)} ${getTimeZoneValue(
              startedAt
            )}`,
            messageCount: totalMessages,
            actions: { userId, ...user }
          };
        }
      );
      setLoading(false);
      setData(formattedData);
      setPageCount(Math.ceil(data.count / size));
    }
    setLoading(false);
  }


  const headers = [
    {
      label: formatMessage({ id: "User Name" }),
      key: "user",
      enableSorting: true,
      filtering: false,
      cell: true,
    },
    {
      label: formatMessage({ id: "Last Session On" }),
      key: "lastSessionOn",
      enableSorting: true,
      filtering: false,
    },
    {
      label: formatMessage({ id: "Message Count" }),
      key: "messageCount",
      enableSorting: true,
      filtering: false,
    },
    {
      label: formatMessage({ id: "Actions" }),
      key: "actions",
      enableSorting: true,
      filtering: false,
      cell: true,
    },
  ];

  const columns = React.useMemo<any>(
    () =>
      map(headers, (item) => {
        return {
          header: get(item, "label"),
          accessorKey: get(item, "key"),
          enableSorting: get(item, "enableSorting"),
          footer: (props: any) => props.column.id,
          cell: (info: any) => {
            if (item.cell) {
              if (isEqual(get(item, "key"), "user")) {
                return (
                  <div className="flex align-items-center">
                    <img
                      className="rounded"
                      width={32}
                      height={32}
                      alt=""
                      src={
                        info.getValue()?.photo
                          ? getCdnUrl(info.getValue().photo, "width=32")
                          : userDefault
                      }
                    />
                    <p className="ml-2">
                      {info.getValue().firstName} {info.getValue().lastName}
                    </p>
                  </div>
                );
              } else if (isEqual(get(item, "key"), "actions")) {
                return (
                  <div onClick={() => setUser(info.getValue())} className="rounded px-4 py-1 bg-[#C2D24B1A] text-[#c2d24b] w-fit">
                    {formatMessage({ id: "Show Chats" })}
                  </div>
                );
              }
            } else {
              return info.getValue();
            }
          },
        };
      }),

    []
  );

  return (
    <div
      className={
        "min-h-[74vh] md:min-h-[60vh] relative rounded tab-container pb-[24px] md:p-[24px] mt-4  h-full overflow-hidden"
      }
    >
      <CustomTable
        tableData={data}
        pageCount={pageCount}
        loading={loading}
        headers={headers}
        columns={columns}
        fetchData={getHistory}
        optionalTrigger={["search", selectedPersonality]}
        enableFilters={false}
      />
      {user && <InteractionChats setUser={setUser} user={user} />}
    </div>
  );
};

export default InteractionList;
