import "../../assets/index.css";
import { Link, Outlet } from "react-router-dom";
import AuthBg from "../../assets/images/auth/auth-background.png";
import LogoImg from "../../assets/images/auth/logo.svg";
import { useIntl } from "react-intl";
import { Toaster } from "../widgets/components/General/Toaster";
import Slider from "./components/slider";
import Image1 from "./images/auth-1.png";
import Image2 from "./images/auth-2.png";
import Image3 from "./images/auth-3.png";
import { ToastContainer } from "react-toastify";
export const AuthLayout = () => {
  const { formatMessage } = useIntl();

  const sliders = [
    {
      id: 123123,
      heading: formatMessage({ id: "Create or Engage with Celebrity AI" }),
      description: formatMessage({
        id: "Dreamed of talking to celebrities? Kamoto.AI enables interaction with licensed celebrity AI personalities for unique access and authenticity",
      }),
      image: Image1,
    },
    {
      id: 124323123,
      heading: formatMessage({ id: "Personalized AI Creation" }),
      description: formatMessage({
        id: "Unleash your creativity with Kamoto.AI. Craft your own AI personality to offer a unique experience. Embrace the future of digital interaction, right at your fingertips",
      }),

      image: Image2,
    },
    {
      id: 6769567,
      heading: formatMessage({ id: "Monetize Your AI Personality" }),
      description: formatMessage({
        id: "With Kamoto.AI, your creativity earns. Rent out your AI personas and monetize your unique creations. Discover the world’s first AI personalities Marketplace",
      }),

      image: Image3,
    },
  ];

  return (
    <>
      <ToastContainer />
      <div className={"grid grid-cols-12 h-screen"}>
        <div
          className={"col-span-12 md:col-span-5 tab-container  py-[60px] px-4"}
        >
          <div className={"flex justify-center mb-[20px] lg:mb-[60px]"}>
            <Link to={"/"}>
              <img
                src={LogoImg}
                className={"w-[125px] md:w-[290px]"}
                alt={"logo"}
              />
            </Link>
          </div>
          <div className={"flex flex-col max-w-[420px] mx-auto"}>
            <Outlet />
          </div>
        </div>
        <div
          className={
            "col-span-12 md:col-span-7 py-[20px] md:py-[60px] flex justify-center items-center  px-4"
          }
        >
          <Slider data={sliders} />
        </div>
      </div>
      {/*<div*/}
      {/*  className={"h-screen relative auth-bg"}*/}
      {/*  style={{*/}
      {/*    backgroundImage: `url(${AuthBg})`,*/}
      {/*  }}*/}
      {/*>*/}
      {/*  <div*/}
      {/*    className={*/}
      {/*      "min-w-[340px] sm:min-w-[540px] absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2"*/}
      {/*    }*/}
      {/*  >*/}
      {/*    <div className={"flex justify-center mb-8"}>*/}
      {/*      <Link to={'/'}>*/}
      {/*        <img src={LogoImg} className={"w-[125px] md:w-[290px]"} />*/}
      {/*      </Link>*/}
      {/*    </div>*/}
      {/*    <div*/}
      {/*      className={*/}
      {/*        " bg-[#171825]  shadow-[0px_2px_15px_0px_#00000040] px-[16px] md:px-[68px] py-[32px] md:py-12 rounded-md"*/}
      {/*      }*/}
      {/*    >*/}
      {/*      <Outlet />*/}
      {/*    </div>*/}
      {/*    <div className="flex flex-wrap justify-center md:justify-between mt-[24px] md:mt-[12px] text-[#FFFFFFA6] text-[13px] leading-5">*/}
      {/*      <p className={"w-full md:w-auto text-center mb-[8px]"}>*/}
      {/*        2023© */}
      {/*      </p>*/}
      {/*      <div className={"flex justify-between gap-[25px]  mb-[8px]"}>*/}
      {/*        <a href={process.env.REACT_APP_TERMS_POLICY_LINK} target="_blank" rel="noreferrer">*/}
      {/*          {formatMessage({ id: "Terms of use" })}*/}
      {/*        </a>*/}

      {/*        <a href={process.env.REACT_APP_PRIVACY_POLICY_LINK} target="_blank" rel="noreferrer">*/}
      {/*          {formatMessage({ id: "Privacy Policy" })}*/}
      {/*        </a>*/}
      {/*      </div>*/}
      {/*    </div>*/}
      {/*  </div>*/}
      {/*</div>*/}
    </>
  );
};
