// @ts-ignore
import useComponentSize from "@rehooks/component-size";
// @ts-ignore
import * as echarts from "echarts";


import React, { useEffect } from "react";

const useChartResizer = (chartRef: any) => {
    const size = useComponentSize(chartRef);

    useEffect(() => {
        const chart =
            chartRef.current && echarts.getInstanceByDom(chartRef.current);
        if (chart) {
            chart.resize();
        }
    }, [chartRef, size]);
};

export default useChartResizer;