import { map, range } from "lodash";
import React, { useEffect, useState } from "react";
import { LineGraph } from "../components/line-graph";
import { Link } from "react-router-dom";
import { useIntl } from "react-intl";
import { getApiLogs } from "../core/_requests";
import { useDateFormat } from "../../../hooks/useDateFormat";
import { Spinner } from "react-bootstrap";
import Empty from "../../../components/empty/Empty";
import request from "../../../core/_apis";
import { useAuth } from "../../auth";
import { CombinedData, LineChartWithData } from "../../../components/line-chart/line-chart-with-data";
import { LineGraphWithData } from "../components/line-graph/line-graph-with-data";
import { DateTime } from "luxon";
const Overview = () => {
  const { formatMessage } = useIntl();
  const {selectedPersonality} = useAuth()
  const [errors, setErrors] = useState<any[]>([])
  const [loading, setLoading] = useState<boolean>(true)
  const [apiData, setApiData] = useState<any>()
  const { getDateValue } = useDateFormat();
  useEffect(()=>{
    async function get(){
      setLoading(true)
     const {data:{success,data}} =  await getApiLogs('failed')
     if(success){
      setErrors(data.rows)
     }
     setLoading(false)
    }

    get();
  },[])

  const fetchApiData  =  async () => {
    let resp = await request.get('/reports/api',{
      params: {
        startDate: new Date(new Date().setDate(new Date().getDate() - 60)),
        forDays: 29
      },
      headers: {
        'x-personality-id': selectedPersonality.personalityId
      }
    })

    return resp.data.data
  }

  const processApiData = async () => {
    let feedbacks = await fetchApiData()
    let dailyData:CombinedData = {
      currentTitle : "Current Period",
      comparedTitle: "Previous Period",
      currentPeriod: feedbacks?.currentPeriod?.map((el:any) => {
        return {
          date: el.date,
          value: el.total_requests
        }
      }),
      comparedPeriod: feedbacks?.comparedPeriod?.map((el:any) => {
        return {
          date: el.date,
          value: el.total_requests
        }
      }),
    }
    let failedData:CombinedData = {
      currentTitle : "Current Period",
      comparedTitle: "Previous Period",
      currentPeriod: feedbacks?.currentPeriod?.map((el:any) => {
        return {
          date: el.date,
          value: el.failed_requests
        }
      }),
      comparedPeriod: feedbacks?.comparedPeriod?.map((el:any) => {
        return {
          date: el.date,
          value: el.failed_requests
        }
      }),
    }
    setApiData({...feedbacks,dailyData,failedData})
  }

  useEffect(() => {
    processApiData()
  },[])

  return (
    <div className={"mt-[16px] md:mt-0"}>
      {
        selectedPersonality?.showDemoDashboard &&
        <div className="flex-row graph-placeholder-wrapper d-flex md:align-items-center ">
          <div className="mr-[14px]">
            <svg
              width="32"
              height="32"
              viewBox="0 0 32 32"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g clipPath="url(#clip0_4933_32805)">
                <path
                  d="M14.667 22.667H17.3337V14.667H14.667V22.667ZM16.0003 12.0003C16.3781 12.0003 16.695 11.8723 16.951 11.6163C17.207 11.3603 17.3345 11.0439 17.3337 10.667C17.3337 10.2892 17.2057 9.97233 16.9497 9.71633C16.6937 9.46033 16.3772 9.33277 16.0003 9.33366C15.6225 9.33366 15.3057 9.46166 15.0497 9.71766C14.7937 9.97366 14.6661 10.2901 14.667 10.667C14.667 11.0448 14.795 11.3617 15.051 11.6177C15.307 11.8737 15.6234 12.0012 16.0003 12.0003ZM16.0003 29.3337C14.1559 29.3337 12.4225 28.9834 10.8003 28.283C9.1781 27.5825 7.76699 26.6328 6.56699 25.4337C5.36699 24.2337 4.41721 22.8225 3.71766 21.2003C3.0181 19.5781 2.66788 17.8448 2.66699 16.0003C2.66699 14.1559 3.01721 12.4225 3.71766 10.8003C4.4181 9.1781 5.36788 7.76699 6.56699 6.56699C7.76699 5.36699 9.1781 4.41721 10.8003 3.71766C12.4225 3.0181 14.1559 2.66788 16.0003 2.66699C17.8448 2.66699 19.5781 3.01721 21.2003 3.71766C22.8225 4.4181 24.2337 5.36788 25.4337 6.56699C26.6337 7.76699 27.5839 9.1781 28.2843 10.8003C28.9848 12.4225 29.3345 14.1559 29.3337 16.0003C29.3337 17.8448 28.9834 19.5781 28.283 21.2003C27.5825 22.8225 26.6328 24.2337 25.4337 25.4337C24.2337 26.6337 22.8225 27.5839 21.2003 28.2843C19.5781 28.9848 17.8448 29.3345 16.0003 29.3337ZM16.0003 26.667C18.9781 26.667 21.5003 25.6337 23.567 23.567C25.6337 21.5003 26.667 18.9781 26.667 16.0003C26.667 13.0225 25.6337 10.5003 23.567 8.43366C21.5003 6.36699 18.9781 5.33366 16.0003 5.33366C13.0225 5.33366 10.5003 6.36699 8.43366 8.43366C6.36699 10.5003 5.33366 13.0225 5.33366 16.0003C5.33366 18.9781 6.36699 21.5003 8.43366 23.567C10.5003 25.6337 13.0225 26.667 16.0003 26.667Z"
                  fill="#C2D24B"
                  fillOpacity="0.5"
                />
              </g>
              <defs>
                <clipPath id="clip0_4933_32805">
                  <rect width="32" height="32" fill="white" />
                </clipPath>
              </defs>
            </svg>
          </div>
          <div className="">
            <h5 className="text-[14px] leading-[20px] font-weight-500 primary-text">
              {" "}
              {formatMessage({ id: "Sample Dashboard" })}
            </h5>
            <p className="text-[12px] leading-[18px] font-weight-400 mt-[2px] secondary-text">
              {formatMessage({
                id: "This is a sample/placeholder dashboard for now. Your tailored dashboard will be displayed once activity and traffic begin populating your AI Character or personality page in the Marketplace.",
              })}
            </p>
          </div>
        </div>
      }
      <div
        className={
          "tab-container shadow-default py-[14px] px-[6px] md:p-6 rounded"
        }
      >
        <div className={"grid grid-cols-12 gap-y-3.5 md:gap-x-6"}>
          <div
            className={
              "col-span-12 md:col-span-8 grid grid-cols-12 md:gap-x-3.5 gap-y-3.5"
            }
          >
            <div
              className={
                "col-span-12 md:col-span-6 p-3.5 card-bg shadow-default  rounded"
              }
            >
              <h3
                className={
                  "text-[16px] leading-[22px] primary-text font-medium mb-2"
                }
              >
                {formatMessage({ id: "API Requests" })}
              </h3>
              <div className={"flex gap-x-6 mb-2"}>
                <div>
                  <label
                    className={"text-[12px] leading-5  secondary-text mb-0.5"}
                  >
                    {formatMessage({ id: "Successful" })}
                  </label>
                  <p
                    className={
                      "text-[16px] leading-5 secondary-text font-medium"
                    }
                  >
                    {apiData?.currentPeriodTotal?.successful}
                  </p>
                </div>
                <div>
                  <label
                    className={"text-[12px] leading-5 primary-text mb-0.5"}
                  >
                    {formatMessage({ id: "Failed" })}
                  </label>
                  <p
                    className={
                      "text-[16px] leading-5 secondary-text font-medium"
                    }
                  >
                    {apiData?.currentPeriodTotal?.failed}
                  </p>
                </div>
              </div>
              {apiData && <LineGraphWithData className="" compareData={apiData?.dailyData} />}
              <div className={"flex justify-between items-center -mt-4 px-4"}>
                <label className={"text-[10px] primary-text  leading-[14px]"}>
                  {DateTime.now().minus({days: 30}).toFormat("dd LLL")}
                </label>
                <label className={"text-[10px] primary-text leading-[14px]"}>
                  {formatMessage({ id: "Yesterday" })}
                </label>
              </div>
            </div>
            <div
              className={
                "col-span-12 md:col-span-6 p-3.5 card-bg shadow-default rounded"
              }
            >
              <h3
                className={
                  "text-[16px] leading-[22px] primary-text font-medium mb-2"
                }
              >
                {formatMessage({ id: "API Errors" })}
              </h3>
              <div className={"flex gap-x-6 mb-2"}>
                <div>
                  <label
                    className={"text-[12px] leading-5 primary-text mb-0.5"}
                  >
                    {formatMessage({ id: "GET" })}
                  </label>
                  <p
                    className={
                      "text-[16px] leading-5 secondary-text font-medium"
                    }
                  >
                    {apiData?.currentPeriodTotal?.failed_get_requests}
                  </p>
                </div>
                <div>
                  <label
                    className={"text-[12px] leading-5 primary-text mb-0.5"}
                  >
                    {formatMessage({ id: "POST" })}
                  </label>
                  <p
                    className={
                      "text-[16px] leading-5 secondary-text font-medium"
                    }
                  >
                    {apiData?.currentPeriodTotal?.failed_post_requests}
                  </p>
                </div>
                <div>
                  <label
                    className={"text-[12px] leading-5 primary-text mb-0.5"}
                  >
                    {formatMessage({ id: "DELETE" })}
                  </label>
                  <p
                    className={
                      "text-[16px] leading-5 secondary-text font-medium"
                    }
                  >
                    {apiData?.currentPeriodTotal?.failed_delete_requests}
                  </p>
                </div>
              </div>
              {apiData && <LineGraphWithData className="" compareData={apiData?.failedData} />}
              <div className={"flex justify-between items-center -mt-4 px-4"}>
                <label className={"text-[10px] primary-text leading-[14px]"}>
                {DateTime.now().minus({days: 30}).toFormat("dd LLL")}
                </label>
                <label className={"text-[10px] primary-text leading-[14px]"}>
                  {formatMessage({ id: "Yesterday" })}
                </label>
              </div>
            </div>
            {/* <div
              className={
                "col-span-12 md:col-span-6 p-3.5 card-bg shadow-default rounded"
              }
            >
              <h3
                className={
                  "text-[16px] leading-[22px] primary-text font-medium mb-2"
                }
              >
                {formatMessage({ id: "Webhooks" })}
              </h3>
              <div className={"flex gap-x-6 mb-2"}>
                <div>
                  <label
                    className={"text-[12px] leading-5 primary-text mb-0.5"}
                  >
                    {formatMessage({ id: "Successful" })}
                  </label>
                  <p
                    className={
                      "text-[16px] leading-5 secondary-text font-medium"
                    }
                  >
                    11k
                  </p>
                </div>
                <div>
                  <label
                    className={"text-[12px] leading-5 primary-text mb-0.5"}
                  >
                    {formatMessage({ id: "Failed" })}
                  </label>
                  <p
                    className={
                      "text-[16px] leading-5 secondary-text font-medium"
                    }
                  >
                    50
                  </p>
                </div>
              </div>
              <LineGraph />
              <div className={"flex justify-between items-center -mt-4 px-4"}>
                <label className={"text-[10px] primary-text leading-[14px]"}>
                  25 Oct
                </label>
                <label className={"text-[10px] primary-text leading-[14px]"}>
                  {formatMessage({ id: "Yesterday" })}
                </label>
              </div>
            </div> */}
            {/* <div
              className={
                "col-span-12 md:col-span-6 p-3.5 card-bg shadow-default rounded"
              }
            >
              <h3
                className={
                  "text-[16px] leading-[22px] primary-text font-medium mb-2"
                }
              >
                {formatMessage({ id: "Webhooks response time" })}
              </h3>
              <div className={"flex gap-x-6 mb-2"}>
                <div>
                  <label
                    className={"text-[12px] leading-5 primary-text mb-0.5"}
                  >
                    {formatMessage({ id: "Min" })}
                  </label>
                  <p
                    className={
                      "text-[16px] leading-5 secondary-text font-medium"
                    }
                  >
                    450ms
                  </p>
                </div>
                <div>
                  <label
                    className={"text-[12px] leading-5 primary-text mb-0.5"}
                  >
                    {formatMessage({ id: "Avg" })}
                  </label>
                  <p
                    className={
                      "text-[16px] leading-5 secondary-text font-medium"
                    }
                  >
                    1.2s
                  </p>
                </div>
                <div>
                  <label
                    className={"text-[12px] leading-5 primary-text mb-0.5"}
                  >
                    {formatMessage({ id: "Max" })}
                  </label>
                  <p
                    className={
                      "text-[16px] leading-5 secondary-text font-medium"
                    }
                  >
                    5.3s
                  </p>
                </div>
              </div>
              <LineGraph />
              <div className={"flex justify-between items-center -mt-4 px-4"}>
                <label className={"text-[10px] primary-text leading-[14px]"}>
                  25 Oct
                </label>
                <label className={"text-[10px] primary-text leading-[14px]"}>
                  {formatMessage({ id: "Yesterday" })}
                </label>
              </div>
            </div> */}
          </div>
          <div className={"col-span-12 md:col-span-4"}>
            <div
              className={
                "p-3.5 card-bg shadow-default rounded h-full flex flex-col justify-between"
              }
            >
              <div>
                <h3
                  className={
                    "text-[16px] leading-[22px] primary-text font-medium mb-6"
                  }
                >
                  {formatMessage({ id: "Errors" })}
                </h3>
                <div className={"h-[50vh] overflow-y-auto custom-scroll mb-4 relative"}>
                  <ul className={"pr-4"}>
                    {loading ? <div className="w-full absolute top-0 left-0 h-full min-h-[200px] flex justify-content-center align-items-center">
                      <Spinner animation="border" />
                    </div> : !errors.length ? <Empty heading={formatMessage({ id: "No errors found"})} content={formatMessage({ id: "There were no errors"})}/>
                    :<>
                    {map(errors, ({httpMethod, endpoint, apiVersion, createdAt}) => (
                      <li className={"border-b main-border py-2"}>
                        {/* <h3
                          className={
                            "text-[14px] leading-5 secondary-text font-medium mb-3"
                          }
                        >
                          {formatMessage({
                            id: "parameter_unknown - level3",
                          })}
                        </h3> */}
                        <div
                          className={
                            "flex items-start gap-x-[32px] primary-text text-[12px] leading-[18px]"
                          }
                        >
                          <p>
                              {httpMethod?.toUpperCase()} {apiVersion} {new URL(endpoint).pathname} confirm 1 error",
                          </p>
                          <p> {getDateValue(createdAt)} </p>
                        </div>
                      </li>
                    ))}
                    </>
                    }
                  </ul>
                </div>
              </div>
              <div className={"flex justify-end "}>
                <Link
                  to={"/apis?tab=3&type=failed"}
                  className={
                    "text-[13px] leading-[18px] main-text font-medium flex items-center gap-x-2"
                  }
                >
                  {formatMessage({ id: "Show All" })}
                  <svg
                    width="4"
                    height="9"
                    viewBox="0 0 4 9"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M3.93586 4.57889C4.03888 4.77912 4.01959 5.0363 3.87186 5.21403L0.87202 8.82282C0.68647 9.04603 0.37 9.06026 0.16517 8.8546C-0.0396605 8.64893 -0.0552907 8.30126 0.13026 8.07804L2.80643 4.85863L0.15635 1.93583C-0.0384703 1.72096 -0.0378604 1.37314 0.15771 1.15895C0.35328 0.944759 0.66976 0.94531 0.86458 1.16018L3.85243 4.45551C3.88639 4.49296 3.9142 4.53459 3.93586 4.57889Z"
                      fill="#C2D24B"
                    />
                  </svg>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Overview;
