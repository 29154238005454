import React, { useState } from "react";
import TextInput from "../../../widgets/components/Input/TextInput";
import { useIntl } from "react-intl";
import { FieldArray, Form, Formik } from "formik";
import TextArea from "../../../widgets/components/Input/TextArea";
import { InfoCard } from "../../../widgets/components/UI/InfoCard";
import FormikReactSelect from "../../../widgets/components/Input/FormikReactSelect";
import * as Yup from "yup";
import { useAuth } from "../../../auth";
import { SelectInput } from "../../../widgets/components/Input/SelectInput";
import { get, map } from "lodash";
import Select from "../../../../components/select/select";
import FormikReactCreatable from "../../../widgets/components/Input/FormikReactCreatable";
import { updatePersonalityInfo } from "../../../profile/core/_requests";
import { toast } from "react-toastify";
import useGTM from "../../../../hooks/useGTM";
import { GTMEvent } from "../../../../hooks/gtm_helpers";

const Identity: React.FC<any> = ({ setOpenEdit, personalityData }) => {
  const { formatMessage } = useIntl();
  const {dataLayerPush} = useGTM()
  const {
    referenceData,
    setSelectedPersonality,
    selectedPersonality: updatedData,
  } = useAuth();
  personalityData = updatedData || personalityData;

  const [disableSave, setDisableSave] = useState(false);

  const onSubmit = async (values: any, { setSubmitting }: any) => {
    let requestData: any = {
      stage_in_life_of_this_ai_personality:
        values.stage_in_life_of_this_ai_personality,
      professional_role: values.professional_role,
      alternative_names_of_this_ai_personality:
        values.alternative_names_of_this_ai_personality,
      wikipedia_link: values.wikipedia_link,
      visual_appearance_of_this_ai_personality:
        values.visual_appearance_of_this_ai_personality,
      racial_information_of_this_ai_personality:
        values.racial_information_of_this_ai_personality,
      relationship_status_of_this_ai_personality:
        values.relationship_status_of_this_ai_personality,
      family_members_of_this_ai_personality:
        values.family_members_of_this_ai_personality,
      additional_family_details_of_this_ai_personality:
        values.additional_family_details_of_this_ai_personality,
      professional_life_details_of_this_ai_personality:
        values.professional_life_details_of_this_ai_personality,
    };
    // remove undefined or null values
    Object.keys(requestData).forEach((key) => {
      if (requestData[key] === null || requestData[key] == "undefined") {
        delete requestData[key];
      }
    });
    // save data to api
    setDisableSave(true);
    setSubmitting(false);
    try {
      let {
        data: { success, data },
      } = await updatePersonalityInfo({
        personalityId: personalityData.personalityId,
        personalityJson: {
          ...personalityData.personalityJson,
          identity: requestData,
        },
      });
      if (success) {
        setSelectedPersonality(data);
        toast.success(
          formatMessage({ id: "Identity details updated successfully" })
        );
      } else {
        setSubmitting(false);
        toast.error(
          formatMessage({ id: "Error when updating Identity details" }),
          {
            autoClose: false,
            closeButton: true, // Display close button (cross icon)
          }
        );
      }
      setDisableSave(false);
      dataLayerPush(GTMEvent.AIPersonalityEditSuccessful,{"tab": "identity-details"})
    } catch (e) {
      toast.error(
        formatMessage({ id: "Error when updating Identity details" }),
        {
          autoClose: false,
          closeButton: true, // Display close button (cross icon)
        }
      );
      setSubmitting(false);
      setDisableSave(false);
      dataLayerPush(GTMEvent.AIPersonalityEditFailed,{"tab": "identity-details"})
    }
  };

  const identityValidationSchema = Yup.object().shape({
    stage_in_life_of_this_ai_personality: Yup.string().oneOf([
      "Infancy (0-2 years)",
      "Early Childhood (3-5 years)",
      "Middle Childhood (6-12 years)",
      "Adolescence (13-19 years)",
      "Young Adulthood (20-29 years)",
      "Early Adulthood (30-39 years)",
      "Middle Adulthood (40-59 years)",
      "Late Adulthood (60+ years)",
      "Retirement (65+ years)",
      "Senior Years (80+ years)",
      "Golden Years (90+ years)",
      "Not Applicable",
    ]),
    // .required("Stage in life is required")
    professional_role: Yup.string()
      // .min(5, "Professional role must be at least 5 characters")
      .max(200, "Professional role cannot exceed 200 characters"),
    // .required("Professional role is required")
    alternative_names_of_this_ai_personality: Yup.array()
      .of(
        Yup.string()
          .min(2, "Alternative name must be at least 2 characters")
          .max(100, "Alternative name cannot exceed 100 characters")
      )
      .max(5, "Maximum of 5 alternative names allowed"),
    wikipedia_link: Yup.string()
      // .min(15, "Wikipedia link must be at least 15 characters")
      .max(500, "Wikipedia link cannot exceed 500 characters"),
    visual_appearance_of_this_ai_personality: Yup.string()
      // .min(5, "Visual appearance must be at least 5 characters")
      .max(500, "Visual appearance cannot exceed 500 characters"),
    racial_information_of_this_ai_personality: Yup.array()
      .of(
        Yup.string().oneOf([
          "African/Black",
          "Asian",
          "Caucasian/White",
          "Chinese",
          "Hispanic/Latinx",
          "Indian",
          "Middle Eastern",
          "Mixed/Multiracial",
          "Native American/First Nations",
          "Not Applicable",
          "Pacific Islander",
          "Other",
        ])
      )
      // .min(1, "At least one racial information is required")
      .max(5, "Maximum of 5 racial information allowed"),
    relationship_status_of_this_ai_personality: Yup.string().oneOf([
      "Divorced",
      "Engaged",
      "In a civil union",
      "In a domestic partnership",
      "In a relationship",
      "It's complicated",
      "Married",
      "Single",
      "Widowed",
      "Not applicable",
      "Other",
    ]),
    family_members_of_this_ai_personality: Yup.array()
      .of(
        Yup.object().shape({
          name: Yup.string()
            .min(2, "Family member name must be at least 2 characters")
            .max(100, "Family member name cannot exceed 100 characters"),
          gender: Yup.string()
            .min(4, "Family member gender must be at least 4 characters")
            .max(10, "Family member gender cannot exceed 10 characters"),
          age: Yup.number()
            .integer("Family member age must be an integer")
            .min(0, "Family member age must be at least 0")
            .max(150, "Family member age cannot exceed 150"),
          relationship_with_ai_personality: Yup.string().oneOf([
            "Aunt",
            "Boss",
            "Boyfriend",
            "Brother",
            "Colleague",
            "Cousin",
            "Daughter",
            "Father",
            "Fiancé",
            "Fiancée",
            "Friend",
            "Girlfriend",
            "Granddaughter",
            "Grandfather",
            "Grandmother",
            "Grandson",
            "Husband",
            "Mentor",
            "Mother",
            "Neighbor",
            "Nephew",
            "Niece",
            "Partner",
            "Sister",
            "Son",
            "Spouse",
            "Student",
            "Teacher",
            "Uncle",
            "Wife",
            "Family Pet",
            "Other",
          ]),
          relationship_status: Yup.string()
            .min(
              2,
              "Family member relationship status must be at least 2 characters"
            )
            .max(
              50,
              "Family member relationship status cannot exceed 50 characters"
            ),
          married_to: Yup.string()
            .min(2, "Family member's spouse name must be at least 2 characters")
            .max(
              100,
              "Family member's spouse name cannot exceed 100 characters"
            ),
        })
      )
      // .min(1, "At least one family member is required")
      .max(10, "Maximum of 10 family members allowed"),
    additional_family_details_of_this_ai_personality: Yup.string()
      // .min(10, "Additional family details must be at least 10 characters")
      .max(500, "Additional family details cannot exceed 500 characters"),
    professional_life_details_of_this_ai_personality: Yup.string()
      // .min(10, "Professional life details must be at least 10 characters")
      .max(500, "Professional life details cannot exceed 500 characters"),
  });

  // const familyMembersFields = {
  //   name: "",
  //   gender: "",
  //   age: "",
  //   relationshipStatusAi: "",
  //   relationshipStatus: "",
  //   marriedTo: "",
  // };
  return (
    <Formik
      initialValues={personalityData?.personalityJson?.identity || {}}
      // initialValues={{
      //   relationStatus: "married",
      //   name: "",
      //   gender: "",
      //   age: "",
      //   relationshipStatusAi: "",
      //   relationshipStatus: "",
      //   marriedTo: "",
      //   familyMembers: [],
      // }}
      onSubmit={onSubmit}
      validationSchema={identityValidationSchema}
      validateOnMount
    >
      {(formik) => {
        return (
          <Form>
            <div className={"grid grid-cols-12 gap-y-[20px] md:gap-x-[40px]"}>
              <div className={"col-span-12 md:col-span-7 order-0"}>
                <FormikReactSelect
                  label={formatMessage({
                    id: "Stage in life",
                  })}
                  tooltipText={formatMessage({
                    id: "GLOBAL.TOOLTIP.EDIT_AI_PERSONALITY.IDENTITY.STAGE_IN_LIFE",
                  })}
                  name={"stage_in_life_of_this_ai_personality"}
                  id={"stage_in_life_of_this_ai_personality"}
                  isClearable={false}
                  defaultValue={{
                    value: "Teenager",
                    label: "Teenager",
                  }}
                  options={referenceData?.personalityOptions?.stageInLife?.map(
                    (el: string, index: number) => {
                      return { value: el, label: el, id: index };
                    }
                  )}
                />
                <TextInput
                  fieldType={"text"}
                  fieldName={"professional_role"}
                  value={
                    "Tell me about your experience on working on Circus sets"
                  }
                  formik={formik}
                  placeholder={formatMessage({
                    id: "You can assign roles like doctor, artist or airplane pilot",
                  })}
                  label={formatMessage({ id: "Role" })}
                  toolTipText={formatMessage({
                    id: "GLOBAL.TOOLTIP.EDIT_AI_PERSONALITY.IDENTITY.ROLE",
                  })}
                />
                <FormikReactCreatable
                  tooltipText={formatMessage({
                    id: "GLOBAL.TOOLTIP.EDIT_AI_PERSONALITY.IDENTITY.ALTERNATIVE_NAME",
                  })}
                  isMulti={true}
                  label={formatMessage({ id: "Alternative Names" })}
                  name={"alternative_names_of_this_ai_personality"}
                  isClearable={true}
                  formik={formik}
                  placeholder={formatMessage({
                    id: "Type something and press Enter or Tab to create name",
                  })}
                  // menuIsOpen={false}
                  defaultValue={formik.values?.alternative_names_of_this_ai_personality?.map(
                    (el: string, index: number) => {
                      return { value: el, label: el, id: index };
                    }
                  )}
                />
                <TextInput
                  isFieldArray={true}
                  fieldType={"text"}
                  fieldName={"wikipedia_link"}
                  value={
                    "Tell me about your experience on working on Circus sets"
                  }
                  formik={formik}
                  placeholder={formatMessage({
                    id: "If your character is any famous personality add his wikipedia page here",
                  })}
                  label={formatMessage({ id: "Wikipedia link" })}
                  toolTipText={formatMessage({
                    id: "GLOBAL.TOOLTIP.EDIT_AI_PERSONALITY.IDENTITY.WIKIPEDIA_LINK",
                  })}
                />
                <TextArea
                  label={formatMessage({ id: "Visual Appearance" })}
                  fieldName={"visual_appearance_of_this_ai_personality"}
                  placeholder={formatMessage({
                    id: "Explain briefly how the personality looks like in the real world",
                  })}
                  formik={formik}
                  toolTipText={formatMessage({
                    id: "GLOBAL.TOOLTIP.EDIT_AI_PERSONALITY.IDENTITY.VISUAL_APPEARANCE",
                  })}
                  className={"!min-h-[107px]"}
                />
                <FormikReactSelect
                  tooltipText={formatMessage({
                    id: "GLOBAL.TOOLTIP.EDIT_AI_PERSONALITY.IDENTITY.RACIAL_INFORMATION",
                  })}
                  isMulti={true}
                  name={"racial_information_of_this_ai_personality"}
                  label={formatMessage({ id: "Racial Information" })}
                  isClearable={false}
                  formik={formik}
                  options={referenceData?.personalityOptions?.racialInformation?.map(
                    (el: string, index: number) => {
                      return { value: el, label: el, id: index };
                    }
                  )}
                />
                <FormikReactSelect
                  isMulti={false}
                  tooltipText={formatMessage({
                    id: "GLOBAL.TOOLTIP.EDIT_AI_PERSONALITY.IDENTITY.RELATIONSHIP_STATUS",
                  })}
                  name={"relationship_status_of_this_ai_personality"}
                  label={formatMessage({ id: "Relationship Status" })}
                  isClearable={false}
                  formik={formik}
                  defaultValue={{ value: "married", label: "Married" }}
                  options={referenceData?.personalityOptions?.relationshipStatus?.map(
                    (el: string, index: number) => {
                      return { value: el, label: el, id: index };
                    }
                  )}
                />
                <h4
                  className={
                    "text-[16px] leading-6 font-medium primary-text mb-[16px]"
                  }
                >
                  {formatMessage({ id: "Family Members (if any)" })}
                </h4>
                <div
                  className={
                    "tab-container-2  p-[14px] md:p-[12px_24px_14px_24px] rounded mt-[20px] table-responsive"
                  }
                >
                  {/* <div className="table-responsive"> */}
                  <table className={"w-full"}>
                    <tbody>
                      {map(
                        formik.values?.family_members_of_this_ai_personality ||
                        [],
                        (item, index: number) => (
                          <tr className="text-[#FFFFFFCC] text-[14px] leading-[20px] border-b border-solid border-[#2E2F45] p-[12px_0px_8px_0px] h-[58px] whitespace-nowrap">
                            <td className="px-[8px]">{get(item, "name")}</td>
                            <td className="px-[8px]">{get(item, "age")}y</td>
                            <td className="px-[8px]">{get(item, "gender")}</td>
                            <td className="px-[8px]">
                              {get(item, "relationship_with_ai_personality")}
                            </td>
                            <td className="px-[8px]">
                              {get(item, "relationship_status")}
                            </td>
                            <td className="px-[8px]">
                              {get(item, "married_to")}
                            </td>
                            <td className="px-[8px]">
                              <div className={"flex justify-end gap-[12px]  "}>
                                <button
                                  type="button"
                                  className={
                                    "p-[6px] bg-[#FFFFFF1A] rounded-[6px]"
                                  }
                                  onClick={() => {
                                    formik.values.family_members_of_this_ai_personality.splice(
                                      index,
                                      1
                                    );
                                    formik.setFieldValue(
                                      "family_members_of_this_ai_personality",
                                      formik.values
                                        .family_members_of_this_ai_personality
                                    );
                                  }}
                                >
                                  <svg
                                    width="24"
                                    height="24"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      d="M4 7H20M10 11V17M14 11V17M5 7L6 19C6 19.5304 6.21071 20.0391 6.58579 20.4142C6.96086 20.7893 7.46957 21 8 21H16C16.5304 21 17.0391 20.7893 17.4142 20.4142C17.7893 20.0391 18 19.5304 18 19L19 7M9 7V4C9 3.73478 9.10536 3.48043 9.29289 3.29289C9.48043 3.10536 9.73478 3 10 3H14C14.2652 3 14.5196 3.10536 14.7071 3.29289C14.8946 3.48043 15 3.73478 15 4V7"
                                      stroke="#FFFFFFA6"
                                      strokeWidth="1.6"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                    />
                                  </svg>
                                </button>
                                {/* <button type="button" 
                                className={
                                  "p-[6px] bg-[#FFFFFF1A] rounded-[6px]"
                                }
                              >
                                <svg
                                  width="24"
                                  height="24"
                                  viewBox="0 0 24 24"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <g clipPath="url(#clip0_344_35631)">
                                    <path
                                      d="M17 21H7M17 21H17.803C18.921 21 19.48 21 19.907 20.782C20.284 20.59 20.59 20.284 20.782 19.908C21 19.481 21 18.921 21 17.803V9.22C21 8.77 21 8.545 20.952 8.331C20.9094 8.14007 20.839 7.95643 20.743 7.786C20.637 7.596 20.487 7.431 20.193 7.104L17.438 4.042C17.097 3.664 16.924 3.472 16.717 3.334C16.5303 3.21012 16.3241 3.11851 16.107 3.063C15.863 3 15.6 3 15.075 3H6.2C5.08 3 4.52 3 4.092 3.218C3.71565 3.40969 3.40969 3.71565 3.218 4.092C3 4.52 3 5.08 3 6.2V17.8C3 18.92 3 19.48 3.218 19.907C3.41 20.284 3.715 20.59 4.092 20.782C4.519 21 5.079 21 6.197 21H7M17 21V17.197C17 16.079 17 15.519 16.782 15.092C16.5899 14.7156 16.2836 14.4096 15.907 14.218C15.48 14 14.92 14 13.8 14H10.2C9.08 14 8.52 14 8.092 14.218C7.71565 14.4097 7.40969 14.7157 7.218 15.092C7 15.52 7 16.08 7 17.2V21M15 7H9"
                                      stroke="#FFFFFFA6"
                                      strokeWidth="1.6"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                    />
                                  </g>
                                  <defs>
                                    <clipPath id="clip0_344_35631">
                                      <rect
                                        width="24"
                                        height="24"
                                        fill="white"
                                      />
                                    </clipPath>
                                  </defs>
                                </svg>
                              </button> */}
                              </div>
                            </td>
                          </tr>
                        )
                      )}
                    </tbody>
                  </table>
                  {/* </div> */}
                </div>
                <div className={"tab-container-2 p-[14px]  md:p-[20px] rounded my-[20px]"}>
                  <div>
                    <div className={"grid grid-cols-12 md:gap-x-8 items-end"}>
                      <div className={"col-span-12 md:col-span-6"}>
                        <TextInput
                          isFieldArray={true}
                          fieldType={"text"}
                          fieldName={"name"}
                          formik={formik}
                          placeholder={formatMessage({ id: "Enter Name" })}
                          label={formatMessage({
                            id: "Name",
                          })}
                          toolTipText={formatMessage({
                            id: "GLOBAL.TOOLTIP.EDIT_AI_PERSONALITY.IDENTITY.ENTER_NAME",
                          })}
                          isStarRequired={true}
                        />
                      </div>
                      <div className={"col-span-12 md:col-span-6"}>
                        <FormikReactSelect
                          isFieldArray={true}
                          name={"gender"}
                          formik={formik}
                          placeholder={formatMessage({ id: "Select Gender" })}
                          label={formatMessage({
                            id: "Gender",
                          })}
                          tooltipText={formatMessage({
                            id: "GLOBAL.TOOLTIP.EDIT_AI_PERSONALITY.IDENTITY.ENTER_GENDER",
                          })}
                          isStarRequired={true}
                          options={[
                            { label: "Male", value: "male" },
                            { label: "Female", value: "female" },
                          ]}
                        />
                        {/*<SelectInput*/}
                        {/*  isFieldArray={true}*/}
                        {/*  fieldName={"gender"}*/}
                        {/*  formik={formik}*/}
                        {/*  placeholder={formatMessage({ id: "Enter Gender" })}*/}
                        {/*  label={formatMessage({*/}
                        {/*    id: "Gender",*/}
                        {/*  })}*/}
                        {/*  toolTipText={formatMessage({*/}
                        {/*    id: "GLOBAL.TOOLTIP.EDIT_AI_PERSONALITY.IDENTITY.ENTER_GENDER",*/}
                        {/*  })}*/}
                        {/*  isStarRequired={true}*/}
                        {/*  options={[*/}
                        {/*    { name: "Male", value: "male" },*/}
                        {/*    { name: "Female", value: "female" },*/}
                        {/*  ]}*/}
                        {/*/>*/}
                      </div>
                      <div className={"col-span-12 md:col-span-6"}>
                        {/* <TextInput
                          isFieldArray={true}
                          fieldType={"number"}
                          fieldName={"age"}
                          formik={formik}
                          placeholder={formatMessage({ id: "Enter age" })}
                          label={formatMessage({
                            id: "Age",
                          })}
                          toolTipText={formatMessage({
                            id: "GLOBAL.TOOLTIP.EDIT_AI_PERSONALITY.IDENTITY.ENTER_AGE",
                          })}
                          isStarRequired={true}
                        /> */}
                        <FormikReactSelect
                          isFieldArray={true}
                          name={"age"}
                          formik={formik}
                          label={formatMessage({
                            id: "Age",
                          })}
                          placeholder={formatMessage({
                            id: "Enter age",
                          })}
                          tooltipText={formatMessage({
                            id: "GLOBAL.TOOLTIP.EDIT_AI_PERSONALITY.IDENTITY.ENTER_AGE",
                          })}
                          isStarRequired={true}
                          options={[
                            { "value": "40", "label": "40 years" },
                            { "value": "41", "label": "41 years" },
                            { "value": "42", "label": "42 years" },
                            { "value": "43", "label": "43 years" },
                            { "value": "44", "label": "44 years" },
                            { "value": "45", "label": "45 years" },
                            { "value": "46", "label": "46 years" },
                            
                          ]}
                        />
                      </div>
                      <div className={"col-span-12 md:col-span-6"}>
                        <FormikReactSelect
                          isFieldArray={true}
                          name={"relationshipStatusAi"}
                          formik={formik}
                          label={formatMessage({
                            id: "Relationship with AI Personality",
                          })}
                          placeholder={formatMessage({
                            id: "Select Relationship with AI Personality",
                          })}
                          tooltipText={formatMessage({
                            id: "GLOBAL.TOOLTIP.EDIT_AI_PERSONALITY.IDENTITY.ENTER_RELATIONSHIP",
                          })}
                          isStarRequired={true}
                          options={[
                            { "value": "Aunt", "label": "Aunt" },
                            { "value": "Boss", "label": "Boss" },
                            { "value": "Boyfriend", "label": "Boyfriend" },
                            { "value": "Brother", "label": "Brother" },
                            { "value": "Colleague", "label": "Colleague" },
                            { "value": "Cousin", "label": "Cousin" },
                            { "value": "Daughter", "label": "Daughter" },
                            { "value": "Father", "label": "Father" },
                            { "value": "Fiancé", "label": "Fiancé" },
                            { "value": "Fiancée", "label": "Fiancée" },
                            { "value": "Friend", "label": "Friend" },
                            { "value": "Girlfriend", "label": "Girlfriend" },
                            { "value": "Granddaughter", "label": "Granddaughter" },
                            { "value": "Grandfather", "label": "Grandfather" },
                            { "value": "Grandmother", "label": "Grandmother" },
                            { "value": "Grandson", "label": "Grandson" },
                            { "value": "Husband", "label": "Husband" },
                            { "value": "Mentor", "label": "Mentor" },
                            { "value": "Mother", "label": "Mother" },
                            { "value": "Neighbor", "label": "Neighbor" },
                            { "value": "Nephew", "label": "Nephew" },
                            { "value": "Niece", "label": "Niece" },
                            { "value": "Partner", "label": "Partner" },
                            { "value": "Sister", "label": "Sister" },
                            { "value": "Son", "label": "Son" },
                            { "value": "Spouse", "label": "Spouse" },
                            { "value": "Student", "label": "Student" },
                            { "value": "Teacher", "label": "Teacher" },
                            { "value": "Uncle", "label": "Uncle" },
                            { "value": "Wife", "label": "Wife" },
                            { "value": "Family Pet", "label": "Family Pet" },
                            { "value": "Other", "label": "Other" }
                          ]}
                        />
                        {/*<SelectInput*/}
                        {/*  isFieldArray={true}*/}
                        {/*  fieldName={"relationshipStatusAi"}*/}
                        {/*  formik={formik}*/}
                        {/*  placeholder={formatMessage({*/}
                        {/*    id: "Enter Relationship with AI Personality",*/}
                        {/*  })}*/}
                        {/*  label={formatMessage({*/}
                        {/*    id: "Relationship with AI Personality",*/}
                        {/*  })}*/}
                        {/*  toolTipText={formatMessage({*/}
                        {/*    id: "GLOBAL.TOOLTIP.EDIT_AI_PERSONALITY.IDENTITY.ENTER_RELATIONSHIP",*/}
                        {/*  })}*/}
                        {/*  isStarRequired={true}*/}
                        {/*  options={[*/}
                        {/*    { label: "Husband", value: "husband" },*/}
                        {/*    { label: "Wife", value: "wife" },*/}
                        {/*    { label: "Son", value: "son" },*/}
                        {/*  ]}*/}
                        {/*/>*/}
                      </div>
                      <div className={"col-span-12 md:col-span-6"}>
                        <FormikReactSelect
                          isFieldArray={true}
                          fieldType="text"
                          name={"relationshipStatus"}
                          formik={formik}
                          placeholder={formatMessage({
                            id: "Select Relationship status",
                          })}
                          label={formatMessage({
                            id: "Relationship Status",
                          })}
                          toolTipText={formatMessage({
                            id: "GLOBAL.TOOLTIP.EDIT_AI_PERSONALITY.IDENTITY.RELATIONSHIP_STATUS_OF_FAMILY",
                          })}
                          options={[
                            { "value": "Divorced", "label": "Divorced" },
                            { "value": "Engaged", "label": "Engaged" },
                            { "value": "In a civil union", "label": "In a civil union" },
                            { "value": "In a domestic partnership", "label": "In a domestic partnership" },
                            { "value": "In a relationship", "label": "In a relationship" },
                            { "value": "It's complicated", "label": "It's complicated" },
                            { "value": "Married", "label": "Married" },
                            { "value": "Single", "label": "Single" },
                            { "value": "Widowed", "label": "Widowed" },
                            { "value": "Not applicable", "label": "Not applicable" },
                            { "value": "Other", "label": "Other" }
                          ]}

                        />
                      </div>
                      <div className={"col-span-12 md:col-span-6"}>
                        <TextInput
                          isFieldArray={true}
                          fieldType={"text"}
                          fieldName={"marriedTo"}
                          formik={formik}
                          placeholder={formatMessage({
                            id: "This Family member is married to",
                          })}
                          value={"NA"}
                          label={formatMessage({
                            id: "This Family member is married to",
                          })}
                          toolTipText={formatMessage({
                            id: "GLOBAL.TOOLTIP.EDIT_AI_PERSONALITY.IDENTITY.FAMILY_MEMBER_MARRIED",
                          })}
                        />
                      </div>
                    </div>
                  </div>
                  <div className={"flex justify-end mt-[16px]"}>
                    <button
                      type="button"
                      className="bg-[#C2D24B1A]  text-[#C2D24B] text-[14px] leading-[20px] font-medium py-[8px] w-1/2 md:w-[115px] rounded me-[14px]"
                      onClick={() => {
                        formik.setFieldValue("name", "")
                        formik.setFieldValue("gender", "")
                        formik.setFieldValue("age", "")
                        formik.setFieldValue("relationshipStatusAi", "")
                        formik.setFieldValue("relationshipStatus", "")
                        formik.setFieldValue("marriedTo", "")
                      }}
                    >
                      {formatMessage({ id: "Reset" })}
                    </button>
                    <button
                      type="button"
                      className={
                        "bg-[#C2D24B] font-medium text-black text-[13px] leading-5  py-[8px] w-1/2  md:w-[150px] rounded"
                      }
                      onClick={() => {
                        if (
                          !(
                            formik.values.name &&
                            formik.values.gender &&
                            formik.values.age &&
                            formik.values.relationshipStatusAi
                            // formik.values.relationshipStatus &&
                            // formik.values.marriedTo
                          )
                        ) {
                          toast.error(
                            formatMessage({
                              id: "Please fill mandatory fields to add a family member",
                            }),
                            {
                              autoClose: false,
                              closeButton: true, // Display close button (cross icon)
                            }
                          );
                          return;
                        }
                        const newFamilyMember = [
                          ...(formik.values
                            ?.family_members_of_this_ai_personality || []),
                          {
                            name: formik.values.name,
                            gender: formik.values.gender,
                            age: formik.values.age,
                            relationship_with_ai_personality:
                              formik.values.relationshipStatusAi,
                            relationship_status:
                              formik.values.relationshipStatus,
                            married_to: formik.values.marriedTo,
                          },
                        ];
                        formik.setFieldValue(
                          "family_members_of_this_ai_personality",
                          newFamilyMember
                        );
                        formik.setFieldValue("name", "")
                        formik.setFieldValue("gender", "")
                        formik.setFieldValue("age", "")
                        formik.setFieldValue("relationshipStatusAi", "")
                        formik.setFieldValue("relationshipStatus", "")
                        formik.setFieldValue("marriedTo", "")
                      }}
                    >
                      {formatMessage({ id: "Add Family Member" })}
                    </button>
                  </div>
                </div>
                {/* <div className="mt-[12px] mb-[24px] text-right">
                  <button className="bg-[#C2D24B] text-black text-[13px] leading-[20px] font-medium  p-[12px_24px] w-full  md:w-[fit-content] rounded">
                    Add New Family Member
                  </button>
                </div> */}

                <TextArea
                  label={formatMessage({ id: "Generic details about family" })}
                  fieldName={"additional_family_details_of_this_ai_personality"}
                  placeholder={formatMessage({
                    id: "Details of the AI Personality’s family. You can leave it blank if you want to.",
                  })}
                  formik={formik}
                  toolTipText={formatMessage({
                    id: "GLOBAL.TOOLTIP.EDIT_AI_PERSONALITY.IDENTITY.GENERIC_DETAILS_ABOUT_FAMILY",
                  })}
                  className={"!min-h-[107px] "}
                />
                <TextArea
                  label={formatMessage({
                    id: "Professional Life Details, if any",
                  })}
                  fieldName={"professional_life_details_of_this_ai_personality"}
                  placeholder={formatMessage({
                    id: "Details of the AI Personality’s professional life. You can leave it blank if you want to.",
                  })}
                  formik={formik}
                  toolTipText={formatMessage({
                    id: "GLOBAL.TOOLTIP.EDIT_AI_PERSONALITY.IDENTITY.PROFESSIONAL_LIFE_DETAILS",
                  })}
                  className={"!min-h-[107px]"}
                />
              </div>

              <div
                className={
                  "col-span-12 md:col-span-5 flex flex-col justify-start gap-[20px] "
                }
              >
                <div
                  className={
                    "flex justify-end gap-[10px] md:order-1 md:mt-auto"
                  }
                >
                  {/* <button
                    onClick={() => setOpenEdit(false)}
                    className={
                      "main-button-primary-light text-[14px] leading-5 font-medium py-[12px] w-1/2 md:w-[128px] rounded"
                    }
                  >
                    {formatMessage({
                      id: "Cancel",
                    })}
                  </button> */}
                  <button
                    type="submit"
                    className={`main-button text-[14px] leading-5 font-medium py-[12px] w-full sm:w-1/2  md:w-[140px] rounded ${disableSave ? "opacity-75" : ""
                      }`}
                    disabled={disableSave}
                  >
                    {formatMessage({
                      id: `${disableSave ? "Please Wait" : "Save"}`,
                    })}
                  </button>
                </div>
                <InfoCard
                  title={formatMessage({
                    id: "Understanding Identity of AI Personality",
                  })}
                  desc={
                    <>
                      {formatMessage({
                        id: "In Kamoto.AI, the \"Identity\" section empowers you to shape the identity of your virtual AI persona. You can define its stage of life, whether it's a teenager, adult, or any other stage that fits your persona's story. \n",
                      })}
                      <br></br>
                      <br></br>
                      {formatMessage({
                        id: "Specify its role, such as doctor, actor, or any occupation that reflects its character. Explore alternative names or alias names for your AI persona, adding depth and personality to its identity. \n",
                      })}
                      <br></br>
                      <br></br>
                      {formatMessage({
                        id: "You can even include a Wikipedia link, if available, to provide additional information about your persona. Visual appearance and racial information further enhance the persona's uniqueness. With Kamoto.AI, you have the freedom to create AI personalities with distinct identities, ensuring they stand out in the virtual world.",
                      })}
                      <br></br>
                      <br></br>
                    </>
                  }
                  slug={""}
                  isAnchor={
                    "https://help.kamoto.ai/en/articles/7941782-exploring-the-identity-tab-of-ai-personalities"
                  }
                />
              </div>
            </div>
          </Form>
        );
      }}
    </Formik>
  );
};

export default Identity;
