import React from "react";
import Slider from "rc-slider";
import { useThemeMode } from "../../../_metronic/partials";
import { isEqual } from "lodash";

type Props = {
  startLabel: string;
  stopLabel: string;
  defaultValue?: number;
  min?: number;
  max?: number;
  valueInPoints?: boolean;
  fieldName?: string;
  formik?: any;
  onChange?: any;
};
const Index: React.FC<Props> = ({
  startLabel = "startLabel",
  stopLabel = "stopLabel",
  defaultValue = -1,
  min = 0,
  max = 20,
  valueInPoints = true,
  fieldName,
  formik,
  onChange,
}) => {
  const { mode, } = useThemeMode();
  type DynamicObj = { [key: string | number]: any };
  let marksKeys: DynamicObj = {};
  marksKeys[min] = (
    <label className={"whitespace-nowrap secondary-text"}>{startLabel}</label>
  );
  marksKeys[max] = (
    <label className={"whitespace-nowrap secondary-text"}>{stopLabel}</label>
  );

  const setFormikFieldValue = (value: any) => {
    if (formik && fieldName) {
      formik.setFieldValue(fieldName, valueInPoints ? value / 20 : value);
    }
  };
  // get value from formik field if
  // let defaultValue = 0
  if (defaultValue < 0 && formik && fieldName) {
    defaultValue = getNestedValue(formik.values, fieldName);
    defaultValue = valueInPoints ? defaultValue * 20 : defaultValue;
  }

  return (
    <div className={"my-[20px]"}>
      <Slider
        marks={marksKeys}
        className={"custom-slider"}
        dots={true}
        min={min}
        max={max}
        onChange={onChange || setFormikFieldValue}
        defaultValue={defaultValue}
        railStyle={{
          background: isEqual(mode , 'dark')?"#2E2F45":"#F9F9F9",
          height: 10,
          borderRadius: 2,
        }}
        trackStyle={{
          background: isEqual(mode , 'dark')?"#C2D24B":"#758027",
          height: 10,
          borderRadius: 2,
        }}
        dotStyle={{
          borderRadius: 0,
          width: 0.1,
          boxShadow: "none",
          border: "none",
        }}
        handleStyle={{
          marginTop: -3,
          opacity: 1,
          border: "none",
          height: 16,
          width: 16,
          background: isEqual(mode , 'dark')?"#fff":"#FFFFFF",
          filter:"drop-shadow(0px 1.3333333730697632px 2.6666667461395264px rgba(0, 0, 0, 0.20))"
        }}
      />
    </div>
  );
};

const getNestedValue = (object: any, path: string) => {
  const pathArr = path.split(".");
  let value = object;

  for (let prop of pathArr) {
    if (value && value.hasOwnProperty(prop)) {
      value = value[prop];
    } else {
      value = undefined;
      break;
    }
  }

  return value;
};

export default Index;
