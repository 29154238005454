import { motion, AnimatePresence } from "framer-motion";
import React, { useState } from "react";
const Index: React.FC<any> = ({
  index,
  // expanded,
  // setExpanded,
  title,
  paragraph,
  onDelete = () => {},
}) => {
  const [expanded, setExpanded] = useState();
  const isOpen = index === expanded;
  return (
    <>
      <motion.header initial={false}>
        <div
          className="flex flex-row align-items-center justify-content-between cursor-pointer"
          onClick={() => setExpanded(isOpen ? false : index)}
        >
          <div className="flex flex-row align-items-center">
            <button
              type="button"
              className={
                "min-w-[24px] min-h-[24px] mr-[8px]  rounded-full flex items-center justify-center bg-[#2E2F45]"
              }
            >
              {isOpen ? (
                <svg
                  width="18"
                  height="18"
                  viewBox="0 0 18 18"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g clipPath="url(#clip0_541_11526)">
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M2.25 9C2.25 8.58579 2.58579 8.25 3 8.25H15C15.4142 8.25 15.75 8.58579 15.75 9C15.75 9.41421 15.4142 9.75 15 9.75H3C2.58579 9.75 2.25 9.41421 2.25 9Z"
                      fill="white"
                      fillOpacity="0.65"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_541_11526">
                      <rect width="18" height="18" fill="white" />
                    </clipPath>
                  </defs>
                </svg>
              ) : (
                <svg
                  width="18"
                  height="18"
                  viewBox="0 0 18 18"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g clipPath="url(#clip0_344_24366)">
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M8.25 15C8.25 15.4142 8.58579 15.75 9 15.75C9.41421 15.75 9.75 15.4142 9.75 15V9.75H15C15.4142 9.75 15.75 9.41421 15.75 9C15.75 8.58579 15.4142 8.25 15 8.25H9.75V3C9.75 2.58579 9.41421 2.25 9 2.25C8.58579 2.25 8.25 2.58579 8.25 3V8.25H3C2.58579 8.25 2.25 8.58579 2.25 9C2.25 9.41421 2.58579 9.75 3 9.75H8.25V15Z"
                      fill="white"
                      fillOpacity="0.65"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_344_24366">
                      <rect width="18" height="18" fill="white" />
                    </clipPath>
                  </defs>
                </svg>
              )}
            </button>
            <h3
              className={
                "text-[13px] leading-[20px] primary-text cursor-pointer font-medium flex justify-between items-center"
              }
            >
              {title}
            </h3>
          </div>

          <div className={"flex justify-end gap-[12px]  "}>
            <button
              type="button"
              className={"p-[6px] bg-[#FFFFFF1A] rounded-[6px]"}
              onClick={onDelete}
            >
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M4 7H20M10 11V17M14 11V17M5 7L6 19C6 19.5304 6.21071 20.0391 6.58579 20.4142C6.96086 20.7893 7.46957 21 8 21H16C16.5304 21 17.0391 20.7893 17.4142 20.4142C17.7893 20.0391 18 19.5304 18 19L19 7M9 7V4C9 3.73478 9.10536 3.48043 9.29289 3.29289C9.48043 3.10536 9.73478 3 10 3H14C14.2652 3 14.5196 3.10536 14.7071 3.29289C14.8946 3.48043 15 3.73478 15 4V7"
                  stroke="#FFFFFFA6"
                  strokeWidth="1.6"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </button>
            {/* <button type="button" className={"p-[6px] bg-[#FFFFFF1A] rounded-[6px]"}>
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g clipPath="url(#clip0_344_35631)">
                  <path
                    d="M17 21H7M17 21H17.803C18.921 21 19.48 21 19.907 20.782C20.284 20.59 20.59 20.284 20.782 19.908C21 19.481 21 18.921 21 17.803V9.22C21 8.77 21 8.545 20.952 8.331C20.9094 8.14007 20.839 7.95643 20.743 7.786C20.637 7.596 20.487 7.431 20.193 7.104L17.438 4.042C17.097 3.664 16.924 3.472 16.717 3.334C16.5303 3.21012 16.3241 3.11851 16.107 3.063C15.863 3 15.6 3 15.075 3H6.2C5.08 3 4.52 3 4.092 3.218C3.71565 3.40969 3.40969 3.71565 3.218 4.092C3 4.52 3 5.08 3 6.2V17.8C3 18.92 3 19.48 3.218 19.907C3.41 20.284 3.715 20.59 4.092 20.782C4.519 21 5.079 21 6.197 21H7M17 21V17.197C17 16.079 17 15.519 16.782 15.092C16.5899 14.7156 16.2836 14.4096 15.907 14.218C15.48 14 14.92 14 13.8 14H10.2C9.08 14 8.52 14 8.092 14.218C7.71565 14.4097 7.40969 14.7157 7.218 15.092C7 15.52 7 16.08 7 17.2V21M15 7H9"
                    stroke="#FFFFFFA6"
                    strokeWidth="1.6"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_344_35631">
                    <rect width="24" height="24" fill="white" />
                  </clipPath>
                </defs>
              </svg>
            </button> */}
          </div>
        </div>
      </motion.header>
      <AnimatePresence initial={false}>
        {isOpen && (
          <motion.section
            key="content"
            initial="collapsed"
            animate="open"
            exit="collapsed"
            variants={{
              open: { opacity: 1, height: "auto" },
              collapsed: { opacity: 0, height: 0 },
            }}
            transition={{ duration: 0.8, ease: [0.04, 0.62, 0.23, 0.98] }}
          >
            <p
              className={
                "text-[12px] leading-[18px] secondary-text pt-[8px] pl-[32px] pr-[8px]"
              }
            >
              {paragraph}
            </p>
          </motion.section>
        )}
      </AnimatePresence>
    </>
  );
};

export default Index;
