import React, {useEffect, useState} from 'react';
import SplashScreen from "../components/splash-screen";
import Footer from "../components/footer";
import {Link, useNavigate} from "react-router-dom";

const CallDisconnectPage = () => {
    const navigate = useNavigate()
    const [isLoading, setIsLoading] = useState(true)
    const [isPending, setIsPending] = useState(false)

    useEffect(() => {
        setTimeout(()=>{
            setIsLoading(false)
        },2000)
    }, []);

    if (isLoading){
        return  <SplashScreen/>
    }
    return (
        <div className={'flex flex-col justify-end bg-image-successful h-full w-full'}>
            <div className={'recharge-footer-container px-5 flex flex-col justify-end'}>
                <div className={'recharge-input-container p-3 pb-4'}>
                    <div className="mb-3 flex items-center gap-x-2">
                        <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 48 48" fill="none">
                            <g clipPath="url(#clip0_10666_34637)">
                                <path d="M24.0003 2.66406C19.781 2.66406 15.6564 3.91524 12.1482 6.25938C8.63992 8.60351 5.90557 11.9353 4.2909 15.8335C2.67624 19.7316 2.25376 24.0211 3.07692 28.1593C3.90007 32.2976 5.93187 36.0988 8.91539 39.0823C11.8989 42.0659 15.7001 44.0977 19.8384 44.9208C23.9767 45.744 28.2661 45.3215 32.1642 43.7068C36.0624 42.0922 39.3942 39.3578 41.7384 35.8496C44.0825 32.3413 45.3337 28.2167 45.3337 23.9974C45.3337 18.3394 43.0861 12.9132 39.0853 8.91245C35.0845 4.91168 29.6583 2.66406 24.0003 2.66406ZM24.0003 42.6641C20.3084 42.6641 16.6994 41.5693 13.6297 39.5182C10.56 37.467 8.16742 34.5517 6.75458 31.1408C5.34175 27.7299 4.97209 23.9767 5.69234 20.3557C6.4126 16.7347 8.19043 13.4086 10.801 10.7981C13.4116 8.18749 16.7377 6.40966 20.3587 5.6894C23.9796 4.96915 27.7329 5.33881 31.1438 6.75165C34.5546 8.16448 37.47 10.557 39.5211 13.6268C41.5722 16.6965 42.667 20.3055 42.667 23.9974C42.667 28.9481 40.7003 33.696 37.1997 37.1967C33.699 40.6974 28.951 42.6641 24.0003 42.6641Z" fill="#3DD883"/>
                                <path d="M14 25L19.6 31L34 18" stroke="#3DD883" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round"/>
                            </g>
                            <defs>
                                <clipPath id="clip0_10666_34637">
                                    <rect width="48" height="48" fill="white"/>
                                </clipPath>
                            </defs>
                        </svg>
                        <h2 className="text-[20px] font-semibold text-white">Call Disconnected!</h2>
                    </div>
                    <p className="mb-3 text-[#FFFFFFCC]">Let’s get to know each other better! </p>
                    <button className="bg-[#399717] py-3 rounded cursor-pointer mb-3 w-full flex items-center justify-center gap-x-3" onClick={()=>{ navigate('/call/speaking')}}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="25" height="24" viewBox="0 0 25 24" fill="none">
                            <g clipPath="url(#clip0_10665_33417)">
                                <path d="M21.5 16.42V19.956C21.5001 20.2092 21.4042 20.453 21.2316 20.6382C21.059 20.8234 20.8226 20.9363 20.57 20.954C20.133 20.984 19.776 21 19.5 21C10.663 21 3.5 13.837 3.5 5C3.5 4.724 3.515 4.367 3.546 3.93C3.56372 3.67744 3.67658 3.44101 3.8618 3.26841C4.04703 3.09581 4.29082 2.99989 4.544 3H8.08C8.20404 2.99987 8.3237 3.04586 8.41573 3.12902C8.50776 3.21218 8.5656 3.32658 8.578 3.45C8.601 3.68 8.622 3.863 8.642 4.002C8.84073 5.38892 9.248 6.73783 9.85 8.003C9.945 8.203 9.883 8.442 9.703 8.57L7.545 10.112C8.86445 13.1865 11.3145 15.6365 14.389 16.956L15.929 14.802C15.9919 14.714 16.0838 14.6509 16.1885 14.6237C16.2932 14.5964 16.4042 14.6068 16.502 14.653C17.767 15.2539 19.1156 15.6602 20.502 15.858C20.641 15.878 20.824 15.899 21.052 15.922C21.1752 15.9346 21.2894 15.9926 21.3723 16.0846C21.4553 16.1766 21.5001 16.2961 21.5 16.42Z" fill="white"/>
                            </g>
                            <defs>
                                <clipPath id="clip0_10665_33417">
                                    <rect width="24" height="24" fill="white" transform="translate(0.5)"/>
                                </clipPath>
                            </defs>
                        </svg>
                        <h5 className={'text-[14px] font-semibold text-white'}>Call Me Now</h5>
                    </button>

                    <div className="flex justify-between items-center">
                        <p className="text-[#FFFFFFCC]">15 minutes available</p>

                        {isPending ? <p  className="text-[#FFFFFFCC]">Please Wait...</p>:
                        <button onClick={()=>{
                            setIsPending(true)
                            setTimeout(()=>{
                                navigate('/call/add-credit')
                                setIsPending(false)
                            },1500)
                        }} className="flex items-center gap-x-1">
                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                <circle cx="10" cy="10" r="10" fill="#C2D24B"/>
                                <rect x="9" y="4" width="2" height="12" fill="black"/>
                                <rect x="4" y="11" width="2" height="12" transform="rotate(-90 4 11)" fill="black"/>
                            </svg>
                            <h4 className="text-[#C2D24B] text-[16px] font-medium">Add Credits</h4>
                        </button>}
                    </div>
                </div>
            <Footer/>
            </div>
        </div>
    );
};

export default CallDisconnectPage;