import React, { useEffect, useState } from "react";
import Empty from "../../../components/empty/Empty";
import { useIntl } from "react-intl";
import { ToolTipUI } from "../../widgets/components/UI/ToolTipUI";
import { Form, Modal, Spinner } from "react-bootstrap";
import { Link } from "react-router-dom";
import clsx from "clsx";
import userPhoto from "../../../assets/images/profile/avatar-image.png";
import SocialMediaEditPost from "../components/modals/SocialMediaEditPost";
import { deleteSocialPost, getAllSocialPostsForContent } from "../core/_request";
import { useAuth } from "../../auth";
import { getCdnUrl } from "../../../core/_util";
import userDefault from "../../../assets/images/profile/user-default.png";
import { CustomButton } from "../../widgets/components/UI/CustomButton";
import { BasicButton } from "../../widgets/components/UI/BasicButton";
import { Formik } from "formik";
import { toast } from "react-toastify";
import moment from "moment-timezone";
const SocialMediaContent: React.FC<any> = ({ selectedSocialMedia, timezone }) => {
  timezone = timezone || "UTC"
  const { formatMessage } = useIntl();
  const [modalShow, setModalShow] = React.useState(false);
  const [page, setPage] = useState(1)
  const [posts, setPosts] = useState<any[]>([])
  const [loading, setLoading] = useState<boolean>(false)
  const [isExhausted, setIsExhausted] = useState<boolean>(false)
  const [deleteModalOpen, setDeleteModalOpen] = useState<boolean>(false)
  const { selectedPersonality } = useAuth()
  const [selectedPost, setSelectedPost] = useState<any>(null)
  async function getPosts() {
    setLoading(true)
    let { data: { success, data } } = await getAllSocialPostsForContent(selectedPersonality.personalityId, {
      page,
      purpose: "content",
      network: selectedSocialMedia,
      zone: timezone
    })
    if (success) {
      if (!data.length) {
        setIsExhausted(true)
      } else {
        const today = moment.tz(timezone)
        const tomorrow = moment.tz(timezone).add(1, 'day')
        data = data.map((item) => {
          let date = moment.tz(item.startTime, timezone)
          const title = date.isSame(today, 'day') ? 'Today' : date.isSame(tomorrow, 'day') ? "Tomorrow" : date.format("D MMM YYYY")
          const time = date.format("hh:mm A")
          return {
            ...item,
            title,
            subTitle: `${title} at ${time}`
          }
        })
        setPosts((posts: any[]) => [...posts, ...data])
      }
    }
    setLoading(false)
  }

  useEffect(() => {
    if (selectedPersonality) {
      getPosts()
    }
  }, [selectedPersonality, page, selectedSocialMedia])

  useEffect(() => {
    setPosts([])
    setPage(1)
  }, [selectedSocialMedia])

  const personalityImage = selectedPersonality?.image
    ? getCdnUrl(selectedPersonality.image)
    : userDefault;

  function onUpdateSuccess(updatedPost: any) {
    setPosts((posts) => {
      const updatedPosts = posts.map((item) => {
        if (item.postId == updatedPost.postId) {
          return updatedPost
        } else {
          return item
        }
      })
      return updatedPosts
    })
  }
  return (
    <div className="flex flex-col  mt-[12px] gap-[24px] md:gap-[48px]">
      {posts.map((post: any, index) => {
        const { text, title, subTitle } = post;
        return <div className="">
          {title != posts[index - 1]?.title && <h4 className="text-[#FFFFFFCC]  text-[16px] font-[500] leading-[24px]">
            {title}
          </h4>}
          <div
            className={clsx(
              "secondary-bg mt-[8px] border-main rounded  border p-[14px_8px] md:p-[16px] xl:p-[16px_32px_10px_32px]"
            )}
          >
            <div className={"primary-text flex justify-between mb-[16px]"}>
              <div className={"flex gap-[12px]"}>
                <img
                  alt="Pic"
                  src={personalityImage}
                  className={"w-[48px] h-[48px] rounded-full object-cover object-top"}
                />

                <div>
                  <h4
                    className={clsx(
                      "text-[#FFFFFF] flex items-center gap-[8px]  text-[16px] leading-[24px] font-semibold mb-1"
                    )}
                  >
                    {selectedPersonality.name}
                    {!!selectedPersonality.isVerified && <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M12 0L14.8553 3.21224L19.0534 2.2918L19.4753 6.56886L23.4127 8.2918L21.24 12L23.4127 15.7082L19.4753 17.4311L19.0534 21.7082L14.8553 20.7878L12 24L9.14468 20.7878L4.94658 21.7082L4.52468 17.4311L0.587322 15.7082L2.76 12L0.587322 8.2918L4.52468 6.56886L4.94658 2.2918L9.14468 3.21224L12 0Z"
                        fill="#4776E6"
                      />
                      <path
                        d="M8.30762 12.0001L10.7745 14.7693L17.0768 9.23083"
                        stroke="white"
                        strokeWidth="2"
                        strokeLinecap="round"
                      />
                    </svg>}
                  </h4>
                  <p
                    className={clsx(
                      "flex gap-2 text-[#FFFFFF80] text-[12px] leading-[18px]"
                    )}
                  >
                    <svg
                      width="20"
                      height="20"
                      viewBox="0 0 20 20"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g clip-path="url(#clip0_7074_88045)">
                        <path
                          d="M2.5 10C2.5 10.9849 2.69399 11.9602 3.0709 12.8701C3.44781 13.7801 4.00026 14.6069 4.6967 15.3033C5.39314 15.9997 6.21993 16.5522 7.12987 16.9291C8.03982 17.306 9.01509 17.5 10 17.5C10.9849 17.5 11.9602 17.306 12.8701 16.9291C13.7801 16.5522 14.6069 15.9997 15.3033 15.3033C15.9997 14.6069 16.5522 13.7801 16.9291 12.8701C17.306 11.9602 17.5 10.9849 17.5 10C17.5 8.01088 16.7098 6.10322 15.3033 4.6967C13.8968 3.29018 11.9891 2.5 10 2.5C8.01088 2.5 6.10322 3.29018 4.6967 4.6967C3.29018 6.10322 2.5 8.01088 2.5 10Z"
                          stroke="white"
                          stroke-opacity="0.5"
                          stroke-width="1.6"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M10 5.83203V9.9987L12.5 12.4987"
                          stroke="white"
                          stroke-opacity="0.5"
                          stroke-width="1.6"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </g>
                      <defs>
                        <clipPath id="clip0_7074_88045">
                          <rect width="20" height="20" fill="white" />
                        </clipPath>
                      </defs>
                    </svg>
                    {subTitle}
                  </p>
                </div>
              </div>
              <div>
                <div className="flex gap-3">
                  <div className="" onClick={() => { setDeleteModalOpen(true); setSelectedPost(post) }}>
                    <svg
                      width="36"
                      height="36"
                      viewBox="0 0 36 36"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <rect width="36" height="36" rx="6" fill="#3C3D54" />
                      <g clip-path="url(#clip0_7201_44545)">
                        <path
                          d="M26 11C26.2652 11 26.5196 11.1054 26.7071 11.2929C26.8946 11.4804 27 11.7348 27 12C27 12.2652 26.8946 12.5196 26.7071 12.7071C26.5196 12.8946 26.2652 13 26 13H25L24.997 13.071L24.064 26.142C24.0281 26.6466 23.8023 27.1188 23.4321 27.4636C23.0619 27.8083 22.5749 28 22.069 28H13.93C13.4241 28 12.9371 27.8083 12.5669 27.4636C12.1967 27.1188 11.9709 26.6466 11.935 26.142L11.002 13.072C11.0005 13.048 10.9998 13.024 11 13H10C9.73478 13 9.48043 12.8946 9.29289 12.7071C9.10536 12.5196 9 12.2652 9 12C9 11.7348 9.10536 11.4804 9.29289 11.2929C9.48043 11.1054 9.73478 11 10 11H26ZM22.997 13H13.003L13.931 26H22.069L22.997 13ZM20 8C20.2652 8 20.5196 8.10536 20.7071 8.29289C20.8946 8.48043 21 8.73478 21 9C21 9.26522 20.8946 9.51957 20.7071 9.70711C20.5196 9.89464 20.2652 10 20 10H16C15.7348 10 15.4804 9.89464 15.2929 9.70711C15.1054 9.51957 15 9.26522 15 9C15 8.73478 15.1054 8.48043 15.2929 8.29289C15.4804 8.10536 15.7348 8 16 8H20Z"
                          fill="white"
                          fill-opacity="0.6"
                        />
                      </g>
                      <defs>
                        <clipPath id="clip0_7201_44545">
                          <rect
                            width="24"
                            height="24"
                            fill="white"
                            transform="translate(6 6)"
                          />
                        </clipPath>
                      </defs>
                    </svg>
                  </div>
                  <div
                    className="cursor-pointer"
                    onClick={() => { setModalShow(true); setSelectedPost(post) }}
                  >
                    <svg
                      width="36"
                      height="36"
                      viewBox="0 0 36 36"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <rect width="36" height="36" rx="6" fill="#3C3D54" />
                      <path
                        d="M26.5091 15.0455C27.1636 14.3545 27.1636 13.3182 26.5091 12.6273L23.5636 9.51818C22.9091 8.82727 21.9273 8.82727 21.2727 9.51818L9 22.4727V28H14.2364L26.5091 15.0455ZM22.4182 10.7273L25.3636 13.8364L22.9091 16.4273L19.9636 13.3182L22.4182 10.7273ZM10.6364 26.2727V23.1636L18.8182 14.5273L21.7636 17.6364L13.5818 26.2727H10.6364Z"
                        fill="white"
                        fill-opacity="0.65"
                      />
                    </svg>
                  </div>
                </div>
              </div>
            </div>
            <div>
              <p
                className={clsx(
                  "text-[#FFFFFFCC] mb-[8px]  text-[14px] leading-[22px]"
                )}
              >
                {text}
              </p>
            </div>
          </div>
        </div>
      })}
      {loading && (
        <div className="d-flex h-[100px] justify-content-center align-items-center">
          <Spinner animation="border" />
        </div>
      )}
      {!posts.length && !loading &&  <Empty
        heading={formatMessage({ id: "No Social Posts Found!" })}
        content={formatMessage({
          id: "No social posts found. Link your social account and start creating.",
        })}
      />}
      {!!posts.length && (
        <CustomButton
          isSubmitting={loading}
          isValid={!isExhausted}
          buttonText={
            isExhausted
              ? formatMessage({ id: "That's all for now" })
              : formatMessage({ id: "Show More" })
          }
          loading={loading}
          customClass={"mx-auto w-fit my-4 main-button"}
          onSubmit={() => setPage((page) => page + 1)}
        />
      )}
      {/* <div className="">
        <h4 className="text-[#FFFFFFCC]  text-[16px] font-[500] leading-[24px]">
          Tomorrow
        </h4>
        <div
          className={clsx(
            "secondary-bg mt-[8px] border-main rounded  border p-[14px_8px] md:p-[16px_32px_10px_32px]"
          )}
        >
          <div className={"primary-text flex justify-between mb-[16px]"}>
            <div className={"flex gap-[12px]"}>
              <img
                alt="Pic"
                src={userPhoto}
                className={"w-[48px] h-[48px] rounded-full object-cover object-top"}
              />

              <div>
                <h4
                  className={clsx(
                    "text-[#FFFFFF] flex items-center gap-[8px]  text-[16px] leading-[24px] font-semibold mb-1"
                  )}
                >
                  Shahrukh Khan
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M12 0L14.8553 3.21224L19.0534 2.2918L19.4753 6.56886L23.4127 8.2918L21.24 12L23.4127 15.7082L19.4753 17.4311L19.0534 21.7082L14.8553 20.7878L12 24L9.14468 20.7878L4.94658 21.7082L4.52468 17.4311L0.587322 15.7082L2.76 12L0.587322 8.2918L4.52468 6.56886L4.94658 2.2918L9.14468 3.21224L12 0Z"
                      fill="#4776E6"
                    />
                    <path
                      d="M8.30762 12.0001L10.7745 14.7693L17.0768 9.23083"
                      stroke="white"
                      strokeWidth="2"
                      strokeLinecap="round"
                    />
                  </svg>
                </h4>
                <p
                  className={clsx(
                    "flex gap-2 text-[#FFFFFF80] text-[12px] leading-[18px]"
                  )}
                >
                  <svg
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g clip-path="url(#clip0_7074_88045)">
                      <path
                        d="M2.5 10C2.5 10.9849 2.69399 11.9602 3.0709 12.8701C3.44781 13.7801 4.00026 14.6069 4.6967 15.3033C5.39314 15.9997 6.21993 16.5522 7.12987 16.9291C8.03982 17.306 9.01509 17.5 10 17.5C10.9849 17.5 11.9602 17.306 12.8701 16.9291C13.7801 16.5522 14.6069 15.9997 15.3033 15.3033C15.9997 14.6069 16.5522 13.7801 16.9291 12.8701C17.306 11.9602 17.5 10.9849 17.5 10C17.5 8.01088 16.7098 6.10322 15.3033 4.6967C13.8968 3.29018 11.9891 2.5 10 2.5C8.01088 2.5 6.10322 3.29018 4.6967 4.6967C3.29018 6.10322 2.5 8.01088 2.5 10Z"
                        stroke="white"
                        stroke-opacity="0.5"
                        stroke-width="1.6"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M10 5.83203V9.9987L12.5 12.4987"
                        stroke="white"
                        stroke-opacity="0.5"
                        stroke-width="1.6"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_7074_88045">
                        <rect width="20" height="20" fill="white" />
                      </clipPath>
                    </defs>
                  </svg>
                  Today at 5:06 PM
                </p>
              </div>
            </div>
            <div>
              <div className="flex gap-3">
                <div className="">
                  <svg
                    width="36"
                    height="36"
                    viewBox="0 0 36 36"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <rect width="36" height="36" rx="6" fill="#3C3D54" />
                    <g clip-path="url(#clip0_7201_44545)">
                      <path
                        d="M26 11C26.2652 11 26.5196 11.1054 26.7071 11.2929C26.8946 11.4804 27 11.7348 27 12C27 12.2652 26.8946 12.5196 26.7071 12.7071C26.5196 12.8946 26.2652 13 26 13H25L24.997 13.071L24.064 26.142C24.0281 26.6466 23.8023 27.1188 23.4321 27.4636C23.0619 27.8083 22.5749 28 22.069 28H13.93C13.4241 28 12.9371 27.8083 12.5669 27.4636C12.1967 27.1188 11.9709 26.6466 11.935 26.142L11.002 13.072C11.0005 13.048 10.9998 13.024 11 13H10C9.73478 13 9.48043 12.8946 9.29289 12.7071C9.10536 12.5196 9 12.2652 9 12C9 11.7348 9.10536 11.4804 9.29289 11.2929C9.48043 11.1054 9.73478 11 10 11H26ZM22.997 13H13.003L13.931 26H22.069L22.997 13ZM20 8C20.2652 8 20.5196 8.10536 20.7071 8.29289C20.8946 8.48043 21 8.73478 21 9C21 9.26522 20.8946 9.51957 20.7071 9.70711C20.5196 9.89464 20.2652 10 20 10H16C15.7348 10 15.4804 9.89464 15.2929 9.70711C15.1054 9.51957 15 9.26522 15 9C15 8.73478 15.1054 8.48043 15.2929 8.29289C15.4804 8.10536 15.7348 8 16 8H20Z"
                        fill="white"
                        fill-opacity="0.6"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_7201_44545">
                        <rect
                          width="24"
                          height="24"
                          fill="white"
                          transform="translate(6 6)"
                        />
                      </clipPath>
                    </defs>
                  </svg>
                </div>

                <div
                  className="cursor-pointer"
                  onClick={() => setModalShow(true)}
                >
                  <svg
                    width="36"
                    height="36"
                    viewBox="0 0 36 36"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <rect width="36" height="36" rx="6" fill="#3C3D54" />
                    <path
                      d="M26.5091 15.0455C27.1636 14.3545 27.1636 13.3182 26.5091 12.6273L23.5636 9.51818C22.9091 8.82727 21.9273 8.82727 21.2727 9.51818L9 22.4727V28H14.2364L26.5091 15.0455ZM22.4182 10.7273L25.3636 13.8364L22.9091 16.4273L19.9636 13.3182L22.4182 10.7273ZM10.6364 26.2727V23.1636L18.8182 14.5273L21.7636 17.6364L13.5818 26.2727H10.6364Z"
                      fill="white"
                      fill-opacity="0.65"
                    />
                  </svg>
                </div>
              </div>
            </div>
          </div>
          <div>
            <p
              className={clsx(
                "text-[#FFFFFFCC] mb-[8px]  text-[14px] leading-[22px]"
              )}
            >
              In a humorous twist, Shahrukh, sporting his famous dimpled grin,
              teased Karan Johar about his ever-changing hair color. Karan,
              always quick with a comeback, playfully retorted, "SRK, at least
              my hair isn't greying like yours!" The room burst into laughter.
              #kamotoai #ai-characters #ai #celebrity
            </p>
          </div>
          <div className={"flex items-center gap-[4px] mt-[20px]"}>
            <button
              className={clsx(
                "flex items-center gap-x-2 rounded-[6px] px-3 py-2.5 mr-2 "
              )}
            >
              <>
                <svg
                  width="23"
                  height="23"
                  viewBox="0 0 23 23"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g clipPath="url(#clip0_344_24492)">
                    <path
                      d="M6.70833 10.5417V18.2084C6.70833 18.4625 6.60737 18.7063 6.42764 18.886C6.24792 19.0657 6.00417 19.1667 5.75 19.1667H3.83333C3.57917 19.1667 3.33541 19.0657 3.15569 18.886C2.97597 18.7063 2.875 18.4625 2.875 18.2084V11.5C2.875 11.2459 2.97597 11.0021 3.15569 10.8224C3.33541 10.6427 3.57917 10.5417 3.83333 10.5417H6.70833ZM6.70833 10.5417C7.725 10.5417 8.70002 10.1378 9.41891 9.41895C10.1378 8.70006 10.5417 7.72504 10.5417 6.70837V5.75004C10.5417 5.24171 10.7436 4.7542 11.103 4.39475C11.4625 4.03531 11.95 3.83337 12.4583 3.83337C12.9667 3.83337 13.4542 4.03531 13.8136 4.39475C14.1731 4.7542 14.375 5.24171 14.375 5.75004V10.5417H17.25C17.7583 10.5417 18.2458 10.7436 18.6053 11.1031C18.9647 11.4625 19.1667 11.95 19.1667 12.4584L18.2083 17.25C18.0705 17.838 17.8091 18.3428 17.4634 18.6885C17.1177 19.0342 16.7065 19.202 16.2917 19.1667H9.58333C8.82084 19.1667 8.08957 18.8638 7.5504 18.3246C7.01123 17.7855 6.70833 17.0542 6.70833 16.2917"
                      // stroke={liked ? "#C2D24B" : "white"}
                      className={clsx("icon-stroke-primary")}
                      strokeOpacity="0.8"
                      strokeWidth="1.2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_344_24492">
                      <rect width="23" height="23" className={"icon"} />
                    </clipPath>
                  </defs>
                </svg>
                <span
                  className={"text-[12px] leading-[18px] secondary-text-light"}
                >
                  47k Likes
                </span>
              </>
            </button>
          </div>
        </div>
      </div>
      <div className="">
        <h4 className="text-[#FFFFFFCC]  text-[16px] font-[500] leading-[24px]">
          23rd Aug, 2023
        </h4>
        <div
          className={clsx(
            "secondary-bg mt-[8px] border-main rounded  border p-[14px_8px] md:p-[16px_32px_10px_32px]"
          )}
        >
          <div className={"primary-text flex justify-between mb-[16px]"}>
            <div className={"flex gap-[12px]"}>
              <img
                alt="Pic"
                src={userPhoto}
                className={"w-[48px] h-[48px] rounded-full object-cover object-top"}
              />

              <div>
                <h4
                  className={clsx(
                    "text-[#FFFFFF] flex items-center gap-[8px]  text-[16px] leading-[24px] font-semibold mb-1"
                  )}
                >
                  Shahrukh Khan
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M12 0L14.8553 3.21224L19.0534 2.2918L19.4753 6.56886L23.4127 8.2918L21.24 12L23.4127 15.7082L19.4753 17.4311L19.0534 21.7082L14.8553 20.7878L12 24L9.14468 20.7878L4.94658 21.7082L4.52468 17.4311L0.587322 15.7082L2.76 12L0.587322 8.2918L4.52468 6.56886L4.94658 2.2918L9.14468 3.21224L12 0Z"
                      fill="#4776E6"
                    />
                    <path
                      d="M8.30762 12.0001L10.7745 14.7693L17.0768 9.23083"
                      stroke="white"
                      strokeWidth="2"
                      strokeLinecap="round"
                    />
                  </svg>
                </h4>
                <p
                  className={clsx(
                    "flex gap-2 text-[#FFFFFF80] text-[12px] leading-[18px]"
                  )}
                >
                  <svg
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g clip-path="url(#clip0_7074_88045)">
                      <path
                        d="M2.5 10C2.5 10.9849 2.69399 11.9602 3.0709 12.8701C3.44781 13.7801 4.00026 14.6069 4.6967 15.3033C5.39314 15.9997 6.21993 16.5522 7.12987 16.9291C8.03982 17.306 9.01509 17.5 10 17.5C10.9849 17.5 11.9602 17.306 12.8701 16.9291C13.7801 16.5522 14.6069 15.9997 15.3033 15.3033C15.9997 14.6069 16.5522 13.7801 16.9291 12.8701C17.306 11.9602 17.5 10.9849 17.5 10C17.5 8.01088 16.7098 6.10322 15.3033 4.6967C13.8968 3.29018 11.9891 2.5 10 2.5C8.01088 2.5 6.10322 3.29018 4.6967 4.6967C3.29018 6.10322 2.5 8.01088 2.5 10Z"
                        stroke="white"
                        stroke-opacity="0.5"
                        stroke-width="1.6"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M10 5.83203V9.9987L12.5 12.4987"
                        stroke="white"
                        stroke-opacity="0.5"
                        stroke-width="1.6"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_7074_88045">
                        <rect width="20" height="20" fill="white" />
                      </clipPath>
                    </defs>
                  </svg>
                  Today at 5:06 PM
                </p>
              </div>
            </div>
            <div>
              <div className="flex gap-3">
                <div className="">
                  <svg
                    width="36"
                    height="36"
                    viewBox="0 0 36 36"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <rect width="36" height="36" rx="6" fill="#3C3D54" />
                    <g clip-path="url(#clip0_7201_44545)">
                      <path
                        d="M26 11C26.2652 11 26.5196 11.1054 26.7071 11.2929C26.8946 11.4804 27 11.7348 27 12C27 12.2652 26.8946 12.5196 26.7071 12.7071C26.5196 12.8946 26.2652 13 26 13H25L24.997 13.071L24.064 26.142C24.0281 26.6466 23.8023 27.1188 23.4321 27.4636C23.0619 27.8083 22.5749 28 22.069 28H13.93C13.4241 28 12.9371 27.8083 12.5669 27.4636C12.1967 27.1188 11.9709 26.6466 11.935 26.142L11.002 13.072C11.0005 13.048 10.9998 13.024 11 13H10C9.73478 13 9.48043 12.8946 9.29289 12.7071C9.10536 12.5196 9 12.2652 9 12C9 11.7348 9.10536 11.4804 9.29289 11.2929C9.48043 11.1054 9.73478 11 10 11H26ZM22.997 13H13.003L13.931 26H22.069L22.997 13ZM20 8C20.2652 8 20.5196 8.10536 20.7071 8.29289C20.8946 8.48043 21 8.73478 21 9C21 9.26522 20.8946 9.51957 20.7071 9.70711C20.5196 9.89464 20.2652 10 20 10H16C15.7348 10 15.4804 9.89464 15.2929 9.70711C15.1054 9.51957 15 9.26522 15 9C15 8.73478 15.1054 8.48043 15.2929 8.29289C15.4804 8.10536 15.7348 8 16 8H20Z"
                        fill="white"
                        fill-opacity="0.6"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_7201_44545">
                        <rect
                          width="24"
                          height="24"
                          fill="white"
                          transform="translate(6 6)"
                        />
                      </clipPath>
                    </defs>
                  </svg>
                </div>

                <div
                  className="cursor-pointer"
                  onClick={() => setModalShow(true)}
                >
                  <svg
                    width="36"
                    height="36"
                    viewBox="0 0 36 36"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <rect width="36" height="36" rx="6" fill="#3C3D54" />
                    <path
                      d="M26.5091 15.0455C27.1636 14.3545 27.1636 13.3182 26.5091 12.6273L23.5636 9.51818C22.9091 8.82727 21.9273 8.82727 21.2727 9.51818L9 22.4727V28H14.2364L26.5091 15.0455ZM22.4182 10.7273L25.3636 13.8364L22.9091 16.4273L19.9636 13.3182L22.4182 10.7273ZM10.6364 26.2727V23.1636L18.8182 14.5273L21.7636 17.6364L13.5818 26.2727H10.6364Z"
                      fill="white"
                      fill-opacity="0.65"
                    />
                  </svg>
                </div>
              </div>
            </div>
          </div>
          <div>
            <p
              className={clsx(
                "text-[#FFFFFFCC] mb-[8px]  text-[14px] leading-[22px]"
              )}
            >
              In a humorous twist, Shahrukh, sporting his famous dimpled grin,
              teased Karan Johar about his ever-changing hair color. Karan,
              always quick with a comeback, playfully retorted, "SRK, at least
              my hair isn't greying like yours!" The room burst into laughter.
              #kamotoai #ai-characters #ai #celebrity
            </p>
          </div>
          <div className={"flex items-center gap-[4px] mt-[20px]"}>
            <button
              className={clsx(
                "flex items-center gap-x-2 rounded-[6px] px-3 py-2.5 mr-2 "
              )}
            >
              <>
                <svg
                  width="23"
                  height="23"
                  viewBox="0 0 23 23"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g clipPath="url(#clip0_344_24492)">
                    <path
                      d="M6.70833 10.5417V18.2084C6.70833 18.4625 6.60737 18.7063 6.42764 18.886C6.24792 19.0657 6.00417 19.1667 5.75 19.1667H3.83333C3.57917 19.1667 3.33541 19.0657 3.15569 18.886C2.97597 18.7063 2.875 18.4625 2.875 18.2084V11.5C2.875 11.2459 2.97597 11.0021 3.15569 10.8224C3.33541 10.6427 3.57917 10.5417 3.83333 10.5417H6.70833ZM6.70833 10.5417C7.725 10.5417 8.70002 10.1378 9.41891 9.41895C10.1378 8.70006 10.5417 7.72504 10.5417 6.70837V5.75004C10.5417 5.24171 10.7436 4.7542 11.103 4.39475C11.4625 4.03531 11.95 3.83337 12.4583 3.83337C12.9667 3.83337 13.4542 4.03531 13.8136 4.39475C14.1731 4.7542 14.375 5.24171 14.375 5.75004V10.5417H17.25C17.7583 10.5417 18.2458 10.7436 18.6053 11.1031C18.9647 11.4625 19.1667 11.95 19.1667 12.4584L18.2083 17.25C18.0705 17.838 17.8091 18.3428 17.4634 18.6885C17.1177 19.0342 16.7065 19.202 16.2917 19.1667H9.58333C8.82084 19.1667 8.08957 18.8638 7.5504 18.3246C7.01123 17.7855 6.70833 17.0542 6.70833 16.2917"
                      // stroke={liked ? "#C2D24B" : "white"}
                      className={clsx("icon-stroke-primary")}
                      strokeOpacity="0.8"
                      strokeWidth="1.2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_344_24492">
                      <rect width="23" height="23" className={"icon"} />
                    </clipPath>
                  </defs>
                </svg>
                <span
                  className={"text-[12px] leading-[18px] secondary-text-light"}
                >
                  47k Likes
                </span>
              </>
            </button>
          </div>
        </div>
      </div> */}
      <SocialMediaEditPost
        show={modalShow}
        onHide={() => setModalShow(false)}
        selectedPost={selectedPost}
        onUpdateSuccess={onUpdateSuccess}
      />
      <Modal
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={deleteModalOpen}
        onHide={() => setDeleteModalOpen(false)}
      >
        <Modal.Header closeButton>
          <Modal.Title
            className="modal-title text-[20px] leading-[28px] font-weight-600 text-[#FFFFFFCC] "
            id="contained-modal-title-vcenter"
          >
            {formatMessage({ id: "Delete Confirmation" })}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className=" p-[16px] md:p-[24px]">
          <p className="text-[#FFFFFFCC] text-[16px]">
            {formatMessage({ id: "Are you sure to delete this post?" })}
          </p>
          <Formik
            initialValues={{}}
            onSubmit={async (values, { setSubmitting }) => {
              setSubmitting(true);
              const {
                data: { success, data },
              } = await deleteSocialPost(selectedPost.postId);
              if (success) {
                setPosts((posts) =>
                  posts.filter(
                    ({ postId }) =>
                      postId !== selectedPost.postId
                  )
                );
                setDeleteModalOpen(false);
                setSelectedPost(null);
                toast.success("Post deleted successfully");
              } else {
                toast.error("Error while deleting the post",
                  {
                    autoClose: false,
                    closeButton: true, // Display close button (cross icon)
                  });
              }
              setSubmitting(false);
            }}
          >
            {(formik) => (
              <div className="flex gap-x-3 justify-end items-center">
                <BasicButton
                  buttonText={formatMessage({ id: "Cancel" })}
                  border="none"
                  color="#C2D24B1A"
                  textColor="#C2D24B"
                  customClass={"px-[24px] mt-8"}
                  height="44px"
                  onClick={() => {
                    setDeleteModalOpen(false);
                  }}
                />
                <CustomButton
                  customClass="mt-8"
                  isSubmitting={formik.isSubmitting}
                  isValid={true}
                  buttonText={formatMessage({ id: "Delete" })}
                  loading={formik.isSubmitting}
                  height={44}
                  widthLoading={1}
                  width={1}
                  onSubmit={formik.handleSubmit}
                />
              </div>
            )}
          </Formik>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default SocialMediaContent;
