import React, { useEffect, useState } from "react";
import TextInput from "../../../widgets/components/Input/TextInput";
import { useIntl } from "react-intl";
import { Field, FieldArray, Form, Formik } from "formik";
import TextArea from "../../../widgets/components/Input/TextArea";
import { InfoCard } from "../../../widgets/components/UI/InfoCard";
import { isEqual } from "lodash";
import { ToolTipUI } from "../../../widgets/components/UI/ToolTipUI";
import clsx from "clsx";
import { SelectInput } from "../../../widgets/components/Input/SelectInput";
import Slider from "../../../../components/slider";
import Select from "../../../../components/select/select";
import CounterQuestion from "./dialog/counter-question";
import { useAuth } from "../../../auth";
import * as Yup from "yup";
import FormikReactCreatable from "../../../widgets/components/Input/FormikReactCreatable";
import { updatePersonalityInfo } from "../../../profile/core/_requests";
import { toast } from "react-toastify";
import FormikReactSelect from "../../../widgets/components/Input/FormikReactSelect";
import useGTM from "../../../../hooks/useGTM";
import { GTMEvent } from "../../../../hooks/gtm_helpers";
const Dialog: React.FC<any> = ({ setOpenEdit, personalityData }) => {
  const { formatMessage } = useIntl();
  const {
    referenceData,
    setSelectedPersonality,
    selectedPersonality: updatedData,
  } = useAuth();
  personalityData = updatedData || personalityData;

  const [normalType, setNormalType] = useState(false);
  const [disableSave, setDisableSave] = useState(false);
  const {dataLayerPush} = useGTM()
  const dialogLength = ["Shortest", "Short", "Medium", "Long", "Longest"];
  const counterQuestions = ["Never", "Rarely", "Sometimes", "Often", "Always"];
  const defaultObject = {
    dialogue_length_this_ai_personality_should_reply_in: "Medium",
    counter_question_frequency: "Sometimes",
  };
  // const messageFields = {
  //   messageText: "",
  // };
  // const questionFields = {
  //   questionText: "",
  // };
  // const counterQuestionFields = {
  //   questionText: "",
  // };

  // const initialValues = {
  //   dialog_example:
  //     "In the resonant and unmistakable voice of Shahrukh Khan, he speaks with an authoritative tone, \"The movie industry isn't for the faint-hearted. It's a place where creativity and commerce fuse, where dreams are forged into realities. It demands tenacity, dedication, and unquenchable passion. It's an arena that pushes you to continually redefine your limits. So, brace yourself for the challenge, and remember - the spotlight isn't just given, it's earned",
  //   messageTextList: [messageFields],
  //   questionTextList: [questionFields],
  //   questionList: [
  //     "What do you think about my hairstyle?",
  //     "What do you think about my hairstyle?",
  //   ],
  // };

  const dialogueValidationSchema = Yup.object().shape({
    style: Yup.string()
      // .min(5, "The dialogue style must have at least 5 characters.")
      .max(50, "The dialogue style cannot exceed 50 characters."),
    // .required("The dialogue style is required.")
    sample_dialogue_of_this_ai_personality: Yup.string()
      // .min(10, "The sample dialogue must have at least 10 characters.")
      .max(200, "The sample dialogue cannot exceed 200 characters."),
    // .required("The sample dialogue is required.")
    dialogue_length_this_ai_personality_should_reply_in: Yup.string(),
    // .required(
    //   "The dialogue length is required."
    // )
    counter_question_frequency: Yup.string(),
    // .required(
    //   "The counter question frequency is required."
    // )
    counter_questions_this_ai_personality_should_ask: Yup.array()
      .of(
        Yup.string()
          // .min(10, "The counter question must have at least 10 characters.")
          .max(200, "The counter question cannot exceed 200 characters.")
      )
      // .min(0, "At least 0 counter questions are required.")
      .max(10, "The maximum number of counter questions is 10."),
    capitalization_in_reply: Yup.string(),
    // .oneOf(["Whenever needed", "Never"], "Invalid capitalization preference.")
    // .required("The capitalization preference is required.")
    words_to_say_before_reply: Yup.array()
      .of(
        Yup.string()
          // .min(12, "The word must have at least 12 characters.")
          .max(50, "The word cannot exceed 50 characters.")
      )
      // .min(1, "At least 1 word is required.")
      .max(10, "The maximum number of words is 10."),
    words_to_say_at_end_of_each_reply: Yup.array()
      .of(
        Yup.string()
          // .min(2, "The word must have at least 2 characters.")
          .max(50, "The word cannot exceed 50 characters.")
      )
      // .min(1, "At least 1 word is required.")
      .max(10, "The maximum number of words is 10."),
    list_of_famous_dialogues: Yup.array()
      .of(
        Yup.string()
          // .min(10, "The famous dialogue must have at least 10 characters.")
          .max(200, "The famous dialogue cannot exceed 200 characters.")
      )
      // .min(1, "At least 1 famous dialogue is required.")
      .max(10, "The maximum number of famous dialogues is 10."),
  });

  const onSubmit = async (values: any, { setSubmitting }: any) => {
    // values.counter_question_frequency = counterQuestions[values.counter_question_frequency -1 ]
    // values.dialogue_length_this_ai_personality_should_reply_in = dialogLength[values.dialogue_length_this_ai_personality_should_reply_in -1 ]
    // save data to api
    values.list_of_famous_dialogues = values?.list_of_famous_dialogues?.filter((el:string) => el ? true : false) || []
    values.counter_questions_this_ai_personality_should_ask = values?.counter_questions_this_ai_personality_should_ask?.filter((el:string) => el ? true : false) || []
    setDisableSave(true);
    setSubmitting(true);
    try {
      let {
        data: { success, data },
      } = await updatePersonalityInfo({
        personalityId: personalityData.personalityId,
        personalityJson: {
          ...personalityData.personalityJson,
          dialogue_style_of_this_ai_personality: values,
        },
      });
      if (success) {
        setSelectedPersonality(data);
        toast.success(
          formatMessage({ id: "Dialogue details updated successfully" })
        );
      } else {
        setSubmitting(false);
        toast.error(
          formatMessage({ id: "Error when updating Dialogue details" }),
          {
            autoClose: false,
            closeButton: true, // Display close button (cross icon)
          }
        );
      }
      setDisableSave(false);
      dataLayerPush(GTMEvent.AIPersonalityEditSuccessful,{"tab": "dialog-details"})
    } catch (e) {
      toast.error(
        formatMessage({ id: "Error when updating Dialogue details" }),
        {
          autoClose: false,
          closeButton: true, // Display close button (cross icon)
        }
      );
      setSubmitting(false);
      setDisableSave(false);
      dataLayerPush(GTMEvent.AIPersonalityEditFailed,{"tab": "dialog-details"})
    }
  };
  let initValues =
    personalityData?.personalityJson?.dialogue_style_of_this_ai_personality ||
    defaultObject;
  // console.log(initValues)
  // useEffect(() => {
  //   console.log('initiated',personalityData?.personalityJson)
  //   if(typeof initValues.dialogue_length_this_ai_personality_should_reply_in != 'undefined'){
  //     initValues.dialogue_length_this_ai_personality_should_reply_in = dialogLength.indexOf(initValues.dialogue_length_this_ai_personality_should_reply_in)+1
  //   }
  //   if(typeof initValues.counter_question_frequency != 'undefined'){
  //     initValues.counter_question_frequency = counterQuestions.indexOf(initValues.counter_question_frequency)+1
  //   }
  // },[])

  return (
    <Formik
      initialValues={initValues}
      validationSchema={dialogueValidationSchema}
      onSubmit={onSubmit}
      validateOnMount
    >
      {(formik) => {
        return (
          <Form>
            <div className={"grid grid-cols-12 gap-y-[20px] md:gap-x-[40px]"}>
              <div className={"col-span-12 md:col-span-7 order-0"}>
                <div className={"tab-container-2 p-[14px] md:p-[20px] rounded"}>
                  {/* <h4
                    className={
                      "text-[16px] leading-6 font-medium text-[#FFFFFFCC] mb-[16px]"
                    }
                  >
                    Dialogue Style
                  </h4>
                  <div className={"flex gap-x-[20px] md:gap-x-20 mb-6"}>
                    <div className={"flex items-center gap-x-4"}>
                      <input
                        type={"radio"}
                        className={"form-check-input"}
                        checked={presetType}
                        onClick={() => setPresetType(true)}
                      />
                      <label
                        className={clsx(
                          "text-[16px] leading-6 font-medium md:font-semibold  flex gap-1",
                          presetType ? "!text-[#C2D24B]" : "!text-[#FFFFFFA6]"
                        )}
                      >
                        Preset <ToolTipUI tooltipText={""} />
                      </label>
                    </div>
                    <div className={"flex items-center gap-x-4"}>
                      <input
                        type={"radio"}
                        className={"form-check-input"}
                        checked={!presetType}
                        onClick={() => setPresetType(false)}
                      />
                      <label
                        className={clsx(
                          "text-[16px] leading-6 font-medium md:font-semibold flex gap-1",
                          !presetType ? "!text-[#C2D24B]" : "!text-[#FFFFFFA6]"
                        )}
                      >
                        Custom <ToolTipUI tooltipText={""} />
                      </label>
                    </div>
                  </div> */}
                  {/* {presetType ? ( */}
                  <div>
                    <FormikReactSelect
                        name={"style"}
                        placeholder={formatMessage({
                          id: "Select Dialogue Style",
                        })}
                        formik={formik}
                        options={[
                          { label: "Commanding", value: "Commanding" },
                          { label: "Cooperative", value: "Cooperative" },
                          { label: "Engaging", value: "Engaging" },
                        ]}
                    />
                    {/*<SelectInput*/}
                    {/*  fieldName={"style"}*/}
                    {/*  placeholder={formatMessage({*/}
                    {/*    id: "Select Dialogue Style",*/}
                    {/*  })}*/}
                    {/*  formik={formik}*/}
                    {/*  options={[*/}
                    {/*    { name: "Commanding", value: "Commanding" },*/}
                    {/*    { name: "Cooperative", value: "Cooperative" },*/}
                    {/*    { name: "Engaging", value: "Engaging" },*/}
                    {/*  ]}*/}
                    {/*/>*/}
                    <TextArea
                      fieldName={"sample_dialogue_of_this_ai_personality"}
                      label={formatMessage({
                        id: "Example dialogue",
                      })}
                      toolTipText={formatMessage({
                        id: "GLOBAL.TOOLTIP.EDIT_AI_PERSONALITY.DIALOGUE.EXAMPLE_DIALOGUE",
                      })}
                      formik={formik}
                      // defaultValue={
                      //   "In the resonant and unmistakable voice of Shahrukh Khan, he speaks with an authoritative tone, \"The movie industry isn't for the faint-hearted. It's a place where creativity and commerce fuse, where dreams are forged into realities. It demands tenacity, dedication, and unquenchable passion. It's an arena that pushes you to continually redefine your limits. So, brace yourself for the challenge, and remember - the spotlight isn't just given, it's earned"
                      // }
                      placeholder={""}
                      className={"!min-h-[140px]"}
                    />
                  </div>
                  {/* ) : ( */}
                  {/* <div>
                      <TextInput
                        label={"Adjectives"}
                        toolTipText={"Adjectives"}
                        fieldName={"adjectives"}
                        placeholder={"Custom Adjectives"}
                        fieldType={"text"}
                        formik={formik}
                      />
                      <TextInput
                        label={"Adverbs"}
                        toolTipText={"Adverbs"}
                        fieldName={"adverbs"}
                        placeholder={"Custom Adverbs"}
                        fieldType={"text"}
                        formik={formik}
                      />
                      <TextInput
                        label={"Colloquialism"}
                        toolTipText={"Colloquialism"}
                        fieldName={"colloquialism"}
                        placeholder={"Custom Colloquialism"}
                        fieldType={"text"}
                        formik={formik}
                      />{" "}
                      <TextArea
                        fieldName={"dialog-example"}
                        label={"Example dialogue"}
                        toolTipText={"Example dialogue"}
                        formik={formik}
                        defaultValue={
                          "In the resonant and unmistakable voice of Shahrukh Khan, he speaks with an authoritative tone, \"The movie industry isn't for the faint-hearted. It's a place where creativity and commerce fuse, where dreams are forged into realities. It demands tenacity, dedication, and unquenchable passion. It's an arena that pushes you to continually redefine your limits. So, brace yourself for the challenge, and remember - the spotlight isn't just given, it's earned"
                        }
                        placeholder={""}
                        className={"!min-h-[140px]"}
                      />
                    </div> */}
                  {/* )} */}
                </div>
                <div className={"mb-[32px] mt-[16px]"}>
                  <label
                    className={
                      "text-[13px] secondary-text leading-5   flex gap-1 mb-[28px]"
                    }
                  >
                    {formatMessage({
                      id: "Select dialogue length",
                    })}
                    <ToolTipUI
                      tooltipText={formatMessage({
                        id: "GLOBAL.TOOLTIP.EDIT_AI_PERSONALITY.DIALOGUE.SELECT_DIALOGUE_LENGTH",
                      })}
                    />
                  </label>
                  <div className={"md:w-1/2"}>
                    <Slider
                      startLabel={formatMessage({
                        id: "Shortest",
                      })}
                      stopLabel={formatMessage({
                        id: "Longest",
                      })}
                      fieldName={
                        "dialogue_length_this_ai_personality_should_reply_in"
                      }
                      formik={formik}
                      min={1}
                      max={5}
                      valueInPoints={false}
                      defaultValue={
                        dialogLength.indexOf(
                          initValues[
                            "dialogue_length_this_ai_personality_should_reply_in"
                          ]
                        ) + 1
                      }
                      onChange={(value: any) => {
                        formik.setFieldValue(
                          "dialogue_length_this_ai_personality_should_reply_in",
                          dialogLength[value - 1]
                        );
                      }}
                    />
                  </div>
                </div>
                <div>
                  <label
                    className={
                      "text-[13px] secondary-text leading-5   flex gap-1 mb-[28px]"
                    }
                  >
                    {formatMessage({
                      id: "How often you want this personality to ask counter questions",
                    })}

                    <ToolTipUI
                      tooltipText={formatMessage({
                        id: "GLOBAL.TOOLTIP.EDIT_AI_PERSONALITY.DIALOGUE.COUNTER_QUESTION",
                      })}
                    />
                  </label>
                  <div className={"md:w-1/2"}>
                    <Slider
                      startLabel={formatMessage({
                        id: "Never",
                      })}
                      stopLabel={formatMessage({
                        id: "Always",
                      })}
                      fieldName={"counter_question_frequency"}
                      formik={formik}
                      min={1}
                      max={5}
                      valueInPoints={false}
                      defaultValue={
                        counterQuestions.indexOf(
                          initValues["counter_question_frequency"]
                        ) + 1
                      }
                      onChange={(value: any) => {
                        formik.setFieldValue(
                          "counter_question_frequency",
                          counterQuestions[value - 1]
                        );
                      }}
                    />
                  </div>
                </div>
                <div className={"tab-container-2 p-[14px] md:p-[20px] rounded mt-[32px]"}>
                  <h4
                    className={
                      "text-[16px] leading-6 font-medium primary-text mb-[16px]"
                    }
                  >
                    {formatMessage({
                      id: "List of counter questions",
                    })}
                  </h4>
                  <FieldArray
                    name="counter_questions_this_ai_personality_should_ask"
                    render={(arrayHelpers) => {
                      return (
                        <>
                          {(
                            formik.values
                              ?.counter_questions_this_ai_personality_should_ask || [
                              "",
                            ]
                          ).map((_: any, index: number) => (
                            <div key={index}>
                              <div
                                className={
                                  "flex flex-col md:flex-row  items-start gap-x-[20px] gap-y-[12px]"
                                }
                              >
                                <div className={"flex-grow w-full md:w-auto"}>
                                  <TextArea
                                    label={formatMessage({
                                      id: "Question" + " " + (index + 1),
                                    })}
                                    fieldName={`counter_questions_this_ai_personality_should_ask[${index}]`}
                                    placeholder={formatMessage({
                                      id: "Write a question here",
                                    })}
                                    formik={formik}
                                    // defaultValue={_}
                                    toolTipText={formatMessage({
                                      id: "GLOBAL.TOOLTIP.EDIT_AI_PERSONALITY.DIALOGUE.LIST_OF_QUESTION",
                                    })}
                                    className={"!min-h-[140px]"}
                                  />
                                </div>
                                <div
                                  className={
                                    "flex justify-end gap-[12px] md:mt-[26px]"
                                  }
                                >
                                  <button
                                    className={
                                      "p-[6px] bg-[#FFFFFF1A] rounded-[6px]"
                                    }
                                    onClick={() => {
                                      if (!isEqual(index, 0))
                                        arrayHelpers.remove(index);
                                    }}
                                  >
                                    <svg
                                      width="24"
                                      height="24"
                                      viewBox="0 0 24 24"
                                      fill="none"
                                      xmlns="http://www.w3.org/2000/svg"
                                    >
                                      <path
                                        d="M4 7H20M10 11V17M14 11V17M5 7L6 19C6 19.5304 6.21071 20.0391 6.58579 20.4142C6.96086 20.7893 7.46957 21 8 21H16C16.5304 21 17.0391 20.7893 17.4142 20.4142C17.7893 20.0391 18 19.5304 18 19L19 7M9 7V4C9 3.73478 9.10536 3.48043 9.29289 3.29289C9.48043 3.10536 9.73478 3 10 3H14C14.2652 3 14.5196 3.10536 14.7071 3.29289C14.8946 3.48043 15 3.73478 15 4V7"
                                        stroke="#FFFFFFA6"
                                        strokeWidth="1.6"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                      />
                                    </svg>
                                  </button>
                                  {/* <button
                                    className={
                                      "p-[6px] bg-[#FFFFFF1A] rounded-[6px]"
                                    }
                                  >
                                    <svg
                                      width="24"
                                      height="24"
                                      viewBox="0 0 24 24"
                                      fill="none"
                                      xmlns="http://www.w3.org/2000/svg"
                                    >
                                      <g clipPath="url(#clip0_344_35631)">
                                        <path
                                          d="M17 21H7M17 21H17.803C18.921 21 19.48 21 19.907 20.782C20.284 20.59 20.59 20.284 20.782 19.908C21 19.481 21 18.921 21 17.803V9.22C21 8.77 21 8.545 20.952 8.331C20.9094 8.14007 20.839 7.95643 20.743 7.786C20.637 7.596 20.487 7.431 20.193 7.104L17.438 4.042C17.097 3.664 16.924 3.472 16.717 3.334C16.5303 3.21012 16.3241 3.11851 16.107 3.063C15.863 3 15.6 3 15.075 3H6.2C5.08 3 4.52 3 4.092 3.218C3.71565 3.40969 3.40969 3.71565 3.218 4.092C3 4.52 3 5.08 3 6.2V17.8C3 18.92 3 19.48 3.218 19.907C3.41 20.284 3.715 20.59 4.092 20.782C4.519 21 5.079 21 6.197 21H7M17 21V17.197C17 16.079 17 15.519 16.782 15.092C16.5899 14.7156 16.2836 14.4096 15.907 14.218C15.48 14 14.92 14 13.8 14H10.2C9.08 14 8.52 14 8.092 14.218C7.71565 14.4097 7.40969 14.7157 7.218 15.092C7 15.52 7 16.08 7 17.2V21M15 7H9"
                                          stroke="#FFFFFFA6"
                                          strokeWidth="1.6"
                                          strokeLinecap="round"
                                          strokeLinejoin="round"
                                        />
                                      </g>
                                      <defs>
                                        <clipPath id="clip0_344_35631">
                                          <rect
                                            width="24"
                                            height="24"
                                            fill="white"
                                          />
                                        </clipPath>
                                      </defs>
                                    </svg>
                                  </button> */}
                                </div>
                              </div>
                            </div>
                          ))}
                          <div className={"flex justify-end mt-[16px]"}>
                            <button
                              type="button"
                              className={
                                "bg-[#C2D24B] text-black text-[13px] leading-[20px] font-medium  py-[8px] w-full  md:w-[180px] rounded"
                              }
                              onClick={() => arrayHelpers.push("")}
                            >
                              {formatMessage({
                                id: "Add More Question",
                              })}
                            </button>
                          </div>
                        </>
                      );
                    }}
                  />
                </div>
                <div className={"mt-[16px]"}>
                  <label
                    className={
                      "text-[13px] secondary-text leading-5   flex gap-1 mb-[8px]"
                    }
                  >
                    {formatMessage({
                      id: "Capitalization in Chat Reply",
                    })}

                    <ToolTipUI
                      tooltipText={formatMessage({
                        id: "GLOBAL.TOOLTIP.EDIT_AI_PERSONALITY.DIALOGUE.CAPITALIZATION",
                      })}
                    />
                  </label>
                  <div
                    className={
                      "flex flex-col md:flex-row gap-x-[20px] md:gap-x-20 gap-y-4 mb-6"
                    }
                  >
                    <div className={"flex items-center gap-x-4"}>
                      <Field
                        type={"radio"}
                        formik={formik}
                        name="capitalization_in_reply"
                        className={"form-check-input min-w-[23px]"}
                        checked={normalType}
                        value={"Whenever needed"}
                        onClick={() => setNormalType(true)}
                      />
                      <label
                        className={clsx(
                          "text-[14px] leading-6 flex gap-1",
                          normalType ? "main-text" : "secondary-text"
                        )}
                      >
                        {formatMessage({
                          id: "Normal Style",
                        })}

                        <ToolTipUI
                          tooltipText={formatMessage({
                            id: "GLOBAL.TOOLTIP.EDIT_AI_PERSONALITY.DIALOGUE.CAPITALIZATION.NORMAL_STYLE",
                          })}
                        />
                      </label>
                    </div>
                    <div className={"flex items-center gap-x-4"}>
                      <Field
                        type={"radio"}
                        formik={formik}
                        name="capitalization_in_reply"
                        className={"form-check-input min-w-[23px]"}
                        checked={!normalType}
                        value={"Never"}
                        onClick={() => setNormalType(false)}
                      />
                      <label
                        className={clsx(
                          "text-[14px] leading-6 flex gap-1",
                          !normalType ? "main-text" : "secondary-text"
                        )}
                      >
                        {formatMessage({
                          id: "Never capitalize replies",
                        })}

                        <ToolTipUI
                          tooltipText={formatMessage({
                            id: "GLOBAL.TOOLTIP.EDIT_AI_PERSONALITY.DIALOGUE.CAPITALIZATION.NEVER_CAPITALIZE_REPLIES",
                          })}
                        />
                      </label>
                    </div>
                  </div>
                </div>

                <div
                  className={
                    "tab-container-2 p-[14px] md:p-[20px_20px_1px_20px] rounded mt-[24px]"
                  }
                >
                  <h4
                    className={
                      "text-[16px] leading-6 font-medium primary-text mb-[16px]"
                    }
                  >
                    {formatMessage({
                      id: "Frequently used words at the start of replies by AI personality",
                    })}
                  </h4>
                  {/* <div
                    className={
                      "flex flex-col md:flex-row gap-x-[20px] md:gap-x-20 gap-y-4 mb-6"
                    }
                  >
                    <div className={"flex items-center gap-x-4"}>
                      <input
                        type={"radio"}
                        className={"form-check-input"}
                        checked={replyType}
                        onClick={() => setReplyType(true)}
                      />
                      <label
                        className={clsx(
                          "text-[14px] leading-6 flex gap-1",
                          replyType ? "!text-[#C2D24B]" : "!text-[#FFFFFFA6]"
                        )}
                      >
                        In some of the replies <ToolTipUI tooltipText={""} />
                      </label>
                    </div>
                    <div className={"flex items-center gap-x-4"}>
                      <input
                        type={"radio"}
                        className={"form-check-input"}
                        checked={!replyType}
                        onClick={() => setReplyType(false)}
                      />
                      <label
                        className={clsx(
                          "text-[14px] leading-6 flex gap-1",
                          !replyType ? "!text-[#C2D24B]" : "!text-[#FFFFFFA6]"
                        )}
                      >
                        In every reply <ToolTipUI tooltipText={""} />
                      </label>
                    </div>
                  </div> */}
                  <FormikReactCreatable
                    isMulti={true}
                    formik={formik}
                    name={"words_to_say_before_reply"}
                    className={"without-arrow mb-0"}
                    label={formatMessage({
                      id: "Words to say at the beginning of each reply",
                    })}
                    placeholder={formatMessage({
                      id: "Type something and press Enter or Tab to create words",
                    })}
                    tooltipText={formatMessage({
                      id: "GLOBAL.TOOLTIP.EDIT_AI_PERSONALITY.DIALOGUE.BEGINNING_WORDS",
                    })}
                    isClearable={false}
                    defaultValue={formik.values?.words_to_say_before_reply?.map(
                      (el: string, index: number) => {
                        return { value: el, label: el, id: index };
                      }
                    )}
                  />
                </div>

                <div
                  className={
                    "tab-container-2 p-[14px] md:p-[20px_20px_1px_20px] rounded mt-[24px]"
                  }
                >
                  <h4
                    className={
                      "text-[16px] leading-6 font-medium primary-text mb-[16px]"
                    }
                  >
                    {formatMessage({
                      id: "Frequently used words at the end of replies by AI personality",
                    })}
                  </h4>
                  {/* <div
                    className={
                      "flex flex-col md:flex-row gap-x-[20px] md:gap-x-20 gap-y-4 mb-6"
                    }
                  >
                    <div className={"flex items-center gap-x-4"}>
                      <input
                        type={"radio"}
                        className={"form-check-input"}
                        checked={replyType}
                        onClick={() => setReplyType(true)}
                      />
                      <label
                        className={clsx(
                          "text-[14px] leading-6 flex gap-1",
                          replyType ? "!text-[#C2D24B]" : "!text-[#FFFFFFA6]"
                        )}
                      >
                        In some of the replies <ToolTipUI tooltipText={""} />
                      </label>
                    </div>
                    <div className={"flex items-center gap-x-4"}>
                      <input
                        type={"radio"}
                        className={"form-check-input"}
                        checked={!replyType}
                        onClick={() => setReplyType(false)}
                      />
                      <label
                        className={clsx(
                          "text-[14px] leading-6 flex gap-1",
                          !replyType ? "!text-[#C2D24B]" : "!text-[#FFFFFFA6]"
                        )}
                      >
                        In every reply <ToolTipUI tooltipText={""} />
                      </label>
                    </div>
                  </div> */}
                  <FormikReactCreatable
                    isMulti={true}
                    className={"without-arrow mb-0"}
                    name={"words_to_say_at_end_of_each_reply"}
                    label={formatMessage({
                      id: "Words to say at the end of each reply",
                    })}
                    isClearable={false}
                    placeholder={formatMessage({
                      id: "Type something and press Enter or Tab to create name",
                    })}
                    tooltipText={formatMessage({
                      id: "GLOBAL.TOOLTIP.EDIT_AI_PERSONALITY.DIALOGUE.ENDING_WORDS",
                    })}
                    formik={formik}
                    defaultValue={formik.values?.words_to_say_at_end_of_each_reply?.map(
                      (el: string, index: number) => {
                        return { value: el, label: el, id: index };
                      }
                    )}
                  />
                </div>
                <div className={"tab-container-2 p-[14px] md:p-[20px] rounded mt-[16px]"}>
                  <h4
                    className={
                      "text-[16px] leading-6 font-medium primary-text mb-[16px]"
                    }
                  >
                    {formatMessage({
                      id: "List of famous dialogues of this AI Personality which it will often use",
                    })}
                  </h4>
                  <FieldArray
                    name="list_of_famous_dialogues"
                    render={(arrayHelpers) => {
                      return (
                        <>
                          {(
                            formik.values
                              ?.list_of_famous_dialogues || [
                              "",
                            ]
                          ).map((_: any, index: number) => (
                            <div key={index}>
                              <div
                                className={
                                  "flex flex-col md:flex-row  items-start gap-x-[20px] gap-y-[12px]"
                                }
                              >
                                <div className={"flex-grow w-full md:w-auto"}>
                                  <TextArea
                                    label={formatMessage({
                                      id: "Dialogue" + " " + (index + 1),
                                    })}
                                    fieldName={`list_of_famous_dialogues[${index}]`}
                                    placeholder={formatMessage({
                                      id: "Write a dialogue here",
                                    })}
                                    formik={formik}
                                    // defaultValue={_}
                                    toolTipText={formatMessage({
                                      id: "GLOBAL.TOOLTIP.EDIT_AI_PERSONALITY.DIALOGUE.DIALOGUE_VALUE",
                                    })}
                                    className={"!min-h-[140px]"}
                                  />
                                </div>
                                <div
                                  className={
                                    "flex justify-end gap-[12px] md:mt-[26px]"
                                  }
                                >
                                  <button
                                    className={
                                      "p-[6px] bg-[#FFFFFF1A] rounded-[6px]"
                                    }
                                    onClick={() => {
                                      if (!isEqual(index, 0))
                                        arrayHelpers.remove(index);
                                    }}
                                  >
                                    <svg
                                      width="24"
                                      height="24"
                                      viewBox="0 0 24 24"
                                      fill="none"
                                      xmlns="http://www.w3.org/2000/svg"
                                    >
                                      <path
                                        d="M4 7H20M10 11V17M14 11V17M5 7L6 19C6 19.5304 6.21071 20.0391 6.58579 20.4142C6.96086 20.7893 7.46957 21 8 21H16C16.5304 21 17.0391 20.7893 17.4142 20.4142C17.7893 20.0391 18 19.5304 18 19L19 7M9 7V4C9 3.73478 9.10536 3.48043 9.29289 3.29289C9.48043 3.10536 9.73478 3 10 3H14C14.2652 3 14.5196 3.10536 14.7071 3.29289C14.8946 3.48043 15 3.73478 15 4V7"
                                        stroke="#FFFFFFA6"
                                        strokeWidth="1.6"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                      />
                                    </svg>
                                  </button>
                                  {/* <button
                                    className={
                                      "p-[6px] bg-[#FFFFFF1A] rounded-[6px]"
                                    }
                                  >
                                    <svg
                                      width="24"
                                      height="24"
                                      viewBox="0 0 24 24"
                                      fill="none"
                                      xmlns="http://www.w3.org/2000/svg"
                                    >
                                      <g clipPath="url(#clip0_344_35631)">
                                        <path
                                          d="M17 21H7M17 21H17.803C18.921 21 19.48 21 19.907 20.782C20.284 20.59 20.59 20.284 20.782 19.908C21 19.481 21 18.921 21 17.803V9.22C21 8.77 21 8.545 20.952 8.331C20.9094 8.14007 20.839 7.95643 20.743 7.786C20.637 7.596 20.487 7.431 20.193 7.104L17.438 4.042C17.097 3.664 16.924 3.472 16.717 3.334C16.5303 3.21012 16.3241 3.11851 16.107 3.063C15.863 3 15.6 3 15.075 3H6.2C5.08 3 4.52 3 4.092 3.218C3.71565 3.40969 3.40969 3.71565 3.218 4.092C3 4.52 3 5.08 3 6.2V17.8C3 18.92 3 19.48 3.218 19.907C3.41 20.284 3.715 20.59 4.092 20.782C4.519 21 5.079 21 6.197 21H7M17 21V17.197C17 16.079 17 15.519 16.782 15.092C16.5899 14.7156 16.2836 14.4096 15.907 14.218C15.48 14 14.92 14 13.8 14H10.2C9.08 14 8.52 14 8.092 14.218C7.71565 14.4097 7.40969 14.7157 7.218 15.092C7 15.52 7 16.08 7 17.2V21M15 7H9"
                                          stroke="#FFFFFFA6"
                                          strokeWidth="1.6"
                                          strokeLinecap="round"
                                          strokeLinejoin="round"
                                        />
                                      </g>
                                      <defs>
                                        <clipPath id="clip0_344_35631">
                                          <rect
                                            width="24"
                                            height="24"
                                            fill="white"
                                          />
                                        </clipPath>
                                      </defs>
                                    </svg>
                                  </button> */}
                                </div>
                              </div>
                            </div>
                          ))}
                          <div className={"flex justify-end mt-[16px]"}>
                            <button
                              type="button"
                              className={
                                "bg-[#C2D24B] text-black text-[13px] leading-[20px] font-medium  py-[8px] w-full  md:w-[180px] rounded"
                              }
                              onClick={() => arrayHelpers.push("")}
                            >
                              {formatMessage({
                                id: "Add More Dialogue",
                              })}
                            </button>
                          </div>
                        </>
                      );
                    }}
                  />
                </div>
              </div>

              <div
                className={
                  "col-span-12 md:col-span-5 flex flex-col justify-start gap-[20px]"
                }
              >
                <div
                  className={
                    "flex justify-end gap-[10px] md:order-1 md:mt-auto"
                  }
                >
                  {/* <button
                    type="button"
                    onClick={() => setOpenEdit(false)}
                    className={
                      "main-button-primary-light text-[14px] leading-5 font-medium py-[12px] w-1/2 md:w-[128px] rounded"
                    }
                  >
                    {formatMessage({
                      id: "Cancel",
                    })}
                  </button> */}
                  <button
                    type="submit"
                    className={`main-button text-[14px] leading-5 font-medium py-[12px] w-full sm:w-1/2  md:w-[140px] rounded ${
                      disableSave ? "opacity-75" : ""
                    }`}
                    disabled={disableSave}
                  >
                    {formatMessage({
                      id: `${disableSave ? "Please Wait" : "Save"}`,
                    })}
                  </button>
                </div>
                <InfoCard
                  title={formatMessage({
                    id: "Understanding Dialogue Style of AI Personality",
                  })}
                  desc={
                    <>
                      {formatMessage({
                        id: 'In Kamoto.AI, the "Dialogue Style" section allows you to shape the manner in which your virtual AI persona communicates with users. You have the freedom to define the dialogue style, whether it\'s assertive, commanding, or submissive, among others. ',
                      })}
                      <br></br>
                      <br></br>
                      {formatMessage({
                        id: "By customizing the dialogue style, you give your AI personality a distinct voice and tone that aligns with its character traits and purpose. Whether it's a friendly companion, an authoritative guide, or a playful conversationalist, Kamoto.AI empowers you to create AI personalities that resonate with users on a deeper level. ",
                      })}
                      <br></br>
                      <br></br>
                      {formatMessage({
                        id: "With the ability to provide example dialogues, you can bring your AI personality to life and create meaningful interactions that captivate and engage users.",
                      })}
                      <br></br>
                      <br></br>
                    </>
                  }
                  slug={""}
                  isAnchor={
                    "https://help.kamoto.ai/en/articles/7941891-exploring-dialogue-style-tab-of-ai-personalities"
                  }
                />
              </div>
            </div>
          </Form>
        );
      }}
    </Formik>
  );
};

export default Dialog;
