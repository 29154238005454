import React from "react";
import { Modal } from "react-bootstrap";
import { ToolTipUI } from "../../../widgets/components/UI/ToolTipUI";
import { useIntl } from "react-intl";
import { Form, Formik } from "formik";
import TextInput from "../../../widgets/components/Input/TextInput";
import TextArea from "../../../widgets/components/Input/TextArea";

const BlockModal = ({
  show,
  onHide,
}: {
  show: boolean;
  onHide: () => void;
}) => {
  const { formatMessage } = useIntl();
  return (
    <div>
      <Modal
        size="sm"
        show={show}
        onHide={onHide}
        aria-labelledby="contained-modal-title-vcenter"
        className={"z-[999999] "}
        centered
        contentClassName={"md:min-w-[480px]"}
      >
        <Modal.Header closeButton className={"px-10"}>
          <div className={"flex items-center justify-between w-full"}>
            <div className="d-flex fw-bold">
              <h2 className="text-[20px] primary-text leading-[28px]">
                {formatMessage({ id: "Block Carol Rack" })}
              </h2>{" "}
            </div>
            <button onClick={onHide}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
              >
                <path
                  d="M16.2414 6.34311L6.34187 16.2426C5.95135 16.6331 5.95135 17.2663 6.34187 17.6568C6.7324 18.0473 7.36556 18.0473 7.75609 17.6568L17.6556 7.75732C18.0461 7.36679 18.0461 6.73363 17.6556 6.34311C17.2651 5.95258 16.6319 5.95258 16.2414 6.34311Z"
                  fill="#C2D24B"
                  fill-opacity="0.5"
                />
                <path
                  opacity="0.3"
                  d="M17.6554 16.2426L7.75593 6.34309C7.36541 5.95257 6.73225 5.95257 6.34172 6.34309C5.9512 6.73362 5.9512 7.36678 6.34172 7.75731L16.2412 17.6568C16.6317 18.0473 17.2649 18.0473 17.6554 17.6568C18.046 17.2663 18.046 16.6331 17.6554 16.2426Z"
                  fill="#C2D24B"
                  fill-opacity="0.5"
                />
              </svg>
            </button>
          </div>
        </Modal.Header>
        <Modal.Body>
          <Formik initialValues={{ voiceName: "" }} onSubmit={() => {}}>
            {(formik: any) => (
              <Form>
                <TextArea
                  fieldName={"voiceName"}
                  formik={formik}
                  placeholder={formatMessage({
                    id: "Type You message here, user will get this message in notification",
                  })}
                  label={formatMessage({ id: "Send Messagee" })}
                  toolTipText={formatMessage({
                    id: "Send Message",
                  })}
                  className="!min-h-[80px]"
                />
              </Form>
            )}
          </Formik>
          <div className={"flex flex-grow md:justify-end gap-x-4"}>
            <button
              type="submit"
              className={`main-button-primary-light text-[14px] leading-5 font-medium py-[12px] w-1/2 md:w-[128px] rounded ${
                false ? "opacity-75" : ""
              }`}
              onClick={onHide}
            >
              {formatMessage({
                id: "Cancel",
              })}
            </button>
            <button
              type="submit"
              className={`main-button text-[14px] leading-5 font-medium py-[12px] w-full sm:w-1/2  md:w-[140px] rounded ${
                false ? "opacity-75" : ""
              }`}
            >
              {formatMessage({
                id: "Send",
              })}
            </button>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default BlockModal;
