import React, { useState } from "react";
import TextInput from "../../../widgets/components/Input/TextInput";
import { useIntl } from "react-intl";
import { Form, Formik } from "formik";
import TextArea from "../../../widgets/components/Input/TextArea";
import { InfoCard } from "../../../widgets/components/UI/InfoCard";
import Select from "../../../../components/select/select";
import { ToolTipUI } from "../../../widgets/components/UI/ToolTipUI";
import Slider from "../../../../components/slider";
import FormikReactCreatable from "../../../widgets/components/Input/FormikReactCreatable";
import { updatePersonalityInfo } from "../../../profile/core/_requests";
import { useAuth } from "../../../auth";
import * as Yup from "yup";
import { toast } from "react-toastify";
import useGTM from "../../../../hooks/useGTM";
import { GTMEvent } from "../../../../hooks/gtm_helpers";

const Personality: React.FC<any> = ({ setOpenEdit, personalityData }) => {
  const { formatMessage } = useIntl();
  const [disableSave, setDisableSave] = useState(false);
  const {dataLayerPush} = useGTM()
  const {
    referenceData,
    setSelectedPersonality,
    selectedPersonality: updatedData,
  } = useAuth();
  personalityData = updatedData || personalityData;
  const onSubmit = async (values: any, { setSubmitting }: any) => {
    // save data to api
    console.log(values)
    setDisableSave(true);
    setSubmitting(true);
    try {
      let {
        data: { success, data },
      } = await updatePersonalityInfo({
        personalityId: personalityData.personalityId,
        personalityJson: {
          ...personalityData.personalityJson,
          personality: values,
        },
      });
      if (success) {
        setSelectedPersonality(data);
        toast.success(
          formatMessage({ id: "Personality details updated successfully" })
        );
      } else {
        setSubmitting(false);
        toast.error(
          formatMessage({ id: "Error when updating Personality Details" }),
          {
            autoClose: false,
            closeButton: true, // Display close button (cross icon)
          }
        );
      }
      setDisableSave(false);
      dataLayerPush(GTMEvent.AIPersonalityEditSuccessful,{"tab": "personality-details"})
    } catch (e) {
      toast.error(
        formatMessage({ id: "Error when updating Personality Details" }),
        {
          autoClose: false,
          closeButton: true, // Display close button (cross icon)
        }
      );
      setSubmitting(false);
      setDisableSave(false);
      dataLayerPush(GTMEvent.AIPersonalityEditFailed,{"tab": "personality-details"})
    }
  };

  const personalityValidationSchema = Yup.object().shape({
    // traits: Yup.array()
    //   .of(
    //     Yup.string()
    //       .min(2, "Trait must be at least 2 characters")
    //       .max(50, "Trait cannot exceed 50 characters")
    //   )
    //   // .min(1, "At least one trait is required")
    //   .max(10, "Maximum of 10 traits allowed"),
    mood_of_this_ai_personality: Yup.object().shape({
      happy_and_sad: Yup.number()
        .min(0, "Value must be between 0 and 1")
        .max(1, "Value must be between 0 and 1"),
      calm_and_angry: Yup.number()
        .min(0, "Value must be between 0 and 1")
        .max(1, "Value must be between 0 and 1"),
      relaxed_and_stressed: Yup.number()
        .min(0, "Value must be between 0 and 1")
        .max(1, "Value must be between 0 and 1"),
      excited_and_bored: Yup.number()
        .min(0, "Value must be between 0 and 1")
        .max(1, "Value must be between 0 and 1"),
      energized_and_exhausted: Yup.number()
        .min(0, "Value must be between 0 and 1")
        .max(1, "Value must be between 0 and 1"),
      cheerful_and_dull: Yup.number()
        .min(0, "Value must be between 0 and 1")
        .max(1, "Value must be between 0 and 1"),
      hopefull_and_hopeless: Yup.number()
        .min(0, "Value must be between 0 and 1")
        .max(1, "Value must be between 0 and 1"),
      inspired_and_dishearted: Yup.number()
        .min(0, "Value must be between 0 and 1")
        .max(1, "Value must be between 0 and 1"),
      admiration_and_disgust: Yup.number()
        .min(0, "Value must be between 0 and 1")
        .max(1, "Value must be between 0 and 1"),
      peaceful_and_distressed: Yup.number()
        .min(0, "Value must be between 0 and 1")
        .max(1, "Value must be between 0 and 1"),
    }),
    personality_type_of_this_ai_personality: Yup.object().shape({
      positive_and_negative: Yup.number()
        .min(0, "Value must be between 0 and 1")
        .max(1, "Value must be between 0 and 1"),
      calm_and_anxious: Yup.number()
        .min(0, "Value must be between 0 and 1")
        .max(1, "Value must be between 0 and 1"),
      peaceful_and_aggressive: Yup.number()
        .min(0, "Value must be between 0 and 1")
        .max(1, "Value must be between 0 and 1"),
      extrovert_and_introvert: Yup.number()
        .min(0, "Value must be between 0 and 1")
        .max(1, "Value must be between 0 and 1"),
      optimistic_and_pessimistic: Yup.number()
        .min(0, "Value must be between 0 and 1")
        .max(1, "Value must be between 0 and 1"),
      humble_and_arrogant: Yup.number()
        .min(0, "Value must be between 0 and 1")
        .max(1, "Value must be between 0 and 1"),
      confident_and_insecure: Yup.number()
        .min(0, "Value must be between 0 and 1")
        .max(1, "Value must be between 0 and 1"),
      risk_taking_and_cautious: Yup.number()
        .min(0, "Value must be between 0 and 1")
        .max(1, "Value must be between 0 and 1"),
      agreeableness_and_disagreeableness: Yup.number()
        .min(0, "Value must be between 0 and 1")
        .max(1, "Value must be between 0 and 1"),
      deliberate_and_impulsive: Yup.number()
        .min(0, "Value must be between 0 and 1")
        .max(1, "Value must be between 0 and 1"),
    }),
  });

  return (
    <Formik
      initialValues={personalityData?.personalityJson?.personality || {
        mood_of_this_ai_personality: {
          happy_and_sad: 0.5,
          calm_and_angry: 0.5,
          relaxed_and_stressed: 0.5,
          excited_and_bored: 0.5,
          energized_and_exhausted: 0.5,
          cheerful_and_dull: 0.5,
          hopefull_and_hopeless: 0.5,
          inspired_and_dishearted: 0.5,
          admiration_and_disgust: 0.5,
          peaceful_and_distressed: 0.5,
        },personality_type_of_this_ai_personality: {
          positive_and_negative: 0.5,
          calm_and_anxious: 0.5,
          peaceful_and_aggressive: 0.5,
          extrovert_and_introvert: 0.5,
          optimistic_and_pessimistic: 0.5,
          humble_and_arrogant: 0.5,
          confident_and_insecure: 0.5,
          risk_taking_and_cautious: 0.5,
          agreeableness_and_disagreeableness: 0.5,
          deliberate_and_impulsive: 0.5,
        },
      }}
      onSubmit={onSubmit}
      validationSchema={personalityValidationSchema}
      validateOnMount
    >
      {(formik) => {
        return (
          <Form>
            <div className={"grid grid-cols-12 gap-y-[20px] md:gap-x-[40px]"}>
              <div className={"col-span-12 xl:col-span-7 order-0"}>
                <FormikReactCreatable
                  tooltipText={formatMessage({
                    id: "GLOBAL.TOOLTIP.EDIT_AI_PERSONALITY.PERSONALITY.CHARACTER_TRAITS",
                  })}
                  isMulti={true}
                  name={"traits"}
                  label={formatMessage({
                    id: "Character Traits",
                  })}
                  isClearable={false}
                  formik={formik}
                  placeholder={formatMessage({
                    id: "Type something and press Enter or Tab to create name",
                  })}
                  defaultValue={formik.values?.traits?.map(
                    (el: string, index: number) => {
                      return { value: el, label: el, id: index };
                    }
                  )}
                  // defaultValue={[
                  //   { value: "funny", label: "funny" },
                  //   { value: "romantic", label: "romantic" },
                  //   { value: "flirty", label: "flirty" },
                  //   { value: "witty", label: "witty" },
                  // ]}
                  // options={[
                  //   { value: "funny", label: "funny" },
                  //   { value: "romantic", label: "romantic" },
                  //   { value: "flirty", label: "flirty" },
                  //   { value: "witty", label: "witty" },
                  //   { value: "vanilla", label: "Vanilla" },
                  // ]}
                />
                <label
                  className={"flex text-[13px] leading-5 secondary-text mb-1"}
                >
                  {formatMessage({
                    id: "Basic Traits Control",
                  })}
                  <ToolTipUI
                    tooltipText={formatMessage({
                      id: "GLOBAL.TOOLTIP.EDIT_AI_PERSONALITY.PERSONALITY.BASIC_TRAITS",
                    })}
                  />
                </label>
                <div
                  className={
                    "flex flex-col md:flex-row  gap-y-[24px] md:gap-x-[24px]"
                  }
                >
                  <div className="p-[14px] md:p-[20px] md:pb-[32px] tab-container-2 rounded md:w-1/2 w-full">
                    <div className={"pb-[20px] border-b  border-main"}>
                      <Slider
                        startLabel={formatMessage({ id: "Happy" })}
                        stopLabel={formatMessage({ id: "Sad" })}
                        fieldName="mood_of_this_ai_personality.happy_and_sad"
                        formik={formik}
                        // min={1}
                        // max={21}
                        // valueInPoints={false}
                        // defaultValue={11}
                      />
                    </div>
                    <div className={"py-[20px] border-b border-main"}>
                      <Slider
                        startLabel={formatMessage({ id: "Calm" })}
                        stopLabel={formatMessage({ id: "Anger" })}
                        fieldName="mood_of_this_ai_personality.calm_and_angry"
                        formik={formik}
                        // min={1}
                        // max={21}
                        // valueInPoints={false}
                        // defaultValue={11}
                      />
                    </div>
                    <div className={"py-[20px] border-b border-main"}>
                      <Slider
                        startLabel={formatMessage({ id: "Relaxed" })}
                        stopLabel={formatMessage({ id: "Stressed" })}
                        fieldName="mood_of_this_ai_personality.relaxed_and_stressed"
                        formik={formik}
                        // min={1}
                        // max={21}
                        // valueInPoints={false}
                        // defaultValue={11}
                      />
                    </div>
                    <div className={"py-[20px] border-b  border-main"}>
                      <Slider
                        startLabel={formatMessage({ id: "Excited" })}
                        stopLabel={formatMessage({ id: "Bored" })}
                        fieldName="mood_of_this_ai_personality.excited_and_bored"
                        formik={formik}
                        // min={1}
                        // max={21}
                        // valueInPoints={false}
                        // defaultValue={11}
                      />
                    </div>
                    <div className={"py-[20px] border-b border-main"}>
                      <Slider
                        startLabel={formatMessage({ id: "Energized" })}
                        stopLabel={formatMessage({ id: "Exhausted" })}
                        fieldName="mood_of_this_ai_personality.energized_and_exhausted"
                        formik={formik}
                        // min={1}
                        // max={21}
                        // valueInPoints={false}
                        // defaultValue={11}
                      />
                    </div>
                    <div className={"py-[20px] border-b  border-main"}>
                      <Slider
                        startLabel={formatMessage({ id: "Cheerful" })}
                        stopLabel={formatMessage({ id: "Gloomy (Dull)" })}
                        fieldName="mood_of_this_ai_personality.cheerful_and_dull"
                        formik={formik}
                        // min={1}
                        // max={21}
                        // valueInPoints={false}
                        // defaultValue={11}
                      />
                    </div>
                    <div className={"py-[20px] border-b  border-main"}>
                      <Slider
                        startLabel={formatMessage({ id: "Hopeful" })}
                        stopLabel={formatMessage({ id: "Hopeless" })}
                        fieldName="mood_of_this_ai_personality.hopefull_and_hopeless"
                        formik={formik}
                        // min={1}
                        // max={21}
                        // valueInPoints={false}
                        // defaultValue={11}
                      />
                    </div>
                    <div className={"py-[20px] border-b  border-main"}>
                      <Slider
                        startLabel={formatMessage({ id: "Inspired" })}
                        stopLabel={formatMessage({ id: "Dishearted" })}
                        fieldName="mood_of_this_ai_personality.inspired_and_dishearted"
                        formik={formik}
                        // min={1}
                        // max={21}
                        // valueInPoints={false}
                        // defaultValue={11}
                      />
                    </div>
                    <div className={"py-[20px] border-b  border-main"}>
                      <Slider
                        startLabel={formatMessage({ id: "Admiration" })}
                        stopLabel={formatMessage({ id: "Disgust" })}
                        fieldName="mood_of_this_ai_personality.admiration_and_disgust"
                        formik={formik}
                        // min={1}
                        // max={21}
                        // valueInPoints={false}
                        // defaultValue={11}
                      />
                    </div>
                    <div className={"pt-[20px]"}>
                      <Slider
                        startLabel={formatMessage({ id: "Serene (peaceful)" })}
                        stopLabel={formatMessage({ id: "Distressed" })}
                        fieldName="mood_of_this_ai_personality.peaceful_and_distressed"
                        formik={formik}
                        // min={1}
                        // max={21}
                        // valueInPoints={false}
                        // defaultValue={11}
                      />
                    </div>
                  </div>
                  <div
                    className={
                      "p-[14px] md:p-[20px] md:pb-[32px] tab-container-2 rounded md:w-1/2 w-full"
                    }
                  >
                    <div className={"pb-[20px] border-b border-main"}>
                      <Slider
                        startLabel={formatMessage({ id: "Positive" })}
                        stopLabel={formatMessage({ id: "Negative" })}
                        fieldName="personality_type_of_this_ai_personality.positive_and_negative"
                        formik={formik}
                        // min={1}
                        // max={21}
                        // valueInPoints={false}
                        // defaultValue={11}
                      />
                    </div>
                    <div className={"py-[20px] border-b  border-main"}>
                      <Slider
                        startLabel={formatMessage({ id: "Calm" })}
                        stopLabel={formatMessage({ id: "Anxious" })}
                        fieldName="personality_type_of_this_ai_personality.calm_and_anxious"
                        formik={formik}
                        // min={1}
                        // max={21}
                        // valueInPoints={false}
                        // defaultValue={11}
                      />
                    </div>
                    <div className={"py-[20px] border-b  border-main"}>
                      <Slider
                        startLabel={formatMessage({ id: "Peaceful" })}
                        stopLabel={formatMessage({ id: "Aggressive" })}
                        fieldName="personality_type_of_this_ai_personality.peaceful_and_aggressive"
                        formik={formik}
                        // min={1}
                        // max={21}
                        // valueInPoints={false}
                        // defaultValue={11}
                      />
                    </div>
                    <div className={"py-[20px] border-b  border-main"}>
                      <Slider
                        startLabel={formatMessage({ id: "Extrovert" })}
                        stopLabel={formatMessage({ id: "Introvert" })}
                        fieldName="personality_type_of_this_ai_personality.extrovert_and_introvert"
                        formik={formik}
                        // min={1}
                        // max={21}
                        // valueInPoints={false}
                        // defaultValue={11}
                      />
                    </div>
                    <div className={"py-[20px] border-b  border-main"}>
                      <Slider
                        startLabel={formatMessage({ id: "Optimistic" })}
                        stopLabel={formatMessage({ id: "Pessimistic" })}
                        fieldName="personality_type_of_this_ai_personality.optimistic_and_pessimistic"
                        formik={formik}
                        // min={1}
                        // max={21}
                        // valueInPoints={false}
                        // defaultValue={11}
                      />
                    </div>
                    <div className={"py-[20px] border-b  border-main"}>
                      <Slider
                        startLabel={formatMessage({ id: "Humble" })}
                        stopLabel={formatMessage({ id: "Arrogant" })}
                        fieldName="personality_type_of_this_ai_personality.humble_and_arrogant"
                        formik={formik}
                        // min={1}
                        // max={21}
                        // valueInPoints={false}
                        // defaultValue={11}
                      />
                    </div>
                    <div className={"py-[20px] border-b  border-main"}>
                      <Slider
                        startLabel={formatMessage({ id: "Confident" })}
                        stopLabel={formatMessage({ id: "Insecure" })}
                        fieldName="personality_type_of_this_ai_personality.confident_and_insecure"
                        formik={formik}
                        // min={1}
                        // max={21}
                        // valueInPoints={false}
                        // defaultValue={11}
                      />
                    </div>
                    <div className={"py-[20px] border-b  border-main"}>
                      <Slider
                        startLabel={formatMessage({ id: "Risk Taking" })}
                        stopLabel={formatMessage({ id: "Cautious" })}
                        fieldName="personality_type_of_this_ai_personality.risk_taking_and_cautious"
                        formik={formik}
                        // min={1}
                        // max={21}
                        // valueInPoints={false}
                        // defaultValue={11}
                      />
                    </div>
                    <div className={"py-[20px] border-b  border-[#2E2F45]"}>
                      <Slider
                        startLabel={formatMessage({ id: "Agreeableness" })}
                        stopLabel={formatMessage({ id: "Disagreeableness" })}
                        fieldName="personality_type_of_this_ai_personality.agreeableness_and_disagreeableness"
                        formik={formik}
                        // min={1}
                        // max={21}
                        // valueInPoints={false}
                        // defaultValue={11}
                      />
                    </div>
                    <div className={"py-[20px] border-[#2E2F45]"}>
                      <Slider
                        startLabel={formatMessage({ id: "Deliberate" })}
                        stopLabel={formatMessage({ id: "Impulsive" })}
                        fieldName="personality_type_of_this_ai_personality.deliberate_and_impulsive"
                        formik={formik}
                        // min={1}
                        // max={21}
                        // valueInPoints={false}
                        // defaultValue={11}
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div
                className={
                  "col-span-12 xl:col-span-5 flex flex-col justify-start gap-[20px]"
                }
              >
                <div
                  className={
                    "flex justify-end gap-[10px] md:order-1 md:mt-auto"
                  }
                >
                  {/* <button
                    type="button"
                    onClick={() => setOpenEdit(false)}
                    className={
                      "main-button-primary-light text-[14px] leading-5 font-medium py-[12px] w-1/2 md:w-[128px] rounded"
                    }
                  >
                    {formatMessage({ id: "Cancel" })}
                    
                  </button> */}
                  <button
                    type="submit"
                    className={`main-button text-[14px] leading-5 font-medium py-[12px] w-full sm:w-1/2  md:w-[140px] rounded ${
                      disableSave ? "opacity-75" : ""
                    }`}
                    disabled={disableSave}
                  >
                    {formatMessage({
                      id: `${disableSave ? "Please Wait" : "Save"}`,
                    })}
                  </button>
                </div>
                <InfoCard
                  title={formatMessage({
                    id: "Understanding Personality of AI Personality",
                  })}
                  desc={
                    <>
                      {formatMessage({
                        id: "In Kamoto.AI, the 'Personality' section empowers you to infuse your virtual AI persona with captivating character traits. You have the opportunity to shape its personality and make it truly unforgettable. Explore traits like humor, romance, wit, and more, breathing life into your AI personality's interactions. With basic trait controls for mood and personality type, such as sadness, anger, aggressiveness, or cautiousness, you can fine-tune the nuances of its behavior. ",
                      })}
                      <br></br>
                      <br></br>
                      {formatMessage({
                        id: "By customizing your AI personality's personality traits, you create a dynamic and engaging virtual persona that resonates with users. With Kamoto.AI, you have the tools to create AI personalities that captivate and leave a lasting impression.",
                      })}
                      <br></br>
                      <br></br>
                    </>
                  }
                  slug={""}
                  isAnchor={
                    "https://help.kamoto.ai/en/articles/7941819-understanding-the-personality-traits-tab-of-ai-personalities"
                  }
                />
              </div>
            </div>
          </Form>
        );
      }}
    </Formik>
  );
};

export default Personality;
