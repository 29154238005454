export const userStatus = (status: string) => {
  switch (status) {
    case "Verified":
      return "bg-[#00B7121A] text-[#00B712]";
      break;
    case "Unverified":
      return "bg-[#D24B4B1A] text-[#D24B4B]";
      break;
    case "New":
      return "bg-[#F59A101A] text-[#FF9900]";
      break;
    default:
      return "bg-[#00B7121A] text-[#00B712]";
  }
};
export const personalityStatus = (status: string) => {
  switch (status) {
    case "Licenced":
      return "bg-[#00B7121A] text-[#00B712]";
      break;
    case "Unlicenced":
      return "bg-[#D24B4B1A] text-[#D24B4B]";
      break;

    default:
      return "bg-[#00B7121A] text-[#00B712]";
  }
};
