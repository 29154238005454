import React, { useState } from "react";
import Select, { components } from "react-select";
import { get } from "lodash";
import { useIntl } from "react-intl";

const Index: React.FC<any> = ({ options=[], defaultValue }) => {
  const { formatMessage } = useIntl();
  const [selected, setSelected] = useState<any>([]);
  return (
    <Select
      isMulti
      name="chartParams"
      theme={(theme) => ({
        ...theme,
        colors: {
          ...theme.colors,
          primary25: "#C2D24B",
          primary: "#FFFFFF1A",
        },
      })}
      classNames={{
        control: () => {
          return "secondary-bg border-main";
        },
        option: () => {
          return "border-b border-main last:border-b-0";
        },
      }}
      hideSelectedOptions={false}
      components={{
        ClearIndicator: () => {
          return null;
        },
        MultiValue: ({ children, ...rest }) => {
          return (
            <p
              {...rest}
              className={
                "whitespace-nowrap secondary-text font-medium text-[12px] leading-[18x]"
              }
            >
              {children},{" "}
            </p>
          );
        },
        Option: ({ children, ...rest }) => {
          return (
            <components.Option {...rest} className={"secondary-bg"}>
              <div className={"flex items-center gap-x-2 event-filter"}>
                <input
                  type={"checkbox"}
                  className={"form-check-input h-6 w-6 "}
                  onChange={() => null}
                  checked={get(rest, "isSelected")}
                />
                <label
                  className={
                    "primary-text text-[12px] leading-[18px] gap-x-1 flex items-center z-50 "
                  }
                >
                  {children}
                </label>
              </div>
            </components.Option>
          );
        },
      }}
      defaultValue={defaultValue}
      options={options}
      className="!w-[240px] h-[40px] setting-multiselect"
      onChange={(chartParams) => {
        setSelected(chartParams);
      }}
      placeholder={formatMessage({
        id: "Choose Investor type",
      })}
      classNamePrefix="react-select"
      required={true}
    />
  );
};

export default Index;
