import { get, map } from "lodash";
import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Pagination } from "swiper";
import './styles/slider.scss'
import "rc-slider/assets/index.css";

const Slider: React.FC<any> = ({ data }) => {
  return (
    <Swiper
      modules={[Pagination, Autoplay]}
      initialSlide={0}
      className="w-full py-10 auth-swiper"
      slidesPerView={1}
      loop={true}
      autoplay={true}
      pagination={{
        clickable: true,
      }}
    >
      {map(data, (item) => (
        <SwiperSlide className={"mb-[20px] md:mb-[60px]"}>
          <div className={"max-w-3xl mx-auto text-center"}>
            <h1
              className={
                "text-[24px] md:text-[32px] leading-7  md:leading-10 font-semibold primary-text mb-3.5 md:mb-[24px]"
              }
            >
              {get(item, "heading")}
            </h1>
            <p
              className={
                "text-[14px] md:text-[16px] leading-5 md:leading-6 secondary-text h-[72px] mb-[32px] md:mb-[50px]"
              }
            >
              {get(item, "description")}
            </p>
            <img src={get(item, "image")} />
          </div>
        </SwiperSlide>
      ))}
    </Swiper>
  );
};

export default Slider;
