import React from 'react';
import {toAbsoluteUrl} from "../../../../_metronic/helpers";
import Footer from "./footer";

const SplashScreen = () => {
    return (
        <div className={'bg-[#21233A] h-full flex flex-col justify-center gap-y-10'}>
            <img src={toAbsoluteUrl('/recharge/loading.gif')}/>
            <Footer/>
        </div>
    );
};

export default SplashScreen;