import React, { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import CustomTable from "../../../../../components/table";
import { get, isEqual, join, map } from "lodash";
import LeaderboardListFilters from "./LeaderboardListFilters";
import verifiedIcon from "../../../../../assets/svgs/verified-icon.svg";
import p1 from "../../../../../assets/images/marketplace/p1.png";
import p2 from "../../../../../assets/images/marketplace/p2.png";
import p3 from "../../../../../assets/images/marketplace/p3.png";
import p4 from "../../../../../assets/images/marketplace/p4.png";
import p5 from "../../../../../assets/images/marketplace/p5.png";
import p6 from "../../../../../assets/images/marketplace/p6.png";
import p7 from "../../../../../assets/images/marketplace/p7.png";
import p8 from "../../../../../assets/images/marketplace/p8.png";
import request from "../../../../../core/_apis";
import { ComponentSpinner } from "../../../../widgets/components/General/Spinner";
import { getCdnUrl, kmbConvertor } from "../../../../../core/_util";
import {Link, useSearchParams } from "react-router-dom";
const Index = () => {
  const { formatMessage } = useIntl();
  const [sp,setSp] = useSearchParams()
  const [loading, setLoading] = useState(true);
  const [resourceType, setResourceType] = useState(sp.get('tab') || 'trending');
  const [category, setCategory] = useState(sp.get('category') || undefined);
  const [country_id, setCountryId] = useState(sp.get('country') || undefined);
  const [time_frame, setTimeFrame] = useState(sp.get('period') || '30d');
  const [personalities, setPersonalities] = useState([]);


  const fetchLatest = async () => {
    setLoading(true)
    try {
      let response = await request.get('/trending_personalities', {
        params: {
          resourceType,
          time_frame,
          category,
          country_id
        }
      })
      if (response.status === 200) {
        let data = response.data.data || []
        let rank = 0
        data = data.map((el: any) => {
          return {
            rank: ++rank,
            personality: {
              name: el.personality.name,
              image: el.personality.image,
              isVerified: el.personality.isVerified,
              username: el.personality.username
            },
            owner: {
              name: `${el.personality.users?.[0]?.firstName || ''} ${el.personality.users?.[0]?.lastName || ''}`,
              username: el.personality.users?.[0]?.username
            },
            interactions: {
              number: kmbConvertor(el.interactions),
            },
            interactions_diff: {
              number: kmbConvertor(el.interactions_diff),
            },
            likes: {
              number: kmbConvertor(el.likes),
            },
            followers: {
              number: kmbConvertor(el.followers),
            },
          };
        })
        setPersonalities(data)
      }
      setLoading(false)
    } catch (e) {
      setLoading(false)
    }
  }

  // useEffect(() => {
  //   fetchLatest()
  // },[])

  useEffect(() => {
    fetchLatest()
    let params:any = {tab: resourceType,period: time_frame, category:category, country: country_id}
    params = Object.fromEntries(
      Object.entries(params).filter(([key, value]) => value !== undefined)
    );
    setSp(params)
  }, [resourceType, time_frame, category, country_id])

  const headers = [
    {
      label: formatMessage({ id: "Rank" }),
      key: "rank",
      enableSorting: true,
      sortingFn: (
          rowA: { getValue: (arg0: any) => {
              number: any; (): any; new(): any; count: any;
            }; },
          rowB: { getValue: (arg0: any) => {
              number: any; (): any; new(): any; count: any;
            }; },
          columnId: any
      ) => {
        const numA = rowA.getValue(columnId);
        const numB= rowB.getValue(columnId);
        return numA < numB ? 1 : numA > numB ? -1 : 0;
      }
    },
    {
      label: formatMessage({ id: "Personality" }),
      key: "personality",
      cell: true,
      enableSorting: true,
      sortingFn: (
          rowA: { getValue: (arg0: any) => {
              name: any; (): any; new(): any; count: any;
            }; },
          rowB: { getValue: (arg0: any) => {
              name: any; (): any; new(): any; count: any;
            }; },
          columnId: any
      ) => {
        const numA = rowA.getValue(columnId)?.name;
        const numB= rowB.getValue(columnId)?.name;
        return numA < numB ? 1 : numA > numB ? -1 : 0;
      }
    },
    {
      label: formatMessage({ id: "Owner" }),
      key: "owner",
      cell: true,
      enableSorting: true,
      sortingFn: (
          rowA: { getValue: (arg0: any) => {
              name: any; (): any; new(): any; count: any;
            }; },
          rowB: { getValue: (arg0: any) => {
              name: any; (): any; new(): any; count: any;
            }; },
          columnId: any
      ) => {
        const numA = rowA.getValue(columnId)?.name;
        const numB= rowB.getValue(columnId)?.name;
        return numA < numB ? 1 : numA > numB ? -1 : 0;
      }
    },
    
    {
      label:  formatMessage({ id: "Recent Interactions" }),
      key: "interactions_diff",
      cell: true,
      enableSorting: true,
      sortingFn: (
          rowA: { getValue: (arg0: any) => {
              number: any; (): any; new(): any; count: any;
            }; },
          rowB: { getValue: (arg0: any) => {
              number: any; (): any; new(): any; count: any;
            }; },
          columnId: any
      ) => {
        const numA = rowA.getValue(columnId)?.number;
        const numB= rowB.getValue(columnId)?.number;
        return numA < numB ? 1 : numA > numB ? -1 : 0;
      }
    },
    {
      label: formatMessage({ id: "All Time Interactions" }),
      key: "interactions",
      cell: true,
      enableSorting: true,
      sortingFn: (
          rowA: { getValue: (arg0: any) => {
              number: any; (): any; new(): any; count: any;
            }; },
          rowB: { getValue: (arg0: any) => {
              number: any; (): any; new(): any; count: any;
            }; },
          columnId: any
      ) => {
        const numA = rowA.getValue(columnId)?.number;
        const numB= rowB.getValue(columnId)?.number;
        return numA < numB ? 1 : numA > numB ? -1 : 0;
      }
    },
    {
      label: formatMessage({ id: "Likes" }),
      key: "likes",
      cell: true,
      enableSorting: true,
      sortingFn: (
          rowA: { getValue: (arg0: any) => {
              number: any; (): any; new(): any; count: any;
            }; },
          rowB: { getValue: (arg0: any) => {
              number: any; (): any; new(): any; count: any;
            }; },
          columnId: any
      ) => {
        const numA = rowA.getValue(columnId)?.number;
        const numB= rowB.getValue(columnId)?.number;
        return numA < numB ? 1 : numA > numB ? -1 : 0;
      }
    },
    {
      label: formatMessage({ id: "Followers" }),
      key: "followers",
      cell: true,
      enableSorting: true,
      sortingFn: (
          rowA: { getValue: (arg0: any) => {
              number: any; (): any; new(): any; count: any;
            }; },
          rowB: { getValue: (arg0: any) => {
              number: any; (): any; new(): any; count: any;
            }; },
          columnId: any
      ) => {
        const numA = rowA.getValue(columnId)?.number;
        const numB= rowB.getValue(columnId)?.number;
        return numA < numB ? 1 : numA > numB ? -1 : 0;
      }
    },
  ];

  const columns = React.useMemo<any>(
    () =>
      map(headers, (item: any) => {
        return {
          header: get(item, "label"),
          accessorKey: get(item, "key"),
          id: get(item, "key"),
          sortingFn:get(item, "sortingFn",()=>{}),
          enableSorting: get(item, "enableSorting"),
          cell: (info: any) => {
            if (item.cell) {
              if (isEqual(get(item, "key"), "personality")) {
                return (
                  <Link to={`/p/${info.getValue().username}`} className={"flex items-center min-w-max"} target={"_blank"}>
                    <img
                      src={getCdnUrl(info.getValue().image,"width=200,fit=cover,gravity=auto")}
                      alt=""
                      className={"rounded-[4px] w-[54px] h-[54px] mr-[14px]"}
                    />
                    <p className="primary-text text-[14px] leading-[20px] font-[600] mr-[4px]">
                      {info.getValue().name}
                    </p>
                    {info.getValue().isVerified ? (
                      <img src={verifiedIcon} alt="" />
                    ) : null}
                  </Link>
                );
              }
              if (isEqual(get(item, "key"), "owner")) {
                return (
                  <Link to={`/u/${info.getValue().username}`} className={"flex items-center"} target={"_blank"}>
                    <p className="primary-text text-[14px] leading-[20px] font-[600]">
                      {info.getValue().name}
                    </p>
                  </Link>
                );
              }
              if (isEqual(get(item, "key"), "interactions")) {
                return (
                  <div className={"flex items-center gap-[8px]"}>
                    <svg
                      width="20"
                      height="20"
                      viewBox="0 0 20 20"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g clipPath="url(#clip0_7513_26735)">
                        <path
                          d="M11.6667 12.5V14.1667C11.6667 14.3877 11.5789 14.5996 11.4226 14.7559C11.2663 14.9122 11.0543 15 10.8333 15H5L2.5 17.5V9.16667C2.5 8.94565 2.5878 8.73369 2.74408 8.57741C2.90036 8.42113 3.11232 8.33333 3.33333 8.33333H5M17.5 11.6667L15 9.16667H9.16667C8.94565 9.16667 8.73369 9.07887 8.57741 8.92259C8.42113 8.76631 8.33333 8.55435 8.33333 8.33333V3.33333C8.33333 3.11232 8.42113 2.90036 8.57741 2.74408C8.73369 2.5878 8.94565 2.5 9.16667 2.5H16.6667C16.8877 2.5 17.0996 2.5878 17.2559 2.74408C17.4122 2.90036 17.5 3.11232 17.5 3.33333V11.6667Z"
                          stroke="white"
                          strokeOpacity="0.8"
                          strokeWidth="1.2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </g>
                      <defs>
                        <clipPath id="clip0_7513_26735">
                          <rect width="20" height="20" fill="white" />
                        </clipPath>
                      </defs>
                    </svg>

                    <p className="primary-text text-[14px] leading-[20px] font-[600]">
                      {info.getValue().number}
                    </p>
                  </div>
                );
              }
              if (isEqual(get(item, "key"), "interactions_diff")) {
                return (
                  <div className={"flex items-center gap-[8px]"}>
                    <svg
                      width="20"
                      height="20"
                      viewBox="0 0 20 20"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g clipPath="url(#clip0_7513_26735)">
                        <path
                          d="M11.6667 12.5V14.1667C11.6667 14.3877 11.5789 14.5996 11.4226 14.7559C11.2663 14.9122 11.0543 15 10.8333 15H5L2.5 17.5V9.16667C2.5 8.94565 2.5878 8.73369 2.74408 8.57741C2.90036 8.42113 3.11232 8.33333 3.33333 8.33333H5M17.5 11.6667L15 9.16667H9.16667C8.94565 9.16667 8.73369 9.07887 8.57741 8.92259C8.42113 8.76631 8.33333 8.55435 8.33333 8.33333V3.33333C8.33333 3.11232 8.42113 2.90036 8.57741 2.74408C8.73369 2.5878 8.94565 2.5 9.16667 2.5H16.6667C16.8877 2.5 17.0996 2.5878 17.2559 2.74408C17.4122 2.90036 17.5 3.11232 17.5 3.33333V11.6667Z"
                          stroke="white"
                          strokeOpacity="0.8"
                          strokeWidth="1.2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </g>
                      <defs>
                        <clipPath id="clip0_7513_26735">
                          <rect width="20" height="20" fill="white" />
                        </clipPath>
                      </defs>
                    </svg>

                    <p className="primary-text text-[14px] leading-[20px] font-[600]">
                      {info.getValue()?.number}
                    </p>
                  </div>
                );
              }
              if (isEqual(get(item, "key"), "likes")) {
                return (
                  <div className={"flex items-center gap-[8px]"}>
                    <svg
                      width="20"
                      height="20"
                      viewBox="0 0 20 20"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g clipPath="url(#clip0_7525_33807)">
                        <path
                          d="M16.2501 10.4732L10.0001 16.6632L3.75009 10.4732C3.33784 10.072 3.01312 9.58983 2.79638 9.05701C2.57963 8.5242 2.47556 7.95228 2.4907 7.37726C2.50585 6.80225 2.63989 6.2366 2.88439 5.71593C3.12888 5.19527 3.47853 4.73086 3.91133 4.35197C4.34412 3.97307 4.85068 3.68789 5.3991 3.51439C5.94752 3.34088 6.52593 3.28281 7.09789 3.34382C7.66986 3.40483 8.223 3.58362 8.72248 3.86891C9.22196 4.1542 9.65696 4.53982 10.0001 5.00148C10.3447 4.54317 10.7802 4.16092 11.2793 3.87866C11.7785 3.59639 12.3305 3.42019 12.9009 3.36109C13.4712 3.30198 14.0477 3.36123 14.5941 3.53515C15.1405 3.70906 15.6451 3.99388 16.0764 4.37179C16.5077 4.7497 16.8563 5.21256 17.1004 5.7314C17.3446 6.25024 17.479 6.81389 17.4953 7.38708C17.5117 7.96026 17.4095 8.53065 17.1952 9.06253C16.9809 9.59441 16.6592 10.0763 16.2501 10.4782"
                          stroke="white"
                          strokeOpacity="0.8"
                          strokeWidth="1.2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </g>
                      <defs>
                        <clipPath id="clip0_7525_33807">
                          <rect width="20" height="20" fill="white" />
                        </clipPath>
                      </defs>
                    </svg>

                    <p className="primary-text text-[14px] leading-[20px] font-[600]">
                      {info.getValue().number}
                    </p>
                  </div>
                );
              }
              if (isEqual(get(item, "key"), "followers")) {
                return (
                  <div className={"flex items-center gap-[8px]"}>
                    <svg
                      width="20"
                      height="20"
                      viewBox="0 0 20 20"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g clipPath="url(#clip0_7525_33847)">
                        <path
                          d="M2.5 17.5V15.8333C2.5 14.9493 2.85119 14.1014 3.47631 13.4763C4.10143 12.8512 4.94928 12.5 5.83333 12.5H9.16667C10.0507 12.5 10.8986 12.8512 11.5237 13.4763C12.1488 14.1014 12.5 14.9493 12.5 15.8333V17.5M13.3333 2.60833C14.0503 2.79192 14.6859 3.20892 15.1397 3.79359C15.5935 4.37827 15.8399 5.09736 15.8399 5.8375C15.8399 6.57764 15.5935 7.29673 15.1397 7.88141C14.6859 8.46608 14.0503 8.88308 13.3333 9.06667M17.5 17.5V15.8333C17.4958 15.0976 17.2483 14.384 16.7961 13.8037C16.3439 13.2233 15.7124 12.8089 15 12.625M4.16667 5.83333C4.16667 6.71739 4.51786 7.56523 5.14298 8.19036C5.7681 8.81548 6.61594 9.16667 7.5 9.16667C8.38405 9.16667 9.2319 8.81548 9.85702 8.19036C10.4821 7.56523 10.8333 6.71739 10.8333 5.83333C10.8333 4.94928 10.4821 4.10143 9.85702 3.47631C9.2319 2.85119 8.38405 2.5 7.5 2.5C6.61594 2.5 5.7681 2.85119 5.14298 3.47631C4.51786 4.10143 4.16667 4.94928 4.16667 5.83333Z"
                          stroke="white"
                          strokeWidth="1.2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </g>
                      <defs>
                        <clipPath id="clip0_7525_33847">
                          <rect width="20" height="20" fill="white" />
                        </clipPath>
                      </defs>
                    </svg>

                    <p className="primary-text text-[14px] leading-[20px] font-[600]">
                      {info.getValue().number}
                    </p>
                  </div>
                );
              }
            } else {
              return info.getValue();
            }
          },
          footer: (props: any) => {
           return  props.column.id
          }
        };
      }),

    []
  );

  // if(loading){
  //   return <ComponentSpinner/>
  // }

  return (
    <div className="mt-[32px]">
      <LeaderboardListFilters ViewAllList={true}
        setResourceType={setResourceType}
        setCategory={setCategory}
        setCountryId={setCountryId}
        setTimeFrame={setTimeFrame}
        resourceType={resourceType}
        category={category}
        country_id={country_id}
        time_frame={time_frame}
      />
      {
        loading ? <ComponentSpinner/> :
        <div className="leadership-table tab-container border main-border rounded-[8px] px-[20px] pb-[26px] mt-[16px]">
          <CustomTable
            tableData={personalities}
            pageCount={10}
            //   loading={loading}
            TableSearch="false"
            TablePagination="false"
            headers={headers}
            columns={columns}
          //   fetchData={getHistory}
          />
        </div>
      }
    </div>
  );
};

export default Index;
