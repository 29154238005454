import React, { useLayoutEffect } from "react";
import * as am5 from "@amcharts/amcharts5";
import * as am5map from "@amcharts/amcharts5/map";

import am5geodata_worldLow from "@amcharts/amcharts5-geodata/worldLow";

function interpolateColor(factor:number) {
  let color1 = am5.color(0xe9efbc)
  let color2 = am5.color(0xa5b531)
  const r = Math.round(color1.r + factor * (color2.r - color1.r));
  const g = Math.round(color1.g + factor * (color2.g - color1.g));
  const b = Math.round(color1.b + factor * (color2.b - color1.b));
  return am5.color(`rgb(${r},${g},${b})`);
}

const Index = ({data,...props}:{data:any}) => {
  useLayoutEffect(() => {
    const root = am5.Root.new("chartdiv");

    const chart = root.container.children.push(
      am5map.MapChart.new(root, {
        panX: "none",
        panY: "none",
        pinchZoom: false,
        maxZoomLevel: 1,
        projection: am5map.geoNaturalEarth1(),
      })
    );

    const polygonSeries = chart.series.push(
      am5map.MapPolygonSeries.new(root, {
        geoJSON: am5geodata_worldLow,
        exclude: ["AQ"],
      })
    );

    polygonSeries.mapPolygons.template.setAll({
      tooltipText: "{name}",
      templateField: "polygonSettings",
    });

    polygonSeries.set("fill", am5.color(0x21233a));

    polygonSeries.mapPolygons.template.states.create("hover", {
      fill: am5.color(0x677935),
    });
    let countries:any;
    if(data?.length > 0){
      countries = data.map((countryData:any) => {
        let factor = (countryData.visit_count - 0) / (Math.max(...(data.map((d:any) => d.visit_count))) - 0)
        return {
          id: countryData.country,
          polygonSettings: {
            fill: interpolateColor(factor),
          }
        }
      });
    }else{
      countries = [
        {
          id: "US",
          polygonSettings: {
            fill: am5.color(0xa5b531),
          },
        },
        {
          id: "CA",
          polygonSettings: {
            fill: am5.color(0xc2d24b),
          },
        },
        {
          id: "MX",
          polygonSettings: {
            fill: am5.color(0xc2d24b),
          },
        },
        {
          id: "RU",
          polygonSettings: {
            fill: am5.color(0xd5e26e),
          },
        },
        {
          id: "CN",
          polygonSettings: {
            fill: am5.color(0xe9efbc),
          },
        },
        {
          id: "IN",
          polygonSettings: {
            fill: am5.color(0xc2d24b),
          },
        },
        {
          id: "SA",
          polygonSettings: {
            fill: am5.color(0xc2d24b),
          },
        },
        {
          id: "BR",
          polygonSettings: {
            fill: am5.color(0xe9efbc),
          },
        },
        {
          id: "AR",
          polygonSettings: {
            fill: am5.color(0xe9efbc),
          },
        },
        {
          id: "AU",
          polygonSettings: {
            fill: am5.color(0xc2d24b),
          },
        },
      ]
    }
    console.log(countries)
    polygonSeries.data.setAll(countries);
    // chart.appear(1000, 100);
  }, []);

  return (
    <div id="chartdiv" className={"h-[260px] relative"}>
      <div
        className={"w-20 z-10 h-10 absolute  bottom-0 left-0"}
      ></div>
    </div>
  );
};

export default Index;
