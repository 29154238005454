import React, { useEffect, useState } from "react";
import SocialMediaContent from "../containers/SocialMediaContent";
import SocialMediaSetting from "../containers/SocialMediaSetting";
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
import { useIntl } from "react-intl";
import { useSearchParams } from "react-router-dom";
import { linkSocialAccount } from "../core/_request";
import { useAuth } from "../../auth";
import { toast } from "react-toastify";
import MyCalendar from "../containers/MyCalendar";

const panels = ["content", "schedule", "settings"];
type AccountTabProps = {
  selectedTab: string;
  accountDetails: any;
  setAccounts: React.Dispatch<React.SetStateAction<any>>;
};
export default function AccountTabs(props: AccountTabProps) {
  const { selectedTab, accountDetails, setAccounts } = props;
  const { formatMessage } = useIntl();
  const [searchParams, setSearchParams] = useSearchParams();
  const { selectedPersonality } = useAuth();
  const [activePanel, setActivePanel] = useState<number>(
    panels.indexOf(searchParams.get("panel") || "content")
  );
  useEffect(() => {
    let panel = searchParams.get("panel");
    if (panel) {
      setActivePanel(panels.indexOf(panel));
    }
  }, [searchParams.get("panel")]);

  useEffect(() => {
    searchParams.set("panel", panels[activePanel]);
    setSearchParams(searchParams, { replace: true });
  }, [activePanel]);

  useEffect(() => {
    selectedTab == "all" && activePanel==2 && setActivePanel(0);
  }, [selectedTab]);

  async function link(personalityId: string, type: string, code: string) {
    toast.info(`Please wait, your ${type} account is being linked...`);
    const {
      data: { data, success, errors },
    } = await linkSocialAccount(personalityId, type, code);
    if (success) {
      toast.success("Account Linked Successfully");
      setAccounts((acc: any) => {
        return { ...acc, [type]: data };
      });
    } else {
      toast.error(errors[0], {
        autoClose: false,
        closeButton: true,
      });
    }
  }
  useEffect(() => {
    if (!selectedPersonality) {
      return;
    }
    const code = searchParams.get("code");
    if (["twitter", "facebook","kamoto"].includes(selectedTab)) {
      if (code) {
        link(selectedPersonality.personalityId, selectedTab, code);
      }
    }
    searchParams.delete("code");
    searchParams.delete("state");
    setSearchParams(searchParams);
  }, [searchParams, selectedPersonality]);

  return (
    <Tabs
      defaultIndex={activePanel}
      selectedIndex={activePanel}
      onSelect={(index) => {
        setActivePanel(index);
      }}
      className="font-size-13"
      selectedTabClassName="bg-primary"
    >
      <TabList
        className={
          " text-[13px] leading-[20px] font-normal primary-text social-media-tabs overflow-x-auto overflow-y-hidden whitespace-nowrap  "
        }
      >
        <Tab>
          {" "}
          <div className="p-[20px_12px] rounded-[8px] max-w-[192px] w-full">
            <p className="text-[13px] leading-[18px] font-[500] ">
              {formatMessage({
                id: "Content",
              })}
            </p>
            <span className="text-[12px] mt-[6px] leading-[18px] font-[400] text-[#FFFFFFA6]">
              {formatMessage({
                id: "Edit social media content",
              })}
            </span>
          </div>
        </Tab>
        <Tab>
          {" "}
          <div className="p-[20px_12px] rounded-[8px]  max-w-[192px] w-full">
            <p className="text-[13px] leading-[18px] font-[500] ">
              {formatMessage({
                id: "Schedule",
              })}
            </p>
            <span className="text-[12px] mt-[6px] leading-[18px] font-[400] text-[#FFFFFFA6]">
              {formatMessage({
                id: "Preplan automated posts",
              })}
            </span>
          </div>
        </Tab>
        {selectedTab !== "all" && (
          <Tab>
            <div className="p-[20px_12px] rounded-[8px]  max-w-[192px] w-full">
              <p className="text-[13px] leading-[18px] font-[500]">
                {formatMessage({
                  id: "Settings",
                })}
              </p>
              <span className="text-[12px] mt-[6px] leading-[18px] font-[400] text-[#FFFFFFA6]">
                {formatMessage({
                  id: "Content settings of posts",
                })}
              </span>
            </div>
          </Tab>
        )}
      </TabList>
      <TabPanel>
        <SocialMediaContent selectedSocialMedia={selectedTab=='all' ? undefined: selectedTab} timezone={accountDetails?.postSettings?.timezone}/>
      </TabPanel>
      <TabPanel>
        <MyCalendar selectedSocialMedia={selectedTab=='all' ? null: selectedTab} timezone={accountDetails?.postSettings?.timezone} />
      </TabPanel>
      {selectedTab !== "all" && (
        <TabPanel>
          <SocialMediaSetting
            selectedSocialMedia={selectedTab}
            accountDetails={accountDetails}
            setAccounts={setAccounts}
          />
        </TabPanel>
      )}
    </Tabs>
  );
}
