import React, { useEffect, useMemo, useState } from "react";
import { Calendar, ToolbarProps, momentLocalizer } from "react-big-calendar";
import "react-big-calendar/lib/css/react-big-calendar.css";
import moment from "moment";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";

import { Appointment } from "./CustomCalendar.types";
import EventModal from "../components/modals/EventModal";
import AppointmentEvent from "./AppointmentEvent";
import { createSocialPost, deleteSocialPost, getAllSocialPostsForSchedule } from "../core/_request";
import { useAuth } from "../../auth";
import { toast } from "react-toastify";
// @ts-ignore
import Toolbar from "react-big-calendar/lib/Toolbar";
import MomentTimezone from 'moment-timezone';
import SocialMediaEditPost from "../components/modals/SocialMediaEditPost";
import { useIntl } from "react-intl";
import { BasicButton } from "../../widgets/components/UI/BasicButton";
import { CustomButton } from "../../widgets/components/UI/CustomButton";
import { Formik } from "formik";
interface Event {
  text: string;
  start: Date;
  end: Date;
  socialAccountType: string;
}

interface SlotInfo {
  start: Date;
  end: Date;
}

interface Props {
  selectedSocialMedia: string | null;
  timezone: string;
}
const MyCalendar = ({ selectedSocialMedia, timezone }: Props) => {
  const [modalShow, setModalShow] = React.useState(false);
  const [selectedPost, setSelectedPost] = useState<any>(null)
  const [events, setEvents] = useState<any[]>([
    // {
    //   text: "Event 1",
    //   start: new Date(2023, 9, 11, 8),
    //   end: new Date(2023, 9, 11, 8),
    //   socialAccountType: "twitter"
    // },
    // {
    //   text: "Event 2",
    //   start: new Date(2023, 9, 11, 8),
    //   end: new Date(2023, 9, 11, 8),
    //   socialAccountType: "twitter"
    // },
    // {
    //   text: "Event 3",
    //   start: new Date(2023, 9, 11, 8),
    //   end: new Date(2023, 9, 11, 8),
    //   socialAccountType: "twitter"
    // },
    // Add more events as needed
  ]);
  timezone = timezone ? timezone : "UTC"
  const { formatMessage } = useIntl();
  const localizer = momentLocalizer(moment.tz.setDefault(timezone));
  const [showModal, setShowModal] = useState(false);
  const [selectedSlot, setSelectedSlot] = useState<SlotInfo | null>(null);
  const { selectedPersonality } = useAuth()
  const [start, setStart] = useState<string>()
  const [end, setEnd] = useState<string>()
  const [deleteModalOpen, setDeleteModalOpen] = useState<boolean>(false)
  const handleSelectSlot = (slotInfo: SlotInfo) => {
    const currentTime = moment().tz(timezone);
    const slotTime = moment(slotInfo.start).tz(timezone);
    if (selectedSocialMedia && !slotTime.isBefore(currentTime)) {
      setSelectedSlot(slotInfo);
      setShowModal(true);
    }
  };

  const handleModalClose = () => {
    setShowModal(false);
  };

  const THIRTY_MINUTES_IN_MS = 30 * 60 * 1000
  const handleModalSubmit = async ({ post }: any, { setSubmitting }: any) => {
    setSubmitting(true)
    if (post && selectedSlot && selectedSocialMedia) {
      const { data: { data, success, errors } } = await createSocialPost(selectedPersonality.personalityId, {
        startAt: new Date(selectedSlot.start).toISOString(),
        endAt: new Date(new Date(selectedSlot.start).valueOf() + THIRTY_MINUTES_IN_MS).toISOString(),
        socialNetwork: selectedSocialMedia,
        text: post,
      })
      if (success) {
        const newEvent: Event = {
          text: post,
          start: selectedSlot.start,
          end: selectedSlot.end,
          socialAccountType: selectedSocialMedia
        };
        toast.success("Post added successfully")
        setEvents([...events, newEvent]);
        setShowModal(false);
      } else {
        toast.error(errors[0],
          {
            autoClose: false,
            closeButton: true, // Display close button (cross icon)
          });
      }
    }
    setSubmitting(false)
  };

  async function getPosts(start: string, end: string) {
    const { data: { success, data } } = await getAllSocialPostsForSchedule(selectedPersonality.personalityId, {
      start,
      end,
      network: selectedSocialMedia,
      purpose: 'schedule'
    })
    if (success) {
      const posts = data.map((item) => { return { ...item, start: new Date(item.startTime), end: new Date(item.endTime) } })
      setEvents(posts)
    }
  }

  
  function onUpdateSuccess(updatedPost:any){
    setEvents((posts)=>{
      const updatedPosts = posts.map((item)=>{
        if(item.postId==updatedPost.postId){
          return updatedPost
        }else{
          return item
        }
      })
      return updatedPosts
    })
  }

  useEffect(() => {
    if (start && end) {
      getPosts(start, end)
    }
  }, [start, end, selectedSocialMedia])

  function handleRangeChange(days: any) {
    const start = moment(days[0]).toISOString()
    const end = moment(days[6]).toISOString()
    setStart(start)
    setEnd(end)
  }

  const CustomToolbar = (props: any) => {
    useEffect(() => {
      props.onView(props.view);
    }, []);
    return <Toolbar {...props} />;
  }
  const components = {
    event: ({ event }: { event: Event }) => {
      const data = event;
      if (data.text) {
        return (
          <AppointmentEvent post={data} />
        );
      }

      return null;
    },
    toolbar: CustomToolbar
  };

  return (
    <div className="calendar-wrapper ">
      <Calendar
        components={components}
        defaultView="week"
        localizer={localizer}
        events={events}
        startAccessor="start"
        endAccessor="end"
        style={{ height: 500 }}
        selectable={true}
        onSelectSlot={handleSelectSlot}
        onRangeChange={handleRangeChange}
        onSelectEvent={(event)=>{
          setSelectedPost(event)
          setModalShow(true)
        }}
      />

      <EventModal
        selectedSocialMedia={selectedSocialMedia}
        showModal={showModal}
        handleModalClose={handleModalClose}
        handleModalSubmit={handleModalSubmit}
      />
      <SocialMediaEditPost
        show={modalShow}
        onHide={() => setModalShow(false)}
        selectedPost={selectedPost}
        onUpdateSuccess={onUpdateSuccess}
      />
      <Modal
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={deleteModalOpen}
        onHide={() => setDeleteModalOpen(false)}
      >
        <Modal.Header closeButton>
          <Modal.Title
            className="modal-title text-[20px] leading-[28px] font-weight-600 text-[#FFFFFFCC] "
            id="contained-modal-title-vcenter"
          >
            {formatMessage({ id: "Delete Confirmation" })}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className=" p-[16px] md:p-[24px]">
          <p className="text-[#FFFFFFCC] text-[16px]">
            {formatMessage({ id: "Are you sure to delete this post?" })}
          </p>
          <Formik
            initialValues={{}}
            onSubmit={async (values, { setSubmitting }) => {
              setSubmitting(true);
              const {
                data: { success, data },
              } = await deleteSocialPost(selectedPost.postId);
              if (success) {
                setEvents((posts) =>
                  posts.filter(
                    ({ postId }) =>
                      postId !== selectedPost.postId
                  )
                );
                setDeleteModalOpen(false);
                setSelectedPost(null);
                toast.success("Post deleted successfully");
              } else {
                toast.error("Error while deleting the post",
                  {
                    autoClose: false,
                    closeButton: true, // Display close button (cross icon)
                  });
              }
              setSubmitting(false);
            }}
          >
            {(formik) => (
              <div className="flex gap-x-3 justify-end items-center">
                <BasicButton
                  buttonText={formatMessage({ id: "Cancel" })}
                  border="none"
                  color="#C2D24B1A"
                  textColor="#C2D24B"
                  customClass={"px-[24px] mt-8"}
                  height="44px"
                  onClick={() => {
                    setDeleteModalOpen(false);
                  }}
                />
                <CustomButton
                  customClass="mt-8"
                  isSubmitting={formik.isSubmitting}
                  isValid={true}
                  buttonText={formatMessage({ id: "Delete" })}
                  loading={formik.isSubmitting}
                  height={44}
                  widthLoading={1}
                  width={1}
                  onSubmit={formik.handleSubmit}
                />
              </div>
            )}
          </Formik>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default MyCalendar;
