import React from "react";
import useGTM from "../../../../hooks/useGTM";
import Container from "../../containers/ai-user-profile";
import { GTMEvent } from "../../../../hooks/gtm_helpers";

const Index = () => {
  useGTM(false,[{event:GTMEvent.MarketplaceUserPageView,meta:{page_url : window.location.href}}])
  return (
    <div>
      <Container />
    </div>
  );
};

export default Index;
