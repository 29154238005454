import axios from "axios"
const API_URL = process.env.REACT_APP_BASE_API_URL;

export const INTERACTIONS = `${API_URL}/interactions`
export const SOCIAL_ACCOUNTS = `${API_URL}/social-accounts`
export const SOCIAL_POSTS = `${API_URL}/social-posts`
export type ResponseType<T> = {
  success: boolean;
  data: T;
  errors: string[];
};

export const getInteractions = async (personalityId:string, params: { [key: string]: any }) => {
  return await axios.get<ResponseType<{ count: number, rows: any[] }>>(INTERACTIONS, {
    params,
    headers:{
      'x-personality-id':personalityId
    },
    validateStatus(status) {
      return true;
    },
  })
}

export const getInteractionChats = async (personalityId:string, userId:string, params: { [key: string]: any }) => {
  return await axios.get<ResponseType<any[]>>(`${INTERACTIONS}/chats/${userId}`, {
    params,
    headers:{
      'x-personality-id':personalityId
    },
    validateStatus(status) {
      return true;
    },
  })
}

export const linkSocialAccount = async (personalityId:string, accountType:string, code?:string ) => {
  return await axios.post<ResponseType<any>>(SOCIAL_ACCOUNTS, {accountType, code},{
    headers:{
      'x-personality-id':personalityId
    },
    validateStatus(status) {
      return true;
    },
  })
}

export const getLinkedSocialAccounts = async (personalityId:string) => {
  return await axios.get<ResponseType<any[]>>(SOCIAL_ACCOUNTS, {
    headers:{
      'x-personality-id':personalityId
    },
    validateStatus(status) {
      return true;
    },
  })
}

export const unlinkSocialAccount = async (personalityId:string, type:string) => {
  return await axios.delete<ResponseType<any[]>>(SOCIAL_ACCOUNTS, {
    params:{
      type
    },
    headers:{
      'x-personality-id':personalityId
    },
    validateStatus(status) {
      return true;
    },
  })
}

export const updateSocialAccount = async (personalityId:string, accountType:string, postSettings:any ) => {
  return await axios.put<ResponseType<any>>(SOCIAL_ACCOUNTS, {accountType, postSettings},{
    headers:{
      'x-personality-id':personalityId
    },
    validateStatus(status) {
      return true;
    },
  })
}


type SocialPostCreate = {
  text: string;
  socialNetwork: string;
  startAt: string;
  endAt: string;
}
export const createSocialPost = async (personalityId:string, data:SocialPostCreate) => {
  return await axios.post<ResponseType<any>>(SOCIAL_POSTS, data,{
    headers:{
      'x-personality-id':personalityId
    },
    validateStatus(status) {
      return true;
    },
  })
}

export const getAllSocialPostsForSchedule = async (personalityId:string, params:Record<string,any>) => {
  return await axios.get<ResponseType<any[]>>(SOCIAL_POSTS, {
    headers:{
      'x-personality-id':personalityId
    },
    params,
    validateStatus(status) {
      return true;
    },
  })
}

export const getAllSocialPostsForContent = async (personalityId:string, params:Record<string,any>) => {
  return await axios.get<ResponseType<any[]>>(SOCIAL_POSTS, {
    headers:{
      'x-personality-id':personalityId
    },
    params,
    validateStatus(status) {
      return true;
    },
  })
}

export const getAIPostContent = async (personalityId:string, type:string) => {
  return await axios.get<ResponseType<any>>(`${SOCIAL_POSTS}/content`, {
    headers:{
      'x-personality-id':personalityId
    },
    params:{type},
    validateStatus(status) {
      return true;
    },
  })
}


export const deleteSocialPost = async (postId:string) => {
  return await axios.delete<ResponseType<any>>(`${SOCIAL_POSTS}/${postId}`, {
    validateStatus(status) {
      return true;
    },
  })
}

export const updateSocialPost = async (postId:string, text:string ) => {
  return await axios.put<ResponseType<any>>(`${SOCIAL_POSTS}/${postId}`, {text},{
    validateStatus(status) {
      return true;
    },
  })
}


