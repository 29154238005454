import React, { useEffect, useRef } from 'react'
import { GTMEvent } from './gtm_helpers';

declare global {
    interface Window {
        dataLayer: any[];
        personalityDataLayer: any[];
    }
}

type StringKeyObject = {
    [key: string]: any;
};

type gtmEventMetadata = {
    event: GTMEvent,
    meta?: StringKeyObject
};

const useGTM = (trackViewEvent?: boolean | undefined,events?: gtmEventMetadata[] | []) => {
    // ensure only one page load event
    const pageLoaded = useRef(false)
    const pathname = window.location.pathname

    useEffect(() => {
        if (!pageLoaded?.current) {
            pageLoaded.current = true
            if (trackViewEvent) {
                dataLayerPush(GTMEvent.PageView)
                // do not track page views except personality pages
                if (pathname?.startsWith('/p/')) {
                    personalityDataLayerPush(GTMEvent.PageView)
                }
            }
            // fire all custom events after page view
            if(events && (events?.length || 0)> 0){
                events.forEach(element => {
                    dataLayerPush(element.event,element?.meta)
                });
            }
        }
    }, [])

    

    const dataLayerPush = (event: GTMEvent | string, data?: StringKeyObject) => {
        window?.dataLayer?.push({ event, metadata : data })
    }

    const personalityDataLayerPush = (event: GTMEvent | string, data?: StringKeyObject) => {
        window?.personalityDataLayer?.push({ event, metadata: data })
    }

    return {
        dataLayerPush,
        personalityDataLayerPush,
    }
}




export default useGTM