import {
  FC,
  useState,
  useEffect,
  createContext,
  useContext,
  useRef,
  Dispatch,
  SetStateAction,
} from 'react'
import { LayoutSplashScreen } from '../../../../_metronic/layout/core'
import { AuthModel, ReferenceData, UserModel } from './_models'
import * as authHelper from './AuthHelpers'
import { getAllPersonalities, getReferenceData, getUserByToken } from './_requests'
import { WithChildren } from '../../../../_metronic/helpers'
import useGTM from '../../../hooks/useGTM'
import { GTMEvent, emailVerificationMeta, loginSuccessMeta } from '../../../hooks/gtm_helpers'
import { getLocation } from '../../onboarding/core/_requests'
import request from '../../../core/_apis'
import { PlanData } from '../../onboarding/views/BillingModal'

export type AuthContextProps = {
  auth: AuthModel | undefined
  saveAuth: (auth: AuthModel | undefined) => void
  currentUser: Partial<UserModel> | undefined
  setCurrentUser: Dispatch<SetStateAction<Partial<UserModel> | undefined>>
  logout: () => void
  userToken: string | undefined
  setUserToken: Dispatch<SetStateAction<string | undefined>>
  personalityId: string | undefined
  storePersonalityId: Dispatch<SetStateAction<string | undefined>>
  investorId: number | undefined
  storeInvestorId: Dispatch<SetStateAction<number | undefined>>
  newPersonality: boolean | undefined
  setNewPersonality: Dispatch<SetStateAction<boolean | undefined>>
  showBillingModal: boolean
  setShowBillingModal: Dispatch<SetStateAction<boolean>>
  currentState: string
  setCurrentState: Dispatch<SetStateAction<string>>
  selected: string
  setSelected: Dispatch<SetStateAction<string>>
  currencyBill: string
  setCurrencyBill: Dispatch<SetStateAction<string>>
  billingData: any
  setBillingData: Dispatch<SetStateAction<any>>
  onboardingData: any
  setOnboardingData: Dispatch<SetStateAction<any>>
  referenceData: Partial<ReferenceData> | undefined
  setReferenceData: Dispatch<SetStateAction<Partial<ReferenceData> | undefined>>
  currentChatSession: any,
  setCurrentChatSession: Dispatch<SetStateAction<any>>,
  prevChatSession: any,
  selectedPersonality: any,
  setSelectedPersonality: Dispatch<SetStateAction<any>>
  personalityInView: any,
  setPersonalityInView: Dispatch<SetStateAction<any>>
  credits: any,
  setCredits: Dispatch<SetStateAction<any>>
  nextRoute: any
  setNextRoute: Dispatch<SetStateAction<any>>
  ipStackCountry: any,
  setIpStackCountry: Dispatch<SetStateAction<any>>
  playTextAvatar: any,
  setPlayTextAvatar: Dispatch<SetStateAction<any>>
  avatarMuted: boolean,
  setAvatarMuted: Dispatch<SetStateAction<boolean>>
  isAvatarEnabled: boolean,
  setIsAvatarEnabled: Dispatch<SetStateAction<boolean>>
  pauseListening: boolean,
  setPauseListening: Dispatch<SetStateAction<boolean>>
  billingPlans: any,
  setBillingPlans: Dispatch<SetStateAction<any>>
}

const defaultCountry = {
  "countryId": "239",
  "country_code": "US",
  "country_name": "United States",
  "phone_code": "1",
  "currency_symbol": "$",
  "capital": "Washington",
  "currency": "USD",
  "continent": "North America",
  "continent_code": "NA",
  "alpha_3": "USA",
  "flag_icon": "flags/flags-icon/us.png",
  "flag_medium": "flags/flags-medium/us.png",
  "flag_large": "flags/flags-large/us.png",
  "flag_extralarge": "flags/flags-extralarge/us.png"
}

const initAuthContextPropsState = {
  auth: authHelper.getAuth(),
  data: authHelper.getAuth(),
  saveAuth: () => { },
  currentUser: undefined,
  setCurrentUser: () => { },
  logout: () => { },
  userToken: undefined,
  setUserToken: () => { },
  personalityId: undefined,
  storePersonalityId: () => { },
  investorId: undefined,
  storeInvestorId: () => { },
  newPersonality: undefined,
  setNewPersonality: () => { },
  showBillingModal: false,
  setShowBillingModal: () => { },
  currentState: 'Monthly',
  setCurrentState: () => { },
  selected: 'Free',
  setSelected: () => { },
  currencyBill: 'USD',
  setCurrencyBill: () => { },
  billingData: undefined,
  setBillingData: () => { },
  onboardingData: getOnboardingData(),
  setOnboardingData: () => { },
  referenceData: undefined,
  setReferenceData: () => { },
  currentChatSession: undefined,
  setCurrentChatSession: () => { },
  prevChatSession: undefined,
  selectedPersonality: undefined,
  setSelectedPersonality: () => { },
  personalityInView: undefined,
  setPersonalityInView: () => { },
  credits: undefined,
  setCredits: () => { },
  nextRoute: null,
  setNextRoute: () => { },
  ipStackCountry: defaultCountry,
  setIpStackCountry: () => { },
  playTextAvatar: null,
  setPlayTextAvatar: () => { },
  avatarMuted: false,
  setAvatarMuted: () => { },
  isAvatarEnabled: false,
  setIsAvatarEnabled: () => { },
  pauseListening: false,
  setPauseListening: () => { },
  billingPlans: [],
  setBillingPlans: () => { },
}

const AuthContext = createContext<AuthContextProps>(initAuthContextPropsState);

const useAuth = () => {
  return useContext(AuthContext);
};

const AuthProvider: FC<WithChildren> = ({ children }) => {
  const [auth, setAuth] = useState<AuthModel | undefined>(authHelper.getAuth())
  const [currentUser, setCurrentUser] = useState<Partial<UserModel> | undefined>()
  const [userToken, setUserToken] = useState<string | undefined>()
  const [personalityId, storePersonalityId] = useState<string | undefined>()
  const [investorId, storeInvestorId] = useState<number | undefined>()
  const [newPersonality, setNewPersonality] = useState<boolean | undefined>()
  const [showBillingModal, setShowBillingModal] = useState<boolean>(false)
  const [currentState, setCurrentState] = useState<string>('Monthly')
  const [selected, setSelected] = useState('Free')
  const [currencyBill, setCurrencyBill] = useState('USD')
  const [billingData, setBillingData] = useState<any>()
  const [onboardingData, setOnboardingData] = useState<any>()
  const [referenceData, setReferenceData] = useState<any>()
  const [currentChatSession, setCurrentChatSession] = useState<any>(undefined)
  const [selectedPersonality, setSelectedPersonality] = useState<any>(undefined)
  const [personalityInView, setPersonalityInView] = useState<any>(undefined)
  const [credits, setCredits] = useState<number>(0)
  const [nextRoute, setNextRoute] = useState<string | null>(null)
  const prevChatSession = useRef(undefined)
  const [ipStackCountry, setIpStackCountry] = useState(null)
  const { dataLayerPush } = useGTM()
  const [playTextAvatar, setPlayTextAvatar] = useState(null)
  const [avatarMuted, setAvatarMuted] = useState(false)
  const [isAvatarEnabled, setIsAvatarEnabled] = useState(false)
  const [pauseListening, setPauseListening] = useState(false)
  const [billingPlans, setBillingPlans] = useState<any>([])

  const delay = (ms: number) => new Promise(resolve => setTimeout(resolve, ms));

  const saveAuth = async (auth: AuthModel | undefined) => {
    setAuth(auth);
    if (auth) {
      authHelper.setAuth(auth);

      const { data } = await getUserByToken(auth.token)
      const { data: { data: personalities } } = await getAllPersonalities()
      if (data) {
        if (auth?.is_signup) {
          dataLayerPush(GTMEvent.SignupSuccess, { email: data.data.email, method: auth?.type, name: `${data.data?.firstName ?? ''} ${data.data?.lastName ?? ''}`,first_name: data.data?.firstName || '', last_name: data.data?.lastName || '' })
          if (auth?.type != 'EMAIL') {
            dataLayerPush(GTMEvent.EmailVerificationSuccess, emailVerificationMeta(data.data))
            dataLayerPush(GTMEvent.OnboardingInitiatedSuccessful, { email: data.data.email })
          }
        } else {
          data.data.personalities = personalities
          dataLayerPush(GTMEvent.LoginSuccess, loginSuccessMeta(data.data))
        }
        setCurrentUser(data.data);
      }
    } else {
      authHelper.removeAuth();
    }
  };

  useEffect(() => {
    let onboarding = getOnboardingData()
    if (onboarding) {
      setOnboardingData(onboarding)
    }
    fetchReferenceData().then((data) => {
      setReferenceData(data)
    })
    request.get('/subscription-plans').then((resp) => {
      if (resp.status == 200 && resp.data.success == true) {
        let plans: PlanData[] = resp.data.data
        plans = plans.filter((el) => ['Free','Starter','Professional'].includes(el.display_name) || el.display_name.startsWith('Forever'))
        plans.sort((a, b) => a.plan.price - b.plan.price)
        setBillingPlans(plans)
      }
    })
  }, [])

  useEffect(() => {
    if (typeof onboardingData != undefined && onboardingData != undefined) {
      localStorage.setItem('onboarding_data', JSON.stringify(onboardingData))
    }
    if (onboardingData == null) {
      localStorage.removeItem('onboarding_data')
    }
  }, [onboardingData])

  useEffect(() => {
    prevChatSession.current = currentChatSession
  }, [currentChatSession])

  useEffect(() => {
    getLocation()
      .then(({ data: { country_code } }) => {
        console.log("hello ip stack 1")
        if (country_code) {
          setIpStackCountry(
            referenceData?.platformOptions?.countries.find(
              (el: any) => el.country_code == country_code
            )
          )
        } else {
          setIpStackCountry(
            referenceData?.platformOptions?.countries.find(
              (el: any) => el.country_code == "US"
            )
          )
        }
      })
      .catch((err) => {
        console.log("hello ip stack 2")
        setIpStackCountry(
          referenceData?.platformOptions?.countries.find(
            (el: any) => el.country_code == "US"
          )
        )
      });
  }, [referenceData]);

  const logout = async () => {
    let userObject = { ...currentUser }
    try {
      if (userObject?.userId) {
        dataLayerPush(GTMEvent.LogoutSuccess, { user_id: userObject.userId, user_email: userObject.email })
      }
      setCurrentUser(undefined)
      setUserToken(undefined)
      storePersonalityId(undefined)
      localStorage.removeItem('personalityId')
      await saveAuth(undefined)
    } catch (e) {
      if (userObject?.userId) {
        dataLayerPush(GTMEvent.LogoutFailed, { user_id: userObject.userId, user_email: userObject.email })
      }
    }
  }

  useEffect(() => {
    const storedNextRoute = localStorage.getItem('nextRoute');
    if (storedNextRoute) {
      setNextRoute(storedNextRoute);
    }
  }, []);
  useEffect(() => {
    if (nextRoute) {
      localStorage.setItem('nextRoute', nextRoute);
    }
  }, [nextRoute]);

  return (
    <AuthContext.Provider
      value={{
        ipStackCountry,
        setIpStackCountry,
        referenceData,
        setReferenceData,
        auth,
        saveAuth,
        currentUser,
        setCurrentUser,
        logout,
        setUserToken,
        userToken,
        personalityId,
        storePersonalityId,
        investorId,
        storeInvestorId,
        newPersonality,
        setNewPersonality,
        showBillingModal,
        setShowBillingModal,
        currentState,
        setCurrentState,
        selected,
        setSelected,
        currencyBill,
        setCurrencyBill,
        billingData,
        setBillingData,
        onboardingData,
        setOnboardingData,
        currentChatSession,
        setCurrentChatSession,
        prevChatSession,
        selectedPersonality,
        setSelectedPersonality,
        personalityInView,
        setPersonalityInView,
        credits,
        setCredits,
        nextRoute,
        setNextRoute,
        playTextAvatar,
        setPlayTextAvatar,
        avatarMuted,
        setAvatarMuted,
        isAvatarEnabled,
        setIsAvatarEnabled,
        pauseListening,
        setPauseListening,
        billingPlans,
        setBillingPlans
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

const AuthInit: FC<WithChildren> = ({ children }) => {
  const { auth, logout, setCurrentUser } = useAuth()
  const {dataLayerPush} = useGTM()
  const didRequest = useRef(false)
  const [showSplashScreen, setShowSplashScreen] = useState(true)
  // We should request user by authToken (IN OUR EXAMPLE IT'S API_TOKEN) before rendering the application
  useEffect(() => {
    const requestUser = async (apiToken: string) => {
      try {
        if (!didRequest.current) {
          const { data } = await getUserByToken(apiToken)
          const { data: { data: personalities } } = await getAllPersonalities()
          if (data) {
            data.data.personalities = personalities
            setCurrentUser(data.data);
            dataLayerPush(GTMEvent.PageLoad,loginSuccessMeta(data.data))
          }
        }
      } catch (error) {
        console.error(error);
        if (!didRequest.current) {
          logout();
        }
      } finally {
        setShowSplashScreen(false);
      }

      return () => (didRequest.current = true);
    };
    if (auth && auth.token) {
      requestUser(auth.token);
    } else {
      logout();
      setShowSplashScreen(false);
    }
    // eslint-disable-next-line
  }, []);

  return showSplashScreen ? <LayoutSplashScreen /> : <>{children}</>;
};

function getOnboardingData() {
  const onboarding = localStorage.getItem('onboarding_data')
  return onboarding ? JSON.parse(onboarding) : null
}

async function fetchReferenceData() {
  let { data, status } = await getReferenceData()
  if (status === 200 && data.success === true) {
    return data.data
  }
}

export { AuthProvider, AuthInit, useAuth }
