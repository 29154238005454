import axios from "axios";

export const API_URL = process.env.REACT_APP_BASE_API_URL;
const GET_NOTIFICATIONS = `${API_URL}/notifications`;
const GET_ALERTS = `${API_URL}/me/alerts`;
const UPDATE_ALERTS = `${API_URL}/me/alerts/status`;
const GET_UPDATES = `${API_URL}/platform_updates`;
const GET_LOCALE = `${API_URL}/user-meta`;
export const getNotifications = (personalityId: string | undefined) => {
  return axios.get(`${GET_NOTIFICATIONS}`, {
    validateStatus(status) {
      return true;
    },
  });
};

export const getAllNotifications = (personalityId: string | undefined) => {
  return axios.get(`${GET_NOTIFICATIONS}`, {
    validateStatus(status) {
      return true;
    },
  });
};

export const getAllAlerts = () => {
  return axios.get(`${GET_ALERTS}`, {
    validateStatus(status) {
      return true;
    },
  });
};

export const getAllUpdates = () => {
  return axios.get(`${GET_UPDATES}`, {
    validateStatus(status) {
      return true;
    },
  });
};

export const updateStatus = (body:any) => {
  return axios.post(`${UPDATE_ALERTS}`,body ,{
    validateStatus(status) {
      return true;
    },
  });
};

export const getLocaleData = () => {
  return axios.get(`${GET_LOCALE}`);
};
