import React from "react";
import noResult from "../../../_metronic/assets/images/images/no-result-found-img.png";
import { Link, useLocation } from "react-router-dom";
import { useIntl } from "react-intl";
import {toAbsoluteUrl} from "../../../_metronic/helpers";
import { isEqual } from "lodash";
import {useThemeMode} from "../../../_metronic/partials";

const Empty = ({
  heading,
  content,
  image,
  footer,
  smallSize,
}: {
  heading: string;
  content: string;
  image?: string;
  footer?:React.ReactNode,
  smallSize?: boolean
}) => {
  const { formatMessage } = useIntl();
  const location = useLocation();
  const { mode } = useThemeMode();

  return (
    <div className={"h-full"}>
      <div className={"flex flex-col items-center justify-center h-full py-[50px]"}>
        <div>
          {!image ? isEqual(mode,'light') ?  <img
              src={toAbsoluteUrl("/media/svg/illustrations/noActivityLight.svg")}
              alt="notification"
              width={smallSize ? 150 : 300}
              height={smallSize ? 100 : 200}
              className="mt-17 mb-12"
          /> : <img
              src={toAbsoluteUrl("/media/svg/illustrations/noActivity.svg")}
              alt="notification"
              width={smallSize ? 150 : 300}
              height={smallSize ? 100 : 200}
              className="mt-17 mb-12"
          /> : <img src={image} alt=""></img>}


        </div>
        <div className={"mt-[32px] text-center max-w-md"}>
          <h3
            className={
              "text-[14px] leading-[21px] secondary-text font-bold mb-[8px]"
            }
          >
            {heading}
          </h3>
          <p className={"text-[13px] leading-[18px] primary-text"}>
            {formatMessage({
              id: content,
            })}
          </p>
        </div>
            {footer}
      </div>
    </div>
  );
};

export default Empty;
