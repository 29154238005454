import React from "react";
import { useIntl } from "react-intl";
import clsx from "clsx";
import { Button, Modal } from "react-bootstrap";
import { ToolTipUI } from "../../../widgets/components/UI/ToolTipUI";
import { Field, Form, Formik } from "formik";
import TextArea from "../../../widgets/components/Input/TextArea";
import FormikReactSelect from "../../../widgets/components/Input/FormikReactSelect";
import { updateSocialPost } from "../../core/_request";
import { toast } from "react-toastify";
import { BasicButton } from "../../../widgets/components/UI/BasicButton";
import { CustomButton } from "../../../widgets/components/UI/CustomButton";

const SocialMediaEditPost: React.FC<any> = (props) => {
  const { formatMessage } = useIntl();
  const { selectedPost, onHide, onUpdateSuccess } = props;
  const onSubmit = async (values: any, { setSubmitting }: any) => {
    const { postId } = selectedPost;
    const { data: { success, data } } = await updateSocialPost(postId, values.text)
    if (success) {
      toast.success("Post updated successfully");
      const updatedPost = {...selectedPost, text: values.text}
      onUpdateSuccess(updatedPost);
      onHide();
    } else {
      toast.error("Error while updating the post",
        {
          autoClose: false,
          closeButton: true, // Display close button (cross icon)
        });
    }

  };
  return (
    <Modal
      {...props}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header
        closeButton
        className=" flex flex-row items-center justify-between px-[24px] pt-[24px] pb-[16px]"
      >
        <Modal.Title
          id="contained-modal-title-vcenter"
          className="flex flex-row items-center text-[20px] leading-[28px] font-[600] secondary-text"
        >
          Edit Post{" "}
          <ToolTipUI
            tooltipText={formatMessage({
              id: "Edit Post",
            })}
          />
        </Modal.Title>
        <svg
          onClick={props.onHide}
          width="32"
          height="32"
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M21.6568 8.45943L8.45746 21.6588C7.93676 22.1795 7.93676 23.0237 8.45746 23.5444C8.97816 24.0651 9.82238 24.0651 10.3431 23.5444L23.5424 10.345C24.0631 9.82435 24.0631 8.98013 23.5424 8.45943C23.0217 7.93873 22.1775 7.93873 21.6568 8.45943Z"
            fill="white"
          />
          <path
            opacity="0.5"
            d="M23.5424 21.6578L10.3431 8.45843C9.82238 7.93773 8.97816 7.93773 8.45746 8.45843C7.93676 8.97913 7.93676 9.82335 8.45746 10.3441L21.6568 23.5434C22.1775 24.0641 23.0217 24.0641 23.5424 23.5434C24.0631 23.0227 24.0631 22.1785 23.5424 21.6578Z"
            fill="white"
          />
        </svg>
      </Modal.Header>
      <Modal.Body className="p-[16px] md:p-[21px]">
        <Formik
          initialValues={{
            text: selectedPost?.text || ""
          }}
          onSubmit={onSubmit}
          // validationSchema={basicDetailsValidation}
          validateOnMount
        >
          {(formik) => {
            return (
              <Form>
                <div className="">
                  <TextArea
                    label={formatMessage({
                      id: "Anything you want to specify about the post",
                    })}
                    toolTipText={formatMessage({
                      id: "Anything you want to specify about the post",
                    })}
                    fieldName={"text"}
                    placeholder={""}
                    formik={formik}
                    className={"!min-h-[96px]"}
                  />
                  {/* <label className="flex flex-row items-center text-[#FFFFFFA6] text-[13px] leading-[20px] mb-[4px]">
                    {formatMessage({
                      id: "Reschedule Post",
                    })}
                    <ToolTipUI
                      tooltipText={formatMessage({
                        id: "Reschedule Post",
                      })}
                    />
                  </label>
                  <div className="flex-col lg:flex-row flex lg:gap-[16px]">
                    <div className="lg:w-1/2">
                      <FormikReactSelect
                        name={"date"}
                        placeholder={formatMessage({
                          id: "-",
                        })}
                        formik={formik}
                        options={[
                          {
                            label: "8/10/23",
                            value: "Commanding",
                          },
                          {
                            label: "8/10/23",
                            value: "Cooperative",
                          },
                          {
                            label: "8/10/23",
                            value: "Engaging",
                          },
                        ]}
                      />
                    </div>
                    <div className="lg:w-1/2">
                      <FormikReactSelect
                        name={"time"}
                        placeholder={formatMessage({
                          id: "-",
                        })}
                        formik={formik}
                        options={[
                          {
                            label: "16:40",
                            value: "Commanding",
                          },
                          {
                            label: "12:40",
                            value: "Cooperative",
                          },
                          {
                            label: "5:10",
                            value: "Engaging",
                          },
                        ]}
                      />
                    </div>
                  </div> */}
                </div>
                <div className="flex justify-end items-center gap-x-3">
                  <BasicButton
                    buttonText={formatMessage({ id: "Cancel" })}
                    border="none"
                    color="#C2D24B1A"
                    textColor="#C2D24B"
                    customClass={"px-[24px] mt-8"}
                    height="44px"
                    onClick={onHide}
                  />
                  <CustomButton
                    customClass="mt-8"
                    isSubmitting={formik.isSubmitting}
                    isValid={true}
                    buttonText={formatMessage({ id: "Update Post" })}
                    loading={formik.isSubmitting}
                    height={44}
                    widthLoading={1}
                    width={1}
                    onSubmit={formik.handleSubmit}
                  />
                </div>
              </Form>
            );
          }}
        </Formik>
      </Modal.Body>

      {/* <Modal.Footer className="flex flex-col-reverse sm:flex-row p-[14px] md:p-[18px]">


        <button
          onClick={props.onHide}
          className={
            "main-button-primary-light text-[14px] leading-[20px] font-medium py-[12px] w-full md:mr-[10px] sm:w-[128px] rounded"
          }
        >
          {formatMessage({
            id: "Cancel",
          })}
        </button>
        <Button variant="primary" className="w-full sm:w-auto" onClick={props.onHide}>
          Update Post
        </Button>
      </Modal.Footer> */}
    </Modal>
  );
};

export default SocialMediaEditPost;
