import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { useIntl } from "react-intl";
import { toAbsoluteUrl } from "../../../../../_metronic/helpers";
import { updateBillingAccess } from "../../../profile/core/_requests";
import { BasicButton } from "../../../widgets/components/UI/BasicButton";
import { ToolTipUI } from "../../../widgets/components/UI/ToolTipUI";
import { toast } from "react-toastify";
import { AuthContextProps, useAuth } from "../.././../auth";
import clsx from "clsx";
import crossIcon from "../../../../../_metronic/assets/images/svg/cross-dual-green-icon.svg";
import useGTM from "../../../../hooks/useGTM";
import { GTMEvent } from "../../../../hooks/gtm_helpers";
import { kmbConvertor } from "../../../../utils";

interface PlanEntitlement {
  id: string;
  name: string;
  value: string;
  object: string;
  item_id: string;
  item_type: string;
  feature_id: string;
  feature_name: string;
}

interface Plan {
  id: string;
  name: string;
  price: number;
  object: string;
  period: number;
  status: string;
  channel: string;
  item_id: string;
  item_type: string;
  archivable: boolean;
  created_at: number;
  is_taxable: boolean;
  updated_at: number;
  period_unit: string;
  currency_code: string;
  external_name: string;
  free_quantity: number;
  pricing_model: string;
  item_family_id: string;
  resource_version: number;
  show_description_in_quotes: boolean;
  show_description_in_invoices: boolean;
}

export interface PlanData {
  chargebee_id: string;
  name: string;
  display_name: string;
  currency_code: string;
  recurrence: string;
  plan: Plan;
  entitlements: PlanEntitlement[];
  updated_at: string;
  created_at: string;
  createdAt: string;
  updatedAt: string;
}

const ChangePlanModal = ({
  show,
  onHide,
  currentState,
  setCurrentState,
  selected,
  setSelected,
  currencyBill,
  // plans,
  setPrice,
  upgrade,
  companyId,
  getBillingDetails,
  planError,
}: {
  show: boolean;
  onHide: () => void;
  currentState?: string;
  setCurrentState?: Dispatch<SetStateAction<string>>;
  selected?: string;
  setSelected?: Dispatch<SetStateAction<string>>;
  currencyBill?: string;
  setPrice?: Dispatch<SetStateAction<string>>;
  upgrade?: boolean;
  getBillingDetails?: () => Promise<void>;
  // plans: PlanData[];
  companyId?: string | undefined;
  planError?: string | undefined;
}) => {
  const { billingPlans: plans, selectedPersonality } = useAuth() as {
    billingPlans: PlanData[];
    selectedPersonality: AuthContextProps["selectedPersonality"];
  };

  const [localBilling, setlocalBilling] = useState<string>("month");
  const [localPlan, setlocalPlan] = useState<any | undefined>(
    "Free-INR-Monthly"
  );
  const [localPrice, setlocalPrice] = useState("0");
  const [loading, setLoading] = useState(false);
  const { formatMessage } = useIntl();
  const { setBillingData } = useAuth();
  const [prevSelected, setPrevSelected] = useState<any>();
  const [prevBillingPeriod, setPrevBillingPeriod] = useState<any>();
  // console.log({currentState,selected,currencyBill})
  const { dataLayerPush } = useGTM();
  const messages: any = {
    "Plan doesn't allowed blue tick verification": formatMessage({
      id: "Your current plan does not allow access to the blue tick verification service. Please consider upgrading your plan to enable this feature.",
    }),
    "Plan doesn't allowed api monetization": formatMessage({
      id: "Your current plan does not allow access to the API Monetization. Please consider upgrading your plan to enable this feature.",
    }),
    "Plan doesn't allowed chat monetization": formatMessage({
      id: "Your current plan does not allow access to the Chat Monetization. Please consider upgrading your plan to enable this feature.",
    }),
    "Plan doesn't allowed 3d avatar feature": formatMessage({
      id: "Your current plan does not allow access to the 3D Avatar. Please consider upgrading your plan to enable this feature.",
    }),
    "Plan doesn't allowed hyper realistic avatar feature": formatMessage({
      id: "Your current plan does not allow access to the Hyper Realisitic Avatar. Please consider upgrading your plan to enable this feature.",
    }),
  };
  const onUpgradePlan = async () => {
    try {
      if (upgrade) {
        setLoading(true);
        if (companyId) {
          const chargebeePlanId = localPlan;
          const {
            data: { success, errors },
          } = await updateBillingAccess(companyId, chargebeePlanId);
          if (success) {
            setLoading(false);
            onHide();
            toast.success(formatMessage({ id: "Successful Update Plan" }));
            setBillingData(await getBillingDetails!());
            dataLayerPush(GTMEvent.PersonalityPlanChangedSuccessful, {
              old_plan: selectedPersonality.planId,
              new_plan: chargebeePlanId,
              new_plan_amount: localPrice,
              currency: currencyBill,
            });
          } else {
            setLoading(false);
            errors.forEach((error: string) => {
              toast.error(formatMessage({ id: error }), {
                autoClose: false,
                closeButton: true, // Display close button (cross icon)
              });
            });
            dataLayerPush(GTMEvent.PersonalityPlanChangedFailed, {
              old_plan: selectedPersonality.planId,
              new_plan: chargebeePlanId,
              new_plan_amount: localPrice,
              currency: currencyBill,
            });
          }
        }
      }
      if (setCurrentState && setSelected && setPrice) {
        setCurrentState(localBilling);
        setSelected(localPlan);
        setPrice(localPrice);
        onHide();
      }
    } catch (err) {
      dataLayerPush(GTMEvent.PersonalityPlanChangedFailed, {
        old_plan: selectedPersonality.planId,
        new_plan: localPlan,
        new_plan_amount: localPrice,
      });
      setLoading(false);
      console.log(err);
    }
  };

  useEffect(() => {
    if (currentState == "Monthly") {
      setPrevBillingPeriod("month");
      setlocalBilling("month");
    } else {
      setPrevBillingPeriod("year");
      setlocalBilling("year");
    }
    if (selectedPersonality?.planId) {
      setPrevSelected(selectedPersonality.planId);
      // setPrevSelected(`${selected}-${currencyBill}-${currentState}`);
    }
  }, [currentState, selected, show]);

  useEffect(() => {
    if (show) {
      dataLayerPush(GTMEvent.PaywallShownSuccessful, {});
    }
  }, [show]);

  return (
    <>
      <Modal
        size="xl"
        show={show}
        onHide={onHide}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <div className=" d-flex fw-bold">
            <h2 className="font-size-20">
              {formatMessage({ id: "Change your plan" })}
            </h2>{" "}
            <ToolTipUI
              tooltipText={formatMessage({
                id: "GLOBAL.TOOLTIP.BILLING.UPGRADE_PLAN",
              })}
            />
          </div>
          <img
            src={crossIcon}
            className="cursor-pointer"
            alt=""
            onClick={onHide}
          ></img>
        </Modal.Header>
        <Modal.Body className="py-3">
          {messages?.[planError || ""] && (
            <div className="flex-row graph-placeholder-wrapper d-flex md:align-items-center ">
              <div className="mr-[14px]">
                <svg
                  width="32"
                  height="32"
                  viewBox="0 0 32 32"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g clipPath="url(#clip0_4933_32805)">
                    <path
                      d="M14.667 22.667H17.3337V14.667H14.667V22.667ZM16.0003 12.0003C16.3781 12.0003 16.695 11.8723 16.951 11.6163C17.207 11.3603 17.3345 11.0439 17.3337 10.667C17.3337 10.2892 17.2057 9.97233 16.9497 9.71633C16.6937 9.46033 16.3772 9.33277 16.0003 9.33366C15.6225 9.33366 15.3057 9.46166 15.0497 9.71766C14.7937 9.97366 14.6661 10.2901 14.667 10.667C14.667 11.0448 14.795 11.3617 15.051 11.6177C15.307 11.8737 15.6234 12.0012 16.0003 12.0003ZM16.0003 29.3337C14.1559 29.3337 12.4225 28.9834 10.8003 28.283C9.1781 27.5825 7.76699 26.6328 6.56699 25.4337C5.36699 24.2337 4.41721 22.8225 3.71766 21.2003C3.0181 19.5781 2.66788 17.8448 2.66699 16.0003C2.66699 14.1559 3.01721 12.4225 3.71766 10.8003C4.4181 9.1781 5.36788 7.76699 6.56699 6.56699C7.76699 5.36699 9.1781 4.41721 10.8003 3.71766C12.4225 3.0181 14.1559 2.66788 16.0003 2.66699C17.8448 2.66699 19.5781 3.01721 21.2003 3.71766C22.8225 4.4181 24.2337 5.36788 25.4337 6.56699C26.6337 7.76699 27.5839 9.1781 28.2843 10.8003C28.9848 12.4225 29.3345 14.1559 29.3337 16.0003C29.3337 17.8448 28.9834 19.5781 28.283 21.2003C27.5825 22.8225 26.6328 24.2337 25.4337 25.4337C24.2337 26.6337 22.8225 27.5839 21.2003 28.2843C19.5781 28.9848 17.8448 29.3345 16.0003 29.3337ZM16.0003 26.667C18.9781 26.667 21.5003 25.6337 23.567 23.567C25.6337 21.5003 26.667 18.9781 26.667 16.0003C26.667 13.0225 25.6337 10.5003 23.567 8.43366C21.5003 6.36699 18.9781 5.33366 16.0003 5.33366C13.0225 5.33366 10.5003 6.36699 8.43366 8.43366C6.36699 10.5003 5.33366 13.0225 5.33366 16.0003C5.33366 18.9781 6.36699 21.5003 8.43366 23.567C10.5003 25.6337 13.0225 26.667 16.0003 26.667Z"
                      fill="#C2D24B"
                      fillOpacity="0.5"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_4933_32805">
                      <rect width="32" height="32" fill="white" />
                    </clipPath>
                  </defs>
                </svg>
              </div>
              <div className="">
                <h5 className="text-[14px] leading-[20px] font-weight-500 primary-text">
                  {" "}
                  {formatMessage({ id: "Upgrade Required" })}
                </h5>
                <p className="text-[12px] leading-[18px] font-weight-400 mt-[2px] secondary-text">
                  {messages?.[planError || ""]}
                </p>
              </div>
            </div>
          )}
          <div className="pb-4">
            <div className="gap-3 d-flex justify-content-center">
              <button
                type="button"
                className={
                  localBilling === "month"
                    ? "activeB export-button"
                    : "non-active main-button-primary-light"
                }
                // onClick={() => {
                //   setlocalPlan(plans[0].title);
                //   currencyBill === "USD"
                //     ? setlocalPrice(plans[0]?.priceMonthUSD)
                //     : setlocalPrice(plans[0]?.priceMonthINR);
                //   setlocalBilling("month");
                // }}
                onClick={() => {
                  setlocalBilling("month");
                }}
              >
                {formatMessage({ id: "Monthly" })}
              </button>
              <button
                type="button"
                className={
                  localBilling === "year"
                    ? "activeB export-button"
                    : "non-active main-button-primary-light"
                }
                // onClick={() => {
                //   setlocalPlan(plans[0].title);
                //   currencyBill === "USD"
                //     ? setlocalPrice(plans[0]?.priceAnnualUSD)
                //     : setlocalPrice(plans[0]?.priceAnnualINR);
                //   setlocalBilling("year");
                // }}
                onClick={() => {
                  setlocalBilling("year");
                }}
              >
                {formatMessage({ id: "Annual" })}
              </button>
            </div>
            <div className="mt-10 row">
              <div className="mb-10 col-lg-6 mb-lg-0">
                <div className="nav flex-column">
                  {plans
                    .filter(
                      (el) =>
                        el.recurrence == localBilling &&
                        el.currency_code == currencyBill
                    )
                    .map((plan, index) => {
                      return (
                        <div
                          key={`custom-${index}`}
                          onClick={() => {
                            setlocalPlan(plan.chargebee_id);
                            setlocalPrice(
                              (plan?.plan?.price / 100)?.toString()
                            );
                          }}
                          className={
                            `nav-link btn btn-outline btn-outline-dashed btn-color-dark d-flex flex-stack h-80px text-start p-6 ` +
                            (index !== plans.length - 1 && "mb-6 ") +
                            (plan.chargebee_id === localPlan &&
                              prevSelected !== plan.chargebee_id &&
                              " active ") +
                            "btn-active btn-active-primary " +
                            (prevSelected === plan.chargebee_id && "disableDiv")
                          }
                          data-bs-toggle="tab"
                          data-bs-target={`#kt_upgrade_plan_${index}`}
                        >
                          <div className="w-100 d-flex align-items-center me-2">
                            <div className="form-check form-check-custom form-check-solid form-check-success me-6">
                              <input
                                className={`form-check-input `}
                                type="radio"
                                name="plan"
                                value={plan.chargebee_id}
                                disabled={prevSelected === plan.chargebee_id}
                                checked={
                                  localPlan === plan.chargebee_id &&
                                  (prevSelected !== plan.chargebee_id ||
                                    prevBillingPeriod !== localBilling)
                                }
                                onChange={(e) => {
                                  setlocalPlan(e.target.value);
                                }}
                              />
                            </div>

                            <div className="flex-grow-1">
                              <h2
                                className={clsx(
                                  "d-flex align-items-center  font-size-16 fw-bolder flex-wrap",
                                  localPlan === plan.chargebee_id &&
                                    (prevSelected !== plan.chargebee_id ||
                                      prevBillingPeriod !== localBilling) &&
                                    "text-black"
                                )}
                              >
                                {plan.display_name}
                                {prevSelected === plan.chargebee_id &&
                                prevBillingPeriod === localBilling ? (
                                  <>
                                    <span className="badge badge-light-warning ms-2 fs-7">
                                      {formatMessage({ id: "Active Plan" })}
                                    </span>
                                  </>
                                ) : (
                                  <>
                                    {/* {plan.label && (
                                    <span
                                      className={`badge  ms-2 fs-7 ${plan.label === "Recommended"
                                          ? "badge-light-success"
                                          : "badge-light-primary"
                                        }`}
                                    >
                                      {plan.label}
                                    </span>
                                  )} */}
                                  </>
                                )}
                              </h2>
                            </div>

                            <div
                              className={clsx(
                                "ms-5",
                                localPlan === plan.chargebee_id &&
                                  (prevSelected !== plan.chargebee_id ||
                                    prevBillingPeriod !== localBilling) &&
                                  "text-black"
                              )}
                            >
                              {/* {plan.custom && (
                              <BasicButton
                                height="36px"
                                border="1px solid #F5F8FA"
                                color="#50CD89"
                                textColor="#FFFFFF"
                                padding="8px 16px"
                                buttonText="Contact Us"
                                minWidth={111}
                              />
                            )} */}

                              <>
                                <span className="mb-2">
                                  {currencyBill === "INR" ? "₹" : "$"}
                                </span>
                                <span className="fs-3x fw-bolder">
                                  {plan.plan.price / 100}
                                </span>
                                <span className="opacity-50 fs-7">
                                  /
                                  <span data-kt-element="period">
                                    {localBilling}
                                  </span>
                                </span>
                              </>
                            </div>
                          </div>
                        </div>
                      );
                    })}

                  <div
                    className={`enterprise-plan d-flex h-80px text-start p-6  rounded-[8px]`}
                  >
                    <div className="w-100 d-flex align-items-center me-2">
                      <div className="form-check form-check-custom form-check-solid form-check-success me-6 w-[23px]"></div>

                      <div className="flex-grow-1">
                        <h2
                          className={clsx(
                            "d-flex align-items-center  font-size-16 fw-bolder flex-wrap"
                          )}
                        >
                          Enterprise
                        </h2>
                      </div>

                      <div className={clsx("ms-5")}>
                        <a
                          target="_blank"
                          rel="noreferrer"
                          href="mailto:support@kamoto.ai?bcc=tosh@kamoto.ai&subject=Looking%20for%20Enterprise%20Plan%20of%20Kamoto.AI&body=Hi%2C%0A%0AI%20am%20looking%20for%20a%20Custom%20Enterprise%20Plan%20for%20Kamoto.AI%2C%0A%0APlease%20get%20in%20touch%20with%20me.%0A"
                          className="bg-[#C2D24B] p-[8px_16px] rounded-[4px]   text-[14px] leading-[20px] font-[600]"
                        >
                          Contact Us
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-lg-6 plan-feature-list h-[300px] overflow-y-auto">
                <div className="px-0 md:px-10 py-5 rounded tab-content">
                  {plans.map((plan, index) => {
                    return (
                      <div key={`custom ${index}`}>
                        {plan.chargebee_id === localPlan && (
                          <>
                            <div
                              className={
                                `tab-pane fade` +
                                (plan.chargebee_id === localPlan &&
                                  "show active ")
                              }
                              id={`kt_upgrade_plan_${index}`}
                              key={index}
                            >
                              <div className="pt-1">
                                {plan.entitlements
                                  .sort((a, b) =>
                                    a.value === "true"
                                      ? -1
                                      : b.value === "true"
                                      ? 1
                                      : 0
                                  )!
                                  .map((feature, i) => {
                                    return (
                                      <div
                                        className={
                                          `d-flex align-items-center` +
                                          (i !==
                                            plan.entitlements!.length - 1 &&
                                            " mb-7")
                                        }
                                        key={`${i}-${feature.feature_name}`}
                                      >
                                        {/* {true && (
                                        // feature.supported
                                        <> */}
                                        <span className="text-gray-700 font-size-13 flex-grow-1">
                                          {feature.feature_name}
                                        </span>
                                        {feature.value == "true" ? (
                                          <span className=" svg-icon svg-icon-1 svg-icon-success">
                                            <img
                                              src={toAbsoluteUrl(
                                                "/media/icons/duotune/general/bill_tick.svg"
                                              )}
                                              height="20px"
                                              width="20px"
                                              alt="img_icon"
                                            />
                                          </span>
                                        ) : (
                                          <span className="svg-icon svg-icon-1 svg-icon-success">
                                            {feature.name}
                                          </span>
                                        )}
                                        {/* </>
                                      )} */}
                                        {/* {!feature.supported && (
                                        <>
                                          <span className="text-gray-400 font-size-13 flex-grow-1">
                                            {feature.title}
                                          </span>

                                          <span className="svg-icon-1">
                                            <img
                                              src={toAbsoluteUrl(
                                                "/media/icons/duotune/general/bill_cross.svg"
                                              )}
                                              height="20px"
                                              width="20px"
                                              alt="img_icon"
                                            />
                                          </span>
                                        </>
                                      )} */}
                                      </div>
                                    );
                                  })}
                              </div>
                            </div>
                          </>
                        )}
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div className="gap-3 w-100 d-flex justify-content-md-end justify-content-around">
            <BasicButton
              buttonText={formatMessage({ id: "Cancel" })}
              onClick={onHide}
              border="none"
              // color="#C2D24B1A"
              customClass={"main-button-primary-light"}
              // textColor="#C2D24B"
              height="44"
              padding="12px 22px"
              minWidth={91}
            />
            <BasicButton
              buttonText={formatMessage({ id: "Upgrade Now" })}
              border="none"
              customClass={"export-button"}
              height="44"
              padding="12px 24px"
              minWidth={137}
              onClick={onUpgradePlan}
              loading={loading}
              disabled={
                loading ||
                (localPlan === prevSelected &&
                  prevBillingPeriod === localBilling)
              }
            />
          </div>
        </Modal.Footer>
      </Modal>
    </>
  );
};
export default ChangePlanModal;
