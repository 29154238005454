/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC, useState } from "react";
import clsx from "clsx";
import {
  toAbsoluteUrl,
  defaultMessages,
  defaultUserInfos,
  MessageModel,
  UserInfoModel,
  messageFromClient,
} from "../../../../../../_metronic/helpers";
import { isEqual } from "lodash";
import Chat from "../../../containers/ai-profile/chat";
import { useAuth } from "../../../../auth";
import { getCdnUrl } from "../../../../../core/_util";
import userDefault from "../../../../../assets/images/profile/user-default.png";
type Props = {
  isDrawer?: boolean;
  isDark?: boolean;
};
const bufferMessages = defaultMessages;

const Index: FC<Props> = ({ isDrawer = false,isDark=false }) => {
  const [chatUpdateFlag, toggleChatUpdateFlat] = useState<boolean>(false);
  const [message, setMessage] = useState<string>("");
  const [messages, setMessages] = useState<MessageModel[]>(bufferMessages);
  const [userInfos] = useState<UserInfoModel[]>(defaultUserInfos);
  const { currentUser, personalityInView } = useAuth();
  const currentUserImage = currentUser?.photo
    ? getCdnUrl(currentUser.photo)
    : userDefault;
  const personalityImage = personalityInView?.image
    ? getCdnUrl(personalityInView.image)
    : userDefault;


  return (
    <div className="relative  p-0 overflow-y-auto custom-scroll">
      <Chat
        startChat={true}
        setStartChat={() => { }}
        personalityImage={personalityImage}
        userImage={currentUserImage}
        isAd={false}
        showTimer={true}
      />
    </div>
  );
};

export default Index;
