import { get, isEqual, map } from "lodash";
import React, { useEffect, useState } from "react";
import CustomTable from "../../../components/table";
import { downloadInvoice, getRechargeHistory } from "../core/_request";
import { useDateFormat } from "../../../hooks/useDateFormat";
import { useTimeZone } from "../../../hooks/useTimeZone";
import { toast } from "react-toastify";
import { Spinner } from "react-bootstrap";
import { useIntl } from "react-intl";
import { commaSeparated } from "../../../utils";

const RechargeHistory = () => {
  const { formatMessage } = useIntl();
  const { getDateValue } = useDateFormat();
  const { getTimeZoneValue } = useTimeZone();
  const [loading, setLoading] = useState<boolean>(true);
  const [data, setData] = useState<any[]>([])
  const [pageCount, setPageCount] = useState<number>(0)

  async function getHistory({page, size, search}:any) {
    setLoading(true);
    const {
      data: { success, data },
    } = await getRechargeHistory({ page:page + 1, limit:size, search:search });
    if (success) {
      let formattedData = data.history.map(
        ({ transactionId, createdAt, status, amount, credits, currency, invoiceId }: any) => {
          return {
            transactionId: transactionId || "-",
            amount: `${currency} ${commaSeparated(amount)}`,
            creditAmount: status == "success" ? `$ ${commaSeparated(credits)}` : "-",
            createdAt: `${getDateValue(createdAt)} ${getTimeZoneValue(
              createdAt
            )}`,
            status,
            invoiceId,
          };
        }
      );
      setLoading(false);
      setData(formattedData)
      setPageCount(Math.ceil(data.count / size))
    }
  }


  const headers = [
    {
      label: `${formatMessage({ id: "Recharge date" })}`,
      key: "createdAt",
      enableSorting: true,
    },
    {
      label: `${formatMessage({ id: "Amount" })}`,
      key: "amount",
      enableSorting: true,
    },
    {
      label: `${formatMessage({ id: "Credits added" })}`,
      key: "creditAmount",
      enableSorting: true,
    },
    {
      label: `${formatMessage({ id: "Transaction Id" })}`,
      key: "transactionId",
      enableSorting: true,
    },
    {
      label: `${formatMessage({ id: "Status" })}`,
      key: "status",
      enableSorting: true,
    },
    { label: `${formatMessage({ id: "Invoice" })}`, key: "invoiceId" },
  ];

  const ButtonOrLoader = ({ info }: any) => {
    const [downloading, setDownloading] = useState<boolean>(false);
    async function download(invoiceId: string) {
      if (!invoiceId) {
        return toast.error(`${formatMessage({ id: "Invoice not found" })}`,
        {
          autoClose: false,
          closeButton: true, // Display close button (cross icon)
        });
      }
      setDownloading(true);
      const {
        data: { success, data, errors },
      } = await downloadInvoice(invoiceId);
      if (success) {
        const link = document.createElement("a");
        link.href = data;
        link.download = invoiceId;
        link.click();
      } else {
        toast.error(errors[0],
          {
            autoClose: false,
            closeButton: true, // Display close button (cross icon)
          });
      }
      setDownloading(false);
    }

    return (
      <button
        className={
          "secondary-bg py-[7px] px-[12px] rounded flex items-center justify-center"
        }
        onClick={() => !downloading && download(info.getValue("invoiceId"))}
      >
        {downloading ? (
          <Spinner style={{ width: 20, height: 20 }} animation="border" />
        ) : (
          <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g clipPath="url(#clip0_497_28930)">
              <path
                d="M2.6665 11.3337V12.667C2.6665 13.0206 2.80698 13.3598 3.05703 13.6098C3.30708 13.8598 3.64622 14.0003 3.99984 14.0003H11.9998C12.3535 14.0003 12.6926 13.8598 12.9426 13.6098C13.1927 13.3598 13.3332 13.0206 13.3332 12.667V11.3337M4.6665 7.33366L7.99984 10.667M7.99984 10.667L11.3332 7.33366M7.99984 10.667V2.66699"
                className={"icon-stroke"}
                strokeOpacity="0.65"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </g>
            <defs>
              <clipPath id="clip0_497_28930">
                <rect width="16" height="16" fill="white" />
              </clipPath>
            </defs>
          </svg>
        )}
      </button>
    );
  };
  const columns = React.useMemo<any>(
    () =>
      map(headers, (item) => {
        return {
          header: get(item, "label"),
          accessorKey: get(item, "key"),
          enableSorting: get(item, "enableSorting"),
          cell: (info: any) => {
            if (get(item, "key") == "invoiceId") {
              return <ButtonOrLoader info={info} />;
            }
            if (isEqual(get(item, "key", ""), "status")) {
              return (
                <div>
                  {isEqual(info.getValue(), "success") && (
                    <span
                      className={
                        "px-4 py-1 bg-[#00B7121A] text-[#00B712] rounded"
                      }
                    >
                       {formatMessage({ id: "Success" })}
                    </span>
                  )}
                  {isEqual(info.getValue(), "failed") && (
                    <span
                      className={
                        "px-4 py-1 bg-[#D24B4B1A] text-[#D24B4B] rounded"
                      }
                    >
                       {formatMessage({ id: "Failed" })}
                    </span>
                  )}
                  {isEqual(info.getValue(), "in-progress") && (
                    <span
                      className={
                        "px-4 py-1 bg-[#F59A101A] text-[#FF9900] rounded"
                      }
                    >
                       {formatMessage({ id: "In Progress" })}
                    </span>
                  )}
                  {isEqual(info.getValue(), "submitted") && (
                    <span
                      className={
                        "px-4 py-1 bg-[#F59A101A] text-[#FF9900] rounded"
                      }
                    >
                        {formatMessage({ id: "Submitted" })}
                    </span>
                  )}
                  {isEqual(info.getValue(), "pending") && (
                    <span
                      className={
                        "px-4 py-1 bg-[#F59A101A] text-[#FF9900] rounded"
                      }
                    >
                       {formatMessage({ id: "Pending" })}
                    </span>
                  )}
                </div>
              );
            } else {
              return info.getValue();
            }
          },
          footer: (props: any) => props.column.id,
        };
      }),

    []
  );

  return (
    <div className={"min-h-[60vh] py-5"}>
      <CustomTable
        tableData={data}
        pageCount={pageCount}
        loading={loading}
        headers={headers}
        columns={columns}
        fetchData={getHistory}
      />
    </div>
  );
};

export default RechargeHistory;
