import React, { useState } from "react";
import CustomTable from "../../../components/table";
import { get, map } from "lodash";
import { useDateFormat } from "../../../hooks/useDateFormat";
import { useTimeZone } from "../../../hooks/useTimeZone";
import { getUsageHistory } from "../core/_request";
import { useIntl } from "react-intl";

const RechargeHistory = () => {
  const { formatMessage } = useIntl();
  const { getDateValue } = useDateFormat();
  const { getTimeZoneValue } = useTimeZone();
  const [loading, setLoading] = useState<boolean>(true);
  const [data, setData] = useState<any[]>([])
  const [pageCount, setPageCount] = useState<number>(0)

  async function getHistory({ page, size }: any) {
    setLoading(true);
    const {
      data: { success, data },
    } = await getUsageHistory({ page : page + 1, limit: size });
    if (success) {
      let formattedData = data.history.map(
        ({
          personalityName,
          startedAt,
          creditConsumed,
          totalDuration,
        }: any) => {
          return {
            personalityName,
            startedAt: `${getDateValue(startedAt)} ${getTimeZoneValue(
              startedAt
            )}`,
            creditConsumed: `$ ${creditConsumed}`,
            totalDuration: `${Math.ceil(totalDuration / 60)} Minutes`,
          };
        }
      );
      setLoading(false);
      setData(formattedData)
      setPageCount(Math.ceil(data.count / size))
    }
  }

  const headers = [
    {
      label: formatMessage({ id: "Personality Name" }),
      key: "personalityName",
      enableSorting: true,
    },
    {
      label: formatMessage({ id: "Interacted On" }),
      key: "startedAt",
      enableSorting: true,
    },
    {
      label: formatMessage({ id: "Credits Consumed" }),
      key: "creditConsumed",
      enableSorting: true,
    },
    {
      label: formatMessage({ id: "Minutes Used" }),
      key: "totalDuration",
      enableSorting: true,
    },
  ];

  const columns = React.useMemo<any>(
    () =>
      map(headers, (item) => {
        return {
          header: get(item, "label"),
          accessorKey: get(item, "key"),
          enableSorting: get(item, "enableSorting"),
          footer: (props: any) => props.column.id,
        };
      }),

    []
  );

  return (
    <div className={"min-h-[60vh] py-5"}>
      <CustomTable
        tableData={data}
        pageCount={pageCount}
        loading={loading}
        headers={headers}
        columns={columns}
        fetchData={getHistory}
      />
    </div>
  );
};

export default RechargeHistory;
