import React, { useEffect } from 'react'
import { useSearchParams } from 'react-router-dom'

const DiscordCallback = () => {

    const [searchParams, setSearchParams] = useSearchParams()

    useEffect(() => {
        if(searchParams.get('code')){
            window.opener.postMessage({code: searchParams.get('code')}, window.location.origin);
            // window.close();
        }
    },[])

    return (
        <></>
    )
}

export default DiscordCallback