/* eslint-disable jsx-a11y/anchor-is-valid */
import clsx from "clsx";
import { FC, useEffect, useState } from "react";
import { toAbsoluteUrl } from "../../../helpers";
import { useLang, setLanguage } from "../../../i18n/Metronici18n";
import { createLocale } from "../../../../app/modules/profile/core/_requests";
import { useAuth } from "../../../../app/modules/auth";
import  {useWindowSize} from '../../../../app/hooks/useWindowSize'
import { useIntl } from "react-intl";
import useGTM from "../../../../app/hooks/useGTM";
import { GTMEvent } from "../../../../app/hooks/gtm_helpers";
const languages = [
  {
    lang: "en",
    locale: "en_US",
    name: "English",
    flag: toAbsoluteUrl("/media/flags/united-states.svg"),
  },
  {
    lang: "zh",
    locale: "zh_CN",
    name: "Mandarin-普通話",
    flag: toAbsoluteUrl("/media/flags/china.svg"),
  },
  {
    lang: "es",
    locale: "es_ES",
    name: "Spanish-Español",
    flag: toAbsoluteUrl("/media/flags/spain.svg"),
  },
  {
    lang: "ja",
    locale: "ja_JP",
    name: "Japanese-日本",
    flag: toAbsoluteUrl("/media/flags/japan.svg"),
  },
  {
    lang: "de",
    locale: "de_GN",
    name: "German-Deutsch",
    flag: toAbsoluteUrl("/media/flags/germany.svg"),
  },
  {
    lang: "fr",
    locale: "ft_FR",
    name: "French-Français",
    flag: toAbsoluteUrl("/media/flags/france.svg"),
  },
  {
    lang: "hi",
    locale: "hi_HI",
    name: "Hindi-हिंदी",
    flag: toAbsoluteUrl("/media/flags/india.svg"),
  },
  {
    lang: "bn",
    locale: "bn_BN",
    name: "Bangla-বাংলা",
    flag: toAbsoluteUrl("/media/flags/bangladesh.svg"),
  },
  {
    lang: "it",
    locale: "it_IT",
    name: "Italian-italiano",
    flag: toAbsoluteUrl("/media/flags/italy.svg"),
  },
  {
    lang: "ko",
    locale: "ko_KO",
    name: "Korean-한국인",
    flag: toAbsoluteUrl("/media/flags/south-korea.svg"),
  },
  {
    lang: "pt",
    locale: "pt_PT",
    name: "Portuguese-Português",
    flag: toAbsoluteUrl("/media/flags/portugal.svg"),
  },
  {
    lang: "ru",
    locale: "ru_RU",
    name: "Russian-Русский",
    flag: toAbsoluteUrl("/media/flags/russia.svg"),
  },
];

const Languages: FC = () => {
// @ts-ignore
  const windowSize = useWindowSize()
  const lang = useLang();
  const {dataLayerPush} = useGTM()
  const {currentUser} = useAuth()
  const currentLanguage = languages.find((x) => x.lang === lang);
  const changeLanguage = async (lang:any) => {
    // change language in api first
    createLocale(
      "locale",
      lang.locale,
      currentUser?.personality[0].personalityId
    ).then(() => {
      // changed
    }).finally(() => {
      setLanguage(lang.lang)
      dataLayerPush(GTMEvent.LanguagesSwitchSuccessful,{new_language: lang.lang})
    })
  }
  const { formatMessage } = useIntl();


  return (
    <div
      className="menu-item px-5"
      data-kt-menu-trigger="hover"
      data-kt-menu-placement={windowSize.innerWidth < 620 ?"bottom" :'left-start'}
      data-kt-menu-flip="bottom"
    >
      <a href="#" className="menu-link px-5">
        <span className="menu-title position-relative">
          {formatMessage({ id: "Language" })}
          <span className="fs-7 rounded bg-light px-3 py-2 position-absolute translate-middle-y top-1/2 end-0">
            <div className={"flex items-center gap-1"}>
              {currentLanguage?.name}{" "}
              <img
                className="w-15px h-15px rounded-1 ms-2"
                src={currentLanguage?.flag}
                alt="metronic"
              />
            </div>
          </span>
        </span>
      </a>

      <div className="menu-sub menu-sub-dropdown  py-4">
        {languages.map((l) => (
          <div
            className="menu-item px-3"
            key={l.lang}
            onClick={() => {
              changeLanguage(l);
            }}
          >
            <a
              href="#"
              className={clsx("menu-link d-flex px-5", {
                active: l.lang === currentLanguage?.lang,
              })}
            >
              <span className="symbol symbol-20px me-4">
                <img className="rounded-1" src={l.flag} alt="metronic" />
              </span>
              {l.name}
            </a>
          </div>
        ))}
      </div>
    </div>
  );
};

export { Languages };
