import { useState } from "react";
import { deleteTrainingChat } from "../../core/_request";
import { toast } from "react-toastify";
import { Spinner } from "react-bootstrap";

interface RequestInterface{
  prompt:string;
  image:string;
  chatId:string;
  setTrainingChats?:React.Dispatch<React.SetStateAction<any>>;
  isOwned:boolean;
  status:string;
}
const Index = ({prompt, image, chatId, setTrainingChats, isOwned,status}:RequestInterface) => {

  const [loading, setLoading] = useState<boolean>(false)
  async function deleteChat(){
    if(!chatId) return;
    setLoading(true)
    const {data:{success}} = await deleteTrainingChat(chatId)
    if(success){
      setTrainingChats && setTrainingChats((tc:any)=>tc.filter((c:any)=>c.chatId!==chatId))
    } else {
      toast.error('Error while deleting chat',
      {
        autoClose: false,
        closeButton: true, // Display close button (cross icon)
      })
    }
    setLoading(false)
  }

  return (
    <div className={"flex justify-end"}>
      <div
        className={
          "px-8 py-[17px] secondary-bg rounded-[6px] flex md:items-center gap-x-5 md:gap-x-11"
        }
      >
        <p>{prompt}</p>
        <div className={"flex items-center gap-x-4"}>
          <img
            src={image}
            className={"w-8 h-8 rounded-full"}
            alt={"Avatar"}
          />
          {setTrainingChats && isOwned && status==='draft' && (loading ? <Spinner animation="border"/>: <svg
            onClick={deleteChat}
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g clipPath="url(#clip0_497_13298)">
              <path
                d="M4 7H20M10 11V17M14 11V17M5 7L6 19C6 19.5304 6.21071 20.0391 6.58579 20.4142C6.96086 20.7893 7.46957 21 8 21H16C16.5304 21 17.0391 20.7893 17.4142 20.4142C17.7893 20.0391 18 19.5304 18 19L19 7M9 7V4C9 3.73478 9.10536 3.48043 9.29289 3.29289C9.48043 3.10536 9.73478 3 10 3H14C14.2652 3 14.5196 3.10536 14.7071 3.29289C14.8946 3.48043 15 3.73478 15 4V7"
                stroke="#8898A6"
                strokeWidth="1.6"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </g>
            <defs>
              <clipPath id="clip0_497_13298">
                <rect width="24" height="24" className={'icon'} />
              </clipPath>
            </defs>
          </svg>)}
        </div>
      </div>
    </div>
  );
};

export default Index;
