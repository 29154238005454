import { useIntl } from "react-intl";
import "../styles/index.scss";
import { ToolTipUI } from "../../widgets/components/UI/ToolTipUI";
import {
  EmailPreferencesInput,
  EmailPreferencesOutput,
} from "../core/_constants";
import {
  EmailPreferencesInputType,
  EmailPreferencesOutputType,
} from "../core/_models";
import { Dispatch, SetStateAction, useState, useEffect, memo } from "react";
import { createEmailPreferences, getEmailPreferences } from "../core/_requests";
import { useAuth } from "../../auth";
import { toast } from "react-toastify";
import { Spinner } from "../../widgets/components/General/Spinner";
import { BasicButton } from "../../widgets/components/UI/BasicButton";
import SearchInput from "./SearchInput";
import useGTM from "../../../hooks/useGTM";
import { GTMEvent } from "../../../hooks/gtm_helpers";

const NotificationPreferencesComponent = () => {
  const [loading, setLoading] = useState(false);
  const [getPreferencesApiLoading, setGetPreferencesApiLoading] =
    useState(false);
  const [getPreferences, setGetPreferences] = useState(EmailPreferencesOutput);
  const {dataLayerPush} = useGTM()
  const { personalityId } = useAuth();

  const handleToogle = (id: number, checked: boolean) => {
    let newGetPreferences: Array<EmailPreferencesOutputType> =
      getPreferences.map((preference: EmailPreferencesOutputType) => {
        if (preference.id == id) {
          return { ...preference, value: checked };
        } else {
          return preference;
        }
      });
    setGetPreferences(newGetPreferences);
  };

  useEffect(() => {
    const getApiEmailPreferences = async () => {
      try {
        setGetPreferencesApiLoading(true);
        const {
          data: {
            data: { value },
            success,
            errors,
          },
        } = await getEmailPreferences();
        if (success) {
          setGetPreferencesApiLoading(false);
          let newGetPreferences = getPreferences.map((item) => {
            if (value[item.title] !== null) {
              return { ...item, value: value[item.title] };
            } else {
              return item;
            }
          });
          setGetPreferences(newGetPreferences);
        } else {
          setGetPreferencesApiLoading(false);
          errors.forEach((error: string) => {
            toast.error(formatMessage({ id: error }),
            {
              autoClose: false,
              closeButton: true, // Display close button (cross icon)
            });
          });
        }
      } catch (err) {
        setGetPreferencesApiLoading(false);
        console.log(err);
      }
    };
    getApiEmailPreferences();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const handleSumbit = async () => {
    try {
      setLoading(true);
      if (personalityId) {
        let obj: { [key: string]: boolean } = {};
        getPreferences.forEach((item) => {
          obj[item.title] = item.value;
        });
        const {
          data: { success, errors },
        } = await createEmailPreferences(JSON.stringify(obj));
        if (success) {
          const {
            data: {
              data: { value },
              success,
            },
          } = await getEmailPreferences();
          if (success) {
            let newGetPreferences = getPreferences.map((item) => {
              if (value[item.title] !== null) {
                return { ...item, value: value[item.title] };
              } else {
                return item;
              }
            });
            setGetPreferences(newGetPreferences);
            dataLayerPush(GTMEvent.UserSettingsEditSuccessful,{"tab": "notifications"})
          }
          setLoading(false);
          toast.success(
            formatMessage({ id: "Preferences updated successfully" })
          );
        } else {
          setLoading(false);
          errors.forEach((error: string) => {
            toast.error(formatMessage({ id: error }),
            {
              autoClose: false,
              closeButton: true, // Display close button (cross icon)
            });
          });
          dataLayerPush(GTMEvent.UserSettingsEditFailed,{"tab": "notifications"})
        }
      }
    } catch (err) {
      setLoading(false);
      console.log(err);
    }
  };

  const { formatMessage } = useIntl();
  interface TooltipObj {
    [key: number]: string;
  }
  const tooltipObj: TooltipObj = {
    1: `${formatMessage({
      id: "GLOBAL.TOOLTIP.NOTIFICATIONS.AI_PERSONALITY_DETAILS_EDIT",
    })}`,
    2: `${formatMessage({
      id: "GLOBAL.TOOLTIP.NOTIFICATIONS.AI_PERSONALITY_TRAINING_BY_CHAT",
    })}`,
    3: `${formatMessage({
      id: "GLOBAL.TOOLTIP.NOTIFICATIONS.AI_PERSONALITY_TRAINING_BY_PUBLIC_LINKS",
    })}`,
    4: `${formatMessage({
      id: "GLOBAL.TOOLTIP.NOTIFICATIONS.AI_PERSONALITY_TRAINING_BY_PRIVATE_DATA",
    })}`,
    5: `${formatMessage({
      id: "GLOBAL.TOOLTIP.NOTIFICATIONS.REFUND_DETAILS.MONETIZATION_DETAILS_UPDATED",
    })}`,
    6: `${formatMessage({
      id: "GLOBAL.TOOLTIP.NOTIFICATIONS.INVOICE_PAYMENT.LISTING_STATUS_CHANGED",
    })}`,
    7: `${formatMessage({
      id: "GLOBAL.TOOLTIP.NOTIFICATIONS.BILLING UPDATES.SOMEONE_FOLLOWED_THE_AI_PERSONALITY",
    })}`,
    8: `${formatMessage({
      id: "GLOBAL.TOOLTIP.NOTIFICATIONS.NEW_TEAM_MEMBERS.SOMEONE_SUBSCRIBE_TO_PAID_ACCESS_OF_AI_PERSONALITY",
    })}`,
    9: `${formatMessage({
      id: "GLOBAL.TOOLTIP.NOTIFICATIONS.COMPLETED_INVESTMENTS.PAID_SESSION_COMPLETED",
    })}`,
    10: `${formatMessage({
      id: "GLOBAL.TOOLTIP.NOTIFICATIONS.NEWSLETTERS.SETTLEMENT_EXECUTED",
    })}`,
  };
  const EmailPreferencesInput = [
    {
      id: 1,
      title: `${formatMessage({
        id: "AI Personality Details Edited in Dashboard",
      })}`,
      subtitle: `${formatMessage({
        id: "Receive a notification every time the details of your AI personality is edited",
      })}`,
      toggleLabel: "editAiPersonality",
    },
    {
      id: 2,
      title: `${formatMessage({
        id: "AI Personality Training by Chat Submitted",
      })}`,
      subtitle: `${formatMessage({
        id: "Receive a notification for every submission on Train by Chat",
      })}`,
      toggleLabel: "trainingChat",
    },
    {
      id: 3,
      title: `${formatMessage({
        id: "AI Personality Training by Public Links Submitted",
      })}`,
      subtitle: `${formatMessage({
        id: "Receive a notification for every submission on Train by Public Links",
      })}`,
      toggleLabel: "trainingLink",
    },
    {
      id: 4,
      title: `${formatMessage({
        id: "AI Personality Training by Private Data Submitted",
      })}`,
      subtitle: `${formatMessage({
        id: "Receive a notification for every submission on Train by Private Data",
      })}`,
      toggleLabel: "trainingPrivate",
    },
    {
      id: 5,
      title: `${formatMessage({
        id: "Training for AI Personality Completed",
      })}`,
      subtitle: `${formatMessage({
        id: "Receive a notification when Training is Over for your AI Personality",
      })}`,
      toggleLabel: "monetizationDetailsUpdated",
    },
    {
      id: 6,
      title: `${formatMessage({
        id: "Monetization Details updated in Dashboard",
      })}`,
      subtitle: `${formatMessage({
        id: "Receive a notification when your AI Personality’s monetization details are updated",
      })}`,
      toggleLabel: "listingStatusChanged",
    },
    {
      id: 7,
      title: `${formatMessage({ id: "Listing Status Changed in Dashboard" })}`,
      subtitle: `${formatMessage({
        id: "Receive a notification when your AI Personality’s Makrteplace listing status changes",
      })}`,
      toggleLabel: "followerUpdates",
    },
    {
      id: 8,
      title: `${formatMessage({
        id: "AI Personality Followed on Marketplace",
      })}`,
      subtitle: `${formatMessage({
        id: "Receive a notification when anyone follows this AI Personality on AI Marketplace",
      })}`,
      toggleLabel: "paidAccess",
    },
    {
      id: 9,
      title: `${formatMessage({
        id: "Someone Subscribe to API access of AI Personality in Marketplace",
      })}`,
      subtitle: `${formatMessage({
        id: "Receive a notification when a user subscribe to the API access of your AI Personality in Marketplace",
      })}`,
      toggleLabel: "PaidSessionCompleted",
    },
    {
      id: 10,
      title: `${formatMessage({
        id: "Paid Chat Interactions Subscribed for AI Personality in Marketplace",
      })}`,
      subtitle: `${formatMessage({
        id: "Receive a notification when someone subscribe to the paid chat interaction to this AI Personality",
      })}`,
      toggleLabel: "fillAccount",
    },
    {
      id: 11,
      title: `${formatMessage({
        id: "Paid Chat Session Completed in Marketplace",
      })}`,
      subtitle: `${formatMessage({
        id: "Receive a notification when a user complete a paid chat session with your AI Personality",
      })}`,
      toggleLabel: "sessionchat",
    },
    {
      id: 12,
      title: `${formatMessage({
        id: "User Has Provided Feedback on Chat Interactions with Your AI Personality",
      })}`,
      subtitle: `${formatMessage({
        id: "Receive a notification when feedback is provided by the users after interaction with your AI Personality",
      })}`,
      toggleLabel: "feedbackonchat",
    },
    {
      id: 13,
      title: `${formatMessage({ id: "Settlement Executed by Kamoto.AI" })}`,
      subtitle: `${formatMessage({
        id: "Receive a notification when Kamoto.AI settle the money in your account.",
      })}`,
      toggleLabel: "settlementexecuted",
    },
  ];
  return (
    <>
      <SearchInput />
      {getPreferencesApiLoading ? <Spinner className="mt-[-10px]" /> : null}
      <div className="tab-container p-[0px] md:p-[24px] md:pb-[40px] shadow-default rounded primary-text company-container">
        <h3 className={"text-[16px] leading-[22px] font-medium mb-[8px]"}>
          {formatMessage({ id: "Notification Preferences" })}
        </h3>
        {EmailPreferencesInput.map(
          (
            { id, title, subtitle }: EmailPreferencesInputType,
            index: number
          ) => (
            <div className="border-bottom company-container" key={id}>
              <form className="form-check  form-check-custom react-switch-bg form-check-solid form-check-primary form-switch flex items-center gap-x-[8px]">
                <div>
                  <input
                    className="form-check-input flex-grow !w-[45px]"
                    type="checkbox"
                    id={id.toString()}
                    name="model.app.sidebar.default.minimize.desktop.hoverable"
                    checked={getPreferences && getPreferences[index]?.value}
                    onChange={(e) => {
                      handleToogle(id, e.target.checked);
                    }}
                  />
                </div>
                <div className="d-flex flex-column justify-content-end gap-1 md:gap-2 py-[4px] md:py-2">
                  <div className="d-flex justify-between md:justify-normal">
                    <div>
                      <label
                        className="text-[13px] leading-[18px]  primary-text font-semibold"
                        htmlFor={id.toString()}
                        data-bs-toggle="tooltip"
                        data-kt-initialized="1"
                      >
                        {title}
                      </label>
                    </div>
                    <div>{<ToolTipUI tooltipText={subtitle} />}</div>
                  </div>
                  <label
                    className="text-[12px] leading-[18px]  secondary-text"
                    htmlFor={id.toString()}
                    data-bs-toggle="tooltip"
                    data-kt-initialized="1"
                  >
                    {subtitle}
                  </label>
                </div>
              </form>
            </div>
          )
        )}
        <div className="flex justify-end mt-[20px] md:mt-[135px]">
          <BasicButton
            buttonText={formatMessage({ id: "Save Changes" })}
            onClick={handleSumbit}
            disabled={loading}
            loading={loading}
            height="44px"
            customClass={"w-full md:w-auto export-button"}
            padding="12px 24px"
          />
        </div>
      </div>
    </>
  );
};

export const NotificationPreferences = memo(NotificationPreferencesComponent);
