/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useRef } from "react";
import ApexCharts, { ApexOptions } from "apexcharts";

import { useThemeMode } from "../../../../../_metronic/partials";
import {
  getCSS,
  getCSSVariableValue,
} from "../../../../../_metronic/assets/ts/_utils";
import { get, isEqual, map } from "lodash";

type Props = {
  className: string;
  isCumulative?: boolean;
  data: any;
  categories: any;
};

const Index: React.FC<Props> = ({ className, isCumulative = false, data, categories }) => {
  const chartRef = useRef<HTMLDivElement | null>(null);
  const { mode } = useThemeMode();
  const refreshMode = () => {
    if (!chartRef.current) {
      return;
    }

    const height = parseInt(getCSS(chartRef.current, "height"));

    const chart = new ApexCharts(
      chartRef.current,
      getChartOptions(isCumulative, data , mode, categories)
    );
    if (chart) {
      chart.render();
    }

    return chart;
  };

  useEffect(() => {
    const chart = refreshMode();

    return () => {
      if (chart) {
        chart.destroy();
      }
    };
  }, [chartRef, mode, isCumulative, data]);

  return (
    <div
      ref={chartRef}
      id="kt_charts_widget_3_chart"
      style={{ width: "100%" }}
    ></div>
  );
};

export default Index;

function getChartOptions(isCumulative: boolean, data: any , mode:any, categories:any) {
  const borderColor = getCSSVariableValue("--kt-gray-200");
  return {
    series: map(data, (item) => {
      return {
        name: get(item, "label"),
        data: get(item, "data", [])?.map((el:any) => {return el.value}),
      };
    }),
    colors: map(data, (item) => get(item, "color")),
    chart: {
      height: 240,
      type: isCumulative ? "area" : "bar",
      toolbar: {
        show: false,
      },
    },
    dataLabels: {
      enabled: false,
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: "36px",
      },
    },
    legend: {
      show: false,
    },
    stroke: {
      curve: "smooth",
      show: isCumulative && true,
      width: 1,
      colors: map(data, (item) => get(item, "color")),
    },
    grid: {
      borderColor: borderColor,
      // strokeDashArray: 4,
      yaxis: {
        lines: {
          show: true,
        },
      },
    },
    xaxis: {
      categories: categories,
      axisBorder: {
        show: true,
        color: borderColor,
        
      },
      axisTicks: {
        show: true,
        color:borderColor,
      },

      labels: {
        show: true,
        style: {
          colors: isEqual(mode, "light") ? "#3F4254" : "#FFFFFFA6",
          fontSize: "12px",
        },
      },
      crosshairs: {
        position: "front",
        stroke: {
          colors: isEqual(mode, "light") ? "#3F4254" : "#FFFFFFA6",
          width: 1,
          dashArray: 3,
        },
      },
      tooltip: {
        enabled: true,
        formatter: undefined,
        offsetY: 0,
        style: {
          fontSize: "12px",
        },
      },
    },
    yaxis: {
      show: true,
      labels: {
        style: {
          colors: isEqual(mode, "light") ? "#3F4254" : "#FFFFFFA6",
          fontSize: "12px",
        },
      },
    },
  };
}
