import React, { useEffect, useState } from "react";
import Breadcrumb from "../components/breadcrumb";
import { TabList, Tabs, Tab, TabPanel } from "react-tabs";
import { useIntl } from "react-intl";
import { isEqual } from "lodash";
import { FullModal } from "../../../components/modal";
import { useSearchParams } from "react-router-dom";
import UsageTracking from "../components/UsageTracking";
import { Billing } from "../components/Billing";
import { useAuth } from "../../auth";
import useGetBillingData from "../../../hooks/useGetBillingData";
import { Spinner } from "../../widgets/components/General/Spinner";

const tabsToIndex: { [key: string]: number } = {
    'subscription': 0,
    'usage-quota': 1,
}
const indexToTabs: { [key: number]: string } = {
    0: 'subscription',
    1: 'usage-quota',
}
const Index = () => {
    const { formatMessage } = useIntl();
    const [searchParams, setSearchParams] = useSearchParams()
    const [activeTab, setActiveTab] = useState<number>(tabsToIndex[searchParams.get('tab') || 'subscription']);
    const { billingData, personalityId, currencyBill, currentState, selected, setSelected, setCurrencyBill, setCurrentState, setBillingData } = useAuth()
    const { getBillingDetailsAPI } = useGetBillingData(personalityId!);
    const [loading, setLoading] = useState<boolean>(false);
    useEffect(() => {
        searchParams.set('tab', '' + indexToTabs[activeTab])
        setSearchParams(searchParams)
    }, [activeTab])

    useEffect(()=>{
        let tab = searchParams.get('tab')
        if(tab){
          setActiveTab(tabsToIndex[tab])
        }
      },[searchParams])

      useEffect(() => {
        if (billingData && billingData.subscription_items) {
          const plan: any =
            billingData?.subscription_items[0].item_price_id?.split("-");
            const parts = plan.splice(-2)
          if (plan) {
            setSelected(plan.join('-'));
            setCurrencyBill(parts[0]);
            setCurrentState(parts[1]);
          }
        }
      }, [billingData]); // eslint-disable-line react-hooks/exhaustive-deps
      useEffect(() => {
        const getBillingDetails = async () => {
            setLoading(true)
            let data = await getBillingDetailsAPI()
            setBillingData(data);
            setLoading(false)
        };
        if (personalityId) {
            getBillingDetails();
        }
      }, [personalityId]); 

    return (
        <div className={"md:px-5 px-3 py-3 "}>
            <div
                className={
                    "flex flex-col md:flex-row md:items-end md:justify-between gap-y-3.5 mb-0 md:mb-0"
                }
            >
                <Breadcrumb />
            </div>
            <Tabs
                defaultIndex={activeTab}
                selectedIndex={activeTab}
                className="font-size-13"
                selectedTabClassName="bg-primary"
                onSelect={(index) => {
                    setSearchParams({})
                    setActiveTab(index)
                }}
            >
                <TabList
                    className={
                        " text-[13px] leading-[20px] font-normal primary-text setting-tabs overflow-x-auto overflow-y-hidden whitespace-nowrap  border-main border-b"
                    }
                >
                    <Tab>{formatMessage({ id: "Subscription" })}</Tab>
                    <Tab>{formatMessage({ id: "Usage Quota" })}</Tab>
                </TabList>
                <TabPanel
                    className="md:mr-0 mt-4 relative"
                >
                  {loading && <Spinner/>}
                    <Billing
                        key={1}
                        billingData={billingData}
                        getBillingDetails={getBillingDetailsAPI}
                        currencyBill={currencyBill}
                        selected={selected}
                        currentState={currentState}
                        companyId={personalityId}
                    />
                </TabPanel>
                <TabPanel
                    className="md:mr-0 mt-4"
                >
                    <UsageTracking />
                </TabPanel>
            </Tabs>
        </div>
    );
};

export default Index;
