import React from "react";
import { ToolTipUI } from "../../../widgets/components/UI/ToolTipUI";
import { LineChart } from "../../../../components/line-chart";
import { useIntl } from "react-intl";

const Index: React.FC<any> = ({
  heading,
  count,
  description,
  tooltipText,
  linkText = "Check all users",
  onClickLink,
  increase = "2.6",
}) => {
  const { formatMessage } = useIntl();
  return (
    <div className={"chart-card rounded"}>
      <div className="flex flex-col justify-between h-full">
        <div className={"px-[20px] pt-[20px] min-h-[114px] flex-1"}>
          <h3 className="flex items-center  text-[14px] leading-[20px] primary-text mb-[8px]">
            {formatMessage({ id: heading })}
            <ToolTipUI
              tooltipText={formatMessage({
                id: tooltipText,
              })}
            />
          </h3>
          <div className="flex flex-wrap items-center gap-[8px] mb-[4px]">
            <h1 className="mb-0 text-[28px] leading-[36px] secondary-text font-semibold flex items-center gap-[4px]">
              {!count && (
                <span className={"text-[14px] leading-[20px] primary-text"}>
                  $
                </span>
              )}
              {count}
            </h1>
            {increase && (
              <p className="mb-0 flex items-center text-[14px] leading-[20px] secondary-text font-normal">
                <svg
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    opacity="0.5"
                    d="M10.7617 14.2644V6.1677C10.7617 5.76119 10.4205 5.43164 9.9996 5.43164C9.57869 5.43164 9.23748 5.76119 9.23748 6.1677V14.2644C9.23748 14.6709 9.57869 15.0005 9.9996 15.0005C10.4205 15.0005 10.7617 14.6709 10.7617 14.2644Z"
                    fill="#3DD883"
                  />
                  <path
                    d="M10.4311 7.31969L13.6201 10.3996C13.9357 10.7045 14.4476 10.7045 14.7632 10.3996C15.0789 10.0947 15.0789 9.60039 14.7632 9.29551L10.5389 5.21559C10.2413 4.92814 9.75872 4.92814 9.46111 5.21559L5.23676 9.29551C4.92108 9.60039 4.92108 10.0947 5.23676 10.3996C5.55244 10.7045 6.06426 10.7045 6.37994 10.3996L9.56887 7.31969C9.80696 7.08973 10.193 7.08973 10.4311 7.31969Z"
                    fill="#3DD883"
                  />
                </svg>
                {increase}%
              </p>
            )}
          </div>
          <p
            className={
              "text-[14px] leading-[20px] font-normal primary-text flex gap-[8px]"
            }
          >
            <span>{description}</span>
          </p>
        </div>
        <div className="">
          <div className={"mx-[-10px] !overflow-hidden"}>
            <LineChart withData={true} className={""} />
          </div>

          <div className="flex justify-end px-[8px] pb-[8px]">
            {linkText ?
            <a
              className={
                "text-[12px] leading-[18px] font-medium main-text  flex items-center gap-1 cursor-pointer"
              }
              onClick={onClickLink}
            >
              {formatMessage({ id: linkText })}
              <svg
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  opacity="0.3"
                  d="M4.00065 7.33268C3.63246 7.33268 3.33398 7.63116 3.33398 7.99935C3.33398 8.36754 3.63246 8.66602 4.00065 8.66602H12.0007C12.3688 8.66602 12.6673 8.36754 12.6673 7.99935C12.6673 7.63116 12.3688 7.33268 12.0007 7.33268H4.00065Z"
                  className={"primary-icon-fill"}
                />
                <path
                  d="M7.52794 11.5279C7.26759 11.7883 7.26759 12.2104 7.52794 12.4708C7.78829 12.7311 8.2104 12.7311 8.47075 12.4708L12.4708 8.47075C12.7231 8.21837 12.732 7.81198 12.4908 7.54887L8.82412 3.54887C8.57532 3.27746 8.15361 3.25912 7.8822 3.50791C7.61079 3.75671 7.59245 4.17842 7.84125 4.44983L11.0766 7.9793L7.52794 11.5279Z"
                  className={"primary-icon-fill"}
                />
              </svg>
            </a> : <div className={'h-[18px]'}></div>
            }

          </div>
        </div>
      </div>
    </div>
  );
};

export default Index;
