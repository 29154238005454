import React, { useEffect, useRef, useState } from "react";
import MoreMenu from "../../../partials/layout/header-menus/MoreMenu";
import { useIntl } from "react-intl";

export default function NavLinks() {
  const { formatMessage } = useIntl();
  const [moreOpen, setMoreOpen] = useState<boolean>(false);
  const menuRef = useRef<HTMLDivElement>(null);

  // Function to handle clicks outside the MoreMenu
  const handleOutsideClick = (event: MouseEvent) => {
    if (menuRef.current && !menuRef.current.contains(event.target as Node)) {
      setMoreOpen(false);
    }
  };

  // Add event listener when component mounts
  useEffect(() => {
    document.addEventListener("mousedown", handleOutsideClick);
    return () => {
      // Clean up the event listener when component unmounts
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, []);
  return (
    <div className="position-relative">
      <div
        className="cursor-pointer"
        // data-kt-menu-trigger="click"
        // data-kt-menu-placement="bottom-end"
        // data-kt-menu-flip="top-end"
        onClick={() => setMoreOpen((open) => !open)}
      >
        <div className="flex flex-row  align-items-center secondary-text text-[13px] leading-[18px] font-weight-500">
          {formatMessage({ id: "More" })}

          <svg
            className="ml-2"
            width="8"
            height="4"
            viewBox="0 0 8 4"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M4.42111 3.93537C4.22088 4.03839 3.9637 4.0191 3.78597 3.87137L0.177181 0.871531C-0.046034 0.685982 -0.0602611 0.369511 0.145404 0.164681C0.351069 -0.0401487 0.698744 -0.055779 0.921959 0.129771L4.14137 2.80594L7.06417 0.155861C7.27904 -0.0389585 7.62686 -0.0383486 7.84105 0.157221C8.05524 0.352791 8.05469 0.669272 7.83982 0.864091L4.54449 3.85194C4.50704 3.8859 4.46541 3.91371 4.42111 3.93537Z"
              className={'icon-main-color'}
              fillOpacity="0.65"
            />
          </svg>
        </div>
      </div>

      {/* Render the MoreMenu component when moreOpen is true */}
      {moreOpen && (
        <div
          ref={menuRef} 
          className="position-absolute top-[26px] left-[-44px] more-category-dropdown "
          style={{ zIndex: 999 }}
        >
          <MoreMenu isDark={true} />
        </div>
      )}
    </div>
  );
}
