import React, { useEffect, useState } from "react";
import Breadcrumb from "../components/breadcrumb";
import Trainbychat from "./trainbychat";
import Trainbylink from "./trainbylink";
import History from "./history";
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
import Trainbydata from "./trainbydata";
import { useIntl } from "react-intl";
import { useSearchParams } from "react-router-dom";

const tabsToIndex: { [key: string]: number } = {
  'train-by-chats': 0,
  'train-by-public-links': 1,
  'train-by-private-files': 2,
  'history': 3
}
const indexToTabs: { [key: number]: string } = {
  0: 'train-by-chats',
  1: 'train-by-public-links',
  2: 'train-by-private-files',
  3: 'history'
}
const Index = () => {
  const { formatMessage } = useIntl();
  const [searchParams, setSearchParams] = useSearchParams()
  const [activeTab, setActiveTab] = useState<number>(tabsToIndex[searchParams.get('tab') || 'train-by-chats']);
  useEffect(() => {
    searchParams.set('tab', '' + indexToTabs[activeTab])
    setSearchParams(searchParams)
  }, [activeTab])

  useEffect(() => {
    let tab = searchParams.get('tab')
    if (tab) {
      setActiveTab(tabsToIndex[tab])
    }
  }, [searchParams])

  return (
    <div className="px-3 md:px-5 py-3">
      <Breadcrumb />
      <Tabs
        defaultIndex={activeTab}
        selectedIndex={activeTab}
        onSelect={(index) => {
          setActiveTab(index)
        }}
        className="font-size-13"
        selectedTabClassName="bg-primary">
        <TabList
          className={
            " text-[13px] leading-[20px] font-normal primary-text setting-tabs overflow-x-auto overflow-y-hidden whitespace-nowrap  border-main border-b"
          }
        >
          <Tab>{formatMessage({ id: "Train by Chat" })}</Tab>
          <Tab>{formatMessage({ id: "Train by Public Links" })}</Tab>
          <Tab>{formatMessage({ id: "Train by Private Data" })}</Tab>
          <Tab>{formatMessage({ id: "History" })}</Tab>
        </TabList>
        <TabPanel>
          <Trainbychat />
        </TabPanel>
        <TabPanel>
          <Trainbylink />
        </TabPanel>
        <TabPanel>
          <Trainbydata />
        </TabPanel>
        <TabPanel>
          <History />
        </TabPanel>
      </Tabs>
    </div>
  );
};

export default Index;
