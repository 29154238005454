import { createRoot } from "react-dom/client";
// Axios
import axios from "axios";
import { Chart, registerables } from "chart.js";
import { QueryClient, QueryClientProvider } from "react-query";
import { MetronicI18nProvider } from "./_metronic/i18n/Metronici18n";

import "./_metronic/assets/sass/style.scss";
import "./_metronic/assets/sass/plugins.scss";
import "./_metronic/assets/sass/style.react.scss";
import { AppRoutes } from "./app/routing/AppRoutes";
import { AuthProvider, setupAxios } from "./app/modules/auth";

import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/bundle";
import "swiper/css/a11y";
import "swiper/css/autoplay";
import "swiper/css/effect-cards";
import "swiper/css/free-mode";
import "swiper/css/grid";
import "./app/assets/style.scss";
import "react-modern-drawer/dist/index.css";
import 'react-date-range/dist/styles.css'; // main css file
import 'react-date-range/dist/theme/default.css'; // theme css file
import 'react-day-picker/dist/style.css';
import "react-datepicker/dist/react-datepicker.css";
import TagManager from "react-gtm-module"

setupAxios(axios);
Chart.register(...registerables);

if(process.env.REACT_APP_ENVIRONMENT == 'production'){
  TagManager.initialize({
    gtmId: process.env.REACT_APP_GTM_ID ?? '',
  })
}else{
  TagManager.initialize({
    gtmId: process.env.REACT_APP_GTM_ID ?? '',
    auth: 'ClQqLfsvmbhZ--l_0Do0xQ',
    preview: 'env-13'
  })
}

if(process.env.REACT_APP_ENVIRONMENT == 'production'){
  // disable console.log in production
  console.log = function () {}
}

const queryClient = new QueryClient();
const container = document.getElementById("root");
if (container) {
  createRoot(container).render(
    <QueryClientProvider client={queryClient}>
      <MetronicI18nProvider>
        <AuthProvider>
          <AppRoutes />
        </AuthProvider>
      </MetronicI18nProvider>
    </QueryClientProvider>
  );
}
