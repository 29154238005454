import React, { useEffect, useState } from "react";

import Breadcrumb from "../components/breadcrumb/settings-team-breadcrumb"
// import Trainbychat from "./trainbychat";
// import Trainbylink from "./trainbylink";
// import History from "./history";
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
// import Trainbydata from "./trainbydata";
import { useIntl } from "react-intl";
import Behavior from "./behavior";
import AvatarVisuals from "./avatar";
import VoiceSettings from "./voice";
import { useSearchParams } from "react-router-dom";
import { AiPersonality } from "../components/AiPersonality";
import SeoSettings from "../components/SeoSettings";
import { Team } from "../components/Team";

const Index = () => {
    const { formatMessage } = useIntl();
    const [searchParams, setSearchParams] = useSearchParams()
    const allTabs = [
        'personality-profile',
        'seo',
        'team'
    ]
    const [key, setKey] = useState("M" + Math.random().toString())
    const [getApiLoading, setApiLoading] = useState(false);
    const [imgName, setImgName] = useState<string>();
    const [active, setActive] = useState<number>(0)
    useEffect(() => {
        searchParams.set("tab",allTabs[active])
        setSearchParams(searchParams)
    }, [active])

    useEffect(()=>{
            setActive(allTabs.indexOf(searchParams.get('tab')||'personality-profile'))
    },[searchParams])

    return (
        <div className="md:px-5 px-3 py-3" key={key}>
            <Breadcrumb />
            <Tabs className="font-size-13" selectedTabClassName="bg-primary"
                defaultIndex={active}
                selectedIndex={active}
                onSelect={(index: number) => setActive(index)}
            >
                <TabList
                    className={
                        " text-[13px] leading-[20px] font-normal primary-text setting-tabs overflow-x-auto overflow-y-hidden whitespace-nowrap  border-main border-b"
                    }
                >
                    <Tab>{formatMessage({ id: "Personality Profile" })}</Tab>
                    <Tab>{formatMessage({ id: "SEO" })}</Tab>
                    <Tab>{formatMessage({ id: "Team" })}</Tab>
                    {/* <Tab>{formatMessage({ id: "History" })}</Tab> */}
                </TabList>
                <TabPanel>
                    <AiPersonality
                        key={1}
                        getApiLoading={getApiLoading}
                        setImgName={setImgName}
                        imgName={imgName}
                    // countryOptions={countryOptions}
                    />
                </TabPanel>
                <TabPanel>
                    <SeoSettings />
                </TabPanel>
                <TabPanel>
                    <Team key={1}/>
                </TabPanel>
                {/* <TabPanel>
          <History />
        </TabPanel> */}
            </Tabs>
        </div>
    );
};

export default Index;
