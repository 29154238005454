import React, { useEffect, useState } from 'react'
import ChatWidget from './ChatWidget'
import { useSearchParams } from 'react-router-dom'
import { getWidgetByIdForChat } from './core/_request'
import useGTM from '../hooks/useGTM';
import { GTMEvent } from '../hooks/gtm_helpers';
import { useAuth } from '../modules/auth';

export default function WidgetControl() {
  const [loading, setLoading] = useState(true);
  // const [visible, setVisible] = useState(false)
  const [searchParams, setSearchParams] = useSearchParams()
  const [widget, setWidget] = useState<any>(null)
  const widgetId = searchParams.get('wid')
  const [fault, setFault] = useState<boolean>(false)
  const [inactive, setInactive] = useState<boolean>(false)
  const {dataLayerPush} = useGTM()
  const {setPersonalityInView} = useAuth()
  useEffect(() => {
    async function get(widgetId: string) {
      setLoading(true)
      const { data: { success, data } } = await getWidgetByIdForChat(widgetId)
      if (success) {
        if(!data){
          setFault(true)
          return;
        }
        const { personality, ...rest } = data
        setPersonalityInView(personality)
        dataLayerPush(GTMEvent.ChatWidgetLoadSuccessful, {personality_name:personality.name, personality_url:`${window.location.origin}/p/${personality.username}}`, widget_id: rest.widgetId});
        if(data.isActive){
        setWidget(rest)
        }else {
          setInactive(true)
          setWidget(rest)
        }
      } else {
        setFault(true)
      }
      setLoading(false)
    }
    if (widgetId) {
      try {
        get(widgetId);
      } catch (err) {
        setFault(true)
      }
    } else {
      setLoading(false)
      // setVisible(true)
      setFault(true)
    }
  }, [])
  return (
    <div className='absolute right-0 bottom-0 w-full h-full overflow-hidden'>
      {loading ? null : <>
        {/* {!visible && <div style={{backgroundColor: widget?.config?.primaryColor || '#c2d24b'}} onClick={() => setVisible(true)} className={`absolute right-2 bottom-2 w-16 h-16 rounded-full border border-[red] text-center`}></div>} */}
        <ChatWidget widget={widget}  loading={loading} fault={fault} inactive={inactive} />
      </>}
    </div>
  )
}
