import React from "react";
import avatarImage from "../../../../assets/images/profile/avatar-image.png";
import avatarImage2 from "../../../../assets/images/profile/avatar-image-2.png";
import Avatar from "../../components/ai-user-profile/avatar";
import { get, map } from "lodash";
import Card from "../../components/card"

const Followings = ({personalities} : {personalities: any}) => {
  
  // const aiPersons = personalities.map((el:any) => {return {
  //     id: 1,
  //     name: el.name,
  //     username: el.username,
  //     job: el.industry,
  //     description:
  //       el.description,
  //     image: el.image,
  //     isPremium: false,
  //     likes: 0,
  // }})
  // const aiPersons = [
  //   {
  //     id: 1,
  //     name: "Shahrukh Khan",
  //     job: "Bollywood Superstar",
  //     description:
  //       "Hi there, I'm Shahrukh Khan, a romantic at heart and an actor",
  //     image: avatarImage,
  //     isPremium: true,
  //     likes: 3489,
  //   },
  //   {
  //     id: 2,
  //     name: "Gauri Khan",
  //     job: "Bollywood Producer & Designer",
  //     description: "Hi there, I'm Gauri Khan, producer & designer by passion. ",
  //     image: avatarImage2,
  //     isPremium: false,
  //     likes: 3489,
  //   },
  // ];
  return (
    <div className={"grid grid-cols-12 gap-4 min-h-[80vh]"}>
      {map(personalities, (el) => (
        <div
          className={"col-span-12 lg:col-span-6  xl:col-span-4 2xl:col-span-3"}
        >
          {/* <Avatar data={el} /> */}
          <Card 
            id={get(el, 'personalityId')}
            slug={get(el, 'username')}
            image={get(el, "image")}
            name={get(el, "name")}
            occupation={get(el, "category")}
            isVerified={get(el, "isVerified")}
            followers={get(el, "followers")}
            likes={get(el, "likes")}
            owners={get(el,"users")}
            interactions={get(el,"interactions")}
          />
        </div>
      ))}
    </div>
  );
};

export default Followings;
