import React, {useState} from 'react';
import {OverlayTrigger, Popover} from "react-bootstrap";
import {get, isEqual, map} from "lodash";
import CustomDatePicker from "../../../dashboard/components/custom-date-picker";
import moment from "moment/moment";
import {useIntl} from "react-intl";

const Index = () => {
    const { formatMessage } = useIntl();
    const [dateFrom, setDateFrom] = useState<any>({
        label: "Today",
        date: new Date(),
    });
    const [durationInDays,setDurationInDays] = useState<number>(7)
    const [logsFromDate,setLogsFromDate] = useState<any>(dateFrom.date)
    const [comparedData, setComparedData] = useState({
        title: "Pervious period",
        value: "Jun 26 - Jul 2",
    });

    const comparedDate = [
        {
            title: "Pervious period",
            child: "Jun 26 - Jul 2",
            value: "Jun 26 - Jul 2",
        },
        {
            title: "Pervious month",
            child: "Mar 10 - Jul 2",
            value: "Mar 10 - Jul 2",
        },
        {
            title: "Pervious quarter",
            child: "Jun 26 - Jul 2",
            value: "Jun 18 - Jul 24",
        },
        {
            title: "Custom",
            value: "custom",
        },
        {
            title: "Pervious period",
            value: "No comparision",
        },
    ];

    const dateFromSelect = [
        { title: "Today", amount: 0, unit: "t" },
        { title: "Last 7 days", amount: 7, unit: "d" },
        { title: "Last 4 weeks", amount: 4, unit: "w" },
        { title: "Last 3 months", amount: 3, unit: "m" },
        { title: "Last 12 months", amount: 12, unit: "m" },
    ];

    const dateFromFunc = (amount: number, unit: string = "d", label: string) => {
        if (isEqual(unit, "t")) {
            setDateFrom({ label: label, date: new Date() });
        }
        if (isEqual(unit, "d")) {
            setDateFrom({
                label: label,
                date: moment().subtract(amount, "d").format("YYYY-MM-DD"),
            });
        }
        if (isEqual(unit, "w")) {
            setDateFrom({
                label: label,
                date: moment()
                    .subtract(amount, "week")
                    .startOf("week")
                    .format("YYYY-MM-DD"),
            });
        }
        if (isEqual(unit, "m")) {
            setDateFrom({
                label: label,
                date: moment()
                    .subtract(amount, "months")
                    .endOf("month")
                    .format("YYYY-MM-DD"),
            });
        }
    };

    return (
        <div>
            <div
                className={
                    "flex flex-wrap md:justify-end items-center gap-x-[8px] gap-y-2"
                }
            >
                <div className={"w-full md:w-auto"}>
                    <OverlayTrigger
                        trigger={"click"}
                        placement={"bottom"}
                        rootClose={true}
                        overlay={
                            <Popover>
                                <ul
                                    className={
                                        "child:flex child:gap-x-5 child:px-2 child:py-1 child:transition-all child:duration-300 child-hover:bg-[#C2D24B1A] child-hover:text-[#C2D24B] child:cursor-pointer"
                                    }
                                >
                                    {map(dateFromSelect, (item) => (
                                        <li
                                            onClick={() => {
                                                dateFromFunc(
                                                    get(item, "amount"),
                                                    get(item, "unit"),
                                                    get(item, "title")
                                                );
                                            }}
                                            key={Math.random().toString()}
                                        >
                      <span className={"w-44 flex items-center"}>
                        <span className={"w-5 mr-2"}>
                          {isEqual(
                              get(item, "title"),
                              get(dateFrom, "label")
                          ) && (
                              <svg
                                  width="18"
                                  height="18"
                                  viewBox="0 0 18 18"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                              >
                                  <path
                                      d="M6.66667 14L2 9.31915L3.63333 7.68085L6.66667 10.7234L14.3667 3L16 4.6383L6.66667 14Z"
                                      fill="#C2D24B"
                                  />
                              </svg>
                          )}
                        </span>
                          {get(item, "title")}
                      </span>
                                        </li>
                                    ))}
                                </ul>
                            </Popover>
                        }
                    >
                        <select
                            defaultValue={dateFrom}
                            placeholder={formatMessage({ id: "please select address" })}
                            className={
                                "form-control search-input-table form-select  min-w-[180px] !h-[34px] py-0  !pt-0.5 px-4 primary-text !font-medium"
                            }
                        >
                            <option hidden defaultValue={comparedData.value}>
                                {dateFrom.label}
                            </option>
                        </select>
                    </OverlayTrigger>
                </div>
                <CustomDatePicker dateFrom={dateFrom.date} setDurationInDays={setDurationInDays} setLogsFromDate={setLogsFromDate} />
                {formatMessage({ id: "Compared to" })}
                <div className={"flex-grow md:flex-grow-0"}>
                    <OverlayTrigger
                        trigger={"click"}
                        placement={"bottom"}
                        rootClose={true}
                        overlay={
                            <Popover>
                                <div className={"py-1 flex text-[14px] primary-text"}>
                                    <ul
                                        className={
                                            "child:flex child:gap-x-5 child:px-2 child:py-1 child:transition-all child:duration-300 child-hover:bg-[#C2D24B1A] child-hover:text-[#C2D24B] child:cursor-pointer"
                                        }
                                    >
                                        {map(comparedDate, (item) => (
                                            <li
                                                onClick={() =>
                                                    setComparedData({
                                                        title: get(item, "title"),
                                                        value: get(item, "value"),
                                                    })
                                                }
                                                key={Math.random().toString()}
                                            >
                        <span className={"w-44 flex items-center"}>
                          <span className={"w-5 mr-2"}>
                            {isEqual(
                                get(item, "value"),
                                get(comparedData, "value")
                            ) && (
                                <svg
                                    width="18"
                                    height="18"
                                    viewBox="0 0 18 18"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        d="M6.66667 14L2 9.31915L3.63333 7.68085L6.66667 10.7234L14.3667 3L16 4.6383L6.66667 14Z"
                                        fill="#C2D24B"
                                    />
                                </svg>
                            )}
                          </span>
                            {get(item, "title")}
                        </span>
                                                <span className={"whitespace-nowrap"}>
                          {get(item, "child")}
                        </span>
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                            </Popover>
                        }
                    >
                        <select
                            defaultValue={comparedData.value}
                            placeholder={formatMessage({ id: "please select address" })}
                            className={
                                "form-control search-input-table form-select min-w-[180px] !h-[34px] py-0  !pt-0.5 px-4 !font-medium"
                            }
                        >
                            <option hidden defaultValue={comparedData.value}>
                                {get(comparedData, "title")}
                            </option>
                        </select>
                    </OverlayTrigger>
                </div>
            </div>
        </div>
    );
};

export default Index;