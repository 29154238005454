import React, { useEffect, useState } from "react";

import Breadcrumb from "../components/breadcrumb";

import { Tab, TabList, Tabs } from "react-tabs";
import { useIntl } from "react-intl";

import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
import SocialPosts from "./SocialPosts";
import SocialMediaAccounts from "./SocialMediaAccounts";
import MessengerBots from "./MessengerBots";

const tabsToIndex: { [key: string]: number } = {
  "social-posts": 0,
  "social-media-accounts": 1,
  "messenger-bots": 2,
};

const Index = () => {
  const { formatMessage } = useIntl();
  const { pathname } = useLocation()
  const [activeTab, setActiveTab] = useState<number>(tabsToIndex[pathname.split('/')[2]]);

  const navigate = useNavigate()

  useEffect(() => {
    if (!pathname) {
      return
    }
    let path = pathname.split('/')[2]
    setActiveTab(tabsToIndex[path])
  }, [pathname]);
  return (
    <div className="md:px-5 px-3 py-3">
      <Breadcrumb />
      <Tabs
        defaultIndex={activeTab}
        selectedIndex={activeTab}
        className="font-size-13"
        selectedTabClassName="bg-primary"
        onSelect={(index) => {
          setActiveTab(index);
        }}
      >
        <TabList
          className={
            " text-[13px] leading-[20px] font-normal primary-text setting-tabs overflow-x-auto overflow-y-hidden whitespace-nowrap  border-main border-b"
          }
        >
          <Tab onClick={()=> navigate("social-posts")}>{formatMessage({ id: "Social Posts" })}</Tab>
          <Tab onClick={()=> navigate("social-media-accounts/all")}>{formatMessage({ id: "Social Media Accounts" })}</Tab>
          <Tab onClick={()=> navigate("messenger-bots")}>{formatMessage({ id: "Messengers Bots" })}</Tab>
          {/* <Tab>{formatMessage({ id: "History" })}</Tab> */}
        </TabList>
        <Routes>
          <Route path="social-posts" element={<SocialPosts />} />
          <Route path="social-media-accounts/:type" element={<SocialMediaAccounts />} />
          <Route path="messenger-bots" element={<MessengerBots />} />
        </Routes>
        {/* <TabPanel>
          <SocialPosts />
        </TabPanel>
        <TabPanel>
          <SocialMediaAccounts />
        </TabPanel>
        <TabPanel>
          <MessengerBots />{" "}
        </TabPanel> */}
      </Tabs>
    </div>
  );
};

export default Index;
