import React from "react";
import { useIntl } from "react-intl";
import searchDummy1 from "../../../../_metronic/assets/images/images/search-dummy-img1.png";
import { ComponentSpinner } from "../../../../app/modules/widgets/components/General/Spinner";
import { Link } from "react-router-dom";
import { getCdnUrl } from "../../../../app/core/_util";

export default function NavSearchModal({ apiLoading, personalities, ...props }: { apiLoading: any, personalities: any }) {
  const { formatMessage } = useIntl();

  return (
    <div
      className="menu menu-sub menu-sub-dropdown w-200px show p-[8px]"

    >
      <p className="text-[12px] secondary-text leading-[18px] mb-[6px]">
        {formatMessage({ id: "Popular" })}
      </p>
      {
        apiLoading ?
          <ComponentSpinner size={'sm'} />
          :
          <ul className="flex flex-col  ">
            {
              personalities &&
              personalities.map((el: any) => (
                <Link to={`/p/${el.username}`}>
                <li className="mb-[4px] secondary-bg p-[4px] flex flex-row align-items-center rounded-[2px]">
                  <img
                    src={getCdnUrl(el.image,"width=50,fit=cover,gravity=auto")}
                    alt=""
                    className="mr-[4px] w-[20px] h-[20px] object-cover rounded-[2px]"
                  ></img>
                  <p className="main-text text-[12px] leading-[18px]">
                    {el.name}
                  </p>
                </li>
                </Link>
              ))
            }

          </ul>
      }
      <Link to={'/marketplace/explore'} className="flex flex-row align-items-center text-[#C2D24B] text-[12px] leading-[18px] font-weight-500 mt-[6px]">
        {formatMessage({ id: "See all AI characters" })}

        <svg
          className="ml-[8px]"
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            opacity="0.3"
            d="M4.00065 7.33268C3.63246 7.33268 3.33398 7.63116 3.33398 7.99935C3.33398 8.36754 3.63246 8.66602 4.00065 8.66602H12.0007C12.3688 8.66602 12.6673 8.36754 12.6673 7.99935C12.6673 7.63116 12.3688 7.33268 12.0007 7.33268H4.00065Z"
            fill="#C2D24B"
          />
          <path
            d="M7.52794 11.5279C7.26759 11.7883 7.26759 12.2104 7.52794 12.4708C7.78829 12.7311 8.2104 12.7311 8.47075 12.4708L12.4708 8.47075C12.7231 8.21837 12.732 7.81198 12.4908 7.54887L8.82412 3.54887C8.57532 3.27746 8.15361 3.25912 7.8822 3.50791C7.61079 3.75671 7.59245 4.17842 7.84125 4.44983L11.0766 7.9793L7.52794 11.5279Z"
            fill="#C2D24B"
          />
        </svg>
      </Link>
    </div>
  );
}
