import React, { useEffect } from "react";
import { useIntl } from "react-intl";
import ShareModal from "./ShareModal";
import { useAuth } from "../../../auth";
import { getUserByToken } from "../../../auth/core/_requests";
import useGTM from "../../../../hooks/useGTM";
import { GTMEvent, basePersonalityMeta } from "../../../../hooks/gtm_helpers";
import SharePersonalityModal from "../../containers/ai-profile/modals/SharePersonalityModal";
import ShareUserModal from "../../containers/ai-user-profile/ShareUserModal";
interface Links {
  facebook?: string;
  instagram?: string;
  linkedin?: string;
  twitter?: string;
  // reddit?: string;
  youtube?: string;
  // Add more social media links if needed
}
interface IndexProps {
  links?: Links;
  personality?: any;
  owner?: any;
  admin?: any;
  user?:any;
}
const Index: React.FC<IndexProps> = ({ links,personality,owner,admin,user }) => {
  const { formatMessage } = useIntl();
  const [shareModalShow, setShareModalShow] = React.useState(false);
  const {
    currentUser,
    storePersonalityId,
  } = useAuth();
  const {dataLayerPush} = useGTM()

  const openInDashboard = async () => {
    try {
      storePersonalityId(personality.personalityId);
      localStorage.setItem("personalityId", personality.personalityId);
      await getUserByToken('', personality.personalityId)
      dataLayerPush(GTMEvent.PersonalitySwitchSuccessful, basePersonalityMeta(personality, currentUser?.personalities?.length || 0))
      setTimeout(() => {
        window.location.href = window.location.origin+'/dashboard';
      }, 0);
    } catch (e) {
      dataLayerPush(GTMEvent.PersonalitySwitchFailed)
    }
  }

  useEffect(() => {
    if(window.location.pathname.startsWith('/u') && shareModalShow){
      dataLayerPush(GTMEvent.UserShareModalOpenSuccessful)
    }
    if(window.location.pathname.startsWith('/p') && shareModalShow){
      dataLayerPush(GTMEvent.PersonalityShareModalOpenSuccessful)
    }
  },[shareModalShow])

  return (
    <div className={"flex items-center gap-x-3 my-5"}>
      {links && links.facebook && (
        <a
          className="w-[28px] h-[28px] hashtag border border-main rounded flex items-center justify-center"
          href={links.facebook}
          target="_blank"
          rel="noreferrer"
        >
          <svg
            width="11"
            height="20"
            viewBox="0 0 11 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M2.90174 19.7992V10.6023H0.400024V7.29101H2.90174V4.46271C2.90174 2.24021 4.37951 0.199219 7.78461 0.199219C9.16328 0.199219 10.1827 0.327697 10.1827 0.327697L10.1024 3.41991C10.1024 3.41991 9.06273 3.41007 7.92817 3.41007C6.70024 3.41007 6.50351 3.96015 6.50351 4.87313V7.29101H10.2L10.0392 10.6023H6.50351V19.7992H2.90174Z"
              fill="white"
              fillOpacity="0.8"
            />
          </svg>
        </a>
      )}
      {links && links.instagram && (
        <a
          className="w-[28px] h-[28px] bg-[#1C223F] border !border-[#FFFFFF1A] rounded flex items-center justify-center"
          href={links.instagram}
          target="_blank"
          rel="noreferrer"
        >
          <svg
            width="14"
            height="14"
            viewBox="0 0 14 14"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M6.99825 4.66567C5.71288 4.66567 4.66392 5.71463 4.66392 7C4.66392 8.28537 5.71288 9.33433 6.99825 9.33433C8.28362 9.33433 9.33258 8.28537 9.33258 7C9.33258 5.71463 8.28362 4.66567 6.99825 4.66567ZM13.9995 7C13.9995 6.03335 14.0082 5.07545 13.9539 4.11055C13.8997 2.98979 13.644 1.99512 12.8244 1.17556C12.0031 0.35426 11.0102 0.100338 9.88945 0.0460516C8.9228 -0.00823506 7.9649 0.000520879 7 0.000520879C6.03335 0.000520879 5.07545 -0.00823506 4.11055 0.0460516C2.98979 0.100338 1.99512 0.356011 1.17556 1.17556C0.35426 1.99687 0.100338 2.98979 0.0460516 4.11055C-0.00823506 5.0772 0.000520879 6.0351 0.000520879 7C0.000520879 7.9649 -0.00823506 8.92455 0.0460516 9.88945C0.100338 11.0102 0.356011 12.0049 1.17556 12.8244C1.99687 13.6457 2.98979 13.8997 4.11055 13.954C5.0772 14.0082 6.0351 13.9995 7 13.9995C7.96665 13.9995 8.92455 14.0082 9.88945 13.954C11.0102 13.8997 12.0049 13.644 12.8244 12.8244C13.6457 12.0031 13.8997 11.0102 13.9539 9.88945C14.01 8.92455 13.9995 7.96665 13.9995 7ZM6.99825 10.5917C5.01066 10.5917 3.40657 8.98759 3.40657 7C3.40657 5.01241 5.01066 3.40832 6.99825 3.40832C8.98584 3.40832 10.5899 5.01241 10.5899 7C10.5899 8.98759 8.98584 10.5917 6.99825 10.5917ZM10.737 4.10004C10.273 4.10004 9.89821 3.72529 9.89821 3.26122C9.89821 2.79716 10.273 2.42241 10.737 2.42241C11.2011 2.42241 11.5758 2.79716 11.5758 3.26122C11.576 3.37142 11.5544 3.48056 11.5123 3.58239C11.4702 3.68422 11.4084 3.77675 11.3305 3.85467C11.2525 3.93259 11.16 3.99437 11.0582 4.03647C10.9564 4.07858 10.8472 4.10018 10.737 4.10004Z"
              fill="white"
              fillOpacity="0.8"
            />
          </svg>
        </a>
      )}
      {links && links.linkedin && (
        <a
          className="w-[28px] h-[28px] bg-[#1C223F] border !border-[#FFFFFF1A] rounded flex items-center justify-center"
          href={links.linkedin}
          target="_blank"
          rel="noreferrer"
        >
          <svg
            width="14"
            height="14"
            viewBox="0 0 14 14"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M5.2003 4.8783H7.8001V6.1733C8.1746 5.4285 9.135 4.7593 10.5777 4.7593C13.3434 4.7593 14 6.2419 14 8.9621V14H11.2V9.5816C11.2 8.0325 10.8255 7.1589 9.8721 7.1589C8.5498 7.1589 8.0003 8.1004 8.0003 9.5809V14H5.2003V4.8783ZM0.399 13.881H3.199V4.7593H0.399V13.881ZM3.6001 1.785C3.6002 2.01969 3.55366 2.25206 3.46317 2.46861C3.37268 2.68516 3.24006 2.88156 3.073 3.0464C2.73448 3.38284 2.27627 3.57116 1.799 3.57C1.32257 3.56968 0.865418 3.38184 0.5264 3.0471C0.359948 2.8817 0.22777 2.68508 0.137441 2.4685C0.047111 2.25193 0.000405392 2.01966 0 1.785C0 1.3111 0.189 0.8575 0.5271 0.5229C0.86582 0.187711 1.32317 -0.000209448 1.7997 1.75185e-07C2.2771 1.75185e-07 2.7349 0.1883 3.073 0.5229C3.4104 0.8575 3.6001 1.3111 3.6001 1.785Z"
              fill="white"
              fillOpacity="0.8"
            />
          </svg>
        </a>
      )}
      {links && links.twitter && (
        <a
          className="w-[28px] h-[28px] bg-[#1C223F] border !border-[#FFFFFF1A] rounded flex items-center justify-center"
          href={links.twitter}
          target="_blank"
          rel="noreferrer"
        >
          <svg
            width="18"
            height="14"
            viewBox="0 0 18 14"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M17.4 1.66066C16.7822 1.94128 16.1185 2.1309 15.4208 2.21661C16.1407 1.77503 16.6793 1.08003 16.9361 0.261302C16.2597 0.673137 15.5194 0.963023 14.7475 1.11836C14.2283 0.550172 13.5407 0.173569 12.7914 0.0470186C12.0421 -0.0795316 11.273 0.0510517 10.6035 0.418495C9.934 0.785938 9.40157 1.36968 9.08888 2.0791C8.77618 2.78852 8.70072 3.58391 8.87419 4.3418C7.50368 4.27126 6.16295 3.90611 4.93902 3.27003C3.71509 2.63395 2.6353 1.74117 1.76975 0.649633C1.47379 1.17297 1.30361 1.77973 1.30361 2.42594C1.30328 3.00767 1.44303 3.58049 1.71046 4.09358C1.9779 4.60667 2.36474 5.04416 2.83667 5.36724C2.28935 5.34938 1.75411 5.19778 1.2755 4.92506V4.97056C1.27544 5.78647 1.55076 6.57726 2.05474 7.20877C2.55872 7.84027 3.26031 8.27359 4.04048 8.4352C3.53275 8.57605 3.00044 8.5968 2.48374 8.49587C2.70386 9.19791 3.13262 9.81182 3.71002 10.2516C4.28741 10.6915 4.98452 10.9352 5.70375 10.9487C4.48281 11.9312 2.97495 12.4642 1.42274 12.4618C1.14778 12.4619 0.873052 12.4455 0.599976 12.4125C2.17556 13.451 4.00965 14.0021 5.88281 14C12.2237 14 15.6901 8.61647 15.6901 3.9474C15.6901 3.79571 15.6864 3.6425 15.6797 3.49081C16.354 2.99097 16.936 2.37201 17.3985 1.66293L17.4 1.66066V1.66066Z"
              fill="white"
              fillOpacity="0.8"
            />
          </svg>
        </a>
      )}
      {links && links.youtube && (
        <a
          className="w-[28px] h-[28px] bg-[#1C223F] border !border-[#FFFFFF1A] rounded flex items-center justify-center"
          href={links.youtube}
          target="_blank"
          rel="noreferrer"
        >
          <svg
            width="30"
            height="30"
            viewBox="0 0 30 30"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <rect x="1" y="1" width="28" height="28" rx="4" fill="#1C223F" />
            <path
              d="M15.244 7C15.778 7.003 17.114 7.016 18.534 7.073L19.038 7.095C20.467 7.162 21.895 7.278 22.604 7.475C23.549 7.741 24.291 8.515 24.542 9.497C24.942 11.057 24.992 14.099 24.998 14.836L24.999 14.988V15.162C24.992 15.899 24.942 18.942 24.542 20.501C24.288 21.486 23.545 22.261 22.604 22.523C21.895 22.72 20.467 22.836 19.038 22.903L18.534 22.926C17.114 22.982 15.778 22.996 15.244 22.998L15.009 22.999H14.754C13.624 22.992 8.898 22.941 7.394 22.523C6.45 22.257 5.707 21.483 5.456 20.501C5.056 18.941 5.006 15.899 5 15.162V14.836C5.006 14.099 5.056 11.056 5.456 9.497C5.71 8.512 6.453 7.737 7.395 7.476C8.898 7.057 13.625 7.006 14.755 7H15.244ZM12.999 11.5V18.5L18.999 15L12.999 11.5Z"
              fill="white"
              fillOpacity="0.8"
            />
            <rect
              x="1"
              y="1"
              width="28"
              height="28"
              rx="4"
              stroke="white"
              strokeOpacity="0.1"
            />
          </svg>
        </a>
      )}
      {/* {links && links.reddit && (
        <a
          className="w-[28px] h-[28px] bg-[#1C223F] border !border-[#FFFFFF1A] rounded flex items-center justify-center"
          href={links.reddit}
          target="_blank"
          rel="noreferrer"
        >
          <svg
            width="18"
            height="14"
            viewBox="0 0 18 14"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M17.4 7.09859C17.4 6.11268 16.5613 5.32394 15.5129 5.42254C15.0936 5.42254 14.5694 5.61972 14.2549 5.91549C12.7872 5.02817 11.1098 4.43662 9.32759 4.43662L10.1663 0.690141L12.892 1.28169C12.892 1.97183 13.521 2.46479 14.2549 2.46479C14.9887 2.46479 15.5129 1.87324 15.5129 1.1831C15.5129 0.492958 14.8839 0 14.1501 0C13.6259 0 13.2065 0.295774 12.9969 0.690141L10.0614 0H9.85177C9.74693 0 9.74693 0.0985914 9.74693 0.197183L8.69857 4.43662C6.91636 4.43662 5.23898 4.92958 3.77127 5.91549C3.03742 5.22535 1.88422 5.22535 1.15037 5.91549C0.416512 6.60563 0.416512 7.69014 1.15037 8.38028C1.2552 8.47887 1.46487 8.67606 1.67455 8.67606V9.16901C1.67455 11.831 4.92447 14 9.01308 14C13.1017 14 16.3516 11.831 16.3516 9.16901V8.67606C16.9806 8.38028 17.4 7.78873 17.4 7.09859ZM4.81963 8.38028C4.81963 7.69014 5.44865 7.19718 6.07767 7.19718C6.81152 7.19718 7.3357 7.78873 7.3357 8.38028C7.3357 8.97183 6.70668 9.56338 6.07767 9.56338C5.34381 9.56338 4.81963 9.07042 4.81963 8.38028ZM12.1582 11.6338C11.2146 12.2254 10.1663 12.6197 9.01308 12.5211C7.85988 12.5211 6.81152 12.2254 5.86799 11.6338C5.76316 11.5352 5.76316 11.338 5.86799 11.1408C5.97283 11.0423 6.1825 11.0423 6.28734 11.1408C7.02119 11.6338 7.96472 11.9296 8.90824 11.831C9.85177 11.9296 10.7953 11.6338 11.5291 11.1408C11.634 11.0423 11.8437 11.0423 12.0533 11.1408C12.263 11.2394 12.263 11.4366 12.1582 11.6338ZM11.8437 9.56338C11.1098 9.56338 10.5856 8.97183 10.5856 8.38028C10.5856 7.78873 11.2146 7.19718 11.8437 7.19718C12.5775 7.19718 13.1017 7.78873 13.1017 8.38028C13.2065 9.07042 12.5775 9.56338 11.8437 9.56338Z"
              fill="white"
              fillOpacity="0.8"
            />
          </svg>
        </a>
      )} */}{" "}
      <div
        className="w-auto h-[28px] p-[5px_6px] hashtag border border-main rounded flex items-center justify-center cursor-pointer primary-text"
        onClick={() => setShareModalShow(true)}
      >
        <svg
          className="me-[4px]"
          width="18"
          height="18"
          viewBox="0 0 18 18"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g clipPath="url(#clip0_3311_27880)">
            <path
              d="M6.525 8.025L11.475 5.475M6.525 9.975L11.475 12.525M2.25 9C2.25 9.59674 2.48705 10.169 2.90901 10.591C3.33097 11.0129 3.90326 11.25 4.5 11.25C5.09674 11.25 5.66903 11.0129 6.09099 10.591C6.51295 10.169 6.75 9.59674 6.75 9C6.75 8.40326 6.51295 7.83097 6.09099 7.40901C5.66903 6.98705 5.09674 6.75 4.5 6.75C3.90326 6.75 3.33097 6.98705 2.90901 7.40901C2.48705 7.83097 2.25 8.40326 2.25 9ZM11.25 4.5C11.25 5.09674 11.4871 5.66903 11.909 6.09099C12.331 6.51295 12.9033 6.75 13.5 6.75C14.0967 6.75 14.669 6.51295 15.091 6.09099C15.5129 5.66903 15.75 5.09674 15.75 4.5C15.75 3.90326 15.5129 3.33097 15.091 2.90901C14.669 2.48705 14.0967 2.25 13.5 2.25C12.9033 2.25 12.331 2.48705 11.909 2.90901C11.4871 3.33097 11.25 3.90326 11.25 4.5ZM11.25 13.5C11.25 14.0967 11.4871 14.669 11.909 15.091C12.331 15.5129 12.9033 15.75 13.5 15.75C14.0967 15.75 14.669 15.5129 15.091 15.091C15.5129 14.669 15.75 14.0967 15.75 13.5C15.75 12.9033 15.5129 12.331 15.091 11.909C14.669 11.4871 14.0967 11.25 13.5 11.25C12.9033 11.25 12.331 11.4871 11.909 11.909C11.4871 12.331 11.25 12.9033 11.25 13.5Z"
              className={'icon-stroke-primary'}
              strokeOpacity="0.6"
              strokeWidth="1.4"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </g>
          <defs>
            <clipPath id="clip0_3311_27880">
              <rect width="18" height="18" className={'icon-main-color'} />
            </clipPath>
          </defs>
        </svg>
        {formatMessage({ id: "Share" })}
      </div>
      { window.location.pathname.startsWith('/p') && currentUser && ((owner && currentUser.userId == owner.userId) || (admin && currentUser.userId == admin.userId)) && <div
        className="w-auto h-[28px] p-[5px_6px] hashtag border border-main rounded flex items-center justify-center cursor-pointer primary-text"
        onClick={openInDashboard}
      >
        {formatMessage({ id: "Open in Dashboard" })}
      </div>}
      {window.location.pathname.startsWith('/p') && <SharePersonalityModal
        show={shareModalShow}
        onHide={() => setShareModalShow(false)}
        link={window.location.href}
      />}

      {window.location.pathname.startsWith('/u') && <ShareUserModal
      user={user}
      show={shareModalShow}
      onHide={() => setShareModalShow(false)}
      link={window.location.href}
      />}
    </div>
  );
};

export default Index;
