import React, { useCallback, useEffect, useRef, useState } from "react";
import { useIntl } from "react-intl";
import { ToolTipUI } from "../../widgets/components/UI/ToolTipUI";
import { getInteractionChats } from "../core/_request";
import { useAuth } from "../../auth";
import clsx from "clsx";
import { useDateFormat } from "../../../hooks/useDateFormat";
import { getCdnUrl } from "../../../core/_util";
import userDefault from "../../../assets/images/profile/user-default.png";
import { Modal, Spinner } from "react-bootstrap";
import { EMOJI_REGEX } from "../../../utils";
import { useTimeZone } from "../../../hooks/useTimeZone";
type User = null | Record<string, any>;

interface InteractionChatProps {
  user: User;
  setUser: React.Dispatch<React.SetStateAction<User>>;
}
export default function InteractionChats(props: InteractionChatProps) {
  const { user, setUser } = props;
  const { formatMessage } = useIntl();
  const [isHidden, setIsHidden] = useState(true);
  const [page, setPage] = useState<number>(1);
  const { selectedPersonality } = useAuth();
  const [loading, setLoading] = useState<boolean>(false);
  const [messages, setMessages] = useState<any[]>([]);
  const { getDateValue } = useDateFormat();
  const { getTimeZoneValue } = useTimeZone();
  const [downloading, setDownloading] = useState<boolean>(false);
  const [show, setShow] = useState(true);
  const containerRef = useRef<any>(null);
  const handleClose = () => {
    setIsHidden(false);
    setUser(null);
  };

  async function getChats() {
    setLoading(true);
    const {
      data: { success, data },
    } = await getInteractionChats(
      selectedPersonality.personalityId,
      user?.userId,
      { page }
    );
    if (success) {
      setMessages([...data, ...messages]);
      if (data && data.length === 0) {
        containerRef.current?.removeEventListener("scroll", debounceScroll);
      }
      const chatContainer = containerRef.current;

      if (chatContainer) {
        const { scrollTop, scrollHeight } = chatContainer;
        requestAnimationFrame(() => {
          const newScrollPosition =
            chatContainer.scrollHeight - scrollHeight + scrollTop;
          chatContainer.scrollTop = newScrollPosition;
        });
      }
    }
    setLoading(false);
  }
  useEffect(() => {
    if (!user) return;
    setIsHidden(false);
    getChats();
  }, [page]);

  function checkTopAndFetchChats(e: any) {
    if (e.target.scrollTop <= 200) {
      setPage((page) => page + 1);
    }
  }

  let timeoutRef: NodeJS.Timeout;
  const debounceScroll = useCallback((e: any) => {
    clearTimeout(timeoutRef);
    timeoutRef = setTimeout(() => checkTopAndFetchChats(e), 200);
  }, []);

  useEffect(() => {
    containerRef.current?.addEventListener("scroll", debounceScroll);
    return () =>
      containerRef.current?.removeEventListener("scroll", debounceScroll);
  }, []);

  function exportMessages() {
    setDownloading(true);
    const textToWrite = messages.reduce((acc, msg) => {
      return (
        acc +
        msg?.prompt?.replace(EMOJI_REGEX, "") +
        "\n" +
        msg?.response?.replace(EMOJI_REGEX, "") +
        "\n\n"
      );
    }, "");

    const blob = new Blob([textToWrite], { type: "text/plain" });

    const url = URL.createObjectURL(blob);

    const a = document.createElement("a");
    a.href = url;
    a.download = `${user?.firstName}-${
      selectedPersonality?.name
    }-conversation-${new Date().valueOf()}.txt`;

    a.click();

    URL.revokeObjectURL(url);
    setDownloading(false);
  }
  const personalityImage = selectedPersonality?.image
    ? getCdnUrl(selectedPersonality.image)
    : userDefault;
  const userImage = user?.photo ? getCdnUrl(user?.photo) : userDefault;

  return (
    <div className="interaction-chat-modal">
      <Modal
        show={true}
        onHide={() => setShow(false)}
        dialogClassName="max-w-[100%] m-0 user-ai-chat-modal"
        aria-labelledby="example-custom-modal-styling-title"
      >
        <Modal.Header
          closeButton
          className="p-[16px] tab-container-2 md:min-w-[768px] md:max-w-[768px] md:mx-auto "
        >
          <Modal.Title
            id="example-custom-modal-styling-title"
            className="text-[#FFFFFFCC] text-[16px] leading-[24px]  font-[600]"
          >
            {user?.firstName}'s{" "}
            {formatMessage({
              id: "Interaction with your AI Personality",
            })}
          </Modal.Title>
          <svg
            onClick={handleClose}
            width="32"
            height="32"
            viewBox="0 0 32 32"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M21.6568 8.45943L8.45746 21.6588C7.93676 22.1795 7.93676 23.0237 8.45746 23.5444C8.97816 24.0651 9.82238 24.0651 10.3431 23.5444L23.5424 10.345C24.0631 9.82435 24.0631 8.98013 23.5424 8.45943C23.0217 7.93873 22.1775 7.93873 21.6568 8.45943Z"
              fill="white"
            />
            <path
              opacity="0.5"
              d="M23.5425 21.6578L10.3432 8.45843C9.8225 7.93773 8.97828 7.93773 8.45758 8.45843C7.93688 8.97913 7.93688 9.82335 8.45758 10.3441L21.6569 23.5434C22.1776 24.0641 23.0218 24.0641 23.5425 23.5434C24.0632 23.0227 24.0632 22.1785 23.5425 21.6578Z"
              fill="white"
            />
          </svg>
        </Modal.Header>
        <Modal.Body className="p-0">
          <div className="max-w-[768px] mx-auto tab-container-2 p-[20px]">
            <div
              ref={containerRef}
              className="flex-grow max-h-[calc(100vh-226px)] min-h-[calc(100vh-226px)] md:max-h-[calc(100vh-162px)] md:min-h-[calc(100vh-162px)] overflow-y-auto custom-scroll  flex-col flex pr-[12px] md:pr-[24px] "
            >
              {loading && (
                <div className="flex h-12 justify-content-center align-items-center">
                  <Spinner animation="border" />
                </div>
              )}
              {messages.map(
                ({ id, prompt, response, createdAt, customParams }, index) => {
                  const templateAttr = {};
                  const contentClass = `"d-flex mb-10`;
                  return (
                    <>
                      {(index == 0 ||
                        new Date(createdAt).getDate() !=
                          new Date(
                            messages[index - 1].createdAt
                          ).getDate()) && (
                        <div className="py-4 timeline">
                          <p className={clsx("text-[#FFFFFFCC] ", "mx-auto")}>
                            {getDateValue(createdAt)}
                          </p>
                        </div>
                      )}
                      <>
                        <div
                          key={`${id}-prompt`}
                          className={clsx(
                            "d-flex",
                            contentClass,
                            "mb-10",
                            "justify-content-end"
                          )}
                          {...templateAttr}
                        >
                          <div
                            className={
                              "flex flex-col md:flex-row md:items-start gap-x-8 gap-y-2"
                            }
                          >
                            <div
                              className={clsx(
                                "bg-[#171825] flex items-start gap-x-2 rounded px-[12px] py-[8px] relative"
                              )}
                            >
                              <div className={"flex min-h-[48px] items-center"}>
                                <div
                                  className={clsx(
                                    "secondary-text ",
                                    " text-[14px] overflow-hidden text-break leading-[22px] font-normal max-w-2xl float-right"
                                  )}
                                  data-kt-element="message-text"
                                  dangerouslySetInnerHTML={{
                                    __html: prompt,
                                  }}
                                ></div>
                              </div>
                              <div className="flex items-start">
                                <>
                                  <div className="w-12 h-12 symbol symbol-35px symbol-circle">
                                    <img alt="Pic" src={userImage} />
                                  </div>
                                </>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div
                          key={`${id}-response`}
                          className={clsx(
                            "d-flex",
                            contentClass,
                            "mb-10",
                            "justify-content-start"
                          )}
                        >
                          <div
                            className={
                              "flex flex-col md:flex-row md:items-start gap-x-8 gap-y-2"
                            }
                          >
                            <div
                              className={clsx(
                                "bg-[#2E2F45] rounded p-[12px]  relative "
                              )}
                            >
                              {customParams?.responseEmoji && (
                                <span
                                  className={
                                    "text-[20px] absolute right-1 md:-right-3 -bottom-4"
                                  }
                                >
                                  {customParams.responseEmoji}
                                </span>
                              )}

                              <div className="flex items-start gap-x-2">
                                <div className="flex items-center">
                                  <>
                                    <div className="w-12 h-12 symbol symbol-35px symbol-circle">
                                      <img alt="Pic" src={personalityImage} />
                                    </div>
                                  </>
                                </div>
                                <div
                                  className={clsx(
                                    "secondary-text ",
                                    " text-[14px] overflow-hidden text-break leading-[22px] font-normal max-w-2xl float-right"
                                  )}
                                  data-kt-element="message-text"
                                  dangerouslySetInnerHTML={{
                                    __html: response,
                                  }}
                                ></div>
                              </div>
                              <div className=" w-full text-right text-[#ffffff80] text-[11px] leading-[17px] pl-[44px] mt-[4px]">
                                {getTimeZoneValue(createdAt)},{" "}
                                {getDateValue(createdAt)}
                              </div>
                            </div>
                          </div>
                        </div>
                      </>
                    </>
                  );
                }
              )}
            </div>
            <div className="flex flex-col-reverse md:flex-row gap-y-4  justify-end gap-x-2.5 w-full md:px-[12px] pt-[12px]">
              <button
                className="secondary-btn w-full md:w-auto !text-[14px] !leading-[20px] !font-medium !py-[12px] !px-[16px]  rounded !ml-0 !my-0"
                onClick={handleClose}
              >
                {formatMessage({
                  id: "Close",
                })}
              </button>
              <div
                className="w-full md:w-auto
    }"
              >
                <button
                  type="submit"
                  className="btn md:w-auto h-[44px] btn-primary w-full main-button flex flex-row gap-[8px] flex-grow justify-center items-center"
                  disabled={downloading}
                  onClick={exportMessages}
                >
                  {downloading ? (
                    <Spinner className="w-[20px] h-[20px]" animation="border" />
                  ) : (
                    <svg
                      width="20"
                      height="20"
                      viewBox="0 0 20 20"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g clip-path="url(#clip0_8328_153142)">
                        <path
                          opacity="0.3"
                          d="M14.1665 6.66855C13.7063 6.66855 13.3332 6.29545 13.3332 5.83521C13.3332 5.37498 13.7063 5.00188 14.1665 5.00188H14.9998C16.8408 5.00188 18.3332 6.49426 18.3332 8.33521V15.0019C18.3332 16.8428 16.8408 18.3352 14.9998 18.3352H4.99984C3.15889 18.3352 1.6665 16.8428 1.6665 15.0019V8.32943C1.6665 6.48848 3.15889 4.99609 4.99984 4.99609H5.83318C6.29341 4.99609 6.66651 5.36919 6.66651 5.82943C6.66651 6.28966 6.29341 6.66276 5.83318 6.66276H4.99984C4.07936 6.66276 3.33317 7.40895 3.33317 8.32943V15.0019C3.33317 15.9224 4.07936 16.6685 4.99984 16.6685H14.9998C15.9203 16.6685 16.6665 15.9224 16.6665 15.0019V8.33521C16.6665 7.41474 15.9203 6.66855 14.9998 6.66855H14.1665Z"
                          fill="black"
                        />
                        <path
                          opacity="0.3"
                          d="M9.16683 2.5013C9.16683 2.04106 9.53993 1.66797 10.0002 1.66797C10.4604 1.66797 10.8335 2.04106 10.8335 2.5013L10.8335 10.8346C10.8335 11.2949 10.4604 11.668 10.0002 11.668C9.53993 11.668 9.16683 11.2949 9.16683 10.8346L9.16683 2.5013Z"
                          fill="black"
                        />
                        <path
                          d="M9.99984 2.01315L11.9106 3.92389C12.236 4.24933 12.7637 4.24933 13.0891 3.92389C13.4145 3.59845 13.4145 3.07082 13.0891 2.74538L10.5891 0.245381C10.2637 -0.0800562 9.73602 -0.0800562 9.41058 0.245381L6.91058 2.74538C6.58514 3.07082 6.58514 3.59845 6.91058 3.92389C7.23602 4.24933 7.76366 4.24933 8.08909 3.92389L9.99984 2.01315Z"
                          fill="black"
                        />
                      </g>
                      <defs>
                        <clipPath id="clip0_8328_153142">
                          <rect width="20" height="20" fill="white" />
                        </clipPath>
                      </defs>
                    </svg>
                  )}

                  <span className="text-[14px] font-medium leading-[20px]">
                    {formatMessage({
                      id: "Export as File for Training",
                    })}
                  </span>
                </button>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </div>
    //   <div
    //     className={` absolute  interaction-transition left-0 w-full rounded interaction-chat-box-modal  filter-tab-container  min-h-[74vh] md:min-h-[60vh]   interaction-chat-box-modal  h-full flex flex-col ${
    //       isHidden ? "top-[-100%]" : "top-[0px]"
    //     } `}
    //   >
    //       <div className="flex flex-row  gap-[24px] md:gap-[0px] items-center justify-between border-b border-solid border-[#2E2F45] p-[12px_16px] ">
    //         <div className="flex flex-row items-center  text-[#FFFFFFCC] text-[16px] leading-[24px]  font-[600]">
    //           <span>
    //             {user?.firstName}'s{" "}
    //             {formatMessage({
    //               id: "Interaction with your AI Personality",
    //             })}
    //           </span>
    //         </div>
    //       </div>
    //       <div
    //         ref={containerRef}
    //         className="flex-grow overflow-y-auto custom-scroll pl-[24px] flex-col flex gap-[20px]  pr-[16px] mr-[8px] mt-[12px]"
    //       >
    //         {loading && (
    //           <div className="flex h-12 justify-content-center align-items-center">
    //             <Spinner animation="border" />
    //           </div>
    //         )}
    //         {messages.map(
    //           ({ id, prompt, response, createdAt, customParams }, index) => {
    //             const templateAttr = {};
    //             const contentClass = `"d-flex mb-10`;
    //             return (
    //               <>
    //                 {(index == 0 ||
    //                   new Date(createdAt).getDate() !=
    //                     new Date(messages[index - 1].createdAt).getDate()) && (
    //                   <div className="py-4 timeline">
    //                     <p className={clsx("text-[#FFFFFFCC] ", "mx-auto")}>
    //                       {getDateValue(createdAt)}
    //                     </p>
    //                   </div>
    //                 )}
    //                 <>
    //                   <div
    //                     key={`${id}-prompt`}
    //                     className={clsx(
    //                       "d-flex",
    //                       contentClass,
    //                       "mb-10",
    //                       "justify-content-end"
    //                     )}
    //                     {...templateAttr}
    //                   >
    //                     <div
    //                       className={
    //                         "flex flex-col md:flex-row md:items-start gap-x-8 gap-y-2"
    //                       }
    //                     >
    //                       <div
    //                         className={clsx(
    //                           "bg-[#171825]",
    //                           "flex items-start gap-x-2 rounded px-2 py-3 relative"
    //                         )}
    //                       >
    //                         <div className={"flex min-h-[48px] items-center"}>
    //                           <div
    //                             className={clsx(
    //                               "secondary-text ",
    //                               " text-[14px] overflow-hidden text-break leading-[22px] font-normal max-w-2xl float-right"
    //                             )}
    //                             data-kt-element="message-text"
    //                             dangerouslySetInnerHTML={{
    //                               __html: prompt,
    //                             }}
    //                           ></div>
    //                         </div>
    //                         <div className="flex items-start">
    //                           <>
    //                             <div className="w-12 h-12 symbol symbol-35px symbol-circle">
    //                               <img alt="Pic" src={userImage} />
    //                             </div>
    //                           </>
    //                         </div>
    //                       </div>
    //                     </div>
    //                   </div>
    //                   <div
    //                     key={`${id}-response`}
    //                     className={clsx(
    //                       "d-flex",
    //                       contentClass,
    //                       "mb-10",
    //                       "justify-content-start"
    //                     )}
    //                   >
    //                     <div
    //                       className={
    //                         "flex flex-col md:flex-row md:items-start gap-x-8 gap-y-2"
    //                       }
    //                     >
    //                       <div
    //                         className={clsx(
    //                           "bg-[#2E2F45]",
    //                           "flex items-start gap-x-2 rounded px-2 py-3 relative"
    //                         )}
    //                       >
    //                         {customParams?.responseEmoji && (
    //                           <span
    //                             className={
    //                               "text-[20px] absolute right-1 md:-right-3 -bottom-4"
    //                             }
    //                           >
    //                             {customParams.responseEmoji}
    //                           </span>
    //                         )}
    //                         <div className="flex items-center">
    //                           <>
    //                             <div className="w-12 h-12 symbol symbol-35px symbol-circle">
    //                               <img alt="Pic" src={personalityImage} />
    //                             </div>
    //                           </>
    //                         </div>
    //                         <div
    //                           className={clsx(
    //                             "secondary-text ",
    //                             " text-[14px] overflow-hidden text-break leading-[22px] font-normal max-w-2xl float-right"
    //                           )}
    //                           data-kt-element="message-text"
    //                           dangerouslySetInnerHTML={{
    //                             __html: response,
    //                           }}
    //                         ></div>
    //                       </div>
    //                     </div>
    //                   </div>
    //                 </>
    //               </>
    //             );
    //           }
    //         )}
    //       </div>
    //       <div className="flex flex-col-reverse md:flex-row gap-y-4  justify-end gap-x-2.5 w-full px-[24px] py-[12px]">
    //         <button
    //           className="secondary-btn w-full md:w-auto !text-[14px] !leading-[20px] !font-medium !py-[12px] !px-[16px]  rounded !ml-0 !my-0"
    //           onClick={handleClose}
    //         >
    //           {formatMessage({
    //             id: "Close",
    //           })}
    //         </button>
    //         <div
    //           className="w-full md:w-auto
    // }"
    //         >
    //           <button
    //             type="submit"
    //             className="btn md:w-auto h-[44px] btn-primary w-full main-button flex flex-row gap-[8px] flex-grow justify-center items-center"
    //             disabled={downloading}
    //             onClick={exportMessages}
    //           >
    //             {downloading ? (
    //               <Spinner className="w-[20px] h-[20px]" animation="border" />
    //             ) : (
    //               <svg
    //                 width="20"
    //                 height="20"
    //                 viewBox="0 0 20 20"
    //                 fill="none"
    //                 xmlns="http://www.w3.org/2000/svg"
    //               >
    //                 <g clip-path="url(#clip0_8328_153142)">
    //                   <path
    //                     opacity="0.3"
    //                     d="M14.1665 6.66855C13.7063 6.66855 13.3332 6.29545 13.3332 5.83521C13.3332 5.37498 13.7063 5.00188 14.1665 5.00188H14.9998C16.8408 5.00188 18.3332 6.49426 18.3332 8.33521V15.0019C18.3332 16.8428 16.8408 18.3352 14.9998 18.3352H4.99984C3.15889 18.3352 1.6665 16.8428 1.6665 15.0019V8.32943C1.6665 6.48848 3.15889 4.99609 4.99984 4.99609H5.83318C6.29341 4.99609 6.66651 5.36919 6.66651 5.82943C6.66651 6.28966 6.29341 6.66276 5.83318 6.66276H4.99984C4.07936 6.66276 3.33317 7.40895 3.33317 8.32943V15.0019C3.33317 15.9224 4.07936 16.6685 4.99984 16.6685H14.9998C15.9203 16.6685 16.6665 15.9224 16.6665 15.0019V8.33521C16.6665 7.41474 15.9203 6.66855 14.9998 6.66855H14.1665Z"
    //                     fill="black"
    //                   />
    //                   <path
    //                     opacity="0.3"
    //                     d="M9.16683 2.5013C9.16683 2.04106 9.53993 1.66797 10.0002 1.66797C10.4604 1.66797 10.8335 2.04106 10.8335 2.5013L10.8335 10.8346C10.8335 11.2949 10.4604 11.668 10.0002 11.668C9.53993 11.668 9.16683 11.2949 9.16683 10.8346L9.16683 2.5013Z"
    //                     fill="black"
    //                   />
    //                   <path
    //                     d="M9.99984 2.01315L11.9106 3.92389C12.236 4.24933 12.7637 4.24933 13.0891 3.92389C13.4145 3.59845 13.4145 3.07082 13.0891 2.74538L10.5891 0.245381C10.2637 -0.0800562 9.73602 -0.0800562 9.41058 0.245381L6.91058 2.74538C6.58514 3.07082 6.58514 3.59845 6.91058 3.92389C7.23602 4.24933 7.76366 4.24933 8.08909 3.92389L9.99984 2.01315Z"
    //                     fill="black"
    //                   />
    //                 </g>
    //                 <defs>
    //                   <clipPath id="clip0_8328_153142">
    //                     <rect width="20" height="20" fill="white" />
    //                   </clipPath>
    //                 </defs>
    //               </svg>
    //             )}

    //             <span className="text-[14px] font-medium leading-[20px]">
    //               {formatMessage({
    //                 id: "Export as File for Training",
    //               })}
    //             </span>
    //           </button>
    //         </div>
    //       </div>

    //   </div>
  );
}
