import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import { ToolTipUI } from "../../../../widgets/components/UI/ToolTipUI";
import { useIntl } from "react-intl";
import avatarImage from "../../../../../assets/images/profile/profile-image.png";
import { Link } from "react-router-dom";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { toast } from "react-toastify";

const SubscribeApiModal: React.FC<any> = ({ open, setOpen, setSubscribed }) => {
  const { formatMessage } = useIntl();

  const [apiKey, setApiKey] = useState(
    " hgcvxhsat9237e0238bd e278r3497220ufj373488"
  );
  const [apiKeyCopied, setApiKeyCopied] = useState(false);

  const copyHandler = () => {
    setApiKeyCopied(true);
    toast.success(formatMessage({ id: "Copied Successfully" }));
  };

  return (
    <Modal
      size="lg"
      show={open}
      onHide={() => setOpen(false)}
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      {apiKeyCopied && "Copied"}
      <Modal.Header closeButton>
        <div className={"flex items-center justify-between w-full"}>
          <div className="d-flex fw-bold">
            <h2 className="font-size-20">
              {formatMessage({
                id: "API Key",
              })}
            </h2>{" "}
            <ToolTipUI
              tooltipText={formatMessage({
                id: "GLOBAL.TOOLTIP.BILLING.UPGRADE_PLAN",
              })}
            />
          </div>
          <button onClick={() => setOpen(false)}>
            <svg
              width="32"
              height="32"
              viewBox="0 0 32 32"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M21.6568 8.45747L8.45746 21.6568C7.93676 22.1775 7.93676 23.0217 8.45746 23.5424C8.97816 24.0631 9.82238 24.0631 10.3431 23.5424L23.5424 10.3431C24.0631 9.82239 24.0631 8.97817 23.5424 8.45747C23.0217 7.93677 22.1775 7.93677 21.6568 8.45747Z"
                fill="white"
              />
              <path
                opacity="0.5"
                d="M23.5425 21.6578L10.3432 8.45843C9.8225 7.93773 8.97828 7.93773 8.45758 8.45843C7.93688 8.97913 7.93688 9.82335 8.45758 10.3441L21.6569 23.5434C22.1776 24.0641 23.0218 24.0641 23.5425 23.5434C24.0632 23.0227 24.0632 22.1785 23.5425 21.6578Z"
                fill="white"
              />
            </svg>
          </button>
        </div>
      </Modal.Header>
      <Modal.Body className={"flex justify-center flex-col"}>
        <div className={"text-center"}>
          <h2
            className={
              "text-[14px] md:text-[24px] leading-5 md:leading-[32px] text-[#FFFFFFCC] font-semibold mb-3.5 md:mb-[24px]"
            }
          >
            Here’s your API Key
          </h2>
        </div>
        <div
          className={
            "bg-[#C2D24B1A] border !border-[#C2D24B40] border-dashed rounded max-w-2xl mx-auto p-[14px] mb-6"
          }
        >
          <div className={"flex gap-x-2"}>
            <div>
              <svg
                width="32"
                height="32"
                viewBox="0 0 32 32"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M15.9993 29.3327C8.63535 29.3327 2.66602 23.3633 2.66602 15.9993C2.66602 8.63535 8.63535 2.66602 15.9993 2.66602C23.3633 2.66602 29.3327 8.63535 29.3327 15.9993C29.3327 23.3633 23.3633 29.3327 15.9993 29.3327ZM15.9993 26.666C18.8283 26.666 21.5414 25.5422 23.5418 23.5418C25.5422 21.5414 26.666 18.8283 26.666 15.9993C26.666 13.1704 25.5422 10.4573 23.5418 8.45688C21.5414 6.45649 18.8283 5.33268 15.9993 5.33268C13.1704 5.33268 10.4573 6.45649 8.45688 8.45688C6.45649 10.4573 5.33268 13.1704 5.33268 15.9993C5.33268 18.8283 6.45649 21.5414 8.45688 23.5418C10.4573 25.5422 13.1704 26.666 15.9993 26.666ZM14.666 9.33268H17.3327V11.9993H14.666V9.33268ZM14.666 14.666H17.3327V22.666H14.666V14.666Z"
                  fill="#C2D24B"
                  fillOpacity="0.65"
                />
              </svg>
            </div>
            <div>
              <p>
                Please save this secret key somewhere safe and accessible. For
                security reasons, you won't be able to view it again through
                your Kamoto.AI account. If you lose this secret key, you'll need
                to generate a new one.
              </p>
              <div className={"flex justify-end mt-1"}>
                <Link
                  to={"#"}
                  className={
                    "text-[#C2D24B] font-medium flex items-center gap-x-3.5"
                  }
                >
                  Learn More
                  <svg
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      opacity="0.3"
                      d="M4.00065 7.33268C3.63246 7.33268 3.33398 7.63116 3.33398 7.99935C3.33398 8.36754 3.63246 8.66602 4.00065 8.66602H12.0007C12.3688 8.66602 12.6673 8.36754 12.6673 7.99935C12.6673 7.63116 12.3688 7.33268 12.0007 7.33268H4.00065Z"
                      fill="#C2D24B"
                    />
                    <path
                      d="M7.52794 11.5279C7.26759 11.7883 7.26759 12.2104 7.52794 12.4708C7.78829 12.7311 8.2104 12.7311 8.47075 12.4708L12.4708 8.47075C12.7231 8.21837 12.732 7.81198 12.4908 7.54887L8.82412 3.54887C8.57532 3.27746 8.15361 3.25912 7.8822 3.50791C7.61079 3.75671 7.59245 4.17842 7.84125 4.44983L11.0766 7.9793L7.52794 11.5279Z"
                      fill="#C2D24B"
                    />
                  </svg>
                </Link>
              </div>
            </div>
          </div>
        </div>
        <div className={"bg-[#2E2F45] p-[14px] shadow-default rounded md:mb-8"}>
          <div
            className={
              "flex flex-col md:flex-row gap-y-2 justify-between items-center"
            }
          >
            <div
              className={
                "bg-[#171825] flex-grow py-[6px] md:py-4 px-7 rounded-[4px] md:rounded-r-none md:rounded-l w-full md:w-auto flex justify-center md:justify-start -mr-5"
              }
            >
              <span
                className={
                  "text-[13px] md:text-[16px] leading-5 md:leading-6 text-[#FFFFFFA6]"
                }
              >
                {apiKey}
              </span>
            </div>
            <button
              className={
                "bg-[#C2D24B] py-[12px] md:py-[16px] px-[58px] rounded w-full md:w-auto text-black font-semibold text-[14px] leading-5"
              }
              onClick={() => {
                navigator.clipboard.writeText(`${apiKey}`);
                toast.success(formatMessage({ id: "Copied Successfully" }));
              }}
            >
              Copy API Key
            </button>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <div className={"flex flex-grow md:justify-end gap-x-4"}>
          <button
            className={
              "bg-[#C2D24B1A] py-[12px] px-[24px] rounded w-full md:w-auto text-[#C2D24B]"
            }
            onClick={() => {
              setOpen(false);
            }}
          >
            Ok
          </button>
        </div>
      </Modal.Footer>
    </Modal>
  );
};

export default SubscribeApiModal;
