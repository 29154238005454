import noAvatarImage from "./images/avatar-no.png";
import customAvatar from "./images/avatar-custom.png";
import AvatarImage1 from "./images/avatar-1.png";
import AvatarImage2 from "./images/avatar-2.png";
import AvatarImage3 from "./images/avatar-3.png";
import AvatarImage4 from "./images/avatar-4.png";
import AvatarImage5 from "./images/avatar-5.png";
import AvatarImage6 from "./images/avatar-6.png";
import AvatarWomenImage1 from "./images/avatar-women-1.png";
import AvatarWomenImage2 from "./images/avatar-women-2.png";
import AvatarWomenImage3 from "./images/avatar-women-3.png";
import AvatarWomenImage4 from "./images/avatar-women-4.png";
import AvatarWomenImage5 from "./images/avatar-women-5.png";
import AvatarWomenImage6 from "./images/avatar-women-6.png";
import AvatarMainImage from "./images/avatar-main.png";
import AvatarMainWomenImage from "./images/avatar-women-main.png";
import noAvatarImageMain from "./images/avatar-no-main.png";
import AvatarOtherImage1 from "./images/avatar-other-1.png";
import AvatarOtherImage2 from "./images/avatar-other-2.png";
import AvatarOtherImage3 from "./images/avatar-other-3.png";
import AvatarOtherImage4 from "./images/avatar-other-4.png";
import AvatarOtherImage5 from "./images/avatar-other-5.png";
import customAvatar2 from "./images/custom-147285.png"

export const men = [
  // { name: "No Avatar", thumbImage: noAvatarImage, image: noAvatarImageMain },
  {
    name: "Peter",
    thumbImage: AvatarImage1,
    glbUrl:
      "https://public-content.kamoto.ai/personality-avatars-library/male-avatars/64c0c4b80f68c0b2fbe50bc2.glb",
  },
  {
    name: "Jack",
    thumbImage: AvatarImage2,
    glbUrl:
      "https://public-content.kamoto.ai/personality-avatars-library/male-avatars/64c0c84e0f68c0b2fbe50f83.glb",
  },
  {
    name: "Adam",
    thumbImage: AvatarImage3,
    glbUrl:
      "https://public-content.kamoto.ai/personality-avatars-library/male-avatars/64c0c8a80f68c0b2fbe50fd4.glb",
  },
  {
    name: "John",
    thumbImage: AvatarImage4,
    glbUrl:
      "https://public-content.kamoto.ai/personality-avatars-library/male-avatars/64c0c9270a4ad5e1bbb793a4.glb",
  },
  {
    name: "Richard",
    thumbImage: AvatarImage5,
    glbUrl:
      "https://public-content.kamoto.ai/personality-avatars-library/male-avatars/64ad4a295fb414e5c68f71c5.glb",
  },
  {
    name: "Custom",
    thumbImage: customAvatar,
    image: customAvatar2
    // glbUrl:
    //   "https://public-content.kamoto.ai/personality-avatars-library/male-avatars/64ad4a295fb414e5c68f71c5.glb",
  },
];

export const women = [
  // { name: "No Avatar", thumbImage: noAvatarImage, image: noAvatarImageMain },
  {
    name: "Emma",
    thumbImage: AvatarWomenImage1,
    glbUrl:
      "https://public-content.kamoto.ai/personality-avatars-library/female-avatars/64105a7d502aafe373a23bb1.glb",
  },
  {
    name: "Maya",
    thumbImage: AvatarWomenImage2,
    glbUrl:
      "https://public-content.kamoto.ai/personality-avatars-library/female-avatars/64c0c9ba0a4ad5e1bbb79439.glb",
  },
  {
    name: "Olivia",
    thumbImage: AvatarWomenImage3,
    glbUrl:
      "https://public-content.kamoto.ai/personality-avatars-library/female-avatars/64c0c9fb21bbda362c9b0b8c.glb",
  },
  {
    name: "Emily",
    thumbImage: AvatarWomenImage4,
    glbUrl:
      "https://public-content.kamoto.ai/personality-avatars-library/female-avatars/64c0ca2821bbda362c9b0bc4.glb",
  },
  {
    name: "Sophia",
    thumbImage: AvatarWomenImage5,
    glbUrl:
      "https://public-content.kamoto.ai/personality-avatars-library/female-avatars/64c0caa70a4ad5e1bbb7952c.glb",
  },
  {
    name: "Custom",
    thumbImage: customAvatar,
    image: customAvatar2
    // glbUrl:
    //   "https://public-content.kamoto.ai/personality-avatars-library/female-avatars/64c0caa70a4ad5e1bbb7952c.glb",
  },
];

export const nonGender = [
  // { name: "No Avatar", thumbImage: noAvatarImage, image: noAvatarImageMain },
  {
    name: "Racer",
    thumbImage: AvatarOtherImage1,
    glbUrl:
      "https://public-content.kamoto.ai/personality-avatars-library/non-gender-avatars/64c0cb1921bbda362c9b0cca.glb",
  },
  {
    name: "Monster",
    thumbImage: AvatarOtherImage2,
    glbUrl:
      "https://public-content.kamoto.ai/personality-avatars-library/non-gender-avatars/64c0cb5d0f68c0b2fbe512af.glb",
  },
  {
    name: "Gamer",
    thumbImage: AvatarOtherImage3,
    glbUrl:
      "https://public-content.kamoto.ai/personality-avatars-library/non-gender-avatars/64c0cb8d0a4ad5e1bbb79627.glb",
  },
  {
    name: "Wolf",
    thumbImage: AvatarOtherImage4,
    glbUrl:
      "https://public-content.kamoto.ai/personality-avatars-library/non-gender-avatars/64c0cbfa0f68c0b2fbe5134c.glb",
  },
  {
    name: "Dragon",
    thumbImage: AvatarOtherImage5,
    glbUrl:
      "https://public-content.kamoto.ai/personality-avatars-library/non-gender-avatars/64c0cc3b0a4ad5e1bbb796d0.glb",
  },
  {
    name: "Custom",
    thumbImage: customAvatar,
    image: customAvatar2
    // glbUrl:
    //   "https://public-content.kamoto.ai/personality-avatars-library/non-gender-avatars/64c0cc3b0a4ad5e1bbb796d0.glb",
  },
];
