import React from "react";
// @ts-ignore
import { Dropdown } from "react-bootstrap";
// @ts-ignore
import { CSVLink } from "react-csv";
import { useIntl } from "react-intl";
import { useThemeMode } from "../../../_metronic/partials";
import { isEqual } from "lodash";

const Index: React.FC<any> = ({
  getExportFileBlob,
  data,
  columns,
  headers,
  csvData,
}) => {
  const { formatMessage } = useIntl();
  const { mode } = useThemeMode();

  // @ts-ignore
  // @ts-ignore
  return (
    <Dropdown className={"w-full md:w-auto"}>
      <Dropdown.Toggle
        className={
          "export-button flex  justify-center gap-x-2 items-center h-[36px] px-4 rounded w-full md:w-auto z-50"
        }
      >
        {isEqual(mode, "dark") ? (
          <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g clipPath="url(#clip0_497_17302)">
              <path
                opacity="0.3"
                d="M14.1667 6.66672C13.7065 6.66672 13.3334 6.29362 13.3334 5.83338C13.3334 5.37314 13.7065 5.00005 14.1667 5.00005H15.0001C16.841 5.00005 18.3334 6.49243 18.3334 8.33338V15C18.3334 16.841 16.841 18.3334 15.0001 18.3334H5.00008C3.15913 18.3334 1.66675 16.841 1.66675 15V8.3276C1.66675 6.48665 3.15913 4.99426 5.00008 4.99426H5.83342C6.29366 4.99426 6.66675 5.36736 6.66675 5.8276C6.66675 6.28783 6.29366 6.66093 5.83342 6.66093H5.00008C4.07961 6.66093 3.33342 7.40712 3.33342 8.3276V15C3.33342 15.9205 4.07961 16.6667 5.00008 16.6667H15.0001C15.9206 16.6667 16.6668 15.9205 16.6668 15V8.33338C16.6668 7.41291 15.9206 6.66672 15.0001 6.66672H14.1667Z"
                fill="black"
              />
              <path
                opacity="0.3"
                d="M9.16658 2.49996C9.16658 2.03972 9.53968 1.66663 9.99992 1.66663C10.4602 1.66663 10.8333 2.03972 10.8333 2.49996L10.8333 10.8333C10.8333 11.2935 10.4602 11.6666 9.99992 11.6666C9.53968 11.6666 9.16658 11.2935 9.16658 10.8333L9.16658 2.49996Z"
                fill="black"
              />
              <path
                d="M10.0001 2.0118L11.9108 3.92255C12.2363 4.24799 12.7639 4.24799 13.0893 3.92255C13.4148 3.59711 13.4148 3.06947 13.0893 2.74404L10.5893 0.244038C10.2639 -0.081399 9.73626 -0.081399 9.41082 0.244038L6.91083 2.74404C6.58539 3.06947 6.58539 3.59711 6.91083 3.92255C7.23626 4.24799 7.7639 4.24799 8.08934 3.92255L10.0001 2.0118Z"
                fill="black"
              />
            </g>
            <defs>
              <clipPath id="clip0_497_17302">
                <rect width="20" height="20" fill="white" />
              </clipPath>
            </defs>
          </svg>
        ) : (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
          >
            <g clipPath="url(#clip0_6161_19584)">
              <path
                opacity="0.3"
                d="M14.167 6.66659C13.7068 6.66659 13.3337 6.2935 13.3337 5.83326C13.3337 5.37302 13.7068 4.99993 14.167 4.99993H15.0003C16.8413 4.99993 18.3337 6.49231 18.3337 8.33326V14.9999C18.3337 16.8409 16.8413 18.3333 15.0003 18.3333H5.00033C3.15938 18.3333 1.66699 16.8409 1.66699 14.9999V8.32747C1.66699 6.48653 3.15938 4.99414 5.00033 4.99414H5.83366C6.2939 4.99414 6.667 5.36724 6.667 5.82747C6.667 6.28771 6.2939 6.66081 5.83366 6.66081H5.00033C4.07985 6.66081 3.33366 7.407 3.33366 8.32747V14.9999C3.33366 15.9204 4.07985 16.6666 5.00033 16.6666H15.0003C15.9208 16.6666 16.667 15.9204 16.667 14.9999V8.33326C16.667 7.41279 15.9208 6.66659 15.0003 6.66659H14.167Z"
                fill="white"
              />
              <path
                opacity="0.3"
                d="M9.16634 2.50008C9.16634 2.03984 9.53944 1.66675 9.99967 1.66675C10.4599 1.66675 10.833 2.03984 10.833 2.50008L10.833 10.8334C10.833 11.2937 10.4599 11.6667 9.99967 11.6667C9.53944 11.6667 9.16634 11.2937 9.16634 10.8334L9.16634 2.50008Z"
                fill="white"
              />
              <path
                d="M10.0003 2.01193L11.9111 3.92267C12.2365 4.24811 12.7641 4.24811 13.0896 3.92267C13.415 3.59723 13.415 3.0696 13.0896 2.74416L10.5896 0.24416C10.2641 -0.0812769 9.73651 -0.0812769 9.41107 0.24416L6.91107 2.74416C6.58563 3.0696 6.58563 3.59723 6.91107 3.92267C7.23651 4.24811 7.76414 4.24811 8.08958 3.92267L10.0003 2.01193Z"
                fill="white"
              />
            </g>
            <defs>
              <clipPath id="clip0_6161_19584">
                <rect width="20" height="20" fill="white" />
              </clipPath>
            </defs>
          </svg>
        )}
        <span className={"text-[12px] leading-[18px] font-medium"}>
          {formatMessage({ id: "Export" })}
        </span>
      </Dropdown.Toggle>
      <Dropdown.Menu className={"px-4"}>
        <Dropdown.Item className="px-3 rounded menu-item" as={"div"}>
          <a
            href="#"
            className="px-3 menu-link"
            data-kt-export="copy"
            onClick={() => {
              getExportFileBlob({
                columns: columns,
                data: data,
                headers: headers,
                fileType: "xlsx",
                fileName: "save",
              });
            }}
          >
            {formatMessage({ id: "Export as Excel" })}
          </a>
        </Dropdown.Item>
        <Dropdown.Item as={"div"} className="px-3 rounded menu-item">
          <CSVLink
            filename="my-file.csv"
            data={csvData?.data}
            headers={csvData?.headers}
            className="px-3 menu-link"
          >
            {formatMessage({ id: "Export as CSV" })}
          </CSVLink>
        </Dropdown.Item>
        <Dropdown.Item className="px-3 rounded menu-item" as={"div"}>
          <a
            href="#"
            className="px-3 menu-link"
            data-kt-export="copy"
            onClick={() => {
              getExportFileBlob({
                columns: columns,
                data: data,
                headers: headers,
                fileType: "pdf",
                fileName: "save",
              });
            }}
          >
            {formatMessage({ id: "Export as PDF" })}
          </a>
        </Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default Index;
