import { get, isEqual, map } from "lodash";
import React, { useState } from "react";
import DateFilter from "../components/date-filter";
import LineChart from "../components/line-chart";
import CustomTable from "../../../components/table";
import { useIntl } from "react-intl";
import { Dropdown, Modal } from "react-bootstrap";
import clsx from "clsx";
import { userStatus } from "../utils";
import WarningModal from "../components/users/WarningModal";
import BlockModal from "../components/users/BlockModal";
import EditModal from "../components/users/EditModal";

const UsersTable = () => {
  const { formatMessage } = useIntl();
  const [openWarningModal, setOpenWarningModal] = useState(false);
  const [openBlockModal, setOpenBlockModal] = useState(false);
  const [openEditModal, setOpenEditModal] = useState(false);
  const data = [
    {
      userName: "Toshendra Sharma",
      personalities: [0, 1, 3],
      createdDate: "15th Jun 23, 02:39 AM",
      lastSeen: "15th Jun 23, 02:39 AM",
      userStatus: "Verified",
      earning: "$10,688",
    },
    {
      userName: "Rohendra Sharma",
      personalities: [0, 1, 3],
      createdDate: "15th Jun 23, 02:39 AM",
      lastSeen: "15th Jun 23, 02:39 AM",
      userStatus: "Unverified",
      earning: "$10,688",
    },
    {
      userName: "Chiraag dhawan",
      personalities: [0, 1, 3],
      createdDate: "15th Jun 23, 02:39 AM",
      lastSeen: "15th Jun 23, 02:39 AM",
      userStatus: "New",
      earning: "$10,688",
    },
  ];

  const headers = [
    {
      label: "User Name",
      key: "userName",
      enableSorting: true,
    },
    {
      label: "Personalities",
      key: "personalities",
      enableSorting: true,
    },
    {
      label: "Created Date",
      key: "createdDate",
      enableSorting: true,
    },
    {
      label: "Last Seen",
      key: "lastSeen",
      enableSorting: true,
    },
    {
      label: "User Status",
      key: "userStatus",
      enableSorting: true,
    },
    {
      label: "Earning",
      key: "earning",
      enableSorting: true,
    },
    {
      label: "Action",
      key: "action",
      enableSorting: true,
    },
  ];

  const columns = React.useMemo<any>(
    () =>
      map(headers, (item) => {
        return {
          header: get(item, "label"),
          accessorKey: get(item, "key"),
          enableSorting: get(item, "enableSorting"),
          footer: (props: any) => props.column.id,
          cell: (info: any) => {
            if (item) {
              if (isEqual(get(item, "key"), "personalities")) {
                return (
                  <div className="flex text-white ">
                    <div className="w-6 h-6 rounded-full bg-[#FFC700] flex items-center justify-center  border-2 border-[#ffd233] z-30">
                      A
                    </div>
                    <div className="w-6 h-6 rounded-full bg-[#FFC700] flex items-center justify-center -ml-2 border-2 border-[#ffd233] z-20">
                      A
                    </div>
                    <div className="w-6 h-6 rounded-full bg-[#FFC700] flex items-center justify-center -ml-2 border-2 border-[#ffd233] z-10">
                      A
                    </div>
                  </div>
                );
              }
              if (isEqual(get(item, "key"), "userStatus")) {
                return (
                  <button
                    className={clsx(
                      userStatus(info.getValue()),
                      "text-[12px] py-1 px-3 rounded"
                    )}
                  >
                    {info.getValue()}
                  </button>
                );
              }
              if (isEqual(get(item, "key"), "action")) {
                return (
                  <Dropdown>
                    <Dropdown.Toggle
                      as={"button"}
                      className="bg-secondary w-8 h-8 rounded flex flex-col items-center justify-center gap-y-[3px]"
                    >
                      <svg
                        width="4"
                        height="4"
                        viewBox="0 0 4 4"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <circle
                          cx="2"
                          cy="2"
                          r="2"
                          className="icon-main-color"
                          fill-opacity="0.65"
                        />
                      </svg>
                      <svg
                        width="4"
                        height="4"
                        viewBox="0 0 4 4"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <circle
                          cx="2"
                          cy="2"
                          r="2"
                          className="icon-main-color"
                          fill-opacity="0.65"
                        />
                      </svg>
                      <svg
                        width="4"
                        height="4"
                        viewBox="0 0 4 4"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <circle
                          cx="2"
                          cy="2"
                          r="2"
                          className="icon-main-color"
                          fill-opacity="0.65"
                        />
                      </svg>
                    </Dropdown.Toggle>
                    <Dropdown.Menu className={"px-4"}>
                      <Dropdown.Item
                        className="px-3 py-2 rounded menu-item"
                        as={"div"}
                        onClick={() => {
                          setOpenEditModal(true);
                        }}
                      >
                        Edit
                      </Dropdown.Item>
                      <Dropdown.Item
                        className="px-3 py-2 rounded menu-item"
                        as={"div"}
                        onClick={() => {
                          setOpenWarningModal(true);
                        }}
                      >
                        Send Warning
                      </Dropdown.Item>{" "}
                      <Dropdown.Item
                        className="px-3 py-2 rounded menu-item"
                        as={"div"}
                        onClick={() => {
                          setOpenBlockModal(true);
                        }}
                      >
                        Block
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                );
              } else {
                return info.getValue();
              }
            }
          },
        };
      }),

    []
  );

  return (
    <div>
      <div className={"mt-[16px] md:mt-4"}>
        <div
          className={
            "tab-container shadow-default sm:py-[14px] sm:px-[6px] md:p-6  rounded min-h-[70vh]"
          }
        >
          <CustomTable
            loading={false}
            tableData={data}
            pageCount={10}
            headers={[]}
            columns={columns}
            fetchData={() => {}}
            emptyControl={{
              heading: formatMessage({ id: "No Events found" }),
            }}
          />
        </div>
      </div>
      <WarningModal
        show={openWarningModal}
        onHide={() => setOpenWarningModal(false)}
      />
      <BlockModal
        show={openBlockModal}
        onHide={() => setOpenBlockModal(false)}
      />
      <EditModal show={openEditModal} onHide={() => setOpenEditModal(false)} />
    </div>
  );
};

export default UsersTable;
