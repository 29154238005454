import React, { useEffect, useState } from "react";
import useGTM from "../../../../hooks/useGTM";
import SearchWidget from "./search-widget";
import { GTMEvent } from "../../../../hooks/gtm_helpers";
import { useAuth } from "../../../auth";
import { useSearchParams } from "react-router-dom";

const Index = () => {
  // const {ipStackCountry}= useAuth()
  useGTM(false, [{ event: GTMEvent.MarketplaceExplorePageView }]);
  // const [currentCountry,setCurrentCountry] = useState<any>(null)

  // useEffect(() => {
  //   setCurrentCountry(ipStackCountry)
  // },[])

  // const [search, setSearch] = useSearchParams()

  // if(!currentCountry){
  //   return <></>
  // }

  return (
    <SearchWidget
      showFilterButton={true}
      updateUrl={true}
      showFilterApplied={true}
      searchParamsFilter={true}
      paginationDefault={{ perPage: 30, values: [20, 30, 40, 50] }}
      // preAppliedFilters={{country: [currentCountry?.countryId],category: search.getAll('category')}}
      showViewAllButton={undefined}
    />
  );
};

export default Index;
