import { get, map } from "lodash";
import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Pagination } from "swiper";

const ModalSliderLogin: React.FC<any> = ({ data }) => {
  return (
    <Swiper
      modules={[Pagination, Autoplay]}
      initialSlide={0}
      className="w-full py-10 auth-swiper"
      slidesPerView={1}
      loop={true}
      autoplay={true}
      pagination={{
        clickable: true,
      }}
    >
      {map(data, (item) => (
        <SwiperSlide className={"!w-full"}>
          <div className={" mx-auto text-center"}>
         
            <img src={get(item, "image")} />
          </div>
        </SwiperSlide>
      ))}
    </Swiper>
  );
};

export default ModalSliderLogin;
