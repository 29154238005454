import React, { useEffect, useRef, useState } from "react";
import clsx from "clsx";
import Lottie from "lottie-react";
import Sider from "../components/sider";
import { InfoCard } from "../../widgets/components/UI/InfoCard";
import { useIntl } from "react-intl";
import TextareaAutosize from "react-textarea-autosize";
import ChatItem from "../components/chat-item";
import Request from "../components/request";
import Response from "../components/response";
import { useAuth } from "../../auth";
import {
  getTrainingsByType,
  getTrainingChats,
  addTrainingChat,
  createTraining,
  updateTraining,
  deleteTraining,
  deleteTrainingChat,
  cloneTraining,
} from "../core/_request";
import { getCdnUrl } from "../../../core/_util";
import userDefault from "../../../assets/images/profile/user-default.png";
import { TrainingChatItem } from "../core/_models";
import { Form, Modal, Spinner } from "react-bootstrap";
import { toast } from "react-toastify";
import { Formik } from "formik";
import TextInput from "../../widgets/components/Input/TextInput";
import { CustomButton } from "../../widgets/components/UI/CustomButton";
import { BasicButton } from "../../widgets/components/UI/BasicButton";
import useInfiniteScroll from "../../../hooks/useInfiniteScroll";
import TypingAnimationJson from "../../../assets/lotties/typing-animation.json";
import useGTM from "../../../hooks/useGTM";
import { GTMEvent } from "../../../hooks/gtm_helpers";

const initialMessage = {
  chatId: Math.random() * 10000 + "" + Date().valueOf(),
  prompt: "",
  completion: "",
};

const Trainbychat: React.FC<any> = ({}) => {
  const { formatMessage } = useIntl();
  const {dataLayerPush} = useGTM()
  const [openSider, setOpenSider] = useState(false);
  const [trainings, setTrainings] = useState<any[]>([]);
  const [trainingChats, setTrainingChats] = useState<TrainingChatItem[]>([]);
  const [activeTraining, setActiveTraining] = useState<any>(null);
  const [selectedTraining, setSelectedTraining] = useState<any>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [chatLoading, setChatLoading] = useState<boolean>(false);
  const [message, setMessage] = useState<string>("");
  const [defaultMessage, setDefaultMessage] = useState<any>(initialMessage);
  const [isResponding, setIsResponding] = useState<boolean>(false);
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const [deleteModalOpen, setDeleteModalOpen] = useState<boolean>(false);
  const [draftTrainings, setDraftTrainings] = useState<string[]>([]);
  const [page, setPage] = useState<number>(1);
  const [chatPage, setChatPage] = useState<number>(1);
  const [available, setAvailable] = useState<boolean>(true);
  const [cloneModalOpen, setCloneModalOpen] = useState<boolean>(false);
  const { selectedPersonality, currentUser } = useAuth();
  const ref = useRef();
  const inputRef = useRef<HTMLTextAreaElement | null>(null);
  const chatScroll = useRef<HTMLDivElement | null>(null);
  const { removeListener } = useInfiniteScroll(ref, 140, () => {
    setPage((page) => page + 1);
  });
  useInfiniteScroll(
    chatScroll,
    200,
    () => {
      setChatPage((page) => page + 1);
    },
    true
  );

  function goToBottom() {
    if (chatScroll.current) {
      chatScroll.current.scrollTop = chatScroll.current.scrollHeight;
    }
  }

  function autoFocusInput() {
    setTimeout(() => inputRef?.current?.focus(), 0);
  }
  async function get() {
    const {
      data: { data, success },
    } = await getTrainingsByType(
      selectedPersonality.personalityId,
      "chat",
      page
    );
    if (success) {
      setTrainings((trainings) => [...trainings, ...data]);
      if (!data.length) {
        removeListener();
      }
    }
    setLoading(false);
  }

  useEffect(() => {
    const draftIds = trainings.reduce((acc, item) => {
      if (item.status === "draft" && item.userId == currentUser?.userId) {
        acc.push(item.trainingId);
      }
      return acc;
    }, []);

    setDraftTrainings(draftIds);
  }, [trainings]);

  useEffect(() => {
    if (page == 1 && trainings.length) {
      setActiveTraining(trainings[0]);
    }
  }, [trainings]);

  useEffect(() => {
    selectedPersonality && get();
  }, [selectedPersonality, page]);

  async function sendMessage() {
    let msg = message.trim();
    if (!msg.length) return;
    setDefaultMessage((msg: any) => {
      return { ...msg, prompt: message };
    });
    setIsResponding(true);
    setMessage("");
    setTimeout(() => goToBottom(), 100);
    const {
      data: { success, data },
    } = await addTrainingChat(activeTraining.trainingId, msg);
    if (success) {
      setTrainingChats((trainingChats) => [...trainingChats, data]);
      setTimeout(() => goToBottom(), 100);
    } else {
      setMessage(msg);
      toast.error("Error while sending message. Please retry.",
      {
        autoClose: false,
        closeButton: true, // Display close button (cross icon)
      });
    }
    autoFocusInput();
    setIsResponding(false);
  }

  async function getChats() {
    setChatLoading(true);
    const {
      data: { data, success },
    } = await getTrainingChats(activeTraining.trainingId, chatPage);
    if (success) {
      const chatContainer = chatScroll.current;

      if (!data.length) {
        setAvailable(false);
        setChatLoading(false);
        return;
      }

      if (chatContainer) {
        const { scrollTop, scrollHeight } = chatContainer;
        setTrainingChats((chats) =>
          chatPage == 1 ? data : [...data, ...chats]
        );
        requestAnimationFrame(() => {
          const newScrollPosition =
            chatContainer.scrollHeight - scrollHeight + scrollTop;
          chatContainer.scrollTop = newScrollPosition;
        });
        if (chatPage == 1) {
          goToBottom();
        }
      }
    }
    setChatLoading(false);
  }
  useEffect(() => {
    if (activeTraining?.trainingId && available) {
      getChats();
    }
  }, [chatPage, available, activeTraining?.trainingId]);

  useEffect(() => {
    if (activeTraining?.trainingId) {
      setIsEdit(false);
      setTrainingChats([]);
      setAvailable(true);
      setChatPage(1);
    }
  }, [activeTraining?.trainingId]);

  async function addNewChatTraining() {
    toast.info("Creating chat training. Please wait...", { autoClose: 2000 });
    const {
      data: { data, success, errors },
    } = await createTraining(selectedPersonality.personalityId, "chat", {
      title: "New Chat Training",
    });
    if (success) {
      setTrainings((trainings) => [
        {
          ...data[0],
          title: formatMessage({ id: "New Chat Training" }),
          isNew: true,
          user,
        },
        ...trainings,
      ]);
      toast.success("Training created successfully", {
        autoClose: 2000,
        delay: 2000,
      });
      dataLayerPush(GTMEvent.TrainingChatCreationSuccessful)
    } else {
      toast.error(errors[0]?.includes("Quota") ?"Your Quota for chat training has been exhausted" : "Training cannot be created at the moment",
      {
        autoClose: false,
        closeButton: true, // Display close button (cross icon)
      });
      dataLayerPush(GTMEvent.TrainingChatCreationFailed)
    }
  }

  const onEnterPress = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (e.keyCode === 13 && e.shiftKey === false) {
      e.preventDefault();
      sendMessage();
    }
  };

  const user = {
    username: currentUser?.username,
    userId: currentUser?.userId,
    firstName: currentUser?.firstName,
    lastName: currentUser?.lastName,
    photo: currentUser?.photo,
  };
  const personalityImage = selectedPersonality?.image
    ? getCdnUrl(selectedPersonality.image)
    : userDefault;
  const trainerImage = activeTraining?.user?.photo
    ? getCdnUrl(activeTraining.user.photo)
    : userDefault;
  return (
    <div
      className={`tab-container rounded  h-[calc(100vh-200px)] lg:h-[calc(100vh-220px)]' mt-4`}
    >
      <div className={"flex h-full"}>
        <Sider
          open={openSider}
          setOpen={setOpenSider}
          heading={formatMessage({ id: "Chats for Training" })}
          onPlusClick={addNewChatTraining}
          ref={ref}
          draftTrainings={draftTrainings}
          setTrainings={setTrainings}
        >
          <ul>
            {trainings.map((training) => (
              <li key={training.trainingId}>
                <ChatItem
                  training={training}
                  setActiveTraining={setActiveTraining}
                  activeTraining={activeTraining}
                  setIsEdit={setIsEdit}
                  setDeleteModalOpen={setDeleteModalOpen}
                  setSelectedTraining={setSelectedTraining}
                  setCloneModalOpen={setCloneModalOpen}
                  isOwned={training.userId === currentUser?.userId}
                />
              </li>
            ))}
          </ul>
          {loading && (
            <div className="h-[80px] w-full d-flex justify-content-center align-items-center">
              <Spinner animation="border" />
            </div>
          )}
        </Sider>
        <div className={"flex-grow p-[16px_16px_20px]  xl:p-[16px_20px_20px] relative h-full"}>
          <button onClick={() => setOpenSider(true)} className={"md:hidden "}>
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M5.14291 7.42859H18.8572M5.14062 12H18.8515M5.14291 16.5714H18.8515"
                stroke="#8898A6"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </button>
          <div className="h-full d-flex flex-column">
            {isEdit && (
              <div className="mb-8">
                <Formik
                  enableReinitialize
                  initialValues={{ title: selectedTraining.title || "" }}
                  onSubmit={async (values, { setSubmitting }) => {
                    setSubmitting(true);
                    const {
                      data: { success, data, errors },
                    } = await updateTraining(selectedTraining.trainingId, {
                      title: values.title.trim(),
                    });
                    if (success) {
                      const updated = trainings.map((item) => {
                        if (item.trainingId === selectedTraining.trainingId) {
                          return {
                            ...item,
                            title: data.title,
                          };
                        } else return item;
                      });
                      setTrainings(updated);
                      setIsEdit(false);
                    } else {
                      toast.error(errors[0],
                        {
                          autoClose: false,
                          closeButton: true, // Display close button (cross icon)
                        });
                    }
                    setSubmitting(false);
                  }}
                >
                  {(formik) => (
                    <form>
                      <TextInput
                        label={"Title"}
                        toolTipText={formatMessage({
                          id: "Please, Enter title of the chat training",
                        })}
                        fieldName={"title"}
                        placeholder={"Enter title here"}
                        fieldType={"text"}
                        formik={formik}
                      />

                      <div
                        className={
                          "flex flex-wrap items-center gap-x-2 gap-y-3.5 justify-end text-[13px] leading-5"
                        }
                      >
                        <BasicButton
                          buttonText={formatMessage({ id: "Cancel" })}
                          border="none"
                          color="#C2D24B1A"
                          textColor="#C2D24B"
                          customClass={"px-[24px]"}
                          height="44px"
                          onClick={() => {
                            formik.resetForm();
                            setIsEdit(false);
                          }}
                        />
                        <CustomButton
                          isSubmitting={formik.isSubmitting}
                          isValid={
                            formik.dirty && formik.values.title.trim().length
                          }
                          buttonText={"Save"}
                          loading={formik.isSubmitting}
                          height={44}
                          widthLoading={1}
                          width={1}
                          onSubmit={formik.handleSubmit}
                        />
                      </div>
                    </form>
                  )}
                </Formik>
              </div>
            )}
            <div
              ref={chatScroll}
              className={`custom-scroll overflow-y-scroll  pb-8`}
              style={{ height: "calc(100% - 60px" }}
            >
              {activeTraining ? (
                <>
                  <div className="h-[40px] w-full d-flex justify-content-center align-items-center">
                    {chatLoading && <Spinner animation="border" />}
                  </div>
                  {trainingChats.map(
                    ({ prompt, completion, chatId }, index) => (
                      <div
                        className={`${
                          index % 2 == 0 ? "bg-[#ffffff10]" : ""
                        } px-4 py-4`}
                        key={chatId}
                      >
                        <Request
                          isOwned={
                            activeTraining.userId === currentUser?.userId
                          }
                          chatId={chatId}
                          setTrainingChats={setTrainingChats}
                          prompt={prompt}
                          image={trainerImage}
                          status={activeTraining.status}
                        />
                        <Response
                          isOwned={
                            activeTraining.userId === currentUser?.userId
                          }
                          chatId={chatId}
                          setTrainingChats={setTrainingChats}
                          personalityImage={personalityImage}
                          response={completion}
                          status={activeTraining.status}
                        />
                      </div>
                    )
                  )}
                  {isResponding ? (
                    <div
                      className={`${
                        trainingChats.length % 2 == 0 ? "bg-[#ffffff10]" : ""
                      } px-4 py-4`}
                      key={defaultMessage.chatId}
                    >
                      <Request
                        status={activeTraining.status}
                        isOwned={false}
                        chatId={defaultMessage.chatId}
                        prompt={defaultMessage.prompt}
                        image={trainerImage}
                      />
                      <div className="flex justify-start">
                        <Lottie
                          animationData={TypingAnimationJson}
                          style={{
                            width: 85,
                            height: 45,
                            borderRadius: 25,
                            borderTopLeftRadius: 0,
                            overflow: "hidden",
                          }}
                        />
                      </div>
                    </div>
                  ) : (
                    <>
                      <div
                        className={clsx(
                          "secondary-text text-[14px] overflow-hidden text-break leading-[22px] font-normal max-w-2xl float-right"
                          // `text-${message.type === 'in' ? 'start' : 'end'}`
                        )}
                        data-kt-element="message-text"
                        dangerouslySetInnerHTML={{
                          __html: defaultMessage.response,
                        }}
                      ></div>
                      <p
                        style={{ marginTop: -20, color: "#ff2424" }}
                        className="text-sm"
                      >
                        {defaultMessage.error}
                      </p>
                    </>
                  )}
                </>
              ) : (
                <InfoCard
                  title={formatMessage({
                    id: "How can you train an AI personality or character using chats?",
                  })}
                  desc={
                    <>
                      {formatMessage({
                        id: "Training an AI personality within Kamoto.AI is both intuitive and engaging. Start by asking a question you'd like the AI to learn. When the initial response arrives, you have the opportunity to edit it to reflect the answer you desire. Focus on embedding the core knowledge or principles you want the AI to master, rather than the specific language or sentence structure. Kamoto.AI's algorithms will handle the linguistic nuances, allowing you to craft an AI persona that truly resonates with your vision.",
                      })}
                      <br></br>
                      <br></br>
                    </>
                  }
                  slug={""}
                  isAnchor={
                    "https://help.kamoto.ai/en/articles/7945460-training-your-ai-personality-interactive-chat-based-training-in-kamotoai"
                  }
                />
              )}
              <div
                className={
                  "absolute !bottom-[0px] flex  tab-container  md:!bottom-[20px] inset-x-0 md:inset-x-6"
                }
              >
                {!activeTraining ? null : activeTraining.status !== "draft" ||
                  activeTraining.userId !== currentUser?.userId ? (
                  <div
                    className={
                      "bg-[#2E2F45] rounded py-[12px] px-2 flex justify-between h-[52px]"
                    }
                  >
                    <div
                      className={"flex gap-x-2 justify-between items-center"}
                    >
                      <svg
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <g clipPath="url(#clip0_497_13458)">
                          <path
                            d="M5 13C5 12.4696 5.21071 11.9609 5.58579 11.5858C5.96086 11.2107 6.46957 11 7 11H17C17.5304 11 18.0391 11.2107 18.4142 11.5858C18.7893 11.9609 19 12.4696 19 13V19C19 19.5304 18.7893 20.0391 18.4142 20.4142C18.0391 20.7893 17.5304 21 17 21H7C6.46957 21 5.96086 20.7893 5.58579 20.4142C5.21071 20.0391 5 19.5304 5 19V13Z"
                            stroke="#8898A6"
                            strokeWidth="1.6"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            d="M8 11V7C8 5.93913 8.42143 4.92172 9.17157 4.17157C9.92172 3.42143 10.9391 3 12 3C13.0609 3 14.0783 3.42143 14.8284 4.17157C15.5786 4.92172 16 5.93913 16 7V11M11 16C11 16.2652 11.1054 16.5196 11.2929 16.7071C11.4804 16.8946 11.7348 17 12 17C12.2652 17 12.5196 16.8946 12.7071 16.7071C12.8946 16.5196 13 16.2652 13 16C13 15.7348 12.8946 15.4804 12.7071 15.2929C12.5196 15.1054 12.2652 15 12 15C11.7348 15 11.4804 15.1054 11.2929 15.2929C11.1054 15.4804 11 15.7348 11 16Z"
                            stroke="#8898A6"
                            strokeWidth="1.6"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </g>
                        <defs>
                          <clipPath id="clip0_497_13458">
                            <rect width="24" height="24" fill="white" />
                          </clipPath>
                        </defs>
                      </svg>
                      <span
                        className={
                          "line-clamp-1 text-[14px] leading-5 text-[#FFFFFFA6]"
                        }
                      >
                        {activeTraining.userId !== currentUser?.userId
                          ? formatMessage({
                              id: "Chat training is not owned by you. Please duplicate this thread to extend or edit.",
                            })
                          : formatMessage({
                              id: "Chat training already initiated for this chat. Please duplicate this thread to extend or edit.",
                            })}
                      </span>
                    </div>
                    <svg
                      width="32"
                      height="32"
                      viewBox="0 0 32 32"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g clipPath="url(#clip0_497_13464)">
                        <path
                          d="M10.6667 13.3334C10.6667 12.6261 10.9477 11.9478 11.4478 11.4477C11.9479 10.9476 12.6262 10.6667 13.3334 10.6667H24.0001C24.7073 10.6667 25.3856 10.9476 25.8857 11.4477C26.3858 11.9478 26.6667 12.6261 26.6667 13.3334V24C26.6667 24.7073 26.3858 25.3855 25.8857 25.8856C25.3856 26.3857 24.7073 26.6667 24.0001 26.6667H13.3334C12.6262 26.6667 11.9479 26.3857 11.4478 25.8856C10.9477 25.3855 10.6667 24.7073 10.6667 24V13.3334Z"
                          stroke="#C2D24B"
                          strokeWidth="1.6"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M21.3334 10.6667V8.00001C21.3334 7.29277 21.0524 6.61449 20.5523 6.11439C20.0522 5.61429 19.374 5.33334 18.6667 5.33334H8.00004C7.2928 5.33334 6.61452 5.61429 6.11442 6.11439C5.61433 6.61449 5.33337 7.29277 5.33337 8.00001V18.6667C5.33337 19.3739 5.61433 20.0522 6.11442 20.5523C6.61452 21.0524 7.2928 21.3333 8.00004 21.3333H10.6667"
                          stroke="#C2D24B"
                          strokeWidth="1.6"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </g>
                      <defs>
                        <clipPath id="clip0_497_13464">
                          <rect width="32" height="32" fill="white" />
                        </clipPath>
                      </defs>
                    </svg>
                  </div>
                ) : (
                  <div className={"relative flex-grow"}>
                    <TextareaAutosize
                      ref={inputRef}
                      // className={
                      //   "form-control form-control-flush !bg-[#2E2F45] rounded pr-10  py-[15px]"
                      // }
                      className={
                        "form-control search-input-table rounded pr-10"
                      }
                      data-kt-element="input"
                      disabled={isResponding}
                      placeholder={formatMessage({
                        id: `${
                          isResponding
                            ? formatMessage({
                                id: "Waiting for reply",
                              })
                            : formatMessage({
                                id: "Start typing your message here...",
                              })
                        }`,
                      })}
                      value={message}
                      onChange={(e) => setMessage(e.target.value)}
                      onKeyDown={onEnterPress}
                    />
                    <button
                      onClick={sendMessage}
                      className={"absolute right-4 bottom-3 z-40"}
                    >
                      <svg
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <g clipPath="url(#clip0_344_24401)">
                          <path
                            d="M9.99958 14L20.9996 3M9.99958 14L13.4996 21C13.5435 21.0957 13.6139 21.1769 13.7025 21.2338C13.7912 21.2906 13.8943 21.3209 13.9996 21.3209C14.1049 21.3209 14.208 21.2906 14.2966 21.2338C14.3853 21.1769 14.4557 21.0957 14.4996 21L20.9996 3M9.99958 14L2.99958 10.5C2.90384 10.4561 2.82271 10.3857 2.76583 10.2971C2.70895 10.2084 2.67871 10.1053 2.67871 10C2.67871 9.89468 2.70895 9.79158 2.76583 9.70295C2.82271 9.61431 2.90384 9.54387 2.99958 9.5L20.9996 3"
                            stroke={"#C2D24B"}
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </g>
                        <defs>
                          <clipPath id="clip0_344_24401">
                            <rect width="24" height="24" fill="white" />
                          </clipPath>
                        </defs>
                      </svg>
                    </button>
                  </div>
                )}
              </div>
            </div>
          </div>
          <Modal
            aria-labelledby="contained-modal-title-vcenter"
            centered
            show={deleteModalOpen}
            onHide={() => setDeleteModalOpen(false)}
          >
            <Modal.Header closeButton>
              <Modal.Title
                className="modal-title text-[20px] leading-[28px] font-weight-600 text-[#FFFFFFCC] "
                id="contained-modal-title-vcenter"
              >
                {formatMessage({ id: "Delete Confirmation" })}
              </Modal.Title>
            </Modal.Header>
            <Modal.Body className=" p-[16px] md:p-[24px]">
              <p className="text-[#FFFFFFCC] text-[16px]">
                {formatMessage({ id: "Are you sure to delete" })}{" "}
                <span className="text-[#C2D24B]">
                  {selectedTraining?.title || "this"}
                </span>{" "}
                {formatMessage({ id: "training?" })}
              </p>
              <Formik
                initialValues={{}}
                onSubmit={async (values, { setSubmitting }) => {
                  setSubmitting(true);
                  const {
                    data: { success, data },
                  } = await deleteTraining(selectedTraining.trainingId);
                  if (success) {
                    setTrainings((trainings) =>
                      trainings.filter(
                        ({ trainingId }) =>
                          trainingId !== selectedTraining.trainingId
                      )
                    );
                    setDeleteModalOpen(false);
                    setActiveTraining(selectedTraining.trainingId==trainings[0].trainingId ? null : trainings[0]);
                    toast.success("Training deleted successfully");
                    dataLayerPush(GTMEvent.TrainingChatDeletionSuccessful)
                  } else {
                    toast.error("Error while deleting the training",
                    {
                      autoClose: false,
                      closeButton: true, // Display close button (cross icon)
                    });
                    dataLayerPush(GTMEvent.TrainingChatDeletionFailed)
                  }
                  setSubmitting(false);
                }}
              >
                {(formik) => (
                  <div className="flex gap-x-3 justify-end items-center">
                    <BasicButton
                      buttonText={formatMessage({ id: "Cancel" })}
                      border="none"
                      color="#C2D24B1A"
                      textColor="#C2D24B"
                      customClass={"px-[24px] mt-8"}
                      height="44px"
                      onClick={() => {
                        setDeleteModalOpen(false);
                      }}
                    />
                    <CustomButton
                      customClass="mt-8"
                      isSubmitting={formik.isSubmitting}
                      isValid={true}
                      buttonText={formatMessage({ id: "Delete" })}
                      loading={formik.isSubmitting}
                      height={44}
                      widthLoading={1}
                      width={1}
                      onSubmit={formik.handleSubmit}
                    />
                  </div>
                )}
              </Formik>
            </Modal.Body>
          </Modal>

          <Modal
            aria-labelledby="contained-modal-title-vcenter"
            centered
            show={cloneModalOpen}
            onHide={() => setCloneModalOpen(false)}
          >
            <Modal.Header closeButton>
              <Modal.Title
                className="modal-title text-[20px] leading-[28px] font-weight-600 text-[#FFFFFFCC] "
                id="contained-modal-title-vcenter"
              >
                {formatMessage({ id: "Cloning Confirmation?" })}
              </Modal.Title>
            </Modal.Header>
            <Modal.Body className=" p-[16px] md:p-[24px]">
              <p className="text-[#FFFFFFCC] text-[16px]">
                {formatMessage({ id: "Are you sure to clone" })}
                <span className="text-[#C2D24B]">
                  {selectedTraining?.title || "this"}
                </span>{" "}
                {formatMessage({ id: "training?" })}
              </p>
              <Formik
                initialValues={{}}
                onSubmit={async (values, { setSubmitting }) => {
                  setSubmitting(true);
                  const {
                    data: { success, data },
                  } = await cloneTraining(selectedTraining.trainingId);
                  if (success) {
                    data.user = user;
                    setTrainings((trainings) => [data, ...trainings]);
                    setCloneModalOpen(false);
                    setActiveTraining(null);
                    toast.success("Training cloned successfully");
                  } else {
                    toast.error("Error while cloned the training",
                    {
                      autoClose: false,
                      closeButton: true, // Display close button (cross icon)
                    });
                  }
                  setSubmitting(false);
                }}
              >
                {(formik) => (
                  <div className="flex gap-x-3 justify-end items-center">
                    <BasicButton
                      buttonText={formatMessage({ id: "Cancel" })}
                      border="none"
                      color="#C2D24B1A"
                      textColor="#C2D24B"
                      customClass={"px-[24px] mt-8"}
                      height="44px"
                      onClick={() => {
                        setCloneModalOpen(false);
                      }}
                    />
                    <CustomButton
                      customClass="mt-8"
                      isSubmitting={formik.isSubmitting}
                      isValid={true}
                      buttonText={"Clone"}
                      loading={formik.isSubmitting}
                      height={44}
                      widthLoading={1}
                      width={1}
                      onSubmit={formik.handleSubmit}
                    />
                  </div>
                )}
              </Formik>
            </Modal.Body>
          </Modal>
        </div>
      </div>
    </div>
  );
};

export default Trainbychat;
