/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable react/jsx-no-target-blank */
import { useIntl } from "react-intl";
import { Link } from "react-router-dom";

const SidebarFooter = () => {
  const { formatMessage } = useIntl();
  return (
    <div
      className="app-sidebar-footer d-flex flex-column justify-content-end px-[18px] mb-10  "
      id="kt_app_sidebar_footer"
    >
      <Link
        to="/my-chats"
        className="d-flex flex-row text-nowrap align-items-center justify-content-center p-[6px] md:p-[8px] main-button-light rounded-[6px] main-text text-[14px] leading-[20px] mb-[20px]"
      >
        {" "}
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M20 11.5065C19.9036 9.94807 19.3528 8.45189 18.4155 7.20241C17.4783 5.95294 16.1955 5.00479 14.7254 4.47486C13.2553 3.94492 11.6621 3.85637 10.1422 4.22011C8.62228 4.58386 7.24216 5.384 6.17195 6.52189C5.10174 7.65979 4.38823 9.08569 4.11937 10.6238C3.85052 12.1619 4.03808 13.745 4.65893 15.178C5.27978 16.6109 6.30678 17.831 7.61332 18.6878C8.91986 19.5447 10.4488 20.0008 12.0117 20H16.6668C17.5506 19.9991 18.3979 19.648 19.0228 19.0237C19.6477 18.3994 19.9991 17.5529 20 16.67V11.5065ZM18.6667 16.67C18.6667 17.1999 18.456 17.7081 18.081 18.0828C17.7059 18.4575 17.1972 18.668 16.6668 18.668H12.0117C11.071 18.6676 10.141 18.4691 9.2823 18.0855C8.42358 17.7018 7.65543 17.1417 7.02794 16.4416C6.39742 15.7418 5.92395 14.9154 5.63927 14.0178C5.35459 13.1203 5.26529 12.1723 5.37735 11.2374C5.5543 9.76286 6.21744 8.38907 7.26223 7.33262C8.30701 6.27616 9.67402 5.59714 11.1477 5.4026C11.4352 5.3666 11.7247 5.34836 12.0144 5.34799C13.568 5.34375 15.0734 5.88605 16.2668 6.87979C16.9638 7.4585 17.5359 8.1726 17.9484 8.97877C18.361 9.78495 18.6053 10.6664 18.6667 11.5698V16.67Z"
            className="icon-cta-fill"
          />
          <path
            d="M9.18508 10.2221H12.148C12.3445 10.2221 12.5329 10.1285 12.6718 9.96176C12.8107 9.79507 12.8888 9.56897 12.8888 9.33322C12.8888 9.09748 12.8107 8.87138 12.6718 8.70469C12.5329 8.53799 12.3445 8.44434 12.148 8.44434H9.18508C8.98862 8.44434 8.80021 8.53799 8.66129 8.70469C8.52238 8.87138 8.44434 9.09748 8.44434 9.33322C8.44434 9.56897 8.52238 9.79507 8.66129 9.96176C8.80021 10.1285 8.98862 10.2221 9.18508 10.2221Z"
            className="icon-cta-fill"
          />
          <path
            d="M14.8443 11.1113H9.15545C8.96685 11.1113 8.78597 11.205 8.65262 11.3717C8.51926 11.5384 8.44434 11.7645 8.44434 12.0002C8.44434 12.236 8.51926 12.4621 8.65262 12.6288C8.78597 12.7955 8.96685 12.8891 9.15545 12.8891H14.8443C15.0329 12.8891 15.2138 12.7955 15.3472 12.6288C15.4805 12.4621 15.5554 12.236 15.5554 12.0002C15.5554 11.7645 15.4805 11.5384 15.3472 11.3717C15.2138 11.205 15.0329 11.1113 14.8443 11.1113Z"
            className="icon-cta-fill"
          />
          <path
            d="M14.8443 13.7773H9.15545C8.96685 13.7773 8.78597 13.871 8.65262 14.0377C8.51926 14.2044 8.44434 14.4305 8.44434 14.6662C8.44434 14.902 8.51926 15.1281 8.65262 15.2948C8.78597 15.4615 8.96685 15.5551 9.15545 15.5551H14.8443C15.0329 15.5551 15.2138 15.4615 15.3472 15.2948C15.4805 15.1281 15.5554 14.902 15.5554 14.6662C15.5554 14.4305 15.4805 14.2044 15.3472 14.0377C15.2138 13.871 15.0329 13.7773 14.8443 13.7773Z"
            className="icon-cta-fill"
          />
        </svg>
        <span className="app-sidebar-logo-default ms-[10px]">
          {formatMessage({ id: "My Chats" })}
        </span>
      </Link>
      <Link
        className=""
        to="/"
        target="_blank"
        data-bs-dismiss-="click"
        title="Metronic Docs & Components"
      >
        {process.env.REACT_APP_ENVIRONMENT == "development" ? (
          <img
            className={"mx-auto"}
            width={134}
            src="../media/auth/logo-dev.png"
          />
        ) : (
          <>
            <div className="app-sidebar-logo-default logo-container-big h-[26px]" />
            <div className="mt-1 logo-container-small h-20px app-sidebar-logo-minimize w-100" />
          </>
        )}
      </Link>
    </div>
  );
};

export { SidebarFooter };
