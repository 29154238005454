import { ErrorMessage, Field, FieldProps, getIn, useField, useFormikContext } from "formik";
import React from "react";
import Select, { StylesConfig } from "react-select";
import { StateManagerProps } from "react-select/dist/declarations/src/useStateManager";
import clsx from "clsx";
import { ToolTipUI } from "../../components/UI/ToolTipUI";
import { inspect } from "util";
import { useTheme } from "react-query/types/devtools/theme";
import { useThemeMode } from "../../../../../_metronic/partials";
import { isEqual } from "lodash";

type MyOption = {
  label: string;
  value: any;
};

type GroupedOption = {
  label: string; // group label
  options: MyOption[];
};

type Props = {
  name: string;
  label: string;
  tooltipText: string;
  isFieldArray: boolean;
  hasMarginBottom?: boolean;
  hasErrorMessage?: boolean;
  isStarRequired?: boolean;
  noRightBorder?: boolean;

} & Omit<
  StateManagerProps<MyOption, false | true, GroupedOption>,
  "value" | "onChange"
>;

const FormikReactSelect: React.FC<any> = (props: Props) => {
  const { mode } = useThemeMode();
  const { name, className,hasErrorMessage=true, label, tooltipText, isFieldArray, defaultValue,hasMarginBottom=true, isStarRequired=false, noRightBorder =false, ...restProps } =
    props;
  const [field] = useField(name);
  const { setFieldValue } = useFormikContext();

  const colourStyles: StylesConfig<MyOption, false | true, GroupedOption> = {
    control: (styles, { isFocused }) => ({
      // ...styles,
      display:"flex",
      ...(
        noRightBorder ?
        {
          textAlign: 'right',
          borderTopLeftRadius:'0.475rem',
          borderBottomLeftRadius: '0.475rem',
          borderTopRightRadius:'0rem',
          borderBottomRightRadius:'0rem',
        }
        :
        {borderRadius: '0.475rem'}
      ),
      height:"40px",
      backgroundColor: isEqual(mode, "light") ? "#F5F8FA" : "#2E2F45",
      borderWidth: isEqual(mode, "light") ? "1px":"0px",
      borderColor: isEqual(mode, "light") ? "#DBDCDE" : "#ffffff1a",
      boxShadow: "none",
      color: isEqual(mode, "light") ? "#383940" : "#FFFFFFCC",
    }),
    menu: (styles) => {
      return {
        ...styles,
        backgroundColor: isEqual(mode, "light") ? "#F5F8FA" : "#2E2F45",
        color: isEqual(mode, "light") ? "#383940" : "#FFFFFFCC",
      };
    },
    option: (styles, { data, isDisabled, isFocused, isSelected, }) => {
      return {
        ...styles,
        // color: isSelected  ,
        // color: isDisabled
        //   ? "#red"
        //   : isSelected
        //   ? isEqual(mode, "light")
        //     ? "#383940"
        //     : "#FFFFFFCC"
        //   : isEqual(mode, "light")
        //   ? "#383940"
        //   : "#FFFFFFCC",
        cursor: isDisabled ? "not-allowed" : "default",
        // ":active": {
        //   ...styles[":active"],
        // },
      };
    },

    input: (styles) => ({ ...styles,
      color: isEqual(mode, "light") ? "#383940" : "#FFFFFFCC",
     }),
    placeholder: (styles) => ({ ...styles ,
    color: isEqual(mode, "light") ? "#a1a5b7" : "#FFFFFFA6",}),
    singleValue: (styles, { data }) => ( {
      ...styles,
      color: isEqual(mode, "light") ? "#383940" : "#FFFFFFCC",
    }),
    menuList: (styles) => ({
      ...styles,
      color: isEqual(mode, "light") ? "#383940" : "#FFFFFFCC",
    }),
    multiValue: (styles) => {
      return {
        ...styles,
        alignItems: "center",
        backgroundColor: isEqual(mode, "light") ? "#F5F8FA" : "#171825",
        borderRadius: "40px",
        padding: "2px 10px",
        color: isEqual(mode, "light") ? "#383940" : "#FFFFFFCC",
      };
    },
  };

  //flatten the options so that it will be easier to find the value
  const flattenedOptions = props.options?.flatMap((o: any) => {
    const isNotGrouped = "value" in o;
    if (isNotGrouped) {
      return o;
    } else {
      return o.options;
    }
  });

  //get the value using flattenedOptions and field.value
  const value = flattenedOptions?.filter((o: any) => {
    const isArrayValue = Array.isArray(field.value);
    // if(defaultValue){
    //   return defaultValue
    // }
    if (isArrayValue) {
      const values = field.value as Array<any>;
      return values.includes(o.value);
    } else {
      return field.value === o.value;
    }
  });

  const FieldArrayErrorMessage = ({ name }: any) => (
    <Field
      name={name}
    >
      {({ field,form,meta }:FieldProps) => {
        const error = getIn(form.errors, name);
        const touch = getIn(form.touched, name);

        return touch && error ? error : null;
      }}
    </Field>
  );
  return (
    <div className={hasMarginBottom ? "mb-[16px] md:mb-[24px]":""}>
      {label &&
      <label
        className={
          "flex gap-2 items-center text-[13px] leading-[20px] primary-text mb-[4.5px]"
        }
      >
        {label}{isStarRequired ? '*': ''}
        <ToolTipUI tooltipText={tooltipText} />
      </label>}
      <Select
        {...restProps}
          classNames={{
            option: (state) => {
              if(state.isFocused){
                return "!text-[#000] !cursor-pointer"
              }else  if(state.isSelected){
                return "!text-[#000] !cursor-pointer !bg-[#C2D24B]"
              }
              else{
                return  "text-blue-400"
              }
            }
          }}
        value={value}
        // menuIsOpen={true}
        // onChange implementation
        onChange={(val) => {
          //here I used explicit typing but there maybe a better way to type the value.
          const _val = val as MyOption[] | MyOption;
          const isArray = Array.isArray(_val);
          if (isArray) {
            const values = _val.map((o) => o.value);
            setFieldValue(name, values);
          } else {
            setFieldValue(name, _val.value);
          }
        }}
        getOptionLabel={(option) => {
          if(noRightBorder){
            return option.value === field.value ? `+${field.value?.slice(0,-2)}` : option.label
          }else{
            return option.label
          }
        }}
        className={clsx(className, "react-select")}
        styles={colourStyles}
        theme={(theme) => ({
          ...theme,
          colors: {
            ...theme.colors,
            primary25: "#C2D24B",
            primary: "#FFFFFF1A",
          },
        })}
      />
      {hasErrorMessage &&
      <div className="text-danger mt-2">
        {isFieldArray ? (
          <FieldArrayErrorMessage name={name} />
        ) : (
          <ErrorMessage name={name} />
        )}
      </div>}
    </div>
  );
};

export default FormikReactSelect;
