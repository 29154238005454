import clsx from 'clsx'
import { get, map } from 'lodash'
import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import request from '../../../../../core/_apis'
import { kmbConvertor } from '../../../../../utils'
import { useAuth } from '../../../../auth'
import { ComponentSpinner } from '../../../../widgets/components/General/Spinner'
import Card from '../../card'

const TrendingPersonalities = ({ category, heading, ...props }: { category: string, heading: string }) => {
    const { ipStackCountry } = useAuth()
    const [loading, setLoading] = useState(true)
    const [personalities, setPersonalities] = useState([])

    const fetchLatest = async () => {
        setLoading(true)
        try {
            let response = await request.get('/trending_personalities', {
                params: {
                    resourceType: 'trending',
                    time_frame: '7d',
                    category: category,
                    // country_id: ipStackCountry.countryId,
                    quantity: 5
                }
            })
            if (response.status === 200) {
                let data = response.data.data || []
                let rank = 0
                data = data.map((el: any) => {
                    return {
                        personalityId: el.personalityId,
                        username: el.personality.username,
                        image: el.personality.image,
                        name: el.personality.name,
                        category: el.personality.category,
                        isVerified: el.personality.isVerified,
                        followers: el.followers,
                        likes: el.likes,
                        users: el.personality.users,
                        interactions: el.interactions
                    };
                })
                setPersonalities(data)
            }
            setLoading(false)
        } catch (e) {
            setLoading(false)
        }
    }

    useEffect(() => {
        fetchLatest()
    }, [])

    if(loading){
        return <ComponentSpinner/>
    }

    return (
        <div>
            <div className="flex md:justify-between flex-row md:items-center my-[27px] mb-[25px]">
                <h2 className="text-[24px] lg:text-[32px] leading-[32px] lg:leading-[45px] font-semibold primary-text">
                    {heading}
                </h2>
                <Link
                    className="flex flex-row whitespace-nowrap align-items-center ml-auto sm:ml-0 main-text text-[16px] leading-[24px] font-weight-500"
                    to={`/marketplace/explore?category=${category}`}
                >
                    View all
                    <svg
                        className="ml-[10px]"
                        width="6"
                        height="10"
                        viewBox="0 0 6 10"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            d="M1 1L5 5L1 9"
                            stroke="#C2D24B"
                            strokeWidth="1.6"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                        ></path>
                    </svg>
                </Link>
            </div>
            <div className={"flex-grow transition-all duration-300"}>
                {loading ? (
                    <ComponentSpinner />
                ) : (
                    <div
                        className={clsx(
                            "grid grid-cols-12  gap-x-[8px] md:gap-x-[18px] gap-y-5 mb-9",
                            "xl:grid-cols-10 2xl:grid-cols-12"
                        )}
                    >
                        {map(personalities, (el) => (
                            <div
                                className={clsx(
                                    "col-span-12    2xl:col-span-2",
                                    "xl:col-span-2 lg:col-span-3 md:col-span-4"
                                )}
                                key={get(el, "id")}
                            >
                                <Card
                                    id={get(el, "personalityId")}
                                    slug={get(el, "username")}
                                    image={get(el, "image")}
                                    name={get(el, "name")}
                                    occupation={get(el, "category")}
                                    isVerified={get(el, "isVerified")}
                                    followers={get(el, "followers")}
                                    likes={get(el, "likes")}
                                    owners={get(el, "users")}
                                    interactions={get(el, "interactions")}
                                />
                            </div>
                        ))}
                    </div>
                )}
            </div>
        </div>
    )
}

export default TrendingPersonalities