import React, { useState } from "react";
import Breadcrumb from "./components/breadcrumb";
import { Link } from "react-router-dom";
import GetApiModal from "../marketplace-public/components/ai-profile/modals/get-api-modal";
import SubscribeApiModal from "../marketplace-public/components/ai-profile/modals/subscribe-api-modal";
import SubscribeModal from "../marketplace-public/components/ai-profile/modals/subscribe-modal";
import LicenceModal from "../marketplace-public/components/ai-profile/modals/licence-modal";
import { useAuth } from "../auth";

const Index = () => {
  const [openModal1, setOpenModal1] = useState(false);
  const [openModal2, setOpenModal2] = useState(false);
  const [openModal3, setOpenModal3] = useState(false);
  const [openModal4, setOpenModal4] = useState(false);
  const {currentUser,selectedPersonality} = useAuth()
  return (
    <div>
      <Breadcrumb />

      <table className="table-auto border-collapse border w-full rounded">
        <thead>
          <tr>
            <th className={"border px-2 py-2"}>Page Name</th>
            <th className={"border px-2 py-2"}>Page Link</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td className={"border px-2 py-2"}>Login</td>
            <td className={"border px-2 py-2"}>
              <Link to={"/auth/login"} className={"text-blue-500"}>
                {" "}
                Login
              </Link>
            </td>
          </tr>
          <tr>
            <td className={"border px-2 py-2"}>Login</td>
            <td className={"border px-2 py-2"}>
              <Link to={"/auth/registration"} className={"text-blue-500"}>
                {" "}
                Registration
              </Link>
            </td>
          </tr>
          <tr>
            <td className={"border px-2 py-2"}>Login</td>
            <td className={"border px-2 py-2"}>
              <Link to={"/auth/forgot-password"} className={"text-blue-500"}>
                {" "}
                Forgot Password
              </Link>
            </td>
          </tr>
          <tr>
            <td className={"border px-2 py-2"}>Onboarding</td>
            <td className={"border px-2 py-2"}>
              <Link to={"/onboarding"} className={"text-blue-500"}>
                {" "}
                Onboarding
              </Link>
            </td>
          </tr>
          <tr>
            <td className={"border px-2 py-2"}>Onboarding Member</td>
            <td className={"border px-2 py-2"}>
              <Link to={"/onboarding/team-members"} className={"text-blue-500"}>
                {" "}
                Onboarding Member
              </Link>
            </td>
          </tr>
          <tr>
            <td className={"border px-2 py-2"}>Onboarding Detail</td>
            <td className={"border px-2 py-2"}>
              <Link
                to={"/onboarding/create-ai-personality"}
                className={"text-blue-500"}
              >
                {" "}
                Onboarding Detail
              </Link>
            </td>
          </tr>
          <tr>
            <td className={"border px-2 py-2"}>Public User Profile</td>
            <td className={"border px-2 py-2"}>
              <Link
                to={`/u/${currentUser?.username}`}
                className={"text-blue-500"}
              >
                {" "}
                Public User Profile
              </Link>
            </td>
          </tr>
          <tr>
            <td className={"border px-2 py-2"}>Personality Profile</td>
            <td className={"border px-2 py-2"}>
              <Link
                to={`/p/${selectedPersonality?.username}`}
                className={"text-blue-500"}
              >
                {" "}
                Personality Profile
              </Link>
            </td>
          </tr>
          <tr>
            <td className={"border px-2 py-2"}>Marketplace Public</td>
            <td className={"border px-2 py-2"}>
              <Link to={"/marketplace-public"} className={"text-blue-500"}>
                {" "}
                Marketplace Public
              </Link>
            </td>
          </tr>
          
          <tr>
            <td className={"border px-2 py-2"}>Marketplace Public</td>
            <td className={"border px-2 py-2"}>
              <Link
                to={"/marketplace-public/without-register"}
                className={"text-blue-500"}
              >
                Marketplace Public Without Register
              </Link>
            </td>
          </tr>
          <tr>
            <td className={"border px-2 py-2"}>Marketplace Public</td>
            <td className={"border px-2 py-2"}>
              <Link
                to={"/marketplace"}
                className={"text-blue-500"}
              >
                Marketplace Unlocking Personalized
              </Link>
            </td>
          </tr>
          <tr>
            <td className={"border px-2 py-2"}>Marketplace Public Discovery</td>
            <td className={"border px-2 py-2"}>
              <Link
                to={"/marketplace/explore"}
                className={"text-blue-500"}
              >
                Marketplace Unlocking Personalized Discover
              </Link>
            </td>
          </tr>
          <tr>
            <td className={"border px-2 py-2"}>Marketplace Public Discovery</td>
            <td className={"border px-2 py-2"}>
              <Link
                to={"/my-chats"}
                className={"text-blue-500"}
              >
                Marketplace Chat
              </Link>
            </td>
          </tr>
        </tbody>
      </table>
      <div className={"flex gap-x-6 items-center mt-10"}>
        <button
          className={"px-6 py-3.5 bg-blue-400 rounded"}
          onClick={() => setOpenModal1(true)}
        >
          Modal 1
        </button>
        <button
          className={"px-6 py-3.5 bg-blue-400 rounded"}
          onClick={() => setOpenModal2(true)}
        >
          Modal 2
        </button>
        <button
          className={"px-6 py-3.5 bg-blue-400 rounded"}
          onClick={() => setOpenModal3(true)}
        >
          Modal 3
        </button>
        <button
          className={"px-6 py-3.5 bg-blue-400 rounded"}
          onClick={() => setOpenModal4(true)}
        >
          Modal 4
        </button>
        <SubscribeApiModal open={openModal1} setOpen={setOpenModal1} />
        <GetApiModal open={openModal2} setOpen={setOpenModal2} />
        <SubscribeModal open={openModal3} setOpen={setOpenModal3} />
        <LicenceModal open={openModal4} setOpen={setOpenModal4} />
      </div>
    </div>
  );
};

export default Index;
