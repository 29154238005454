import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { BasicButton } from "../../../widgets/components/UI/BasicButton";
import { useIntl } from "react-intl";
import { range, map } from "lodash";
import FormItem from "./form-item";
import { QUOTA_ITEM_CHARGES } from "../../../../core/charges";
import { accessPortalForQuotaUpgrade } from "../../core/_requests";
import { useAuth } from "../../../auth";
import { toast } from "react-toastify";
import useGTM from "../../../../hooks/useGTM";
import { GTMEvent } from "../../../../hooks/gtm_helpers";

const Index: React.FC<any> = ({ openUpgrade, setOpenUpgrade }) => {
  const { formatMessage } = useIntl();
  const [totalPrice, setTotalPrice] = useState(0);
  const [appliedDiscount, setAppliedDiscount] = useState<boolean>(false);
  const [discount, setDiscount] = useState<any>(null);
  const [discountPrice, setDiscountPrice] = useState<any>(0);
  const [selection, setSelection] = useState<any>({})
  const [showModal, setShowModal] = useState(false);
  const [urlData, setUrlData] = useState("");
  const [loading, setLoading] = useState(false);
  const {selectedPersonality} = useAuth();
  const {dataLayerPush} = useGTM()
  useEffect(()=>{
    let price = 0
    Object.keys(selection).forEach((key:string)=>{
      price+=selection[key]*QUOTA_ITEM_CHARGES[key]
    })
    setTotalPrice(price)
  },[selection])

  async function getPortalLink() {
    setLoading(true);
    let {
      data: { success, data },
    } = await accessPortalForQuotaUpgrade(selectedPersonality.personalityId, selection);
    if (success) {
      if (data) {
        setUrlData(data);
        setShowModal(true);
      }
      dataLayerPush(GTMEvent.PaymentPortalOpenedSuccessful)
    }else {
      toast.error('Error while connecting to payment portal. Please retry later.',
      {
        autoClose: false,
        closeButton: true, // Display close button (cross icon)
      })
      dataLayerPush(GTMEvent.PaymentPortalOpenedFailed)
    }
    setLoading(false);
  }

  const quotas = [
    {
      title: `${formatMessage({ id: "Chat & Conversation tokens" })}`,
      price: QUOTA_ITEM_CHARGES.chatTokens,
      priceFor: "million",
      key:"chatTokens"
    },
    {
      title: `${formatMessage({ id: "Stock voice usage tokens" })}`,
      price: QUOTA_ITEM_CHARGES.stockVoice,
      priceFor: "million",
      key:"stockVoice"
    },
    {
      title: `${formatMessage({ id: "Cloned voice quota" })}`,
      price: QUOTA_ITEM_CHARGES.clonedVoice,
      priceFor: "100k",
      key:"clonedVoice"
    },
    {
      title: `${formatMessage({ id: "Hyper-realistic avatar quota" })}`,
      price: QUOTA_ITEM_CHARGES.hyperRealisticAvatar,
      priceFor: "100 mins",
      key:"hyperRealisticAvatar"
    },
    {
      title: `${formatMessage({ id: "Number of training chats" })}`,
      price:  QUOTA_ITEM_CHARGES.chatTraining,
      priceFor: "chat",
      key:"chatTraining"
    },
    {
      title: `${formatMessage({ id: "Number of public training links" })}`,
      price:  QUOTA_ITEM_CHARGES.linkTraining,
      priceFor: "file",
      key:"linkTraining"
    },
    {
      title: `${formatMessage({ id: "Number of Private training files" })}`,
      price:  QUOTA_ITEM_CHARGES.fileTraining,
      priceFor: "file",
      key:"fileTraining"
    },
  ];

  return (
    <div>
      <Modal
        show={openUpgrade}
        animation={false}
        onHide={() => setOpenUpgrade(!openUpgrade)}
        centered
        size={"lg"}
        contentClassName={"max-w-[720px]"}
      >
        <Modal.Header closeButton>
          <div className={"flex justify-between items-center !w-full"}>
            <h2
              className={
                "text-[20px] leading-[28px] font-semibold primary-text"
              }
            >
              {formatMessage({ id: "Increase Usage Quota" })}
            </h2>
            <button onClick={() => setOpenUpgrade(false)}>
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M16.2426 6.34359L6.34309 16.2431C5.95257 16.6336 5.95257 17.2668 6.34309 17.6573C6.73362 18.0478 7.36678 18.0478 7.75731 17.6573L17.6568 7.75781C18.0473 7.36728 18.0473 6.73412 17.6568 6.34359C17.2663 5.95307 16.6331 5.95307 16.2426 6.34359Z"
                  fill="#C2D24B"
                  fillOpacity="0.5"
                />
                <path
                  opacity="0.3"
                  d="M17.6569 16.2423L7.7574 6.34285C7.36688 5.95232 6.73371 5.95232 6.34319 6.34285C5.95266 6.73337 5.95266 7.36654 6.34319 7.75706L16.2427 17.6566C16.6332 18.0471 17.2664 18.0471 17.6569 17.6566C18.0474 17.266 18.0474 16.6329 17.6569 16.2423Z"
                  fill="#C2D24B"
                  fillOpacity="0.5"
                />
              </svg>
            </button>
          </div>
        </Modal.Header>
        <Modal.Body>
          {map(quotas, (item) => (
            <FormItem
              data={item}
              setSelection={setSelection}
            />
          ))}
          <div className={"py-3.5 border-y border-main"}>
            <div
              className={"grid grid-cols-12 gap-y-3.5 md:gap-x-5 primary-text"}
            >
              <div
                className={
                  "col-span-12 md:col-span-6 flex items-center justify-between"
                }
              >
                {/* {appliedDiscount ? (
                  <div
                    className={
                      "text-[14px] text-[#C2D24B] leading-[20px] flex gap-x-1 underline"
                    }
                  >
                    {discount}{" "}
                    <button
                      className={
                        "w-[20px] h-[20px] bg-[#C2D24B1A] flex justify-center items-center rounded"
                      }
                      onClick={() => {
                        setDiscount(null);
                        setDiscountPrice(0);
                        setAppliedDiscount(false);
                      }}
                    >
                      <svg
                        width="8"
                        height="8"
                        viewBox="0 0 8 8"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M7.32669 1.55624L4.91687 3.96606L7.36063 6.40982L6.39897 7.37149L3.95521 4.92772L1.5567 7.32623L0.685544 6.45507L3.08405 4.05657L0.628975 1.60149L1.59064 0.639829L4.04571 3.0949L6.45553 0.685084L7.32669 1.55624Z"
                          fill="#C2D24B"
                        />
                      </svg>
                    </button>
                  </div>
                ) : (
                  <div className={"flex w-full gap-x-1"}>
                    <input
                      className={
                        "form-control h-[36px] font-size-12 text-primary border-main secondary-bg  hover:border-blue-500 md:!max-w-[200px]  md:flex-grow-0"
                      }
                      placeholder={formatMessage({ id: "Enter Promo Code" })}
                      onChange={(e) => {
                        setDiscount(e.target.value);
                      }}
                    />
                    <button
                      className={
                        "text-[14px] leading-[20px] font-medium export-button rounded px-4"
                      }
                      onClick={() => {
                        if (discount.length > 6) {
                          setAppliedDiscount(true);
                          setDiscountPrice(31);
                        } else {
                          return;
                        }
                      }}
                    >
                      {formatMessage({ id: "Apply" })}
                    </button>
                  </div>
                )} */}
              </div>
              <div
                className={
                  "col-span-12 md:col-span-6 flex items-center justify-between "
                }
              >
                <div>
                  {appliedDiscount ? (
                    <p>{formatMessage({ id: "Promo Discount" })}</p>
                  ) : (
                    ""
                  )}
                  <p> {formatMessage({ id: "Grand Total" })}</p>
                </div>
                <div className={"text-[16px] leading-[24px] font-semibold"}>
                  {appliedDiscount ? <p>- $ {discountPrice}</p> : ""}
                  <p>$ {totalPrice - discountPrice}</p>
                </div>
              </div>
            </div>
          </div>
          <div className="flex flex-wrap justify-end gap-3 mt-5">
            <BasicButton
              buttonText={formatMessage({ id: "Cancel" })}
              customClass={"px-[14px] md:px-[24px] py-[8px] md:py-4  main-button-primary-light"}
              onClick={() => setOpenUpgrade(false)}
            />
            <BasicButton
              buttonText={formatMessage({ id: "Pay Now" })}
              disabled={!Object.keys(selection).length}
              loading={loading}
              onClick={getPortalLink}
              customClass={"px-[24px] export-button"}
            />
          </div>
        </Modal.Body>
      </Modal>
      <Modal
        show={showModal}
        onHide={() => setShowModal(false)}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdropClassName="sub-modal-2"
        className="sub-modal-2"
      >
        <Modal.Body>
          <iframe
            title="Add Credits"
            src={urlData}
            width="100%"
            height={600}
          ></iframe>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default Index;
