import React, { useState } from "react";
import { Outlet, useLocation } from "react-router-dom";
import Header from "./header";
import Footer from "./footer";
import { ToastContainer } from "react-toastify";
import { includes } from "lodash";

const Index = () => {
  const { pathname } = useLocation();
  const [openChat, setOpenChat] = useState(false);

  const handleChat = () => {
    setOpenChat((prevState) => !prevState);
  };
  return (
    <div className={" h-full  min-h-screen"}>
      <div className={""}>
        <Header handleChat={handleChat} isMarketPlace={true} />
        <div className={"pt-[80px]"}>
          <Outlet context={[openChat]} />
        </div>
        {!includes(["/my-chats"], pathname) && (
          <Footer />
        )}
      </div>
      <ToastContainer />
    </div>
  );
};

export default Index;
