import { Formik, Form } from "formik";
import React, { useEffect, useState } from "react";
import { SelectInput } from "../../widgets/components/Input/SelectInput";
import TextInput from "../../widgets/components/Input/TextInput";
import { CustomButton } from "../../widgets/components/UI/CustomButton";
import { InfoCard } from "../../widgets/components/UI/InfoCard";
import { useIntl } from "react-intl";
import { createPersonality, createRound } from "../core/_requests";
import { useLocation, useNavigate } from "react-router-dom";
import { useAuth } from "../../auth";
import { roundTypeOptions } from "../core/_constants";
import { toast } from "react-toastify";
import { Toaster } from "../../widgets/components/General/Toaster";
import { useInitializeRoundSchema } from "../../../hooks/useInitializeRoundSchema";
import { roundInitialValues } from "../../../core/_constants";
import { useCurrency } from "../../../hooks/useCurrency";
import TextArea from "../../widgets/components/Input/TextArea";
import {
  getAllPersonalities,
  getUserByToken,
  verifyToken,
} from "../../auth/core/_requests";

import Lottie from "lottie-react";
import * as Yup from "yup";

import processingJson from "../../../assets/lotties/processing-animation.json";
import useGTM from "../../../hooks/useGTM";
import {
  GTMEvent,
  basePersonalityMeta,
  loginSuccessMeta,
} from "../../../hooks/gtm_helpers";

export const InitializeRound = () => {
  const [loading, setLoading] = useState(false);
  const [creating, setCreating] = useState(false);
  const [selectedCurrency, setSelectedCurrency] = useState("USD");
  const { formatMessage } = useIntl();
  const navigate = useNavigate();
  const {
    storePersonalityId,
    setCurrentUser,
    referenceData,
    onboardingData,
    setOnboardingData,
    setNewPersonality,
    currentUser,
    nextRoute
  } = useAuth();
  // const { intializeRoundSchema } = useInitializeRoundSchema();

  const initCreatePersonalitySchema = Yup.object().shape({
    // brand: Yup.string()
    //   .required("Brand is required")
    //   .oneOf(["Amazon Poly", "11labs", "Custom"], "Invalid brand"),
    category: Yup.string().nullable().required("Category is required"),
    description: Yup.string()
      .required("Description is required")
      .min(50, "Minimum 50 characters are required."),
  });
  const { currencyOptions } = useCurrency();
  const { dataLayerPush } = useGTM()
  const location = useLocation()
  const locationStateData:any = location.state

  useEffect(() => {
    if(referenceData?.platformConfig?.EXPRESS_ONBOARDING && locationStateData?.onboardData){
      onSubmit(locationStateData?.onboardData,{})
    }
  },[])

  const onSubmit = async (values: any, actions: any) => {
    let onboarding = { ...onboardingData, ...values };
    setOnboardingData(onboarding);

    // navigate("/onboarding/team-members");
    try {
      // const chargebeePlanId = `${selected}-${currencyBill}-${currentState}`;
      setCreating(true);
      const {
        data: { data, success, errors },
      } = await createPersonality(
        values.personalityName,
        values.industry,
        values.country,
        values.state,
        values.logoId,
        onboarding.chargebeePlanId,
        onboarding.description,
        onboarding.category
      );
      if (success) {
        setLoading(false);
        const {
          data: { success, data: value },
        } = await verifyToken(data.token);
        if (success) {
          const { data: response } = await getUserByToken(data.token);
          const {
            data: { data: personalities },
          } = await getAllPersonalities();
          response.data.personalities = personalities;
          if (currentUser?.personality?.length == 0 || false) {
            let userData = response.data
            userData.personality.planId = onboarding.chargebeePlanId
            dataLayerPush(
              GTMEvent.OnboardingCompletedSuccessful,
              loginSuccessMeta(userData)
            );
            dataLayerPush(
              GTMEvent.LoginSuccess,
              loginSuccessMeta(userData)
            );
          } else {
            let personalityData = data.personality
            personalityData.planId = onboarding.chargebeePlanId
            dataLayerPush(
              GTMEvent.NewPersonalityCreationCompletedSuccessful,
              basePersonalityMeta(
                personalityData,
                (currentUser?.personalities?.length || 0) + 1
              )
            );
          }
          if (response) {
            // setNewPersonality(undefined);
            setOnboardingData(null);
            // setCurrentUser(response.data);
            // storePersonalityId(data.personalityId);
            // setTimeout(() => {
            // navigate("/dashboard");
            // });
            //need reload for now to propagate the new personality
            // storePersonalityId(data.personalityId);
            localStorage.setItem("personalityId", data.personalityId);
            await getUserByToken('', data.personalityId)
            setTimeout(() => {
              window.location.href = window.location.origin + (nextRoute ? nextRoute : '/marketplace');
            }, 0);
          }
        }
      } else {
        errors.forEach((error: string) => {
          toast.error(formatMessage({ id: error }),
          {
            autoClose: false,
            closeButton: true, // Display close button (cross icon)
          });
        });
        setLoading(false);
        setCreating(false);
      }
    } catch (error) {
      if (currentUser?.personality?.length == 0 || false) {
        dataLayerPush(GTMEvent.OnboardingCompletedFailed, {
          email: currentUser.email,
        });
        dataLayerPush(GTMEvent.LoginFailed, { email: currentUser.email });
      } else {
        dataLayerPush(GTMEvent.NewPersonalityCreationCompletedFailed);
      }
      setLoading(false);
      console.error(error);
    }
  };

  const cancelPersonalityAdd = () => {
    setNewPersonality(undefined);
    setOnboardingData(undefined);
    navigate("/dashboard");
  };

  return (
    <>
      <Toaster />
      {creating ? (
        <div className="flex-col mb-20 mt-14 d-flex align-items-center">
          <Lottie
            className=""
            animationData={processingJson}
            style={{
              width: 415,
              height: 265,
              borderRadius: 25,
              // borderTopLeftRadius: 0,
              // overflow: "hidden",
            }}
          />
          <h5 className="text-[22px] mb-[16px] leading-[32px] font-weight-600 primary-text">
            {formatMessage({
              id: "Please wait...",
            })}
          </h5>
          <p className="text-[13px] leading-[20px] secondary-text max-w-[516px] text-center">
           <br></br> 
            {formatMessage({
              id: "We are taking you to your dashboard.",
            })}{" "}
            <br></br>
            {formatMessage({
              id: "You can start managing your AI personality immediately.",
            })}
          </p>
        </div>
      ) : (
        <Formik
          validationSchema={initCreatePersonalitySchema}
          initialValues={{ ...onboardingData, description: '', category: '' }}
          onSubmit={onSubmit}
          validateOnMount
        >
          {(formik) => {
            formik.values.currency !== "" &&
              currencyOptions.some((currency: any) => {
                if (currency.id === parseInt(formik.values.currency)) {
                  setSelectedCurrency(currency.name.substring(0, 4));
                  return true;
                }
                return null;
              });

            return (
              <Form className="primary-text grid grid-cols-12 md:gap-x-[32px] gap-y-[40px]">
                <div className={"col-span-12 font-medium"}>
                  <h1
                    className={
                      "text-[14px] md:text-[22px] leading-[20px] md:leading-[32px] mb-2 primary-text"
                    }
                  >
                    {formatMessage({
                      id: "Give us some details & background about this AI personality",
                    })}
                  </h1>
                  <p
                    className={
                      "text-[13px] md:text-[16px] leading-[20px] secondary-text font-medium md:font-semibold"
                    }
                  >
                    {formatMessage({
                      id: "This will help this AI personality behave in the way you want.",
                    })}
                  </p>
                </div>
                <div className={"col-span-12 md:col-span-6"}>
                  <TextInput
                    fieldType={"text"}
                    label={formatMessage({ id: "Personality’s Name" })}
                    fieldName={"personalityName"}
                    placeholder={formatMessage({
                      id: "Enter Personality’s Name",
                    })}
                    formik={formik}
                    toolTipText={formatMessage({
                      id: "GLOBAL.TOOLTIP.INITIALIZE_ROUND.ROUND_NAME",
                    })}
                    isStarRequired={true}
                  />
                  <SelectInput
                    label={formatMessage({ id: "Category" })}
                    fieldName={"category"}
                    placeholder={formatMessage({
                      id: "Select Category",
                    })}
                    formik={formik}
                    toolTipText={formatMessage({
                      id: "GLOBAL.TOOLTIP.AI_PERSONALITY.PERSONALITY_TYPE",
                    })}
                    margin={"me-md-14"}
                    options={referenceData?.platformOptions?.categoryPersonalityTypes?.map(
                      (el: any, index: number) => {
                        return {
                          name: el.category,
                          value: el.category,
                          id: index,
                        };
                      }
                    )}
                    isStarRequired={true}
                  />
                  <TextArea
                    fieldName={"description"}
                    label={formatMessage({ id: "Description" })}
                    toolTipText={formatMessage({
                      id: "GLOBAL.TOOLTIP.AI_PERSONALITY.PERSONALITY_DESCRIPTION",
                    })}
                    formik={formik}
                    placeholder={formatMessage({
                      id: "Write a paragraph describing who your character is",
                    })}
                    isStarRequired={true}
                  />
                  <p
                    className={"!text-[12px] !leading-[18px] text-[#FFFFFFA6]"}
                  >
                    <span className={"text-[#FFFFFFCC] font-semibold"}>
                      {formatMessage({
                        id: "Note",
                      })}{" "}
                      :
                    </span>{" "}
                    {formatMessage({
                      id: "There are tons of customizations options available for the AI personality fine-tuning, you can edit the above fields along with others later.",
                    })}
                  </p>
                </div>
                <div className={"col-span-12 md:col-span-6"}>
                  <InfoCard
                    title={formatMessage({ id: "Why we need this?" })}
                    desc={
                      <>
                        {formatMessage({
                          id: "The more detail & clear information you provide the better this AI personality behave without much training. You can always modify this detail later from the dashboard.",
                        })}
                        <br></br>
                        <br></br>
                      </>
                    }
                    slug={""}
                    isAnchor={"#"}
                  />
                </div>
                <div className={"col-span-12 flex justify-end"}>
                  <div className="mr-4">
                    {(currentUser?.personality?.length > 0 || false) && (
                      <button
                        type="reset"
                        className="main-button-primary-light  text-[14px] leading-5 font-medium py-[12px] w-1/2 md:w-[110px] rounded"
                        onClick={cancelPersonalityAdd}
                      >
                        <span className="text-[14px] font-medium leading-[20px]">
                          {formatMessage({
                            id: "Cancel",
                          })}
                        </span>
                      </button>
                    )}
                  </div>
                  <div className={"w-full md:w-auto"}>
                    <button
                      type="submit"
                      className="btn btn-primary !w-full md:w-auto"
                    >
                      <span className="text-[14px] font-medium leading-[20px] text-black">
                        {
                          formik.isSubmitting
                            ? formatMessage({ id: "Please Wait" })
                            : formatMessage({ id: "Create Personality" })
                        }
                      </span>
                    </button>
                    {/* <CustomButton
                      isSubmitting={formik.isSubmitting}
                      isValid={formik.isValid}
                      buttonText={
                        formik.isSubmitting
                          ? formatMessage({ id: "Please Wait" })
                          : formatMessage({ id: "Create Personality" })
                      }
                      loading={loading}
                      width={2}
                      customClass={"!w-full md:w-auto export-button"}
                      widthLoading={4}
                      height={44}
                    /> */}
                  </div>
                </div>
              </Form>
            );
          }}
        </Formik>
      )}
    </>
  );
};
