import React, { useEffect } from "react";

import {
  useReactTable,
  getCoreRowModel,
  getFilteredRowModel,
  getFacetedRowModel,
  getPaginationRowModel,
  sortingFns,
  getSortedRowModel,
  FilterFn,
  SortingFn,
  flexRender,
  getExpandedRowModel,
  PaginationState,
  SortingState,
} from "@tanstack/react-table";
// @ts-ignore
import {
  RankingInfo,
  rankItem,
  compareItems,
} from "@tanstack/match-sorter-utils";
// @ts-ignore
import { useExportData } from "react-table-plugins";
import XLSX from "xlsx";
import JsPDF from "jspdf";
import "jspdf-autotable";

import ReactPaginate from "react-paginate";
import ExportTableButton from "./export-table-button";
import { get, isEmpty, isEqual, map } from "lodash";
import FilterTableButton from "./filter-table-button";
import clsx from "clsx";
import SubRow from "./subrow";
import { useQuery } from "react-query";
import { Spinner } from "../modules/widgets/components/General/Spinner";
import { useIntl } from "react-intl";
import Empty from "./empty/Empty";
import { useLocation } from "react-router-dom";

declare module "@tanstack/table-core" {
  interface FilterFns {
    fuzzy: FilterFn<unknown>;
  }
  interface FilterMeta {
    itemRank: RankingInfo;
  }
}

const fuzzyFilter: FilterFn<any> = (row, columnId, value, addMeta) => {
  const itemRank = rankItem(row.getValue(columnId), value);

  addMeta({
    itemRank,
  });

  return itemRank.passed;
};

const fuzzySort: SortingFn<any> = (rowA, rowB, columnId) => {
  let dir = 0;
  if (rowA.columnFiltersMeta[columnId]) {
    dir = compareItems(
      rowA.columnFiltersMeta[columnId]?.itemRank!,
      rowB.columnFiltersMeta[columnId]?.itemRank!
    );
  }

  return dir === 0 ? sortingFns.alphanumeric(rowA, rowB, columnId) : dir;
};

// @ts-ignore
function getExportFileBlob({ columns, data, headers, fileType, fileName }) {
  if (fileType === "xlsx") {
    const header = map(headers, (item) => {
      return {
        header: get(item, "label"),
        propName: get(item, "key"),
      };
    });
    const compatibleData = data.map((row: any) => {
      const obj = {};
      header.forEach((item: any, index) => {
        // @ts-ignore
        obj[item.header] = row[item.propName];
      });
      return obj;
    });
    let wb = XLSX.utils.book_new();
    let ws1 = XLSX.utils.json_to_sheet(compatibleData);
    XLSX.utils.book_append_sheet(wb, ws1, "React Table Data");
    XLSX.writeFile(wb, `${fileName}.xlsx`);
    return false;
  }
  if (fileType === "pdf") {
    const headerNames = columns.map((column: any) => column.header);
    // @ts-ignore
    const body = (data, headers) =>
      map(data, (item) => {
        return map(headers, (header) => get(item, `${header.key}`));
      });

    // @ts-ignore
    const doc = new JsPDF();
    // @ts-ignore
    doc.autoTable({
      head: [headerNames],
      body: body(data, headers),
      margin: { top: 20 },
      styles: {
        minCellHeight: 9,
        halign: "left",
        valign: "center",
        fontSize: 11,
      },
    });
    doc.save(`${fileName}.pdf`);
    return false;
  }
  return false;
}

const CustomTable: React.FC<any> = ({
  tableData,
  pageCount,
  headers,
  columns,
  extra,
  fullTd = false,
  TableSearch = false,
  TablePagination = false,
  type,
  renderSubComponent,
  getRowCanExpand,
  expandedRow,
  loading = false,
  fetchData,
  optionalTrigger,
  emptyControl = {},
  controlBesideSearch,
  enableFilters=true
}) => {
  const { formatMessage } = useIntl();
  const location = useLocation();
  const [globalFilter, setGlobalFilter] = React.useState("");
  const [rowSelection, setRowSelection] = React.useState({});
  const [expanded, setExpanded] = React.useState<any>(true);
  const [sorting, setSorting] = React.useState<SortingState>([])
  const { pathname } = location;

  // Define the pathname where you want to show the ExportTableButton
  const targetPathname = "/monetize-distribute/widgets";

  useEffect(() => {
    setExpanded(expandedRow);
  }, [expanded]);

  const [{ pageIndex, pageSize }, setPagination] =
    React.useState<PaginationState>({
      pageIndex: 0,
      pageSize: 10,
    });

  useEffect(() => {
    setPagination((obj) => {
      return { ...obj, pageIndex: 0 };
    });
    table.setPageIndex(0);
  }, [globalFilter]);

  const triggers: any[] = [pageIndex, pageSize];
  if (optionalTrigger) {
    if(optionalTrigger.includes("search")){
      triggers.push(globalFilter);
      optionalTrigger = optionalTrigger.filter((item:string)=>item!="search")
    }
    triggers.push(...optionalTrigger)
  }
  useEffect(() => {
    fetchData &&
      fetchData({ page: pageIndex, size: pageSize, search: globalFilter });
  }, triggers);

  const pagination = React.useMemo(
    () => ({
      pageIndex,
      pageSize,
    }),
    [pageIndex, pageSize]
  );

  const table = useReactTable({
    data: tableData,
    pageCount: pageCount ?? -1,
    columns,
    getRowCanExpand,
    enableFilters,
    filterFns: {
      fuzzy: fuzzyFilter,
    },
    state: {
      globalFilter,
      rowSelection,
      pagination,
      sorting
    },
    onGlobalFilterChange: setGlobalFilter,
    globalFilterFn: fuzzyFilter,
    getCoreRowModel: getCoreRowModel(),
    onSortingChange: setSorting,
    getFilteredRowModel: getFilteredRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getExpandedRowModel: getExpandedRowModel(),
    onRowSelectionChange: setRowSelection,
    getFacetedRowModel: getFacetedRowModel(),
    enableMultiRowSelection: true,
    debugTable: true,
    manualPagination: true,
    onPaginationChange: setPagination,
  });
  // React.useEffect(() => {
  //   if (table.getState().columnFilters[0]?.id === "fullName") {
  //     if (table.getState().sorting[0]?.id !== "fullName") {
  //       table.setSorting([{ id: "fullName", desc: false }]);
  //     }
  //   }
  // }, [table.getState().columnFilters[0]?.id]);

  const csvData = {
    headers: headers,
    data: tableData,
  };
  return (
    <div
      className={
        "flex flex-col min-h-[60vh] justify-between w-full mt-4 relative"
      }
    >
      <div className={"flex-grow"}>
        {!TableSearch ? (
          <div
            className={
              "flex flex-col md:flex-row gap-y-3.5 justify-between md:items-center"
            }
          >
            <div className={"flex justify-between w-full gap-x-4 md:w-auto"}>
              <div className={"relative flex-1"}>
                <svg
                  className={"absolute top-1/2 -translate-y-1/2 left-2"}
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g clipPath="url(#clip0_497_31689)">
                    <path
                      opacity="0.3"
                      d="M14.2929 16.7071C13.9024 16.3166 13.9024 15.6834 14.2929 15.2929C14.6834 14.9024 15.3166 14.9024 15.7071 15.2929L19.7071 19.2929C20.0976 19.6834 20.0976 20.3166 19.7071 20.7071C19.3166 21.0976 18.6834 21.0976 18.2929 20.7071L14.2929 16.7071Z"
                      fill="#585960"
                    />
                    <path
                      d="M11 16C13.7614 16 16 13.7614 16 11C16 8.23858 13.7614 6 11 6C8.23858 6 6 8.23858 6 11C6 13.7614 8.23858 16 11 16ZM11 18C7.13401 18 4 14.866 4 11C4 7.13401 7.13401 4 11 4C14.866 4 18 7.13401 18 11C18 14.866 14.866 18 11 18Z"
                      fill="#585960"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_497_31689">
                      <rect width="24" height="24" fill="white" />
                    </clipPath>
                  </defs>
                </svg>
                <DebouncedInput
                  value={globalFilter ?? ""}
                  onChange={(value) => setGlobalFilter(String(value))}
                  className="form-control search-input-table h-[42px] md:min-w-[220px] pl-10"
                  placeholder={formatMessage({
                    id: "Search",
                  })}
                />
              </div>
            </div>
            <div
              className={
                "flex items-center  md:gap-x-4 flex-grow md:flex-grow-0 justify-between"
              }
            >
              <div className="w-full md:w-auto">
                {" "}
                {controlBesideSearch ? controlBesideSearch : null}
              </div>
              <div className="">
                {pathname !== targetPathname && (
                  <ExportTableButton
                    getExportFileBlob={getExportFileBlob}
                    data={tableData}
                    headers={headers}
                    columns={columns}
                    csvData={csvData}
                  />
                )}
              </div>
            </div>
          </div>
        ) : null}

        <div className={"grid grid-cols-12 md:gap-x-5"}>
          <div
            className={clsx(
              extra ? "col-span-12 md:col-span-6" : "col-span-12"
            )}
          >
            <div
              className={clsx(
                "w-full overflow-x-auto pb-[50px]",
                extra && "flex flex-col justify-between min-h-full"
              )}
            >
              <table className={"table w-full mt-5 "}>
                <thead className={"primary-text!font-medium"}>
                  {table.getHeaderGroups().map((headerGroup) => (
                    <tr
                      key={headerGroup.id}
                      className={clsx("pb-[14px] border_bottom align-middle")}
                    >
                      {headerGroup.headers.map((header, index) => {
                        if (isEqual(type, "logs")) {
                          if (isEqual(index, 0)) {
                            return (
                              <th
                                key={header.id}
                                colSpan={4}
                                className={
                                  "items-center gap-x-2 whitespace-nowrap"
                                }
                              >
                                {header.isPlaceholder ? null : (
                                  <>
                                    <div
                                      {...{
                                        className: header.column.getCanSort()
                                          ? clsx(
                                              "cursor-pointer select-none flex   gap-x-2 items-center"
                                            )
                                          : "",
                                        onClick:
                                          header.column.getToggleSortingHandler(),
                                      }}
                                    >
                                      {flexRender(
                                        header.column.columnDef.header,
                                        header.getContext()
                                      )}
                                      {header.column.columnDef
                                        .enableSorting && (
                                        <div className={"ml-5 w-5"}>
                                          {{
                                            asc: (
                                              <svg
                                                width="10"
                                                className={"rotate-180 "}
                                                height="5"
                                                viewBox="0 0 10 5"
                                                fill="none"
                                                xmlns="http://www.w3.org/2000/svg"
                                              >
                                                <path
                                                  fillRule="evenodd"
                                                  clipRule="evenodd"
                                                  d="M4.47453 4.91825C4.72478 5.047 5.0462 5.0229 5.26833 4.83826L9.77856 1.08909C10.0575 0.857191 10.0753 0.461669 9.81828 0.205675C9.56124 -0.0503206 9.12672 -0.0698547 8.84774 0.162044L4.82415 3.5067L1.17126 0.194651C0.902716 -0.0488334 0.468013 -0.0480709 0.20032 0.196351C-0.0673723 0.440773 -0.0666847 0.836307 0.201858 1.07979L4.32033 4.81398C4.36714 4.85642 4.41917 4.89118 4.47453 4.91825Z"
                                                  className={"table-sort-icon"}
                                                  fillOpacity="0.65"
                                                />
                                              </svg>
                                            ),
                                            desc: (
                                              <svg
                                                className={"absolute "}
                                                width="10"
                                                height="5"
                                                viewBox="0 0 10 5"
                                                fill="none"
                                                xmlns="http://www.w3.org/2000/svg"
                                              >
                                                <path
                                                  fillRule="evenodd"
                                                  clipRule="evenodd"
                                                  d="M4.47453 4.91825C4.72478 5.047 5.0462 5.0229 5.26833 4.83826L9.77856 1.08909C10.0575 0.857191 10.0753 0.461669 9.81828 0.205675C9.56124 -0.0503206 9.12672 -0.0698547 8.84774 0.162044L4.82415 3.5067L1.17126 0.194651C0.902716 -0.0488334 0.468013 -0.0480709 0.20032 0.196351C-0.0673723 0.440773 -0.0666847 0.836307 0.201858 1.07979L4.32033 4.81398C4.36714 4.85642 4.41917 4.89118 4.47453 4.91825Z"
                                                  className={"table-sort-icon"}
                                                  fillOpacity="0.65"
                                                />
                                              </svg>
                                            ),
                                          }[
                                            header.column.getIsSorted() as string
                                          ] ||
                                            (header.column.columnDef
                                              .enableSorting && (
                                              <svg
                                                className={""}
                                                width="16"
                                                height="16"
                                                viewBox="0 0 16 16"
                                                fill="none"
                                                xmlns="http://www.w3.org/2000/svg"
                                              >
                                                <path
                                                  fillRule="evenodd"
                                                  clipRule="evenodd"
                                                  d="M8.42038 2.56463C8.22015 2.46161 7.96297 2.4809 7.78524 2.62863L4.17645 5.62847C3.95323 5.81402 3.93901 6.13049 4.14467 6.33532C4.35034 6.54015 4.69801 6.55578 4.92123 6.37023L8.14064 3.69406L11.0634 6.34414C11.2783 6.53896 11.6261 6.53835 11.8403 6.34278C12.0545 6.14721 12.054 5.83073 11.8391 5.63591L8.54376 2.64806C8.50631 2.6141 8.46468 2.58629 8.42038 2.56463Z"
                                                  className={"table-sort-icon"}
                                                  fillOpacity="0.65"
                                                ></path>
                                                <path
                                                  fillRule="evenodd"
                                                  clipRule="evenodd"
                                                  d="M8.42038 13.4354C8.22015 13.5384 7.96297 13.5191 7.78524 13.3714L4.17645 10.3715C3.95323 10.186 3.93901 9.86951 4.14467 9.66468C4.35034 9.45985 4.69801 9.44422 4.92123 9.62977L8.14064 12.3059L11.0634 9.65586C11.2783 9.46104 11.6261 9.46165 11.8403 9.65722C12.0545 9.85279 12.054 10.1693 11.8391 10.3641L8.54376 13.3519C8.50631 13.3859 8.46468 13.4137 8.42038 13.4354Z"
                                                  className={"table-sort-icon"}
                                                  fillOpacity="0.65"
                                                ></path>
                                              </svg>
                                            ))}
                                        </div>
                                      )}
                                    </div>
                                  </>
                                )}
                              </th>
                            );
                          }
                        } else {
                          return (
                            <th
                              key={header.id}
                              colSpan={header.colSpan}
                              className={
                                "items-center gap-x-2 whitespace-nowrap"
                              }
                            >
                              {header.isPlaceholder ? null : (
                                <>
                                  <div
                                    {...{
                                      className: header.column.getCanSort()
                                        ? clsx(
                                            "cursor-pointer select-none flex   gap-x-2 items-center",
                                            fullTd && "justify-between"
                                          )
                                        : "",
                                      onClick:
                                        header.column.getToggleSortingHandler(),
                                    }}
                                  >
                                    {flexRender(
                                      header.column.columnDef.header,
                                      header.getContext()
                                    )}
                                    {/*{header.column.columnDef.enableSorting && "enable"}*/}
                                    {header.column.columnDef.enableSorting && (
                                      <div className={"ml-5 w-5"}>
                                        {{
                                          asc: (
                                            <svg
                                              width="10"
                                              className={"rotate-180 "}
                                              height="5"
                                              viewBox="0 0 10 5"
                                              fill="none"
                                              xmlns="http://www.w3.org/2000/svg"
                                            >
                                              <path
                                                fillRule="evenodd"
                                                clipRule="evenodd"
                                                d="M4.47453 4.91825C4.72478 5.047 5.0462 5.0229 5.26833 4.83826L9.77856 1.08909C10.0575 0.857191 10.0753 0.461669 9.81828 0.205675C9.56124 -0.0503206 9.12672 -0.0698547 8.84774 0.162044L4.82415 3.5067L1.17126 0.194651C0.902716 -0.0488334 0.468013 -0.0480709 0.20032 0.196351C-0.0673723 0.440773 -0.0666847 0.836307 0.201858 1.07979L4.32033 4.81398C4.36714 4.85642 4.41917 4.89118 4.47453 4.91825Z"
                                                className={"table-sort-icon"}
                                                fillOpacity="0.65"
                                              />
                                            </svg>
                                          ),
                                          desc: (
                                            <svg
                                              className={"absolute"}
                                              width="10"
                                              height="5"
                                              viewBox="0 0 10 5"
                                              fill="none"
                                              xmlns="http://www.w3.org/2000/svg"
                                            >
                                              <path
                                                fillRule="evenodd"
                                                clipRule="evenodd"
                                                d="M4.47453 4.91825C4.72478 5.047 5.0462 5.0229 5.26833 4.83826L9.77856 1.08909C10.0575 0.857191 10.0753 0.461669 9.81828 0.205675C9.56124 -0.0503206 9.12672 -0.0698547 8.84774 0.162044L4.82415 3.5067L1.17126 0.194651C0.902716 -0.0488334 0.468013 -0.0480709 0.20032 0.196351C-0.0673723 0.440773 -0.0666847 0.836307 0.201858 1.07979L4.32033 4.81398C4.36714 4.85642 4.41917 4.89118 4.47453 4.91825Z"
                                                className={"table-sort-icon"}
                                                fillOpacity="0.65"
                                              />
                                            </svg>
                                          ),
                                        }[
                                          header.column.getIsSorted() as string
                                        ] ||
                                          (header.column.columnDef
                                            .enableSorting && (
                                            <svg
                                              className={""}
                                              width="16"
                                              height="16"
                                              viewBox="0 0 16 16"
                                              fill="none"
                                              xmlns="http://www.w3.org/2000/svg"
                                            >
                                              <path
                                                fillRule="evenodd"
                                                clipRule="evenodd"
                                                d="M8.42038 2.56463C8.22015 2.46161 7.96297 2.4809 7.78524 2.62863L4.17645 5.62847C3.95323 5.81402 3.93901 6.13049 4.14467 6.33532C4.35034 6.54015 4.69801 6.55578 4.92123 6.37023L8.14064 3.69406L11.0634 6.34414C11.2783 6.53896 11.6261 6.53835 11.8403 6.34278C12.0545 6.14721 12.054 5.83073 11.8391 5.63591L8.54376 2.64806C8.50631 2.6141 8.46468 2.58629 8.42038 2.56463Z"
                                                className={"table-sort-icon"}
                                                fillOpacity="0.65"
                                              ></path>
                                              <path
                                                fillRule="evenodd"
                                                clipRule="evenodd"
                                                d="M8.42038 13.4354C8.22015 13.5384 7.96297 13.5191 7.78524 13.3714L4.17645 10.3715C3.95323 10.186 3.93901 9.86951 4.14467 9.66468C4.35034 9.45985 4.69801 9.44422 4.92123 9.62977L8.14064 12.3059L11.0634 9.65586C11.2783 9.46104 11.6261 9.46165 11.8403 9.65722C12.0545 9.85279 12.054 10.1693 11.8391 10.3641L8.54376 13.3519C8.50631 13.3859 8.46468 13.4137 8.42038 13.4354Z"
                                                className={"table-sort-icon"}
                                                fillOpacity="0.65"
                                              ></path>
                                            </svg>
                                          ))}
                                      </div>
                                    )}
                                  </div>
                                </>
                              )}
                            </th>
                          );
                        }
                      })}
                    </tr>
                  ))}
                </thead>
                {loading && (
                  <div className="w-full absolute top-0 left-0 h-full min-h-[200px] flex justify-content-center z-50 align-items-center">
                    <Spinner />
                  </div>
                )}
                <tbody className={"!text-[#FFFFFFA6]"}>
                  {loading ? null : tableData.length ? (
                    table.getRowModel().rows.map((row: any, index: number) => {
                      return (
                        <>
                          <tr
                            key={row.id}
                            className={clsx(
                              " align-middle  !min-h-[50px] relative cursor-pointer",
                              isEqual(type, "logs") &&
                                isEqual(rowSelection, row.id) &&
                                "!border-[#C2D24B] !border-b-[#192F46] bg-[#C2D24B1A] w-full",
                              get(row, "subRows") ? "" : "border_bottom",
                              row.getIsExpanded()
                                ? "widgets-customize"
                                : "border_bottom"
                            )}
                            {...{
                              onClick: row.getToggleExpandedHandler(),
                            }}
                          >
                            {row
                              .getVisibleCells()
                              .map((cell: any, index: number) => {
                                if (isEqual(type, "logs")) {
                                  if (isEqual(index, 0)) {
                                    return (
                                      <td
                                        key={cell.id}
                                        className={clsx(
                                          "whitespace-nowrap h-full secondary-text"
                                        )}
                                        onClick={() => setRowSelection(row.id)}
                                      >
                                        {isEqual(type, "logs") &&
                                          isEqual(index, 0) &&
                                          isEqual(rowSelection, row.id) && (
                                            <div
                                              className={
                                                "w-1 h-full bg-[#C2D24B] absolute bottom-0"
                                              }
                                            />
                                          )}
                                        {flexRender(
                                          cell.column.columnDef.cell,
                                          cell.getContext()
                                        )}
                                      </td>
                                    );
                                  }
                                } else {
                                  return (
                                    <td
                                      key={cell.id}
                                      className={clsx(
                                        "whitespace-nowrap px-5 secondary-text"
                                      )}
                                    >
                                      {isEqual(type, "logs") &&
                                        isEqual(index, 0) &&
                                        isEqual(rowSelection, row.id) && (
                                          <div
                                            className={
                                              "w-1 h-full bg-[#C2D24B] absolute bottom-0"
                                            }
                                          />
                                        )}
                                      {flexRender(
                                        cell.column.columnDef.cell,
                                        cell.getContext()
                                      )}
                                    </td>
                                  );
                                }
                              })}
                          </tr>
                          {row.getIsExpanded() && (
                            <tr className={"widgets-customize border_bottom "}>
                              <td
                                colSpan={row.getVisibleCells().length}
                                className={"px-6 secondary-text"}
                              >
                                {renderSubComponent({ row })}
                              </td>
                            </tr>
                          )}
                        </>
                      );
                    })
                  ) : (
                    <tr>
                      <td colSpan={headers.length}>
                        <Empty
                          heading={
                            emptyControl.heading ||
                            formatMessage({
                              id: "No results found",
                            })
                          }
                          content={
                            emptyControl.content ||
                            formatMessage({
                              id: "There are currently no results to display. Please check back later",
                            })
                          }
                          footer={emptyControl.footer}
                        />
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
              {/* {extra && loading ? null : tableData.length ?(
                <div className="flex flex-col items-center justify-between gap-2 mt-5 md:flex-row">
                  <div className={"flex items-center gap-x-3.5"}>
                    <select
                      className={
                        "form-control form-select form-control-sm py-0 leading-0 !min-h-[32px] !h-[32px]"
                      }
                      value={table.getState().pagination.pageSize}
                      onChange={(e) => {
                        table.setPageSize(Number(e.target.value));
                      }}
                    >
                      <option value={5}>5</option>
                      <option value={10}>10</option>
                      <option value={15}>15</option>
                      <option value={20}>20</option>
                      <option value={25}>25</option>
                      <option value={50}>50</option>
                    </select>
                    <p
                      className={
                        "whitespace-nowrap text-[12px] leading-[18px] text-[#FFFFFFA6]"
                      }
                    >
                      {formatMessage({
                        id: `Showing 1 to ${
                          table.getPaginationRowModel().rows.length
                        } of ${
                          table.getPrePaginationRowModel().rows.length
                        } records`,
                      })}
                    </p>
                  </div>
                  <ReactPaginate
                    onPageChange={({ selected }) =>
                      table.setPageIndex(selected)
                    }
                    pageCount={table.getPageCount()}
                    containerClassName={"flex gap-x-2"}
                    pageClassName={
                      "w-8 h-8 bg-[#21233A] text-[14px] leading-[18px] secondary-text flex items-center justify-center rounded"
                    }
                    nextClassName={
                      "w-8 h-8 bg-[#21233A] flex items-center justify-center rounded"
                    }
                    previousClassName={
                      "w-8 h-8 bg-[#21233A] flex items-center justify-center rounded"
                    }
                    activeClassName={"bg-[#C2D24B] text-black"}
                    nextLabel={
                      <svg
                        width="5"
                        height="7"
                        viewBox="0 0 5 7"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M0.218094 0.265H1.71309L4.85909 3.242L1.71309 6.232H0.218094L3.36409 3.242L0.218094 0.265Z"
                          fill="white"
                          fillOpacity="0.6"
                        />
                      </svg>
                    }
                    previousLabel={
                      <svg
                        width="6"
                        height="7"
                        viewBox="0 0 6 7"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M4.11953 6.232L0.986531 3.242L4.11953 0.265H5.61453L2.46853 3.242L5.61453 6.232H4.11953Z"
                          fill="white"
                          fillOpacity="0.6"
                        />
                      </svg>
                    }
                  />
                </div>
              ): null} */}
            </div>
          </div>
          {extra && (
            <div className={"col-span-12 md:col-span-6 md:mt-16"}>{extra}</div>
          )}
        </div>
      </div>

      {!TablePagination && !extra && (
        <div className="flex flex-col justify-center md:items-center md:justify-between gap-2 mt-5 md:flex-row">
          <div className={"flex items-center gap-x-3.5"}>
            <select
              className={
                "form-control form-select form-control-sm py-0 leading-0 !min-h-[32px] !h-[32px]"
              }
              value={table.getState().pagination.pageSize}
              onChange={(e) => {
                table.setPageSize(Number(e.target.value));
              }}
            >
              <option value={5}>5</option>
              <option value={10}>10</option>
              <option value={15}>15</option>
              <option value={20}>20</option>
              <option value={25}>25</option>
              <option value={50}>50</option>
            </select>
            <p
              className={
                "whitespace-nowrap text-[12px] leading-[18px] primary-text"
              }
            >
              {formatMessage({
                id: `Showing 1 to ${
                  table.getPaginationRowModel().rows.length
                } of ${table.getPrePaginationRowModel().rows.length} records`,
              })}
            </p>
          </div>
          <ReactPaginate
            onPageChange={({ selected }) => table.setPageIndex(selected)}
            pageCount={table.getPageCount()}
            containerClassName={
              "flex justify-center md:justify-start gap-x-3.5 mt-[12px] sm:mt-0"
            }
            pageClassName={
              "w-8 h-8 pagination-item text-[14px] leading-[18px] flex items-center justify-center rounded"
            }
            nextClassName={
              "w-8 h-8 pagination-item flex items-center justify-center rounded"
            }
            previousClassName={
              "w-8 h-8 pagination-item flex items-center justify-center rounded"
            }
            activeClassName={"active-pagination"}
            nextLabel={
              <svg
                width="5"
                height="7"
                viewBox="0 0 5 7"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M0.218094 0.265H1.71309L4.85909 3.242L1.71309 6.232H0.218094L3.36409 3.242L0.218094 0.265Z"
                  className={"pagination-icon"}
                  fillOpacity="0.6"
                />
              </svg>
            }
            previousLabel={
              <svg
                width="6"
                height="7"
                viewBox="0 0 6 7"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M4.11953 6.232L0.986531 3.242L4.11953 0.265H5.61453L2.46853 3.242L5.61453 6.232H4.11953Z"
                  className={"pagination-icon"}
                  fillOpacity="0.6"
                />
              </svg>
            }
          />
        </div>
      )}
    </div>
  );
};

export default CustomTable;

function DebouncedInput({
  value: initialValue,
  onChange,
  debounce = 500,
  ...props
}: {
  value: string | number;
  onChange: (value: string | number) => void;
  debounce?: number;
} & Omit<React.InputHTMLAttributes<HTMLInputElement>, "onChange">) {
  const [value, setValue] = React.useState(initialValue);

  React.useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  React.useEffect(() => {
    const timeout = setTimeout(() => {
      onChange(value);
    }, debounce);

    return () => clearTimeout(timeout);
  }, [value]);

  return (
    <input
      {...props}
      value={value}
      onChange={(e) => setValue(e.target.value)}
    />
  );
}

// @ts-ignore
