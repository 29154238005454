import React, { useEffect, useState } from "react";
import clsx from "clsx";
import FilterForm from "./filter-form";
import { get, isEqual, map } from "lodash";
import { chatList } from "../../../containers/marketplace-chat/chat-list";
import { useOutletContext, useSearchParams } from "react-router-dom";
import { getAllChatSubscriptions } from "../../../containers/ai-profile/core/_request";
import { getCdnUrl } from "../../../../../core/_util";
import axios from "axios";
import { getPersonalityById } from "../core/_request";
import { useAuth } from "../../../../auth";
import { Spinner } from "react-bootstrap";
import { useIntl } from "react-intl";

const Index: React.FC<any> = ({
  open = true,
  setOpen,
  chatChange,
  loadChats,
  setLoadChats,
  setChatsCount,
}) => {
  const { formatMessage } = useIntl();
  const [activeChat, setActiveChat] = useState("");
  const [chatOpen] = useOutletContext<any>();
  const [chats, setChats] = useState<any>([]);
  const [filteredChats, setFilteredChats] = useState<any>([]);
  const [search, setSearch] = useState<string>("");
  const [searchParams, setSearchParams] = useSearchParams();
  useEffect(() => {
    if (activeChat) {
      chatChange(activeChat);
      searchParams.set("cid", activeChat);
      setSearchParams(searchParams, { replace: true });
    }
  }, [activeChat]);

  function handleSearch(search: string) {
    setFilteredChats(
      !search || search.length < 3
        ? []
        : chats.filter((item: any) => item.name.includes(search))
    );
  }

  useEffect(() => {
    let timeout = setTimeout(() => {
      handleSearch(search);
    }, 300);
    return () => clearTimeout(timeout);
  }, [search]);

  useEffect(() => {
    async function getSub() {
      setLoadChats(true);
      const {
        data: { success, data },
      } = await getAllChatSubscriptions();
      setChatsCount(data?.length || 0);
      setLoadChats(false);
      setChats(data);
      setActiveChat(searchParams.get("cid") || data[0].personalityId || "");
    }
    getSub();
  }, []);

  useEffect(() => {
    setOpen(chatOpen);
  }, [chatOpen]);

  return (
    <div
      className={clsx(
        "inset-x-5 md:w-[300px] md:min-w-[300px] lg:w-[340px] lg:min-w-[340px] fixed top-[90px]    md:static duration-300 transition-all flex z-40 md:border-r main-border rounded md:!rounded-r-none h-full  tab-container",
        open
          ? "shadow-default left-5"
          : "md:translate-x-0 -translate-x-full -left-12"
      )}
    >
      <div className={"flex-grow relative "}>
        <div
          className={
            "flex flex-col flex-grow overflow-hidden chat-list-theme-bg rounded justify-between"
          }
        >
          <div className={"px-4 py-2.5 border-b  border-main"}>
            <div className={"relative md:w-full"}>
              <svg
                className={"absolute top-1/2 -translate-y-1/2 left-2"}
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g clipPath="url(#clip0_497_31689)">
                  <path
                    opacity="0.3"
                    d="M14.2929 16.7071C13.9024 16.3166 13.9024 15.6834 14.2929 15.2929C14.6834 14.9024 15.3166 14.9024 15.7071 15.2929L19.7071 19.2929C20.0976 19.6834 20.0976 20.3166 19.7071 20.7071C19.3166 21.0976 18.6834 21.0976 18.2929 20.7071L14.2929 16.7071Z"
                    fill="#585960"
                  />
                  <path
                    d="M11 16C13.7614 16 16 13.7614 16 11C16 8.23858 13.7614 6 11 6C8.23858 6 6 8.23858 6 11C6 13.7614 8.23858 16 11 16ZM11 18C7.13401 18 4 14.866 4 11C4 7.13401 7.13401 4 11 4C14.866 4 18 7.13401 18 11C18 14.866 14.866 18 11 18Z"
                    fill="#585960"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_497_31689">
                    <rect width="24" height="24" fill="white" />
                  </clipPath>
                </defs>
              </svg>
              <input
                className={"form-control border-main !h-[44px]  pl-10 !w-full"}
                placeholder={formatMessage({
                  id: "Search Personality By Name or category",
                })}
                onChange={(e) => setSearch(e.target.value)}
                value={search}
              />
            </div>
          </div>
          {/*<FilterForm />*/}
          <div className={"relative"}>
            {loadChats ? (
              <div className="h-[calc(95vh-142px)] flex justify-content-center py-20">
                <Spinner animation="border" />
              </div>
            ) : (
              <ul
                className={
                  "h-[calc(95vh-142px)] overflow-y-auto custom-scroll "
                }
              >
                {!loadChats &&
                ((search && !filteredChats.length) || !chats.length) ? (
                  <p className="text-[12px] md:text-[16px] my-4 secondary-text text-center">
                    {formatMessage({
                      id: "No chats found",
                    })}
                  </p>
                ) : null}
                {map(filteredChats.length ? filteredChats : chats, (item) => (
                  <li
                    key={get(item, "id")}
                    className={clsx(
                      "py-2 px-6 border-b border-bottom-chat flex items-center gap-x-2 border-l-[4px] cursor-pointer z-50",
                      isEqual(activeChat, item.personalityId)
                        ? "!border-l-[#C2D24B] active-chat-bg"
                        : "!border-l-[transparent]"
                    )}
                    onClick={() => {
                      setActiveChat(item.personalityId)
                      setOpen(false)
                    }}
                  >
                    <img
                      src={getCdnUrl(get(item, "image"))}
                      className={"w-12 h-12 rounded-full"}
                      alt={"avatar"}
                    />{" "}
                    <div>
                      <h3
                        className={
                          "text-[16px] leading-6 primary-text font-medium mb-1"
                        }
                      >
                        {get(item, "name")}
                      </h3>
                      <p
                        className={"text-[12px] leading-[18px] secondary-text"}
                      >
                        {get(item, "personalityType")}
                      </p>
                    </div>
                  </li>
                ))}
              </ul>
            )}
            <div
              className={
                "w-full h-40 my-chat-sider-bottom absolute bottom-0 -z-50"
              }
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Index;
