import React, { useEffect, useRef } from "react";
// @ts-ignore
import * as echarts from "echarts";
import useChartResizer from "../../../../hooks/useChartResizer";
import {useThemeMode} from "../../../../../_metronic/partials";
import {isEqual} from "lodash";
const Index = ({}) => {
    const { mode } = useThemeMode();

    const option = {
        title: {
            show: false,
        },
        parentHeightOffset: 0,
        tooltip: {
            trigger: 'item',
            formatter: '{a} <br/>{b} : {c}%'
        },
        legend: {
            show: false,
        },
        series: [
            {
                name: 'Expected',
                type: 'funnel',
                left: '0%',
                top:0,
                gap:0,
                bottom:0,
                minSize:"35%",
                maxSize:"80%",
                label: {
                    show: true,
                    position: 'inside',
                    formatter: '{c}%',
                    fontWeight:600,
                    fontSize:16,
                    color:'#FFFFFF'
                },
                labelLine: {
                    show: true,
                },
                emphasis:{
                    disabled:true,
                },
                data: [
                    { value: 60, name: 'Visit' ,  itemStyle: { color: '#2B3007' }},
                    { value: 50, name: 'Inquiry' ,itemStyle: { color: '#383E0D' }},
                    { value: 30, name: 'Order' ,itemStyle: { color: '#4E5611' }},
                    { value: 30, name: 'Click',itemStyle: { color: '#5B6514' } },
                    { value: 30, name: 'Show',itemStyle: { color: '#869328' } }
                ],
                itemStyle:{
                    borderColor:"transparent",
                    borderWidth: 0,
                },
            },
            {
                name: 'Expected',
                type: 'funnel',
                left: '0%',
                top:0,
                gap:0,
                bottom:0,
                minSize:"35%",
                maxSize:"80%",
                label: {
                    show: true,
                    position: 'outside',
                    formatter: '{b}',
                    color:isEqual(mode,'light')?"#000": '#FFFFFF'
                },
                emphasis:{
                    disabled:true,
                },
                labelLine: {
                    show: true,
                    position:"left",
                    lineStyle:{
                        color:isEqual(mode,'light')?"#000": '#FFFFFF'
                    },
                    length:100,
                },
                data: [
                    { value: 60, name: 'Visit' ,  itemStyle: { color: '#2B3007',  }},
                    { value: 50, name: 'Inquiry' ,itemStyle: { color: '#383E0D' }},
                    { value: 30, name: 'Order' ,itemStyle: { color: '#4E5611' }},
                    { value: 30, name: 'Click',itemStyle: { color: '#5B6514' } },
                    { value: 30, name: 'Show',itemStyle: { color: '#869328' } }
                ],
                itemStyle:{
                    borderColor:"transparent",
                    borderWidth: 0,
                },
            },
        ]
    };
    const chartRef = useRef();
    useChartResizer(chartRef);
    useEffect(() => {
        const chart = echarts.init(chartRef.current, null);
        // @ts-ignore
        chart.setOption(option);
    }, [option]);

    // @ts-ignore
    return <div className={"w-full h-full"} ref={chartRef}></div>;
};

export default Index;