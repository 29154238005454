import clsx from "clsx";
import { Link } from "react-router-dom";
import { useLayout } from "../../../core";
import { usePageData } from "../../../core/PageData";
import React from "react";
import { useIntl } from "react-intl";

const PageTitle = () => {
  const { formatMessage } = useIntl();

  const { pageTitle, pageDescription, pageBreadcrumbs } = usePageData();
  const { config, classes } = useLayout();
  const appPageTitleDirection = config.app?.pageTitle?.direction;
  return (
    <div>
      <h4 className="mb-1 mt-3 leading-7 text-[20px] font-semibold primary-text]">
        {" "}
        {formatMessage({ id: "Setting" })}
      </h4>
      <p className="flex items-center text-[12px] leading-[18px] font-normal secondary-text">
        <Link to="/" className={"main-text"}>
          {formatMessage({ id: "Home" })}
        </Link>
        <span className="h-1px bullet mx-2"></span>
        <span className="secondary-text font-size-12">
          {" "}
          {formatMessage({ id: "Setting" })}
        </span>
      </p>
    </div>
  );
};

export { PageTitle };
