import React, { useState } from "react";
import TextInput from "../../../widgets/components/Input/TextInput";
import { useIntl } from "react-intl";
import { Form, Formik } from "formik";
import TextArea from "../../../widgets/components/Input/TextArea";
import { InfoCard } from "../../../widgets/components/UI/InfoCard";
import Slider from "rc-slider";
import { SelectInput } from "../../../widgets/components/Input/SelectInput";
import Select from "../../../../components/select/select";
import { useAuth } from "../../../auth";
import FormikReactCreatable from "../../../widgets/components/Input/FormikReactCreatable";
import { updatePersonalityInfo } from "../../../profile/core/_requests";
import * as Yup from "yup";
import { toast } from "react-toastify";
import useGTM from "../../../../hooks/useGTM";
import { GTMEvent } from "../../../../hooks/gtm_helpers";

const NegativeLearning: React.FC<any> = ({ setOpenEdit, personalityData }) => {
  const { formatMessage } = useIntl();
  const [disableSave, setDisableSave] = useState(false);
  const {dataLayerPush} = useGTM()
  const {
    referenceData,
    setSelectedPersonality,
    selectedPersonality: updatedData,
  } = useAuth();
  personalityData = updatedData || personalityData;

  const onSubmit = async (values: any, { setSubmitting }: any) => {
    // save data to api
    setDisableSave(true);
    setSubmitting(true);
    try {
      let {
        data: { success, data },
      } = await updatePersonalityInfo({
        personalityId: personalityData.personalityId,
        personalityJson: {
          ...personalityData.personalityJson,
          negative_learning: values,
        },
      });
      if (success) {
        setSelectedPersonality(data);
        toast.success(
          formatMessage({ id: "Negative Learnings updated successfully" })
        );
      } else {
        setSubmitting(false);
        toast.error(
          formatMessage({ id: "Error when updating Negative Learnings" }),
          {
            autoClose: false,
            closeButton: true, // Display close button (cross icon)
          }
        );
      }
      setDisableSave(false);
      dataLayerPush(GTMEvent.AIPersonalityEditSuccessful,{"tab": "negative-learnings"})
    } catch (e) {
      toast.error(
        formatMessage({ id: "Error when updating Negative Learnings" }),
        {
          autoClose: false,
          closeButton: true, // Display close button (cross icon)
        }
      );
      setSubmitting(false);
      setDisableSave(false);
      dataLayerPush(GTMEvent.AIPersonalityEditFailed,{"tab": "negative-learnings"})
    }
  };

  const negativeLearningValidationSchema = Yup.object().shape({
    topics_this_ai_personality_want_to_avoid_during_conversation: Yup.array()
      .of(
        Yup.string()
          .min(2, "Topic must have at least 2 characters")
          .max(100, "Topic can have at most 100 characters")
      )
      // .min(1, "At least 1 topic to avoid is required")
      .max(10, "Topics to avoid cannot exceed 10")
      .test("unique", "Topics to avoid must be unique", (value) => {
        if (value) {
          const uniqueSet = new Set(value);
          return uniqueSet.size === value.length;
        }
        return true;
      }),
    response_this_ai_personality_will_give_when_topics_to_avoid_are_brought_up_during_conversation:
      Yup.string()
        .min(5, "Response must have at least 5 characters")
        .max(200, "Response can have at most 200 characters"),
  });

  return (
    <Formik
      initialValues={personalityData?.personalityJson?.negative_learning || {}}
      onSubmit={onSubmit}
      validationSchema={negativeLearningValidationSchema}
      validateOnMount
    >
      {(formik) => {
        return (
          <Form>
            <div className={"grid grid-cols-12 gap-y-[20px] md:gap-x-[40px]"}>
              <div className={"col-span-12 md:col-span-7 order-0"}>
                <div className={"mb-[24px]"}>
                  <FormikReactCreatable
                    tooltipText={formatMessage({
                      id: "GLOBAL.TOOLTIP.EDIT_AI_PERSONALITY.NEGATIVE_LEARNING.DO_NOT_TALK",
                    })}
                    label={formatMessage({
                      id: "Topics this personality do not want to talk about",
                    })}
                    className={"without-arrow"}
                    isMulti={true}
                    name={
                      "topics_this_ai_personality_want_to_avoid_during_conversation"
                    }
                    formik={formik}
                    isClearable={false}
                    placeholder={formatMessage({
                      id: "Type something and press Enter or Tab to create name",
                    })}
                    defaultValue={formik.values?.topics_this_ai_personality_want_to_avoid_during_conversation?.map(
                      (el: string, index: number) => {
                        return { value: el, label: el, id: index };
                      }
                    )}
                  />
                </div>
                <TextArea
                  label={formatMessage({
                    id: "Standard reply when asked about above topics",
                  })}
                  fieldName={
                    "response_this_ai_personality_will_give_when_topics_to_avoid_are_brought_up_during_conversation"
                  }
                  placeholder={formatMessage({
                    id: "Write a paragraph describing who your personality is",
                  })}
                  // defaultValue={"I respectfully steer clear of discussing politics, family matters, sex, and religion. These subjects are personal and sensitive. My focus is on spreading joy and entertainment through my work. I believe in keeping my personal life separate from my professional persona."}
                  formik={formik}
                  toolTipText={formatMessage({
                    id: "GLOBAL.TOOLTIP.EDIT_AI_PERSONALITY.NEGATIVE_LEARNING.REPLY_ASKED_TOPICS",
                  })}
                  className={"!min-h-[142px]"}
                />
                {/* <TextArea
                                label={formatMessage({
                                    id: "Standard reply when there is a server error",
                                })}
                                fieldName={"description"}
                                placeholder={formatMessage({
                                    id: "Standard reply when there is a server error",
                                })}
                                defaultValue={
                                    "Ah, it seems we've hit a snag in our conversation due to internet issues or a server glitch. Don't worry, these things happen. Just like in my films, a little drama adds suspense. We'll reconnect soon!"
                                }
                                formik={formik}
                                toolTipText={"text"}
                                className={"!min-h-[140px]"}
                            /> */}
              </div>

              <div
                className={
                  "col-span-12 md:col-span-5 flex flex-col justify-start gap-[20px]"
                }
              >
                <div
                  className={
                    "flex justify-end gap-[10px] md:order-1 md:mt-auto"
                  }
                >
                  {/* <button
                    onClick={() => setOpenEdit(false)}
                    className={
                      "main-button-primary-light text-[14px] leading-5 font-medium py-[12px] w-1/2 md:w-[128px] rounded"
                    }
                  >
                    {formatMessage({
                      id: "Cancel",
                    })}
                  </button> */}
                  <button
                    type="submit"
                    className={`main-button text-[14px] leading-5 font-medium py-[12px] w-full sm:w-1/2  md:w-[140px] rounded ${
                      disableSave ? "opacity-75" : ""
                    }`}
                    disabled={disableSave}
                  >
                    {formatMessage({
                      id: `${disableSave ? "Please Wait" : "Save"}`,
                    })}
                  </button>
                </div>
                <InfoCard
                  title={formatMessage({
                    id: "Understanding Negative Learnings of AI Personality",
                  })}
                  desc={
                    <>
                      {formatMessage({
                        id: "In Kamoto.AI, the 'Negative Learnings' section gives you control over the topics your AI personality does not engage with. You can identify and define restricted topics, ensuring that your AI personality avoids discussing sensitive subjects such as politics, family's private information, sex, pornography, guns law, religion, and more. \n",
                      })}
                      <br></br>
                      <br></br>
                      {formatMessage({
                        id: "By setting these boundaries, you create a safe and respectful environment for users to interact with your AI personality. When faced with restricted topics, your AI personality can deliver standard replies that redirect the conversation or politely decline to engage. With Kamoto.AI, you have the power to curate a positive and inclusive experience, where users can confidently engage with your AI personality without encountering uncomfortable or inappropriate discussions.",
                      })}
                      <br></br>
                      <br></br>
                    </>
                  }
                  slug={""}
                  isAnchor={
                    "https://help.kamoto.ai/en/articles/7941921-understanding-negative-learnings-tab-in-ai-personalities"
                  }
                />
              </div>
            </div>
          </Form>
        );
      }}
    </Formik>
  );
};

export default NegativeLearning;
