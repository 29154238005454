/**
 * High level router.
 *
 * Note: It's recommended to compose related routes in internal router
 * components (e.g: `src/app/modules/Auth/pages/AuthPage`, `src/app/BasePage`).
 */

import { FC, useEffect, useState } from "react";
import { Routes, Route, Navigate, useLocation } from "react-router-dom";
import { SuspensedView } from "./PrivateRoutes";
import { AuthPage, useAuth, getAuth } from "../modules/auth";
import { App } from "../App";
import { LinkedInCallback } from "react-linkedin-login-oauth2";

import { VerifyInvite } from "../modules/auth/components/VerifyInvite";
import { AuthLayout } from "../modules/auth/AuthLayout";
import { ErrorsPage } from "../modules/errors/ErrorsPage";
import VerifyEmailID from "../modules/auth/views/VerifyEmailID";
import MarketPlacePublicRoute from "../modules/marketplace-public/MarketPlacePublicRoute";
import ProfilePage from "../modules/marketplace-public/pages/ai-profile";
import UserProfilePage from "../modules/marketplace-public/pages/ai-user-profile";
import MarketplacePublicLayout from "../../_metronic/layout/marketplace-public-layout";
import DiscordCallback from "../modules/auth/components/DiscordCallback";

/**
 * Base URL of the website.
 *
 * @see https://facebook.github.io/create-react-app/docs/using-the-public-folder
 */

const PublicRoutes: FC = () => {

    const {pathname} = useLocation()
    const {nextRoute,setNextRoute} = useAuth()

    const dashboardPaths = ['/dashboard','/settings/*','/my-ai/*','/train-my-ai/*','/edit-ai-personality/*','/communicate-engage/*','/monetize-distribute/*','/apis/*','/chat-credits','/support','/my-feed','/my-chats']

    const exclusion = [
        '/',
        '/auth/login',
        '/auth/login/',
        '/auth/registration',
        '/auth/registration/',
        '/auth/reset-password',
        '/auth/reset-password/',
        '/auth/forgot-password',
        '/auth/forgot-password/',
        '/email-verify',
        '/email-verify/',
        '/auth/linkedin',
        '/auth/linkedin/',
        '/onboarding',
        '/onboarding/',
        '/onboarding/create-ai-personality',
        '/onboarding/create-ai-personality/',
    ]
    useEffect(() => {
        if(!exclusion.includes(pathname)){
            setNextRoute(pathname)
        }
    },[pathname])

    return (
        <Routes>
            <Route element={<App />}>
                <Route index element={<Navigate to="/marketplace" />} />
                <Route path="auth/linkedin" element={<LinkedInCallback />} />
                <Route path="auth/discord" element={<DiscordCallback/>} />
                <Route
                    path="/auth/invite/:inviteToken"
                    element={<VerifyInvite />}
                />
                <Route path="auth/*" element={<AuthPage />} />
                <Route element={<AuthLayout />}>
                    {/* <Route path="email-verify" element={<EmailVerify />} /> */}
                    <Route path="email-verify" element={<VerifyEmailID />} />
                </Route>
                <Route
                    path={"marketplace/*"}
                    element={
                        <SuspensedView>
                            <MarketPlacePublicRoute />
                        </SuspensedView>
                    }
                />
                <Route element={<MarketplacePublicLayout />}>
                    <Route path={"p/:slug"} element={<ProfilePage />} />
                    <Route path={"u/:username"} element={<UserProfilePage />} />
                </Route>
                {
                    dashboardPaths.map((path:string) => (
                        <Route path={path} element={<Navigate to={'/auth/login'}/>} />
                    ))
                }
                <Route path="*" element={<ErrorsPage />} />
            </Route>
        </Routes>
    );
};

export { PublicRoutes };
