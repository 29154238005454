export const serialize = function (obj: any) {
  let str = [];
  for (let p in obj)
    if (obj.hasOwnProperty(p) && !!obj[p]) {
      str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
    }
  return str.join("&");
};


export const getCdnUrl = function (url: string, transformOptions: string = "width=auto"){
  if(typeof url == 'undefined' || url == undefined || url == null){
    return url
  }

  let optimizedUrl = url;

  if((/true/).test(process.env.REACT_APP_ENABLE_CDN || '')){
    if(typeof process.env.REACT_APP_APP_DATA_CDN_BASE_URL != 'undefined' && process.env.REACT_APP_APP_DATA_CDN_BASE_URL){
      if(url.startsWith(`https://${process.env.REACT_APP_APP_DATA_BUCKET_URL}.s3.amazonaws.com`)){
        optimizedUrl = url.replace(`https://${process.env.REACT_APP_APP_DATA_BUCKET_URL}.s3.amazonaws.com`,process.env.REACT_APP_APP_DATA_CDN_BASE_URL)
      }
    }
    if(typeof process.env.REACT_APP_APP_PUBLIC_CDN_BASE_URL != 'undefined' && process.env.REACT_APP_APP_PUBLIC_CDN_BASE_URL){
      if(url.startsWith(`https://${process.env.REACT_APP_APP_PUBLIC_BUCKET_URL}.s3.amazonaws.com`)){
        optimizedUrl = url.replace(`https://${process.env.REACT_APP_APP_PUBLIC_BUCKET_URL}.s3.amazonaws.com`,process.env.REACT_APP_APP_PUBLIC_CDN_BASE_URL)
      }
    }

    if((/true/).test(process.env.REACT_APP_ENABLE_CLOUDFLARE_IMAGE_CDN || '')) {

      const lastPathSeparator = isAbsoluteUrl(optimizedUrl)? "/" : ""
      optimizedUrl = "/cdn-cgi/image/format=auto," + transformOptions +  lastPathSeparator + optimizedUrl
    }
  }

  return optimizedUrl
}

export const isAbsoluteUrl = function (url: string): boolean {
  // Regular expression to match absolute URLs
  const absoluteUrlRegex = new RegExp('^(?:[a-z]+:)?//', 'i');
  
  return absoluteUrlRegex.test(url);
}

export const kmbConvertor = (n:number, decimalUpto = 1) => {
  if (n === undefined || n === null) {
    return 0;
  }

  if (n < 1e3) return n;
  if (n >= 1e3 && n < 1e6) return +(n / 1e3).toFixed(decimalUpto) + "K";
  if (n >= 1e6 && n < 1e9) return +(n / 1e6).toFixed(decimalUpto) + "M";
  if (n >= 1e9 && n < 1e12) return +(n / 1e9).toFixed(decimalUpto) + "B";
  if (n >= 1e12) return +(n / 1e12).toFixed(decimalUpto) + "T";
};