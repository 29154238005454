import {get, isEqual, map} from "lodash";
import React from "react";
import DateFilter from "../components/date-filter";
import LineChart from "../components/line-chart";
import Select from "../../../components/select/select";
import { useIntl } from "react-intl";
import { ToolTipUI } from "../../widgets/components/UI/ToolTipUI";
import LineGraph from "../components/referral/line-graph";
import {toAbsoluteUrl} from "../../../../_metronic/helpers";

const Referral = () => {
  const { formatMessage } = useIntl();


  const lineChart = [
    {
      heading: "Total Referrals Sent",
      count: "2,00,000",
      increase: false,
      description: "50,000 Last Month",
      linkTitle: false
    },
    {
      heading: "Users Joined",
      count: "2,00,000",
      increase: "60",
      description: "12,58,666 Last Month",
      linkTitle: "View Joined Users",
    },
    {
      heading: "Pending Referrals",
      count: "20,000",
      increase: false,
      description: "0 Last Month",
      linkTitle: false
    },
    {
      heading: "Money Spend",
      count: "$82k",
      increase: false,
      description: "$2k Last Month",
      linkTitle: false
    },
  ];
  const topPerformance = [
    {
      id: 1,
      name: "Shahrukh Khan",
      owner: "Toshendra Sharma",
      totalVisitors: "2,000,000",
      followers: "200k",
      likes: "90k",
    },
    {
      id: 2,
      name: "Shahrukh Khan",
      owner: "Toshendra Sharma",
      totalVisitors: "2,000,000",
      followers: "200k",
      likes: "90k",
    },
    {
      id: 3,
      name: "Shahrukh Khan",
      owner: "Toshendra Sharma",
      totalVisitors: "2,000,000",
      followers: "200k",
      likes: "90k",
    },
    {
      id: 4,
      name: "Shahrukh Khan",
      owner: "Toshendra Sharma",
      totalVisitors: "2,000,000",
      followers: "200k",
      likes: "90k",
    },
    {
      id: 5,
      name: "Shahrukh Khan",
      owner: "Toshendra Sharma",
      totalVisitors: "2,000,000",
      followers: "200k",
      likes: "90k",
    },
    {
      id: 6,
      name: "Shahrukh Khan",
      owner: "Toshendra Sharma",
      totalVisitors: "2,000,000",
      followers: "200k",
      likes: "90k",
    },
    {
      id: 7,
      name: "Shahrukh Khan",
      owner: "Toshendra Sharma",
      totalVisitors: "2,000,000",
      followers: "200k",
      likes: "90k",
    },
    {
      id: 8,
      name: "Shahrukh Khan",
      owner: "Toshendra Sharma",
      totalVisitors: "2,000,000",
      followers: "200k",
      likes: "90k",
    },
    {
      id: 9,
      name: "Shahrukh Khan",
      owner: "Toshendra Sharma",
      totalVisitors: "2,000,000",
      followers: "200k",
      likes: "90k",
    },
    {
      id: 10,
      name: "Shahrukh Khan",
      owner: "Toshendra Sharma",
      totalVisitors: "2,000,000",
      followers: "200k",
      likes: "90k",
    },
  ];
  return (
    <div className={'pb-10'}>
      <div className="flex justify-end">
        <DateFilter />
      </div>
      <div className={"grid grid-cols-12 gap-4 mt-[16px] mb-[24px]"}>
        {map(lineChart, (item: any) => (
          <div className={"col-span-12 md:col-span-6 xl:col-span-3"}>
            <LineChart
              heading={get(item, "heading")}
              count={get(item, "count")}
              description={get(item, "description")}
              tooltipText={"Test"}
              linkText={get(item, "linkTitle")}
              increase={get(item, "increase")}
            />
          </div>
        ))}
      </div>
      <div
        className={
          "grid grid-cols-12 md:gap-x-[22px] gap-y-[14px] md:gap-y-0 my-5"
        }
      >
        <div
            className={
              "col-span-12 md:col-span-6 p-5 rounded chart-card"
            }
        >
          <div
              className={
                "flex items-center flex-wrap justify-between gap-y-2 mb-5"
              }
          >
            <h3 className={"text-[20px] font-semibold primary-text"}>
              Top Performance
            </h3>
            <Select
                options={[]}
                label={false}
                className={"event-select min-w-[150px] !w-full text-[14px]"}
                placeHolder={formatMessage({ id: "Select" })}
            />
          </div>
          <div className={"h-96 overflow-y-auto custom-scroll pr-5"}>
            <table className={"table table-auto"}>
              <tbody>
              {map(topPerformance, (item) => (
                  <tr>
                    <td>
                      <div className={"flex items-center gap-x-2"}>
                        <img
                            alt="Pic"
                            src={toAbsoluteUrl(`/media/avatars/300-1.jpg`)}
                            className={"w-[50px] h-[50px] rounded"}
                        />
                        <div className={"flex flex-col gap-y-1"}>
                          <p className="leading-5 font-semibold text-[14px] primary-text">
                            {get(item, "owner")}
                          </p>
                          <p className="text-[12px] secondary-text font-medium">
                            Referral Code : 1234567
                          </p>
                        </div>
                      </div>
                    </td>
                    <td>
                      <div className={"flex flex-col gap-y-1"}>
                        <p className="leading-5 font-semibold text-[14px] primary-text flex gap-x-1">
                          1,00,000
                        </p>
                        <p className="text-[12px] secondary-text font-medium">
                          Invited
                        </p>
                      </div>
                    </td>
                    <td>
                      {" "}
                      <div className={"flex flex-col gap-y-1"}>
                        <p className="leading-5 font-semibold text-[14px] primary-text flex gap-x-1">
                          $100
                        </p>
                        <p className="text-[12px] secondary-text font-medium">
                          Earned
                        </p>
                      </div>
                    </td>
                  </tr>
              ))}
              </tbody>
            </table>
          </div>
          <div className={'flex justify-end mt-4'}>
          <a
              className={
                "text-[12px] leading-[18px] font-medium main-text  flex items-center gap-1 cursor-pointer"
              }
          >
            {formatMessage({ id: 'View Full List' })}
            <svg
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
              <path
                  opacity="0.3"
                  d="M4.00065 7.33268C3.63246 7.33268 3.33398 7.63116 3.33398 7.99935C3.33398 8.36754 3.63246 8.66602 4.00065 8.66602H12.0007C12.3688 8.66602 12.6673 8.36754 12.6673 7.99935C12.6673 7.63116 12.3688 7.33268 12.0007 7.33268H4.00065Z"
                  className={"primary-icon-fill"}
              />
              <path
                  d="M7.52794 11.5279C7.26759 11.7883 7.26759 12.2104 7.52794 12.4708C7.78829 12.7311 8.2104 12.7311 8.47075 12.4708L12.4708 8.47075C12.7231 8.21837 12.732 7.81198 12.4908 7.54887L8.82412 3.54887C8.57532 3.27746 8.15361 3.25912 7.8822 3.50791C7.61079 3.75671 7.59245 4.17842 7.84125 4.44983L11.0766 7.9793L7.52794 11.5279Z"
                  className={"primary-icon-fill"}
              />
            </svg>
          </a>
          </div>
        </div>
        <div
          className={
            "col-span-12 md:col-span-6 p-5 rounded chart-card"
          }
        >
          <div className={"flex justify-between items-center mb-5"}>
            <h3 className="flex items-center  text-[14px] leading-[20px] primary-text">
              {formatMessage({ id: "Followers" })}
              <ToolTipUI
                tooltipText={formatMessage({
                  id: "Followers",
                })}
              />
            </h3>
            <Select
              options={[{ value: "quater", label: "Quaterly" }]}
              defaultValue={{ value: "quater", label: "Quaterly" }}
              label={false}
              height={36}
              className={"event-select min-w-[150px] !w-full text-[14px]"}
              placeHolder={formatMessage({ id: "Select" })}
            />
          </div>
          <div className="flex justify-between max-w-sm">
            <div className="mb-2">
              <div className="flex items-center gap-x-1 mb-3">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                >
                  <rect width="16" height="16" rx="8" fill="#3DD883" />
                </svg>
                <span className="text-[14px] leading-[20px] secondary-text">
                  {formatMessage({ id: "User Joined" })}
                </span>
                <ToolTipUI
                  tooltipText={formatMessage({
                    id: "User Joined",
                  })}
                />
              </div>
              <h3 className={"text-[24px] primary-text font-semibold mb-1"}>
                1,00,000
              </h3>
            </div>
            <div className="mb-2">
              <div className="flex items-center gap-x-1 mb-3">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                >
                  <rect width="16" height="16" rx="8" fill="#C2D24B" />
                </svg>
                <span className="text-[14px] leading-[20px] secondary-text">
                  {formatMessage({ id: "Pending action" })}
                </span>
                <ToolTipUI
                  tooltipText={formatMessage({
                    id: "Pending action",
                  })}
                />
              </div>
              <h3 className={"text-[24px] primary-text font-semibold mb-1"}>
                10,000
              </h3>
            </div>
          </div>
          <div className={"h-[280px]"}>
            <LineGraph />
          </div>
        </div>

      </div>
    </div>
  );
};

export default Referral;
