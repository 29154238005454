import { AvatarCreatorViewer } from "@readyplayerme/rpm-react-sdk";
import { Form, Formik } from "formik";
import { Modal } from "react-bootstrap";
import { useIntl } from "react-intl";
import { GTMEvent } from "../../../../hooks/gtm_helpers";
import useGTM from "../../../../hooks/useGTM";
import TextInput from "../../../widgets/components/Input/TextInput";
import { ToolTipUI } from "../../../widgets/components/UI/ToolTipUI";


const RpmModal: React.FC<any> = ({
    open,
    setOpen,
    setAvatarUrl
}) => {

    const { formatMessage } = useIntl()
    const {dataLayerPush} = useGTM()
    const handleClose = () => {
        setOpen(false)
    }

    const handleExported = (url:any) => {
        setOpen(false)
        setAvatarUrl(url)
        dataLayerPush(GTMEvent.CustomAvatarSelectionSuccessful)
    }

    return (
        <Modal
            size="lg"
            show={open}
            onHide={handleClose}
            aria-labelledby="contained-modal-title-vcenter"
            className={"z-[999999] min-h-[600px]"}
            centered
            contentClassName={"max-w-[1080px]"}
        >
            <Modal.Header closeButton className={"px-10"}>
                <div className={"flex items-center justify-between w-full"}>
                    <div className="d-flex fw-bold">
                        <h2 className="text-[20px] primary-text leading-[28px]">
                            {formatMessage({ id: "Create Custom Avatar" })}
                        </h2>{" "}
                        <ToolTipUI
                            tooltipText={formatMessage({
                                id: "GLOBAL.TOOLTIP.BILLING.UPGRADE_PLAN",
                            })}
                        />
                    </div>
                    <button onClick={handleClose}>
                        <svg
                            width="32"
                            height="32"
                            viewBox="0 0 32 32"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                d="M21.6568 8.45747L8.45746 21.6568C7.93676 22.1775 7.93676 23.0217 8.45746 23.5424C8.97816 24.0631 9.82238 24.0631 10.3431 23.5424L23.5424 10.3431C24.0631 9.82239 24.0631 8.97817 23.5424 8.45747C23.0217 7.93677 22.1775 7.93677 21.6568 8.45747Z"
                                fill="white"
                            />
                            <path
                                opacity="0.5"
                                d="M23.5425 21.6578L10.3432 8.45843C9.8225 7.93773 8.97828 7.93773 8.45758 8.45843C7.93688 8.97913 7.93688 9.82335 8.45758 10.3441L21.6569 23.5434C22.1776 24.0641 23.0218 24.0641 23.5425 23.5434C24.0632 23.0227 24.0632 22.1785 23.5425 21.6578Z"
                                fill="white"
                            />
                        </svg>
                    </button>
                </div>
            </Modal.Header>
            <div className="h-[600px]">

                <AvatarCreatorViewer
                    subdomain={process.env.REACT_APP_RPM_SUBDOMAIN || "demo"}
                    onAvatarExported={handleExported}
                    avatarConfig={{ morphTargets: ["Oculus Visemes"] }}
                />
            </div>
        </Modal>
    );
};

export default RpmModal;
