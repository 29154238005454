/**
 * High level router.
 *
 * Note: It's recommended to compose related routes in internal router
 * components (e.g: `src/app/modules/Auth/pages/AuthPage`, `src/app/BasePage`).
 */

import { FC, useEffect, useState } from "react";
import { Routes, Route, BrowserRouter, Navigate } from "react-router-dom";
import { PrivateRoutes, SuspensedView } from "./PrivateRoutes";
import { AuthPage, useAuth, getAuth } from "../modules/auth";
import { App } from "../App";
import { LinkedInCallback } from "react-linkedin-login-oauth2";

import { VerifyInvite } from "../modules/auth/components/VerifyInvite";
import { PrivacyPolicy } from "../pages/PrivacyPolicy";
import { TermsOfUse } from "../pages/TermsOfUse";
import { AuthLayout } from "../modules/auth/AuthLayout";
import { ResetPassword } from "../modules/auth/views/ResetPassword";
import { ErrorsPage } from "../modules/errors/ErrorsPage";
import { EmailVerify } from "../modules/auth/views/EmailVerify";
import VerifyEmailID from "../modules/auth/views/VerifyEmailID";
import MarketPlacePublicRoute from "../modules/marketplace-public/MarketPlacePublicRoute";
import ProfilePage from "../modules/marketplace-public/pages/ai-profile";
import UserProfilePage from "../modules/marketplace-public/pages/ai-user-profile";
import MarketplacePublicLayout from "../../_metronic/layout/marketplace-public-layout";
import { PublicRoutes } from "./PublicRoutes";
import WidgetControl from "../widget/WidgetControl";

/**
 * Base URL of the website.
 *
 * @see https://facebook.github.io/create-react-app/docs/using-the-public-folder
 */

const AppRoutes: FC = () => {
  const { userToken, setUserToken, currentUser } = useAuth();
  const [isEmailVerified, setIsEmailVerified] = useState(true);
  const token = getAuth();

  const utmSource =
    new URL(window.location.href).searchParams.get("utm_source") || null;
  const utmMedium =
    new URL(window.location.href).searchParams.get("utm_medium") || null;
  const utmCampaign =
    new URL(window.location.href).searchParams.get("utm_campaign") || null;
  const referral = new URL(window.location.href).searchParams.get("r") || null;

  const lsUtmSource = localStorage.getItem("utm_source");
  const lsUtmMedium = localStorage.getItem("utm_medium");
  const lsUtmCampaign = localStorage.getItem("utm_campaign");
  const lsReferral = localStorage.getItem("referral");

  if (!lsUtmSource) {
    localStorage.setItem(
      "utm_source",
      JSON.stringify({ firstTouch: utmSource })
    );
  } else {
    localStorage.setItem(
      "utm_source",
      JSON.stringify({ ...JSON.parse(lsUtmSource), lastTouch: utmSource })
    );
  }
  if (!lsUtmMedium) {
    localStorage.setItem(
      "utm_medium",
      JSON.stringify({ firstTouch: utmMedium })
    );
  } else {
    localStorage.setItem(
      "utm_medium",
      JSON.stringify({ ...JSON.parse(lsUtmMedium), lastTouch: utmMedium })
    );
  }
  if (!lsUtmCampaign) {
    localStorage.setItem(
      "utm_campaign",
      JSON.stringify({ firstTouch: utmCampaign })
    );
  } else {
    localStorage.setItem(
      "utm_campaign",
      JSON.stringify({ ...JSON.parse(lsUtmCampaign), lastTouch: utmCampaign })
    );
  }
  if (!lsReferral?.length) {
    localStorage.setItem("referral", referral || "");
  }else{
    referral && localStorage.setItem("last_referral", referral || "");
  }

  useEffect(() => {
    if (token) {
      setUserToken(token.token);
    }
  }, []);

  useEffect(() => {
    if (typeof currentUser != "undefined" && currentUser) {
      if (!currentUser.verifiedAt) {
        setIsEmailVerified(false);
      } else {
        setIsEmailVerified(true);
      }
    }
  }, [currentUser]);

  return (
    <BrowserRouter>
      <Routes>
        <Route element={<App />}>
          <Route element={<AuthLayout />}>
            <Route path="terms-of-use" element={<TermsOfUse />} />
            <Route path="privacy-policy" element={<PrivacyPolicy />} />
            <Route
              path="auth/reset-password/:token"
              element={<ResetPassword />}
            />
            <Route path="email-verify" element={<VerifyEmailID />} />
          </Route>
            <Route path="widget" element={<WidgetControl/>}/>
          {userToken ? (
            <>
              {isEmailVerified ? (
                <>
                  {/* <Route path="/auth/login" element={<Navigate to={'/dashboard'} replace />} />
                  <Route path="/auth/registration" element={<Navigate to={'/dashboard'} replace />} /> */}
                  <Route
                    path="/auth/invite/:inviteToken"
                    element={<VerifyInvite />}
                  />
                  <Route path="/*" element={<PrivateRoutes />} />
                  {/* <Route path='email-verify' element={<Navigate to={'/dashboard'} replace />} /> */}
                </>
              ) : (
                <>
                  <Route element={<AuthLayout />}>
                    {/* <Route path="email-verify" element={<EmailVerify />} /> */}
                    <Route path="email-verify" element={<VerifyEmailID />} />
                  </Route>
                  <Route
                    path="*"
                    element={<Navigate to={"email-verify"} replace />}
                  />
                </>
              )}
            </>
          ) : (
            <>
              <Route path="/*" element={<PublicRoutes />} />
            </>
          )}
        </Route>
      </Routes>
    </BrowserRouter>
  );
};

export { AppRoutes };
