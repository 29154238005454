import React, { ReactElement, useEffect, useRef, useState } from 'react'
import useSpeech2Text from '../../../hooks/useSpeechRecognition';
import { useAuth } from '../../auth';
import AWS from "aws-sdk";
import request from '../../../core/_apis';
import axios from 'axios';
import { addChat, closeChatSession, createChatSession, getCurrentChatSession } from '../../apps/chat/core/_request';
import { CALL_BALANCE_REQUIRED_FOR_MINS, CALL_TIMEOUT_IN_MS } from '../../../core/_constants';
//@ts-ignore
import song from "../../recharge/assets/ringtone/iphone.mp3"

import Lottie from "lottie-react";
import ringingAnimation from "../../../assets/lotties/ringing-animation.json"
import micAnimation from "../../../assets/lotties/mic-animation.json"
import { getCdnUrl } from '../../../core/_util';
import Footer from '../../call/components/footer';
import { toAbsoluteUrl } from '../../../../_metronic/helpers';
import clsx from 'clsx';
import { getUserBalance } from '../../chat-credits/core/_request';
import SubscribeModal from '../../marketplace-public/components/ai-profile/modals/subscribe-modal';
export enum SpeechServiceProvider {
  Polly,
  ElevenLabs,
  PlayHT
}

AWS.config.update({
  region: (process.env.REACT_APP_AWS_POLLY_IDENTITY_POOL_ID || '')?.split(":")?.[0] || 'us-east-1',
  credentials: new AWS.CognitoIdentityCredentials({ IdentityPoolId: process.env.REACT_APP_AWS_POLLY_IDENTITY_POOL_ID || '' })
});

interface ContainerProps {
  open: boolean;
  handleClose: () => void

}
export default function CallContainer({ open, handleClose }: ContainerProps) {
  const [openSubscribeModal, setOpenSubscribeModal] = useState(false);
  const [message, setMessage] = useState<string>('')
  const emojiRegex = /[\u{1F600}-\u{1F64F}]|[\u{1F300}-\u{1F5FF}]|[\u{1F680}-\u{1F6FF}]|[\u{1F700}-\u{1F77F}]|[\u{1F780}-\u{1F7FF}]|[\u{1F800}-\u{1F8FF}]|[\u{1F900}-\u{1F9FF}]|[\u{1FA00}-\u{1FA6F}]|[\u{2600}-\u{26FF}]|[\u{2700}-\u{27BF}]|\u{23E9}|\u{23EA}|\u{23EB}|\u{23EC}|\u{2B05}|\u{2B06}|\u{2B07}|\u{2B1B}|\u{2B1C}|\u{2B50}|\u{2B55}|[0-9]\u{FE0F}\u{20E3}|\u{203C}\u{FE0F}|\u{2049}\u{FE0F}|\u{2122}\u{FE0F}|\u{2139}\u{FE0F}|\u{2194}-\u{2199}|\u{21A9}\u{FE0F}|\u{21AA}\u{FE0F}|\u{231A}\u{FE0F}|\u{231B}\u{FE0F}|\u{2328}\u{FE0F}|\u{23CF}\u{FE0F}|\u{23E9}\u{FE0F}|\u{23EA}\u{FE0F}|\u{23EB}\u{FE0F}|\u{23EC}\u{FE0F}|\u{23ED}\u{FE0F}|\u{23EE}\u{FE0F}|\u{23EF}\u{FE0F}|\u{23F0}\u{FE0F}|\u{23F1}\u{FE0F}|\u{23F2}\u{FE0F}|\u{23F3}\u{FE0F}|\u{23F8}\u{FE0F}|\u{23F9}\u{FE0F}|\u{23FA}\u{FE0F}|\u{24C2}\u{FE0F}/ug;
  const [currentChunkIndex, setCurrentChunkIndex] = useState(0);
  const [visemeChunks, setVisemeChunks] = useState<any>([])
  const [playlist, setPlaylist] = useState<any>([]);
  const [playing, setPlaying] = useState(false);
  const { startListening, stopListening } = useSpeech2Text(sendMessage, setMessage);
  const { setPauseListening, pauseListening, avatarMuted, personalityInView, referenceData, currentUser, setCurrentUser, credits } = useAuth()
  const [text, setText] = useState('')
  const [chunks, setChunks] = useState<any>([]);
  const [currentChatSession, setCurrentChatSession] = useState<any>()
  const audioRef = useRef(null)
  const [stage, setStage] = useState<number>(0)
  const [seconds, setSeconds] = useState<number>(0)
  const [availableMinutes, setAvailableMinutes] = useState<number>(0)
  const [loading , setLoading] = useState<boolean>(true)
  let voiceId: any;
  let service: SpeechServiceProvider = SpeechServiceProvider.Polly
  const voiceType = personalityInView?.personalityJson?.voice?.voice_type;
  const cpm = personalityInView?.listings?.chat_monetization?.charge_per_minute ?? 1
  const isClonedVoice = voiceType === 'cloned';
  const errorAction: { [key: string]: { close: boolean; create: boolean } } = {
    SESSION_EXPIRED: { close: true, create: false },
    LOW_BALANCE: { close: true, create: false },
    NO_BALANCE: { close: true, create: false },
    SESSION_NOT_FOUND: { close: true, create: false },
    TRIAL_LIMIT_EXCEEDED: {close: true, create:false}
  };
  if (voiceType === 'stock') {
    voiceId = personalityInView?.personalityJson?.voice?.model;
    service = SpeechServiceProvider.Polly
  } else if (isClonedVoice) {
    let cloneEnabled: boolean
    if (personalityInView?.personalityJson?.voice?.brand == 'play-ht') {
      cloneEnabled = referenceData?.platformConfig?.['VOICE_SERVICE_PLAY_HT_ENABLED'] === 'true';
      service = SpeechServiceProvider.PlayHT
    } else if (personalityInView?.personalityJson?.voice?.brand == '11labs') {
      cloneEnabled = referenceData?.platformConfig?.['VOICE_SERVICE_11_LABS_ENABLED'] === 'true';
      service = SpeechServiceProvider.ElevenLabs
    } else {
      cloneEnabled = false
      service = SpeechServiceProvider.Polly
    }
    if (cloneEnabled) {
      voiceId = personalityInView?.personalityJson?.voice?.cloned_voices[0]?.voiceId;
    } else {
      service = SpeechServiceProvider.Polly
      const defaultVoiceId = personalityInView?.personalityJson?.avatar?.gender === 'Male'
        ? referenceData?.platformConfig?.['POLLY_DEFAULT_MALE_VOICE_ID']
        : referenceData?.platformConfig?.['POLLY_DEFAULT_FEMALE_VOICE_ID'];
      voiceId = defaultVoiceId;
    }
  } else {
    service = SpeechServiceProvider.Polly
    const defaultVoiceId = personalityInView?.personalityJson?.avatar?.gender === 'Male'
      ? referenceData?.platformConfig?.['POLLY_DEFAULT_MALE_VOICE_ID']
      : referenceData?.platformConfig?.['POLLY_DEFAULT_FEMALE_VOICE_ID'];
    voiceId = defaultVoiceId;
  }

  const fetchTextToSpeech = async (text: string) => {
    const textChunks = splitTextIntoChunks(text) || [];
    setChunks(textChunks);

    for (let i = 0; i < textChunks?.length; i++) {
      const params = {
        OutputFormat: 'mp3',
        Text: textChunks[i],
        VoiceId: voiceId
      };
      const visemeParams = {
        OutputFormat: 'json',
        Text: textChunks[i],
        VoiceId: service == SpeechServiceProvider.Polly ? voiceId : 'Justin',
        SpeechMarkTypes: ['viseme']
      }
      try {
        const polly = new AWS.Polly();
        // here deduct for polly viseme data
        await request.post('/polly-access', { text: textChunks[i] }, { headers: { 'x-personality-id': personalityInView.personalityId } })

        if (service == SpeechServiceProvider.Polly) {
          // here deduct for polly voice
          await request.post('/polly-access', { text: textChunks[i] }, { headers: { 'x-personality-id': personalityInView.personalityId } })
          const result = await polly.synthesizeSpeech(params).promise();
          setPlaylist((prevPlaylist: any) => [...prevPlaylist, result.AudioStream]);
        }
        if (service == SpeechServiceProvider.ElevenLabs) {
          const result = await get11LabsConvert(textChunks[i]);
          setPlaylist((prevPlaylist: any) => [...prevPlaylist, result]);
        }
        if (service == SpeechServiceProvider.PlayHT) {
          const result = await getPlayHtConvert(textChunks[i]);
          setPlaylist((prevPlaylist: any) => [...prevPlaylist, result]);
        }

        setCurrentChunkIndex(i + 1);
      } catch (error) {
        console.error('Error synthesizing speech:', error);
        setPauseListening(false)
      }
    }
  };


  const get11LabsConvert = async (text: any) => {
    // const headers = {
    //   'accept': 'audio/mpeg',
    //   'xi-api-key': process.env.REACT_APP_20_KI || '',
    //   'Content-Type': 'application/json'
    // };

    // const requestData = {
    //   text: text,
    //   model_id: 'eleven_monolingual_v1',
    //   voice_settings: {
    //     stability: 0.5,
    //     similarity_boost: 0.5
    //   }
    // };
    let relayUrl = await getRelayUrl(text)
    const requestOptions = {
      method: 'GET',
      // headers: headers,
      // body: JSON.stringify(requestData)
    };

    try {
      const response = await fetch(relayUrl, requestOptions);
      const blobData = await response.blob();
      const url = URL.createObjectURL(blobData);
      return url;
    } catch (error) {
      console.error('Error:', error);
      // Handle the error as needed
      return null;
    }
  }

  const getPlayHtConvert = async (text: any) => {
    const requestOptions = {
      method: 'GET',
    };
    let relayUrl = await getRelayUrl(text)
    try {
      const response = await fetch(relayUrl, requestOptions);
      const blobData = await response.blob();
      const url = URL.createObjectURL(blobData);
      return url;
    } catch (error) {
      console.error('Error:', error);
      // Handle the error as needed
      return null;
    }
  }

  const getRelayUrl = async (textForConvert: string) => {
    let serviceReq = service == SpeechServiceProvider.PlayHT ? 'play-ht' : (service == SpeechServiceProvider.ElevenLabs ? '11labs' : false)
    if (!serviceReq) {
      return;
    }
    const resp = await axios.post(
      process.env.REACT_APP_BASE_API_URL + '/streaming/relay-url',
      {
        text: textForConvert,
        voiceId: voiceId,
        service: serviceReq
      },
      { headers: { 'x-personality-id': personalityInView.personalityId } }
    );
    if (resp.status == 200) {
      return resp.data.data.url
    }
  }

  useEffect(() => {
    if (playlist.length > 0 && !playing) {
      setPlaying(true);
      playChunk(playlist[0], visemeChunks[0]);
      // playViseme(visemeChunks[0]);
    }
  }, [playlist, playing]);

  useEffect(() => {
    if (text) {
      fetchTextToSpeech(text)
    }
  }, [text])


  function checkSessionValid(date: string) {
    return (
      new Date().valueOf() - new Date(date).valueOf() <= CALL_TIMEOUT_IN_MS
    );
  }

  async function closeSession(sessionId: string, reason?: string) {
    const {
      data: { success, data, errors },
    } = await closeChatSession(sessionId, reason);
    if (success) {
      setCurrentChatSession(undefined);
    }
  }

  function prepare(){
    document.dispatchEvent(new Event('reset-audio'))
    stopListening()
    setText("")
    setMessage("")
    startListening()
    setPauseListening(false)
  }


  async function getLatestCallSession(){
    if (!personalityInView)
    return;
    const { data: { success, data } } = await getCurrentChatSession(personalityInView.personalityId, 'call')
    if (success) {
      if (data) {
        setCurrentChatSession(data);
        let isValid = checkSessionValid(
          data?.lastMessageAt || data?.startedAt
        );
        if (!isValid) {
          closeSession(data.id, "SESSION_EXPIRED");
        }
      } else {
        setCurrentChatSession(null);
      }
    }
  }


  async function createSession() {
    const {
      data: { success, data, errors },
    } = await createChatSession(personalityInView.personalityId, 'call');
    if (success) {
      setCurrentChatSession(data);
      return data.id;
    } else {
      setPauseListening(false)
      if(["NO_BALANCE","LOW_BALANCE"].includes(errors[0])){
        setOpenSubscribeModal(true)
        endCall()
      }
    }
    return null;
  }

  useEffect(() => {
    if (!currentChatSession) {
      setSeconds(0)
      return;
    }
      currentChatSession.lastMessageAt && setSeconds(Math.ceil((new Date(currentChatSession.lastMessageAt).valueOf() -new Date(currentChatSession.startedAt).valueOf())/1000) )
    const interval = setInterval(() => {
      setSeconds((prevSeconds) => prevSeconds + 1);
    }, 1000);

    let intervalId: any;
    intervalId = setInterval(async () => {
      if (!currentChatSession) {
        clearInterval(intervalId);
      }
      if (
        currentChatSession &&
        !checkSessionValid(
          currentChatSession.lastMessageAt || currentChatSession.startedAt
        )
      ) {
        clearInterval(intervalId);
        await closeSession(currentChatSession.id, "SESSION_EXPIRED");
      }
    }, 2000);

    return () => {
      clearInterval(intervalId);
      clearInterval(interval);
    };
  }, [currentChatSession]);

  async function sendMessage() {
    let currentSessionId = currentChatSession?.id;
    if (!currentSessionId) {
      currentSessionId = await createSession();
    }
    if (!currentSessionId) {
      return;
    }
    setMessage("");

    const {
      data: { success, data, errors },
      status,
    } = await addChat(message, currentSessionId);
    if (success) {
      setMessage("");
      setCurrentChatSession((currentChatSession: any) => {
        return { ...currentChatSession, lastMessageAt: data.createdAt };
      });
      if (data.error && errorAction[data.error]?.close) {
        await closeSession(currentSessionId, data.error);
        prepare()
      } else {
        setText(data.response?.replace(emojiRegex, ""))
      }
    } else {
      setPauseListening(false)
    }
  };


  // Function to split text into chunks of 300 characters
  const splitTextIntoChunks = (text: string) => {
    // const chunkSize = 300;
    // const regex = /.{1,300}\s|\S+$/g;
    // return text.match(regex);
    return [text]
  };

  const playAudioInSync = (url: any) => {
    const audio = new Audio(url);
    return new Promise((resolve, reject) => {
      audio.addEventListener('canplay', () => {
        // audio.load()
        resolve(audio);
      });
      audio.addEventListener('error', (error) => {
        reject(error);
      });
      audio.load(); // Start loading the audio
      if (audio.readyState >= 4) {
        // audio.play()
        resolve(audio);
      }
    });
  };


  const playChunk = async (audioStream: any, visemes: any) => {
    let audioUrl
    if (service == SpeechServiceProvider.Polly) {
      const audioBlob = new Blob([audioStream], { type: 'audio/mp3' });
      audioUrl = URL.createObjectURL(audioBlob);
    }
    if (service == SpeechServiceProvider.ElevenLabs) {
      audioUrl = audioStream
    }
    if (service == SpeechServiceProvider.PlayHT) {
      audioUrl = audioStream
    }

    // const audioElement = new Audio(audioUrl);
    // audioElement.play();
    let audio: any = await playAudioInSync(audioUrl)
    audio.play()
    let duration = audio.duration * 1000
    audio.muted = avatarMuted
    const mute = () => {
      audio.muted = true; // Toggle the muted state
    };
    const unmute = () => {
      audio.muted = false; // Toggle the muted state
    };

    const resetAudio = ()=>{
      audio.pause()
      audio.src=""
    }
    document.addEventListener('mute', mute);
    document.addEventListener('unmute', unmute);
    document.addEventListener('reset-audio',resetAudio)
    // Wait for the current audio to finish before playing the next chunk
    await new Promise<void>(resolve => {
      audio.addEventListener('ended', () => {
        if (playlist.length === 1) {
          setPauseListening(false)
        }
        // Remove the played chunk from the playlist
        setPlaylist((prevPlaylist: any) => prevPlaylist.slice(1));
        setPlaying(false); // Allow the next chunk to play
        document.removeEventListener('mute', mute);
        document.removeEventListener('unmute', unmute);
        document.removeEventListener('reset-audio',resetAudio)
        resolve();
      });
    });
  }

  async function fetchBalanceAndCalculateMinutes(){
    const {
      data: { success, data },
    }  = await getUserBalance()
    if(success){
      setAvailableMinutes(Math.floor(data.amount/(cpm)))
    }
  }
  useEffect(() => {
    if (stage == 1) {
      const audio: any = audioRef?.current
      if (!audio) {
        return;
      }

      audio.src = song
      audio.loop = true
      audio.play()
      let timeout = setTimeout(() => {
        startListening();
        !openSubscribeModal && setStage(2)
      }, 2000)
      return () => {
        clearTimeout(timeout)
        audio.pause()
        audio.src = ""
      }

    } else if (stage == 2) {
      getLatestCallSession()
    } else if(stage==3){
      fetchBalanceAndCalculateMinutes()
    }
  }, [stage])

  const formatTime = (time: number) => {
    const minutes = Math.floor(time / 60);
    const remainingSeconds = time % 60;

    const formattedMinutes = String(minutes).padStart(2, '0');
    const formattedSeconds = String(remainingSeconds).padStart(2, '0');

    return `${formattedMinutes}:${formattedSeconds}`;
  };


  function endCall(){
    if(currentChatSession){
      closeSession(currentChatSession.id,'CLOSED_BY_USER')
    }
    setStage(3)
    document.dispatchEvent(new Event('reset-audio'))
    stopListening()
    setText("")
    setMessage("")
  }


  useEffect(() => {
    setPauseListening(false)
  }, [])

  useEffect(() => {
    //check available minutes
    setLoading(false)
    const isFirstSession = currentUser?.trialChatVoiceUsed ? (currentUser.trialChatVoiceUsed=="10" ? true : false) :true
    if(isFirstSession){
      setCurrentUser((currentUser: any) => {
        return { ...currentUser, trialChatVoiceUsed:  currentUser?.trialChatVoiceUsed ? "11":"01" };
      });
    }
    if(personalityInView?.isOwned || isFirstSession){
      setStage(1)
      return;
    }
    const mins = Math.floor((credits || 0) / (cpm))
    if (mins < CALL_BALANCE_REQUIRED_FOR_MINS) {
      setOpenSubscribeModal(true)
    }else{
      setOpenSubscribeModal(false)
      setStage(1)
    }
  }, [credits,personalityInView])


  if (!open || loading) {
    return null
  }

  if(openSubscribeModal){
    return   <SubscribeModal
    open={openSubscribeModal}
    setOpen={() => {
      endCall()
      handleClose()
    }}
    setStartChat={() => { }}
    insufficient={true}
    personalityImage={getCdnUrl(personalityInView?.image)}
    userImage={getCdnUrl(currentUser?.photo || "")}
    setSubscribed={() => { }}
    isSubscribed={true}
    cpm={cpm}
    subUpdate={false}
    setSubUpdate={() => { }}
    setSubscription={() => { }}
  />
  }


  const component: Record<number, ReactElement> = {
    1: <>
      <Lottie style={{ height: 100, transform:"scale(1.5)" }} animationData={ringingAnimation} loop={true} className={'scale-1.5'} />
      <p className={'my-2.5'}>Ringing...</p>
    </>,
    2: <>
      <p className="mb-3">{playing ? "Sunny is Speaking..." : (pauseListening ? "Sunny is thinking..." : "Sunny is Listening...")}</p>

      <div className="h-[100px] flex items-center gap-x-8">
        <img className={'w-[57px] h-[57px] rounded-full border-[3px] border-[#C2C751]'} src={getCdnUrl(currentUser?.photo || "")} />

        {!pauseListening ? <Lottie style={{
          width: 100,
          height: 100,
          // borderTopLeftRadius: 0,
          // overflow: "hidden",
        }} animationData={micAnimation} /> : <svg width="80" height="50" viewBox="0 0 42 42" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M41.5465 21.0696C41.5465 32.417 32.3477 41.6159 21.0003 41.6159C9.65294 41.6159 0.454102 32.417 0.454102 21.0696C0.454102 9.72227 9.65294 0.523438 21.0003 0.523438C32.3477 0.523438 41.5465 9.72227 41.5465 21.0696Z" fill="#366745" />
          <path d="M21 24.9016C22.0343 24.9016 23.0263 24.4907 23.7577 23.7593C24.4891 23.0279 24.9 22.0359 24.9 21.0016V14.5016C24.9 13.4672 24.4891 12.4752 23.7577 11.7438C23.0263 11.0125 22.0343 10.6016 21 10.6016C19.9656 10.6016 18.9737 11.0125 18.2423 11.7438C17.5109 12.4752 17.1 13.4672 17.1 14.5016V21.0016C17.1 22.0359 17.5109 23.0279 18.2423 23.7593C18.9737 24.4907 19.9656 24.9016 21 24.9016ZM21 23.6016C20.3104 23.6016 19.6491 23.3276 19.1615 22.84C18.6739 22.3524 18.4 21.6911 18.4 21.0016V14.5016C18.4 13.812 18.6739 13.1507 19.1615 12.6631C19.6491 12.1755 20.3104 11.9016 21 11.9016C21.6895 11.9016 22.3509 12.1755 22.8385 12.6631C23.326 13.1507 23.6 13.812 23.6 14.5016V21.0016C23.6 21.6911 23.326 22.3524 22.8385 22.84C22.3509 23.3276 21.6895 23.6016 21 23.6016ZM14.5 20.3516C14.6724 20.3516 14.8377 20.42 14.9596 20.5419C15.0815 20.6638 15.15 20.8292 15.15 21.0016C15.15 21.7698 15.3013 22.5305 15.5953 23.2403C15.8893 23.95 16.3202 24.5949 16.8634 25.1381C17.4066 25.6814 18.0515 26.1123 18.7613 26.4063C19.471 26.7002 20.2317 26.8516 21 26.8516C21.7682 26.8516 22.5289 26.7002 23.2387 26.4063C23.9484 26.1123 24.5933 25.6814 25.1365 25.1381C25.6798 24.5949 26.1107 23.95 26.4047 23.2403C26.6987 22.5305 26.85 21.7698 26.85 21.0016C26.85 20.8292 26.9185 20.6638 27.0404 20.5419C27.1623 20.42 27.3276 20.3516 27.5 20.3516C27.6724 20.3516 27.8377 20.42 27.9596 20.5419C28.0815 20.6638 28.15 20.8292 28.15 21.0016C28.1502 22.7855 27.4836 24.5051 26.2809 25.8227C25.0783 27.1403 23.4265 27.9608 21.65 28.123V30.7516C21.65 30.924 21.5815 31.0893 21.4596 31.2112C21.3377 31.3331 21.1724 31.4016 21 31.4016C20.8276 31.4016 20.6623 31.3331 20.5404 31.2112C20.4185 31.0893 20.35 30.924 20.35 30.7516V28.123C18.5734 27.9608 16.9217 27.1403 15.719 25.8227C14.5164 24.5051 13.8497 22.7855 13.85 21.0016C13.85 20.8292 13.9185 20.6638 14.0404 20.5419C14.1623 20.42 14.3276 20.3516 14.5 20.3516Z" fill="white" />
        </svg>}

        <p>{pauseListening ? "Please Wait..." : "Speak now..."}</p>
      </div>
      <p className='min-h-[16px]'>{message}</p>
    </>,
    3: <>
      <div className="mb-3 flex items-center ">
        <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 48 48" fill="none">
          <g clipPath="url(#clip0_10666_34637)">
            <path d="M24.0003 2.66406C19.781 2.66406 15.6564 3.91524 12.1482 6.25938C8.63992 8.60351 5.90557 11.9353 4.2909 15.8335C2.67624 19.7316 2.25376 24.0211 3.07692 28.1593C3.90007 32.2976 5.93187 36.0988 8.91539 39.0823C11.8989 42.0659 15.7001 44.0977 19.8384 44.9208C23.9767 45.744 28.2661 45.3215 32.1642 43.7068C36.0624 42.0922 39.3942 39.3578 41.7384 35.8496C44.0825 32.3413 45.3337 28.2167 45.3337 23.9974C45.3337 18.3394 43.0861 12.9132 39.0853 8.91245C35.0845 4.91168 29.6583 2.66406 24.0003 2.66406ZM24.0003 42.6641C20.3084 42.6641 16.6994 41.5693 13.6297 39.5182C10.56 37.467 8.16742 34.5517 6.75458 31.1408C5.34175 27.7299 4.97209 23.9767 5.69234 20.3557C6.4126 16.7347 8.19043 13.4086 10.801 10.7981C13.4116 8.18749 16.7377 6.40966 20.3587 5.6894C23.9796 4.96915 27.7329 5.33881 31.1438 6.75165C34.5546 8.16448 37.47 10.557 39.5211 13.6268C41.5722 16.6965 42.667 20.3055 42.667 23.9974C42.667 28.9481 40.7003 33.696 37.1997 37.1967C33.699 40.6974 28.951 42.6641 24.0003 42.6641Z" fill="#3DD883" />
            <path d="M14 25L19.6 31L34 18" stroke="#3DD883" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
          </g>
          <defs>
            <clipPath id="clip0_10666_34637">
              <rect width="48" height="48" fill="white" />
            </clipPath>
          </defs>
        </svg>
        <h2 className="text-[20px] font-semibold text-white">Call Disconnected!</h2>
      </div>
      <p className="mb-3 text-[#FFFFFFCC]">Let’s get to know each other better! </p>
      <button className="bg-[#399717] py-3 rounded cursor-pointer mb-3 w-full flex items-center justify-center gap-x-3" onClick={() => { }}>
        <svg xmlns="http://www.w3.org/2000/svg" width="25" height="24" viewBox="0 0 25 24" fill="none">
          <g clipPath="url(#clip0_10665_33417)">
            <path d="M21.5 16.42V19.956C21.5001 20.2092 21.4042 20.453 21.2316 20.6382C21.059 20.8234 20.8226 20.9363 20.57 20.954C20.133 20.984 19.776 21 19.5 21C10.663 21 3.5 13.837 3.5 5C3.5 4.724 3.515 4.367 3.546 3.93C3.56372 3.67744 3.67658 3.44101 3.8618 3.26841C4.04703 3.09581 4.29082 2.99989 4.544 3H8.08C8.20404 2.99987 8.3237 3.04586 8.41573 3.12902C8.50776 3.21218 8.5656 3.32658 8.578 3.45C8.601 3.68 8.622 3.863 8.642 4.002C8.84073 5.38892 9.248 6.73783 9.85 8.003C9.945 8.203 9.883 8.442 9.703 8.57L7.545 10.112C8.86445 13.1865 11.3145 15.6365 14.389 16.956L15.929 14.802C15.9919 14.714 16.0838 14.6509 16.1885 14.6237C16.2932 14.5964 16.4042 14.6068 16.502 14.653C17.767 15.2539 19.1156 15.6602 20.502 15.858C20.641 15.878 20.824 15.899 21.052 15.922C21.1752 15.9346 21.2894 15.9926 21.3723 16.0846C21.4553 16.1766 21.5001 16.2961 21.5 16.42Z" fill="white" />
          </g>
          <defs>
            <clipPath id="clip0_10665_33417">
              <rect width="24" height="24" fill="white" transform="translate(0.5)" />
            </clipPath>
          </defs>
        </svg>
        <h5 onClick={() => setStage(1)} className={'text-[14px] font-semibold text-white'}>Call Me Now</h5>
      </button>

      <div className="flex justify-between items-center gap-x-4">
        <p className="text-[#FFFFFFCC]">{availableMinutes} minutes available</p>

        <button onClick={() => {

        }} className="flex items-center gap-x-1">
          <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
            <circle cx="10" cy="10" r="10" fill="#C2D24B" />
            <rect x="9" y="4" width="2" height="12" fill="black" />
            <rect x="4" y="11" width="2" height="12" transform="rotate(-90 4 11)" fill="black" />
          </svg>
          <h4 className="text-[#C2D24B] text-[16px] font-medium">Add Credits</h4>
        </button>
      </div>
    </>
  }

  return (
    <div className='fixed z-[999999] overflow-hidden bg-[#11121ccf]  top-0 left-0 w-full h-full flex gap-x-2 justify-center align-items-center p-4'>
      <audio ref={audioRef} src="" />
      <div style={{ height: "95dvh" }} className={'relative max-w-[370px] flex flex-col justify-end h-full w-full ' + (stage == 3 ? 'bg-image-successful' : 'bg-image-speaking')}>
        <div className={'recharge-footer-container px-5 flex flex-col justify-end'}>
          <div className={clsx('recharge-input-container p-3 pb-4 flex flex-col',stage<3 ? 'h-full items-center':'')}>
            {stage < 3 && <div className="-mt-[50px] relative  w-[90px] !h-[90px]">
              {playing && <div className={'bg-[#C2C751] rounded-full p-1 blur-md w-[80px] h-[80px] absolute z-10'} />}
              <img src={toAbsoluteUrl('/recharge/images/sunny.png')}  className={'z-40 absolute bg-[#1F083E] w-[74px] h-[74px] rounded-full'}/>
              <p className="absolute  right-5 bottom-[5px] !text-[20px] z-[50]">😍</p>
            </div>}

            {component[stage]}
            {stage < 3 && <div className={'flex flex-col items-center mt-2'}>
              <button className="btn btn-back w-36" onClick={endCall}>
                <a className="underline-0 text-white">
                  End Call
                </a>
              </button>
            </div>}
          </div>
          <Footer />
        </div>
        <div className={'flex justify-end px-5'}>
          {stage == 2 && <div className={'absolute top-[20px]  bg-[#171825CC] px-3 py-[9px] rounded-[64px] flex items-center gap-x-2 shadow-[0px_3px_4px_0px_rgba(0,0,0,0.10)]'}>
            <svg xmlns="http://www.w3.org/2000/svg" width="13" height="13" viewBox="0 0 13 13" fill="none">
              <circle cx="6.5" cy="6.5" r="6.5" fill="url(#paint0_linear_11073_50942)" />
              <defs>
                <linearGradient id="paint0_linear_11073_50942" x1="6.5" y1="0" x2="6.5" y2="13" gradientUnits="userSpaceOnUse">
                  <stop stopColor="#FF4F4F" />
                  <stop offset="1" stopColor="#E3261A" />
                </linearGradient>
              </defs>
            </svg>

            <p className={'text-white text-[14px]'}>{formatTime(seconds)}</p>
          </div>}
        </div>
      </div>
      <span onClick={()=>{
        endCall()
        handleClose()
      }} className='align-self-start'>
        <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http:www.w3.org/2000/svg">
          <g clip-path="url(#clip0_11327_43016)">
            <path d="M26.5128 5.50398C26.3584 5.34929 26.1751 5.22656 25.9732 5.14282C25.7713 5.05908 25.5549 5.01598 25.3364 5.01598C25.1178 5.01598 24.9014 5.05908 24.6996 5.14282C24.4977 5.22656 24.3143 5.34929 24.16 5.50398L16.0001 13.6472L7.84015 5.48729C7.68566 5.3328 7.50225 5.21025 7.3004 5.12664C7.09855 5.04303 6.8822 5 6.66372 5C6.44524 5 6.22889 5.04303 6.02704 5.12664C5.82519 5.21025 5.64178 5.3328 5.48729 5.48729C5.3328 5.64178 5.21025 5.82519 5.12664 6.02704C5.04303 6.22889 5 6.44524 5 6.66372C5 6.8822 5.04303 7.09855 5.12664 7.3004C5.21025 7.50225 5.3328 7.68566 5.48729 7.84015L13.6472 16.0001L5.48729 24.16C5.3328 24.3144 5.21025 24.4979 5.12664 24.6997C5.04303 24.9016 5 25.1179 5 25.3364C5 25.5549 5.04303 25.7712 5.12664 25.9731C5.21025 26.1749 5.3328 26.3583 5.48729 26.5128C5.64178 26.6673 5.82519 26.7899 6.02704 26.8735C6.22889 26.9571 6.44524 27.0001 6.66372 27.0001C6.8822 27.0001 7.09855 26.9571 7.3004 26.8735C7.50225 26.7899 7.68566 26.6673 7.84015 26.5128L16.0001 18.3529L24.16 26.5128C24.3144 26.6673 24.4979 26.7899 24.6997 26.8735C24.9016 26.9571 25.1179 27.0001 25.3364 27.0001C25.5549 27.0001 25.7712 26.9571 25.9731 26.8735C26.1749 26.7899 26.3583 26.6673 26.5128 26.5128C26.6673 26.3583 26.7899 26.1749 26.8735 25.9731C26.9571 25.7712 27.0001 25.5549 27.0001 25.3364C27.0001 25.1179 26.9571 24.9016 26.8735 24.6997C26.7899 24.4979 26.6673 24.3144 26.5128 24.16L18.3529 16.0001L26.5128 7.84015C27.1469 7.20605 27.1469 6.13808 26.5128 5.50398Z" fill="white" />
          </g>
          <defs>
            <clipPath id="clip0_11327_43016">
              <rect width="32" height="32" fill="white" />
            </clipPath>
          </defs>
        </svg>
      </span>
    </div>
  )
}
