import React from "react";
import Header from "../../components/leaderboard/header";
import LeaderboardList from "../../components/leaderboard/leaderboard-list";

import { useIntl } from "react-intl";

const Index = () => {
  const { formatMessage } = useIntl();

  return (
    <div>
      <Header />
      <LeaderboardList />
    </div>
  );
};

export default Index;
