import { DateTime } from "luxon";
import React, { useState } from "react";
import { useIntl } from "react-intl";
import { useNavigate } from "react-router-dom";
import Empty from "../../../components/empty/Empty";
import ReadMore from "../../../components/utility/readMore";
import { getCdnUrl } from "../../../core/_util";

interface ListItem {
  id: number;
  username: string;
  name: string;
  lastMessageText: string;
  verifiedIcon: boolean;
  userProfileImage: string;
  date: string;
  starred: boolean;
  unreadCount: number;
}

interface UserListProps {
  listData: ListItem[];
}

export const showPastForChat = (date: any) => {
  if (!date) {
    return "";
  }

  const now = DateTime.utc();
  const pastTime = DateTime.fromISO(date);

  const diff = now.diff(pastTime, ["seconds", "minutes", "hours", "days", "months", "years"]).toObject();

  if (diff.years !== undefined && diff.years >= 1) {
    if (diff.years === 1) {
      return "1 year ago";
    } else {
      return `${diff.years} years ago`;
    }
  } else if (diff.months !== undefined && diff.months >= 1) {
    if (diff.months === 1) {
      return "1 month ago";
    } else {
      return `${diff.months} months ago`;
    }
  } else if (diff.days !== undefined && diff.days >= 1) {
    if (diff.days === 1) {
      return "Yesterday";
    } else if (diff.days <= 7) {
      return pastTime.toFormat("EEE");
    } else {
      return pastTime.toFormat("MMM dd");
    }
  } else if (diff.hours !== undefined && diff.hours >= 1) {
    return pastTime.toFormat("hh:mm a");
  } else if (diff.minutes !== undefined && diff.minutes >= 1) {
    return pastTime.toFormat("hh:mm a");
  } else {
    return "just now";
  }
};

const UserList: React.FC<UserListProps> = ({ listData }) => {
  const [selectedItemId, setSelectedItemId] = useState<number | null>(null);
  const navigate = useNavigate()
  const {formatMessage} = useIntl()
  const handleItemClick = (item: ListItem) => {
    setSelectedItemId(item.id);
    navigate(`/communicate-engage/user-inbox?tab=user-inbox&u=${item.username}`)
  };
  return (
    <div className="overflow-y-auto h-[calc(83vh-100px)] sm:h-[calc(55vh+100px)] custom-scroll">
      {
        listData.length > 0
        ?
        <ul className="">
          {listData.map((item) => (
            <li
              key={item.id}
              onClick={() => handleItemClick(item)}
              className={`flex flex-row cursor-pointer justify-between p-[10px_8px] border-b border-solid border-[#2E2F45] min-h-[60px] ${selectedItemId === item.id ? "bg-[#21233A]" : ""
                }`}
            >
              <div className="flex flex-row gap-[4px]">
                <img
                  src={getCdnUrl(item.userProfileImage)}
                  className="rounded-full min-w-[40px] h-[40px] bg-grey object-cover object-top"
                  alt=""
                ></img>
                <div className="flex flex-col  gap-[2px]">
                  <p className="flex flex-row gap-[4px] items-center text-[#FFFFFFCC] text-[14px] leading-[20px] font-[500]">
                    <ReadMore lines={1} onlyTruncate={true}>{item.name}</ReadMore>

                    {item.verifiedIcon ? (
                      <svg
                        width="16"
                        height="16"
                        viewBox="0 0 16 16"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M8 0L9.90354 2.14149L12.7023 1.52786L12.9835 4.37924L15.6085 5.52786L14.16 8L15.6085 10.4721L12.9835 11.6208L12.7023 14.4721L9.90354 13.8585L8 16L6.09646 13.8585L3.29772 14.4721L3.01646 11.6208L0.391548 10.4721L1.84 8L0.391548 5.52786L3.01646 4.37924L3.29772 1.52786L6.09646 2.14149L8 0Z"
                          fill="#4776E6"
                        />
                        <path
                          d="M5.53809 7.9985L7.18265 9.84465L11.3842 6.15234"
                          stroke="white"
                          stroke-width="1.4"
                          stroke-linecap="round"
                        />
                      </svg>
                    ) : null}
                  </p>
                  <span className="text-[#FFFFFFA6] text-[12px] leading-[18px]">
                    <ReadMore lines={1} onlyTruncate={true}>{item.lastMessageText}</ReadMore>
                  </span>
                </div>
              </div>
              <div className="flex flex-col items-end gap-[4px]">
                <p className="text-[#FFFFFFA6] text-[12px] leading-[18px]">
                  {showPastForChat(item.date)}
                </p>
                <div className="flex flex-row">
                  {item.unreadCount !== 0 ?
                  <span className=" bg-secondary w-6 h-6 flex items-center justify-center rounded-full">{item.unreadCount}</span>
                  :null}
                  {item.starred ? (
                    <svg
                      width="20"
                      height="20"
                      viewBox="0 0 20 20"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      className="ml-2"
                    >
                      <g clipPath="url(#clip0_9145_30386)">
                        <path
                          d="M9.99986 14.7913L4.85653 17.4955L5.83903 11.768L1.67236 7.71214L7.42236 6.8788L9.99403 1.66797L12.5657 6.8788L18.3157 7.71214L14.149 11.768L15.1315 17.4955L9.99986 14.7913Z"
                          fill="#C2D24B"
                          stroke="#C2D24B"
                          strokeWidth="1.2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </g>
                      <defs>
                        <clipPath id="clip0_9145_30386">
                          <rect width="20" height="20" fill="white" />
                        </clipPath>
                      </defs>
                    </svg>
                  ) : null}
                </div>
              </div>
            </li>
          ))}
        </ul>
        :
        <>
          <Empty
          smallSize={true}
          heading={formatMessage({ id: "No Message threads found!" })}
          content={formatMessage({
            id: "There are currently no results to display. Please check back later",
          })}
          />
        </>
      }
    </div>
  );
};

export default UserList;
