import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import "../styles/index.scss";
import { toAbsoluteUrl } from "../../../../_metronic/helpers/AssetHelpers";
import TextInput from "../../widgets/components/Input/TextInput";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import { useIntl } from "react-intl";

import { SelectInput } from "../../widgets/components/Input/SelectInput";
import { CustomButton } from "../../widgets/components/UI/CustomButton";
import { profileData, updateProfileInfo } from "../core/_requests";
import { FileUpload } from "../../widgets/components/FileUpload";
import { designationOptions } from "../core/_constants";
import { useAuth } from "../../auth";
import { ChangePasswordModal } from "../../auth/components/ChangePasswordModal";
import { forgotPassword } from "../../auth/core/_requests";
import { DisplayImage } from "../../widgets/components/General/DisplayImage";
import { toast } from "react-toastify";
import { Spinner } from "../../widgets/components/General/Spinner";
import { ToolTipUI } from "../../widgets/components/UI/ToolTipUI";
import { BasicButton } from "../../widgets/components/UI/BasicButton";
import TextArea from "../../widgets/components/Input/TextArea";
import SearchInput from "./SearchInput";
import { CountryModel } from "../../onboarding";
import { getCountry } from "../../onboarding/core/_requests";
import { getCdnUrl } from "../../../core/_util";
import publicProfileIcon from "../../../../_metronic/assets/images/svg/public-profile-icon.svg";
import { Link } from "react-router-dom";
import { Country } from "../../widgets/components/General/Country";
import FormikReactSelect from "../../widgets/components/Input/FormikReactSelect";
import { get, map } from "lodash";
import useGTM from "../../../hooks/useGTM";
import { GTMEvent } from "../../../hooks/gtm_helpers";
import { UpdatePhoneModal } from "./update-phone-modal";

export function User({
  key,
}: // setImgName,
  // imgName,
  // getApiLoading,
  // countryOptions,
  // phoneCodes
  {
    key: number;
    // setImgName: Dispatch<SetStateAction<string | undefined>>;
    // imgName: string | undefined;
    // getApiLoading: boolean;
    // countryOptions: any;
    // phoneCodes: any;
  }) {
  const { formatMessage } = useIntl();
  const { dataLayerPush } = useGTM()
  const [modelStatus, setModelStatus] = useState<boolean>(false);
  const [contactChangeModal, setContactChangeModal] = useState<boolean>(false);
  const [modalStatusBanner, setModalStatusBanner] = useState<boolean>(false);
  const [imgName, setImgName] = useState<string | undefined>();
  const [bannerUrl, setBannerUrl] = useState<string | undefined>(
    toAbsoluteUrl("/media/avatars/banner-image.png")
  );
  const { personalityId, currentUser, setCurrentUser } = useAuth();
  const [showModal, setShowModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [changePasswordLoading, setChangePasswordLoading] = useState(false);
  const [, setHasErrors] = useState<boolean | undefined>();
  const [stateOptions, setStateOptions] = useState<Array<any>>([]);
  const [countryOptions, setcountryOptions] = useState<any[]>([]);
  const [phoneCodes, setPhoneCodes] = useState<any[]>([]);
  const [userFormikFields, setUserFormikFields] = useState<any>(currentUser);
  const [disableSave, setDisableSave] = useState(false);

  useEffect(() => {
    const fetchCountry = async () => {
      const {
        data: { data: countries },
      } = await getCountry();
      const countriesData = countries.map((country: CountryModel) => {
        return {
          id: country.countryId,
          name: country.country_name,
          value: country.countryId,
        };
      });
      setcountryOptions([...countriesData]);
      const phoneCodes = countries.map((country: CountryModel) => {
        return {
          id: country.countryId,
          name: "+" + country.phone_code,
          value: country.countryId,
        };
      });
      phoneCodes.sort((a: any, b: any) => a.name.localeCompare(b.name));
      setPhoneCodes(phoneCodes);
    };
    fetchCountry();
  }, []);

  const userSchema = Yup.object().shape({
    firstName: Yup.string()
      .min(3, formatMessage({ id: "Minimum 3 characters" }))
      .max(50, formatMessage({ id: "Maximum 50 characters" }))
      .required(formatMessage({ id: "First name is required" })),
    lastName: Yup.string()
      .nullable()
      .max(50, formatMessage({ id: "Maximum 50 characters" })),
    email: Yup.string()
      .email(formatMessage({ id: "Invalid email format" }))
      .max(50, formatMessage({ id: "Maximum 50 characters" }))
      .required(formatMessage({ id: "Email is required" })),
    // contact: Yup.string()
    //   .nullable()
    //   .min(6, formatMessage({ id: "Minimum 6 digits" }))
    //   .max(10, formatMessage({ id: "Maximum 10 digits" })),
    countryId: Yup.string().required(
      formatMessage({ id: "Country is required" })
    ),
  });

  const onSubmit = async (values: any, actions: any) => {
    setDisableSave(true);
    console.log("saving");
    // actions.setSubmitting(true)
    try {
      setLoading(true);
      let {
        data: { success, errors },
      } = await updateProfileInfo({
        communication: values.communication,
        contact: values.contact,
        firstName: values.firstName,
        lastName: values.lastName,
        country: parseInt(values.countryId),
        // phoneCode: values.phoneCode,
        website: values.website,
        photo: values.photo,
        banner: values.banner,
        description: values.description,
      });

      if (success) {
        if (personalityId) {
          const {
            data: { success, data, errors },
          } = await profileData(personalityId);
          if (success) {
            setCurrentUser({
              ...data,
            });
            setImgName(data.profileImage);
            setUserFormikFields({
              ...userFormikFields,
              communication: data.communication,
            });
            // actions.setSubmitting(false)
            // const communicationData = data.communication;
            // userInitialValues.firstName = data.firstName;
            // userInitialValues.lastName = data.lastName;
            // userInitialValues.username = data.username;
            // userInitialValues.email = data.email;
            // userInitialValues.contact = data.contact!;
            // userInitialValues.country = data.countryId!;
            // userInitialValues.designation = data.designation;
            // userInitialValues.photo = data.photo!;
            // userInitialValues.banner = data.banner!;
            // userInitialValues.website = data.website;
            // userInitialValues.communication.email =
            //   communicationData?.email || false;
            // userInitialValues.communication.phone =
            //   communicationData?.phone || false;
          } else if (errors) {
            // actions.setSubmitting(false)
            errors.forEach((error: string) => {
              toast.error(formatMessage({ id: error }),
                {
                  autoClose: false,
                  closeButton: true, // Display close button (cross icon)
                });
            });
          }
        }
        setLoading(false);
        setDisableSave(false);
        toast.success(formatMessage({ id: "Profile updated successfully" }));
        dataLayerPush(GTMEvent.UserSettingsEditSuccessful, { tab: "profile" })
      } else if (errors) {
        setLoading(false);
        setDisableSave(false);
        errors.forEach((error: string) => {
          toast.error(formatMessage({ id: error }),
            {
              autoClose: false,
              closeButton: true, // Display close button (cross icon)
            });
        });
        dataLayerPush(GTMEvent.UserSettingsEditFailed, { tab: "profile" })
      }
    } catch (err) {
      setLoading(false);
      setDisableSave(false);
      // actions.setSubmitting(false)
      console.log(err);
      dataLayerPush(GTMEvent.UserSettingsEditFailed, { tab: "profile" })
    }
  };

  useEffect(() => {
    // setImgName(userInitialValues.photo)
    // if(userInitialValues.banner){
    //   setBannerUrl(userInitialValues.banner)
    // }
    setUserFormikFields({
      ...userFormikFields,
      country: userFormikFields.countryId,
    });
    setImgName(currentUser?.photo);
    setBannerUrl(currentUser?.banner);
  }, [currentUser]);
  // ToDoAnand fix for country and phone code
  const getForgotedPassword = async () => {
    if (currentUser?.email) {
      try {
        setChangePasswordLoading(true);
        const {
          data: { success },
        } = await forgotPassword(currentUser?.email);
        if (success) {
          setShowModal(true);
          setHasErrors(false);
          dataLayerPush(GTMEvent.PasswordChangeRequestSuccessful)
        } else {
          setHasErrors(true);
          dataLayerPush(GTMEvent.PasswordChangeRequestFailed)
        }
      } catch (error) {
        console.log(error);
      } finally {
        setChangePasswordLoading(false);
      }
    }
  };

  const handleOpen = () => {
    setModelStatus(true);
  };
  const handleClose = () => {
    setModelStatus(false);
  };

  const handleBannerClose = () => {
    setModalStatusBanner(false);
  };

  return (
    <>
      {loading ? <Spinner className="mt-[-10px]" /> : null}
      <SearchInput />
      <div className="flex flex-col md:flex-row gap-y-[20px] gap-x-[40px] tab-container p-[0px] md:p-[24px] md:pb-[40px] shadow-default rounded">
        <div className={"flex flex-col max-w-[120px]"}>
          <div className={`relative `}>
            <DisplayImage
              imgName={imgName}
              fit="contain"
              alt="profile"
              className={"w-[120px] h-[120px] !object-cover display-image-position  !rounded !overflow-hidden"}
            />
            <div
              className="rounded-full widgets-customize absolute -top-2 -right-3 p-2 shadow-[0px_2px_4px_0px_#0000001A]"
              onClick={handleOpen}
            >
              <svg
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g clipPath="url(#clip0_344_12864)">
                  <path
                    d="M12.8667 5.94967L10.0333 3.14967L10.9667 2.21634C11.2222 1.96079 11.5362 1.83301 11.9087 1.83301C12.2807 1.83301 12.5944 1.96079 12.85 2.21634L13.7833 3.14967C14.0389 3.40523 14.1722 3.71367 14.1833 4.07501C14.1944 4.4359 14.0722 4.74412 13.8167 4.99967L12.8667 5.94967ZM11.9 6.93301L4.83333 13.9997H2V11.1663L9.06667 4.09967L11.9 6.93301Z"
                    className={"icon"}
                    fillOpacity="0.65"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_344_12864">
                    <rect width="16" height="16" className={"icon"} />
                  </clipPath>
                </defs>
              </svg>
            </div>
          </div>
          <p className=" text-[9px] leading-[18px] secondary-text mt-[12px]">
            {formatMessage({
              id: "Use image of square size (ratio 1:1), in png or jpg format only",
            })}
          </p>
          <Link
            to={`/u/${currentUser?.username}` ?? "/"}
            target={"_blank"}
            className="d-flex mt-[4px] flex-row align-items-center main-text text-[13px] leading-[20px]   p-[8px_6px] border-[#97A33C] border-solid border-[1px] rounded"
          >
            {formatMessage({
              id: "Public Profile",
            })}
            <img src={publicProfileIcon} className="ms-[4px]" alt=""></img>{" "}
          </Link>
        </div>
        <Formik
          initialValues={userFormikFields || {}}
          validationSchema={userSchema}
          onSubmit={onSubmit}
          validateOnMount
          className={"flex-grow"}
        >
          {(formik) => {
            return (
              <Form className={"w-full"}>
                <div className={"grid grid-cols-12 md:gap-x-[24px]"}>
                  <div className={"col-span-12 md:col-span-6"}>
                    <TextInput
                      fieldType={"text"}
                      label={formatMessage({ id: "First Name" })}
                      isStarRequired={true}
                      fieldName={"firstName"}
                      formik={formik}
                      placeholder=""
                      toolTipText={formatMessage({
                        id: "GLOBAL.TOOLTIP.USER.FIRST_NAME",
                      })}
                    />
                  </div>
                  <div className={"col-span-12 md:col-span-6"}>
                    <TextInput
                      fieldType={"text"}
                      label={formatMessage({ id: "Last Name" })}
                      fieldName={"lastName"}
                      formik={formik}
                      placeholder=""
                      toolTipText={formatMessage({
                        id: "GLOBAL.TOOLTIP.USER.LAST_NAME",
                      })}
                    />
                  </div>
                  <div className={"col-span-12 md:col-span-6"}>
                    <TextInput
                      fieldType={"text"}
                      label={formatMessage({ id: "Username" })}
                      isStarRequired={true}
                      fieldName={"username"}
                      formik={formik}
                      placeholder=""
                      toolTipText={formatMessage({
                        id: "GLOBAL.TOOLTIP.USER.USERNAME",
                      })}
                      isDisabled={true}
                    />
                  </div>
                  {/* ToDoAnand create column in user table as designation */}
                  {/* <div className={"col-span-12 md:col-span-6"}>
                    <SelectInput
                      label={formatMessage({ id: "Position" })}
                      fieldName={"designation"}
                      isStarRequired={false}
                      placeholder={formatMessage({
                        id: "Select your Position",
                      })}
                      formik={formik}
                      toolTipText={formatMessage({
                        id: "GLOBAL.TOOLTIP.USER.POSITION",
                      })}
                      options={designationOptions}
                    />
                  </div> */}
                  <div className={"col-span-12 md:col-span-6"}>
                    <TextInput
                      fieldType={"email"}
                      label={formatMessage({ id: "Email Address" })}
                      isStarRequired={true}
                      fieldName={"email"}
                      formik={formik}
                      placeholder=""
                      toolTipText={formatMessage({
                        id: "GLOBAL.TOOLTIP.USER.EMAIL",
                      })}
                      isDisabled={true}
                    />
                  </div>
                  <div className={"col-span-12 md:col-span-6"}>
                    <Country
                      initialValues={userFormikFields}
                      formik={formik}
                      fieldName={"countryId"}
                      label={formatMessage({ id: "Country" })}
                      isStarRequired={true}
                      setCountryId={() => { }}
                      tooltipText={formatMessage({
                        id: "GLOBAL.TOOLTIP.COMPANY_DETAILS.COUNTRY",
                      })}
                      width={12}
                    />
                    {/*<FormikReactSelect*/}
                    {/*    isFieldArray={true}*/}
                    {/*    name={"countryId"}*/}
                    {/*    formik={formik}*/}
                    {/*    placeholder={formatMessage({ id: "Enter Gender" })}*/}
                    {/*    label={formatMessage({ id: "Country" })}*/}
                    {/*    tooltipText={formatMessage({*/}
                    {/*      id: "GLOBAL.TOOLTIP.COMPANY_DETAILS.COUNTRY",*/}
                    {/*    })}*/} {/*<FormikReactSelect*/}
                    {/*    isFieldArray={true}*/}
                    {/*    name={"countryId"}*/}
                    {/*    formik={formik}*/}
                    {/*    placeholder={formatMessage({ id: "Enter Gender" })}*/}
                    {/*    label={formatMessage({ id: "Country" })}*/}
                    {/*    tooltipText={formatMessage({*/}
                    {/*      id: "GLOBAL.TOOLTIP.COMPANY_DETAILS.COUNTRY",*/}
                    {/*    })}*/}
                    {/*    isStarRequired={true}*/}
                    {/*    options={[*/}
                    {/*      { label: "Male", value: "male" },*/}
                    {/*      { label: "Female", value: "female" },*/}
                    {/*    ]}*/}
                    {/*/>
                    {/*    isStarRequired={true}*/}
                    {/*    options={[*/}
                    {/*      { label: "Male", value: "male" },*/}
                    {/*      { label: "Female", value: "female" },*/}
                    {/*    ]}*/}
                    {/*/>*/}
                    {/* <SelectInput
                      label={formatMessage({ id: "User’s Country" })}
                      fieldName={"country"}
                      placeholder={formatMessage({ id: "Select the Country" })}
                      formik={formik}
                      toolTipText={formatMessage({
                        id: "GLOBAL.TOOLTIP.USER.COUNTRY",
                      })}
                      options={countryOptions}
                    /> */}
                  </div>
                  <div className={"col-span-12 md:col-span-6"}>
                    <label
                      className={
                        "mb-2  text-[13px] leading-5 secondary-text flex"
                      }
                    >
                      {formatMessage({ id: "Contact Number" })}
                      <ToolTipUI
                        tooltipText={formatMessage({
                          id: "GLOBAL.TOOLTIP.USER.CONTACT_NUMBER",
                        })}
                      />
                    </label>
                    <div className={"flex gap-[8px]"} onClick={(e) => setContactChangeModal(true)}>
                      <div className={"w-[100px]"}>
                        <TextInput
                          fieldType={"text"}
                          withoutLabel={true}
                          readonly={true}
                          fieldName={"phoneCode"}
                          formik={formik}
                          placeholder=""
                          toolTipText={formatMessage({
                            id: "GLOBAL.TOOLTIP.USER.CONTACT_NUMBER",
                          })}
                        />
                        {/*<SelectInput*/}
                        {/*  fieldName={"countryId"}*/}
                        {/*  placeholder={"-"}*/}
                        {/*  // label={formatMessage({id: 'Contact Number'})}*/}
                        {/*  formik={formik}*/}
                        {/*  toolTipText={formatMessage({*/}
                        {/*    id: "GLOBAL.TOOLTIP.USER.CONTACT_NUMBER",*/}
                        {/*  })}*/}
                        {/*  options={phoneCodes}*/}
                        {/*/>*/}
                      </div>
                      <div className={"flex-grow"}>
                        <TextInput
                          fieldType={"contact"}
                          withoutLabel={true}
                          readonly={true}
                          fieldName={"contact"}
                          formik={formik}
                          placeholder=""
                          toolTipText={formatMessage({
                            id: "GLOBAL.TOOLTIP.USER.CONTACT_NUMBER",
                          })}
                        />
                      </div>
                      <div className="w-[100px]">
                        <button
                          type="button"
                          className={`export-button text-[14px] leading-5 font-medium py-[10px] w-full sm:w-1/2   md:w-[100px] rounded`}
                          onClick={() => setContactChangeModal(true)}
                        >
                          {formatMessage({
                            id: `Update`,
                          })}
                        </button>
                      </div>
                    </div>
                  </div>
                  <div className={"col-span-12 md:col-span-6"}>
                    <TextInput
                      label={formatMessage({ id: "Website URL" })}
                      fieldName={"website"}
                      withoutLabel={true}
                      formik={formik}
                      placeholder={formatMessage({ id: "www.example.com" })}
                      toolTipText={formatMessage({
                        id: "GLOBAL.TOOLTIP.USER.WEBSITE_URL",
                      })}
                      fieldType={"text"}
                    />
                  </div>
                  <div className={"col-span-12 md:col-span-6 "}>
                    <TextArea
                      label={formatMessage({ id: "Description" })}
                      fieldName={"description"}
                      formik={formik}
                      placeholder={formatMessage({ id: "Short Description" })}
                      toolTipText={formatMessage({
                        id: "GLOBAL.TOOLTIP.USER.DESCRIPTION",
                      })}
                      className={"!min-h-[80px]"}
                    />
                  </div>
                  <div
                    className={
                      "col-span-12 field p-[8px] shadow-[0px_2px_4px_0px_#0000001A] rounded relative"
                    }
                  >
                    <div
                      className="rounded-full  widgets-customize absolute -top-2 -right-3 p-2 shadow-[0px_2px_4px_0px_#0000001A]"
                      onClick={(e) => setModalStatusBanner(true)}
                    >
                      <svg
                        width="16"
                        height="16"
                        viewBox="0 0 16 16"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <g clipPath="url(#clip0_344_12864)">
                          <path
                            d="M12.8667 5.94967L10.0333 3.14967L10.9667 2.21634C11.2222 1.96079 11.5362 1.83301 11.9087 1.83301C12.2807 1.83301 12.5944 1.96079 12.85 2.21634L13.7833 3.14967C14.0389 3.40523 14.1722 3.71367 14.1833 4.07501C14.1944 4.4359 14.0722 4.74412 13.8167 4.99967L12.8667 5.94967ZM11.9 6.93301L4.83333 13.9997H2V11.1663L9.06667 4.09967L11.9 6.93301Z"
                            className={"icon"}
                            fillOpacity="0.65"
                          />
                        </g>
                        <defs>
                          <clipPath id="clip0_344_12864">
                            <rect width="16" height="16" className={"icon"} />
                          </clipPath>
                        </defs>
                      </svg>
                    </div>
                    <img
                      alt="User Banner"
                      src={getCdnUrl(bannerUrl || "")}
                      className={"w-full h-[110px]"}
                    />
                  </div>
                  <p className="col-span-12 text-[12px] text-[#FFFFFFA6] mt-[12px]">
                    {formatMessage({
                      id: "* Recommended image size is 1440x320 px i.e. width of 1440 pixels, height of 320 pixels.",
                    })}
                  </p>
                </div>
                {/* ToDoAnand change all fileupload with respect to new flow */}
                <FileUpload
                  fileSize={2097152}
                  maxFileNumber={1}
                  allowType={["image/*", ".jpg", ".jpeg", ".png"]}
                  metaData={{ module: "profileimg", isProtected: true }}
                  modalStatus={modelStatus}
                  handleClose={handleClose}
                  handleSuccess={(id: number, name: string) => {
                    setImgName(name);
                    formik.setFieldValue("photo", name);
                    dataLayerPush(GTMEvent.UserProfilePicUploadSuccessful)
                  }}
                  resourceType="user-avatar"
                />
                <FileUpload
                  fileSize={2097152}
                  maxFileNumber={1}
                  allowType={["image/*", ".jpg", ".jpeg", ".png"]}
                  metaData={{ module: "profileimg", isProtected: true }}
                  modalStatus={modalStatusBanner}
                  handleClose={handleBannerClose}
                  handleSuccess={(id: number, name: string) => {
                    setBannerUrl(name);
                    formik.setFieldValue("banner", name);
                    dataLayerPush(GTMEvent.UserProfileBannerUploadSuccessful)
                  }}
                  resourceType="user-banner"
                />
                <div
                  className={
                    "flex flex-col md:flex-row justify-end mt-5 gap-y-[14px] md:gap-x-[20px]"
                  }
                >
                  <BasicButton
                    buttonText={formatMessage({ id: "Change password" })}
                    onClick={getForgotedPassword}
                    disabled={changePasswordLoading}
                    loading={changePasswordLoading}
                    height="44px"
                    customClass={"main-button-primary-light"}
                    padding="12px 24px"
                  />
                  <button
                    type="submit"
                    className={`export-button text-[14px] leading-5 font-medium py-[12px] w-full sm:w-1/2   md:w-[140px] rounded ${disableSave ? "opacity-75" : ""
                      }`}
                    disabled={disableSave}
                  // onClick={onSubmit}
                  >
                    {formatMessage({
                      id: `${disableSave ? "Please Wait" : "Save Changes"}`,
                    })}
                  </button>
                  {/* <CustomButton
                    isSubmitting={formik.isSubmitting}
                    isValid={formik.isValid && formik.dirty}
                    buttonText={formatMessage({ id: "Save Changes" })}
                    loading={loading}
                    height={44}
                  /> */}
                </div>
              </Form>
            );
          }}
        </Formik>
      </div>
      <ChangePasswordModal
        show={showModal}
        handleClose={() => setShowModal(false)}
        setShowModal={setShowModal}
      />
      <UpdatePhoneModal 
        show={contactChangeModal}
        handleClose={() => setContactChangeModal(false)}
      />
    </>
  );
}
