// @ts-ignore
import React from "react";
import {Autoplay, Pagination} from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
const SubscribeSwiper = ({images}:{images:string[]}) => {
  return (
    <div className={"bg-container rounded-lg"}>
      <Swiper
        slidesPerView={1}
        className="h-96 md:h-[400px] pb-8 personality-swiper mb-6"
        modules={[Pagination , Autoplay]}
        pagination={true}
        spaceBetween={40}
      >
        {images.map((item) => (
          <SwiperSlide className="h-96 md:h-[400px] marketplace-card-hoverless rounded-lg p-2 md:p-4 shadow-none drop-shadow-none">
            <img src={item} className={"w-full h-full rounded-lg"} />
          </SwiperSlide>
        ))
      }
      </Swiper>
    </div>
  );
};

export default SubscribeSwiper;
