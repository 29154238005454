import moment from "moment";
import React from "react";

interface AppointmentEventProps {
  post: {
    text: string;
    socialAccountType: string;
    start:Date;
    end: Date;
  };
}

const svgBook:Record<string,any> = {
  'twitter': <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_7201_44965)">
      <path
        d="M14.362 4.73651C14.3718 4.87763 14.3717 5.01863 14.3717 5.16101C14.3717 9.49851 11.0696 14.501 5.03175 14.501V14.4985C3.24801 14.501 1.50124 13.9901 0 13.0268C0.259375 13.058 0.52 13.0736 0.78125 13.0743C2.25964 13.0754 3.6955 12.5796 4.85813 11.6664C4.17312 11.6534 3.50928 11.4268 2.95943 11.018C2.40957 10.6093 2.00119 10.039 1.79138 9.38676C2.2835 9.48176 2.7905 9.46213 3.2735 9.33013C1.742 9.02076 0.64025 7.67526 0.64025 6.11263V6.07101C1.09679 6.32534 1.60776 6.46626 2.13013 6.48188C0.6875 5.51788 0.243125 3.59901 1.11413 2.09888C1.93824 3.11296 2.96645 3.94235 4.13198 4.53317C5.29751 5.12399 6.57428 5.46302 7.87937 5.52826C7.74929 4.9681 7.76854 4.38361 7.93521 3.83323C8.10188 3.28286 8.41013 2.78588 8.82913 2.39201C10.1513 1.14913 12.2306 1.21288 13.4734 2.53438C14.2085 2.38922 14.9135 2.11969 15.558 1.73738C15.3129 2.49747 14.8001 3.14268 14.115 3.55301C14.7657 3.47628 15.4012 3.30204 16 3.03613C15.5595 3.69583 15.0048 4.27165 14.362 4.73651Z"
        fill="#1D9BF0"
      />
    </g>
    <defs>
      <clipPath id="clip0_7201_44965">
        <rect width="16" height="16" fill="white" />
      </clipPath>
    </defs>
  </svg>,
  'facebook': <svg
    width={16}
    height={16}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_7601_52215)">
      <path
        d="M16 8C16 3.58175 12.4183 0 8 0C3.58175 0 0 3.58169 0 8C0 11.993 2.9255 15.3027 6.75 15.9028V10.3125H4.71875V8H6.75V6.2375C6.75 4.2325 7.94438 3.125 9.77169 3.125C10.647 3.125 11.5625 3.28125 11.5625 3.28125V5.25H10.5538C9.55988 5.25 9.25 5.86669 9.25 6.49937V8H11.4688L11.1141 10.3125H9.25V15.9028C13.0745 15.3027 16 11.9931 16 8Z"
        fill="#1877F2"
      />
      <path
        d="M11.1141 10.3125L11.4688 8H9.25V6.49937C9.25 5.86662 9.55994 5.25 10.5538 5.25H11.5625V3.28125C11.5625 3.28125 10.647 3.125 9.77169 3.125C7.94438 3.125 6.75 4.2325 6.75 6.2375V8H4.71875V10.3125H6.75V15.9028C7.16351 15.9676 7.58144 16.0001 8 16C8.41856 16.0001 8.83649 15.9676 9.25 15.9028V10.3125H11.1141Z"
        fill="white"
      />
    </g>
    <defs>
      <clipPath id="clip0_7601_52215">
        <rect width="16" height="16" fill="white" />
      </clipPath>
    </defs>
  </svg>
}

const AppointmentEvent: React.FC<AppointmentEventProps> = ({
  post,
}) => {
  return (
    <div>
      <div className="flex flex-row justify-between items-center">
        <p>{moment(post.start).format("hh:mm")}</p>
        <div className="">
         {svgBook[post.socialAccountType]}
        </div>
      </div>
      <p className="text-[10px] mt-[8px] leading-[16px] text-[#FFFFFFA6] line-clamp-2">
        {post.text}
      </p>
    </div>
  );
};

export default AppointmentEvent;
