import { useEffect, useState } from "react";
import PublishedPost from "./published";
import UnpublishedPost from "./unpublished";
import { get, isEqual, map, range } from "lodash";
import { useLocation } from "react-router-dom";
import { getAllPosts, getFeedPosts } from "../../core/_request";
import { useAuth } from "../../../auth";
import { getCdnUrl } from "../../../../core/_util";
import userDefault from "../../../../assets/images/profile/user-default.png";
import { CustomButton } from "../../../widgets/components/UI/CustomButton";
import { Spinner } from "react-bootstrap";
import { useIntl } from "react-intl";
import Empty from "../../../../components/empty/Empty";

interface PostContainerProps {
  showAddPost?: boolean;
  personality?: any;
  isPrivateFeed?: boolean;
  removeAddLike?: boolean;
  showProfileLink?: boolean;
}
export default function PostContainer({
  personality,
  showAddPost = false,
  isPrivateFeed = false,
  removeAddLike = false,
  showProfileLink = false,
}: PostContainerProps) {
  const { formatMessage } = useIntl();
  const [posts, setPosts] = useState<any[]>([]);
  const location = useLocation();
  const [loading, setLoading] = useState<boolean>(true);
  const [page, setPage] = useState(1);
  const [isExhausted, setIsExhausted] = useState<boolean>(false);

  async function getFeed() {
    setLoading(true);
    const {
      data: { success, data, errors },
    } = await getFeedPosts(page);
    if (success) {
      if (page > 0 && data.length == 0) {
        setIsExhausted(true);
      }
      setPosts((posts) => [...posts, ...data]);
    }
    setLoading(false);
  }
  async function get() {
    if (isPrivateFeed) {
      getFeed();
      return;
    }
    if (!personality.personalityId) return;
    setLoading(true);
    const {
      data: { success, data, errors },
    } = await getAllPosts(personality.personalityId, page);
    if (success) {
      if (page > 0 && data.length == 0) {
        setIsExhausted(true);
      }
      setPosts((posts) => [...posts, ...data]);
    }
    setLoading(false);
  }
  useEffect(() => {
    page !== 1 && get();
  }, [page]);

  useEffect(() => {
    get();
  }, [personality]);

  function filterPostOnDelete(id: string) {
    setPosts((posts) => posts.filter((post) => post.postId !== id));
    if (!isExhausted && posts.length - 1 === 0) {
      get();
    }
  }

  const personalityImage = personality?.image
    ? getCdnUrl(personality.image)
    : userDefault;
  const isVerified = personality?.isVerified || false;
  return (
    <div className={"md:border-r md:pr-[22px] border-main"}>
      {showAddPost && (
        <div>
          <UnpublishedPost
            setPosts={setPosts}
            personalityImage={personalityImage}
          />
        </div>
      )}
      {!loading && posts.length === 0 ? (
        <Empty
          heading={formatMessage({ id: "No Posts Found!" })}
          content={formatMessage({
            id: "There are no posts to display at the moment.",
          })}
        />
      ) : (
        <ul className={"flex flex-col gap-[20px] mt-[20px]"}>
          {map(posts, (post, index) => (
            <PublishedPost
              postId={post.postId}
              likes={post.likes}
              key={post.postId}
              postContent={post.content}
              postImage={post.image}
              createdAt={post.createdAt}
              personalityPhoto={
                isPrivateFeed ? getCdnUrl(post.personalityImage) : personalityImage
              }
              personalityName={
                isPrivateFeed ? post.personalityName : personality?.name
              }
              isDark={false}
              isVerified={isPrivateFeed ? !!post.isVerified : !!isVerified}
              isLiked={post.isLiked ? true : false}
              removeAddLike={removeAddLike}
              username={post.username}
              showProfileLink={showProfileLink}
              showMenu={showAddPost}
              filterPostOnDelete={filterPostOnDelete}
            />
          ))}
          {loading && (
            <div className="d-flex h-[100px] justify-content-center align-items-center">
              <Spinner animation="border" />
            </div>
          )}
        </ul>
      )}
      <div className="d-flex justify-content-center">
        {!!posts.length && (
          <CustomButton
            isSubmitting={loading}
            isValid={!isExhausted}
            buttonText={
              isExhausted
                ? formatMessage({ id: "That's all for now" })
                : formatMessage({ id: "Show More" })
            }
            loading={loading}
            customClass={"mx-auto w-fit my-4 main-button"}
            onSubmit={() => setPage((page) => page + 1)}
          />
        )}
      </div>
    </div>
  );
}
