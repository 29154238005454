import { ReactNode } from "react";
import {
  DrawerComponent,
  MenuComponent, ScrollComponent,
  ScrollTopComponent,
  StickyComponent, SwapperComponent,
  ToggleComponent
} from "../assets/ts/components";

type WithChildren = {
  children?: ReactNode;
};

const reInitMenu = () => {
  setTimeout(() => {
    MenuComponent.reinitialization();
    DrawerComponent.reinitialization()
    ToggleComponent.reinitialization();
    ScrollTopComponent.reinitialization();
    ScrollComponent.reinitialization();
    SwapperComponent.reinitialization();
  }, 500);
};

export { type WithChildren, reInitMenu };
