import React, { useEffect, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper";
import { filter, get, isEqual, map, range } from "lodash";
// import { personalities } from "./constants";
import clsx from "clsx";
import Basic from "../../ai-personality/components/edit-profile/basic";
import Welcome from "../../ai-personality/components/edit-profile/welcome";
import Avatar from "../../ai-personality/components/edit-profile/avatar";
import Voice from "../../ai-personality/components/edit-profile/voice";
import Identity from "../../ai-personality/components/edit-profile/identity";
import Dialog from "../../ai-personality/components/edit-profile/dialog";
import Personality from "../../ai-personality/components/edit-profile/personality";
import Knowledge from "../../ai-personality/components/edit-profile/knowledge";
import ConversationStyle from "../../ai-personality/components/edit-profile/conversation-style";
import NegativeLearning from "../../ai-personality/components/edit-profile/negative-learning";
import { useAuth } from "../../auth";
import { useIntl } from "react-intl";
import { useSearchParams } from "react-router-dom";

const Behavior: React.FC<any> = ({ setOpenEdit }) => {
  const {
    personalityId,
    currentUser,
    setSelectedPersonality,
    selectedPersonality,
  } = useAuth();
  const personalityData =
    selectedPersonality ||
    currentUser?.personality?.find(
      (el: any) => el.personalityId == personalityId
    );
  setSelectedPersonality(personalityData);
  const { formatMessage } = useIntl();
  const settings = {
    breakpoints: {
      "240": {
        slidesPerView: 1,
      },
      "768": {
        slidesPerView: 3,
      },
      "1024": {
        slidesPerView: 4,
      },
      "1280": {
        preventInteractionOnTransition: true,
        slidesPerView: 4.8,
        watchSlidesProgress: true,
        shortSwipes: false,
      },
    },
  };

  const personalities = [
    {
      name: "basic",
      tabName:"basic-characterization",
      title: `${formatMessage({ id: "Basic Characterization" })}`,
      text: `${formatMessage({
        id: "Core characteristic can be defined here",
      })}`,
    },
    {
      name: "welcome",
      tabName:"welcome-message",
      title: `${formatMessage({ id: "Welcome Message" })}`,
      text: `${formatMessage({ id: "Customize how bot welcomes new users" })}`,
    },

    {
      name: "identity",
      tabName:"identity",
      title: `${formatMessage({ id: "Identity" })}`,
      text: `${formatMessage({ id: "Portrayed or perceived character" })}`,
    },
    {
      name: "dialogue",
      tabName:"dialogue-style",
      title: `${formatMessage({ id: "Dialogue Style" })}`,
      text: `${formatMessage({
        id: "You can define custom dialogue style here",
      })}`,
    },

    {
      name: "personality",
      tabName:"personality",
      title: `${formatMessage({ id: "Personality" })}`,
      text: `${formatMessage({ id: "Character traits can be managed here" })}`,
    },
    // {
    //   name: "voice",
    //   title: `${formatMessage({ id: "Voice & Audio" })}`,
    //   text: `${formatMessage({ id: "Give Voice to your AI Personality" })}`,
    // },
    // {
    //   name: "avatar",
    //   title: `${formatMessage({ id: "Avatar & Visuals" })}`,
    //   text: `${formatMessage({
    //     id: "Assign Avatar & give visual look to your AI Personality",
    //   })}`,
    // },
    {
      name: "knowledge",
      tabName:"back-story-knowledge",
      title: `${formatMessage({ id: "Back Story & Knowledge" })}`,
      text: `${formatMessage({ id: "You can add additional knowledge" })}`,
    },
    {
      name: "conversation",
      tabName:"conversation-style",
      title: `${formatMessage({ id: "Conversation style" })}`,
      text: `${formatMessage({ id: "Control how the user replies in chats" })}`,
    },
    {
      name: "learnings",
      tabName:"negative-learnings",
      title: `${formatMessage({ id: "Negative Learnings" })}`,
      text: `${formatMessage({
        id: "Restrict topics which you don’t want to talk about",
      })}`,
    },
  ];

  function getIndex(name:string){
    let index =  personalities.findIndex((item)=>item.tabName==name)
    return index==-1 ? 0 : index
  }


  const [searchParams, setSearchParams] = useSearchParams()
  const [active, setActive] = useState<number>(getIndex(searchParams.get('subTab')||'basic-characterization'));
  useEffect(()=>{
    searchParams.set('subTab',personalities[active].tabName)
    setSearchParams(searchParams,{replace: true})
  },[active])

  return (
    <div className={"edit-profile-section p-[0px_10px_16px] md:p-[0px_20px_16px_20px] rounded-[4px] mt-4"}>
      <Swiper
        modules={[Navigation]}
        initialSlide={active}
        className="w-full !py-[24px] personality-swiper"
        navigation={true}
        // cssMode={true}
        slidesPerView={"auto"}
        pagination={{
          clickable: true,
        }}
      >
        {map(personalities, (value, index) => (
          <SwiperSlide
            className={"mx-3 box-border max-w-[192px]"}
            key={get(value, "key")}
            onClick={() => setActive(index)}
          >
            <div
              className={clsx(
                " p-[20px_12px_12px_12px] border rounded-md min-h-[92px] max-w-[300px] md:w-auto cursor-pointer",
                isEqual(active, index)
                  ? "!border-[#C2D24B] bg-[#C2D24B1A] "
                  : "border-main edit-profile-section"
              )}
            >
              <h4
                className={
                  "secondary-text font-medium text-[13px] leading-[18px] mb-[6px]"
                }
              >
                {formatMessage({ id: get(value, "title") })}
              </h4>
              <p
                className={
                  "primary-text font-normal text-[12px] leading-[18px]"
                }
              >
                {formatMessage({ id: get(value, "text") })}
              </p>
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
      <div className={"px-0"}>
        {filter(
          [
            <Basic
              setOpenEdit={setOpenEdit}
              personalityData={personalityData}
            />,
            <Welcome
              setOpenEdit={setOpenEdit}
              personalityData={personalityData}
            />,
            <Identity
              setOpenEdit={setOpenEdit}
              personalityData={personalityData}
            />,
            <Dialog
              setOpenEdit={setOpenEdit}
              personalityData={personalityData}
            />,
            <Personality
              setOpenEdit={setOpenEdit}
              personalityData={personalityData}
            />,
            // <Voice
            //   setOpenEdit={setOpenEdit}
            //   personalityData={personalityData}
            // />,
            // <Avatar
            //   setOpenEdit={setOpenEdit}
            //   personalityData={personalityData}
            // />,
            <Knowledge
              setOpenEdit={setOpenEdit}
              personalityData={personalityData}
            />,
            <ConversationStyle
              setOpenEdit={setOpenEdit}
              personalityData={personalityData}
            />,
            <NegativeLearning
              setOpenEdit={setOpenEdit}
              personalityData={personalityData}
            />,
          ],
          (item, index) => {
            return index === active;
          }
        )}
      </div>
    </div>
  );
};

export default Behavior;
