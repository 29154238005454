import React from "react";
import CreateAiPersonality from "../../components/ai-profile/create-ai-personality";
import { useIntl } from "react-intl";

const Profile = ({
  personality,
  isAd,
  ...props
}: {
  personality: any;
  isAd: boolean;
}) => {
  const { formatMessage } = useIntl();

  const labelClass = "text-[13px] leading-[20px] secondary-text mb-1";
  const propertyClass = "text-[14px] leading-5 primary-text font-semibold ";
  return (
    <div className={"h-[80vh] overflow-auto custom-scroll"}>
      {isAd && <CreateAiPersonality />}
      {/* <CreateAiPersonality /> */}
      <div className={"grid grid-cols-12 gap-y-3.5 p-[14px] md:p-[0px] md:gap-y-6 mt-[20px] "}>
        <div className={"col-span-12 md:col-span-3"}>
          <label className={labelClass}>
            {" "}
            {formatMessage({
              id: "Name",
            })}
          </label>
          <h6 className={propertyClass}>{personality?.name}</h6>
        </div>
        <div className={"col-span-12 md:col-span-3"}>
          <label className={labelClass}>
            {formatMessage({
              id: "Alternative names",
            })}
          </label>
          <h6 className={propertyClass}>
            {personality?.alternative_names
              ? personality.alternative_names.join(", ")
              : "-"}
          </h6>
        </div>{" "}
        <div className={"col-span-12 md:col-span-3"}>
          <label className={labelClass}>
            {" "}
            {formatMessage({
              id: "Gender",
            })}
          </label>
          <h6 className={propertyClass}>{personality?.gender ?? "-"}</h6>
        </div>
        <div className={"col-span-12 md:col-span-3"}>
          <label className={labelClass}>
            {" "}
            {formatMessage({
              id: "Category",
            })}
          </label>
          <h6 className={propertyClass}>
            {personality?.category ?? "-"}
          </h6>
        </div>
        <div className={"col-span-12 md:col-span-3"}>
          <label className={labelClass}>
            {" "}
            {formatMessage({
              id: "Industry",
            })}
          </label>
          <h6 className={propertyClass}>{personality?.industry ?? "-"}</h6>
        </div>
        <div className={"col-span-12 md:col-span-3"}>
          <label className={labelClass}>
            {" "}
            {formatMessage({
              id: "Country",
            })}
          </label>
          <h6 className={propertyClass}>
            {personality?.country?.country_name ?? "-"}
          </h6>
        </div>
        <div className={"col-span-12 md:col-span-3"}>
          <label className={labelClass}>
            {" "}
            {formatMessage({
              id: "Stage of Life",
            })}
          </label>
          <h6 className={propertyClass}>{personality?.stage_in_life ?? "-"}</h6>
        </div>
        <div className={"col-span-12 md:col-span-3"}>
          <label className={labelClass}>
            {" "}
            {formatMessage({
              id: "Has Voice",
            })}
          </label>
          <h6 className={propertyClass}>
            {personality?.hasVoice ? "Yes" : "No"}
          </h6>
        </div>
        <div className={"col-span-12 md:col-span-3"}>
          <label className={labelClass}>
            {" "}
            {formatMessage({
              id: "Is Licensed",
            })}
          </label>
          <h6 className={propertyClass}>
            {personality?.isLicensed ? "Yes" : "No"}
          </h6>
        </div>
      </div>
    </div>
  );
};

export default Profile;
