import React from "react";
import clsx from "clsx";
import { isEqual } from "lodash";
import userDefault from "../../../../assets/images/profile/user-default.png";
import { getCdnUrl } from "../../../../core/_util";
import { getStatusStyle, getStatusText } from "../../core/_util";
import { useTimeZone } from "../../../../hooks/useTimeZone";
import { useDateFormat } from "../../../../hooks/useDateFormat";
interface LinkItemProps{
  training:{[key:string]:any};
  activeTraining:{[key:string]:any};
  setActiveTraining:(val:any)=>void;
  setCreateFormOpen:(val:boolean)=>void;
  setDeleteModalOpen:React.Dispatch<React.SetStateAction<boolean>>;
  setSelectedTraining:(val:any)=>void;
  isOwned:boolean;
}

const Index: React.FC<LinkItemProps> = ({
  training,
  activeTraining,
  setActiveTraining,
  setCreateFormOpen,
  setDeleteModalOpen,
  setSelectedTraining,
  isOwned
}) => {
  const {getTimeZoneValue} = useTimeZone()
  const {getDateValue} = useDateFormat()

  return (
    <div
      className={clsx(
        "border-b !border-[#2E2F45] py-3 px-4 cursor-pointer text-[#FFFFFFA6] w-full",
        isEqual(activeTraining?.trainingId, training.trainingId) && "bg-[#21233A]"
      )}
      onClick={() => {
        setActiveTraining(training);
        setCreateFormOpen(false);
      }}
    >
      <div className={"flex items-center justify-between"}>
        <div className={"flex items-start gap-x-3 mb-2"}>
          <div>
            <svg
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g clipPath="url(#clip0_497_13861)">
                <path
                  d="M7.49979 12.5L12.4998 7.49999M9.16646 4.99999L9.55229 4.55332C10.3338 3.77193 11.3937 3.33299 12.4988 3.33307C13.604 3.33315 14.6638 3.77224 15.4452 4.55374C16.2266 5.33524 16.6655 6.39515 16.6655 7.50029C16.6654 8.60542 16.2263 9.66526 15.4448 10.4467L14.9998 10.8333M10.8331 15L10.5023 15.445C9.71167 16.2268 8.64462 16.6653 7.53271 16.6653C6.4208 16.6653 5.35375 16.2268 4.56313 15.445C4.17342 15.0597 3.86404 14.6008 3.65289 14.0951C3.44173 13.5894 3.33301 13.0468 3.33301 12.4987C3.33301 11.9507 3.44173 11.4081 3.65289 10.9024C3.86404 10.3966 4.17342 9.93782 4.56313 9.55249L4.99979 9.16666"
                  stroke="white"
                  strokeWidth="1.4"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </g>
              <defs>
                <clipPath id="clip0_497_13861">
                  <rect width="20" height="20" fill="white" />
                </clipPath>
              </defs>
            </svg>
          </div>
          <h5
            className={clsx(
              "text-[14px] leading-5 ",
              isEqual(activeTraining?.trainingId, training.trainingId) ? "text-white" : "text-[#FFFFFFA6]"
            )}
          >
            {training.title}
          </h5>
        </div>

       <div className={"flex items-center gap-x-1"}>
          {isEqual(training.status, "draft") && isOwned ? (
            <button>
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g clipPath="url(#clip0_497_13276)">
                  <path
                    d="M13.5 6.5L17.5 10.5M16 18H20M8 20L18.5 9.5C18.7626 9.23735 18.971 8.92555 19.1131 8.58239C19.2553 8.23923 19.3284 7.87143 19.3284 7.5C19.3284 7.12856 19.2553 6.76077 19.1131 6.4176C18.971 6.07444 18.7626 5.76264 18.5 5.5C18.2374 5.23735 17.9256 5.02901 17.5824 4.88687C17.2392 4.74473 16.8714 4.67157 16.5 4.67157C16.1286 4.67157 15.7608 4.74473 15.4176 4.88687C15.0744 5.02901 14.7626 5.23735 14.5 5.5L4 16V20H8Z"
                    stroke="#8898A6"
                    strokeWidth="1.6"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_497_13276">
                    <rect width="24" height="24" fill="white" />
                  </clipPath>
                </defs>
              </svg>
            </button>
          ) : (
            null
          )}
          {isOwned && ['draft','failed','success'].includes(training.status) ? (
            <button onClick={(e)=>{
              e.stopPropagation();
              setSelectedTraining(training);
              setDeleteModalOpen(true)
            }}>
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g clipPath="url(#clip0_497_13243)">
                  <path
                    d="M4 7H20M10 11V17M14 11V17M5 7L6 19C6 19.5304 6.21071 20.0391 6.58579 20.4142C6.96086 20.7893 7.46957 21 8 21H16C16.5304 21 17.0391 20.7893 17.4142 20.4142C17.7893 20.0391 18 19.5304 18 19L19 7M9 7V4C9 3.73478 9.10536 3.48043 9.29289 3.29289C9.48043 3.10536 9.73478 3 10 3H14C14.2652 3 14.5196 3.10536 14.7071 3.29289C14.8946 3.48043 15 3.73478 15 4V7"
                    stroke="#8898A6"
                    strokeWidth="1.6"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_497_13243">
                    <rect width="24" height="24" fill="white" />
                  </clipPath>
                </defs>
              </svg>
            </button>
          ) : (
            <button>
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g clipPath="url(#clip0_497_13444)">
                  <path
                    d="M5 13C5 12.4696 5.21071 11.9609 5.58579 11.5858C5.96086 11.2107 6.46957 11 7 11H17C17.5304 11 18.0391 11.2107 18.4142 11.5858C18.7893 11.9609 19 12.4696 19 13V19C19 19.5304 18.7893 20.0391 18.4142 20.4142C18.0391 20.7893 17.5304 21 17 21H7C6.46957 21 5.96086 20.7893 5.58579 20.4142C5.21071 20.0391 5 19.5304 5 19V13Z"
                    stroke="#8898A6"
                    strokeWidth="1.6"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M8 11V7C8 5.93913 8.42143 4.92172 9.17157 4.17157C9.92172 3.42143 10.9391 3 12 3C13.0609 3 14.0783 3.42143 14.8284 4.17157C15.5786 4.92172 16 5.93913 16 7V11M11 16C11 16.2652 11.1054 16.5196 11.2929 16.7071C11.4804 16.8946 11.7348 17 12 17C12.2652 17 12.5196 16.8946 12.7071 16.7071C12.8946 16.5196 13 16.2652 13 16C13 15.7348 12.8946 15.4804 12.7071 15.2929C12.5196 15.1054 12.2652 15 12 15C11.7348 15 11.4804 15.1054 11.2929 15.2929C11.1054 15.4804 11 15.7348 11 16Z"
                    stroke="#8898A6"
                    strokeWidth="1.6"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_497_13444">
                    <rect width="24" height="24" fill="white" />
                  </clipPath>
                </defs>
              </svg>
            </button>
          )}
        </div>
      </div>
      <div className={"flex justify-between items-center ml-9 mt-2 mb-3"}>
        <p
          className={clsx(
            "text-[14px] leading-5 text-break ",
            isEqual(activeTraining?.trainingId, training.trainingId) ? "text-white" : "text-[#FFFFFFA6]"
          )}
        >
          {training.publicLink}
        </p>
      </div>
      <div className={"flex justify-between items-center ml-8"}>
        <p
          className={
            "text-[12px] leading-[18px] text-[#FFFFFFA6] flex items-center gap-x-2"
          }
        >
           {getDateValue(training.createdAt)} {getTimeZoneValue(training.createdAt)}
          <span
            className={clsx(
              getStatusStyle(training.status),
              "px-2 py-[3px] rounded"
            )}
          >
            {getStatusText(training.status)}
          </span>
        </p>
        <img
          src={training?.user?.photo ? getCdnUrl(training.user.photo) : userDefault}
          className={"w-6 h-6 rounded-full"}
          alt={"Avatar"}
        />
      </div>
    </div>
  );
};

export default Index;
