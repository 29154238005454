import React, { useEffect, useState } from "react";
import SubscribeSwiper from "./subscribe-swiper";
import clsx from "clsx";
import {isEqual} from "lodash";
import { useAuth } from "../../../auth";
import { processRazorpayAuthPayment, subscribeToFanPlan, subscribeToFanPlanViaRazorpay } from "./core/_request";
import { toast } from "react-toastify";
import { Modal, Spinner } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import { getBenefitIcon } from "../../../../core/icon";
import { getCdnUrl } from "../../../../core/_util";
import RazorpayModal from "../../../chat-credits/components/modal/RazorpayModal";
import { GTMEvent } from "../../../../hooks/gtm_helpers";
import useGTM from "../../../../hooks/useGTM";
import { getBillingAccess } from "../../../profile/core/_requests";
import { useIntl } from "react-intl";

type Benefit = {
  icon: string;
  text: string;
  heading: string;
}

type PriceItem = {
  id: string;
  amount: number;
  frequency: string;
}

const frequencyMap:Record<string,string> = {
  monthly: 'month',
  yearly: 'year'
}

const frequencyMap2:Record<string,string> = {
  monthly: 'MONTHLY',
  yearly: 'ANNUALlY'
}
const Subscribe = ({ isModal=false}:{isModal?:boolean}) => {
  const { formatMessage } = useIntl();
  const [showModal, setShowModal] = useState(false);
  const [portalUrl, setPortalUrl] = useState('')
  const [subscriptionId, setSubscriptionId] = useState('');
  const {personalityInView, ipStackCountry, currentUser, setPersonalityInView} = useAuth()
  const hasFanPlan = !!personalityInView?.fan_subscription_plan
  const {priceJson={}, planJson={}, razorpayMonthlyPlan, razorpayYearlyPlan} = personalityInView?.fan_subscription_plan ||  {}
  const currency = ipStackCountry?.currency || "USD"
  const currencySymbol = currency=="INR" ? ipStackCountry?.currency_symbol : "$"
  let perMonth = (priceJson[currency] || priceJson["USD"])?.find((item:PriceItem)=> item.frequency=="monthly")?.amount || 0
  const [subscribing, setSubscribing] = useState(false)
  const [razorpaySubscriptionId, setRazorpaySubscriptionId] = useState('')
  const [razorpayModal, setRazorpayModal] = useState<boolean>(false)
  const [modalLoading, setModalLoading] = useState<boolean>(false)
  const navigate = useNavigate();
  const {dataLayerPush} = useGTM()
  useEffect(()=>{
    if(priceJson && currency){
   setSubscriptionId((priceJson[currency] || priceJson["USD"])?.find((item:PriceItem)=> item.frequency=="yearly")?.id)
    }
  },[priceJson])

 const subscribed = personalityInView?.fanPlanSubscribed
 const isRazorpay = ipStackCountry?.country_code==="IN" && (process.env.REACT_APP_RAZORPAY_SUBSCRIPTION_ENABLED=="1" ? true : false)

 if(isRazorpay){
  priceJson["INR"].forEach((item:PriceItem) => {
    item.id = item.frequency=='monthly' ? razorpayMonthlyPlan : (item.frequency=='yearly' ? razorpayYearlyPlan : item.id)
    
  });
 }

 const accessChargebeePortal = async () => {
  setModalLoading(true)
  try {
      const {
        data: { data: value, success, errors },
      } = await getBillingAccess(personalityInView.personalityId, {type:"user"});
      if (success) {
        setPortalUrl(value);
        dataLayerPush(GTMEvent.PaymentPortalOpenedSuccessful)
      } else {
        errors.forEach((error: string) => {
          toast.error(error,
          {
            autoClose: false,
            closeButton: true, // Display close button (cross icon)
          });
        });
        dataLayerPush(GTMEvent.PaymentPortalOpenedSuccessful)
      }
  } catch (err) {
    console.log(err);
    dataLayerPush(GTMEvent.PaymentPortalOpenedSuccessful)
  }
  setModalLoading(false)
};

 async function subscribeViaChargebee(){
  setSubscribing(true)
  const {data:{success, errors}} =  await subscribeToFanPlan(subscriptionId, personalityInView.personalityId)
   if(success){
     toast.success("Subscribed Successfully")
     setPersonalityInView((prev:any)=>{ return {...prev, fanPlanSubscribed:true}}) 
   }else{
     toast.error(errors[0] || "Failed to subscribe",{
      autoClose: false,
      closeButton: true, // Display close button (cross icon)
    })
     if(errors[0].includes("add a payment method")){
      accessChargebeePortal()
      setShowModal(true);
     }
   }
   setSubscribing(false)
 }


 async function processAuthPayment(data:any){
  setSubscribing(true)
  const {data:{success, errors}} =  await processRazorpayAuthPayment(data)
   if(success){
    toast.success('Subscribed successfully')
     setPersonalityInView((prev:any)=>{ return {...prev, fanPlanSubscribed:true}}) 
   }else{
     toast.error(errors[0] || "Failed to subscribe")
   }
   setSubscribing(false)
 }
 async function subscribeViaRazorpay(){
  setSubscribing(true)
  const {data:{success,data, errors}} =  await subscribeToFanPlanViaRazorpay(subscriptionId, personalityInView.personalityId)
   if(success){
    setRazorpaySubscriptionId(data)
    setRazorpayModal(true)
   }else{
     toast.error(errors[0] || "Failed to subscribe")
   }
   setSubscribing(false)
 }

  async function subscribePlan(){
    if(!currentUser){
      navigate('/auth/login')
      return
    }
    if(!subscriptionId ??  !personalityInView)
    return;

    if(isRazorpay){
      subscribeViaRazorpay()
    }else{
      subscribeViaChargebee()
    }
  }

  if(!hasFanPlan){
    return null;
  }
  return (
    <>
      <div className={isModal ? "" :"mt-6"}>
        <div
          className={clsx(
            "h-full bg-center bg-no-repeat overflow-y-auto  relative rounded-[12px] min-h-[700px]",
            !subscribed && "p-2 py-6 lg:p-8 ",
            !isModal && "primary-bg"
          )}
        >
          <div
            className={clsx(
              "grid grid-cols-12 md:px-5",
              !subscribed && "divide-y lg:divide-y-0 lg:divide-x divide-color"
            )}
          >
            <div className="col-span-12 lg:col-span-6 xl:col-span-5 pb-5 lg:!pr-5 xl:pr-[60px]">
              {subscribed ? (
                <div className={"h-[450px] pb-6 relative"}>
                  <img src={getCdnUrl(planJson?.carouselImages?.[0])} className={"w-full h-full"} />
                  <div
                    className={
                      "absolute bottom-0 w-full subscribed-image-bottom h-[85px]"
                    }
                  ></div>
                </div>
              ) : (
                <>
                  <SubscribeSwiper images={planJson?.carouselImages?.map((item:string)=>getCdnUrl(item)) || []} />
                  <div className={"text-center"}>
                    <h3
                      className={
                        "text-[24px] leading-8 font-semibold  primary-text mb-2"
                      }
                    >
                      {planJson?.mainHeading}
                    </h3>
                    <p className={"text-[14px] secondary-text leading-5"}>
                    {planJson?.mainSubHeading}
                    {planJson?.termsLink ? 
                    <><a href={planJson?.termsLink} target={'_blank'} rel={'noreferrer'} className={'text-primary underline'}> Visit here </a>to see all the benefits and terms of Elite Club membership.</>
                    :
                    <></>
                    }
                    </p>
                  </div>
                </>
              )}
            </div>
            <div className="col-span-12  lg:col-span-6 xl:col-span-7 p-2 lg:!pl-5 xl:!pl-[60px] xl:p-5">
              {subscribed && (
                <div className={"mb-3 max-w-md"}>
                  <h3
                    className={
                      "text-[24px] leading-8 font-semibold  primary-text mb-2"
                    }
                  >
                    {formatMessage({id:"Great! You have an active subscription"})}
                  </h3>
                  <p className={"text-[14px] secondary-text leading-5"}>
                  {formatMessage({id:"Your benefits include"})}
                  </p>
                </div>
              )}
              <ul className={"flex flex-col gap-y-3 mb-8"}>
                {planJson?.benefits?.map(({icon,heading,text}:Benefit)=><li className={"flex  items-center gap-x-5"}>
                  <div
                    className={
                      "w-9 h-9 bg-secondary rounded flex items-center justify-center"
                    }
                  >
                    {getBenefitIcon(icon)}
                  </div>
                  <p className={"secondary-text text-[14px]"}>
                    <span className={"font-semibold primary-text"}>
                      {heading}{":"}
                    </span>{" "}
                    {text}
                  </p>
                </li>)}
                {/* <li className={"flex  items-center gap-x-5"}>
                  <div
                    className={
                      "w-9 h-9 bg-secondary rounded flex items-center justify-center"
                    }
                  >
                    <svg
                      width="30"
                      height="30"
                      viewBox="0 0 30 30"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g clip-path="url(#clip0_10714_33652)">
                        <path
                          d="M3.75 21.25C7.91625 17.0838 10 13.75 10 11.25C10 7.5 8.75 7.5 7.5 7.5C6.25 7.5 4.96 8.85625 5 11.25C5.0425 13.81 7.0725 17.3463 8.125 18.75C10 21.25 11.25 21.875 12.5 20L15 16.25C15.4163 19.5838 16.6662 21.25 18.75 21.25C19.4125 21.25 22.0487 18.75 22.5 18.75C23.1463 18.75 24.3962 19.5838 26.25 21.25"
                          stroke="white"
                          stroke-opacity="0.5"
                          stroke-width="1.6"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </g>
                      <defs>
                        <clipPath id="clip0_10714_33652">
                          <rect width="30" height="30" fill="white" />
                        </clipPath>
                      </defs>
                    </svg>
                  </div>
                  <p className={"secondary-text text-[14px]"}>
                    <span className={"font-semibold primary-text"}>
                      {" "}
                      Signed Memorabilia:
                    </span>{" "}
                    100 lucky subscribers will receive a T-shirt signed by Sunny
                    every month
                  </p>
                </li>
                <li className={"flex  items-center gap-x-5"}>
                  <div
                    className={
                      "w-9 h-9 bg-secondary rounded flex items-center justify-center"
                    }
                  >
                    <svg
                      width="30"
                      height="30"
                      viewBox="0 0 30 30"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g clip-path="url(#clip0_10714_33655)">
                        <path
                          d="M18.75 8.75C19.413 8.75 20.0489 9.01339 20.5178 9.48223C20.9866 9.95107 21.25 10.587 21.25 11.25M18.75 3.75C20.7391 3.75 22.6468 4.54018 24.0533 5.9467C25.4598 7.35322 26.25 9.26088 26.25 11.25M6.25 5H11.25L13.75 11.25L10.625 13.125C11.9637 15.8394 14.1606 18.0363 16.875 19.375L18.75 16.25L25 18.75V23.75C25 24.413 24.7366 25.0489 24.2678 25.5178C23.7989 25.9866 23.163 26.25 22.5 26.25C17.6241 25.9537 13.0252 23.8831 9.57104 20.429C6.11688 16.9748 4.04631 12.3759 3.75 7.5C3.75 6.83696 4.01339 6.20107 4.48223 5.73223C4.95107 5.26339 5.58696 5 6.25 5Z"
                          stroke="white"
                          stroke-opacity="0.5"
                          stroke-width="1.6"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </g>
                      <defs>
                        <clipPath id="clip0_10714_33655">
                          <rect width="30" height="30" fill="white" />
                        </clipPath>
                      </defs>
                    </svg>
                  </div>
                  <p className={"secondary-text text-[14px]"}>
                    <span className={"font-semibold primary-text"}>
                      Monthly Calls:
                    </span>{" "}
                    Enjoy a 5-minute In-bound call from Sunny's AI every month,
                    free of charge
                  </p>
                </li>
                <li className={"flex  items-center gap-x-5"}>
                  <div
                    className={
                      "w-9 h-9 bg-secondary rounded flex items-center justify-center"
                    }
                  >
                    <svg
                      width="30"
                      height="30"
                      viewBox="0 0 30 30"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g clip-path="url(#clip0_10714_33658)">
                        <path
                          d="M15 10V26.25M3.75 11.25C3.75 10.9185 3.8817 10.6005 4.11612 10.3661C4.35054 10.1317 4.66848 10 5 10H25C25.3315 10 25.6495 10.1317 25.8839 10.3661C26.1183 10.6005 26.25 10.9185 26.25 11.25V13.75C26.25 14.0815 26.1183 14.3995 25.8839 14.6339C25.6495 14.8683 25.3315 15 25 15H5C4.66848 15 4.35054 14.8683 4.11612 14.6339C3.8817 14.3995 3.75 14.0815 3.75 13.75V11.25Z"
                          stroke="white"
                          stroke-opacity="0.5"
                          stroke-width="1.6"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M23.75 15.0005V23.7505C23.75 24.4136 23.4866 25.0495 23.0178 25.5183C22.5489 25.9872 21.913 26.2505 21.25 26.2505H8.75C8.08696 26.2505 7.45107 25.9872 6.98223 25.5183C6.51339 25.0495 6.25 24.4136 6.25 23.7505V15.0005M9.375 10.0005C8.5462 10.0005 7.75134 9.67131 7.16529 9.08526C6.57924 8.4992 6.25 7.70435 6.25 6.87555C6.25 6.04675 6.57924 5.25189 7.16529 4.66584C7.75134 4.07979 8.5462 3.75055 9.375 3.75055C10.5809 3.72954 11.7625 4.31462 12.7659 5.4295C13.7693 6.54437 14.5478 8.1373 15 10.0005C15.4522 8.1373 16.2307 6.54437 17.2341 5.4295C18.2375 4.31462 19.4191 3.72954 20.625 3.75055C21.4538 3.75055 22.2487 4.07979 22.8347 4.66584C23.4208 5.25189 23.75 6.04675 23.75 6.87555C23.75 7.70435 23.4208 8.4992 22.8347 9.08526C22.2487 9.67131 21.4538 10.0005 20.625 10.0005"
                          stroke="white"
                          stroke-opacity="0.5"
                          stroke-width="1.6"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </g>
                      <defs>
                        <clipPath id="clip0_10714_33658">
                          <rect width="30" height="30" fill="white" />
                        </clipPath>
                      </defs>
                    </svg>
                  </div>
                  <p className={"secondary-text text-[14px]"}>
                    <span className={"font-semibold primary-text"}>
                      Early Access:
                    </span>{" "}
                    Subscribers get early access to Sunny's new private content,
                    and life updates
                  </p>
                </li>
                <li className={"flex  items-center gap-x-5"}>
                  <div
                    className={
                      "w-9 h-9 bg-secondary rounded flex items-center justify-center"
                    }
                  >
                    <svg
                      width="30"
                      height="30"
                      viewBox="0 0 30 30"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g clipPath="url(#clip0_10714_33663)">
                        <path
                          d="M11.25 18.75L18.75 11.25"
                          stroke="white"
                          strokeOpacity="0.5"
                          strokeWidth="1.6"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M11.875 12.5C12.2202 12.5 12.5 12.2202 12.5 11.875C12.5 11.5298 12.2202 11.25 11.875 11.25C11.5298 11.25 11.25 11.5298 11.25 11.875C11.25 12.2202 11.5298 12.5 11.875 12.5Z"
                          fill="white"
                          fill-opacity="0.5"
                          stroke="white"
                          stroke-opacity="0.5"
                          stroke-width="1.6"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M18.125 18.75C18.4702 18.75 18.75 18.4702 18.75 18.125C18.75 17.7798 18.4702 17.5 18.125 17.5C17.7798 17.5 17.5 17.7798 17.5 18.125C17.5 18.4702 17.7798 18.75 18.125 18.75Z"
                          fill="white"
                          fill-opacity="0.5"
                          stroke="white"
                          stroke-opacity="0.5"
                          stroke-width="1.6"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M6.25009 9.00155C6.25009 8.27221 6.53982 7.57273 7.05554 7.05701C7.57127 6.54128 8.27074 6.25155 9.00009 6.25155H10.2501C10.9762 6.25114 11.6727 5.96356 12.1876 5.45155L13.0626 4.57655C13.3181 4.31955 13.622 4.11561 13.9566 3.97644C14.2913 3.83727 14.6502 3.76563 15.0126 3.76562C15.375 3.76563 15.7339 3.83727 16.0685 3.97644C16.4032 4.11561 16.707 4.31955 16.9626 4.57655L17.8376 5.45155C18.3525 5.96356 19.049 6.25114 19.7751 6.25155H21.0251C21.7544 6.25155 22.4539 6.54128 22.9696 7.05701C23.4854 7.57273 23.7751 8.27221 23.7751 9.00155V10.2516C23.7755 10.9777 24.0631 11.6742 24.5751 12.1891L25.4501 13.0641C25.7071 13.3196 25.911 13.6235 26.0502 13.9581C26.1894 14.2928 26.261 14.6516 26.261 15.0141C26.261 15.3765 26.1894 15.7353 26.0502 16.07C25.911 16.4047 25.7071 16.7085 25.4501 16.9641L24.5751 17.8391C24.0631 18.3539 23.7755 19.0504 23.7751 19.7766V21.0266C23.7751 21.7559 23.4854 22.4554 22.9696 22.9711C22.4539 23.4868 21.7544 23.7766 21.0251 23.7766H19.7751C19.049 23.777 18.3525 24.0646 17.8376 24.5766L16.9626 25.4516C16.707 25.7086 16.4032 25.9125 16.0685 26.0517C15.7339 26.1908 15.375 26.2625 15.0126 26.2625C14.6502 26.2625 14.2913 26.1908 13.9566 26.0517C13.622 25.9125 13.3181 25.7086 13.0626 25.4516L12.1876 24.5766C11.6727 24.0646 10.9762 23.777 10.2501 23.7766H9.00009C8.27074 23.7766 7.57127 23.4868 7.05554 22.9711C6.53982 22.4554 6.25009 21.7559 6.25009 21.0266V19.7766C6.24967 19.0504 5.96209 18.3539 5.45009 17.8391L4.57509 16.9641C4.31809 16.7085 4.11414 16.4047 3.97497 16.07C3.8358 15.7353 3.76416 15.3765 3.76416 15.0141C3.76416 14.6516 3.8358 14.2928 3.97497 13.9581C4.11414 13.6235 4.31809 13.3196 4.57509 13.0641L5.45009 12.1891C5.96209 11.6742 6.24967 10.9777 6.25009 10.2516V9.00155Z"
                          stroke="white"
                          stroke-opacity="0.5"
                          stroke-width="1.6"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </g>
                      <defs>
                        <clipPath id="clip0_10714_33663">
                          <rect width="30" height="30" fill="white" />
                        </clipPath>
                      </defs>
                    </svg>
                  </div>
                  <p className={"secondary-text text-[14px]"}>
                    <span className={"font-semibold primary-text"}>
                      Personal Interaction:
                    </span>{" "}
                    Chat with Sunny's AI personality at special discounted rates
                  </p>
                </li> */}
              </ul>
              {subscribed ? (
                <div className={"text-end"}>
                  <Link to="/chat-credits?tab=personality-subscriptions" className={"main-text underline cursor-pointer"}>
                  {formatMessage({id:"Manage Subscription"})}
                  </Link>
                </div>
              ) : (
                <div className={"flex flex-col gap-y-4"}>
                  <div className={"flex flex-col md:flex-row md:gap-x-4 gap-y-3" +  (isModal ? " !flex-col" :"")}>
                    {(priceJson[currency] || priceJson["USD"])?.map(({id, amount, frequency}:PriceItem)=>
                    <button
                    className={clsx(
                        "px-3 flex justify-between items-center  w-full h-[52px] rounded secondary-text text-[12px] ",
                        isEqual(subscriptionId,id) ? "!border primary-border rounded bg-[#C2D24B1A]":"container-field border border-transparent"
                    )}
                  onClick={()=>setSubscriptionId(id)}
                >
                  <div>
                    <div className={"flex items-center gap-x-2"}>
                      <div>
                        { subscriptionId==id ?  <div className={'rounded-full w-4 h-4 flex items-center justify-center bg-primary'}>
                          <svg width="9" height="7" viewBox="0 0 9 7" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M1 3.00446L3.57143 5.57589L8.42857 0.71875" stroke="black" stroke-linecap="round" stroke-linejoin="round"/>
                          </svg>
                        </div>: <div className={'rounded-full w-4 h-4 border border-main'}></div>}
                      </div>
                      {frequency=="yearly" && <span className={"text-[16px] font-medium line-through"}>
                      {currencySymbol}{perMonth * 12}
                      </span>}
                      <span
                        className={"text-[20px] font-semibold primary-text"}
                      >
                        {currencySymbol}{amount}
                      </span>{" "}
                      /{frequencyMap[frequency]}
                      {frequency=="yearly" &&<span style={{borderRadius:15}} className={"text-[12px] bg-primary p-2 text-black font-medium whitespace-nowrap hidden sm:flex ml-4"}>
                        2 Months Free
                      </span>}
                      </div>
                  </div>
                </button>
                )}

                  </div>
                  {(priceJson[currency] || priceJson["USD"]) && <button
                    className={
                      "btn-primary flex justify-center items-center gap-x-2.5 w-full p-4 rounded font-medium" + (isModal ? " absolute bottom-6 right-4 !w-fit" :"")
                    }
                    onClick={subscribing ? ()=>{} :subscribePlan}
                  >
                    {subscribing ? <Spinner animation="border"/> : <><svg
                      width="21"
                      height="20"
                      viewBox="0 0 21 20"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g clip-path="url(#clip0_10714_33577)">
                        <path
                          d="M4.48019 7.58506L5.20686 12.4967H15.8202L16.5427 7.51339L13.6319 10.0301L10.5027 6.00756L7.24519 10.0167L4.48019 7.58506ZM12.8452 6.30422L12.7802 6.36006L12.8977 6.37172L13.8702 7.62089L15.0677 6.58589L15.1844 6.59756L15.1327 6.52922L17.1435 4.79089C17.2721 4.67998 17.4314 4.61098 17.6003 4.59313C17.7691 4.57527 17.9394 4.60941 18.0883 4.69097C18.2372 4.77254 18.3576 4.89764 18.4335 5.04953C18.5093 5.20142 18.5369 5.37286 18.5127 5.54089L17.2627 14.1634H3.76853L2.49936 5.58006C2.47415 5.41109 2.50148 5.23845 2.57765 5.08553C2.65382 4.93261 2.77515 4.8068 2.92521 4.72513C3.07527 4.64346 3.2468 4.60989 3.41657 4.62896C3.58634 4.64802 3.74615 4.71881 3.87436 4.83172L5.79186 6.51922L5.75019 6.56922L5.84103 6.56256L7.04436 7.62089L8.05436 6.37672L8.14519 6.36922L8.09519 6.32589L9.87103 4.14339C9.95013 4.04615 10.0502 3.96801 10.1636 3.91478C10.2771 3.86156 10.4012 3.83463 10.5265 3.83599C10.6519 3.83735 10.7753 3.86697 10.8876 3.92265C10.9999 3.97832 11.0982 4.05862 11.1752 4.15756L12.8452 6.30422ZM3.83353 14.9967H17.1669V15.8301C17.1669 16.0511 17.0791 16.263 16.9228 16.4193C16.7665 16.5756 16.5545 16.6634 16.3335 16.6634H4.66686C4.44585 16.6634 4.23388 16.5756 4.0776 16.4193C3.92132 16.263 3.83353 16.0511 3.83353 15.8301V14.9967Z"
                          fill="black"
                        />
                      </g>
                      <defs>
                        <clipPath id="clip0_10714_33577">
                          <rect
                            width="20"
                            height="20"
                            fill="white"
                            transform="translate(0.5)"
                          />
                        </clipPath>
                      </defs>
                    </svg>
                    {formatMessage({id:"Subscribe Now"})}
                    </>}
                  </button>}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <>
        <Modal
          show={showModal}
          onHide={() => setShowModal(false)}
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header closeButton className={"px-10"}>
            <div className={"flex items-center justify-between w-full"}>
              <div className="d-flex fw-bold">
                <h2 className="text-[20px] primary-text leading-[28px]">
                  Billing Portal
                </h2>{" "}
              </div>
              <button onClick={() => setShowModal(false)}>
                <svg
                  width="32"
                  height="32"
                  viewBox="0 0 32 32"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M21.6568 8.45747L8.45746 21.6568C7.93676 22.1775 7.93676 23.0217 8.45746 23.5424C8.97816 24.0631 9.82238 24.0631 10.3431 23.5424L23.5424 10.3431C24.0631 9.82239 24.0631 8.97817 23.5424 8.45747C23.0217 7.93677 22.1775 7.93677 21.6568 8.45747Z"
                    fill="white"
                  />
                  <path
                    opacity="0.5"
                    d="M23.5425 21.6578L10.3432 8.45843C9.8225 7.93773 8.97828 7.93773 8.45758 8.45843C7.93688 8.97913 7.93688 9.82335 8.45758 10.3441L21.6569 23.5434C22.1776 24.0641 23.0218 24.0641 23.5425 23.5434C24.0632 23.0227 24.0632 22.1785 23.5425 21.6578Z"
                    fill="white"
                  />
                </svg>
              </button>
            </div>
          </Modal.Header>
          <Modal.Body>
            {modalLoading ? <div className="flex h-[500px] flex-col justify-content-center align-items-center">
              <Spinner style={{ width: 40, height: 40 }} animation="border" />
              <p className="mt-4 text-lg">Loading billing portal. Please wait ...</p>
            </div> :
              <iframe
                title="Manage Subscription"
                src={portalUrl}
                width="100%"
                height={600}
              ></iframe>}
          </Modal.Body>
        </Modal>
      </>
      <RazorpayModal open={razorpayModal} options={{
        subscription_id: razorpaySubscriptionId,
        handler: (async(response)=> {
          const { razorpay_subscription_id, razorpay_payment_id, razorpay_signature } = response
          await processAuthPayment({razorpay_subscription_id, razorpay_payment_id, razorpay_signature})
          setRazorpaySubscriptionId('')
          setRazorpayModal(false)
        }),
      }}
      modalOptions={{
       ondismiss:()=>{
        setSubscriptionId('')
        setRazorpayModal(false)
       } 
      }}
      />
    </>
  );
};

export default Subscribe;
