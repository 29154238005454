import React, { useEffect, useState } from "react";
import { Field, Form, Formik } from "formik";
import TextInput from "../../widgets/components/Input/TextInput";
import clsx from "clsx";
import { isEqual } from "lodash";
import { ToolTipUI } from "../../widgets/components/UI/ToolTipUI";
import { toAbsoluteUrl } from "../../../../_metronic/helpers";
import Switch from "react-switch";
import FormikReactCreatable from "../../widgets/components/Input/FormikReactCreatable";
import { InfoCard } from "../../widgets/components/UI/InfoCard";
import { FileUpload } from "../../widgets/components/FileUpload";
import { useIntl } from "react-intl";
import { useAuth } from "../../auth";
import { updatePersonalityInfo } from "../../profile/core/_requests";
import { toast } from "react-toastify";
import { Spinner } from "../../widgets/components/General/Spinner";
import { getCdnUrl } from "../../../core/_util";
import useGTM from "../../../hooks/useGTM";
import { GTMEvent } from "../../../hooks/gtm_helpers";

const SeoSettings = () => {
  const [modelStatus, setModelStatus] = useState<boolean>(false);
  const { formatMessage } = useIntl();
  const { dataLayerPush } = useGTM();
  const [disableSave, setDisableSave] = useState(false);
  const { selectedPersonality, setSelectedPersonality } = useAuth();
  const [socialShareImg, setSocialShareImg] = useState<string>();

  const onSubmit = async (values: any) => {
    setDisableSave(true);
    try {
      let resp: any = await updatePersonalityInfo({
        personalityId: selectedPersonality.personalityId,
        marketplaceSettings: values,
      });
      if (resp?.status == 200) {
        setSelectedPersonality(resp?.data?.data);
        toast.success(formatMessage({ id: "Settings updated successfully" }));
        dataLayerPush(GTMEvent.AIPersonalityEditSuccessful, {
          tab: "marketplace",
        });
      }
      setDisableSave(false);
    } catch (e: any) {
      console.log(e);
      // toast.error("Error while saving. Try again later",
      // {
      //   autoClose: false,
      //   closeButton: true, // Display close button (cross icon)
      // })
      setDisableSave(false);
      dataLayerPush(GTMEvent.AIPersonalityEditFailed, { tab: "marketplace" });
    }
  };

  const initValues = selectedPersonality?.marketplaceSettings
    ? selectedPersonality?.marketplaceSettings
    : {
        terms_link: "",
        featured_image: "",
        // ga4: "",
        // gtm: "",
        // analytics_provider: "ga4",
        verification: false,
        keywords: [],
        hashtags: [],
      };
  useEffect(() => {
    setSocialShareImg(
      initValues?.featured_image ||
        toAbsoluteUrl("/media/avatars/banner-image.png")
    );
  }, [selectedPersonality]);
  if (!selectedPersonality) {
    return <Spinner />;
  }
  return (
    <div>
      <Formik initialValues={initValues} onSubmit={onSubmit} validateOnMount>
        {(formik) => {
          return (
            <Form
              className={
                "tab-container rounded sm:p-[16px] md:p-[24px] md:pb-[40px] mt-4"
              }
            >
              <div
                className={"grid grid-cols-12 md:gap-y-[20px] md:gap-x-[40px] "}
              >
                <div className={"col-span-12 md:col-span-7 order-0"}>
                  {/* <div
                    className={
                      "flex flex-col md:flex-row gap-y-[14px] md:gap-x-[40px]"
                    }
                  >
                    <div className={"flex items-center gap-x-[16px]"}>
                      <Field
                        className={"form-check-input "}
                        type={"radio"}
                        name={"analytics_provider"}
                        value={"ga4"}
                      />
                      <label
                        className={clsx(
                          "text-[16px] leading-6 font-semibold",
                          isEqual(formik.values.analytics_provider, "ga4")
                            ? "!text-[#C2D24B]"
                            : "secondary-text"
                        )}
                      >
                        {formatMessage({
                          id: "GA4 Analytics",
                        })}
                      </label>
                    </div>
                    <div>
                      <div className={"flex items-center gap-x-[16px]"}>
                        <Field
                          className={"form-check-input"}
                          type={"radio"}
                          name={"analytics_provider"}
                          value={"gtm"}
                        />
                        <label
                          className={clsx(
                            "text-[16px] leading-6 font-semibold",
                            isEqual(formik.values.analytics_provider, "gtm")
                              ? "!text-[#C2D24B]"
                              : "secondary-text"
                          )}
                        >
                          {formatMessage({
                            id: "Google Tag Manager",
                          })}
                        </label>
                      </div>
                    </div>
                  </div>
                  <div className={"mt-[12px]"}>
                    {isEqual(formik.values.analytics_provider, "ga4") ? (
                      <TextInput
                        label={formatMessage({
                          id: "GA4 Analytics",
                        })}
                        toolTipText={formatMessage({
                          id: "GLOBAL.TOOLTIP.MARKETPLACE.MARKETPLACE_SETTINGS.GA4_ANALYTICS",
                        })}
                        fieldName={"ga4"}
                        placeholder={formatMessage({
                          id: "Enter GA4 Analytics",
                        })}
                        fieldType={"text"}
                        formik={formik}
                      />
                    ) : (
                      <TextInput
                        label={formatMessage({
                          id: "Google Tag Manager",
                        })}
                        toolTipText={formatMessage({
                          id: "GLOBAL.TOOLTIP.MARKETPLACE.MARKETPLACE_SETTINGS.GOOGLE_TAG_MANAGER",
                        })}
                        fieldName={"gtm"}
                        placeholder={formatMessage({
                          id: "Enter Google Tag Manager",
                        })}
                        fieldType={"text"}
                        formik={formik}
                      />
                    )}
                  </div> */}
                  <div className={"md:max-w-[240px] mb-[24px]"}>
                    <label
                      className={
                        "flex gap-x-1 text-[13px] leading-5 secondary-text mb-[4px] md:mb-[8px]"
                      }
                    >
                      {formatMessage({
                        id: "Featured Image (Social share)",
                      })}
                      <ToolTipUI
                        tooltipText={formatMessage({
                          id: "GLOBAL.TOOLTIP.MARKETPLACE.MARKETPLACE_SETTINGS.FEATURED_IMAGE",
                        })}
                      />
                    </label>
                    <div
                      className={
                        "secondary-bg p-[8px] shadow-default rounded relative"
                      }
                    >
                      <div
                        className="rounded-full  widgets-customize absolute -top-2 -right-3 p-2 shadow-[0px_2px_4px_0px_#0000001A]"
                        onClick={() => setModelStatus(true)}
                      >
                        <svg
                          width="16"
                          height="16"
                          viewBox="0 0 16 16"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <g clipPath="url(#clip0_344_12864)">
                            <path
                              d="M12.8667 5.94967L10.0333 3.14967L10.9667 2.21634C11.2222 1.96079 11.5362 1.83301 11.9087 1.83301C12.2807 1.83301 12.5944 1.96079 12.85 2.21634L13.7833 3.14967C14.0389 3.40523 14.1722 3.71367 14.1833 4.07501C14.1944 4.4359 14.0722 4.74412 13.8167 4.99967L12.8667 5.94967ZM11.9 6.93301L4.83333 13.9997H2V11.1663L9.06667 4.09967L11.9 6.93301Z"
                              className={"icon"}
                              fillOpacity="0.65"
                            />
                          </g>
                          <defs>
                            <clipPath id="clip0_344_12864">
                              <rect width="16" height="16" fill="white" />
                            </clipPath>
                          </defs>
                        </svg>
                      </div>
                      <img
                        alt="ai social share"
                        src={getCdnUrl(socialShareImg || "")}
                        className={"w-full h-[125px]"}
                      />
                    </div>
                    <div
                      className={
                        "flex justify-center md:justify-start  mt-[8px]"
                      }
                    >
                      <div className={"max-w-[240px] md:max-w-full"}>
                        <p className={"text-[13px] leading-5 secondary-text"}>
                          {formatMessage({
                            id: "Image must in 16:9 ration preferably 1280x720px in jpg or png format only",
                          })}
                        </p>
                      </div>
                    </div>
                  </div>

                  <FormikReactCreatable
                    tooltipText={formatMessage({
                      id: "GLOBAL.TOOLTIP.MARKETPLACE.MARKETPLACE_SETTINGS.KEYWORDS_INPUT",
                    })}
                    isMulti={true}
                    label={formatMessage({
                      id: "Keywords",
                    })}
                    name={"keywords"}
                    className={"without-arrow"}
                    isClearable={true}
                    placeholder={formatMessage({
                      id: "Type something and press Enter or Tab to create name",
                    })}
                    formik={formik}
                    defaultValue={formik.values?.keywords?.map(
                      (el: string, index: number) => {
                        return { value: el, label: el, id: index };
                      }
                    )}
                  />
                  <FormikReactCreatable
                    tooltipText={formatMessage({
                      id: "GLOBAL.TOOLTIP.MARKETPLACE.MARKETPLACE_SETTINGS.HASHTAGS_INPUT",
                    })}
                    isMulti={true}
                    label={formatMessage({
                      id: "Hashtags",
                    })}
                    name={"hashtags"}
                    className={"without-arrow"}
                    isClearable={true}
                    placeholder={formatMessage({
                      id: "Type something and press Enter or Tab to create name",
                    })}
                    formik={formik}
                    defaultValue={formik.values?.hashtags?.map(
                      (el: string, index: number) => {
                        return { value: el, label: el, id: index };
                      }
                    )}
                    formatOptionLabel={(inputValue: any) =>
                      `#${inputValue.value}`
                    }
                    // isValidNewOption={(inputValue:any) => {
                    //   // Check if the inputValue contains the "#" character
                    //   if (inputValue.includes("#")) {
                    //     return false; // Prevent typing the "#" character
                    //   }
                    //   // You can add additional validation logic here if needed
                    //   return true; // Allow other input values
                    // }}
                  />
                </div>

                <div
                  className={
                    "col-span-12 md:col-span-5 flex flex-col justify-start gap-[20px] md:mt-[22px]"
                  }
                >
                  <InfoCard
                    title={formatMessage({
                      id: "Understanding the SEO Settings of AI Personality’s profile page",
                    })}
                    desc={
                      <>
                        {formatMessage({
                          id: "In the ever-evolving digital landscape, visibility is key to success. Whether you're crafting an AI Personality for entertainment, assistance, or engagement, ensuring that it's easily discoverable by users is paramount. \n",
                        })}
                        <br></br>
                        <br></br>
                        {formatMessage({
                          id: "That's where Search Engine Optimization (SEO) comes into play. At Kamoto.AI, we understand the importance of making your AI Personality readily accessible to its intended audience. \n",
                        })}
                        <br></br>
                        <br></br>
                        {formatMessage({
                          id: "Setting up SEO for your AI Personality allows you to boost its visibility and reach. From updating feature images to adding relevant keywords and hashtags, we've got you covered. Let's dive into the world of SEO and unlock the full potential of your AI Personality on Kamoto.AI.",
                        })}
                        <br></br>
                        <br></br>
                      </>
                    }
                    slug={""}
                    isAnchor={
                      "https://help.kamoto.ai/en/articles/8409173-a-guide-to-setting-up-seo-for-your-ai-personality-on-kamoto-ai"
                    }
                  />
                </div>
              </div>
              <FileUpload
                fileSize={2097152}
                maxFileNumber={1}
                allowType={["image/*", ".jpg", ".jpeg", ".png"]}
                metaData={{
                  module: "profileimg",
                  isProtected: true,
                }}
                modalStatus={modelStatus}
                handleClose={() => setModelStatus(false)}
                handleSuccess={(id: number, name: string) => {
                  // setImgName(name);
                  setSocialShareImg(name);
                  formik.setFieldValue("featured_image", name);
                  dataLayerPush(
                    GTMEvent.PersonalityFeaturedImageUploadSuccessful
                  );
                }}
                resourceType="ai-social-share-featured-image"
              />
              <div className={"flex justify-end gap-[10px] md:mt-[180px]"}>
                <button
                  type="button"
                  className={
                    "main-button-primary-light text-[14px] leading-5 font-medium py-[12px] w-1/2 md:w-[128px] rounded"
                  }
                >
                  {formatMessage({
                    id: "Cancel",
                  })}
                </button>
                <button
                  type="submit"
                  className={`main-button text-[14px] leading-5 font-medium py-[12px] w-1/2  md:w-[140px] rounded ${
                    disableSave ? "opacity-75" : ""
                  }`}
                  disabled={disableSave}
                >
                  {disableSave ? "Please Wait" : "Save"}
                </button>
              </div>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};

export default SeoSettings;
