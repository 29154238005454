import React from "react";
import bannerImage from "../../../../assets/images/profile/banner-bg.png";

const Index = ({bannerImageAlt} : {bannerImageAlt?:any}) => {
  return (
    <div
      className={"h-[220px] bg-cover bg-no-repeat object-cover"}
      style={{
        backgroundImage: `url(${bannerImageAlt || bannerImage})`,
      }}
    />
  );
};

export default Index;
