/* eslint-disable react-hooks/rules-of-hooks */
import React, { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { InfoCard } from "../../widgets/components/UI/InfoCard";
import base1 from "../../../assets/svgs/base1.svg";
import base2 from "../../../assets/svgs/base2.svg";
import base3 from "../../../assets/svgs/base3.svg";
import base4 from "../../../assets/svgs/base4.svg";
import base5 from "../../../assets/svgs/base5.svg";
import base6 from "../../../assets/svgs/base6.svg";
const knowledgeBase: React.FC<any> = ({}) => {
  const { formatMessage } = useIntl();
  const baseTiles = [
    {
      id: 1,
      img: base1,
      text: `${formatMessage({ id: "Daily Astrology" })}`,
      subText: `${formatMessage({
        id: "Send Daily Astrology related messages to users",
      })}`,
    },
    {
      id: 2,
      img: base2,
      text: `${formatMessage({ id: "Daily News" })}`,
      subText: `${formatMessage({
        id: "Send daily crisp news bites to the users",
      })}`,
    },
    {
      id: 3,
      img: base3,
      text: `${formatMessage({ id: "This Day in History" })}`,
      subText: `${formatMessage({
        id: "Send interesting information about this day in history",
      })}`,
    },
    {
      id: 4,
      img: base4,
      text: `${formatMessage({ id: "Daily Jokes" })}`,
      subText: `${formatMessage({
        id: "Send daily jokes to engage user and make them laugh",
      })}`,
    },
    {
      id: 5,
      img: base5,
      text: `${formatMessage({ id: "Cricket Updates" })}`,
      subText: `${formatMessage({
        id: "Send cricket updates to users to engage them",
      })}`,
    },
    {
      id: 6,
      img: base6,
      text: `${formatMessage({ id: "More Coming Soon" })}`,
      subText: `${formatMessage({
        id: "We will be adding more knowledge base in future",
      })}`,
    },
  ];
  return (
    <div
      className={
        "edit-profile-section p-[24px_10px_16px_10px] md:p-[24px] rounded-[4px] mt-4"
      }
    >
      <div className={"grid grid-cols-12 gap-y-[20px] md:gap-x-[40px]"}>
        <div className={"col-span-12 md:col-span-7 order-0"}>
          <div className="flex-row graph-placeholder-wrapper d-flex md:align-items-center mt-0">
            <div className="mr-[14px]">
              <svg
                width="32"
                height="32"
                viewBox="0 0 32 32"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g clipPath="url(#clip0_4933_32805)">
                  <path
                    d="M14.667 22.667H17.3337V14.667H14.667V22.667ZM16.0003 12.0003C16.3781 12.0003 16.695 11.8723 16.951 11.6163C17.207 11.3603 17.3345 11.0439 17.3337 10.667C17.3337 10.2892 17.2057 9.97233 16.9497 9.71633C16.6937 9.46033 16.3772 9.33277 16.0003 9.33366C15.6225 9.33366 15.3057 9.46166 15.0497 9.71766C14.7937 9.97366 14.6661 10.2901 14.667 10.667C14.667 11.0448 14.795 11.3617 15.051 11.6177C15.307 11.8737 15.6234 12.0012 16.0003 12.0003ZM16.0003 29.3337C14.1559 29.3337 12.4225 28.9834 10.8003 28.283C9.1781 27.5825 7.76699 26.6328 6.56699 25.4337C5.36699 24.2337 4.41721 22.8225 3.71766 21.2003C3.0181 19.5781 2.66788 17.8448 2.66699 16.0003C2.66699 14.1559 3.01721 12.4225 3.71766 10.8003C4.4181 9.1781 5.36788 7.76699 6.56699 6.56699C7.76699 5.36699 9.1781 4.41721 10.8003 3.71766C12.4225 3.0181 14.1559 2.66788 16.0003 2.66699C17.8448 2.66699 19.5781 3.01721 21.2003 3.71766C22.8225 4.4181 24.2337 5.36788 25.4337 6.56699C26.6337 7.76699 27.5839 9.1781 28.2843 10.8003C28.9848 12.4225 29.3345 14.1559 29.3337 16.0003C29.3337 17.8448 28.9834 19.5781 28.283 21.2003C27.5825 22.8225 26.6328 24.2337 25.4337 25.4337C24.2337 26.6337 22.8225 27.5839 21.2003 28.2843C19.5781 28.9848 17.8448 29.3345 16.0003 29.3337ZM16.0003 26.667C18.9781 26.667 21.5003 25.6337 23.567 23.567C25.6337 21.5003 26.667 18.9781 26.667 16.0003C26.667 13.0225 25.6337 10.5003 23.567 8.43366C21.5003 6.36699 18.9781 5.33366 16.0003 5.33366C13.0225 5.33366 10.5003 6.36699 8.43366 8.43366C6.36699 10.5003 5.33366 13.0225 5.33366 16.0003C5.33366 18.9781 6.36699 21.5003 8.43366 23.567C10.5003 25.6337 13.0225 26.667 16.0003 26.667Z"
                    fill="#C2D24B"
                    fillOpacity="0.5"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_4933_32805">
                    <rect width="32" height="32" fill="white" />
                  </clipPath>
                </defs>
              </svg>
            </div>
            <div className="">
              <h5 className="text-[14px] leading-[20px] font-weight-500 primary-text">
                {" "}
                {formatMessage({ id: "Coming soon..." })}
              </h5>
              <p className="text-[12px] leading-[18px] font-weight-400 mt-[2px] secondary-text">
                {formatMessage({
                  id: "This feature is currently under development and is scheduled for release in the next few weeks. Stay tuned!",
                })}
              </p>
            </div>
          </div>

          <div className="grid grid-cols-12 gap-[12px]">
            {baseTiles.map((el) => (
              <div
                key={el.id}
                className="col-span-6 xl:col-span-4 rounded-[6px] border-[#2E2F45] border-solid border pl-[16px] pr-[8px] pt-[8px] pb-[16px] md:pb-[36px]"
              >
                <div className="flex flex-row justify-between items-start">
                  <img
                    src={el.img}
                    alt=""
                    className="min-w-[48px] mt-[12px] min-h-[48px] max-w-[48px] w-full"
                  ></img>
                  <div
                    className={
                      "flex items-center gap-x-[8px] company-container"
                    }
                  >
                    <form className="form-check  form-check-custom react-switch-bg form-check-solid form-check-primary form-switch flex items-center gap-x-[8px]">
                      <input
                        className="form-check-input flex-grow !w-[45px]"
                        type="checkbox"
                        name=""
                        // onChange={(e) => {
                        //   setCumulative(e.target.checked);
                        // }}
                      />
                    </form>
                  </div>
                </div>
                <p className="mt-[12px] mb-[8px] text-[#FFFFFFCC] text-[14px] font-[600] leading-[20px]">
                  {el.text}
                </p>
                <p className="text-[#FFFFFFA6] text-[12px] leading-[18px]">
                  {el.subText}
                </p>
              </div>
            ))}
          </div>
          <button
            type="submit"
            className={`main-button text-[14px] leading-[20px] font-medium py-[12px] w-full sm:max-w-[166px] rounded-[4px] mt-[20px] md:mt-[40px] sm:float-right`}
            // className={`main-button text-[14px] leading-5 font-medium py-[12px] w-full sm:w-1/2  md:w-[140px] rounded ${
            //   disableSave ? "opacity-75" : ""
            // }`}
            // disabled={disableSave}
          >
            {formatMessage({
              // id: `${disableSave ? "Please Wait" : "Save"}`,
              id: `${false ? "Please Wait" : "Save"}`,
            })}
          </button>
        </div>

        <div
          className={
            "col-span-12 md:col-span-5 flex flex-col justify-start gap-[20px]"
          }
        >
          <InfoCard
            title={formatMessage({
              id: "Understanding Knowledge Base",
            })}
            desc={
              <>
                {formatMessage({
                  id: "Knowledge Bases are dynamic skill sets, dependent on time & date, that can be linked to your AI Personality, enhancing its responses to users. \n",
                })}
                <br></br>
                <br></br>
                {formatMessage({
                  id: " These Knowledge bases not only simplify your daily tasks by saving time & effort, but also enable daily updates, such as the latest news, without the need for continuous training. This streamlined approach makes your AI Personality more informed and engaging, increasing the potential for regular interaction and daily usage.  \n",
                })}
                <br></br>
                <br></br>
                {formatMessage({
                  id: " It's your key to making your AI Personality smarter and more vibrant than ever, all while conserving your valuable time & resources.",
                })}
                <br></br>
                <br></br>
              </>
            }
            slug={""}
            isAnchor={
              "https://help.kamoto.ai/en/articles/7952367-customizing-voice-style-and-audio-of-ai-personality-in-kamoto-ai"
            }
          />
        </div>
      </div>
    </div>
  );
};

export default knowledgeBase;
