import React, { useEffect, useState } from "react";
import { useIntl } from "react-intl";

import {  useLocation, useNavigate } from "react-router-dom";
import clsx from "clsx";

import AccountTabs from "../components/AccountTabs";
import { getLinkedSocialAccounts } from "../core/_request";
import { useAuth } from "../../auth";
import { toast } from "react-toastify";
import { Spinner } from "../../widgets/components/General/Spinner";

const subTabs = ['all', 'twitter', 'facebook','kamoto']

const leftMenu = [{
  title: 'All Accounts',
  key: 'all',
  svg: (width = 24, height = 24, className='') => <svg
  className={className}  
  width={width}
    height={height}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M22 12.0005C22 13.3138 21.7414 14.6142 21.2388 15.8275C20.7362 17.0408 19.9996 18.1433 19.0709 19.0719C18.1423 20.0006 17.0399 20.7372 15.8265 21.2398C14.6132 21.7423 13.3128 22.001 11.9995 22.001C10.6862 22.001 9.38581 21.7423 8.1725 21.2398C6.95918 20.7372 5.85673 20.0006 4.9281 19.0719C3.99947 18.1433 3.26284 17.0408 2.76027 15.8275C2.25769 14.6142 1.99902 13.3138 1.99902 12.0005C1.99902 9.3482 3.05264 6.80454 4.9281 4.92908C6.80356 3.05362 9.34723 2 11.9995 2C14.6518 2 17.1955 3.05362 19.0709 4.92908C20.9464 6.80454 22 9.3482 22 12.0005Z"
      stroke="#C2D24B"
      stroke-width="1.5"
    />
    <path
      d="M16.0001 12C16.0001 13.313 15.8961 14.614 15.6951 15.827C15.4951 17.04 15.2001 18.142 14.8281 19.071C14.4571 20 14.0161 20.736 13.5311 21.239C13.0451 21.741 12.5251 22 12.0001 22C11.4751 22 10.9551 21.741 10.4701 21.239C9.98405 20.736 9.54305 19.999 9.17205 19.071C8.80005 18.142 8.50505 17.041 8.30405 15.827C8.09902 14.5617 7.99735 13.2818 8.00005 12C8.00005 10.687 8.10305 9.386 8.30405 8.173C8.50505 6.96 8.80005 5.858 9.17205 4.929C9.54305 4 9.98405 3.264 10.4691 2.761C10.9551 2.26 11.4751 2 12.0001 2C12.5251 2 13.0451 2.259 13.5301 2.761C14.0161 3.264 14.4571 4.001 14.8281 4.929C15.2001 5.858 15.4951 6.959 15.6951 8.173C15.8961 9.386 16.0001 10.687 16.0001 12Z"
      stroke="#C2D24B"
      stroke-width="1.5"
    />
    <path
      d="M2 12H22"
      stroke="#C2D24B"
      stroke-width="1.5"
      stroke-linecap="round"
    />
  </svg>,
  toggle: false
},
{
  title: 'Twitter/ x.com',
  key: 'twitter',
  svg: (width = 24, height = 24, className='') => <svg
  className={className}
    width={width}
    height={height}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_7601_52185)">
      <path
        d="M21.543 7.10476C21.5576 7.31645 21.5576 7.52795 21.5576 7.74151C21.5576 14.2478 16.6044 21.7515 7.54763 21.7515V21.7478C4.87202 21.7515 2.25186 20.9851 0 19.5401C0.389063 19.587 0.78 19.6104 1.17188 19.6114C3.38946 19.6132 5.54326 18.8694 7.28719 17.4996C6.25968 17.4802 5.26393 17.1401 4.43914 16.527C3.61436 15.914 3.00178 15.0584 2.68706 14.0801C3.42525 14.2226 4.18575 14.1932 4.91025 13.9952C2.613 13.5311 0.960375 11.5129 0.960375 9.16895V9.10651C1.64518 9.48801 2.41163 9.69938 3.19519 9.72282C1.03125 8.27682 0.364688 5.39851 1.67119 3.14832C2.90736 4.66944 4.44967 5.91352 6.19797 6.79975C7.94626 7.68598 9.86142 8.19453 11.8191 8.29238C11.6239 7.45215 11.6528 6.57542 11.9028 5.74985C12.1528 4.92428 12.6152 4.17882 13.2437 3.58801C15.2269 1.7237 18.3459 1.81932 20.2101 3.80157C21.3128 3.58383 22.3703 3.17953 23.337 2.60607C22.9694 3.7462 22.2002 4.71401 21.1725 5.32951C22.1486 5.21443 23.1017 4.95306 24 4.5542C23.3392 5.54375 22.5072 6.40747 21.543 7.10476Z"
        fill="#1D9BF0"
      />
    </g>
    <defs>
      <clipPath id="clip0_7601_52185">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </svg>,
  toggle: true
},
{
  title: 'Facebook',
  key: 'facebook',
  svg: (width = 24, height = 24, className='') => <svg
  className={className}
    width={width}
    height={height}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_7601_52215)">
      <path
        d="M16 8C16 3.58175 12.4183 0 8 0C3.58175 0 0 3.58169 0 8C0 11.993 2.9255 15.3027 6.75 15.9028V10.3125H4.71875V8H6.75V6.2375C6.75 4.2325 7.94438 3.125 9.77169 3.125C10.647 3.125 11.5625 3.28125 11.5625 3.28125V5.25H10.5538C9.55988 5.25 9.25 5.86669 9.25 6.49937V8H11.4688L11.1141 10.3125H9.25V15.9028C13.0745 15.3027 16 11.9931 16 8Z"
        fill="#1877F2"
      />
      <path
        d="M11.1141 10.3125L11.4688 8H9.25V6.49937C9.25 5.86662 9.55994 5.25 10.5538 5.25H11.5625V3.28125C11.5625 3.28125 10.647 3.125 9.77169 3.125C7.94438 3.125 6.75 4.2325 6.75 6.2375V8H4.71875V10.3125H6.75V15.9028C7.16351 15.9676 7.58144 16.0001 8 16C8.41856 16.0001 8.83649 15.9676 9.25 15.9028V10.3125H11.1141Z"
        fill="white"
      />
    </g>
    <defs>
      <clipPath id="clip0_7601_52215">
        <rect width="16" height="16" fill="white" />
      </clipPath>
    </defs>
  </svg>,
  toggle: true
},
{
  title: 'Kamoto',
  key: 'kamoto',
  svg: (width = 24, height = 24, className='') => <svg
  className={'bg-[black] rounded-[4px] ' + className}  
  width={width}
    height={height}
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
   <g clip-path="url(#clip0_5237_17083)">
      <path fill-rule="evenodd" clip-rule="evenodd" d="M25.1797 1.72973H7.32021C4.50206 1.72973 2.2175 4.05301 2.2175 6.91892V25.0811C2.2175 27.947 4.50206 30.2703 7.32021 30.2703H25.1797C27.9978 30.2703 30.2824 27.947 30.2824 25.0811V6.91892C30.2824 4.05301 27.9978 1.72973 25.1797 1.72973ZM7.32021 0C3.56268 0 0.516602 3.09771 0.516602 6.91892V25.0811C0.516602 28.9023 3.56268 32 7.32021 32H25.1797C28.9372 32 31.9833 28.9023 31.9833 25.0811V6.91892C31.9833 3.09771 28.9372 0 25.1797 0H7.32021Z" fill="#C2D24B"></path>
      <path d="M12.4186 5.1621C11.1506 5.40912 10.1295 6.21301 9.56136 7.41883C9.34728 7.87939 9.23611 8.33576 9.20729 8.89681L9.18259 9.36155L8.82852 9.45366C7.94334 9.67557 7.0664 10.291 6.56411 11.0447C6.32532 11.4048 6.08241 11.9281 5.97536 12.3259C5.84773 12.8074 5.84773 13.8122 5.97536 14.2853C6.08241 14.6831 6.32532 15.2232 6.55587 15.5665L6.72879 15.8219L6.55999 16.0731C6.3212 16.429 6.08241 16.9524 5.97536 17.3501C5.84773 17.8316 5.84773 18.8365 5.97536 19.318C6.35414 20.729 7.46576 21.851 8.82852 22.1902L9.18259 22.2823L9.20729 22.747C9.28552 24.2585 10.1254 25.523 11.4841 26.1719C12.0646 26.4483 12.3734 26.5111 13.1062 26.5111C13.6291 26.5111 13.802 26.4943 14.0737 26.4231C14.4895 26.3101 15.1442 25.9793 15.5065 25.703L15.7823 25.4853V6.15858L15.5065 5.94086C15.14 5.66034 14.4854 5.33376 14.0737 5.2249C13.6497 5.11186 12.8304 5.08255 12.4186 5.1621Z" fill="#C2D24B"></path>
      <path d="M19.0055 5.16276C18.3345 5.29255 17.7128 5.59401 17.1734 6.04201L17.017 6.1718V11.0077H18.355L18.458 10.7858C18.5897 10.501 18.9932 10.0949 19.2896 9.94838C20.4054 9.40408 21.7146 10.0949 21.9246 11.3384C22.0934 12.3433 21.4058 13.3105 20.3971 13.4863C19.6396 13.6161 18.8409 13.2225 18.495 12.5359L18.355 12.2637H17.017V15.1946H20.0019L20.1048 14.9726C20.2366 14.6879 20.64 14.2818 20.9365 14.1353C21.8463 13.6915 22.9415 14.0641 23.3944 14.9768C23.9584 16.1115 23.2791 17.4555 22.0399 17.6732C21.2864 17.803 20.4877 17.4052 20.1419 16.7228L20.0019 16.4506H17.017V19.3814H18.355L18.458 19.1595C18.5897 18.8748 18.9932 18.4687 19.2896 18.3222C20.4054 17.7779 21.7146 18.4687 21.9246 19.7122C22.0934 20.7171 21.4058 21.6842 20.3971 21.8601C19.6396 21.9899 18.8409 21.5963 18.495 20.9097L18.355 20.6375H17.017V25.4734L17.1734 25.6032C17.6098 25.9674 18.1945 26.2773 18.7256 26.4196C19.2155 26.5578 20.1501 26.5578 20.64 26.4238C21.8216 26.0972 22.7274 25.3268 23.2379 24.2215C23.4561 23.7484 23.5632 23.2962 23.592 22.7477L23.6167 22.283L23.9708 22.1908C25.3335 21.8517 26.4452 20.7296 26.8198 19.3186C26.9557 18.8204 26.9557 17.87 26.8239 17.3717C26.7087 16.9447 26.4781 16.4297 26.2434 16.078L26.0705 15.8226L26.2434 15.5672C26.4781 15.2155 26.7087 14.7005 26.8239 14.2734C26.9557 13.7752 26.9557 12.8248 26.8198 12.3265C26.4452 10.9155 25.3335 9.79346 23.9708 9.45432L23.6167 9.36221L23.592 8.89747C23.5385 7.89261 23.1474 6.98825 22.4557 6.27647C21.9863 5.79498 21.3111 5.40141 20.6606 5.22556C20.2366 5.11252 19.4214 5.08321 19.0055 5.16276Z" fill="#C2D24B"></path>
      <path d="M19.8093 11.0949C19.6323 11.187 19.4882 11.4341 19.4882 11.635C19.4882 11.8444 19.6323 12.083 19.8175 12.1793C19.904 12.2254 20.0357 12.2631 20.1057 12.2631C20.1757 12.2631 20.3075 12.2254 20.3939 12.1793C20.5792 12.083 20.7233 11.8444 20.7233 11.635C20.7233 11.4257 20.5792 11.187 20.3939 11.0907C20.1922 10.9861 20.0069 10.9861 19.8093 11.0949Z" fill="#C2D24B"></path>
      <path d="M21.4549 15.282C21.2778 15.3741 21.1337 15.6211 21.1337 15.8221C21.1337 16.0315 21.2778 16.2701 21.4631 16.3664C21.5495 16.4125 21.6813 16.4501 21.7513 16.4501C21.9571 16.4501 22.1918 16.3036 22.2865 16.1152C22.3318 16.0273 22.3688 15.8933 22.3688 15.8221C22.3688 15.6128 22.2248 15.3741 22.0395 15.2778C21.8377 15.1731 21.6525 15.1731 21.4549 15.282Z" fill="#C2D24B"></path>
      <path d="M19.8093 19.4691C19.6323 19.5612 19.4882 19.8082 19.4882 20.0092C19.4882 20.0804 19.5252 20.2144 19.5705 20.3023C19.6652 20.4907 19.8999 20.6372 20.1057 20.6372C20.4186 20.6372 20.7233 20.3274 20.7233 20.0092C20.7233 19.7999 20.5792 19.5612 20.3939 19.4649C20.1922 19.3602 20.0069 19.3602 19.8093 19.4691Z" fill="#C2D24B"></path>
   </g>
   <defs>
      <clipPath id="clip0_5237_17083">
         <rect width="32" height="32" fill="white"></rect>
      </clipPath>
   </defs>
  </svg>,
  toggle: true
},]

const SocialMediaAccounts: React.FC<any> = () => {
  const { formatMessage } = useIntl();
  const { pathname, search } = useLocation()
  const { selectedPersonality } = useAuth()
  const splits = pathname.split('/')
  const [activeSubTab, setActiveSubTab] = useState<number>(
    subTabs.indexOf(splits[3] || "all")
  );
  const [accounts, setAccounts] = useState<Record<string, any>>({})
  const [loading, setLoading] = useState<boolean>(true);
  const navigate = useNavigate()
  useEffect(() => {
    if (splits[2] == "social-media-accounts") {
      navigate(`/communicate-engage/social-media-accounts/${subTabs[activeSubTab]}${search}`)
    }
  }, [activeSubTab]);

  useEffect(() => {
    let subTab = splits[3]
    if (subTab) {
      setActiveSubTab(subTabs.indexOf(subTab));
    } else {
      setActiveSubTab(0)
    }
  }, [splits[3]]);

  async function getAccounts() {
    const { data: { data, success } } = await getLinkedSocialAccounts(selectedPersonality.personalityId)
    if (success) {
      if (data.length) {
        let obj: Record<string, any> = {}
        data.forEach((item) => {
          obj[item.socialAccountType] = item
        })
        setAccounts(obj)
      }
    } else {
      toast.error("Error while fetching social accounts", {
        autoClose: false,
        closeButton: true,
      });
    }
  }
  useEffect(() => {
    if (selectedPersonality) {
      setLoading(true)
      getAccounts()
      setLoading(false)
    }
  }, [selectedPersonality])

  return (
    <div>
      <div className="tab-container rounded  mt-4 md:pt-[10px] relative">
      {loading && <Spinner/>}
        <div className="flex-row graph-placeholder-wrapper d-flex md:align-items-center md:w-[calc(100%-48px)] !mx-auto !mb-[24px] md:!mt-[14px]">
          <div className="mr-[14px]">
            <svg
              width="32"
              height="32"
              viewBox="0 0 32 32"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g clipPath="url(#clip0_4933_32805)">
                <path
                  d="M14.667 22.667H17.3337V14.667H14.667V22.667ZM16.0003 12.0003C16.3781 12.0003 16.695 11.8723 16.951 11.6163C17.207 11.3603 17.3345 11.0439 17.3337 10.667C17.3337 10.2892 17.2057 9.97233 16.9497 9.71633C16.6937 9.46033 16.3772 9.33277 16.0003 9.33366C15.6225 9.33366 15.3057 9.46166 15.0497 9.71766C14.7937 9.97366 14.6661 10.2901 14.667 10.667C14.667 11.0448 14.795 11.3617 15.051 11.6177C15.307 11.8737 15.6234 12.0012 16.0003 12.0003ZM16.0003 29.3337C14.1559 29.3337 12.4225 28.9834 10.8003 28.283C9.1781 27.5825 7.76699 26.6328 6.56699 25.4337C5.36699 24.2337 4.41721 22.8225 3.71766 21.2003C3.0181 19.5781 2.66788 17.8448 2.66699 16.0003C2.66699 14.1559 3.01721 12.4225 3.71766 10.8003C4.4181 9.1781 5.36788 7.76699 6.56699 6.56699C7.76699 5.36699 9.1781 4.41721 10.8003 3.71766C12.4225 3.0181 14.1559 2.66788 16.0003 2.66699C17.8448 2.66699 19.5781 3.01721 21.2003 3.71766C22.8225 4.4181 24.2337 5.36788 25.4337 6.56699C26.6337 7.76699 27.5839 9.1781 28.2843 10.8003C28.9848 12.4225 29.3345 14.1559 29.3337 16.0003C29.3337 17.8448 28.9834 19.5781 28.283 21.2003C27.5825 22.8225 26.6328 24.2337 25.4337 25.4337C24.2337 26.6337 22.8225 27.5839 21.2003 28.2843C19.5781 28.9848 17.8448 29.3345 16.0003 29.3337ZM16.0003 26.667C18.9781 26.667 21.5003 25.6337 23.567 23.567C25.6337 21.5003 26.667 18.9781 26.667 16.0003C26.667 13.0225 25.6337 10.5003 23.567 8.43366C21.5003 6.36699 18.9781 5.33366 16.0003 5.33366C13.0225 5.33366 10.5003 6.36699 8.43366 8.43366C6.36699 10.5003 5.33366 13.0225 5.33366 16.0003C5.33366 18.9781 6.36699 21.5003 8.43366 23.567C10.5003 25.6337 13.0225 26.667 16.0003 26.667Z"
                  fill="#C2D24B"
                  fillOpacity="0.5"
                />
              </g>
              <defs>
                <clipPath id="clip0_4933_32805">
                  <rect width="32" height="32" fill="white" />
                </clipPath>
              </defs>
            </svg>
          </div>
          <div className="">
            <h5 className="text-[14px] leading-[20px] font-weight-500 primary-text">
              {" "}
              {formatMessage({ id: "Coming soon..." })}
            </h5>
            <p className="text-[12px] leading-[18px] font-weight-400 mt-[2px] secondary-text">
              {formatMessage({
                id: "This feature is currently under development and is scheduled for release in the next few weeks. Stay tuned!",
              })}
            </p>
          </div>
        </div>
        <div className="flex flex-col md:flex-row h-full md:min-h-[calc(95vh-196px)]">
          <div className="md:max-w-[200px] md:min-w-[200px] w-full md:border-r border-solid border-[#2E2F45] ">
            {leftMenu.map(({ title, toggle,key, svg }, index) =>
              <div onClick={() => setActiveSubTab(index)} className={clsx("border-b border-solid border-b-[#2E2F45] h-[52px] flex flex-row items-center gap-[8px] py-[14px] px-[16px]", activeSubTab == index && "border-r-[2px] border-r-[#C2D24B] bg-[#21233A]")}>
                {toggle && accounts?.[key] ?
                  <div className="relative flex items-end justify-end">
                    <div className="overflow-hidden w-[36px] h-[36px] rounded-full bg-slate-600">
                      <img src={accounts[key].image} alt="" className=""></img>
                    </div>
                    {svg(16, 16,'absolute')}
                  </div> : svg()}
                <span className="text-[#FFFFFFCC] text-[14px] font-[500] leading-[20px]">
                  {toggle && accounts?.[key] ? accounts[key].accountName : title}
                </span>
              </div>
            )}
            <div className="border-b border-solid border-[#2E2F45] h-[52px] flex flex-row items-center gap-[8px] py-[14px] px-[16px]">
              <span className="text-[#FFFFFFCC] text-[14px] font-[500] leading-[20px]">
                {formatMessage({
                  id: "More Coming Soon",
                })}
              </span>
            </div>
          </div>
          <div className="py-[24px] md:pt-[6px] md:pb-[22px] md:px-[22px] flex-grow overflow-hidden">
            <AccountTabs selectedTab={subTabs[activeSubTab]} accountDetails={accounts[subTabs[activeSubTab]]} setAccounts={setAccounts} />
          </div>
        </div>

        {/* <Empty
        heading={formatMessage({
          id: "Coming Soon",
        })}
        content={formatMessage({
          id: "This feature is coming soon on Kamoto.AI. Please come back later.",
        })}
      /> */}
      </div>
      {/* <Empty
        heading={formatMessage({
          id: "Coming Soon",
        })}
        content={formatMessage({
          id: "This feature is coming soon on Kamoto.AI. Please come back later.",
        })}
      /> */}
    </div>
  );
};

export default SocialMediaAccounts;
