import React from "react";
import { useIntl } from "react-intl";

const GeoPersonality: React.FC<any> = ({ personalityImage, userImage }) => {
  const { formatMessage } = useIntl();

  return (
    <div>
      <div
        className={
          "h-full bg-center bg-no-repeat overflow-y-auto  primary-bg relative"
        }
      >
        <div
          className={
            "p-6 flex flex-grow w-full justify-center  items-center  rounded py-20"
          }
        >
          <div className={"text-center"}>
            <h2
              className={
                "text-[14px] md:text-[24px] leading-[24px] md:leading-[32px] primary-text font-semibold mb-[8px]"
              }
            >
              {formatMessage({ id: "Not Available in Your Country" })}
            </h2>
            <div className={"flex justify-center"}>
              <div className={"relative h-[70px] md:h-[120px] w-72 mb-[28px]"}>
                <div
                  className={
                    "absolute top-0 right-32 secondary-bg shadow-default rounded-full p-1.5"
                  }
                >
                  <div className="relative">
                    <img
                      src={personalityImage}
                      className={
                        "rounded-full object-cover object-top h-[60px] md:h-[116px] w-[60px] md:w-[116px]"
                      }
                      alt={"profile"}
                    />
                    <svg
                      className="absolute left-0 top-0 h-[60px] md:h-[116px] w-[60px] md:w-[116px]"
                      width="106"
                      height="106"
                      viewBox="0 0 106 106"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <circle
                        cx="53"
                        cy="53"
                        r="53"
                        fill="#1A1B25"
                        fillOpacity="0.5"
                      />
                      <path
                        d="M53.3125 1C24.4212 1 1 24.421 1 53.3125C1 82.204 24.4212 105.625 53.3125 105.625C82.2038 105.625 105.625 82.2038 105.625 53.3125C105.625 24.4212 82.204 1 53.3125 1ZM80.7572 25.8678C94.5626 39.6733 95.5228 60.774 85.1187 75.5725L31.0523 21.5065C45.8609 11.0955 66.9593 12.0702 80.7572 25.8678ZM25.8678 80.7572C12.0624 66.9517 11.1022 45.851 21.5063 31.0525L75.5727 85.1185C60.7643 95.5295 39.6657 94.555 25.8678 80.7572Z"
                        fill="#EE2020"
                        fillOpacity="0.8"
                      />
                    </svg>
                  </div>
                </div>
                <div
                  className={
                    "w-[48px] h-[48px] flex flex-row items-center justify-center rounded-full secondary-bg shadow-default absolute bottom-0 left-1/2 -translate-x-1/2 z-10"
                  }
                >
                  <svg
                    width="48"
                    height="48"
                    viewBox="0 0 48 48"
                    fill="none"
                    className="mb-[-8px]"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g filter="url(#filter0_d_8036_41900)">
                      <circle cx="24" cy="20" r="20" fill="#2E2F45" />
                      <circle cx="24" cy="20" r="19.5" stroke="#C2D24B" />
                    </g>
                    <path
                      d="M24.9873 8.56752L35.8473 27.2973C35.9473 27.4699 36 27.6656 36 27.8649C36 28.0641 35.9473 28.2599 35.8473 28.4324C35.7472 28.605 35.6033 28.7483 35.43 28.8479C35.2567 28.9475 35.0601 29 34.86 29H13.14C12.9399 29 12.7433 28.9475 12.57 28.8479C12.3967 28.7483 12.2528 28.605 12.1527 28.4324C12.0527 28.2599 12 28.0641 12 27.8649C12 27.6656 12.0527 27.4699 12.1527 27.2973L23.0127 8.56752C23.1128 8.39497 23.2567 8.25168 23.43 8.15206C23.6033 8.05245 23.7999 8 24 8C24.2001 8 24.3967 8.05245 24.57 8.15206C24.7433 8.25168 24.8872 8.39497 24.9873 8.56752ZM22.86 23.3243V25.5946H25.14V23.3243H22.86ZM22.86 15.3783V21.054H25.14V15.3783H22.86Z"
                      fill="#C2D24B"
                    />
                    <defs>
                      <filter
                        id="filter0_d_8036_41900"
                        x="0"
                        y="0"
                        width="48"
                        height="48"
                        filterUnits="userSpaceOnUse"
                        color-interpolation-filters="sRGB"
                      >
                        <feFlood
                          flood-opacity="0"
                          result="BackgroundImageFix"
                        />
                        <feColorMatrix
                          in="SourceAlpha"
                          type="matrix"
                          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                          result="hardAlpha"
                        />
                        <feOffset dy="4" />
                        <feGaussianBlur stdDeviation="2" />
                        <feComposite in2="hardAlpha" operator="out" />
                        <feColorMatrix
                          type="matrix"
                          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
                        />
                        <feBlend
                          mode="normal"
                          in2="BackgroundImageFix"
                          result="effect1_dropShadow_8036_41900"
                        />
                        <feBlend
                          mode="normal"
                          in="SourceGraphic"
                          in2="effect1_dropShadow_8036_41900"
                          result="shape"
                        />
                      </filter>
                    </defs>
                  </svg>
                </div>
                <div
                  className={
                    "absolute top-0  left-32 secondary-bg shadow-default rounded-full p-1.5"
                  }
                >
                  <img
                    src={userImage}
                    className={
                      "rounded-full h-[60px] md:h-[116px] w-[60px] md:w-[116px]"
                    }
                    alt={"profile"}
                  />
                </div>
              </div>
            </div>

            <p className={"max-w-[464px] mb-[20px] secondary-text"}>
              {formatMessage({
                id: `Unfortunately, the creator has opted to restrict access to this AI Personality or Character in your geographical location. `,
              })}
            </p>
            <a href="https://help.kamoto.ai/en/articles/8340240-understanding-geographic-restrictions-on-ai-personalities" target="_blank" className={""}>
              <button
                // onClick={() => setOpenSubscribeModal(true)}
                className={
                  "main-bg w-full max-w-[200px] py-[16px] text-[13px] md:text-[14px] leading-5 text-black font-semibold rounded"
                }
                // onClick={() => setStartChat(true)}
              >
                {formatMessage({ id: "Know More" })}
              </button>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GeoPersonality;
