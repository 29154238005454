import { get, isEqual, map } from "lodash";
import React, { useState } from "react";
import DateFilter from "../components/date-filter";
import LineChart from "../components/line-chart";
import Select from "../../../components/select/select";
import { useIntl } from "react-intl";
import { ToolTipUI } from "../../widgets/components/UI/ToolTipUI";
import LineGraph from "../components/sales-revenue/line-graph";
import Map from "../../dashboard/components/chart/Map";
import BarChartCountry from "../../dashboard/components/bar-chart";
import BarChart from "../components/bar-chart";
import GaugeChart from "../components/gauge-chart";
import { toAbsoluteUrl } from "../../../../_metronic/helpers";
import clsx from "clsx";

const SalesAndRevenue = () => {
  const { formatMessage } = useIntl();
  const [isMRR, setMRR] = useState(true);
  const topPerformance = [
    {
      id: 1,
      name: "Shahrukh Khan",
      owner: "Toshendra Sharma",
      totalVisitors: "2,000,000",
      followers: "200k",
      likes: "90k",
    },
    {
      id: 2,
      name: "Shahrukh Khan",
      owner: "Toshendra Sharma",
      totalVisitors: "2,000,000",
      followers: "200k",
      likes: "90k",
    },
    {
      id: 3,
      name: "Shahrukh Khan",
      owner: "Toshendra Sharma",
      totalVisitors: "2,000,000",
      followers: "200k",
      likes: "90k",
    },
    {
      id: 4,
      name: "Shahrukh Khan",
      owner: "Toshendra Sharma",
      totalVisitors: "2,000,000",
      followers: "200k",
      likes: "90k",
    },
    {
      id: 5,
      name: "Shahrukh Khan",
      owner: "Toshendra Sharma",
      totalVisitors: "2,000,000",
      followers: "200k",
      likes: "90k",
    },
    {
      id: 6,
      name: "Shahrukh Khan",
      owner: "Toshendra Sharma",
      totalVisitors: "2,000,000",
      followers: "200k",
      likes: "90k",
    },
    {
      id: 7,
      name: "Shahrukh Khan",
      owner: "Toshendra Sharma",
      totalVisitors: "2,000,000",
      followers: "200k",
      likes: "90k",
    },
    {
      id: 8,
      name: "Shahrukh Khan",
      owner: "Toshendra Sharma",
      totalVisitors: "2,000,000",
      followers: "200k",
      likes: "90k",
    },
    {
      id: 9,
      name: "Shahrukh Khan",
      owner: "Toshendra Sharma",
      totalVisitors: "2,000,000",
      followers: "200k",
      likes: "90k",
    },
    {
      id: 10,
      name: "Shahrukh Khan",
      owner: "Toshendra Sharma",
      totalVisitors: "2,000,000",
      followers: "200k",
      likes: "90k",
    },
    {
      id: 3,
      name: "Shahrukh Khan",
      owner: "Toshendra Sharma",
      totalVisitors: "2,000,000",
      followers: "200k",
      likes: "90k",
    },
    {
      id: 4,
      name: "Shahrukh Khan",
      owner: "Toshendra Sharma",
      totalVisitors: "2,000,000",
      followers: "200k",
      likes: "90k",
    },
    {
      id: 5,
      name: "Shahrukh Khan",
      owner: "Toshendra Sharma",
      totalVisitors: "2,000,000",
      followers: "200k",
      likes: "90k",
    },
    {
      id: 6,
      name: "Shahrukh Khan",
      owner: "Toshendra Sharma",
      totalVisitors: "2,000,000",
      followers: "200k",
      likes: "90k",
    },
    {
      id: 7,
      name: "Shahrukh Khan",
      owner: "Toshendra Sharma",
      totalVisitors: "2,000,000",
      followers: "200k",
      likes: "90k",
    },
    {
      id: 8,
      name: "Shahrukh Khan",
      owner: "Toshendra Sharma",
      totalVisitors: "2,000,000",
      followers: "200k",
      likes: "90k",
    },
    {
      id: 9,
      name: "Shahrukh Khan",
      owner: "Toshendra Sharma",
      totalVisitors: "2,000,000",
      followers: "200k",
      likes: "90k",
    },
    {
      id: 10,
      name: "Shahrukh Khan",
      owner: "Toshendra Sharma",
      totalVisitors: "2,000,000",
      followers: "200k",
      likes: "90k",
    },
    {
      id: 3,
      name: "Shahrukh Khan",
      owner: "Toshendra Sharma",
      totalVisitors: "2,000,000",
      followers: "200k",
      likes: "90k",
    },
    {
      id: 4,
      name: "Shahrukh Khan",
      owner: "Toshendra Sharma",
      totalVisitors: "2,000,000",
      followers: "200k",
      likes: "90k",
    },
    {
      id: 5,
      name: "Shahrukh Khan",
      owner: "Toshendra Sharma",
      totalVisitors: "2,000,000",
      followers: "200k",
      likes: "90k",
    },
    {
      id: 6,
      name: "Shahrukh Khan",
      owner: "Toshendra Sharma",
      totalVisitors: "2,000,000",
      followers: "200k",
      likes: "90k",
    },
    {
      id: 7,
      name: "Shahrukh Khan",
      owner: "Toshendra Sharma",
      totalVisitors: "2,000,000",
      followers: "200k",
      likes: "90k",
    },
    {
      id: 8,
      name: "Shahrukh Khan",
      owner: "Toshendra Sharma",
      totalVisitors: "2,000,000",
      followers: "200k",
      likes: "90k",
    },
    {
      id: 9,
      name: "Shahrukh Khan",
      owner: "Toshendra Sharma",
      totalVisitors: "2,000,000",
      followers: "200k",
      likes: "90k",
    },
    {
      id: 10,
      name: "Shahrukh Khan",
      owner: "Toshendra Sharma",
      totalVisitors: "2,000,000",
      followers: "200k",
      likes: "90k",
    },
    {
      id: 3,
      name: "Shahrukh Khan",
      owner: "Toshendra Sharma",
      totalVisitors: "2,000,000",
      followers: "200k",
      likes: "90k",
    },
    {
      id: 4,
      name: "Shahrukh Khan",
      owner: "Toshendra Sharma",
      totalVisitors: "2,000,000",
      followers: "200k",
      likes: "90k",
    },
    {
      id: 5,
      name: "Shahrukh Khan",
      owner: "Toshendra Sharma",
      totalVisitors: "2,000,000",
      followers: "200k",
      likes: "90k",
    },
    {
      id: 6,
      name: "Shahrukh Khan",
      owner: "Toshendra Sharma",
      totalVisitors: "2,000,000",
      followers: "200k",
      likes: "90k",
    },
    {
      id: 7,
      name: "Shahrukh Khan",
      owner: "Toshendra Sharma",
      totalVisitors: "2,000,000",
      followers: "200k",
      likes: "90k",
    },
    {
      id: 8,
      name: "Shahrukh Khan",
      owner: "Toshendra Sharma",
      totalVisitors: "2,000,000",
      followers: "200k",
      likes: "90k",
    },
    {
      id: 9,
      name: "Shahrukh Khan",
      owner: "Toshendra Sharma",
      totalVisitors: "2,000,000",
      followers: "200k",
      likes: "90k",
    },
    {
      id: 10,
      name: "Shahrukh Khan",
      owner: "Toshendra Sharma",
      totalVisitors: "2,000,000",
      followers: "200k",
      likes: "90k",
    },
  ];

  const lineChart = [
    {
      heading: "Total Posts",
      count: "3000",
      increase: false,
      description: "12,58,666 Last Month",
      linkTitle: "See All Posts",
    },
    {
      heading: "Total Posts",
      count: "3000",
      increase: "800",
      description: "12,58,666 Last Month",
      linkTitle: "See All Posts",
    },
    {
      heading: "Total Likes",
      count: "36,36,369,000",
      increase: false,
      description: "12,58,666 Last Month",
      linkTitle: "See All Posts",
    },
    {
      heading: "Shares",
      count: "36,000",
      increase: 60,
      description: "12,58,666 Last Month",
      linkTitle: "See All Posts",
    },
  ];

  return (
    <div className={"pb-10"}>
      <div className="flex justify-end">
        <DateFilter />
      </div>
      <div className={"grid grid-cols-12 gap-4 mt-[16px] mb-[24px]"}>
        {map(lineChart, (item: any) => (
          <div className={"col-span-12 md:col-span-6 xl:col-span-3"}>
            <LineChart
              heading={get(item, "heading")}
              count={get(item, "count")}
              description={get(item, "description")}
              tooltipText={"Test"}
              linkText={get(item, "linkTitle")}
              increase={get(item, "increase")}
            />
          </div>
        ))}
      </div>

      <div
        className={
          "grid grid-cols-12 xl:grid-rows-3 md:gap-x-[22px] gap-y-5 my-5"
        }
      >
        <div
          className={
            "col-span-12 xl:col-span-6 row-span-2  p-5 rounded chart-card"
          }
        >
          <div className={"flex flex-col gap-y-10 h-full"}>
            <div>
              <div
                className={
                  "flex flex-col md:flex-row  md:items-start justify-between gap-y-2 mb-5"
                }
              >
                <div>
                  <h3 className="flex items-center  text-[14px] leading-[20px] primary-text mb-2">
                    {formatMessage({ id: "Total MRR" })}
                    <ToolTipUI
                      tooltipText={formatMessage({
                        id: "Total MRR",
                      })}
                    />
                  </h3>
                  <h3 className={"text-[24px] primary-text font-semibold mb-4"}>
                    $30,65,88,625
                  </h3>
                </div>
                <div className={"flex items-center gap-x-2"}>
                  <div
                    className={
                      "flex  main-border rounded-[7px] primary-text font-medium"
                    }
                  >
                    <button
                      className={clsx(
                        "px-4 py-3.5 rounded-[7px]",
                        isMRR && "main-button"
                      )}
                      onClick={() => setMRR(true)}
                    >
                      MRR
                    </button>
                    <button
                      className={clsx(
                        "px-4 py-3.5 rounded-[7px]",
                        !isMRR && "main-button"
                      )}
                      onClick={() => setMRR(false)}
                    >
                      ARR
                    </button>
                  </div>
                  <Select
                    options={[]}
                    label={false}
                    className={"event-select min-w-[200px] !w-full text-[14px]"}
                    placeHolder={formatMessage({ id: "Select" })}
                  />
                </div>
              </div>
              <div>
                <div className={"flex justify-between max-w-md"}>
                  <div>
                    <h3 className="flex items-center  text-[14px] leading-[20px] primary-text  mb-2">
                      {formatMessage({ id: "Expansion MRR" })}
                      <ToolTipUI
                        tooltipText={formatMessage({
                          id: "Expansion MRR",
                        })}
                      />
                    </h3>
                    <h3
                      className={"text-[24px] primary-text font-semibold mb-1"}
                    >
                      $30,65,88,625
                    </h3>
                  </div>
                  <div>
                    <h3 className="flex items-center  text-[14px] leading-[20px] primary-text  mb-2">
                      {formatMessage({ id: "MRR Net Grow" })}
                      <ToolTipUI
                        tooltipText={formatMessage({
                          id: "MRR Net Grow",
                        })}
                      />
                    </h3>
                    <h3
                      className={"text-[24px] primary-text font-semibold mb-1"}
                    >
                      60%
                    </h3>
                  </div>
                </div>
              </div>
            </div>
              <div className={"flex-grow min-h-[300px]"}>
                <LineGraph />
              </div>
          </div>
        </div>
        <div
          className={
            "col-span-12 xl:col-span-6 row-span-3 p-5 rounded chart-card"
          }
        >
          <div
            className={
              "flex flex-col md:flex-row  md:items-center justify-between gap-y-2 mb-5"
            }
          >
            <h3 className={"text-[20px] font-semibold primary-text"}>
              Revenue From Personalities
            </h3>
            <div className={"flex items-center gap-x-2"}>
              <Select
                options={[]}
                label={false}
                className={"event-select min-w-[200px] !w-full text-[14px]"}
                placeHolder={formatMessage({ id: "Select" })}
              />
            </div>
          </div>
          <div
            className={
              "h-[450px] xl:h-[750px] overflow-y-auto custom-scroll pr-5"
            }
          >
            <table className={"table table-auto"}>
              <tbody>
                {map(topPerformance, (item) => (
                  <tr>
                    <td>
                      <div className={"flex items-center gap-x-2"}>
                        <img
                          alt="Pic"
                          src={toAbsoluteUrl(`/media/avatars/300-1.jpg`)}
                          className={"w-[50px] h-[50px] rounded"}
                        />
                        <div className={"flex flex-col gap-y-1"}>
                          <p className="leading-5 font-semibold text-[14px] primary-text">
                            {get(item, "name")}
                          </p>
                          <p className="text-[12px] secondary-text font-medium">
                            Owner: {get(item, "owner")}
                          </p>
                        </div>
                      </div>
                    </td>
                    <td>
                      <div className={"flex flex-col gap-y-1"}>
                        <p className="leading-5 font-semibold text-[14px] primary-text flex gap-x-1">
                          $36k
                          <span className="text-[10px] text-[#3DD883] flex items-center gap-x-1">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="14"
                              height="14"
                              viewBox="0 0 14 14"
                              fill="none"
                            >
                              <path
                                opacity="0.5"
                                d="M7.5332 9.98475V4.31706C7.5332 4.0325 7.29435 3.80182 6.99972 3.80182C6.70508 3.80182 6.46624 4.0325 6.46624 4.31706V9.98475C6.46624 10.2693 6.70508 10.5 6.99972 10.5C7.29435 10.5 7.5332 10.2693 7.5332 9.98475Z"
                                fill="#3DD883"
                              />
                              <path
                                d="M7.30179 5.12378L9.53405 7.27972C9.75502 7.49314 10.1133 7.49314 10.3343 7.27972C10.5552 7.06631 10.5552 6.72027 10.3343 6.50686L7.37723 3.65091C7.1689 3.4497 6.8311 3.4497 6.62278 3.65091L3.66573 6.50686C3.44476 6.72027 3.44476 7.06631 3.66573 7.27972C3.8867 7.49314 4.24498 7.49314 4.46596 7.27972L6.69821 5.12378C6.86487 4.96281 7.13513 4.96281 7.30179 5.12378Z"
                                fill="#3DD883"
                              />
                            </svg>
                            2.6%
                          </span>
                        </p>
                        <p className="text-[12px] secondary-text font-medium">
                          Revenue
                        </p>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
        <div
          className={
            "col-span-12 md:col-span-6 xl:col-span-3 chart-card p-5 rounded"
          }
        >

          <div className={"h-60"}>
            <GaugeChart />
          </div>
          <div className={"text-center"}>
            <span className={"text-[12px] secondary-text"}>
              Avg. for view: 60.009 %{" "}
            </span>
          </div>
        </div>
        <div
          className={
            "col-span-12 md:col-span-6 xl:col-span-3 chart-card p-5 rounded"
          }
        >

          <div className={"h-60"}>
            <GaugeChart />
          </div>
          <div className={"text-center"}>
            <span className={"text-[12px] secondary-text"}>
              Avg. for view: 60.009 %{" "}
            </span>
          </div>
        </div>
      </div>
      <div
        className={
          "grid grid-cols-12  chart-card  shadow-default rounded p-[16px] md:p-[20px] mb-5"
        }
      >
        <div
          className={
            "col-span-12 md:col-span-6 border-main md:border-r pr-[12px]"
          }
        >
          <h4
            className={
              "flex justify-between secondary-text items-center mb-8 text-[16px] leading-6 font-semibold"
            }
          >
            {formatMessage({
              id: "Countries",
            })}
            <span
              className={
                "flex items-center  gap-2 main-text font-medium text-[12px]"
              }
            >
              {formatMessage({
                id: "Earning & Reports",
              })}

              <svg
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  opacity="0.3"
                  d="M4.00065 7.33268C3.63246 7.33268 3.33398 7.63116 3.33398 7.99935C3.33398 8.36754 3.63246 8.66602 4.00065 8.66602H12.0007C12.3688 8.66602 12.6673 8.36754 12.6673 7.99935C12.6673 7.63116 12.3688 7.33268 12.0007 7.33268H4.00065Z"
                  fill="#C2D24B"
                />
                <path
                  d="M7.52794 11.5279C7.26759 11.7883 7.26759 12.2104 7.52794 12.4708C7.78829 12.7311 8.2104 12.7311 8.47075 12.4708L12.4708 8.47075C12.7231 8.21837 12.732 7.81198 12.4908 7.54887L8.82412 3.54887C8.57532 3.27746 8.15361 3.25912 7.8822 3.50791C7.61079 3.75671 7.59245 4.17842 7.84125 4.44983L11.0766 7.9793L7.52794 11.5279Z"
                  fill="#C2D24B"
                />
              </svg>
            </span>
          </h4>
          {/* <Map /> */}
        </div>
        <div
          className={
            "col-span-12 md:col-span-6 pt-[24px] md:pt-0  md:pr-[4px] md:pl-[32px]"
          }
        >
          <h4
            className={
              "flex justify-between secondary-text items-center mb-8 text-[16px] leading-6 font-semibold"
            }
          >
            {formatMessage({
              id: "Revenue",
            })}
          </h4>
          <div className={"-mt-10"}>
            <BarChartCountry
              className={""}
              categories={[
                "South Korea",
                "Canada",
                "United Kingdom",
                "Netherlands",
                "Italy",
                "France",
                "Japan",
                "United States",
                "China",
                "Germany",
              ]}
            />
          </div>
        </div>
      </div>
      <h3 className="text-[28px] font-semibold primary-text">Infra bills</h3>
      <div
        className={
          "grid grid-cols-12  md:gap-x-[22px] gap-y-[14px] md:gap-y-5 my-5"
        }
      >
        <div
          className={
            "col-span-12 md:col-span-3 p-5 rounded chart-card"
          }
        >
          <div className={"flex flex-wrap gap-y-1 justify-between items-center mb-2"}>
            <h3 className="flex items-center  text-[14px] leading-[20px] primary-text min-w-[100px]">
              {formatMessage({ id: "AWS" })}
              <ToolTipUI
                tooltipText={formatMessage({
                  id: "AWS",
                })}
              />
            </h3>
            <Select
              options={[{ value: "quater", label: "Quaterly" }]}
              defaultValue={{ value: "quater", label: "Quaterly" }}
              label={false}
              height={36}
              className={"event-select min-w-[150px] !w-full text-[14px]"}
              placeHolder={formatMessage({ id: "Select" })}
            />
          </div>
          <h3 className={"text-[24px] primary-text font-semibold mb-1"}>
            $300
          </h3>
          <p className="text-[14px] secondary-text font-normal">
            $200 Last Six Month
          </p>
          <div className={"h-40"}>
            <BarChart />
          </div>
        </div>
        <div
          className={
            "col-span-12 md:col-span-3 p-5 rounded chart-card"
          }
        >
          <div className={"flex flex-wrap gap-y-1 justify-between items-center mb-2"}>
            <h3 className="flex items-center  text-[14px] leading-[20px] primary-text min-w-[100px]">
              {formatMessage({ id: "AWS polly" })}
              <ToolTipUI
                tooltipText={formatMessage({
                  id: "AWS polly",
                })}
              />
            </h3>
            <Select
              options={[{ value: "quater", label: "Quaterly" }]}
              defaultValue={{ value: "quater", label: "Quaterly" }}
              label={false}
              height={36}
              className={"event-select min-w-[150px] !w-full text-[14px]"}
              placeHolder={formatMessage({ id: "Select" })}
            />
          </div>
          <h3 className={"text-[24px] primary-text font-semibold mb-1"}>
            $300
          </h3>
          <p className="text-[14px] secondary-text font-normal">
            $200 Last Six Month
          </p>
          <div className={"h-40"}>
            <BarChart quater={true} />
          </div>
        </div>
        <div
          className={
            "col-span-12 md:col-span-3 p-5 rounded chart-card"
          }
        >
          <div className={"flex flex-wrap gap-y-1 justify-between items-center mb-2"}>
            <h3 className="flex items-center  text-[14px] leading-[20px] primary-text min-w-[100px]">
              {formatMessage({ id: "Chat GPT" })}
              <ToolTipUI
                tooltipText={formatMessage({
                  id: "Chat GPT",
                })}
              />
            </h3>
            <Select
              options={[{ value: "quater", label: "Quaterly" }]}
              defaultValue={{ value: "quater", label: "Quaterly" }}
              label={false}
              height={36}
              className={"event-select min-w-[150px] !w-full text-[14px]"}
              placeHolder={formatMessage({ id: "Select" })}
            />
          </div>
          <h3 className={"text-[24px] primary-text font-semibold mb-1"}>
            $300
          </h3>
          <p className="text-[14px] secondary-text font-normal">
            $200 Last Six Month
          </p>
          <div className={"h-40"}>
            <BarChart quater={true} />
          </div>
        </div>
        <div
          className={
            "col-span-12 md:col-span-3 p-5 rounded chart-card"
          }
        >
          <div className={"flex flex-wrap gap-y-1 justify-between items-center mb-2"}>
            <h3 className="flex items-center  text-[14px] leading-[20px] primary-text min-w-[100px]">
              {formatMessage({ id: "Bard" })}
              <ToolTipUI
                tooltipText={formatMessage({
                  id: "Bard",
                })}
              />
            </h3>
            <Select
              options={[{ value: "quater", label: "Quaterly" }]}
              defaultValue={{ value: "quater", label: "Quaterly" }}
              label={false}
              height={36}
              className={"event-select min-w-[150px] !w-full text-[14px]"}
              placeHolder={formatMessage({ id: "Select" })}
            />
          </div>
          <h3 className={"text-[24px] primary-text font-semibold mb-1"}>
            $300
          </h3>
          <p className="text-[14px] secondary-text font-normal">
            $200 Last Six Month
          </p>
          <div className={"h-40"}>
            <BarChart quater={true} />
          </div>
        </div>
        <div
            className={
              "col-span-12 md:col-span-3 p-5 rounded chart-card"
            }
        >
          <div className={"flex flex-wrap gap-y-1 justify-between items-center mb-2"}>
            <h3 className="flex items-center  text-[14px] leading-[20px] primary-text min-w-[100px]">
              {formatMessage({ id: "Elevenlabs" })}
              <ToolTipUI
                tooltipText={formatMessage({
                  id: "Elevenlabs",
                })}
              />
            </h3>
            <Select
              options={[{ value: "quater", label: "Quaterly" }]}
              defaultValue={{ value: "quater", label: "Quaterly" }}
              label={false}
              height={36}
              className={"event-select min-w-[150px] !w-full text-[14px]"}
              placeHolder={formatMessage({ id: "Select" })}
            />
          </div>
          <h3 className={"text-[24px] primary-text font-semibold mb-1"}>
            $300
          </h3>
          <p className="text-[14px] secondary-text font-normal">
            $200 Last Six Month
          </p>
          <div className={"h-40"}>
            <BarChart quater={true} />
          </div>
        </div>
        <div
            className={
              "col-span-12 md:col-span-3 p-5 rounded chart-card"
            }
        >
          <div className={"flex flex-wrap gap-y-1 justify-between items-center mb-2"}>
            <h3 className="flex items-center  text-[14px] leading-[20px] primary-text min-w-[100px]">
              {formatMessage({ id: "Intercom" })}
              <ToolTipUI
                tooltipText={formatMessage({
                  id: "Intercom",
                })}
              />
            </h3>
            <Select
              options={[{ value: "quater", label: "Quaterly" }]}
              defaultValue={{ value: "quater", label: "Quaterly" }}
              label={false}
              height={36}
              className={"event-select min-w-[150px] !w-full text-[14px]"}
              placeHolder={formatMessage({ id: "Select" })}
            />
          </div>
          <h3 className={"text-[24px] primary-text font-semibold mb-1"}>
            $300
          </h3>
          <p className="text-[14px] secondary-text font-normal">
            $200 Last Six Month
          </p>
          <div className={"h-40"}>
            <BarChart quater={true} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default SalesAndRevenue;
