import { FC, Suspense, lazy, useEffect, useState } from "react";
import { Route, Routes, Navigate } from "react-router-dom";
import { MasterLayout } from "../../_metronic/layout/MasterLayout";
import TopBarProgress from "react-topbar-progress-indicator";

import { getCSSVariableValue } from "../../_metronic/assets/ts/_utils";
import { WithChildren } from "../../_metronic/helpers";

import { OnboardingRoutes } from "../modules/onboarding";
import { useAuth } from "../modules/auth";
import { ToolbarWrapper } from "../../_metronic/layout/components/toolbar";

import { InvestorCrmRoutes } from "../modules/investor-crm/InvestorCrmRoutes";
import { ErrorsPage } from "../modules/errors/ErrorsPage";
import DataRoomsRoutes from "../modules/data-rooms/DataRoomsRoutes";
import { DashboardPage } from "../modules/dashboard/DashboardPage";
import { AiPersonalityPage } from "../modules/ai-personality/AiPersonalityPage";
import SupportPage from "../modules/support";
import Sitemap from "../modules/sitemap";
import ChatCreditsPage from "../modules/chat-credits";
import MarketplacePublicLayout from "../../_metronic/layout/marketplace-public-layout";
import MarketPlacePublicRoute from "../modules/marketplace-public/MarketPlacePublicRoute";
import TrainAiRouting from "../modules/ai-traning/TrainAiRouting";
import MyFeedsPage from "../modules/my-feed/page";
import APIsPage from "../modules/apis";
import BillingPage from "../modules/billing";
import ProfilePage from "../modules/marketplace-public/pages/ai-profile";
import UserProfilePage from "../modules/marketplace-public/pages/ai-user-profile";
import MarketplaceChatPage from  "../modules/marketplace-public/pages/marketplace-chat";
import EditAiPersonalityRouting from "../modules/edit-ai-personality/EditAiPersonalityRouting";
import EngageNShareRouting from "../modules/engage-n-share/EngageNShareRouting";
import DashboardInternalPage from "../modules/dashboard-internal/DashboardInternalPage";
import CallLayout from "../../_metronic/layout/CallLayout";
import CallDisconnectPage from "../modules/call/pages/disconnect";
import AddCreditPage from "../modules/call/pages/add-credit";
import MatchedPage from "../modules/call/pages/matched";
import RingingPage from "../modules/call/pages/ringing";
import SpeakingPage from "../modules/call/pages/speaking";
const InvestorDBRoutes = lazy(
  () => import("../modules//investor-database/InvestorDBRoutes")
);
const MarketplaceRouting = lazy(
  () => import("../modules/marketplace/MarketplaceRouting")
);
const PrivateRoutes = () => {
  const { currentUser, storePersonalityId, personalityId, newPersonality, setSelectedPersonality } =
    useAuth();
  const [ready, setReady] = useState(false);

  useEffect(() => {
    console.log(`private routes ready ${personalityId} ${currentUser}`)
    if (!personalityId) {
      storePersonalityId(
        !!localStorage.getItem("personalityId")
          ? localStorage.getItem("personalityId")
          : (currentUser?.personality?.[0]?.personalityId || currentUser?.personalities?.[0]?.personalityId)
      );
    }
    if (currentUser) {
      console.log(`private routes ready`)
      setReady(true);
    }
    if (personalityId) {
      setSelectedPersonality(currentUser?.personality?.find((el: any) => { return el.personalityId == personalityId }))
    }
  }, [JSON.stringify(currentUser), personalityId]); // eslint-disable-line
  return ready ? (
    <Routes>
      {/* Redirect to Dashboard after success login/registartion */}
      {newPersonality ?? personalityId ? (
        <>
          <Route index element={<Navigate to="/marketplace" />} />
          <Route element={<MasterLayout />}>
            <Route path="dashboard" element={<DashboardPage />} />
            <Route path="dashboard-internal" element={<DashboardInternalPage />} />
            <Route path="my-ai/*" element={<AiPersonalityPage />} />
            <Route path="investor-crm/*" element={<InvestorCrmRoutes />} />
            <Route
              path="user-settings"
              element={
                <SuspensedView>
                  <ToolbarWrapper />
                </SuspensedView>
              }
            />
            <Route
              path={"/train-my-ai/*"}
              element={
                <SuspensedView>
                  <TrainAiRouting />
                </SuspensedView>
              }
            />
            <Route
              path={"/edit-ai-personality/*"}
              element={
                <SuspensedView>
                  <EditAiPersonalityRouting/>
                </SuspensedView>
              }
            />
            <Route
              path={"/communicate-engage/*"}
              element={
                <SuspensedView>
                  <EngageNShareRouting/>
                </SuspensedView>
              }
            />
            <Route
              path="monetize-distribute/*"
              element={
                <SuspensedView>
                  <MarketplaceRouting />
                </SuspensedView>
              }
            />
            <Route
              path="apis"
              element={
                <SuspensedView>
                  <APIsPage />
                </SuspensedView>
              }
            />
             <Route
              path="billing"
              element={
                <SuspensedView>
                  <BillingPage />
                </SuspensedView>
              }
            />
            <Route
              path="data-rooms/*"
              element={
                <SuspensedView>
                  <DataRoomsRoutes />
                </SuspensedView>
              }
            />
            <Route
              path="investor-database/*"
              element={
                <SuspensedView>
                  <InvestorDBRoutes />
                </SuspensedView>
              }
            />
            <Route
              path="chat-credits"
              element={
                <SuspensedView>
                  <ChatCreditsPage />
                </SuspensedView>
              }
            />
            <Route
              path="support"
              element={
                <SuspensedView>
                  <SupportPage />
                </SuspensedView>
              }
            />
            <Route
              path="my-feed"
              element={
                <SuspensedView>
                  <MyFeedsPage />
                </SuspensedView>
              }
            />
            <Route path="/sitemap/*" element={<Sitemap />} />
            <Route path="*" element={<ErrorsPage />} />
          </Route>
          <Route element={<MarketplacePublicLayout/>}>
          <Route path={"my-chats"} element={<MarketplaceChatPage />} />
          </Route>
          <Route
            path={"/marketplace/*"}
            element={
              <SuspensedView>
                <MarketPlacePublicRoute />
              </SuspensedView>
            }
          />

          <Route
              path={"/call/*"}
              element={
                <SuspensedView>
                  <CallLayout />
                </SuspensedView>
              }
          >
            <Route
                path="matched"
                element={
                  <SuspensedView>
                    <MatchedPage />
                  </SuspensedView>
                }
            />
            <Route
                path="disconnect"
                element={
                  <SuspensedView>
                    <CallDisconnectPage />
                  </SuspensedView>
                }
            />
            <Route
                path="add-credit"
                element={
                  <SuspensedView>
                    <AddCreditPage />
                  </SuspensedView>
                }
            />
            <Route
                path="ringing"
                element={
                  <SuspensedView>
                    <RingingPage />
                  </SuspensedView>
                }
            />
            <Route
                path="speaking"
                element={
                  <SuspensedView>
                    <SpeakingPage />
                  </SuspensedView>
                }
            />
            <Route path="*" element={<Navigate to={'matched'} />} />
          </Route>
          <Route element={<MarketplacePublicLayout />}>
            <Route path={"p/:slug"} element={<ProfilePage />} />
            <Route path={"u/:username"} element={<UserProfilePage />} />
          </Route>
          {/* navigate user to dashboard on these routes */}
          <Route path="/auth/login" element={<Navigate to={'/dashboard'} />} />
          <Route path="/auth/registration" element={<Navigate to={'/dashboard'} />} />
          <Route path="/auth/forgot-password" element={<Navigate to={'/dashboard'} />} />
          {/* navigate user to dashboard on these routes */}
        </>
      ) : (
        <>
          <Route index element={<Navigate to="/onboarding/" />} />
          <Route path="/onboarding/*" element={<OnboardingRoutes />} />
          <Route element={<MasterLayout />}></Route>
          <Route path="*" element={<ErrorsPage />} />
        </>
      )}
      {/* Pages */}
    </Routes>
  ) : null;
};

export const SuspensedView: FC<WithChildren> = ({ children }) => {
  const baseColor = getCSSVariableValue("--kt-primary");
  TopBarProgress.config({
    barColors: {
      "0": baseColor,
    },
    barThickness: 1,
    shadowBlur: 5,
  });
  return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>;
};

export { PrivateRoutes };
