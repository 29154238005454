import React from "react";
import Lottie from "lottie-react";
import processingJson from "../../../../app/assets/lotties/processing-animation.json";
import { useIntl } from "react-intl";
export default function PersonalitySwitchModal() {
  const { formatMessage } = useIntl();
  return (
    <div className="fixed w-full h-screen z-10">
      <div className="relative w-full h-full">
        <div className="absolute inset-0 backdrop-blur"></div>
        <div
          className="tab-container shadow-xl block w-full h-auto absolute left-[50%] top-[50%] mx-auto rounded-[4px] max-w-[480px] "
          style={{ transform: "translate(-50%, -50%)" }}
        >
          <div className="perosnality-switch-header primary-text text-[20px] leading-[28px] font-[600] p-[24px_22px_16px] border-b border-main">
            {formatMessage({ id: "Switching AI Personality..." })}
          </div>
          <div className="flex flex-col items-center pt-[32px] pb-[70px]">
            <Lottie
              className=""
              animationData={processingJson}
              style={{
                width: 185,
                height: 145,
                borderRadius: 25,
                // borderTopLeftRadius: 0,
                // overflow: "hidden",
              }}
            />
            <h4 className="primary-text text-[22px] leading-[32px] font-[600]">
              {formatMessage({ id: "Please wait" })}
            </h4>
            <p className="secondary-text text-[13px] leading-[20px] font-[400]">
              {formatMessage({ id: "Page will refresh automatically" })}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
