import React, { useState } from "react";
import Breadcrumb from "./components/breadcrumb";
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
import { useIntl } from "react-intl";
import { useSearchParams } from "react-router-dom";
import { get } from "lodash";
import Referral from "./containers/referral";
import TrafficAndVisitors from "./containers/trafficAndVisitors";
import SalesAndRevenue from "./containers/salesAndRevenue";
import Engagement from "./containers/engagement";
import UsersContainer from "./containers/users-container";
import PersonalitiesContainer from "./containers/personalities-container";

const DashboardInternalPage = () => {
  const { formatMessage } = useIntl();
  const [searchParams, setSearchParams] = useSearchParams();
  const tab = searchParams.get("tab");
  const [breadcrumb, setBreadcrumb] = useState("Users");
  const allTabs = [
    {
      url: "users",
      title: "Users",
    },
    {
      url: "personalities",
      title: "Personalities",
    },
    {
      url: "traffic-visitors",
      title: "Visitors",
    },
    {
      url: "sales-revenue",
      title: "Revenue",
    },
    {
      url: "engagement",
      title: "Engagement",
    },
    {
      url: "referral",
      title: "Referral",
    },
  ];

  return (
    <div>
      <Breadcrumb breadcrumbTitle={breadcrumb} />
      {}
      <Tabs
        className="font-size-13"
        selectedTabClassName="bg-primary"
        // defaultIndex={defaultTab || 0}
        onSelect={(index: number) => {
          setBreadcrumb(get(allTabs[index], "title"));
          setSearchParams({ tab: get(allTabs[index], "url") });
        }}
      >
        <TabList
          className={
            " text-[13px] leading-[20px] font-normal primary-text setting-tabs overflow-x-auto overflow-y-hidden whitespace-nowrap  border-main border-b mb-4"
          }
        >
          <Tab>{formatMessage({ id: "Users" })}</Tab>
          <Tab>{formatMessage({ id: "Personalities" })}</Tab>
          <Tab>{formatMessage({ id: "Traffic & Visitors" })}</Tab>
          <Tab>{formatMessage({ id: "Sales & Revenue" })}</Tab>
          <Tab>{formatMessage({ id: "Engagement" })}</Tab>
          <Tab>{formatMessage({ id: "User Referral" })}</Tab>
        </TabList>
        <TabPanel>
          <UsersContainer />
        </TabPanel>
        <TabPanel>
          <PersonalitiesContainer />
        </TabPanel>
        <TabPanel>
          <TrafficAndVisitors />
        </TabPanel>
        <TabPanel>
          <SalesAndRevenue />
        </TabPanel>
        <TabPanel>
          <Engagement />
        </TabPanel>
        <TabPanel>
          <Referral />
        </TabPanel>
      </Tabs>
    </div>
  );
};

export default DashboardInternalPage;
