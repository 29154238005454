import clsx from "clsx";
import CopyIcon from "../../../../assets/svgs/chat/copy-message.svg";
import ShareIcon from "../../../../assets/svgs/chat/share.svg";
import LikeIcon from "../../../../assets/svgs/chat/like.svg";
import DislikeIcon from "../../../../assets/svgs/chat/dislike.svg";
import LikeFillIcon from "../../../../assets/svgs/chat/like-fill.svg";
import DislikeFillIcon from "../../../../assets/svgs/chat/dislike-fill.svg";
import { useState } from "react";
import { updateChat } from "../../../apps/chat/core/_request";
import { ChatResponseElement } from "../../../apps/chat/core/_models";
import { copyToClipboard } from "../../../../utils";
import React from "react";
import ShareChatModal from "../modals/ShareChatModal";
import useGTM from "../../../../hooks/useGTM";
import { GTMEvent } from "../../../../hooks/gtm_helpers";
export default function ChatResponse({ data }: { data: ChatResponseElement }) {
  const {
    liked,
    personalityImage,
    id,
    contentClass,
    error,
    response,
    customParams,
    setShareChatId,
  } = data;
  const [like, setLike] = useState<number>(liked);
  const {dataLayerPush} = useGTM()
  async function handleLike(value: number) {
    if (like == value) return;
    setLike(value);
    try {
      let resp =await updateChat(id, { liked: value });
      if(resp.status === 200){
        if(value == 1){
          dataLayerPush(GTMEvent.ChatMessageLikeSuccessful)
        }else{
          dataLayerPush(GTMEvent.ChatMessageDislikeSuccessful)
        }
      }else{
        if(value == 1){
          dataLayerPush(GTMEvent.ChatMessageLikeFailed)
        }else{
          dataLayerPush(GTMEvent.ChatMessageDislikeFailed)
        }
      }
    } catch (err) {
      setLike(liked);
      if(value == 1){
        dataLayerPush(GTMEvent.ChatMessageLikeFailed)
      }else{
        dataLayerPush(GTMEvent.ChatMessageDislikeFailed)
      }
    }
  }
  return (
    <>
      <div
        key={`${id}-response`}
        className={clsx(
          "d-flex",
          contentClass,
          "mb-10",
          "justify-content-start",
          // "scrollFade",
          {
            // "d-none": message.template,
          }
        )}
      >
        <div
          className={
            "flex flex-col md:flex-row md:items-start gap-x-[8px] lg:gap-x-8 gap-y-2"
          }
        >
          <div
            className={clsx(
              "flex items-start gap-x-2 rounded px-2 py-3 relative",
              "chat-response "
            )}
          >
            {customParams?.responseEmoji && (
              <span
                className={"text-[20px] absolute right-1 md:-right-3 -bottom-4"}
              >
                {customParams.responseEmoji}
              </span>
            )}
            <div className="flex items-center">
              <>
                <div className="w-[35px] h-[35px] symbol symbol-35px symbol-circle">
                  <img className="w-full object-cover object-top" alt="Pic" src={personalityImage} />
                </div>
              </>
            </div>
            <div
              className={clsx(
                "secondary-text text-[14px] overflow-hidden text-break leading-[22px] font-normal max-w-2xl float-right"
                // `text-${message.type === 'in' ? 'start' : 'end'}`
              )}
              data-kt-element="message-text"
              dangerouslySetInnerHTML={{ __html: response }}
            ></div>
          </div>
          {!error && response && (
            <div
              className={
                "flex items-center gap-[8px] xl:w-auto min-w-[48px] md:w-[48px] flex-wrap md:flex-nowrap "
              }
            >
              <button
                className="w-20px"
                onClick={() =>
                  {
                    dataLayerPush(GTMEvent.AiResponseCopySuccessful)
                    copyToClipboard(
                      response,
                      true,
                      "Response copied successfully"
                    )
                  }
                }
              >
                <img src={CopyIcon} alt="" />
              </button>
              <button className="w-20px" onClick={() => setShareChatId(id)}>
                <img src={ShareIcon} alt="" />
              </button>

              <button className="w-20px">
                <img
                  src={like == 1 ? LikeFillIcon : LikeIcon}
                  onClick={() => handleLike(1)}
                  alt=""
                />
              </button>
              <button className="w-20px">
                <img
                  src={like == -1 ? DislikeFillIcon : DislikeIcon}
                  onClick={() => handleLike(-1)}
                  alt=""
                />
              </button>
            </div>
          )}
        </div>
      </div>
    </>
  );
}
