import React, { useEffect, useRef } from "react";
import { useThemeMode } from "../../../../../_metronic/partials";
import {
  getCSS,
  getCSSVariableValue,
} from "../../../../../_metronic/assets/ts/_utils";
import ApexCharts, { ApexOptions } from "apexcharts";
import { isEqual } from "lodash";

const LineGraphWithData: React.FC<any> = ({ className, compareData }) => {
  const chartRef = useRef<HTMLDivElement | null>(null);
  const { mode } = useThemeMode();
  const refreshMode = () => {
    if (!chartRef.current) {
      return;
    }

    const height = parseInt(getCSS(chartRef.current, "height"));

    const chart = new ApexCharts(
      chartRef.current,
      getChartOptions(height, compareData, mode)
    );
    if (chart) {
      chart.render();
    }

    return chart;
  };

  useEffect(() => {
    const chart = refreshMode();

    return () => {
      if (chart) {
        chart.destroy();
      }
    };
  }, [chartRef, mode]);

  return <div ref={chartRef} style={{ height: "100px", width: "100%" }}></div>;
};

export { LineGraphWithData };

// @ts-ignore
function getChartOptions(
  height: number,
  compareData?: any,
  mode?: string
): ApexOptions {
  const labelColor = getCSSVariableValue("--kt-gray-500");
  const borderColor = getCSSVariableValue("--kt-gray-200");

  return {
    series: [
      {
        name: compareData?.currentTitle || "Current Period",
        data: (compareData?.currentPeriod?.map((el:any) => {return el.value}))?.filter((el:number) => el !== 0)  || [],
      },
    ],

    chart: {
      height: 140,
      type: "line",
      toolbar: {
        show: false,
      },
    },
    dataLabels: {
      enabled: false,
    },
    plotOptions: {
      bar: {
        horizontal: false,
      },
    },
    legend: {
      show: false,
    },
    stroke: {
      show: true,
      width: 2,
      colors: ["#C2D24B"],
    },
    grid: {
      borderColor: borderColor,
      strokeDashArray: 4,
      yaxis: {
        lines: {
          show: false,
        },
      },
    },

    xaxis: {
      categories: compareData?.currentPeriod?.filter((el:any) => el?.value !== 0)?.map((el:any) => {return el.date}) || [],
      type: "category",
      tickPlacement: "between",
      tickAmount: "dataPoints",
      axisBorder: {
        show: true,
        color: isEqual(mode, "light") ? "#dbdcde" : "#2E2F45",
        offsetY: 10,
      },
      axisTicks: {
        show: false,
      },
      labels: {
        show: false,
        style: {
          colors: labelColor,
          fontSize: "12px",
        },
      },
      crosshairs: {
        show: false,
      },
      tooltip: {
        enabled: false,
      },
    },
    yaxis: {
      show: false,
      labels: {
        style: {
          colors: labelColor,
          fontSize: "12px",
        },
      },
    },
  };
}
