import React from "react";
import Header from "../../components/marketplace/header";
import Categories from "../../components/marketplace/categories";
import LeaderBoardListHome from "../../components/marketplace/leaderboard-home";
import AiList from "../../components/marketplace/ai-list";
import SearchWidget from "../marketplace-discovery/search-widget";
import useGTM from "../../../../hooks/useGTM";
import { GTMEvent } from "../../../../hooks/gtm_helpers";
import { useAuth } from "../../../auth";
import { useIntl } from "react-intl";
import TrendingPersonalities from "../../components/marketplace/trendings";

const Index = () => {
  const { formatMessage } = useIntl();
  useGTM(false, [{ event: GTMEvent.MarketplaceHomePageView }]);
  const { ipStackCountry } = useAuth();
  console.log(ipStackCountry);
  if (!ipStackCountry) {
    return <></>;
  }

  return (
    <div>
      <Header />
      <Categories />
      <SearchWidget
        heading={formatMessage({
          id: "Uncover Your Perfect AI Companion from vast selection",
        })}
        preAppliedFilters={{ country: [ipStackCountry?.countryId] }}
        paginationDefault={{ perPage: 10, values: [10, 15, 20] }}
        showViewAllButton={undefined}
        showFilterApplied={false}
      />
      <LeaderBoardListHome />
      <TrendingPersonalities category={'Celebrity'} heading={formatMessage({ id: "Trending in Celebrities" })} />
      <TrendingPersonalities category={'Influencer'} heading={formatMessage({ id: "Trending in Influencer" })} />
      <TrendingPersonalities category={'Fictional Character'} heading={formatMessage({ id: "Trending in Fictional" })} />
      <TrendingPersonalities category={'YouTuber'} heading={formatMessage({ id: "Trending in YouTuber" })} />
      <TrendingPersonalities category={'Sports Personality'} heading={formatMessage({ id: "Trending in Sports Personality" })} />
      <TrendingPersonalities category={'Entrepreneur'} heading={formatMessage({ id: "Trending in Entrepreneur" })} />
    </div>
  );
};

export default Index;
