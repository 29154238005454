import React from "react";
import Container from "../../containers/marketplace";

const Index = () => {
  return (
    <div className={"px-[20px] md:p-[40px] xl:px-20"}>
      <Container />
    </div>
  );
};

export default Index;
