import { ErrorMessage, Field, getIn, useField, useFormikContext } from "formik";
import React from "react";
import { StylesConfig } from "react-select";
import { StateManagerProps } from "react-select/dist/declarations/src/useStateManager";
import clsx from "clsx";
import { ToolTipUI } from "../../components/UI/ToolTipUI";
import Creatable, { useCreatable } from "react-select/creatable";
import { isEqual } from "lodash";
import { useThemeMode } from "../../../../../_metronic/partials";

type MyOption = {
  label: string;
  value: any;
};

type GroupedOption = {
  label: string; // group label
  options: MyOption[];
};

type Props = {
  name: string;
  label: string;
  tooltipText: string;
  isFieldArray: boolean;
  formatOptionLabel?:any;
} & Omit<
  StateManagerProps<MyOption, false | true, GroupedOption>,
  "value" | "onChange"
>;

const FormikReactCreatable: React.FC<any> = (props: Props) => {
  const { name, className, label, tooltipText, isFieldArray,formatOptionLabel, ...restProps } =
    props;
  const [field] = useField(name);
  const { setFieldValue } = useFormikContext();
  const { mode } = useThemeMode();

  const colourStyles: StylesConfig<MyOption, false | true, GroupedOption> = {
    control: (styles, { isFocused }) => ({
      display:"flex",
      borderRadius:'0.475rem',
      height:"40px",
      backgroundColor: isEqual(mode, "light") ? "#F5F8FA" : "#2E2F45",
      borderWidth: isEqual(mode, "light") ? "1px":"0px",
      borderColor: isEqual(mode, "light") ? "#DBDCDE" : "#ffffff1a",
      boxShadow: "none",
      color: isEqual(mode, "light") ? "#383940" : "#FFFFFFCC",
    }),
    menu: (styles) => {
      return {
        ...styles,
        backgroundColor: isEqual(mode, "light") ? "#F5F8FA" : "#2E2F45",
        color: isEqual(mode, "light") ? "#383940" : "#FFFFFFCC",
      };
    },
    option: (styles, { data, isDisabled, isFocused, isSelected, }) => {
      return {
        ...styles,
        // color: isSelected  ,
        // color: isDisabled
        //   ? "#red"
        //   : isSelected
        //   ? isEqual(mode, "light")
        //     ? "#383940"
        //     : "#FFFFFFCC"
        //   : isEqual(mode, "light")
        //   ? "#383940"
        //   : "#FFFFFFCC",
        cursor: isDisabled ? "not-allowed" : "default",
        // ":active": {
        //   ...styles[":active"],
        // },
      };
    },
    input: (styles) => ({ ...styles }),
    placeholder: (styles) => ({ ...styles ,
      color: isEqual(mode, "light") ? "#a1a5b7" : "#FFFFFFA6",}),
    singleValue: (styles, { data }) => ({
      ...styles,
      color: isEqual(mode, "light") ? "#383940" : "#FFFFFFCC",
    }),
    menuList: (styles) => ({
      ...styles,
      color: isEqual(mode, "light") ? "#383940" : "#FFFFFFCC",
    }),
    multiValue: (styles) => {
      return {
        ...styles,
        alignItems: "center",
        backgroundColor: isEqual(mode, "light") ? "#F5F8FA" : "#171825",
        borderRadius: "40px",
        padding: "2px 10px",
        color: isEqual(mode, "light") ? "#383940" : "#FFFFFFCC",
      };
    },
  };

  //flatten the options so that it will be easier to find the value
  const flattenedOptions = props.options?.flatMap((o: any) => {
    const isNotGrouped = "value" in o;
    if (isNotGrouped) {
      return o;
    } else {
      return o.options;
    }
  });

  //get the value using flattenedOptions and field.value
  const value = flattenedOptions?.filter((o: any) => {
    const isArrayValue = Array.isArray(field.value);

    if (isArrayValue) {
      const values = field.value as Array<any>;
      return values.includes(o.value);
    } else {
      return field.value === o.value;
    }
  });

  const FieldArrayErrorMessage = ({ name }: any) => (
    <Field
      name={name}
      render={({ form }: any) => {
        const error = getIn(form.errors, name);
        const touch = getIn(form.touched, name);

        return touch && error ? error : null;
      }}
    />
  );

  return (
    <div className={"mb-[16px] md:mb-[24px]"}>
      <label
        className={
          "flex gap-2 items-center text-[13px] leading-[20px] secondary-text mb-[4px]"
        }
      >
        {label}
        <ToolTipUI tooltipText={tooltipText} />
      </label>
      <Creatable
        {...restProps}
        value={value}
        // onKeyDown={(event) => {
        //   if (event.key === 'Enter' || event.key === 'Tab') {
        //     console.log(event)
        //   }
        // }}
        // onChange implementation
        classNames={{
          option: (state) => {
            if(state.isFocused){
              return "!text-[#000] !cursor-pointer"
            }else  if(state.isSelected){
              return "!text-[#000] !cursor-pointer !bg-[#C2D24B]"
            }
            else{
              return  "text-blue-400"
            }
          }
        }}
        formatOptionLabel={formatOptionLabel}
        onChange={(val) => {
          //here I used explicit typing but there maybe a better way to type the value.
          const _val = val as MyOption[] | MyOption;
          const isArray = Array.isArray(_val);
          if (isArray) {
            const values = _val.map((o) => o.value);
            setFieldValue(name, values);
          } else {
            setFieldValue(name, _val.value);
          }
        }}
        className={clsx(className, "react-select ")}
        styles={colourStyles}
        theme={(theme) => ({
          ...theme,
          colors: {
            ...theme.colors,
            primary25: "#C2D24B",
            primary: "#FFFFFF1A",
          },
        })}
      />
      <div className="mt-2 text-danger">
        {isFieldArray ? (
          <FieldArrayErrorMessage name={name} />
        ) : (
          <ErrorMessage name={name} />
        )}
      </div>
    </div>
  );
};

export default FormikReactCreatable;
