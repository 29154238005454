import React from "react"
import { useIntl } from "react-intl";
import { toAbsoluteUrl } from "../../../../_metronic/helpers";
import { useEffect, useState } from "react";
import { useDateFormat } from "../../../hooks/useDateFormat";
import { DateTime } from "luxon";
import { useTimeZone } from "../../../hooks/useTimeZone";
import { acceptPersonalityInvite, deleteReceivedInvite, getReceivedInvites } from "../core/_requests";
import { toast } from "react-toastify";
import { capitalize } from "lodash";
import { Modal } from "react-bootstrap";
import { BasicButton } from "../../widgets/components/UI/BasicButton";
import crossIcon from "../../../../_metronic/assets/images/svg/cross-dual-green-icon.svg";
import Lottie from "lottie-react";
import processingJson from "../../../assets/lotties/processing-animation.json";
import teamsJoined from "../../../assets/images/teams-joined.png";
import teamsNotJoined from "../../../assets/images/team-not-joined.svg";
const ReceivedInvites = () => {
    const { formatMessage } = useIntl();
    const [list, setList] = useState<any[]>([]);
    const { getDateValue } = useDateFormat();
    const { getTimeZoneValue } = useTimeZone();
    const [loading, setLoading] = useState<boolean>(true);
    const [modal, setModal] = useState<string>("")
    const [current, setCurrent] = useState<any>(null)
    const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false)
    const [deleting, setDeleting] = useState<boolean>(false)
    async function getInvites() {
        setLoading(true)
        const { data: { success, data } } = await getReceivedInvites()
        if (success) {
            setList(data)
        } else {
            toast.error('Error while fetching received invites',
            {
              autoClose: false,
              closeButton: true, // Display close button (cross icon)
            })
        }
        setLoading(false)
    }

    useEffect(() => {
        getInvites()
    }, [])

    async function acceptInviteFlow(inviteId: string) {
        setModal('processing')
        const { data: { success, data, errors } } = await acceptPersonalityInvite(inviteId)
        if (success) {
            setModal('success')
        } else {
            setModal('failed')
            toast.error(errors[0],
                {
                  autoClose: false,
                  closeButton: true, // Display close button (cross icon)
                })
        }
    }

    async function deleteInvite() {
        setDeleting(true)
        const { data: { success } } = await deleteReceivedInvite(current.inviteId)
        if (success) {
            toast.success('Deleted successfully')
            getInvites()
            setShowDeleteModal(false)
        } else {
            toast.error('Error while deleting the invite',
            {
              autoClose: false,
              closeButton: true, // Display close button (cross icon)
            })
        }
        setDeleting(false)
    }
    if (loading || !loading && !list.length) {
        return null;
    }
    return (
        <div className="tab-container !px-[0px] !py-[8px] md:!p-[24px] shadow-default rounded mt-8">
            <div className=" card-body">
                <div className="row d-flex">
                    <div className="col-md-9 col-12">
                        <h4 className="text-[16px] leading-[22px] primary-text font-medium  mb-[4px] md:mb-[8px]">
                            {formatMessage({ id: "Invites I have Received" })}
                        </h4>
                        <p className="text-[14px] leading-5 secondary-text font-normal">
                            {formatMessage({ id: "List of Invites received by you to join other Teams to manage their AI Personality" })}
                        </p>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12">
                        <div className="mt-4 table-responsive custom-table">
                            <table className="w-100">
                                <thead>
                                    <tr className="custom-thead font-size-12 leading-[16px] font-weight-400 primary-text">
                                        <td className="pb-4">{formatMessage({ id: "Invited By" })}</td>
                                        <td className="pb-4">
                                            {formatMessage({ id: "Role Invited For" })}
                                        </td>
                                        <td className="pb-4">
                                            {formatMessage({ id: "AI Personality" })}
                                        </td>
                                        <td className="pb-4">
                                            <div className="gap-2 d-flex">
                                                <p>{formatMessage({ id: "Invited On" })}</p>
                                                <img
                                                    src={toAbsoluteUrl(
                                                        "/media/icons/profile/down_arrow.svg"
                                                    )}
                                                    alt=""
                                                    width={10}
                                                    className="mt-2"
                                                    height={7}
                                                />
                                            </div>
                                        </td>
                                        <td className="pb-4 text-center">
                                            {formatMessage({ id: "Action" })}
                                        </td>
                                    </tr>
                                </thead>
                                <tbody className="font-size-13 leading-[20px] font-weight-400 secondary-text">
                                    {
                                        list.map((item: any) => (
                                            <>

                                                <tr className="!h-[40px]" id={item.inviteId}>
                                                    <td className="min-w-175px">{item?.user?.firstName} {item?.user?.lastName}</td>
                                                    <td className=" min-w-250px">{capitalize(item.role)}</td>
                                                    <td className=" min-w-250px">{item?.personality?.name}</td>
                                                    <td className=" min-w-175px">
                                                        {item.createdAt
                                                            ? getDateValue(item.createdAt?.toString())
                                                                ? getDateValue(item.createdAt?.toString())
                                                                : DateTime.fromISO(
                                                                    item.createdAt?.toString()
                                                                ).toLocaleString(DateTime.DATE_MED)
                                                            : "-"}{" "}
                                                        {item.createdAt &&
                                                            getTimeZoneValue(item.createdAt?.toString())}
                                                    </td>
                                                    <td
                                                        className="text-center flex justify-center cursor-pointer main-text min-w-175px"
                                                        onClick={() => {
                                                        }}
                                                    >
                                                        <div className="flex gap-x-2">
                                                            <button
                                                                className={
                                                                    "w-9 h-9 flex items-center justify-center rounded-[6px] bg-[#C2D24B1A]"
                                                                }
                                                                onClick={() => {
                                                                    setCurrent(item)
                                                                    acceptInviteFlow(item.inviteId)
                                                                }}
                                                            >
                                                                <svg width="15" height="11" viewBox="0 0 15 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                    <path d="M1.5 5.5L4.87568 9.5L13.5 1.5" stroke="#C2D24B" strokeWidth="1.6" strokeLinecap="round" strokeLinejoin="round" />
                                                                </svg>
                                                            </button>
                                                            <button
                                                                className={
                                                                    "w-9 h-9 flex items-center justify-center rounded-[6px] bg-[#C2D24B1A]"
                                                                }
                                                                onClick={() => {
                                                                    setCurrent(item)
                                                                    setShowDeleteModal(true);
                                                                }}
                                                            >
                                                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                    <path d="M16.2426 6.34262L6.34309 16.2421C5.95257 16.6326 5.95257 17.2658 6.34309 17.6563C6.73362 18.0468 7.36678 18.0468 7.75731 17.6563L17.6568 7.75683C18.0473 7.36631 18.0473 6.73314 17.6568 6.34262C17.2663 5.95209 16.6331 5.95209 16.2426 6.34262Z" fill="#C2D24B" />
                                                                    <path opacity="0.3" d="M17.6569 16.2433L7.7574 6.34383C7.36688 5.9533 6.73371 5.9533 6.34319 6.34383C5.95266 6.73435 5.95266 7.36751 6.34319 7.75804L16.2427 17.6575C16.6332 18.0481 17.2664 18.0481 17.6569 17.6575C18.0474 17.267 18.0474 16.6338 17.6569 16.2433Z" fill="#C2D24B" />
                                                                </svg>
                                                            </button>
                                                        </div>
                                                    </td>
                                                </tr>
                                            </>
                                        ))}
                                </tbody>

                                <div>
                                    <Modal
                                        show={showDeleteModal}
                                        onHide={() => setShowDeleteModal(false)}
                                        aria-labelledby="contained-modal-title-vcenter"
                                        centered
                                    >
                                        <Modal.Header closeButton>
                                            <h2>{formatMessage({ id: "Delete Received Invites" })}</h2>
                                        </Modal.Header>
                                        <Modal.Body>
                                            <p>
                                                {formatMessage({
                                                    id: "Please note that the delete invite is a permanent action and cannot be undone. As a result, you will no longer be able to accept the invite. Are you certain you wish to proceed with this?",
                                                })}
                                            </p>
                                        </Modal.Body>
                                        <Modal.Footer>
                                            <BasicButton
                                                height="44px"
                                                border="none"
                                                color="#C2D24B1A"
                                                textColor="#C2D24B"
                                                padding="12px 16px"
                                                onClick={() => {
                                                    setShowDeleteModal(false);
                                                }}
                                                buttonText={formatMessage({ id: "Cancel" })}
                                                minWidth={56}
                                            />

                                            <BasicButton
                                                height="44px"
                                                border="1px solid #C2D24B"
                                                color="#C2D24B"
                                                textColor="#000000"
                                                padding="12px 24px"
                                                buttonText={formatMessage({ id: "Confirm Delete" })}
                                                minWidth={56}
                                                loading={deleting}
                                                disabled={deleting}
                                                onClick={deleteInvite}
                                            />
                                        </Modal.Footer>
                                    </Modal>
                                    {/* Processing modal */}
                                    <Modal
                                        backdropClassName="sub-modal-1"
                                        className="sub-modal-1"
                                        aria-labelledby="contained-modal-title-vcenter"
                                        centered
                                        show={modal === 'processing'}
                                    >
                                        <Modal.Header>
                                            <Modal.Title
                                                className="modal-title text-[20px] leading-[28px] font-weight-600 primary-text"
                                                id="contained-modal-title-vcenter"
                                            >
                                                {formatMessage({
                                                    id: "Please Wait...",
                                                })}
                                            </Modal.Title>
                                            <img src={crossIcon} alt=""></img>
                                        </Modal.Header>
                                        <Modal.Body>
                                            <div className="flex-column d-flex align-items-center">
                                                <Lottie
                                                    className=""
                                                    animationData={processingJson}
                                                    style={{
                                                        width: 185,
                                                        height: 145,
                                                        borderRadius: 25,
                                                        // borderTopLeftRadius: 0,
                                                        // overflow: "hidden",
                                                    }}
                                                />
                                                <h4 className="text-[22px] mt-[20px] mb-[8px] leading-[32px] font-weight-600 primary-text">
                                                    {formatMessage({
                                                        id: "Processing...",
                                                    })}
                                                </h4>
                                                <p className="text-[13px] leading-[20px] font-weight-400 secondary-text">
                                                    {formatMessage({
                                                        id: "We are processing your request...",
                                                    })}
                                                </p>
                                            </div>
                                        </Modal.Body>
                                        {/* <Modal.Footer>
                                            <BasicButton
                                                height="44px"
                                                border="1px solid #C2D24B"
                                                color="#C2D24B"
                                                textColor="#000000"
                                                padding="12px 24px"
                                                buttonText={formatMessage({ id: "Okay" })}
                                                minWidth={56}
                                            // disabled={deletePendingLoading}
                                            // onClick={() => deleteInvite(selectedInviteId)}
                                            />
                                        </Modal.Footer> */}
                                    </Modal>
                                </div>
                                <div className="">
                                    {/* Successfully team joined modal */}
                                    <Modal
                                        backdropClassName="sub-modal-1"
                                        className="sub-modal-1"
                                        aria-labelledby="contained-modal-title-vcenter"
                                        centered
                                        show={modal == 'success'}
                                    >
                                        <Modal.Header>
                                            <Modal.Title
                                                className="modal-title text-[20px] leading-[28px] font-weight-600 primary-text"
                                                id="contained-modal-title-vcenter"
                                            >
                                                {formatMessage({
                                                    id: "Team Joined Successfully",
                                                })}
                                            </Modal.Title>
                                            <img src={crossIcon} alt=""></img>
                                        </Modal.Header>
                                        <Modal.Body>
                                            <p className="text-[#FFFFFFCC]  text-center text-[13px] leading-[20px]">
                                                {" "}
                                                {formatMessage({
                                                    id: "Congratulations! You've successfully joined the team behind",
                                                })}
                                                {' '}{current?.personality?.name?.split(' ')?.[0]}{"'s "}
                                                {formatMessage({
                                                    id: "AI personality"
                                                })}
                                            </p>
                                            <div className="p-[16px] bg-[#2E2F45] mt-[24px]">
                                                <p className="text-[12px] mb-[16px] leading-[18px] text-[#FFFFFFCC]">
                                                    {formatMessage({
                                                        id: "To access your new role, please switch to this newly-joined AI personality. You can do so by selecting it from the personality switcher dropdown located at the top-left corner, as indicated above.",
                                                    })}
                                                </p>
                                                <img src={teamsJoined} alt=""></img>
                                            </div>
                                        </Modal.Body>
                                        <Modal.Footer>
                                            <BasicButton
                                                height="44px"
                                                border="1px solid #C2D24B"
                                                color="#C2D24B"
                                                textColor="#000000"
                                                padding="12px 24px"
                                                buttonText={formatMessage({ id: "Okay" })}
                                                minWidth={56}
                                                // disabled={deletePendingLoading}
                                                onClick={() => {
                                                    setModal("")
                                                    getInvites()
                                                    setTimeout(()=>window.location.reload(),3000)
                                                }}
                                            />
                                        </Modal.Footer>
                                    </Modal>
                                </div>
                                <div className="">
                                    {/* Not able to join modal */}
                                    <Modal
                                        backdropClassName="sub-modal-1"
                                        className="sub-modal-1"
                                        aria-labelledby="contained-modal-title-vcenter"
                                        centered
                                        show={modal == 'failed'}
                                    >
                                        <Modal.Header>
                                            <Modal.Title
                                                className="modal-title text-[20px] leading-[28px] font-weight-600 primary-text"
                                                id="contained-modal-title-vcenter"
                                            >
                                                {formatMessage({
                                                    id: "Sorry, couldn’t join the team",
                                                })}
                                            </Modal.Title>
                                            <img src={crossIcon} alt=""></img>
                                        </Modal.Header>
                                        <Modal.Body>
                                            <p className="text-[#FFFFFFCC]  text-center text-[13px] leading-[20px]">
                                                {" "}
                                                {formatMessage({
                                                    id: "Apologies, it appears there's an issue with the invite from this AI personality.",
                                                })}
                                            </p>
                                            <img
                                                src={teamsNotJoined}
                                                alt=""
                                                className="mx-auto my-[22px]"
                                            ></img>
                                            <p className="text-[#FFFFFFCC]  text-center text-[13px] leading-[20px]">
                                                {" "}
                                                {formatMessage({
                                                    id: "The invite may have expired, or the AI personality's plan may have been downgraded.",
                                                })}
                                            </p>
                                        </Modal.Body>
                                        <Modal.Footer>
                                            <BasicButton
                                                height="44px"
                                                border="1px solid #C2D24B"
                                                color="#C2D24B"
                                                textColor="#000000"
                                                padding="12px 24px"
                                                buttonText={formatMessage({ id: "Okay" })}
                                                minWidth={56}
                                                // disabled={deletePendingLoading}
                                                onClick={() => {
                                                    setModal("")
                                                }}
                                            />
                                        </Modal.Footer>
                                    </Modal>
                                </div>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ReceivedInvites;
