import { toAbsoluteUrl } from "../../../../../../_metronic/helpers";
import cardImage1 from "../../../../../assets/images/event/card-image-1.png";
import cardImage2 from "../../../../../assets/images/event/card-image-2.png";
import cardImage3 from "../../../../../assets/images/event/card-image-3.png";
export const cards = [
  {
    id: 1,
    image: cardImage3,
    name: "Pamela Sigmund",
    occupation: "Hollywood Superstar",
    follower: "200k",
    likes: "356k",
  },

  {
    id: 2,
    image: cardImage2,
    name: "Harry Potter",
    occupation: "Hollywood Superstar",
    follower: "200k",
    likes: "356k",
    isVerified: true,
  },
  {
    id: 3,
    image: cardImage1,
    name: "Shahrukh Khan",
    occupation: "Bollywood Superstar",
    follower: "200k",
    likes: "356k",
    isVerified: true,
  },
  {
    id: 4,
    image: cardImage3,
    name: "Pamela Sigmund",
    occupation: "Hollywood Superstar",
    follower: "200k",
    likes: "356k",
  },
  {
    id: 5,
    image: cardImage3,
    name: "Pamela Sigmund",
    occupation: "Hollywood Superstar",
    follower: "200k",
    likes: "356k",
  },
  {
    id: 6,
    image: cardImage2,
    name: "Harry Potter",
    occupation: "Hollywood Superstar",
    follower: "200k",
    likes: "356k",
    isVerified: true,
  },
  {
    id: 7,
    image: cardImage3,
    name: "Pamela Sigmund",
    occupation: "Hollywood Superstar",
    follower: "200k",
    likes: "356k",
  },
  {
    id: 8,
    image: cardImage2,
    name: "Harry Potter",
    occupation: "Hollywood Superstar",
    follower: "200k",
    likes: "356k",
    isVerified: true,
  },
  {
    id: 9,
    image: cardImage3,
    name: "Pamela Sigmund",
    occupation: "Hollywood Superstar",
    follower: "200k",
    likes: "356k",
    isVerified: false,
  },
  {
    id: 10,
    image: cardImage1,
    name: "Shahrukh Khan",
    occupation: "Bollywood Superstar",
    follower: "200k",
    likes: "356k",
    isVerified: true,
  },
  {
    id: 11,
    image: cardImage1,
    name: "Shahrukh Khan",
    occupation: "Bollywood Superstar",
    follower: "200k",
    likes: "356k",
    isVerified: true,
  },
  {
    id: 12,
    image: cardImage3,
    name: "Pamela Sigmund",
    occupation: "Hollywood Superstar",
    follower: "200k",
    likes: "356k",
    isVerified: false,
  },
];
