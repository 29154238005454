import React, { useEffect, useRef, useState } from "react";
import CreateAiPersonality from "../../components/ai-profile/create-ai-personality";
import ChatHeader from "../../components/ai-profile/chat-header";
import ChatBody from "../../components/ai-profile/chat-body";
import Matching from "./matching";
import SubscribeModal from "../../components/ai-profile/modals/subscribe-modal";
import {  createPersonalitySubscription, getPersonalityChargePerMinute, getPersonalitySubscription } from "./core/_request";
import { useAuth } from "../../../auth";
import { BALANCE_REQUIRED_FOR_MINS } from "../../../../core/_constants";
import { getCurrentChatSession } from "../../../apps/chat/core/_request";
import { getUserBalance } from "../../../chat-credits/core/_request";
import { useSearchParams } from "react-router-dom";
import AiProfileFeedbackModal from "./modals/AiProfileFeedbackModal";
import { Spinner } from "../../../widgets/components/General/Spinner";
import ChatAvatar, { SpeechServiceProvider } from "../../../ai-personality/components/chat/chat-avatar";
import { toast } from "react-toastify";
import GeoPersonality from "./GeoPersonality";
import clsx from "clsx";

interface ChatInterface{
  startChat: boolean;
  setStartChat: (value:boolean)=>void,
  personalityImage: string;
  userImage: string;
  isAd: boolean;
  alreadySubscribed?:boolean;
  showTimer?:boolean;
  isDark?:boolean;
  fullScreen?:boolean;
}
const Chat: React.FC<ChatInterface> = ({ startChat, setStartChat, personalityImage, userImage,isAd, alreadySubscribed=false, showTimer=false,isDark=false,fullScreen}) => {
  const [hasBackground, setBackground] = useState(false);
  const [openSubscribeModal, setOpenSubscribeModal] = useState(false);
  const [isSubscribed, setIsSubscribed] = useState(alreadySubscribed)
  const {personalityInView, credits, setCurrentChatSession, currentChatSession, setCredits, currentUser, ipStackCountry,setCurrentUser} = useAuth();
  const [insufficient, setInsufficient] = useState<boolean>(false)
  const [cpm, setCpm] = useState<number>(0);
  const [searchParams, setSearchParams] = useSearchParams()
  const [loading , setLoading] = useState<boolean>(false);
  const [showFeedbackModal, setShowFeedbackModal] = useState<boolean>(false);
  const [subscriptionFetched, setSubscriptionFetched] = useState<boolean>(false);
  const [subUpdate, setSubUpdate] = useState<boolean>(false);
  const [subscription, setSubscription] = useState<any>();
  const [accessible, setAccessible] = useState<boolean|null>(null);
  useEffect(()=>{
    if(searchParams.get('id') && searchParams.get("ref")!='call'){
      setStartChat(true)
      setOpenSubscribeModal(true)
    }
  },[])

  useEffect(()=>{
    if(personalityInView){
      const cpm = personalityInView?.listings?.chat_monetization?.enabled ? personalityInView?.listings?.chat_monetization?.charge_per_minute || 0 : 0
      setCpm(cpm)
    }
    return () => {
      setSubscriptionFetched(false)
      setAccessible(null)
    };
  },[personalityInView])


  useEffect(() => {
    setAccessible(null)
    if (!personalityInView) {
      return;
    }
    if (personalityInView.isOwned) {
      setAccessible(true)
      return;
    }
    let accessibility = personalityInView?.listings?.geography_accessibility
    if(accessibility && ipStackCountry){
      if(accessibility.all && !accessibility?.exclude?.includes(ipStackCountry.countryId) || (accessibility?.include?.includes(ipStackCountry.countryId))){
        setAccessible(true)
      }else {
        setAccessible(false)
      }
    }else {
      setAccessible(true)
    }
  }, [personalityInView, ipStackCountry])

  // useEffect(()=>{
  //   async function check(){
  //     const {data:{data,success}} = await getPersonalitySubscription(personalityInView?.personalityId)
  //     setIsSubscribed(success)
  //     setSubscriptionFetched(true);
  // }
  // personalityInView && !isSubscribed && currentUser &&  check()
  // },[personalityInView, currentUser, isSubscribed])

  useEffect(()=>{
    async function check(){
      const isOwned = !!personalityInView.isOwned
      const {data:{data,success, errors}, status} = await getPersonalitySubscription(personalityInView?.personalityId)
      if(errors && status!==404){
        return toast.error('Error while fetching subscription status',
        {
          autoClose: false,
          closeButton: true, // Display close button (cross icon)
        })
      }
      if(success){
        setIsSubscribed(true)
        setSubscription(data)
    }else {
      if(!cpm || isOwned){
        const {data:{success,data}}= await createPersonalitySubscription(personalityInView.personalityId)
        if(success){
          setIsSubscribed(true);
          setSubscription(data)
        }
      }
    }
      setSubscriptionFetched(true);
  }
  personalityInView   && currentUser && !subscriptionFetched && startChat && !!accessible && check()
  },[personalityInView, currentUser, subscriptionFetched, startChat, accessible])

  useEffect(()=>{
    if(personalityInView && currentUser  && !isSubscribed && startChat && subscriptionFetched){
      setOpenSubscribeModal(true)
    }
  },[ personalityInView, currentUser, isSubscribed, subscriptionFetched, startChat])

  useEffect(()=>{
    if(subUpdate && startChat ){
      setOpenSubscribeModal(true)
    }
  },[subUpdate, startChat])

  // useEffect(()=>{
  //   async function get(){
  //     const {data:{success,data}} = await getCurrentChatSession(personalityInView?.personalityId)
  //     if(success){
  //       setCurrentChatSession(data)
  //     }
  //   }
  //   startChat && currentUser && personalityInView && get()
  // },[personalityInView])


  useEffect(()=>{
    if(!startChat || personalityInView?.isOwned || loading){
      console.log('no check')
      return;
    }
    if(cpm * BALANCE_REQUIRED_FOR_MINS > credits){
      console.log('insufficient')
      setInsufficient(true)
      // setOpenSubscribeModal(true)
    } else {
      console.log('sufficient')
      let txId = searchParams.get('id')
      isSubscribed && !subUpdate && !txId && setOpenSubscribeModal(false)
      setInsufficient(false)
    }
  },[cpm, startChat, credits, personalityInView, searchParams, loading])


  async function onSessionEnd(success: boolean, data: any, errors: any) {
    if (success && data.isOwned){
      return;
    }
    if (success) {
      if(data.chargePerMinute){
        setLoading(true)
        const { data: { success, data } } = await getUserBalance()
        success && setCredits(data.amount)
        setLoading(false)
      }
      currentUser && setShowFeedbackModal(true)
    }
  }

  async function createSessionCheck(){
    if(personalityInView.isOwned){
      return true;
    }
    const isFirstSession = currentUser?.trialChatVoiceUsed ? (currentUser.trialChatVoiceUsed=="01" ? true : false) :true
    if(isFirstSession){
      setCurrentUser((currentUser: any) => {
        return { ...currentUser, trialChatVoiceUsed:  currentUser?.trialChatVoiceUsed ? "11":"10" };
      });
      return true;
    }
  
    if(insufficient){
      setOpenSubscribeModal(true)
      return false;
    }
    const {data:{success,data}} = await getPersonalityChargePerMinute(personalityInView.personalityId)
    if(!success){
      return false;
    }
    if(subscription.lastCharge !== data  && data!==0){
      setCpm(data)
      setOpenSubscribeModal(true)
      setSubUpdate(true)
      return false
    }
    let val = data * BALANCE_REQUIRED_FOR_MINS > credits
    setInsufficient(val)

    if(val){
      setOpenSubscribeModal(true);
      return false;
    }
    
    return true
      
  }
if(accessible==null){
  return null;
}
if(!accessible){
    return <GeoPersonality personalityImage={personalityImage} userImage={userImage}/>
}

  return (
    <div className={ clsx(fullScreen ? "md:h-[90vh] h-[80vh]":"h-[80vh]"," flex flex-col chat-body relative") }>
      {loading && <Spinner/>}
      {isAd && <CreateAiPersonality />}
      <ChatHeader
        isSubscribed={isSubscribed}
        showTimer={showTimer}
        onSessionEnd={onSessionEnd}
        startChat={startChat}
        setStartChat={setStartChat}
        insufficient={insufficient}
        setOpenSubscribeModal={setOpenSubscribeModal}
        setBackground={setBackground}
        hasBackground={hasBackground}
        personalityImage={personalityImage}
        userImage={userImage}
        createSessionCheck={createSessionCheck}
      />
      <div
        className={
          "h-full bg-center bg-no-repeat overflow-y-auto  primary-bg relative"
        }
        // style={{
        //   backgroundImage: hasBackground ? `url(${personalityImage})` : "",
        // }}
      >
          {hasBackground && <div className="absolute top-0 left-0 w-full h-full">
            {/* <ChatAvatar 
              avatarUrl={(personalityInView?.personalityJson?.avatar?.file || 'https://models.readyplayer.me/64cbd0317345960bf885198c.glb')+'?morphTargets=ARKit,Oculus Visemes'}
              voiceId={
                personalityInView?.personalityJson?.voice?.brand == '11labs'
                ?
                personalityInView?.personalityJson?.voice?.model
                :
                (personalityInView?.personalityJson?.avatar?.gender == 'Male' ? 'Joey' : (personalityInView?.personalityJson?.avatar?.gender == 'Female' ? 'Salli' : 'Justin') )
              }
              service={
                personalityInView?.personalityJson?.voice?.brand == '11labs'
                ?
                SpeechServiceProvider.ElevenLabs
                :
                SpeechServiceProvider.Polly
              }
            /> */}
          </div>}
          {((startChat) || currentChatSession) || !currentUser ?
            <ChatBody
              onSessionEnd={onSessionEnd}
              setOpenSubscribeModal={setOpenSubscribeModal}
              hasBackground={hasBackground}
              createSessionCheck={createSessionCheck}
            /> :
            <Matching
              personalityImage={personalityImage}
              setStartChat={setStartChat}
              userImage={userImage}
            />}
            {/* <GeoPersonality
            personalityImage={personalityImage}
            userImage={userImage}
            /> */}
      </div>
      {/* {startChat && <ChatFooter />} */}
      <SubscribeModal
        open={openSubscribeModal}
        setStartChat={setStartChat}
        insufficient={insufficient}
        setOpen={setOpenSubscribeModal}
        personalityImage={personalityImage}
        userImage={userImage}
        setSubscribed={setIsSubscribed}
        isSubscribed={isSubscribed}
        cpm={cpm}
        subUpdate={subUpdate}
        setSubUpdate={setSubUpdate}
        setSubscription={setSubscription}
      />
      <AiProfileFeedbackModal show={showFeedbackModal} discard={()=>setShowFeedbackModal(false)} />
    </div>
  );
};

export default Chat;
