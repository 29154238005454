import React from "react";
import Select from "../../../../../components/select/select";
import SortButton from "../sort-button";

const Index: React.FC<any> = ({ setOpenFilter }) => {
  return (
    <div
      className={
        "flex flex-col md:flex-row md:justify-between md:items-center gap-y-3.5"
      }
    >
      <div className={"relative"}>
        <svg
          className={"absolute top-1/2 -translate-y-1/2 left-2"}
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g clipPath="url(#clip0_497_31689)">
            <path
              opacity="0.3"
              d="M14.2929 16.7071C13.9024 16.3166 13.9024 15.6834 14.2929 15.2929C14.6834 14.9024 15.3166 14.9024 15.7071 15.2929L19.7071 19.2929C20.0976 19.6834 20.0976 20.3166 19.7071 20.7071C19.3166 21.0976 18.6834 21.0976 18.2929 20.7071L14.2929 16.7071Z"
              fill="#585960"
            />
            <path
              d="M11 16C13.7614 16 16 13.7614 16 11C16 8.23858 13.7614 6 11 6C8.23858 6 6 8.23858 6 11C6 13.7614 8.23858 16 11 16ZM11 18C7.13401 18 4 14.866 4 11C4 7.13401 7.13401 4 11 4C14.866 4 18 7.13401 18 11C18 14.866 14.866 18 11 18Z"
              fill="#585960"
            />
          </g>
          <defs>
            <clipPath id="clip0_497_31689">
              <rect width="24" height="24" fill="white" />
            </clipPath>
          </defs>
        </svg>
        <input
          className={"form-control !bg-[#2E2F45] md:min-w-[320px]  pl-10"}
          placeholder={"Search"}
        />
      </div>
      <div className={"flex items-center gap-x-2"}>
        <div>
          <button
            className={
              "flex items-center md:gap-x-4 bg-[#2E2F45] py-[8px] px-[24px] border !border-[#FFFFFF1A] rounded-[24px]"
            }
            onClick={() => setOpenFilter(true)}
          >
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g clipPath="url(#clip0_497_31682)">
                <path
                  d="M4 4H20V6.172C19.9999 6.70239 19.7891 7.21101 19.414 7.586L15 12V19L9 21V12.5L4.52 7.572C4.18545 7.20393 4.00005 6.7244 4 6.227V4Z"
                  stroke="white"
                  strokeOpacity="0.65"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </g>
              <defs>
                <clipPath id="clip0_497_31682">
                  <rect width="24" height="24" fill="white" />
                </clipPath>
              </defs>
            </svg>
            <span className={"text-[14px] leading-5 text-[#FFFFFFCC]"}>
              Filters
            </span>
          </button>
        </div>
        <div className={"flex-grow"}>
          <Select
            options={[
              { value: "popular", label: "Most Popular" },
              { value: "likes", label: "Most Liked" },
              { value: "interacted", label: "Most Interacted" },
              { value: "created", label: "Creation Time" },
              { value: "name", label: "Name" },
            ]}
            label={false}
            className={"event-select w-full md:min-w-[170px] text-[14px]"}
            placeHolder={"Select"}
          />
        </div>
        <div>
          <SortButton />
        </div>
      </div>
    </div>
  );
};

export default Index;
