import React, { useState } from "react";
import { toAbsoluteUrl } from "../../../../../_metronic/helpers";
import { useTimeZone } from "../../../../hooks/useTimeZone";
import { useDateFormat } from "../../../../hooks/useDateFormat";
import { kmbConvertor } from "../../../../utils";
import clsx from "clsx";
import { deletePost, likePost, unlikePost } from "../../core/_request";
import { Spinner } from "react-bootstrap";
import { getCdnUrl } from "../../../../core/_util";
import { Link } from "react-router-dom";
import Menu from "../../../../components/menu/menu";
import { toast } from "react-toastify";
import { is } from "date-fns/locale";
import useGTM from "../../../../hooks/useGTM";
import { GTMEvent } from "../../../../hooks/gtm_helpers";

interface PostProps {
  postId: string;
  personalityName?: string;
  personalityPhoto: string;
  postImage?: string;
  postContent?: string;
  createdAt: string;
  isVerified: boolean;
  likes: number;
  isLiked: boolean;
  removeAddLike: boolean;
  username: string;
  isDark?: boolean;
  showProfileLink: boolean;
  showMenu?: boolean;
  filterPostOnDelete?: (id: string) => void;
}
const Published: React.FC<PostProps> = ({
  postId,
  personalityName,
  personalityPhoto,
  postImage,
  postContent,
  createdAt,
  isVerified,
  likes,
  isDark,
  isLiked,
  removeAddLike,
  username,
  showProfileLink,
  showMenu = false,
  filterPostOnDelete,
}) => {
  const { getTimeZoneValue } = useTimeZone();
  const { getDateValue } = useDateFormat();
  const [liked, setLiked] = useState<boolean>(isLiked);
  const [loading, setLoading] = useState<boolean>(false);
  const [postLikes, setPostLikes] = useState<number>(likes || 0);
  const {dataLayerPush} = useGTM()
  async function handleClick() {
    setLoading(true);
    if (!liked) {
      const {
        data: { success },
      } = await likePost(postId);
      if (success) {
        setLiked(true);
        setPostLikes((likes) => likes + 1);
        dataLayerPush(GTMEvent.PostLikeSuccessful)
      }else{
        dataLayerPush(GTMEvent.PostLikeFailed)
      }
    } else {
      const {
        data: { success },
      } = await unlikePost(postId);
      if (success) {
        setLiked(false);
        setPostLikes((likes) => likes - 1);
        dataLayerPush(GTMEvent.PostLikeRevertSuccessful)
      }else{
        dataLayerPush(GTMEvent.PostLikeRevertFailed)
      }
    }
    setLoading(false);
  }

  async function deleteSelectedPost() {
    const {
      data: { success },
    } = await deletePost(postId);
    if (success) {
      filterPostOnDelete && filterPostOnDelete(postId);
      toast.success("Post deleted successfully");
      dataLayerPush(GTMEvent.PostDeletionSuccessful)
    }else{
      dataLayerPush(GTMEvent.PostDeletionFailed)
    }
  }
  return (
    <div
      className={clsx(
        isDark ? "secondary-bg  border-main" : "secondary-bg border-main",
        " rounded  border p-[14px_8px] md:p-[16px_32px_24px_32px]"
      )}
    >
      <div className={"primary-text flex justify-between mb-[16px]"}>
        <div className={"flex gap-[12px]"}>
          {showProfileLink ? (
            <Link to={`/p/${username}`} target="_blank">
              <img
                alt="Pic"
                src={personalityPhoto}
                className={"w-[48px] h-[48px] rounded-full object-cover object-top"}
              />
            </Link>
          ) : (
            <img
              alt="Pic"
              src={personalityPhoto}
              className={"w-[48px] h-[48px] rounded-full object-cover object-top"}
            />
          )}
          <div>
            <h4
              className={clsx(
                isDark ? "text-[#FFFFFF]" : "primary-text",
                "flex items-center gap-[8px]  text-[16px] leading-[24px] font-semibold mb-1"
              )}
            >
              {showProfileLink ? (
                <Link to={`/p/${username}`} target="_blank">
                  {personalityName}
                </Link>
              ) : (
                personalityName
              )}
              {isVerified && (
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M12 0L14.8553 3.21224L19.0534 2.2918L19.4753 6.56886L23.4127 8.2918L21.24 12L23.4127 15.7082L19.4753 17.4311L19.0534 21.7082L14.8553 20.7878L12 24L9.14468 20.7878L4.94658 21.7082L4.52468 17.4311L0.587322 15.7082L2.76 12L0.587322 8.2918L4.52468 6.56886L4.94658 2.2918L9.14468 3.21224L12 0Z"
                    fill="#4776E6"
                  />
                  <path
                    d="M8.30762 12.0001L10.7745 14.7693L17.0768 9.23083"
                    stroke="white"
                    strokeWidth="2"
                    strokeLinecap="round"
                  />
                </svg>
              )}
            </h4>
            <p
              className={clsx(
                isDark ? "text-[#FFFFFF80]" : "secondary-text-light",
                "text-[12px] leading-[18px]"
              )}
            >
              {getDateValue(createdAt)} {getTimeZoneValue(createdAt)}
            </p>
          </div>
        </div>
        <div>
          {showMenu && (
            <Menu
              closeOnItemClick={true}
              items={[{ label: "Delete", handleClick: deleteSelectedPost }]}
            />
          )}
        </div>
      </div>
      <div>
        <p
          className={clsx(
            isDark ? "text-[#FFFFFFCC]" : "primary-text",
            "mb-[8px]  text-[14px] leading-[22px]"
          )}
        >
          {postContent}
        </p>
        {postImage && (
          <img
            alt="post"
            src={getCdnUrl(postImage)}
            className={"mb-[8px] max-h-[600px] object-contain mx-auto"}
          />
        )}
      </div>
      <div className={"flex items-center gap-[4px] mt-[20px]"}>
        {removeAddLike ? null : (
          <button
            className={clsx(
              "flex items-center gap-x-2 rounded-[6px] px-3 py-2.5 mr-2",
              liked ? "bg-[#C2D24B1A]" : "menu-bg"
            )}
            onClick={() => !loading && handleClick()}
          >
            {loading ? (
              <Spinner animation="border" />
            ) : (
              <>
                <svg
                  width="23"
                  height="23"
                  viewBox="0 0 23 23"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g clipPath="url(#clip0_344_24492)">
                    <path
                      d="M6.70833 10.5417V18.2084C6.70833 18.4625 6.60737 18.7063 6.42764 18.886C6.24792 19.0657 6.00417 19.1667 5.75 19.1667H3.83333C3.57917 19.1667 3.33541 19.0657 3.15569 18.886C2.97597 18.7063 2.875 18.4625 2.875 18.2084V11.5C2.875 11.2459 2.97597 11.0021 3.15569 10.8224C3.33541 10.6427 3.57917 10.5417 3.83333 10.5417H6.70833ZM6.70833 10.5417C7.725 10.5417 8.70002 10.1378 9.41891 9.41895C10.1378 8.70006 10.5417 7.72504 10.5417 6.70837V5.75004C10.5417 5.24171 10.7436 4.7542 11.103 4.39475C11.4625 4.03531 11.95 3.83337 12.4583 3.83337C12.9667 3.83337 13.4542 4.03531 13.8136 4.39475C14.1731 4.7542 14.375 5.24171 14.375 5.75004V10.5417H17.25C17.7583 10.5417 18.2458 10.7436 18.6053 11.1031C18.9647 11.4625 19.1667 11.95 19.1667 12.4584L18.2083 17.25C18.0705 17.838 17.8091 18.3428 17.4634 18.6885C17.1177 19.0342 16.7065 19.202 16.2917 19.1667H9.58333C8.82084 19.1667 8.08957 18.8638 7.5504 18.3246C7.01123 17.7855 6.70833 17.0542 6.70833 16.2917"
                      // stroke={liked ? "#C2D24B" : "white"}
                      className={clsx(
                        liked ? "stroke-[#C2D24B]" : "icon-stroke-primary"
                      )}
                      strokeOpacity="0.8"
                      strokeWidth="1.2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_344_24492">
                      <rect width="23" height="23" className={"icon"} />
                    </clipPath>
                  </defs>
                </svg>
                <span
                  className={"text-[12px] leading-[18px] secondary-text-light"}
                >
                  {liked ? "Liked" : "Like"}
                </span>
              </>
            )}
          </button>
        )}
        <span
          className={
            "text-[12px] d-flex align-items-center leading-[18px] secondary-text-light"
          }
        >
          {removeAddLike ? (
            <svg
              width="23"
              height="23"
              viewBox="0 0 23 23"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              className="mr-2"
            >
              <g clipPath="url(#clip0_344_24492)">
                <path
                  d="M6.70833 10.5417V18.2084C6.70833 18.4625 6.60737 18.7063 6.42764 18.886C6.24792 19.0657 6.00417 19.1667 5.75 19.1667H3.83333C3.57917 19.1667 3.33541 19.0657 3.15569 18.886C2.97597 18.7063 2.875 18.4625 2.875 18.2084V11.5C2.875 11.2459 2.97597 11.0021 3.15569 10.8224C3.33541 10.6427 3.57917 10.5417 3.83333 10.5417H6.70833ZM6.70833 10.5417C7.725 10.5417 8.70002 10.1378 9.41891 9.41895C10.1378 8.70006 10.5417 7.72504 10.5417 6.70837V5.75004C10.5417 5.24171 10.7436 4.7542 11.103 4.39475C11.4625 4.03531 11.95 3.83337 12.4583 3.83337C12.9667 3.83337 13.4542 4.03531 13.8136 4.39475C14.1731 4.7542 14.375 5.24171 14.375 5.75004V10.5417H17.25C17.7583 10.5417 18.2458 10.7436 18.6053 11.1031C18.9647 11.4625 19.1667 11.95 19.1667 12.4584L18.2083 17.25C18.0705 17.838 17.8091 18.3428 17.4634 18.6885C17.1177 19.0342 16.7065 19.202 16.2917 19.1667H9.58333C8.82084 19.1667 8.08957 18.8638 7.5504 18.3246C7.01123 17.7855 6.70833 17.0542 6.70833 16.2917"
                  stroke={"white"}
                  strokeOpacity="0.8"
                  strokeWidth="1.2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </g>
              <defs>
                <clipPath id="clip0_344_24492">
                  <rect width="23" height="23" fill="white" />
                </clipPath>
              </defs>
            </svg>
          ) : null}
          {kmbConvertor(postLikes)} Likes
        </span>
      </div>
    </div>
  );
};

export default Published;
