import { useEffect, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination } from "swiper";
import { get, map } from "lodash";
// import { categories } from "./categories";
import { useAuth } from "../../../../auth";
import request from "../../../../../core/_apis";
import { useNavigate } from "react-router";
import { ComponentSpinner } from "../../../../widgets/components/General/Spinner";
import { useIntl } from "react-intl";
import sportImg1 from "../../../../../assets/images/marketplace/sport-1.png";
import sportImg2 from "../../../../../assets/images/marketplace/sport-2.png";
import sportImg3 from "../../../../../assets/images/marketplace/sport-3.png";
import fictionalImg1 from "../../../../../assets/images/marketplace/fiction-1.png";
import fictionalImg2 from "../../../../../assets/images/marketplace/fiction-2.png";
import fictionalImg3 from "../../../../../assets/images/marketplace/fiction-3.png";
import entrepreneursImg1 from "../../../../../assets/images/marketplace/entrepreneurs-1.png";
import entrepreneursImg2 from "../../../../../assets/images/marketplace/entrepreneurs-2.png";
import entrepreneursImg3 from "../../../../../assets/images/marketplace/entrepreneurs-3.png";
import { getCdnUrl } from "../../../../../core/_util";
import { DisplayImage } from "../../../../widgets/components/General/DisplayImage";

function getNearAboutNumber(number: number) {
  const milestones = [
    10, 50, 100, 200, 500, 1000, 2000, 5000, 10000, 20000, 50000, 100000,
  ];
  let nearestMilestone = milestones[0];
  let minDifference = Math.abs(number - milestones[0]);

  for (let i = 1; i < milestones.length; i++) {
    const difference = Math.abs(number - milestones[i]);
    if (difference < minDifference) {
      minDifference = difference;
      nearestMilestone = milestones[i];
    }
  }

  return nearestMilestone;
}
const Slider = () => {
  const { formatMessage } = useIntl();
  const { referenceData, ipStackCountry } = useAuth();
  const [cards, setCards] = useState<any>([]);
  const [apiLoading, setApiLoading] = useState(false);
  const navigate = useNavigate();
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    // Function to check if the screen width is below a certain threshold (e.g., 768px for mobile)
    const checkIsMobile = () => {
      setIsMobile(window.innerWidth < 768);
    };

    // Initial check
    checkIsMobile();

    // Attach the event listener to check on resize
    window.addEventListener("resize", checkIsMobile);

    // Clean up the event listener when the component is unmounted
    return () => {
      window.removeEventListener("resize", checkIsMobile);
    };
  }, []);
  const categories = [
    {
      id: 1,
      key: "Sports Personality",
      title: `${formatMessage({ id: "Sports Persons" })}`,
      description: `${formatMessage({
        id: "Train and interact with your favorite sports idols! Get personalized tips and motivation from our Sports AI personalities on Kamoto.AI.",
      })}`,
      footerText: `${formatMessage({
        id: "Featuring over 500 Sports AI personalities",
      })}`,
      rows: [
        { image: getCdnUrl(sportImg1, "width=122,fit=cover,gravity=auto") },
        { image: getCdnUrl(sportImg2, "width=122,fit=cover,gravity=auto") },
        { image: getCdnUrl(sportImg3, "width=122,fit=cover,gravity=auto") },
      ],
    },
    {
      id: 2,
      key: "Fictional Character",
      title: `${formatMessage({ id: "Fictional Characters" })}`,
      description: `${formatMessage({
        id: "Engage with your beloved cartoon characters! Experience captivating chats and delightful adventures with our Cartoon Characters AI personalities on Kamoto.AI.",
      })}`,
      footerText: `${formatMessage({
        id: "Featuring over 500 Sports AI personalities",
      })}`,
      rows: [
        { image: getCdnUrl(fictionalImg1, "width=122,fit=cover,gravity=auto") },
        { image: getCdnUrl(fictionalImg2, "width=122,fit=cover,gravity=auto") },
        { image: getCdnUrl(fictionalImg3, "width=122,fit=cover,gravity=auto") },
      ],
    },
    {
      id: 3,
      key: "Entrepreneur",
      title: `${formatMessage({ id: "Entrepreneurs" })}`,
      description: `${formatMessage({
        id: "Unleash your business potential with Kamoto.AI! Gain insights and inspiration from our AI personalities modeled on top entrepreneurs and business leaders",
      })}`,
      footerText: `${formatMessage({
        id: "Featuring over 500 Business AI personalities",
      })}`,
      rows: [
        {
          image: getCdnUrl(
            entrepreneursImg1,
            "width=122,fit=cover,gravity=auto"
          ),
        },
        {
          image: getCdnUrl(
            entrepreneursImg2,
            "width=122,fit=cover,gravity=auto"
          ),
        },
        {
          image: getCdnUrl(
            entrepreneursImg3,
            "width=122,fit=cover,gravity=auto"
          ),
        },
      ],
    },
    // {
    //   id: 4,
    //   key: "Sports Person",
    //   title: "Sports Persons",
    //   description:
    //     "Train and interact with your favorite sports idols! Get personalized tips and motivation from our Sports AI personalities on Kamoto.AI.",
    //   footerText: "Featuring over 500 Sports AI personalities",
    //   image1: sportImg1,
    //   image2: sportImg2,
    //   image3: sportImg3,
    // },
    // {
    //   id: 5,
    //   key: "Fictional Character",
    //   title: "Fictional Characters",
    //   description:
    //     "Engage with your beloved cartoon characters! Experience captivating chats and delightful adventures with our Cartoon Characters AI personalities on Kamoto.AI.",
    //   footerText: "Featuring over 500 Sports AI personalities",
    //   image1: fictionalImg1,
    //   image2: fictionalImg2,
    //   image3: fictionalImg3,
    // },
    // {
    //   id: 6,
    //   key: "Entrepreneur",
    //   title: "Entrepreneurs",
    //   description:
    //     "Unleash your business potential with Kamoto.AI! Gain insights and inspiration from our AI personalities modeled on top entrepreneurs and business leaders",
    //   footerText: "Featuring over 500 Business AI personalities",
    //   image1: entrepreneursImg1,
    //   image2: entrepreneursImg2,
    //   image3: entrepreneursImg3,
    // },
  ];
  useEffect(() => {
    setApiLoading(true);
    if (ipStackCountry) {
      let queries: any = [];
      categories.forEach((element) => {
        let queryString = `?pick=3&country=${
          ipStackCountry?.countryId
        }&country=${
          referenceData?.platformOptions?.countries.find(
            (el) => el.country_code == "US"
          )?.countryId
        }&category=${encodeURIComponent(element.key)}`;
        queries.push(request.get(`/personality${queryString}`));
      });
      Promise.all(queries).then((values) => {
        let obj: any = [];
        values.forEach((value: any) => {
          if (value.status == 200 && value.data.data.rows.length >= 3) {
            let category = categories.find(
              (el) => el.key == value.data.data.personality_type
            );
            obj.push({
              id: category?.id,
              key: category?.key,
              title: category?.title,
              description: category?.description,
              rows: value.data.data.rows,
              footerText: `Featuring over ${getNearAboutNumber(
                value?.data?.data?.count || 0
              )} ${category?.title}`,
            });
          }
        });
        setCards(obj);
        setApiLoading(false);
      });
    }
  }, [JSON.stringify(ipStackCountry)]);

  if (apiLoading) {
    return <ComponentSpinner />;
  }

  const setting = {};

  return (
    <Swiper
      modules={[Navigation, Pagination]}
      centeredSlides={isMobile} // Only center slides on mobile
      centerInsufficientSlides={isMobile} // Only center insufficient slides on mobile
      threshold={10}
      breakpoints={{
        340: {
          slidesPerView: 1,
          shortSwipes: true,
        },
        540: {
          slidesPerView: 2,
          spaceBetween: 12,
        },
        1024: {
          slidesPerView: 3,
          shortSwipes: false,
          spaceBetween: 12,
        },
      }}
      className="relative w-full py-10 personality-swiper"
      navigation={true}
      touchAngle={50}
      pagination={{
        clickable: true,
      }}
    >
      {map(cards.length > 0 ? cards : categories, (el, i) => (
        <SwiperSlide key={get(el, "id")} className={"flex justify-center"}>
          <div
            className={
              "py-5 px-2 widgets-customize shadow-default border border-main rounded-[8px] md:max-w-[350px]"
            }
          >
            <div className={" flex justify-between pt-8 relative"}>
              <img
               src={get(el, "rows[0].image")}
                className={"w-[122px] h-[122px] rounded"}
                alt={"card-img"}
              />
              <img
                src={get(el, "rows[1].image")}
                className={
                  "absolute left-1/2 -translate-x-1/2 bottom-[16px] w-[122px] h-[122px] rounded"
                }
                alt={"card-img"}
              />
              <img
                src={get(el, "rows[2].image")}
                className={"w-[122px] h-[122px] rounded"}
                alt={"card-img"}
              />
            </div>
            <div className={"text-center mb-2 mt-5"}>
              <h4
                className={"text-[16px] leading-6 primary-text font-semibold"}
              >
                {get(el, "title")}
              </h4>
            </div>
            <div className={"h-[120px]"}>
              <p className={"text-[13px]  leading-5 secondary-text px-2"}>
                {get(el, "description")}
              </p>
            </div>
            <div className={"flex justify-center mb-3"}>
              <button
                className={
                  "main-button-light shadow-default px-5 py-[10px] rounded text-[14px] leading-5 font-medium "
                }
                onClick={(e) =>
                  navigate(
                    `/marketplace/explore?category=${el.key}&country=${ipStackCountry?.countryId}`
                  )
                }
              >
                {formatMessage({ id: "Explore Now" })}
              </button>
            </div>
            <div className={"text-center"}>
              <p className={"text-[12px] leading-[18px] text-[#FFFFFF80]"}>
                {get(el, "footerText")}
              </p>
            </div>
          </div>
        </SwiperSlide>
      ))}

      <div
        className={
          "w-16 absolute right-0 top-10 bottom-10  z-10 bg-opacity-80 marketplace-slider-right hidden md:block"
        }
      />
    </Swiper>
  );
};

export default Slider;
