import React from "react";
import TextInput from "../../../../app/modules/widgets/components/Input/TextInput";

/* eslint-disable jsx-a11y/anchor-is-valid */
import { useState, useEffect } from "react";
import * as Yup from "yup";
import { Link, useLocation, useSearchParams } from "react-router-dom";
import {
  getUserByToken,
  login,
  sendVerifyEmail,
  verifyEmail,
} from "../core/_requests";
import { useAuth } from "../core/Auth";
import { Form, Formik } from "formik";
import SocialLoginButtons from "../components/SocialLoginButtons";

// import TextInput from "../../widgets/components/Input/TextInput";

import Lottie from "lottie-react";

import processingJson from "../../../assets/lotties/processing-animation.json";
import { useIntl } from "react-intl";
import { CustomButton } from "../../widgets/components/UI/CustomButton";
import { useNavigate } from "react-router-dom";
import { CustomToast } from "../../widgets/components/UI/CustomToast";
import { FormikProps } from "../core/_models";
import { Toaster } from "../../widgets/components/General/Toaster";
import { toast } from "react-toastify";
import { Spinner } from "../../widgets/components/General/Spinner";
import useGTM from "../../../hooks/useGTM";
import { GTMEvent, emailVerificationMeta } from "../../../hooks/gtm_helpers";

export default function VerifyEmailID() {
  // const { formatMessage } = useIntl();
  // const [emailDisabled, setEmailDisabled] = useState<boolean>(false);

  const initialValues = {
    email: "",
  };
  const onSubmit = () => {};

  const { formatMessage } = useIntl();

  const { state } = useLocation() as any;
  const {
    currentUser,
    setCurrentUser,
    userToken,
    saveAuth,
    setUserToken,
    logout,
  } = useAuth();

  const [emailVerified, setIsEmailVerified] = useState(0);
  const [isLoading, setLoading] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();
  const {dataLayerPush} = useGTM()

  const takeToSignIn = async () => {
    console.log("signing out");
    await logout();
    setTimeout(() => {
      navigate(`/auth/login`);
    }, 0);
  };

  const sendVerifcationMail = async () => {
    // apply verification logic here ToDoAnand
    if (currentUser?.userId) {
      setLoading(true);
      let res = await sendVerifyEmail(currentUser.userId);
      if (res.data.success) {
        toast.success(
          formatMessage({ id: "Verification email sent successfully" })
        );
        setIsEmailVerified(4);
        setLoading(false);
      } else {
        setIsEmailVerified(5);
        setLoading(false);
      }
    }
  };

  useEffect(() => {
    async function check() {
      if (searchParams.has("token")) {
        setLoading(true);
        let token: string = searchParams.get("token") || "";
        let res = await verifyEmail(token);
        if (res?.data?.success) {
          const { isVerified, wasAlreadyVerified, token, user,verifiedEmail } = res?.data?.data;
          if (isVerified) {
            //update currentUser object with latest data if has
            if (currentUser && userToken) {
              let {
                data: { data, success },
              } = await getUserByToken(userToken);
              if (success) {
                setCurrentUser(data);
                setTimeout(() => {
                  dataLayerPush(GTMEvent.EmailVerificationSuccess,emailVerificationMeta(user))
                  dataLayerPush(GTMEvent.OnboardingInitiatedSuccessful,{email: verifiedEmail})
                  setLoading(false);
                  navigate("/onboarding");
                  wasAlreadyVerified
                    ? setIsEmailVerified(1)
                    : setIsEmailVerified(2);
                });
                return;
              }
            }
            if (!wasAlreadyVerified) {
              await saveAuth(token);
              setUserToken(token.token);
              setLoading(false);
              navigate("/");
            } else {
              dataLayerPush(GTMEvent.EmailVerificationSuccess,emailVerificationMeta(user))
              dataLayerPush(GTMEvent.OnboardingInitiatedSuccessful,{email: verifiedEmail})
              setIsEmailVerified(1);
              setLoading(false);
            }
            return;
          } else {
            dataLayerPush(GTMEvent.EmailVerificationFailed,{email:verifiedEmail})
            setLoading(false);
            setIsEmailVerified(3);
          }
        } else {
          setLoading(false);
          setIsEmailVerified(3);
        }
      }
    }
    check();
  }, []);

  const renderMessage = (emailVerified: number) => {
    switch (emailVerified) {
      case 0:
        return (
          <>
            <p className={'primary-text'}>

            {formatMessage({
                id: "We have sent an email to the provided email address. To complete the verification process, kindly click on the verification link enclosed in the email",
              })}
              .
            </p>
          </>
        );

      case 1:
        return (
          <>
            <p className={'primary-text'}>
              {formatMessage({ id: "Your email is already verified" })}.</p>
          </>
        );

      case 2:
        return (
          <>
            <p className={'primary-text'}>

            {formatMessage({
                id: "You have verified your email successfully",
              })}
              .
            </p>
          </>
        );

      case 3:
        return (
            <p className={'primary-text'}>

            {formatMessage({
              id: "Looks like the link you used to verify your email is invalid. Please retry again with correct verification link",
            })}
            .
          </p>
        );

      case 4:
        return (
          <>
            <p className={'primary-text'}>

            {formatMessage({
                id: "We have sent another verification mail to your registered email. If email is not received",
              })}
              .
            </p>
          </>
        );

      case 5:
        return (
          <p className={'primary-text'}>
            {formatMessage({
              id: "Can't send email at the moment. Please try again later",
            })}
            .
          </p>
        );
    }
  };

  return (
    <div>
      <h1 className="text-[22px] leading-[32px] font-[600] primary-text text-center mb-[20px]">
        {formatMessage({ id: "Verify Email ID" })}
      </h1>
      {isLoading ? (
        <div className="flex-row my-24 d-flex justify-content-center">
          <Lottie
            className=""
            animationData={processingJson}
            style={{
              width: 185,
              height: 145,
              borderRadius: 25,
              // borderTopLeftRadius: 0,
              // overflow: "hidden",
            }}
          />
        </div>
      ) : (
        <div className="">
          <div className="max-w-[404px] p-[12px_16px] bg-[#C2D24B1A] rounded text-[12px] leading-[18px] font-normal  border-dashed !border-[#C2D24B40] border-2">
            {renderMessage(emailVerified)}
          </div>
          <Formik
            initialValues={{ email: currentUser?.email }}
            onSubmit={onSubmit}
            validateOnMount
          >
            {(formik) => {
              //  setEmailDisabled(true);
              return (
                <>
                  <Form className="form w-100 mt-[18px]">
                    <TextInput
                      isDisabled={true}
                      fieldName={"email"}
                      formik={formik}
                      fieldType={"text"}
                      placeholder={"Enter Your Email Id"}
                      label={formatMessage({ id: "Email" })}
                      toolTipText={formatMessage({
                        id: "GLOBAL.TOOLTIP.LOGIN.VERIFY.EMAIL",
                      })}
                      isStarRequired={true}
                    />

                    <button
                      type="button"
                      className="btn btn-primary bg-main text-black w-100 h-[52px]"
                      onClick={(e) => sendVerifcationMail()}
                    >
                      {formatMessage({ id: "Send Verification Link Again" })}
                    </button>
                  </Form>
                </>
              );
            }}
          </Formik>
        </div>
      )}
      <div className="mt-[28px] text-center w-100">
        <button
          className="text-[14px] leading-[20px] font-semibold main-text"
          onClick={takeToSignIn}
        >
          {formatMessage({ id: "Go Back to Sign in" })}
        </button>
      </div>
    </div>
  );
}
