import React from "react";
import { useIntl } from "react-intl";
import ReadMore from "../../../../components/utility/readMore";
import { kmbConvertor } from "../../../../utils";
import clsx from "clsx";
import { Spinner } from "react-bootstrap";
import SocialLinks from "../../components/social-links";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import ImageRight from "./banner-image.png";
import ImageTop from "./banner-top-image.png";
import { getCdnUrl } from "../../../../core/_util";
const TopBanner: React.FC<any> = ({
  personality,
  setOpenPersonaLicence,
  personalityImage,
  followers,
  follow,
  followLoading,
  handleFollow,
  links,
  owner,
  manager,
  setStartChat,
  startCall,
  setFullScreen
}) => {
  const navigate = useNavigate();
  const { formatMessage } = useIntl();
  const capitalizeFirstLetter = (str: any) =>
    `${str.charAt(0).toUpperCase()}${str.slice(1)}`;
  const [searchParams,setSearchParams] = useSearchParams()
  function getUrlWithProtocol(url: any) {
    // Check if the URL starts with http:// or https://
    if (url.startsWith("http://") || url.startsWith("https://")) {
      return url;
    } else {
      // Prepend https:// to the URL
      return "https://" + url;
    }
  }

  const {rightBanner='', fullBanner=''} = personality?.fan_subscription_plan?.planJson || {}

  return (
    <div className={"bg-[#071727] pb-6 pt-[130px] relative z-10"}>
      <img
        src={getCdnUrl(fullBanner)}
        className={"hidden md:block absolute bottom-0 right-0 -z-[1]"}
      />
      <div className={"md:hidden absolute top-0 w-full -z-[1]"}>
        <div className={"relative w-full"}>
          <img src={getCdnUrl(fullBanner)} className={"w-full h-[260px] full-banner-img-mobile"} />
          <div
            className={
              "w-full h-[140px] top-banner-bottom absolute bottom-0 "
            }
          ></div>
        </div>
      </div>
      <div className={"bottom-gradient"}></div>  
      <div
        className={
          "w-full h-[140px] top-banner-bottom absolute bottom-0 -z-[1]"
        }
      ></div>
      <div
        className={
          "flex flex-col md:flex-row gap-y-3 gap-x-4 z-50 px-[20px] md:px-20 relative"
        }
      >
        <div>
          <div
            className={
              "rounded-full border-4 border-main w-[152px] h-[152px]"
            }
          >
            <img
              src={personalityImage}
              className={
                "object-cover object-top w-full h-full img rounded-full"
              }
              alt=""
            />
          </div>
        </div>
        <div className={"relative"}>
          <div className={"bottom-gradient-mobile"}></div>  
          <div
            className={
              "flex flex-col md:flex-row md:items-center gap-y-2 md:gap-x-4 mb-2"
            }
          >
            <h3 className={"flex items-center gap-x-2"}>
              <span
                className={
                  "text-[24px] leading-[32px] primary-text font-semibold"
                }
              >
                {personality?.name}{" "}
              </span>
              {personality?.isVerified ? (
                <svg
                  width="26"
                  height="26"
                  viewBox="0 0 26 26"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M13 0L16.0933 3.47992L20.6412 2.48278L21.0983 7.11627L25.3637 8.98278L23.01 13L25.3637 17.0172L21.0983 18.8837L20.6412 23.5172L16.0933 22.5201L13 26L9.90674 22.5201L5.35879 23.5172L4.90174 18.8837L0.636266 17.0172L2.99 13L0.636266 8.98278L4.90174 7.11627L5.35879 2.48278L9.90674 3.47992L13 0Z"
                    fill="#0386FF"
                  />
                  <path
                    d="M9 13L11.6724 16L18.5 10"
                    stroke="white"
                    strokeWidth="2"
                    strokeLinecap="round"
                  />
                </svg>
              ) : (
                ""
              )}
            </h3>
            <div className="flex items-center gap-x-4">
              <button
                className={clsx(
                  "text-[#C2D24B]  py-[4px] px-5 border !border-[#C2D24B] flex items-center justify-center gap-x-2.5 rounded-[34px]",
                  follow ? "bg-[#97A33C1A]" : "bg-[#C2D24B1A]"
                )}
                onClick={() => {
                  !followLoading && handleFollow(!follow);
                }}
              >
                {followLoading ? (
                  <Spinner animation="border" />
                ) : (
                  <>
                    {!follow ? (
                      <svg
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <g clipPath="url(#clip0_344_19479)">
                          <path
                            d="M16.2501 10.4771L10.0001 16.6671L3.75009 10.4771C3.33784 10.0759 3.01312 9.59374 2.79638 9.06092C2.57963 8.52811 2.47556 7.95618 2.4907 7.38117C2.50585 6.80615 2.63989 6.2405 2.88439 5.71984C3.12888 5.19917 3.47853 4.73477 3.91133 4.35587C4.34412 3.97698 4.85068 3.6918 5.3991 3.51829C5.94752 3.34479 6.52593 3.28671 7.09789 3.34773C7.66986 3.40874 8.223 3.58752 8.72248 3.87281C9.22196 4.1581 9.65696 4.54372 10.0001 5.00539C10.3447 4.54708 10.7802 4.16483 11.2793 3.88256C11.7785 3.6003 12.3305 3.4241 12.9009 3.36499C13.4712 3.30588 14.0477 3.36514 14.5941 3.53905C15.1405 3.71296 15.6451 3.99779 16.0764 4.37569C16.5077 4.7536 16.8563 5.21646 17.1004 5.7353C17.3446 6.25414 17.479 6.81779 17.4953 7.39098C17.5116 7.96417 17.4095 8.53455 17.1952 9.06643C16.9809 9.59831 16.6592 10.0802 16.2501 10.4821"
                            stroke="#C2D24B"
                            strokeWidth="1.4"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </g>
                        <defs>
                          <clipPath id="clip0_344_19479">
                            <rect width="20" height="20" fill="white" />
                          </clipPath>
                        </defs>
                      </svg>
                    ) : (
                      <svg
                        width="18"
                        height="18"
                        viewBox="0 0 21 19"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M18.5001 10.5717L11.0001 17.9997L3.50006 10.5717C3.00536 10.0903 2.6157 9.5117 2.3556 8.87232C2.09551 8.23295 1.97062 7.54664 1.98879 6.85662C2.00697 6.1666 2.16782 5.48782 2.46121 4.86303C2.75461 4.23823 3.17419 3.68094 3.69354 3.22627C4.21289 2.77159 4.82076 2.42938 5.47887 2.22117C6.13697 2.01296 6.83106 1.94327 7.51743 2.01649C8.20379 2.08971 8.86756 2.30425 9.46693 2.64659C10.0663 2.98894 10.5883 3.45169 11.0001 4.00569C11.4136 3.45571 11.9362 2.99701 12.5352 2.65829C13.1341 2.31958 13.7966 2.10814 14.481 2.03721C15.1654 1.96628 15.8571 2.03739 16.5128 2.24608C17.1685 2.45477 17.7741 2.79656 18.2916 3.25005C18.8091 3.70354 19.2275 4.25897 19.5205 4.88158C19.8135 5.50419 19.9748 6.18057 19.9944 6.8684C20.0139 7.55622 19.8913 8.24068 19.6342 8.87894C19.3771 9.5172 18.991 10.0955 18.5001 10.5777"
                          fill="#FF5A5A"
                        />
                        <path
                          d="M18.5001 10.5717L11.0001 17.9997L3.50006 10.5717C3.00536 10.0903 2.6157 9.5117 2.3556 8.87232C2.09551 8.23295 1.97062 7.54664 1.98879 6.85662C2.00697 6.1666 2.16782 5.48782 2.46121 4.86303C2.75461 4.23823 3.17419 3.68094 3.69354 3.22627C4.21289 2.77159 4.82076 2.42938 5.47887 2.22117C6.13697 2.01296 6.83106 1.94327 7.51743 2.01649C8.20379 2.08971 8.86756 2.30425 9.46693 2.64659C10.0663 2.98894 10.5883 3.45169 11.0001 4.00569C11.4136 3.45571 11.9362 2.99701 12.5352 2.65829C13.1341 2.31958 13.7966 2.10814 14.481 2.03721C15.1654 1.96628 15.8571 2.03739 16.5128 2.24608C17.1685 2.45477 17.7741 2.79656 18.2916 3.25005C18.8091 3.70354 19.2275 4.25897 19.5205 4.88158C19.8135 5.50419 19.9748 6.18057 19.9944 6.8684C20.0139 7.55622 19.8913 8.24068 19.6342 8.87894C19.3771 9.5172 18.991 10.0955 18.5001 10.5777"
                          stroke="#FF5A5A"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                    )}
                    {follow
                      ? formatMessage({ id: "Unfollow" })
                      : formatMessage({ id: "Follow" })}
                  </>
                )}
              </button>
              {personality?.isLicensed ? (
                <button
                  className={"flex items-center gap-x-2 main-text rounded"}
                  onClick={() => setOpenPersonaLicence(true)}
                >
                  <svg
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g clipPath="url(#clip0_1993_28135)">
                      <path
                        d="M10 12.5C10 13.163 10.2634 13.7989 10.7322 14.2678C11.2011 14.7366 11.837 15 12.5 15C13.163 15 13.7989 14.7366 14.2678 14.2678C14.7366 13.7989 15 13.163 15 12.5C15 11.837 14.7366 11.2011 14.2678 10.7322C13.7989 10.2634 13.163 10 12.5 10C11.837 10 11.2011 10.2634 10.7322 10.7322C10.2634 11.2011 10 11.837 10 12.5Z"
                        stroke="#C2D24B"
                        strokeWidth="1.4"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M10.834 14.582V18.332L12.5007 17.082L14.1673 18.332V14.582"
                        stroke="#C2D24B"
                        strokeWidth="1.4"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M8.33333 15.8327H4.16667C3.72464 15.8327 3.30072 15.6571 2.98816 15.3445C2.67559 15.032 2.5 14.608 2.5 14.166V5.83268C2.5 4.91602 3.25 4.16602 4.16667 4.16602H15.8333C16.2754 4.16602 16.6993 4.34161 17.0118 4.65417C17.3244 4.96673 17.5 5.39065 17.5 5.83268V14.166C17.4997 14.4583 17.4225 14.7453 17.2763 14.9984C17.13 15.2514 16.9198 15.4615 16.6667 15.6077M5 7.49935H15M5 9.99935H7.5M5 12.4993H6.66667"
                        stroke="#C2D24B"
                        strokeWidth="1.4"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_1993_28135">
                        <rect width="20" height="20" fill="white" />
                      </clipPath>
                    </defs>
                  </svg>
                  {formatMessage({ id: "Licensed AI Personality" })}
                </button>
              ) : (
                <button
                  onClick={() => setOpenPersonaLicence(true)}
                  className={
                    "flex items-center text-[12px] leading-[18px] gap-x-2 main-text"
                  }
                >
                  {formatMessage({ id: "Unlicensed AI Personality" })}
                </button>
              )}
            </div>
          </div>
          <div className={'max-w-7xl'}>
          <p className={"secondary-text mb-4 personality-desc"}>
            <ReadMore lines={2}>{personality?.description}</ReadMore>
          </p>
          </div>
          <div className={"flex gap-x-10 md:gap-x-6 primary-text mb-4"}>
            <div className={"flex flex-col gap-y-2 md:flex-row md:gap-x-6"}>
              <div className={"flex items-center gap-x-2 "}>
                <svg
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g clipPath="url(#clip0_344_19587)">
                    <path
                      d="M2.5 17.5V15.8333C2.5 14.9493 2.85119 14.1014 3.47631 13.4763C4.10143 12.8512 4.94928 12.5 5.83333 12.5H9.16667C10.0507 12.5 10.8986 12.8512 11.5237 13.4763C12.1488 14.1014 12.5 14.9493 12.5 15.8333V17.5M13.3333 2.60833C14.0503 2.79192 14.6859 3.20892 15.1397 3.79359C15.5935 4.37827 15.8399 5.09736 15.8399 5.8375C15.8399 6.57764 15.5935 7.29673 15.1397 7.88141C14.6859 8.46608 14.0503 8.88308 13.3333 9.06667M17.5 17.5V15.8333C17.4958 15.0976 17.2483 14.384 16.7961 13.8037C16.3439 13.2233 15.7124 12.8089 15 12.625M4.16667 5.83333C4.16667 6.71739 4.51786 7.56523 5.14298 8.19036C5.7681 8.81548 6.61594 9.16667 7.5 9.16667C8.38405 9.16667 9.2319 8.81548 9.85702 8.19036C10.4821 7.56523 10.8333 6.71739 10.8333 5.83333C10.8333 4.94928 10.4821 4.10143 9.85702 3.47631C9.2319 2.85119 8.38405 2.5 7.5 2.5C6.61594 2.5 5.7681 2.85119 5.14298 3.47631C4.51786 4.10143 4.16667 4.94928 4.16667 5.83333Z"
                      className={"icon-stroke-primary"}
                      strokeWidth="1.4"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_344_19587">
                      <rect width="20" height="20" fill="white" />
                    </clipPath>
                  </defs>
                </svg>
                <span className={"font-semibold"}>
                  {kmbConvertor(followers)}
                </span>{" "}
                {formatMessage({ id: "Followers" })}
              </div>
              <div className={"flex items-center gap-x-2 "}>
                <svg
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g clipPath="url(#clip0_344_19592)">
                    <path
                      d="M5.83333 9.16732V15.834C5.83333 16.055 5.74554 16.267 5.58926 16.4232C5.43298 16.5795 5.22101 16.6673 5 16.6673H3.33333C3.11232 16.6673 2.90036 16.5795 2.74408 16.4232C2.5878 16.267 2.5 16.055 2.5 15.834V10.0007C2.5 9.77964 2.5878 9.56768 2.74408 9.4114C2.90036 9.25512 3.11232 9.16732 3.33333 9.16732H5.83333ZM5.83333 9.16732C6.71739 9.16732 7.56524 8.81613 8.19036 8.19101C8.81548 7.56589 9.16667 6.71804 9.16667 5.83398V5.00065C9.16667 4.55862 9.34226 4.1347 9.65482 3.82214C9.96738 3.50958 10.3913 3.33398 10.8333 3.33398C11.2754 3.33398 11.6993 3.50958 12.0118 3.82214C12.3244 4.1347 12.5 4.55862 12.5 5.00065V9.16732H15C15.442 9.16732 15.866 9.34291 16.1785 9.65547C16.4911 9.96803 16.6667 10.392 16.6667 10.834L15.8333 15.0007C15.7135 15.5119 15.4861 15.9509 15.1855 16.2515C14.8849 16.5521 14.5274 16.698 14.1667 16.6673H8.33333C7.67029 16.6673 7.03441 16.4039 6.56557 15.9351C6.09673 15.4662 5.83333 14.8304 5.83333 14.1673"
                      className={"icon-stroke-primary"}
                      strokeOpacity="0.8"
                      strokeWidth="1.2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_344_19592">
                      <rect width="20" height="20" fill="white" />
                    </clipPath>
                  </defs>
                </svg>
                <span className={"font-semibold"}>
                  {kmbConvertor(personality?.likes)}
                </span>{" "}
                {formatMessage({ id: "Likes" })}
              </div>
            </div>
            <div className={"flex flex-col gap-y-2 md:flex-row md:gap-x-6"}>
              <div className={"flex items-center gap-x-2 "}>
                <svg
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g clipPath="url(#clip0_2207_26633)">
                    <path
                      d="M11.6667 12.5V14.1667C11.6667 14.3877 11.5789 14.5996 11.4226 14.7559C11.2663 14.9122 11.0543 15 10.8333 15H5L2.5 17.5V9.16667C2.5 8.94565 2.5878 8.73369 2.74408 8.57741C2.90036 8.42113 3.11232 8.33333 3.33333 8.33333H5M17.5 11.6667L15 9.16667H9.16667C8.94565 9.16667 8.73369 9.07887 8.57741 8.92259C8.42113 8.76631 8.33333 8.55435 8.33333 8.33333V3.33333C8.33333 3.11232 8.42113 2.90036 8.57741 2.74408C8.73369 2.5878 8.94565 2.5 9.16667 2.5H16.6667C16.8877 2.5 17.0996 2.5878 17.2559 2.74408C17.4122 2.90036 17.5 3.11232 17.5 3.33333V11.6667Z"
                      className={"icon-stroke-primary"}
                      strokeOpacity="0.8"
                      strokeWidth="1.4"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_2207_26633">
                      <rect width="20" height="20" fill="white" />
                    </clipPath>
                  </defs>
                </svg>
                <span className={"font-semibold"}>
                  {kmbConvertor(personality?.interactions)}
                </span>{" "}
                {formatMessage({ id: "Interactions" })}
              </div>
              <div className={"flex items-center gap-x-2 "}>
                <svg
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g clipPath="url(#clip0_2207_26638)">
                    <path
                      d="M3 7.5H17M3 12.5H17M2.5 10C2.5 10.9849 2.69399 11.9602 3.0709 12.8701C3.44781 13.7801 4.00026 14.6069 4.6967 15.3033C5.39314 15.9997 6.21993 16.5522 7.12987 16.9291C8.03982 17.306 9.01509 17.5 10 17.5C10.9849 17.5 11.9602 17.306 12.8701 16.9291C13.7801 16.5522 14.6069 15.9997 15.3033 15.3033C15.9997 14.6069 16.5522 13.7801 16.9291 12.8701C17.306 11.9602 17.5 10.9849 17.5 10C17.5 8.01088 16.7098 6.10322 15.3033 4.6967C13.8968 3.29018 11.9891 2.5 10 2.5C8.01088 2.5 6.10322 3.29018 4.6967 4.6967C3.29018 6.10322 2.5 8.01088 2.5 10Z"
                      className={"icon-stroke-primary"}
                      strokeOpacity="0.8"
                      strokeWidth="1.4"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M9.58334 2.5C8.17946 4.74968 7.43518 7.34822 7.43518 10C7.43518 12.6518 8.17946 15.2503 9.58334 17.5M10.4167 2.5C11.8206 4.74968 12.5648 7.34822 12.5648 10C12.5648 12.6518 11.8206 15.2503 10.4167 17.5"
                      className={"icon-stroke-primary"}
                      strokeOpacity="0.8"
                      strokeWidth="1.4"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_2207_26638">
                      <rect width="20" height="20" fill="white" />
                    </clipPath>
                  </defs>
                </svg>
                {formatMessage({
                  id: `${capitalizeFirstLetter(
                    personality?.listings
                      ? personality?.listings?.listing_status || "private"
                      : "private"
                  )}`,
                })}
              </div>
            </div>
          </div>
          <div className={"flex gap-x-4 font-medium max-w-sm mb-5"}>
            <button
              className={
                "export-button flex items-center justify-center gap-x-2.5 py-1.5 rounded flex-grow "
              }
              onClick={()=>{
                searchParams.set("tab", "chat");
                setSearchParams(searchParams, { replace: true });
                setStartChat(true)
                setTimeout(() => {
                  setFullScreen(true)
                }, 200);
              }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
              >
                <g clip-path="url(#clip0_11199_53027)">
                  <path
                    d="M6.66667 7.5026H13.3333M6.66667 10.8359H11.6667M15 3.33594C15.663 3.33594 16.2989 3.59933 16.7678 4.06817C17.2366 4.53701 17.5 5.1729 17.5 5.83594V12.5026C17.5 13.1656 17.2366 13.8015 16.7678 14.2704C16.2989 14.7392 15.663 15.0026 15 15.0026H10.8333L6.66667 17.5026V15.0026H5C4.33696 15.0026 3.70107 14.7392 3.23223 14.2704C2.76339 13.8015 2.5 13.1656 2.5 12.5026V5.83594C2.5 5.1729 2.76339 4.53701 3.23223 4.06817C3.70107 3.59933 4.33696 3.33594 5 3.33594H15Z"
                    stroke="black"
                    stroke-width="1.4"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_11199_53027">
                    <rect width="20" height="20" fill="white" />
                  </clipPath>
                </defs>
              </svg>
              {formatMessage({id:"Chat Now"})}
            </button>
            <button
              className={
                "bg-[#399717] text-white flex items-center justify-center gap-x-2.5 py-1.5 rounded flex-grow "
              }
              onClick={startCall}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="32"
                height="32"
                viewBox="0 0 32 32"
                fill="none"
              >
                <path
                  d="M9.98499 11.801C9.70951 9.90599 10.9922 8.19958 12.9517 7.57344C13.2996 7.46234 13.675 7.49391 14.0014 7.66171C14.3277 7.82951 14.5805 8.1209 14.708 8.47644L15.3363 10.2266C15.4373 10.5081 15.4554 10.8147 15.3883 11.1071C15.3212 11.3994 15.1719 11.6642 14.9596 11.8674L13.0905 13.656C12.9983 13.7444 12.9296 13.8561 12.8911 13.9804C12.8526 14.1046 12.8456 14.2372 12.8707 14.3651L12.8873 14.4436L12.9336 14.6405C12.9748 14.8064 13.037 15.0403 13.1245 15.3194C13.298 15.8739 13.5749 16.6192 13.9871 17.3638C14.3992 18.1084 14.8793 18.7315 15.2524 19.1653C15.4467 19.3911 15.6498 19.6086 15.8612 19.817L15.9191 19.8729C16.0126 19.9593 16.1259 20.0189 16.2481 20.046C16.3702 20.0731 16.497 20.0667 16.6161 20.0275L19.0354 19.2332C19.3103 19.143 19.6047 19.1406 19.8809 19.2262C20.1571 19.3118 20.4025 19.4816 20.5856 19.7137L21.7302 21.1636C22.2074 21.7671 22.151 22.6543 21.6022 23.1876C20.1026 24.6458 18.0405 24.9453 16.606 23.7413C14.8476 22.2617 13.3653 20.4571 12.233 18.4177C11.0913 16.3796 10.3286 14.1342 9.98499 11.801ZM14.3855 14.464L15.9371 12.9793C16.3618 12.5729 16.6604 12.0434 16.7946 11.4587C16.9288 10.874 16.8926 10.2608 16.6906 9.69778L16.0615 7.94762C15.8049 7.23256 15.2965 6.64653 14.6401 6.309C13.9836 5.97146 13.2285 5.90783 12.5287 6.13107C10.0956 6.90808 8.14194 9.18405 8.55552 12.0273C8.92645 14.5485 9.74994 16.974 10.9828 19.1766C12.2041 21.3759 13.8029 23.3219 15.6993 24.9174C17.8503 26.7204 20.7237 26.1056 22.5885 24.2913C23.1221 23.7724 23.4458 23.0608 23.4938 22.3014C23.5418 21.5421 23.3103 20.7922 22.8466 20.2048L21.702 18.7549C21.3358 18.2905 20.8448 17.9509 20.2923 17.7796C19.7397 17.6084 19.1507 17.6133 18.6009 17.7938L16.593 18.4531C16.5031 18.3567 16.4151 18.2583 16.329 18.1582C15.9164 17.6818 15.5513 17.1629 15.2394 16.6094C14.9358 16.0508 14.6875 15.4613 14.4983 14.8502C14.4582 14.7223 14.4205 14.5935 14.3855 14.464Z"
                  fill="white"
                />
              </svg>
              {formatMessage({id:"Call Me Now"})}
            </button>
          </div>
          <div className={"flex md:gap-x-10"}>
            {manager ? (
              <div>
                <label className={"secondary-text mb-2"}>Managed By</label>
                <div className="flex flex-row items-center">
                  <Link to={`/u/${manager.username}`}>
                    <div className={"flex items-center gap-x-2"}>
                      <img
                        src={manager.photo}
                        className={"w-6 h-6 rounded-full"}
                        alt={"image"}
                      />
                      <h6 className={"font-medium secondary-text"}>
                        {`${manager.firstName} ${manager.lastName ?? ""}`}
                        {owner.isVerified ? (
                          <svg
                            width="13"
                            height="13"
                            viewBox="0 0 13 13"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M6.5 0L8.04663 1.73996L10.3206 1.24139L10.5491 3.55813L12.6819 4.49139L11.505 6.5L12.6819 8.50861L10.5491 9.44186L10.3206 11.7586L8.04663 11.26L6.5 13L4.95337 11.26L2.6794 11.7586L2.45087 9.44186L0.318133 8.50861L1.495 6.5L0.318133 4.49139L2.45087 3.55813L2.6794 1.24139L4.95337 1.73996L6.5 0Z"
                              fill="#0386FF"
                            />
                            <path
                              d="M4.5 6.5L5.83621 8L9.25 5"
                              stroke="white"
                              strokeLinecap="round"
                            />
                          </svg>
                        ) : (
                          ""
                        )}
                      </h6>
                    </div>
                  </Link>
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    className="ml-3 cursor-pointer"
                    onClick={(e) =>
                      navigate(
                        `/communicate-engage/user-inbox?tab=user-inbox&u=${manager.username}`
                      )
                    }
                  >
                    <rect
                      width="24"
                      height="24"
                      rx="4"
                      fill="#C2D24B"
                      fill-opacity="0.1"
                    />
                    <g clip-path="url(#clip0_8300_67611)">
                      <path
                        d="M16.9011 4.29688H7.10107C6.17316 4.29799 5.28356 4.66709 4.62743 5.32323C3.97129 5.97937 3.60219 6.86896 3.60107 7.79688L3.60107 16.1969C3.60219 17.1248 3.97129 18.0144 4.62743 18.6705C5.28356 19.3267 6.17316 19.6958 7.10107 19.6969H16.9011C17.829 19.6958 18.7186 19.3267 19.3747 18.6705C20.0309 18.0144 20.4 17.1248 20.4011 16.1969V7.79688C20.4 6.86896 20.0309 5.97937 19.3747 5.32323C18.7186 4.66709 17.829 4.29799 16.9011 4.29688ZM7.10107 5.69688H16.9011C17.3202 5.6977 17.7295 5.82394 18.0763 6.05934C18.4231 6.29474 18.6916 6.62854 18.8471 7.01778L13.4865 12.3791C13.092 12.772 12.5579 12.9926 12.0011 12.9926C11.4443 12.9926 10.9102 12.772 10.5157 12.3791L5.15507 7.01778C5.31058 6.62854 5.579 6.29474 5.92581 6.05934C6.27261 5.82394 6.68192 5.6977 7.10107 5.69688ZM16.9011 18.2969H7.10107C6.54412 18.2969 6.00998 18.0756 5.61615 17.6818C5.22232 17.288 5.00107 16.7538 5.00107 16.1969V8.84688L9.52587 13.3689C10.1829 14.0243 11.0731 14.3923 12.0011 14.3923C12.9291 14.3923 13.8192 14.0243 14.4763 13.3689L19.0011 8.84688V16.1969C19.0011 16.7538 18.7798 17.288 18.386 17.6818C17.9922 18.0756 17.458 18.2969 16.9011 18.2969Z"
                        fill="#C2D24B"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_8300_67611">
                        <rect
                          width="16.8"
                          height="16.8"
                          fill="white"
                          transform="translate(3.60107 3.59375)"
                        />
                      </clipPath>
                    </defs>
                  </svg>
                </div>
              </div>
            ) : (
              <div>
                <label className={"secondary-text  mb-2"}>
                  {formatMessage({ id: "Managed By" })}
                </label>
                <div className="flex flex-row items-center">
                  <Link to={`/u/${owner?.username}`}>
                    <div className={"flex items-center gap-x-2"}>
                      <img
                        src={owner?.photo}
                        className={"w-6 h-6 rounded-full"}
                      />
                      <h6
                        className={
                          "font-medium secondary-text flex items-center gap-x-1"
                        }
                      >
                        {`${owner?.firstName} ${owner?.lastName ?? ""}`}
                        {owner?.isVerified ? (
                          <svg
                            width="13"
                            height="13"
                            viewBox="0 0 13 13"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M6.5 0L8.04663 1.73996L10.3206 1.24139L10.5491 3.55813L12.6819 4.49139L11.505 6.5L12.6819 8.50861L10.5491 9.44186L10.3206 11.7586L8.04663 11.26L6.5 13L4.95337 11.26L2.6794 11.7586L2.45087 9.44186L0.318133 8.50861L1.495 6.5L0.318133 4.49139L2.45087 3.55813L2.6794 1.24139L4.95337 1.73996L6.5 0Z"
                              fill="#0386FF"
                            />
                            <path
                              d="M4.5 6.5L5.83621 8L9.25 5"
                              stroke="white"
                              strokeLinecap="round"
                            />
                          </svg>
                        ) : (
                          ""
                        )}
                      </h6>
                    </div>
                  </Link>
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    className="ml-3 cursor-pointer"
                    onClick={(e) =>
                      navigate(
                        `/communicate-engage/user-inbox?tab=user-inbox&u=${owner.username}`
                      )
                    }
                  >
                    <rect
                      width="24"
                      height="24"
                      rx="4"
                      fill="#C2D24B"
                      fill-opacity="0.1"
                    />
                    <g clip-path="url(#clip0_8300_67611)">
                      <path
                        d="M16.9011 4.29688H7.10107C6.17316 4.29799 5.28356 4.66709 4.62743 5.32323C3.97129 5.97937 3.60219 6.86896 3.60107 7.79688L3.60107 16.1969C3.60219 17.1248 3.97129 18.0144 4.62743 18.6705C5.28356 19.3267 6.17316 19.6958 7.10107 19.6969H16.9011C17.829 19.6958 18.7186 19.3267 19.3747 18.6705C20.0309 18.0144 20.4 17.1248 20.4011 16.1969V7.79688C20.4 6.86896 20.0309 5.97937 19.3747 5.32323C18.7186 4.66709 17.829 4.29799 16.9011 4.29688ZM7.10107 5.69688H16.9011C17.3202 5.6977 17.7295 5.82394 18.0763 6.05934C18.4231 6.29474 18.6916 6.62854 18.8471 7.01778L13.4865 12.3791C13.092 12.772 12.5579 12.9926 12.0011 12.9926C11.4443 12.9926 10.9102 12.772 10.5157 12.3791L5.15507 7.01778C5.31058 6.62854 5.579 6.29474 5.92581 6.05934C6.27261 5.82394 6.68192 5.6977 7.10107 5.69688ZM16.9011 18.2969H7.10107C6.54412 18.2969 6.00998 18.0756 5.61615 17.6818C5.22232 17.288 5.00107 16.7538 5.00107 16.1969V8.84688L9.52587 13.3689C10.1829 14.0243 11.0731 14.3923 12.0011 14.3923C12.9291 14.3923 13.8192 14.0243 14.4763 13.3689L19.0011 8.84688V16.1969C19.0011 16.7538 18.7798 17.288 18.386 17.6818C17.9922 18.0756 17.458 18.2969 16.9011 18.2969Z"
                        fill="#C2D24B"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_8300_67611">
                        <rect
                          width="16.8"
                          height="16.8"
                          fill="white"
                          transform="translate(3.60107 3.59375)"
                        />
                      </clipPath>
                    </defs>
                  </svg>
                </div>
              </div>
            )}
            {owner && (
              <div>
                <label className={"secondary-text  mb-2"}>
                  {formatMessage({ id: "Owned By" })}
                </label>
                <div className="flex flex-row items-center">
                  <Link to={`/u/${owner.username}`}>
                    <div className={"flex items-center gap-x-2"}>
                      <img
                        src={owner.photo}
                        className={"w-6 h-6 rounded-full"}
                      />
                      <h6
                        className={
                          "font-medium secondary-text flex items-center gap-x-1"
                        }
                      >
                        {`${owner.firstName} ${owner.lastName ?? ""}`}
                        {owner.isVerified ? (
                          <svg
                            width="13"
                            height="13"
                            viewBox="0 0 13 13"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M6.5 0L8.04663 1.73996L10.3206 1.24139L10.5491 3.55813L12.6819 4.49139L11.505 6.5L12.6819 8.50861L10.5491 9.44186L10.3206 11.7586L8.04663 11.26L6.5 13L4.95337 11.26L2.6794 11.7586L2.45087 9.44186L0.318133 8.50861L1.495 6.5L0.318133 4.49139L2.45087 3.55813L2.6794 1.24139L4.95337 1.73996L6.5 0Z"
                              fill="#0386FF"
                            />
                            <path
                              d="M4.5 6.5L5.83621 8L9.25 5"
                              stroke="white"
                              strokeLinecap="round"
                            />
                          </svg>
                        ) : (
                          ""
                        )}
                      </h6>
                    </div>
                  </Link>
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    className="ml-3 cursor-pointer"
                    onClick={(e) =>
                      navigate(
                        `/communicate-engage/user-inbox?tab=user-inbox&u=${owner.username}`
                      )
                    }
                  >
                    <rect
                      width="24"
                      height="24"
                      rx="4"
                      fill="#C2D24B"
                      fill-opacity="0.1"
                    />
                    <g clip-path="url(#clip0_8300_67611)">
                      <path
                        d="M16.9011 4.29688H7.10107C6.17316 4.29799 5.28356 4.66709 4.62743 5.32323C3.97129 5.97937 3.60219 6.86896 3.60107 7.79688L3.60107 16.1969C3.60219 17.1248 3.97129 18.0144 4.62743 18.6705C5.28356 19.3267 6.17316 19.6958 7.10107 19.6969H16.9011C17.829 19.6958 18.7186 19.3267 19.3747 18.6705C20.0309 18.0144 20.4 17.1248 20.4011 16.1969V7.79688C20.4 6.86896 20.0309 5.97937 19.3747 5.32323C18.7186 4.66709 17.829 4.29799 16.9011 4.29688ZM7.10107 5.69688H16.9011C17.3202 5.6977 17.7295 5.82394 18.0763 6.05934C18.4231 6.29474 18.6916 6.62854 18.8471 7.01778L13.4865 12.3791C13.092 12.772 12.5579 12.9926 12.0011 12.9926C11.4443 12.9926 10.9102 12.772 10.5157 12.3791L5.15507 7.01778C5.31058 6.62854 5.579 6.29474 5.92581 6.05934C6.27261 5.82394 6.68192 5.6977 7.10107 5.69688ZM16.9011 18.2969H7.10107C6.54412 18.2969 6.00998 18.0756 5.61615 17.6818C5.22232 17.288 5.00107 16.7538 5.00107 16.1969V8.84688L9.52587 13.3689C10.1829 14.0243 11.0731 14.3923 12.0011 14.3923C12.9291 14.3923 13.8192 14.0243 14.4763 13.3689L19.0011 8.84688V16.1969C19.0011 16.7538 18.7798 17.288 18.386 17.6818C17.9922 18.0756 17.458 18.2969 16.9011 18.2969Z"
                        fill="#C2D24B"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_8300_67611">
                        <rect
                          width="16.8"
                          height="16.8"
                          fill="white"
                          transform="translate(3.60107 3.59375)"
                        />
                      </clipPath>
                    </defs>
                  </svg>
                </div>
              </div>
            )}

            {personality?.website ? (
              <div>
                <label className={"secondary-text  mb-2"}>
                  {formatMessage({ id: "Website" })}
                </label>
                <a
                  href={getUrlWithProtocol(personality?.website)}
                  target={"_blank"}
                  rel={"noreferrer"}
                  className={
                    "flex gap-x-2 main-text underline cursor-pointer mb-[14px]"
                  }
                >
                  <svg
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g clipPath="url(#clip0_344_19460)">
                      <path
                        d="M10 0C8.02219 0 6.08879 0.58649 4.4443 1.6853C2.79981 2.78412 1.51809 4.3459 0.761209 6.17317C0.00433286 8.00043 -0.193701 10.0111 0.192152 11.9509C0.578004 13.8907 1.53041 15.6725 2.92894 17.0711C4.32746 18.4696 6.10929 19.422 8.0491 19.8079C9.98891 20.1937 11.9996 19.9957 13.8268 19.2388C15.6541 18.4819 17.2159 17.2002 18.3147 15.5557C19.4135 13.9112 20 11.9778 20 10C19.9971 7.34872 18.9426 4.80684 17.0679 2.9321C15.1932 1.05736 12.6513 0.00286757 10 0V0ZM17.2058 5.83333H14.5217C13.9208 4.44082 13.1297 3.1384 12.1708 1.96333C14.2929 2.54077 16.1019 3.93122 17.2058 5.83333V5.83333ZM13.75 10C13.7432 10.8484 13.6095 11.6911 13.3533 12.5H6.64667C6.39055 11.6911 6.25685 10.8484 6.25 10C6.25685 9.15155 6.39055 8.3089 6.64667 7.5H13.3533C13.6095 8.3089 13.7432 9.15155 13.75 10V10ZM7.315 14.1667H12.685C11.9777 15.5631 11.0735 16.8507 10 17.99C8.92615 16.851 8.02187 15.5634 7.315 14.1667V14.1667ZM7.315 5.83333C8.02231 4.43689 8.92654 3.1493 10 2.01C11.0739 3.14898 11.9781 4.43663 12.685 5.83333H7.315ZM7.83334 1.96333C6.87305 3.13815 6.08049 4.44058 5.47834 5.83333H2.79417C3.89905 3.93036 5.70972 2.5398 7.83334 1.96333V1.96333ZM2.05084 7.5H4.91667C4.70064 8.31595 4.58864 9.15595 4.58334 10C4.58864 10.8441 4.70064 11.684 4.91667 12.5H2.05084C1.53862 10.8727 1.53862 9.12731 2.05084 7.5V7.5ZM2.79417 14.1667H5.47834C6.08049 15.5594 6.87305 16.8618 7.83334 18.0367C5.70972 17.4602 3.89905 16.0696 2.79417 14.1667V14.1667ZM12.1708 18.0367C13.1297 16.8616 13.9208 15.5592 14.5217 14.1667H17.2058C16.1019 16.0688 14.2929 17.4592 12.1708 18.0367V18.0367ZM17.9492 12.5H15.0833C15.2994 11.684 15.4114 10.8441 15.4167 10C15.4114 9.15595 15.2994 8.31595 15.0833 7.5H17.9475C18.4597 9.12731 18.4597 10.8727 17.9475 12.5H17.9492Z"
                        fill="white"
                        fillOpacity="0.8"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_344_19460">
                        <rect width="20" height="20" fill="white" />
                      </clipPath>
                    </defs>
                  </svg>
                  {personality?.website}
                </a>
              </div>
            ) : (
              ""
            )}
            <div>
              <label className={"secondary-text  mb-2"}>Social Links</label>
              <div className={"flex gap-x-[11px]"}>
                {links && links.facebook && (
                  <a
                    className="w-[28px] h-[28px] hashtag border border-main rounded flex items-center justify-center"
                    href={links.facebook}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <svg
                      width="11"
                      height="20"
                      viewBox="0 0 11 20"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M2.90174 19.7992V10.6023H0.400024V7.29101H2.90174V4.46271C2.90174 2.24021 4.37951 0.199219 7.78461 0.199219C9.16328 0.199219 10.1827 0.327697 10.1827 0.327697L10.1024 3.41991C10.1024 3.41991 9.06273 3.41007 7.92817 3.41007C6.70024 3.41007 6.50351 3.96015 6.50351 4.87313V7.29101H10.2L10.0392 10.6023H6.50351V19.7992H2.90174Z"
                        fill="white"
                        fillOpacity="0.8"
                      />
                    </svg>
                  </a>
                )}
                {links && links.instagram && (
                  <a
                    className="w-[28px] h-[28px] bg-[#1C223F] border !border-[#FFFFFF1A] rounded flex items-center justify-center"
                    href={links.instagram}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <svg
                      width="14"
                      height="14"
                      viewBox="0 0 14 14"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M6.99825 4.66567C5.71288 4.66567 4.66392 5.71463 4.66392 7C4.66392 8.28537 5.71288 9.33433 6.99825 9.33433C8.28362 9.33433 9.33258 8.28537 9.33258 7C9.33258 5.71463 8.28362 4.66567 6.99825 4.66567ZM13.9995 7C13.9995 6.03335 14.0082 5.07545 13.9539 4.11055C13.8997 2.98979 13.644 1.99512 12.8244 1.17556C12.0031 0.35426 11.0102 0.100338 9.88945 0.0460516C8.9228 -0.00823506 7.9649 0.000520879 7 0.000520879C6.03335 0.000520879 5.07545 -0.00823506 4.11055 0.0460516C2.98979 0.100338 1.99512 0.356011 1.17556 1.17556C0.35426 1.99687 0.100338 2.98979 0.0460516 4.11055C-0.00823506 5.0772 0.000520879 6.0351 0.000520879 7C0.000520879 7.9649 -0.00823506 8.92455 0.0460516 9.88945C0.100338 11.0102 0.356011 12.0049 1.17556 12.8244C1.99687 13.6457 2.98979 13.8997 4.11055 13.954C5.0772 14.0082 6.0351 13.9995 7 13.9995C7.96665 13.9995 8.92455 14.0082 9.88945 13.954C11.0102 13.8997 12.0049 13.644 12.8244 12.8244C13.6457 12.0031 13.8997 11.0102 13.9539 9.88945C14.01 8.92455 13.9995 7.96665 13.9995 7ZM6.99825 10.5917C5.01066 10.5917 3.40657 8.98759 3.40657 7C3.40657 5.01241 5.01066 3.40832 6.99825 3.40832C8.98584 3.40832 10.5899 5.01241 10.5899 7C10.5899 8.98759 8.98584 10.5917 6.99825 10.5917ZM10.737 4.10004C10.273 4.10004 9.89821 3.72529 9.89821 3.26122C9.89821 2.79716 10.273 2.42241 10.737 2.42241C11.2011 2.42241 11.5758 2.79716 11.5758 3.26122C11.576 3.37142 11.5544 3.48056 11.5123 3.58239C11.4702 3.68422 11.4084 3.77675 11.3305 3.85467C11.2525 3.93259 11.16 3.99437 11.0582 4.03647C10.9564 4.07858 10.8472 4.10018 10.737 4.10004Z"
                        fill="white"
                        fillOpacity="0.8"
                      />
                    </svg>
                  </a>
                )}
                {links && links.linkedin && (
                  <a
                    className="w-[28px] h-[28px] bg-[#1C223F] border !border-[#FFFFFF1A] rounded flex items-center justify-center"
                    href={links.linkedin}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <svg
                      width="14"
                      height="14"
                      viewBox="0 0 14 14"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M5.2003 4.8783H7.8001V6.1733C8.1746 5.4285 9.135 4.7593 10.5777 4.7593C13.3434 4.7593 14 6.2419 14 8.9621V14H11.2V9.5816C11.2 8.0325 10.8255 7.1589 9.8721 7.1589C8.5498 7.1589 8.0003 8.1004 8.0003 9.5809V14H5.2003V4.8783ZM0.399 13.881H3.199V4.7593H0.399V13.881ZM3.6001 1.785C3.6002 2.01969 3.55366 2.25206 3.46317 2.46861C3.37268 2.68516 3.24006 2.88156 3.073 3.0464C2.73448 3.38284 2.27627 3.57116 1.799 3.57C1.32257 3.56968 0.865418 3.38184 0.5264 3.0471C0.359948 2.8817 0.22777 2.68508 0.137441 2.4685C0.047111 2.25193 0.000405392 2.01966 0 1.785C0 1.3111 0.189 0.8575 0.5271 0.5229C0.86582 0.187711 1.32317 -0.000209448 1.7997 1.75185e-07C2.2771 1.75185e-07 2.7349 0.1883 3.073 0.5229C3.4104 0.8575 3.6001 1.3111 3.6001 1.785Z"
                        fill="white"
                        fillOpacity="0.8"
                      />
                    </svg>
                  </a>
                )}
                {links && links.twitter && (
                  <a
                    className="w-[28px] h-[28px] bg-[#1C223F] border !border-[#FFFFFF1A] rounded flex items-center justify-center"
                    href={links.twitter}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <svg
                      width="18"
                      height="14"
                      viewBox="0 0 18 14"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M17.4 1.66066C16.7822 1.94128 16.1185 2.1309 15.4208 2.21661C16.1407 1.77503 16.6793 1.08003 16.9361 0.261302C16.2597 0.673137 15.5194 0.963023 14.7475 1.11836C14.2283 0.550172 13.5407 0.173569 12.7914 0.0470186C12.0421 -0.0795316 11.273 0.0510517 10.6035 0.418495C9.934 0.785938 9.40157 1.36968 9.08888 2.0791C8.77618 2.78852 8.70072 3.58391 8.87419 4.3418C7.50368 4.27126 6.16295 3.90611 4.93902 3.27003C3.71509 2.63395 2.6353 1.74117 1.76975 0.649633C1.47379 1.17297 1.30361 1.77973 1.30361 2.42594C1.30328 3.00767 1.44303 3.58049 1.71046 4.09358C1.9779 4.60667 2.36474 5.04416 2.83667 5.36724C2.28935 5.34938 1.75411 5.19778 1.2755 4.92506V4.97056C1.27544 5.78647 1.55076 6.57726 2.05474 7.20877C2.55872 7.84027 3.26031 8.27359 4.04048 8.4352C3.53275 8.57605 3.00044 8.5968 2.48374 8.49587C2.70386 9.19791 3.13262 9.81182 3.71002 10.2516C4.28741 10.6915 4.98452 10.9352 5.70375 10.9487C4.48281 11.9312 2.97495 12.4642 1.42274 12.4618C1.14778 12.4619 0.873052 12.4455 0.599976 12.4125C2.17556 13.451 4.00965 14.0021 5.88281 14C12.2237 14 15.6901 8.61647 15.6901 3.9474C15.6901 3.79571 15.6864 3.6425 15.6797 3.49081C16.354 2.99097 16.936 2.37201 17.3985 1.66293L17.4 1.66066V1.66066Z"
                        fill="white"
                        fillOpacity="0.8"
                      />
                    </svg>
                  </a>
                )}
                {links && links.youtube && (
                  <a
                    className="w-[28px] h-[28px] bg-[#1C223F] border !border-[#FFFFFF1A] rounded flex items-center justify-center"
                    href={links.youtube}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <svg
                      width="30"
                      height="30"
                      viewBox="0 0 30 30"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <rect
                        x="1"
                        y="1"
                        width="28"
                        height="28"
                        rx="4"
                        fill="#1C223F"
                      />
                      <path
                        d="M15.244 7C15.778 7.003 17.114 7.016 18.534 7.073L19.038 7.095C20.467 7.162 21.895 7.278 22.604 7.475C23.549 7.741 24.291 8.515 24.542 9.497C24.942 11.057 24.992 14.099 24.998 14.836L24.999 14.988V15.162C24.992 15.899 24.942 18.942 24.542 20.501C24.288 21.486 23.545 22.261 22.604 22.523C21.895 22.72 20.467 22.836 19.038 22.903L18.534 22.926C17.114 22.982 15.778 22.996 15.244 22.998L15.009 22.999H14.754C13.624 22.992 8.898 22.941 7.394 22.523C6.45 22.257 5.707 21.483 5.456 20.501C5.056 18.941 5.006 15.899 5 15.162V14.836C5.006 14.099 5.056 11.056 5.456 9.497C5.71 8.512 6.453 7.737 7.395 7.476C8.898 7.057 13.625 7.006 14.755 7H15.244ZM12.999 11.5V18.5L18.999 15L12.999 11.5Z"
                        fill="white"
                        fillOpacity="0.8"
                      />
                      <rect
                        x="1"
                        y="1"
                        width="28"
                        height="28"
                        rx="4"
                        stroke="white"
                        strokeOpacity="0.1"
                      />
                    </svg>
                  </a>
                )}
              </div>{" "}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TopBanner;
