import axios from "axios"
import { ChatItem, ResponseType, ChatSessionInterface } from "./_models";
const API_URL = process.env.REACT_APP_BASE_API_URL;

export const CHAT_URL = `${API_URL}/chats`
export const GET_CHATS = `${API_URL}/chats`
export const CREATE_SESSION = `${API_URL}/chat-sessions`
export const GET_CHAT_SESSION = `${API_URL}/chat-sessions`
export const CLOSE_CHAT_SESSION = `${API_URL}/chat-sessions/close`

export const getChats = async(personalityId:string, page:number)=>{
    return await axios.get<ResponseType<ChatItem[]>>(GET_CHATS,{
      params:{page},
        validateStatus(status) {
          return true;
        },
      headers:{
        'x-personality-id':personalityId
      }})
}

export const addChat = async(prompt:string,chatSessionId:string)=>{
  return await axios.post<ResponseType<ChatItem>>(CHAT_URL,{
    prompt,
    chatSessionId
  },{
    validateStatus(status) {
      return true;
    }})
}

export const getCurrentChatSession = async(personalityId:string, type:string='latest')=>{
  return await axios.get<ResponseType<ChatSessionInterface>>(`${GET_CHAT_SESSION}?type=${type}`,{
    // validateStatus(status) {
    //   return true;
    // },
  headers:{
    'x-personality-id':personalityId
  }})
}


export const createChatSession = async(personalityId:string,type:string='chat')=>{
  return await axios.post<ResponseType<ChatSessionInterface>>(CREATE_SESSION,{},{
    params:{type},
    validateStatus(status) {
      return true;
    },
  headers:{
    'x-personality-id':personalityId
  }})
}

export const closeChatSession = async(sessionId:string, reason?:string)=>{
  return await axios.post<ResponseType<ChatSessionInterface>>(CLOSE_CHAT_SESSION,{
    sessionId,
    closeReason: reason
  },{
    validateStatus(status) {
      return true;
    }
 })
}


export const updateChat = async(chatId:string,data:{[key:string]: string | number})=>{
  return await axios.post<ResponseType<ChatSessionInterface>>(`${CHAT_URL}/${chatId}`,data,{
    validateStatus(status) {
      return true;
    }
 })
}

export const addFreeChat = async(prompt:string,chatSessionId:string)=>{
  return await axios.post<ResponseType<ChatItem>>(`${CHAT_URL}/trial`,{
    prompt,
    chatSessionId
  },{
    validateStatus(status) {
      return true;
    },
})
}

export const createFreeChatSession = async(personalityId:string)=>{
  return await axios.post<ResponseType<ChatSessionInterface>>(`${CREATE_SESSION}/trial`,{},{
    validateStatus(status) {
      return true;
    },
  headers:{
    'x-personality-id':personalityId
  }})
}