import React from "react";
import { Form, Formik, useField } from "formik";
import DatePicker from "react-datepicker";
import calenderIcon from "../../../../../_metronic/assets/images/svg/calender-icon.svg";
import { useIntl } from "react-intl";
import { ToolTipUI } from "../UI/ToolTipUI";
import {OverlayTrigger, Popover} from "react-bootstrap";
import {DayPicker, useNavigation} from "react-day-picker";
import {format} from "date-fns";
import moment from "moment/moment";

function DatePickerInput({ name = "", formik }: { name: any; formik: any }) {
  const [field, meta, helpers] = useField(name);
  const { value } = meta;
  const { setValue } = helpers;
  const { formatMessage } = useIntl();


  // document.getElementsByClassName('react-datepicker__navigation--years-upcoming')


    return (
    <div className="relative flex flex-col">
      <label className="text-[13px] leading-5 secondary-text mb-[4px] md:mb-[8px]">
        <div className="flex items-center gap-1">
          {formatMessage({ id: "Birth Date" })}
          <ToolTipUI
            tooltipText={formatMessage({
              id: "GLOBAL.TOOLTIP.AI_PERSONALITY.BIRTH_DATE",
            })}
          />
        </div>
      </label>
      <DatePicker
        {...field}
        selected={formik && formik.values && new Date(formik.values[name])}
        // dateFormat="dd/MM/YYYY"
        //   showMonthYearDropdown
        // showMonthDropdown
        // showYearPicker={true}
        // showFullMonthYearPicker={true}
        showYearDropdown={true}
        showMonthDropdown={true}
        // dropdownMode="select"
        // scrollableYearDropdown={true}
        onChange={(date) => {
          formik.setFieldValue(name, date);
        }}
        placeholderText={formatMessage({ id: "Select Birth date" })}
        className="form-control w-100 font-size-12 search-input-table secondary-bg border-main"

      />
      <img
        className="absolute right-[8px] top-[34px]"
        src={calenderIcon}
        alt=""
      ></img>
    </div>
  );
}

export default DatePickerInput;
