import React, { useEffect, useState } from "react";
import TextInput from "../../../widgets/components/Input/TextInput";
import { useIntl } from "react-intl";
import { Form, Formik } from "formik";
import TextArea from "../../../widgets/components/Input/TextArea";
import { InfoCard } from "../../../widgets/components/UI/InfoCard";
// import Slider from "rc-slider";
import Slider from "../../../../components/slider";
import { SelectInput } from "../../../widgets/components/Input/SelectInput";
import Select from "../../../../components/select/select";
import { get, isEqual, map } from "lodash";
import clsx from "clsx";
import VoiceModal from "./voice-modal";
import FormikReactSelect from "../../../widgets/components/Input/FormikReactSelect";
import { updatePersonalityInfo } from "../../../profile/core/_requests";
import { useAuth } from "../../../auth";
import * as Yup from "yup";
import { toast } from "react-toastify";
import useGTM from "../../../../hooks/useGTM";
import { GTMEvent } from "../../../../hooks/gtm_helpers";

const Voice: React.FC<any> = ({ setOpenEdit, personalityData }) => {
  const { formatMessage } = useIntl();
  const { dataLayerPush } = useGTM();
  const [openCloneModal, setOpenCloneModal] = useState(false);
  const [recloneMode, setRecloneMode] = useState(false);
  const [disableSave, setDisableSave] = useState(false);
  const [cloneData, setCloneData] = useState<any>(undefined);
  const [initValues, setInitValues] = useState<any>(undefined);
  const {
    referenceData,
    setSelectedPersonality,
    selectedPersonality: updatedData,
  } = useAuth();
  personalityData = updatedData || personalityData;

  const onSubmit = async (values: any, { setSubmitting }: any) => {
    // save data to api
    let obj: any = values;
    let voiceData: any = {};
    if (obj.voiceType == "clonedVoice") {
      voiceData.voice_type = "cloned";
      voiceData.brand =
        referenceData?.platformConfig?.["VOICE_SERVICE_11_LABS_ENABLED"] ==
        "true"
          ? "11labs"
          : "play-ht";
      voiceData.cloned_voices = [cloneData];
      voiceData.pitch = obj.pitch;
      voiceData.speed = obj.speed;
      voiceData.reclone = recloneMode;
      if (personalityData?.personalityJson?.voice?.model) {
        voiceData.model = personalityData?.personalityJson?.voice?.model;
      }
    } else {
      voiceData.voice_type = "stock";
      voiceData.brand = "Amazon Poly";
      voiceData.model = obj.voiceId;
      voiceData.pitch = obj.pitch;
      voiceData.speed = obj.speed;
      if (cloneData) {
        voiceData.cloned_voices = [cloneData];
      }
    }
    setDisableSave(true);
    setSubmitting(true);
    try {
      let {
        data: { success, data },
      } = await updatePersonalityInfo({
        personalityId: personalityData.personalityId,
        personalityJson: {
          ...personalityData.personalityJson,
          voice: voiceData,
        },
      });
      if (success) {
        setSelectedPersonality(data);
        toast.success(
          formatMessage({ id: "Voice details updated successfully" })
        );
        dataLayerPush(GTMEvent.AIPersonalityEditSuccessful, {
          tab: "voice-audio",
        });
      } else {
        setSubmitting(false);
        toast.error(
          formatMessage({ id: "Error when updating Voice Details" }),
          {
            autoClose: false,
            closeButton: true, // Display close button (cross icon)
          }
        );
        dataLayerPush(GTMEvent.AIPersonalityEditFailed, { tab: "voice-audio" });
      }
      setDisableSave(false);
    } catch (e) {
      setSubmitting(false);
      setDisableSave(false);
    }
  };

  const voiceSchema = Yup.object().shape({
    // brand: Yup.string()
    //   .required("Brand is required")
    //   .oneOf(["Amazon Poly", "11labs", "Custom"], "Invalid brand"),
    pitch: Yup.number()
      // .required("Pitch is required")
      .min(0, "Pitch must be greater than or equal to 0")
      .max(1, "Pitch must be less than or equal to 1"),
    speed: Yup.number()
      // .required("Speed is required")
      .min(0, "Speed must be greater than or equal to 0")
      .max(1, "Speed must be less than or equal to 1"),
  });

  const name_gender_country_array = [
    "Male - Spanish (Lupe)",
    "Female - Arabic (Zeina)",
    "Female - Chinese (Zhiyu)",
    "Female - Danish (Naja)",
    "Male - Danish (Mads)",
    "Female - Dutch (Lotte)",
    "Male - Dutch (Ruben)",
    "Female - English (Nicole)",
    "Male - English (Russell)",
    "Female - English (Amy)",
    "Female - English (Emma)",
    "Male - English (Brian)",
    "Female - English (Aditi)",
    "Female - English (Raveena)",
    "Female - English (Ivy)",
    "Female - English (Joanna)",
    "Female - English (Kendra)",
    "Female - English (Kimberly)",
    "Female - English (Salli)",
    "Male - English (Joey)",
    "Male - English (Justin)",
    "Male - English (Matthew)",
    "Male - French (Mathieu)",
    "Male - French (Rémi)",
    "Female - French (Chantal)",
    "Male - German (Hans)",
    "Female - Icelandic (Dora)",
    "Male - Icelandic (Karl)",
    "Female - Italian (Carla)",
    "Female - Italian (Bianca)",
    "Male - Italian (Giorgio)",
    "Male - Italian (Adriano)",
    "Female - Japanese (Mizuki)",
    "Male - Japanese (Takumi)",
    "Male - Polish (Jan)",
    "Female - Portuguese (Camila)",
    "Female - Portuguese (Vitoria)",
    "Male - Portuguese (Ricardo)",
    "Male - Portuguese (Thiago)",
    "Female - Portuguese (Inês)",
    "Male - Portuguese (Cristiano)",
    "Female - Romanian (Carmen)",
    "Female - Russian (Tatyana)",
    "Male - Russian (Maxim)",
    "Female - Spanish (Lucia)",
    "Male - Spanish (Andrés)",
    "Female - Spanish (Lupe)",
    "Female - Spanish(Penélope)",
    "Male - Spanish (Miguel)",
    "Female - Swedish (Astrid)",
  ];

  interface NamesObject {
    [key: string]: string;
  }
  const voiceSample: NamesObject = {
    Zeina:
      "https://d1.awsstatic.com/product-marketing/Polly/voices/hello-zeina.d8d41aae2c1469f2c25b65615fc7264eebe33dcd.mp3",
    Nicole:
      "https://d1.awsstatic.com/product-marketing/Polly/voices/nicole.8656c0be485fb3c43c29a7cc799960211fa224e5.mp3",
    Russell:
      "https://d1.awsstatic.com/product-marketing/Polly/voices/russell.85286a07b33c9ee2bd97dd994294ccebb50a784d.mp3",
    Vitoria:
      "https://d1.awsstatic.com/product-marketing/Polly/voices/vitoria.dccdde767360bb45f5ef615d4ae93245e43a4320.mp3",
    Camila:
      "https://d1.awsstatic.com/product-marketing/Polly/voices/Camila%20(Standard).9b8c491fb72f909b7b0619ebe4186714cfc693c8.mp3",
    Ricardo:
      "https://d1.awsstatic.com/product-marketing/Polly/voices/ricardo.485ed1dff89ee3606e46ef0f84b14d73fcd42a6b.mp3",
    Thiago:
      "https://d1.awsstatic.com/product-marketing/Polly/voices/pt_br_thiago_sample.a48cdd290eb3500b36bce8027c7feaca2fd0e6bb.mp3",
    Amy: "https://d1.awsstatic.com/product-marketing/Polly/voices/amy.19b8f5cf54bce4bc1010b4234ec6e9ea1496e97f.mp3",
    Emma: "https://d1.awsstatic.com/product-marketing/Polly/voices/emma.21bd3065d00d15f8f7df800436c0e52970953d36.mp3",
    Brian:
      "https://d1.awsstatic.com/product-marketing/Polly/voices/brian.f5abc46f50f1042bac587d990dd05912daf09089.mp3",
    Chantal:
      "https://d1.awsstatic.com/product-marketing/Polly/voices/chantal.322a64f32393d0c6a5a291465f451cbcd681c295.mp3",
    Naja: "https://d1.awsstatic.com/product-marketing/Polly/voices/naja.b704af10c1e90008689ef17863d949c7b3d229a5.mp3",
    Mads: "https://d1.awsstatic.com/product-marketing/Polly/voices/mads.84fb272b1941303d0b744f267d5a7bbe940b34f4.mp3",
    Lotte:
      "https://d1.awsstatic.com/product-marketing/Polly/voices/lotte.b60b7c845c0135c05852c837813038abe87a824a.mp3",
    Ruben:
      "https://d1.awsstatic.com/product-marketing/Polly/voices/ruben.e8d339872f9d4aac2cf9c6844ab793f81550a015.mp3",
    "Léa (Standard)":
      "https://d1.awsstatic.com/product-marketing/Polly/voices/myNameIs_standard.f5013afc4c4d76869542fbbaab88980ec6f89dbe.wav",
    Céline:
      "https://d1.awsstatic.com/product-marketing/Polly/voices/celine.e6c15212622f6f5225f234d980a65e9a41201196.mp3",
    Mathieu:
      "https://d1.awsstatic.com/product-marketing/Polly/voices/mathieu.feedabefbaa9a94fe1bdeae8e503b49819f910c5.mp3",
    Rémi: "https://d1.awsstatic.com/product-marketing/Polly/voices/fr_fr_remi_sample.0b32b6e64d6e87965cbe12714dd4b962374c34f8.mp3",
    "Vicki (Standard)":
      "https://d1.awsstatic.com/product-marketing/Polly/voices/vicki.cafbf81c1cb7f9c5c060b02ffda104a7f6ee6cb9.mp3",
    Marlene:
      "https://d1.awsstatic.com/product-marketing/Polly/voices/marlene.39191e409bfc0031ea32c63aa8f6027e2110da96.mp3",
    Hans: "https://d1.awsstatic.com/product-marketing/Polly/voices/hans.c8171993a58a550ba31d7c7c9caadb4042d01609.mp3",
    // "Aditi_hindi": "https://d1.awsstatic.com/product-marketing/Polly/voices/aditi_hindi.87e7512d4a1eb60235e8fc4edd08ab2071a8718c.mp3",
    Dora: "https://d1.awsstatic.com/product-marketing/Polly/voices/dora.7579e97fbfe4a3d4ff00df48dd9c588b377f3308.mp3",
    Karl: "https://d1.awsstatic.com/product-marketing/Polly/voices/karl.5ea12538bbd81ede68314aca4b6dbf7e73405be3.mp3",
    Raveena:
      "https://d1.awsstatic.com/product-marketing/Polly/voices/raveena.1819674fbbf0720fdf94018f1df918ade38ebf5a.mp3",
    Aditi:
      "https://d1.awsstatic.com/product-marketing/Polly/voices/aditi.09b7fbaf5620f9b49b6b759f6b2df58fdcbc5d3e.mp3",
    Carla:
      "https://d1.awsstatic.com/product-marketing/Polly/voices/carla.a990b3137f13cb66ef3b9d80e1eba87e30855386.mp3",
    Bianca:
      "https://d1.awsstatic.com/product-marketing/Polly/voices/bianca.78e6329a8f2992e54e171b5b6f95665cb79b15af.mp3",
    Giorgio:
      "https://d1.awsstatic.com/product-marketing/Polly/voices/giorgio.74927aa24bee5d8eea0b09619e29379ed8053010.mp3",
    Adriano:
      "https://d1.awsstatic.com/product-marketing/Polly/voices/it_it_adriano_sample.82b1159c90427dab56e4e490d9d6d4bcc1a2e665.mp3",
    Mizuki:
      "https://d1.awsstatic.com/product-marketing/Polly/voices/mizuki.ae1d119948ee76011e51299748db48c25943e82e.mp3",
    Takumi:
      "https://d1.awsstatic.com/product-marketing/Polly/voices/takumi.b3728282feaaea563b23345fb9fb434d9b727c7c.mp3",
    "Seoyeon (Standard)":
      "https://d1.awsstatic.com/product-marketing/Polly/HelloKorean_Seoyeon.b0ae8ddfc55e602e3f0657afe112b8902282880a.wav",
    Zhiyu:
      "https://d1.awsstatic.com/product-marketing/Polly/voices/Zhiyu-hi.9785e6f8b598d3e6bdcb5c2a9ec95859bfd1292d.mp3",
    Liv: "https://d1.awsstatic.com/product-marketing/Polly/voices/liv.b5a39b3792911ed1d8d35c6c6f327850aae05972.mp3",
    Ewa: "https://d1.awsstatic.com/product-marketing/Polly/voices/ewa.497d83b9f6c486ce14d0b78b9514591a18d4f1b8.mp3",
    Maja: "https://d1.awsstatic.com/product-marketing/Polly/voices/maja.c24bddf411c7c750c6aa621fc8fc4c009c0866f3.mp3",
    Jacek:
      "https://d1.awsstatic.com/product-marketing/Polly/voices/jacek.8851713e855fb82baaf76e1eb4e97fed97f65a36.mp3",
    Jan: "https://d1.awsstatic.com/product-marketing/Polly/voices/jan.9bb856fb6dfe128cc7033943d7009c19f0dddd73.mp3",
    Inês: "https://d1.awsstatic.com/product-marketing/Polly/voices/ines.136466e69d98c766946a311d151b13a0b6e86dfa.mp3",
    Cristiano:
      "https://d1.awsstatic.com/product-marketing/Polly/voices/cristiano.aec3b12945b55ae6990c6b78f534031fb3ee9b6a.mp3",
    Carmen:
      "https://d1.awsstatic.com/product-marketing/Polly/voices/carmen.b732da15cb57ac7c873dd3d016193437cb8ea93c.mp3",
    Tatyana:
      "https://d1.awsstatic.com/product-marketing/Polly/voices/tatyana.1f33b6e72ad56ce6a22c577c5090fdc7ca6c8dd0.mp3",
    Maxim:
      "https://d1.awsstatic.com/product-marketing/Polly/voices/maxim.30cd883f122322e8afd315a49b72efcca91130d2.mp3",
    Ayanda:
      "https://d1.awsstatic.com/product-marketing/Polly/voices/Ayanda_new%20sample_website.14550dcb20d0fdf99e2162525c7252eab9ca3423.wav",
    Conchita:
      "https://d1.awsstatic.com/product-marketing/Polly/voices/conchita.103a380cdb2471d794dbdc4808e18f4bcf52e299.mp3",
    Lucia:
      "https://d1.awsstatic.com/product-marketing/Polly/voices/Lucia.87826fe11c360a96c5652a41a45a990178c633ff.mp3",
    Enrique:
      "https://d1.awsstatic.com/product-marketing/Polly/voices/enrique.22473b31f265f8b050f91df18a8b36a89d19b70e.mp3",
    Sergio:
      "https://d1.awsstatic.com/product-marketing/Polly/voices/es_es_sergio_sample.db934e9be11ebfdba0187679735476109116c614.mp3",
    Mia: "https://d1.awsstatic.com/product-marketing/Polly/voices/Mia.575350d0eeeafa39c121f756c4bfac6437c4e650.mp3",
    Andrés:
      "https://d1.awsstatic.com/product-marketing/Polly/voices/es_mx_andres_sample.8f1a188c3bb994eac3a8d2ff5065ff8377667e30.mp3",
    Astrid:
      "https://d1.awsstatic.com/product-marketing/Polly/voices/astrid.4c9e160e86557f9610fa718f1d871a0caf61e586.mp3",
    Filiz:
      "https://d1.awsstatic.com/product-marketing/Polly/voices/filiz.15c85361bbac56743b00ebf345c2a7d54a3e99f8.mp3",
    Joanna:
      "https://d1.awsstatic.com/product-marketing/Polly/voices/joanna.84722a684fbb16e766944ea6e34dd0042195571c.mp3",
    Salli:
      "https://d1.awsstatic.com/product-marketing/Polly/voices/salli.20a721cb6b8a5fbb016ab6d9ded37f9a91fe7a58.mp3",
    Kendra:
      "https://d1.awsstatic.com/product-marketing/Polly/voices/kendra.d768f43e12c08892d4495511e84e82f1b7195673.mp3",
    Kimberly:
      "https://d1.awsstatic.com/product-marketing/Polly/voices/kimberly.42e50dd5056c92a5fff41952d0c057a7f09adca3.mp3",
    Ivy: "https://d1.awsstatic.com/product-marketing/Polly/voices/ivy.70016451b3c186bcacba09acf5ee1b68c12db745.mp3",
    Matthew:
      "https://d1.awsstatic.com/product-marketing/Polly/voices/matthew.b2a8b7d5b329742fc718c7a8d0efdad1c11fb25f.mp3",
    Justin:
      "https://d1.awsstatic.com/product-marketing/Polly/voices/justin.acfadcd365d37a1ecc88fdb2a640f1a75f83bdf7.mp3",
    Joey: "https://d1.awsstatic.com/product-marketing/Polly/voices/joey.3abd7f17e6dae6c9248cacd7eb0ec910c691c4f4.mp3",
    Penélope:
      "https://d1.awsstatic.com/product-marketing/Polly/voices/penelope.7723493fdb9c6b7e1400efe02a5c1e7848310ba4.mp3",
    Lupe: "https://d1.awsstatic.com/product-marketing/Polly/voices/Lupe%20(Standard).85d69fc3a36ee08455b352221435154afd19420c.mp3",
    Miguel:
      "https://d1.awsstatic.com/product-marketing/Polly/voices/miguel.91030339cc81f8df461e3ab7325168fa5fdc0035.mp3",
    Gwyneth:
      "https://d1.awsstatic.com/product-marketing/Polly/voices/gwyneth.be497813ff11614acbdac0fb6c334b94bce20b45.mp3",
    Geraint:
      "https://d1.awsstatic.com/product-marketing/Polly/voices/geraint.4aa21b628bd99741c0275c77d8daa0ff7290265e.mp3",
  };

  useEffect(() => {
    // set values from data
    let voice = personalityData?.personalityJson?.voice;
    if (voice) {
      let init: any = {};
      init.voiceType =
        voice?.voice_type == "cloned" ? "clonedVoice" : "stockVoice";
      init.brand = voice?.brand;
      init.pitch = voice?.pitch;
      init.speed = voice?.speed;
      init.voiceId = voice?.model;
      if (voice?.cloned_voices) {
        setCloneData(voice?.cloned_voices[0]);
      }
      setInitValues(init);
    } else {
      setInitValues({
        voiceType: "stockVoice",
        pitch: 0.5,
        speed: 0.5,
      });
    }
  }, [personalityData]);

  if (!initValues) {
    return <></>;
  }

  return (
    <Formik
      initialValues={initValues}
      validationSchema={voiceSchema}
      validateOnMount
      onSubmit={onSubmit}
    >
      {(formik) => {
        return (
          <Form>
            <div className={"grid grid-cols-12 gap-y-[20px] md:gap-x-[40px]"}>
              <div className={"col-span-12 md:col-span-7 order-0"}>
                <div
                  className={
                    "tab-container-2 p-[14px] md:p-[20px] pb-[20px] md:pb-[40px]  rounded mb-[14px] md:mb-[24px]"
                  }
                >
                  <div className={"flex justify-between items-center"}>
                    <h4
                      className={
                        "text-[16px] leading-6 font-medium primary-text mb-[16px]"
                      }
                    >
                      {formatMessage({
                        id: "Select Voice",
                      })}
                    </h4>
                    {formik.values["voiceType"] == "clonedVoice" && (
                      <>
                        {cloneData ? (
                          <button
                            className={
                              "flex items-center font-medium gap-1 bg-[#C2D24B]  rounded py-[12px] px-6 text-[13px]  px-6 text-black  leading-5"
                            }
                            type="button"
                            onClick={() => {
                              setRecloneMode(true);
                              setOpenCloneModal(true);
                            }}
                          >
                            <svg
                              width="20"
                              height="20"
                              viewBox="0 0 20 20"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M7.5 4.1665C7.5 3.50346 7.76339 2.86758 8.23223 2.39874C8.70107 1.9299 9.33696 1.6665 10 1.6665C10.663 1.6665 11.2989 1.9299 11.7678 2.39874C12.2366 2.86758 12.5 3.50346 12.5 4.1665V8.33317C12.5 8.99621 12.2366 9.6321 11.7678 10.1009C11.2989 10.5698 10.663 10.8332 10 10.8332C9.33696 10.8332 8.70107 10.5698 8.23223 10.1009C7.76339 9.6321 7.5 8.99621 7.5 8.33317V4.1665Z"
                                stroke="black"
                                strokeWidth="1.66667"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                              <path
                                d="M4.16699 8.33301C4.16699 9.8801 4.78157 11.3638 5.87554 12.4578C6.9695 13.5518 8.45323 14.1663 10.0003 14.1663M10.0003 14.1663C11.5474 14.1663 13.0312 13.5518 14.1251 12.4578C15.2191 11.3638 15.8337 9.8801 15.8337 8.33301M10.0003 14.1663V17.4997M6.66699 17.4997H13.3337"
                                stroke="black"
                                strokeWidth="1.66667"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                            </svg>
                            {formatMessage({ id: "Re-Clone Voice" })}
                          </button>
                        ) : (
                          <button
                            className={
                              "flex items-center font-medium gap-1 bg-[#C2D24B]  rounded py-[12px] px-6 text-[13px]  px-6 text-black  leading-5"
                            }
                            type="button"
                            onClick={() => {
                              setOpenCloneModal(true);
                            }}
                          >
                            <svg
                              width="20"
                              height="20"
                              viewBox="0 0 20 20"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M7.5 4.1665C7.5 3.50346 7.76339 2.86758 8.23223 2.39874C8.70107 1.9299 9.33696 1.6665 10 1.6665C10.663 1.6665 11.2989 1.9299 11.7678 2.39874C12.2366 2.86758 12.5 3.50346 12.5 4.1665V8.33317C12.5 8.99621 12.2366 9.6321 11.7678 10.1009C11.2989 10.5698 10.663 10.8332 10 10.8332C9.33696 10.8332 8.70107 10.5698 8.23223 10.1009C7.76339 9.6321 7.5 8.99621 7.5 8.33317V4.1665Z"
                                stroke="black"
                                strokeWidth="1.66667"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                              <path
                                d="M4.16699 8.33301C4.16699 9.8801 4.78157 11.3638 5.87554 12.4578C6.9695 13.5518 8.45323 14.1663 10.0003 14.1663M10.0003 14.1663C11.5474 14.1663 13.0312 13.5518 14.1251 12.4578C15.2191 11.3638 15.8337 9.8801 15.8337 8.33301M10.0003 14.1663V17.4997M6.66699 17.4997H13.3337"
                                stroke="black"
                                strokeWidth="1.66667"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                            </svg>
                            {formatMessage({ id: "Clone A Voice" })}
                          </button>
                        )}
                      </>
                    )}
                  </div>
                  <div className={"flex  gap-[8px] md:gap-x-[32px]  py-[16px]"}>
                    {map(
                      [
                        {
                          id: "stockVoice",
                          label: `${formatMessage({ id: "Stock Voice" })}`,
                          value: "stockVoice",
                        },
                        {
                          id: "clonedVoice",
                          label: `${formatMessage({ id: "Cloned Voice" })}`,
                          value: "clonedVoice",
                        },
                      ],
                      (item) => (
                        <div className={"flex items-center gap-x-2"}>
                          <input
                            type={"radio"}
                            name={"voiceType"}
                            className={"form-check-input cursor-pointer"}
                            value={get(item, "value")}
                            defaultChecked={
                              get(item, "value") == formik.values?.["voiceType"]
                            }
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                          />
                          <label
                            className={clsx(
                              "text-[16px]  leading-6 font-medium",
                              isEqual(
                                formik.values.voiceType,
                                get(item, "value")
                              )
                                ? "!text-[#C2D24B]"
                                : "secondary-text"
                            )}
                          >
                            {get(item, "label")}
                          </label>
                        </div>
                      )
                    )}
                  </div>
                  <div
                    className={
                      "flex flex-col md:flex-row  items-center gap-x-[20px] gap-y-[12px]"
                    }
                  >
                    <div className={"flex-grow w-full md:w-auto "}>
                      {formik.values.voiceType === "stockVoice" ? (
                        <FormikReactSelect
                          key={"stockVoice"}
                          tooltipText={formatMessage({
                            id: "GLOBAL.TOOLTIP.EDIT_AI_PERSONALITY.VOICE.SELECT_VOICE",
                          })}
                          label={formatMessage({
                            id: "Select Voice from this list",
                          })}
                          // isClearable={false}
                          formik={formik}
                          name={"voiceId"}
                          options={
                            formik.values?.["voiceType"] == "stockVoice"
                              ? name_gender_country_array
                                  .sort((a, b) => b.localeCompare(a))
                                  .map((el: string) => {
                                    return {
                                      label: el,
                                      value: el?.match(/\(([^)]+)\)/)?.[1],
                                    };
                                  })
                              : cloneData && [
                                  {
                                    label: cloneData?.name,
                                    value: cloneData?.url,
                                  },
                                ]
                          }
                          // defaultValue={{ label: "Amazon Poly", value: "Amazon Poly", }}
                        />
                      ) : (
                        <FormikReactSelect
                          key={"cloneVoice"}
                          label={formatMessage({
                            id: "Select Voice from this list (Cloned)",
                          })}
                          name={"clonedVoiceId"}
                          formik={formik}
                          defaultValue={
                            formik.values.clonedVoiceId ||
                            (cloneData && {
                              label: cloneData.name,
                              value: cloneData.url,
                            })
                          }
                          options={
                            cloneData && [
                              {
                                label: cloneData?.name,
                                value: cloneData?.url,
                              },
                            ]
                          }
                        />
                      )}
                    </div>
                    <div
                      className={"flex justify-end gap-[12px] w-full md:w-auto"}
                    >
                      {formik.values?.["voiceType"] == "stockVoice" && (
                        <button
                          type="button"
                          className={
                            "flex items-center gap-1 bg-[#C2D24B1A] border !border-[#C2D24B] h-full rounded py-[8px] px-6 text-[13px]  text-[#C2D24B]  leading-5"
                          }
                          onClick={(e) => {
                            const voiceId: string = formik.values?.["voiceId"];
                            let url: string;
                            if ((url = voiceSample?.[voiceId])) {
                              const audio = new Audio(url);
                              audio.load();
                              audio.play();
                            } else {
                              console.log(voiceId, url);
                            }
                          }}
                        >
                          <svg
                            width="20"
                            height="20"
                            viewBox="0 0 20 20"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <g clipPath="url(#clip0_344_29788)">
                              <path
                                d="M12.5 6.66666C13.0175 7.05478 13.4375 7.55804 13.7268 8.13661C14.0161 8.71517 14.1667 9.35314 14.1667 10C14.1667 10.6469 14.0161 11.2848 13.7268 11.8634C13.4375 12.442 13.0175 12.9452 12.5 13.3333M14.75 4.16666C15.62 4.86971 16.3217 5.75844 16.8037 6.76776C17.2858 7.77709 17.536 8.88146 17.536 10C17.536 11.1185 17.2858 12.2229 16.8037 13.2322C16.3217 14.2416 15.62 15.1303 14.75 15.8333M5 12.5H3.33333C3.11232 12.5 2.90036 12.4122 2.74408 12.2559C2.5878 12.0996 2.5 11.8877 2.5 11.6667V8.33333C2.5 8.11232 2.5878 7.90036 2.74408 7.74408C2.90036 7.5878 3.11232 7.5 3.33333 7.5H5L7.91667 3.75C7.9895 3.60852 8.11054 3.49777 8.25791 3.43774C8.40527 3.37772 8.56925 3.37238 8.72021 3.4227C8.87117 3.47302 8.99915 3.57567 9.08103 3.71211C9.16291 3.84856 9.19328 4.00978 9.16667 4.16666V15.8333C9.19328 15.9902 9.16291 16.1514 9.08103 16.2879C8.99915 16.4243 8.87117 16.527 8.72021 16.5773C8.56925 16.6276 8.40527 16.6223 8.25791 16.5623C8.11054 16.5022 7.9895 16.3915 7.91667 16.25L5 12.5Z"
                                stroke="#C2D24B"
                                strokeWidth="1.6"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                            </g>
                            <defs>
                              <clipPath id="clip0_344_29788">
                                <rect width="20" height="20" fill="white" />
                              </clipPath>
                            </defs>
                          </svg>
                          {formatMessage({
                            id: "Listen",
                          })}
                        </button>
                      )}
                    </div>
                  </div>
                  <div className={"flex flex-col md:flex-row gap-[40px]"}>
                    <div className={"md:w-1/2"}>
                      <label className={"text-[13px] leading-5 secondary-text"}>
                        {formatMessage({
                          id: "Pitch",
                        })}
                      </label>
                      <Slider
                        startLabel=""
                        stopLabel=""
                        formik={formik}
                        fieldName="pitch"
                      />
                      {/* <Slider
                        dots={true}
                        min={0}
                        max={30}
                        defaultValue={15}
                        railStyle={{
                          background: "#2E2F45",
                          height: 10,
                          borderRadius: 2,
                        }}
                        trackStyle={{
                          background: "#C2D24B",
                          height: 10,
                          borderRadius: 2,
                        }}
                        dotStyle={{
                          borderRadius: 0,
                          width: 0.1,
                          boxShadow: "none",
                          border: "none",
                        }}
                        handleStyle={{
                          marginTop: -3,
                          opacity: 1,
                          border: "none",
                          height: 16,
                          width: 16,
                          background: "#fff",
                        }}
                      /> */}
                    </div>
                    <div className={"md:w-1/2"}>
                      <label className={"text-[13px] leading-5 secondary-text"}>
                        {formatMessage({
                          id: "Talking Speed",
                        })}
                      </label>
                      <Slider
                        startLabel=""
                        stopLabel=""
                        formik={formik}
                        fieldName="speed"
                      />
                      {/* <Slider
                        dots={true}
                        min={0}
                        max={30}
                        defaultValue={15}
                        railStyle={{
                          background: "#2E2F45",
                          height: 10,
                          borderRadius: 2,
                        }}
                        trackStyle={{
                          background: "#C2D24B",
                          height: 10,
                          borderRadius: 2,
                        }}
                        dotStyle={{
                          borderRadius: 0,
                          width: 0.1,
                          boxShadow: "none",
                          border: "none",
                        }}
                        handleStyle={{
                          marginTop: -3,
                          opacity: 1,
                          border: "none",
                          height: 16,
                          width: 16,
                          background: "#fff",
                        }}
                      /> */}
                    </div>
                  </div>
                </div>
                <div
                  className={
                    "flex justify-end gap-[10px] md:order-1 md:mt-auto"
                  }
                >
                  {/* <button
                    onClick={() => setOpenEdit(false)}
                    className={
                      "main-button-primary-light text-[14px] leading-5 font-medium py-[12px] w-1/2 md:w-[128px] rounded"
                    }
                  >
                    {formatMessage({
                      id: "Cancel",
                    })}

                  </button> */}
                  <button
                    type="submit"
                    className={`main-button text-[14px] leading-5 font-medium py-[12px] w-full sm:w-1/2  md:w-[140px] rounded ${
                      disableSave ? "opacity-75" : ""
                    }`}
                    disabled={disableSave}
                  >
                    {disableSave
                      ? `${formatMessage({ id: "Please Wait" })}`
                      : `${formatMessage({ id: "Save" })}`}
                  </button>
                </div>
              </div>

              <div
                className={
                  "col-span-12 md:col-span-5 flex flex-col justify-start gap-[20px]"
                }
              >
                <InfoCard
                  title={formatMessage({
                    id: "Understanding Voice of AI Personality",
                  })}
                  desc={
                    <>
                      {formatMessage({
                        id: "In Kamoto.AI, AI Personality owners can customize their personality's voice style and audio to enhance the user experience. \n",
                      })}
                      <br></br>
                      <br></br>
                      {formatMessage({
                        id: "Paid users can select a voice, adjust pitch and talking speed to match their desired criteria. During chat-item, users have control over the AI Personality's audio, with the ability to toggle it on or off. They can also reply using their own voice by enabling the microphone option. These features provide a unique and interactive chat-item experience. \n",
                      })}
                      <br></br>
                      <br></br>
                      {formatMessage({
                        id: "Voice customization adds a personal touch to interactions, allowing users to fully immerse themselves in conversations with their AI Personality. For detailed instructions, please refer to our documentation or contact our support team.",
                      })}
                      <br></br>
                      <br></br>
                    </>
                  }
                  slug={""}
                  isAnchor={
                    "https://help.kamoto.ai/en/articles/7952367-customizing-voice-style-and-audio-of-ai-personality-in-kamoto-ai"
                  }
                />
              </div>
              <VoiceModal
                open={openCloneModal}
                setOpen={setOpenCloneModal}
                setCloneData={setCloneData}
              />
            </div>
          </Form>
        );
      }}
    </Formik>
  );
};

export default Voice;
