import React, { useEffect, useState } from "react";
import CustomTable from "../../../components/table";
import { get, isEqual, map } from "lodash";
import { useDateFormat } from "../../../hooks/useDateFormat";
import { useTimeZone } from "../../../hooks/useTimeZone";
import { cancelFanSubscription, downloadSubscriptionInvoice, getPersonalitySubscriptionHistory } from "../core/_request";
import { useIntl } from "react-intl";
import { getCdnUrl } from "../../../core/_util";
import { toast } from "react-toastify";
import { commaSeparated } from "../../../utils";
import { Modal, Spinner } from "react-bootstrap";
import { Link } from "react-router-dom";
import { Formik } from "formik";
import { BasicButton } from "../../widgets/components/UI/BasicButton";
import { CustomButton } from "../../widgets/components/UI/CustomButton";
import Menu from "../../../components/menu/menu";

const PersonalitySubscriptionHistory = () => {
    const { formatMessage } = useIntl();
    const { getDateValue } = useDateFormat();
    const { getTimeZoneValue } = useTimeZone();
    const [loading, setLoading] = useState<boolean>(true);
    const [data, setData] = useState<any[]>([])
    const [pageCount, setPageCount] = useState<number>(0)
    const [deleteModalOpen,setDeleteModalOpen] = useState<boolean>(false);
    const [selectedSubscription, setSelectedSubscription] = useState<any>(null);

    async function getHistory({page, size, search}:any) {
      setLoading(true);
      const {
        data: { success, data },
      } = await getPersonalitySubscriptionHistory({ page:page + 1, limit:size, search:search });
      if (success) {
        let formattedData = data.rows.map(
          ({ amount, currency, personality, createdAt, chargebeePlanPriceId,chargebeeSubscriptionId }: any) => {
            const frequency = chargebeePlanPriceId.split('-').pop()?.toUpperCase()
            return {
                personality: personality,
              amount: `${currency} ${commaSeparated(amount)}`,
              createdAt: `${getDateValue(createdAt)} ${getTimeZoneValue(
                createdAt
              )}`,
              frequency,
              chargebeeSubscriptionId,
              actions:{
                subscriptionId: chargebeeSubscriptionId,
                frequency,
                personalityName: personality?.name

              },
            };
          }
        );
        setLoading(false);
        setData(formattedData)
        setPageCount(Math.ceil(data.count / size))
      }
    }
  
    useEffect(()=>{
      setDeleteModalOpen(!!selectedSubscription)
    },[selectedSubscription])
  
    const headers = [
      {
        label: `${formatMessage({ id: "Personality Name" })}`,
        key: "personality",
        enableSorting: true,
      },
      {
        label: `${formatMessage({ id: "Amount" })}`,
        key: "amount",
        enableSorting: true,
      },
      {
        label: `${formatMessage({ id: "Subscription Date" })}`,
        key: "createdAt",
        enableSorting: true,
      },
      {
        label: `${formatMessage({ id: "Subscription Frequency" })}`,
        key: "frequency",
        enableSorting: true,
      },
      { label: `${formatMessage({ id: "Actions" })}`, key: "actions" },
    ];
  
    const Actions = ({ info, setSelectedSubscription }: any) => {
      const [downloading, setDownloading] = useState<boolean>(false);
      const {subscriptionId} = info
      async function download() {
        if (!subscriptionId) {
          return toast.error(`${formatMessage({ id: "Invoice not found" })}`,
          {
            autoClose: false,
            closeButton: true, // Display close button (cross icon)
          });
        }
        setDownloading(true);
        const {
          data: { success, data, errors },
        } = await downloadSubscriptionInvoice(subscriptionId);
        if (success) {
          const link = document.createElement("a");
          link.href = data;
          link.download = subscriptionId;
          link.click();
        } else {
          toast.error(errors[0],
            {
              autoClose: false,
              closeButton: true, // Display close button (cross icon)
            });
        }
        setDownloading(false);
      }
  
      return (
        <div className="flex gap-x-4">
        <button
          className={
            "secondary-bg py-[7px] px-[12px] rounded flex items-center justify-center"
          }
          onClick={() => !downloading && download()}
        >
          {downloading ? (
            <Spinner style={{ width: 20, height: 20 }} animation="border" />
          ) : (
            <svg
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g clipPath="url(#clip0_497_28930)">
                <path
                  d="M2.6665 11.3337V12.667C2.6665 13.0206 2.80698 13.3598 3.05703 13.6098C3.30708 13.8598 3.64622 14.0003 3.99984 14.0003H11.9998C12.3535 14.0003 12.6926 13.8598 12.9426 13.6098C13.1927 13.3598 13.3332 13.0206 13.3332 12.667V11.3337M4.6665 7.33366L7.99984 10.667M7.99984 10.667L11.3332 7.33366M7.99984 10.667V2.66699"
                  className={"icon-stroke"}
                  strokeOpacity="0.65"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </g>
              <defs>
                <clipPath id="clip0_497_28930">
                  <rect width="16" height="16" fill="white" />
                </clipPath>
              </defs>
            </svg>
          )}
        </button>
        <Menu verticalDots items={[{
          overrideElement: <button className="bg-[#ef1235] text-[#fff] px-4 py-2 rounded" onClick={()=>setSelectedSubscription(info)}>Cancel</button>
        }]}/>
          
        </div>
      );
    };
    const columns = React.useMemo<any>(
      () =>
        map(headers, (item) => {
          return {
            header: get(item, "label"),
            accessorKey: get(item, "key"),
            enableSorting: get(item, "enableSorting"),
            cell: (info: any) => {
              if (get(item, "key") == "actions") {
                return <Actions info={info.getValue()} setSelectedSubscription={setSelectedSubscription}/>;
              }
              if (isEqual(get(item, "key"), "personality")) {
                return (
                  <Link target="_blank" to={`/p/${info.getValue().username}`} className={"flex items-center gap-x-2"}>
                    <img
                      src={getCdnUrl(info.getValue()?.image)}
                      className={"rounded-full w-6 h-6"}
                    />
                    {info.getValue()?.name}
                  </Link>
                );
              }
                return info.getValue();
            },
            footer: (props: any) => props.column.id,
          };
        }),
  
      []
    );
  
    return (
      <div className={"min-h-[60vh] py-5"}>
        <CustomTable
          tableData={data}
          pageCount={pageCount}
          loading={loading}
          headers={headers}
          columns={columns}
          fetchData={getHistory}
        />
        <Modal
          aria-labelledby="contained-modal-title-vcenter"
          centered
          show={deleteModalOpen}
          onHide={() => {
            setDeleteModalOpen(false)
            setSelectedSubscription(null)
          }}
        >
          <Modal.Header closeButton>
            <Modal.Title
              className="modal-title text-[20px] leading-[28px] font-weight-600 text-[#FFFFFFCC] "
              id="contained-modal-title-vcenter"
            >
              {formatMessage({ id: "Confirmation to Cancel" })}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body className=" p-[16px] md:p-[24px]">
            <p className="text-[#FFFFFFCC] text-[16px]">
              {formatMessage({ id: "Are you sure to cancel the " })}{" "}
              <span className="text-[#C2D24B]">
                {selectedSubscription?.personalityName || "this"}
              </span>{" "}
              {selectedSubscription?.frequency}{" "}
              {formatMessage({ id: "fan subscription?" })}
            </p>
            <Formik
              initialValues={{}}
              onSubmit={async (values,{ setSubmitting }) => {
                setSubmitting(true);
                const {
                  data: { success, data, errors },
                } = await cancelFanSubscription(selectedSubscription?.subscriptionId);
                if (success) {
                  setDeleteModalOpen(false);
                  setData((prevData)=>prevData.filter((item)=>item.chargebeeSubscriptionId!==selectedSubscription?.subscriptionId))
                  setSelectedSubscription(null);
                  toast.success("Unsubscribed successfully");
                } else {
                  toast.error(errors[0] || "Error while cancelling the subscription",
                    {
                      autoClose: false,
                      closeButton: true, // Display close button (cross icon)
                    });
                }
                setSubmitting(false);
              }}
            >
              {(formik) => (
                <div className="flex gap-x-3 justify-end items-center">
                  <BasicButton
                    buttonText={formatMessage({ id: "No" })}
                    border="none"
                    color="#C2D24B1A"
                    textColor="#C2D24B"
                    customClass={"px-[24px] mt-8"}
                    height="44px"
                    onClick={() => {
                      setDeleteModalOpen(false);
                      setSelectedSubscription(null);
                    }}
                  />
                  <CustomButton
                    customClass="mt-8"
                    isSubmitting={formik.isSubmitting}
                    isValid={true}
                    buttonText={formatMessage({ id: "Yes, Cancel" })}
                    loading={formik.isSubmitting}
                    height={44}
                    widthLoading={1}
                    width={1}
                    onSubmit={formik.handleSubmit}
                  />
                </div>
              )}
            </Formik>
          </Modal.Body>
        </Modal>
      </div>
    );
};

export default PersonalitySubscriptionHistory;
