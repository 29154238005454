import { get, map } from "lodash";
import React from "react";
import DateFilter from "../components/date-filter";
import LineChart from "../components/line-chart";
import Select from "../../../components/select/select";
import { useIntl } from "react-intl";
import { ToolTipUI } from "../../widgets/components/UI/ToolTipUI";
import BarChart from "../components/bar-chart";
import LineGraph from "../components/engagement/line-graph";
import Map from "../../dashboard/components/chart/Map";

const Engagement = () => {
    const { formatMessage } = useIntl();

    const lineChart = [
        {
            heading: "Total Posts",
            count: "3000",
            increase: false,
            description: "12,58,666 Last Month",
            linkTitle: "See All Posts",
        },
        {
            heading: "Total Posts",
            count: "3000",
            increase: "800",
            description: "12,58,666 Last Month",
            linkTitle: "See All Posts",
        },
        {
            heading: "Total Likes",
            count: "36,36,369,000",
            increase: false,
            description: "12,58,666 Last Month",
            linkTitle: "See All Posts",
        },
        {
            heading: "Shares",
            count: "36,000",
            increase: 60,
            description: "12,58,666 Last Month",
            linkTitle: "See All Posts",
        },
    ];

    return (
        <div>
            <div className="flex flex-col gap-y-2 lg:flex-row justify-between ">
                <div>
                <Select
                    options={[
                        {
                            value: "facebook",
                            label: "Facebook",
                        },
                        {
                            value: "instagramm",
                            label: "Instagramm",
                        },
                    ]}
                    label={false}
                    className={"event-select min-w-[200px] !w-full text-[14px]"}
                    placeHolder={formatMessage({ id: "Select" })}
                    height={35}
                />
                </div>
                <DateFilter />
            </div>
            <div className={"grid grid-cols-12 gap-4 mt-[16px] mb-[24px]"}>
                {map(lineChart, (item: any) => (
                    <div className={"col-span-12 md:col-span-6 xl:col-span-3"}>
                        <LineChart
                            heading={get(item, "heading")}
                            count={get(item, "count")}
                            description={get(item, "description")}
                            tooltipText={"Test"}
                            linkText={get(item, "linkTitle")}
                            increase={get(item, "increase")}
                        />
                    </div>
                ))}
            </div>
            <div
                className={
                    "grid grid-cols-12 md:gap-x-[22px] gap-y-[14px] md:gap-y-0 my-5"
                }
            >
                <div
                    className={
                        "col-span-12 md:col-span-6 p-5 rounded chart-card"
                    }
                >
                    <div className={"flex justify-between items-center mb-2"}>
                        <h3 className="flex items-center  text-[14px] leading-[20px] primary-text">
                            {formatMessage({ id: "Followers" })}
                            <ToolTipUI
                                tooltipText={formatMessage({
                                    id: "Followers",
                                })}
                            />
                        </h3>
                        <Select
                            options={[{ value: "quater", label: "Quaterly" }]}
                            defaultValue={{ value: "quater", label: "Quaterly" }}
                            label={false}
                            height={36}
                            className={"event-select min-w-[200px] !w-full text-[14px]"}
                            placeHolder={formatMessage({ id: "Select" })}
                        />
                    </div>
                    <div className="flex justify-between max-w-sm">
                        <div className="mb-2">
                            <div className="flex items-center gap-x-1 mb-3">
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="16"
                                    height="16"
                                    viewBox="0 0 16 16"
                                    fill="none"
                                >
                                    <rect width="16" height="16" rx="8" fill="#3DD883" />
                                </svg>
                                <span className="text-[14px] leading-[20px] secondary-text">
                  {formatMessage({ id: "Organic" })}
                </span>
                                <ToolTipUI
                                    tooltipText={formatMessage({
                                        id: "Organic",
                                    })}
                                />
                            </div>
                            <h3 className={"text-[24px] primary-text font-semibold mb-1"}>
                                20,000
                            </h3>
                        </div>
                        <div className="mb-2">
                            <div className="flex items-center gap-x-1 mb-3">
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="16"
                                    height="16"
                                    viewBox="0 0 16 16"
                                    fill="none"
                                >
                                    <rect width="16" height="16" rx="8" fill="#C2D24B" />
                                </svg>
                                <span className="text-[14px] leading-[20px] secondary-text">
                  {formatMessage({ id: "Paid" })}
                </span>
                                <ToolTipUI
                                    tooltipText={formatMessage({
                                        id: "Paid",
                                    })}
                                />
                            </div>
                            <h3 className={"text-[24px] primary-text font-semibold mb-1"}>
                                32,000
                            </h3>
                        </div>
                    </div>
                    <div className={"h-96"}>
                        <LineGraph />
                    </div>
                </div>
                <div
                    className={
                        "col-span-12 md:col-span-6 p-5 rounded chart-card"
                    }
                >
                    <div className={"flex justify-between items-center mb-2"}>
                        <h3 className="flex items-center  text-[14px] leading-[20px] primary-text">
                            {formatMessage({ id: "Reach By Country" })}
                            <ToolTipUI
                                tooltipText={formatMessage({
                                    id: "Reach By Country",
                                })}
                            />
                        </h3>
                        <Select
                            options={[{ value: "quater", label: "Quaterly" }]}
                            defaultValue={{ value: "quater", label: "Quaterly" }}
                            label={false}
                            height={36}
                            className={"event-select min-w-[200px] !w-full text-[14px]"}
                            placeHolder={formatMessage({ id: "Select" })}
                        />
                    </div>
                    <div className="flex justify-between max-w-sm">
                        <div className="mb-2">
                            <div className="flex items-center gap-x-1 mb-3">
                <span className="text-[14px] leading-[20px] secondary-text">
                  {formatMessage({ id: "Organic" })}
                </span>
                                <ToolTipUI
                                    tooltipText={formatMessage({
                                        id: "Organic",
                                    })}
                                />
                            </div>
                            <h3 className={"text-[24px] primary-text font-semibold mb-1"}>
                                20,000
                            </h3>
                        </div>
                        <div className="mb-2">
                            <div className="flex items-center gap-x-1 mb-3">
                <span className="text-[14px] leading-[20px] secondary-text">
                  {formatMessage({ id: "Paid" })}
                </span>
                                <ToolTipUI
                                    tooltipText={formatMessage({
                                        id: "Paid",
                                    })}
                                />
                            </div>
                            <h3 className={"text-[24px] primary-text font-semibold mb-1"}>
                                32,000
                            </h3>
                        </div>
                    </div>
                    <div className={"h-96"}>
                        {/* <Map height={300} /> */}
                    </div>
                </div>
            </div>
            <h3 className="text-[28px] font-semibold primary-text">Spends</h3>
            <div
                className={
                    "grid grid-cols-12 md:gap-x-[22px] gap-y-[14px] my-5"
                }
            >
                <div
                    className={
                        "col-span-12 md:col-span-6  xl:col-span-3 p-5 rounded chart-card"
                    }
                >
                    <div className={"flex flex-wrap gap-y-1 justify-between items-center mb-2"}>
                        <h3 className="flex items-center  text-[14px] leading-[20px] primary-text min-w-[100px]">
                            {formatMessage({ id: "Ads" })}
                            <ToolTipUI
                                tooltipText={formatMessage({
                                    id: "Ads",
                                })}
                            />
                        </h3>
                        <Select
                            options={[{ value: "quater", label: "Quaterly" }]}
                            defaultValue={{ value: "quater", label: "Quaterly" }}
                            label={false}
                            height={36}
                            className={"event-select min-w-[150px] !w-full text-[14px]"}
                            placeHolder={formatMessage({ id: "Select" })}
                        />
                    </div>
                    <h3 className={"text-[24px] primary-text font-semibold mb-1"}>
                        $300
                    </h3>
                    <p className="text-[14px] secondary-text font-normal">
                        $200 Last Six Month
                    </p>
                    <div className={"h-40"}>
                        <BarChart quater={true} />
                    </div>
                </div>
                <div
                    className={
                        "col-span-12 md:col-span-6  xl:col-span-3 p-5 rounded chart-card"
                    }
                >
                    <div className={"flex flex-wrap gap-y-1 justify-between items-center mb-2"}>
                        <h3 className="flex items-center  text-[14px] leading-[20px] primary-text min-w-[100px]">
                            {formatMessage({ id: "PR" })}
                            <ToolTipUI
                                tooltipText={formatMessage({
                                    id: "PR",
                                })}
                            />
                        </h3>
                        <Select
                            options={[{ value: "quater", label: "Quaterly" }]}
                            defaultValue={{ value: "quater", label: "Quaterly" }}
                            label={false}
                            height={36}
                            className={"event-select min-w-[150px] !w-full text-[14px]"}
                            placeHolder={formatMessage({ id: "Select" })}
                        />
                    </div>
                    <h3 className={"text-[24px] primary-text font-semibold mb-1"}>
                        $300
                    </h3>
                    <p className="text-[14px] secondary-text font-normal">
                        $200 Last Six Month
                    </p>
                    <div className={"h-40"}>
                        <BarChart quater={true} />
                    </div>
                </div>
                <div
                    className={
                        "col-span-12 md:col-span-6  xl:col-span-3 p-5 rounded chart-card"
                    }
                >
                    <div className={"flex flex-wrap gap-y-1 justify-between items-center mb-2"}>
                        <h3 className="flex items-center  text-[14px] leading-[20px] primary-text min-w-[100px]">
                            {formatMessage({ id: "Influencers" })}
                            <ToolTipUI
                                tooltipText={formatMessage({
                                    id: "Influencers",
                                })}
                            />
                        </h3>
                        <Select
                            options={[{ value: "quater", label: "Quaterly" }]}
                            defaultValue={{ value: "quater", label: "Quaterly" }}
                            label={false}
                            height={36}
                            className={"event-select min-w-[150px] !w-full text-[14px]"}
                            placeHolder={formatMessage({ id: "Select" })}
                        />
                    </div>
                    <h3 className={"text-[24px] primary-text font-semibold mb-1"}>
                        $300
                    </h3>
                    <p className="text-[14px] secondary-text font-normal">
                        $200 Last Six Month
                    </p>
                    <div className={"h-40"}>
                        <BarChart quater={true} />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Engagement;
