import React, { useState } from "react";
import { useIntl } from "react-intl";
import DateFilter from "../components/date-filter";
import { get, isEqual, map } from "lodash";
import LineChart from "../components/line-chart";
import UsersTable from "./users-table";
import { useNavigate } from "react-router-dom";
import Select from "../../../components/select/select";
import clsx from "clsx";
import { toAbsoluteUrl } from "../../../../_metronic/helpers";
import { ToolTipUI } from "../../widgets/components/UI/ToolTipUI";
import { CorothonChart } from "../../../components/cohort-chart";
import FunnelChart from "../components/funnel-chart";
import CheckboxSelect from "../components/checkbox-select";

const UsersContainer = () => {
  const { formatMessage } = useIntl();
  const navigate = useNavigate();
  const [showUsersTable, setShowUsersTable] = useState(false);
  const [activePerformanceFilter, setActivePerformanceFilter] =
    useState("personalities");
  const sortOptions = [
    { value: "popular", label: formatMessage({ id: "Most Popular" }) },
    { value: "likes", label: formatMessage({ id: "Most Liked" }) },
    { value: "interacted", label: formatMessage({ id: "Most Interacted" }) },
    { value: "created", label: formatMessage({ id: "Creation Time" }) },
    { value: "name", label: formatMessage({ id: "Name" }) },
  ];
  const topPerformance = [
    {
      id: 1,
      name: "Shahrukh Khan",
      owner: "Toshendra Sharma",
      totalVisitors: "2,000,000",
      followers: "200k",
      likes: "90k",
    },
    {
      id: 2,
      name: "Shahrukh Khan",
      owner: "Toshendra Sharma",
      totalVisitors: "2,000,000",
      followers: "200k",
      likes: "90k",
    },
    {
      id: 3,
      name: "Shahrukh Khan",
      owner: "Toshendra Sharma",
      totalVisitors: "2,000,000",
      followers: "200k",
      likes: "90k",
    },
    {
      id: 4,
      name: "Shahrukh Khan",
      owner: "Toshendra Sharma",
      totalVisitors: "2,000,000",
      followers: "200k",
      likes: "90k",
    },
    {
      id: 5,
      name: "Shahrukh Khan",
      owner: "Toshendra Sharma",
      totalVisitors: "2,000,000",
      followers: "200k",
      likes: "90k",
    },
    {
      id: 6,
      name: "Shahrukh Khan",
      owner: "Toshendra Sharma",
      totalVisitors: "2,000,000",
      followers: "200k",
      likes: "90k",
    },
    {
      id: 7,
      name: "Shahrukh Khan",
      owner: "Toshendra Sharma",
      totalVisitors: "2,000,000",
      followers: "200k",
      likes: "90k",
    },
    {
      id: 8,
      name: "Shahrukh Khan",
      owner: "Toshendra Sharma",
      totalVisitors: "2,000,000",
      followers: "200k",
      likes: "90k",
    },
    {
      id: 9,
      name: "Shahrukh Khan",
      owner: "Toshendra Sharma",
      totalVisitors: "2,000,000",
      followers: "200k",
      likes: "90k",
    },
    {
      id: 10,
      name: "Shahrukh Khan",
      owner: "Toshendra Sharma",
      totalVisitors: "2,000,000",
      followers: "200k",
      likes: "90k",
    },
  ];
  const lineChart = [
    {
      heading: "All Users",
      count: "8,31,22,625",
      increase: true,
      description: "33,662 Joined Yesterday",
      linkTitle: "Check all users",
    },
    {
      heading: "Registered users",
      count: "3,41,00,625",
      increase: true,
      description: "33,662 Verified Yesterday",
      linkTitle: "Check all users",
    },
    {
      heading: "Verified Users",
      count: "4,70,80,452",
      increase: true,
      description: "33,662 Pending action",
      linkTitle: "Check all users",
    },
    {
      heading: "Paid Personality Owners",
      count: "6,31,22,625",
      increase: true,
      description: "33,662 Paid Yesterday",
      linkTitle: "Check all users",
    },
    {
      heading: "Active Users",
      count: "8,31,22,625",
      increase: true,
      description: "33,662 Joined Yesterday",
      linkTitle: "Check all users",
    },
    {
      heading: "Daily Active Users (DAU) ",
      count: "3,41,00,625",
      increase: true,
      description: "33,662 Verified Yesterday",
      linkTitle: "Check all users",
    },
    {
      heading: "Monthly Active Users (MAU) ",
      count: "4,70,80,452",
      increase: true,
      description: "33,662 Pending action",
      linkTitle: "Check all users",
    },
    {
      heading: "Paid Chatters",
      count: "6,31,22,625",
      increase: true,
      description: "33,662 Paid Yesterday",
      linkTitle: "Check all users",
    },
  ];

  return showUsersTable ? (
    <UsersTable />
  ) : (
    <div className={'pb-10'}>
      <DateFilter />
      <div className={"grid grid-cols-12 gap-4 mt-[16px] mb-[24px]"}>
        {map(lineChart, (item: any) => (
          <div className={"col-span-12 md:col-span-6 xl:col-span-3"}>
            <LineChart
              heading={get(item, "heading")}
              count={get(item, "count")}
              description={get(item, "description")}
              tooltipText={"Test"}
              linkText={get(item, "linkTitle")}
              onClickLink={() => {
                setShowUsersTable(true);
              }}
            />
          </div>
        ))}
      </div>
      <div
        className={
          "grid grid-cols-12 md:gap-x-[22px] gap-y-[14px] md:gap-y-0 my-5"
        }
      >
        <div
          className={
            "col-span-12 lg:col-span-6  p-5 rounded  chart-card"
          }
        >
          <div
            className={
              "flex flex-col md:flex-row  md:items-center justify-between gap-y-2 mb-5"
            }
          >
            <h3 className={"text-[20px] font-semibold primary-text"}>
              Top Performance
            </h3>
            <div className={"flex items-center gap-x-2"}>
              <Select
                options={sortOptions}
                label={false}
                className={"event-select min-w-[200px] !w-full text-[14px]"}
                placeHolder={formatMessage({ id: "Select" })}
              />
            </div>
          </div>
          <div className={"h-96 overflow-y-auto custom-scroll pr-5"}>
            <table className={"table table-auto"}>
              <tbody>
                {map(topPerformance, (item) => (
                  <tr>
                    <td>
                      <div className={"flex items-center gap-x-2"}>
                        <img
                          alt="Pic"
                          src={toAbsoluteUrl(`/media/avatars/300-1.jpg`)}
                          className={"w-[50px] h-[50px] rounded"}
                        />
                        <div className={"flex flex-col gap-y-1"}>
                          <p className="leading-5 font-semibold text-[14px] primary-text">
                            {get(item, "name")}
                          </p>
                          <p className="text-[12px] secondary-text font-medium">
                            Owner: {get(item, "owner")}
                          </p>
                        </div>
                      </div>
                    </td>
                    <td>
                      <div className={"flex flex-col gap-y-1"}>
                        <p className="leading-5 font-semibold text-[14px] primary-text flex gap-x-1">
                          $88,690
                        </p>
                        <p className="text-[12px] secondary-text font-medium">
                          Earned
                        </p>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
        <div
          className={
            "col-span-12 lg:col-span-6 p-5 rounded  chart-card"
          }
        >
          <div
            className={
              "flex flex-wrap items-center justify-between gap-y-2 mb-5"
            }
          >
            <h3 className={"text-[20px] font-semibold primary-text"}>
              Top Performance
            </h3>
            <div className={"flex items-center gap-x-2"}>
              <CheckboxSelect
                  options={[
                    {
                      value: "personality_creation",
                      label: `${formatMessage({ id: "Personality Creation" })}`,
                    },
                    {
                      value: "personality_edited",
                      label: `${formatMessage({ id: "Personality Edited" })}`,
                    },
                    {
                      value: "personality_published",
                      label: `${formatMessage({ id: "Personality Published" })}`,
                    },
                    {
                      value: "personality_used",
                      label: `${formatMessage({ id: "Personality Used" })}`,
                    },
                    {
                      value: "generated_revenue",
                      label: `${formatMessage({ id: "Generated Revenue" })}`,
                    },
                  ]}
                  defaultValue={[
                    {
                      value: "personality_creation",
                      label: `${formatMessage({ id: "Personality Creation" })}`,
                    },
                    {
                      value: "personality_edited",
                      label: `${formatMessage({ id: "Personality Edited" })}`,
                    },
                    {
                      value: "personality_published",
                      label: `${formatMessage({ id: "Personality Published" })}`,
                    },
                    {
                      value: "personality_used",
                      label: `${formatMessage({ id: "Personality Used" })}`,
                    },
                    {
                      value: "generated_revenue",
                      label: `${formatMessage({ id: "Generated Revenue" })}`,
                    },
                  ]}
              />
            </div>
          </div>
          <div className={"h-96"}>
            <FunnelChart />
          </div>
        </div>
      </div>

      <div className={" p-5 rounded  chart-card"}>
        <div
          className={
            "flex flex-col md:flex-row  md:items-center justify-between gap-y-2 mb-5"
          }
        >
          <h3 className={"text-[20px] font-semibold primary-text"}>
            Top Performance
          </h3>
          <div className={"flex items-center gap-x-2"}>
            <div
              className={
                "flex  main-border rounded-[7px] primary-text font-medium"
              }
            >
              <button
                className={clsx(
                  "px-4 py-3.5 rounded-[7px]",
                  isEqual(activePerformanceFilter, "personalities") &&
                    "main-button"
                )}
                onClick={() => setActivePerformanceFilter("personalities")}
              >
                Personalities
              </button>
              <button
                className={clsx(
                  "px-4 py-3.5 rounded-[7px]",
                  isEqual(activePerformanceFilter, "chatters") && "main-button"
                )}
                onClick={() => setActivePerformanceFilter("chatters")}
              >
                Chatters
              </button>
            </div>
            <Select
              options={sortOptions}
              label={false}
              className={"event-select min-w-[200px] !w-full text-[14px]"}
              placeHolder={formatMessage({ id: "Select" })}
            />
          </div>
        </div>
        <div className={"h-96 overflow-y-auto custom-scroll pr-5"}>
          <table className={"table table-auto"}>
            <tbody>
              {map(topPerformance, (item) => (
                <tr>
                  <td>
                    <div className={"flex items-center gap-x-2"}>
                      <img
                        alt="Pic"
                        src={toAbsoluteUrl(`/media/avatars/300-1.jpg`)}
                        className={"w-[50px] h-[50px] rounded"}
                      />
                      <div className={"flex flex-col gap-y-1"}>
                        <p className="leading-5 font-semibold text-[14px] primary-text">
                          {get(item, "name")}
                        </p>
                        <p className="text-[12px] secondary-text font-medium">
                          Owner: {get(item, "owner")}
                        </p>
                      </div>
                    </div>
                  </td>
                  <td>
                    <div className={"flex flex-col gap-y-1"}>
                      <p className="leading-5 font-semibold text-[14px] primary-text flex gap-x-1">
                        {get(item, "totalVisitors")}
                        <span className="text-[10px] text-[#3DD883] flex items-center gap-x-1">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="14"
                            height="14"
                            viewBox="0 0 14 14"
                            fill="none"
                          >
                            <path
                              opacity="0.5"
                              d="M7.5332 9.98475V4.31706C7.5332 4.0325 7.29435 3.80182 6.99972 3.80182C6.70508 3.80182 6.46624 4.0325 6.46624 4.31706V9.98475C6.46624 10.2693 6.70508 10.5 6.99972 10.5C7.29435 10.5 7.5332 10.2693 7.5332 9.98475Z"
                              fill="#3DD883"
                            />
                            <path
                              d="M7.30179 5.12378L9.53405 7.27972C9.75502 7.49314 10.1133 7.49314 10.3343 7.27972C10.5552 7.06631 10.5552 6.72027 10.3343 6.50686L7.37723 3.65091C7.1689 3.4497 6.8311 3.4497 6.62278 3.65091L3.66573 6.50686C3.44476 6.72027 3.44476 7.06631 3.66573 7.27972C3.8867 7.49314 4.24498 7.49314 4.46596 7.27972L6.69821 5.12378C6.86487 4.96281 7.13513 4.96281 7.30179 5.12378Z"
                              fill="#3DD883"
                            />
                          </svg>
                          2.6%
                        </span>
                      </p>
                      <p className="text-[12px] secondary-text font-medium">
                        Total Visitors
                      </p>
                    </div>
                  </td>
                  <td>
                    {" "}
                    <div className={"flex flex-col gap-y-1"}>
                      <p className="leading-5 font-semibold text-[14px] primary-text flex gap-x-1">
                        {get(item, "followers")}
                        <span className="text-[10px] text-[#3DD883] flex items-center gap-x-1">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="14"
                            height="14"
                            viewBox="0 0 14 14"
                            fill="none"
                          >
                            <path
                              opacity="0.5"
                              d="M7.5332 9.98475V4.31706C7.5332 4.0325 7.29435 3.80182 6.99972 3.80182C6.70508 3.80182 6.46624 4.0325 6.46624 4.31706V9.98475C6.46624 10.2693 6.70508 10.5 6.99972 10.5C7.29435 10.5 7.5332 10.2693 7.5332 9.98475Z"
                              fill="#3DD883"
                            />
                            <path
                              d="M7.30179 5.12378L9.53405 7.27972C9.75502 7.49314 10.1133 7.49314 10.3343 7.27972C10.5552 7.06631 10.5552 6.72027 10.3343 6.50686L7.37723 3.65091C7.1689 3.4497 6.8311 3.4497 6.62278 3.65091L3.66573 6.50686C3.44476 6.72027 3.44476 7.06631 3.66573 7.27972C3.8867 7.49314 4.24498 7.49314 4.46596 7.27972L6.69821 5.12378C6.86487 4.96281 7.13513 4.96281 7.30179 5.12378Z"
                              fill="#3DD883"
                            />
                          </svg>
                          2.6%
                        </span>
                      </p>
                      <p className="text-[12px] secondary-text font-medium">
                        Followers
                      </p>
                    </div>
                  </td>
                  <td>
                    <div className={"flex flex-col gap-y-1"}>
                      <p className="leading-5 font-semibold text-[14px] primary-text flex gap-x-1">
                        {get(item, "likes")}
                        <span className="text-[10px] text-[#3DD883] flex items-center gap-x-1">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="14"
                            height="14"
                            viewBox="0 0 14 14"
                            fill="none"
                          >
                            <path
                              opacity="0.5"
                              d="M7.5332 9.98475V4.31706C7.5332 4.0325 7.29435 3.80182 6.99972 3.80182C6.70508 3.80182 6.46624 4.0325 6.46624 4.31706V9.98475C6.46624 10.2693 6.70508 10.5 6.99972 10.5C7.29435 10.5 7.5332 10.2693 7.5332 9.98475Z"
                              fill="#3DD883"
                            />
                            <path
                              d="M7.30179 5.12378L9.53405 7.27972C9.75502 7.49314 10.1133 7.49314 10.3343 7.27972C10.5552 7.06631 10.5552 6.72027 10.3343 6.50686L7.37723 3.65091C7.1689 3.4497 6.8311 3.4497 6.62278 3.65091L3.66573 6.50686C3.44476 6.72027 3.44476 7.06631 3.66573 7.27972C3.8867 7.49314 4.24498 7.49314 4.46596 7.27972L6.69821 5.12378C6.86487 4.96281 7.13513 4.96281 7.30179 5.12378Z"
                              fill="#3DD883"
                            />
                          </svg>
                          2.6%
                        </span>
                      </p>
                      <p className="text-[12px] secondary-text font-medium">
                        Likes
                      </p>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
      <div
        className={
          "grid grid-cols-12 md:gap-x-[22px] gap-y-[14px] md:gap-y-0 mt-5"
        }
      >
        <div
          className={
            "col-span-12 md:col-span-6 chart-card p-[8px] md:p-5 rounded"
          }
        >
          <div className={"flex justify-between items-center"}>
            <h3
              className={
                "text-[16px] leading-6 primary-text font-semibold flex items-center"
              }
            >
              {formatMessage({ id: "Revenue retention by cohort" })}
              <ToolTipUI
                tooltipText={formatMessage({
                  id: "GLOBAL.TOOLTIP.MARKETPLACE.OVERVIEW.REVENUE_RETENTION",
                })}
              />
            </h3>
            <span className={"text-[14px] leading-5 secondary-text"}>
              {formatMessage({ id: "Last 12 months" })}
            </span>
          </div>
          <CorothonChart className={""} />
        </div>
        <div
          className={
            "col-span-12 md:col-span-6 chart-card p-[8px] md:p-5 rounded "
            // cohort
          }
        >
          <div className={"flex justify-between items-center"}>
            <h3
              className={
                "text-[16px] leading-6 primary-text font-semibold flex items-center"
              }
            >
              {formatMessage({ id: "Subscriber retention by cohort" })}
              <ToolTipUI
                tooltipText={formatMessage({
                  id: "GLOBAL.TOOLTIP.MARKETPLACE.OVERVIEW.SUBSCRIBER_RETENTION",
                })}
              />
            </h3>
            <span className={"text-[14px] leading-5 secondary-text"}>
              {formatMessage({ id: "Last 12 months" })}
            </span>
          </div>
          <CorothonChart className={""} />
        </div>
      </div>
    </div>
  );
};

export default UsersContainer;
