import axios from "axios"
import { ResponseType } from "../../../core/_models";
import { RechargeHistoryItem } from "./_models";
const API_URL = process.env.REACT_APP_BASE_API_URL;

export const USER_CREDITS = `${API_URL}/user-credits`
export const ACCESS_PAYMENT_PORTAL = `${USER_CREDITS}/access-portal`
export const PROCESS_CREDITS = `${USER_CREDITS}/process`
export const DOWNLOAD_INVOICE = `${USER_CREDITS}/download-invoice`
export const FAN_PLANS = `${API_URL}/fan-plans`

export const getUserBalance = async()=>{
  return await axios.get<ResponseType<{amount: number}>>(USER_CREDITS,{
      validateStatus(status) {
        return true;
      },
 })
}

export const accessPaymentPortal = async(amount:number)=>{
    return await axios.post<ResponseType<string>>(ACCESS_PAYMENT_PORTAL,{
        amount,
        currencyCode:"USD",
        redirectUrl: window.location.href
    },{
        validateStatus(status) {
          return true;
        },
   })
}

export const createRazorpayOrder = async(amount:number)=>{
  return await axios.post<ResponseType<string>>(`${USER_CREDITS}/razorpay/create`,{
    amount,
},{
    validateStatus(status) {
      return true;
    },
})
}

export const processRazorpayOrder = async(data:any)=>{
  return await axios.post<ResponseType<string>>(`${USER_CREDITS}/razorpay/process`,data,{
    validateStatus(status) {
      return true;
    },
})
}

export const createPhonepePortal = async(amount:number)=>{
  return await axios.post<ResponseType<string>>(`${USER_CREDITS}/phonepe/create`,{
      amount,
      redirectUrl: window.location.href
  },{
      validateStatus(status) {
        return true;
      },
 })
}
type cryptoRechargeBody={
  personalityId:string;
  name: string;
  description: string;
  amount: number;
}
export const createCoinbasePortal = async(body:cryptoRechargeBody)=>{
  return await axios.post<ResponseType<string>>(`${USER_CREDITS}/crypto-charge-create`,{
    ...body,
    successUrl: `${window.location.href}?success=true`,
    failedUrl: `${window.location.href}?failed=true`
},{
    validateStatus(status) {
      return true;
    },
})
}


export const processCredits = async(hostedPageId:string)=>{
    return await axios.post<ResponseType<string>>(PROCESS_CREDITS,{
       hostedPageId
    },{
        validateStatus(status) {
          return true;
        },
   })
}

export const getRechargeHistory = async(params:{[key:string]: string | number})=>{
  return await axios.get<ResponseType<{count: number, history: RechargeHistoryItem[]}>>(`${USER_CREDITS}/recharge-history`,{
    params:{
      ...params
    },
      validateStatus(status) {
        return true;
      },
 })
}

export const getUsageHistory = async(params:{[key:string]: string | number})=>{
  return await axios.get<ResponseType<{count: number, history: RechargeHistoryItem[]}>>(`${API_URL}/chat-sessions/history`,{
    params:{
      ...params
    },
      validateStatus(status) {
        return true;
      },
 })
}


export const downloadInvoice = async(invoiceId:string)=>{
  return await axios.post<ResponseType<string>>(DOWNLOAD_INVOICE,{
      invoiceId,
  },{
      validateStatus(status) {
        return true;
      },
 })
}


export const getApiSubscriptionHistory = async(params:{[key:string]: string | number})=>{
  return await axios.get<ResponseType<{count: number, rows: RechargeHistoryItem[]}>>(`${API_URL}/api-subscription/history`,{
    params:{
      ...params
    },
      validateStatus(status) {
        return true;
      },
 })
}


export const getPersonalitySubscriptionHistory = async(params:{[key:string]: string | number})=>{
  return await axios.get<ResponseType<{count: number, rows: RechargeHistoryItem[]}>>(FAN_PLANS,{
    params:{
      ...params
    },
    validateStatus(status) {
      return true;
    },
 })
}


export const downloadSubscriptionInvoice = async(subscriptionId:string)=>{
  return await axios.get<ResponseType<string>>(`${FAN_PLANS}/invoice`,{
      params:{sid: subscriptionId},
      validateStatus(status) {
        return true;
      },
  },
  
 )
}


export const cancelFanSubscription = async(subscriptionId:string)=>{
  return await axios.delete<ResponseType<any>>(FAN_PLANS,{
      params:{sid: subscriptionId},
      validateStatus(status) {
        return true;
      },
  }
 )
}





