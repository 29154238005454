import React, { useEffect, useRef, useState } from "react";
import Container from "../../containers/ai-profile";
import TagManager from "react-gtm-module";
import useGTM from "../../../../hooks/useGTM";
import { GTMEvent } from "../../../../hooks/gtm_helpers";
import { useAuth } from "../../../auth";
import request from "../../../../core/_apis";
const Index = () => {

  // use this gtm id from personality settings if any
  // let gtmId:any = null
  const gtmIdRef = useRef<string | null>(null);
  const gtmInitRef = useRef(false);
  const {personalityInView,ipStackCountry,currentUser} = useAuth()

  useEffect(() => {
    // const scriptElements = Array.from(document.getElementsByTagName('script')).filter(element => {
    //   return element.src.includes(gtmId);
    // });

    // request.post('/reports/personality/visit',{
    //   country: ipStackCountry.country_code,
    //   userId: currentUser?.userId
    // },{
    //   headers: {
    //     'x-personality-id' : personalityInView?.personalityId
    //   }
    // })

    let gtmId = personalityInView?.marketplaceSettings?.gtm
    if(!gtmId){return}
    if (gtmId !== gtmIdRef.current) {
      gtmIdRef.current = gtmId;
      gtmInitRef.current = false;
    }
    // Initialize the second GTM container
    if (!gtmInitRef.current) {
      gtmInitRef.current = true;
      TagManager.initialize({
        gtmId: gtmId,
        dataLayerName: 'userDataLayer'
      })
    }

    // Clean up the GTM initialization when the component is unmounted
    return () => {
      removeNoscriptTags(gtmId)
      removeScriptTagsBySrcText(gtmId)
      removeScriptTagsByText(gtmId)
      // remove gtm code from head
    };

  }, [personalityInView?.marketplaceSettings?.gtm])
  useGTM(false,[{event:GTMEvent.MarketplacePersonalityPageView,meta:{page_url : window.location.href}}])
  return (
    <div>
      <Container />
    </div>
  );
};

function removeNoscriptTags(gtmId:any) {
  var noscriptTags = document.getElementsByTagName('noscript');

  for (var i = noscriptTags.length - 1; i >= 0; i--) {
    var noscriptTag = noscriptTags[i];
    var innerHTML = noscriptTag.innerHTML;

    if (innerHTML.includes(gtmId)) {
      noscriptTag?.parentNode?.removeChild(noscriptTag);
    }
  }
}

function removeScriptTagsByText(gtmId:any) {
  var noscriptTags = document.getElementsByTagName('script');

  for (var i = noscriptTags.length - 1; i >= 0; i--) {
    var noscriptTag = noscriptTags[i];
    var innerHTML = noscriptTag.innerHTML;

    if (innerHTML.includes(gtmId)) {
      noscriptTag?.parentNode?.removeChild(noscriptTag);
    }
  }
}

function removeScriptTagsBySrcText(text:any) {
  var scriptTags = document.getElementsByTagName('script');

  for (var i = scriptTags.length - 1; i >= 0; i--) {
    var scriptTag = scriptTags[i];
    var src = scriptTag.getAttribute('src');

    if (src && src.includes(text)) {
      scriptTag?.parentNode?.removeChild(scriptTag);
    }
  }
}

export default Index;
