import React, { useState } from "react";
import TextInput from "../../../widgets/components/Input/TextInput";
import { useIntl } from "react-intl";
import { FieldArray, Form, Formik } from "formik";
import TextArea from "../../../widgets/components/Input/TextArea";
import { InfoCard } from "../../../widgets/components/UI/InfoCard";
import { isEqual } from "lodash";
import * as Yup from "yup";
import { useAuth } from "../../../auth";
import { updatePersonalityInfo } from "../../../profile/core/_requests";
import { toast } from "react-toastify";
import useGTM from "../../../../hooks/useGTM";
import { GTMEvent } from "../../../../hooks/gtm_helpers";
const Welcome: React.FC<any> = ({ setOpenEdit, personalityData }) => {
  const { formatMessage } = useIntl();
  const {
    referenceData,
    setSelectedPersonality,
    selectedPersonality: updatedData,
  } = useAuth();
  const [disableSave, setDisableSave] = useState(false);
  const {dataLayerPush} = useGTM()
  personalityData = updatedData || personalityData;

  const onSubmit = async (values: any, { setSubmitting }: any) => {
    setDisableSave(true);
    setSubmitting(true);
    try {
      let {
        data: { success, data },
      } = await updatePersonalityInfo({
        personalityId: personalityData.personalityId,
        personalityJson: {
          ...personalityData.personalityJson,
          welcome_message: values,
        },
      });
      if (success) {
        setSelectedPersonality(data);
        toast.success(
          formatMessage({ id: "Welcome messages updated successfully" })
        );
      } else {
        setSubmitting(false);
        toast.error(
          formatMessage({ id: "Error when updating Welcome messages" }),
          {
            autoClose: false,
            closeButton: true, // Display close button (cross icon)
          }
        );
      }
      setDisableSave(false);
      dataLayerPush(GTMEvent.AIPersonalityEditSuccessful,{"tab": "welcome-messages"})
    } catch (e) {
      toast.error(
        formatMessage({ id: "Error when updating Welcome Messages" }),
        {
          autoClose: false,
          closeButton: true, // Display close button (cross icon)
        }
      );
      setSubmitting(false);
      setDisableSave(false);
      dataLayerPush(GTMEvent.AIPersonalityEditFailed,{"tab": "welcome-messages"})
    }
  };
  let initValues = personalityData?.personalityJson?.welcome_message || {};

  const welcomeDetailsValidation = Yup.object().shape({
    welcome_messages_this_ai_personality_use_regularly: Yup.array()
      .of(
        Yup.string()
          // .min(5, "Welcome message must be at least 5 characters")
          .max(100, "Welcome message cannot exceed 100 characters")
      )
      // .min(1, "At least one welcome message is required")
      .max(10, "Maximum of 10 welcome messages allowed"),
    sample_questions_users_can_ask: Yup.array()
      .of(
        Yup.string()
          // .min(10, "Sample question must be at least 30 characters")
          .max(200, "Sample question cannot exceed 200 characters")
      )
      // .min(1, "At least one sample question is required")
      .max(10, "Maximum of 10 sample questions allowed"),
  });
  return (
    <Formik
      initialValues={initValues}
      onSubmit={onSubmit}
      validationSchema={welcomeDetailsValidation}
      validateOnMount
    >
      {(formik) => {
        return (
          <Form>
            <div className={"grid grid-cols-12 gap-y-[20px] md:gap-x-[40px]"}>
              <div className={"col-span-12 md:col-span-7 order-0"}>
                <div className={"tab-container-2 p-[14px] md:p-[20px] rounded"}>
                  <h4
                    className={
                      "text-[16px] leading-6 font-medium primary-text mb-[16px]"
                    }
                  >
                    {formatMessage({
                      id: "Welcome Message",
                    })}
                  </h4>
                  <FieldArray
                    name="welcome_messages_this_ai_personality_use_regularly"
                    render={(arrayHelpers) => {
                      return (
                        <>
                          {(
                            formik.values
                              ?.welcome_messages_this_ai_personality_use_regularly || [
                              "",
                            ]
                          ).map((_: any, index: number) => (
                            <div key={index}>
                              <div
                                className={
                                  "flex flex-col md:flex-row  items-start gap-x-[20px] gap-y-[12px]"
                                }
                              >
                                <div className={"flex-grow w-full md:w-auto"}>
                                  <TextArea
                                    label={formatMessage({
                                      id: "Message" + " " + (index + 1),
                                    })}
                                    fieldName={`welcome_messages_this_ai_personality_use_regularly[${index}]`}
                                    placeholder={formatMessage({
                                      id: "Write a paragraph describing who your personality is",
                                    })}
                                    formik={formik}
                                    // defaultValue={_}

                                    toolTipText={formatMessage({
                                      id: "GLOBAL.TOOLTIP.EDIT_AI_PERSONALITY.WELCOME_MESSAGE",
                                    })}
                                    className={"!min-h-[140px]"}
                                  />
                                </div>
                                <div
                                  className={
                                    "flex justify-end gap-[12px] md:mt-[26px]"
                                  }
                                >
                                  <button
                                    type="button"
                                    className={
                                      "p-[6px] bg-[#FFFFFF1A] rounded-[6px]"
                                    }
                                    onClick={() => {
                                      if (!isEqual(index, 0))
                                        arrayHelpers.remove(index);
                                    }}
                                  >
                                    <svg
                                      width="24"
                                      height="24"
                                      viewBox="0 0 24 24"
                                      fill="none"
                                      xmlns="http://www.w3.org/2000/svg"
                                    >
                                      <path
                                        d="M4 7H20M10 11V17M14 11V17M5 7L6 19C6 19.5304 6.21071 20.0391 6.58579 20.4142C6.96086 20.7893 7.46957 21 8 21H16C16.5304 21 17.0391 20.7893 17.4142 20.4142C17.7893 20.0391 18 19.5304 18 19L19 7M9 7V4C9 3.73478 9.10536 3.48043 9.29289 3.29289C9.48043 3.10536 9.73478 3 10 3H14C14.2652 3 14.5196 3.10536 14.7071 3.29289C14.8946 3.48043 15 3.73478 15 4V7"
                                        stroke="#FFFFFFA6"
                                        strokeWidth="1.6"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                      />
                                    </svg>
                                  </button>
                                  {/* <button
                                    type="button"
                                    className={
                                      "p-[6px] bg-[#FFFFFF1A] rounded-[6px]"
                                    }
                                  >
                                    <svg
                                      width="24"
                                      height="24"
                                      viewBox="0 0 24 24"
                                      fill="none"
                                      xmlns="http://www.w3.org/2000/svg"
                                    >
                                      <g clipPath="url(#clip0_344_35631)">
                                        <path
                                          d="M17 21H7M17 21H17.803C18.921 21 19.48 21 19.907 20.782C20.284 20.59 20.59 20.284 20.782 19.908C21 19.481 21 18.921 21 17.803V9.22C21 8.77 21 8.545 20.952 8.331C20.9094 8.14007 20.839 7.95643 20.743 7.786C20.637 7.596 20.487 7.431 20.193 7.104L17.438 4.042C17.097 3.664 16.924 3.472 16.717 3.334C16.5303 3.21012 16.3241 3.11851 16.107 3.063C15.863 3 15.6 3 15.075 3H6.2C5.08 3 4.52 3 4.092 3.218C3.71565 3.40969 3.40969 3.71565 3.218 4.092C3 4.52 3 5.08 3 6.2V17.8C3 18.92 3 19.48 3.218 19.907C3.41 20.284 3.715 20.59 4.092 20.782C4.519 21 5.079 21 6.197 21H7M17 21V17.197C17 16.079 17 15.519 16.782 15.092C16.5899 14.7156 16.2836 14.4096 15.907 14.218C15.48 14 14.92 14 13.8 14H10.2C9.08 14 8.52 14 8.092 14.218C7.71565 14.4097 7.40969 14.7157 7.218 15.092C7 15.52 7 16.08 7 17.2V21M15 7H9"
                                          stroke="#FFFFFFA6"
                                          strokeWidth="1.6"
                                          strokeLinecap="round"
                                          strokeLinejoin="round"
                                        />
                                      </g>
                                      <defs>
                                        <clipPath id="clip0_344_35631">
                                          <rect
                                            width="24"
                                            height="24"
                                            fill="white"
                                          />
                                        </clipPath>
                                      </defs>
                                    </svg>
                                  </button> */}
                                </div>
                              </div>
                            </div>
                          ))}
                          <div className={"flex justify-end mt-[16px]"}>
                            <button
                              type="button"
                              className={
                                "bg-[#C2D24B] text-black text-[13px] leading-[20px] font-medium  py-[8px] w-full  md:w-[180px] rounded"
                              }
                              onClick={() => arrayHelpers.push("")}
                            >
                              {formatMessage({
                                id: "Add More Messages",
                              })}
                            </button>
                          </div>
                        </>
                      );
                    }}
                  />
                </div>
                <div className={"tab-container-2 p-[14px] md:p-[20px] rounded mt-[20px]"}>
                  <h4
                    className={
                      "text-[16px] leading-6 font-medium primary-text  mb-[16px]"
                    }
                  >
                    {formatMessage({
                      id: "Define some questions that User can ask",
                    })}
                  </h4>
                  <FieldArray
                    name="sample_questions_users_can_ask"
                    render={(arrayHelpers) => {
                      return (
                        <>
                          {(
                            formik.values?.sample_questions_users_can_ask || [
                              "",
                            ]
                          ).map((_: any, index: number) => (
                            <div key={index}>
                              <div
                                className={
                                  "flex flex-col md:flex-row  items-start  gap-x-[20px]"
                                }
                              >
                                <div className={"flex-grow w-full md:w-auto"}>
                                  {/* <TextArea
                                  label={formatMessage({
                                    id: "Question" + " " + (index + 1),
                                  })}
                                  fieldName={`sample_questions_users_can_ask[${index}]`}
                                  placeholder={formatMessage({
                                    id: "Write a paragraph describing who your personality is",
                                  })}
                                  formik={formik}
                                  defaultValue={_}
                                  toolTipText={"text"}
                                  className={"!max-h-[30px]"}
                                /> */}
                                  <TextInput
                                    isFieldArray={true}
                                    fieldType={"text"}
                                    fieldName={`sample_questions_users_can_ask[${index}]`}
                                    value={
                                      "Tell me about your experience on working on Circus sets"
                                    }
                                    formik={formik}
                                    placeholder={formatMessage({
                                      id: "Tell me about your experience on working on Circus sets",
                                    })}
                                    label={formatMessage({
                                      id: "Question" + " " + (index + 1),
                                    })}
                                    toolTipText={formatMessage({
                                      id: "GLOBAL.TOOLTIP.EDIT_AI_PERSONALITY.WELCOME_QUESTION",
                                    })}
                                    isStarRequired={true}
                                  />
                                </div>
                                <div
                                  className={
                                    "flex justify-end gap-[12px] md:mt-[29px] mb-4 md:mb-0"
                                  }
                                >
                                  <button
                                    type="button"
                                    className={
                                      "p-[6px] bg-[#FFFFFF1A] rounded-[6px]"
                                    }
                                    onClick={() => {
                                      if (!isEqual(index, 0))
                                        arrayHelpers.remove(index);
                                    }}
                                  >
                                    <svg
                                      width="24"
                                      height="24"
                                      viewBox="0 0 24 24"
                                      fill="none"
                                      xmlns="http://www.w3.org/2000/svg"
                                    >
                                      <path
                                        d="M4 7H20M10 11V17M14 11V17M5 7L6 19C6 19.5304 6.21071 20.0391 6.58579 20.4142C6.96086 20.7893 7.46957 21 8 21H16C16.5304 21 17.0391 20.7893 17.4142 20.4142C17.7893 20.0391 18 19.5304 18 19L19 7M9 7V4C9 3.73478 9.10536 3.48043 9.29289 3.29289C9.48043 3.10536 9.73478 3 10 3H14C14.2652 3 14.5196 3.10536 14.7071 3.29289C14.8946 3.48043 15 3.73478 15 4V7"
                                        stroke="#FFFFFFA6"
                                        strokeWidth="1.6"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                      />
                                    </svg>
                                  </button>
                                  {/* <button
                                    type="button"
                                    className={
                                      "p-[6px] bg-[#FFFFFF1A] rounded-[6px]"
                                    }
                                  >
                                    <svg
                                      width="24"
                                      height="24"
                                      viewBox="0 0 24 24"
                                      fill="none"
                                      xmlns="http://www.w3.org/2000/svg"
                                    >
                                      <g clipPath="url(#clip0_344_35631)">
                                        <path
                                          d="M17 21H7M17 21H17.803C18.921 21 19.48 21 19.907 20.782C20.284 20.59 20.59 20.284 20.782 19.908C21 19.481 21 18.921 21 17.803V9.22C21 8.77 21 8.545 20.952 8.331C20.9094 8.14007 20.839 7.95643 20.743 7.786C20.637 7.596 20.487 7.431 20.193 7.104L17.438 4.042C17.097 3.664 16.924 3.472 16.717 3.334C16.5303 3.21012 16.3241 3.11851 16.107 3.063C15.863 3 15.6 3 15.075 3H6.2C5.08 3 4.52 3 4.092 3.218C3.71565 3.40969 3.40969 3.71565 3.218 4.092C3 4.52 3 5.08 3 6.2V17.8C3 18.92 3 19.48 3.218 19.907C3.41 20.284 3.715 20.59 4.092 20.782C4.519 21 5.079 21 6.197 21H7M17 21V17.197C17 16.079 17 15.519 16.782 15.092C16.5899 14.7156 16.2836 14.4096 15.907 14.218C15.48 14 14.92 14 13.8 14H10.2C9.08 14 8.52 14 8.092 14.218C7.71565 14.4097 7.40969 14.7157 7.218 15.092C7 15.52 7 16.08 7 17.2V21M15 7H9"
                                          stroke="#FFFFFFA6"
                                          strokeWidth="1.6"
                                          strokeLinecap="round"
                                          strokeLinejoin="round"
                                        />
                                      </g>
                                      <defs>
                                        <clipPath id="clip0_344_35631">
                                          <rect
                                            width="24"
                                            height="24"
                                            fill="white"
                                          />
                                        </clipPath>
                                      </defs>
                                    </svg>
                                  </button> */}
                                </div>
                              </div>
                            </div>
                          ))}
                          <div className={"flex justify-end mt-[16px]"}>
                            <button
                              type="button"
                              className={
                                "bg-[#C2D24B] text-black text-[13px] leading-[20px] font-medium  py-[8px] w-full  md:w-[180px] rounded"
                              }
                              onClick={() => arrayHelpers.push("")}
                            >
                              {formatMessage({
                                id: "Add More Question",
                              })}
                            </button>
                          </div>
                        </>
                      );
                    }}
                  />
                </div>
              </div>

              <div
                className={
                  "col-span-12 md:col-span-5 flex flex-col justify-start gap-[20px]"
                }
              >
                <div
                  className={
                    "flex justify-end gap-[10px] md:order-1 md:mt-auto"
                  }
                >
                  {/* <button
                    onClick={() => setOpenEdit(false)}
                    className={
                      "main-button-primary-light text-[14px] leading-5 font-medium py-[12px] w-1/2 md:w-[128px] rounded"
                    }
                  >
                    {formatMessage({
                      id: "Cancel",
                    })}
                  </button> */}
                  <button
                    type="submit"
                    className={`main-button text-[14px] leading-5 font-medium py-[12px] w-full sm:w-1/2  md:w-[140px] rounded ${
                      disableSave ? "opacity-75" : ""
                    }`}
                    disabled={disableSave}
                  >
                    {formatMessage({
                      id: `${disableSave ? "Please Wait" : "Save"}`,
                    })}
                  </button>
                </div>
                <InfoCard
                  title={formatMessage({
                    id: "Understanding Welcome Message Section of AI Personality",
                  })}
                  desc={
                    <>
                      {formatMessage({
                        id: 'In Kamoto.AI, the "Welcome Message” section allows you to create a captivating and customized first impression for users interacting with your AI personality. You can define a list of default welcome messages that your AI personality will send at the beginning of each chat, setting the tone and establishing a friendly and engaging atmosphere. \n',
                      })}
                      <br></br>
                      <br></br>
                      {formatMessage({
                        id: "Additionally, you have the option to define suggested questions that users can ask, providing them with conversation starters and guiding them towards meaningful interactions. By personalizing the welcome experience, you can make a lasting impact and ensure that users feel welcomed, valued, and excited to engage with your AI personality.  ",
                      })}
                      <br></br>
                      <br></br>
                    </>
                  }
                  slug={""}
                  isAnchor={
                    "https://help.kamoto.ai/en/articles/7941937-exploring-welcome-message-tab-in-ai-personalities"
                  }
                />
              </div>
            </div>
          </Form>
        );
      }}
    </Formik>
  );
};

export default Welcome;
