import axios from "axios"
const API_URL = process.env.REACT_APP_BASE_API_URL;

export const CHAT_URL = `${API_URL}/chats`
export const CHAT_COMPLETION = `${API_URL}/chat-completions`
export const WIDGET_URL =   `${API_URL}/widgets`
export type ResponseType<T> = {
    success: boolean;
    data: T;
    errors: string[];
};


export const addWidgetChat = async(widgetId:string,personalityId:string,messages:{[key:string]:string}[])=>{
  return await axios.post(CHAT_COMPLETION,{
    messages
  },{
    validateStatus(status) {
      return true;
    },
    headers:{
      'x-widget-id':widgetId,
      'x-personality-id':personalityId
    }})
}

export const getWidgetByIdForChat = async(widgetId:string)=>{
  return await axios.get<ResponseType<any>>(`${WIDGET_URL}/${widgetId}/chat`,{
    validateStatus(status) {
      return true;
    }
  })
}