import React, { useEffect, useState } from 'react'
import SpeechRecognition, { useSpeechRecognition } from 'react-speech-recognition';
import { useAuth } from '../modules/auth';
import { GTMEvent } from './gtm_helpers';
import useGTM from './useGTM';

const useSpeech2Text = (submitText = () => {},setMessage:any) => {
    const {dataLayerPush} = useGTM()
    const [isSupported, setIsSupported] = useState<boolean>(true)
    const {pauseListening,setPauseListening} = useAuth()
    const [voiceText, setVoiceText] = useState<string>('')
    const [lastTranscript, setLastTranscript] = useState<string>('');
    const [listening,setListening] = useState(false);

    const {
        transcript,
        listening:recognitionStatus,
        resetTranscript,
        browserSupportsSpeechRecognition,
    } = useSpeechRecognition({ clearTranscriptOnListen: true });

    useEffect(() => {
        if (!browserSupportsSpeechRecognition) {
            setIsSupported(false)
        }
    }, [])

    useEffect(() => {
        (async () =>{
            if(!listening){
                return;
            }
            if(pauseListening){
                await SpeechRecognition.stopListening()
                resetTranscript()
            }else{
                setVoiceText('')
                setMessage('')
                await SpeechRecognition.startListening({ continuous: true })
            }
        })()
    },[pauseListening])

    useEffect(() => {
        // set transcript text if it is not empty
        if(pauseListening){
            return;
        }
        if(transcript){
            setVoiceText(transcript)
            setMessage(transcript)
        }
        setLastTranscript(transcript);
        console.log(transcript,lastTranscript)
        const timeoutId = setTimeout(() => {
            if (transcript === lastTranscript) {
                if(transcript){
                    submitText()
                    setMessage('')
                    setPauseListening(true)
                    setVoiceText('')
                }
                // stopListening();
                // if(transcript){
                //     dataLayerPush(GTMEvent.MicTurnOffSuccessful)
                // }
            }
        }, parseInt(process.env.REACT_APP_SPEECH_TO_TEXT_AUTOSUBMIT_IN_MS || "2000"));
        return () => clearTimeout(timeoutId);
    }, [transcript, lastTranscript,pauseListening]);

    const startListening = async () => {
        setVoiceText('')
        setMessage('')
        setListening(true)
        await SpeechRecognition.startListening({ continuous: true })
    }

    const stopListening = async () => {
        setListening(false)
        await SpeechRecognition.stopListening()
        resetTranscript()
    }

    return {
        startListening,
        stopListening,
        voiceText,
        listening,
        isSupported,
        // setPauseListening
    }
}

export default useSpeech2Text