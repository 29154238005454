/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useRef } from "react";
import ApexCharts, { ApexOptions } from "apexcharts";
import {
  getCSS,
  getCSSVariableValue,
} from "../../../../../../_metronic/assets/ts/_utils";
import { useThemeMode } from "../../../../../../_metronic/partials";
import { isEqual } from "lodash";

type Props = {
  className: string;
  categories: any;
  visitCounts: any;
};

const Index: React.FC<Props> = ({ className, categories, visitCounts }) => {
  const chartRef = useRef<HTMLDivElement | null>(null);
  const { mode } = useThemeMode();
  const refreshMode = () => {
    if (!chartRef.current) {
      return;
    }

    const height = parseInt(getCSS(chartRef.current, "height"));

    const chart = new ApexCharts(
      chartRef.current,
      getChartOptions(height, categories, mode,visitCounts)
    );
    if (chart) {
      chart.render();
    }

    return chart;
  };

  useEffect(() => {
    const chart = refreshMode();

    return () => {
      if (chart) {
        chart.destroy();
      }
    };
  }, [chartRef, mode]);

  return <div ref={chartRef} id="" style={{ height: "", width: "100%" }}></div>;
};

export default Index;

function getChartOptions(
  height: number,
  categories: any,
  mode: any,
  visitCounts: any
): ApexOptions {
  return {
    series: [
      {
        data: visitCounts &&  visitCounts?.length > 0 ?  visitCounts : [400, 430, 448, 470, 540, 580, 690, 1100, 1200, 1380],
      },
    ],

    chart: {
      type: "bar",
      height: 260,
      stacked: true,
      toolbar: { show: false },
    },
    dataLabels: {
      enabled: true,
      formatter: function (val) {
        return `${val}`;
      },
      offsetX: 30,
      style: {
        fontSize: "12px",
        colors: ["#FFFFFFA6"],
        fontWeight: 400,
      },
    },
    plotOptions: {
      bar: {
        borderRadius: 0,
        horizontal: true,
        dataLabels: {
          position: "top", // top, center, bottom
        },
      },
    },
    fill: {
      colors: ["#C2D24B"],
    },
    grid: {
      show: false,
    },

    xaxis: {
      categories: categories,
      labels: {
        show: false,
      },
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
    },

    yaxis: {
      labels: {
        show: true,
        style: {
          colors: isEqual(mode, "light") ? "" : "#FFFFFFA6",
          fontSize: "12px",
        },
      },
    },
  };
}
