import React, {useEffect, useState} from 'react';
import {useNavigate} from "react-router-dom";
import SplashScreen from "../components/splash-screen";
import Footer from "../components/footer";

const MatchedPage = () => {
    const navigate = useNavigate()
    const [isLoading, setIsLoading] = useState(true)
    const [isPending, setIsPending] = useState(false)

    useEffect(() => {
        setTimeout(()=>{
            setIsLoading(false)
        },2000)
    }, []);

    if (isLoading){
        return  <SplashScreen/>
    }
    return (
        <div className={'flex flex-col justify-end bg-image-matched  h-full w-full'}>
            <div className={'recharge-footer-container px-5 flex flex-col justify-end'}>
                <div className={'recharge-input-container p-3 pb-4'}>
                    <div className="mb-3 flex items-center gap-x-2">
                        <h2 className="text-[20px] font-semibold text-white">You matched</h2>
                    </div>
                    <p className="mb-3 text-[#FFFFFFCC]">Let’s get to know each other better! </p>
                    <button className="bg-[#399717] py-3 rounded cursor-pointer mb-3 w-full flex items-center justify-center gap-x-3" onClick={()=>{  navigate('/call/ringing.json')}}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="25" height="24" viewBox="0 0 25 24" fill="none">
                            <g clipPath="url(#clip0_10665_33417)">
                                <path d="M21.5 16.42V19.956C21.5001 20.2092 21.4042 20.453 21.2316 20.6382C21.059 20.8234 20.8226 20.9363 20.57 20.954C20.133 20.984 19.776 21 19.5 21C10.663 21 3.5 13.837 3.5 5C3.5 4.724 3.515 4.367 3.546 3.93C3.56372 3.67744 3.67658 3.44101 3.8618 3.26841C4.04703 3.09581 4.29082 2.99989 4.544 3H8.08C8.20404 2.99987 8.3237 3.04586 8.41573 3.12902C8.50776 3.21218 8.5656 3.32658 8.578 3.45C8.601 3.68 8.622 3.863 8.642 4.002C8.84073 5.38892 9.248 6.73783 9.85 8.003C9.945 8.203 9.883 8.442 9.703 8.57L7.545 10.112C8.86445 13.1865 11.3145 15.6365 14.389 16.956L15.929 14.802C15.9919 14.714 16.0838 14.6509 16.1885 14.6237C16.2932 14.5964 16.4042 14.6068 16.502 14.653C17.767 15.2539 19.1156 15.6602 20.502 15.858C20.641 15.878 20.824 15.899 21.052 15.922C21.1752 15.9346 21.2894 15.9926 21.3723 16.0846C21.4553 16.1766 21.5001 16.2961 21.5 16.42Z" fill="white"/>
                            </g>
                            <defs>
                                <clipPath id="clip0_10665_33417">
                                    <rect width="24" height="24" fill="white" transform="translate(0.5)"/>
                                </clipPath>
                            </defs>
                        </svg>
                        <h5 className={'text-[14px] font-semibold text-white'} >Call Me Now</h5>
                    </button>

                    <div className="flex justify-between items-center">
                        <p className="text-[#FFFFFFCC]">15 minutes available</p>

                        {isPending ? <p  className="text-[#FFFFFFCC]">Please Wait...</p>:
                            <button onClick={()=>{
                                setIsPending(true)
                                setTimeout(()=>{
                                    navigate('/call/add-credit')
                                    setIsPending(false)
                                },1500)
                            }} className="flex items-center gap-x-1">
                                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                    <circle cx="10" cy="10" r="10" fill="#C2D24B"/>
                                    <rect x="9" y="4" width="2" height="12" fill="black"/>
                                    <rect x="4" y="11" width="2" height="12" transform="rotate(-90 4 11)" fill="black"/>
                                </svg>
                                <h4 className="text-[#C2D24B] text-[16px] font-medium">Add Credits</h4>
                            </button>}
                    </div>
                </div>
                <Footer/>
            </div>
        </div>
    );
};

export default MatchedPage;