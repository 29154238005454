import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import "../../../profile/styles/index.scss";
import { toAbsoluteUrl } from "../../../../../_metronic/helpers/AssetHelpers";
import TextInput from "../../../widgets/components/Input/TextInput";
import { Form, Formik, useField } from "formik";
import * as Yup from "yup";
import { useIntl } from "react-intl";
import { SelectInput } from "../../../widgets/components/Input/SelectInput";
import {
  getPersonalityInfo,
  profileData,
  updatePersonalityInfo,
} from "../../../profile/core/_requests";
import { FileUpload } from "../../../widgets/components/FileUpload";
// import { designationOptions } from "../core/_constants";
import { useAuth } from "../../../auth";
import { ChangePasswordModal } from "../../../auth/components/ChangePasswordModal";
// import { forgotPassword } from "../../auth/core/_requests";
import { DisplayImage } from "../../../widgets/components/General/DisplayImage";
import { toast } from "react-toastify";
import { Spinner } from "../../../widgets/components/General/Spinner";
import { ToolTipUI } from "../../../widgets/components/UI/ToolTipUI";
import TextArea from "../../../widgets/components/Input/TextArea";
import { Country } from "../../../widgets/components/General/Country";
import { State } from "../../../widgets/components/General/State";
import SearchInput from "../../../profile/components/SearchInput";
import { industryOptions } from "../../../../core/_constants";
import { CountryModel } from "../../../onboarding";
import { getCountry } from "../../../onboarding/core/_requests";
import { getCdnUrl } from "../../../../core/_util";
import DatePickerInput from "../../../widgets/components/Input/DatePickerInput";
import publicProfileIcon from "../../../../../_metronic/assets/images/svg/public-profile-icon.svg";
import { Link } from "react-router-dom";
import { get, map } from "lodash";
import FormikReactSelect from "../../../widgets/components/Input/FormikReactSelect";
import useGTM from "../../../../hooks/useGTM";
import { GTMEvent } from "../../../../hooks/gtm_helpers";
import { CustomButton } from "../../../widgets/components/UI/CustomButton";
import { Modal } from "react-bootstrap";

const EditModal = ({
  key,
  getApiLoading,
  show,
  onHide,
}: // countryOptions,
{
  key?: number;
  setImgName?: Dispatch<SetStateAction<string | undefined>>;
  imgName?: string | undefined;
  getApiLoading?: boolean;
  show: boolean;
  onHide: () => void;
  // countryOptions: any;
}) => {
  const { formatMessage } = useIntl();
  const { dataLayerPush } = useGTM();
  const [modelStatus, setModelStatus] = useState<boolean>(false);
  const [modalStatusBanner, setModalStatusBanner] = useState<boolean>(false);
  const [countryId, setCountryId] = useState<string | undefined>();
  const [imgName, setImgName] = useState<string | undefined>();
  const [bannerUrl, setBannerUrl] = useState<string | undefined>();
  const [disableSave, setDisableSave] = useState(false);

  const {
    personalityId,
    currentUser,
    setCurrentUser,
    referenceData,
    setSelectedPersonality: setPersonalityData,
    selectedPersonality,
  } = useAuth();
  const [showModal, setShowModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [changePasswordLoading, setChangePasswordLoading] = useState(false);
  const [, setHasErrors] = useState<boolean | undefined>();
  // const [personalityData, setPersonalityData] = useState(
  const personalityData =
    selectedPersonality ||
    currentUser?.personality.find(
      (el: any) => el.personalityId == personalityId
    ) ||
    null;
  // );
  console.log(personalityData);
  let category = referenceData?.platformOptions?.categoryPersonalityTypes?.map(
    (el: any, index: number) => {
      return {
        label: el.category,
        value: el.category,
        id: index,
      };
    }
  );
  category?.sort((a: any, b: any) => a.value.localeCompare(b.value));
  const [countryOptions, setcountryOptions] = useState<any[]>([]);
  const [phoneCodes, setPhoneCodes] = useState<any[]>([]);

  useEffect(() => {
    const fetchCountry = async () => {
      const {
        data: { data: countries },
      } = await getCountry();
      const countriesData = countries.map((country: CountryModel) => {
        return {
          id: country.countryId,
          name: country.country_name,
          value: country.countryId,
        };
      });
      setcountryOptions([...countriesData]);
      const phoneCodes = countries.map((country: CountryModel) => {
        return {
          id: country.countryId,
          name: "+" + country.phone_code,
          value: country.countryId,
        };
      });
      setPhoneCodes(phoneCodes);
    };
    fetchCountry();
  }, []);

  const personalitySchema = Yup.object().shape({
    name: Yup.string()
      .min(3, formatMessage({ id: "Minimum 3 characters" }))
      .max(50, formatMessage({ id: "Maximum 50 characters" }))
      .required(formatMessage({ id: "First name is required" })),
    tagline: Yup.string()
      .nullable()
      .max(50, formatMessage({ id: "Maximum 50 characters" })),
    description: Yup.string()
      .max(1000, formatMessage({ id: "Maximum 1000 characters" }))
      .required(formatMessage({ id: "Description is required" })),
    // gender: Yup.string().required(formatMessage({ id: "Gender is required" })),
    category: Yup.string().required(
      formatMessage({ id: "Category is required" })
    ),
  });

  useEffect(() => {
    setImgName(personalityData.image);
    if (personalityData.banner) {
      setBannerUrl(personalityData.banner);
    }
  }, [personalityData]);

  useEffect(() => {
    // if personality id is not null update the latest personality details
    (async () => {
      let {
        data: { success, data },
      } = await getPersonalityInfo(personalityId?.toString() || "");
      if (success) {
        setPersonalityData(data);
      }
    })();
  }, []);

  const onSubmit = async (values: any) => {
    try {
      setDisableSave(true);
      setLoading(true);
      let {
        data: { success, errors, data: personalityUpdated },
      } = await updatePersonalityInfo({
        name: values.name,
        tagline: values.tagline,
        description: values.description,
        gender: values.gender,
        category: values.category,
        birthdate: values.birthdate,
        industry: values.industry,
        website: values.website,
        address: values.address,
        image: values.image,
        banner: values.banner,
        countryId: parseInt(values.countryId),
        stateId: parseInt(values.stateId),
        personalityId,
        marketplaceSettings: values.marketplaceSettings,
      });

      if (success) {
        setPersonalityData(personalityUpdated);
        if (personalityId) {
          const {
            data: { success, data, errors },
          } = await profileData(personalityId);
          if (success) {
            console.log(data, success);
          } else if (errors) {
            errors.forEach((error: string) => {
              toast.error(formatMessage({ id: error }), {
                autoClose: false,
                closeButton: true, // Display close button (cross icon)
              });
            });
          }
        }
        setLoading(false);
        setDisableSave(false);
        toast.success(
          formatMessage({ id: "AI Personality updated successfully" })
        );
        dataLayerPush(GTMEvent.AIPersonalityEditSuccessful, { tab: "profile" });
      } else if (errors) {
        setLoading(false);
        errors.forEach((error: string) => {
          toast.error(formatMessage({ id: error }), {
            autoClose: false,
            closeButton: true, // Display close button (cross icon)
          });
        });
        dataLayerPush(GTMEvent.AIPersonalityEditFailed, { tab: "profile" });
      }
    } catch (err) {
      setDisableSave(false);
      setLoading(false);
      console.log(err);
      dataLayerPush(GTMEvent.AIPersonalityEditFailed, { tab: "profile" });
    }
  };

  const handleOpen = () => {
    setModelStatus(true);
  };
  const handleClose = () => {
    setModelStatus(false);
  };

  const handleBannerClose = () => {
    setModalStatusBanner(false);
  };

  return (
    <Modal
      size="xl"
      show={show}
      onHide={onHide}
      aria-labelledby="contained-modal-title-vcenter"
      className={"z-[999999] "}
      centered
    >
      <Modal.Header closeButton className={"px-10"}>
        <div className={"flex items-center justify-between w-full"}>
          <div className="d-flex fw-bold">
            <h2 className="text-[20px] primary-text leading-[28px]">
              {formatMessage({ id: "Edit User" })}
            </h2>{" "}
          </div>
          <button onClick={onHide}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
            >
              <path
                d="M16.2414 6.34311L6.34187 16.2426C5.95135 16.6331 5.95135 17.2663 6.34187 17.6568C6.7324 18.0473 7.36556 18.0473 7.75609 17.6568L17.6556 7.75732C18.0461 7.36679 18.0461 6.73363 17.6556 6.34311C17.2651 5.95258 16.6319 5.95258 16.2414 6.34311Z"
                fill="#C2D24B"
                fill-opacity="0.5"
              />
              <path
                opacity="0.3"
                d="M17.6554 16.2426L7.75593 6.34309C7.36541 5.95257 6.73225 5.95257 6.34172 6.34309C5.9512 6.73362 5.9512 7.36678 6.34172 7.75731L16.2412 17.6568C16.6317 18.0473 17.2649 18.0473 17.6554 17.6568C18.046 17.2663 18.046 16.6331 17.6554 16.2426Z"
                fill="#C2D24B"
                fill-opacity="0.5"
              />
            </svg>
          </button>
        </div>
      </Modal.Header>
      <Modal.Body className="flex flex-col lg:flex-row gap-y-[20px] gap-x-[40px] p-5 lg:p-[24px] rounded mt-4 ">
        <div className={"flex flex-col max-w-[120px]"}>
          <div className={`relative  `}>
            <DisplayImage
              imgName={imgName}
              fit="contain"
              alt="profile"
              className={
                "w-[120px] h-[120px] !object-cover display-image-position  !rounded !overflow-hidden"
              }
            />
            <div
              className="rounded-full widgets-customize absolute -top-2 -right-3 p-2 shadow-[0px_2px_4px_0px_#0000001A]"
              onClick={handleOpen}
            >
              <svg
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g clipPath="url(#clip0_344_12864)">
                  <path
                    d="M12.8667 5.94967L10.0333 3.14967L10.9667 2.21634C11.2222 1.96079 11.5362 1.83301 11.9087 1.83301C12.2807 1.83301 12.5944 1.96079 12.85 2.21634L13.7833 3.14967C14.0389 3.40523 14.1722 3.71367 14.1833 4.07501C14.1944 4.4359 14.0722 4.74412 13.8167 4.99967L12.8667 5.94967ZM11.9 6.93301L4.83333 13.9997H2V11.1663L9.06667 4.09967L11.9 6.93301Z"
                    className={"icon"}
                    fillOpacity="0.65"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_344_12864">
                    <rect width="16" height="16" className={"icon"} />
                  </clipPath>
                </defs>
              </svg>
            </div>
          </div>
          <p className=" text-[9px] leading-[18px]  secondary-text mt-[12px]">
            {formatMessage({
              id: "Use image of square size (ratio 1:1), in png or jpg format only",
            })}
          </p>
        </div>
        <Formik
          initialValues={personalityData}
          validationSchema={personalitySchema}
          onSubmit={onSubmit}
          className={"flex-grow "}
        >
          {(formik) => {
            setCountryId(formik.values?.countryId);
            return (
              <Form
                className={
                  "w-full max-h-[500px] lg:max-h-max px-5 custom-scroll overflow-y-auto"
                }
              >
                <div className={"grid grid-cols-12 lg:gap-x-[24px]"}>
                  <div className={"col-span-12 lg:col-span-6"}>
                    <TextInput
                      fieldType={"text"}
                      label={formatMessage({ id: "First Name" })}
                      fieldName={"firstName"}
                      formik={formik}
                      placeholder={formatMessage({ id: "My AI Personality" })}
                      toolTipText={formatMessage({
                        id: "GLOBAL.TOOLTIP.AI_PERSONALITY.NAME",
                      })}
                    />
                  </div>
                  <div className={"col-span-12 lg:col-span-6"}>
                    <TextInput
                      fieldType={"text"}
                      label={formatMessage({ id: "Last Name" })}
                      fieldName={"lastName"}
                      formik={formik}
                      placeholder={formatMessage({ id: "My AI Personality" })}
                      toolTipText={formatMessage({
                        id: "GLOBAL.TOOLTIP.AI_PERSONALITY.NAME",
                      })}
                    />
                  </div>
                  <div className={"col-span-12 lg:col-span-6"}>
                    <TextInput
                      fieldType={"text"}
                      label={formatMessage({ id: "Username" })}
                      fieldName={"username"}
                      formik={formik}
                      placeholder={formatMessage({ id: "My AI Personality" })}
                      toolTipText={formatMessage({
                        id: "GLOBAL.TOOLTIP.AI_PERSONALITY.NAME",
                      })}
                    />
                  </div>
                  <div className={"col-span-12 lg:col-span-6"}>
                    <TextInput
                      fieldType={"text"}
                      label={formatMessage({ id: "Position" })}
                      fieldName={"position"}
                      formik={formik}
                      placeholder={formatMessage({
                        id: "Position Personality",
                      })}
                      toolTipText={formatMessage({
                        id: "GLOBAL.TOOLTIP.AI_PERSONALITY.NAME",
                      })}
                    />
                  </div>
                  <div className={"col-span-12 lg:col-span-6"}>
                    <TextInput
                      fieldType={"text"}
                      label={formatMessage({ id: "Email Address" })}
                      fieldName={"email"}
                      formik={formik}
                      placeholder={formatMessage({
                        id: "Email Address",
                      })}
                      toolTipText={formatMessage({
                        id: "GLOBAL.TOOLTIP.AI_PERSONALITY.TAGLINE",
                      })}
                      width={5}
                    />
                  </div>

                  <div className={"col-span-12 lg:col-span-6"}>
                    <label
                      className={
                        "mb-2  text-[13px] leading-5 secondary-text flex"
                      }
                    >
                      {formatMessage({ id: "Contact Number" })}
                      <ToolTipUI
                        tooltipText={formatMessage({
                          id: "GLOBAL.TOOLTIP.USER.CONTACT_NUMBER",
                        })}
                      />
                    </label>
                    <div className={"flex gap-[8px]"}>
                      <div className={"w-[100px]"}>
                        <FormikReactSelect
                          isFieldArray={true}
                          name={"countryId"}
                          formik={formik}
                          placeholder={formatMessage({ id: "-" })}
                          toolTipText={formatMessage({
                            id: "GLOBAL.TOOLTIP.USER.CONTACT_NUMBER",
                          })}
                          isStarRequired={true}
                          options={map(phoneCodes, (item) => {
                            return {
                              label: get(item, "name"),
                              value: get(item, "value"),
                            };
                          })}
                        />
                      </div>
                      <div className={"flex-grow"}>
                        <TextInput
                          fieldType={"contact"}
                          withoutLabel={true}
                          fieldName={"contact"}
                          formik={formik}
                          placeholder=""
                          toolTipText={formatMessage({
                            id: "GLOBAL.TOOLTIP.USER.CONTACT_NUMBER",
                          })}
                        />
                      </div>
                    </div>
                  </div>
                  <div className={"col-span-12 lg:col-span-6"}>
                    <Country
                      initialValues={personalityData}
                      formik={formik}
                      fieldName={"countryId"}
                      label={formatMessage({ id: "Country" })}
                      setCountryId={setCountryId}
                      tooltipText={formatMessage({
                        id: "GLOBAL.TOOLTIP.COMPANY_DETAILS.COUNTRY",
                      })}
                      width={12}
                    />
                  </div>
                  <div className={"col-span-12 lg:col-span-6"}>
                    <TextInput
                      label={formatMessage({ id: "Website URL" })}
                      fieldName={"website"}
                      withoutLabel={true}
                      formik={formik}
                      placeholder={formatMessage({ id: "www.example.com" })}
                      toolTipText={formatMessage({
                        id: "GLOBAL.TOOLTIP.USER.WEBSITE_URL",
                      })}
                      fieldType={"text"}
                    />
                  </div>

                  <div className={"col-span-12 lg:col-span-6 "}>
                    <TextArea
                      label={formatMessage({ id: "Description" })}
                      fieldName={"description"}
                      formik={formik}
                      placeholder={formatMessage({ id: "Short Description" })}
                      className={"!min-h-[80px]"}
                      toolTipText={formatMessage({
                        id: "GLOBAL.TOOLTIP.AI_PERSONALITY.SHORT_DESCRIPTION",
                      })}
                      isStarRequired={true}
                    />
                  </div>
                  <div
                    className={
                      "col-span-12 field p-[8px] shadow-[0px_2px_4px_0px_#0000001A] rounded relative "
                    }
                  >
                    <div
                      className="rounded-full  widgets-customize absolute -top-2 -right-3 p-2 shadow-[0px_2px_4px_0px_#0000001A]"
                      onClick={(e) => setModalStatusBanner(true)}
                    >
                      <svg
                        width="16"
                        height="16"
                        viewBox="0 0 16 16"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <g clipPath="url(#clip0_344_12864)">
                          <path
                            d="M12.8667 5.94967L10.0333 3.14967L10.9667 2.21634C11.2222 1.96079 11.5362 1.83301 11.9087 1.83301C12.2807 1.83301 12.5944 1.96079 12.85 2.21634L13.7833 3.14967C14.0389 3.40523 14.1722 3.71367 14.1833 4.07501C14.1944 4.4359 14.0722 4.74412 13.8167 4.99967L12.8667 5.94967ZM11.9 6.93301L4.83333 13.9997H2V11.1663L9.06667 4.09967L11.9 6.93301Z"
                            className={"icon"}
                            fillOpacity="0.65"
                          />
                        </g>
                        <defs>
                          <clipPath id="clip0_344_12864">
                            <rect width="16" height="16" className={"icon"} />
                          </clipPath>
                        </defs>
                      </svg>
                    </div>
                    <img
                      alt="AI Banner"
                      src={getCdnUrl(
                        bannerUrl ||
                          toAbsoluteUrl("/media/avatars/banner-image.png") ||
                          ""
                      )}
                      className={"w-full h-[110px]"}
                    />
                  </div>
                  <p className="col-span-12 text-[12px]  secondary-text mt-[12px] mb-[18px] md:mb-[32px]">
                    {" "}
                    {formatMessage({
                      id: "* Recommended image size is 1440x320 px i.e. width of 1440 pixels, height of 320 pixels.",
                    })}
                  </p>
                </div>
                <FileUpload
                  fileSize={2097152}
                  maxFileNumber={1}
                  allowType={["image/*", ".jpg", ".jpeg", ".png"]}
                  metaData={{ module: "profileimg", isProtected: true }}
                  modalStatus={modelStatus}
                  handleClose={handleClose}
                  handleSuccess={(id: number, name: string) => {
                    setImgName(name);
                    formik.setFieldValue("image", name);
                    // formik.setFieldValue("profileImageId", id);
                    dataLayerPush(
                      GTMEvent.PersonalityProfilePicUploadSuccessful
                    );
                  }}
                  resourceType="personality-avatar"
                />
                <FileUpload
                  fileSize={2097152}
                  maxFileNumber={1}
                  allowType={["image/*", ".jpg", ".jpeg", ".png"]}
                  metaData={{ module: "profileimg", isProtected: true }}
                  modalStatus={modalStatusBanner}
                  handleClose={handleBannerClose}
                  handleSuccess={(id: number, name: string) => {
                    setBannerUrl(name);
                    formik.setFieldValue("banner", name);
                    // formik.setFieldValue("profileImageId", id);
                    dataLayerPush(
                      GTMEvent.PersonalityProfileBannerUploadSuccessful
                    );
                  }}
                  resourceType="personality-banner"
                />
                <div
                  className={
                    "flex flex-col md:flex-row justify-end mt-5 gap-y-[14px] md:gap-x-[20px]"
                  }
                >
                  <button
                    type="submit"
                    className={`export-button text-[14px] leading-5 font-medium py-[12px] w-full sm:w-1/2  md:w-[140px] rounded ${
                      disableSave ? "opacity-75" : ""
                    }`}
                    disabled={disableSave}
                  >
                    {formatMessage({
                      id: `${disableSave ? "Please Wait" : "Save Changes"}`,
                    })}
                  </button>
                  {/* <CustomButton
                    isSubmitting={formik.isSubmitting}
                    isValid={formik.isValid}
                    buttonText={formatMessage({ id: "Save Changes" })}
                    loading={loading}
                    height={44}
                  /> */}
                </div>
              </Form>
            );
          }}
        </Formik>
      </Modal.Body>
      <ChangePasswordModal
        show={showModal}
        handleClose={() => setShowModal(false)}
        setShowModal={setShowModal}
      />
    </Modal>
  );
};

export default EditModal;
