import clsx from "clsx";
import { KTSVG } from "../../../helpers";
import { HeaderNotificationsMenu, HeaderUserMenu } from "../../../partials";
import { useLayout } from "../../core";
import { useAuth } from "../../../../app/modules/auth";
import { DisplayImage } from "../../../../app/modules/widgets/components/General/DisplayImage";
import { useEffect, useState } from "react";
import { MenuComponent } from "../../../assets/ts/components";
import marketplaceIcon from "../../../../_metronic/assets/images/svg/marketplace-icon-navbar.svg";

import { Link, useLocation } from "react-router-dom";
import { getUserBalance } from "../../../../app/modules/chat-credits/core/_request";
import { Spinner } from "react-bootstrap";
import { includes } from "lodash";
import HeaderSearchMob from "./HeaderSearchMob";
import { useIntl } from "react-intl";
import { kmbConvertor } from "../../../../app/utils";

const itemClass = "ms-1 ms-lg-3";
const btnClass =
  "btn btn-icon btn-custom btn-icon-muted btn-active-light btn-active-color-primary w-35px h-35px w-md-40px h-md-40px";
const userAvatarClass = "symbol-35px symbol-md-40px";
const btnIconClass = "svg-icon-1";

interface NavProps {
  hideStats?: boolean;
  hideNotifications?: boolean;
  showMarketplace?: boolean;
  hideMarketplace?: boolean;
  isMarketPlace?: boolean;
}
const Navbar = ({
  hideStats = false,
  hideNotifications = false,
  showMarketplace = false,
  hideMarketplace = false,
  isMarketPlace = false,
}: NavProps) => {
  const { config } = useLayout();
  const { currentUser } = useAuth();
  const [blinker, setBlinker] = useState(false);
  const { credits, setCredits } = useAuth();
  const [loading, setLoading] = useState<boolean>(true);
  const onBellIcon = () => {
    setBlinker(false);
  };

  useEffect(() => {
    setTimeout(() => {
      MenuComponent.reinitialization();
    }, 500);
  }, []);
  const location = useLocation();

  useEffect(() => {
    async function getBal() {
      const {
        data: { data, success },
      } = await getUserBalance();
      if (success) {
        setCredits(data.amount);
      }
      setLoading(false);
    }
    if (currentUser) {
      getBal();
    }
  }, [currentUser]);
  const { pathname } = useLocation();
  const { formatMessage } = useIntl();

  return (
    <div className="flex flex-row flex-shrink-0 app-navbar align-items-center">
      <div className={clsx("app-navbar-item align-items-stretch", itemClass)}>
        {/* <Search /> */}
      </div>

      {!hideStats && (
        <div className={clsx("app-navbar-item", itemClass)}>
          {/* <div id="kt_activities_toggle" className={btnClass}>
          <KTSVG
            path="/media/icons/duotune/general/gen032.svg"
            className={btnIconClass}
          />
        </div> */}
        </div>
      )}
      {includes(["/marketplace"], pathname) && <HeaderSearchMob />}
      <div className="app-navbar-item">
        {!hideMarketplace && (
          <Link
            to="/marketplace"
            className="primary-text hide-280 me-5 text-[13px] leading-[18px] font-weight-500 d-flex flex-row align-items-center"
          >
            <img src={marketplaceIcon} className="me-[6px]" alt=""></img>
            <div className="hidden sm:block">
              {" "}
              {formatMessage({ id: "Marketplace" })}
            </div>
          </Link>
        )}

        <Link to={"/chat-credits"}>
          <div
            className={
              "bg-[#2E2F4500] border-main border flex items-center justify-content-center py-[4px] sm:py-[8px] px-[14px] md:px-[12px]  md:gap-x-[12px] rounded min-w-[80px]"
            }
          >
            <p
              className={
                "text-[12px] sm:text-[14px] leading-[20px] secondary-text font-semibold"
              }
            >
              {loading ? (
                <Spinner style={{ width: 20, height: 20 }} animation="border" />
              ) : (
                <>${kmbConvertor(credits,0)}</>
              )}
            </p>
          </div>
        </Link>
      </div>

      {!hideNotifications && (
        <div className={clsx("app-navbar-item mr-2", itemClass)}>
          <div
            data-kt-menu-trigger="{default: 'click'}"
            data-kt-menu-attach="parent"
            data-kt-menu-placement="bottom-end"
            className={btnClass}
          >
            <div
              className={clsx("position-relative", btnClass)}
              onClick={onBellIcon}
            >
              <KTSVG
                path="/media/icons/header/NotificationIcon.svg"
                className={btnIconClass}
              />
              <span
                className={` ${
                  blinker
                    ? " animation-blink bg-success bullet bullet-dot h-6px w-6px position-absolute translate-middle top-0 start-50"
                    : "bullet bullet-dot h-6px w-6px position-absolute translate-middle top-0 start-50"
                }`}
              />
            </div>{" "}
          </div>
          <HeaderNotificationsMenu blinker={blinker} setBlinker={setBlinker} />
        </div>
      )}

      <div className={clsx("app-navbar-item", itemClass)}>
        <div
          className={clsx("cursor-pointer symbol", userAvatarClass)}
          data-kt-menu-trigger="{default: 'click'}"
          data-kt-menu-attach="parent"
          data-kt-menu-placement="bottom-end"
        >
          <DisplayImage
            imgName={currentUser?.photo}
            width="100%"
            alt="profile"
            fit="contain"
          />
          <div className={clsx("!hidden md:!inline-flex", itemClass)}>
            <p className="m-1 fw-bold">
              <span
                className={clsx(
                   "primary-text",
                  "font-size-13"
                )}
              >
                {formatMessage({ id: "Hi" })},{" "}
              </span>

              <span
                className={clsx(
                  "secondary-text-light",
                  "font-size-13"
                )}
              >
                {currentUser?.firstName}
              </span>
            </p>
          </div>
        </div>
        <HeaderUserMenu />
      </div>

      {config.app?.header?.default?.menu?.display && (
        <div
          className="app-navbar-item d-none ms-2 me-n3"
          title="Show header menu"
        >
          <div
            className="btn btn-icon btn-active-color-primary w-35px h-35px"
            id="kt_app_header_menu_toggle"
          >
            <KTSVG
              path="/media/icons/duotune/text/txt001.svg"
              className={btnIconClass}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export { Navbar };
