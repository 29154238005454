import React from "react";
import Container from "../../containers/marketplace-discovery";

const Index = () => {
  return (
    <div className={"px-[20px] md:px-20"}>
      <Container />
    </div>
  );
};

export default Index;
