import { get, isEqual, map } from "lodash";
import React, { useState } from "react";
import CustomTable from "../../../components/table";
import { useIntl } from "react-intl";
import { Dropdown, Modal } from "react-bootstrap";
import clsx from "clsx";
import { personalityStatus } from "../utils";
import EditModal from "../components/personalities/EditModal";
import { toAbsoluteUrl } from "../../../../_metronic/helpers/AssetHelpers";
import ChangePlanModal from "../components/personalities/ChangePlanModal";
import useGetBillingData from "../../../hooks/useGetBillingData";
import {useAuth} from "../../auth";

const PersonalitiesTable: React.FC<any> = () => {
  const {personalityId, ipStackCountry,currentState,
    selected,} = useAuth()
  const { formatMessage } = useIntl();
  const [openWarningModal, setOpenWarningModal] = useState(false);
  const [openChangePlanModal, setOpenChangePlanModal] = useState(false);
  const [openEditModal, setOpenEditModal] = useState(false);
  const { getBillingDetailsAPI } = useGetBillingData(personalityId!);

  const data = [
    {
      personality: "Shahrukh Khan",
      owner: "Toshendra Sharma",
      createdDate: "15th Jun 23, 02:39 AM",
      NoOfchats: "69,550",
      userStatus: "Licenced",
    },
    {
      personality: "Mahatma Gandhi",
      owner: "Rohendra Sharma",
      createdDate: "15th Jun 23, 02:39 AM",
      NoOfchats: "69,550",
      userStatus: "Licenced",
    },
    {
      personality: "Elon Musk",
      owner: "Chiraag dhawan",
      createdDate: "15th Jun 23, 02:39 AM",
      NoOfchats: "69,550",
      userStatus: "Unlicenced",
    },
    {
      personality: "Mohammed Ali",
      owner: "Rohendra Sharma",
      createdDate: "15th Jun 23, 02:39 AM",
      NoOfchats: "69,550",
      userStatus: "Unlicenced",
    },
    {
      personality: "Iron Man",
      owner: "Rohendra Sharma",
      createdDate: "15th Jun 23, 02:39 AM",
      NoOfchats: "69,550",
      userStatus: "Licenced",
    },
  ];

  const headers = [
    {
      label: "Personality",
      key: "personality",
      enableSorting: true,
    },
    {
      label: "Owner",
      key: "owner",
      enableSorting: true,
    },
    {
      label: "Created Date",
      key: "createdDate",
      enableSorting: true,
    },
    {
      label: "No. of chats",
      key: "NoOfchats",
      enableSorting: true,
    },
    {
      label: "User Status",
      key: "userStatus",
      enableSorting: true,
    },
    {
      label: "Action",
      key: "action",
      enableSorting: true,
    },
  ];

  const columns = React.useMemo<any>(
    () =>
      map(headers, (item) => {
        return {
          header: get(item, "label"),
          accessorKey: get(item, "key"),
          enableSorting: get(item, "enableSorting"),
          footer: (props: any) => props.column.id,
          cell: (info: any) => {
            if (item) {
              if (isEqual(get(item, "key"), "personality")) {
                return (
                  <div className="flex gap-x-2 items-center">
                    <img
                      alt="Pic"
                      src={toAbsoluteUrl(`/media/avatars/300-1.jpg`)}
                      className={"w-8 h-8 rounded"}
                    />
                    {info.getValue()}
                  </div>
                );
              }
              if (isEqual(get(item, "key"), "personalities")) {
                return (
                  <div className="flex text-white ">
                    <div className="w-6 h-6 rounded-full bg-[#FFC700] flex items-center justify-center  border-2 border-[#ffd233] z-30">
                      A
                    </div>
                    <div className="w-6 h-6 rounded-full bg-[#FFC700] flex items-center justify-center -ml-2 border-2 border-[#ffd233] z-20">
                      A
                    </div>
                    <div className="w-6 h-6 rounded-full bg-[#FFC700] flex items-center justify-center -ml-2 border-2 border-[#ffd233] z-10">
                      A
                    </div>
                  </div>
                );
              }
              if (isEqual(get(item, "key"), "userStatus")) {
                return (
                  <button
                    className={clsx(
                      personalityStatus(info.getValue()),
                      "text-[12px] py-1 px-3 rounded"
                    )}
                  >
                    {info.getValue()}
                  </button>
                );
              }
              if (isEqual(get(item, "key"), "action")) {
                return (
                  <Dropdown>
                    <Dropdown.Toggle
                      as={"button"}
                      className="bg-secondary w-8 h-8 rounded flex flex-col items-center justify-center gap-y-[3px]"
                    >
                      <svg
                        width="4"
                        height="4"
                        viewBox="0 0 4 4"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <circle
                          cx="2"
                          cy="2"
                          r="2"
                          className="icon-main-color"
                          fill-opacity="0.65"
                        />
                      </svg>
                      <svg
                        width="4"
                        height="4"
                        viewBox="0 0 4 4"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <circle
                          cx="2"
                          cy="2"
                          r="2"
                          className="icon-main-color"
                          fill-opacity="0.65"
                        />
                      </svg>
                      <svg
                        width="4"
                        height="4"
                        viewBox="0 0 4 4"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <circle
                          cx="2"
                          cy="2"
                          r="2"
                          className="icon-main-color"
                          fill-opacity="0.65"
                        />
                      </svg>
                    </Dropdown.Toggle>
                    <Dropdown.Menu className={"px-4"}>
                      <Dropdown.Item
                        className="px-3 py-2 rounded menu-item"
                        as={"div"}
                        onClick={() => {
                          setOpenEditModal(true);
                        }}
                      >
                        Edit
                      </Dropdown.Item>
                      <Dropdown.Item
                        className="px-3 py-2 rounded menu-item"
                        as={"div"}
                        onClick={() => {
                          setOpenChangePlanModal(true);
                        }}
                      >
                        Change Plan
                      </Dropdown.Item>{" "}
                      <Dropdown.Item
                        className="px-3 py-2 rounded menu-item"
                        as={"div"}
                      >
                        Change Quota
                      </Dropdown.Item>
                      <Dropdown.Item
                          className="px-3 py-2 rounded menu-item"
                          as={"div"}
                      >
                        Block/Disable
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                );
              } else {
                return info.getValue();
              }
            }
          },
        };
      }),

    []
  );

  return (
    <div>
      <div className={"mt-[16px] md:mt-4"}>
        <div
          className={
            "tab-container shadow-default sm:py-[14px] sm:px-[6px] md:p-6  rounded min-h-[70vh]"
          }
        >
          <CustomTable
            loading={false}
            tableData={data}
            pageCount={10}
            headers={[]}
            columns={columns}
            fetchData={() => {}}
            emptyControl={{
              heading: formatMessage({ id: "No Events found" }),
            }}
          />
        </div>
      </div>
      <EditModal show={openEditModal} onHide={() => setOpenEditModal(false)} />
      <ChangePlanModal
          getBillingDetails={getBillingDetailsAPI}
          currencyBill={ipStackCountry?.country_code == 'IN' ? 'INR': 'USD'}
          selected={selected}
          currentState={currentState}
          // plans={plans}
          // planError={planError}
          upgrade={true}
          companyId={personalityId}
        show={openChangePlanModal}
        onHide={() => setOpenChangePlanModal(false)}
      />
    </div>
  );
};

export default PersonalitiesTable;
