import clsx from "clsx";
import React, { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import ReactPaginate from "react-paginate";

const Index = ({
  handleFilters = () => {},
  total,
  filters,
  paginationDefault,
  showPagination,
  isDark
}: {
  handleFilters?: any;
  total?: any;
  filters?: any;
  paginationDefault: any;
  showPagination?: boolean;
  isDark?:boolean
}) => {
  const [perPage, setPerPage] = useState<any>(
    filters?.perPage || paginationDefault.perPage
  );
  const [page, setPage] = useState<any>(filters?.page || 1);
  const { formatMessage } = useIntl();
  const handlePageChange = ({ selected }: { selected: number }) => {
    setPage(selected + 1);
  };

  useEffect(() => {
    handleFilters({ page, perPage });
  }, [page, perPage]);

  useEffect(() => {
    if (filters?.page == page) {
      setPage(1);
    }
  }, [
    filters?.searchText,
    filters?.industry,
    filters?.category,
    filters?.gender,
    filters?.racial_information,
    filters?.stage_in_life,
    filters?.country,
    filters?.licensed,
  ]);

  if (!total) {
    return <></>;
  }

  return (
    <div
      className={`${
        showPagination ? "" : "hidden"
      } flex flex-col sm:flex-row sm:justify-between sm:items-center`}
    >
      <div className={"flex items-center gap-x-2"}>
        <div className={"w-[75px]"}>
          <select
            className={clsx(
              "form-control form-select form-control-sm py-0 leading-0 !min-h-[32px] !h-[32px]")
            }
            onChange={(event) => setPerPage(event.target.value)}
          >
            {paginationDefault.values.map((el: any) => (
              <option value={el} selected={el === perPage}>
                {el}
              </option>
            ))}
          </select>
        </div>
        <p
          className={
            "secondary-text text-[12px] leading-[18px] hidden md:inline-block"
          }
        >
          {formatMessage({
            id: `Showing ${(page - 1) * perPage + 1} to ${
              perPage * page > total ? total : perPage * page
            } of ${total} records`,
          })}
        </p>
      </div>
      <div>
        <ReactPaginate
          forcePage={page - 1}
          onPageChange={handlePageChange}
          pageCount={Math.ceil(total / perPage)}
          containerClassName={
            "flex justify-between sm:gap-x-2 mt-[12px] sm:mt-0"
          }
          pageClassName={
            "w-8 h-8 pagination-item text-[14px] leading-[18px] flex items-center justify-center rounded"
          }
          nextClassName={
            "w-8 h-8 pagination-item flex items-center justify-center rounded"
          }
          previousClassName={
            "w-8 h-8 pagination-item flex items-center justify-center rounded"
          }
          activeClassName={"active-pagination"}
          nextLabel={
            <svg
              width="5"
              height="7"
              viewBox="0 0 5 7"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M0.218094 0.265H1.71309L4.85909 3.242L1.71309 6.232H0.218094L3.36409 3.242L0.218094 0.265Z"
                className={'pagination-icon'}
                fillOpacity="0.6"
              />
            </svg>
          }
          previousLabel={
            <svg
              width="6"
              height="7"
              viewBox="0 0 6 7"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M4.11953 6.232L0.986531 3.242L4.11953 0.265H5.61453L2.46853 3.242L5.61453 6.232H4.11953Z"
                className={'pagination-icon'}
                fillOpacity="0.6"
              />
            </svg>
          }
        />
      </div>
    </div>
  );
};

export default Index;
