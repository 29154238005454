import React, { useState } from "react";
import { useIntl } from "react-intl";
import { Link } from "react-router-dom";
import { useAuth } from "../../../../auth";

const LeaderboardListFilters = (
  { ViewAllList = false,
    setResourceType,
    setCategory,
    setCountryId,
    setTimeFrame,
    resourceType,
    category,
    country_id,
    time_frame 
  }:{
    ViewAllList:boolean,
    setResourceType:any,
    setCategory:any,
    setCountryId:any,
    setTimeFrame:any,
    resourceType:any,
    category:any,
    country_id:any,
    time_frame:any 
  }) => {
  const { formatMessage } = useIntl();
  const {referenceData} = useAuth()
  const [activeButton, setActiveButton] = useState(resourceType); // Initially, 'Trending' is active

  const handleButtonClick = (button: React.SetStateAction<string>) => {
    setActiveButton(button);
    setResourceType(button);
  };

  // State to track the active button
  const [activeTimeButton, setActiveTimeButton] = useState<string>(time_frame);

  // Function to handle button click
  const handleTimeButtonClick = (time: string) => {
    setActiveTimeButton(time);
    setTimeFrame(time)
  };
  return (
    <div>
      <div className="flex flex-col md:flex-row md:items-center justify-between gap-[16px]">
        <div className="flex flex-row items-center gap-[12px]">
          <div
            className={`py-[11px] px-[16px] cursor-pointer rounded-[8px] ${
              activeButton === "trending"
                ? "bg-[#C2D24B1A] border-[1px] border-solid border-[#C2D24B]"
                  : "tab-container border main-border"
            }`}
            onClick={() => handleButtonClick("trending")}
          >
            {formatMessage({ id: "Trending" })}
          </div>
          <div
            className={`py-[11px] px-[16px] cursor-pointer rounded-[8px] ${
              activeButton === "top"
                ? "bg-[#C2D24B1A] border-[1px] border-solid border-[#C2D24B]"
                : "tab-container border main-border"
            }`}
            onClick={() => handleButtonClick("top")}
          >
            {formatMessage({ id: "Top" })}
          </div>
        </div>

        <div className="flex flex-col md:flex-row md:items-center gap-[12px]">
          <div className="flex flex-row items-center justify-between p-[4px] tab-container border rounded-[8px]">
            {/* <div
              className={`p-[4px] text-center w-[42px] cursor-pointer h-[32px] rounded-[4px] flex flex-row items-center justify-center ${
                activeTimeButton === "1h"
                    ? "main-button-light"
                    : "bg-transparent primary-text"
              }`}
              onClick={() => handleTimeButtonClick("1h")}
            >
              {formatMessage({ id: "1h" })}
            </div>
            <div
              className={`p-[4px] text-center w-[42px] cursor-pointer h-[32px] rounded-[4px] flex flex-row items-center justify-center ${
                activeTimeButton === "6h"
                  ? "main-button-light"
                  : "bg-transparent primary-text"
              }`}
              onClick={() => handleTimeButtonClick("6h")}
            >
              {formatMessage({ id: "6h" })}
            </div> */}
            <div
              className={`p-[4px] text-center w-[42px] cursor-pointer h-[32px]   rounded-[4px] flex flex-row items-center justify-center ${
                activeTimeButton === "1d"
                    ? "main-button-light"
                    : "bg-transparent primary-text"
              }`}
              onClick={() => handleTimeButtonClick("1d")}
            >
              {formatMessage({ id: "24h" })}
            </div>
            <div
              className={`p-[4px] text-center w-[42px] cursor-pointer h-[32px] rounded-[4px] flex flex-row items-center justify-center ${
                activeTimeButton === "7d"
                    ? "main-button-light"
                    : "bg-transparent primary-text"
              }`}
              onClick={() => handleTimeButtonClick("7d")}
            >
              {formatMessage({ id: "7d" })}
            </div>
            <div
              className={`p-[4px] text-center w-[42px] cursor-pointer h-[32px] rounded-[4px] flex flex-row items-center justify-center ${
                activeTimeButton === "30d"
                    ? "main-button-light"
                    : "bg-transparent primary-text"
              }`}
              onClick={() => handleTimeButtonClick("30d")}
            >
              {formatMessage({ id: "30d" })}
            </div>
          </div>

          <div className="flex flex-row gap-[12px]">
            <select
              className="form-select filter-tab-container cursor-pointer lg:min-w-[160px] border main-border !rounded-[8px]"
              aria-label="Select example"
              value={category}
              onChange={(e) => {setCategory(e.target.value || undefined)}}
            >
              <option value={''}>{formatMessage({ id: "All Categories" })}</option>
              {
                referenceData?.platformOptions?.categoryPersonalityTypes?.map(
                  (el: any, index: number) => {
                    return (
                      <option value={el.category}>{el.category}</option>
                    )
                  }
                )
              }
            </select>

            <select
              className="form-select filter-tab-container cursor-pointer lg:min-w-[160px] border main-border !rounded-[8px]"
              aria-label="Select example"
              value={country_id}
              onChange={(e) => {setCountryId(e.target.value || undefined)}}
            >
              <option value={''}>{formatMessage({ id: "All Countries" })}</option>
              {
                referenceData?.platformOptions?.countries?.map(
                  (el: any, index: number) => {
                    return (
                      <option value={el.countryId}>{el.country_name}</option>
                    )
                  }
                )
              }
            </select>
          </div>
          {!ViewAllList ? (
            <Link
              to={`/marketplace/trending?tab=${resourceType}&period=${time_frame}${category ? '&category='+category: ''}${country_id ? '&country='+country_id: ''}`}
              className="flex flex-row gap-[10px] items-center text-[#C2D24B] font-[500] text-[16px] leading-[24px]"
              onClick={() => window.scrollTo({top:0})}
            >
              {formatMessage({ id: "View All" })}
              <svg
                width="6"
                height="10"
                viewBox="0 0 6 10"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M1 1L5 5L1 9"
                  stroke="#C2D24B"
                  strokeWidth="1.6"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </Link>
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default LeaderboardListFilters;
