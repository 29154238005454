import React from "react";
import { Route, Routes } from "react-router-dom";
import ViewPage from "./pages/view";
import InteractionsPage from "./pages/view/interactions";
import UserInbox from "./containers/user-inbox";
const EngageNShareRouting = () => {
  return (
    <Routes>
      <Route path="user-interactions" element={<InteractionsPage/>}/>
      <Route path="user-inbox" element={<UserInbox/>}/>
      <Route path="*" element={<ViewPage />} />
    </Routes>
  );
};

export default EngageNShareRouting;



