import React, { useState } from "react";
import TextInput from "../../../widgets/components/Input/TextInput";
import { useIntl } from "react-intl";
import { Form, Formik } from "formik";
import TextArea from "../../../widgets/components/Input/TextArea";
import { InfoCard } from "../../../widgets/components/UI/InfoCard";
import Slider from "rc-slider";
import { SelectInput } from "../../../widgets/components/Input/SelectInput";
import Select from "../../../../components/select/select";
import FormikReactCreatable from "../../../widgets/components/Input/FormikReactCreatable";
import { updatePersonalityInfo } from "../../../profile/core/_requests";
import { useAuth } from "../../../auth";
import * as Yup from "yup";
import { toast } from "react-toastify";

const ConversationStyle: React.FC<any> = ({ setOpenEdit, personalityData }) => {
  const { formatMessage } = useIntl();
  const [disableSave, setDisableSave] = useState(false);
  const {
    referenceData,
    setSelectedPersonality,
    selectedPersonality: updatedData,
  } = useAuth();
  personalityData = updatedData || personalityData;

  const onSubmit = async (values: any, { setSubmitting }: any) => {
    // save data to api
    setDisableSave(true);
    setSubmitting(true);
    try {
      let {
        data: { success, data },
      } = await updatePersonalityInfo({
        personalityId: personalityData.personalityId,
        personalityJson: {
          ...personalityData.personalityJson,
          conversation_style_of_this_ai_personality: values,
        },
      });
      if (success) {
        setSelectedPersonality(data);
        toast.success(
          formatMessage({
            id: "Conversation style details updated successfully",
          })
        );
      } else {
        setSubmitting(false);
        toast.error(
          formatMessage({ id: "Error when updating Conversation Details" }),
          {
            autoClose: false,
            closeButton: true, // Display close button (cross icon)
          }
        );
      }
      setDisableSave(false);
    } catch (e) {
      toast.error(
        formatMessage({ id: "Error when updating Conversation details" }),
        {
          autoClose: false,
          closeButton: true, // Display close button (cross icon)
        }
      );
      setSubmitting(false);
      setDisableSave(false);
    }
  };

  const conversationValidationSchema = Yup.object().shape({
    filler_words: Yup.array()
      .of(
        Yup.string()
          // .min(2, "Filler word must have at least 2 characters")
          .max(50, "Filler word can have at most 50 characters")
      )
      // .min(1, "At least 1 filler word is required")
      .max(10, "Filler words cannot exceed 10")
      .test("unique", "Filler words must be unique", (value) => {
        if (value) {
          const uniqueSet = new Set(value);
          return uniqueSet.size === value.length;
        }
        return true;
      }),
    response_of_this_ai_personality_when_it_doesnt_know_the_answer: Yup.string()
      // .min(10, "Response must have at least 10 characters")
      .max(500, "Response can have at most 500 characters"),
    response_of_this_ai_personality_when_faces_any_server_error: Yup.string()
      // .min(10, "Response must have at least 10 characters")
      .max(500, "Response can have at most 500 characters"),
  });

  return (
    <Formik
      initialValues={
        personalityData?.personalityJson
          ?.conversation_style_of_this_ai_personality || {}
      }
      onSubmit={onSubmit}
      validationSchema={conversationValidationSchema}
      validateOnMount
    >
      {(formik) => {
        return (
          <Form>
            <div className={"grid grid-cols-12 gap-y-[20px] md:gap-x-[40px]"}>
              <div className={"col-span-12 md:col-span-7 order-0"}>
                <div className={"mb-[24px]"}>
                  <FormikReactCreatable
                    tooltipText={formatMessage({
                      id: "GLOBAL.TOOLTIP.EDIT_AI_PERSONALITY.CONVERSATION_STYLE",
                    })}
                    label={formatMessage({
                      id: "Commonly used filler words",
                    })}
                    className={"without-arrow"}
                    isMulti={true}
                    isClearable={false}
                    name={"filler_words"}
                    formik={formik}
                    defaultValue={formik.values?.filler_words?.map(
                      (el: string, index: number) => {
                        return { value: el, label: el, id: index };
                      }
                    )}
                  />
                </div>
                <TextArea
                  label={formatMessage({
                    id: "Standard reply when this AI Personality doesn’t know a specific answer",
                  })}
                  fieldName={
                    "response_of_this_ai_personality_when_it_doesnt_know_the_answer"
                  }
                  placeholder={formatMessage({
                    id: "Write a paragraph describing who your personality is",
                  })}
                  // defaultValue={
                  //     "Well, I must admit, I don't have all the answers. While I've traversed the world of cinema extensively, I'm still learning in other areas. Let's keep our conversations entertaining and enlightening, exploring what we both love - the magic of cinema."
                  // }
                  formik={formik}
                  toolTipText={formatMessage({
                    id: "GLOBAL.TOOLTIP.EDIT_AI_PERSONALITY.CONVERSATION_STYLE.DOES_NOT_KNOW",
                  })}
                  className={"!min-h-[140px]"}
                />
                <TextArea
                  label={formatMessage({
                    id: "Standard reply when there is a server error",
                  })}
                  fieldName={
                    "response_of_this_ai_personality_when_faces_any_server_error"
                  }
                  placeholder={formatMessage({
                    id: "Standard reply when there is a server error",
                  })}
                  // defaultValue={
                  //     "Ah, it seems we've hit a snag in our conversation due to internet issues or a server glitch. Don't worry, these things happen. Just like in my films, a little drama adds suspense. We'll reconnect soon!"
                  // }
                  formik={formik}
                  toolTipText={formatMessage({
                    id: "GLOBAL.TOOLTIP.EDIT_AI_PERSONALITY.CONVERSATION_STYLE.SERVER_ERROR",
                  })}
                  className={"!min-h-[140px]"}
                />
              </div>

              <div
                className={
                  "col-span-12 md:col-span-5 flex flex-col justify-start gap-[20px]"
                }
              >
                <div
                  className={
                    "flex justify-end gap-[10px] md:order-1 md:mt-auto"
                  }
                >
                  {/* <button
                    onClick={() => setOpenEdit(false)}
                    className={
                      "main-button-primary-light text-[14px] leading-5 font-medium py-[12px] w-1/2 md:w-[128px] rounded"
                    }
                  >
                    {formatMessage({
                    id: "Cancel",
                  })}
                    
                  </button> */}
                  <button
                    type="submit"
                    className={`main-button text-[14px] leading-5 font-medium py-[12px] w-full sm:w-1/2  md:w-[140px] rounded ${
                      disableSave ? "opacity-75" : ""
                    }`}
                    disabled={disableSave}
                  >
                    {formatMessage({
                      id: `${disableSave ? "Please Wait" : "Save"}`,
                    })}
                  </button>
                </div>
                <InfoCard
                  title={formatMessage({
                    id: "Understanding Conversation Style of AI Personality",
                  })}
                  desc={
                    <>
                      {formatMessage({
                        id: 'In Kamoto.AI, the "Conversation Style" section empowers you to customize the way your virtual AI persona engages in conversations. You can define commonly used fillers, those little linguistic quirks that make your AI personality\'s interactions more natural and relatable. ',
                      })}
                      <br></br>
                      <br></br>
                      {formatMessage({
                        id: "Additionally, you have the ability to set standard replies for situations when your AI personality doesn't have a specific answer or when there is a Kamoto.AI server error.",
                      })}
                      <br></br>
                      <br></br>
                      {formatMessage({
                        id: "By shaping the conversation style of your AI persona, you create a unique and engaging experience for users. With Kamoto.AI, you have the tools to craft AI personalities that strike the perfect balance between authenticity and functionality, fostering meaningful and interactive dialogues.",
                      })}
                      <br></br>
                      <br></br>
                    </>
                  }
                  slug={""}
                  isAnchor={
                    "https://help.kamoto.ai/en/articles/7941878-understanding-the-conversation-style-tab-of-ai-personalities"
                  }
                />
              </div>
            </div>
          </Form>
        );
      }}
    </Formik>
  );
};

export default ConversationStyle;
