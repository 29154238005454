import React, { CSSProperties, useEffect, useRef, useState } from "react";
import Sider from "../components/sider";
import { InfoCard } from "../../widgets/components/UI/InfoCard";
import { useIntl } from "react-intl";
import { map } from "lodash";
import LinkChatItem from "../components/chat-item/link-chat-item";
import { Form, Formik } from "formik";
import TextInput from "../../widgets/components/Input/TextInput";
import clsx from "clsx";
import { useAuth } from "../../auth";
import {
  createTraining,
  deleteTraining,
  getTrainingsByType,
  updateTraining,
} from "../core/_request";
import { BasicButton } from "../../widgets/components/UI/BasicButton";
import { CustomButton } from "../../widgets/components/UI/CustomButton";
import * as Yup from "yup";
import { toast } from "react-toastify";
import { useCSVReader } from "react-papaparse";
import { Modal, Spinner } from "react-bootstrap";
import useInfiniteScroll from "../../../hooks/useInfiniteScroll";

type LinkItem = {
  link: string;
  title: string;
  status: string;
};

const Trainbylink: React.FC<any> = ({}) => {
  const [openSider, setOpenSider] = useState(false);
  const [activeChat, setActiveChat] = useState<null | number>(null);
  const [createFormOpen, setCreateFormOpen] = useState(false);
  const [uploadFormOpen, setUploadFormOpen] = useState(false);
  const [trainings, setTrainings] = useState<any[]>([]);
  const [activeTraining, setActiveTraining] = useState<any>(null);
  const [selectedTraining, setSelectedTraining] = useState<any>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const { formatMessage } = useIntl();
  const { selectedPersonality, currentUser } = useAuth();
  const [bulkLinks, setBulkLinks] = useState<LinkItem[]>([]);
  const [draftTrainings, setDraftTrainings] = useState<string[]>([]);
  const [deleteModalOpen, setDeleteModalOpen] = useState<boolean>(false);
  const [page, setPage] = useState<number>(1);
  const ref = useRef();
  const { removeListener } = useInfiniteScroll(ref, 140, () => {
    setPage((page) => page + 1);
  });
  async function get() {
    const {
      data: { data, success },
    } = await getTrainingsByType(
      selectedPersonality.personalityId,
      "link",
      page
    );
    if (success) {
      setTrainings((trainings) => [...trainings, ...data]);
      if (!data.length) {
        removeListener();
      }
    }
    setLoading(false);
  }

  const user = {
    username: currentUser?.username,
    userId: currentUser?.userId,
    firstName: currentUser?.firstName,
    lastName: currentUser?.lastName,
    photo: currentUser?.photo,
  };
  async function addTraining(items: { [key: string]: any }) {
    const {
      data: { success, data, errors },
    } = await createTraining(selectedPersonality.personalityId, "link", items);
    if (success) {
      data.map((item: any) => (item.user = user));
      setTrainings((trainings) => [...data, ...trainings]);
      toast.success("Training added successfully");
    } else {
      toast.error(errors[0]?.includes("Quota") ?"Your Quota for training by link has been exhausted" : "Training cannot be created at the moment",
      {
        autoClose: false,
        closeButton: true, // Display close button (cross icon)
      });
    }
    return success;
  }

  useEffect(() => {
    const draftIds = trainings.reduce((acc, item) => {
      if (item.status === "draft") {
        acc.push(item.trainingId);
      }
      return acc;
    }, []);

    setDraftTrainings(draftIds);
  }, [trainings]);

  useEffect(() => {
    selectedPersonality && get();
  }, [selectedPersonality, page]);

  const initialValues = {
    title: activeTraining?.title,
    link: activeTraining?.publicLink,
  };

  const schema = Yup.object().shape({
    title: Yup.string().required(formatMessage({ id: "Title is required" })),
    link: Yup.string()
      .url(formatMessage({ id: "Public link is invalid" }))
      .required(formatMessage({ id: "Public link is required" })),
  });

  const { CSVReader } = useCSVReader();
  const styles = {
    csvReader: {
      display: "flex",
      flexDirection: "row",
      marginBottom: 10,
    } as CSSProperties,
    browseFile: {
      width: "20%",
    } as CSSProperties,
    acceptedFile: {
      border: "1px solid #ccc",
      height: 45,
      lineHeight: 2.5,
      paddingLeft: 10,
      width: "80%",
    } as CSSProperties,
    remove: {
      borderRadius: 0,
      padding: "0 20px",
    } as CSSProperties,
    progressBarBackgroundColor: {
      backgroundColor: "#c2d24b",
    } as CSSProperties,
  };

  const isEditable =
    currentUser?.userId == activeTraining?.userId &&
    activeTraining?.status == "draft";
  return (
    <div
      className={
        "tab-container rounded h-[calc(100vh-200px)] lg:h-[calc(100vh-220px)] mt-4"
      }
    >
      <div className={"flex h-full"}>
        <Sider
          open={openSider}
          setOpen={setOpenSider}
          onPlusClick={setCreateFormOpen}
          heading={formatMessage({ id: "Public Links for Training " })}
          ref={ref}
          draftTrainings={draftTrainings}
          setTrainings={setTrainings}
        >
          <ul>
            {map(trainings, (item, index) => (
              <li key={item.trainingId}>
                <LinkChatItem
                  training={item}
                  setCreateFormOpen={setCreateFormOpen}
                  setActiveTraining={setActiveTraining}
                  activeTraining={activeTraining}
                  setDeleteModalOpen={setDeleteModalOpen}
                  setSelectedTraining={setSelectedTraining}
                  isOwned={item.userId === currentUser?.userId}
                />
              </li>
            ))}
          </ul>
          {loading && (
            <div className="h-[100px] w-full d-flex justify-content-center align-items-center">
              <Spinner animation="border" />
            </div>
          )}
        </Sider>
        <div
          className={
            "flex-grow  md:p-[16px_16px_24px] relative overflow-y-auto pr-2 custom-scroll h-[calc(100vh-200px)] lg:h-[calc(100vh-220px)]"
          }
        >
          <button onClick={() => setOpenSider(true)} className={"md:hidden "}>
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M5.14291 7.42859H18.8572M5.14062 12H18.8515M5.14291 16.5714H18.8515"
                stroke="#8898A6"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </button>
          {true ? (
            <div>
              <InfoCard
                title={formatMessage({
                  id: "How to train AI personality using public links?",
                })}
                desc={
                  <>
                    {formatMessage({
                      id: "Training your AI personality with Kamoto.AI through public links is a streamlined process. Simply provide the publicly accessible URL of the specific web page you want our AI engine to crawl to. This information will be used to enhance the AI's intelligence. All that's required from you is the URL. Once processed, your AI personality or character will leverage the information from that web page to create more informed and context-aware replies.",
                    })}
                    <br></br>
                    <br></br>
                  </>
                }
                slug={""}
                isAnchor={
                  "https://help.kamoto.ai/en/articles/7945516-training-your-ai-personality-enhance-capabilities-with-public-links-in-kamotoai"
                }
              />

              <Formik
                initialValues={{ title: "", link: "" }}
                onSubmit={async (values, { resetForm, setSubmitting }) => {
                  setSubmitting(true);
                  const success: boolean = await addTraining({
                    links: [values],
                  });
                  if (success) {
                    resetForm();
                  }
                  setSubmitting(false);
                }}
                validationSchema={schema}
                validateOnMount
              >
                {(formik) => (
                  <Form>
                    <TextInput
                      label={formatMessage({
                        id: "Title",
                      })}
                      toolTipText={formatMessage({
                        id: "Please, Enter title of the link",
                      })}
                      fieldName={"title"}
                      placeholder={formatMessage({
                        id: "Enter title here",
                      })}
                      fieldType={"text"}
                      formik={formik}
                    />
                    <TextInput
                      label={formatMessage({
                        id: "Public URL",
                      })}
                      toolTipText={formatMessage({
                        id: "Please, Enter title of the Public URL",
                      })}
                      fieldName={"link"}
                      placeholder={formatMessage({
                        id: "Enter Public URL here",
                      })}
                      fieldType={"text"}
                      formik={formik}
                    />
                    <div
                      className={
                        "flex md:flex-row flex-col-reverse md:items-center gap-2 md:justify-end text-[13px] leading-5"
                      }
                    >
                      <BasicButton
                        buttonText={formatMessage({ id: "Cancel" })}
                        border="none"
                        color="#C2D24B1A"
                        textColor="#C2D24B"
                        customClass={"px-[24px]"}
                        height="44px"
                        onClick={() => formik.resetForm()}
                      />
                      <CustomButton
                        isSubmitting={formik.isSubmitting}
                        isValid={formik.isValid}
                        buttonText={formatMessage({ id: "Save" })}
                        loading={loading}
                        height={44}
                        widthLoading={1}
                        width={1}
                        onSubmit={formik.handleSubmit}
                      />
                      {/* <button
                        className={
                          "py-2 px-6 bg-[#C2D24B1A] text-[#C2D24B] rounded w-1/2 md:w-auto"
                        }
                        onClick={()=>formik.resetForm()}
                      >
                        Cancel
                      </button>
                      <button
                        className={
                          "py-2 px-6 bg-[#C2D24B] text-[#000] rounded w-1/2 md:w-auto"
                        }
                      >
                        Save
                      </button> */}
                    </div>
                    <div>
                      <div className={"secondary-bg p-6 rounded mt-8"}>
                        <div className={"flex items-center justify-between"}>
                          <div className={"flex items-center gap-x-3"}>
                            <svg
                              width="36"
                              height="36"
                              viewBox="0 0 36 36"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <g clipPath="url(#clip0_497_14067)">
                                <path
                                  d="M21 4.5V10.5C21 10.8978 21.158 11.2794 21.4393 11.5607C21.7206 11.842 22.1022 12 22.5 12H28.5"
                                  stroke="#C2D24B"
                                  strokeWidth="2"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                                <path
                                  d="M18 16.5V25.5M25.5 31.5H10.5C9.70435 31.5 8.94129 31.1839 8.37868 30.6213C7.81607 30.0587 7.5 29.2956 7.5 28.5V7.5C7.5 6.70435 7.81607 5.94129 8.37868 5.37868C8.94129 4.81607 9.70435 4.5 10.5 4.5H21L28.5 12V28.5C28.5 29.2956 28.1839 30.0587 27.6213 30.6213C27.0587 31.1839 26.2956 31.5 25.5 31.5Z"
                                  stroke="#C2D24B"
                                  strokeWidth="2"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                                <path
                                  d="M14.25 20.25L18 16.5L21.75 20.25"
                                  stroke="#C2D24B"
                                  strokeWidth="2"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                              </g>
                              <defs>
                                <clipPath id="clip0_497_14067">
                                  <rect width="36" height="36" fill="white" />
                                </clipPath>
                              </defs>
                            </svg>
                            <div>
                              <h3>
                                {formatMessage({ id: "Bulk upload Links" })}
                              </h3>
                              <p>
                                {formatMessage({
                                  id: "You can upload csv or xls of links directly",
                                })}
                              </p>
                            </div>
                          </div>
                          <button
                            onClick={(e) => {
                              e.preventDefault();
                              setUploadFormOpen((prevState) => !prevState);
                            }}
                          >
                            <svg
                              className={clsx(
                                "transition-all duration-200",
                                !uploadFormOpen && "-rotate-90"
                              )}
                              width="18"
                              height="9"
                              viewBox="0 0 18 9"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                fillRule="evenodd"
                                clipRule="evenodd"
                                d="M8.159 7.87251C8.55948 8.07856 9.07387 8.03998 9.42935 7.7445L16.6473 1.74449C17.0938 1.37337 17.1222 0.740391 16.7109 0.330709C16.2995 -0.0789738 15.6041 -0.110236 15.1577 0.260884L8.71851 5.61352L2.87259 0.313067C2.44283 -0.0765945 1.74715 -0.0753738 1.31874 0.315787C0.890341 0.706949 0.891441 1.33994 1.32121 1.72961L7.91223 7.70564C7.98713 7.77356 8.0704 7.82918 8.159 7.87251Z"
                                className={"icon"}
                                fillOpacity="0.25"
                              />
                            </svg>
                          </button>
                        </div>
                      </div>
                    </div>
                  </Form>
                )}
              </Formik>
              {uploadFormOpen && (
                <Formik
                  initialValues={{}}
                  onSubmit={async (values, { resetForm, setSubmitting }) => {
                    setSubmitting(true);
                    const success: boolean = await addTraining({
                      links: bulkLinks,
                    });
                    if (success) {
                      resetForm();
                    }
                    setSubmitting(false);
                  }}
                >
                  {(formik) => (
                    <div className={clsx("mt-4")}>
                      <CSVReader
                        onUploadAccepted={(results: any) => {
                          if (results?.data?.length) {
                            let out = [];
                            for (let i = 1; i < results.data.length; i++) {
                              if (results.data[i][0] && results.data[i][1]) {
                                out.push({
                                  title: results.data[i][0],
                                  link: results.data[i][1],
                                  status: "",
                                });
                              }
                            }
                            setBulkLinks(out);
                          }
                        }}
                      >
                        {({
                          getRootProps,
                          acceptedFile,
                          ProgressBar,
                          getRemoveFileProps,
                        }: any) => (
                          <>
                            <div style={styles.csvReader}>
                              <button
                                type="button"
                                {...getRootProps()}
                                style={styles.browseFile}
                              >
                                Browse file
                              </button>
                              <div style={styles.acceptedFile}>
                                {acceptedFile && acceptedFile.name}
                              </div>
                              <button
                                {...getRemoveFileProps()}
                                style={styles.remove}
                              >
                                Remove
                              </button>
                            </div>
                            <ProgressBar
                              style={styles.progressBarBackgroundColor}
                            />
                          </>
                        )}
                      </CSVReader>
                      <CustomButton
                        isValid={!!bulkLinks.length}
                        buttonText={"Create"}
                        loading={formik.isSubmitting}
                        height={44}
                        widthLoading={1}
                        width={1}
                        onSubmit={formik.handleSubmit}
                      />
                      {/* <FileUpload /> */}
                    </div>
                  )}
                </Formik>
              )}
            </div>
          ) : activeTraining ? (
            <Formik
              initialValues={initialValues}
              validationSchema={schema}
              enableReinitialize
              onSubmit={async (values, { setSubmitting }) => {
                setSubmitting(true);
                const {
                  data: { success, errors, data },
                } = await updateTraining(activeTraining.trainingId, values);
                if (success) {
                  const updated = trainings.map((item) => {
                    if (item.trainingId === activeTraining.trainingId) {
                      return {
                        ...item,
                        title: data.title,
                        pubicLink: data.link,
                      };
                    } else return item;
                  });
                  setTrainings(updated);
                } else {
                  toast.error(errors[0],
                    {
                      autoClose: false,
                      closeButton: true, // Display close button (cross icon)
                    });
                }
                setSubmitting(false);
              }}
            >
              {(formik) => (
                <Form>
                  <TextInput
                    label={"Title"}
                    toolTipText={formatMessage({
                      id: "Please, Enter title of the link",
                    })}
                    fieldName={"title"}
                    placeholder={"Enter title here"}
                    fieldType={"text"}
                    isDisabled={!isEditable}
                    formik={formik}
                  />
                  <TextInput
                    label={"Public URL"}
                    toolTipText={formatMessage({
                      id: "Please, Enter title of the Public URL",
                    })}
                    fieldName={"link"}
                    placeholder={"Enter Public URL here"}
                    fieldType={"text"}
                    formik={formik}
                    isDisabled={!isEditable}
                  />
                  {isEditable && (
                    <div
                      className={
                        "flex items-center gap-x-2 justify-end text-[13px] leading-5"
                      }
                    >
                      <BasicButton
                        buttonText={formatMessage({ id: "Cancel" })}
                        border="none"
                        color="#C2D24B1A"
                        textColor="#C2D24B"
                        customClass={"px-[24px]"}
                        height="44px"
                        onClick={() => formik.resetForm()}
                      />
                      <CustomButton
                        isSubmitting={formik.isSubmitting}
                        isValid={formik.isValid && formik.dirty}
                        buttonText={"Save"}
                        loading={loading}
                        height={44}
                        widthLoading={1}
                        width={1}
                        onSubmit={formik.handleSubmit}
                      />
                    </div>
                  )}
                </Form>
              )}
            </Formik>
          ) : null}
        </div>
        <Modal
          aria-labelledby="contained-modal-title-vcenter"
          centered
          show={deleteModalOpen}
          onHide={() => setDeleteModalOpen(false)}
        >
          <Modal.Header closeButton>
            <Modal.Title
              className="modal-title text-[20px] leading-[28px] font-weight-600 text-[#FFFFFFCC] "
              id="contained-modal-title-vcenter"
            >
              {formatMessage({ id: "Delete Confirmation" })}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body className=" p-[16px] md:p-[24px]">
            <p className="text-[#FFFFFFCC] text-[16px]">
              {formatMessage({ id: "Are you sure to delete" })}{" "}
              <span className="text-[#C2D24B]">
                {selectedTraining?.title || "this"}
              </span>{" "}
              {formatMessage({ id: "training?" })}
            </p>
            <Formik
              initialValues={{}}
              onSubmit={async (values, { setSubmitting }) => {
                setSubmitting(true);
                const {
                  data: { success, data },
                } = await deleteTraining(selectedTraining.trainingId);
                if (success) {
                  setTrainings((trainings) =>
                    trainings.filter(
                      ({ trainingId }) =>
                        trainingId !== selectedTraining.trainingId
                    )
                  );
                  setDeleteModalOpen(false);
                  setActiveTraining(selectedTraining.trainingId==trainings[0].trainingId ? null : trainings[0]);
                  toast.success("Training deleted successfully");
                } else {
                  toast.error("Error while deleting the training",
                  {
                    autoClose: false,
                    closeButton: true, // Display close button (cross icon)
                  });
                }
                setSubmitting(false);
              }}
            >
              {(formik) => (
                <div className="flex gap-x-3 justify-end items-center">
                  <BasicButton
                    buttonText={formatMessage({ id: "Cancel" })}
                    border="none"
                    color="#C2D24B1A"
                    textColor="#C2D24B"
                    customClass={"px-[24px] mt-8"}
                    height="44px"
                    onClick={() => {
                      setDeleteModalOpen(false);
                    }}
                  />
                  <CustomButton
                    customClass="mt-8"
                    isSubmitting={formik.isSubmitting}
                    isValid={true}
                    buttonText={formatMessage({ id: "Delete" })}
                    loading={formik.isSubmitting}
                    height={44}
                    widthLoading={1}
                    width={1}
                    onSubmit={formik.handleSubmit}
                  />
                </div>
              )}
            </Formik>
          </Modal.Body>
        </Modal>
      </div>
    </div>
  );
};

export default Trainbylink;
