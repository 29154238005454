import React from "react";
import Select, { StylesConfig } from "react-select";
import clsx from "clsx";
import { ToolTipUI } from "../../modules/widgets/components/UI/ToolTipUI";
import { inspect } from "util";
import { isEqual } from "lodash";
import { useThemeMode } from "../../../_metronic/partials";
import { StateManagerProps } from "react-select/dist/declarations/src/useStateManager";

// @ts-ignore

type MyOption = {
  label: string;
  value: any;
};

type GroupedOption = {
  label: string; // group label
  options: MyOption[];
};

type Props = {
  name: string;
  label: string;
  tooltipText: string;
  isFieldArray: boolean;
} & Omit<
  StateManagerProps<MyOption, false | true, GroupedOption>,
  "value" | "onChange"
>;
const CustomSelect: React.FC<any> = ({
  label = "label",
  tooltipText = "toolTipText",
  className,
  height = 44,
  ...rest
}) => {
  const { mode } = useThemeMode();
  const colourStyles: StylesConfig<MyOption, false | true, GroupedOption> = {
    control: (styles, { isFocused }) => ({
      ...styles,
      backgroundColor: isEqual(mode, "light") ? "#F5F8FA" : "#2E2F45",
      borderWidth: "1px",
      borderColor: isEqual(mode, "light") ? "#DBDCDE" : "#ffffff1a",
      boxShadow: "none",
      color: isEqual(mode, "light") ? "#383940" : "#FFFFFFCC",
      height: height,
      minHeight: height,
    }),
    menu: (styles) => {
      return {
        ...styles,
        backgroundColor: isEqual(mode, "light") ? "#F5F8FA" : "#2E2F45",
        color: isEqual(mode, "light") ? "#383940" : "#FFFFFFCC",
      };
    },
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      return {
        ...styles,
        // color: isDisabled
        //     && "red",
        cursor: isDisabled ? "not-allowed" : "default",
        ":active": {
          ...styles[":active"],
        },
      };
    },

    input: (styles) => ({ ...styles }),
    placeholder: (styles) => ({
      ...styles,
      color: isEqual(mode, "light") ? "#383940" : "#FFFFFFCC",
    }),
    singleValue: (styles, { data }) => ({
      ...styles,
      color: isEqual(mode, "light") ? "#383940" : "#FFFFFFCC",
    }),
    menuList: (styles) => ({
      ...styles,
      color: isEqual(mode, "light") ? "#383940" : "#FFFFFFCC",
    }),
    multiValue: (styles) => {
      return {
        ...styles,
        alignItems: "center",
        backgroundColor: isEqual(mode, "light") ? "#F5F8FA" : "#171825",
        borderRadius: "40px",
        padding: "2px 10px",
        color: isEqual(mode, "light") ? "#383940" : "#FFFFFFCC",
      };
    },
  };

  return (
    <div className={clsx(label && "mb-[16px] md:mb-[24px]")}>
      {label && (
        <label
          className={
            "flex gap-2 items-center text-[13px] leading-[20px] secondary-text mb-[4px]"
          }
        >
          {label}
          <ToolTipUI tooltipText={tooltipText} />
        </label>
      )}
      <Select
        {...rest}
        className={clsx(className, "react-select")}
        styles={colourStyles}
        classNames={{
          option: (state) => {
            if (state.isFocused) {
              return "!text-[#000] !cursor-pointer";
            } else if (state.isSelected) {
              return "!text-[#000] !cursor-pointer !bg-[#C2D24B]";
            } else {
              return "text-blue-400";
            }
          },
        }}
        theme={(theme) => ({
          ...theme,
          colors: {
            ...theme.colors,
            primary25: "#C2D24B",
            primary: "#FFFFFF1A",
          },
        })}
      />
    </div>
  );
};

export default CustomSelect;
