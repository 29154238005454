import { ErrorMessage, Field, getIn, useField, useFormikContext } from "formik";
import React, { ReactElement, useEffect, useState } from "react";
import  { StylesConfig } from "react-select";
import AsyncSelect from "react-select/async"
import { StateManagerProps } from "react-select/dist/declarations/src/useStateManager";
import clsx from "clsx";
import { ToolTipUI } from "../UI/ToolTipUI";
import { useThemeMode } from "../../../../../_metronic/partials";
import { isEqual } from "lodash";

type MyOption = {
  label: string;
  value: any;
};

type GroupedOption = {
  label: string; // group label
  options: MyOption[];
};

type Props = {
  name: string;
  label: string;
  tooltipText: string;
  isFieldArray: boolean;
  defaultOptions:MyOption[];
  promiseOptions:(inputValue: string) => Promise<MyOption[]>;
  labelNote?: ReactElement;
  selection:any;
  setSelection: React.SetStateAction<any>;
} & Omit<
  StateManagerProps<MyOption, false | true, GroupedOption>,
  "value" | "onChange"
>;

const AsyncFormikReactSelect: React.FC<any> = (props: Props) => {
  const { mode } = useThemeMode();
  const { name, className, label, tooltipText, isFieldArray,defaultOptions,promiseOptions,labelNote, selection=null, setSelection, ...restProps } =
    props;
  const [field] = useField(name);
  const { setFieldValue } = useFormikContext();

  useEffect(()=>{
    if(selection){
      setFieldValue(name,selection.value)
    }
  },[selection])

  const colourStyles: StylesConfig<MyOption, false | true, GroupedOption> = {
    control: (styles, { isFocused }) => ({
      ...styles,
      backgroundColor: isEqual(mode, "light") ? "#F5F8FA" : "#2E2F45",
      borderWidth: "1px",
      borderColor: isEqual(mode, "light") ? "#DBDCDE" : "#ffffff1a",
      boxShadow: "none",
      color: isEqual(mode, "light") ? "#383940" : "#FFFFFFCC",
    }),
    menu: (styles) => {
      return {
        ...styles,
        backgroundColor: isEqual(mode, "light") ? "#F5F8FA" : "#2E2F45",
        color: isEqual(mode, "light") ? "#383940" : "#FFFFFFCC",
      };
    },
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      return {
        ...styles,
        color: isDisabled
          ? "#red"
          : isSelected
          ? isEqual(mode, "light")
            ? "#383940"
            : "#FFFFFFCC"
          : isEqual(mode, "light")
          ? "#383940"
          : "#FFFFFFCC",
        cursor: isDisabled ? "not-allowed" : "default",
        ":active": {
          ...styles[":active"],
        },
      };
    },

    input: (styles) => ({ ...styles, 
      color: isEqual(mode, "light") ? "#383940" : "#FFFFFFCC",
     }),
    placeholder: (styles) => ({ ...styles }),
    singleValue: (styles, { data }) => ({
      ...styles,
      color: isEqual(mode, "light") ? "#383940" : "#FFFFFFCC",
    }),
    menuList: (styles) => ({
      ...styles,
      color: isEqual(mode, "light") ? "#383940" : "#FFFFFFCC",
    }),
    multiValue: (styles) => {
      return {
        ...styles,
        alignItems: "center",
        backgroundColor: isEqual(mode, "light") ? "#F5F8FA" : "#171825",
        borderRadius: "40px",
        padding: "2px 10px",
        color: isEqual(mode, "light") ? "#383940" : "#FFFFFFCC",
      };
    },
  };

  //flatten the options so that it will be easier to find the value
  const flattenedOptions = props.options?.flatMap((o: any) => {
    const isNotGrouped = "value" in o;
    if (isNotGrouped) {
      return o;
    } else {
      return o.options;
    }
  });

  //get the value using flattenedOptions and field.value
  const value = flattenedOptions?.filter((o: any) => {
    const isArrayValue = Array.isArray(field.value);

    if (isArrayValue) {
      const values = field.value as Array<any>;
      return values.includes(o.value);
    } else {
      return field.value === o.value;
    }
  });

  const FieldArrayErrorMessage = ({ name }: any) => (
    <Field
      name={name}
      render={({ form }: any) => {
        const error = getIn(form.errors, name);
        const touch = getIn(form.touched, name);

        return touch && error ? error : null;
      }}
    />
  );
  return (
    <div className={"mb-[16px] md:mb-[24px]"}>
      <label
        className={
          "flex gap-2 items-center text-[13px] leading-[20px] primary-text mb-[4px]"
        }
      >
        {label}
        <ToolTipUI tooltipText={tooltipText} />
      </label>
      {labelNote ? labelNote : null}
      <AsyncSelect
        {...restProps}
        value={selection}
        defaultOptions={defaultOptions}
        loadOptions={promiseOptions}
        // onChange implementation
        onChange={(val) => {
          setSelection(val)
        }}
        className={clsx(className, "react-select")}
        styles={colourStyles}
        theme={(theme) => ({
          ...theme,
          colors: {
            ...theme.colors,
            primary25: "#C2D24B",
            primary: "#FFFFFF1A",
          },
        })}
      />
      <div className="text-danger mt-2">
        {isFieldArray ? (
          <FieldArrayErrorMessage name={name} />
        ) : (
          <ErrorMessage name={name} />
        )}
      </div>
    </div>
  );
};

export default AsyncFormikReactSelect;
