import allIcon from "../../../../../assets/images/event/categories/all.svg";
import allIconActive from "../../../../../assets/images/event/categories/allActive.svg";
import movieIcon from "../../../../../assets/images/event/categories/movie.svg";
import movieIconActive from "../../../../../assets/images/event/categories/movieActive.svg";
import sportIcon from "../../../../../assets/images/event/categories/sport.svg";
import sportIconActive from "../../../../../assets/images/event/categories/sportActive.svg";
import starIcon from "../../../../../assets/images/event/categories/star.svg";
import starIconActive from "../../../../../assets/images/event/categories/starActive.svg";
import educationIcon from "../../../../../assets/images/event/categories/education.svg";
import educationIconActive from "../../../../../assets/images/event/categories/educationActive.svg";
import carIcon from "../../../../../assets/images/event/categories/car.svg";
import carIconActive from "../../../../../assets/images/event/categories/carActive.svg";
import apartmentIcon from "../../../../../assets/images/event/categories/apartment.svg";
import apartmentIconActive from "../../../../../assets/images/event/categories/apartmentActive.svg";
import moneyIcon from "../../../../../assets/images/event/categories/money.svg";
import moneyIconActive from "../../../../../assets/images/event/categories/moneyActive.svg";
import blockchainIcon from "../../../../../assets/images/event/categories/blockchain.svg";
import blockchainIconActive from "../../../../../assets/images/event/categories/blockchainActive.svg";
import ecommerceIcon from "../../../../../assets/images/event/categories/ecommerce.svg";
import ecommerceIconActive from "../../../../../assets/images/event/categories/ecommerceActive.svg";
import legalIcon from "../../../../../assets/images/event/categories/legal.svg";
import legalIconActive from "../../../../../assets/images/event/categories/legalActive.svg";

export const categories: any[] = [
  {
    id: 878101,
    title: "All",
    icon: allIcon,
    activeIcon: allIconActive,
  },
  {
    id: 87962,
    title: "Bollywood",
    icon: movieIcon,
    activeIcon: movieIconActive,
  },
  {
    id: 56853,
    title: "Sports",
    icon: sportIcon,
    activeIcon: sportIconActive,
  },
  {
    id: 43254,
    title: "Hollywood",
    icon: starIcon,
    activeIcon: starIconActive,
  },
  {
    id: 12345,
    title: "Education",
    icon: educationIcon,
    activeIcon: educationIconActive,
  },
  {
    id: 571236,
    title: "Automobile",
    icon: carIcon,
    activeIcon: carIconActive,
  },
  {
    id: 53456,
    title: "Real Estate",
    icon: apartmentIcon,
    activeIcon: apartmentIconActive,
  },
  {
    id: 53287,
    title: "Consumer Products",
    icon: movieIcon,
    activeIcon: movieIconActive,
  },
  {
    id: 509987,
    title: "Company",
    icon: movieIcon,
    activeIcon: movieIconActive,
  },
  {
    id: 5123,
    title: "Banking",
    icon: moneyIcon,
    activeIcon: moneyIconActive,
  },
  {
    id: 534,
    title: "Ecommerce",
    icon: ecommerceIcon,
    activeIcon: ecommerceIconActive,
  },
  {
    id: 521,
    title: "Blockchain",
    icon: blockchainIcon,
    activeIcon: blockchainIconActive,
  },
  {
    id: 53,
    title: "Legal",
    icon: legalIcon,
    activeIcon: legalIconActive,
  },
];
