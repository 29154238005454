import { toast } from "react-toastify";

export const copyToClipboard = (
  textToCopy: string,
  alertToast: boolean = false,
  toastMessage: string = ""
) => {
  navigator.clipboard.writeText(textToCopy);
  if (alertToast && toastMessage) {
    toast.success(toastMessage);
  }
};

export function kmbConvertor(num: number, decimalPlaces: number = 0) {
  if (!num) return num;
  // decimalPlaces = 0;
  const absNum = Math.abs(num);

  if (absNum >= 1e9) {
    return `${(num / 1e9).toFixed(decimalPlaces)}B`;
  } else if (absNum >= 1e6) {
    return `${(num / 1e6).toFixed(decimalPlaces)}M`;
  } else if (absNum >= 1e3) {
    return `${(num / 1e3).toFixed(decimalPlaces)}K`;
  } else {
    return parseFloat(num.toString()).toFixed(decimalPlaces);
  }
}

export function commaSeparated(num:number, decimalUpto:number=2){
  if(!num) return num;
  return Number(Number(num).toFixed(decimalUpto)).toLocaleString()
}

export const EMOJI_REGEX =
/[\u{1F600}-\u{1F64F}]|[\u{1F300}-\u{1F5FF}]|[\u{1F680}-\u{1F6FF}]|[\u{1F700}-\u{1F77F}]|[\u{1F780}-\u{1F7FF}]|[\u{1F800}-\u{1F8FF}]|[\u{1F900}-\u{1F9FF}]|[\u{1FA00}-\u{1FA6F}]|[\u{2600}-\u{26FF}]|[\u{2700}-\u{27BF}]|\u{23E9}|\u{23EA}|\u{23EB}|\u{23EC}|\u{2B05}|\u{2B06}|\u{2B07}|\u{2B1B}|\u{2B1C}|\u{2B50}|\u{2B55}|[0-9]\u{FE0F}\u{20E3}|\u{203C}\u{FE0F}|\u{2049}\u{FE0F}|\u{2122}\u{FE0F}|\u{2139}\u{FE0F}|\u{2194}-\u{2199}|\u{21A9}\u{FE0F}|\u{21AA}\u{FE0F}|\u{231A}\u{FE0F}|\u{231B}\u{FE0F}|\u{2328}\u{FE0F}|\u{23CF}\u{FE0F}|\u{23E9}\u{FE0F}|\u{23EA}\u{FE0F}|\u{23EB}\u{FE0F}|\u{23EC}\u{FE0F}|\u{23ED}\u{FE0F}|\u{23EE}\u{FE0F}|\u{23EF}\u{FE0F}|\u{23F0}\u{FE0F}|\u{23F1}\u{FE0F}|\u{23F2}\u{FE0F}|\u{23F3}\u{FE0F}|\u{23F8}\u{FE0F}|\u{23F9}\u{FE0F}|\u{23FA}\u{FE0F}|\u{24C2}\u{FE0F}/gu;

