import axios from "axios"
import { ResponseType } from "../../../../../core/_models";

const API_URL = process.env.REACT_APP_BASE_API_URL;
export const FOLLOW = `${API_URL}/follow`
export const CHAT_SUBSCRIPTION = `${API_URL}/chat-subscription`
export const ADD_FEEDBACK=`${API_URL}/feedbacks`
export const FAN_PLANS = `${API_URL}/fan-plans`
export const followEntity = async (entityType: string, entityId: string) => {
    return await axios.post<ResponseType<{ amount: number }>>(FOLLOW, {
        entityType,
        entityId
    }, {
        validateStatus(status) {
            return true;
        },
    })
}

export const unfollowEntity = async (entityType: string, entityId: string) => {
    return await axios.delete<ResponseType<string>>(`${FOLLOW}/${entityType}/${entityId}`, {
        validateStatus(status) {
            return true;
        },
    })
}

export const getPersonalitySubscription = async(personalityId:string)=>{
    return await axios.get<ResponseType<any>>(`${CHAT_SUBSCRIPTION}`, {
        validateStatus(status) {
            return true;
        },
        headers:{
            'x-personality-id':personalityId
          }
    })
}

export const createPersonalitySubscription = async(personalityId:string)=>{
    return await axios.post<ResponseType<string>>(`${CHAT_SUBSCRIPTION}`, null,{
        validateStatus(status) {
            return true;
        },
        headers:{
            'x-personality-id':personalityId
          }
    })
}

export const updatePersonalitySubscription = async(personalityId:string)=>{
    return await axios.post<ResponseType<string>>(`${CHAT_SUBSCRIPTION}/update`, null,{
        validateStatus(status) {
            return true;
        },
        headers:{
            'x-personality-id':personalityId
          }
    })
}

export const getAllChatSubscriptions = async()=>{
    return await axios.get<ResponseType<any[]>>(`${CHAT_SUBSCRIPTION}/all`, {
        validateStatus(status) {
            return true;
        }
    })
}

export const addFeedback = async(personalityId:string, sessionId: string, rating: number, text: string)=>{
    return await axios.post<ResponseType<string>>(ADD_FEEDBACK, {
        rating,
        chatSessionId: sessionId,
        text
    },{
        validateStatus(status) {
            return true;
        },
        headers:{
            'x-personality-id':personalityId
          }
    })
}

export const getPersonalityChargePerMinute = async(personalityId:string)=>{
    return await axios.get<ResponseType<number>>(`${API_URL}/listings/${personalityId}?only=charge`, {
        validateStatus(status) {
            return true;
        }
    })
}

export const subscribeToFanPlan = async(chargebeePlanId:string, personalityId:string)=>{
    return await axios.post<ResponseType<any>>(`${FAN_PLANS}/subscribe`, {
        chargebeePlanId,
        medium: "web"
    }
    ,{
        headers:{
            "x-personality-id": personalityId
        },
        validateStatus(status) {
            return true;
        }
    })
}
export const subscribeToFanPlanViaRazorpay = async(planId:string, personalityId:string)=>{
    return await axios.post<ResponseType<any>>(`${FAN_PLANS}/subscribe/razorpay`, {
        planId,
        medium: "web"
    }
    ,{
        headers:{
            "x-personality-id": personalityId
        },
        validateStatus(status) {
            return true;
        }
    })
}

export const processRazorpayAuthPayment = async(data:any)=>{
    return await axios.post<ResponseType<any>>(`${FAN_PLANS}/subscribe/razorpay/process`,data,
    {
        validateStatus(status) {
            return true;
        }
    })
}


