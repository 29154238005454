const statusStyles:{[key:string]:string}={
    'success':"bg-[#00B7121A] text-[#00B712]",
    "failed":"bg-[#D24B4B1A] text-[#D24B4B]",
    "draft":"bg-[#F59A101A] text-[#FF9900]",
    "submitted":"bg-[#0090ff1A] text-[#0090ff]"
  }


export function getStatusStyle(status:string){
    return statusStyles[status] || statusStyles["submitted"]
}
const statusText:{[key:string]:string} = {
  'success':'Success',
  'failed':'Failed',
  'in-progress': 'In Progress',
  'submitted':'Pending',
  'chunking':'In Progress',
  'ready-for-training':'In Progress',
  'chunking-failed':'In Progress',
  'training':'Training Underway',
  'draft': 'Draft',
  'partial-success':'Partially Success'
}
export function getStatusText(status:string){
  if(!status) return '';
  return statusText[status] || (status[0].toUpperCase() + status.slice(1))
}