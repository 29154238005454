import React from "react";
import { useIntl } from "react-intl";
import { Link, NavLink, useLocation } from "react-router-dom";
import clsx from 'clsx'
import { useAuth } from "../../../../app/modules/auth";
 const MoreMenu:React.FC<any>=({isDark}) => {
  const { formatMessage } = useIntl();
  const {ipStackCountry} = useAuth()

  return (
    <div
      className={clsx("menu menu-sub menu-sub-dropdown w-140px nav-more-menu z-10 show") }
      // data-kt-menu="true"
    >
      <ul className="flex flex-col px-[24px] pt-[20px]">
        <li className="mb-[20px] sub-nav-link-hide1">
          <Link
            state={{rerender:'Celebrity'}}
            to={`/marketplace/explore?category=${"Celebrity"}&country=${ipStackCountry?.countryId}`}
            className="secondary-text text-[13px] leading-[18px] font-weight-500"
          >
            {formatMessage({ id: "Celebrities" })}
          </Link>
        </li>
        <li className="mb-[20px] sub-nav-link-hide1">
          <Link
            state={{rerender:'Influencer'}}
            to={`/marketplace/explore?category=${"Influencer"}&country=${ipStackCountry?.countryId}`}
            className="secondary-text text-[13px] leading-[18px] font-weight-500"
          >
            {formatMessage({ id: "Influencers" })}
          </Link>
        </li>
        {/* <li className="mb-[20px] sub-nav-link-hide2">
          <Link
            replace
            to={`/marketplace/explore?category=${"Sports Personality"}`}
            className="text-[#FFFFFFA6] text-[13px] leading-[18px] font-weight-500"
          >
            {formatMessage({ id: "Sports" })}
          </Link>
        </li> */}
        <li className="mb-[20px] sub-nav-link-hide2">
          <Link
            state={{rerender:'Fictional Character'}}
            to={`/marketplace/explore?category=${"Fictional Character"}&country=${ipStackCountry?.countryId}`}
            className="secondary-text text-[13px] leading-[18px] font-weight-500"
          >
            {formatMessage({ id: "Fictional" })}
          </Link>
        </li>
        <li className="mb-[20px]">
          <Link
            state={{rerender:'Political'}}
            to={`/marketplace/explore?category=${"Political"}&country=${ipStackCountry?.countryId}`}
            className="secondary-text text-[13px] leading-[18px] font-weight-500"
          >
            {formatMessage({ id: "Political" })}
          </Link>
        </li>
        <li className="mb-[20px] ">
          <Link
            state={{rerender:'Celebrity'}}
            to={`/marketplace/explore?category=${"YouTuber"}&country=${ipStackCountry?.countryId}`}
            className="secondary-text text-[13px] leading-[18px] font-weight-500"
          >
            {formatMessage({ id: "YouTuber" })}
          </Link>
        </li>
        <li className="mb-[20px] ">
          <Link
            state={{rerender:'Athlete'}}
            to={`/marketplace/explore?category=${"Athlete"}&country=${ipStackCountry?.countryId}`}
            className="secondary-text text-[13px] leading-[18px] font-weight-500"
          >
            {formatMessage({ id: "Athlete" })}
          </Link>
        </li>
        <li className="mb-[20px] ">
          <Link
            state={{rerender:'Comedian'}}
            to={`/marketplace/explore?category=${"Comedian"}&country=${ipStackCountry?.countryId}`}
            className="secondary-text text-[13px] leading-[18px] font-weight-500"
          >
            {formatMessage({ id: "Comedian" })}
          </Link>
        </li>
        <li className="mb-[20px] ">
          <Link
            state={{rerender:'Actor'}}
            to={`/marketplace/explore?category=${"Actor"}&country=${ipStackCountry?.countryId}`}
            className="secondary-text text-[13px] leading-[18px] font-weight-500"
          >
            {formatMessage({ id: "Actor" })}
          </Link>
        </li>
        <li className="mb-[20px] ">
          <Link
            state={{rerender:'Gaming'}}
            to={`/marketplace/explore?category=${"Gaming"}&country=${ipStackCountry?.countryId}`}
            className="secondary-text text-[13px] leading-[18px] font-weight-500"
          >
            {formatMessage({ id: "Gaming" })}
          </Link>
        </li>
        <li className="mb-[20px] ">
          <Link
            state={{rerender:'Historical figure'}}
            to={`/marketplace/explore?category=${"Historical figure"}&country=${ipStackCountry?.countryId}`}
            className="secondary-text text-[13px] leading-[18px] font-weight-500"
          >
            {formatMessage({ id: "Historical figure" })}
          </Link>
        </li>
      </ul>
    </div>
  );
}
export default MoreMenu