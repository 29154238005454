import React from "react";
import Empty from "../../../components/empty/Empty";
import PostContainer from "../../ai-personality/components/post";
import { InfoCard } from "../../widgets/components/UI/InfoCard";
import { useIntl } from "react-intl";
import { useAuth } from "../../auth";

const SocialPosts: React.FC<any> = () => {
  const { formatMessage } = useIntl();
  const { selectedPersonality } = useAuth();

  return (
    <div
      className={
        "grid grid-cols-12 gap-y-[18px] rounded md:gap-x-8 tab-container pb-[24px] md:p-[24px] mt-4"
      }
    >
      <div className={"col-span-12 md:col-span-7 lg:col-span-8"}>
        <PostContainer
          showAddPost={true}
          personality={selectedPersonality}
          removeAddLike={true}
        />
      </div>
      <div className={"col-span-12 md:col-span-5 lg:col-span-4"}>
        <InfoCard
          title={formatMessage({
            id: "What are posts? How do they work?",
          })}
          desc={
            <>
              {formatMessage({
                id: "Kamoto.AI empowers AI personality owners and managers to create engaging social media-like posts. These posts serve as broadcasted messages from the AI personality, similar to tweets or Facebook posts. They are visible on the AI Personality's Public page within the Marketplace. \n",
              })}
               <br></br>
              <br></br>
              {formatMessage({
                id: "The purpose of these posts is to increase user engagement.tsx, fostering connections and interactions between the AI personality and Kamoto.AI's user base. By creating compelling content, AI personality owners can effectively grow their audience, enhance user engagement.tsx, and ultimately boost their revenue potential within the platform.",
              })}
              <br></br>
              <br></br>
            </>
          }
          slug={""}
          isAnchor={
            "https://help.kamoto.ai/en/articles/7945412-enhancing-engagement-with-ai-personalities-publishing-posts-on-kamoto-ai"
          }
        />
      </div>
    </div>
  );
};

export default SocialPosts;
