import React, { useCallback, useEffect, useState } from "react";
import { Form, Formik, useFormik } from "formik";
import { InfoCard } from "../../../widgets/components/UI/InfoCard";
import { useIntl } from "react-intl";
import FormikReactSelect from "../../../widgets/components/Input/FormikReactSelect";
import { toAbsoluteUrl, useDebounce } from "../../../../../_metronic/helpers";
import { Link, useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";
import TextInput from "../../../widgets/components/Input/TextInput";
import { getCdnUrl } from "../../../../core/_util";
import * as Yup from "yup";
import { CustomButton } from "../../../widgets/components/UI/CustomButton";
import apiKeyImg from "../../../../assets/images/marketplace/api-key.svg";
import {
  createApiKey,
  getApiSubscription,
  searchPersonalityForApiKey,
} from "../../core/_requests";
import AsyncFormikReactSelect from "../../../widgets/components/Input/AsyncFormikReactSelect";
import { debounce } from "lodash";
import SubscribeApiModal from "../../../marketplace-public/components/ai-profile/modals/subscribe-api-modal";
import { PLANS_NOT_ALLOWED_API_KEY_CREATION } from "../../../../core/_constants";
import { Button, Modal } from "react-bootstrap";
import crossIcon from "../../../../../_metronic/assets/images/svg/cross-dual-green-icon.svg";
import useGTM from "../../../../hooks/useGTM";
import { GTMEvent } from "../../../../hooks/gtm_helpers";
const Index: React.FC<any> = ({ setOpen, setApiKeyCreated }) => {
  const { formatMessage } = useIntl();
  const [createdApiKey, setCreatedApiKey] = useState(false);
  const [apiKey, setApiKey] = useState("");
  const [apiKeyPersonality, setApiKeyPersonality] = useState("");
  const [defaultOptions, setDefaultOptions] = useState<any[]>([]);
  const [defaultOptionsOthers, setDefaultOptionsOthers] = useState<any[]>([]);
  const [isMine, setIsMine] = useState<boolean>(true);
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [selection, setSelection] = useState<any>(null);
  const [showUpgradeModal, setShowUpgradeModal] = useState<boolean>(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const [notAllowed, setNotAllowed] = useState<any>(null);
  const {dataLayerPush} = useGTM()

  const initialState = {
    name: "",
    personalityId: "",
  };
  const STORAGE_KEY = "kai-api-rem-select";
  useEffect(() => {
    let id = searchParams.get("id");
    let state = searchParams.get("state");
    if (id && state) {
      let item = localStorage.getItem(STORAGE_KEY);
      const state = item ? JSON.parse(item) : null;
      localStorage.removeItem(STORAGE_KEY);
      setSelection(state.selection);
      initialState.name = state.name;
      setIsMine(false);
      setOpenModal(true);
    }
  }, []);

  async function getOptions(search: string) {
    return new Promise(async (resolve, reject) => {
      let {
        data: { success, data },
      } = await searchPersonalityForApiKey(search, isMine);
      if (success) {
        let options = data?.map(
          ({
            image,
            name,
            personalityId,
            listings,
            planId,
            marketplaceSettings,
          }: any) => {
            let isDisabled =
              !planId || PLANS_NOT_ALLOWED_API_KEY_CREATION.includes(planId);
            return {
              label:
                name +
                (isDisabled
                  ? " (Not eligible for API Key. Please upgrade plan)"
                  : ""),
              value: personalityId,
              image,
              charge: listings?.charge,
              isDisabled,
              personalityTnc: marketplaceSettings?.terms_link,
            };
          }
        );
        resolve(options);
      }
    });
  }
  useEffect(() => {
    async function get() {
      let options: any = await getOptions("");
      isMine ? setDefaultOptions(options) : setDefaultOptionsOthers(options);
    }
    !(isMine ? defaultOptions.length : defaultOptionsOthers.length) && get();
  }, [isMine]);

  const validationSchema = Yup.object().shape({
    personalityId: Yup.string().required("Personality should be selected"),
    name: Yup.string()
      .required("Name is required")
      .max(50, "Maximum 50 characters allowed"),
  });

  const loadOptions = useCallback(
    debounce((inputText, callback) => {
      getOptions(inputText).then((options) => callback(options));
    }, 800),
    [isMine]
  );

  async function getSubscription(personalityId: string) {
    const {
      data: { success },
      status,
    } = await getApiSubscription(personalityId);
    if (status == 200 || status == 404) {
      return success;
    } else {
      throw Error("can not find subscription");
    }
  }

  function saveSelectionInStorage(name: string) {
    const STORAGE_KEY = "kai-api-rem-select";
    localStorage.setItem(STORAGE_KEY, JSON.stringify({ name, selection }));
  }

  return (
    <>
      {!createdApiKey ? (
        <div className="">
          <Formik
            validationSchema={validationSchema}
            initialValues={initialState}
            onSubmit={async ({ personalityId, name }, { setSubmitting }) => {
              if (!isMine) {
                setSubmitting(true);
                let isSubscribed = false;
                try {
                  isSubscribed = await getSubscription(personalityId);
                } catch (err) {
                  toast.error(
                    `Can't fetch subscription at the moment. Try again later`,
                    {
                      autoClose: false,
                      closeButton: true, // Display close button (cross icon)
                    }
                  );
                  return;
                }
                if (!isSubscribed) {
                  setOpenModal(true);
                  saveSelectionInStorage(name);
                  setSubmitting(false);
                  return;
                }
              }
              setSubmitting(true);
              const {
                data: { data, success },
              } = await createApiKey(personalityId, name);
              if (success) {
                setApiKey(data.apiKey);
                toast.success(`API Key generated successfully`, {
                  autoClose: 1500,
                });
                setApiKeyPersonality(data.personalityId);
                setCreatedApiKey(true);
                setApiKeyCreated(true);
                dataLayerPush(GTMEvent.ApiKeyCreationSuccessful,{type: isMine ? 'self': 'others'})
              } else {
                toast.error("Error while generating API key",
                {
                  autoClose: false,
                  closeButton: true, // Display close button (cross icon)
                });
                dataLayerPush(GTMEvent.ApiKeyCreationFailed,{type: isMine ? 'self': 'others'})
              }
              setSubmitting(false);
            }}
          >
            {(formik) => (
              <Form className={"mt-7"}>
                <div className={"grid grid-cols-12 md:gap-x-10 "}>
                  <div className={"col-span-12 md:col-span-6"}>
                    <div className="d-flex gap-x-4 mt-0 mb-6">
                      <div
                        onClick={() => {
                          setIsMine(true);
                          setSelection(null);
                          formik.resetForm();
                        }}
                        className={`border-[1px] cursor-pointer ${
                          isMine ? "border-[#C2D24B]" : "border-[#2e2f45]"
                        } rounded-[8px] py-5 px-3 sm:max-w-[190px]`}
                      >
                        <p className="primary-text text-[13px]">
                          {formatMessage({ id: "Your own AI personality" })}
                        </p>
                        <p className="secondary-text mt-1.5 text-[12px]">
                          {formatMessage({
                            id: "Talk to your own AI Clone with this API key",
                          })}
                        </p>
                      </div>
                      <div
                        onClick={() => {
                          setIsMine(false);
                          setSelection(null);
                          formik.resetForm();
                        }}
                        className={`border-[1px] cursor-pointer ${
                          !isMine ? "border-[#C2D24B]" : "border-[#2e2f45]"
                        } rounded-[8px] py-5 px-3 sm:max-w-[190px]`}
                      >
                        <p className="primary-text text-[13px]">
                          {formatMessage({ id: "Other AI Personalities" })}
                        </p>
                        <p className="secondary-text mt-1.5 text-[12px]">
                          {formatMessage({
                            id: "Talk to other AI Clones available on Marketplace",
                          })}
                        </p>
                      </div>
                    </div>
                    <AsyncFormikReactSelect
                      selection={selection}
                      setSelection={setSelection}
                      closeMenuOnScroll={false}
                      captureMenuScroll={false}
                      label={formatMessage({
                        id: "Select Personality",
                      })}
                      toolTipText={formatMessage({
                        id: "Only personalities with paid plan are eligible for API Key creation",
                      })}
                      defaultOptions={
                        isMine ? defaultOptions : defaultOptionsOthers
                      }
                      promiseOptions={loadOptions}
                      name={"personalityId"}
                      formatOptionLabel={(option: any) => (
                        <div
                          onClick={() => {
                            if (option.isDisabled) {
                              setShowUpgradeModal(true);
                              setNotAllowed(option);
                            } else {
                              setSelection(option);
                            }
                          }}
                          key={option.value}
                          className={
                            "flex items-center gap-x-2 " +
                            (option.isDisabled ? "grayscale" : "")
                          }
                        >
                          <img
                            src={getCdnUrl(option.image)}
                            className={"w-8 h-8 rounded-full"}
                          />
                          {option.label}
                        </div>
                      )}
                      labelNote={
                        <p className="text-[10px] secondary-text mb-2">
                          {formatMessage({
                            id: "Only personalities with paid plan are eligible for API Key creation",
                          })}
                        </p>
                      }
                    />
                    <TextInput
                      label={formatMessage({
                        id: "Api Key Name",
                      })}
                      toolTipText={formatMessage({
                        id: "Please, Enter name of the api key",
                      })}
                      fieldName={"name"}
                      placeholder={formatMessage({
                        id: "Enter Api Key name here",
                      })}
                      fieldType={"text"}
                      formik={formik}
                    />
                    <div
                      className={
                        "flex flex-col-reverse md:flex-row md:justify-end gap-[10px] md:mt-[250px] md:mb-0 mb-[24px]"
                      }
                    >
                      <button
                        onClick={() => {
                          formik.resetForm();
                          setOpen(false);
                        }}
                        className={
                          "bg-[#C2D24B1A] text-[#C2D24B] text-[14px] leading-5 font-medium py-[12px] flex-grow-1 md:!flex-grow-0 md:w-[128px] rounded"
                        }
                      >
                        {formatMessage({
                          id: "Cancel",
                        })}
                      </button>
                      <CustomButton
                        isValid={formik.isValid}
                        loading={formik.isSubmitting}
                        buttonText={formatMessage({
                          id: "Create API Key",
                        })}
                      />
                    </div>
                  </div>
                  <div className={"col-span-12 md:col-span-6"}>
                    <InfoCard
                      title={formatMessage({
                        id: "API Keys for AI Personalities or Characters",
                      })}
                      desc={
                        <>
                          {formatMessage({
                            id: "With Kamoto.AI, integrating unique AI Personalities or Characters into your business applications, personal web apps, mobile apps, or even a Discord bot has never been easier. However, there are a few important things to keep in mind: \n",
                          })}
                          <br></br>
                          <br></br>
                          <b>{formatMessage({ id: "Availability:" })}</b>
                          {formatMessage({
                            id: "You can only create an API key for AI Personalities that have enabled API monetization. Your search results on this page will display only those Personalities or Characters that are available for monetization. \n",
                          })}
                          <br></br>
                          <br></br>
                          <b>{formatMessage({ id: "Requirements: " })}</b>
                          {formatMessage({
                            id: "To successfully communicate with a Personality or Character via API, you'll need both the API key and the corresponding AI Personality's ID. The API key is used for authentication, granting access to specific functionalities. \n",
                          })}
                          <br></br>
                          <br></br>
                          <b>
                            {formatMessage({ id: "Security Considerations: " })}
                          </b>
                          {formatMessage({
                            id: "Since an API key can provide access to potentially sensitive functionality, it's critical to keep it secure. Make sure to follow best practices for storing and handling the API key. \n",
                          })}
                          <br></br>
                          <br></br>
                          <b>
                            {formatMessage({
                              id: "How to Use the API Key & Personality ID: ",
                            })}
                          </b>
                          {formatMessage({
                            id: "If you're unsure about how to use the generated API key and Personality ID, please refer to our API Documentation. Here you'll find detailed guides and examples to ensure a smooth integration process. \n",
                          })}
                          <br></br>
                          <br></br>
                          {formatMessage({
                            id: "By leveraging Kamoto.AI's robust AI Marketplace, you can explore innovative and personalized experiences, all while adhering to our commitment to data privacy and authenticity. If you have any further questions or need assistance, don't hesitate to reach out to our support team. \n",
                          })}
                          <br></br>
                          <br></br>
                        </>
                      }
                      slug={""}
                      isAnchor={
                        "https://help.kamoto.ai/en/articles/8276384-empower-your-ai-avatar-creating-api-keys-on-kamoto-ai-for-seamless-integration"
                      }
                    />
                  </div>
                </div>
                <SubscribeApiModal
                  submitForm={formik.submitForm}
                  selection={selection}
                  open={openModal}
                  setOpen={setOpenModal}
                />
              </Form>
            )}
          </Formik>
          <Modal
            aria-labelledby="contained-modal-title-vcenter"
            centered
            show={showUpgradeModal}
            onHide={() => setShowUpgradeModal(false)}
          >
            <Modal.Header closeButton>
              <Modal.Title
                className="modal-title text-[20px] leading-[28px] font-weight-600 text-[#FFFFFFCC] "
                id="contained-modal-title-vcenter"
              >
                {formatMessage({
                  id: "Please Upgrade this AI Personality",
                })}
              </Modal.Title>
              <img
                src={crossIcon}
                className="cursor-pointer"
                alt=""
                onClick={() => setShowUpgradeModal(false)}
              ></img>
            </Modal.Header>
            <Modal.Body className=" p-[16px] md:p-[24px]">
              <p className="!text-[13px] text-center !leading-[20px] !text-[#FFFFFFCC]">
                {formatMessage({
                  id: "This AI personality plan currently does not support API key generation.",
                })}
              </p>
              <img src={apiKeyImg} alt="" className="mx-auto my-[20px]"></img>
              <p className="!text-[13px] text-center !leading-[20px] !text-[#FFFFFFCC]">
                {formatMessage({
                  id: "To enable this feature, please upgrade the plan associated with this AI personality.",
                })}
              </p>
            </Modal.Body>
            <Modal.Footer>
              <Button
                className={
                  " !text-[black] !text-[14px] !leading-[20px] !font-medium !py-[12px] !px-[16px]  rounded  !ml-0 !my-0"
                }
                onClick={() => setShowUpgradeModal(false)}
              >
                {formatMessage({
                  id: "Okay",
                })}
              </Button>
            </Modal.Footer>
          </Modal>
        </div>
      ) : (
        <div className={"mt-10"}>
          <div className={"grid grid-cols-12 md:gap-x-10 "}>
            <div className={"col-span-12 md:col-span-6"}>
              <h2
                className={
                  "text-[14px] md:text-[24px]  md:leading-[32px] text-[#FFFFFFCC] font-semibold mb-[14px] md:mb-[24px]"
                }
              >
                {formatMessage({
                  id: "Here’s your API Key",
                })}
              </h2>
              <div
                className={
                  "bg-[#C2D24B1A] border !border-[#C2D24B40] border-dashed rounded p-[14px] mb-6"
                }
              >
                <div className={"flex gap-x-2"}>
                  <div>
                    <svg
                      width="32"
                      height="32"
                      viewBox="0 0 32 32"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M15.9993 29.3327C8.63535 29.3327 2.66602 23.3633 2.66602 15.9993C2.66602 8.63535 8.63535 2.66602 15.9993 2.66602C23.3633 2.66602 29.3327 8.63535 29.3327 15.9993C29.3327 23.3633 23.3633 29.3327 15.9993 29.3327ZM15.9993 26.666C18.8283 26.666 21.5414 25.5422 23.5418 23.5418C25.5422 21.5414 26.666 18.8283 26.666 15.9993C26.666 13.1704 25.5422 10.4573 23.5418 8.45688C21.5414 6.45649 18.8283 5.33268 15.9993 5.33268C13.1704 5.33268 10.4573 6.45649 8.45688 8.45688C6.45649 10.4573 5.33268 13.1704 5.33268 15.9993C5.33268 18.8283 6.45649 21.5414 8.45688 23.5418C10.4573 25.5422 13.1704 26.666 15.9993 26.666ZM14.666 9.33268H17.3327V11.9993H14.666V9.33268ZM14.666 14.666H17.3327V22.666H14.666V14.666Z"
                        fill="#C2D24B"
                        fillOpacity="0.65"
                      />
                    </svg>
                  </div>
                  <div>
                    <p>
                      {formatMessage({
                        id: "Please save this secret key somewhere safe and accessible. For security reasons, you won't be able to view it again through your Kamoto.AI account. If you lose this secret key, you'll need to generate a new one.",
                      })}
                    </p>
                    <div className={"flex justify-end mt-1"}>
                      <Link
                        to={"#"}
                        className={
                          "text-[#C2D24B] font-medium flex items-center gap-x-3.5"
                        }
                      >
                        {formatMessage({
                          id: "Learn More",
                        })}

                        <svg
                          width="16"
                          height="16"
                          viewBox="0 0 16 16"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            opacity="0.3"
                            d="M4.00065 7.33268C3.63246 7.33268 3.33398 7.63116 3.33398 7.99935C3.33398 8.36754 3.63246 8.66602 4.00065 8.66602H12.0007C12.3688 8.66602 12.6673 8.36754 12.6673 7.99935C12.6673 7.63116 12.3688 7.33268 12.0007 7.33268H4.00065Z"
                            fill="#C2D24B"
                          />
                          <path
                            d="M7.52794 11.5279C7.26759 11.7883 7.26759 12.2104 7.52794 12.4708C7.78829 12.7311 8.2104 12.7311 8.47075 12.4708L12.4708 8.47075C12.7231 8.21837 12.732 7.81198 12.4908 7.54887L8.82412 3.54887C8.57532 3.27746 8.15361 3.25912 7.8822 3.50791C7.61079 3.75671 7.59245 4.17842 7.84125 4.44983L11.0766 7.9793L7.52794 11.5279Z"
                            fill="#C2D24B"
                          />
                        </svg>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className={
                  "bg-[#2E2F45] p-[10px] md:p-[14px] shadow-default rounded mb-8"
                }
              >
                <div className={"flex flex-col lg:flex-row gap-y-2 "}>
                  <div
                    className={
                      "bg-[#171825] flex-grow p-[12px] md:py-5  md:px-7  rounded lg:rounded-start w-full md:w-auto flex justify-center lg:!rounded-r-none md:justify-start"
                    }
                  >
                    <span
                      className={
                        "text-[13px] md:text-[16px] leading-5 md:leading-6 text-[#FFFFFFA6]"
                      }
                    >
                      {apiKey}
                    </span>
                  </div>
                  <button
                    className={
                      "bg-[#C2D24B] min-w-[180px] p-[12px] md:p-5  w-full rounded lg:rounded-end lg:w-auto text-black font-semibold lg:!rounded-l-none text-[14px] h-auto leading-5"
                    }
                    onClick={() => {
                      navigator.clipboard.writeText(`${apiKey}`);
                      toast.success(
                        formatMessage({ id: "Copied Successfully" })
                      );
                    }}
                  >
                    {formatMessage({
                      id: "Copy API Key",
                    })}
                  </button>
                </div>
              </div>

              <div
                className={
                  "bg-[#2E2F45] p-[10px] md:p-[14px] shadow-default rounded mb-8"
                }
              >
                <div className={"flex flex-col lg:flex-row gap-y-2   "}>
                  <div
                    className={
                      "bg-[#171825] flex-grow p-[12px] md:py-5 md:px-7 rounded lg:rounded-start w-full md:w-auto flex justify-center lg:!rounded-r-none md:justify-start"
                    }
                  >
                    <span
                      className={
                        "text-[13px] md:text-[16px] leading-5 md:leading-6 text-[#FFFFFFA6]"
                      }
                    >
                      {apiKeyPersonality}
                    </span>
                  </div>
                  <button
                    className={
                      "text-[#C2D24B] bg-[#171825] border-[#C2D24B] border-2 border-solid p-[12px] md:p-[15px] min-w-[180px] h-auto rounded lg:!rounded-l-none lg:rounded-end w-full lg:w-auto font-semibold text-[14px] leading-5"
                    }
                    onClick={() => {
                      navigator.clipboard.writeText(`${apiKeyPersonality}`);
                      toast.success(
                        formatMessage({ id: "Copied Successfully" })
                      );
                    }}
                  >
                    {formatMessage({
                      id: "Copy Personality Id",
                    })}
                  </button>
                </div>
              </div>
            </div>
            <div className={"col-span-12 md:col-span-6"}>
            <InfoCard
                      title={formatMessage({
                        id: "API Keys for AI Personalities or Characters",
                      })}
                      desc={
                        <>
                          {formatMessage({
                            id: "With Kamoto.AI, integrating unique AI Personalities or Characters into your business applications, personal web apps, mobile apps, or even a Discord bot has never been easier. However, there are a few important things to keep in mind: \n",
                          })}
                          <br></br>
                          <br></br>
                          <b>{formatMessage({ id: "Availability:" })}</b>
                          {formatMessage({
                            id: "You can only create an API key for AI Personalities that have enabled API monetization. Your search results on this page will display only those Personalities or Characters that are available for monetization. \n",
                          })}
                          <br></br>
                          <br></br>
                          <b>{formatMessage({ id: "Requirements: " })}</b>
                          {formatMessage({
                            id: "To successfully communicate with a Personality or Character via API, you'll need both the API key and the corresponding AI Personality's ID. The API key is used for authentication, granting access to specific functionalities. \n",
                          })}
                          <br></br>
                          <br></br>
                          <b>
                            {formatMessage({ id: "Security Considerations: " })}
                          </b>
                          {formatMessage({
                            id: "Since an API key can provide access to potentially sensitive functionality, it's critical to keep it secure. Make sure to follow best practices for storing and handling the API key. \n",
                          })}
                          <br></br>
                          <br></br>
                          <b>
                            {formatMessage({
                              id: "How to Use the API Key & Personality ID: ",
                            })}
                          </b>
                          {formatMessage({
                            id: "If you're unsure about how to use the generated API key and Personality ID, please refer to our API Documentation. Here you'll find detailed guides and examples to ensure a smooth integration process. \n",
                          })}
                          <br></br>
                          <br></br>
                          {formatMessage({
                            id: "By leveraging Kamoto.AI's robust AI Marketplace, you can explore innovative and personalized experiences, all while adhering to our commitment to data privacy and authenticity. If you have any further questions or need assistance, don't hesitate to reach out to our support team. \n",
                          })}
                          <br></br>
                          <br></br>
                        </>
                      }
                      slug={""}
                      isAnchor={
                        "https://help.kamoto.ai/en/articles/8276384-empower-your-ai-avatar-creating-api-keys-on-kamoto-ai-for-seamless-integration"
                      }
                    />
            </div>
            <div
              className={
                "flex col-span-12 max-h-[150px] justify-end align-items-center gap-[10px] md:mt-[250px]"
              }
            >
              <a
                href={process.env.REACT_APP_API_DOCS_URL || ""}
                target="_blank"
                className="main-text text-[14px] leading-[18px] font-medium flex items-center"
              >
                {formatMessage({ id: "View API Docs" })}
                <span className="ms-[10px]">
                  <svg
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      opacity="0.3"
                      d="M4.00065 7.33268C3.63246 7.33268 3.33398 7.63116 3.33398 7.99935C3.33398 8.36754 3.63246 8.66602 4.00065 8.66602H12.0007C12.3688 8.66602 12.6673 8.36754 12.6673 7.99935C12.6673 7.63116 12.3688 7.33268 12.0007 7.33268H4.00065Z"
                      fill="#C2D24B"
                    />
                    <path
                      d="M7.52794 11.5279C7.26759 11.7883 7.26759 12.2104 7.52794 12.4708C7.78829 12.7311 8.2104 12.7311 8.47075 12.4708L12.4708 8.47075C12.7231 8.21837 12.732 7.81198 12.4908 7.54887L8.82412 3.54887C8.57532 3.27746 8.15361 3.25912 7.8822 3.50791C7.61079 3.75671 7.59245 4.17842 7.84125 4.44983L11.0766 7.9793L7.52794 11.5279Z"
                      fill="#C2D24B"
                    />
                  </svg>
                </span>
              </a>
              <CustomButton
                isValid={true}
                loading={false}
                buttonText={formatMessage({
                  id: "Close",
                })}
                onSubmit={() => setOpen(false)}
              />
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Index;
