import clsx from "clsx";
import React from "react";

export const Spinner = ({ placement, className }: { placement?: boolean; className?: string }) => {
  return (
    <div className={`spinner-wrapper ${className || ""}  `}>
      <div
        className={`spinner-border ${placement ? "d-center" : ""}`}
        style={{ width: "4rem", height: "4rem" }}
        role="status"
      >
        <span className="visually-hidden">Loading...</span>
      </div>
    </div>
  );
};


export const ComponentSpinner = ({size = 'xl',...props}:{size?:'sm' | 'md' | 'lg' | 'xl'}) => {
  // let height
  // let spinnerSize
  // switch (size) {
  //   case 'sm':
  //     height = '100px'
  //     spinnerSize = '20px'
  //     break;
  //   case 'md':
  //     height = '125px'
  //     spinnerSize = '30px'
  //     break;
  //   case 'lg':
  //     height = '150px'
  //     spinnerSize = '40px'
  //     break;
  //   case 'xl':
  //     height = '200px'
  //     spinnerSize = '50px'
  //     break;
  //   default:
  //     height = '200px'
  //     spinnerSize = '50px'
  //     break;
  // }

  return (
    <div className={clsx("h-full flex flex-row items-center justify-center",`min-h-[200px]`)}>
      <div
        className={clsx(`h-[50px] w-[50px]`,"inline-block animate-spin rounded-full border-2 border-solid border-current border-r-transparent align-[-0.125em] motion-reduce:animate-[spin_1.5s_linear_infinite]")}
        role="status">
        <span
          className="!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]"
        >Loading...</span
        >
      </div>
    </div>
  );
};

