import { ToolTipUI } from "../../../widgets/components/UI/ToolTipUI";
import { useRef, useState } from "react";
import { updateTrainingChat } from "../../core/_request";
import { toast } from "react-toastify";
import { Spinner } from "react-bootstrap";
import TextareaAutosize from "react-textarea-autosize";
import { useOutsideAlerter } from "../../../../hooks/useOutsideClick";

interface TrainingChatResponse {
  personalityImage: string;
  response: string;
  chatId: string;
  setTrainingChats: React.Dispatch<React.SetStateAction<any>>;
  isOwned:boolean;
  status:string;
}
const Index = ({ personalityImage, response, chatId, setTrainingChats, isOwned,status }: TrainingChatResponse) => {
  const [completion, setCompletion] = useState<string>(response)
  const [loading, setLoading] = useState<boolean>(false)
  const [edit, setEdit] = useState<boolean>(false)
  const ref = useRef(null)
  async function updateChat() {
    if (!chatId) return;
    setLoading(true)
    let newValue = completion?.trim()||''
    const { data: { success } } = await updateTrainingChat(chatId, newValue)
    if (success) {
      setTrainingChats((tc: any) => {
       return tc.map((item:any)=>{
          return item.chatId===chatId ? {...item, completion:newValue} : item
        })
      })
    } else {
      toast.error('Error while updating chat',
      {
        autoClose: false,
        closeButton: true, // Display close button (cross icon)
      })
    }
    setLoading(false)
  }

  useOutsideAlerter(ref, ()=>{
    setEdit(false)
    let c = completion.trim()
    if(c && c!== response){
      updateChat()
    }
  })
  return (
    <div className={"flex justify-start mt-12"}>
      <div className={"-mt-7"}>
        <label className={"flex mb-1 text-[13px] leading-5 primary-text"}>
          AI Personality Response{" "}
          <ToolTipUI tooltipText={"AI Personality Response "} />
        </label>
        <div
          className={"px-8 py-[17px] bg-secondary rounded-[6px] flex gap-x-2"}
        >
          <img
            src={personalityImage}
            className={"w-8 h-8 rounded-full"}
            alt={"Avatar"}
          />

          <div className="flex gap-2">
            <div>
              <p className={edit?"!h-[1px] opacity-0 overflow-y-hidden":""}>{edit ? completion : response}</p>
              {edit && <TextareaAutosize
              ref={ref}
              autoFocus
              style={{ width: '100%', height: 100 }}
              className={
                "w-full form-control form-control-flush !bg-[#ffffff12] rounded !border !border-[#fff] !text-[13px]"
              }
              data-kt-element="input"
              value={completion}
              onChange={(e) => setCompletion(e.target.value)}
            />}
            </div>
            {isOwned && status==='draft' ? (loading ? <Spinner style={{width:25, height:25}} className="shrink-0" animation="border"/> :<svg
              onClick={() => setEdit((edit) => !edit)}
              className="shrink-0 mb-2"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g clipPath="url(#clip0_497_13276)">
                <path
                  d="M13.5 6.5L17.5 10.5M16 18H20M8 20L18.5 9.5C18.7626 9.23735 18.971 8.92555 19.1131 8.58239C19.2553 8.23923 19.3284 7.87143 19.3284 7.5C19.3284 7.12856 19.2553 6.76077 19.1131 6.4176C18.971 6.07444 18.7626 5.76264 18.5 5.5C18.2374 5.23735 17.9256 5.02901 17.5824 4.88687C17.2392 4.74473 16.8714 4.67157 16.5 4.67157C16.1286 4.67157 15.7608 4.74473 15.4176 4.88687C15.0744 5.02901 14.7626 5.23735 14.5 5.5L4 16V20H8Z"
                  stroke="#8898A6"
                  strokeWidth="1.6"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </g>
              <defs>
                <clipPath id="clip0_497_13276">
                  <rect width="24" height="24" fill="white" />
                </clipPath>
              </defs>
            </svg>): null}
          </div>
        </div>
        
      </div>
    </div>
  );
};

export default Index;
