import icActivistA from './ic_activist.png';
import icActivistALight from './icons-light/ic_activist.png';
import icActorA from './ic_actor.png';
import icActorALight from './icons-light/ic_actor.png';
import icAuthor from './ic_author.png';
import icAuthorLight from './icons-light/ic_author.png';
import icAthleteA from './ic_athlete.png';
import icAthleteALight from './icons-light/ic_athlete.png';
import icBusiness from './ic_business.png';
import icBusinessLight from './icons-light/ic_business.png';
import icCelebrities from './ic_celebrities.png';
import icCelebritiesLight from './icons-light/ic_celebrities.png';
import icChef from './ic_chef.png';
import icChefLight from './icons-light/ic_chef.png';
import icCoachA from './ic_coach.png';
import icCoachALight from './icons-light/ic_coach.png';
import icComedian from './ic_comedian.png';
import icComedianLight from './icons-light/ic_comedian.png';
import icDancerA from './ic_dancer.png';
import icDancerALight from './icons-light/ic_dancer.png';
import icDigitalMediaA from './ic_digital-media.png';
import icDirectorA from './ic_director.png';
import icDirectorALight from './icons-light/ic_director.png';
import icFashionA from './ic_fashion.png';
import icFashionALight from './icons-light/ic_fashion.png';
import fictionalCharacterA from './ic_fictional-character.png';
import fictionalCharacterALight from './icons-light/ic_fictional-character.png';
import icGamingA from './ic_gaming.png';
import icGamingALight from './icons-light/ic_gaming.png';
import icHistoryMythologyA from './ic_history-Mythology.png';
import icHistoryMythologyALight from './icons-light/ic_history-Mythology.png';
import icHostAnchorA from './ic_host-anchor.png';
import icHostAnchorALight from './icons-light/ic_host-anchor.png';
import icInfluencerA from './ic_influencer.png';
import icInfluencerALight from './icons-light/ic_influencer.png';
import icInventorA from './ic_inventor.png';
import icInventorALight from './icons-light/ic_inventor.png';
import icJournalistA from './ic_journalist.png';
import icMusicA from './ic_music.png';
import icMusicALight from './icons-light/ic_music.png';
import icNewsA from './ic_news.png';
import icNewsALight from './icons-light/ic_news.png';
import icPoliticalA from './ic_political.png';
import icPoliticalALight from './icons-light/ic_political.png';
import icRevolutionaryA from './ic_revolutionary.png';
import icRevolutionaryALight from './icons-light/ic_revolutionary.png';
import icScholarA from './ic_scholar.png';
import icScholarALight from './icons-light/ic_scholar.png';
import icSportsA from './ic_sports.png';
import icSportsALight from './icons-light/ic_sports.png';
import icSuperheroA from './ic_superhero.png';
import icSuperheroALight from './icons-light/ic_superhero.png';
import icTelevisionA from './ic_television.png';
import icTelevisionALight from './icons-light/ic_television.png';
import icUnknownA from './ic_unknown.png';
import icUnknownALight from './icons-light/ic_unknown.png';
import icVillainA from './ic_villain.png';
import icVillainALight from './icons-light/ic_villain.png';
import icYouTuberA from './ic_youtuber.png';
import icYouTuberALight from './icons-light/ic_youtuber.png';
import { intlForJs } from '../../../../../_metronic/i18n/i18nProvider';


export const categories = [
    {
        name: intlForJs.formatMessage({id:'Fictional Character'}),
        icon: fictionalCharacterA,
        lightIcon: fictionalCharacterALight,
    },
    {
        name: intlForJs.formatMessage({id:'Musician'}),
        icon: icMusicA,
        lightIcon: icMusicALight,

    },
    {
        name: intlForJs.formatMessage({id:'Celebrity'}),
        icon: icCelebrities,
        lightIcon: icCelebritiesLight,

    },
    {
        name: intlForJs.formatMessage({id:'TV Personality'}),
        icon: icTelevisionA,
        lightIcon: icTelevisionALight,
    },
    {
        name: intlForJs.formatMessage({id:'Influencer'}),
        icon: icInfluencerA,
        lightIcon: icInfluencerALight

    },
    {
        name: intlForJs.formatMessage({id:'Political'}),
        icon: icPoliticalA,
        lightIcon: icPoliticalALight,
    },
    {
        name: intlForJs.formatMessage({id:'Unknown'}),
        icon: icUnknownA,
        lightIcon: icUnknownALight,
    },
    {
        name: intlForJs.formatMessage({id:'YouTuber'}),
        icon: icYouTuberA,
        lightIcon: icYouTuberALight,
    },
    {
        name: intlForJs.formatMessage({id:'Talk Show Host or Anchor'}),
        icon: icHostAnchorA,
        lightIcon: icHostAnchorALight,

    },
    {
        name: intlForJs.formatMessage({id:'Athlete'}),
        icon: icAthleteA,
        lightIcon: icAthleteALight,

    },
    {
        name: intlForJs.formatMessage({id:'Comedian'}),
        icon: icComedian,
        lightIcon: icComedianLight,

    },
    {
        name: intlForJs.formatMessage({id:'Actor'}),
        icon: icActorA,
        lightIcon:icActorALight
    },
    {
        name: intlForJs.formatMessage({id:'Author'}),
        icon: icAuthor,
        lightIcon:icAuthorLight
    },
    {
        name: intlForJs.formatMessage({id:'Film Director'}),
        icon: icDirectorA,
        lightIcon:icDirectorALight
    },
    {
        name: intlForJs.formatMessage({id:'Gaming'}),
        icon: icGamingA,
        lightIcon:icGamingALight

    },
    {
        name: intlForJs.formatMessage({id:'Sports Personality'}),
        icon: icSportsA,
        lightIcon:icSportsALight

    },
    {
        name: intlForJs.formatMessage({id:'Historical Figure'}),
        icon: icHistoryMythologyA,
        lightIcon:icHistoryMythologyALight

    },
    {
        name: intlForJs.formatMessage({id:'Revolutionary'}),
        icon: icRevolutionaryA,
        lightIcon:icRevolutionaryALight

    },
    {
        name: intlForJs.formatMessage({id:'Superhero'}),
        icon: icSuperheroA,
        lightIcon:icSuperheroALight

    },
    {
        name: intlForJs.formatMessage({id:'Villain'}),
        icon: icVillainA,
        lightIcon:icVillainALight

    },
    {
        name: intlForJs.formatMessage({id:'News Anchor'}),
        icon: icNewsA,
        lightIcon:icNewsALight

    },
    {
        name: intlForJs.formatMessage({id:'Coach'}),
        icon: icCoachA,
        lightIcon:icCoachALight

    },
    {
        name: intlForJs.formatMessage({id:'Activist'}),
        icon: icActivistA,
        lightIcon:icActivistALight

    },
    // {
    //     name: intlForJs.formatMessage({id:'Journalist'}),
    //     icon: icJournalistA
    // },
    {
        name: intlForJs.formatMessage({id:'Social Media Influencer'}),
        icon: icInfluencerA,
        lightIcon:icInfluencerALight

    },
    {
        name: intlForJs.formatMessage({id:'Fashion Designer'}),
        icon: icFashionA,
        lightIcon:icFashionALight

    },
    {
        name: intlForJs.formatMessage({id:'Business Leader'}),
        icon: icBusiness,
        lightIcon:icBusinessLight

    },
    {
        name: intlForJs.formatMessage({id:'Chef'}),
        icon: icChef,
        lightIcon:icChefLight,

    },
    {
        name: intlForJs.formatMessage({id:'Dancer'}),
        icon: icDancerA,
        lightIcon:icDancerALight,

    },
    {
        name: intlForJs.formatMessage({id:'Inventor'}),
        icon: icInventorA,
        lightIcon:icInventorALight,

    },
    {
        name: intlForJs.formatMessage({id:'Scholar'}),
        icon: icScholarA,
        lightIcon:icScholarALight,

    }
];
