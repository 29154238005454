import React from "react";
import { Modal } from "react-bootstrap";
import { useIntl } from "react-intl";
import { Form, Formik } from "formik";
import TextArea from "../../../widgets/components/Input/TextArea";

const WarningModal = ({
  show,
  onHide,
}: {
  show: boolean;
  onHide: () => void;
}) => {
  const { formatMessage } = useIntl();
  return (
    <div>
      <Modal
        size="sm"
        show={show}
        onHide={onHide}
        aria-labelledby="contained-modal-title-vcenter"
        className={"z-[999999] "}
        centered
        contentClassName={"md:min-w-[480px]"}
      >
        {/* <Modal.Header closeButton className={"px-10"}>
          <div className={"flex items-center justify-between w-full"}>
            <div className="d-flex fw-bold">
              <h2 className="text-[20px] primary-text leading-[28px]">
                {formatMessage({ id: "Send Waring Message" })}
              </h2>{" "}
              <ToolTipUI
                tooltipText={formatMessage({
                  id: "Send Waring Message",
                })}
              />
            </div>
          </div>
        </Modal.Header> */}
        <Modal.Body>
          <Formik initialValues={{ voiceName: "" }} onSubmit={() => {}}>
            {(formik: any) => (
              <Form>
                <TextArea
                  fieldName={"voiceName"}
                  formik={formik}
                  placeholder={formatMessage({
                    id: "Type You message here, user will get this message in notification",
                  })}
                  label={formatMessage({ id: "Send Waring Message" })}
                  toolTipText={formatMessage({
                    id: "Send Waring Message",
                  })}
                  className="!min-h-[80px]"
                />
              </Form>
            )}
          </Formik>
          <div className={"flex flex-grow md:justify-end gap-x-4"}>
            <button
              type="submit"
              className={`main-button text-[14px] leading-5 font-medium py-[12px] w-full sm:w-1/2  md:w-[140px] rounded ${
                false ? "opacity-75" : ""
              }`}
            >
              {formatMessage({
                id: "Send",
              })}
            </button>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default WarningModal;
