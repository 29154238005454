import React from "react";
import postImage from "../../../../assets/images/profile/post-image.png";
import Post from "../../components/ai-profile/post";
import CreateAiPersonality from "../../components/ai-profile/create-ai-personality";
import PostContainer from "../../../ai-personality/components/post";
import { useAuth } from "../../../auth";

const Posts = ({isAd} : {isAd: boolean}) => {
  const {personalityInView} = useAuth();
  return (
    <div className={"col-span-12 md:col-span-8 h-[80vh] order-1 md:order-1"}>
      {isAd && <CreateAiPersonality />}
      <div
        className={
          "flex flex-col gap-y-[16px] h-[calc(100%-180px)] md:h-[calc(100%-80px)] xl:h-[calc(100%-160px)] overflow-y-auto custom-scroll"
        }
      >
       <PostContainer personality={personalityInView}/>
      </div>
    </div>
  );
};

export default Posts;
