import { useEffect, useState } from "react";
import Breadcrumb from "../components/breadcrumb";
import { ToolTipUI } from "../../widgets/components/UI/ToolTipUI";
import UsageHistory from "./usage-history";
import RechargeHistory from "./recharge-history";
import AddCreditsModal from "../components/modal/AddCreditsModal";
import crossIcon from "../../../../_metronic/assets/images/svg/cross-dual-green-icon.svg";
import Lottie from "lottie-react";
import processingJson from "../../../assets/lotties/processing-animation.json";
import Button from "react-bootstrap/Button";
import React from "react";
import AddCredit from "../components/modal/AddCredit";
import { useAuth } from "../../auth";
import { useIntl } from "react-intl";
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
import { useSearchParams } from "react-router-dom";
import ApiSubscriptionHistory from "./api-subscriptions";
import { commaSeparated } from "../../../utils";
import PersonalitySubscriptionHistory from "./personality-subscriptions";

const tabsToIndex:{[key:string]: number} = {
  'recharge-history':0,
  'usage-history':1,
  'api-subscriptions':2,
  'personality-subscriptions':3
}
const indexToTabs:{[key:number]: string} = {
  0:'recharge-history',
  1:'usage-history',
  2:'api-subscriptions',
  3:'personality-subscriptions'
}
const Index = () => {
  const { formatMessage } = useIntl();
  const [searchParams, setSearchParams] = useSearchParams()
  const [activeTab, setActiveTab] = useState<number>(tabsToIndex[searchParams.get('tab') || 'usage-history']);
  const [processing, setProcessing] = useState<boolean>(false);
  const [modalShow, setModalShow] = useState<boolean>(true);
  const { credits } = useAuth();
  
  useEffect(()=>{
    searchParams.set('tab',''+indexToTabs[activeTab])
    setSearchParams(searchParams)
   },[activeTab])
 
   useEffect(()=>{
     let tab = searchParams.get('tab')
     if(tab){
       setActiveTab(tabsToIndex[tab])
     }
   },[searchParams])

  return (
    <div className={"md:px-5 px-3 py-3"}>
      <Breadcrumb />
      <div
        className={
          "tab-container shadow-default rounded md:p-[32px] mt-4 flex flex-col gap-y-3.5 md:flex-row justify-between"
        }
      >
        <div>
          <label
            className={"flex items-center text-[13px] leading-[20px] text mb-2"}
          >
            {formatMessage({ id: "Chat Credits" })}{" "}
            <ToolTipUI
              tooltipText={formatMessage({
                id: "GLOBAL.TOOLTIP.CHAT_CREDIT.HEAD",
              })}
            />
          </label>
          <h3 className={"text-[24px] leading-[32px] secondary-text font-semibold"}>
            $ {commaSeparated(credits)}
          </h3>
          <p className={"text-[12px] leading-[18px] mt-2 secondary-text"}>
            {formatMessage({
              id: "This is the balance which you can utilize while interacting with premium/paid AI Personalities",
            })}
          </p>
        </div>
        <div className={"flex items-center"}>
          {/* <button
            className={
              "px-16 bg-[#C2D24B] py-4 rounded text-[14px] leading-5 text-black font-semibold w-full md:w-auto"
            }
            data-bs-toggle="modal"
            data-bs-target="#AddCreditsModal"
          >
            Add Credits
          </button> */}
          <AddCreditsModal />
          {/* <AddCreditsModal isOpen={true}/> */}
        </div>
      </div>
      {processing && (
        <div className="modal d-block bg-opacity-1 bg-[#22222266]">
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-header p-[20px_24px_16px]">
                <h5 className="modal-title text-[20px] leading-[28px] font-weight-600 text-[#FFFFFFCC]">
                  {formatMessage({
                    id: "Please Wait...",
                  })}
                </h5>
                <button
                  type="button"
                  className="p-0 btn-close h-[24px] w-[24px]"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                >
                  <img src={crossIcon} alt=""></img>
                </button>
              </div>
              <div className="flex-column modal-body d-flex align-items-center">
                <Lottie
                  className=""
                  animationData={processingJson}
                  style={{
                    width: 185,
                    height: 145,
                    borderRadius: 25,
                    // borderTopLeftRadius: 0,
                    // overflow: "hidden",
                  }}
                />
                <h4 className="text-[22px] mt-[20px] mb-[8px] leading-[32px] font-weight-600 text-[#FFFFFFCC]">
                  {formatMessage({
                    id: "Processing...",
                  })}
                </h4>
                <p className="text-[13px] leading-[20px] font-weight-400 text-[#FFFFFFA6]">
                  {formatMessage({
                    id: "We are processing your request...",
                  })}
                </p>
              </div>
              <div className="modal-footer p-[16px_20px]">
                <button
                  className={
                    "px-6 bg-[#C2D24B1A] py-4 rounded text-[14px] leading-5 text-[#C2D24B] font-semibold w-full md:w-auto"
                  }
                  data-bs-dismiss="modal"
                >
                  {formatMessage({
                    id: "Cancel",
                  })}
                </button>
                <button
                  className={
                    "px-8 bg-[#C2D24B] py-4 rounded text-[14px] leading-5 text-black font-semibold w-full md:w-auto"
                  }
                >
                  {formatMessage({
                    id: "Okay",
                  })}
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
      <div className={'tab-container mt-5 md:p-8 rounded-[4px]'}>
        <Tabs
          className="font-size-13"
          selectedTabClassName="bg-primary fill-red-500"
          defaultIndex={activeTab}
          selectedIndex={activeTab}
          onSelect={(index) => {
            setActiveTab(index)
          }}
        >
          <TabList
            className={
              " text-[13px] leading-[20px] font-normal primary-text setting-tabs overflow-x-auto overflow-y-hidden whitespace-nowrap  border-main border-b"
            }
          >
            <Tab> {formatMessage({ id: "Credit Recharge History" })}</Tab>
            <Tab> {formatMessage({ id: "Credit Usage History" })}</Tab>
            <Tab> {formatMessage({ id: "API Subscription Payments" })}</Tab>
            <Tab> {formatMessage({ id: "Fan Club Subscription Payments" })}</Tab>
          </TabList>
          <TabPanel>
            <RechargeHistory />
          </TabPanel>
          <TabPanel>
            <UsageHistory />
          </TabPanel>
          <TabPanel>
            <ApiSubscriptionHistory />
          </TabPanel>
          <TabPanel>
            <PersonalitySubscriptionHistory />
          </TabPanel>
        </Tabs>
      </div>
    </div>
  );
};

export default Index;
