import React, {ChangeEventHandler, useEffect, useState} from "react";

import { format, isAfter, isBefore, isValid, parse } from "date-fns";
import {
  DateRange,
  DayPicker,
  SelectRangeEventHandler,
  useNavigation,
} from "react-day-picker";
import { OverlayTrigger, Popover } from "react-bootstrap";
import calenderIcon from "../../../../../_metronic/assets/images/svg/calender-icon.svg";
import moment from "moment";

const Index:React.FC<any> = ({dateFrom,setDurationInDays,setLogsFromDate}) => {
  const [selectedRange, setSelectedRange] = useState<DateRange>();
  const [fromValue, setFromValue] = useState<any>();
  const [toValue, setToValue] = useState<any>(new  Date());
  const handleFromChange: ChangeEventHandler<HTMLInputElement> = (e) => {
    setFromValue(e.target.value);
    const date = parse(e.target.value, "y-MM-dd", new Date());
    if (!isValid(date)) {
      return setSelectedRange({ from: undefined, to: undefined });
    }
    if (selectedRange?.to && isAfter(date, selectedRange.to)) {
      setSelectedRange({ from: selectedRange.to, to: date });
    } else {
      setSelectedRange({ from: date, to: selectedRange?.to });
    }
  };

  const handleToChange: ChangeEventHandler<HTMLInputElement> = (e) => {
    setToValue(e.target.value);
    const date = parse(e.target.value, "y-MM-dd", new Date());

    if (!isValid(date)) {
      return setSelectedRange({ from: selectedRange?.from, to: undefined });
    }
    if (selectedRange?.from && isBefore(date, selectedRange.from)) {
      setSelectedRange({ from: date, to: selectedRange.from });
    } else {
      setSelectedRange({ from: selectedRange?.from, to: date });
    }
  };

  const handleRangeSelect: SelectRangeEventHandler = (
    range: DateRange | undefined
  ) => {
    setSelectedRange(range);
    if (range?.from) {
      setFromValue(format(range.from, "y-MM-dd"));
    } else {
      setFromValue("");
    }
    if (range?.to) {
      setToValue(format(range.to, "y-MM-dd"));
    } else {
      setToValue("");
    }
  };

  useEffect(()=>{
    const date = parse(dateFrom, "y-MM-dd", new Date());
    setFromValue(dateFrom)
    setToValue(new Date())
    setSelectedRange({ from: date, to: new Date() });
  },[dateFrom])

  useEffect(() => {
    if(selectedRange?.from?.getTime() && selectedRange?.to?.getTime()){
      setDurationInDays(moment(selectedRange?.to).diff(moment(selectedRange?.from),'days'))
      setLogsFromDate(selectedRange?.from)
      // console.log(moment(selectedRange?.to).diff(moment(selectedRange?.from),'days'))
    }else{
      setDurationInDays(7)
    }
  },[JSON.stringify(selectedRange || {}),dateFrom])


  return (
    <div>
      <OverlayTrigger
        trigger={"click"}
        placement={"bottom"}
        rootClose={true}
        overlay={
          <Popover className={"max-w-lg"}>
            <div
              className={
                "flex items-center gap-x-4 border-b border-main secondary-bg px-4 py-4"
              }
            >
              <input
                size={10}
                className={"form-control search-input-table form-select py-0.5"}
                value={fromValue}
                placeholder="From Date"
                onChange={handleFromChange}
              />
              <svg
                aria-hidden="true"
                className="SVGInline-svg SVGInline--cleaned-svg SVG-svg Icon-svg Icon--arrowRight-svg db-DateRangeInput-input-arrow-svg Icon-color-svg Icon-color--gray600-svg"
                height="32"
                width="32"
                viewBox="0 0 16 16"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M12.583 7 7.992 2.409A1 1 0 1 1 9.407.993l6.3 6.3a1 1 0 0 1 0 1.414l-6.3 6.3a1 1 0 0 1-1.415-1.416L12.583 9H1a1 1 0 1 1 0-2z"
                  fillRule="evenodd"
                  fill={'#fff'}
                ></path>
              </svg>
              <input
                size={10}
                className={"form-control search-input-table form-select py-0.5"}
                placeholder="To Date"
                value={toValue}
                onChange={handleToChange}
              />
            </div>
            <DayPicker
              className={"primary-text"}
              components={{
                Caption: ({ displayMonth }) => {
                  const { goToMonth, nextMonth, previousMonth } =
                    useNavigation();
                  return (
                    <div className={"flex items-center justify-between"}>
                      {" "}
                      <button
                        disabled={!previousMonth}
                        onClick={() =>
                          previousMonth && goToMonth(previousMonth)
                        }
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="13"
                          height="13"
                          viewBox="0 0 13 13"
                          className={"rotate-180"}
                        >
                          <path
                            fill="#fff"
                            fillRule="evenodd"
                            d="M7.288 6.296L3.202 2.21a.71.71 0 0 1 .007-.999c.28-.28.725-.28.999-.007L8.803 5.8a.695.695 0 0 1 .202.496.695.695 0 0 1-.202.497l-4.595 4.595a.704.704 0 0 1-1-.007.71.71 0 0 1-.006-.999l4.086-4.086z"
                          />
                        </svg>
                      </button>
                      <h3 className={"text-[18px] leading-6 font-semibold"}>
                        {format(displayMonth, "MMMM  yyy")}
                      </h3>
                      <button
                        disabled={!nextMonth}
                        onClick={() => nextMonth && goToMonth(nextMonth)}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="13"
                          height="13"
                          viewBox="0 0 13 13"
                        >
                          <path
                            fill="#fff"
                            fillRule="evenodd"
                            d="M7.288 6.296L3.202 2.21a.71.71 0 0 1 .007-.999c.28-.28.725-.28.999-.007L8.803 5.8a.695.695 0 0 1 .202.496.695.695 0 0 1-.202.497l-4.595 4.595a.704.704 0 0 1-1-.007.71.71 0 0 1-.006-.999l4.086-4.086z"
                          />
                        </svg>
                      </button>
                    </div>
                  );
                },
              }}
              pagedNavigation={true}
              numberOfMonths={1}
              mode="range"
              selected={selectedRange}
              onSelect={handleRangeSelect}
            />
          </Popover>
        }
      >
        <div className={"relative "}>
          <input
            className="pl-10 form-control search-input-table !h-[34px] flex items-center py-0  !min-w-[225px] !pt-0.5 !font-medium "
            value={`${moment(fromValue).format("DD MMM YYYY")} - ${moment(
              toValue
            ).format("DD MMM YYYY")}`}
          />
          <img
            className="absolute left-[8px] top-1/2 -translate-y-1/2"
            src={calenderIcon}
            alt=""
          />
        </div>
      </OverlayTrigger>
    </div>
  );
};

export default Index;
