import React, { useEffect, useRef } from "react";
import * as echarts from "echarts";
import { get, isEqual } from "lodash";
import { useThemeMode } from "../../../../../_metronic/partials";
// @ts-ignore
import useChartResizer from "../../../../hooks/useChartResizer";
import moment from "moment";
const Index: React.FC<any> = ({ quater }) => {
  const { mode } = useThemeMode();
  let option = {
    xAxis: {
      type: "category",
      data: quater
        ? ["Jan", "Feb", "Mar", "Apr", "May", "Jun"]
        : [
            "Jan",
            "Feb",
            "Mar",
            "Apr",
            "May",
            "Jun",
            "Jul",
            "Aug",
            "Sep",
            "Oct",
            "Nov",
            "Dec",
          ],
      scale: true,
      axisLine: {
        show: false,
      },
      axisTick: {
        show: false,
      },
      axisLabel: {
        margin: 4,
        fontSize: 12,
        color: isEqual(mode, "light") ? "#5E6278" : "#FFFFFFA6",
        // formatter: dayjs("{value}"),
      },
      splitLine: {
        show: false,
      },
    },
    yAxis: {
      show: false,
      type: "value",
    },
    grid: {
      left: 0,
      top: 5,
      right: 0,
      bottom: 25,
      containLabel: false,
    },
    tooltip: {
      trigger: "axis",
      className: "secondary-bg",
      borderWidth: 0,
      formatter: (data: any) => {
        return `<span class="text-[12px] font-semibold primary-text">Likes Count : ${get(
          data[0],
          "data[1]"
        )}</span> </br> <span class="text-[10px] font-normal primary-text">${moment(
          get(data[0], "data[0]")
        ).format("MMM DD, YYYY HH:mm")}</span>`;
      },
      axisPointer: {
        animation: false,
      },
    },
    legend: {
      show: false,
    },
    series: [
      {
        type: "bar",
        itemStyle: {
          borderRadius: [6, 6, 6, 6],
        },
        barMaxWidth: 12,
        color: "#C2D24B4D",
        data: [20, 20, 15, 25, 20, 20, 15, 15, 25, 25, 12, 12],
      },
      {
        type: "bar",
        itemStyle: {
          borderRadius: [6, 6, 6, 6],
        },
        barMaxWidth: 12,
        color: "#C2D24B",
        data: [12, 15, 20, 20, 12, 15, 22, 12, 20, 15, 15, 20],
      },
    ],
  };
  const chartRef = useRef();
  useChartResizer(chartRef);
  useEffect(() => {
    const chart = echarts.init(chartRef.current, null);
    // @ts-ignore
    chart.setOption(option);
  }, [option]);

  // @ts-ignore
  return <div className={"w-full h-full"} ref={chartRef}></div>;
};

export default Index;
