import React from "react";
import { Route, Routes } from "react-router-dom";
import MarketplacePublicLayout from "../../../_metronic/layout/marketplace-public-layout";
import EventPage from "./pages/events";
import EventWithoutRegisterPage from "./pages/events-noregister";
import MarketplacePage from "./pages/marketplace";
import LeaderboardPage from "./pages/leaderboard";
import MarketplaceDiscoveryPage from "./pages/marketplace-discovery";
import MarketplaceChatPage from "./pages/marketplace-chat";

const MarketPlacePublicRoute = () => {
  return (
    <Routes>
      <Route element={<MarketplacePublicLayout />}>
        {/* <Route index element={<EventPage />} /> */}
        <Route index element={<MarketplacePage />} />
        <Route path={"trending"} element={<LeaderboardPage/>} />
        <Route
          path={"explore"}
          element={<MarketplaceDiscoveryPage />}
        />
        <Route
          path={"without-register"}
          element={<EventWithoutRegisterPage />}
        />
        
      </Route>
    </Routes>
  );
};

export default MarketPlacePublicRoute;
