import React, { useEffect, useState } from "react";
import Breadcrumb from "../components/breadcrumb";
// import Tabs, { Tab } from "react-best-tabs";
import Overview from "./overview";
import Logs from "./logs";
import Events from "./events";
import Webhooks from "./webhooks";
import ApiKeys from "./api-keys";
import { TabList, Tabs, Tab, TabPanel } from "react-tabs";
import { useIntl } from "react-intl";
import { isEqual } from "lodash";
import { FullModal } from "../../../components/modal";
import CreateApiForm from "../components/create-api-form";
import { useSearchParams } from "react-router-dom";

const tabsToIndex: { [key: string]: number } = {
  overview: 0,
  "api-keys": 1,
  events: 2,
  logs: 3,
};
const indexToTabs: { [key: number]: string } = {
  0: "overview",
  1: "api-keys",
  2: "events",
  3: "logs",
};
const Index = () => {
  const { formatMessage } = useIntl();
  const [searchParams, setSearchParams] = useSearchParams();
  const [activeTab, setActiveTab] = useState<number>(
    tabsToIndex[searchParams.get("tab") || "overview"]
  );
  const [openCreateApiModal, setOpenCreateApiModal] = useState(false);
  const [apiKeyCreated, setApiKeyCreated] = useState<boolean>(false);
  useEffect(() => {
    searchParams.set("tab", "" + indexToTabs[activeTab]);
    setSearchParams(searchParams);
  }, [activeTab]);

  useEffect(() => {
    let tab = searchParams.get("tab");
    if (tab) {
      setActiveTab(tabsToIndex[tab]);
    }
    let id = searchParams.get("id");
    let state = searchParams.get("state");
    if (id && state) {
      setOpenCreateApiModal(true);
    }
  }, [searchParams]);

  return (
    <div className={"md:px-5 px-3 py-3 "}>
      <div
        className={
          "flex flex-col md:flex-row md:items-center md:justify-between gap-y-3.5  md:mb-0"
        }
      >
        <Breadcrumb />
        {isEqual(activeTab, 1) && (
          <div className={"flex gap-x-3 justify-end items-center md:px-[0px] md:mb-0 mb-4 "}>
            <button
              className={
                "main-button text-[14px] leading-[20px]  rounded flex-grow h-[36px] text-black px-6"
              }
              onClick={() => setOpenCreateApiModal(true)}
            >
              {formatMessage({ id: "Create API Key " })}
            </button>
            <button
              className={
                "w-[36px] h-[36px]  rounded-[6px] menu-bg flex items-center justify-center"
              }
            >
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g clipPath="url(#clip0_497_24643)">
                  <path
                    d="M12 3C10.9 3 10 3.9 10 5C10 6.1 10.9 7 12 7C13.1 7 14 6.1 14 5C14 3.9 13.1 3 12 3ZM12 17C10.9 17 10 17.9 10 19C10 20.1 10.9 21 12 21C13.1 21 14 20.1 14 19C14 17.9 13.1 17 12 17ZM12 10C10.9 10 10 10.9 10 12C10 13.1 10.9 14 12 14C13.1 14 14 13.1 14 12C14 10.9 13.1 10 12 10Z"
                    className={"icon"}
                    fillOpacity="0.65"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_497_24643">
                    <rect width="24" height="24" className={"icon"} />
                  </clipPath>
                </defs>
              </svg>
            </button>
          </div>
        )}
      </div>
      <Tabs
        defaultIndex={activeTab}
        selectedIndex={activeTab}
        className="font-size-13"
        selectedTabClassName="bg-primary"
        onSelect={(index) => {
          setSearchParams({});
          setActiveTab(index);
        }}
      >
        <TabList
          className={
            " text-[13px] leading-[20px] font-normal primary-text setting-tabs overflow-x-auto overflow-y-hidden whitespace-nowrap  border-main border-b"
          }
        >
          <Tab>{formatMessage({ id: "Overview" })}</Tab>
          <Tab>{formatMessage({ id: "API Keys" })}</Tab>
          {/* <Tab>{formatMessage({ id: "Webhooks" })}</Tab> */}
          <Tab>{formatMessage({ id: "Events" })}</Tab>
          <Tab>{formatMessage({ id: "Logs" })}</Tab>
        </TabList>
        <TabPanel className="md:mr-0 mt-0">
          <Overview />
        </TabPanel>
        <TabPanel className="md:mr-0 mt-0">
          <ApiKeys
            setOpen={setOpenCreateApiModal}
            apiKeyCreated={apiKeyCreated}
            setApiKeyCreated={setApiKeyCreated}
          />
        </TabPanel>
        {/* <TabPanel
          title={formatMessage({ id: "Webhooks" })}
          className="md:mr-0 mt-2"
        >
          <Webhooks />
        </TabPanel> */}
        <TabPanel className="md:mr-0 mt-0">
          <Events />
        </TabPanel>
        <TabPanel className="md:mr-0 mt-0">
          <Logs />
        </TabPanel>
      </Tabs>
      <FullModal
        open={openCreateApiModal}
        setOpen={setOpenCreateApiModal}
        title={formatMessage({ id: "Create API Key" })}
        className={"max-w-[1440px]"}
      >
        {openCreateApiModal && (
          <CreateApiForm
            setApiKeyCreated={setApiKeyCreated}
            setOpen={setOpenCreateApiModal}
          />
        )}
      </FullModal>
    </div>
  );
};

export default Index;
