import React, { useEffect, useState } from "react";
import SearchInput from "../../profile/components/SearchInput";
import { useIntl } from "react-intl";
import Switch from "react-switch";
import UsageTrackingChart from "../../profile/components/usage-tracking-chart";
import { ToolTipUI } from "../../widgets/components/UI/ToolTipUI";
import { filter, find, get, includes, isEqual, map } from "lodash";
import Select, { components } from "react-select";
import UsageTrackingModal from "../../profile/components/usage-tracking-modal";
import FormikReactSelect from "../../widgets/components/Input/FormikReactSelect";
import { Formik } from "formik";
import Lottie from "lottie-react";
import processingJson from "../../../assets/lotties/processing-animation.json";
import {
  getPersonalityQuota,
  processUpgradeQuota,
} from "../../profile/core/_requests";
import { useAuth } from "../../auth";
import { Spinner } from "../../widgets/components/General/Spinner";
import { useSearchParams } from "react-router-dom";
import { Modal, Popover,OverlayTrigger } from "react-bootstrap";
import { toast } from "react-toastify";
import crossIcon from "../../../../_metronic/assets/images/svg/cross-dual-green-icon.svg";
import clsx from "clsx";
import useGTM from "../../../hooks/useGTM";
import { GTMEvent } from "../../../hooks/gtm_helpers";
import request from "../../../core/_apis";
import moment from "moment-timezone";
const UsageTracking = () => {
  const { formatMessage } = useIntl();
  const { dataLayerPush } = useGTM();
  const [cumulative, setCumulative] = useState<boolean>(false);
  const [openUpgrade, setOpenUpgrade] = useState<boolean>(false);
  const { selectedPersonality } = useAuth();
  const [quota, setQuota] = useState<any>({});
  const [quotaLoading, setQuotaLoading] = useState<boolean>(false);
  const [queryParams, setQueryParams] = useSearchParams();
  const [processing, setProcessing] = useState<boolean>(false);
  const [type, setType] = useState<string>("planQuota");
  const [key, setKey] = useState<number>(0);
  const [durationInDays, setDurationInDays] = useState<number>()
  const [logsFromDate, setLogsFromDate] = useState<any>()
  const [usageData, setUsageData] = useState<any>()

  const [selected, setSelected] = useState<any>();

  useEffect(() => {
    async function get() {
      setQuotaLoading(true);
      const {
        data: { success, data },
      } = await getPersonalityQuota(selectedPersonality.personalityId);
      const quota: any = {};
      data?.forEach(
        ({
          quotaType,
          planQuota,
          planQuotaConsumed,
          purchasedQuota,
          purchasedQuotaConsumed,
        }: any) => {
          quota[quotaType] = {
            planQuota,
            planQuotaConsumed,
            purchasedQuota,
            purchasedQuotaConsumed,
          };
        }
      );
      setQuota(quota);
      setQuotaLoading(false);
    }
    selectedPersonality && get();
  }, [key, selectedPersonality]);

  async function processQuota() {
    setProcessing(true);
    const {
      data: { success, data, errors },
    } = await processUpgradeQuota(
      selectedPersonality.personalityId,
      queryParams.get("id") || ""
    );
    if (success) {
      toast.success("Amount added to your account successfully");
      setKey(Math.random());
      let quotaRechargeData: any = data;
      dataLayerPush(GTMEvent.QuotaPurchaseSuccessful, {
        data: quotaRechargeData?.quotas?.map((el: any) => {
          return {
            quota_name: el.entityId,
            quantity: el.quantity,
            amount: el.amount,
            currency: "USD",
          };
        }),
      });
    } else {
      dataLayerPush(GTMEvent.QuotaPurchaseFailed);
      toast.error(errors[0],
        {
          autoClose: false,
          closeButton: true, // Display close button (cross icon)
        });
    }
    setProcessing(false);
    queryParams.delete("id");
    queryParams.delete("state");
    setQueryParams(queryParams);
  }

  useEffect(() => {
    if (
      selectedPersonality &&
      queryParams.has("id") &&
      queryParams.has("state")
    ) {
      processQuota();
    }
  }, [selectedPersonality]);
  const quotas = [
    {
      title: `${formatMessage({ id: "Chat token quota" })}`,
      from:
        quota["chat-tokens"]?.[
        type == "purchasedQuota" ? "purchasedQuota" : "planQuota"
        ] || 0,
      slice:
        quota["chat-tokens"]?.[
        type == "purchasedQuota"
          ? "purchasedQuotaConsumed"
          : "planQuotaConsumed"
        ] || 0,
    },
    {
      title: `${formatMessage({ id: "Hyper-realistic Avatar Mins" })}`,
      from:
        quota["hyper-realistic-avatar"]?.[
        type == "purchasedQuota" ? "purchasedQuota" : "planQuota"
        ] || 0,
      slice:
        quota["hyper-realistic-avatar"]?.[
        type == "purchasedQuota"
          ? "purchasedQuotaConsumed"
          : "planQuotaConsumed"
        ] || 0,
    },
    {
      title: `${formatMessage({ id: "Stock voice quota" })}`,
      from:
        quota["stock-voice"]?.[
        type == "purchasedQuota" ? "purchasedQuota" : "planQuota"
        ] || 0,
      slice:
        quota["stock-voice"]?.[
        type == "purchasedQuota"
          ? "purchasedQuotaConsumed"
          : "planQuotaConsumed"
        ] || 0,
    },
    {
      title: `${formatMessage({ id: "Cloned voice quota" })}`,
      from:
        quota["cloned-voice"]?.[
        type == "purchasedQuota" ? "purchasedQuota" : "planQuota"
        ] || 0,
      slice:
        quota["cloned-voice"]?.[
        type == "purchasedQuota"
          ? "purchasedQuotaConsumed"
          : "planQuotaConsumed"
        ] || 0,
    },
    {
      title: `${formatMessage({ id: "Training chats" })}`,
      from:
        quota["train-by-chat"]?.[
        type == "purchasedQuota" ? "purchasedQuota" : "planQuota"
        ] || 0,
      slice:
        quota["train-by-chat"]?.[
        type == "purchasedQuota"
          ? "purchasedQuotaConsumed"
          : "planQuotaConsumed"
        ] || 0,
    },
    {
      title: `${formatMessage({ id: "Public training links" })}`,
      from:
        quota["train-by-link"]?.[
        type == "purchasedQuota" ? "purchasedQuota" : "planQuota"
        ] || 0,
      slice:
        quota["train-by-link"]?.[
        type == "purchasedQuota"
          ? "purchasedQuotaConsumed"
          : "planQuotaConsumed"
        ] || 0,
    },
    {
      title: `${formatMessage({ id: "Private training files" })}`,
      from:
        quota["train-by-file"]?.[
        type == "purchasedQuota" ? "purchasedQuota" : "planQuota"
        ] || 0,
      slice:
        quota["train-by-file"]?.[
        type == "purchasedQuota"
          ? "purchasedQuotaConsumed"
          : "planQuotaConsumed"
        ] || 0,
    },
  ];

  let comparedData = [
    {
      label: formatMessage({ id: "This week" }),
      value: 7,
    },
    {
      label: formatMessage({ id: "Last 15 Days" }),
      value: 15,
    },
    {
      label: formatMessage({ id: "This month" }),
      value: 30,
    },
  ]

  const fetchUsageData = async (overriddenDate?:any) => {
    let resp = await request.get('/reports/usage', {
      params: {
        startDate: overriddenDate || logsFromDate?.toISOString() || new Date(moment().subtract(15, "d").format("YYYY-MM-DD")).toISOString(),
        forDays: durationInDays || 15
      },
      headers: {
        'x-personality-id': selectedPersonality.personalityId
      }
    })
    return resp.data.data
  }

  const processUsageData = async (overriddenDate?:any) => {
    let results: any;
    if (selectedPersonality?.showDemoDashboard) {
      results = [
        {
          value: "chatTokens",
          label: "Chat Tokens",
          data: [600, 632, 544, 123, 574, 234, 1054, 302, 542, 110],
          color: "#C2D24B",
          box: <div className={"w-[12px] h-[12px] bg-[#C2D24B]"} />,
        },
        {
          value: "stockVoices",
          label: "Stock voices",
          data: [200, 632, 544, 345, 100, 234, 1054, 302, 100, 110],
          color: "#8898A6",
          box: <div className={"w-[12px] h-[12px] bg-[#8898A6]"} />,
        },
        {
          value: "clonedVoice",
          label: "Cloned voices",
          data: [300, 632, 544, 123, 574, 645, 1054, 122, 300, 110],
          color: "#4F516D",
          box: <div className={"w-[12px] h-[12px] bg-[#4F516D]"} />,
        },
      ]
    } else {
      results = await fetchUsageData(overriddenDate)
    }

    let groupedData: any = {};
    results?.currentPeriod?.forEach((item: any) => {
      const { date, quotaType, totalQuotaConsumed } = item;

      if (!groupedData?.[quotaType]) {
        groupedData[quotaType] = [];
      }

      groupedData[quotaType].push({ date, value: totalQuotaConsumed });
    });

    let initData = [
      {
        value: "chatTokens",
        label: "Chat Tokens",
        data: groupedData["chat-tokens"],
        color: "#C2D24B",
        box: <div className={"w-[12px] h-[12px] bg-[#C2D24B]"} />,
      },
      {
        value: "stockVoices",
        label: "Stock voices",
        data: groupedData["stock-voice"],
        color: "#8898A6",
        box: <div className={"w-[12px] h-[12px] bg-[#8898A6]"} />,
      },
      {
        value: "clonedVoice",
        label: "Cloned voices",
        data: groupedData["cloned-voice"],
        color: "#4F516D",
        box: <div className={"w-[12px] h-[12px] bg-[#4F516D]"} />,
      }
    ]

    setUsageData(groupedData)
    setSelected(initData)
  }

  useEffect(() => {
    setUsageData(undefined)
    processUsageData()
  }, [])

  useEffect(() => {
    let overriddenDate = new Date(moment().subtract(durationInDays, "d").format("YYYY-MM-DD"))
    setLogsFromDate(overriddenDate)
    setUsageData(undefined)
    processUsageData(overriddenDate)
  }, [durationInDays])

  // @ts-ignore
  // @ts-ignore
  return (
    <>
      <SearchInput />
      {/* <div className="flex-row graph-placeholder-wrapper d-flex md:align-items-center ">
        <div className="mr-[14px]">
          <svg
            width="32"
            height="32"
            viewBox="0 0 32 32"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g clipPath="url(#clip0_4933_32805)">
              <path
                d="M14.667 22.667H17.3337V14.667H14.667V22.667ZM16.0003 12.0003C16.3781 12.0003 16.695 11.8723 16.951 11.6163C17.207 11.3603 17.3345 11.0439 17.3337 10.667C17.3337 10.2892 17.2057 9.97233 16.9497 9.71633C16.6937 9.46033 16.3772 9.33277 16.0003 9.33366C15.6225 9.33366 15.3057 9.46166 15.0497 9.71766C14.7937 9.97366 14.6661 10.2901 14.667 10.667C14.667 11.0448 14.795 11.3617 15.051 11.6177C15.307 11.8737 15.6234 12.0012 16.0003 12.0003ZM16.0003 29.3337C14.1559 29.3337 12.4225 28.9834 10.8003 28.283C9.1781 27.5825 7.76699 26.6328 6.56699 25.4337C5.36699 24.2337 4.41721 22.8225 3.71766 21.2003C3.0181 19.5781 2.66788 17.8448 2.66699 16.0003C2.66699 14.1559 3.01721 12.4225 3.71766 10.8003C4.4181 9.1781 5.36788 7.76699 6.56699 6.56699C7.76699 5.36699 9.1781 4.41721 10.8003 3.71766C12.4225 3.0181 14.1559 2.66788 16.0003 2.66699C17.8448 2.66699 19.5781 3.01721 21.2003 3.71766C22.8225 4.4181 24.2337 5.36788 25.4337 6.56699C26.6337 7.76699 27.5839 9.1781 28.2843 10.8003C28.9848 12.4225 29.3345 14.1559 29.3337 16.0003C29.3337 17.8448 28.9834 19.5781 28.283 21.2003C27.5825 22.8225 26.6328 24.2337 25.4337 25.4337C24.2337 26.6337 22.8225 27.5839 21.2003 28.2843C19.5781 28.9848 17.8448 29.3345 16.0003 29.3337ZM16.0003 26.667C18.9781 26.667 21.5003 25.6337 23.567 23.567C25.6337 21.5003 26.667 18.9781 26.667 16.0003C26.667 13.0225 25.6337 10.5003 23.567 8.43366C21.5003 6.36699 18.9781 5.33366 16.0003 5.33366C13.0225 5.33366 10.5003 6.36699 8.43366 8.43366C6.36699 10.5003 5.33366 13.0225 5.33366 16.0003C5.33366 18.9781 6.36699 21.5003 8.43366 23.567C10.5003 25.6337 13.0225 26.667 16.0003 26.667Z"
                fill="#C2D24B"
                fillOpacity="0.5"
              />
            </g>
            <defs>
              <clipPath id="clip0_4933_32805">
                <rect width="32" height="32" fill="white" />
              </clipPath>
            </defs>
          </svg>
        </div>
        <div className="">
          <h5 className="text-[14px] leading-[20px] font-weight-500 primary-text">
            {" "}
            {formatMessage({ id: "Sample Dashboard" })}
          </h5>
          <p className="text-[12px] leading-[18px] font-weight-400 mt-[2px] secondary-text">
            {formatMessage({
              id: "This is a sample/placeholder dashboard for now. Your tailored dashboard will be displayed once activity and traffic begin populating your AI Character or personality page in the Marketplace.",
            })}
          </p>
        </div>
      </div> */}
      <div className="tab-container py-[16px] md:p-[24px] md:pb-[140px] shadow-default rounded">
        <div className={"flex flex-col md:flex-row gap-y-3.5 justify-between"}>
          <div>
            <h4 className="text-[16px] leading-[22px] primary-text font-medium  mb-[4px] md:mb-[8px]">
              {formatMessage({ id: "Usage Tracking" })}
            </h4>
            <p className="text-[14px] leading-5 secondary-text font-normal">
              {formatMessage({
                id: "Summary of API usage from your organization",
              })}
            </p>
          </div>
          <div>
            <button
              className="btn export-button text-[14px] font-medium md:w-auto w-full"
              onClick={() => {
                setOpenUpgrade(true);
              }}
            >
              {formatMessage({ id: "Upgrade Plan" })}
            </button>
          </div>
        </div>
        <div
          className={
            "flex justify-between flex-wrap gap-y-3.5 items-center mt-7"
          }
        >
          <div className={"flex-grow"}>
            {usageData && <Select
              isMulti
              name="chartParams"
              theme={(theme) => ({
                ...theme,
                colors: {
                  ...theme.colors,
                  primary25: "#C2D24B",
                  primary: "#FFFFFF1A",
                },
              })}
              classNames={{
                control: () => {
                  return "secondary-bg border-main";
                },
                option: () => {
                  return "border-b border-main last:border-b-0";
                },
              }}
              hideSelectedOptions={false}
              components={{
                ClearIndicator: () => {
                  return null;
                },
                MultiValue: ({ children, ...rest }) => {
                  return (
                    <p
                      {...rest}
                      className={
                        "whitespace-nowrap secondary-text font-medium text-[12px] leading-[18x]"
                      }
                    >
                      {children},{" "}
                    </p>
                  );
                },
                Option: ({ children, ...rest }) => {
                  return (
                    <components.Option {...rest} className={"secondary-bg"}>
                      <div className={"flex items-center gap-x-2 event-filter"}>
                        <input
                          type={"checkbox"}
                          className={"form-check-input h-6 w-6 "}
                          onChange={() => null}
                          checked={get(rest, "isSelected")}
                        />
                        <label
                          className={
                            "primary-text text-[12px] leading-[18px] gap-x-1 flex items-center z-50 "
                          }
                        >
                          {get(rest, "data.box")}
                          {children}
                        </label>
                      </div>
                    </components.Option>
                  );
                },
              }}
              defaultValue={[
                {
                  value: "chatTokens",
                  label: `${formatMessage({ id: "Chat Tokens" })}`,
                  data: usageData?.["chat-tokens"],
                  color: "#C2D24B",
                  box: <div className={"w-[12px] h-[12px] bg-[#C2D24B]"} />,
                },
                {
                  value: "stockVoices",
                  label: `${formatMessage({ id: "Stock voices" })}`,
                  data: usageData?.["stock-voice"],
                  color: "#8898A6",
                  box: <div className={"w-[12px] h-[12px] bg-[#8898A6]"} />,
                },
                {
                  value: "clonedVoice",
                  label: `${formatMessage({ id: "Cloned voices" })}`,
                  data: usageData?.["cloned-voice"],
                  color: "#4F516D",
                  box: <div className={"w-[12px] h-[12px] bg-[#4F516D]"} />,
                },
              ]}
              options={[
                {
                  value: "chatTokens",
                  label: `${formatMessage({ id: "Chat Tokens" })}`,
                  data: usageData?.["chat-tokens"],
                  color: "#C2D24B",
                  box: <div className={"w-[12px] h-[12px] bg-[#C2D24B]"} />,
                },
                {
                  value: "stockVoices",
                  label: `${formatMessage({ id: "Stock voices" })}`,
                  data: usageData?.["stock-voice"],
                  color: "#8898A6",
                  box: <div className={"w-[12px] h-[12px] bg-[#8898A6]"} />,
                },
                {
                  value: "clonedVoice",
                  label: `${formatMessage({ id: "Cloned voices" })}`,
                  data: usageData?.["cloned-voice"],
                  color: "#4F516D",
                  box: <div className={"w-[12px] h-[12px] bg-[#4F516D]"} />,
                },
              ]}
              className="md:!w-[240px] h-[40px] setting-multiselect !w-full"
              onChange={(chartParams) => {
                setSelected(chartParams);
              }}
              placeholder={formatMessage({
                id: "Choose Investor type",
              })}
              classNamePrefix="react-select"
              required={true}
            />}
          </div>
          <div
            className={
              "flex flex-col  md:flex-row md:items-center gap-y-2.5 md:gap-x-8"
            }
          >
            <div className={"flex items-center gap-x-[8px] company-container"}>
              <form className="form-check  form-check-custom react-switch-bg form-check-solid form-check-primary form-switch flex items-center gap-x-[8px]">
                <input
                  className="form-check-input flex-grow !w-[45px]"
                  type="checkbox"
                  name="model.app.sidebar.default.minimize.desktop.hoverable"
                  onChange={(e) => {
                    setCumulative(e.target.checked);
                  }}
                />
              </form>
              <label className={"text-[13px] leading-5 primary-text"}>
                {formatMessage({ id: "Cumulative" })}
              </label>
            </div>
            <div className={"flex-grow md:flex-grow-0"}>
              <OverlayTrigger
                trigger={"click"}
                placement={"bottom"}
                rootClose={true}
                overlay={
                  <Popover>
                    <div className={"py-1 flex text-[14px] primary-text"}>
                      <ul
                        className={
                          "child:flex child:gap-x-5 child:px-2 child:py-1 child:transition-all child:duration-300 child-hover:bg-[#C2D24B1A] child-hover:text-[#C2D24B] child:cursor-pointer"
                        }
                      >
                        {map(comparedData, (item) => (
                          <li
                            onClick={() =>
                              setDurationInDays(item.value)
                            }
                            key={Math.random().toString()}
                          >
                            <span className={"w-44 flex items-center"}>
                              <span className={"w-5 mr-2"}>
                                {isEqual(
                                  get(item, "value"),
                                  get(comparedData, "value")
                                ) && (
                                    <svg
                                      width="18"
                                      height="18"
                                      viewBox="0 0 18 18"
                                      fill="none"
                                      xmlns="http://www.w3.org/2000/svg"
                                    >
                                      <path
                                        d="M6.66667 14L2 9.31915L3.63333 7.68085L6.66667 10.7234L14.3667 3L16 4.6383L6.66667 14Z"
                                        fill="#C2D24B"
                                      />
                                    </svg>
                                  )}
                              </span>
                              {get(item, "label")}
                            </span>
                            {/* <span className={"whitespace-nowrap"}>
                              {get(item, "label")}
                            </span> */}
                          </li>
                        ))}
                      </ul>
                    </div>
                  </Popover>
                }
              >
                <select
                  defaultValue={comparedData?.find((el) => el.value == durationInDays)?.value}
                  placeholder={formatMessage({ id: "please select address" })}
                  className={
                    "form-control search-input-table form-select min-w-[180px] !h-[34px] py-0  !pt-0.5 px-4 !font-medium"
                  }
                >
                  <option hidden defaultValue={comparedData?.find((el) => el.value == durationInDays)?.value}>
                    {comparedData?.find((el) => el.value == durationInDays)?.label}
                  </option>
                </select>
              </OverlayTrigger>
            </div>
          </div>
        </div>
        {usageData && <UsageTrackingChart
          className={""}
          isCumulative={cumulative}
          data={selected}
          categories={usageData?.["chat-tokens"]?.map((el: any) => { return moment(el.date).format("D-MMM") })}
        />}
        <div className={"flex flex-col md:flex-row gap-[16px] md:gap-[8px] md:gap-x-[32px]  py-[16px]"}>
          <div className={"flex items-center gap-x-2"}>
            <div>
              <input
                type={"radio"}
                name={"voiceType"}
                className={"form-check-input cursor-pointer"}
                value={"planQuota"}
                checked={type == "planQuota"}
                onChange={() => setType("planQuota")}
              />
            </div>
            <label
              className={clsx(
                "text-[16px] flex  leading-[24px] font-medium",
                type == "planQuota" ? "!text-[#C2D24B]" : "secondary-text"
              )}
            >
              {formatMessage({ id: "Quota included in subscription plan" })}
              <ToolTipUI
                tooltipText={formatMessage({
                  id: "Quota included in subscription plan",
                })}
              />
            </label>
          </div>
          <div className={"flex items-center gap-x-2"}>
            <div>
              <input
                type={"radio"}
                className={"form-check-input cursor-pointer"}
                value={"purchasedQuota"}
                checked={type == "purchasedQuota"}
                onChange={() => setType("purchasedQuota")}
              />
            </div>
            <label
              className={clsx(
                "text-[16px] flex  leading-[24px] font-medium",
                type == "purchasedQuota" ? "!text-[#C2D24B]" : "secondary-text"
              )}
            >
              {formatMessage({ id: "Additional Purchased Quota" })}
              <ToolTipUI
                tooltipText={formatMessage({
                  id: "Additional Purchased Quota",
                })}
              />
            </label>
          </div>
        </div>
        <div className="flex-row graph-placeholder-wrapper d-flex md:align-items-center  !mb-[24px] md:!mt-[14px]">
          <div className="mr-[8px] md:mr-[14px]">
            <svg
              width="32"
              height="32"
              viewBox="0 0 32 32"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g clipPath="url(#clip0_4933_32805)">
                <path
                  d="M14.667 22.667H17.3337V14.667H14.667V22.667ZM16.0003 12.0003C16.3781 12.0003 16.695 11.8723 16.951 11.6163C17.207 11.3603 17.3345 11.0439 17.3337 10.667C17.3337 10.2892 17.2057 9.97233 16.9497 9.71633C16.6937 9.46033 16.3772 9.33277 16.0003 9.33366C15.6225 9.33366 15.3057 9.46166 15.0497 9.71766C14.7937 9.97366 14.6661 10.2901 14.667 10.667C14.667 11.0448 14.795 11.3617 15.051 11.6177C15.307 11.8737 15.6234 12.0012 16.0003 12.0003ZM16.0003 29.3337C14.1559 29.3337 12.4225 28.9834 10.8003 28.283C9.1781 27.5825 7.76699 26.6328 6.56699 25.4337C5.36699 24.2337 4.41721 22.8225 3.71766 21.2003C3.0181 19.5781 2.66788 17.8448 2.66699 16.0003C2.66699 14.1559 3.01721 12.4225 3.71766 10.8003C4.4181 9.1781 5.36788 7.76699 6.56699 6.56699C7.76699 5.36699 9.1781 4.41721 10.8003 3.71766C12.4225 3.0181 14.1559 2.66788 16.0003 2.66699C17.8448 2.66699 19.5781 3.01721 21.2003 3.71766C22.8225 4.4181 24.2337 5.36788 25.4337 6.56699C26.6337 7.76699 27.5839 9.1781 28.2843 10.8003C28.9848 12.4225 29.3345 14.1559 29.3337 16.0003C29.3337 17.8448 28.9834 19.5781 28.283 21.2003C27.5825 22.8225 26.6328 24.2337 25.4337 25.4337C24.2337 26.6337 22.8225 27.5839 21.2003 28.2843C19.5781 28.9848 17.8448 29.3345 16.0003 29.3337ZM16.0003 26.667C18.9781 26.667 21.5003 25.6337 23.567 23.567C25.6337 21.5003 26.667 18.9781 26.667 16.0003C26.667 13.0225 25.6337 10.5003 23.567 8.43366C21.5003 6.36699 18.9781 5.33366 16.0003 5.33366C13.0225 5.33366 10.5003 6.36699 8.43366 8.43366C6.36699 10.5003 5.33366 13.0225 5.33366 16.0003C5.33366 18.9781 6.36699 21.5003 8.43366 23.567C10.5003 25.6337 13.0225 26.667 16.0003 26.667Z"
                  fill="#C2D24B"
                  fillOpacity="0.5"
                />
              </g>
              <defs>
                <clipPath id="clip0_4933_32805">
                  <rect width="32" height="32" fill="white" />
                </clipPath>
              </defs>
            </svg>
          </div>
          <div className="flex flex-col md:flex-row gap-1 ">
            <p className="text-[12px] whitespace-nowrap leading-[20px]  secondary-text !font-[700]">
              {formatMessage({
                id: "Important:",
              })}
            </p>
            <p className="text-[12px] leading-[20px] font-weight-400  secondary-text">
              {formatMessage({
                id: "Additional purchased quota will be used only after the monthly subscription quota is depleted. The subscription quota resets monthly on the start date of your subscription.",
              })}
            </p>
          </div>
        </div>
        <div
          className={
            "grid grid-cols-12 gap-y-5 md:gap-y-[36px] md:gap-x-[36px] relative"
          }
        >
          {quotaLoading && <Spinner />}
          {map(quotas, (item) => (
            <div className={"col-span-12 md:col-span-6"}>
              <div className={"mb-1 flex justify-between items-center"}>
                <label
                  className={
                    "flex gap-x-1 text-[16px] font-medium primary-text"
                  }
                >
                  {formatMessage({ id: get(item, "title") })}
                  <ToolTipUI
                    tooltipText={formatMessage({ id: "Usage Tracking" })}
                  />
                </label>
                <div
                  className={
                    "main-text text-[13px] leading-5 flex items-center gap-x-1 cursor-pointer"
                  }
                  onClick={() => {
                    setOpenUpgrade(true);
                  }}
                >
                  <button
                    className={
                      "p-1 rounded flex justify-center items-center bg-[#C2D24B1A]"
                    }
                  >
                    <svg
                      width="12"
                      height="12"
                      viewBox="0 0 12 12"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M5.9987 1.33301V10.6663M1.33203 5.99967H10.6654"
                        stroke="#C2D24B"
                        strokeWidth="1.2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </button>
                  {formatMessage({ id: "Increase Quota" })}
                </div>
              </div>
              <div className={"flex items-center gap-x-8"}>
                <div className={"secondary-bg rounded-[10px] flex-grow"}>
                  <div
                    className="bg-[#C2D24B] rounded-[10px] h-[12px]"
                    role="progressbar"
                    style={{
                      width:
                        item.slice == 0
                          ? 0
                          : Math.min(
                            100,
                            get(item, "slice") / (get(item, "from") / 100)
                          ) + "%",
                    }}
                  />
                </div>
                {/*  @ts-ignore */}
                <div
                  className={"text-[13px] primary-text leading-5 font-medium"}
                >
                  {Intl.NumberFormat("en-US", {
                    notation: "compact",
                    maximumFractionDigits: 1,
                  }).format(get(item, "slice"))}{" "}
                  /{" "}
                  {Intl.NumberFormat("en-US", {
                    notation: "compact",
                    maximumFractionDigits: 1,
                  }).format(get(item, "from"))}
                </div>
              </div>
            </div>
          ))}

          {/* <div className={"col-span-12 md:col-span-6 text-[#FFFFFFA6]"}>
            {formatMessage({ id: "Important: Additional purchased quota will be used only after the monthly subscription quota is depleted. The subscription quota resets monthly on the start date of your subscription." })}
          </div> */}
        </div>
      </div>
      <UsageTrackingModal
        openUpgrade={openUpgrade}
        setOpenUpgrade={setOpenUpgrade}
      />
      <Modal
        backdropClassName="sub-modal-1"
        className="sub-modal-1"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={processing}
      >
        <Modal.Header>
          <Modal.Title
            className="modal-title text-[20px] leading-[28px] font-weight-600 primary-text"
            id="contained-modal-title-vcenter"
          >
            {formatMessage({
              id: "Please Wait...",
            })}
          </Modal.Title>
          <img src={crossIcon} alt=""></img>
        </Modal.Header>
        <Modal.Body>
          <div className="flex-column d-flex align-items-center">
            <Lottie
              className=""
              animationData={processingJson}
              style={{
                width: 185,
                height: 145,
                borderRadius: 25,
                // borderTopLeftRadius: 0,
                // overflow: "hidden",
              }}
            />
            <h4 className="text-[22px] mt-[20px] mb-[8px] leading-[32px] font-weight-600 primary-text">
              {formatMessage({
                id: "Processing...",
              })}
            </h4>
            <p className="text-[13px] leading-[20px] font-weight-400 secondary-text">
              {formatMessage({
                id: "We are processing your request...",
              })}
            </p>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default UsageTracking;
