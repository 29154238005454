import { get, isEqual, map } from "lodash";
import React, { useEffect, useState } from "react";
import CustomTable from "../../../components/table";
import clsx from "clsx";
import { getTrainingHistory } from "../core/_request";
import { useAuth } from "../../auth";
import { useDateFormat } from "../../../hooks/useDateFormat";
import { useTimeZone } from "../../../hooks/useTimeZone";
import { getStatusStyle, getStatusText } from "../core/_util";
import { useIntl } from "react-intl";

const History = () => {
  const [expandRow, setExpandRow] = useState<any>();
  const { selectedPersonality } = useAuth();
  const { formatMessage } = useIntl();
  const { getDateValue } = useDateFormat();
  const { getTimeZoneValue } = useTimeZone();
  const [loading, setLoading] = useState<boolean>(true);
  const [data, setData] = useState<any[]>([]);
  const [pageCount, setPageCount] = useState<number>(0);

  async function getHistory({ page, size }: any) {
    setLoading(true);
    const {
      data: { success, data },
    } = await getTrainingHistory(
      selectedPersonality?.personalityId,
      page + 1,
      size
    );
    if (success) {
      let formattedData = data.trainings.map(
        ({
          trainingId,
          title,
          type,
          createdAt,
          updatedAt,
          status,
          statusMessage,
          tokensUsed,
          trainingDuration,
          user
        }) => {
          return {
            id: trainingId,
            title,
            type,
            createdAt: `${getDateValue(createdAt)} ${getTimeZoneValue(
              createdAt
            )}`,
            updatedAt: `${getDateValue(updatedAt)} ${getTimeZoneValue(
              updatedAt
            )}`,
            status,
            details: [
              status,
              statusMessage || "-",
              tokensUsed || "-",
              trainingDuration || "-",
            ],
            subRows: [
              {
                status,
                statusMessage: statusMessage || "-",
                tokensUsed: tokensUsed || "-",
                minutesUsed: trainingDuration
                  ? Math.ceil(trainingDuration / 60)
                  : "-",
                submittedBy: `${user?.firstName} ${user?.lastName}`
              },
            ],
          };
        }
      );
      setLoading(false);
      setData(formattedData);
      setPageCount(Math.ceil(data.count / size));
    }
    setLoading(false);
  }

  const headers = [
    {
      label: formatMessage({ id: "Title" }),
      key: "title",
      enableSorting: true,
    },
    {
      label: formatMessage({ id: "Training Type" }),
      key: "type",
      enableSorting: true,
    },
    {
      label: formatMessage({ id: "Submitted at" }),
      key: "createdAt",
      enableSorting: true,
    },
    {
      label: formatMessage({ id: "Updated at" }),
      key: "updatedAt",
      enableSorting: true,
    },
    {
      label: formatMessage({ id: "Training Status" }),
      key: "status",
      enableSorting: true,
    },
    {
      label: formatMessage({ id: "Details" }),
      key: "details",
      cell: true,
      enableSorting: false,
    },
  ];

  const columns = React.useMemo<any>(
    () =>
      map(headers, (item) => {
        return {
          header: get(item, "label"),
          accessorKey: get(item, "key"),
          enableSorting: get(item, "enableSorting"),
          // @ts-ignore
          cell: ({ row, ...info }) => {
            if (item.cell) {
              if (isEqual(get(item, "key"), "details")) {
                return (
                  <button
                    className={clsx(
                      " h-[26px] w-[36px] flex justify-center items-center rounded",
                      row.getIsExpanded() ? "bg-[#C2D24B1A]" : "secondary-bg"
                    )}
                  >
                    {row.getIsExpanded() ? (
                      <svg
                        width="8"
                        height="2"
                        viewBox="0 0 8 2"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M7.312 0.52V1.752H0.928V0.52H7.312Z"
                          fill="#C2D24B"
                        />
                      </svg>
                    ) : (
                      <svg
                        width="9"
                        height="9"
                        viewBox="0 0 9 9"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M8.552 4.768H5.144V8.224H3.784V4.768H0.392V3.536H3.784V0.0639992H5.144V3.536H8.552V4.768Z"
                          className={"icon"}
                          fillOpacity="0.65"
                        />
                      </svg>
                    )}
                  </button>
                );
              }
            }
            if (isEqual(get(item, "key", ""), "type")) {
              return (
                <div>
                  {isEqual(info.getValue(), "chat") && (
                    <span className={"flex items gap-x-1"}>
                      <svg
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <g clipPath="url(#clip0_497_13835)">
                          <path
                            d="M6.66667 7.49998H13.3333M6.66667 10.8333H11.6667M15 3.33331C15.663 3.33331 16.2989 3.59671 16.7678 4.06555C17.2366 4.53439 17.5 5.17027 17.5 5.83331V12.5C17.5 13.163 17.2366 13.7989 16.7678 14.2677C16.2989 14.7366 15.663 15 15 15H10.8333L6.66667 17.5V15H5C4.33696 15 3.70107 14.7366 3.23223 14.2677C2.76339 13.7989 2.5 13.163 2.5 12.5V5.83331C2.5 5.17027 2.76339 4.53439 3.23223 4.06555C3.70107 3.59671 4.33696 3.33331 5 3.33331H15Z"
                            stroke="white"
                            strokeOpacity="0.8"
                            strokeWidth="1.4"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </g>
                        <defs>
                          <clipPath id="clip0_497_13835">
                            <rect width="20" height="20" fill="white" />
                          </clipPath>
                        </defs>
                      </svg>
                      {formatMessage({ id: "Chat" })}
                    </span>
                  )}
                  {isEqual(info.getValue(), "link") && (
                    <span className={"flex items gap-x-1"}>
                      <svg
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <g clipPath="url(#clip0_497_13861)">
                          <path
                            d="M7.49979 12.5L12.4998 7.49999M9.16646 4.99999L9.55229 4.55332C10.3338 3.77193 11.3937 3.33299 12.4988 3.33307C13.604 3.33315 14.6638 3.77224 15.4452 4.55374C16.2266 5.33524 16.6655 6.39515 16.6655 7.50029C16.6654 8.60542 16.2263 9.66526 15.4448 10.4467L14.9998 10.8333M10.8331 15L10.5023 15.445C9.71167 16.2268 8.64462 16.6653 7.53271 16.6653C6.4208 16.6653 5.35375 16.2268 4.56313 15.445C4.17342 15.0597 3.86404 14.6008 3.65289 14.0951C3.44173 13.5894 3.33301 13.0468 3.33301 12.4987C3.33301 11.9507 3.44173 11.4081 3.65289 10.9024C3.86404 10.3966 4.17342 9.93782 4.56313 9.55249L4.99979 9.16666"
                            stroke="white"
                            strokeWidth="1.4"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </g>
                        <defs>
                          <clipPath id="clip0_497_13861">
                            <rect width="20" height="20" fill="white" />
                          </clipPath>
                        </defs>
                      </svg>
                      {formatMessage({ id: "Link" })}
                    </span>
                  )}
                  {isEqual(info.getValue(), "file") && (
                    <span className={"flex items gap-x-1"}>
                      <svg
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <g clipPath="url(#clip0_497_13900)">
                          <path
                            d="M11.667 2.5V5.83333C11.667 6.05435 11.7548 6.26631 11.9111 6.42259C12.0674 6.57887 12.2793 6.66667 12.5003 6.66667H15.8337"
                            stroke="white"
                            strokeWidth="1.4"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            d="M14.167 17.5H5.83366C5.39163 17.5 4.96771 17.3244 4.65515 17.0118C4.34259 16.6993 4.16699 16.2754 4.16699 15.8333V4.16667C4.16699 3.72464 4.34259 3.30072 4.65515 2.98816C4.96771 2.67559 5.39163 2.5 5.83366 2.5H11.667L15.8337 6.66667V15.8333C15.8337 16.2754 15.6581 16.6993 15.3455 17.0118C15.0329 17.3244 14.609 17.5 14.167 17.5Z"
                            stroke="white"
                            strokeWidth="1.4"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            d="M6.66699 12.5H13.3337M9.16699 9.16669V15M6.66699 9.16669H13.3337V15H6.66699V9.16669Z"
                            stroke="white"
                            strokeWidth="1.4"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </g>
                        <defs>
                          <clipPath id="clip0_497_13900">
                            <rect width="20" height="20" fill="white" />
                          </clipPath>
                        </defs>
                      </svg>
                      {formatMessage({ id: "File" })}
                    </span>
                  )}
                </div>
              );
            }
            if (isEqual(get(item, "key", ""), "status")) {
              return (
                <div>
                  <span
                    className={
                      "px-4 py-1 rounded " + getStatusStyle(info.getValue())
                    }
                  >
                    {getStatusText(info.getValue())}
                  </span>
                </div>
              );
            } else return info.getValue();
          },
          // @ts-ignore
          footer: (props: any) => props.column.id,
        };
      }),

    []
  );

  // @ts-ignore
  const renderSubComponent = ({ row }: { row: Row<Person> }) => {
    return map(get(row, "original.subRows"), (item) => {
      return (
        <div className={"flex md:gap-x-32"}>
          <div>
            <label>{formatMessage({ id: "Status" })}</label>
            <p>{getStatusText(get(item, "status"))}</p>
          </div>
          <div>
            <label>{formatMessage({ id: "Status Message" })}</label>
            <p>{get(item, "statusMessage")}</p>
          </div>
          <div>
            <label>{formatMessage({ id: "Tokens Used" })}</label>
            <p>{get(item, "tokensUsed")}</p>
          </div>
          <div>
            <label>{formatMessage({ id: "Minutes Used" })}</label>
            <p>{get(item, "minutesUsed")}</p>
          </div>
          <div>
            <label>{formatMessage({ id: "Submitted By" })}</label>
            <p>{get(item, "submittedBy")}</p>
          </div>
        </div>
      );
    });
  };
  return (
    <div
      className={
        "min-h-[60vh] tab-container  sm:py-[16px] md:pt-5 md:pb-[32px] shadow-default rounded  mt-4  sm:px-[16px] md:px-[24px]"
      }
    >
      {selectedPersonality && <CustomTable
        loading={loading}
        expandedRow={expandRow}
        tableData={data}
        pageCount={pageCount}
        headers={headers}
        columns={columns}
        getRowCanExpand={() => true}
        renderSubComponent={renderSubComponent}
        fetchData={getHistory}
      />}
    </div>
  );
};

export default History;
