import clsx from "clsx";
import { motion, AnimatePresence } from "framer-motion";
import React from "react";
import { useIntl } from "react-intl";
const Index: React.FC<any> = ({
  index,
  expanded,
  setExpanded,
  title,
  paragraph,
}) => {
  const isOpen = index === expanded;
  const { formatMessage } = useIntl();

  return (
    <>
      <motion.header initial={false}>
        <h3
          className={
            "text-[14px] leading-[20px] secondary-text cursor-pointer font-medium flex justify-between items-center"
          }
          onClick={() => setExpanded(isOpen ? false : index)}
        >
          {formatMessage({ id: title })}
          <button
            className={
              "min-w-[24px] min-h-[24px] ml-[12px]  rounded-full flex items-center justify-center secondary-bg relative"
            }
          >
            <svg
              className={clsx(
                "absolute transition-all duration-300",
                isOpen ? " rotate-0 opacity-0" : "rotate-90"
              )}
              width="18"
              height="18"
              viewBox="0 0 18 18"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g clipPath="url(#clip0_541_11526)">
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M2.25 9C2.25 8.58579 2.58579 8.25 3 8.25H15C15.4142 8.25 15.75 8.58579 15.75 9C15.75 9.41421 15.4142 9.75 15 9.75H3C2.58579 9.75 2.25 9.41421 2.25 9Z"
                  className={"icon"}
                  fillOpacity="0.65"
                />
              </g>
              <defs>
                <clipPath id="clip0_541_11526">
                  <rect width="18" height="18" className={"icon"} />
                </clipPath>
              </defs>
            </svg>
            <svg
              width="18"
              height="18"
              viewBox="0 0 18 18"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g clipPath="url(#clip0_541_11526)">
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M2.25 9C2.25 8.58579 2.58579 8.25 3 8.25H15C15.4142 8.25 15.75 8.58579 15.75 9C15.75 9.41421 15.4142 9.75 15 9.75H3C2.58579 9.75 2.25 9.41421 2.25 9Z"
                  className={"icon"}
                  fillOpacity="0.65"
                />
              </g>
              <defs>
                <clipPath id="clip0_541_11526">
                  <rect width="18" height="18" className={"icon"} />
                </clipPath>
              </defs>
            </svg>
          </button>
        </h3>
      </motion.header>
      <AnimatePresence initial={false}>
        {isOpen && (
          <motion.section
            key="content"
            initial="collapsed"
            animate="open"
            exit="collapsed"
            variants={{
              open: { opacity: 1, height: "auto" },
              collapsed: { opacity: 0, height: 0 },
            }}
            transition={{ duration: 0.8, ease: [0.04, 0.62, 0.23, 0.98] }}
          >
            <p className={"text-[12px] leading-[20px] primary-text pt-[12px]"}>
              {formatMessage({ id: paragraph })}
            </p>
          </motion.section>
        )}
      </AnimatePresence>
    </>
  );
};

export default Index;
