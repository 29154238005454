export enum GTMEvent {
    PageView = "Page View",
    PageLoad = "page_load",
    SignupSuccess = "signup_successful",
    SignupFailed = "signup_failed",
    LoginSuccess = "login_successful",
    LoginFailed = "login_failed",
    LogoutSuccess = "logout_successful",
    LogoutFailed = "logout_failed",
    OnboardingInitiatedSuccessful = 'onboarding_initiated_successful',
    OnboardingInitiatedFailed = 'onboarding_initiated_failed',
    OnboardingCompletedSuccessful = 'onboarding_completed_successful',
    OnboardingCompletedFailed = 'onboarding_completed_failed',
    NewPersonalityCreationInitiatedSuccessful = 'new_personality_creation_initiated_successful',
    NewPersonalityCreationInitiatedFailed = 'new_personality_creation_initiated_failed',
    NewPersonalityCreationCompletedSuccessful = 'new_personality_creation_completed_successful',
    NewPersonalityCreationCompletedFailed = 'new_personality_creation_completed_failed',
    PersonalitySwitchedSuccessful = 'personality_switched_successful',
    PersonalitySwitchedFailed = 'personality_switched_failed',
    EmailVerificationSuccess = "email_verification_successful",
    EmailVerificationFailed = "email_verification_failed",
    ForgotPasswordSuccess = "forgot_password_successful",
    ForgotPasswordFailed = "forgot_password_failed",
    ResetPasswordSuccess = "password_reset_successful",
    ResetPasswordFailed = "password_reset_failed",
    AIPersonalityEditSuccessful = "ai_personality_edit_successful",
    AIPersonalityEditFailed = "ai_personality_edit_failed",
    PaywallShownSuccessful = "paywall_shown_successful",
    PaymentPortalOpenedSuccessful = "payment_portal_opened_successful",
    PaymentPortalOpenedFailed = "payment_portal_opened_failed",
    PersonalityPlanChangedSuccessful = "personality_plan_changed_successful",
    PersonalityPlanChangedFailed = "personality_plan_changed_failed",
    CreditRechargeSuccessful = "credit_recharge_successful",
    CreditRechargeFailed = "credit_recharge_failed",
    UserSettingsEditSuccessful = "user_settings_edit_successful",
    UserSettingsEditFailed = "user_settings_edit_failed",
    MarketplaceExplorePageView = "marketplace_explore_page_view",
    MarketplaceHomePageView = "marketplace_home_page_view",
    MarketplacePersonalityPageView = "marketplace_personality_page_view",
    MarketplaceUserPageView = "marketplace_user_page_view",
    ChatWidgetLoadSuccessful = "chat_widget_load_successful",
    PersonalitySwitchSuccessful = "personality_switch_successful",
    PersonalitySwitchFailed = "personality_switch_failed",
    QuotaPurchaseSuccessful = 'quota_purchase_successful',
    QuotaPurchaseFailed = 'quota_purchase_failed',
    // these below are new events from 14-09-2023
    ChatWidgetCreationFailed = "chat_widget_creation_failed",
    ChatWidgetCreationSuccessful = "chat_widget_creation_successful",
    ChatWidgetEditFailed = "chat_widget_edit_failed",
    ChatWidgetEditSuccessful = "chat_widget_edit_successful",
    ChatWidgetEnableSuccessful = "chat_widget_enable_successful",
    ChatWidgetEnableFailed = "chat_widget_enable_failed",
    ChatWidgetDisableSuccessful = "chat_widget_disable_successful",
    ChatWidgetDisableFailed = "chat_widget_disable_failed",
    ChatWidgetDeletionSuccessful = "chat_widget_deletion_successful",
    ChatWidgetDeletionFailed = "chat_widget_deletion_failed",
    PersonalityWidgetCreationFailed = "personality_widget_creation_failed",
    PersonalityWidgetCreationSuccessful = "personality_widget_creation_successful",
    PersonalityWidgetEditFailed = "personality_widget_edit_failed",
    PersonalityWidgetEditSuccessful = "personality_widget_edit_successful",
    PersonalityWidgetIframeCopySuccessful = "personality_widget_iframe_copy_successful",
    ApiKeyCreationFailed = "api_key_creation_failed",
    ApiKeyCreationSuccessful = "api_key_creation_successful",
    ApiKeyDeletionFailed = "api_key_deletion_failed",
    ApiKeyDeletionSuccessful = "api_key_deletion_successful",
    ApiKeyEnableFailed = "api_key_enable_failed",
    ApiKeyEnableSuccessful = "api_key_enable_successful",
    ApiKeyDisableFailed = "api_key_disable_failed",
    ApiKeyDisableSuccessful = "api_key_disable_successful",
    TrainingChatCreationFailed = "training_chat_creation_failed",
    TrainingChatCreationSuccessful = "training_chat_creation_successful",
    TrainingChatSubmissionFailed = "training_chat_submission_failed",
    TrainingChatSubmissionSuccessful = "training_chat_submission_successful",
    TrainingChatDeletionFailed = "training_chat_deletion_failed",
    TrainingChatDeletionSuccessful = "training_chat_deletion_successful",
    PostCreationFailed = "post_creation_failed",
    PostCreationSuccessful = "post_creation_successful",
    PostEditFailed = "post_edit_failed",
    PostEditSuccessful = "post_edit_successful",
    PostDeletionFailed = "post_deletion_failed",
    PostDeletionSuccessful = "post_deletion_successful",
    PostLikeFailed = "post_like_failed",
    PostLikeSuccessful = "post_like_successful",
    PostLikeRevertFailed = "post_like_revert_failed",
    PostLikeRevertSuccessful = "post_like_revert_successful",
    FilterChangeFailed = "filter_change_failed",
    FilterChangeSuccessful = "filter_change_successful",
    SearchFailed = "search_failed",
    SearchSuccessful = "search_successful",
    FilterResetFailed = "filter_reset_failed",
    FilterResetSuccessful = "filter_reset_successful",
    // LicensedFilterApplyFailed = "licensed_filter_apply_failed",
    LicensedFilterEnableSuccessful = "licensed_filter_enable_successful",
    LicensedFilterDisableSuccessful = "licensed_filter_disable_successful",
    UserFollowFailed = "user_follow_failed",
    UserFollowSuccessful = "user_follow_successful",
    UserUnfollowFailed = "user_unfollow_failed",
    UserUnfollowSuccessful = "user_unfollow_successful",
    // UserShareModalOpenFailed = "user_share_modal_open_failed",
    UserShareModalOpenSuccessful = "user_share_modal_open_successful",
    // UserProfileShareClickFailed = "user_profile_share_click_failed",
    UserProfileShareClickSuccessful = "user_profile_share_click_successful",
    PersonalityFollowFailed = "personality_follow_failed",
    PersonalityFollowSuccessful = "personality_follow_successful",
    PersonalityUnfollowFailed = "personality_unfollow_failed",
    PersonalityUnfollowSuccessful = "personality_unfollow_successful",
    // PersonalityShareModalOpenFailed = "personality_share_modal_open_failed",
    PersonalityShareModalOpenSuccessful = "personality_share_modal_open_successful",
    // PersonalityProfileShareClickFailed = "personality_profile_share_click_failed",
    PersonalityProfileShareClickSuccessful = "personality_profile_share_click_successful",
    ChatSessionStartFailed = "chat_session_start_failed",
    ChatSessionStartSuccessful = "chat_session_start_successful",
    ChatSessionEndFailed = "chat_session_end_failed",
    ChatSessionEndSuccessful = "chat_session_end_successful",
    ChatMessageLikeFailed = "chat_message_like_failed",
    ChatMessageLikeSuccessful = "chat_message_like_successful",
    ChatMessageDislikeFailed = "chat_message_dislike_failed",
    ChatMessageDislikeSuccessful = "chat_message_dislike_successful",
    ChatMessageShareModalOpenFailed = "chat_message_share_modal_open_failed",
    ChatMessageShareModalOpenSuccessful = "chat_message_share_modal_open_successful",
    ChatMessageShareClickFailed = "chat_message_share_click_failed",
    ChatMessageShareClickSuccessful = "chat_message_share_click_successful",
    AvatarTurnOnFailed = "avatar_turn_on_failed",
    AvatarTurnOnSuccessful = "avatar_turn_on_successful",
    AvatarTurnOffFailed = "avatar_turn_off_failed",
    AvatarTurnOffSuccessful = "avatar_turn_off_successful",
    _3DAvatarOnFailed = "3d_avatar_on_failed",
    _3DAvatarOnSuccessful = "3d_avatar_on_successful",
    _2DAvatarOnFailed = "2d_avatar_on_failed",
    _2DAvatarOnSuccessful = "2d_avatar_on_successful",
    MicTurnOnFailed = "mic_turn_on_failed",
    MicTurnOnSuccessful = "mic_turn_on_successful",
    MicTurnOffFailed = "mic_turn_off_failed",
    MicTurnOffSuccessful = "mic_turn_off_successful",
    SpeakerOnFailed = "speaker_on_failed",
    SpeakerOnSuccessful = "speaker_on_successful",
    SpeakerOffFailed = "speaker_off_failed",
    SpeakerOffSuccessful = "speaker_off_successful",
    FeedbackSubmissionSuccessful = "feedback_submission_successful",
    FeedbackSubmissionFailed = "feedback_submission_failed",
    RestartConversationPressSuccessful = "restart_conversation_press_successful",
    ChatThreadCopySuccessful = "chat_thread_copy_successful",
    StartChattingButtonPressSuccessful = "start_chatting_button_press_successful",
    HeaderSearchBoxSearchSuccessful = "header_search_box_search_successful",
    HeaderSearchBoxSearchFailed = "header_search_box_search_failed",
    // ListingUpdateSuccessful = "listing_update_successful",
    // ListingUpdateFailed = "listing_update_failed",
    // MonetizationUpdateSuccessful = "monetization_update_successful",
    // MonetizationUpdateFailed = "monetization_update_failed",
    // MarketplaceSettingsUpdateSuccessful = "marketplace_settings_update_successful",
    // MarketplaceSettingsUpdateFailed = "marketplace_settings_update_failed",
    // AIPersonalitySettingsUpdateSuccessful = "ai_personality_settings_update_successful",
    // AIPersonalitySettingsUpdateFailed = "ai_personality_settings_update_failed",
    // FeedbackMarkReadSuccessful = "feedback_mark_read_successful",
    // FeedbackMarkReadFailed = "feedback_mark_read_failed",
    // InvoiceDownloadedSuccess = "invoice_downloaded_success",
    // InvoiceDownloadedFailed = "invoice_downloaded_failed",
    // PostCreationSuccessful = "post_creation_successful",
    // PostCreationFailed = "post_creation_failed",
    // PostLikeSuccessful = "post_like_successful",
    // PostLikeFailed = "post_like_failed",
    // PostUnlikeSuccessful = "post_unlike_successful",
    // PostUnlikeFailed = "post_unlike_failed",
    // ChatSubscribeSuccessful = "chat_subscribe_successful",
    // ChatSubscribeFailed = "chat_subscribe_failed",
    // APISubscribeSuccessful = "api_subscribe_successful",
    // APISubscribeFailed = "api_subscribe_failed",
    // ChatSessionStartedSuccessful = "chat_session_started_successful",
    // ChatSessionStartedFailed = "chat_session_started_failed",
    // ChatSessionEndedSuccessful = "chat_session_ended_successful",
    // ChatSessionEndedFailed = "chat_session_ended_failed",
    // ChatMessageSentSuccessful = "chat_message_sent_successful",
    // ChatMessageSentFailed = "chat_message_sent_failed",
    // ChatReplyLikeSuccessful = "chat_reply_like_successful",
    // ChatReplyLikeFailed = "chat_reply_like_failed",
    // ChatReplyUnlikeSuccessful = "chat_reply_unlike_successful",
    // ChatReplyUnlikeFailed = "chat_reply_unlike_failed",
    // PersonalityFollowSuccessful = "personality_follow_successful",
    // PersonalityFollowFailed = "personality_follow_failed",
    // UserFollowSuccessful = "user_follow_successful",
    // UserFollowFailed = "user_follow_failed",
    // ExplorePageFilterUpdateSuccessful = "explore_page_filter_update_successful",
    // ExplorePageFilterUpdateFailed = "explore_page_filter_update_failed",
    // ExplorePageLicenseSwitchTriggerSuccessful = "explore_page_license_switch_trigger_successful",
    // ExplorePageLicenseSwitchTriggerFailed = "explore_page_license_switch_trigger_failed",
    // ExplorePageSearchSuccessful = "explore_page_search_successful",
    // ExplorePageSearchFailed = "explore_page_search_failed",
    // ExplorePageEmptyResultShownSuccessful = "explore_page_empty_result_shown_successful",
    // ExplorePageEmptyResultShownFailed = "explore_page_empty_result_shown_failed",
    
    // PersonalityTrainingByChatSubmitSuccessful = "personality_training_by_chat_submit_successful",
    // PersonalityTrainingByChatSubmitFailed = "personality_training_by_chat_submit_failed",
    // PersonalityTrainingByLinkSubmitSuccessful = "personality_training_by_link_submit_successful",
    // PersonalityTrainingByLinkSubmitFailed = "personality_training_by_link_submit_failed",
    // PersonalityTrainingByPrivateFileSubmitSuccessful = "personality_training_by_private_file_submit_successful",
    // PersonalityTrainingByPrivateFileSubmitFailed = "personality_training_by_private_file_submit_failed",
    // APIKeyCreationSuccessful = "api_key_creation_successful",
    // APIKeyCreationFailed = "api_key_creation_failed",
    // WebhookCreationSuccessful = "webhook_creation_successful",
    // WebhookCreationFailed = "webhook_creation_failed",
    // NotificationBoxOpenedSuccessful = "notification_box_opened_successful",
    // NotificationBoxOpenedFailed = "notification_box_opened_failed",
    TrainingFileCreationSuccessful = "training_file_creation_successful",
    TrainingFileCreationFailed = "training_file_creation_failed",
    TrainingFileEditSuccessful = "training_file_edit_successful",
    TrainingFileDeletionSuccessful = "training_file_deletion_successful",
    TrainingFileDeletionFailed = "training_file_deletion_failed",
    TrainingFileSubmissionSuccessful = "training_file_submission_successful",
    TrainingFileSubmissionFailed = "training_file_submission_failed",
    // TrainingFileTrainingDeletionSuccessful = "training_file_training_deletion_successful",
    // TrainingFileTrainingDeletionFailed = "training_file_training_deletion_failed",
    ThemeSwitchSuccessful = "theme_switch_successful",
    LanguagesSwitchSuccessful = "languages_switch_successful",
    PasswordChangeRequestSuccessful = "password_change_request_successful",
    PasswordChangeRequestFailed = "password_change_request_failed",
    UserProfilePicUploadSuccessful = "user_profile_pic_upload_successful",
    UserProfilePicUploadFailed = "user_profile_pic_upload_failed",
    UserProfileBannerUploadSuccessful = "user_profile_banner_upload_successful",
    UserProfileBannerUploadFailed = "user_profile_banner_upload_failed",
    PersonalityProfilePicUploadSuccessful = "personality_profile_pic_upload_successful",
    PersonalityProfilePicUploadFailed = "personality_profile_pic_upload_failed",
    PersonalityProfileBannerUploadSuccessful = "personality_profile_banner_upload_successful",
    PersonalityProfileBannerUploadFailed = "personality_profile_banner_upload_failed",
    PersonalityFeaturedImageUploadSuccessful = "personality_featured_image_upload_successful",
    PersonalityFeaturedImageUploadFailed = "personality_featured_image_upload_failed",
    PersonalityAvatarImageUploadSuccessful = "personality_avatar_image_upload_successful",
    PersonalityAvatarImageUploadFailed = "personality_avatar_image_upload_failed",
    ClonedVoiceUploadSuccessful = "cloned_voice_upload_successful",
    ClonedVoiceUploadFailed = "cloned_voice_upload_failed",
    CustomAvatarSelectionSuccessful = "custom_avatar_selection_successful",
    CustomAvatarSelectionFailed = "custom_avatar_selection_failed",
    AiResponseCopySuccessful = "ai_response_copy_successful",
}

export const loginSuccessMeta = (user: any) => {
    return {
        user_id: user.userId,
        email: user.email,
        name: `${user?.firstName ?? ''} ${user?.lastName ?? ''}`,
        signup_timestamp: new Date(user.createdAt).getTime() / 1000,
        phone: user.contact,
        user_hash: user.user_hash,
        avatar_image: user.photo,
        utm_source: user.utm_source,
        utm_medium: user.utm_medium,
        utm_campaign: user.utm_campaign,
        refered_by: user.firstReferredBy,
        referral_id: user.referCode,
        personality_count: user.personality.length,
        default_personality_id: user?.personality?.[0]?.personalityId ?? null,
        company: {
            name: user?.personality?.[0]?.name, //Company means the personality
            company_id: user?.personality?.[0]?.personalityId,
            plan: user?.personality?.[0]?.planId, //default
            website: user?.personality?.[0]?.website ?? null,
            industry: user?.personality?.[0]?.industry,
            created_at: new Date(user?.personality?.[0]?.createdAt).getTime() / 1000,
            monthly_spend: 0, //default
            custom_attributes: {
                personality_type: user?.personality?.[0]?.personalityType,
                category: user?.personality?.[0]?.category,
                follower_count: parseInt(user?.personality?.[0]?.followers || 0),
                likes_count: parseInt(user?.personality?.[0]?.likes ?? 0),
                famescore: user?.personality?.[0]?.fameScore || 0,
                username: user?.personality?.[0]?.username,
                url: `${window.location.origin}/p/${user?.personality?.[0]?.username}`
            }
        }
    }
}

export const emailVerificationMeta = (user: any) => {
    return {
        user_id: user.userId,
        email: user.email,
        name: `${user?.firstName ?? ''} ${user?.lastName ?? ''}`,
        signup_timestamp: new Date(user.createdAt).getTime() / 1000,
        phone: user.contact,
        user_hash: user.user_hash,
        avatar_image: user.photo,
        utm_source: user.utm_source,
        utm_medium: user.utm_medium,
        utm_campaign: user.utm_campaign,
        refered_by: user.firstReferredBy,
        referral_id: user.referCode,
    }
}

export const basePersonalityMeta = (personality: any,count:number) => {
    return {
        personality_count: count,
        company: { //new personality where the user switched
            name: personality?.name,
            company_id: personality?.personalityId,
            plan: personality?.planId,
            website: personality?.website,
            industry: personality?.industry,
            created_at: new Date(personality.createdAt).getTime() / 1000,
            monthly_spend: 0,
            custom_attributes: {
                personality_type: personality?.personality_type,
                category: personality?.category,
                follower_count: parseInt(personality?.followers || 0),
                likes_count: parseInt(personality?.[0]?.likes ?? 0),
                famescore: personality?.[0]?.fameScore || 0,
                username: personality?.username,
                url: `${window.location.origin}/p/${personality?.username}`
            }
        }
    }
}