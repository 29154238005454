import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import { useAuth } from "../../../../auth";
import clsx from "clsx";
import { toast } from "react-toastify";
import "./hyper.css";
import { getCdnUrl } from "../../../../../core/_util";
import { useIntl } from "react-intl";
import useGTM from "../../../../../hooks/useGTM";
import { GTMEvent } from "../../../../../hooks/gtm_helpers";

const HyperRealisticAvatar: React.FC<{
  loaderCss?: { [key: string]: string | number };
}> = ({ loaderCss = {} }) => {
  const { personalityInView, setPlayTextAvatar, playTextAvatar,setPauseListening } = useAuth();
  const {dataLayerPush} = useGTM()
  const personalityId = personalityInView.personalityId;
  const streamIdRef = useRef(null);
  const sessionIdRef = useRef(null);
  const [animated, setAnimated] = useState(true);
  const [avatarIsReady, setAvatarIsReady] = useState(false);
  const { formatMessage } = useIntl();
  const [connectingStatus, setConnectingStatus] = useState<number>(0) // 0 means none, 1 trying connecting, 2 connected, 3 failed

  const emojiRegex =
    /[\u{1F600}-\u{1F64F}]|[\u{1F300}-\u{1F5FF}]|[\u{1F680}-\u{1F6FF}]|[\u{1F700}-\u{1F77F}]|[\u{1F780}-\u{1F7FF}]|[\u{1F800}-\u{1F8FF}]|[\u{1F900}-\u{1F9FF}]|[\u{1FA00}-\u{1FA6F}]|[\u{2600}-\u{26FF}]|[\u{2700}-\u{27BF}]|\u{23E9}|\u{23EA}|\u{23EB}|\u{23EC}|\u{2B05}|\u{2B06}|\u{2B07}|\u{2B1B}|\u{2B1C}|\u{2B50}|\u{2B55}|[0-9]\u{FE0F}\u{20E3}|\u{203C}\u{FE0F}|\u{2049}\u{FE0F}|\u{2122}\u{FE0F}|\u{2139}\u{FE0F}|\u{2194}-\u{2199}|\u{21A9}\u{FE0F}|\u{21AA}\u{FE0F}|\u{231A}\u{FE0F}|\u{231B}\u{FE0F}|\u{2328}\u{FE0F}|\u{23CF}\u{FE0F}|\u{23E9}\u{FE0F}|\u{23EA}\u{FE0F}|\u{23EB}\u{FE0F}|\u{23EC}\u{FE0F}|\u{23ED}\u{FE0F}|\u{23EE}\u{FE0F}|\u{23EF}\u{FE0F}|\u{23F0}\u{FE0F}|\u{23F1}\u{FE0F}|\u{23F2}\u{FE0F}|\u{23F3}\u{FE0F}|\u{23F8}\u{FE0F}|\u{23F9}\u{FE0F}|\u{23FA}\u{FE0F}|\u{24C2}\u{FE0F}/gu;

  const RTCPeerConnection = window.RTCPeerConnection;

  const peerConnectionRef = useRef<RTCPeerConnection | null>(null);
  // let streamId: string | undefined;
  // let sessionId: string | undefined;
  let sessionClientAnswer: RTCSessionDescriptionInit | undefined;

  let statsIntervalIdsRef = useRef<NodeJS.Timeout[]>([]);
  let videoIsPlaying: boolean;
  let lastBytesReceived: number;

  const talkVideoRef = useRef<HTMLVideoElement>(null);

  const onFail = () => {
    setConnectingStatus(3)
    dataLayerPush(GTMEvent._2DAvatarOnFailed)
  }

  useEffect(() => {
    const talkVideo = talkVideoRef.current;
    if (talkVideo) {
      talkVideo.setAttribute("playsinline", "");
    }
  }, []);

  const connectSession = async () => {
    setConnectingStatus(1)
    if (
      peerConnectionRef.current &&
      peerConnectionRef.current.connectionState === "connected"
    ) {
      setConnectingStatus(2)
      dataLayerPush(GTMEvent._2DAvatarOnSuccessful)
      return;
    }

    stopAllStreams();
    closePC();
    try {
      const sessionResponse = await axios.post(
        process.env.REACT_APP_BASE_API_URL + "/streaming/connect/session",
        {},
        { headers: { "x-personality-id": personalityId } }
      );

      const {
        id: newStreamId,
        offer,
        ice_servers: iceServers,
        session_id: newSessionId,
      } = await sessionResponse.data.data;
      streamIdRef.current = newStreamId;
      sessionIdRef.current = newSessionId;

      try {
        sessionClientAnswer = await createPeerConnection(offer, iceServers);
      } catch (e) {
        stopAllStreams();
        closePC();
        return;
      }

      const sdpResponse = await axios.post(
        process.env.REACT_APP_BASE_API_URL + "/streaming/connect/sdp",
        {
          streamId: streamIdRef.current,
          answer: sessionClientAnswer,
          sessionId: sessionIdRef.current,
        },
        { headers: { "x-personality-id": personalityId } }
      );
    } catch (e) {
      console.log(e);
      toast.error(
        "Hyper-realistic avatar is currently unavailable. Please try again later.",
        {
          autoClose: false,
          closeButton: true, // Display close button (cross icon)
        }
      );
      onFail();
    }
  };

  const startStream = async () => {
    if (
      peerConnectionRef.current?.signalingState === "stable" ||
      peerConnectionRef.current?.iceConnectionState === "connected"
    ) {
      await axios.post(
        process.env.REACT_APP_BASE_API_URL + "/streaming/stream",
        {
          text: playTextAvatar.replace(emojiRegex, ""),
          streamId: streamIdRef.current,
          sessionId: sessionIdRef.current,
        },
        { headers: { "x-personality-id": personalityId } }
      );
      setPlayTextAvatar(null);
    }
  };

  const destroySession = async () => {
    // await fetch(`${DID_API.url}/talks/streams/${streamId}`, {
    //     method: 'DELETE',
    //     headers: {
    //         Authorization: `Basic ${DID_API.key}`,
    //         'Content-Type': 'application/json',
    //     },
    //     body: JSON.stringify({ session_id: sessionId }),
    // });

    stopAllStreams();
    closePC();
  };

  const onIceGatheringStateChange = () => {
    if (peerConnectionRef.current) {
    }
  };

  const onIceCandidate = (event: RTCPeerConnectionIceEvent) => {
    if (event.candidate) {
      const { candidate, sdpMid, sdpMLineIndex } = event.candidate;

      axios
        .post(
          process.env.REACT_APP_BASE_API_URL + "/streaming/connect/ice",
          {
            candidate,
            sdpMid,
            sdpMLineIndex,
            sessionId: sessionIdRef.current,
            streamId: streamIdRef.current,
          },
          { headers: { "x-personality-id": personalityId } }
        )
        .then((resp) => {
          // handle candidate error here
        });
    }
  };

  const onIceConnectionStateChange = () => {
    if (peerConnectionRef.current) {
      if (
        peerConnectionRef.current.iceConnectionState === "failed" ||
        peerConnectionRef.current.iceConnectionState === "closed"
      ) {
        stopAllStreams();
        closePC();
      }
      if (peerConnectionRef.current.iceConnectionState === "connected") {
        // toast.success("Avatar is Ready")
        setAvatarIsReady(true);
        setConnectingStatus(2)
        dataLayerPush(GTMEvent._2DAvatarOnSuccessful)
      }
    }
  };

  const onConnectionStateChange = () => {
    if (peerConnectionRef.current) {
    }
  };

  const onSignalingStateChange = () => {
    if (peerConnectionRef.current) {
    }
  };

  const onVideoStatusChange = (
    videoIsPlaying: boolean,
    stream: MediaStream
  ) => {
    let status;
    if (videoIsPlaying) {
      status = "streaming";
      setVideoElement(stream);
    } else {
      status = "empty";
      playIdleVideo();
      setPauseListening(false)
    }
  };

  const onTrack = (event: RTCTrackEvent) => {
    if (!event.track) return;
    // if(statsIntervalIdRef.current)return;
    let interval = setInterval(async () => {
      const stats = await peerConnectionRef.current!.getStats(event.track);
      stats.forEach((report: any) => {
        if (report.type === "inbound-rtp" && report.mediaType === "video") {
          const videoStatusChanged =
            videoIsPlaying !== report.bytesReceived > lastBytesReceived;

          if (videoStatusChanged) {
            videoIsPlaying = report.bytesReceived > lastBytesReceived;
            onVideoStatusChange(videoIsPlaying, event.streams[0]);
          }
          lastBytesReceived = report.bytesReceived;
        }
      });
    }, 500);
    statsIntervalIdsRef.current = [...statsIntervalIdsRef.current, interval];
  };

  const createPeerConnection = async (
    offer: RTCSessionDescriptionInit,
    iceServers: RTCIceServer[]
  ) => {
    if (!peerConnectionRef.current) {
      peerConnectionRef.current = new RTCPeerConnection({ iceServers });
      peerConnectionRef.current.addEventListener(
        "icegatheringstatechange",
        onIceGatheringStateChange,
        true
      );
      peerConnectionRef.current.addEventListener(
        "icecandidate",
        onIceCandidate,
        true
      );
      peerConnectionRef.current.addEventListener(
        "iceconnectionstatechange",
        onIceConnectionStateChange,
        true
      );
      peerConnectionRef.current.addEventListener(
        "connectionstatechange",
        onConnectionStateChange,
        true
      );
      peerConnectionRef.current.addEventListener(
        "signalingstatechange",
        onSignalingStateChange,
        true
      );
      peerConnectionRef.current.addEventListener("track", onTrack, true);
    }

    await peerConnectionRef.current.setRemoteDescription(offer);

    const sessionClientAnswer = await peerConnectionRef.current.createAnswer();

    await peerConnectionRef.current.setLocalDescription(sessionClientAnswer);

    return sessionClientAnswer;
  };

  const setVideoElement = (stream: MediaStream | null) => {
    if (!stream) return;
    // setDisplayImage(false)
    setAnimated(false);
    const talkVideo = talkVideoRef.current;
    if (talkVideo) {
      talkVideo.srcObject = stream;
      talkVideo.loop = false;

      if (talkVideo.paused) {
        talkVideo
          .play()
          .then((_) => { })
          .catch((e) => { });
      }
    }
  };

  const playIdleVideo = () => {
    setAnimated(true);
    // if(personalityInView?.personalityJson?.hyper_realistic_avatar?.animation_url){
    //     const talkVideo = talkVideoRef.current;
    //     if (talkVideo) {
    //         talkVideo.srcObject = null;
    //         talkVideo.src = personalityInView?.personalityJson?.hyper_realistic_avatar?.animation_url;
    //         talkVideo.loop = true;
    //     }
    // }
  };

  const stopAllStreams = () => {
    const talkVideo = talkVideoRef.current;
    if (talkVideo && talkVideo.srcObject instanceof MediaStream) {
      talkVideo.srcObject.getTracks().forEach((track) => track.stop());
      talkVideo.srcObject = null;
    }
  };

  const closePC = (
    pc: RTCPeerConnection | null = peerConnectionRef.current
  ) => {
    if (!pc) return;
    pc.close();
    pc.removeEventListener(
      "icegatheringstatechange",
      onIceGatheringStateChange,
      true
    );
    pc.removeEventListener("icecandidate", onIceCandidate, true);
    pc.removeEventListener(
      "iceconnectionstatechange",
      onIceConnectionStateChange,
      true
    );
    pc.removeEventListener(
      "connectionstatechange",
      onConnectionStateChange,
      true
    );
    pc.removeEventListener(
      "signalingstatechange",
      onSignalingStateChange,
      true
    );
    pc.removeEventListener("track", onTrack, true);
    clearAllInvervals();
    if (pc === peerConnectionRef.current) {
      peerConnectionRef.current = null;
    }
  };

  const clearAllInvervals = () => {
    statsIntervalIdsRef.current.forEach((element) => {
      clearInterval(element);
    });
  };

  useEffect(() => {
    if (playTextAvatar) {
      startStream();
    }
  }, [playTextAvatar]);

  useEffect(() => {
    if (peerConnectionRef?.current?.connectionState === "failed") {
      // try reconnect session if session connection failed
      setTimeout(() => {
        connectSession();
      }, 5000);
    }
  }, [peerConnectionRef?.current?.connectionState]);

  useEffect(() => {
    // on load call connection
    connectSession();

    return () => {
      destroySession();
      clearAllInvervals();
    };
  }, []);

  return (
    <div
      className="flex relative justify-center items-center w-[auto] mx-auto mb-[14px] rounded-lg overflow-hidden"
      style={{ height: "calc(100% - 40px)", aspectRatio: "1 / 1" }}
    >
      {/* {!avatarIsReady && (connectingStatus === 1) && (
        <div
          style={loaderCss}
          className={clsx("loading-spinner-avatar h-10 w-10 absolute z-20")}
        ></div>
      )} */}
      {personalityInView?.personalityJson?.hyper_realistic_avatar
        ?.animation_url ? (
        <video
          src={
            personalityInView?.personalityJson?.hyper_realistic_avatar
              ?.animation_url
          }
          className={clsx(
            " h-[100%] rounded-lg box-border z-10 h-full object-cover",
            !animated && "hidden",
          )}
          style={{ objectPosition: "top center" }}
          autoPlay
          loop
        ></video>
      ) : (
        <img
          alt="avatar hyper realistic"
          src={getCdnUrl(
            personalityInView?.personalityJson?.hyper_realistic_avatar?.image
          )}
          className={clsx(
            " h-[100%] rounded-lg box-border z-10",
            !animated && "hidden",
          )}
        />
      )}
      <video
        ref={talkVideoRef}
        id="talk-video"
        className={clsx(
          " h-[100%] rounded-lg box-border z-10 h-full",
          animated && "hidden"
        )}
        autoPlay
      ></video>
      {
        connectingStatus === 1 &&
        <div className="absolute bottom-0 w-full z-10 p-[6px] flex flex-row items-center justify-center bg-[#2E2F45] text-[13px] leading-[20px] text-[#C2D24B]">
          {formatMessage({ id: "Connecting" })}
          <span className=" animate-spin ml-3">
            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
              <mask id="mask0_8328_154218" style={{ maskType: "alpha" }} maskUnits="userSpaceOnUse" x="2" y="2" width="16" height="16">
                <circle cx="10" cy="10" r="7.25" stroke="#C2D24B" strokeWidth="1.5" />
              </mask>
              <g mask="url(#mask0_8328_154218)">
                <path d="M18 12.5L13 11L13.5 18.5L10.5 19.5L3.5 17L1 10L2 2.5L13 1L18 3.5L19 11L18 12.5Z" fill="#C2D24B" />
              </g>
            </svg>
          </span>
        </div>
      }
      {
        connectingStatus === 3 &&
        <div className="absolute bottom-0 w-full z-10 p-[6px] flex flex-row items-center justify-center bg-[#2E2F45] text-[13px] leading-[20px] text-[#D24B4B] cursor-pointer"
          onClick={connectSession}
        >
          {formatMessage({ id: "Failed Try again" })}
          <span className="ml-3">
            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
              <g clipPath="url(#clip0_8328_154256)">
                <path d="M15.6499 4.35179C14.4358 3.13403 12.8617 2.33985 11.1608 2.08698C9.45995 1.83411 7.72287 2.13601 6.20706 2.94793C4.69125 3.75984 3.47739 5.03856 2.7454 6.59454C2.01342 8.15053 1.80226 9.90095 2.14324 11.5864C2.48422 13.2718 3.35919 14.8025 4.63844 15.9516C5.9177 17.1006 7.53315 17.807 9.24536 17.9658C10.9576 18.1246 12.6754 17.7276 14.1442 16.8334C15.613 15.9393 16.7546 14.5957 17.3999 13.0018H15.1799C14.5618 14.0935 13.617 14.964 12.4785 15.4909C11.34 16.0178 10.0649 16.1745 8.8327 15.9391C7.60045 15.7037 6.47299 15.088 5.60887 14.1785C4.74475 13.269 4.18745 12.1115 4.01533 10.8689C3.84322 9.6262 4.06495 8.36084 4.64935 7.25074C5.23375 6.14063 6.15141 5.24163 7.27328 4.68016C8.39516 4.11869 9.66481 3.923 10.9037 4.1206C12.1426 4.31821 13.2883 4.89917 14.1799 5.78179L10.9999 9.00179H17.9999V2.00179L15.6499 4.35179Z" fill="#D24B4B" />
              </g>
              <defs>
                <clipPath id="clip0_8328_154256">
                  <rect width="20" height="20" fill="white" />
                </clipPath>
              </defs>
            </svg>

          </span>
        </div>
      }
    </div>
  );
};

export default HyperRealisticAvatar;
