import React, { useEffect, useRef } from "react";
import * as echarts from "echarts";
import { get, isEqual } from "lodash";
import { useThemeMode } from "../../../../../_metronic/partials";
// @ts-ignore
import useChartResizer from "../../../../hooks/useChartResizer";
import moment from "moment";
const Index = ({}) => {
  const { mode } = useThemeMode();
  let option = {
    grid:{
      left:0,
      right:0,
      top:0,
      bottom:0,
    },
    series: [
      {
        name: 'Pressure',
        type: 'gauge',
        radius: '80%',
        center: ['50%', '50%'] ,
        label:{
          show:false
        },
        detail: {
          show:false,
        },
        axisLine: {
          roundCap:true,
show:true,
          lineStyle: {
            width: 12,
            color: [
              [0, '#282b28'],
              [1, '#282b28']
            ],
          },

        },
        itemStyle:{
          // borderCap:"round"
        },
        progress:{
          show:true,
          roundCap:[true,false],
          width: 12,
          itemStyle:{
            width: 15,
            color:"#C2D24B",
            zlevel:1,
          },
        },
        anchor:{
          show:false,
        },
        axisTick: {
          show: false // Hide the tick marks
        },
        axisLabel:{
          show: true,
          distance:-40,
          color: isEqual(mode, "light") ? "#5E6278" : "#FFFFFFCC",
          formatter: (value:any)=>isEqual(value,100) ? `${value}%`: value,
          fontWeight:500,
        },
        splitLine: {
          show: false // Hide the split lines
        },
        splitNumber:1,
        pointer: {
          show: true,
          icon:'circle',
          showAbove: true,
          offsetCenter: [10, '-63.5%'],
          width:20,
          itemStyle: {
            color: "#fff",
            shadowColor: 'rgba(10, 31, 68, 0.5)',
            zlevel:1000,
            shadowBlur: 2,
            shadowOffsetY: 1,
          }
        },
        data: [{ value: 80 }],
        zlevel:2,
      },
      {
        name: 'Pressure',
        type: 'gauge',
        radius: '80%',
        center: ['50%', '50%'] ,
        // markPoint:{
        //   symbol:"circle"
        // },
        label:{
          show:false
        },
        detail: {
          valueAnimation: true,
          fontSize: 26,
          lineHeight:34,
          fontWeight:500,
          offsetCenter: [0, 0],
          color: isEqual(mode, "light") ? "#5E6278" : "#FFFFFFCC",
          formatter:'{value}%'
        },
        axisLine: {
          roundCap:true,
          show:false,
          lineStyle: {
            width: 12,
            color: [
              [0, '#282b28'],
              [1, '#282b28']
            ],
          },

        },
        itemStyle:{
          // borderCap:"round"
        },
        progress:{
          show:false,
          roundCap:[true,false],
          width: 12,
          itemStyle:{
            width: 15,
            color:"#C2D24B",
            zlevel:1,
          },
        },
        anchor:{
          show:false,
        },
        axisTick: {
          show: false // Hide the tick marks
        },
        axisLabel:{
          show: true,
          distance:-40,
          color: isEqual(mode, "light") ? "#5E6278" : "#FFFFFFCC",
          formatter: (value:any)=>isEqual(value,100) ? `${value}%`: value,
          fontWeight:500,
        },
        splitLine: {
          show: false // Hide the split lines
        },
        splitNumber:1,
        pointer: {
          show: true,
          icon:'circle',
          showAbove: true,
          offsetCenter: [10, '-63.5%'],
          width:20,
          itemStyle: {
            color: "#fff",
            shadowColor: 'rgba(10, 31, 68, 0.5)',
            zlevel:1000,
            shadowBlur: 2,
            shadowOffsetY: 1,
          }
        },
        data: [{ value: 80 }],
        zlevel: 3,
      },
    ]
  };
  const chartRef = useRef();
  useChartResizer(chartRef);
  useEffect(() => {
    const chart = echarts.init(chartRef.current, null);
    // @ts-ignore
    chart.setOption(option);
  }, [option]);

  // @ts-ignore
  return <div className={"w-full h-full"} ref={chartRef}></div>
  ;
};

export default Index;
