import * as Yup from "yup";
import { useState } from "react";
import { Modal } from "react-bootstrap";
import { useIntl } from "react-intl";
import { Link } from "react-router-dom";
import Lottie from "lottie-react";
import processingJson from "../../../assets/lotties/processing-animation.json";
import { Formik } from "formik";
import TextInput from "../../widgets/components/Input/TextInput";
import { CustomButton } from "../../widgets/components/UI/CustomButton";
import { BasicButton } from "../../widgets/components/UI/BasicButton";
import crossIcon from "../../../../_metronic/assets/images/svg/cross-dual-green-icon.svg";
import { useAuth } from "../../auth";
import { subscriptionCouponClaim } from "../../profile/core/_requests";
import { toast } from "react-toastify";
import { SUBSCRIPTION_COUPON_MODAL_LEARN_MORE_LINK } from "../../../core/_constants";
export default function ClaimCouponModal({ modalShow, setModalShow, getBillingDetails }: any) {
    const { formatMessage } = useIntl();
    const [loading, setLoading] = useState<boolean>(false)
    const { selectedPersonality, setBillingData } = useAuth()
    const couponSchema = Yup.object().shape({
        couponCode: Yup.string().required().label("Coupon ID")
    })
    return <Modal
        show={modalShow}
        onHide={() => setModalShow(false)}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        size="lg"
    >

        <Modal.Header closeButton>
            <Modal.Title
                className="modal-title text-[20px] leading-[28px] font-weight-600 primary-text"
                id="contained-modal-title-vcenter"
            >
                {formatMessage({ id: "Claim Subscription" })}
            </Modal.Title>
            <img src={crossIcon} alt="" onClick={() => setModalShow(false)}></img>
        </Modal.Header>
        <Modal.Body>
            {!loading ? <>
                <div
                    className={
                        "bg-[#C2D24B1A] border !border-[#C2D24B40] border-dashed rounded  mx-auto p-[14px] mb-6"
                    }
                >
                    <div className={"flex gap-x-2"}>
                        <div>
                            <svg
                                width="32"
                                height="32"
                                viewBox="0 0 32 32"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    d="M15.9993 29.3327C8.63535 29.3327 2.66602 23.3633 2.66602 15.9993C2.66602 8.63535 8.63535 2.66602 15.9993 2.66602C23.3633 2.66602 29.3327 8.63535 29.3327 15.9993C29.3327 23.3633 23.3633 29.3327 15.9993 29.3327ZM15.9993 26.666C18.8283 26.666 21.5414 25.5422 23.5418 23.5418C25.5422 21.5414 26.666 18.8283 26.666 15.9993C26.666 13.1704 25.5422 10.4573 23.5418 8.45688C21.5414 6.45649 18.8283 5.33268 15.9993 5.33268C13.1704 5.33268 10.4573 6.45649 8.45688 8.45688C6.45649 10.4573 5.33268 13.1704 5.33268 15.9993C5.33268 18.8283 6.45649 21.5414 8.45688 23.5418C10.4573 25.5422 13.1704 26.666 15.9993 26.666ZM14.666 9.33268H17.3327V11.9993H14.666V9.33268ZM14.666 14.666H17.3327V22.666H14.666V14.666Z"
                                    fill="#C2D24B"
                                    fillOpacity="0.65"
                                />
                            </svg>
                        </div>
                        <div>
                            <p>
                                {formatMessage({ id: "If you've acquired a subscription via a third-party or affiliate partner, or through an offline transaction with a local vendor, you can activate your subscription here. Simply enter your Claim ID or Coupon ID, and your existing subscription will automatically upgrade to reflect your new purchase. No further action is needed on your part." })}
                            </p>
                            <div className={"flex justify-end mt-1"}>
                                <a
                                    href={SUBSCRIPTION_COUPON_MODAL_LEARN_MORE_LINK}
                                    target="_blank"
                                    className={
                                        "text-[#C2D24B] font-medium flex items-center gap-x-3.5"
                                    }
                                >
                                    {formatMessage({id:"Learn More"})}
                                    <svg
                                        width="16"
                                        height="16"
                                        viewBox="0 0 16 16"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path
                                            opacity="0.3"
                                            d="M4.00065 7.33268C3.63246 7.33268 3.33398 7.63116 3.33398 7.99935C3.33398 8.36754 3.63246 8.66602 4.00065 8.66602H12.0007C12.3688 8.66602 12.6673 8.36754 12.6673 7.99935C12.6673 7.63116 12.3688 7.33268 12.0007 7.33268H4.00065Z"
                                            fill="#C2D24B"
                                        />
                                        <path
                                            d="M7.52794 11.5279C7.26759 11.7883 7.26759 12.2104 7.52794 12.4708C7.78829 12.7311 8.2104 12.7311 8.47075 12.4708L12.4708 8.47075C12.7231 8.21837 12.732 7.81198 12.4908 7.54887L8.82412 3.54887C8.57532 3.27746 8.15361 3.25912 7.8822 3.50791C7.61079 3.75671 7.59245 4.17842 7.84125 4.44983L11.0766 7.9793L7.52794 11.5279Z"
                                            fill="#C2D24B"
                                        />
                                    </svg>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
                <Formik
                    enableReinitialize
                    validateOnMount
                    initialValues={{ couponCode: "" }}
                    validationSchema={couponSchema}
                    onSubmit={async (values, { setSubmitting, setStatus }) => {
                        if (!selectedPersonality) {
                            return
                        }
                        setLoading(true);
                        const { data: { success,data, errors } } = await subscriptionCouponClaim(selectedPersonality.personalityId, values.couponCode)
                        if(success){
                            toast.success(formatMessage({id:"Coupon Claimed Successfully"}))
                            setBillingData(await getBillingDetails!());
                        }else{
                            toast.error(errors[0], {
                                autoClose: false,
                                closeButton: true,
                            })
                        }
                        setLoading(false);
                    }}
                >
                    {(formik) => (
                        <form>
                            <TextInput
                                label={"Claim ID or Coupon ID"}
                                toolTipText={formatMessage({
                                    id: "Enter your Claim ID or Coupon ID here",
                                })}
                                isDisabled={false}
                                fieldName={"couponCode"}
                                placeholder={formatMessage({
                                    id: "Enter your Claim ID or Coupon ID here",
                                })}
                                fieldType={"text"}
                                formik={formik}
                            />
                            <Modal.Footer>
                                <BasicButton
                                    buttonText={formatMessage({ id: "Cancel" })}
                                    border="none"
                                    color="#C2D24B1A"
                                    textColor="#C2D24B"
                                    customClass={"px-[24px]"}
                                    height="44px"
                                    onClick={() => {
                                        formik.resetForm();
                                        setModalShow(false)
                                    }}
                                />
                                <CustomButton
                                    isSubmitting={formik.isSubmitting}
                                    isValid={formik.isValid}
                                    buttonText={formatMessage({id:"Claim Now"})}
                                    loading={formik.isSubmitting}
                                    height={44}
                                    widthLoading={1}
                                    width={1}
                                    onSubmit={formik.handleSubmit}
                                />
                            </Modal.Footer>
                        </form>

                    )}
                </Formik>
            </> :
                <div className="flex-column d-flex align-items-center">
                    <Lottie
                        className=""
                        animationData={processingJson}
                        style={{
                            width: 185,
                            height: 145,
                            borderRadius: 25,
                            // borderTopLeftRadius: 0,
                            // overflow: "hidden",
                        }}
                    />
                    <h4 className="text-[22px] mt-[20px] mb-[8px] leading-[32px] font-weight-600 primary-text">
                        {formatMessage({
                            id: "Processing...",
                        })}
                    </h4>
                    <p className="text-[13px] leading-[20px] font-weight-400 secondary-text">
                        {formatMessage({
                            id: "We are processing your request...",
                        })}
                    </p>
                </div>}
        </Modal.Body>

    </Modal>
}
