import axios from "axios"
import { ResponseType } from "./_models";
const API_URL = process.env.REACT_APP_BASE_API_URL;

export const POST_URL = `${API_URL}/posts`
export const IMAGE_GENERATOR_URL = `${API_URL}/image`


export const addNewPost = async(personalityId:string, image:string, content:string)=>{
    return await axios.post<ResponseType<any>>(POST_URL,{
        image,
        content
    },{
        validateStatus(status) {
          return true;
        },
      headers:{
        'x-personality-id':personalityId
      }})
}


export const getAllPosts = async(personalityId:string, page:number)=>{
    return await axios.get<ResponseType<any>>(`${POST_URL}?page=${page}`,{
        validateStatus(status) {
          return true;
        },
      headers:{
        'x-personality-id':personalityId
      }})
}

export const getFeedPosts = async(page:number)=>{
    return await axios.get<ResponseType<any>>(`${POST_URL}/feed?page=${page}`,{
        validateStatus(status) {
          return true;
        }})
}

export const likePost = async(postId:string)=>{
  return await axios.post<ResponseType<string>>(`${POST_URL}/${postId}/like`,null,{
      validateStatus(status) {
        return true;
      }})
}

export const unlikePost = async(postId:string)=>{
  return await axios.delete<ResponseType<string>>(`${POST_URL}/${postId}/unlike`,{
      validateStatus(status) {
        return true;
      }})
}

export const deletePost = async(postId:string)=>{
  return await axios.delete<ResponseType<string>>(`${POST_URL}/${postId}`,{
      validateStatus(status) {
        return true;
      }})
}


export const generateChatImage = async(messages:any[], original:boolean)=>{
  return await axios.post(IMAGE_GENERATOR_URL,{
    messages,
    original
  },{
    validateStatus(status) {
      return true;
    }
  })
}


