import React, { useState } from "react";
import Breadcrumb from "./components/breadcrumb";

import Chat from "./components/chat";

import { get, map } from "lodash";
import Accordion from "./components/accordion";
import CustomLink from "../../components/custom-link";
import { InfoCard } from "../widgets/components/UI/InfoCard";
import { useIntl } from "react-intl";
import { FullModal } from "../../components/modal";
import EditProfile from "./components/edit-profile";
import { useLocation, useNavigate } from "react-router-dom";
import { TabList, Tabs, Tab, TabPanel } from "react-tabs";
// import { FAQs } from "./FAQs";
import { useAuth } from "../auth";
import PostContainer from "./components/post";
const AiPersonalityPage = () => {
  const [expanded, setExpanded] = useState<false | number>(0);
  const { formatMessage } = useIntl();
  const [openEdit, setOpenEdit] = useState<boolean>(false);
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { selectedPersonality } = useAuth();
  const FAQs = [
    {
      question: `${formatMessage({ id: "What is an AI Personality?" })}`,
      answer: `${formatMessage({
        id: "An AI Personality is a digital entity powered by advanced AI algorithms that can simulate human-like conversations and behaviors.",
      })}`,
    },
    {
      question: `${formatMessage({
        id: "How can I change the traits & behavior of my AI personality?",
      })}`,
      answer: `${formatMessage({
        id: "You can change your AI personality's traits & behavior by clicking on “Edit AI Personality” on the left panel. You can also utilize the training options like chat, public links, or private data.",
      })}`,
    },
    {
      question: `${formatMessage({
        id: "How can I train my AI personality?",
      })}`,
      answer: `${formatMessage({
        id: "You can train your AI personality in Kamoto.AI through various methods like chat, public links, or private data to customize its behavior and enhance its capabilities.",
      })}`,
    },

    {
      question: `${formatMessage({
        id: "How to Monetize my AI Personality via Marketplace?",
      })}`,
      answer: `${formatMessage({
        id: "Monetize your AI personality in Kamoto.AI's Marketplace by allowing visitors to chat or talk with it and charging them on a per-minute basis according to your set rates.",
      })}`,
    },
  ];

  return (
    <div className="md:px-5 px-3 py-3">
      <Breadcrumb />
      {/* <div className={"flex md:justify-end gap-[8px] mt-[14px] md:mt-0"}>
        <button
          className={
            "main-button flex-grow md:max-w-[146px] font-medium rounded-[6px] text-[13px] leading-[20px]"
          }
          onClick={() => setOpenEdit(true)}
        >
          {formatMessage({ id: "Edit Personality" })}
        </button>
        <button className={"menu-bg p-[6px] rounded-[6px]"}>
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g clipPath="url(#clip0_344_27955)">
              <path
                d="M12 3C10.9 3 10 3.9 10 5C10 6.1 10.9 7 12 7C13.1 7 14 6.1 14 5C14 3.9 13.1 3 12 3ZM12 17C10.9 17 10 17.9 10 19C10 20.1 10.9 21 12 21C13.1 21 14 20.1 14 19C14 17.9 13.1 17 12 17ZM12 10C10.9 10 10 10.9 10 12C10 13.1 10.9 14 12 14C13.1 14 14 13.1 14 12C14 10.9 13.1 10 12 10Z"
                className={"icon"}
                fillOpacity="0.65"
              />
            </g>
            <defs>
              <clipPath id="clip0_344_27955">
                <rect width="24" height="24" className={"icon"} />
              </clipPath>
            </defs>
          </svg>
        </button>
      </div> */}
      <Tabs
        // activeTab={1}
        className="font-size-13 mt-4"
        selectedTabClassName="bg-primary main-text"
      >
        <TabList
          className={
            "text-[13px] leading-[20px] font-normal primary-text setting-tabs overflow-x-auto overflow-y-hidden whitespace-nowrap  border-main border-b"
          }
          onSelect={(value) => {
            console.log(value);
          }}
        >
          <Tab>{formatMessage({ id: "Chat" })}</Tab>
        </TabList>
        <TabPanel>
          <div className="grid grid-cols-12 gap-[24px] tab-container rounded-[4px]  md:p-[24px] mt-4">
            <div
              className={
                "col-span-12 md:col-span-7 lg:col-span-8 primary-bg rounded-lg px-[8px] md:pl-[16px] md:pr-[4px]"
              }
            >
              <Chat currentPersonality={selectedPersonality} />
            </div>
            <div
              className={
                "col-span-12 md:col-span-5 lg:col-span-4 primary-bg rounded-lg py-[20px] px-[16px]"
              }
            >
              <div className={"secondary-text flex flex-col h-full"}>
                <h3
                  className={
                    "text-[20px] leading-[28px] secondary-text font-semibold mb-[18px]"
                  }
                >
                  {formatMessage({ id: "FAQs on AI Personality" })}
                </h3>
                <div className={"flex-grow flex flex-col justify-between"}>
                  <ul className={"list-type-none"}>
                    {map(FAQs, (item, index) => (
                      <li className={"border-main border-b py-[12px]"}>
                        <Accordion
                          expanded={expanded}
                          index={index}
                          setExpanded={setExpanded}
                          title={get(item, "question")}
                          paragraph={get(item, "answer")}
                        />
                      </li>
                    ))}
                  </ul>
                  <div className={"flex justify-end mt-5"}>
                    <a
                      href="https://help.kamoto.ai/en/collections/4038290-faqs"
                      target="_blank"
                      rel="noreferrer"
                      className="flex items-center gap-x-[8px] main-text text-[12px] font-medium leading-[18px]"
                    >
                      {formatMessage({ id: "Learn More" })}

                      <svg
                        width="16"
                        height="16"
                        viewBox="0 0 16 16"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          opacity="0.3"
                          d="M4.00065 7.33268C3.63246 7.33268 3.33398 7.63116 3.33398 7.99935C3.33398 8.36754 3.63246 8.66602 4.00065 8.66602H12.0007C12.3688 8.66602 12.6673 8.36754 12.6673 7.99935C12.6673 7.63116 12.3688 7.33268 12.0007 7.33268H4.00065Z"
                          fill="#C2D24B"
                        />
                        <path
                          d="M7.52794 11.5279C7.26759 11.7883 7.26759 12.2104 7.52794 12.4708C7.78829 12.7311 8.2104 12.7311 8.47075 12.4708L12.4708 8.47075C12.7231 8.21837 12.732 7.81198 12.4908 7.54887L8.82412 3.54887C8.57532 3.27746 8.15361 3.25912 7.8822 3.50791C7.61079 3.75671 7.59245 4.17842 7.84125 4.44983L11.0766 7.9793L7.52794 11.5279Z"
                          fill="#C2D24B"
                        />
                      </svg>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </TabPanel>
      </Tabs>

      <FullModal open={openEdit} setOpen={setOpenEdit}>
        <EditProfile setOpenEdit={setOpenEdit} />
      </FullModal>
    </div>
  );
};

export { AiPersonalityPage };
