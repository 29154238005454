import React, { useEffect, useState } from "react";
import profileImage from "../../../../../assets/images/profile/profile-image.png";
import Countdown from "react-countdown";
import Switch from "react-switch";
import { getCdnUrl } from "../../../../../core/_util";
import { useAuth } from "../../../../auth";
import {
  closeChatSession,
  createChatSession,
} from "../../../../apps/chat/core/_request";
import { toast } from "react-toastify";
import { SESSION_ERRORS } from "../../../../../core/_constants";
import useTimer from "../../../../../hooks/useTimer";
import { useIntl } from "react-intl";
import useGTM from "../../../../../hooks/useGTM";
import { GTMEvent } from "../../../../../hooks/gtm_helpers";
import {useThemeMode} from "../../../../../../_metronic/partials";
import clsx from "clsx";
import { isEqual } from "lodash";

const Index: React.FC<any> = ({
  isSubscribed,
  setBackground,
  hasBackground,
  personalityImage,
  userImage,
  startChat,
  setStartChat,
  insufficient,
  setOpenSubscribeModal,
  onSessionEnd,
  showTimer,
  createSessionCheck,
  isDark = false,
}) => {
  const { formatMessage } = useIntl();
  const {dataLayerPush} = useGTM()
  const { mode } = useThemeMode();

  const {
    personalityInView,
    currentChatSession,
    setCurrentChatSession,
    currentUser,
    setIsAvatarEnabled
  } = useAuth();
  const { Timer, startTimer, stopTimer } = useTimer();
  const [loading, setLoading] = useState(false);
  const has3d = personalityInView?.personalityJson?.avatar?.enabled || false;
  const hasHyper =
    personalityInView?.personalityJson?.hyper_realistic_avatar?.enabled ||
    false;

  async function handleClick() {
    setLoading(true);
    if (currentChatSession) {
      const {
        data: { success, data, errors },
      } = await closeChatSession(currentChatSession.id, "CLOSED_BY_USER");
      if (success) {
        setCurrentChatSession(null);
        onSessionEnd(success, data, errors);
        dataLayerPush(GTMEvent.ChatSessionEndSuccessful)
      }else{
        dataLayerPush(GTMEvent.ChatSessionEndFailed)
      }
    } else {
      let val = await createSessionCheck();
      if (!val) {
        setLoading(false);
        return;
      }
      const {
        data: { success, data, errors },
      } = await createChatSession(personalityInView?.personalityId);
      if (success) {
        setCurrentChatSession(data);
        dataLayerPush(GTMEvent.ChatSessionStartSuccessful)
      } else {
        toast.error(
          errors[0]
            ? SESSION_ERRORS[errors[0]] || "Cannot start chat at the moment"
            : "Cannot start chat at the moment",
            {
              autoClose: false,
              closeButton: true, // Display close button (cross icon)
            }
        );
        dataLayerPush(GTMEvent.ChatSessionStartFailed)
      }
    }
    setLoading(false);
  }

  useEffect(() => {
    if (currentChatSession?.startedAt) {
      let value = Math.floor(
        (new Date().valueOf() -
          new Date(currentChatSession.startedAt).valueOf()) /
          1000
      );
      startTimer(value);
      value && setStartChat(true);
    } else {
      stopTimer();
    }
  }, [currentChatSession]);

  useEffect(() => {
    return () => {
      setLoading(false);
    };
  }, [personalityInView]);

  return (
    <div
      className={
        "col-span-12 flex flex-col xl:flex-row  justify-between px-[10px] mt-[14px] mb-[10px] md:my-[16px] gap-[14px]"
      }
    >
      <div
        className={"flex gap-[6px]  md:gap-[10px] items-start  md:items-center"}
      >
        <div className={" "}>
          <div className={"relative flex flex-row items-end"}>
            <div
              className={
                "bg-[#2E2F45] rounded-full overflow-hidden object-cover w-[34px] h-[34px]"
              }
            >
              <img
                src={personalityImage}
                className={"w-full h-full object-cover object-top "}
                alt={"Profile"}
              />
            </div>{" "}
            <div
              className={
                "bg-[#2E2F45] rounded-full ml-[-10px] overflow-hidden  w-[34px] h-[34px]"
              }
            >
              <img
                src={userImage}
                className={"w-full h-full"}
                alt={"Profile"}
              />
            </div>{" "}
            <div
              className={
                "bg-[#2E2F45] shadow-default w-4 h-4 flex items-center justify-center rounded-full absolute  left-[22px]"
              }
            >
              <svg
                width="12"
                height="12"
                viewBox="0 0 12 12"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g clipPath="url(#clip0_497_20153)">
                  <path
                    d="M3.90446 4.98551H7.60941M3.90446 6.83799H6.68317M8.53565 2.66992C8.90413 2.66992 9.25751 2.8163 9.51807 3.07685C9.77862 3.33741 9.925 3.6908 9.925 4.05928V7.76423C9.925 8.13271 9.77862 8.48609 9.51807 8.74665C9.25751 9.0072 8.90413 9.15358 8.53565 9.15358H6.22005L3.90446 10.5429V9.15358H2.97822C2.60974 9.15358 2.25635 9.0072 1.9958 8.74665C1.73525 8.48609 1.58887 8.13271 1.58887 7.76423V4.05928C1.58887 3.6908 1.73525 3.33741 1.9958 3.07685C2.25635 2.8163 2.60974 2.66992 2.97822 2.66992H8.53565Z"
                    stroke="#C2D24B"
                    strokeWidth="0.513817"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_497_20153">
                    <rect
                      width="11.1148"
                      height="11.1148"
                      fill="white"
                      transform="translate(0.199707 0.818359)"
                    />
                  </clipPath>
                </defs>
              </svg>
            </div>
          </div>
        </div>
        <h3
          className={clsx(isEqual(mode,'light') ? 'text-black' : "text-white" , "text-[14px] primary-text lg:text-[20px] leading-[20px] md:leading-8 font-semibold" )
          }
        >
          {`${personalityInView?.name || ""}’s ${
            personalityInView?.isLicensed
              ? formatMessage({ id: "Licensed" })
              : formatMessage({ id: "Unlicensed" })
          } ${formatMessage({ id: "AI Personality" })}`}
        </h3>
      </div>
      <div className={"flex items-center gap-x-6 justify-end  "}>
        {startChat && showTimer && currentChatSession && (
          <button
            className={
              "secondary-bg rounded flex gap-x-2.5 items-center justify-center py-3 w-32"
            }
          >
            <svg
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g clipPath="url(#clip0_952_24449)">
                <path
                  d="M2 8C2 8.78793 2.15519 9.56815 2.45672 10.2961C2.75825 11.0241 3.20021 11.6855 3.75736 12.2426C4.31451 12.7998 4.97595 13.2417 5.7039 13.5433C6.43185 13.8448 7.21207 14 8 14C8.78793 14 9.56815 13.8448 10.2961 13.5433C11.0241 13.2417 11.6855 12.7998 12.2426 12.2426C12.7998 11.6855 13.2417 11.0241 13.5433 10.2961C13.8448 9.56815 14 8.78793 14 8C14 6.4087 13.3679 4.88258 12.2426 3.75736C11.1174 2.63214 9.5913 2 8 2C6.4087 2 4.88258 2.63214 3.75736 3.75736C2.63214 4.88258 2 6.4087 2 8Z"
                  className={"icon-timer"}
                  strokeWidth="1.4"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M8 4.66602V7.99935L10 9.99935"
                  className={"icon-timer"}
                  strokeWidth="1.4"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </g>
              <defs>
                <clipPath id="clip0_952_24449">
                  <rect width="16" height="16" fill="white" />
                </clipPath>
              </defs>
            </svg>
            <Timer />
          </button>
        )}
        {(!currentUser || startChat) && (has3d || hasHyper) && (
          <div className={"flex items-center gap-x-2"}>
            <Switch
              onChange={(value) => {
                setBackground(value);
                if(value){
                  setIsAvatarEnabled(true)
                  dataLayerPush(GTMEvent.AvatarTurnOnSuccessful)
                }else{
                  setIsAvatarEnabled(false)
                  dataLayerPush(GTMEvent.AvatarTurnOffSuccessful)
                }
              }}
              name={"verification"}
              handleDiameter={22}
              borderRadius={22}
              activeBoxShadow={"null"}
              checked={hasBackground}
              checkedIcon={false}
              uncheckedIcon={false}
              offHandleColor={"#fff"}
              width={45}
              height={30}
              onColor={"#C2D24B"}
              offColor={"#474761"}
            />
            <label className={"text-[14px] leading-5 secondary-text"}>
              {formatMessage({
                id: "Avatar",
              })}
            </label>
          </div>
        )}
        {startChat && (
          <button
            className={
              "text-[#C2D24B] w-full md:w-auto flex justify-center bg-[#C2D24B1A] px-[20px] py-[12px]  items-center gap-x-2.5 rounded border !border-[#C2D24B]"
            }
            onClick={handleClick}
          >
            <svg
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g clipPath="url(#clip0_952_24480)">
                <path
                  d="M5.33333 5.99935H10.6667M5.33333 8.66602H9.33333M12 2.66602C12.5304 2.66602 13.0391 2.87673 13.4142 3.2518C13.7893 3.62687 14 4.13558 14 4.66602V9.99935C14 10.5298 13.7893 11.0385 13.4142 11.4136C13.0391 11.7886 12.5304 11.9994 12 11.9994H8.66667L5.33333 13.9994V11.9994H4C3.46957 11.9994 2.96086 11.7886 2.58579 11.4136C2.21071 11.0385 2 10.5298 2 9.99935V4.66602C2 4.13558 2.21071 3.62687 2.58579 3.2518C2.96086 2.87673 3.46957 2.66602 4 2.66602H12Z"
                  stroke="#C2D24B"
                  strokeWidth="1.4"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </g>
              <defs>
                <clipPath id="clip0_952_24480">
                  <rect width="16" height="16" fill="white" />
                </clipPath>
              </defs>
            </svg>
            {formatMessage({
              id: `${
                loading
                  ? `${formatMessage({ id: "Processing" })}`
                  : currentChatSession
                  ? `${formatMessage({ id: "End Session" })}`
                  : `${formatMessage({ id: "Start Session" })}`
              }`,
            })}
          </button>
        )}
      </div>
    </div>
  );
};

export default Index;
