import React, { useRef } from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import "react-tooltip/dist/react-tooltip.css";
import { Tooltip as ReactTooltip } from "react-tooltip";
const CustomToolTip = ({
  tooltipText,
  children,
}: {
  tooltipText?: string;
  children: JSX.Element;
}) => {
  return (
    <>
      <div id={tooltipText}>{children}</div>
      <ReactTooltip anchorId={tooltipText} place="top" content={tooltipText} />
    </>
    // <OverlayTrigger
    //   placement={"top"}
    //   overlay={
    //     <Tooltip
    //       id="tooltip"
    //       style={{
    //         scrollbarGutter: "stable both-edges",
    //       }}
    //     >
    //       <p>{tooltipText}</p>
    //     </Tooltip>
    //   }
    // >
    //   {children}
    // </OverlayTrigger>
  );
};

export default CustomToolTip;
