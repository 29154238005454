import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import { useIntl } from "react-intl";
import { CustomButton } from "../../../widgets/components/UI/CustomButton";
import { Formik } from "formik";
import { ToolTipUI } from "../../../widgets/components/UI/ToolTipUI";
import { getAIPostContent } from "../../core/_request";
import { useAuth } from "../../../auth";
import { toast } from "react-toastify";


interface EventModalProps {
  showModal: boolean;
  handleModalClose: () => void;
  handleModalSubmit: (...args: any[]) => Promise<void>;
  selectedSocialMedia:string | null;
}

const EventModal: React.FC<EventModalProps> = ({
  showModal,
  handleModalClose,
  handleModalSubmit,
  selectedSocialMedia
}) => {
  const { formatMessage } = useIntl();
  const {selectedPersonality} = useAuth();
  const [aiText, setAiText] = useState("")
  const initialValues = {
    post: aiText || "",
  }
  return (
    <Modal
      show={showModal}
      onHide={handleModalClose}
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title>Create New Post</Modal.Title>
        <div className="" onClick={handleModalClose}>
          <svg
            width="32"
            height="32"
            viewBox="0 0 32 32"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M21.6568 8.45943L8.45746 21.6588C7.93676 22.1795 7.93676 23.0237 8.45746 23.5444C8.97816 24.0651 9.82238 24.0651 10.3431 23.5444L23.5424 10.345C24.0631 9.82435 24.0631 8.98013 23.5424 8.45943C23.0217 7.93873 22.1775 7.93873 21.6568 8.45943Z"
              fill="white"
            />
            <path
              opacity="0.5"
              d="M23.5425 21.6578L10.3432 8.45843C9.8225 7.93773 8.97828 7.93773 8.45758 8.45843C7.93688 8.97913 7.93688 9.82335 8.45758 10.3441L21.6569 23.5434C22.1776 24.0641 23.0218 24.0641 23.5425 23.5434C24.0632 23.0227 24.0632 22.1785 23.5425 21.6578Z"
              fill="white"
            />
          </svg>
        </div>
      </Modal.Header>
      <Modal.Body>
      <Formik initialValues={{}} onSubmit={async(_, {setSubmitting})=>{
        if(!selectedPersonality || !selectedSocialMedia){
          return;
        }
        setSubmitting(true)
        const {data:{success,data, errors}} = await getAIPostContent(selectedPersonality.personalityId, selectedSocialMedia)
        if(success){
        setAiText(data)
        }else{
          toast.error(errors[0] || formatMessage({ id: "AI having some trouble. Please try again later." }),
          {
            autoClose: false,
            closeButton: true, // Display close button (cross icon)
          });
        }
        setSubmitting(false)
      }}>
          {(formik) => {
        return <div className="flex gap-x-2 align-items-center  mb-[12px] ">
        <CustomButton
          isValid={true}
          loading={formik.isSubmitting}
          isSubmitting={formik.isSubmitting}
          customClass={`main-button text-[14px] leading-5 font-medium w-full rounded`}
          onSubmit={formik.handleSubmit}
          buttonText={formatMessage({
            id: "Auto generate post with AI",
          })}
        />
        <ToolTipUI
        tooltipText={formatMessage({
          id: "Generate content with the help of AI",
        })}
      />
      </div>
        }}
        </Formik>
        <Formik enableReinitialize initialValues={initialValues} onSubmit={handleModalSubmit}>
          {(formik) => {
            return (<div>
              <textarea
                className="mb-3 form-control form-control-flush"
                rows={5}
                data-kt-element="input"
                placeholder={formatMessage({
                  id: "Write Post content Here",
                })}
                value={formik.values.post}
                onChange={(e) => formik.setFieldValue('post', e.target.value)}
              ></textarea>
              <div
                className={
                  "flex flex-col-reverse md:flex-row gap-y-4  justify-end gap-x-2.5 w-full"
                }
              >
                <button
                  className={
                    "secondary-btn w-full sm:w-1/2  md:w-[140px] !text-[14px] !leading-[20px] !font-medium !py-[12px] !px-[16px]  rounded !ml-0 !my-0"
                  }
                  onClick={handleModalClose}
                >
                  {formatMessage({
                    id: "Cancel",
                  })}
                </button>

                <CustomButton
                  isValid={true}
                  loading={formik.isSubmitting}
                  isSubmitting={formik.isSubmitting}
                  customClass={`main-button text-[14px] leading-5 font-medium py-[12px] w-full sm:w-1/2  md:w-[140px] rounded`}
                  onSubmit={formik.handleSubmit}
                  buttonText={formatMessage({
                    id: " Schedule Post",
                  })}
                />
              </div>
            </div>)
          }}

        </Formik>
      </Modal.Body>
    </Modal>
  );
};

export default EventModal;
