import React from 'react';
import {Link, Outlet} from "react-router-dom";
import {toAbsoluteUrl} from "../helpers";
const CallLayout = () => {
    // className={'bg-[#11121C]'}

    return (
        <div className={'bg-[#11121C] h-screen'}>
            <header className={''}>
                <nav className="flex recharge-header justify-between p-6 z-10">
                    <div className="flex justify-center items-center gap-x-8">
                        <div className="flex justify-between items-center gap-x-8">
                            <button className="">
                                <Link to="/call">
                                    <svg
                                        width="36"
                                        height="36"
                                        viewBox="0 0 36 36"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path
                                            d="M7.71437 11.1406H28.2858M7.71094 17.9978H28.2772M7.71437 24.8549H28.2772"
                                            stroke="#8898A6"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                        />
                                    </svg>
                                </Link>
                            </button>
                            <h5>Sunny Leone</h5>
                        </div>
                    </div>

                    <div className="items-center flex font-semibold justify-center gap-x-4">
                        <img className="w-10 h-10 rounded-full" src={toAbsoluteUrl('/media/avatars/300-1.jpg')}/>
                        <span>+91 99140 15390</span>
                    </div>
                </nav>
            </header>
            <div className={'mx-auto recharge-bg md:max-w-[400px] h-screen relative'}>
                <Outlet/>
            </div>

        </div>
    );
};

export default CallLayout;