import React, { useEffect,  useState } from "react"
interface TimerHookProps {
    startFromSeconds?: number
}
const useTimer = () => {
    const [count, setCount] = useState(0)
    const [ready, setReady] = useState(false)
    useEffect(() => {
        let intervalId:any;

        if (ready) {
            intervalId = setInterval(() => {
                setCount((count) => count + 1);
            }, 1000);
        } else {
            clearInterval(intervalId)
        }

        return () => {
            clearInterval(intervalId);
        };
    }, [ready])


    function startTimer(value:any) {
        setCount(value)
        setReady(true)
    }

    function stopTimer() {
        setReady(false)
        setCount(0)
    }

    function formatTime(seconds: number) {
        const hours = Math.floor(seconds / 3600);
        const minutes = Math.floor((seconds % 3600) / 60);
        const remainingSeconds = seconds % 60;

        const formattedMinutes = minutes.toString().padStart(2, '0');
        const formattedSeconds = remainingSeconds.toString().padStart(2, '0');

        if (hours) {
            const formattedHours = hours.toString().padStart(2, '0');
            return `${formattedHours}:${formattedMinutes}:${formattedSeconds}`;
        } else {
            return `${formattedMinutes}:${formattedSeconds}`;
        }
    }

    const Timer: React.FC = ({ style }: { style?: string }) => {
        return (<p className={style}>{formatTime(count)}</p>)
    }

    return {
        startTimer, stopTimer, Timer
    }
}
export default useTimer;