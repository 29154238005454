import React from "react";
import { useIntl } from "react-intl";
import ShowMoreText from "react-show-more-text";

const ReadMore = ({
  text,
  lines = 3,
  children,
  onlyTruncate = false,
}: {
  text?: string;
  lines?: number;
  children?: any;
  onlyTruncate?: boolean;
}) => {
  const { formatMessage } = useIntl();

  // const executeOnClick = (isExpanded: boolean) => {
  //   console.log(isExpanded);
  // };

  return (
    <ShowMoreText
      /* Default options */
      lines={lines}
      more={onlyTruncate ? '' :  formatMessage({
        id: "Read more",
      })}
      less={onlyTruncate ? '' :  formatMessage({
        id: "Read less",
      })}
      className="content-css"
      anchorClass="text-[#C2D24B] ml-1 underline"
      // onClick={executeOnClick}
      expanded={false}
      // width={280}
      truncatedEndingComponent={<span>... </span>}
    >
      {text ?? children}
    </ShowMoreText>
  );
};

export default ReadMore;
