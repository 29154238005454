import React, { useState } from "react";
import TextInput from "../../../widgets/components/Input/TextInput";
import { useIntl } from "react-intl";
import { Form, Formik } from "formik";
import TextArea from "../../../widgets/components/Input/TextArea";
import { InfoCard } from "../../../widgets/components/UI/InfoCard";
import Accordion2 from "../../components/accordion-2";
import { updatePersonalityInfo } from "../../../profile/core/_requests";
import { useAuth } from "../../../auth";
import * as Yup from "yup";
import { toast } from "react-toastify";
import useGTM from "../../../../hooks/useGTM";
import { GTMEvent } from "../../../../hooks/gtm_helpers";

const Knowledga: React.FC<any> = ({ setOpenEdit, personalityData }) => {
  const { formatMessage } = useIntl();
  const [expanded, setExpanded] = useState<false | number>(0);
  const [expanded2, setExpanded2] = useState<false | number>(0);
  const {dataLayerPush} = useGTM()
  const {
    referenceData,
    setSelectedPersonality,
    selectedPersonality: updatedData,
  } = useAuth();
  personalityData = updatedData || personalityData;
  const [disableSave, setDisableSave] = useState(false);

  const onSubmit = async (values: any, { setSubmitting }: any) => {
    let requestData: any = {
      back_story_of_this_ai_personality:
        values.back_story_of_this_ai_personality,
      personal_knowledge_of_this_ai_personality:
        values.personal_knowledge_of_this_ai_personality,
      expertise_of_this_ai_personality: values.expertise_of_this_ai_personality,
      strengths_of_this_ai_personality: values.strengths_of_this_ai_personality,
      weaknesses_of_this_ai_personality:
        values.weaknesses_of_this_ai_personality,
      questions_answers_that_can_be_frequently_asked_from_this_ai_personality:
        values.questions_answers_that_can_be_frequently_asked_from_this_ai_personality,
    };
    // remove undefined or null values
    Object.keys(requestData).forEach((key) => {
      if (requestData[key] === null || requestData[key] == "undefined") {
        delete requestData[key];
      }
    });
    setSubmitting(true);
    // save data to api
    setDisableSave(true);
    try {
      let {
        data: { success, data },
      } = await updatePersonalityInfo({
        personalityId: personalityData.personalityId,
        personalityJson: {
          ...personalityData.personalityJson,
          knowledge: requestData,
        },
      });
      if (success) {
        setSelectedPersonality(data);
        toast.success(
          formatMessage({ id: "Knowledge details updated successfully" })
        );
      } else {
        setSubmitting(false);
        toast.error(
          formatMessage({ id: "Error when updating Knowledge details" }),
          {
            autoClose: false,
            closeButton: true, // Display close button (cross icon)
          }
        );
      }
      setDisableSave(false);
      dataLayerPush(GTMEvent.AIPersonalityEditSuccessful,{"tab": "knowledge-details"})
    } catch (e) {
      toast.error(
        formatMessage({ id: "Error when updating Knowledge details" }),
        {
          autoClose: false,
          closeButton: true, // Display close button (cross icon)
        }
      );
      setSubmitting(false);
      setDisableSave(false);
      dataLayerPush(GTMEvent.AIPersonalityEditFailed,{"tab": "knowledge-details"})
    }
  };

  const knowledgeValidationSchema = Yup.object().shape({
    back_story_of_this_ai_personality: Yup.string()
      // .min(10, "Backstory must be at least 10 characters")
      .max(500, "Backstory cannot exceed 500 characters"),
    personal_knowledge_of_this_ai_personality: Yup.string()
      // .min(10, "Personal knowledge must be at least 10 characters")
      .max(500, "Personal knowledge cannot exceed 500 characters"),
    expertise_of_this_ai_personality: Yup.string()
      // .min(10, "Expertise must be at least 10 characters")
      .max(500, "Expertise cannot exceed 500 characters"),
    strengths_of_this_ai_personality: Yup.string()
      // .min(10, "Strengths must be at least 10 characters")
      .max(500, "Strengths cannot exceed 500 characters"),
    weaknesses_of_this_ai_personality: Yup.string()
      // .min(10, "Weaknesses must be at least 10 characters")
      .max(500, "Weaknesses cannot exceed 500 characters"),
    questions_answers_that_can_be_frequently_asked_from_this_ai_personality:
      Yup.array()
        .of(
          Yup.object().shape({
            question: Yup.string()
              // .min(10, "Question must be at least 10 characters")
              .max(500, "Question cannot exceed 500 characters"),
            answer: Yup.string()
              // .min(10, "Answer must be at least 10 characters")
              .max(500, "Answer cannot exceed 500 characters"),
          })
        )
        // .min(1, "At least one question and answer pair is required")
        .max(10, "Maximum of 10 question and answer pairs allowed"),
  });

  return (
    <Formik
      initialValues={personalityData?.personalityJson?.knowledge || {}}
      onSubmit={onSubmit}
      validationSchema={knowledgeValidationSchema}
      validateOnMount
    >
      {(formik) => {
        return (
          <Form>
            <div className={"grid grid-cols-12 gap-y-[20px] md:gap-x-[40px] "}>
              <div className={"col-span-12 md:col-span-7 order-0"}>
                <TextArea
                  label={formatMessage({ id: "Back story" })}
                  fieldName={"back_story_of_this_ai_personality"}
                  placeholder={formatMessage({
                    id: "Define a back story of the Personality",
                  })}
                  formik={formik}
                  toolTipText={formatMessage({
                    id: "GLOBAL.TOOLTIP.EDIT_AI_PERSONALITY.KNOWLEDGE.BACK_STORY",
                  })}
                  className={"!min-h-[107px]"}
                />

                <TextArea
                  label={formatMessage({ id: "Personal Knowledge" })}
                  fieldName={"personal_knowledge_of_this_ai_personality"}
                  placeholder={formatMessage({
                    id: "Write a paragraph of personal knowledge",
                  })}
                  formik={formik}
                  toolTipText={formatMessage({
                    id: "GLOBAL.TOOLTIP.EDIT_AI_PERSONALITY.KNOWLEDGE.PERSONAL",
                  })}
                  className={"!min-h-[107px]"}
                />
                <TextArea
                  label={formatMessage({ id: "Expertise" })}
                  fieldName={"expertise_of_this_ai_personality"}
                  placeholder={formatMessage({
                    id: "Write the list of Expertise of this AI personality",
                  })}
                  formik={formik}
                  toolTipText={formatMessage({
                    id: "GLOBAL.TOOLTIP.EDIT_AI_PERSONALITY.KNOWLEDGE.EXPERTISE",
                  })}
                  className={"!min-h-[107px]"}
                />
                <TextArea
                  label={formatMessage({ id: "Strengths" })}
                  fieldName={"strengths_of_this_ai_personality"}
                  placeholder={formatMessage({
                    id: "Write the list of Strengths of this AI personality",
                  })}
                  formik={formik}
                  toolTipText={formatMessage({
                    id: "GLOBAL.TOOLTIP.EDIT_AI_PERSONALITY.KNOWLEDGE.STRENGTHS",
                  })}
                  className={"!min-h-[107px]"}
                />
                <TextArea
                  label={formatMessage({ id: "Weaknesses" })}
                  fieldName={"weaknesses_of_this_ai_personality"}
                  placeholder={formatMessage({
                    id: "Write the list of Weakneses of this AI personality",
                  })}
                  formik={formik}
                  toolTipText={formatMessage({
                    id: "GLOBAL.TOOLTIP.EDIT_AI_PERSONALITY.KNOWLEDGE.WEAKNESSES",
                  })}
                  className={"!min-h-[107px]"}
                />
                <h4 className="text-[16px] leading-6 font-medium primary-text mb-[16px]">
                  {formatMessage({
                    id: "Potential inquiries/questions users might pose to this AI Persona",
                  })}
                </h4>

                <ul className={"list-type-none"}>
                  {/* {map() => ( */}
                  {(
                    formik.values
                      ?.questions_answers_that_can_be_frequently_asked_from_this_ai_personality ||
                    []
                  ).map((el: any, index: number) => (
                    <li
                      className={
                        "tab-container-2 rounded p-[12px_16px_12px_12px] my-[14px]"
                      }
                    >
                      <Accordion2
                        index={index}
                        title={el.question}
                        paragraph={el.answer}
                        onDelete={() => {
                          formik.values.questions_answers_that_can_be_frequently_asked_from_this_ai_personality.splice(
                            index,
                            1
                          );
                          formik.setFieldValue(
                            "questions_answers_that_can_be_frequently_asked_from_this_ai_personality",
                            formik.values
                              .questions_answers_that_can_be_frequently_asked_from_this_ai_personality
                          );
                        }}
                      />
                    </li>
                  ))}
                </ul>

                <div
                  className={"tab-container p-[8px_16px] md:p-[20px_24px_32px_24px] rounded"}
                >
                  <TextInput
                    label={formatMessage({ id: "Question" })}
                    toolTipText={formatMessage({
                      id: "GLOBAL.TOOLTIP.EDIT_AI_PERSONALITY.KNOWLEDGE.POTENTIAL_QUESTION",
                    })}
                    fieldName={"question"}
                    placeholder={formatMessage({
                      id: "Enter your question here",
                    })}
                    fieldType={"text"}
                    formik={formik}
                  />{" "}
                  <TextArea
                    fieldName={"answer"}
                    label={formatMessage({ id: "Answer" })}
                    toolTipText={formatMessage({
                      id: "GLOBAL.TOOLTIP.EDIT_AI_PERSONALITY.KNOWLEDGE.POTENTIAL_ANSWER",
                    })}
                    formik={formik}
                    defaultValue={""}
                    placeholder={formatMessage({ id: "Enter answer here" })}
                    className={"!min-h-[140px]"}
                  />
                  <div className={"flex justify-end mt-[16px]"}>
                    <button
                      type="button"
                      className="bg-[#C2D24B1A]  text-[#C2D24B] text-[14px] leading-[20px] font-medium py-[8px] w-1/2 md:w-[115px] rounded me-[14px]"
                      onClick={() => {
                        formik.setFieldValue("question", "");
                        formik.setFieldValue("answer", "");
                      }}
                    >
                      {formatMessage({ id: "Reset" })}
                    </button>
                    <button
                      type="button"
                      className={
                        "bg-[#C2D24B] font-medium text-black text-[13px] leading-5  py-[8px] w-1/2  md:w-[100px] rounded"
                      }
                      onClick={() => {
                        if (!(formik.values.question && formik.values.answer)) {
                          return;
                        }
                        let newFaqs = [
                          ...(formik.values
                            ?.questions_answers_that_can_be_frequently_asked_from_this_ai_personality ||
                            []),
                          {
                            question: formik.values.question,
                            answer: formik.values.answer,
                          },
                        ];
                        formik.setFieldValue(
                          "questions_answers_that_can_be_frequently_asked_from_this_ai_personality",
                          newFaqs
                        );
                        formik.setFieldValue("question",'')
                        formik.setFieldValue("answer",'')
                      }}
                    >
                      {formatMessage({ id: "Add" })}
                    </button>
                  </div>
                </div>
              </div>

              <div
                className={
                  "col-span-12 md:col-span-5 flex flex-col justify-start gap-[20px]"
                }
              >
                <div
                  className={
                    "flex justify-end gap-[10px] md:order-1  md:mt-auto"
                  }
                >
                  {/* <button
                    onClick={() => setOpenEdit(false)}
                    className={
                      "main-button-primary-light text-[14px] leading-5 font-medium py-[12px] w-1/2 md:w-[128px] rounded"
                    }
                  >
                    {formatMessage({ id: "Cancel" })}
                  </button> */}
                  <button
                    type="submit"
                    className={`main-button text-[14px] leading-5 font-medium py-[12px] w-full sm:w-1/2  md:w-[140px] rounded ${
                      disableSave ? "opacity-75" : ""
                    }`}
                    disabled={disableSave}
                  >
                    {formatMessage({
                      id: `${disableSave ? "Please Wait" : "Save"}`,
                    })}
                  </button>
                </div>
                <InfoCard
                  title={formatMessage({
                    id: "Understanding Back Story & Knowledge of AI Personality",
                  })}
                  desc={
                    <>
                      {formatMessage({
                        id: "In Kamoto.AI, the 'Back Story & Knowledge' section allows you to delve into the depths of your virtual AI persona's background and expertise. You have the power to shape its backstory, revealing its origins and unique experiences. Personal knowledge adds depth and authenticity to your AI personality, equipping it with specific knowledge in various subjects. \n",
                      })}
                      <br></br>
                      <br></br>
                      {formatMessage({
                        id: "By infusing your AI persona with rich knowledge and a back story, you create a compelling and well-rounded character.  \n",
                      })}
                      <br></br>
                      <br></br>
                      {formatMessage({
                        id: "Whether it's a complex history or specialized expertise, Kamoto.AI enables you to craft AI personalities that are knowledgeable and intriguing. With Kamoto.AI, you can unlock the full potential of your virtual AI persona, ensuring it leaves a lasting impression on users.",
                      })}
                      <br></br>
                      <br></br>
                    </>
                  }
                  slug={""}
                  isAnchor={
                    "https://help.kamoto.ai/en/articles/7941837-exploring-back-story-knowledge-tab-of-ai-personalities"
                  }
                />
              </div>
            </div>
          </Form>
        );
      }}
    </Formik>
  );
};

export default Knowledga;
