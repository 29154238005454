import { memo } from "react";
import clsx from "clsx";
import { KTSVG } from "../../../helpers";
import { useLayout } from "../../core";

import { useEffect, useState, useRef } from "react";
import { useAuth } from "../../../../app/modules/auth";
import { DisplayImage } from "../../../../app/modules/widgets/components/General/DisplayImage";
import { BasicButton } from "../../../../app/modules/widgets/components/UI/BasicButton";
import { useIntl } from "react-intl";
import { useNavigate } from "react-router-dom";
import { getUserByToken } from "../../../../app/modules/auth/core/_requests";
import useGTM from "../../../../app/hooks/useGTM";
import {
  GTMEvent,
  basePersonalityMeta,
} from "../../../../app/hooks/gtm_helpers";
import { set } from "lodash";
import PersonalitySwitchModal from "./PersonalitySwitchModal";
// import request from "../../../../app/core/_apis";
import { getAllPersonalities } from "../../../../app/modules/auth/core/_requests";
import request from "../../../../app/core/_apis";
import axios from "axios";

const SidebarLogo = () => {
  const { formatMessage } = useIntl();
  const navigate = useNavigate();
  const {
    currentUser,
    storePersonalityId,
    setNewPersonality,
    personalityId,
    setCurrentChatSession,
    selectedPersonality,
  } = useAuth();
  const [searchValue, setSearchValue] = useState("");
  const [companyData, setCompanyData] = useState<any[]>();
  const [filteredCompanyData, setFilteredCompanyData] = useState<any[]>();
  const { config } = useLayout();
  const dropdownRef = useRef<HTMLDivElement>(null);
  const [openDropdown, setOpenDropdown] = useState(false);
  const [loading, setLoading] = useState(false);
  const { dataLayerPush } = useGTM();
  const appSidebarDefaultMinimizeDesktopEnabled =
    config?.app?.sidebar?.default?.minimize?.desktop?.enabled;
  const appSidebarDefaultCollapseDesktopEnabled =
    config?.app?.sidebar?.default?.collapse?.desktop?.enabled;
  const toggleType = appSidebarDefaultCollapseDesktopEnabled
    ? "collapse"
    : appSidebarDefaultMinimizeDesktopEnabled
    ? "minimize"
    : "";
  const toggleState = appSidebarDefaultMinimizeDesktopEnabled ? "active" : "";
  const [selectCompany, setSelectedCompany] = useState<any>(null);
  const appSidebarDefaultMinimizeDefault =
    config.app?.sidebar?.default?.minimize?.desktop?.default;
  useEffect(() => {
    if (!selectedPersonality) {
      return;
    }
    if (searchValue) {
      return;
    }
    let allPersonality = [
      selectedPersonality,
      ...(currentUser?.personalities || []).filter((personality: any) => {
        return personality.personalityId !== selectedPersonality.personalityId;
      }),
    ].slice(0, 10);
    setCompanyData(allPersonality);
    setFilteredCompanyData(allPersonality);
    if (personalityId) {
      const findCompany = allPersonality?.find(
        (company: { personalityId: string }) =>
          company.personalityId === personalityId
      );
      if (findCompany) {
        setSelectedCompany(findCompany);
        setCurrentChatSession(undefined);
      } else {
        setSelectedCompany(allPersonality[0]);
        setCurrentChatSession(undefined);
      }
    }
    if (selectCompany === null && !personalityId) {
      setSelectedCompany(allPersonality[0]);
    }
  }, [
    currentUser,
    selectCompany,
    personalityId,
    selectedPersonality,
    searchValue,
  ]); // eslint-disable-line

  const onCompanySelect = async (item: any) => {
    setLoading(true);
    try {
      setSelectedCompany(item);
      storePersonalityId(item.personalityId);
      localStorage.setItem("personalityId", item.personalityId);
      await getUserByToken("", item.personalityId);
      dataLayerPush(
        GTMEvent.PersonalitySwitchSuccessful,
        basePersonalityMeta(item, currentUser?.personalities?.length || 0)
      );
      setTimeout(() => {
        window.location.reload();
      }, 0);
    } catch (e) {
      dataLayerPush(GTMEvent.PersonalitySwitchFailed);
    }
  };

  useEffect(() => {
    // @ts-ignore
    // const newData = companyData?.filter(value => value.name.toLowerCase().includes(searchValue.toLowerCase()))
    // setFilteredCompanyData(newData)
    if (!searchValue) {
      return;
    }
    let timeout = setTimeout(() => {
      (async () => {
        let resp = await axios.get(
          `${process.env.REACT_APP_BASE_API_URL}/me/personalities`,
          { params: { searchText: searchValue } }
        );
        setFilteredCompanyData(resp.data.data);
      })();
    }, 500);
    return () => {
      clearTimeout(timeout);
    };
  }, [searchValue]);

  useEffect(() => {
    const handleClickOutside = (event: any) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setOpenDropdown(false);
      }
    };

    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);
  if (loading) {
    return <PersonalitySwitchModal />;
  }
  return (
    <div
      className="app-sidebar-logo border-0 py-[19px] md:py-[0px] px-6"
      id="kt_app_sidebar_logo"
    >
      <div className="w-100">
        <div className="accordion-item " ref={dropdownRef}>
          <h2 className="accordion-header" id="panelsStayOpen-headingThree">
            <button
              className="  collapsed w-100 app-sidebar-logo-default"
              type="button"
              onClick={() => setOpenDropdown((prevState) => !prevState)}
            >
              <button className="btn d-flex p-0 align-items-center w-full ">
                <DisplayImage
                  className="!rounded-full !min-h-[32px] !max-w-[32px] !max-h-[32px] !w-full !h-full !object-cover display-image-position !overflow-hidden"
                  imgName={selectCompany?.image}
                  alt="comp"
                  height="30px"
                  width="30px"
                  fit="contain"
                />
                <div className={"flex justify-between flex-grow mr-6 overflow-hidden"}>
                  <h4 className="align-self-center  text-dark font-size-13 fw-bold ps-3 m-0 line-clamp-2">
                    {selectCompany?.name}
                  </h4>
                  <i
                    className="align-self-center fa fa-angle-down  ps-4 text-clr477 text-primary"
                    aria-hidden="true"
                  ></i>
                </div>
              </button>
            </button>
          </h2>
          <div className="w-100 app-sidebar-logo-minimize d-flex">
            <DisplayImage
              imgName={selectCompany?.image}
              alt="comp"
              height="30px"
              width="30px"
              fit="contain"
              className={"app-sidebar-logo-minimize rounded-full !min-h-[32px] !max-w-[32px] !max-h-[32px] !w-full !h-full !object-cover display-image-position !overflow-hidden"}
            />
          </div>
          <div
            className={clsx(
              "accordion-collapse  absolute top-[60px] z-50 bg-white  md:!w-[230px] shadow mh-1000px",
              openDropdown ? "" : "collapse"
            )}
          >
            <div className="setting-table p-3.5 rounded shadow-default">
              {currentUser?.personalities &&
                currentUser?.personalities?.length > 3 && (
                  <div className={"border-b main-border pb-2"}>
                    <div className="relative flex-1 ">
                      <svg
                        className="absolute top-1/2 -translate-y-1/2 left-2"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <g clipPath="url(#clip0_497_31689)">
                          <path
                            opacity="0.3"
                            d="M14.2929 16.7071C13.9024 16.3166 13.9024 15.6834 14.2929 15.2929C14.6834 14.9024 15.3166 14.9024 15.7071 15.2929L19.7071 19.2929C20.0976 19.6834 20.0976 20.3166 19.7071 20.7071C19.3166 21.0976 18.6834 21.0976 18.2929 20.7071L14.2929 16.7071Z"
                            fill="#585960"
                          ></path>
                          <path
                            d="M11 16C13.7614 16 16 13.7614 16 11C16 8.23858 13.7614 6 11 6C8.23858 6 6 8.23858 6 11C6 13.7614 8.23858 16 11 16ZM11 18C7.13401 18 4 14.866 4 11C4 7.13401 7.13401 4 11 4C14.866 4 18 7.13401 18 11C18 14.866 14.866 18 11 18Z"
                            fill="#585960"
                          ></path>
                        </g>
                        <defs>
                          <clipPath id="clip0_497_31689">
                            <rect width="24" height="24" fill="white"></rect>
                          </clipPath>
                        </defs>
                      </svg>
                      <input
                        className="form-control search-input-company h-[42px] pl-10"
                        placeholder="Search"
                        value={searchValue}
                        onChange={(e) => setSearchValue(e.target.value)}
                      />
                    </div>
                  </div>
                )}
              <div className={"overflow-y-auto custom-scroll max-h-60"}>
                {companyData &&
                  filteredCompanyData?.map((item) => (
                    <div
                      className="py-2 border-b main-border"
                      key={item.personalityId}
                    >
                      <button
                        className={`${
                          selectCompany &&
                          selectCompany.personalityId === item.personalityId &&
                          "!border primary-border rounded company-active-button"
                        }  flex w-full justify-between items-center  p-2 pr-4`}
                        data-bs-toggle="collapse"
                        data-bs-target="#panelsStayOpen-collapseThree"
                        aria-expanded="false"
                        aria-controls="panelsStayOpen-collapseThree"
                        onClick={() => {
                          if (
                            !(
                              selectCompany &&
                              selectCompany.personalityId === item.personalityId
                            )
                          ) {
                            onCompanySelect(item);
                          }
                        }}
                      >
                        <div
                          className={"flex items-center gap-x-2.5 text-left"}
                        >
                          <div className={"w-[32px] h-[32px]"}>
                            <DisplayImage
                              imgName={item.image}
                              alt="img"
                              height="23px"
                              width="23px"
                              fit="contain"
                              className={
                                "!rounded-full min-w-[32px] !min-h-[32px] !max-w-[32px] !max-h-[32px] !w-full !h-full !object-cover display-image-position !overflow-hidden"
                              }
                            />
                          </div>
                          <p className="text-[14px] font-medium line-clamp-1">
                            {item.name}
                          </p>
                        </div>
                      </button>
                    </div>
                  ))}
              </div>
              <div className="d-flex justify-content-center mt-2">
                <BasicButton
                  buttonText={formatMessage({ id: "Add New Personality" })}
                  height="44px"
                  border="none"
                  // color="#C2D24B"
                  customClass={"whitespace-nowrap export-button"}
                  // textColor="#000000"
                  padding="12px 21px"
                  onClick={() => {
                    setNewPersonality(false);
                    navigate("/onboarding/");
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      {(appSidebarDefaultMinimizeDesktopEnabled ||
        appSidebarDefaultCollapseDesktopEnabled) && (
        <div
          id="kt_app_sidebar_toggle"
          className={clsx(
            "app-sidebar-toggle lg:!inline-flex !hidden btn btn-icon btn-shadow btn-sm btn-color-muted btn-active-color-primary body-bg h-30px w-30px position-absolute top-50 start-100 translate-middle rotate",
            { active: appSidebarDefaultMinimizeDefault }
          )}
          data-kt-toggle="true"
          data-kt-toggle-state={toggleState}
          data-kt-toggle-target="body"
          data-kt-toggle-name={`app-sidebar-${toggleType}`}
        >
          <KTSVG
            path="/media/icons/duotune/arrows/arr079.svg"
            className="svg-icon-2 rotate-181"
          />
        </div>
      )}
    </div>
  );
};

export default memo(SidebarLogo);
