import React, { useEffect, useState } from "react";
import { get } from "lodash";
import { ToolTipUI } from "../../../widgets/components/UI/ToolTipUI";
import { useIntl } from "react-intl";

const FormItem: React.FC<any> = ({ data, setSelection }) => {
  const { formatMessage } = useIntl();
  const [count, setCount] = useState(0);
  useEffect(()=>{
    if(count >0){
    setSelection((selection:any)=>{
      return {
        ...selection,
        [data.key]: count
      }
    })
  }else {
    setSelection((selection:any)=>{
      const newSelection = {...selection}
      delete newSelection[data.key]
      return newSelection
    })
  }
  },[count])
  return (
    <div
      className={
        "grid grid-cols-12 gap-y-3 md:gap-x-5 mb-4 items-center text-[12px] md:text-[16px] font-medium primary-text leading-[22px]"
      }
    >
      <div className={"col-span-12 md:col-span-8"}>
        <div className={"flex justify-between items-center"}>
          <label className={"flex gap-x-1 "}>
            {formatMessage({ id: get(data, "title") })}
            <ToolTipUI
              tooltipText={formatMessage({ id: get(data, "title") })}
            />
          </label>
          <div className={"flex gap-x-3 items-center"}>
            <p>
              ${get(data, "price")}/
              {formatMessage({ id: get(data, "priceFor") })}
            </p>
            <button
              onClick={() => {
                setCount(0);
              }}
            >
              <svg
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g id="Frame" clipPath="url(#clip0_3346_34873)">
                  <path
                    id="Vector"
                    d="M15.2492 4.75852C15.1721 4.68127 15.0805 4.61998 14.9797 4.57816C14.8789 4.53634 14.7708 4.51482 14.6617 4.51482C14.5526 4.51482 14.4445 4.53634 14.3437 4.57816C14.2429 4.61998 14.1513 4.68127 14.0742 4.75852L9.99921 8.82519L5.92421 4.75019C5.84706 4.67303 5.75547 4.61183 5.65466 4.57008C5.55386 4.52833 5.44582 4.50684 5.33671 4.50684C5.2276 4.50684 5.11956 4.52833 5.01876 4.57008C4.91795 4.61183 4.82636 4.67303 4.74921 4.75019C4.67206 4.82734 4.61086 4.91893 4.5691 5.01973C4.52735 5.12054 4.50586 5.22858 4.50586 5.33769C4.50586 5.4468 4.52735 5.55484 4.5691 5.65564C4.61086 5.75644 4.67206 5.84803 4.74921 5.92519L8.82421 10.0002L4.74921 14.0752C4.67206 14.1523 4.61086 14.2439 4.5691 14.3447C4.52735 14.4455 4.50586 14.5536 4.50586 14.6627C4.50586 14.7718 4.52735 14.8798 4.5691 14.9806C4.61086 15.0814 4.67206 15.173 4.74921 15.2502C4.82636 15.3273 4.91795 15.3885 5.01876 15.4303C5.11956 15.472 5.2276 15.4935 5.33671 15.4935C5.44582 15.4935 5.55386 15.472 5.65466 15.4303C5.75547 15.3885 5.84706 15.3273 5.92421 15.2502L9.99921 11.1752L14.0742 15.2502C14.1514 15.3273 14.243 15.3885 14.3438 15.4303C14.4446 15.472 14.5526 15.4935 14.6617 15.4935C14.7708 15.4935 14.8789 15.472 14.9797 15.4303C15.0805 15.3885 15.1721 15.3273 15.2492 15.2502C15.3264 15.173 15.3876 15.0814 15.4293 14.9806C15.4711 14.8798 15.4926 14.7718 15.4926 14.6627C15.4926 14.5536 15.4711 14.4455 15.4293 14.3447C15.3876 14.2439 15.3264 14.1523 15.2492 14.0752L11.1742 10.0002L15.2492 5.92519C15.5659 5.60852 15.5659 5.07519 15.2492 4.75852Z"
                    fill="white"
                    fillOpacity="0.65"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_3346_34873">
                    <rect width="20" height="20" fill="white" />
                  </clipPath>
                </defs>
              </svg>
            </button>
          </div>
        </div>
      </div>
      <div className={"col-span-12 md:col-span-4"}>
        <div className={"flex gap-x-5 md:justify-between items-center"}>
          <div
            className={
              "secondary-bg w-[110px] rounded flex justify-between items-center px-1 py-2"
            }
          >
            <button
              className={
                "bg-[#C2D24B1A] w-[24px] h-[24px] flex items-center justify-center rounded"
              }
              onClick={() => {
                setCount((prevState) =>
                  prevState > 0 ? prevState - 1 : prevState
                );
              }}
            >
              <svg
                width="7"
                height="2"
                viewBox="0 0 7 2"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M6.812 0.52V1.752H0.428V0.52H6.812Z" fill="#C2D24B" />
              </svg>
            </button>
            <span>{count}</span>
            <button
              className={
                "bg-[#C2D24B1A] w-[24px] h-[24px] flex items-center justify-center rounded"
              }
              onClick={() => {
                setCount((prevState) => prevState + 1);
              }}
            >
              <svg
                width="10"
                height="10"
                viewBox="0 0 10 10"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M9.07992 5.62392H5.67192V9.07992H4.31192V5.62392H0.919922V4.39192H4.31192V0.919922H5.67192V4.39192H9.07992V5.62392Z"
                  fill="#C2D24B"
                />
              </svg>
            </button>
          </div>
          <div>=</div>
          <div>$ {(count * get(data, "price")).toFixed(1)}</div>
        </div>
      </div>
    </div>
  );
};

export default FormItem;
