import React from "react";
import { get, map } from "lodash";
import { cards } from "./cards";
import Card from "../../card";
import Pagination from "../../pagination";
import Filter from "../filter";
import clsx from "clsx";
import Empty from "../../../components/ai-user-profile/empty";
import { useIntl } from "react-intl";
import { ComponentSpinner } from "../../../../widgets/components/General/Spinner";

const Index: React.FC<any> = ({
  openFilter,
  handleFilters = () => {},
  personalities,
  total,
  apiLoading,
  onReset,
  filters,
  paginationDefault,
  showPagination,
}) => {
  const { formatMessage } = useIntl();

  // if(apiLoading){
  //   return <ComponentSpinner/>
  // }

  return (
    <div>
      <div className={` d-flex justify-content-center align-items-center`}>
        {!apiLoading && !total && (
          <Empty
            onReset={onReset}
            atMarketplace={true}
            heading={formatMessage({ id: "No results found!" })}
            contents={formatMessage({
              id: "No AI personalities match the filters you have selected. Please try changing your search criteria.",
            })}
          />
        )}
      </div>
      {apiLoading ? (
        <ComponentSpinner />
      ) : (
        <div
          className={clsx(
            "grid grid-cols-12  gap-x-[8px] md:gap-x-[18px] gap-y-5 mb-9",
            openFilter
              ? " grid-cols-10 2xl:grid-cols-10"
              : "xl:grid-cols-10 2xl:grid-cols-12"
          )}
        >
          {map(personalities, (el) => (
            <div
              className={clsx(
                "col-span-12    2xl:col-span-2",
                openFilter
                  ? "xl:col-span-3 lg:col-span-4 md:col-span-10"
                  : "xl:col-span-2 lg:col-span-3 md:col-span-4"
              )}
              key={get(el, "id")}
            >
              <Card
                id={get(el, "personalityId")}
                slug={get(el, "username")}
                image={get(el, "image")}
                name={get(el, "name")}
                occupation={get(el, "category")}
                isVerified={get(el, "isVerified")}
                followers={get(el, "followers")}
                likes={get(el, "likes")}
                owners={get(el, "users")}
                interactions={get(el, "interactions")}
              />
            </div>
          ))}
        </div>
      )}
      <Pagination
      isDark={true}
        showPagination={showPagination}
        handleFilters={handleFilters}
        total={total}
        filters={filters}
        paginationDefault={paginationDefault}
      />
    </div>
  );
};

export default Index;
