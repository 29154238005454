import axios from "axios"
import { ResponseType } from "../../../../../core/_models";

const API_URL = process.env.REACT_APP_BASE_API_URL;
export const FOLLOW = `${API_URL}/follow`
export const CHAT_SUBSCRIPTION = `${API_URL}/chat-subscription`
export const GET_PERSONALITY = `${API_URL}/personality`

export const getAllChatSubscriptions = async()=>{
    return await axios.get<ResponseType<any[]>>(`${CHAT_SUBSCRIPTION}/all`, {
        validateStatus(status) {
            return true;
        }
    })
}

export const getPersonalityById = async(personalityId:string)=>{
    return await axios.get<ResponseType<any>>(`${GET_PERSONALITY}/${personalityId}?includes=isOwned,listing`, {
        validateStatus(status) {
            return true;
        }
    })
}