/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useRef } from "react";
import ApexCharts, { ApexOptions } from "apexcharts";
import {
  getCSS,
  getCSSVariableValue,
} from "../../../_metronic/assets/ts/_utils";
import { useThemeMode } from "../../../_metronic/partials";

export interface CombinedData {
  currentTitle: string;
  comparedTitle: string;
  currentPeriod: any;
  comparedPeriod: any;
}

type Props = {
  className: string;
  // withData: boolean;
  compareData: CombinedData;
};

const LineChartWithData: React.FC<Props> = ({ className, compareData }:Props) => {
  const chartRef = useRef<HTMLDivElement | null>(null);
  const { mode } = useThemeMode();
  const refreshMode = () => {
    if (!chartRef.current) {
      return;
    }

    const height = parseInt(getCSS(chartRef.current, "height"));

    const chart = new ApexCharts(
      chartRef.current,
      getChartOptions(height,compareData)
    );
    if (chart) {
      chart.render();
    }

    return chart;
  };

  useEffect(() => {
    const chart = refreshMode();

    return () => {
      if (chart) {
        chart.destroy();
      }
    };
  }, [chartRef, mode]);

  return (
    <div
      ref={chartRef}
      id="kt_charts_widget_3_chart"
      style={{ height: "100px", width: "100%" }}
    ></div>
  );
};

export { LineChartWithData };

function getChartOptions(height: number, compareData: CombinedData): ApexOptions {
  const labelColor = getCSSVariableValue("--kt-gray-500");
  const borderColor = getCSSVariableValue("--kt-gray-200");
  const baseColor = getCSSVariableValue("--kt-info");
  const lightColor = getCSSVariableValue("--kt-info-light");
  console.log({
    "this" : compareData?.currentPeriod?.map((el:any) => {return el.value})  || [],
    "prev" : compareData?.comparedPeriod?.map((el:any) => {return el.value}) || [],
    "date" : compareData?.currentPeriod?.map((el:any) => {return el.date}) || []
  })
  return {
    series: [
      {
        name: compareData?.currentTitle || "Current Period",
        data: compareData?.currentPeriod?.map((el:any) => {return el.value})  || [],
      },
      {
        name: compareData?.comparedTitle || "Previous Period",
        data: compareData?.comparedPeriod?.map((el:any) => {return el.value}) || [],
      },
    ],
    chart: {
      height: 140,
      type: "line",
      toolbar: {
        show: false,
      },
    },
    dataLabels: {
      enabled: false,
    },
    plotOptions: {
      bar: {
        horizontal: false,
      },
    },
    legend: {
      show: false,
    },
    stroke: {
      curve: "smooth",
      show: true,
      width: 2,
      colors: ["#C2D24B", "#8898A6"],
    },
    grid: {
      borderColor: borderColor,
      strokeDashArray: 4,
      yaxis: {
        lines: {
          show: true,
        },
      },
    },
    xaxis: {
      categories: compareData?.currentPeriod?.map((el:any) => {return el.date}) || [],
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
      labels: {
        show: false,
        style: {
          colors: labelColor,
          fontSize: "12px",
        },
      },
      crosshairs: {
        position: "front",
        stroke: {
          color: baseColor,
          width: 1,
          dashArray: 3,
        },
      },
      tooltip: {
        enabled: true,
        formatter: undefined,
        offsetY: 0,
        style: {
          fontSize: "12px",
        },
      },
    },
    yaxis: {
      show: false,
      labels: {
        style: {
          colors: labelColor,
          fontSize: "12px",
        },
      },
    },
  };
}
