import React, { useEffect, useState } from "react";

import { useIntl } from "react-intl";
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
import UserList from "./UserList";
import avatar from "../../../assets/images/profile/avatar-image.png"
import request from "../../../core/_apis";
import { ComponentSpinner } from "../../widgets/components/General/Spinner";

export default function UserInboxChatList({AllUsers,StarredUser,inboxLoading,...props}:{AllUsers:any,StarredUser:any,inboxLoading:boolean}) {
  const { formatMessage } = useIntl();

  return (
    <div className="sm:min-w-[268px] sm:max-w-[268px] w-full  border-r-0 md:border-r overflow-hidden border-solid border-[#2E2F45]  pt-[16px] ">
      <Tabs className="font-size-13 " selectedTabClassName="bg-primary">
        <TabList
          className={
            "user-inbox-tabs text-[13px] leading-[20px] font-normal primary-text setting-tabs overflow-x-auto overflow-y-hidden whitespace-nowrap  border-main border-b"
          }
        >
          <Tab>{formatMessage({ id: "All" })}</Tab>
          <Tab>{formatMessage({ id: "Starred" })}</Tab>
        </TabList>
        <TabPanel>
          {
            inboxLoading ? <ComponentSpinner/>:<UserList listData={AllUsers} />
          }
        </TabPanel>
        <TabPanel>
        {
            inboxLoading ? <ComponentSpinner/>:<UserList listData={StarredUser} />
          }
        </TabPanel>
      </Tabs>
    </div>
  );
}
