import React, { useState } from "react";
import {get, map} from "lodash";
import CustomTable from "../../../components/table";
import { useIntl } from "react-intl";
import { getEvents } from "../core/_requests";
import { useDateFormat } from "../../../hooks/useDateFormat";
import { useTimeZone } from "../../../hooks/useTimeZone";
import { getCdnUrl } from "../../../core/_util";

const Events = () => {
  const { formatMessage } = useIntl();
  const [loading, setLoading] = useState<boolean>(true);
  const [data, setData] = useState<any[]>([])
  const [pageCount, setPageCount] = useState<number>(0)
  const { getDateValue } = useDateFormat();
  const { getTimeZoneValue } = useTimeZone();
  async function getEvent({page, size, search}:any) {
    setLoading(true);
    const {
      data: { success, data },
    } = await getEvents('apiKeys',{
      page: page + 1,
      limit: size,
      search
    });
    if (success) {
      let formattedData = data.rows.map(
        ({
            description, 
            personality,
            eventTime
        }: any) => {
          return {
            description, 
            personality,
            eventTime: `${getDateValue(eventTime)} ${getTimeZoneValue(eventTime)}`
          };
        }
        );
        setLoading(false);
        setData(formattedData)
        setPageCount(Math.ceil(data.count / size))
    }
  }
  const headers = [
    // { label: `${formatMessage({ id: "Event ID" })}`, key: "eventId" , enableSorting:true},
    { label: `${formatMessage({ id: "Event" })}`, key: "description", enableSorting:true },
    { label: `${formatMessage({ id: "Personality" })}`, key: "personality",cell: true , enableSorting:true},
    { label: `${formatMessage({ id: "Event Date" })}`, key: "eventTime", enableSorting:true },
  ];


  const columns = React.useMemo<any>(
      () =>
          map(headers, (item) => {
            return {
              header: get(item, "label"),
              accessorKey: get(item, "key"),
              enableSorting:get(item, "enableSorting"),
              footer: (props: any) => props.column.id,
              cell: (info: any) => {
                if ( item.key=="personality") {
                  return ( <div  className={"flex items-center gap-x-2"}>
                  <img
                    src={getCdnUrl(info.getValue().image)}
                    className={"w-8 h-8 rounded-full"}
                  />
                  {info.getValue().name}
                </div>)
            } else {
              return info.getValue()
            }
          }}
        }),
      []
  );

  return (
        <div className={"mt-[16px] md:mt-4"}>
          <div className={"tab-container shadow-default sm:py-[14px] sm:px-[6px] md:p-6  rounded min-h-[70vh]"}>
        <CustomTable
          loading={loading}
          tableData={data}
          pageCount={pageCount}
          headers={headers}
          columns={columns}
          fetchData={getEvent}
          emptyControl={{
            heading:formatMessage({ id: "No Events found"}),
          }}
        />
          </div>
        </div>
  );
};

export default Events;
