import React, { useEffect } from 'react'
import { useAuth } from '../../../auth';

interface RazorpayOptions {
    amount?: number;
    order_id?: string;
    callback_url?: string;
    subscription_id?: string;
    handler?: (response: any) => void;
    modal?: any;
}
interface RazorpayModalOptions {
    ondismiss?: () => void;
    backdropclose?: boolean;
    escape?: boolean;
    confirm_close?: boolean;

}
export default function RazorpayModal({ open, options = {}, modalOptions = {} }: { open: boolean; options: RazorpayOptions; modalOptions?: RazorpayModalOptions }) {
    const {currentUser} = useAuth()
    function loadScript(src: string) {
        return new Promise((resolve) => {
            const script = document.createElement('script')
            script.src = src
            script.onload = () => {
                setUpRazorPay()
            }
            script.onerror = () => {
                resolve(false)
            }
            document.body.appendChild(script)
        })
    }

    function setUpRazorPay() {
        const defaultOptions = {
            key: process.env.REACT_APP_RAZORPAY_KEY_ID || "rzp_test_oMW9KEi7X8VF3R",
            name: "Kamoto.AI",
            theme: {
                color: "#c2d24b"
            },
            image: "https://www.kamoto.ai/assets/images/logo.svg",
            modal: {
                backdropclose: false,
                escape: false,
                confirm_close: true,
                ...modalOptions
            },
            prefill: {
                name: currentUser?.fullname, //your customer's name
                email: currentUser?.email, 
                contact: currentUser?.contact  //Provide the customer's phone number for better conversion rates 
            },
        }
        const razorpayOptions = {
            ...defaultOptions,
            ...options
        }
        //@ts-ignore
        const paymentObject = new window.Razorpay(razorpayOptions);
        paymentObject.open();
    }

    useEffect(() => {
        open && (async () => {
            const res = await loadScript('https://checkout.razorpay.com/v1/checkout.js')

            if (!res) {
                console.log('Razropay failed to load!!')
                return
            }
        })()
    }, [open])
    return null;
}
