import React from "react";
import { Route, Routes } from "react-router-dom";
import ViewPage from "./pages/view";
import SettingsTeamPage from "./pages/settings-team"

const EditAiPersonalityRouting = () => {
  return (
    <Routes>
      <Route path="settings-team" element={<SettingsTeamPage/>}/>
      <Route index element={<ViewPage />} />
    </Routes>
  );
};

export default EditAiPersonalityRouting;



