import axios from "axios"
import { ResponseType, TrainingChatItem } from "./_models";
const API_URL = process.env.REACT_APP_BASE_API_URL;

export const TRAINING_URL = `${API_URL}/trainings`
export const TRAINING_CHATS_URL = `${API_URL}/training-chats`


export const getTrainingsByType = async(personalityId:string, type:string, page:number)=>{
    return await axios.get<ResponseType<any[]>>(`${TRAINING_URL}`,{
      params:{page,type},
        validateStatus(status) {
          return true;
        },
      headers:{
        'x-personality-id':personalityId
      }})
}

export const getTrainingHistory = async(personalityId:string, page:number, perPage:number)=>{
  return await axios.get<ResponseType<{trainings:any[], count:number}>>(`${TRAINING_URL}`,{
    params:{page, perPage},
      validateStatus(status) {
        return true;
      },
    headers:{
      'x-personality-id':personalityId
    }})
}


export const getTrainingChats = async(trainingId:string, page:number)=>{
    return await axios.get<ResponseType<TrainingChatItem[]>>(`${TRAINING_URL}/${trainingId}/chats`,{
      params:{page},
        validateStatus(status) {
          return true;
        }
    })
}

export const addTrainingChat = async(trainingId:string, prompt:string)=>{
  return await axios.post<ResponseType<TrainingChatItem>>(`${TRAINING_CHATS_URL}`,{
    trainingId,
    prompt
  },{
      validateStatus(status) {
        return true;
      }
  })
}

export const updateTrainingChat = async(chatId:string, completion:string)=>{
  return await axios.post<ResponseType<TrainingChatItem>>(`${TRAINING_CHATS_URL}/${chatId}`,{
    completion
  },{
      validateStatus(status) {
        return true;
      },
  })
}

export const submitTrainings = async(trainings:string[])=>{
  return await axios.post<ResponseType<TrainingChatItem>>(`${TRAINING_URL}/submit`,{
    trainings
  },{
      validateStatus(status) {
        return true;
      },
  })
}

export const deleteTrainingChat = async(chatId:string)=>{
  return await axios.delete<ResponseType<any>>(`${TRAINING_CHATS_URL}/${chatId}`)
}

export const createTraining = async(personalityId:string, type:string,data:{[key:string]:any})=>{
  return await axios.post<ResponseType<any>>(`${TRAINING_URL}/create`,{
    ...data,
    type
  },{
      // validateStatus(status) {
      //   return true;
      // },
      headers:{
        'x-personality-id':personalityId
      }
  })
}

export const updateTraining = async(trainingId:string,data:{[key:string]:string})=>{
  return await axios.post<ResponseType<any>>(`${TRAINING_URL}/${trainingId}`,{
    ...data,
  },{
      validateStatus(status) {
        return true;
      },
  })
}

export const cloneTraining = async(trainingId:string)=>{
  return await axios.post<ResponseType<any>>(`${TRAINING_URL}/${trainingId}/clone`,{},{
      validateStatus(status) {
        return true;
      },
  })
}

export const deleteTraining = async(trainingId:string)=>{
  return await axios.delete<ResponseType<any>>(`${TRAINING_URL}/${trainingId}`,{
      validateStatus(status) {
        return true;
      },
  })
}